import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { DragulaService } from 'ng2-dragula';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { GlobalDate } from 'src/app/core/date-range/GlobalDate';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { RefreshCardResult, SingleRecordMode } from 'src/app/event.model';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestService } from 'src/app/nest-service.service';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { environment } from 'src/environments/environment';
import { AppServices } from './../../app-services.service';
import { EventService } from './../../event.service';

import { HelpService } from 'src/app/help-service.service';
import { EDokumentsService } from 'src/app/libraries/e-dokuments/e-dokuments.service';
import { tap, map, of, take, switchMap } from 'rxjs';
import { TASK_TYPES_KEY } from 'src/app/consts';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';

@Component({
  selector: 'app-task-canban',
  templateUrl: './task-canban.component.html',
  styleUrls: ['./task-canban.component.scss'],
  inputs: ['readOnly'],
  providers: [DragulaService],
  standalone: false,
})
export class TaskCanbanComponent implements OnInit, OnDestroy {
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  helpService = inject(HelpService);
  canbanHeight: number = 780;
  isDelete: boolean = false;
  newTaskStatus = null;
  newTaskType = null;
  taskTypes = [];
  statuses = [];
  shortcuts: ShortcutInput[] = [];
  readOnly;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  width = 280;
  widthLeftPanel = 280;
  selectedTask = [];
  dynamicFilterUserDefinitions: UserDefinition[] = [];
  readonly DynamicFilterDictionaryCode = 'CRM_TASK';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Crm_zadanie';
  viewUserDefinitionSub: Subscription = null;
  initialLocalStorageData = null;
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  // localStorageData: CustomWindowConfig = {
  pinnedViewsSub: Subscription = null;
  event = inject(EventService);
  localStorageData: any = {
    filter: {
      order: 'ASC',
      orderBy: 'subject',
      value: '',
    },
    tree: [], //{ value: any;key: string;}
    columns: [],
    mainPanelWidth: 'calc(100% - 300px)',
    bottomPanel: {
      selectedIndex: null,
      isVisible: false, //true
      height: 0,
    },
    sidePanel: {
      selectedIndex: 0,
      isVisible: false,
      width: 21,
    },
    windowSize: {
      isFullscreen: false,
      width: this.event.setWidthPopUp(),
      height: '90%',
    },
    params: [],
    // additionalTable: { // optional
    //   text: 'string',
    //   TableId: 'number',
    //   ServerName: 'string',
    // },
    advancedFilter: {
      FilterUsersDefinitionId: null,
      Name: '',
      UserFilterDefinitionValues: null, //AdditionalFieldFilter[],
    },
    skipUserGuide: false, // optional
    other: {
      singleWidth: 300,
      groupsChecked: [],
    },
  };
  popupSelectBoxSource = [
    { i: 'edit', text: 'buttons.edit' },
    { i: 'delete', text: 'buttons.delete' },
    { i: 'show', text: 'buttons.show' },
    { i: 'print', text: 'buttons.prints' },
    { i: 'edocuments', text: 'buttons.eDocuments' },
    { i: 'operations', text: 'form-financial-document.operations' },
  ];
  unicalGuid;
  dropSub = new Subscription();
  refreshCardResult: RefreshCardResult = 'Tak';
  filterCriteria: ICustomSearchItem[] = [
    {
      value: 'subject',
      label: 'temat',
      translationSrc: 'tasks.subjectIntuition',
    },
    {
      value: 'PctOfCompletion',
      label: 'realizacja',
      type: 'number',
      translationSrc: 'tasks.realisation',
    },
    {
      value: 'taskDate',
      label: 'data wprowadzenia',
      type: 'data',
      translationSrc: 'tasks.insertDate',
    }
  ];
  displayValue: string = 'nazwa';
  filterValue = '';
  selectedTaskData: any;
  isRecordCardVisible: boolean;
  recordCardMode: RecordCardMode;
  recordCardObjectId: number;

  @HostListener('window:resize', ['$event.target'])
  public onResize(target) {
    this.changeSize.next(target.innerHeight);
  }

  loadChildComponent: boolean = false;
  private changeSize = new Subject();
  activatedRouteSub;
  componentNests;
  nest;
  nestsSub;
  isNestRunning;
  nestOperation;
  closeNest;
  globalNestSub;
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };
  perEdocuments: boolean = false;
  eDocumentsVisible: boolean = false;

  constructor(
    public cd: ChangeDetectorRef,
    public edocs: EDokumentsService,
    public translate: TranslateService,
    private dragulaService: DragulaService,
    private appService: AppServices,
    private activatedRoute: ActivatedRoute,
    public nestService: NestService,
    private router: Router,
    private dynamicFilterService: DynamicFilterService
  ) {
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
    });
    this.canbanHeight = window.innerHeight - 160;
    this.changeSize
      .asObservable()
      .pipe(throttleTime(500))
      .subscribe((height: any) => {
        this.canbanHeight = height - 160;
      });
    this.perEdocuments = !this.event.isDisabledEDocuments('CrmTaskRelated');
    this.getReminderTimes();

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.dragulaService.createGroup('COLUMNS', {
      direction: 'horizontal',
      moves: (_el, _source, handle) => {
        return handle.className === 'group-handle';
      },
    });

    dragulaService.createGroup('ITEMS', {
      copy: (_el, source) => {
        if (source.id === 'copy-src') {
          return true;
        }
        return false;
      },
      accepts: (_el, target, _source, _sibling) => {
        return target.id !== 'copy-src';
      },
      copyItem: (_item) => {
        return {};
      },
      moves: (_el, _container, handle, _sibling) => {
        return handle.className.indexOf('move-box') > -1;
      },
    });

    this.dropSub.add(
      dragulaService
        .dropModel('ITEMS')
        .subscribe(({ el, target, targetModel, item }) => {
          if (
            item &&
            Object.keys(item).length === 0 &&
            Object.getPrototypeOf(item) === Object.prototype
          ) {
            const index = targetModel.findIndex((el) => !el.TaskId);
            if (index !== -1) {
              targetModel.splice(index, 1);
            }

            this.newTaskType = Number.parseInt(el['dataset']['id']);
            this.newTaskStatus = target['dataset']['id'];
            if (!this.perABD.addBtn) return;

            this.setSingleRecordVisible('add');
          } else {
            if (!this.perABD.editBtn) return;

            const newStatus = target['dataset']['id'];
            const itemToUpdate = Object.assign({}, item);
            itemToUpdate.StatusCode = newStatus;

            this.appService
              .getAuth(`crm/tasks/${itemToUpdate.TaskId}`)
              .subscribe(
                (res) => {
                  const task = res;
                  task.StatusCode = newStatus;
                  this.appService
                    .putAuth(`crm/tasks/${task.TaskId}`, task)
                    .subscribe(
                      () => {},
                      () => {}
                    );
                },
                () => {}
              );
            // TO DO - update rekordu dla itemToUpdate + refresh ?
          }
        })
    );

    // gniazda

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests;

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });
  }

  ngOnDestroy(): void {
    this.dropSub?.unsubscribe();
    this.dragulaService.destroy('COLUMNS');
    this.dragulaService.destroy('ITEMS');
    this.pinnedViewsSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();
    this.isAutoOnboardingSub?.unsubscribe();

    if (
      JSON.stringify(this.initialLocalStorageData) !==
      JSON.stringify(this.localStorageData)
    ) {
      localStorage.setItem(
        'leftCanbanTaskPanel',
        JSON.stringify(this.localStorageData)
      );
      const session = {
        View: 'CanbanTaskPanel',
        Configuration: JSON.stringify(this.localStorageData),
        TenantId: this.event.footerInfo.TenantId,
      };
      this.appService
        .postAuth(`viewConfigurations`, session)
        .subscribe(() => {});
    }

    this.activatedRouteSub.unsubscribe();
    this.globalNestSub.unsubscribe();
    this.currentDateSub?.unsubscribe();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadChildComponent = true;

      if (
        !this.localStorageData.skipUserGuide &&
        this.isAutoOnboarding &&
        this.event.deviceType === 'desktop'
      ) {
        this.isGuideActive = true;
      }
    }, 1000);

    this.shortcuts.push(
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/modul-crm');
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex === 0) {
            this.localStorageData.sidePanel.selectedIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.event.isDisabledEDocuments('CrmTaskRelated')) {
            this.edocs.getToEDocuments(
              'CrmTaskRelated',
              this.selectedTaskData?.TaskId,
              this.selectedTaskData?.Subject
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.deleteFilter = true;
          if (!this.readOnly) {
            if (
              !this.advancedFilterTemplateData &&
              this.localStorageData.advancedFilter
            ) {
              this.localStorageData.advancedFilter =
                this.dynamicFilterService.createDefaultAdvancedFilter(
                  this.localStorageData.advancedFilter?.IsPinned,
                  this.localStorageData.advancedFilter.PinnedFilters
                );
              this.cd.detectChanges();
            }
            this.clearValues();
            this.deleteFilter = false;
          }
        },
        preventDefault: true,
      }
    );
  }

  public groups: Array<any> = [];

  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'leftCanbanTaskPanel',
          JSON.stringify(this.localStorageData)
        );
        this.getTasks();
      }
    })
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;

        this.taskTypes.forEach((el) => {
          this.getTasksOfType(el);
        });
      }
    );

    this.getUserParameters();
    this.getViewConfigurations();
    this.getStatuses();
    this.getTaskTypes();
    this.getTranslations();

    this.perABD = this.event.checkPermissionsBtn(
      'DCrm',
      'ECrm',
      'UCrm',
      'OCrm'
    );

    setTimeout(() => {
      this.loadChildComponent = true;

      this.pinnedViewsSub = this.dynamicFilterService
        .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
        .subscribe({
          next: (data) => {
            if (!data) {
              return;
            }
            if (this.localStorageData?.advancedFilter) {
              this.localStorageData.advancedFilter.PinnedFilters =
                data.pinnedFilters;
            } else {
              this.localStorageData.advancedFilter = {
                PinnedFilters: data.pinnedFilters,
              };
            }
          },
        });
    }, 1000);
  }

  resetFiltr(filterType: string) {
    if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    }
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  }

  getViewConfigurations = () => {
    try {
      if (!localStorage.getItem('leftCanbanTaskPanel')) {
        this.appService
          .getAuth(`viewConfigurations/CanbanTaskPanel`)
          .subscribe((res) => {
            if (res.Configuration) {
              this.localStorageData = Object.assign(
                this.localStorageData,
                JSON.parse(res.Configuration)
              );
            }

            localStorage.setItem(
              'leftCanbanTaskPanel',
              JSON.stringify(this.localStorageData)
            );
            if (this.localStorageData.mainPanelWidth <= '21px') {
              this.localStorageData.sidePanel.isVisible = false;
            }
            this.getViewConfigurations();
          });
      } else if (localStorage.getItem('leftCanbanTaskPanel')) {
        this.localStorageData = JSON.parse(
          localStorage.getItem('leftCanbanTaskPanel')
        );
        this.initialLocalStorageData = JSON.parse(
          localStorage.getItem('leftCanbanTaskPanel')
        );

        this.filterValue = this.localStorageData.filter.value;
        if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
          this.getDefaultFilter();
        }
        else if (
          this.localStorageData?.advancedFilter
            ?.FilterUsersDefinitionId ||
          this.localStorageData?.advancedFilter?.IsPinned
        ) {
          this.getSavedFilterDefinitions();
        }
        if (this.localStorageData?.advancedFilter?.PinnedFilters) {
          this.dynamicFilterService.addPinnedViewsFilters(
            this.DynamicFilterDictionaryCode,
            this.localStorageData.advancedFilter.PinnedFilters
          );
        }
        setTimeout(() => {
          try {
            // if (this.localStorageData.sidePanel.width != null)
            //   this.leftPanel.nativeElement.style.width =
            //     this.localStorageData.sidePanel.width;
            if (this.localStorageData.sidePanel.width != null) {
              this.width = Number(
                this.localStorageData.sidePanel.width.replace('px', '')
              );
            }
            if (this.localStorageData.mainPanelWidth != null) {
              this.rightPanel.nativeElement.style.width =
                this.localStorageData.mainPanelWidth;
            }

            if (this.localStorageData.mainPanelWidth <= '21px') {
              this.localStorageData.sidePanel.isVisible = false;
            }

            this.resizeSingleBox();
          } catch {}
        }, 0);
      }
      //this.getData()
    } catch (e) {
      //this.onInitErr = true;
    }
  };

  getStatuses() {
    const localStatuses: string = sessionStorage.getItem(
      'crmTaskKanbanStatuses'
    );
    if (localStatuses) {
      this.statuses = this.event.decryptString(localStatuses);

      this.onAfterGetStatuses();
      return;
    }
    this.appService.getAuth('crm/tasks/status').subscribe(
      (res) => {
        this.statuses = res.data;
        sessionStorage.setItem(
          'crmTaskKanbanStatuses',
          this.event.encryptString(this.statuses)
        );
        this.onAfterGetStatuses();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        sessionStorage.removeItem('crmTaskKanbanStatuses');
      }
    );
  }

  onAfterGetStatuses() {
    this.groups = this.statuses.map((el) => ({
      name: el.Code,
      caption: el.Description,
      checked: true,
      items: [],
      StatusBackgroundColor: el.StatusBackgroundColor,
      StatusStringColor: el.StatusStringColor,
    }));
    let i = 0;
    this.dataSourceGroups = this.groups.map((el) => ({
      index: i++,
      status: el.name,
    }));

    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  }

  getStatusDescription(statusCode: string) {
    return (
      this.statuses?.find((el) => el.Code == statusCode)?.Description || '-'
    );
  }

  getOrderBy() {
    return this.localStorageData.filter.orderBy;
  }

  getLoadParams(statusCode: string) {
    let obj: any = {
      dateStart:
        this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '',
      dateEnd:
        this.event.dataFormatted({ value: this.currentDate.dateTo }) || '',

      orderBy: this.getOrderBy(), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      order: this.localStorageData.filter.order,
      statusCode,
    };

    if(this.localStorageData.filter.orderBy === 'number'){
      this.localStorageData.filter.orderBy = 'subject';
    }

    if (this.filterValue) {
      switch (this.localStorageData.filter.orderBy) {
        case 'subject':
          obj.subject = this.filterValue;
          break;
        case 'PctOfCompletion':
          obj.PctOfCompletion = this.filterValue;
          break;
        case 'taskDate':
          obj.taskDate = this.filterValue;
          break;
        default:
          break;
      }
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    return obj;
  }

  dataSources: DataSource[] = [];
  dataSourceGroups: any[] = [];

  firstLoad = true;
  getTasks(fromSearch: boolean = false) {
    for (let item of this.groups) {
      item.items = [];
    }

    this.dataSources = [];
    let isEmpty = true;

    for (let dsg of this.dataSourceGroups) {
      this.dataSources.push(
        new DataSource({
          store: AspNetData.createStore({
            key: 'TaskId',
            onBeforeSend: this.event.onBeforeSendDataSource,
            loadUrl: `${environment.domain}crm/tasks`,
            loadParams: this.getLoadParams(dsg.status),
            onAjaxError: this.event.onAjaxDataSourceError,
            deleteUrl: `${environment.domain}crm/tasks`,
            onLoaded: (res) => {
              if (res.length > 0) {
                isEmpty = false;
              }
              const group = this.groups.find((el) => el.name == dsg.status);

              group.items = group.items.concat(res);
              if (this.firstLoad) {
                this.firstLoad = false;
                this.searchControl?.focusInput();
              }
              if (fromSearch) {
                this.searchControl?.focusInput();
              }
            },
          }),
          reshapeOnPush: true,
        })
      );
    }
    if (isEmpty) {
      this.selectedTask = [];
    }

    this.dataSources.forEach((source) => {
      source.load();
    });

    setTimeout(() => {
      this.resizeSingleBox();
    });
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  };

  onResizeStart() {
    this.localStorageData.sidePanel.width =
      this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();

    this.resizeSingleBox();
  }

  getResizeIcon() {
    return this.localStorageData.sidePanel.isVisible
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  onInserted = () => {
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  };

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.isVisible = false;
    this.resizeSingleBox();
  };

  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.width = `280px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.isVisible = true;
    this.resizeSingleBox();
  };

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }
  }

  resizeSingleBox() {
    const panelWidth: number = this.rightPanel.nativeElement.offsetWidth - 25;
    this.localStorageData.other.singleWidth = Math.floor(
      panelWidth / this.groups.filter((group) => group.checked).length
    );
    if (this.localStorageData.other.singleWidth < 220) {
      this.localStorageData.other.singleWidth = 220;
    }
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'leftCanbanTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  clientX;
  clientY;
  arrowBottom: boolean = false;
  arrowRight: boolean = false;

  setSelected(item, e) {
    if (e.clientX > window.innerWidth - 350) {
      this.clientX = e.clientX - 350;
      this.arrowRight = true;
    } else {
      this.clientX = e.clientX - 20;
      this.arrowRight = false;
    }

    if (e.clientY > this.canbanHeight - 90) {
      this.clientY = e.clientY - 250;
      this.arrowBottom = true;
    } else {
      this.clientY = e.clientY;
      this.arrowBottom = false;
    }

    if (item.selected) {
      item.selected = false;
      this.selectedTask = [];
      return;
    }

    this.clearSelectedItem();

    item.selected = true;
    this.selectedTask = [item.TaskId];
    this.selectedTaskData = item;
  }

  onRightClick(item, e) {
    e.preventDefault();
    this.setSelected(item, e);
  }

  getTaskTypesDef() {
    const localData = this.event.getSessionStorageData(TASK_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`crm/taskTypes`).pipe(
        map((res) => res.data || []),
        tap((data) => {
          this.event.saveSessionStorageData(TASK_TYPES_KEY, data);
        })
      );
    }
  }

  getTaskTypes = () => {
    this.getTaskTypesDef().subscribe(
      async (res) => {
        this.taskTypes = res;
        this.taskTypes.forEach((el) => {
          if (el.Name === 'Spotkanie') {
            el.icon = 'user-group';
          } else if (el.Name === 'Telefon') {
            el.icon = 'phone-group';
          } else if (el.Name === 'Wyjazd') {
            el.icon = 'car-group';
          } else if (el.Name === 'Zlecenie serwisowe') {
            el.icon = 'service-group';
          } else {
            el.icon = 'default-icon';
          }

          this.getTasksOfType(el);
        });
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  };

  getTasksOfType(type) {
    this.appService
      .getAuth(`crm/tasks${this.getTasksOfTypeLoadParams(type.TypeId)}`)
      .subscribe({
        next: (res) => {
          type.amount = res.totalCount;
        },
        error: (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
          localStorage.removeItem('crmCanbanTaskTypes');
        },
      });
  }

  getTasksOfTypeLoadParams(typeId: number) {
    let params = `?typeId=${typeId}`;
    if (this.currentDate?.dateFrom) {
      params += `&dateStart=${this.event.dataFormatted({
        value: this.currentDate.dateFrom,
      })}`;
    }
    if (this.currentDate?.dateTo) {
      params += `&dateEnd=${this.event.dataFormatted({
        value: this.currentDate.dateTo,
      })}`;
    }

    switch (this.localStorageData.filter.orderBy) {
      case 'subject':
        params += `&subject=${this.filterValue}`;
        break;
      case 'PctOfCompletion':
        params += `&PctOfCompletion=${this.filterValue}`;
        break;
      case 'taskDate':
        params += `&taskDate=${this.filterValue}`;
        break;
      default:
        break;
    }

    return params;
  }

  getTypeDescription(value) {
    return this.taskTypes.find((el) => el.TypeId == value)?.Name || '-';
  }

  getNameShortcut(employee) {
    if (!employee) {
      return '';
    }

    return employee.FirstName[0] + employee.LastName[0];
  }

  getReminderTimer(val) {
    return this.reminderTimes.find((el) => el.value === val)?.label || '-';
  }

  reminderTimes = [];

  getReminderTimes() {
    const amount = 47;
    this.reminderTimes.push({ value: null, label: 'Brak' });
    this.reminderTimes.push({ value: 0, label: '00:00' });
    for (let i = 0; i < amount; i++) {
      let time =
        i < 19 ? '0' + Math.round(i / 2) : Math.round(i / 2).toString();
      time += i % 2 === 0 ? ':30' : ':00';

      this.reminderTimes.push({ value: i + 1, label: time });
    }
  }

  stop(e) {
    e.stopPropagation();
  }

  selectBoxValue = null;

  onSelectBoxValueChanged(e, item) {
    this.selectBoxValue = null;
    this.selectedTask = [item.TaskId];

    if (e.value.i == 'edit') {
      if (!this.perABD.editBtn) return;

      this.setSingleRecordVisible('edit');
    } else if (e.value.i == 'delete' && this.perABD.deleteBtn) {
      this.isDelete = true;
    } else if (e.value.i == 'show') {
      if (!this.perABD.showBtn) return;

      this.setSingleRecordVisible('show');
    } else if (e.value.i == 'print') {
    } else if (e.value.i == 'edocuments' && this.perEdocuments) {
      this.eDocumentsVisible = true;
      this.cd.detectChanges();
    } else if (e.value.i == 'operations') {
      return;
    }
    this.clearSelectedItem();
    this.isOpened = false;
  }

  onTitleClick(id: number) {
    if (!this.perABD.editBtn) return;

    this.selectedTask = [id];
    this.setSingleRecordVisible('edit');
  }

  clearSelectedItem() {
    this.groups.forEach((g) => {
      g.items.forEach((i) => {
        i.selected = false;
      });
    });
  }

  showNewRow() {
    if (!this.perABD.showBtn) return;

    this.newTaskStatus = null;
    this.newTaskType = null;
    this.setSingleRecordVisible('add');
  }

  addNewTaskBtn(status) {
    if (!this.perABD.addBtn) return;

    this.newTaskStatus = status;
    this.setSingleRecordVisible('add');
  }

  editSelectedRow(item) {
    if (!this.perABD.editBtn) return;

    this.selectedTask = [item.TaskId];
    this.setSingleRecordVisible('edit');
    this.clearSelectedItem();
  }

  showDeleteArticle() {
    if (this.selectedTask.length == 0) {
      return;
    }
    this.appService.deleteAuth(`crm/tasks/${this.selectedTask[0]}`).subscribe(
      () => {
        this.getTasks();
        this.taskTypes.forEach((el) => {
          this.getTasksOfType(el);
        });
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  }

  closeConfirm() {
    this.isDelete = false;
  }

  addTask(task) {
    if (!this.perABD.addBtn) return;

    this.newTaskType = task.TypeId;
    this.setSingleRecordVisible('add');
  }

  dateRangeVisible: boolean = false;

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.getTasks();
      this.taskTypes.forEach((el) => {
        this.getTasksOfType(el);
      });
    }
  }

  getTranslations() {
    this.event.translateSearchboxLabels(this.filterCriteria);
  }

  onFilterDataChanged(e) {
    console.log(e);
    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.displayValue = e.selectedItem.label;
      this.localStorageData.filter.order = 'ASC';
    }
    this.filterValue = e.filterValue;
    this.getTasks(true);
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  }

  addTaskType: boolean = false;

  addNewType() {
    this.addTaskType = true;
  }

  onInsertedTaskType() {
    this.getTaskTypes();
  }

  isOpened: boolean = false;
  columnsChooserVisible: boolean = false;

  onColumnsChanged() {
    this.localStorageData.other.groupsChecked = this.groups
      .filter((group) => group.checked)
      .map((el) => {
        return el.name;
      });
    this.resizeSingleBox();
  }

  extraLoading: boolean = false;

  onScroll(e, group) {
    if (e.reachedBottom && !this.extraLoading) {
      this.extraLoading = true;
      const index: number = this.dataSourceGroups.findIndex(
        (el) => el.status == group.name
      );
      const lastPage = this.dataSources[index].isLastPage();
      if (!lastPage) {
        this.dataSources[index].pageIndex(
          this.dataSources[index].pageIndex() + 1
        );
        this.dataSources[index].load().then(() => {
          this.extraLoading = false;
        });
      }
    }
  }

  filterPanel: any[] = [];

  resetFilterPanel(index: number) {
    //this.filterPanel[index].pin = false
    this.filters[index].value = null;
  }

  filters: any[] = [
    {
      name: 'template',
      type: 'template',
      value: null,
      label: null,
      pin: false,
      isQuickFilter: true,
    },
    {
      name: 'date',
      type: 'date',
      pin: false,
      value: { type: 'any', from: null, to: null },
      label: null,
      isQuickFilter: true,
    },
    {
      name: 'subject',
      type: 'text',
      value: null,
      label: null,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'location',
      type: 'text',
      value: null,
      label: null,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'description',
      type: 'text',
      value: null,
      label: null,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'priority',
      type: 'select',
      value: null,
      label: null,
      isQuickFilter: true,
      pin: false,
      active: false,
      dataSource: [
        { value: null, label: 'Wszystkie' },
        { value: 0, label: 'Niski' },
        { value: 1, label: 'Średni' },
        { value: 2, label: 'Wysoki' },
      ],
    },
    {
      name: 'status',
      type: 'status',
      value: null,
      label: null,
      pin: false,
      isQuickFilter: true,
    },
    {
      name: 'realisation',
      type: 'number-range',
      value: { from: null, to: null },
      label: null,
      pin: false,
      active: false,
      isQuickFilter: false,
    },
    {
      name: 'dateStart',
      type: 'date-range',
      pin: false,
      value: { from: null, to: null },
      label: null,
      active: false,
      isQuickFilter: false,
    },
    {
      name: 'dateEnd',
      type: 'date-range',
      value: { from: null, to: null },
      label: null,
      active: false,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'worker',
      type: 'worker',
      value: null,
      label: null,
      pin: false,
      active: false,
      isQuickFilter: true,
    },
    {
      name: 'noWorker',
      type: 'check-box',
      value: false,
      label: null,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'customer',
      type: 'customer',
      value: null,
      label: null,
      pin: false,
      active: false,
      isQuickFilter: true,
    },
    {
      name: 'customerContact',
      type: 'customerContact',
      value: null,
      label: null,
      active: false,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'noCustomerContact',
      type: 'check-box',
      value: false,
      label: null,
      pin: false,
      isQuickFilter: false,
    },
    {
      name: 'user',
      type: 'user',
      value: null,
      label: null,
      pin: false,
      active: false,
      isQuickFilter: true,
    },
    {
      name: 'attachment',
      type: 'radio',
      value: null,
      label: null,
      active: false,
      pin: false,
      isQuickFilter: true,
      dataSource: [
        { value: 0, label: 'Bez załącznika' },
        { value: 1, label: 'Z załącznikiem' },
      ],
    },
  ];

  // gniazda
  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGCODA2':
        this.findSocketAndRun('XGCOAA2');
        break;
      case 'XGCOPA2':
        this.findSocketAndRun('XGCOAA2');
        break;
      case 'XGCODZ2':
        this.findSocketAndRun('XGCOAZ2');
        break;
      case 'XGCOPZ2':
        this.findSocketAndRun('XGCOAZ2');
        break;
      default:
        break;
    }
  }

  findSocketAndRun(SocketCode: string, operation: string = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest && newNest.Operations && newNest.Operations.length) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  isLeftSelect: boolean = false;

  switchIsOpened(e: MouseEvent) {
    this.isLeftSelect = e.clientX > window.innerWidth - 200;
    setTimeout(() => {
      this.isOpened = !this.isOpened;
    });

    this.stop(e);
  }

  refreshView() {
    sessionStorage.removeItem(TASK_TYPES_KEY);
    sessionStorage.removeItem('crmTaskKanbanStatuses');
    this.getStatuses();
    this.getTaskTypes();
  }

  singleRecordMode: SingleRecordMode = null;
  isSingleRecordVisible: boolean = false;

  onSingleRecordClosing() {
    this.isSingleRecordVisible = false;
    if (this.singleRecordMode === 'add') {
      this.findSocketAndRun('XGCODA2', 'add');
    } else if (this.singleRecordMode === 'edit') {
      this.findSocketAndRun('XGCOPA2', 'update');
    }
    this.singleRecordMode = null;
    this.cd.detectChanges();
  }

  onSingleRecordInserted() {
    this.onInserted();
    if (this.singleRecordMode === 'add') {
      this.findSocketAndRun('XGCODZ2', 'add');
      this.findSocketAndRun('XGCOAZ2', 'add');
    } else if (this.singleRecordMode === 'edit') {
      this.findSocketAndRun('XGCOPZ2', 'update');
      this.findSocketAndRun('XGCOAZ2', 'update');
    }
    this.singleRecordMode = null;
    this.isSingleRecordVisible = false;
  }

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  getUserParameters() {
    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onFilterSaved(e: any) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'leftCanbanTaskPanel',
      this.event.encryptString(this.localStorageData)
    );

    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  }

  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    localStorage.setItem(
      'leftCanbanTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  runGuide() {
    this.isGuideActive = true;
  }

  onShouldReloadTypes() {
    this.getTaskTypes();
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getTasks();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData?.sidePanel?.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  deleteFilter = false;
  advancedFilterTemplateData = null;

  clearValues() {
    this.deleteFilter = true;
    this.cd.detectChanges();
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.deleteFilter = false;
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  contextMenuSignal = computed(() => {
    return [
      {
        icon: 'icon absui-icon--mode-edit',
        text: this.translate.instant('buttons.edit'),
        itemIndex: 0,
      },
      {
        icon: 'icon absui-icon--trash',
        text: this.translate.instant('buttons.delete'),
        itemIndex: 1,
      },
      {
        icon: 'icon absui-icon--form-items',
        text: this.translate.instant('buttons.show'),
        itemIndex: 2,
      },
      {
        icon: 'icon absui-icon--print',
        text: this.translate.instant('buttons.prints'),
        itemIndex: 3,
        disabled: true,
      },
      {
        icon: 'icon absui-icon--edocuments',
        text: this.translate.instant('buttons.eDocuments'),
        itemIndex: 4,
      },
      {
        icon: 'icon absui-icon--operations',
        text: this.translate.instant('form-financial-document.operations'),
        itemIndex: 5,
        items: this.additionalOperationList(),
      },
    ];
  });

  contextMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.perABD.editBtn) return;
        this.setSingleRecordVisible('edit');
        break;

      case 1:
        if (!this.perABD.deleteBtn) return;
        this.isDelete = true;
        break;

      case 2:
        if (!this.perABD.showBtn) return;
        this.setSingleRecordVisible('show');
        break;

      case 3:
        // TODO: podpiąć wydruki
        break;

      case 4:
        if (!this.perEdocuments) return;
        this.eDocumentsVisible = true;
        break;

      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;

      default:
        return;
    }
    this.cd.detectChanges();
  }

  additionalOperationList = signal<any[]>([]);
  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        try {
          this.recordCardObjectId = this.selectedTaskData.CustomerId;
          if (!this.recordCardObjectId) throw new Error();
        } catch (e) {
          this.recordCardObjectId = null;
          this.event.showNotification(
            'warning',
            this.translate.instant('recordCard.noCustomerTask')
          );
        }
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

  changeFilterCriteria(e: any): void {
    let updateDocuments: boolean = e.autoEmit;
    if (e.sortDirection !== this.localStorageData?.filter?.sortOrder) {
      this.localStorageData.filter.order = e.sortDirection;
      updateDocuments = true;
    }
    if (e.selectedItem.value !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
    }
    //this.updateKanbanLocalStorageData();
    if (updateDocuments) {
      this.getTasks();
    }
  }
  
  filterCriteriaViewOpened: boolean = false;
}

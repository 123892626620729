import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-edit-tax-moment',
    templateUrl: './edit-tax-moment.component.html',
    styleUrls: ['./edit-tax-moment.component.scss'],
    inputs: ['readOnly', 'isVisible', 'title', 'selected', 'currencyCode'],
    standalone: false
})
export class EditTaxMomentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  readOnly;
  isVisible;
  title;
  selected;

  unicalGuid;
  widthWindow = 460;
  heightWindow = 270;
  maxHeight;
  maxWidth;
  currencyCode;
  dataSource = [];
  selectedRowKeys;

  shortcuts: ShortcutInput[] = [];
  form;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.createForm();
  }

  ngOnChanges(): void {
    if (this.isVisible) {
      this.form.patchValue(this.selected[0]);
      if (this.currencyCode != '')
        this.form.controls.CurrencyCode.setValue(this.currencyCode);

      if (this.form.value.DateOfCurrencyTax === '0001-01-01T00:00:00') {
        this.form.controls.DateOfCurrencyTax.setValue(null);
      }
    }
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  closeWindow() {
    this.onClosing.emit(true);
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      WarehouseDocumentId: 0,
      DateOfTax: '',
      DateOfCurrencyTax: null,
      CurrencyFactor: 0,
      InvoiceDocumentId: 0,
      CurrencyCode: '',
    });
  };

  onSave = () => {
    this.onInserted.emit(this.form.value);
    this.isVisible = false;
  };
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-create-zd',
    templateUrl: './create-zd.component.html',
    styleUrls: ['./create-zd.component.scss'],
    inputs: ['isVisible', 'product', 'title', 'readOnly', 'OrderDocumentId'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreateZDComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  isVisible;
  product;
  OrderDocumentId;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  widthWindow: string | number | Function = 655;
  heightWindow: string | number | Function = 380;
  title;
  customerId;
  readOnly;
  listRadio = [
    {
      value: 2,
      label: 'niezarezerwowaną',
    },
    {
      value: 1,
      label: 'niezrealizowaną',
    },
    {
      value: 0,
      label: 'całą zamówioną',
    },
    {
      value: 3,
      label: 'zarezerwowaną',
    },
  ];

  object: any = {
    OrderDocumentId: 0,
    SupplierCusotmerId: 0,
    IsMargePositions: false,
    AmountPositionsMode: 0,
    DaysForRealisation: 0,
  };
  shortcuts: ShortcutInput[] = [];
  submitted: boolean = false;

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('documentsOrders.unreserved')
      .subscribe((text) => (this.listRadio[0].label = text));

    this.translate
      .get('documentsOrders.unrealized')
      .subscribe((text) => (this.listRadio[1].label = text));

    this.translate
      .get('documentsOrders.allOrdered')
      .subscribe((text) => (this.listRadio[2].label = text));

    this.translate
      .get('documentsOrders.booked')
      .subscribe((text) => (this.listRadio[3].label = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.readOnly) return;
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  closeWindow() {
    this.isVisible = false;
    this.submitted = false;
    this.onClosing.emit(false);
  }

  visibleChange = (e) => {
    if (!e) this.onClosing.emit(false);
    this.cd.detectChanges();
  };

  onChoosedCustomer(e) {
    if (e) {
      this.object.SupplierCusotmerId = e.CustomerId;
    }
  }

  onSave() {
    this.submitted = true;
    this.object.OrderDocumentId = this.OrderDocumentId;
    this.cd.detectChanges();

    if (
      this.object.SupplierCusotmerId == 0 ||
      this.object.SupplierCusotmerId == null
    )
      return;

    this.appService
      .postAuth(
        `orders/additionalOperations/${this.OrderDocumentId}/createFromCustomerToSupplier`,
        this.object
      )
      .subscribe(
        (data) => {
          this.onInserted.emit(data);
          this.closeWindow();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }
}

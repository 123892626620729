import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-add-advance-payment',
    templateUrl: './add-advance-payment.component.html',
    styleUrls: ['./add-advance-payment.component.scss'],
    inputs: ['isVisible', 'InvoiceDocumentId', 'readOnly', 'left'],
    standalone: false
})
export class AddAdvancePaymentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  isVisible;
  InvoiceDocumentId;
  readOnly;
  unicalGuid;

  widthWindow = 470;
  heightWindow = 358;

  shortcuts: ShortcutInput[] = [];
  form;
  left;
  submitted: boolean = false;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(true);
        },
        preventDefault: true,
      }
    );
  }

  setAmount = () => {
    this.form.controls.Amount.setValue(this.left);
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      InvoiceDocumentId: [this.InvoiceDocumentId],
      Amount: [0, [Validators.required, Validators.min(0.01)]],
      PaymentFormName: ['gotówka', Validators.required],
      Rest: [0],
      PaymentId: [0],
      CurrencyAmount: [0],
      CurrencyCode: ['PLN'],
      CurrencyFactor: [0],
    });
  };

  visibleChange = (e) => {
    if (!e) {
      this.submitted = false;
      this.createForm();
      this.onClosing.emit(false);
    }
  };

  timerAmount = 100;
  myTimerAmount;
  setRest = () => {
    if (this.left < this.form.value.Amount)
      this.form.controls.Rest.setValue(this.form.value.Amount - this.left);

    this.timerAmount = 100;
    clearInterval(this.myTimerAmount);
    this.myTimerAmount = setInterval(() => {
      this.timerAmount--;
      if (this.timerAmount == 0) {
        clearInterval(this.myTimerAmount);
        if (
          this.form.value.CurrencyCode != 'PLN' &&
          this.form.value.CurrencyFactor != 0
        )
          this.form.controls.CurrencyAmount.setValue(
            this.form.value.Amount / this.form.value.CurrencyFactor
          );
      }
    });
  };

  onChoosedFormPeyment = (e) => {
    if (e) {
      this.form.controls.PaymentFormName.setValue(e.PaymentFormName);
    } else this.form.controls.PaymentFormName.setValue('');
  };

  onChoosedCurrency = (e) => {
    if (e) {
      if (e.CurrencyCode != 'PLN') {
        this.form.controls.CurrencyCode.setValue(e.CurrencyCode);
        this.appService
          .getAuth(
            `currencies/rates?currencyCode=${this.form.value.CurrencyCode}&bank=NBP`
          )
          .subscribe((res) => {
            try {
              let dataCurse = new DatePipe('en-US').transform(
                res.data[0].Rates[0].Date,
                'yyyy-MM-dd'
              );

              let dataToday = new DatePipe('en-US').transform(
                new Date(),
                'yyyy-MM-dd'
              );

              this.form.controls.CurrencyFactor.setValue(
                res.data[0].Rates[0].Buy
              );

              if (dataCurse != dataToday) {
                this.idDownloadCurses = true;
              }
            } catch {
              this.idDownloadCurses = true;
            }
            this.form.controls.CurrencyCode.setValue(e.CurrencyCode);
          });
      }
    } else {
      this.form.controls.CurrencyCode.setValue('');
      this.form.controls.CurrencyFactor.setValue(0);
    }
  };

  idDownloadCurses: boolean = false;

  downloadCurse = () => {
    this.event.onShown();
    this.idDownloadCurses = false;
    let DateOfInvoiceDocument = new DatePipe('en-US').transform(
      new Date(),
      'yyyy-MM-dd'
    );
    let object = {
      Bank: 'NBP',
      Date: DateOfInvoiceDocument,
      ForceReset: false,
    };
    this.getRate(object, 1);
  };

  getRate = (object, dayMinus) => {
    this.appService.postAuth(`currencies/importRates`, object).subscribe(
      () => {
        let lastDay = new DatePipe('en-US').transform(
          new Date().getTime() - dayMinus * 1000 * 60 * 60 * 24,
          'yyyy-MM-dd'
        );
        this.appService
          .getAuth(
            `currencies/rates?currencyCode=${this.form.value.CurrencyCode}&date=${lastDay}&bank=NBP`
          )
          .subscribe((res) => {
            // let date = new DatePipe('en-US').transform(
            //   res.data[0].Date,
            //   'yyyy-MM-dd'
            // );

            this.form.controls.CurrencyFactor.setValue(
              res.data[0].Rates[0].Buy
            );
            this.event.onHidden();
          });
      },
      () => {
        dayMinus++;
        let lastDay = new Date().getTime() - dayMinus * 1000 * 60 * 60 * 24;
        let newObject = {
          Bank: 'NBP',
          Date: new DatePipe('en-US').transform(lastDay, 'yyyy-MM-dd'),
          ForceReset: false,
        };
        this.getRate(newObject, dayMinus);
      }
    );
  };

  timerWarehouse = 100;
  myTimerWarehouse;
  setPLNValue = () => {
    this.timerWarehouse = 100;
    clearInterval(this.myTimerWarehouse);
    this.myTimerWarehouse = setInterval(() => {
      this.timerWarehouse--;
      if (this.timerWarehouse == 0) {
        clearInterval(this.myTimerWarehouse);
        this.form.controls.Amount.setValue(
          this.form.value.CurrencyAmount * this.form.value.CurrencyFactor
        );
      }
    });
  };

  onSave = () => {
    this.submitted = true;

    if (this.form.valid) {
      this.isVisible = false;
      this.onInserted.emit(this.form.value);
    }
  };
}

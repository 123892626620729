<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [(fullScreen)]="fullScreen"
  (onShown)="event.onShownPopUp(); setPaymentForm()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (fullScreenChange)="fullScreenChange()"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up title-pop-upPeyment">
      <dx-button
        type="default"
        stylingMode="contained"
        *ngIf="!fullScreen"
        (click)="fullScreen = !fullScreen"
        [id]="'fitToWidth' + unicalGuid"
        icon="icon absui-icon--fullscreen2"
      >
      </dx-button>
      <dx-button
        type="default"
        stylingMode="contained"
        *ngIf="fullScreen"
        (click)="fullScreen = !fullScreen"
        [id]="'fitToWidth' + unicalGuid"
        icon="icon absui-icon--fullscreen-off"
      >
      </dx-button>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        style="margin-right: 6px"
        [disabled]="isAdvancePayment"
        (onClick)="onAdvancePayment()"
        [text]="'form-commercial-operation.advancePayment' | translate"
        [id]="'advancedPayment' + unicalGuid"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="btn-savePayment"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <div
      class="header-payment"
      [ngClass]="{ boxShadowPayment: scrollPosition > 0 }"
    >
      <header class="headerPaymentInvoices">
        <div class="totayToPayBox">
          <div class="row">
            <div class="col-md-6">
              <p class="headerHeadline">
                {{
                  "form-commercial-operation.paymentForProductsFromTheList"
                    | translate
                }}
              </p>
              <p class="toCusomerLabel">
                {{ "form-commercial-operation.forTheRecipient" | translate }}
              </p>
            </div>
            <div class="col-md-6 text-right" style="padding-right: 45px">
              <p class="currencyLabel" *ngIf="!CurrencyCode">PLN</p>
              <p class="currencyLabel" *ngIf="CurrencyCode">
                {{ CurrencyCode }}
              </p>
              <p class="totalToPayLabel">
                {{ TotalGrossAmount | amountFormatter : null }}
              </p>
            </div>
          </div>
        </div>
      </header>

      <div class="justify-content-center-payment">
        <div class="function-payment-box" (click)="emitChosedForPayment()">
          <p class="p_payment_box">
            {{ "form-commercial-operation.paid" | translate }}:
          </p>
          <p class="p_payment_currency">{{ paid | amountFormatter : null }}</p>
        </div>
        <div class="function-payment-box">
          <p class="p_payment_box">
            {{ "form-commercial-operation.rest" | translate }}:
          </p>
          <p
            class="p_payment_currency"
            [ngClass]="{ incorectValue: rest != 0 }"
          >
            {{ rest | amountFormatter : null }}
          </p>
        </div>
        <div class="function-payment-box">
          <p class="p_payment_box">{{ "left" | translate }}:</p>
          <p
            class="p_payment_currency"
            [ngClass]="{ incorectValue: left != 0 }"
          >
            {{ left | amountFormatter : null }}
          </p>
        </div>
      </div>
    </div>

    <dx-scroll-view
      width="100%"
      height="calc(100% - 225px)"
      direction="vertical"
      #scrollView
      (onScroll)="onScroll($event)"
      style="margin-top: 24px"
      *ngIf="!isAdvancePayment; else advancePaymentTemplate"
    >
      <div class="paymentFormBox">
        <div
          class="payment-item"
          (click)="activeFormPayment('isCash')"
          [ngClass]="{ 'selected-form-payment': isCash }"
        >
          <div class="icon-box text-center">
            <i class="icon absui-icon--payment"></i>
          </div>

          <div style="display: inline-block">
            <p class="formName">
              {{ "form-commercial-operation.cash" | translate }}
            </p>
            <p class="descriptionPaymentForm">
              {{ "form-commercial-operation.traditionalPayment" | translate }}
            </p>
          </div>

          <div class="checkBox" *ngIf="isSmashDonations">
            <dx-check-box
              [(value)]="isCash"
              class="checkbox-payment"
              (onValueChanged)="changedChosedFormPayment('isCash', $event)"
            ></dx-check-box>
          </div>

          <div class="dot-box" *ngIf="!isSmashDonations">
            <div class="dot-insert"></div>
          </div>

          <div style="float: right; margin-right: 18px" *ngIf="isCash">
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatPLN"
              valueChangeEvent="input"
              class="payment-box-number"
              [(ngModel)]="cashValue"
              (onValueChanged)="sumValue($event, 'cashValue')"
            ></dx-number-box>
          </div>
        </div>

        <div
          class="payment-item"
          (click)="activeFormPayment('isSpillway')"
          [ngClass]="{ 'selected-form-payment': isSpillway }"
        >
          <div class="icon-box text-center">
            <i class="icon swap-horiz"></i>
          </div>

          <div style="display: inline-block">
            <p class="formName">
              {{ "form-commercial-operation.spillway" | translate }}
            </p>
            <p class="descriptionPaymentForm">
              {{ "form-commercial-operation.onlineOrTraditional" | translate }}
            </p>
          </div>

          <div class="checkBox" *ngIf="isSmashDonations">
            <dx-check-box
              [(value)]="isSpillway"
              class="checkbox-payment"
              (onValueChanged)="changedChosedFormPayment('isSpillway', $event)"
            ></dx-check-box>
          </div>

          <div class="dot-box" *ngIf="!isSmashDonations">
            <div class="dot-insert"></div>
          </div>

          <div style="float: right; margin-right: 18px" *ngIf="isSpillway">
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatPLN"
              class="payment-box-number"
              [(ngModel)]="spillwayValue"
              (onValueChanged)="sumValue($event, 'spillwayValue')"
              [readOnly]="!isSmashDonations"
            ></dx-number-box>
          </div>
        </div>

        <div
          class="payment-item"
          (click)="activeFormPayment('isCreditCard')"
          [ngClass]="{ 'selected-form-payment': isCreditCard }"
        >
          <div class="icon-box text-center">
            <i class="icon credit-card"></i>
          </div>

          <div style="display: inline-block">
            <p class="formName">
              {{ "form-commercial-operation.card" | translate }}
            </p>
            <p class="descriptionPaymentForm">
              {{ "form-commercial-operation.debitOrCreditCard" | translate }}
            </p>
          </div>

          <div class="checkBox" *ngIf="isSmashDonations">
            <dx-check-box
              [(value)]="isCreditCard"
              class="checkbox-payment"
              (onValueChanged)="
                changedChosedFormPayment('isCreditCard', $event)
              "
            ></dx-check-box>
          </div>

          <div class="dot-box" *ngIf="!isSmashDonations">
            <div class="dot-insert"></div>
          </div>

          <div style="float: right; margin-right: 18px" *ngIf="isCreditCard">
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatPLN"
              class="payment-box-number"
              [(ngModel)]="creditCardValue"
              (onValueChanged)="sumValue($event, 'creditCardValue')"
              [readOnly]="!isSmashDonations"
            ></dx-number-box>
          </div>
        </div>

        <div
          class="payment-item"
          (click)="activeFormPayment('isVoucher')"
          [ngClass]="{ 'selected-form-payment': isVoucher }"
        >
          <div class="icon-box text-center">
            <i class="icon money-banknote"></i>
          </div>

          <div style="display: inline-block">
            <p class="formName">
              {{ "form-commercial-operation.voucher" | translate }}
            </p>
            <p class="descriptionPaymentForm">
              {{ "form-commercial-operation.additionalPayment" | translate }}
            </p>
          </div>

          <div class="checkBox" *ngIf="isSmashDonations">
            <dx-check-box
              [(value)]="isVoucher"
              class="checkbox-payment"
              (onValueChanged)="changedChosedFormPayment('isVoucher', $event)"
            ></dx-check-box>
          </div>

          <div class="dot-box" *ngIf="!isSmashDonations">
            <div class="dot-insert"></div>
          </div>

          <div style="float: right; margin-right: 18px" *ngIf="isVoucher">
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatPLN"
              class="payment-box-number"
              [(ngModel)]="voucherValue"
              (onValueChanged)="sumValue($event, 'voucherValue')"
            ></dx-number-box>
          </div>
        </div>

        <div class="text-right" style="padding-right: 5px">
          <p class="labelSplach">
            {{ "form-commercial-operation.smashDonations" | translate }}
          </p>
          <dx-switch
            width="21"
            style="margin-right: 0px"
            [(value)]="isSmashDonations"
            class="filter-switch payment-switch"
          >
          </dx-switch>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-template #advancePaymentTemplate>
  <div
    class="paymentFormBox"
    style="height: calc(100% - 245px)"
    [ngClass]="{ biggerPadding: scrollPosition > 0 }"
  >
    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      #scrollView
      (onScroll)="onScroll($event)"
      style="margin-top: 24px"
    >
      <div *ngFor="let payment of advancePaymentList; let i = index">
        <div
          class="payment-item"
          style="width: calc(100% - 85px); display: inline-block"
          [ngClass]="{
            'expended-form-payment': payment.Expanded,
            'selected-form-payment': payment.Expanded
          }"
        >
          <div class="icon-box text-center">
            <i
              class="icon"
              [ngClass]="{
                'absui-icon--payment': payment.PaymentFormName == 'gotówka',
                'swap-horiz': payment.PaymentFormName == 'przelew',
                'credit-card': payment.PaymentFormName == 'karta kredytowa',
                'money-banknote':
                  payment.PaymentFormName != 'gotówka' &&
                  payment.PaymentFormName != 'przelew' &&
                  payment.PaymentFormName != 'karta kredytowa'
              }"
            ></i>
          </div>

          <div style="display: inline-block">
            <p class="formName">
              {{
                payment.PaymentFormName[0].toUpperCase() +
                  payment.PaymentFormName.substring(1)
              }}
            </p>
            <p
              class="descriptionPaymentForm"
              *ngIf="payment.PaymentFormName == 'gotówka'"
            >
              {{ "form-commercial-operation.traditionalPayment" | translate }}
            </p>
            <p
              class="descriptionPaymentForm"
              *ngIf="payment.PaymentFormName == 'przelew'"
            >
              {{ "form-commercial-operation.onlineOrTraditional" | translate }}
            </p>
            <p
              class="descriptionPaymentForm"
              *ngIf="payment.PaymentFormName == 'karta kredytowa'"
            >
              {{ "form-commercial-operation.debitOrCreditCard" | translate }}
            </p>
            <p
              class="descriptionPaymentForm"
              *ngIf="
                payment.PaymentFormName != 'gotówka' &&
                payment.PaymentFormName != 'przelew' &&
                payment.PaymentFormName != 'karta kredytowa'
              "
            >
              {{ "form-commercial-operation.additionalPayment" | translate }}
            </p>
          </div>

          <div style="float: right; margin-right: 13px; padding-top: 10px">
            <i
              class="icon"
              (click)="payment.Expanded = !payment.Expanded"
              [ngClass]="{
                'absui-icon--expand-more': !payment.Expanded,
                'absui-icon--expand-less top10': payment.Expanded
              }"
            ></i>
          </div>

          <div
            style="float: right; margin-right: 13px"
            *ngIf="!payment.Expanded"
          >
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatPLN"
              valueChangeEvent="input"
              class="payment-box-number"
              [(ngModel)]="payment.Amount"
              (onValueChanged)="setRest(payment, i); countPaymentAdvance()"
            ></dx-number-box>
          </div>

          <div
            class="form-group-inline-right"
            style="width: 100%; padding-top: 15px"
            *ngIf="payment.Expanded"
          >
            <label style="width: 200px">{{
              "form-payment.depositAmount" | translate
            }}</label>
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatPLN"
              valueChangeEvent="input"
              class="payment-box-number text-left-payment"
              [(ngModel)]="payment.Amount"
              (onValueChanged)="countPaymentAdvance(); setRest(payment, i)"
            >
            </dx-number-box>
            <br />

            <label style="width: 200px">{{
              "bankAccounts.form.currency" | translate
            }}</label>
            <app-currency-box
              [dropDownBoxMode]="true"
              [readOnly]="readOnly"
              #currencyList
              [selectedId]="payment.CurrencyCode"
              (onChoosed)="onChoosedCurrency($event, i)"
              [width]="200"
              [paymentForm]="true"
            >
            </app-currency-box>
            <br />

            <label style="width: 200px">{{
              "baseCurrencies.currencyValue" | translate
            }}</label>
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatCurrency"
              valueChangeEvent="input"
              class="payment-box-number text-left-payment"
              [(ngModel)]="payment.CurrencyFactor"
              (onValueChanged)="countPaymentAdvance(); setPLNValue()"
              [readOnly]="
                payment.CurrencyCode == 'PLN' || payment.CurrencyCode == ''
              "
            >
            </dx-number-box>
            <br />

            <label style="width: 200px">{{
              "form-payment.paymentInCurrency" | translate
            }}</label>
            <dx-number-box
              width="200"
              style="height: 38px"
              [format]="event.formatCurrency"
              valueChangeEvent="input"
              class="payment-box-number text-left-payment"
              [(ngModel)]="payment.CurrencyAmount"
              (onValueChanged)="countPaymentAdvance(); setPLNValue()"
              [readOnly]="
                payment.CurrencyCode == 'PLN' || payment.CurrencyCode == ''
              "
            >
            </dx-number-box>
          </div>
        </div>

        <div class="boxDelete">
          <dx-button
            icon="icon absui-icon--highlight-off"
            class="btn-dx-top-menu-payment"
            [disabled]="readOnly"
            [text]="'buttons.delete' | translate"
            (onClick)="deleteAdvancePayment(i)"
          >
          </dx-button>
        </div>
      </div>
    </dx-scroll-view>
  </div>

  <div style="padding: 0px 90px">
    <div class="addPaymentBox" (click)="addRow = true">
      <div class="icon-box-add-payment text-center">
        <i
          class="icon absui-icon--add-circle circlePayment"
          style="top: 1px; position: relative"
        ></i>
      </div>

      <div style="display: inline-block">
        <p class="formName">{{ "form-payment.addPayment" | translate }}</p>
      </div>
    </div>
  </div>

  <app-add-advance-payment
    [isVisible]="addRow"
    (onClosing)="addRow = false"
    [InvoiceDocumentId]="InvoiceDocumentId"
    [left]="left"
    (onInserted)="onInsertedAdvanceFormPayment($event)"
  >
  </app-add-advance-payment>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-commercial-operation.thePaymentMethodIsDifferent"
  [isVisible]="isChangeFormPaymeny"
  (onRemoving)="onFormPaymentChanged()"
  (onClosing)="closingDefrentForm()"
>
</app-confirm-dialog>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-financial-document.rateDateDocumentDifferent"
  [isVisible]="idDownloadCurses"
  (onRemoving)="downloadCurse()"
  (onClosing)="idDownloadCurses = false"
>
</app-confirm-dialog>

<app-confirm-dialog
  btnConfig="close"
  kindDialog="warning"
  confirmText="form-commercial-operation.theAmountofTheNonCashPaymentCannotBeGreaterThanTheAmountToBePaid"
  [isVisible]="isWrongAmountPayment"
  (onClosing)="closingWarnignCash()"
>
</app-confirm-dialog>

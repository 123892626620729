import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../../environments/environment';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-start-realization',
    templateUrl: './start-realization.component.html',
    styleUrls: ['./start-realization.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'readOnly',
        'documentDate',
        'invoicesDocumentId',
        'DateOfInvoiceDocument',
        'SumBy',
        'document',
        'documentType',
    ],
    standalone: false
})
export class StartRealizationComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @Input() isShowNotRealizedForWarehouseDocument: boolean = false;
  @ViewChild('itemGrid') itemGrid;
  documentType;
  isVisible;
  title;
  readOnly;
  documentDate;
  DateOfInvoiceDocument;
  widthWindow = '700';
  heightWindow = 600;
  maxHeight;
  maxWidth;
  unicalGuid;

  invoicesDocumentId;
  shortcuts: ShortcutInput[] = [];
  SumBy;
  document = 'invoices';
  wgList = [
    { label: 'wg nazwy', value: 'ProductFullName' },
    { label: 'wg indeksu katalogowego', value: 'IndexCatalogue' },
    { label: 'wg indeksu handlowego', value: 'IndexTrading' },
    { label: 'wg lokalizacji', value: 'Location' },
  ];
  valueCriteriaPosition = 'ProductFullName';
  filterValuePosition = '';
  orderby: string = 'ProductFullName';
  order: string = 'ASC'; // asc/desc

  selectedPositionDocument = [];
  focusedSelectedPosition = [];
  dataSource;
  useForSelection: boolean = false;

  sumPosition = {
    TotalGrossAmount: 0,
    TotalNetAmount: 0,
  };

  isAmountToRealized: boolean = false;
  amountToRealized = 0;

  myEventEsc;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.widthWindow = this.event.setWidthPopUp();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;

    this.translate
      .get('warehouseDocument.byName')
      .subscribe((text) => (this.wgList[0].label = text));
    this.translate
      .get('warehouseDocument.accordingToTheCatalogIndex')
      .subscribe((text) => (this.wgList[1].label = text));
    this.translate
      .get('warehouseDocument.accordingToTheTradeIndex')
      .subscribe((text) => (this.wgList[2].label = text));
    this.translate
      .get('warehouseDocument.byLocation')
      .subscribe((text) => (this.wgList[3].label = text));

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (this.isVisible && !this.isAmountToRealized) this.isVisible = false;
    });
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnChanges() {
    if (this.isVisible) {
    }
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
    this.myEventEsc.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.amountToRealized = 0;
          this.isAmountToRealized
            ? (this.isAmountToRealized = false)
            : this.cancel();
        },
        preventDefault: true,
      },
      {
        key: 'Enter',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.setAmountToRealizedForSelected();
        },
        preventDefault: true,
      }
    );
  }

  cancel = () => {
    // this.appService
    //   .putAuth(
    //     `orders/documents/${this.documentDate[0].OrderDocumentId}/cancel?modifyMode=2`,
    //     { orderDocumentId: this.documentDate[0].OrderDocumentId, modifyMode: 2 }
    //   )
    //   .subscribe(
    //     (res) => {
    let object;

    object = {
      OrderDocumentId: this.documentDate[0].OrderDocumentId,
      InvoiceDocumentId: this.invoicesDocumentId,
      SumBy: this.SumBy,
    };

    if (this.document == 'warehouseDocuments') {
      object = {
        OrderDocumentId: this.documentDate[0].OrderDocumentId,
        WarehouseDocumentId: this.invoicesDocumentId,
        SumBy: this.SumBy,
      };
    }

    this.appService
      .putAuth(
        `${this.document}/documents/${this.invoicesDocumentId}/cancelRealization`,
        object
      )
      .subscribe(() => {
        this.isVisible = false;
        this.cd.detectChanges();
      });
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.sumPosition = {
        TotalGrossAmount: 0,
        TotalNetAmount: 0,
      };
      this.selectedPositionDocument = [];
      this.focusedSelectedPosition = [];
    }
  };

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  sendRequestTimer;
  focusedRowIndex;
  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e) {
    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onFocusedRowChanged = (e) => {
    if (e.row && e.row.data) {
      this.focusedSelectedPosition = [e.row.data];
    }
  };

  countSum() {
    this.sumPosition = {
      TotalGrossAmount: 0,
      TotalNetAmount: 0,
    };
    this.selectedPositionDocument.forEach((field) => {
      let selected = this.dataSource._items.find(
        (item) => item.OrderDocumentPositionId == field
      );

      this.sumPosition.TotalGrossAmount +=
        selected.PriceGrossWithDiscount * selected.AmountToRealized;
      this.sumPosition.TotalNetAmount +=
        selected.PriceNetWithDiscount * selected.AmountToRealized;
    });
    this.cd.detectChanges();
  }

  onSelectionChangedGridPosition = (e) => {
    this.countSum();

    this.toSelect.push(...e.currentSelectedRowKeys);
    if (e.currentDeselectedRowKeys.length > 0)
      this.toDeselect.push(e.currentDeselectedRowKeys[0]);

    this.toDeselect = this.event.removeDuplicates(this.toDeselect);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length && this.selectedPositionDocument.length > 0) {
        this.toSelect = [];
        this.event.selectRecords(
          `${this.document}/selection/positionsRealization/select`,
          this.selectedPositionDocument
        );
      }
      if (this.toDeselect.length) {
        this.event.selectRecords(
          `${this.document}/selection/positionsRealization/unselect`,
          this.toDeselect
        );
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  };

  getPosition = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'OrderDocumentPositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}orders/documents/${this.documentDate[0].OrderDocumentId}/positions`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (
            this.document == 'warehouseDocuments' &&
            this.documentType != 'SU' &&
            this.documentType != 'ZU'
          ) {
            this.dataSource.filter(['ProductType', '<>', 'U']);
          }

          if (
            this.document == 'warehouseDocuments' &&
            (this.documentType == 'SU' || this.documentType == 'ZU')
          ) {
            this.dataSource.filter(['ProductType', '=', 'U']);
          }

          this.focusedRowIndex = 0;
          this.focusedSelectedPosition = [data[0]];
          const selected: any[] = data
            .filter((el) => el.IsSelected)
            .map((el) => el.FinanceDocumentId);
          if (selected.length > 0)
            this.event.deselectAllRecords(
              `${this.document}/selection/positionsRealization`
            );

          data.forEach((field) => {
            field.AmountToRealized = field.Amount - field.AmountRealized;
          });
          this.cd.detectChanges();
          setTimeout(() => {
            this.event.setFocus(this.itemGrid);
            if (!this.readOnly) this.itemGrid.instance.selectAll();
            if (this.document == 'warehouseDocuments') {
              data.forEach((field) => {
                if (
                  field.ProductType == 'U' &&
                  this.documentType != 'SU' &&
                  this.documentType != 'ZU'
                ) {
                  this.itemGrid.instance.deselectRows(
                    field.OrderDocumentPositionId
                  );
                }

                if (
                  field.ProductType != 'U' &&
                  (this.documentType == 'SU' || this.documentType == 'ZU')
                ) {
                  this.itemGrid.instance.deselectRows(
                    field.OrderDocumentPositionId
                  );
                }
              });
            }
          }, 0);
        },
        deleteUrl: `${environment.domain}orders/documents/${this.documentDate[0].OrderDocumentId}/positions`,
      }),
      reshapeOnPush: true,
    });
  };

  getLoadParams() {
    let obj: any = {
      ParentObjectId: this.documentDate[0].OrderDocumentId,
      RealizationMode: 6,
      order: this.order,
      orderBy: this.orderby,
    };

    if (this.document === 'invoices') {
      obj.InvoiceDocumentId = this.invoicesDocumentId;
    }

    if (this.document === 'warehouseDocuments') {
      obj.WarehouseDocumentId = this.invoicesDocumentId;
    }

    if (this.isShowNotRealizedForWarehouseDocument) {
      obj.IsShowNotRealizedForWarehouseDocument = true;
    }
    if (this.useForSelection) obj.UseForSelection = true;
    return obj;
  }

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  onFilterPosition(e) {
    if (e.selectedItem) {
      this.orderby = e.selectedItem.value;
      this.valueCriteriaPosition = e.selectedItem.value;
    }
    this.filterValuePosition = e.filterValue;
    this.getPosition();
  }

  setAmountToRealizedForSelected = () => {
    let selected;
    this.selectedPositionDocument.forEach((field) => {
      selected = this.dataSource._items.find(
        (item) => item.OrderDocumentPositionId == field
      );
      selected.AmountToRealized = this.amountToRealized;
    });

    let obj = {
      OrderDocumentId: this.documentDate[0].OrderDocumentId,
      OrderDocumentPositionId: null,
      AmountToRealized: this.amountToRealized,
      IsForSelected: true,
    };
    this.appService
      .putAuth(
        `${this.document}/documents/${this.documentDate[0].OrderDocumentId}/modfiyAmountToRealizedOrderDocument`,
        obj
      )
      .subscribe(
        () => {
          this.amountToRealized = 0;
          this.isAmountToRealized = false;
          this.countSum();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  resetAmountToRealized = () => {
    let selected;
    this.selectedPositionDocument.forEach((field) => {
      selected = this.dataSource._items.find(
        (item) => item.OrderDocumentPositionId == field
      );
      selected.AmountToRealized = 0;
    });

    let obj = {
      OrderDocumentId: this.documentDate[0].OrderDocumentId,
      OrderDocumentPositionId: null,
      AmountToRealized: 0,
      IsForSelected: true,
    };
    this.appService
      .putAuth(
        `${this.document}/documents/${this.documentDate[0].OrderDocumentId}/modfiyAmountToRealizedOrderDocument`,
        obj
      )
      .subscribe(
        () => {
          this.amountToRealized = 0;
          this.isAmountToRealized = false;
          this.countSum();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  cantRealized: boolean = false;
  changeAmountToRealization = () => {
    if (this.focusedSelectedPosition.length > 0) {
      this.focusedSelectedPosition[0].isInputAmount = true;
      this.cantRealized = true;
    }
  };

  continueRealization = () => {
    this.event.onShown();
    let object;

    object = {
      InvoiceDocumentId: this.invoicesDocumentId,
      OrderDocumentId: this.documentDate[0].OrderDocumentId,
      CustomerId: this.documentDate[0].CustomerId,
      DateOfInvoiceDocument: this.DateOfInvoiceDocument,
      DaysForDue: 0,
      SumBy: this.SumBy,
    };

    let url = this.document;
    if (this.document == 'warehouseDocuments') {
      url += '/realization';
      object = {
        WarehouseDocumentId: this.invoicesDocumentId,
        OrderDocumentId: this.documentDate[0].OrderDocumentId,
        CustomerId: this.documentDate[0].CustomerId,
        DateOfInvoiceDocument: this.DateOfInvoiceDocument,
        DaysForDue: 0,
        SumBy: this.SumBy,
      };
    } else url += '/documents';

    this.appService.getAuth(`customers/${object.CustomerId}`).subscribe(
      (res) => {
        object.DaysForDue = res.DaysForDue;
        this.appService
          .postAuth(
            `${url}/${this.invoicesDocumentId}/continueRealization`,
            object
          )
          .subscribe(
            (res) => {
              this.isVisible = false;
              this.onInserted.emit(res);
              this.event.onHidden();
            },
            (error) => {
              this.event.httpErrorNotification(error);
              this.event.onHidden();
            }
          );
      },
      (error) => {
        this.event.httpErrorNotification(error);
        this.event.onHidden();
      }
    );
  };

  check(data) {
    let AmountToRealized = data.AmountToRealized;
    if (
      AmountToRealized > data.AmountReserved &&
      this.documentDate[0].CodeOfOrderDocumentType != 'ZD' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO'
    ) {
      this.event.showNotification(
        'warning',
        this.translate.instant(
          'form-commercial-operation.theQuantityToBeProcessedCannotExceedTheReservedQuantity',
          { number: data.AmountReserved }
        )
      );
      return;
    } else {
      this.focusedSelectedPosition[0].AmountToRealized = AmountToRealized;
      let obj = {
        OrderDocumentId: data.OrderDocumentId,
        OrderDocumentPositionId: data.OrderDocumentPositionId,
        AmountToRealized: data.AmountToRealized,
        IsForSelected: false,
      };
      this.event.onShown();
      this.appService
        .putAuth(
          `${this.document}/documents/${this.documentDate[0].OrderDocumentId}/modfiyAmountToRealizedOrderDocument`,
          obj
        )
        .subscribe(
          () => {
            this.focusedSelectedPosition[0].isInputAmount = false;
            this.cantRealized = false;
            this.countSum();
            this.cd.detectChanges();
            this.event.onHidden();
          },
          (error) => {
            this.event.onHidden();
            this.event.httpErrorNotification(error);
          }
        );
    }
  }

  checkAmountToRealized = (e, data) => {
    setTimeout(() => {
      //let AmountToRealized = data.AmountToRealized;
      if (
        e.event.originalEvent.code == 'Enter' ||
        e.event.originalEvent.code == 'NumpadEnter'
      ) {
        this.check(data);
      }
    }, 0);
  };
}

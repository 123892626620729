<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? 520 : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'form-financial-document.item' | translate }}"
  [(visible)]="isVisible"
  *ngIf="openNestFinished"
  (visibleChange)="visibleChange()"
  [minWidth]="730"
  (onShown)="
    event.onShownPopUp();
    event.setFocusWithSelect(firstInputFocus);
    checkFormatCurrency()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, 'data-cy': 'form-position-order' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        class="btn-dx-top-menu-folder"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        style="margin-right: 6px;"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px;"
        (onClick)="visibleUserExtensions = true"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px;"
        (onClick)="addItem()"
        [id]="'btn-save-item' + unicalGuid"
        data-cy="save-button-position-order"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'btn-cancel-item' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'userGuide.runGuideAgain' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-save-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.save' | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-cancel-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.cancel' | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'menu.navigationPanelUser.help' | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px;">
        <p class="p-header-info">
          {{ 'form-commercial-operation.grid.articleName' | translate }}:
          <strong>{{ articleDetail?.ProductFullName }}</strong>
          | {{ 'customerGroups.catalogIndex' | translate }}:
          <strong>{{ articleDetail?.IndexCatalogue }}</strong>
          @if(event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){|
          {{ 'articles.availableAmount' | translate }}:
          <strong>{{ articleDetail?.StockAvailable }}</strong>
          }
        </p>
      </div>
      <form [formGroup]="form">
        <dx-tab-panel
          class=""
          #tabPanel
          [items]="[
            'companies.tabs.basicInfo' | translate,
            'articles.other' | translate
          ]"
          [loop]="false"
          [animationEnabled]="false"
          [(selectedIndex)]="selectedTabIndex"
          [swipeEnabled]="false"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                <div class="row">
                  <div
                    class="col-md-6"
                    style="
                      border-right: 1px solid var(--neutral-80);
                      padding-right: 30px;
                    "
                  >
                    <label style="width: 120px; display: inline-block;">
                      {{ 'documentsOrders.amountOrdered' | translate }}
                    </label>
                    <dx-number-box
                      width="160"
                      [showSpinButtons]="true"
                      [useLargeSpinButtons]="true"
                      [format]="
                        isUnitDivisible ? formatDivisible : formatNonDivisible
                      "
                      formControlName="Amount"
                      [min]="minAmount || 0"
                      class=""
                      (onValueChanged)="onAmountChanged($event)"
                      valueChangeEvent="input"
                      #firstInputFocus
                      (onFocusIn)="event.onNumberBoxFocusIn($event)"
                      style="display: inline-block; margin-bottom: 6px;"
                      (keyup.enter)="event.setFocus(gridBoxUnit)"
                      data-cy="position-quantity"
                    ></dx-number-box>
                    <br />

                    @if(event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
                    event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO'){
                    <ng-container *ngIf="isIncome || isGuideActive">
                      <label style="width: 120px; display: inline-block;">
                        {{ 'documentsOrders.amountToReserve' | translate }}
                      </label>
                      <dx-number-box
                        #gridBoxUnit
                        width="160"
                        [showSpinButtons]="true"
                        [useLargeSpinButtons]="true"
                        class=""
                        [readOnly]="disableAmountToReservation"
                        [format]="
                          isUnitDivisible ? formatDivisible : formatNonDivisible
                        "
                        [min]="0"
                        [max]="articleDetail?.StockAvailable || null"
                        valueChangeEvent="input"
                        (onFocusIn)="event.onNumberBoxFocusIn($event)"
                        formControlName="AmountToReservation"
                        (onValueChanged)="onAmountToReservationChanged($event)"
                        style="display: inline-block;"
                      ></dx-number-box>
                      <!-- [(value)]="positionAmount"
                                                                  (onValueChanged)="onAmountChanged($event)" -->
                      <br />
                    </ng-container>
                    }
                  </div>
                  <div class="col-md-6">
                    <label style="width: 120px; display: inline-block;">
                      {{ 'articles.sizeUnit' | translate }}
                    </label>
                    <dx-drop-down-box
                      [(value)]="unitId"
                      valueExpr="UnitId"
                      [deferRendering]="false"
                      displayExpr="UnitName"
                      [(opened)]="isGridBoxOpened"
                      [dataSource]="articleDetail?.Units"
                      [tabIndex]="-1"
                      [showClearButton]="false"
                      style="
                        width: 160px;
                        display: inline-block;
                        margin-bottom: 6px;
                      "
                      (keydown.space)="isGridBoxOpened = true"
                    >
                      <div
                        *dxTemplate="let data of 'content'"
                        style="height: 280px;"
                      >
                        <div class="row">
                          <div class="col-md-12" class="no-padding">
                            <dx-data-grid
                              [dataSource]="articleDetail?.Units"
                              [wordWrapEnabled]="true"
                              [showBorders]="true"
                              shortcut
                              [height]="258"
                              class="dropDownBoxClassGrid no-max-width"
                              [columnResizingMode]="'widget'"
                              [remoteOperations]="true"
                              (onRowDblClick)="onRowDblClick($event)"
                              [columnAutoWidth]="true"
                              [allowColumnResizing]="true"
                              (onFocusedRowChanged)="
                                onFocusedRowChanged($event)
                              "
                              [id]="'grid' + unicalGuid"
                              [focusedRowEnabled]="true"
                              #gridClass
                              [hoverStateEnabled]="true"
                            >
                              <dxo-load-panel
                                [enabled]="false"
                              ></dxo-load-panel>
                              <dxo-scrolling mode="infinite"></dxo-scrolling>
                              <dxo-paging [pageSize]="50"></dxo-paging>
                              <dxo-selection
                                mode="single"
                                showCheckBoxesMode="always"
                              ></dxo-selection>
                              <dxi-column
                                caption="{{ 'articles.shortcut' | translate }}"
                                width="60"
                                dataField="Code"
                                [allowSorting]="false"
                                cellTemplate="Code"
                                headerCellTemplate="CodeHeaderTemplate"
                              ></dxi-column>
                              <div
                                *dxTemplate="let data of 'CodeHeaderTemplate'"
                                style="padding-left: 10px;"
                              >
                                {{ 'articles.shortcut' | translate }}
                              </div>
                              <div
                                *dxTemplate="let cell of 'Code'"
                                style="padding-left: 10px;"
                              >
                                {{ cell.value }}
                              </div>
                              <dxi-column
                                caption="{{ 'articles.unit' | translate }}"
                                dataField="UnitName"
                                [allowSorting]="false"
                                width="100"
                              ></dxi-column>
                              <dxi-column
                                caption="{{ 'articles.converter' | translate }}"
                                dataField="Factor"
                                [allowSorting]="false"
                                width="100"
                                alignment="left"
                              ></dxi-column>
                            </dx-data-grid>
                          </div>
                        </div>
                        <div class="bottom-drop-box popup-footer">
                          <dx-button
                            class=""
                            type="danger"
                            [text]="
                              'warehouseDocument.selectUnitAndQuantity'
                                | translate
                            "
                            (onClick)="chooseUnitAndQuantity()"
                          ></dx-button>
                          <dx-button
                            class=""
                            type="success"
                            [text]="'buttons.choose' | translate"
                            (onClick)="chooseUnit(focusedUnit)"
                          ></dx-button>
                        </div>
                      </div>
                    </dx-drop-down-box>
                    <br />
                    <label
                      style="width: 120px; display: inline-block;"
                      [ngClass]="{
                        'mobile-width': event.deviceType == 'mobile'
                      }"
                    >
                      {{ 'documentsOrders.orderedInStorageUnit' | translate }}
                    </label>
                    <dx-number-box
                      width="160"
                      [showSpinButtons]="true"
                      [useLargeSpinButtons]="true"
                      [format]="
                        isUnitDivisible ? formatDivisible : formatNonDivisible
                      "
                      [(value)]="amountPerUnit"
                      (onFocusIn)="event.onNumberBoxFocusIn($event)"
                      (onValueChanged)="onAmountFactorChanged($event)"
                      [min]="0"
                      style="display: inline-block;"
                      valueChangeEvent="input"
                    ></dx-number-box>
                  </div>
                </div>
                <div
                  class="row"
                  style="margin-left: 0px; margin-right: 0px; margin-top: 10px;"
                >
                  <div class="col-md-12">
                    <dx-accordion
                      [dataSource]="['buttons.advanced' | translate]"
                      [collapsible]="true"
                      (onSelectionChanged)="onAccordionSelectionChanged($event)"
                      [selectedItems]="selectedAccordionItems"
                      [multiple]="false"
                      [animationDuration]="300"
                      class="internalAccordion offer-pos-accordion no-padding"
                    >
                      <div *dxTemplate="let company of 'item'">
                        <div class="row">
                          <div class="col-md-6">
                            <label style="width: 120px; display: inline-block;">
                              {{ 'offerPosition.priceBox' | translate }}
                            </label>
                            <app-article-price-position-document
                              [isVisible]="isPriceVisible"
                              [title]="articleDetail?.ProductFullName"
                              [productId]="form.get('ProductId').value"
                              [customerId]="customerId"
                              width="160"
                              [PriceName]="priceName"
                              (onChoosed)="onPriceChoosed($event)"
                              [DocumentCurrencyCode]="currencyCode"
                              [CurrencyFactor]="currencyFactor"
                              [DocumentDate]="documentDate"
                              [readOnly]="!ArtykCenWyborPozDok"
                            ></app-article-price-position-document>
                            <br />
                            <label
                              style="width: 120px; display: inline-block;"
                            ></label>
                            <dx-text-box
                              width="160"
                              [readOnly]="true"
                              style="display: inline-block; margin-bottom: 6px;"
                              [value]="PriceDescription"
                            ></dx-text-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'form-commercial-operation.grid.netAmount'
                                  | translate
                              }}
                              <ng-container *ngIf="unit">
                                [{{ unit.Code }}]
                                <b>
                                  {{ currencyCode ? ' ' + currencyCode : '' }}
                                </b>
                              </ng-container>
                            </label>

                            <dx-number-box
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              [readOnly]="
                                (isIncome && !ArtykCenESDok) ||
                                (!isIncome && !ArtykCenEZDok)
                              "
                              *ngIf="!isCurrencyDocument"
                              formControlName="PriceNet"
                              [min]="0"
                              class=""
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              (onValueChanged)="onValueNetChanged($event)"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              [readOnly]="
                                (isIncome && !ArtykCenESDok) ||
                                (!isIncome && !ArtykCenEZDok)
                              "
                              *ngIf="isCurrencyDocument"
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              class=""
                              formControlName="PriceNetCurrency"
                              (onValueChanged)="
                                onValueNetCurrencyChanged($event)
                              "
                              [format]="format"
                              [min]="0"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{ 'taxRates.rate' | translate }} VAT
                            </label>
                            <app-tax-rates
                              [dropDownBoxMode]="true"
                              [taxCode]="form.get('TaxCode').value"
                              (onChoosed)="onTaxCodeChoosed($event)"
                              [width]="160"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              [ngClass]="{
                                'mobile-width margin-bottom':
                                  event.deviceType == 'mobile'
                              }"
                            ></app-tax-rates>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'form-commercial-operation.grid.grossAmount'
                                  | translate
                              }}
                              <ng-container *ngIf="unit">
                                [{{ unit.Code }}]
                              </ng-container>
                            </label>
                            <dx-number-box
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              formControlName="PriceGross"
                              [readOnly]="
                                (isIncome && !ArtykCenESDok) ||
                                (!isIncome && !ArtykCenEZDok)
                              "
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              (onValueChanged)="onValueGrossChanged($event)"
                              [format]="format"
                              [min]="0"
                            ></dx-number-box>
                            <dx-number-box
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              formControlName="PriceGrossCurrency"
                              [readOnly]="
                                (isIncome && !ArtykCenESDok) ||
                                (!isIncome && !ArtykCenEZDok)
                              "
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              (onValueChanged)="
                                onValueGrossCurrencyChanged($event)
                              "
                              [format]="format"
                              [min]="0"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{ 'individualPrices.markupPM' | translate }}
                            </label>
                            <dx-number-box
                              style="margin-bottom: 6px;"
                              formControlName="Discount"
                              [format]="format"
                              width="119"
                              (onValueChanged)="onDiscountChanged($event)"
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              [useLargeSpinButtons]="true"
                              [showSpinButtons]="true"
                              style="display: inline-block;"
                              [ngClass]="{
                                oneIconBeside: event.deviceType == 'mobile'
                              }"
                            ></dx-number-box>

                            <dx-button
                              class="btn-dx-secondary btn-secondary-only-icon documents-orders-form-position-step-1"
                              style="width: auto; margin-left: 6px;"
                              icon="icon absui-icon--calculator-loan"
                              [id]="'btn-discount' + unicalGuid"
                              (onClick)="setDefaultDiscountMarkup()"
                            ></dx-button>

                            <!-- <dx-button type="danger" text="{{ 'offers.discountMarkupConfig' | translate }}"
                                                          style="margin-bottom: 6px; margin-right: 32px; float: right;" [disabled]="readOnly"
                                                          (onClick)="setDefaultDiscountMarkup()">
                                                        </dx-button> -->

                            <app-discount-markup-factor
                              [isVisible]="isDefaultMarkupVisible"
                              (onClosed)="isDefaultMarkupVisible = false"
                              [valueBefore]="
                                isCurrencyDocument
                                  ? form.controls.PriceNetCurrency.value
                                  : form.controls.PriceNet.value
                              "
                              [discountMarkupPercent]="
                                form.controls.Discount.value
                              "
                              (onChoosed)="onDefaultMarkupChoosed($event)"
                            ></app-discount-markup-factor>
                          </div>
                          <div class="col-md-6">
                            <!-- <label style="width: 100%;">Cena preferowana dla grupy cenowej</label>
                                                                                <br> -->
                            <label style="width: 120px; display: inline-block;">
                              {{ 'offerPosition.netAmountWarUnit' | translate }}
                            </label>
                            <!-- cena netto w j. mag. -->
                            <dx-number-box
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              [(value)]="pricePerUnitNet"
                              [readOnly]="
                                (isIncome && !ArtykCenESDok) ||
                                (!isIncome && !ArtykCenEZDok)
                              "
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              (onValueChanged)="onPricePerUnitChanged($event)"
                              [format]="format"
                              [min]="0"
                            ></dx-number-box>
                            <dx-number-box
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              [(value)]="pricePerUnitNetCurrency"
                              [readOnly]="
                                (isIncome && !ArtykCenESDok) ||
                                (!isIncome && !ArtykCenEZDok)
                              "
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              (onValueChanged)="
                                onPricePerUnitCurrencyChanged($event)
                              "
                              [format]="format"
                              [min]="0"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'offerPosition.grossAmountWarUnit' | translate
                              }}
                            </label>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              [(value)]="pricePerUnit"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              [(value)]="pricePerUnitCurrency"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'offerPosition.netAmountWithDiscount'
                                  | translate
                              }}
                            </label>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              [format]="format"
                              [min]="0"
                              [(value)]="PriceNetWithDiscount"
                            ></dx-number-box>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              [min]="0"
                              [format]="format"
                              [(value)]="PriceNetCurrencyWithDiscount"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'offerPosition.grossAmountWithDiscount'
                                  | translate
                              }}
                            </label>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              [(value)]="PriceGrossWithDiscount"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              [(value)]="PriceGrossCurrencyWithDiscount"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'form-commercial-operation.grid.netValue'
                                  | translate
                              }}
                            </label>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              formControlName="TotalNetAmount"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              formControlName="TotalNetAmountCurrency"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px; display: inline-block;">
                              {{
                                'form-commercial-operation.grid.grossValue'
                                  | translate
                              }}
                            </label>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="!isCurrencyDocument"
                              formControlName="TotalGrossAmount"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              [readOnly]="true"
                              style="
                                width: 160px;
                                display: inline-block;
                                margin-bottom: 6px;
                              "
                              *ngIf="isCurrencyDocument"
                              formControlName="TotalGrossAmountCurrency"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                          </div>
                        </div>
                      </div>
                    </dx-accordion>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'articles.other' | translate">
                <div class="d-flex justify-content-center flex-wrap">
                  <div class="form-group-inline-right" style="width: 100%;">
                    <label style="width: 120px;">
                      {{ 'articles.description' | translate }}
                    </label>
                    <dx-text-area
                      [elementAttr]="{ class: 'no-max-width' }"
                      formControlName="Description"
                      width="calc(100% - 120px)"
                    ></dx-text-area>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </form>

      <app-form-user-extensions
        [isVisible]="visibleUserExtensions"
        groupCode="Pozycja_zamowienia"
        title="{{ 'categories.externalFields' | translate }}"
        (onChoosed)="onInsertedUserExtensions($event)"
        (onClosing)="onClosingUserExtensions()"
        [userExtensionsValue]="form.get('AdditionalFields')?.value"
        #isValidUserExtensions
      ></app-form-user-extensions>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'documents-orders-form-position'"
></app-user-guide>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || isExpansionListVisible"
></wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGZP'"
  [objectParameters]="nestObjectParameters"
  [parentObjectParameters]="parentObjectParameters"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGZP'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-confirm-dialog
  kindDialog="question"
  [confirmText]="textQuestion"
  [isVisible]="true"
  *ngIf="isQuestion"
  (onRemoving)="yesContinue()"
  (onClosing)="noContinue()"
></app-confirm-dialog>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
  ViewChild,
  signal,
  computed,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { forkJoin, Subscription,  tap, of, take, switchMap } from 'rxjs';
import { TableService } from 'src/app/core/table-user-extension/table-service.service';
import { AppServices } from '../../app-services.service';
import { EventService } from '../../event.service';
import { NestOperation } from './../../nest/nest.model';
import { Socket } from './../../socket.model';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import {
  FocusedRowChangedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import {
  AdditionalTable,
  DoubleClickResult,
  RefreshCardResult,
  SingleRecordMode,
} from 'src/app/event.model';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestService } from 'src/app/nest-service.service';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { environment } from '../../../environments/environment';
import { GlobalDate } from '../../core/date-range/GlobalDate';
import { CustomWindowConfig } from '../../event.model';

import { AdditionalOperationComponent } from 'src/app/core/additional-operation/additional-operation.component';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { HelpService } from 'src/app/help-service.service';
import { EDokumentsService } from 'src/app/libraries/e-dokuments/e-dokuments.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { SERVICE_TYPES_KEY, TASK_TYPES_KEY } from 'src/app/consts';
import _ from 'lodash';
import { ServiceOrder } from './service-orders.model';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    inputs: ['dropDownBoxMode', 'selectedId', 'readOnly'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrdersComponent implements OnInit {
  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('complaintItemsGrid') complaintItemsGrid: DxDataGridComponent;
  @ViewChild('gridEstimate') gridEstimate: DxDataGridComponent;
  @ViewChild('gridHistory') gridHistory: DxDataGridComponent;
  @ViewChild('gridAddresses') gridAddresses: DxDataGridComponent;
  @ViewChild('gridPackages') gridPackages: DxDataGridComponent;
  @ViewChild('gridBankDH') gridBankDH: DxDataGridComponent;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @Output() onChoosed = new EventEmitter();
  @ViewChild('gridClass') gridClass;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  readOnly;
  nextId;
  selectedAmount = signal(0);
  helpService = inject(HelpService);
  mobileOperationsVisible = false;
  doubleClickResult: DoubleClickResult = 'Edycja';
  additionalTables: AdditionalTable[] = [];
  bottomPanelTabs = [
    'Kosztorys',
    'Historia zmian statusów',
    'Opis',
    'Wykonane czynności serwisowe',
    'Zalecenia serwisowe',
    'Uwagi',
    'Adresy dostawy',
    'Paczki',
    'Notatki',
    'Tabela dodatkowa',
  ];
  selectedRow = [];
  pomSelectedRow = [];
  selectedId;
  dropDownBoxMode;
  choosingDocument = [];
  focusedSelectedWarehouse;
  perEdocuments: boolean = false;
  addRow = false;
  documentListPom = [];
  openCustomDropDown: boolean = false;
  isGridBoxOpened: boolean = false;
  documentList;
  contextMenuMobile = [
    { name: 'Zaznacz', icon: 'icon checkAll', itemIndex: 99 },
    { name: 'Szczegóły', icon: 'icon absui-icon--file-edit', itemIndex: 98 },
  ];
  dataSource;

  pageSize: number = 100;
  isDeleteRow: boolean = false;

  shortcuts: ShortcutInput[] = [];
  heightGrid: number = 245;

  myEventEdit;
  myEventChoose;
  myEventEsc;
  myEventShow;
  myEventDelete;
  unicalGuid;
  contextMenu = [
    {
      text: 'Dodaj',
      icon: 'icon absui-icon--add-circle',
      itemIndex: 0,
      translation: 'buttons.add',
    },
    {
      text: 'Edytuj',
      icon: 'icon absui-icon--mode-edit',
      itemIndex: 1,
      translation: 'buttons.edit',
    },
    {
      text: 'Usuń',
      icon: 'icon absui-icon--highlight-off',
      itemIndex: 2,
      translation: 'buttons.delete',
    },
    {
      text: 'Powiel',
      icon: 'icon absui-icon--files-copy',
      itemIndex: 4,
      translation: 'inventory.duplicate',
    },
    {
      text: 'Zaznacz wszytko',
      icon: 'icon absui-icon--checkbox-all',
      itemIndex: 3,
      translation: 'repairFunctions.selectAll',
    },
    {
      text: 'Odznacz wszystko',
      icon: 'icon absui-icon--deselct-all',
      itemIndex: 5,
      translation: 'articles.setUnselectedAll',
    },
    {
      text: 'Pokaż zaznaczone',
      icon: '',
      itemIndex: 55,
      items: [],
      translation: 'showSelected',
    },
    {
      text: 'Etykiety',
      items: [
        { text: 'Przypisz', itemIndex: 6, translation: 'labels.assign' },
        { text: 'Skasuj', itemIndex: 7, translation: 'labels.delete' },
      ],
      translation: 'labels.labels',
    },
    {
      text: 'Operacje dodatkowe',
      items: [],
      translation: 'form-commercial-operation.additionalOperations',
    },
    {
      text: 'e-Dokumenty',
      itemIndex: 30,
      translation: 'menu.eDocuments',
    },
    {
      text: 'Wystaw dok. magazynowy',
      itemIndex: 8,
      translation: 'servicesDevices.issueDocWarehouse',
    },
    {
      text: 'Wystaw dok. SU',
      itemIndex: 10,
      translation: 'servicesDevices.issueDocWarehouseSU',
    },
    {
      text: 'Wystaw fakturę',
      itemIndex: 11,
      translation: 'servicesDevices.issueInvoice',
    },
    {
      text: 'Wystaw inny dokument handlowy',
      itemIndex: 12,
      translation: 'servicesDevices.issueOtherInvoice',
    },
    { text: 'Dodaj zadanie CRM', itemIndex: 9, translation: 'tasks.addTask' },
  ];
  contextMenuSignal = signal([
    {
      text: 'Dodaj',
      icon: 'icon absui-icon--add-circle',
      itemIndex: 0,
      translation: 'buttons.add',
    },
    {
      text: 'Edytuj',
      icon: 'icon absui-icon--mode-edit',
      itemIndex: 1,
      translation: 'buttons.edit',
    },
    {
      text: 'Usuń',
      icon: 'icon absui-icon--highlight-off',
      itemIndex: 2,
      translation: 'buttons.delete',
    },
    {
      text: 'Powiel',
      icon: 'icon absui-icon--files-copy',
      itemIndex: 4,
      translation: 'inventory.duplicate',
    },
    {
      text: 'Zaznacz wszytko',
      icon: 'icon absui-icon--checkbox-all',
      itemIndex: 3,
      translation: 'repairFunctions.selectAll',
    },
    {
      text: 'Odznacz wszystko',
      icon: 'icon absui-icon--deselct-all',
      itemIndex: 5,
      translation: 'articles.setUnselectedAll',
    },
    {
      text: 'Pokaż zaznaczone',
      icon: '',
      itemIndex: 55,
      items: [],
      translation: 'showSelected',
    },
    {
      text: 'Etykiety',
      items: [
        { text: 'Przypisz', itemIndex: 6, translation: 'labels.assign' },
        { text: 'Skasuj', itemIndex: 7, translation: 'labels.delete' },
      ],
      translation: 'labels.labels',
    },
    {
      text: 'Operacje dodatkowe',
      items: [],
      translation: 'form-commercial-operation.additionalOperations',
    },
    {
      text: 'e-Dokumenty',
      itemIndex: 30,
      disabled: !this.perEdocuments,
      translation: 'menu.eDocuments',
    },
    {
      text: 'Wystaw dok. magazynowy',
      itemIndex: 8,
      translation: 'servicesDevices.issueDocWarehouse',
    },
    {
      text: 'Wystaw dok. SU',
      itemIndex: 10,
      translation: 'servicesDevices.issueDocWarehouseSU',
    },
    {
      text: 'Wystaw fakturę',
      itemIndex: 11,
      translation: 'servicesDevices.issueInvoice',
    },
    {
      text: 'Wystaw inny dokument handlowy',
      itemIndex: 12,
      translation: 'servicesDevices.issueOtherInvoice',
    },
    { text: 'Dodaj zadanie CRM', itemIndex: 9, translation: 'tasks.addTask' },
  ]);
  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        text: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: this.event.deviceType === 'mobile',
      },
      ...this.contextMenuSignal(),
    ];
  });
  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  isPrintDisabled = signal<boolean>(false);
  widthCityColumn = '250';
  myTimer;
  isResizingRight: boolean = false;

  filterCriteria: ICustomSearchItem[] = [
    {
      value: 'number',
      label: '',
      translationSrc: 'form-financial-document.number',
    },
    {
      value: 'dateStart',
      label: '',
      type: 'data',
      translationSrc: 'servicesDevices.ServiceOrderDate',
    },
    {
      value: 'dateReceived',
      label: '',
      type: 'data',
      translationSrc: 'servicesDevices.DateReceived',
    },
    {
      value: 'dateEnd',
      label: '',
      type: 'data',
      translationSrc: 'servicesDevices.DateEnd',
    },
    {
      value: 'customerName',
      label: '',
      translationSrc: 'form-financial-document.payer-type.contractor',
    },
    { value: 'employeeName', label: '', translationSrc: 'worker' },
    { value: 'EAN', label: '', translationSrc: 'production-documents.barcode' },
    {
      value: 'ServiceDeviceName',
      label: '',
      translationSrc: 'servicesDevices.nameDevice',
    },
    {
      value: 'factoryNumber',
      label: '',
      translationSrc: 'servicesDevices.productionNumber',
    },
    {
      value: 'serialNumber',
      label: '',
      translationSrc: 'servicesDevices.serialNumber',
    },
  ];
  filterValue = '';
  valueCriteria2 = null;
  valueCriteria3 = null;
  valueCriteria = '';
  displayValue = '';
  isShowMoreDetails = false;
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'SERVICE_ORDER';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Serwis_zlecenie';
  viewUserDefinitionSub: Subscription = null;
  dataSourceUser;
  dataSourceGroup;
  statusHistory = [];
  timelineHeight;
  paramsObj = {};
  userId: number = null;
  userExtensionColumns = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };

  widthLeftPanel;
  width = 300;
  valueCriteriaName = '';

  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'number',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 210,
    },
    mainPanelWidth: 'calc(100% - 280px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 280,
    },
    windowSize: {
      isFullscreen: false,
      width: '90%',
      height: '90%',
    },
    params: [
      {
        value: false,
        name: 'showFutureOrders',
      },
    ],
  };
  columnsChooserVisible: boolean = false;
  selectedtabIndex = 0;
  descriptionOrder: string = '';
  repairDescription: string = '';
  serviceOrderMessage: string = '';
  remarks: string = '';
  contextMenuOperationsMobile: any = [];
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  permissionToChooseColums: Boolean = false;
  jobCosts = [];
  SerwisZlecCeny: boolean = false;
  //
  nestsSub: Subscription;
  activatedRouteSub: Subscription;
  globalNestSub: Subscription;
  componentNests: Socket;
  closeNest: Socket;
  focusOnLastRow: RefreshCardResult = 'Nie';
  ServiceOrderId = null;
  pinnedViewsSub: Subscription = null;
  closeSub = null;
  readOnlySub = null;
  packages = [];
  deliveryAddresses = [];
  isSendSms = false;
  eDocumentsVisible: boolean = false;
  newRouteVisible: boolean = false;
  addingModeRoute: string = '';
  contextOfAddingRoute = '';
  isMobileReportsPopupVisible: boolean;
  onlyShow: boolean = false;
  isAddInvoices = signal<boolean>(false);

  translate = inject(TranslateService);
  showFutureOrdersList = [
    { value: true, label: this.translate.instant('yes') },
    { value: false, label: this.translate.instant('no') },
  ];

  showFutureOrders = signal<boolean>(false);

  constructor(
    private appService: AppServices,

    public edocs: EDokumentsService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private tableService: TableService,
    private activatedRoute: ActivatedRoute,
    public nestService: NestService,
    private router: Router,
    private dynamicFilterService: DynamicFilterService
  ) {
    this.perEdocuments = !this.event.isDisabledEDocuments(
      'ServiceOrderRelated'
    );
    this.event.isInventory.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getData();
    });
    this.readOnlySub = this.event.sendReadOnlyInfo.subscribe((res) => {
      this.readOnly = res;
      this.getData();
      this.cd.detectChanges();
    });
    this.closeSub = this.event.closeDrobBox.subscribe(() => {
      this.closeDropBox();
    });
    this.userId = event.returnUserId();
    this.translate
      .get('mark')
      .subscribe((text) => (this.contextMenuMobile[0].name = text));
    this.translate
      .get('tasks.details')
      .subscribe((text) => (this.contextMenuMobile[1].name = text));

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.timelineHeight = window.innerHeight - 350;

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests || {};

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
    this.getTypes();
    //this.getData();
    this.deselectAllRecords();
    this.getUserParameters();
    this.SerwisZlecCeny = this.event.checkPermissions('SerwisZlecCeny');
    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 210;
    }
    if(this.event.isAdditionalTableAvailable()){
      this.additionalTables = this.event.getTablesByGroupCode('Serwis_zlecenie');
    }
    this.myEventEdit = this.event.edit.subscribe(() => {
      if (
        !this.readOnly &&
        (this.isGridBoxOpened || !this.dropDownBoxMode) &&
        this.perABD.editBtn &&
        !this.isSingleRecordVisible
      ) {
        if (this.pomSelectedRow.length > 0) {
          this.setEmployeeLock();
        }
      }
    });

    this.myEventShow = this.event.show.subscribe(() => {
      if (!this.readOnly && this.pomSelectedRow.length && this.perABD.showBtn) {
        this.setSingleRecordVisible('show');
      }
    });

    this.myEventDelete = this.event.delete.subscribe(() => {
      this.isDelete();
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        this.closeDropBox();
      }
    });

    this.myEventChoose = this.event.choose.subscribe(() => {
      if (!this.readOnly && (this.isGridBoxOpened || !this.dropDownBoxMode)) {
        if (this.gridClass.instance.getselectedRowData().length > 0) {
          this.onChoosingRow();
        }
      }
    });

    this.contextMenu.forEach((field: any) => {
      this.contextMenuMobile.push(field);
    });

    if (!this.dropDownBoxMode) {
      this.widthCityColumn = null;
    }

    this.getTranslation(this.contextMenu, 'text');
    this.event.translateSearchboxLabels(this.filterCriteria);

    this.translate.get('form-financial-document.number').subscribe((text) => {
      this.displayValue = text;
      this.valueCriteria = 'number';
    });

    this.translate
      .get('servicesDevices.estimate')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));

    this.translate
      .get('servicesDevices.historyStatusChanges')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));

    this.translate
      .get('form-financial-document.description')
      .subscribe((text) => (this.bottomPanelTabs[2] = text));

    this.translate
      .get('servicesDevices.serviceActivitiesPerformed')
      .subscribe((text) => (this.bottomPanelTabs[3] = text));

    this.translate
      .get('servicesDevices.serviceRecommendations')
      .subscribe((text) => (this.bottomPanelTabs[4] = text));

    this.translate
      .get('comments')
      .subscribe((text) => (this.bottomPanelTabs[5] = text));

    this.translate
      .get('deliveries.deliveryAddresses')
      .subscribe((text) => (this.bottomPanelTabs[6] = text));

    this.translate
      .get('form-financial-document.tabs.package')
      .subscribe((text) => (this.bottomPanelTabs[7] = text));

    this.translate
      .get('notes.notes')
      .subscribe((text) => (this.bottomPanelTabs[8] = text));

    if (this.additionalTables.length) {
      this.translate.get('articles.externalTable').subscribe((text) => {
        this.bottomPanelTabs[9] = text;
      });
    }

    this.getGroup();

    setTimeout(() => {
      this.closeNest = this.nestService.findNest(
        this.componentNests?.Sockets,
        'XGSLA'
      );
      this.findSocketAndRun('XGSLO1');
    });

    this.perABD = this.event.checkPermissionsBtn(
      'SerwisZlecD',
      'SerwisZlecE',
      'SerwisZlecU',
      'SerwisZlecO'
    );
    this.permissionToChooseColums =
      this.event.checkPermissions('SerwisKonfKol');

    this.cd.detectChanges();

    this.activatedRoute.queryParams.subscribe((params) => {
      this.ServiceOrderId = params.id;
      if (params.id) {
        this.getData();
      }
    });

    this.pinnedViewsSub = this.dynamicFilterService
      .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          if (this.localStorageData?.advancedFilter) {
            this.localStorageData.advancedFilter.PinnedFilters =
              data.pinnedFilters;
          } else {
            this.localStorageData.advancedFilter = {
              PinnedFilters: data.pinnedFilters,
            } as any;
          }
        },
      });

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.dropDownBoxMode) {
          return;
        }
        if (this.isSingleRecordVisible) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });
  }

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') {
      e.items = [];
    }
  }

  getTranslation(items, field) {
    for (let item of items) {
      if (item.translation) {
        this.translate
          .get(item.translation)
          .subscribe((text) => (item[field] = text));
      }
      if (item.items) {
        this.getTranslation(item.items, field);
      }
    }
  }

  additionalOperationShortcutSub: Subscription = null;
  componentVisible: boolean = false;
  nest: Socket;
  isNestRunning: boolean = false;
  nestOperation: NestOperation;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      this.onNestFinished({ SocketCode });
      return true; // nieznalazlo
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGSLYD1':
        this.findSocketAndRun('XGSLYA1', 'add');
        break;
      case 'XGSLYP1':
        this.findSocketAndRun('XGSLYA1', 'edit');
        break;
      case 'XGSLYU1':
        this.findSocketAndRun('XGSLYA1', 'delete');
        break;
      case 'XGSLYA1':
        if (this.nestOperation === 'add') {
          this.addNewRecordBetween();
        } else if (this.nestOperation === 'edit') {
          this.setEmployeeLockBetween();
          this.event.loadingVisible = true;
        } else if (this.nestOperation === 'delete') {
          this.deleteBetween();
        }
        break;
      case 'XGSLYD2':
        this.findSocketAndRun('XGSLYA2');
        this.cd.detectChanges();
        break;
      case 'XGSLYP2':
        this.findSocketAndRun('XGSLYA2');
        this.cd.detectChanges();
        break;
      case 'XGSLYU2':
        this.findSocketAndRun('XGSLYA2');
        break;
      case 'XGSLO1':
        this.componentVisible = true;
        if (this.onInitErr) {
          //this.ngAfterViewInit();
        }
        this.cd.detectChanges();
        this.findSocketAndRun('XGSLO2');
        break;
      case 'XGSLO2':
        this.searchControl?.focusInput();
        break;
      // operacje dod
      case 'XGSLEXSERWIS_ZLECENIE_BRWPOLADODGRUP_E1':
        this.visibleUserExtensions = true;
        break;
      case 'XGSLEXSERWIS_ZLECENIE_BRWPOLADOD_E1':
        this.visibleUserExtensions = true;
        break;
      case 'XGSLEXSERWIS_ZLECENIE_BRWZMSTATZLEC_E1':
        this.isGroupStatusChangeVisible = true;
        break;
      case 'XGSLEXSERWIS_ZLECENIE_BRWGANPAC_E1':
        this.generatePackage();
        break;
      case 'XGSLEXSERWIS_ZLECENIE_BRWSMSSERWIS_E1':
        this.isSendSms = true;
        break;
        case `XGSLEX${this.selectedOwnOperation?.GroupCode}_${this.selectedOwnOperation?.Name?.toUpperCase()}_E1`:
          this.runInnerOwnOperation();
          break;
      default:
        break;
    }
  }

  additionalFieldIdRecord: number = null;
  isLabel: boolean = false;
  modeSelectionMobile: string = 'none';
  isContextMenuMobile: boolean = false;
  isDetails: boolean = false;
  isTask: boolean = false;
  TypeIdTask = null;

  isAllSelected: boolean = false;
  selectAll = () => {
    this.isAllSelected = true;
    this.appService
      .postAuth(`services/selection/orders`, this.paramsObj)
      .subscribe(({ RowNumber }) => {
        this.selectedAmount.set(RowNumber);
      });
    this.modeSelectionMobile = 'multiple';
  };

  refreshCardResult: RefreshCardResult = 'Tak';

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.getData();
    }
  }

  selectedOwnOperation: any = null;
  runOwnAdditionalOperation(operation: any){
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(`XGSLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`);
  }

  runInnerOwnOperation(){
    const data = this.event.prepareAdditionalOperationData(this.selectedOwnOperation,this.currentDate, this.selectedRow.length == 0 ? this.focusedSelected[0].ServiceOrderId : null)
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if(this.selectedOwnOperation?.RefreshList){
          this.getData();
        }
        this.findSocketAndRun(`XGSLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`);
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      }
    })
  }

  contextMenuClick = (e) => {
    if (e.itemData.IsOuter) {
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }

    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.perABD.addBtn) {
          this.addNewRecord();
        }
        break;
      case 1:
        if (!this.readOnly && this.perABD.editBtn) {
          this.setSingleRecordVisible('edit');
        }
        break;
      case 2:
        if (!this.readOnly && this.perABD.deleteBtn) {
          this.isDelete();
        }
        break;
      case 3:
        this.skipSendSelectionEvent = true;
        this.gridClass.instance.selectAll();
        this.selectAll();
        break;
      case 4:
        if (!this.readOnly && this.perABD.addBtn) {
          this.showCopyDialog(true);
        }
        break;
      // if (!this.readOnly) {
      //   this.gridClass.instance.deselectRows(this.pomSelectedRow[0]);
      // }
      // break;
      case 5:
        if (!this.readOnly) {
          this.skipSendSelectionEvent = true;
          this.selectedRow = [];
          this.deselectAllRecords();
        }
        break;
      case 55:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case '1111_000_GANPAC':
        this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWGANPAC_E1');
        break;
      case 6:
        if (!this.readOnly) {
          if (this.selectedRow.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isLabel = true;
          }
        }
        break;

      case '1111_000_ZYSKZLEC':
        if (!this.readOnly) {
          if (this.selectedRow.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.onProfits();
          }
        }
        break;
      case 7:
        if (!this.readOnly) {
          if (this.selectedRow.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.event.deleteLabel('services/selection/orders/label');
            setTimeout(() => {
              this.selectedRow = [];
              this.dataSource.reload();
            }, 100);
          }
        }
        break;
      case 8:
        this.prepareWarehouseDocument(
          this.focusedSelected[0].ServiceOrderId,
          this.focusedSelected[0].EmployeeId
        );
        break;
      case '0011_000_POLADODGRUP':
        this.onlyShow = false;
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };
        if (!this.readOnly) {
          if (this.selectedRow.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.additionalFieldsList = null;
            this.additionalFieldIdRecord = null;
            this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWPOLADODGRUP_E1');
          }
        }
        break;
      case 10:
        if (this.focusedSelected.length > 0) {
          this.appService
            .postAuth(
              `services/additionalOperations/${this.focusedSelected[0].ServiceOrderId}/warehouseDocument?isService=true`,
              null
            )
            .subscribe(
              (res) => {
                if (res.Id != null && res.Id != undefined && res.Id != 0) {
                  this.appService
                    .getAuth(`warehouseDocuments/documents?ObjectId=${res.Id}`)
                    .subscribe((res) => {
                      this.focusedSelectedWarehouse = [res.data[0]];
                      this.addRow = true;
                      this.cd.detectChanges();
                    });
                } else {
                  this.event.showNotification(
                    'error',
                    this.translate.instant(
                      'form-commercial-operation.theDocumentCouldNotBeIssued'
                    )
                  );
                }
              },
              (error) => {
                this.event.httpErrorNotification(error);
              }
            );
        }

        break;
      case 11:
        if (this.focusedSelected.length > 0) {
          this.createChosedInvoices();
        }

        break;

      case 12:
        if (this.focusedSelected.length > 0) {
          this.getInvoicesDocumentTypes();
        }
        break;
      case 30:
        this.eDocumentsVisible = true;
        this.cd.detectChanges();
        break;
      case '1111_000_ZMSTATZLEC':
        if (!this.readOnly) {
          if (this.selectedRow.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWZMSTATZLEC_E1');
          }
        }
        break;
      case '0011_000_POLADOD':
        this.onlyShow = true;
        if (!this.readOnly && this.pomSelectedRow.length) {
          this.additionalFieldsList = this.focusedSelected[0];
          this.additionalFieldIdRecord = this.pomSelectedRow[0];
          this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWPOLADOD_E1');
        }
        break;
      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridClass.instance.getDataSource().items()[
          this.focusedRowIndex
        ];
        const id = row.ServiceOrderId;
        if (!this.selectedRow.includes(id)) {
          this.gridClass.instance.selectRows(id, true);
        } else {
          this.gridClass.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.isDetails = true;
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;
      case 9:
        this.getTaskType();
        break;
      case '1111_000_SMSSERWIS':
        if (!this.readOnly) {
          if (this.selectedRow.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWSMSSERWIS_E1');
          }
        }
        break;
      case '1111_000_ROUTEADD': {
        if (
          !this.readOnly &&
          (this.selectedRow.length != 0 || this.focusedSelected.length != 0)
        ) {
          this.contextOfAddingRoute = 'ZLECENIE_SERWIS';
          this.newRouteVisible = true;
          if (this.selectedRow.length != 0) {
            this.addingModeRoute = 'multiple';
          } else if (
            this.focusedSelected.length != 0 &&
            this.selectedRow.length == 0
          ) {
            this.addingModeRoute = 'single';
          }
        }
        break;
      }
    }
  };

  getTaskType() {
    const localData = this.event.getSessionStorageData(TASK_TYPES_KEY);
    if (localData) {
      this.findTask(localData);
    } else {
      this.appService.getAuth(`crm/taskTypes`).subscribe((res) => {
        this.findTask(res.data);
        this.event.saveSessionStorageData(TASK_TYPES_KEY, res.data);
      });
    }
  }

  findTask(taskTypes: any[]) {
    this.TypeIdTask = taskTypes.find(
      (field) => field.Name == 'Zlecenie serwisowe'
    ).TypeId;
    this.isTask = true;
    this.cd.detectChanges();
  }

  isProfit: boolean = false;

  onProfits() {
    this.isProfit = true;
    this.cd.detectChanges();
  }

  invoiceDicument: any[] = []
  createChosedInvoices(Signature?) {
    this.createInvoicesDocumentPopupVisible = false;

    let object = {
      ServiceOrderId: this.focusedSelected[0].ServiceOrderId,
      Signature: '',
    };
    if (Signature) {
      object.Signature =
        this.invoicesDocumentsTypes[this.focusedDocRowIndexIncoices].Signature;
    } else {
      object.Signature = 'FV';
    }

    this.appService
      .postAuth(`services/additionalOperations/invoiceDocument`, object)
      .subscribe(
        (res) => {
          if (
            res.length > 0 &&
            res[0].Id != null &&
            res[0].Id != undefined &&
            res[0].Id != 0
          ) {
            this.invoiceDicument = [{InvoiceDocumentId:res[0].Id}];
            this.isAddInvoices.set(true);
            // this.router.navigate([
            //   `/trade-operations/trader-documents/${res[0].Id}/edit`,
            // ]);
          } else {
            this.event.showNotification(
              'error',
              this.translate.instant(
                'form-commercial-operation.theDocumentCouldNotBeIssued'
              )
            );
          }
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
    this.cd.detectChanges();
  }

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'OrdersComponent',
          JSON.stringify(this.localStorageData)
        );
        this.getData();
      }
    })
  }

  onDocRowDblClick() {
    this.createChosedInvoices('Signature');
  }

  onKeyUpDh(e) {
    if (e.event.keyCode == 18 || e.event.keyCode == 13) {
      this.createChosedInvoices('Signature');
    }
  }

  invoicesDocumentsTypes: any = [];
  createInvoicesDocumentPopupVisible: boolean = false;
  focusedDocRowIndexIncoices = 0;

  getInvoiceTypes() {
    let data: any = localStorage.getItem('typesInvoice');
    if (data) {
      return of({ data: this.event.decryptString(data) });
    }
    return this.appService.getAuth(`document/sales/types/invoice`).pipe(
      tap((res) => {
        localStorage.setItem(
          'typesInvoice',
          this.event.encryptString(res.data)
        );
      })
    );
  }

  getInvoicesDocumentTypes() {
    this.invoicesDocumentsTypes = [];
    this.getInvoiceTypes().subscribe({
      next: (res) => {
        this.invoicesDocumentsTypes = res.data.filter(
          (el) => el.DocumentType === 's'
        );

        for (let i = this.invoicesDocumentsTypes.length - 1; i >= 0; i--) {
          if (
            this.invoicesDocumentsTypes[i].Signature.trim() == 'RZk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'PRk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'PMk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'N' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'FZk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'FRk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'FMk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'FVk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'DWk' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'DW' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'DRS' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'FN' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'FM' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'PM' ||
            this.invoicesDocumentsTypes[i].Signature.trim() == 'GV'
          ) {
            this.invoicesDocumentsTypes.splice(i, 1);
          }
        }
        this.createInvoicesDocumentPopupVisible = true;
        this.cd.detectChanges();
      },
      error: () => {},
    });
  }

  onTaskClosing = () => {
    this.isTask = false;
    this.cd.detectChanges();
  };

  prepareWarehouseDocument = (ServiceOrderId, EmployeeId) => {
    let object = {
      ServiceOrderId: ServiceOrderId,
      EmployeeId: EmployeeId ? EmployeeId : 0,
    };

    this.appService
      .postAuth(
        `services/orders/${ServiceOrderId}/prepareWarehouseDocument`,
        object
      )
      .subscribe(
        (res) => {
          this.appService
            .getAuth(`warehouseDocuments/documents?ObjectId=${res.Id}`)
            .subscribe((res) => {
              this.focusedSelectedWarehouse = [res.data[0]];
              this.addRow = true;
              this.cd.detectChanges();
            });
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  onChoosedLabel = () => {
    this.selectedRow = [];
    this.isLabel = false;
    this.dataSource.reload();
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onGridContentReady(e) {
    this.onContentReady(e);

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;

    this.localStorageData.sidePanel.isVisible = false;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
  };

  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.sidePanel.width = `280px`;
    this.localStorageData.mainPanelWidth = 'calc(100% - 280px)';
    this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
  };

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }
  }

  onValueChangeSearch = (e) => {
    if (e.value == '') {
      this.getData();
    }
    //this.localStorageData.filterValue = e.value;
  };

  ngOnChanges() {
    if (this.selectedId && this.selectedId != '') {
      this.appService
        .getAuth(`services/orders?orderId=${this.selectedId}`)
        .subscribe((res) => {
          this.documentList = res.data;
          this.choosingDocument = [Number(this.selectedId)];
          this.cd.detectChanges();
        });
    } else {
      this.documentList = [];
      this.choosingDocument = [];
    }
  }

  setEmployeeLock = () => {
    this.findSocketAndRun('XGSLYP1');
  };

  setEmployeeLockBetween = () => {
    this.appService
      .putAuth(
        `services/orders/${this.pomSelectedRow[0]}/prepareForModify`,
        null
      )
      .subscribe({
        next: () => {
          this.event.loadingVisible = false;
          this.setSingleRecordVisible('edit');
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        },
      });
  };

  onRowClick = (e) => {
    try {
      this.pomSelectedRow = [e.data.ServiceOrderId];
    } catch {}
  };

  skipSendSelectionEvent = false;
  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;
  getData = (fromSearch: boolean = false) => {
    return new Promise<void>((resolve) => {
      this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'ServiceOrderId',
          onBeforeSend: this.event.onBeforeSendDataSource,
          loadUrl: `${environment.domain}services/orders`,
          loadParams: this.getLoadParams(),
          onAjaxError: this.event.onAjaxDataSourceError,
          onLoading(loadOptions: LoadOptions) {
            loadOptions.requireTotalCount = true;
          },
          onLoaded: (data) => {
            const instance = this.gridClass.instance;
            this.localStorageData.columns.forEach((col, i) => {
              instance.columnOption(col.caption, 'visible', col.visible);
              instance.columnOption(col.caption, 'visibleIndex', i);
              instance.columnOption(col.caption, 'fixed', col.fixed);
              if (col.width > 0) {
                instance.columnOption(col.caption, 'width', col.width);
              }
            });
            this.cd.detectChanges();

            if (data.length == 0) {
              this.pomSelectedRow = [];
              this.focusedSelected = [];
              return;
            }
            const toAdd = data.filter(
              (el) =>
                el.IsSelected &&
                this.selectedRow.indexOf(el.ProductionDocumentId) === -1
            );
            for (let item of toAdd) {
              this.selectedRow.push(item.ProductionDocumentId);
              this.skipSendSelectionEvent = true;
            }

            const toDelete = [];
            for (let id of this.selectedRow) {
              const item = data.find((el) => el.ProductionDocumentId === id);
              if (item && !item.IsSelected) {
                toDelete.push(id);
              }
            }
            for (let id of toDelete) {
              this.selectedRow.splice(
                this.selectedRow.findIndex((el) => el === id),
                1
              );
              this.skipSendSelectionEvent = true;
            }

            if (this.firstLoad) {
              this.firstLoad = false;
              setTimeout(() => {
                const filters: any = this.getLoadParams();
                const totalCount: number = this.dataSource.totalCount();
                if (!totalCount) {
                  return;
                }

                if (
                  (filters?.order === 'ASC' && this.focusOnLastRow === 'Tak') ||
                  (filters?.order === 'DESC' && this.focusOnLastRow === 'Nie')
                ) {
                  this.indexToFocus = totalCount - 1;
                  if (this.indexToFocus < 0) {
                    this.indexToFocus = data.length - 1;
                  }
                  const page = Math.floor(totalCount / this.pageSize);
                  if (page > 0) {
                    this.setFocusOnLoad = true;
                    this.dataSource.pageIndex(page);
                    this.dataSource.load();
                  } else {
                    this.focusedRowIndex = this.indexToFocus;
                    this.onFocusedRowChanged({
                      row: { data: data[this.focusedRowIndex] },
                    });
                    this.cd.detectChanges();
                  }
                } else {
                  this.focusedRowIndex = 0;
                  this.onFocusedRowChanged({
                    row: { data: data[this.focusedRowIndex] },
                  });
                  this.cd.detectChanges();
                }
                this.searchControl?.focusInput();
              }, 200);
            }

            if (this.setFocusOnLoad) {
              this.setFocusAfterLoad(data);
            }
            if (fromSearch) {
              this.searchControl?.focusInput();
            }
            resolve();
          },
          deleteUrl: `${environment.domain}services/orders`,
        }),
        reshapeOnPush: true,
        requireTotalCount: true,
        paginate: true,
      });
    });
  };

  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({ row: { data: data[this.focusedRowIndex] } });
  }

  onItemClick(e) {
    this.valueCriteria = e.itemData.value;
    this.displayValue = e.itemData.label;
  }

  resetFiltr = (filterType: string) => {
    this.valueCriteriaName = '';
    this.valueCriteria2 = null;
    this.localStorageData.tree = [];
    this.treeViewAll.instance.unselectAll();
    if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    }
    this.restoreTreeConfig();
    this.getData();
  };

  resetFiltrForClearValue = (filterType: string) => {
    this.valueCriteriaName = '';
    this.valueCriteria2 = null;
    this.localStorageData.tree = [];
    this.treeViewAll.instance.unselectAll();
    if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    }
    this.restoreTreeConfig();
  };

  onItemAllClick = (e) => {
    this.valueCriteria2 = e.itemData.StatusCode;
    this.valueCriteriaName = e.itemData.nameStatus;

    if (e.itemData.ServiceOrderStatusTreeId === 1) {
      this.valueCriteria2 = 'lessThan7Days';
    }

    if (e.itemData.ServiceOrderStatusTreeId === 2) {
      this.valueCriteria2 = 'MoreThan7Days';
    }

    if (e.itemData.ServiceOrderStatusTreeId === 3) {
      this.valueCriteria2 = 'CompletedTrue';
    }

    if (e.itemData.ServiceOrderStatusTreeId === 4) {
      this.valueCriteria2 = 'CompletedFalse';
    }

    let tree = this.localStorageData.tree.findIndex(
      (field) => field.key == 'Status'
    );

    if (tree == -1) {
      this.localStorageData.tree.push({
        key: 'Status',
        value: {
          statusId: e.itemData.StatusCode,
          TypeName: this.valueCriteriaName,
        },
      });
    } else {
      this.localStorageData.tree[tree].value = {
        statusId: e.itemData.StatusCode,
        TypeName: this.valueCriteriaName,
      };
    }

    this.getData();
  };

  onItemAllClickGroup = (e) => {
    this.valueCriteria3 = e.itemData.ServiceGroupId;
    this.getData();
  };

  types: any = [];

  getTypesDef() {
    const localData = this.event.getSessionStorageData(SERVICE_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`services/orders/types`).pipe(
        tap((data) => {
          this.event.saveSessionStorageData(SERVICE_TYPES_KEY, data);
        })
      );
    }
  }

  refreshView() {
    sessionStorage.removeItem(SERVICE_TYPES_KEY);
    this.getTypes();
    this.getData();
  }

  getTypes() {
    this.getTypesDef().subscribe((data) => {
      this.types = data;
    });
  }

  getLoadParams() {
    let obj: any = {};

    (obj.orderBy = this.getOrderBy()), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.order = this.localStorageData.filter.order);
    if (
      this.valueCriteria2 == 'N' ||
      this.valueCriteria2 == 'R' ||
      this.valueCriteria2 == 'Z' ||
      this.valueCriteria2 == 'O' ||
      this.valueCriteria2 == 'Y' ||
      this.valueCriteria2 == 'D' ||
      this.valueCriteria2 == 'P' ||
      this.valueCriteria2 == 'U' ||
      this.valueCriteria2 == 'A' ||
      this.valueCriteria2 == 'X'
    ) {
      obj.serviceOrderStatus = this.valueCriteria2;
    }

    if (this.valueCriteria2 == true || this.valueCriteria2 == false) {
      obj.serviceOrderStatus = 'N';
      obj.isOlderThan7Days = this.valueCriteria2;
    }

    if (
      this.valueCriteria2 == 'CompletedTrue' ||
      this.valueCriteria2 == 'CompletedFalse'
    ) {
      obj.serviceOrderStatus = 'R';
      obj.isCompleted = this.valueCriteria2 == 'CompletedTrue' ? true : false;
    }

    if (
      this.valueCriteria2 == 'lessThan7Days' ||
      this.valueCriteria2 == 'MoreThan7Days'
    ) {
      let ServiceOrderType = this.types.data.find(
        (field) => field.Name == 'Reklamacje' || field.Name == 'Reklamacja'
      );
      obj.serviceOrderStatus = 'N';
      obj.ServiceOrderType = ServiceOrderType?.ServiceOrderTypeId;
      obj.IsOlderThan7Days =
        this.valueCriteria2 == 'lessThan7Days' ? false : true;
    }

    if (this.valueCriteria3 >= 1) {
      obj.serviceGroupId = this.valueCriteria3;
    }

    if (this.ServiceOrderId) {
      obj.ObjectId = this.ServiceOrderId;
    }

    obj.dateStart =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.dateEnd =
      this.showFutureOrders()
        ? null
        : this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    switch (this.localStorageData.filter.orderBy) {
      case 'number':
        obj.number = this.localStorageData.filter.value;
        break;

      case 'dateReceived':
        obj.dateReceived = this.event.setCorrectDate(
          this.localStorageData.filter.value
        );
        break;

      case 'dateStart':
        obj.dateStart = this.event.setCorrectDate(
          this.localStorageData.filter.value
        );
        break;

      case 'dateEnd':
        obj.dateEnd = this.showFutureOrders() ? null : this.event.setCorrectDate(
          this.localStorageData.filter.value
        );
        break;

      case 'customerName':
        obj.customerName = this.localStorageData.filter.value;
        break;

      case 'ServiceDeviceName':
        obj.ServiceDeviceName = this.localStorageData.filter.value;
        break;

      case 'employeeName':
        obj.employeeName = this.localStorageData.filter.value;
        break;

      case 'EAN':
        obj.EAN = this.localStorageData.filter.value;
        break;

      case 'device':
        obj.EAN = this.localStorageData.filter.value;
        break;

      case 'factoryNumber':
        obj.factoryNumber = this.localStorageData.filter.value;
        break;

      case 'serialNumber':
        obj.serialNumber = this.localStorageData.filter.value;
        break;

      default:
        break;
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    this.paramsObj = obj;
    return obj;
  }

  deleteFilters = false;
  deleteFilter = false;
  advancedFilterTemplateData = null;

  clearValues(onlySelected: boolean = false) {
    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = true;
    this.deleteFilters = !this.deleteFilters;
    this.cd.detectChanges();
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.resetFiltr(null);
    this.deleteFilter = false;
  }

  ngOnDestroy() {
    this.additionalOperationShortcutSub?.unsubscribe();
    this.localStorageData.columns = this.event.setWidthColumnGrid(
      this.gridClass
    );
    this.pinnedViewsSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();
    this.tableService.removeTableContext('Serwis_zlecenie');

    this.myEventEdit.unsubscribe();
    this.myEventDelete.unsubscribe();
    this.myEventEsc.unsubscribe();
    this.myEventChoose.unsubscribe();
    this.myEventShow.unsubscribe();
    if (this.event.deviceType != 'mobile') {
      if (
        localStorage.getItem('OrdersComponent') &&
        JSON.stringify(this.localStorageData) !=
          localStorage.getItem('OrdersComponent')
      ) {
        let object = {
          View: 'OrdersComponent',
          Configuration: JSON.stringify(this.localStorageData),
          TenantId: this.event.footerInfo.TenantId,
        };
        localStorage.setItem(
          'OrdersComponent',
          JSON.stringify(this.localStorageData)
        );
        this.appService
          .postAuth(`viewConfigurations`, object)
          .subscribe(() => {});
      }
    }

    this.activatedRouteSub.unsubscribe();
    this.globalNestSub.unsubscribe();
    this.nestsSub?.unsubscribe();
    this.closeSub?.unsubscribe();
    this.readOnlySub?.unsubscribe();
  }

  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  ngAfterViewInit() {
    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.event
      .getUserExtension('Serwis_zlecenie', this.userExtensionColumns)
      .then((res) => {
        try {
          this.localStorageData.columns = this.event.getAdditionalColumnsCaptions(
            res,
            this.localStorageData.columns
          );
        } catch {}
      });
    this.getViewConfigurations();

    if (!this.additionalTables.length) {
      this.bottomPanelTabs.pop();
    }

    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getData();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/modul-serwisu');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.event.isDisabledEDocuments('ServiceOrderRelated')) {
            this.edocs.getToEDocuments(
              'ServiceOrderRelated',
              this.focusedSelected[0]?.ServiceOrderId,
              this.focusedSelected[0]?.Number
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex != 2) {
            this.localStorageData.sidePanel.selectedIndex = 2;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 2 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && this.perABD.addBtn && e.event.shiftKey) {
            this.showCopyDialog(true);
            return;
          }

          if (!this.readOnly && this.perABD.addBtn) {
            document.getElementById(`btn-add${this.unicalGuid}`).click();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeDropBox();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.pomSelectedRow.length == 1) {
            this.isExternalTableVisible = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 0;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 1;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 2;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 3;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 4;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 5;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 6;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 7;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.additionalTables.length) {
            this.selectedtabIndex = 8;
            if (!this.localStorageData.bottomPanel.isVisible) {
              this.ngModelChange();
            }
          }
        },
        preventDefault: true,
      }
    );

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.pomSelectedRow.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
            }
          },
          preventDefault: true,
        });
      }
    }
    if (
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
      this.perEdocuments
    ) {
      this.bottomPanelTabs.push(this.translate.instant('menu.eDocuments'));
    }

    this.appService.getAuth(`services/orders/statusTree`).subscribe((res) => {
      // this.dataSourceUser = [
      //   {
      //     expanded: true,
      //     StatusCode: '-1',
      //     nameStatus: this.translate.instant('constractors.filter.everything'),
      //     items: res.data,
      //   },
      // ];

      // this.dataSourceUser[0].items.forEach((el) => {
      //   el.nameStatus = el.Description;
      //   el.StatusCode = el.Code;

      //   if (el.StatusCode === 'N') {
      //     el.expanded = true;
      //     el.items = [
      //       {
      //         StatusCode: 'lessThan7Days',
      //         nameStatus: this.translate.instant(
      //           'servicesDevices.complaintsLessThan7days'
      //         ),
      //       },
      //       {
      //         StatusCode: 'MoreThan7Days',
      //         nameStatus: this.translate.instant(
      //           'servicesDevices.complaintsOver7days'
      //         ),
      //       },
      //     ];
      //   }

      //   if (el.StatusCode === 'R') {
      //     el.expanded = true;
      //     el.items = [
      //       {
      //         StatusCode: 'CompletedTrue',
      //         nameStatus: this.translate.instant('tasks.realisation') + '100%',
      //       },
      //       {
      //         StatusCode: 'CompletedFalse',
      //         nameStatus:
      //           this.translate.instant('tasks.realisation') + '< 100%',
      //       },
      //     ];
      //   }
      // });
      this.setTypeTree(res);
    });
  }

  grouped;
  setTypeTree = (res) => {
    let typeTree = res;

    this.grouped = _.groupBy(
      typeTree,
      (product) => product.ServiceOrderStatusParentTreeId
    );

    this.dataSourceUser = [
      {
        expanded: true,
        ServiceOrderStatusTreeId: -1,
        ServiceOrderStatusParentTreeId: null,
        InvoiceTypeTreeParentId: null,
        Name: 'Wszystko',
        items: this.childrenOf(null, true),
      },
    ];

    // this.setItems(this.dataSourceUser.items, 'ServiceOrderStatusTreeId');
  };

  childrenOf(ParentPermissionId, expanded) {
    return (this.grouped[ParentPermissionId] || []).map((product) => ({
      expanded: expanded,
      ServiceOrderStatusTreeId: product.ServiceOrderStatusTreeId,
      ServiceOrderStatusParentTreeId: product.ServiceOrderStatusParentTreeId,
      Name: product.StatusName,
      StatusCode: product.StatusCode,
      StatusBackgroundColor: product.StatusBackgroundColor,
      StatusStringColor: product.StatusStringColor,
      nameStatus: product.StatusName,
      items: this.childrenOf(product.ServiceOrderStatusTreeId, true),
    }));
  }

  onClosingForm = () => {
    this.addRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 300);
    this.cd.detectChanges();
  };

  async onInserted(e) {
    await this.dataSource.reload();

    this.focusedRowIndex = this.event.returnFocusIndex(
      this.dataSource._items,
      'ServiceOrderId',
      e.ServiceOrderId
    );
    this.focusedSelected = [this.dataSource._items[this.focusedRowIndex]];
    this.event.setFocus(this.gridClass);
  }

  focusedSelected = [];
  subject: string = '';
  menuName: string = null;
  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e?.row?.data];
    this.pomSelectedRow = [e?.row?.data?.ServiceOrderId];
    this.isDetailOnFocus = false;

    if (this.focusedSelected.length > 0) {
      this.menuName = this.focusedSelected[0].MenuName;
      this.subject = 'Zlecenie serwisowe nr ' + this.focusedSelected[0].Number;
      if (
        this.focusedSelected[0].ServiceOrderName == 'Reklamacje' ||
        this.focusedSelected[0].ServiceOrderName == 'Reklamacja'
      ) {
        this.bottomPanelTabs[0] = this.translate.instant(
          'servicesDevices.complaintItems'
        );
      } else {
        this.bottomPanelTabs[0] = this.translate.instant(
          'servicesDevices.estimate'
        );
      }
    }

    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }

    this.myTimer = setTimeout(() => {
      if (this.localStorageData.bottomPanel.isVisible) {
        this.tableService.addTableContext({
          id: e.row.data.ServiceOrderId,
          idParentObject: e.row.data,
          tableName: 'Serwis_zlecenie',
        });
        this.getDetailsOrder(e.row.data.ServiceOrderId);
      }

      if (this.localStorageData.sidePanel.selectedIndex == 1) {
        this.getHistoryStatus(e.row.data.ServiceOrderId);
      }
    }, 300);
  };

  onChoosingRow = () => {
    if (!this.readOnly) {
      this.documentList = this.documentListPom;
      this.choosingDocument = this.selectedRow[0];
      this.onChoosed.emit(this.documentList[0]);
      this.componentVisible = false;
      this.isGridBoxOpened = false;
    }
  };

  onOpenedChanged = (e) => {
    setTimeout(() => {
      this.event.disabletShortcuts.next(e);
    }, 0);

    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  sendRequestTimer;
  //  useForSelection: boolean = false;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];

  onSelectionChanged = (e: SelectionChangedEvent) => {
    //this.documentListPom = [];
    //this.documentListPom.push(e.selectedRowData[0]);

    // if (
    //   !this.useForSelection &&
    //   e.component.totalCount() == e.selectedRowKeys.length
    // ) {
    //   // jak nie bylo wszystko zaznaczone i jest
    //   this.useForSelection = true;
    //   this.getData();
    //   return;
    // } else if (
    //   this.useForSelection &&
    //   e.component.totalCount() > e.selectedRowKeys.length
    // ) {
    //   // jak bylo i nie jest
    //   this.useForSelection = false;
    //   this.getData();
    //   this.toSelect.push(...e.selectedRowKeys);

    //   if (this.sendRequestTimer) {
    //     clearTimeout(this.sendRequestTimer);
    //     this.sendRequestTimer = null;
    //   }

    //   this.setSelectionTimeout();
    //   return;
    // }

    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRow.length === 0 ||
      (this.selectedRow.length &&
        this.selectedRow.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      let selectData = [];
      let unselectData = [];

      if (this.toSelect.length) {
        for (let item of this.toSelect) {
          if (item?.ServiceOrderId) {
            selectData.push(item.ServiceOrderId);
          } else {
            selectData.push(item);
          }
        }
        selectData = this.event.removeDuplicates(selectData);
      }

      if (this.toDeselect.length) {
        this.toDeselect.forEach((field) => {
          if (typeof field == 'object') {
            unselectData.push(field.ServiceOrderId);
          } else {
            unselectData.push(field);
          }
        });
        unselectData = this.event.removeDuplicates(unselectData);
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of unselectData) {
          const item = items.find((el) => el.ServiceOrderId === toDes);
          if (!item) {
            toRemove.push(unselectData.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          unselectData.splice(toRemove[i], 1);
        }
      }

      //find elements in both selectdata and selectedRows
      selectData = selectData.filter((value) =>
        this.selectedRow.includes(value)
      );
      //remove elements from unselectdata that are not in selectedrows
      unselectData = unselectData.filter(
        (value) => !this.selectedRow.includes(value)
      );

      this.sendRequestTimer = null;
      this.selectedAmount.set(this.selectedAmount() - unselectData.length);
      if (this.selectedAmount() < 0) {
        this.selectedAmount.set(0);
      }
      
      if (selectData.length) {
        this.event.selectRecords(`services/selection/orders/select`, selectData).then(() => {
          if (unselectData.length) {
            this.event.selectRecords(`services/selection/orders/unselect`, unselectData).then(() => {
              this.toSelect = [];
              this.toDeselect = [];
            });
          } else {
            this.toSelect = [];
            this.toDeselect = [];
          }
        });
      } else if (unselectData.length) {
        this.event.selectRecords(`services/selection/orders/unselect`, unselectData).then(() => {
          this.toSelect = [];
          this.toDeselect = [];
        });
      } else {
        this.toSelect = [];
        this.toDeselect = [];
      }
    }, 500);
  }

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  focusedRowIndex;
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.localStorageData.filter.orderBy
    );

    this.localStorageData.filter.value = this.event.setFilterValueBySearchKey(
      this.localStorageData.filter.value,
      key,
      orderByItem.type === 'data'
    );

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.localStorageData.filter.value) !==
          this.paramsObj[this.localStorageData.filter.orderBy]
        ) {
          this.getData();
        }
        return;
      }
      this.getData();
    }, 300);
  }

  onlySelected = false;

  onKeyDown(e) {
    if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    } else if (e.event.key === 'E' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (!this.event.isDisabledEDocuments('ServiceOrderRelated')) {
        this.edocs.getToEDocuments(
          'ServiceOrderRelated',
          this.focusedSelected[0]?.ServiceOrderId,
          this.focusedSelected[0]?.Number
        );
      }
    } else if (e.event.key === 'O' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.gridClass.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.selectedRow = [];
      this.deselectAllRecords();
    } else if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  isDelete = () => {
    if (
      this.pomSelectedRow.length > 0 &&
      !this.isSingleRecordVisible &&
      !this.readOnly &&
      (this.isGridBoxOpened || !this.dropDownBoxMode) &&
      this.perABD.deleteBtn
    ) {
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.instance.focus();
      }
    }, 500);
  }

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode && this.perABD.editBtn) {
      if (this.doubleClickResult === 'Edycja') {
        this.setEmployeeLock();
      } else {
        this.setSingleRecordVisible('show');
      }
    } else {
      this.onChoosingRow();
    }
  };

  delete = () => {
    this.findSocketAndRun('XGSLYU1');
  };

  deleteBetween() {
    if (this.selectedRow.length > 1) {
      let forkArray = [];

      this.selectedRow.forEach((field) => {
        forkArray.push(this.appService.deleteAuth(`services/orders/${field}`));
      });
      this.isDeleteRow = false;
      forkJoin(forkArray).subscribe(
        () => {
          this.getData();
        },
        () => {},
        () => {
          this.findSocketAndRun('XGSLYU2');
        }
      );
      return;
    }

    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(this.pomSelectedRow[0])
    );
    if (this.choosingDocument == this.pomSelectedRow[0]) {
      this.choosingDocument = [];
    }
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridClass) {
        this.gridClass.focusedRowIndex = 0;
        this.gridClass.instance.focus();
      }
    }, 500);
    this.findSocketAndRun('XGSLYU2');
  }

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  onInitErr: boolean = false;
  @ViewChild('treeViewAll') treeViewAll;

  setUserWindowConf = () => {
    this.localStorageData = JSON.parse(localStorage.getItem('OrdersComponent'));
    
    const showFutureOrders = this.localStorageData.params.find(
      (el) => el.name === 'showFutureOrders'
    );
    if (showFutureOrders) {
      this.showFutureOrders.set(showFutureOrders.value);
    }
    try {
      this.width = Number(
        this.localStorageData.sidePanel.width.replace('px', '')
      );
    } catch {}
    if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
      this.getDefaultFilter();
    }
    else if (
      this.localStorageData?.advancedFilter
        ?.FilterUsersDefinitionId ||
      this.localStorageData?.advancedFilter?.IsPinned
    ) {
      this.getSavedFilterDefinitions();
    }
    if (this.localStorageData?.advancedFilter?.PinnedFilters) {
      this.dynamicFilterService.addPinnedViewsFilters(
        this.DynamicFilterDictionaryCode,
        this.localStorageData.advancedFilter.PinnedFilters
      );
    }

    if (this.localStorageData.tree.length > 0) {
      this.onItemAllClick({
        itemData: {
          StatusCode: this.localStorageData.tree[0].value.statusId,
          nameStatus: this.localStorageData.tree[0].value.TypeName,
        },
      });

      setTimeout(() => {
        this.treeViewAll.instance.selectItem(
          this.localStorageData.tree[0].value.statusId
        );
      }, 0);
    }
    setTimeout(() => {
      if (this.localStorageData.bottomPanel.isVisible) {
        setTimeout(() => {
          this.ngModelChange('first');
        }, 0);
      }

      if (
        this.additionalTables.length &&
        this.localStorageData.additionalTable
      ) {
        this.bottomPanelTabs[9] = this.localStorageData.additionalTable.text;
      }

      this.selectedtabIndex = this.localStorageData.bottomPanel.selectedIndex;

      if (!this.localStorageData.sidePanel.isVisible) {
        this.width = 21;
      }
      this.cd.detectChanges();
    }, 0);
  };

  getViewConfigurations = () => {
    if (!localStorage.getItem('OrdersComponent') && !this.dropDownBoxMode) {
      this.appService
        .getAuth(`viewConfigurations/OrdersComponent`)
        .subscribe((res) => {
          if (res.Configuration) {
            this.localStorageData = JSON.parse(res.Configuration);
          }
          localStorage.setItem(
            'OrdersComponent',
            JSON.stringify(this.localStorageData)
          );

          this.setUserWindowConf();
          this.getData();
        });
      return;
    }

    if (!this.dropDownBoxMode) {
      this.localStorageData = JSON.parse(
        localStorage.getItem('OrdersComponent')
      );

      this.setUserWindowConf();
      this.getData();
    }
  };

  getGroup = () => {
    this.appService
      .getAuth(`services/groups?skip=0&take=200`)
      .subscribe((response) => {
        this.dataSourceGroup = [
          {
            expanded: true,
            items: response.data,
            ServiceGroupId: '-1',
            Name: this.translate.instant('constractors.filter.everything'),
          },
        ];
        this.cd.detectChanges();
      });
  };

  getSortIcon() {
    return this.localStorageData.filter.order === 'ASC'
      ? 'icon absui-icon--sort-ascending'
      : 'icon absui-icon--sort-descending';
  }

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      this.localStorageData.filter.order = this.localStorageData.filter.order;
      return;
    }
    this.localStorageData.filter.order = 'ASC';
    this.localStorageData.filter.order = this.localStorageData.filter.order;
  }

  getOrderBy() {
    if (this.localStorageData.filter.orderBy === 'ProductNameIntuition') {
      return 'ProductName1';
    }
    return this.localStorageData.filter.orderBy.indexOf('Intuition') === -1
      ? this.localStorageData.filter.orderBy
      : this.localStorageData.filter.orderBy.substring(
          0,
          this.localStorageData.filter.orderBy.indexOf('Intuition')
        );
  }

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.localStorageData.filter.value = e.filterValue;
      this.getData(true);
    }
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;

    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;

    localStorage.setItem(
      'OrdersComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
    this.cd.detectChanges();
  }

  mapColumnKey(col: any) {
    return col.dataField;
  }

  mapColumn(col: any) {
    return {
      name: col.dataField,
      caption: col.caption,
      visibleIndex: col.visibleIndex,
      checked: col.visible,
      width: col.width,
    };
  }

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {}
    }

    setTimeout(() => {
      this.localStorageData.columns.forEach((field, index) => {
        field.visibleIndex = index;
      });
      this.cd.detectChanges();
    }, 0);
  };

  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  heightBottomPanel;
  showBottomPanel: boolean = false;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  heightBottomGrid = 180;

  resizingBottom = (e) => {
    let height;
    this.showBottomPanel = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '230px';
    if (height < 235) {
      height = 235;
    }

    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 25;

    this.heightGrid = window.innerHeight - height - 200;
    this.localStorageData.bottomPanel.height = `${height}px`;
  };

  timeoutHandler;

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange = (noChangeState?) => {
    try {
      if (noChangeState != 'first' && this.event.deviceType != 'mobile') {
        this.localStorageData.bottomPanel.isVisible =
          !this.localStorageData.bottomPanel.isVisible;
      }
      if (!this.dropDownBoxMode) {
        if (
          this.localStorageData.bottomPanel.isVisible &&
          this.localStorageData.bottomPanel.height == 210
        ) {
          this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
          this.heightGrid = window.innerHeight - 435;
          this.tabPanel.height = '100%';
          this.heightBottomGrid = 210;
        } else if (
          this.localStorageData.bottomPanel.isVisible &&
          this.localStorageData.bottomPanel.height != 210
        ) {
          this.bottomSidePanel.nativeElement.style.minHeight =
            this.localStorageData.bottomPanel.height;
          this.heightGrid =
            window.innerHeight -
            Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
            200;
          this.tabPanel.height = '100%';
          this.heightBottomGrid =
            Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
            25;
        } else {
          this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
          this.tabPanel.height = '34px';
          this.bottomSidePanel.nativeElement.style.height = `34px`;
          this.heightGrid = window.innerHeight - 210;
        }
        // if (this.focusedSelected.length > 0)
        //   this.getDetails(this.focusedSelected[0].InvoiceDocumentId);
      }
    } catch {}

    if (!this.showBottomPanel) {
      this.isDetailOnFocus = false;
    }
  };

  onSelectionTabChanged = () => {
    this.localStorageData.bottomPanel.selectedIndex = this.selectedtabIndex;
    this.getDetailsOrder(this.pomSelectedRow[0]);
  };

  addNewRecord = () => {
    this.findSocketAndRun('XGSLYD1');
  };

  addNewRecordBetween() {
    this.nextId = 0;
    this.setSingleRecordVisible('add');
    // this.appService
    //   .postAuth(`services/orders/header`, { Value: '' })
    //   .subscribe((res) => {
    //     this.nextId = res.Id;

    //     this.cd.detectChanges();
    //   });
  }

  getHistoryStatus = (id) => {
    this.appService
      .getAuth(`services/orders/${id}/statusHistory?skip=0&take=100`)
      .subscribe((response) => {
        this.statusHistory = response.data;
        this.cd.detectChanges();
      });
  };

  onSelectionChangedTabRight = (e) => {
    if (e.addedItems[0] == 2) {
      if (this.pomSelectedRow.length > 0) {
        this.getHistoryStatus(this.pomSelectedRow[0]);
      }
    }
  };

  complaintItems = [];

  @ViewChild('eDocumentss') eDocumentss;
  getDetailsOrder = (ServiceOrderId) => {
    try {
      if(this.bottomPanelTabs[this.selectedtabIndex] === this.translate.instant('menu.eDocuments')){
        this.eDocumentss.getDocuments();
        this.cd.detectChanges();
      }

      switch (this.tabPanel.selectedIndex) {
        case 0:
          if (ServiceOrderId) {
            if (
              this.focusedSelected[0].ServiceOrderName == 'Reklamacje' ||
              this.focusedSelected[0].ServiceOrderName == 'Reklamacja'
            ) {
              this.appService
                .getAuth(`services/orders/${ServiceOrderId}/complaints`)
                .subscribe((response) => {
                  this.complaintItems = response.data;
                  this.cd.detectChanges();
                });
            } else {
              this.appService
                .getAuth(
                  `services/orders/${ServiceOrderId}/jobCosts?skip=0&take=100`
                )
                .subscribe((response) => {
                  this.jobCosts = response.data;
                  this.cd.detectChanges();
                });
            }
          }
          break;
        case 1:
          if (ServiceOrderId) {
            this.getHistoryStatus(ServiceOrderId);
          }
          break;
        case 2:
          if (ServiceOrderId) {
            this.descriptionOrder = this.dataSource._items.find(
              (field) => field.ServiceOrderId == ServiceOrderId
            ).FaultDescription;
            this.cd.detectChanges();
          }
          break;
        case 3:
          if (ServiceOrderId) {
            this.repairDescription = this.dataSource._items.find(
              (field) => field.ServiceOrderId == ServiceOrderId
            ).RepairDescription;
            this.cd.detectChanges();
          }
          break;
        case 4:
          if (ServiceOrderId) {
            this.serviceOrderMessage = this.dataSource._items.find(
              (field) => field.ServiceOrderId == ServiceOrderId
            ).ServiceOrderMessage;
            this.cd.detectChanges();
          }
          break;
        case 5:
          if (ServiceOrderId) {
            this.remarks = this.dataSource._items.find(
              (field) => field.ServiceOrderId == ServiceOrderId
            ).Remarks;
            this.cd.detectChanges();
          }
          break;
        case 6:
          if (ServiceOrderId) {
            this.getAddresses(ServiceOrderId);
          }
          break;
        case 7:
          if (ServiceOrderId) {
            this.getPackages(ServiceOrderId);
          }
          break;
      }
    } catch {}
  };

  getPackages = (id) => {
    this.appService
      .getAuth(
        `deliveries/operators/-1/packages?ObjectId=${id}&DictionaryCode=DOCUMENT_ORDER`
      )
      .subscribe(
        (response) => {
          this.packages = response.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  getAddresses = (ServiceOrderId) => {
    this.appService
      .getAuth(`services/orders/${ServiceOrderId}/deliveryAddresses`)
      .subscribe(
        (response) => {
          this.deliveryAddresses = response.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  editRecord() {
    //e.stopPropagation();
    if (
      !this.readOnly &&
      !this.isSingleRecordVisible &&
      this.pomSelectedRow.length > 0 &&
      this.perABD.editBtn &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.setEmployeeLock();
    }
  }

  onItemClickTab = () => {
    this.showBottomPanel = true;
    this.localStorageData.bottomPanel.isVisible = true;
    if (this.localStorageData.bottomPanel.isVisible == null) {
      this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
      this.heightGrid = window.innerHeight - 435;
      this.tabPanel.height = '100%';
    } else if (this.localStorageData.bottomPanel.isVisible != null) {
      this.bottomSidePanel.nativeElement.style.minHeight =
        this.localStorageData.bottomPanel.height;
      try {
        this.heightGrid =
          window.innerHeight -
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          200;
      } catch {}
      this.tabPanel.height = '100%';
    }
  };

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  onConfirmSingle(socketCode) {
    this.findSocketAndRun(socketCode);
  }

  isExternalTableVisible: boolean = false;

  onAdditionalTableChanged(e) {
    this.localStorageData.additionalTable = Object.assign({}, e);
    this.bottomPanelTabs[9] = e.text;

    localStorage.setItem(
      'OrdersComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  shortcutTable;
  isShortcutTableOpened: boolean = false;

  isSingleRecordVisible: boolean = false;
  singleRecordMode: SingleRecordMode = null;

  onSingleRecordClosing() {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    this.cd.detectChanges();
    this.onClosingForm();
  }

  onSingleRecordInserted(e) {
    this.getDetailsOrder(e.ServiceOrderId);
    this.focusedSelected = [e];
    this.onInserted(e);
    if (this.singleRecordMode === 'add') {
      this.findSocketAndRun('XGSLYD2');
    } else if (this.singleRecordMode === 'edit') {
      this.findSocketAndRun('XGSLYP2');
    }
    this.singleRecordMode = null;
  }

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  isGroupStatusChangeVisible = false;
  visibleUserExtensions = false;
  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  onGroupStatusChangeClosed() {
    this.isGroupStatusChangeVisible = false;
    this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWZMSTATZLEC_E2');
  }

  onGroupStatusChangeSaved() {
    this.isGroupStatusChangeVisible = false;
    this.selectedRow = [];
    this.deselectAllRecords();
    this.dataSource.reload();
    this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWZMSTATZLEC_E2');
  }

  onClosingUserExtensions() {
    this.visibleUserExtensions = false;
    if (this.additionalFieldIdRecord) {
      this.additionalFieldIdRecord = null;
      this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWPOLADOD_E2');
    } else {
      this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWPOLADODGRUP_E2');
    }
  }

  setAdditionalFields(e) {
    if (this.additionalFieldIdRecord) {
      const uri = `?objectId=${this.additionalFieldIdRecord}`;
      this.appService
        .putAuth(
          `services/additionalOperations/${this.additionalFieldIdRecord}/setAdditionalFields${uri}`,
          e
        )
        .subscribe({
          next: () => {
            // this.visibleUserExtensions = false;
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };

            this.selectedRow = [];
            this.dataSource.reload();
            this.cd.detectChanges();
            this.additionalFieldIdRecord = null;
            this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWPOLADOD_E2');
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
    } else {
      this.appService
        .putAuth(`services/additionalOperations/setAdditionalFields`, e)
        .subscribe(
          () => {
            // this.visibleUserExtensions = false;
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };

            this.selectedRow = [];
            this.dataSource.reload();
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWPOLADODGRUP_E2');
          }
        );
    }
  }

  generatePackage() {
    if (!this.pomSelectedRow.length) {
      return;
    }

    this.appService
      .postAuth(
        `services/additionalOperations/addPackage/${this.pomSelectedRow[0]}`,
        null
      )
      .subscribe({
        next: () => {
          this.event.showNotification(
            'success',
            this.translate.instant('ordersGroupChangeStatus.packageGenerated')
          );
          this.selectedRow = [];
          this.dataSource.reload();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
        complete: () => {
          this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWGANPAC_E2');
        },
      });
  }

  filterMobileVisible = false;
  isDetailOnFocus = false;

  onFocusElementChanged(e: boolean) {
    this.isDetailOnFocus = e;
  }

  onCellClick(e) {
    if (this.event.deviceType != 'mobile') {
      if (!e?.row?.cells) {
        return;
      }

      for (let cell of e?.row?.cells) {
        this.event.removeFocusClassFromCell(cell.cellElement);
      }
      e.cellElement.className += ' dx-focused';
    }
  }

  currentFocusedCell = null;

  onFocusedCellChanged(e) {
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedCell = e.cellElement[0];
  }

  onFocusedRowChangedDetail(e: FocusedRowChangedEvent) {
    this.onFocusElementChanged(e.rowIndex > -1);
  }

  currentFocusedDetailCell = null;

  onFocusedCellDetailChanged(e) {
    if (this.currentFocusedDetailCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedDetailCell);
    }
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedDetailCell = e.cellElement[0];
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'OrdersComponent',
      this.event.encryptString(this.localStorageData)
    );

    this.getData();
  }

  restoreTreeConfig = () => {
    setTimeout(() => {
      if (this.valueCriteria2 == null || this.valueCriteria2 == undefined) {
        this.valueCriteria2 = -1;
      }
      this.treeViewAll.instance.selectItem(this.valueCriteria2);
    }, 100);
  };

  lastTreeSelection;

  onTreeSelectionChanged = (e) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection = selectedNodes;
    }
    e.component.selectItem(this.lastTreeSelection);
  };

  openRightPanel = () => {
    if (this.width <= 21) {
      this.resizeWindowBtn();
    }
  };

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getData();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 2,
      isPanelVisible: this.localStorageData?.sidePanel?.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getData();
    this.cd.detectChanges();
  };

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGS');
    }
    this.gridClass.instance.focus();
  }

  isCopyRecord: boolean = false;

  showCopyDialog(state: boolean = true) {
    this.isCopyRecord = state;
    this.cd.detectChanges();
  }

  copyRecord() {
    const orderId = this.selectedRow.length
      ? 0
      : this.focusedSelected[0].ServiceOrderId;
    this.appService
      .postAuth(`services/orders/${orderId}/copy`, null)
      .subscribe({
        next: (res) => {
          this.getData().then(() => {
            if (res.Id != 0) {
              const rowIndex = this.gridClass.instance.getRowIndexByKey(res.Id);
              const rowElement =
                this.gridClass.instance.getRowElement(rowIndex);
              this.gridClass.instance.focus(rowElement);
              setTimeout(() => {
                this.setEmployeeLock(); // open edit popup
              }, 0);
            }
          });
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          this.showCopyDialog(false);
        },
        complete: () => {
          this.showCopyDialog(false);
        },
      });
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('EAN');
    this.localStorageData.filter.value = e.toString();
    this.getData();
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getData();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.deselectAllRecords();
    this.getData();
  }

  deselectAllRecords() {
    this.isAllSelected = false;
    this.selectedAmount.set(0);
    return this.event.deselectAllRecords('services/selection/orders');
  }

  onSmsClosing() {
    this.isSendSms = false;
    this.findSocketAndRun('XGSLEXSERWIS_ZLECENIE_BRWSMSSERWIS_E2');
  }

  showContextMenuMobile = (rowData: ServiceOrder) => {
    if (this.selectedRow.includes(rowData.ServiceOrderId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  };

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridClass.instance.getDataSource().items()[
        this.focusedRowIndex
      ];
      const id = row.ServiceOrderId;
      if (!this.selectedRow.includes(id)) {
        this.gridClass.instance.selectRows(id, true);
      } else {
        this.gridClass.instance.deselectRows(id);
      }
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onClosingNewRoute() {
    this.newRouteVisible = false;
    this.addingModeRoute = '';
    this.contextOfAddingRoute = '';
    this.cd.detectChanges();
  }

  onReportsPopupClosed() {
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }

  onShowFutureOrdersChanged(e) {
    this.showFutureOrders.set(e);
    const index = this.localStorageData.params.findIndex(
      (el) => el.name === 'showFutureOrders'
    );
    if (index > -1) {
      this.localStorageData.params[index].value = e;
    } else {
      this.localStorageData.params.push({ value: e, name: 'showFutureOrders' });
    }

    localStorage.setItem(
      'OrdersComponent',
      JSON.stringify(this.localStorageData)
    );
    this.getData();
  }
}

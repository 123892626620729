import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  DxButtonModule,
  DxDropDownButtonComponent,
  DxDropDownButtonModule,
  DxListModule,
  DxTooltipModule
} from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import {
  ICustomSearchItem,
  ICustomSearchItemType
} from '../custom-dropdown-box/custom-dropdown-box.model';
import { Subject, takeUntil, debounceTime } from 'rxjs';
import { EventService } from '../../event.service';

@Component({
  selector: 'app-custom-filter-dropdown-box',
  imports: [
    CommonModule,
    DxDropDownButtonModule,
    DxListModule,
    DxTooltipModule,
    TranslateModule,
    DxButtonModule
  ],
  templateUrl: './custom-filter-dropdown-box.component.html',
  styleUrl: './custom-filter-dropdown-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CustomFilterDropdownBoxComponent {
  @Input() openDropDown: boolean = false;
  @Input() items: ICustomSearchItem[] = [];
  @Input() dropdownHeight: number = 146;
  @Input() selectedItem: string = '';
  @Input() sortDirection: string = 'ASC';
  @Input() autoEmit: boolean = false;
  @Output() onValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() onArrowDown: EventEmitter<void> = new EventEmitter();
  @ViewChild('filterList', { static: false }) filterList!: DxDropDownButtonComponent;

  @Input() set filterValue(val: string | number) {
    const selectedItem = this.findSelectedItemObject(this.selectedItem);
    this.selectedItemObject = selectedItem;
    if (selectedItem && selectedItem.type == 'Date' && typeof val === 'string') {
      try {
        this._filterValue = val.replace(/-/g, '');
        this.selectedDataType = selectedItem?.type;
      } catch {}
    } else {
      this._filterValue = val;
    }
  }

  @HostListener('document:keydown', ['$event'])
  hostKeyDown(event: any) {
    if (event.key == 'F11') {
      event.preventDefault();
      let ean = this.items?.find((item) => item.value == 'EAN' || item.value == 'Barcode');
      if (ean) {
        this.selectedItem = ean.value;
      }
      this.filterValue = '';
      this.cd.detectChanges();
    }
  }

  public event = inject(EventService);
  public cd = inject(ChangeDetectorRef);

  public selectedItemObject: ICustomSearchItem | any;
  protected _filterValue: string | number | any = '';
  selectedDataType: ICustomSearchItemType | any = null;
  autoEmitSubject: Subject<string> = new Subject();
  destroy$: Subject<void> = new Subject();
  autoEmit$ = this.autoEmitSubject.asObservable().pipe(takeUntil(this.destroy$), debounceTime(300));
  filterDropdownOptions: any;
  tooltipDelay = 300;
  addFocusClass = false;

  mask: string = '';
  maskRules: any = null;

  ngOnInit(): void {
    this.autoEmit$.subscribe({
      next: () => {
        this.emitChanges(true);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges | any) {
    if (changes['selectedItem']?.currentValue) {
      const selectedItem = this.findSelectedItemObject(this.selectedItem);
      this.selectedDataType = selectedItem?.type;
    }
  }

  changeFilterCriteria(e: any) {
    this.clearMasks();
    this.filterValue = '';
    this.openDropDown = false;
    let item;
    if (this.event.deviceType === 'desktop') {
      item = this.findSelectedItemObject(e.itemData.value);
      this.selectedItem = e.itemData.value;
    } else {
      item = this.findSelectedItemObject(e.itemData.value);
      this.selectedItem = e.itemData.value;
    }
    this.selectedItemObject = item;
    if (item?.type == 'Date') {
      this._filterValue = '';
    }
    this.emitChanges();
  }

  findSelectedItemObject(item: string): ICustomSearchItem | null {
    return this.items.find((el) => el.value === item) || null;
  }

  changeSortDirection() {
    if (this.sortDirection === 'ASC') {
      this.sortDirection = 'DESC';
    } else {
      this.sortDirection = 'ASC';
    }
    this.emitChanges();
  }

  emitChanges(autoEmit: boolean = false, sortDirection = this.sortDirection) {
    const selectedItem = this.findSelectedItemObject(this.selectedItem)!;
    this.selectedDataType = selectedItem?.type;
    this.onValueChanged.emit({
      autoEmit,
      selectedItem,
      sortDirection,
      filterValue:
        selectedItem?.type == 'Date' ? this.formatDate(this._filterValue) : this._filterValue
    });
  }

  formatDate(value: string): string {
    if (!value || value.length < 8) {
      return '';
    }
    return value.substring(0, 4) + '-' + value.substring(4, 6) + '-' + value.substring(6, 8);
  }

  addClassForViewDropdown(): void {
    this.filterDropdownOptions = {
      onShown: function (args: any) {
        args.component
          .content()
          .parentElement.parentElement.classList.add('list-view-dropdown-menu');
      },
      height: this.dropdownHeight
    };
    setTimeout(() => {
      this.filterList.instance.focus();
      this.filterList.instance.option('focusedElement', 0);
    }, 100);
  }

  clearMasks() {
    this.mask = '';
    this.maskRules = null;
  }

  keyup = (e: any) => {
    if (e.event.key == 'Enter') {
      this.emitChanges();
      this.addFocusClass = !this.addFocusClass;
      this.cd.detectChanges();
    } else if (e.event.key === 'ArrowDown') {
      this.onArrowDown.emit();
    }
  };
}

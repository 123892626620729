import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-calendar-settings',
    templateUrl: './calendar-settings.component.html',
    styleUrls: ['./calendar-settings.component.scss'],
    inputs: [
        'dropDownBoxMode',
        'readOnly',
        'width',
        'isPopupMode',
        'isVisible',
        'configuration',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalendarSettingsComponent implements OnInit, OnChanges {
  @Output() onClosed = new EventEmitter();
  @Output() onChoosed = new EventEmitter();

  dropDownValue: any = null;
  isGridBoxOpened = false;
  configuration;
  dateStart = new Date().setDate(new Date().getDate() - 7);
  dateEnd = new Date();
  isVisible;
  isPopupMode;
  dropDownBoxMode;
  readOnly;
  width;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {
    this.getReminderTimes();
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.getTranslations();
  }

  ngOnDestroy() {}

  _configuration: any = {};

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.isVisible &&
      changes.isVisible.currentValue &&
      changes.isVisible.currentValue !== changes.isVisible.previousValue
    ) {
      this._configuration = Object.assign({}, this.configuration);
      this.cd.detectChanges();
    }
  }

  saveConfiguration() {
    this.onChoosed.emit(this._configuration);
    this.isVisible = false;
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.saveConfiguration();
          }
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosed.emit(true);
        },
        preventDefault: true,
      }
    );
  }

  visibleChange() {
    setTimeout(() => {
      if (!this.isVisible) {
        this.onClosed.emit(true);
      }
    });
  }

  filterCriteria = [
    { value: 'currentDay', label: 'bieżący dzień' },
    { value: 'lastWeek', label: 'ostatni tydzień' },
    { value: 'currentMonth', label: 'bieżący miesiąc' },
    { value: 'lastMonth', label: 'poprzedni miesiąc' },
    { value: 'currentYear', label: 'bieżący rok' },
    { value: 'year', label: 'rok' },
    { value: 'january', label: 'styczeń' },
    { value: 'february', label: 'luty' },
    { value: 'march', label: 'marzec' },
    { value: 'april', label: 'kwiecień' },
    { value: 'may', label: 'maj' },
    { value: 'june', label: 'czerwiec' },
    { value: 'july', label: 'lipiec' },
    { value: 'august', label: 'sierpień' },
    { value: 'september', label: 'wrzesień' },
    { value: 'october', label: 'październik' },
    { value: 'november', label: 'listopad' },
    { value: 'december', label: 'grudzień' },
  ];

  getTranslations() {
    this.translate.get('calendarSettings.monday').subscribe((text: string) => {
      this.workDaysValues[0].label = text;
    });
    this.translate.get('calendarSettings.tuesday').subscribe((text: string) => {
      this.workDaysValues[1].label = text;
    });
    this.translate
      .get('calendarSettings.wednesday')
      .subscribe((text: string) => {
        this.workDaysValues[2].label = text;
      });
    this.translate
      .get('calendarSettings.thursday')
      .subscribe((text: string) => {
        this.workDaysValues[3].label = text;
      });
    this.translate.get('calendarSettings.friday').subscribe((text: string) => {
      this.workDaysValues[4].label = text;
    });
    this.translate
      .get('calendarSettings.saturday')
      .subscribe((text: string) => {
        this.workDaysValues[5].label = text;
      });
    this.translate.get('calendarSettings.sunday').subscribe((text: string) => {
      this.workDaysValues[6].label = text;
    });

    this.translate
      .get('calendarSettings.workWeek')
      .subscribe((text: string) => {
        this.weekViewItems[0].label = text;
      });
    this.translate
      .get('calendarSettings.calendarWeek')
      .subscribe((text: string) => {
        this.weekViewItems[1].label = text;
      });
    this.translate
      .get('calendarSettings.multipleColumns')
      .subscribe((text: string) => {
        this.weekViewItems[2].label = text;
      });

    this.translate
      .get('calendarSettings.horizontal')
      .subscribe((text: string) => {
        this.workerCalendarItems[0].label = text;
      });
    this.translate
      .get('calendarSettings.vertical')
      .subscribe((text: string) => {
        this.workerCalendarItems[1].label = text;
      });

    this.translate
      .get('calendarSettings.titleSel')
      .subscribe((text: string) => {
        this.taskDescriptions[0].label = text;
      });
    this.translate
      .get('calendarSettings.titleDescriptionSel')
      .subscribe((text: string) => {
        this.taskDescriptions[1].label = text;
      });
  }

  dateFrom: Date = new Date(new Date().setDate(new Date().getDate() - 7));
  dateTo: Date = new Date();

  yearDisabled: boolean = false;
  range: string = 'lastWeek';
  year: number = new Date().getFullYear();

  onYearValueChanged(e) {
    if (!e.event) return;
    this.dateFrom = new Date(
      e.value,
      this.dateFrom.getMonth(),
      this.dateFrom.getDate()
    );
    this.dateTo = new Date(
      e.value,
      this.dateTo.getMonth(),
      this.dateTo.getDate()
    );
  }

  onRangeValueChanged(e) {
    this.yearDisabled = this.checkYearDisabled(e.value);

    const today = new Date();
    switch (e.value) {
      case 'currentDay':
        this.dateFrom = today;
        this.dateTo = today;
        this.year = today.getFullYear();
        break;
      case 'lastWeek':
        this.dateFrom = new Date(new Date().setDate(today.getDate() - 7));
        this.dateTo = today;
        this.year = today.getFullYear();
        break;
      case 'currentMonth':
        this.dateFrom = new Date(new Date().setDate(1));
        this.dateTo = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.year = today.getFullYear();
        break;
      case 'currentYear':
        this.dateFrom = new Date(today.getFullYear(), 0, 1);
        this.dateTo = new Date(today.getFullYear(), 12, 0);
        this.year = today.getFullYear();
        break;
      case 'year':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 0, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 12, 0);
        break;
      case 'january':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 0, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 1, 0);
        break;
      case 'february':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 1, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 2, 0);
        break;
      case 'march':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 2, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 3, 0);
        break;
      case 'april':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 3, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 4, 0);
        break;
      case 'may':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 4, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 5, 0);
        break;
      case 'june':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 5, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 6, 0);
        break;
      case 'july':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 6, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 7, 0);
        break;
      case 'august':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 7, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 8, 0);
        break;
      case 'september':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 8, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 9, 0);
        break;
      case 'october':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 9, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 10, 0);
        break;
      case 'november':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 10, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 11, 0);
        break;
      case 'december':
        this.dateFrom = new Date(this.dateFrom.getFullYear(), 11, 1);
        this.dateTo = new Date(this.dateFrom.getFullYear(), 12, 0);
        break;
      default:
        break;
    }
  }

  unlockedYearItems: string[] = [
    'year',
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ];

  checkYearDisabled(value: string) {
    return !this.unlockedYearItems.includes(value);
  }

  ///////////

  timeValue = 30;
  timeItems = [
    { label: '5min', value: 2.5 },
    { label: '6min', value: 3 },
    { label: '10min', value: 5 },
    { label: '15min', value: 7.5 },
    { label: '20min', value: 10 },
    { label: '30min', value: 15 },
    { label: '1h', value: 30 },
    { label: '2h', value: 60 },
    { label: '3h', value: 90 },
    { label: '4h', value: 120 },
  ];

  taskDescription = 'title';

  taskDescriptions = [
    { label: 'Tytuł', value: 'title' },
    { label: 'Tytuł + opis', value: 'titleDescription' },
  ];

  weekView = 'calendarWeek';

  weekViewItems = [
    { label: 'Tydzień roboczy', value: 'title' },
    { label: 'Tydzień kalendarzowy', value: 'calendarWeek' },
    { label: 'Wiele kolumn', value: 'multipleColumns' },
  ];

  workerCalendar = 'vertical';

  workerCalendarItems = [
    { label: 'Układ poziomy', value: 'horizontal' },
    { label: 'Układ pionowy', value: 'vertical' },
  ];

  timeValueItems = [];

  startTimeValue: number = 18;
  endTimeValue: number = 34;
  onlyWorkWeek: boolean = false;
  workDaysValues = [
    { value: true, label: 'Poniedziałek' },
    { value: true, label: 'Wtorek' },
    { value: true, label: 'Środa' },
    { value: true, label: 'Czwartek' },
    { value: true, label: 'Piątek' },
    { value: false, label: 'Sobota' },
    { value: false, label: 'Niedziela' },
  ];

  getReminderTimes() {
    const amount = 24;
    this.timeValueItems.push({ value: 0, label: '00:00' });
    for (let i = 1; i < amount; i++) {
      let time = i < 10 ? '0' + i : i;
      time += ':00';

      this.timeValueItems.push({ value: i, label: time });
    }
  }
}

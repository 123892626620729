<dx-popup
  [width]="event.deviceType == 'mobile' ? '100%' : widthWindow"
  [height]="event.deviceType != 'mobile' ? 520 : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: {
      type: 'slideIn',
      direction: 'bottom',
      duration: popupAnimationDuration
    },
    hide: {
      type: 'slideOut',
      direction: 'bottom',
      duration: popupAnimationDuration
    }
  }"
  [showTitle]="true"
  title="{{ 'form-financial-document.item' | translate }}"
  [(visible)]="isVisible"
  *ngIf="openNestFinished"
  (visibleChange)="visibleChange()"
  (onShown)="
    event.onShownPopUp(unicalGuid); event.setFocusWithSelect(firstInputFocus)
  "
  (onHidden)="event.onHiddenPopUp(unicalGuid)"
  [wrapperAttr]="{ id: unicalGuid, 'data-cy': 'form-position-offer' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <!-- <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button> -->
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="addItem()"
        [id]="'btn-save-item' + unicalGuid"
        data-cy="save-button-position-offer"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'btn-cancel-item' + unicalGuid"
        data-cy="offer-position-cancel"
      >
      </dx-button>

      <!-- <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip> -->

      <dx-tooltip
        [target]="'#btn-save-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-cancel-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "form-commercial-operation.grid.articleName" | translate }}:
          <strong>{{ articleDetail?.ProductFullName }}</strong> |
          {{ "customerGroups.catalogIndex" | translate }}:
          <strong>{{ articleDetail?.IndexCatalogue }}</strong>
          @if(event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){|
          {{ "articles.availableAmount" | translate }}:
          <strong>{{ articleDetail?.StockAvailable }}</strong>
          }
        </p>
      </div>
      <form [formGroup]="form">
        <dx-tab-panel
          #tabPanel
          [items]="[
            'companies.tabs.basicInfo' | translate,
            'articles.other' | translate
          ]"
          [loop]="false"
          [animationEnabled]="false"
          [(selectedIndex)]="selectedTabIndex"
          [swipeEnabled]="false"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                <div class="d-flex justify-content-center flex-wrap">
                  <div
                    class="form-group-inline-right"
                    style="width: 50%"
                    [ngClass]="{ 'width-mobile': event.deviceType == 'mobile' }"
                  >
                    <div
                      class="offer-position-step-1"
                      style="height: 28px; margin-bottom: 6px"
                    >
                      <label style="width: 120px">{{
                        "stockHistorySummary.amount" | translate
                      }}</label>
                      <dx-number-box
                        #firstInputFocus
                        width="160"
                        [showSpinButtons]="true"
                        [useLargeSpinButtons]="true"
                        [min]="0"
                        [format]="isUnitDivisible ? format : formatNonDivisible"
                        formControlName="Amount"
                        (onFocusIn)="event.onNumberBoxFocusIn($event)"
                        (onValueChanged)="onAmountChanged($event)"
                        valueChangeEvent="input"
                        data-cy="position-quantity"
                      ></dx-number-box>
                    </div>
                  </div>
                  <div
                    class="form-group-inline-right"
                    style="width: 50%"
                    [ngClass]="{ 'width-mobile': event.deviceType == 'mobile' }"
                  >
                    <div
                      class="offer-position-step-2"
                      style="height: 28px; margin-bottom: 6px"
                    >
                      <label style="width: 120px">
                        {{ "articles.sizeUnit" | translate }}
                      </label>
                      <dx-drop-down-box
                        [(value)]="unitId"
                        valueExpr="UnitId"
                        [deferRendering]="false"
                        displayExpr="UnitName"
                        [(opened)]="isGridBoxOpened"
                        [dataSource]="articleDetail?.Units"
                        [tabIndex]="-1"
                        [showClearButton]="false"
                        style="
                          width: 160px;
                          display: inline-block;
                          margin-bottom: 6px;
                        "
                        (onValueChanged)="onUnitChoosed()"
                        (keydown.space)="isGridBoxOpened = true"
                      >
                        <div
                          *dxTemplate="let data of 'content'"
                          style="height: 280px"
                        >
                          <div class="row">
                            <div class="col-md-12" class="no-padding">
                              <dx-data-grid
                                [dataSource]="articleDetail?.Units"
                                [wordWrapEnabled]="true"
                                [showBorders]="true"
                                shortcut
                                [height]="258"
                                class="dropDownBoxClassGrid no-max-width"
                                [columnResizingMode]="'widget'"
                                [remoteOperations]="true"
                                (onRowDblClick)="onRowDblClick($event)"
                                [columnAutoWidth]="true"
                                [allowColumnResizing]="true"
                                (onFocusedRowChanged)="
                                  onFocusedRowChanged($event)
                                "
                                [id]="'grid' + unicalGuid"
                                [focusedRowEnabled]="true"
                                #gridClass
                                [hoverStateEnabled]="true"
                              >
                                <dxo-load-panel
                                  [enabled]="false"
                                ></dxo-load-panel>
                                <dxo-scrolling mode="infinite"></dxo-scrolling>
                                <dxo-paging [pageSize]="50"></dxo-paging>
                                <dxo-selection
                                  mode="single"
                                  showCheckBoxesMode="always"
                                >
                                </dxo-selection>
                                <dxi-column
                                  caption="{{
                                    'articles.shortcut' | translate
                                  }}"
                                  width="60"
                                  dataField="Code"
                                  [allowSorting]="false"
                                  cellTemplate="Code"
                                  headerCellTemplate="CodeHeaderTemplate"
                                >
                                </dxi-column>
                                <div
                                  *dxTemplate="let data of 'CodeHeaderTemplate'"
                                  style="padding-left: 10px"
                                >
                                  {{ "articles.shortcut" | translate }}
                                </div>
                                <div
                                  *dxTemplate="let cell of 'Code'"
                                  style="padding-left: 10px"
                                >
                                  {{ cell.value }}
                                </div>
                                <dxi-column
                                  caption="{{ 'articles.unit' | translate }}"
                                  dataField="UnitName"
                                  [allowSorting]="false"
                                  width="100"
                                ></dxi-column>
                                <dxi-column
                                  caption="{{
                                    'articles.converter' | translate
                                  }}"
                                  dataField="Factor"
                                  [allowSorting]="false"
                                  width="100"
                                  alignment="left"
                                ></dxi-column>
                                <!-- <dxi-column caption="{{'form-commercial-operation.grid.count' | translate}}"
                                dataField="Quantity" [allowSorting]="false" [width]="100" alignment="left"></dxi-column> -->
                              </dx-data-grid>
                            </div>
                          </div>
                          <div class="bottom-drop-box popup-footer">
                            <dx-button
                              class=""
                              type="danger"
                              [text]="
                                'warehouseDocument.selectUnitAndQuantity'
                                  | translate
                              "
                              (onClick)="chooseUnitAndQuantity()"
                            >
                            </dx-button>
                            <dx-button
                              class=""
                              type="success"
                              [text]="'buttons.choose' | translate"
                              (onClick)="chooseUnit()"
                            >
                            </dx-button>
                          </div>
                        </div>
                      </dx-drop-down-box>
                    </div>
                    <label style="width: 120px">{{
                      "articles.amountInJMag" | translate
                    }}</label>
                    <dx-number-box
                      width="160"
                      [showSpinButtons]="true"
                      [useLargeSpinButtons]="true"
                      [format]="format"
                      [(value)]="positionAmount"
                      (onValueChanged)="onAmountFactorChanged($event)"
                      [min]="0"
                      [step]="unit?.Factor || 1"
                      (onFocusIn)="event.onNumberBoxFocusIn($event)"
                      valueChangeEvent="input"
                    >
                    </dx-number-box>
                  </div>
                </div>
                <div class="d-flex justify-content-center flex-wrap">
                  <div class="col-md-12">
                    <dx-accordion
                      [dataSource]="['buttons.advanced' | translate]"
                      [collapsible]="true"
                      (onSelectionChanged)="onAccordionSelectionChanged($event)"
                      [selectedItems]="selectedAccordionItems"
                      [multiple]="false"
                      [animationDuration]="300"
                      class="internalAccordion offer-pos-accordion no-padding"
                    >
                      <div *dxTemplate="let company of 'item'">
                        <div class="d-flex justify-content-center flex-wrap">
                          <div
                            class="form-group-inline-right"
                            style="width: 50%"
                            [ngClass]="{
                              'width-mobile': event.deviceType == 'mobile'
                            }"
                          >
                            <div
                              class="offer-position-step-3"
                              style="height: 28px; margin-bottom: 6px"
                            >
                              <label style="width: 120px">{{
                                "offerPosition.priceBox" | translate
                              }}</label>
                              <app-article-price-position-document
                                [isVisible]="isPriceVisible"
                                [readOnly]="!ArtykCenWyborPozDok"
                                [title]="articleDetail?.ProductFullName"
                                [productId]="form.get('ProductId').value"
                                [customerId]="customerId"
                                width="160"
                                [PriceName]="priceName"
                                (onChoosed)="onPriceChoosed($event)"
                                [DocumentCurrencyCode]="currencyCode"
                                [CurrencyFactor]="currencyFactor"
                                [DocumentDate]="documentDate"
                              >
                              </app-article-price-position-document>
                            </div>
                            <label
                              style="width: 120px; display: inline-block"
                            ></label>
                            <dx-text-box
                              width="160"
                              [readOnly]="true"
                              style="display: inline-block; margin-bottom: 6px"
                              [value]="PriceDescription"
                            ></dx-text-box>
                            <div
                              class="offer-position-step-4"
                              style="height: 96px; margin-bottom: 6px"
                            >
                              <div class="d-flex">
                                <label style="width: 120px"
                                  >{{
                                    "form-commercial-operation.grid.netAmount"
                                      | translate
                                  }}
                                  <ng-container *ngIf="unit">
                                    [{{ unit.Code }}]
                                    <b>{{
                                      currencyCode ? " " + currencyCode : ""
                                    }}</b>
                                  </ng-container>
                                </label>
                                <dx-number-box
                                  style="width: 160px"
                                  *ngIf="!isCurrencyDocument"
                                  formControlName="PriceNet"
                                  [min]="0"
                                  [readOnly]="!ArtykCenESDok"
                                  (onFocusIn)="event.onNumberBoxFocusIn($event)"
                                  (onValueChanged)="onValueNetChanged($event)"
                                  [format]="format"
                                ></dx-number-box>
                                <dx-number-box
                                  style="width: 160px"
                                  *ngIf="isCurrencyDocument"
                                  formControlName="PriceNetCurrency"
                                  [min]="0"
                                  [readOnly]="!ArtykCenESDok"
                                  (onFocusIn)="event.onNumberBoxFocusIn($event)"
                                  (onValueChanged)="
                                    onValueNetCurrencyChanged($event)
                                  "
                                  [format]="format"
                                ></dx-number-box>
                              </div>
                              <label style="width: 120px"
                                >{{ "taxRates.rate" | translate }} VAT</label
                              >
                              <app-tax-rates
                                [dropDownBoxMode]="true"
                                [taxCode]="form.get('TaxCode').value"
                                (onChoosed)="onTaxCodeChoosed($event)"
                                [width]="160"
                                style="width: 160px"
                              ></app-tax-rates>
                              <br />
                              <div class="d-flex">
                                <label style="width: 120px"
                                  >{{
                                    "form-commercial-operation.grid.grossAmount"
                                      | translate
                                  }}
                                  <ng-container *ngIf="unit"
                                    >[{{ unit.Code }}]</ng-container
                                  >
                                </label>
                                <dx-number-box
                                  style="width: 160px"
                                  *ngIf="!isCurrencyDocument"
                                  [min]="0"
                                  formControlName="PriceGross"
                                  [readOnly]="!ArtykCenESDok"
                                  (onFocusIn)="event.onNumberBoxFocusIn($event)"
                                  (onValueChanged)="onValueGrossChanged($event)"
                                  [format]="format"
                                ></dx-number-box>
                                <dx-number-box
                                  style="width: 160px"
                                  *ngIf="isCurrencyDocument"
                                  formControlName="PriceGrossCurrency"
                                  [min]="0"
                                  [readOnly]="!ArtykCenESDok"
                                  (onFocusIn)="event.onNumberBoxFocusIn($event)"
                                  (onValueChanged)="
                                    onValueGrossCurrencyChanged($event)
                                  "
                                  [format]="format"
                                ></dx-number-box>
                              </div>
                            </div>
                            <div
                              class="offer-position-step-5"
                              style="height: 28px; margin-bottom: 6px"
                            >
                              <label style="width: 120px">{{
                                "individualPrices.markupPM" | translate
                              }}</label>
                              <dx-number-box
                                style="width: 160px"
                                formControlName="Discount"
                                [format]="format"
                                (onFocusIn)="event.onNumberBoxFocusIn($event)"
                                (onValueChanged)="onDiscountChanged($event)"
                                [useLargeSpinButtons]="true"
                                [showSpinButtons]="true"
                              ></dx-number-box>
                              <br />
                              <dx-button
                                class="discountMarkupConfig"
                                type="danger"
                                text="{{
                                  'offers.discountMarkupConfig' | translate
                                }}"
                                (onClick)="setDefaultDiscountMarkup()"
                              >
                              </dx-button>
                              <app-discount-markup-factor
                                [isVisible]="isDefaultMarkupVisible"
                                (onClosed)="isDefaultMarkupVisible = false"
                                [valueBefore]="
                                  isCurrencyDocument
                                    ? form.controls.PriceNetCurrency.value
                                    : form.controls.PriceNet.value
                                "
                                [discountMarkupPercent]="
                                  form.controls.Discount.value
                                "
                                (onChoosed)="onDefaultMarkupChoosed()"
                              >
                              </app-discount-markup-factor>
                            </div>
                          </div>
                          <div
                            class="form-group-inline-right"
                            style="width: 50%"
                            [ngClass]="{
                              'width-mobile': event.deviceType == 'mobile'
                            }"
                          >
                            <!-- <label style="width: 100%;">Cena preferowana dla grupy cenowej</label>
                                                <br> -->
                            <label style="width: 120px">{{
                              "offerPosition.netAmountWarUnit" | translate
                            }}</label>
                            <dx-number-box
                              style="width: 160px"
                              *ngIf="!isCurrencyDocument"
                              [(value)]="pricePerUnitNet"
                              [readOnly]="!ArtykCenESDok"
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              (onValueChanged)="onPricePerUnitChanged($event)"
                              [format]="format"
                              [min]="0"
                            ></dx-number-box>
                            <dx-number-box
                              style="width: 160px"
                              *ngIf="isCurrencyDocument"
                              [min]="0"
                              (onFocusIn)="event.onNumberBoxFocusIn($event)"
                              [(value)]="pricePerUnitNetCurrency"
                              [readOnly]="!ArtykCenESDok"
                              (onValueChanged)="
                                onPricePerUnitCurrencyChanged($event)
                              "
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px">{{
                              "offerPosition.grossAmountWarUnit" | translate
                            }}</label>
                            <dx-number-box
                              *ngIf="!isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              [(value)]="pricePerUnit"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              *ngIf="isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              [(value)]="pricePerUnitCurrency"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px"
                              >{{
                                "offerPosition.netAmountWithDiscount"
                                  | translate
                              }}
                            </label>
                            <dx-number-box
                              *ngIf="!isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              [format]="format"
                              [(value)]="valueNetWithDiscount"
                            ></dx-number-box>
                            <dx-number-box
                              *ngIf="isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              [format]="format"
                              [(value)]="PriceNetCurrencyWithDiscount"
                              [min]="0"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px">{{
                              "offerPosition.grossAmountWithDiscount"
                                | translate
                            }}</label>
                            <dx-number-box
                              *ngIf="!isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              [(value)]="valueWithDiscount"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              *ngIf="isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              [(value)]="PriceGrossCurrencyWithDiscount"
                              [min]="0"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px">{{
                              "form-commercial-operation.grid.netValue"
                                | translate
                            }}</label>
                            <dx-number-box
                              [readOnly]="true"
                              style="width: 160px"
                              *ngIf="!isCurrencyDocument"
                              formControlName="TotalNetAmount"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              [readOnly]="true"
                              style="width: 160px"
                              *ngIf="isCurrencyDocument"
                              formControlName="TotalNetAmountCurrency"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                            <label style="width: 120px">{{
                              "form-commercial-operation.grid.grossValue"
                                | translate
                            }}</label>
                            <dx-number-box
                              *ngIf="!isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              formControlName="TotalGrossAmount"
                              [format]="format"
                            ></dx-number-box>
                            <dx-number-box
                              *ngIf="isCurrencyDocument"
                              [readOnly]="true"
                              style="width: 160px"
                              formControlName="TotalGrossAmountCurrency"
                              [format]="format"
                            ></dx-number-box>
                            <br />
                          </div>
                        </div>
                      </div>
                    </dx-accordion>
                  </div>
                </div>
              </div>
              <div *ngSwitchCase="'articles.other' | translate">
                <div class="d-flex justify-content-center flex-wrap">
                  <div class="form-group-inline-right" style="width: 100%">
                    <label style="width: 120px">{{
                      "articles.description" | translate
                    }}</label>
                    <dx-text-area
                      [elementAttr]="{ class: 'no-max-width' }"
                      formControlName="Description"
                      width="calc(100% - 120px)"
                    ></dx-text-area>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </form>
      <wapro-keyboard-shortcuts
        [unicalGuid]="unicalGuid"
        [shortcuts]="shortcuts"
        [disabled]="!isVisible || isExpansionListVisible"
      >
      </wapro-keyboard-shortcuts>

      <app-form-user-extensions
        [isVisible]="visibleUserExtensions"
        groupCode="Pozycja_oferty"
        title="{{ 'categories.externalFields' | translate }}"
        (onChoosed)="onInsertedUserExtensions($event)"
        (onClosing)="onClosingUserExtensions()"
        [userExtensionsValue]="form.get('AdditionalFields')?.value"
        #isValidUserExtensions
      >
      </app-form-user-extensions>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGOP'"
  [parentObjectParameters]="parentObjectParameters"
  [objectParameters]="nestObjectParameters"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGOP'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); onShown()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxWidth]="event.maxWidthSignal()"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up title-pop-upPeyment">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <form [formGroup]="form">
        <div class="form-columns">
          <div class="form-column form-column__full-width">
            <div class="form-column__item">
              <label>{{
                "form-commercial-operation.typeOfAmount" | translate
              }}</label>
              <div class="form-control">
                <dx-radio-group
                  [items]="radioType()"
                  displayExpr="label"
                  valueExpr="value"
                  [readOnly]="true"
                  layout="horizontal"
                  direction="vertical"
                  formControlName="TypeOfAmount"
                >
                </dx-radio-group>
              </div>
            </div>

            <!-- Kwota -->
            <div class="form-column__item">
              <label>{{
                "form-financial-document.grid.amount" | translate
              }}</label>
              <div class="form-control">
                <dx-number-box
                  width="276"
                  [readOnly]="true"
                  [format]="event.formatPLN"
                  [min]="0"
                  formControlName="Amount"
                  #firstInput
                >
                </dx-number-box>
              </div>
            </div>

            <!-- waluta -->
            <div class="form-column__item">
              <label>{{ "bankAccounts.form.currency" | translate }}</label>

              <div class="form-control">
                <app-currency-box
                  [dropDownBoxMode]="true"
                  [readOnly]="true"
                  #currencyList
                  (onChoosed)="onChoosedCurrency($event)"
                  [selectedId]="form.value.CurrencyCode"
                >
                </app-currency-box>
              </div>
            </div>

            <!-- kwota waluty -->
            <div class="form-column__item">
              <label>{{
                "form-financial-document.grid.currency-amount" | translate
              }}</label>
              <div class="form-control">
                <dx-number-box
                  width="276"
                  [readOnly]="true"
                  [format]="event.formatCurrency"
                  [min]="0"
                  formControlName="AmountCurrency"
                >
                </dx-number-box>
              </div>
            </div>

            <!-- forma płatności -->
            <div class="form-column__item">
              <label>{{ "form-payment.paymentMethod" | translate }}</label>
              <div class="form-control">
                <app-forms-payment
                [onlyActive]="true"
                  [dropDownBoxMode]="true"
                  [readOnly]="readOnly"
                  (onChoosed)="onChoosedFormPeyment($event)"
                  [selectedId]="form.value.PaymentFormName"
                >
                </app-forms-payment>
              </div>
            </div>

            <!-- termin płatności -->
            <div class="form-column__item">
              <label>{{
                "form-commercial-operation.dateOfPaymentt" | translate
              }}</label>
              <div class="form-control">
                <wapro-date-box
                  type="date"
                  [readOnly]="readOnly"
                  [displayFormat]="event.dateFormat"
                  formControlName="PaymentDate"
                >
                </wapro-date-box>
              </div>
            </div>

            <!-- numer rachunku -->
            <div class="form-column__item">
              <label>{{ "bankAccounts.form.accountNumber" | translate }}</label>
              <div class="form-control">
                @if(type.trim() == 's'){
                <app-bank-accounts
                  [dropDownBoxMode]="true"
                  [readOnly]="readOnly"
                  (onChoosed)="onChoosedCompanyBank($event)"
                  [selectedId]="form.value.BankAccountId"
                >
                </app-bank-accounts>
                } @else {
                <app-bank-accounts-contractors
                  [readOnly]="readOnly"
                  [customerId]="CustomerId"
                  (onChoosed)="onChoosedCompanyBank($event)"
                  [companyBankAccountId]="form.value.BankAccountId"
                >
                </app-bank-accounts-contractors>
                }
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<dx-popup
  *ngIf="isPopupMode"
  [width]="900"
  [height]="550"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'articles.title' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <ng-container *ngIf="componentVisible">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          id="cancel-choose-article"
        ></dx-button>

        <dx-tooltip
          target="#cancel-choose-article"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ 'buttons.cancel' | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </ng-container>
  </div>
</dx-popup>

<ng-container *ngIf="!isPopupMode">
  <div *ngIf="!dropDownBoxMode; else dropDownMode">
    <ng-container *ngIf="componentVisible">
      <div class="top-menu-panel">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <div class="center-panel-scroll-verticall">
        <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
          <ng-container *ngTemplateOutlet="grid"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="articleName"
    valueExpr="ProductId"
    [deferRendering]="false"
    displayExpr="ProductFullName"
    [(opened)]="isGridBoxOpened"
    width="276"
    [dataSource]="articlesList"
    [showClearButton]="true"
    [readOnly]="readOnly"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="openedChange($event)"
  >
    <div *dxTemplate="let data of 'content'" style="height: 300px;">
      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <div class="d-inline-block tasks-step-2">
      <app-wapro-selected-box
        [(ngModel)]="selectedRows"
        [dataSource]="dataSource"
        [selectedAmount]="selectedAmount()"
        (onDeselectAll)="onDeselectAll()"
        (onOnlySelected)="onOnlySelected()"
      ></app-wapro-selected-box>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        type="default"
        stylingMode="text"
        id="btn-add-articles"
        (onClick)="showNewRow()"
        [disabled]="readOnly || !perABD.addBtn"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        type="default"
        stylingMode="text"
        id="btn-edit-articles"
        (onClick)="showEditArticle()"
        [disabled]="readOnly || !perABD.editBtn || articleEdit.length == 0"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--form-items"
        id="btn-show-articles"
        class="btn-dx-top-menu-folder"
        *ngIf="!isPopupMode"
        [disabled]="!perABD.showBtn || articleEdit.length == 0"
        type="default"
        stylingMode="text"
        (onClick)="setSingleRecordVisible('show')"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--highlight-off"
        type="default"
        stylingMode="text"
        id="btn-delete-articles"
        (onClick)="isDelete = true"
        [disabled]="readOnly || !perABD.deleteBtn || articleEdit.length == 0"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      ></dx-button>

      <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

      <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

      <app-btn-export-excel
        [gridReference]="gridBank"
        [nameFile]="'menu.taskList' | translate"
        url="crm/tasks"
        [paramsObj]="paramsObj"
        *ngIf="perEksportExcel"
      ></app-btn-export-excel>

      <app-additional-operation
        #additionalOperation
        nameComponent="CrmTasks"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      ></app-additional-operation>

      <dx-button
        icon="icon absui-icon--tables"
        [id]="'tables' + unicalGuid"
        class="btn-dx-top-menu"
        type="default"
        stylingMode="text"
        *ngIf="!isPopupMode && additionalTables.length"
        [disabled]="articleEdit.length == 0"
        [text]="'buttons.tables' | translate"
        (onClick)="isExternalTableVisible = true"
      ></dx-button>
    </div>

    <!-- tooltips -->

    <dx-tooltip
      target="#btn-add-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.add' | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-edit-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.edit' | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-delete-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.delete' | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-show-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.show' | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'eDocuments' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.eDocuments' | translate }} (Ctrl + E)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-operations-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'form-financial-document.operations' | translate }} (Ctrl + O)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#tables' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.tables' | translate }} (Ctrl + Y)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px;"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        style="margin-right: 6px;"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (click)="refreshView()"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px;"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder tasks-step-3"
        icon="column-chooser"
        (click)="columnsChooserVisible = true"
        *ngIf="!dropDownBoxMode"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'userGuide.runGuideAgain' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'menu.navigationPanelUser.help' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'refresh' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'columnSettings' | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          [ngClass]="{
            without_search_panel: dropDownBoxMode,
            with_search_panel: !dropDownBoxMode
          }"
          [ngStyle]="{ width: localStorageData.mainPanelWidth }"
          #rightPanel
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="openCustomDropDown"
                class="tasks-step-4"
                [items]="filterCriteria"
                [(selectedItem)]="localStorageData.filter.orderBy"
                [(filterValue)]="localStorageData.filter.value"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px;"
                (onArrowDown)="gridBank.instance.focus()"
                #searchControl
              ></app-custom-dropdown-box>

              <div
                class="d-inline-block tasks-step-5"
                style="line-height: 38px;"
              >
                <app-date-range
                  #dateRange
                  [isVisible]="dateRangeVisible"
                  [readOnly]="readOnly"
                  [isPopupMode]="true"
                  (onChoosed)="onDateRangeChoosed()"
                  [chipsVisible]="true"
                ></app-date-range>

                <!-- pokaż zlecenia przyszłe -->
                <app-custom-chips-button
                  [name]="'servicesDevices.showFutureOrders' | translate"
                  [labelExpr]="'label'"
                  [valueExpr]="'value'"
                  [selectedValueInput]="showFutureOrders()"
                  [list]="showFutureOrdersList"
                  (onValueChanged)="onShowFutureOrdersChanged($event)"
                  [selectedValueInput]="
                    showFutureOrders() ? translate.instant('yes') : ''
                  "
                ></app-custom-chips-button>

                <dx-button
                  class="btn-filter-box"
                  [ngClass]="{ 'chips-btn': customerFilter.CustomerId }"
                  (onClick)="isCustomerVisible = true"
                >
                  {{
                    'form-financial-document.payer-type.contractor' | translate
                  }}
                  <b
                    style="margin-left: 6px;"
                    *ngIf="customerFilter.CustomerId"
                  >
                    {{ customerFilter.CustomerName }}
                  </b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetContrahentFilter()"
                    *ngIf="customerFilter.CustomerId"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="onlySelected"
                >
                  {{ 'showSelected' | translate }}:
                  <b>{{ 'yes' | translate }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('onlySelected')"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="localStorageData?.taskStatusName"
                  (click)="openRightPanel()"
                >
                  Status:
                  <b>{{ localStorageData?.taskStatusName }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('taskStatusName')"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="localStorageData?.taskTypeName"
                  (click)="openRightPanel()"
                >
                  {{ 'userExtensions.fieldType' | translate }}:
                  <b>{{ localStorageData?.taskTypeName }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('taskTypeName')"
                  ></i>
                </dx-button>

                <app-custom-chips-button
                  *ngIf="
                    localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                    localStorageData?.advancedFilter?.IsPinned
                  "
                  [name]="'filterPanel.template' | translate"
                  [labelExpr]="'Name'"
                  [valueExpr]="'FilterUsersDefinitionId'"
                  [selectedValueInput]="
                    localStorageData?.advancedFilter?.Name || null
                  "
                  [list]="dynamicFilterUserDefinitions"
                  (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
                ></app-custom-chips-button>

                <dx-button
                  [id]="'clearValues' + unicalGuid"
                  class="btn-filter-box"
                  icon="icon absui-icon--clear-filter"
                  (onClick)="clearValues()"
                ></dx-button>
                <dx-tooltip
                  [target]="'#clearValues' + unicalGuid"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ 'clearAllFilters' | translate }} (Ctrl + F10)
                  </div>
                </dx-tooltip>

                <app-view-dynamic-quick-filters
                  [dictionaryCode]="DynamicFilterDictionaryCode"
                  [pinnedFilters]="
                    localStorageData.advancedFilter?.PinnedFilters
                  "
                  [values]="
                    localStorageData?.advancedFilter?.UserFilterDefinitionValues
                  "
                  (onValueChanged)="onPinnedValueChanged($event)"
                ></app-view-dynamic-quick-filters>
              </div>

              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                [columnResizingMode]="'widget'"
                shortcut
                [columnAutoWidth]="true"
                [allowColumnReordering]="true"
                (onRowDblClick)="onRowDblClick()"
                (onKeyDown)="onKeyDown($event)"
                [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                [(selectedRowKeys)]="selectedRows"
                (onContentReady)="onGridContentReady($event)"
                class="task-grid"
                [ngClass]="{
                  dropDownBoxClassGrid: dropDownBoxMode,
                  'focus-on-detail': isDetailOnFocus
                }"
                [remoteOperations]="true"
                [id]="'grid' + unicalGuid"
                (onOptionChanged)="onOptionChanged($event)"
                (onCellClick)="onCellClick($event)"
                (onFocusedCellChanged)="onFocusedCellChanged($event)"
                (onInitialized)="onInitialized($event)"
                [allowColumnResizing]="true"
                [autoNavigateToFocusedRow]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [(focusedRowIndex)]="focusedRowIndex"
                (onEditorPreparing)="onEditorPreparing($event)"
                (onSelectionChanged)="onSelectionChanged($event)"
                [focusedRowEnabled]="true"
                #gridBank
                (onRowClick)="onRowClick($event)"
                (onContextMenuPreparing)="contextMenuPreparing($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-selection
                  *ngIf="!isPopupMode"
                  [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                  showCheckBoxesMode="always"
                  [allowSelectAll]="false"
                ></dxo-selection>
                <dxo-scrolling
                  mode="virtual"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-column-fixing [enabled]="!isPopupMode"></dxo-column-fixing>

                <dxi-column
                  cellTemplate="markerConfigTemplate"
                  [fixed]="true"
                  [width]="0"
                ></dxi-column>
                <div *dxTemplate="let data of 'markerConfigTemplate'">
                  <div
                    *ngIf="data.data.Colour"
                    [style.backgroundColor]="'#' + data.data.Colour"
                    class="task-colour"
                  ></div>
                </div>

                <dxi-column
                  caption="{{ 'userExtensions.fieldType' | translate }}"
                  width="40"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TypeId"
                  alignment="center"
                  [cellTemplate]="'typeTemplate'"
                  renderAsync="true"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'tasks.subject' | translate }}"
                  [cssClass]="
                    orderby === 'subject'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="subjectHeaderTemplate"
                  width="150"
                  [allowSorting]="false"
                  dataField="Subject"
                  cellTemplate="mainColumn"
                ></dxi-column>

                <div *dxTemplate="let data of 'mainColumn'">
                  <span
                    [id]="'main' + data.data.TaskId"
                    class="hov-underline"
                    style="
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    "
                    (click)="editRecord($event)"
                  >
                    {{ data.data.Subject }}
                  </span>
                  <dx-tooltip
                    [target]="'#main' + data.data.TaskId"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                    [showEvent]="event.tooltipShowEvent"
                  >
                    <div *dxTemplate="let x of 'content'">
                      {{ data.data.Subject }}
                    </div>
                  </dx-tooltip>
                </div>

                <div *dxTemplate="let data of 'subjectHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('subject')"
                  >
                    {{ 'tasks.subject' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'plannedOrders.priority' | translate }}"
                  width="60"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Prioritate"
                  alignment="center"
                  [cellTemplate]="'priorityTemplate'"
                  renderAsync="true"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'tasks.timeFrom' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataType="date"
                  dataField="DateStart"
                  cellTemplate="timeTemplate"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'tasks.timeTo' | translate }}"
                  dataType="date"
                  width="110"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="DateEnd"
                  cellTemplate="timeTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'timeTemplate'">
                  <div class="text-right black-txt">
                    {{
                      data.value
                        | date: 'dd.MM.yyyy':translate.currentLang || 'pl'
                    }}
                  </div>
                  <div class="text-right grey-txt">
                    {{
                      data.value | date: 'HH:mm':translate.currentLang || 'pl'
                    }}
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'warehouse.location' | translate }}"
                  width="80"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Location"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'tasks.realisation' | translate }}"
                  [cssClass]="
                    orderby === 'PctOfCompletion'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  width="110"
                  [allowSorting]="false"
                  alignment="left"
                  headerCellTemplate="realizationHeaderTemplate"
                  dataField="PctOfCompletion"
                  [cellTemplate]="'realisationTemplate'"
                  renderAsync="true"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'realizationHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('PctOfCompletion')"
                  >
                    {{ 'tasks.realisation' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="Status"
                  width="50"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="StatusCode"
                  cellTemplate="statusCell"
                  renderAsync="true"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.payer-type.contractor' | translate
                  }}"
                  width="200"
                  alignment="left"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="CustomerName"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'workers.attachment' | translate }}"
                  width="80"
                  renderAsync="true"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="AttachmentsAmount"
                  cellTemplate="attachmentTemplate"
                  alignment="right"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'tasks.insertDate' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataType="date"
                  alignment="right"
                  headerCellTemplate="insertDateHeaderTemplate"
                  [cssClass]="
                    orderby === 'taskDate'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  dataField="TaskDate"
                ></dxi-column>

                <div *dxTemplate="let data of 'insertDateHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('taskDate')"
                  >
                    {{ 'tasks.insertDate' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <div
                  *dxTemplate="let cell of 'attachmentTemplate'"
                  class="text-right"
                >
                  <!-- <i
                    class="icon icon-absui-icon--attach-file attachment-icon grid-attachment"
                    *ngIf="cell.value"
                  ></i> -->
                  {{ cell.value }}
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  width="50"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TaskId"
                ></dxi-column>
                <dxi-column
                  caption="Zap."
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="ProductName2"
                ></dxi-column>

                <ng-container *ngFor="let column of externalFieldColumns">
                  <dxi-column
                    *ngIf="column.IsActive"
                    [caption]="column.Name"
                    [visible]="false"
                    [dataField]="column.FieldValue"
                  ></dxi-column>
                </ng-container>

                <div *dxTemplate="let data of 'statusCell'" class="text-center">
                  <app-status-label
                    [text]="data.data.StatusCode"
                    [color]="data.data.StatusStringColor"
                    [backgroundColor]="data.data.StatusBackgroundColor"
                  ></app-status-label>
                </div>
                <div
                  *dxTemplate="let data of 'realisationTemplate'"
                  style="width: 100px;"
                >
                  <dx-progress-bar
                    #progressBar
                    style="display: inline-block;"
                    width="60px"
                    [min]="0"
                    [max]="100"
                    [value]="data.value"
                  ></dx-progress-bar>

                  <div class="dx-progressbar-percent">{{ data.value }}%</div>
                </div>

                <div
                  *dxTemplate="let data of 'priorityTemplate'"
                  class="text-center"
                >
                  <i
                    class="icon"
                    [ngClass]="{
                      'low-priority': data.value == 0,
                      'medium-priority': data.value == 1,
                      'high-priority': data.value == 2
                    }"
                  ></i>
                </div>
                <div
                  *dxTemplate="let data of 'typeTemplate'"
                  class="text-center"
                >
                  <i
                    [id]="'type' + data.data.TaskId"
                    class="icon icon-group icon-cell"
                    [ngClass]="{
                      'user-group': data.data.TypeDescription === 'Spotkanie',
                      'phone-group': data.data.TypeDescription === 'Telefon',
                      'car-group': data.data.TypeDescription === 'Wyjazd',
                      'service-group':
                        data.data.TypeDescription === 'Zlecenie serwisowe',
                      'default-icon':
                        data.data.TypeDescription !== 'Spotkanie' &&
                        data.data.TypeDescription !== 'Telefon' &&
                        data.data.TypeDescription !== 'Wyjazd' &&
                        data.data.TypeDescription !== 'Zlecenie serwisowe'
                    }"
                  ></i>
                  <dx-tooltip
                    [target]="'#type' + data.data.TaskId"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                    [showEvent]="event.tooltipShowEvent"
                  >
                    <div *dxTemplate="let x of 'content'">
                      {{ data.data.TypeDescription }}
                    </div>
                  </dx-tooltip>
                </div>
              </dx-data-grid>

              <wapro-context-menu
                [dataSource]="contextMenuSignal()"
                [width]="200"
                [target]="'#grid' + unicalGuid"
                (onItemClick)="contextMenuClick($event)"
                (onItemRendered)="event.onItemRendered($event)"
              ></wapro-context-menu>

              <app-column-chooser
                [gridRef]="gridBank"
                [(visible)]="columnsChooserVisible"
                (columnsChanged)="onColumnsChanged($event)"
              ></app-column-chooser>

              <dx-tooltip
                *ngFor="let icon of sortingIcons.asc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'columnSorting.asc' | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.desc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'columnSorting.desc' | translate }}
                </div>
              </dx-tooltip>
            </div>
          </div>

          <div class="row" *ngIf="!isPopupMode">
            <div
              class="bottom-side-panel"
              mwlResizable
              *ngIf="!dropDownBoxMode"
              (resizeEnd)="resizeEndBottom()"
              #bottomSidePanel
              (resizeStart)="onResizeStartBottom()"
              (resizing)="resizingBottom($event)"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down': showBottomPanel,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up': !showBottomPanel
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>
                <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          style="width: 300px;"
          [ngStyle]="{ width: localStorageData?.sidePanel?.width }"
          #leftPanel
        >
          <div class="right-panel-btn">
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>
          <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="loadChildComponent">
  <app-new-route
    [crmParam]="paramsObj"
    [selectedCRMTask]="selectedRows"
    [addingServiceOrdersRouteId]="articlesListPom[0]?.TaskId"
    [addingModeServiceOrdersRoute]="addingModeRoute"
    [isVisible]="newRouteVisible"
    [readOnly]="readOnly"
    [mode]="'add'"
    [context]="contextOfAddingRoute"
    (onClosing)="onClosingNewRoute()"
  ></app-new-route>

  <app-new-task
    [isVisible]="isSingleRecordVisible"
    [typeId]="
      localStorageData?.taskTypeId && localStorageData?.taskTypeId != '-1'
        ? localStorageData?.taskTypeId
        : null
    "
    [mode]="singleRecordMode"
    [taskTypes]="taskTypes"
    [componentNests]="singleRecordMode === 'show' ? null : componentNests"
    [selectedId]="articleEdit"
    (onClosing)="onSingleRecordClosing()"
    [readOnly]="readOnly || singleRecordMode === 'show'"
    (onInserted)="onSingleRecordInserted($event)"
    (shouldReloadTypes)="onShouldReloadTypes()"
    colour="4DB6AC"
  ></app-new-task>

  <app-confirm-dialog
    [isVisible]="isDeleteWorker"
    (onRemoving)="deleteWorker(); gridBank.instance.focus()"
    (onClosing)="isDeleteWorker = false; cd.detectChanges()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isDeleteContact"
    (onRemoving)="deleteContact(); gridBank.instance.focus()"
    (onClosing)="isDeleteContact = false; cd.detectChanges()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="isDelete"
    [confirmText]="
      selectedRows.length > 1 ? 'confirmDeleteMultiple' : 'confirmDelete'
    "
    (onRemoving)="showDeleteArticle(); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
    kindDialog="question"
  ></app-confirm-dialog>

  <app-table-user-extension
    [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
    (onClosing)="isExternalTableVisible = false"
    [isVisible]="isExternalTableVisible"
    [isPopupMode]="true"
  ></app-table-user-extension>

  <app-table-data-user-extension
    [readOnly]="readOnly"
    [isVisible]="isShortcutTableOpened"
    [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
    (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
    [table]="shortcutTable"
  ></app-table-data-user-extension>
</ng-container>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="
    isSingleRecordVisible ||
    isExternalTableVisible ||
    isShortcutTableOpened ||
    isDeleteContact ||
    isDeleteWorker ||
    isDelete ||
    isExpansionListVisible ||
    copyPopupVisible ||
    visibleUserExtensions ||
    notesService.isNotesFocused()
  "
></wapro-keyboard-shortcuts>

<!-- pola dodatkowe -->
<app-form-user-extensions
  *ngIf="visibleUserExtensions"
  [isVisible]="visibleUserExtensions"
  [groupCode]="USER_EXTENSIONS_GROUP_CODE"
  [readOnly]="readOnly"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="additionalFieldsList"
  #isValidUserExtensions
></app-form-user-extensions>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGCL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative;">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAll]="isAllSelected"
      [selectedAmount]="selectedAmount()"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      (onSelectAll)="gridBank.instance.selectAll()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      id="btn-add-articles"
      (onClick)="showNewRow()"
      [disabled]="readOnly"
      *ngIf="!dropDownBoxMode && !isPopupMode"
    ></dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0;"
    ></dx-button>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobileSignal()"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false"
      (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="CrmTasks"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    ></app-additional-operation>
  </div>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1;"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <app-custom-chips-button
        *ngIf="
          localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
          localStorageData?.advancedFilter?.IsPinned
        "
        [name]="'filterPanel.template' | translate"
        [labelExpr]="'Name'"
        [valueExpr]="'FilterUsersDefinitionId'"
        [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
        [list]="dynamicFilterUserDefinitions"
        (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
      ></app-custom-chips-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  ></app-filter-panel>
</div>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[1, 2]"
    [(selectedIndex)]="localStorageData?.sidePanel.selectedIndex"
    style="margin-top: -10px;"
    class="filter-panel tasks-step-6 right-panel-box"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-tree-view
            [dataSource]="taskStatuses"
            keyExpr="StatusId"
            style="height: auto;"
            dataStructure="plain"
            displayExpr="Description"
            [selectByClick]="true"
            selectionMode="single"
            parentIdExpr="parentId"
            (onItemClick)="selectItemTaskStatus($event)"
            itemTemplate="treeStatusTemplate"
            (onSelectionChanged)="onTreeSelectionChanged($event, 'statusTree')"
            (onContentReady)="restoreTreeConfig('taskStatusName')"
            #statusTree
          >
            <div *dxTemplate="let data of 'treeStatusTemplate'">
              <app-status-label
                *ngIf="data.Code"
                [text]="data.Code"
                [color]="data.StatusStringColor"
                [backgroundColor]="data.StatusBackgroundColor"
              ></app-status-label>
              <!-- <div *ngIf="data.Code === 'N'" class="statusN">N</div>
              <div *ngIf="data.Code === 'Z'" class="statusZ">Z</div>
              <div *ngIf="data.Code === 'C'" class="statusR">C</div>
              <div *ngIf="data.Code === 'A'" class="statusY">A</div> -->
              {{ data.Description }}
            </div>
          </dx-tree-view>

          <hr style="width: 100%;" />

          <dx-tree-view
            [dataSource]="taskTypes"
            displayExpr="Name"
            keyExpr="TypeId"
            style="height: auto;"
            dataStructure="plain"
            parentIdExpr="parentId"
            (onItemClick)="selectItemTaskType($event)"
            itemTemplate="treeTemplate"
            [selectByClick]="true"
            selectionMode="single"
            (onSelectionChanged)="onTreeSelectionChanged($event, 'typeTree')"
            (onContentReady)="restoreTreeConfig('taskTypeName')"
            #typeTree
            id="typeTree"
          >
            <div *dxTemplate="let data of 'treeTemplate'">
              <ng-container *ngIf="data.TypeId !== -1">
                <i
                  class="icon icon-group icon-cell icon-tree user-group"
                  *ngIf="data.Name === 'Spotkanie'"
                ></i>
                <i
                  class="icon icon-group icon-cell icon-tree phone-group"
                  *ngIf="data.Name === 'Telefon'"
                ></i>
                <i
                  class="icon icon-group icon-cell icon-tree car-group"
                  *ngIf="data.Name === 'Wyjazd'"
                ></i>
                <i
                  class="icon icon-group icon-cell icon-tree service-group"
                  *ngIf="data.Name === 'Zlecenie serwisowe'"
                ></i>
                <i
                  class="icon icon-group icon-cell icon-tree default-icon"
                  *ngIf="
                    data.Name !== 'Spotkanie' &&
                    data.Name !== 'Telefon' &&
                    data.Name !== 'Wyjazd' &&
                    data.Name !== 'Zlecenie serwisowe'
                  "
                ></i>
              </ng-container>
              {{ data.Name }}
            </div>
          </dx-tree-view>
        </div>
        <div *ngSwitchCase="2">
          <app-filter-panel
            [deleteFilters]="deleteFilter"
            [openPanelFilters]="visibleAdvanceFilters"
            (onFilterSave)="onFilterSaved($event)"
            [dictionaryCode]="DynamicFilterDictionaryCode"
            [selectedTemplate]="
              localStorageData?.advancedFilter?.FilterUsersDefinitionId
            "
            [isPinned]="localStorageData?.advancedFilter?.IsPinned"
            (onTemplatePinChanged)="onTemplatePinChanged($event)"
            [panelWidth]="localStorageData.sidePanel.width"
            [tabActive]="
              localStorageData?.sidePanel?.isVisible &&
              localStorageData?.sidePanel?.selectedIndex == 1
            "
            [values]="
              localStorageData?.advancedFilter?.UserFilterDefinitionValues
            "
            [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
          ></app-filter-panel>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="localStorageData.filter.orderBy"
      [(filterValue)]="localStorageData.filter.value"
      (onValueChanged)="onFilterDataChanged($event)"
      (onArrowDown)="gridBank.instance.focus()"
      #searchControl
    ></app-custom-dropdown-box>

    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getData()"
      ></i>
    </dx-button>
  </div>

  <dx-scroll-view
    width="100%"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="onScroll"
  >
    <div style="width: 700px; padding-top: 2px;">
      <app-date-range
        #dateRange
        [isVisible]="dateRangeVisible"
        [readOnly]="readOnly"
        [isPopupMode]="true"
        (onChoosed)="onDateRangeChoosed()"
        [chipsVisible]="true"
      ></app-date-range>

      <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected">
        {{ 'showSelected' | translate }}:
        <b>{{ 'yes' | translate }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('onlySelected')"
        ></i>
      </dx-button>

      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="localStorageData.taskStatusName"
        (click)="openRightPanel()"
      >
        Status:
        <b>{{ localStorageData.taskStatusName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('taskStatusName')"
        ></i>
      </dx-button>

      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="localStorageData.taskTypeName"
      >
        {{ 'userExtensions.fieldType' | translate }}: (click)="openRightPanel()"
        <b>{{ localStorageData.taskTypeName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('taskTypeName')"
        ></i>
      </dx-button>

      <app-custom-chips-button
        *ngIf="
          localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
          localStorageData?.advancedFilter?.IsPinned
        "
        [name]="'filterPanel.template' | translate"
        [labelExpr]="'Name'"
        [valueExpr]="'FilterUsersDefinitionId'"
        [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
        [list]="dynamicFilterUserDefinitions"
        (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
      ></app-custom-chips-button>
    </div>
  </dx-scroll-view>

  <dx-data-grid
    [dataSource]="dataSource"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    [columnResizingMode]="'widget'"
    shortcut
    [columnAutoWidth]="true"
    (onRowDblClick)="onRowDblClick()"
    (onKeyDown)="onKeyDown($event)"
    [height]="heightGrid - 60"
    [(selectedRowKeys)]="selectedRows"
    (onContentReady)="onContentReady($event)"
    [ngClass]="{
      dropDownBoxClassGrid: dropDownBoxMode,
      'focus-on-detail': isDetailOnFocus
    }"
    [remoteOperations]="true"
    [id]="'grid-mobile' + unicalGuid"
    (onOptionChanged)="onOptionChanged($event)"
    (onCellClick)="onCellClick($event)"
    (onFocusedCellChanged)="onFocusedCellChanged($event)"
    (onInitialized)="onInitialized($event)"
    [allowColumnResizing]="true"
    [autoNavigateToFocusedRow]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    #gridBank
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    ></dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex;">
        <div
          class="semafore-mobile"
          [style.background-color]="'#' + data.data.Colour"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <i
            [id]="'type' + data.data.TaskId"
            class="icon icon-group icon-cell"
            [ngClass]="{
              'user-group': data.data.TypeDescription === 'Spotkanie',
              'phone-group': data.data.TypeDescription === 'Telefon',
              'car-group': data.data.TypeDescription === 'Wyjazd',
              'service-group':
                data.data.TypeDescription === 'Zlecenie serwisowe',
              'default-icon':
                data.data.TypeDescription !== 'Spotkanie' &&
                data.data.TypeDescription !== 'Telefon' &&
                data.data.TypeDescription !== 'Wyjazd' &&
                data.data.TypeDescription !== 'Zlecenie serwisowe'
            }"
            style="margin-right: 6px; position: relative; top: 1px;"
          ></i>
          <span
            class="hov-underline-mobile"
            (click)="editRecord($event, data.data)"
          >
            {{ data.data.Subject }}
          </span>
          <br />

          <label class="mobile-grid-label">
            {{ 'form-financial-document.payer-type.contractor' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{
              data.data.CustomerName?.length > 25
                ? (data.data.CustomerName | slice: 0:25) + '...'
                : data.data.CustomerName
            }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ 'servicesDevices.ServiceOrderDate' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.DateStart | date: event.dateFormat }} /
            {{ data.data.DateEnd | date: event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ 'plannedOrders.priority' | translate }}:
          </label>
          <p class="mobile-grid-text">
            <i
              class="icon"
              [ngClass]="{
                'low-priority': data.data.Prioritate == 0,
                'medium-priority': data.data.Prioritate == 1,
                'high-priority': data.data.Prioritate == 2
              }"
            ></i>
          </p>
          <br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px);"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    .
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px;"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [style.background-color]="'#' + articlesListPom[0].Colour"
        ></div>
        <div class="right-details">
          <p *ngIf="articlesListPom[0]" class="nameSelectedPosition">
            {{ articlesListPom[0].Subject }}
          </p>

          <label class="details-label">
            {{ 'form-financial-document.payer-type.contractor' | translate }}:
          </label>
          <p class="details-text">{{ articlesListPom[0].CustomerName }}</p>

          <label class="details-label">
            {{ 'servicesDevices.ServiceOrderDate' | translate }}:
          </label>
          <p class="details-text">
            {{ articlesListPom[0].DateStart | date: event.dateFormat }} /
            {{ articlesListPom[0].DateEnd | date: event.dateFormat }}
          </p>

          <label class="details-label">
            {{ 'plannedOrders.priority' | translate }}:
          </label>
          <p class="details-text">
            <i
              class="icon"
              [ngClass]="{
                'low-priority': articlesListPom[0].Prioritate == 0,
                'medium-priority': articlesListPom[0].Prioritate == 1,
                'high-priority': articlesListPom[0].Prioritate == 2
              }"
            ></i>
          </p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ 'crmDeliveries.contractorData' | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%;">
          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'warehouse.location' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ articlesListPom[0].Location }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'tasks.realisation' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ articlesListPom[0].PctOfCompletion }} %
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">Status:</p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ articlesListPom[0].StatusCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'articles.created' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ articlesListPom[0].AttachmentsAmount }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'tasks.insertDate' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ articlesListPom[0].TaskDate | date: event.dateFormat }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'form-financial-document.number' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ articlesListPom[0].TaskId }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">Zap.:</p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ articlesListPom[0].ProductName2 }}</p>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
  </div>
</ng-template>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    [items]="bottomPanelTabs"
    [selectedIndex]="localStorageData?.selectionTab"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [loop]="false"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="tasks-step-7"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div [id]="'tabToolTip' + i + unicalGuid">
        <span>{{ name }}</span>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container
        [ngSwitch]="name"
        *ngIf="showBottomPanel || event.deviceType == 'mobile'"
      >
        <div *ngSwitchCase="'menu.eDocuments' | translate">
          <app-e-dokuments
            #eDocumentss
            [permission]="isPerToEdocuments()"
            DocumentCode="CrmTaskRelated"
            tableName="CRM_ZADANIE"
            style="display: inline-block; width: 100%;"
            [contextObjectId]="articlesListPom[0]?.TaskId"
            [parentDocumentName]="articlesListPom[0]?.Subject"
            [isShowSidePanel]="false"
            [heightGrid]="
              event.deviceType != 'mobile' ? heightBottomGrid - 65 : 545 - 65
            "
            [ContextObjectId]="
              articlesListPom.length > 0 ? articlesListPom[0]?.TaskId : null
            "
            [readOnly]="articlesListPom.length === 0"
          ></app-e-dokuments>
        </div>
        <div *ngSwitchCase="'constractors.contacts' | translate">
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [id]="'taskscontact' + unicalGuid"
            [dataSource]="details?.Contacts"
            [wordWrapEnabled]="false"
            #contactsGrid
            [(focusedRowIndex)]="focusedRowIndexContact"
            style="width: 100%; margin-bottom: 10px;"
            (onFocusedRowChanged)="
              onFocusedRowChangedDetail($event, 'contacts')
            "
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              [allowSorting]="false"
              dataField="CompanyShortName"
            ></dxi-column>
            <dxi-column
              caption="{{ 'tasks.nameSurname' | translate }}"
              [allowSorting]="false"
              [cellTemplate]="'nameSurnameTemplate'"
              dataField="NameSurname"
              [hidingPriority]="7"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'companies.form.phone' | translate }}"
              [allowSorting]="false"
              dataField="Phone"
              [hidingPriority]="6"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'articles.warning' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              [hidingPriority]="5"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'menu.eMail' | translate }}"
              [allowSorting]="false"
              dataField="Email"
              [hidingPriority]="4"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'constractors.jobPosition' | translate }}"
              [allowSorting]="false"
              dataField="Role"
              [hidingPriority]="2"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'constractors.title' | translate }}"
              [allowSorting]="false"
              dataField="Title"
              [hidingPriority]="1"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'tasks.contractorNr' | translate }}"
              [allowSorting]="false"
              dataField="ContactId"
              [hidingPriority]="0"
              width="204"
            ></dxi-column>
            <div *dxTemplate="let data of 'nameSurnameTemplate'">
              {{ data.data.LastName }} {{ data.data.FirstName }}
            </div>
          </dx-data-grid>

          <wapro-context-menu
            [dataSource]="contextMenuContactsSignal()"
            [width]="200"
            [target]="'#taskscontact' + unicalGuid"
            (onItemClick)="contextContactMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></wapro-context-menu>

          <app-contractors
            [isVisible]="isVisibleContractors"
            [popUpMode]="true"
            [isButtonContact]="true"
            (onChoosedContact)="onChoosedContact($event)"
            (onClosed)="isVisibleContractors = false; cd.detectChanges()"
          ></app-contractors>
        </div>

        <div *ngSwitchCase="'menu.workers' | translate">
          <dx-data-grid
            [showBorders]="true"
            #workersGrid
            [allowColumnReordering]="true"
            [id]="'tasksworker' + unicalGuid"
            [dataSource]="details?.Employees"
            [wordWrapEnabled]="false"
            [(focusedRowIndex)]="focusedRowIndexWorkers"
            style="width: 100%; margin-bottom: 10px;"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxi-column
              caption="{{ 'tasks.evidenceNr' | translate }}"
              [allowSorting]="false"
              dataField="EvidenceNumber"
            ></dxi-column>
            <dxi-column
              caption="{{ 'tasks.nameSurname' | translate }}"
              [allowSorting]="false"
              [cellTemplate]="'nameSurnameTemplate'"
              dataField="NameSurname"
              [hidingPriority]="3"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="PESEL"
              [allowSorting]="false"
              dataField="PESEL"
              [hidingPriority]="2"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="NIP"
              [allowSorting]="false"
              dataField="VatNumber"
              [hidingPriority]="1"
              width="204"
            ></dxi-column>
            <dxi-column
              caption="{{ 'articles.created' | translate }}"
              [allowSorting]="false"
              dataField="CountOfAttachments"
              [hidingPriority]="0"
              width="204"
            ></dxi-column>
            <div *dxTemplate="let data of 'nameSurnameTemplate'">
              {{ data.data.LastName }} {{ data.data.FirstName }}
            </div>
          </dx-data-grid>

          <wapro-context-menu
            [dataSource]="contextMenuWorkersSignal()"
            [width]="200"
            [target]="'#tasksworker' + unicalGuid"
            (onItemClick)="contextWorkerMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></wapro-context-menu>

          <app-workers
            [isVisible]="isVisibleWorkers"
            [popUpMode]="true"
            (onChoosed)="onChoosedWorker($event)"
            (onClosed)="isVisibleWorkers = false; cd.detectChanges()"
          ></app-workers>
        </div>

        <div *ngSwitchCase="'tasks.taskDescription' | translate">
          <dx-text-area
            [height]="heightBottomGrid"
            [value]="details?.Description"
            [readOnly]="true"
            style="max-width: none !important; width: 100%;"
          ></dx-text-area>
        </div>

        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="tasks"
            [objectId]="
              articleEdit?.length && !isSingleRecordVisible
                ? articleEdit[0]
                : null
            "
            *ngIf="
              bottomPanelTabs[tabPanel.selectedIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanelVisible
                : isDetails)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanelHeight
                : 545
            "
          ></app-notes>
        </div>

        <div
          *ngSwitchCase="
            localStorageData?.selectedAdditionalTable?.text ||
              'articles.externalTable' | translate
          "
        >
          <app-table-user-extension
            [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            (onTableChanged)="onAdditionalTableChanged($event)"
            [selectedTable]="localStorageData.selectedAdditionalTable"
            *ngIf="localStorageData.selectionTab == 3 && articleIdFocused"
          ></app-table-user-extension>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>

  <dx-tooltip
    *ngFor="let tabs of bottomPanelTabs; let i = index"
    [target]="'#tabToolTip' + i + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="mouseleave"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">(Alt+{{ i + 1 }})</div>
  </dx-tooltip>
</ng-template>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'tasks'"
></app-user-guide>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGCL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-confirm-dialog
  kindDialog="question"
  [confirmText]="copyText"
  [isVisible]="copyPopupVisible"
  (onRemoving)="copyTask()"
  (onClosing)="closeCopyTask()"
></app-confirm-dialog>

<app-contractors
  [popUpMode]="true"
  [selectedId]="articlesListPom[0]?.CustomerId"
  [isVisible]="isCustomerVisible"
  *ngIf="isCustomerVisible"
  (onClosed)="isCustomerVisible = false"
  (onChoosed)="onChoosedCustomer($event)"
></app-contractors>

<app-add-orders-service
  [isVisible]="isServiceOrderVisible()"
  [mode]="orderServiceMode"
  [readOnly]="readOnly || orderServiceMode === 'show'"
  (onClosing)="onServiceOrderClosing()"
  (onInserted)="onServiceOrderInserted($event)"
  [selectedId]="orderServiceId"
  [taskId]="serviceTaskId"
  [taskCustomerId]="serviceTaskCustomerId"
  title="{{ 'servicesDevices.addOrderService' | translate }}"
></app-add-orders-service>

<app-record-card
  *ngIf="isRecordCardVisible"
  [selectedTab]="'dashboard.sell' | translate"
  [cardMode]="'contractor'"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

<ng-template #reportsTemplate>
  <!-- <app-reports
    #reports
    [code]="'crm'"
    [menuName]="null"
    [type]="null"
    [objectId]="null"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    (onReportsPopupClosed)="onReportsPopupClosed()"
  >
  </app-reports> -->
</ng-template>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="isPerToEdocuments()"
    DocumentCode="CrmTaskRelated"
    tableName="CRM_ZADANIE"
    [contextObjectId]="articlesListPom[0]?.TaskId"
    [parentDocumentName]="articlesListPom[0]?.Subject"
    style="display: inline-block;"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

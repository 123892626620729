<dx-drop-down-box
  #BoxDropDownBox
  [dataSource]="availableValue"
  [valueExpr]="'PackagingId'"
  [width]="width ? width : 276"
  [deferRendering]="false"
  [displayExpr]="'Name'"
  [(opened)]="isGridBoxOpened"
  [(value)]="choosingValue"
  [showClearButton]="true"
  (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
  (openedChange)="onOpenedChanged($event)"
  [ngClass]="{ 'input-required': className == true }"
  [readOnly]="readOnly">
  <div *dxTemplate="let data of 'content'"
       style="height: 280px">
    <div class="row">
      <div class="col-md-12 no-padding"
      >
        <dx-data-grid
          [ngClass]="{ dropDownBoxClassGrid: true }"
          (onRowClick)="onChoosing()"
          [dataSource]="dataSource"
          [wordWrapEnabled]="true"
          [showBorders]="true"
          shortcut
          [height]="258"
          [columnResizingMode]="'widget'"
          [remoteOperations]="true"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          [(focusedRowIndex)]="packingFocusedRowIndex"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          [focusedRowEnabled]="true"
          #gridClass
          [hoverStateEnabled]="true"
          style="width: calc(100% + 16px)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling
            mode="virtual"
            columnRenderingMode="virtual"
            preloadEnabled="true"
          ></dxo-scrolling>
          <dxo-paging [pageSize]="50"
                      [enabled]="true"
          ></dxo-paging>
          <dxo-editing
            mode="row"
            [confirmDelete]="false"
          ></dxo-editing>
          <dxo-selection [mode]="'single'"
                         showCheckBoxesMode="always">
          </dxo-selection>

          <dxi-column
            [width]="event.deviceType != 'mobile' ? 45 : 10"
            cellTemplate="editTemplate"
            [allowSorting]="false"
            alignment="center"
          >
          </dxi-column>

          <div *dxTemplate="let data of 'editTemplate'"
               tabindex="-1">
            <dx-button
              *ngIf="data.data.CompanyId"
              class="btn-dx-top-menu-folder-grid"
              icon="icon absui-icon--mode-edit hover-edit-icon"
              [disabled]="readOnly || focusedBox?.length == 0 || !data.data.CompanyId"
              (onClick)="onEditClick()"
              (disabledChange)="disabledChange($event)"
            >
            </dx-button>
          </div>
          <!--              <dxi-column-->
          <!--                dataField="IsDefault"-->
          <!--                caption=" "-->
          <!--                width="30"-->
          <!--                cellTemplate="IsDefault"-->
          <!--              ></dxi-column>-->
          <!--              <div *dxTemplate="let data of 'IsDefault'">-->
          <!--                <i-->
          <!--                  class="icon absui-icon&#45;&#45;check check-grid"-->
          <!--                  *ngIf="data.data.IsDefault"-->
          <!--                ></i>-->
          <!--                <i-->
          <!--                  class="icon absui-icon&#45;&#45;close close-grid"-->
          <!--                  *ngIf="!data.data.IsDefault"-->
          <!--                ></i>-->
          <!--              </div>-->

          <dxi-column [allowSorting]="false"
                      dataField="Name"
                      [minWidth]="85"
                      [caption]="'companies.grid.columns.name' | translate"
                      [width]="85"
                      cellTemplate="NameTemplate"
          ></dxi-column>
          <div *dxTemplate="let data of 'NameTemplate'">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                {{ data.data.Name }}
              </div>
              <div *ngIf="!data.data.CompanyId">
                <i [id]="'Name' + unicalGuid + data.data.PackagingId"
                   class="icon absui-icon--lock-full"></i>
              </div>
            </div>
            <dx-tooltip
              [target]="'#Name' + unicalGuid + data.data.PackagingId"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "tooltipBlock" | translate }}
              </div>
            </dx-tooltip>
          </div>


          <dxi-column [allowSorting]="false"
                      [width]="50"
                      [minWidth]="50"
                      dataField="Width"
                      [caption]="
                  'articles.width3' | translate
                "
                      headerCellTemplate="Width"
          ></dxi-column>
          <div
            *dxTemplate="let data of 'Width'"
            class="text-center"
          >
            <div
              class="text-center"
              [id]="'Width' + unicalGuid"
            >
              {{ "articles.width3" | translate }}
            </div>
            <dx-tooltip
              [target]="'#Width' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "articles.width2" | translate }}
              </div>
            </dx-tooltip>
          </div>

          <dxi-column [allowSorting]="false"
                      [width]="50"
                      [minWidth]="50"
                      dataField="Height"
                      [caption]="
                  'articles.height3' | translate
                "
                      headerCellTemplate="Height"
          ></dxi-column>
          <div
            *dxTemplate="let data of 'Height'"
            class="text-center"
          >
            <div
              class="text-center"
              [id]="'Height' + unicalGuid"
            >
              {{ "articles.height3" | translate }}
            </div>
            <dx-tooltip
              [target]="'#Height' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "articles.height2" | translate }}
              </div>
            </dx-tooltip>
          </div>

          <dxi-column [allowSorting]="false"
                      [width]="50"
                      [minWidth]="50"
                      dataField="Depth"
                      [caption]="
                  'articles.depth3' | translate
                "
                      headerCellTemplate="Depth"
          ></dxi-column>
          <div
            *dxTemplate="let data of 'Depth'"
          >
            <div
              class="text-center"
              [id]="'Depth' + unicalGuid"
            >
              {{ "articles.depth3" | translate }}
            </div>
            <dx-tooltip
              [target]="'#Depth' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "articles.depth2" | translate }}
              </div>
            </dx-tooltip>
          </div>

          <dxi-column
            [allowSorting]="false"
            [width]="80"
            [minWidth]="80"
            dataField="MaxWeight"
            [caption]="
                  'articles.weight3' | translate
                "
            headerCellTemplate="MaxWeight"
          ></dxi-column>
          <div
            *dxTemplate="let data of 'MaxWeight'"
            class="text-center"
          >
            <div
              class="text-center"
              [id]="'MaxWeight' + unicalGuid"
            >
              {{ "articles.weight3" | translate }}
            </div>
            <dx-tooltip
              [target]="'#MaxWeight' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "articles.weight2" | translate }}
              </div>
            </dx-tooltip>
          </div>

          <dxi-column [allowSorting]="false"
                      [width]="90"
                      [minWidth]="90"
                      alignment="center"
                      dataField="Thickness"
                      [caption]="
                  'articles.packagingThickness' | translate
                "
          ></dxi-column>

          <!--              <dxi-column-->
          <!--                dataField="IsDefault"-->
          <!--                caption=" "-->
          <!--                width="30"-->
          <!--                cellTemplate="IsDefault"-->
          <!--              ></dxi-column>-->
          <!--              <div *dxTemplate="let data of 'IsDefault'">-->
          <!--                <i-->
          <!--                  class="icon absui-icon&#45;&#45;check check-grid"-->
          <!--                  *ngIf="data.data.IsDefault"-->
          <!--                ></i>-->
          <!--                <i-->
          <!--                  class="icon absui-icon&#45;&#45;close close-grid"-->
          <!--                  *ngIf="!data.data.IsDefault"-->
          <!--                ></i>-->
          <!--              </div>-->

        </dx-data-grid>
      </div>
    </div>
    <div
      class="bottom-drop-box"
      style="margin-left: -20px; width: calc(100% + 40px)"
    >
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        [disabled]="readOnly"
        (onClick)="addBox()"
        [id]="'add' + unicalGuid"
        (disabledChange)="disabledChange($event)"
      >
      </dx-button>

      <!--      <dx-button-->
      <!--        (disabledChange)="disabledChange($event)"-->
      <!--        (onClick)="isDelete()"-->
      <!--        [disabled]="readOnly || (focusedBox?.length == 0)"-->
      <!--        [id]="'delete' + unicalGuid"-->
      <!--        [text]="'buttons.delete' | translate"-->
      <!--        class="btn-dx-top-menu-folder"-->
      <!--        icon="icon absui-icon&#45;&#45;highlight-off"-->
      <!--      >-->
      <!--      </dx-button>-->

      <!--      <dx-button-->
      <!--        (disabledChange)="disabledChange($event)"-->
      <!--        icon="icon absui-icon&#45;&#45;check-circle"-->
      <!--        [id]="'btn-choose' + unicalGuid"-->
      <!--        class="btn-dx-top-menu"-->
      <!--        [disabled]="readOnly || (focusedBox?.length == 0)"-->
      <!--        [text]="'buttons.choose' | translate"-->
      <!--        (onClick)="onChoosing()"-->
      <!--      >-->
      <!--      </dx-button>-->

      <!--      <dx-button-->
      <!--        class="btn-dx-top-menu"-->
      <!--        icon="icon absui-icon&#45;&#45;check-circle"-->
      <!--        [id]="'default' + unicalGuid"-->
      <!--        [text]="'buttons.setDefault' | translate"-->
      <!--        [disabled]="readOnly || !selectedCodeId?.length"-->
      <!--        (onClick)="onSetDefault()"-->
      <!--      >-->
      <!--      </dx-button>-->

      <dx-tooltip
        [target]="'#delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }}
        </div>
      </dx-tooltip>

      <!--      <dx-tooltip-->
      <!--        [target]="'#default' + unicalGuid"-->
      <!--        [showEvent]="event.tooltipShowEvent"-->
      <!--        hideEvent="dxhoverend"-->
      <!--        [hideOnOutsideClick]="false"-->
      <!--      >-->
      <!--        <div *dxTemplate="let data of 'content'">-->
      <!--          {{ "buttons.setDefault" | translate }}-->
      <!--        </div>-->
      <!--      </dx-tooltip>-->
    </div>

    <!--    <app-add-trans-del-transp-codes-->
    <!--      [type]="'transaction'"-->
    <!--      [isVisible]="addTransactionCode"-->
    <!--      (onClosing)="onClose()"-->
    <!--      (onInserted)="onInserted()"-->
    <!--    >-->
    <!--    </app-add-trans-del-transp-codes>-->

  </div>
</dx-drop-down-box>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="
    !isGridBoxOpened ||
    isBoxVisible ||
    isDeleteBox
  "
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  [isVisible]="isDeleteBox"
  (onRemoving)="deleteBox(); gridClass.instance.focus()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isAddingOrEditingVisible"
  (onShown)="event.onShownPopUp();"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (visibleChange)="visibleChange($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'save' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isAddingOrEditingVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#save' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <!--    'input-required':-->
    <!--    !form.controls.DeliveryCode.valid && submitted-->
    <dx-scroll-view width="100%"
                    height="100%"
                    direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right"
             style="width: 445px">
          <form [formGroup]="form">
            <label style="width: 156px"
            >{{ 'companies.grid.columns.name' | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              width="276"
              formControlName="Name"
              [ngClass]="{
                    'input-required': submitted && !form.controls.Name.valid
                  }"
              [readOnly]="readOnly"
            >
            </dx-text-box>

            <br/>
            <label style="width: 156px">{{
                'articles.width2' | translate
              }}<strong>*</strong></label>
            <dx-number-box
              [format]="format0"
              formControlName="Width"
              width="276"
              [ngClass]="{
                    'input-required': submitted && !form.controls.Width.valid
                  }"
              [readOnly]="readOnly"
            >
            </dx-number-box>
            <br/>
            <label style="width: 156px">{{
                'articles.height2' | translate
              }}<strong>*</strong></label>
            <dx-number-box
              [format]="format0"
              formControlName="Height"
              width="276"
              [ngClass]="{
                    'input-required': submitted && !form.controls.Height.valid
                  }"
              [readOnly]="readOnly"
            >
            </dx-number-box>
            <br/>
            <label style="width: 156px">{{
                'articles.depth2' | translate
              }}<strong>*</strong></label>
            <dx-number-box
              [format]="format0"
              formControlName="Depth"
              width="276"
              [ngClass]="{
                    'input-required': submitted && !form.controls.Depth.valid
                  }"
              [readOnly]="readOnly"
            >
            </dx-number-box>

            <label style="width: 156px">{{
                'articles.weight2' | translate
              }}<strong>*</strong></label>
            <dx-number-box
              [format]="format000"
              formControlName="MaxWeight"
              width="276"
              [ngClass]="{
                    'input-required': submitted && !form.controls.MaxWeight.valid
                  }"
              [readOnly]="readOnly"
            >
            </dx-number-box>

            <div style="position: relative">
              <label style="width: 156px">{{
                  'articles.packagingThickness' | translate
                }}</label>
              <dx-number-box
                [format]="format0"
                formControlName="Thickness"
                width="276"
                [readOnly]="readOnly"
              >
              </dx-number-box>
              <dx-button
                style="position: absolute; right: -35px; top: 1px"
                *ngIf="event.deviceType != 'mobile'"
                class="btn-dx-top-menu-folder"
                icon="icon absui-icon--help"
                type="default"
                (click)="showTooltipHelp = true"
                [id]="'btn-help' + unicalGuid"
              ></dx-button>
            </div>


            <dx-tooltip
              [target]="'#btn-help' + unicalGuid"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
              [showEvent]="event.tooltipShowEvent"
            >
              <div
                *dxTemplate="let data of 'content'"
                [innerHTML]="
                          'articles.packagingThicknessDscr' | translate
                        "
              ></div>
            </dx-tooltip>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isAddingOrEditingVisible"
>
</wapro-keyboard-shortcuts>

<dx-context-menu
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
  [dataSource]="contextMenu()"
  [target]="'#grid' + unicalGuid"
  [width]="200"
>
</dx-context-menu>




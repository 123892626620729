<dx-popup
  [width]="550"
  [height]="200"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'customerGroups.chooseOption' | translate }}"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'btn-save-item' + unicalGuid"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="close()"
        [id]="'btn-cancel-item' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#btn-save-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-cancel-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <form [formGroup]="form">
        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
          <div
            class="form-group-inline-right flex-box-column"
            style="width: 100%"
          >
            <div class="form-box">
              <div class="box-title"></div>
              <div class="flex-box-column-content">
                <div class="box-row">
                  <label style="width: 180px">{{
                    "customerGroups.enterPriceOption" | translate
                  }}</label>
                  <dx-radio-group
                    width="276"
                    [items]="radioList"
                    layout="horizontal"
                    displayExpr="label"
                    valueExpr="value"
                    [readOnly]="readOnly"
                    [(value)]="radioListValue"
                    (onValueChanged)="onRadioValueChanged($event)"
                  >
                  </dx-radio-group>
                </div>
              </div>
            </div>
            <div class="form-box">
              <div class="box-title"></div>
              <div class="flex-box-column-content">
                <div class="box-row">
                  <label style="width: 180px"
                    >{{ "customerGroups.priceName" | translate }}
                    <strong *ngIf="radioListValue">*</strong></label
                  >
                  <dx-select-box
                    formControlName="PriceId"
                    width="276"
                    displayExpr="PriceName"
                    valueExpr="PriceId"
                    [dataSource]="prices$ | async"
                    [ngClass]="{
                      'input-required':
                        radioListValue && form.value.PriceId == 0 && submitted
                    }"
                    [readOnly]="readOnly || !radioListValue"
                  ></dx-select-box>
                </div>
                <div class="box-row">
                  <label style="width: 180px">{{
                    "form-payment.discountMarkUp" | translate
                  }}</label>
                  <dx-number-box
                    [format]="format"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    formControlName="DiscountMarkup"
                    width="276"
                    [readOnly]="readOnly || !radioListValue"
                  ></dx-number-box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts [shortcuts]="shortcuts" [unicalGuid]="unicalGuid">
</wapro-keyboard-shortcuts>

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
// import DataSource from 'devextreme/data/data_source';
// import * as AspNetData from 'devextreme-aspnet-data-nojquery';

// import { environment } from '../../../../../environments/environment';
import { PipesModule } from '../../../../pipes/pipes.module';
import { ReleasedQuantityComponent } from '../released-quantity/released-quantity.component';
import { ConfirmDialogComponent } from '../../../../core/confirm-dialog/confirm-dialog.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-released-sugestion',
    imports: [
        CommonModule,
        TranslateModule,
        DxPopupModule,
        DxButtonModule,
        DxScrollViewModule,
        DxDataGridModule,
        DxCheckBoxModule,
        ConfirmDialogComponent,
        PipesModule,
        ReleasedQuantityComponent,
        DxTooltipModule,
        NgShortcutsComponent,
    ],
    templateUrl: './released-sugestion.component.html',
    styleUrl: './released-sugestion.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: [
        'isVisible',
        ' readOnly',
        'warehouseDocumentId',
        'position',
        'WarehouseDocumentPositionIdPrevious',
    ]
})
export class ReleasedSugestionComponent implements OnInit, OnDestroy {
  @Output() onClosing = new EventEmitter();

  isVisible: boolean = false;
  readOnly;
  warehouseDocumentId;
  position;
  widthWindow = '90%';
  heightWindow: number = 600;
  title: string = 'Propozycje dostaw do przepięcia';
  unicalGuid;

  isTheSameSupplier: boolean = false;
  isTheSamePrice: boolean = false;
  IsShowLower: boolean = false;
  isChangeQuantity: boolean = false;
  isConfirmDialog: boolean = false;
  dataSource;
  shortcuts: ShortcutInput[] = [];
  heightGrid = 460;
  WarehouseDocumentPositionIdPrevious;
  maxAmount = 0;
  insertAll = 0;

  constructor(
    public event: EventService,
    public appServices: AppServices,
    public cd: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('form-commercial-operation.outgoingDeliveryInTheStorageUnit')
      .subscribe((text) => {
        this.title = text;
      });
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isConfirmDialog = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.insertAmount();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp(this.unicalGuid);
    sessionStorage.removeItem('dataChange');
  }

  getData() {
    this.appServices
      .getAuth(
        `warehouseDocuments/documents/${this.warehouseDocumentId}/releasedSuggestions?ObjectId=${this.position[0].WarehouseDocumentPositionId}&Amount=${this.position[0].AmountOfReleased}&IsTheSameCustomer=${this.isTheSameSupplier}&IsTheSamePrice=${this.isTheSamePrice}&IsShowLower=${this.IsShowLower}`
      )
      .subscribe(
        (data) => {
          this.maxAmount = this.position[0].AmountOfReleased;
          this.insertAll = 0;
          this.dataSource = data.data;

          if (sessionStorage.getItem('dataChange')) {
            const dataChange = JSON.parse(sessionStorage.getItem('dataChange'));
            dataChange.forEach((element) => {
              this.dataSource.forEach((item) => {
                if (
                  element.WarehouseDocumentPositionId ==
                  item.WarehouseDocumentPositionId
                ) {
                  item.InsertAmount = element.InsertAmount;
                }
              });
            });
          } else {
            this.dataSource.forEach((element) => {
              element.InsertAmount = 0;
            });
          }

          this.dataSource.forEach((element) => {
            this.maxAmount -= element.InsertAmount;
            this.insertAll += element.InsertAmount;
          });

          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );

    // setTimeout(() => {
    //   this.dataSource = new DataSource({
    //     store: AspNetData.createStore({
    //       key: 'WarehouseDocumentPositionId',
    //       onBeforeSend: this.event.onBeforeSendDataSource,
    //       loadUrl: `${environment.domain}warehouseDocuments/documents/${this.warehouseDocumentId}/releasedSuggestions`,
    //       loadParams: this.getLoadParams(),
    //       onAjaxError: this.event.onAjaxDataSourceError,
    //       onLoaded(result) {
    //       },
    //     }),
    //     reshapeOnPush: true,
    //   });
    // }, 5000);
  }

  getLoadParams() {
    let obj: any = {};

    obj.ObjectId = this.position[0].WarehouseDocumentPositionId;
    obj.Amount = this.position[0].AmountOfReleased;

    obj.IsTheSameCustomer = this.isTheSameSupplier;
    obj.IsTheSamePrice = this.isTheSamePrice;
    obj.IsShowLower = this.IsShowLower;
    return obj;
  }

  ngOnInit() {
    this.readOnly = this.event.readOnly;
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit();
    }
  }

  closeWindow() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex = 0;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onRowDblClick() {}

  focusedSelected: any = [];
  onFocusedRowChanged = (e) => {
    if (e && e.row) {
      this.focusedSelected = [e.row.data];
    }
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e) {
    if (
      e.event.keyCode == 113 ||
      e.event.keyCode == 32 ||
      e.event.keyCode == 27
    ) {
      e.event.preventDefault();
    }
  }

  insertAmount() {
    this.isChangeQuantity = true;
    this.cd.detectChanges();
  }

  onInserted(e) {
    this.maxAmount = this.position[0].AmountOfReleased;
    this.insertAll = 0;
    this.dataSource.forEach((element) => {
      if (
        element.WarehouseDocumentPositionId ==
        this.focusedSelected[0].WarehouseDocumentPositionId
      ) {
        element.InsertAmount = e;
      }
      this.maxAmount -= element.InsertAmount;
      this.insertAll += element.InsertAmount;
    });

    sessionStorage.setItem('dataChange', JSON.stringify(this.dataSource));
  }

  async yesConfirmDialog() {
    let index = 0;
    for (const item of this.dataSource) {
      await this.waitOperation(
        this.WarehouseDocumentPositionIdPrevious,
        item,
        this.position
      );

      index++;
      if (index == this.dataSource.length) {
        this.isConfirmDialog = false;
        this.isVisible = false;
      }
    }

    this.cd.detectChanges();
  }

  async waitOperation(
    WarehouseDocumentPositionIdPrevious,
    dataSourcePosition,
    wzPosition
  ) {
    await this.skip(
      WarehouseDocumentPositionIdPrevious,
      dataSourcePosition,
      wzPosition
    );
  }

  skip(WarehouseDocumentPositionIdPrevious, dataSourcePosition, wzPosition) {
    return new Promise((resolve) => {
      const data = {
        WarehouseDocumentPositionOldId: WarehouseDocumentPositionIdPrevious,
        WarehouseDocumentPositionOutcomeId:
          wzPosition[0].WarehouseDocumentPositionId,
        WarehouseDocumentPositionNewId:
          dataSourcePosition.WarehouseDocumentPositionId,
        Amount: dataSourcePosition.InsertAmount,
      };
      resolve(true);
      this.appServices
        .putAuth(
          `warehouseDocuments/documents/${this.warehouseDocumentId}/changeReleased`,
          data
        )
        .subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    });
  }
}

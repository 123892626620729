import { Socket } from './../../socket.model';
import { NestOperation } from './../../nest/nest.model';
import { TableService } from 'src/app/core/table-user-extension/table-service.service';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  HostListener,
  SimpleChanges,
  ViewChild,
  inject,
  ElementRef,
  signal,
  computed,
  WritableSignal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { DxDataGridComponent, DxTabPanelComponent } from 'devextreme-angular';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestService } from 'src/app/nest-service.service';
import { Subscription, forkJoin, map, tap, of, take, switchMap } from 'rxjs';
import { GlobalDate } from 'src/app/core/date-range/GlobalDate';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AdditionalTable,
  DoubleClickResult,
  RefreshCardResult,
  SingleRecordMode,
} from 'src/app/event.model';
import {
  FocusedRowChangedEvent,
  SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import { DxTreeViewComponent } from 'devextreme-angular';
import { CyclicalType, Task } from './tasks.model';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { AdditionalOperationComponent } from 'src/app/core/additional-operation/additional-operation.component';
import { HelpService } from 'src/app/help-service.service';
import { EDokumentsService } from 'src/app/libraries/e-dokuments/e-dokuments.service';
import { TASK_TYPES_KEY } from 'src/app/consts';
import { TasksService } from './tasks.service';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';
import { NotesService } from 'src/app/core/notes/notes.service';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  inputs: [
    'dropDownBoxMode',
    'selectedId',
    'readOnly',
    'isPopupMode',
    'isVisible',
  ],
  standalone: false,
})
export class TasksComponent implements OnInit {
  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  @Output('onClosing') onClosing = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('gridBank') gridBank: DxDataGridComponent;
  @ViewChild('workersGrid') workersGrid: DxDataGridComponent;
  @ViewChild('contactsGrid') contactsGrid: DxDataGridComponent;
  @ViewChild('leftPanel') leftPanel: ElementRef;
  @ViewChild('rightPanel') rightPanel: ElementRef;
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  @ViewChild('bottomSidePanel') bottomSidePanel: ElementRef;
  @ViewChild('statusTree') statusTreeView: DxTreeViewComponent;
  @ViewChild('typeTree') typeTreeView: DxTreeViewComponent;
  @ViewChild('reports') reports: ReportsComponent;
  helpService = inject(HelpService);
  selectedAmount = signal(0);
  copyText: string = '';
  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'CRM_TASK';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Crm_zadanie';
  readonly EXTERNAL_FIELDS_KEY = `userExtensionsCrm_zadanie`;
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  viewUserDefinitionSub: Subscription = null;
  pageSize: number = 100;
  singleRecordMode: SingleRecordMode = null;
  additionalTables: AdditionalTable[] = [];
  isVisible = false;
  isPopupMode = false;
  isShowMoreDetails = false;
  componentVisible: boolean = false;
  showBottomPanel: boolean = false;
  readOnly = false;
  openCustomDropDown: boolean = false;
  mobileOperationsVisible: boolean = false;
  filterMobileVisible: boolean = false;
  dropDownBoxMode = false;
  selectedId: number = null;
  dataSource: any;
  selectedRows = [];
  isGridBoxOpened: boolean = false;
  isDelete: boolean = false;
  doubleClickResult: DoubleClickResult = 'Edycja';

  filterCriteria: any[] = [
    { value: 'subject', label: 'temat (intuicja)' },
    { value: 'PctOfCompletion', label: 'realizacja', type: 'number' },
    { value: 'taskDate', label: 'data wprowadzenia', type: 'data' },
  ];
  contextMenuOperationsMobileSignal = computed(() => {
    return [
      {
        name: this.translate.instant('selectMultiple'),
        icon: 'icon checkAll',
        itemIndex: 99,
      },
    ];
  });
  modeSelectionMobile: string = 'none';
  isContextMenuMobile: boolean = false;
  isDetails: boolean = false;
  articleName = [];
  articlesList = [];
  serviceTaskCustomerId: number = null;
  articlesListPom = [];
  // filterValue = '';
  displayValue: string = 'nazwa';
  // valueCriteria = 'subject';
  shortcuts: ShortcutInput[] = [];
  articleIdFocused = null;
  // order: string = 'ASC'; // asc/desc
  orderby: string = 'subject';
  width = 280;
  heightGrid: number = 200;
  heightBottomGrid = 130;
  widthLeftPanel = 280;
  heightBottomPanel: number;
  focusedRowIndexContact: number = -1;
  initialLocalStorageData = null;
  onlySelected = false;
  localStorageData: any = {
    filter: {
      value: '',
      order: 'ASC',
      orderBy: 'subject',
    },
    mainPanelWidth: 'calc(100% - 300px)',
    bottomPanelHeight: null,
    bottomPanelVisible: true,
    selectionTab: 0,
    columns: null,
    filterValue: null,
    filterCriteria: null,
    taskTypeName: '',
    taskStatusName: '',
    taskStatusId: '',
    taskStatusCode: '',
    taskTypeId: '',
    selectedAdditionalTable: null,
    advancedFilter: null,
    skipUserGuide: false,
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 300,
    },
    params: [
      {
        value: false,
        name: 'showFutureOrders',
      },
    ],
  };

  details: any = null;
  cyclicalTypes: CyclicalType[] = [];

  myTimer;
  articleEdit = [];
  taskTypes = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  additionalOperationList = signal([]);
  isPerToAdd = signal(false);
  isPerToEdit = signal(false);
  isPerToDelete = signal(false);
  isPerToShow = signal(false);
  isPerToAddServiceOrder = signal(false);
  isPerToShowServiceOrder = signal(false);
  isPerToEdocuments = signal(false);
  contextMenuSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: !this.isPerToAdd(),
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled: !this.isPerToEdit(),
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled: !this.isPerToDelete(),
      },
      {
        text: this.translate.instant('buttons.show'),
        icon: 'icon absui-icon--form-items',
        itemIndex: 3,
        disabled: !this.isPerToShow(),
      },
      {
        text: this.translate.instant('inventory.duplicate'),
        icon: 'icon absui-icon--files-copy',
        itemIndex: 7,
        disabled: !this.isPerToAdd(),
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        icon: '',
        itemIndex: 4,
        items: this.additionalOperationList(),
      },
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 5,
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 6,
        items: [],
      },
      {
        text: this.translate.instant('showSelected'),
        icon: '',
        itemIndex: 8,
        items: [],
      },
      {
        text: this.translate.instant('menu.eDocuments'),
        itemIndex: 30,
        disabled: !this.isPerToEdocuments(),
        visible:
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO',
      },
      {
        text: this.translate.instant('tasks.addServiceOrder'),
        disabled: !this.isPerToAddServiceOrder(),
        itemIndex: 31,
        visible:
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO',
      },
      {
        text: this.translate.instant('tasks.showServiceOrder'),
        disabled: !this.isPerToShowServiceOrder(),
        itemIndex: 32,
        visible:
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO',
      },
    ];
  });

  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        name: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: false,
      },
      ...this.contextMenuSignal(),
    ];
  });
  isPrintDisabled = signal<boolean>(true);
  customerCardPermission: WritableSignal<boolean> = signal<boolean>(false);
  contextMenuContactsSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--trash',
        itemIndex: 2,
      },
      {
        text: this.translate.instant('recordCard.popupTitle'),
        itemIndex: '1111_000_KARTA_KONTR',
        disabled: !this.customerCardPermission(),
      },
    ];
  });
  contextMenuWorkersSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--trash',
        itemIndex: 2,
      },
    ];
  });
  taskStatuses = [];
  isVisibleContractors: boolean = false;
  isVisibleWorkers: boolean = false;
  isDeleteWorker: boolean = false;
  isDeleteContact: boolean = false;
  columnsChooserVisible: boolean = false;
  dateRangeVisible: boolean = false;
  externalFieldColumns = [];
  redirectedTaskId: number = null;
  bottomPanelTabs = [
    'Kontakty',
    'Pracownicy',
    'Opis Zadania',
    'Notatki',
    'Tabela dodatkowa',
  ];
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();
  route$: Subscription;
  type: string = '';
  typeId: number = null;
  loadChildComponent: boolean = false;
  refreshCardResult: RefreshCardResult = 'Tak';
  focusOnLastRow: RefreshCardResult = 'Nie';
  perEksportExcel = false;
  paramsObj = {};
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };
  eDocumentsVisible: boolean = false;
  isRecordCardVisible: boolean;
  recordCardMode: RecordCardMode;
  recordCardObjectId: number;
  addingModeRoute = '';
  newRouteVisible: boolean = false;
  contextOfAddingRoute = '';
  additionalFieldTaskId: number = null;
  isMobileReportsPopupVisible: boolean;

  translate = inject(TranslateService);
  showFutureOrdersList = [
    { value: true, label: this.translate.instant('yes') },
    { value: false, label: this.translate.instant('no') },
  ];
  showFutureOrders = signal<boolean>(false);

  constructor(
    private appService: AppServices,
    public event: EventService,
    public edocs: EDokumentsService,
    public cd: ChangeDetectorRef,
    public tasks: TasksService,
    private activatedRoute: ActivatedRoute,
    public nestService: NestService,
    private router: Router,
    private tableService: TableService,
    private dynamicFilterService: DynamicFilterService,
    public notesService: NotesService
  ) {
    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });

    this.isPerToEdocuments.set(
      !this.event.isDisabledEDocuments('CrmTaskRelated')
    );
    this.redirectedTaskId =
      this.router.getCurrentNavigation().extras.state?.TaskId || null;
    if (this.redirectedTaskId) {
      this.articleEdit = [this.redirectedTaskId];
      this.setSingleRecordVisible('add');
    }

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests;

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.perEksportExcel = this.event.checkPermissions('EksportExcel');

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });
  }

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') {
      e.items = [];
    }
  }

  pinnedViewsSub: Subscription = null;

  async ngOnInit() {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.getUserParameters();

    this.perABD = this.event.checkPermissionsBtn(
      'DCrm',
      'ECrm',
      'UCrm',
      'OCrm'
    );

    this.perABDServiceOrders = this.event.checkPermissionsBtn(
      'SerwisZlecD',
      'SerwisZlecE',
      'SerwisZlecU',
      'SerwisZlecO'
    );

    this.isPerToAdd.set(this.perABD.addBtn);
    this.isPerToEdit.set(this.perABD.editBtn);
    this.isPerToDelete.set(this.perABD.deleteBtn);
    this.isPerToShow.set(this.perABD.showBtn);

    this.isPerToAddServiceOrder.set(this.perABDServiceOrders.addBtn);
    this.isPerToShowServiceOrder.set(this.perABDServiceOrders.showBtn);

    this.route$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.type = params.type;
      if (params.date === 'today') {
        this.event.setCurrentDate(new GlobalDate('currentDay'), false);
      }
    });

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 210;
    }
    if (this.isPopupMode) {
      this.heightGrid = 300;
    }
    if (this.event.isAdditionalTableAvailable()) {
      this.additionalTables = this.event.getTablesByGroupCode(this.USER_EXTENSIONS_GROUP_CODE);
    }
    this.getExternalFieldsDefinition();
    //this.getData();

    await this.getTaskTypes();
    if (this.type) {
      const taskType = this.taskTypes.find((el) => el.Name === this.type);
      if (taskType) {
        this.typeId = taskType.TypeId;
      }
    }
    this.getCyclicalTypes();
    this.getTranslations();
    this.getViewConfigurations();
    this.getStatuses();
    this.deselectAllRecords();
    this.closeNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      'XGCLA'
    );
    this.findSocketAndRun('XGCLO1');

    setTimeout(() => {
      this.loadChildComponent = true;

      this.pinnedViewsSub = this.dynamicFilterService
        .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
        .subscribe({
          next: (data) => {
            if (!data) {
              return;
            }
            if (this.localStorageData?.advancedFilter) {
              this.localStorageData.advancedFilter.PinnedFilters =
                data.pinnedFilters;
            } else {
              this.localStorageData.advancedFilter = {
                PinnedFilters: data.pinnedFilters,
              };
            }
          },
        });
    }, 1000);

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.isPopupMode && !this.isVisible) {
          return;
        }
        if (this.dropDownBoxMode) {
          return;
        }
        if (
          this.isSingleRecordVisible ||
          this.isExternalTableVisible ||
          this.isShortcutTableOpened ||
          this.isDeleteContact ||
          this.isDeleteWorker ||
          this.isDelete
        ) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });
    this.checkRecordCardPermission();
  }

  checkRecordCardPermission(): void {
    const additionalOperations = this.event.decryptString(
      localStorage.getItem('appAdditionalOperationsPermissions')
    );
    const CRM_BRW = additionalOperations.find(
      (op) => op.GroupCode === 'CRM_BRW'
    );
    this.customerCardPermission.set(
      CRM_BRW.Permissions.find((x) => x.Shortcut === 'Ctrl+Shift+[')
        ? true
        : false
    );
  }

  selectedOwnOperation: any = null;
  runOwnAdditionalOperation(operation: any) {
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(
      `XGCLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`
    );
  }

  runInnerOwnOperation() {
    const data = this.event.prepareAdditionalOperationData(
      this.selectedOwnOperation,
      this.currentDate,
      this.selectedRows.length == 0 ? this.articleEdit[0] : null
    );
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if (this.selectedOwnOperation?.RefreshList) {
          this.getData();
        }
        this.findSocketAndRun(
          `XGCLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`
        );
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  copyPopupVisible = false;
  additionalOperationShortcutSub: Subscription = null;
  contextMenuClick = (e) => {
    if (e.itemData.IsOuter) {
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.perABD.addBtn) {
          this.showNewRow();
        }
        break;
      case 1:
        if (!this.readOnly && this.perABD.editBtn && this.articleEdit.length) {
          this.findSocketAndRun('XGCLYP1');
        }
        break;
      case 2:
        if (
          !this.readOnly &&
          this.perABD.deleteBtn &&
          this.articleEdit.length
        ) {
          this.isDelete = true;
        }
        break;
      case 3:
        if (!this.readOnly && this.perABD.showBtn) {
          this.setSingleRecordVisible('show');
        }
        break;

      case 7:
        if (!this.readOnly && this.articleEdit.length) {
          this.copyPopupVisible = true;
        }
        break;

      case 5:
        this.skipSendSelectionEvent = true;
        // this.gridBank.instance.selectAll();
        this.selectAll();
        break;

      case 6:
        this.skipSendSelectionEvent = true;
        this.selectedRows = [];
        this.deselectAllRecords();
        break;
      case 8:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case 30:
        this.eDocumentsVisible = true;
        this.cd.detectChanges();
        break;
      case 31:
        const taskType = this.taskTypes.find(
          (el) => el.TypeId == this.articlesListPom[0].TypeId
        );
        if (taskType.Name !== 'Zlecenie serwisowe') {
          this.event.showNotification(
            'error',
            this.translate.instant('tasks.cantCreateServiceOrder')
          );
          return;
        }
        this.orderServiceMode = 'add';
        this.serviceTaskId = this.articlesListPom[0]?.TaskId;
        this.serviceTaskCustomerId =
          this.articlesListPom[0]?.CustomerId || null;
        this.isServiceOrderVisible.set(true);
        break;
      case 32:
        this.orderServiceMode = 'show';
        const showTaskType = this.taskTypes.find(
          (el) => el.TypeId == this.articlesListPom[0].TypeId
        );
        if (showTaskType.Name !== 'Zlecenie serwisowe') {
          this.event.showNotification(
            'error',
            this.translate.instant('tasks.notPossibleShowServiceOrder')
          );
          return;
        }
        this.appService
          .getAuth(`services/orders`, {
            TaskId: this.articlesListPom[0]?.TaskId,
          })
          .subscribe({
            next: (res) => {
              if (!res?.data?.length) {
                this.event.showNotification(
                  'error',
                  this.translate.instant('tasks.notPossibleShowServiceOrder')
                );
                return;
              }
              res.data.reverse();
              this.orderServiceId = [res.data[0].ServiceOrderId];
              this.isServiceOrderVisible.set(true);
            },
            error: (error) => {
              this.event.httpErrorNotification(error);
            },
          });
        break;
      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridBank.instance.getDataSource().items()[
          this.focusedRowIndex
        ];
        const id = row.TaskId;
        if (!this.selectedRows.includes(id)) {
          this.gridBank.instance.selectRows(id, true);
        } else {
          this.gridBank.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.isDetails = true;
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;
      case '1111_000_CRMGRUPZM':
        // grupowa zmiana danych
        // this.findSocketAndRun("XGCLEXCRM_BRWCRMGRUPZM_E1")
        break;
      case '0011_000_POLADODGRUP':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };
            this.additionalFieldTaskId = null;
            this.findSocketAndRun('XGCLEXCRM_BRWPOLADODGRUP_E1');
          }
        }
        break;
      case '0011_000_POLADOD':
        if (!this.readOnly && this.articleEdit.length) {
          this.additionalFieldsList = this.articlesListPom[0];
          this.additionalFieldTaskId = this.articleEdit[0];
          this.findSocketAndRun('XGCLEXCRM_BRWPOLADOD_E1');
        }
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
      case '1111_000_ROUTEADD':
        if (
          !this.readOnly &&
          (this.selectedRows.length != 0 || this.articlesListPom.length != 0)
        ) {
          this.contextOfAddingRoute = 'CRM';
          this.newRouteVisible = true;
          if (this.selectedRows.length != 0) {
            this.addingModeRoute = 'multiple';
          } else if (
            this.articlesListPom.length != 0 &&
            this.selectedRows.length == 0
          ) {
            this.addingModeRoute = 'single';
          }
        }
        break;
    }
  };

  onClosingNewRoute() {
    this.newRouteVisible = false;
    this.addingModeRoute = '';
    this.contextOfAddingRoute = '';
    this.cd.detectChanges();
  }

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
    if (this.additionalFieldTaskId) {
      this.findSocketAndRun('XGCLEXCRM_BRWPOLADOD_E2');
    } else {
      this.findSocketAndRun('XGCLEXCRM_BRWPOLADODGRUP_E2');
    }
  };

  onInsertedUserExtensions = (e) => {
    let uri = `crm/additionalOperations/setAdditionalFields`;
    if (this.additionalFieldTaskId) {
      uri += `?objectId=${this.additionalFieldTaskId}`;
    }

    this.appService.putAuth(uri, e).subscribe(
      () => {
        // this.visibleUserExtensions = false;
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };
        this.dataSource.reload();
        this.cd.detectChanges();
        if (this.additionalFieldTaskId) {
          this.findSocketAndRun('XGCLEXCRM_BRWPOLADOD_E2');
        } else {
          this.findSocketAndRun('XGCLEXCRM_BRWPOLADODGRUP_E2');
        }
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  };

  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onGridContentReady(e) {
    this.onContentReady(e);

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  contextContactMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.articlesListPom.length) {
          this.isVisibleContractors = true;
        }
        break;
      case 2:
        if (!this.readOnly && this.focusedRowIndexContact > -1) {
          this.isDeleteContact = true;
        }
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractorContact');
        break;
    }
    this.cd.detectChanges();
  };

  contextWorkerMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly && this.articlesListPom.length) {
          this.isVisibleWorkers = true;
        }
        break;
      case 2:
        if (!this.readOnly && this.focusedRowIndexWorkers > -1) {
          this.isDeleteWorker = true;
        }
        break;
    }
  };

  getCyclicalTypes() {
    const localCyclicalTypes: string =
      localStorage.getItem('tasksCyclicalTypes');
    if (localCyclicalTypes) {
      this.cyclicalTypes = this.event.decryptString(localCyclicalTypes);
      return;
    }
    this.appService.getAuth('crm/tasks/cyclicalTypes').subscribe(
      (res: { totalCount: number; data: CyclicalType[] }) => {
        this.cyclicalTypes = res.data;
        localStorage.setItem(
          'tasksCyclicalTypes',
          this.event.encryptString(res.data)
        );
      },
      (error) => {
        this.event.httpErrorNotification(error);
        localStorage.removeItem('tasksCyclicalTypes');
      }
    );
  }

  getTranslations() {
    this.translate
      .get('tasks.copyText')
      .subscribe((text) => (this.copyText = text));

    this.translate.get('tasks.subjectIntuition').subscribe((text: string) => {
      this.filterCriteria[0].label = text;
      this.displayValue = text;
    });

    this.translate.get('tasks.realisation').subscribe((text: string) => {
      this.filterCriteria[1].label = text.toLowerCase();
    });

    this.translate.get('tasks.insertDate').subscribe((text: string) => {
      this.filterCriteria[2].label = text.toLowerCase();
    });

    this.translate
      .get('constractors.contacts')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));

    this.translate
      .get('menu.workers')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));

    this.translate
      .get('tasks.taskDescription')
      .subscribe((text) => (this.bottomPanelTabs[2] = text));

    this.translate
      .get('notes.notes')
      .subscribe((text) => (this.bottomPanelTabs[3] = text));

    if (this.additionalTables.length) {
      this.translate.get('articles.externalTable').subscribe((text) => {
        this.bottomPanelTabs[4] = text;
      });
    }
  }

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  getViewConfigurations = () => {
    try {
      if (!localStorage.getItem('leftTaskPanel')) {
        this.appService
          .getAuth(`viewConfigurations/TaskPanel`)
          .subscribe((res) => {
            if (res.Configuration) {
              this.localStorageData = Object.assign(
                this.localStorageData,
                JSON.parse(res.Configuration)
              );

              // this.localStorageData = JSON.parse(res.Configuration);
            }

            if (
              this.localStorageData.bottomPanelVisible &&
              this.localStorageData.bottomPanelHeight == null
            ) {
              this.showBottomPanel = true;
              this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
              this.heightGrid = window.innerHeight - 435;
              this.tabPanel.height = '100%';
              this.gridBank?.instance?.updateDimensions();
            }

            const showFutureOrders = this.localStorageData.params.find(
              (el) => el.name === 'showFutureOrders'
            );
            if (showFutureOrders) {
              this.showFutureOrders.set(showFutureOrders.value);
            }
            localStorage.setItem(
              'leftTaskPanel',
              JSON.stringify(this.localStorageData)
            );
          });
      } else if (
        localStorage.getItem('leftTaskPanel') &&
        !this.dropDownBoxMode
      ) {
        setTimeout(() => {
          try {
            this.localStorageData = JSON.parse(
              localStorage.getItem('leftTaskPanel')
            );
            this.initialLocalStorageData = JSON.parse(
              localStorage.getItem('leftTaskPanel')
            );

            const showFutureOrders = this.localStorageData.params.find(
              (el) => el.name === 'showFutureOrders'
            );
            if (showFutureOrders) {
              this.showFutureOrders.set(showFutureOrders.value);
            }

            if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
              this.getDefaultFilter();
            }
            else if (
              this.localStorageData?.advancedFilter
                ?.FilterUsersDefinitionId ||
              this.localStorageData?.advancedFilter?.IsPinned
            ) {
              this.getSavedFilterDefinitions();
            }
            if (this.localStorageData?.advancedFilter?.PinnedFilters) {
              this.dynamicFilterService.addPinnedViewsFilters(
                this.DynamicFilterDictionaryCode,
                this.localStorageData.advancedFilter.PinnedFilters
              );
            }

            if (this.localStorageData.sidePanel.width != null) {
              this.width = Number(
                this.localStorageData.sidePanel.width.replace('px', '')
              );
            }
            if (this.localStorageData.mainPanelWidth != null) {
              this.rightPanel.nativeElement.style.width =
                this.localStorageData.mainPanelWidth;
            }

            if (this.localStorageData.mainPanelWidth <= '21px') {
              this.localStorageData.sidePanel.isVisible = false;
            }

            if (
              this.additionalTables.length &&
              this.localStorageData?.selectedAdditionalTable?.TableId
            ) {
              const name = this.additionalTables.find(
                (el) =>
                  el.TableId ===
                  this.localStorageData?.selectedAdditionalTable?.TableId
              )?.Name;
              this.bottomPanelTabs[4] = name;
              this.localStorageData.selectedAdditionalTable.text = name;
            }

            if (
              this.localStorageData.bottomPanelVisible != null &&
              this.localStorageData.bottomPanelVisible
            ) {
              this.ngModelChange();
            }

            if (!this.localStorageData.bottomPanelVisible) {
              this.showBottomPanel = false;
              this.heightGrid = window.innerHeight - 210;
              this.tabPanel.height = '38px';
              this.gridBank?.instance?.updateDimensions();
            }
          } catch (err) {}
        }, 0);
        if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
          this.getDefaultFilter();
        }
        else if (
          this.localStorageData?.advancedFilter
            ?.FilterUsersDefinitionId ||
          this.localStorageData?.advancedFilter?.IsPinned
        ) {
          this.getSavedFilterDefinitions();
        }
        this.updateColumns();
        if (
          this.localStorageData.bottomPanelHeight != null &&
          this.localStorageData.bottomPanelVisible
        ) {
          this.bottomSidePanel.nativeElement.style.height =
            this.localStorageData.bottomPanelHeight;
          this.heightBottomGrid =
            Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
            45;

          this.heightGrid =
            window.innerHeight -
            Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
            200;
        }

        if (!this.localStorageData.bottomPanelVisible) {
          this.showBottomPanel = false;
          this.heightGrid = window.innerHeight - 210;
          this.tabPanel.height = '38px';
        }
        this.gridBank?.instance?.updateDimensions();
      }
      this.getData();
    } catch (e) {
      this.onInitErr = true;
    }
  };

  ngAfterViewInit() {
    this.cd.detectChanges();

    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getData();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/modul-crm');
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            this.setCustomerFromFocusedRow();
          } else {
            this.isCustomerVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex === 0) {
            this.localStorageData.sidePanel.selectedIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.shiftKey) {
            this.showNewRow();
          }

          if (!this.readOnly && e.event.shiftKey && this.articleEdit.length) {
            this.copyPopupVisible = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isPerToEdocuments()) {
            this.edocs.getToEDocuments(
              'CrmTaskRelated',
              this.articlesListPom[0]?.TaskId,
              this.articlesListPom[0]?.Subject
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.articleEdit.length == 1) {
            this.isExternalTableVisible = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.articleEdit.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.showEditArticle();
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.articleEdit.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.setSingleRecordVisible('show');
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.articleEdit.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.isDelete = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            if (this.articleEdit.length > 0) {
              this.onChoosingArticles();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.localStorageData.selectionTab = 0;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.localStorageData.selectionTab = 1;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.localStorageData.selectionTab = 2;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.additionalTables.length) {
            this.localStorageData.selectionTab = 3;
            if (!this.showBottomPanel) {
              this.ngModelChange();
            }
          }
        },
        preventDefault: true,
      }
    );

    if (!this.additionalTables.length) {
      this.bottomPanelTabs.pop();
    }

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.articleEdit.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
            }
          },
          preventDefault: true,
        });
      }
    }

    if (
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
      this.isPerToEdocuments()
    ) {
      this.bottomPanelTabs.push(this.translate.instant('menu.eDocuments'));
    }

    setTimeout(() => {
      this.loadChildComponent = true;

      if (
        !this.localStorageData.skipUserGuide &&
        this.isAutoOnboarding &&
        this.isInNormalMode()
      ) {
        this.isGuideActive = true;
      }
    }, 1000);
  }

  timeoutHandler;

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange = () => {
    this.showBottomPanel = !this.showBottomPanel;
    if (!this.dropDownBoxMode) {
      if (
        this.showBottomPanel &&
        this.localStorageData.bottomPanelHeight == null
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
        this.heightGrid = window.innerHeight - 435;
        this.tabPanel.height = '100%';
      } else if (
        this.showBottomPanel &&
        this.localStorageData.bottomPanelHeight != null
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanelHeight;
        this.heightGrid =
          window.innerHeight -
          Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
          190;
        this.tabPanel.height = '100%';
      } else {
        this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
        this.tabPanel.height = '34px';
        this.bottomSidePanel.nativeElement.style.height = `34px`;
        this.heightGrid = window.innerHeight - 210;
      }
      //this.heightBottomGrid = 190;
      this.localStorageData.bottomPanelVisible = this.showBottomPanel;
      this.gridBank?.instance?.updateDimensions();
    }
    if (this.showBottomPanel && this.articlesListPom.length && !this.details) {
      this.getDatailsData(this.articlesListPom[0].TaskId);
    }
    if (!this.showBottomPanel) {
      this.details = null;
    }
    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );

    if (!this.showBottomPanel) {
      this.isDetailOnFocus = false;
    }
  };

  ngOnDestroy() {
    this.deselectAllRecords();
    this.selectedRows = [];

    this.additionalOperationShortcutSub?.unsubscribe();
    this.route$?.unsubscribe();
    this.pinnedViewsSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();
    this.tableService.removeTableContext(this.USER_EXTENSIONS_GROUP_CODE);
    this.isAutoOnboardingSub?.unsubscribe();
    this.articleEdit = [];

    if (
      !this.dropDownBoxMode &&
      JSON.stringify(this.initialLocalStorageData) !==
        JSON.stringify(this.localStorageData)
    ) {
      localStorage.setItem(
        'leftTaskPanel',
        JSON.stringify(this.localStorageData)
      );
      const session = {
        View: 'TaskPanel',
        Configuration: JSON.stringify(this.localStorageData),
        TenantId: this.event.footerInfo.TenantId,
      };
      this.appService
        .postAuth(`viewConfigurations`, session)
        .subscribe(() => {});
    }

    this.activatedRouteSub.unsubscribe();
    this.globalNestSub.unsubscribe();
    this.currentDateSub?.unsubscribe();
  }

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }
    this.localStorageData.filter.order = 'ASC';
  }

  getOrderBy() {
    return this.localStorageData.filter.orderBy;
  }

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }

    let obj: any = {
      dateStart:
        this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '',
      dateEnd:
        this.showFutureOrders()
          ? null
          : this.event.dataFormatted({ value: this.currentDate.dateTo }) || '',
      orderBy: this.getOrderBy(),
      order: this.localStorageData.filter.order,
    };

    if (this.localStorageData.taskStatusId) {
      if (this.localStorageData.taskStatusId != '-1') {
        obj.statusCode = this.localStorageData.taskStatusCode;
        obj.statusId = this.localStorageData.taskStatusId;
      }
    }

    if (this.customerFilter.CustomerId) {
      obj.CustomerId = this.customerFilter.CustomerId;
    }

    if (this.localStorageData.taskTypeId || this.type) {
      if (this.typeId) {
        obj.typeId = this.typeId;
        this.localStorageData.taskTypeId = this.typeId.toString();
        this.localStorageData.taskTypeName = this.type;
      } else if (this.localStorageData.taskTypeId != '-1') {
        obj.typeId = this.localStorageData.taskTypeId;
      }
    }

    if (
      this.localStorageData.filter.value ||
      this.localStorageData.filter.value == 0
    ) {
      switch (this.localStorageData.filter.orderBy) {
        case 'subject':
          obj.subject = this.localStorageData.filter.value;
          break;
        case 'PctOfCompletion':
          obj.pctOfCompletion = this.localStorageData.filter.value;
          break;
        case 'taskDate':
          obj.taskDate = this.event.setCorrectDate(
            this.localStorageData.filter.value
          );
          break;
        default:
          break;
      }
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    this.paramsObj = obj;
    console.log(obj);
    return obj;
  }

  onRowClick = (e) => {
    try {
      this.articleEdit = [e.data.TaskId];
    } catch {}
  };

  indexToFocus: number;
  setFocusOnLoad = false;
  firstLoad: boolean = true;

  getData(fromSearch: boolean = false) {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'TaskId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}crm/tasks`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}crm/tasks`,
        onLoaded: (data) => {
          for (let task of data) {
            task.TypeDescription = this.getTypeDescription(task.TypeId);
          }
          if (!data.length) {
            this.articleEdit = [];
            return;
          }
          const toAdd = data.filter(
            (el) => el.IsSelected && this.selectedRows.indexOf(el.TaskId) === -1
          );
          for (let item of toAdd) {
            this.selectedRows.push(item.TaskId);
            this.skipSendSelectionEvent = true;
          }
          const toDelete = [];
          for (let id of this.selectedRows) {
            const item = data.find((el) => el.TaskId === id);
            if (item && !item.IsSelected) {
              toDelete.push(id);
            }
          }
          for (let id of toDelete) {
            this.selectedRows.splice(
              this.selectedRows.findIndex((el) => el === id),
              1
            );
            this.skipSendSelectionEvent = true;
          }

          if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
              const filters: any = this.getLoadParams();
              const totalCount: number = this.dataSource.totalCount();
              if (!totalCount) {
                return;
              }

              if (
                (filters?.order === 'ASC' && this.focusOnLastRow === 'Tak') ||
                (filters?.order === 'DESC' && this.focusOnLastRow === 'Nie')
              ) {
                this.indexToFocus = totalCount - 1;
                if (this.indexToFocus < 0) {
                  this.indexToFocus = data.length - 1;
                }
                const page = Math.floor(totalCount / this.pageSize);
                if (page > 0) {
                  this.setFocusOnLoad = true;
                  this.dataSource.pageIndex(page);
                  this.dataSource.load();
                } else {
                  this.focusedRowIndex = this.indexToFocus;
                  this.onFocusedRowChanged({
                    row: { data: data[this.focusedRowIndex] },
                  });
                  this.cd.detectChanges();
                }
              } else {
                this.focusedRowIndex = 0;
                this.onFocusedRowChanged({
                  row: { data: data[this.focusedRowIndex] },
                });
                this.cd.detectChanges();
              }
              this.searchControl?.focusInput();
            }, 200);
          }

          if (this.setFocusOnLoad) {
            this.setFocusAfterLoad(data);
          }
          if (fromSearch) {
            this.searchControl?.focusInput();
          }
        },
      }),
      reshapeOnPush: true,
      paginate: true,
    });
  }

  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({ row: { data: data[this.focusedRowIndex] } });
  }

  isAllSelected: boolean = false;
  onSelectionChanged = (e: SelectionChangedEvent) => {
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }

    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  selectAll = () => {
    this.isAllSelected = true;
    this.appService
      .postAuth(`crm/selection/documents`, this.paramsObj)
      .subscribe(({ RowNumber }) => {
        if (typeof RowNumber === 'number') {
          this.selectedAmount.set(RowNumber);
        }
        this.getData();
      });
    this.modeSelectionMobile = 'mobile';
  };

  sendRequestTimer;

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let data = [];
        for (let item of this.toSelect) {
          if (item?.TaskId) {
            data.push(item.TaskId);
          } else {
            data.push(item);
          }
        }
        data = this.event.removeDuplicates(data);

        this.event
          .selectRecords(`crm/selection/documents/select`, data)
          .then((val) => {
            if (val) {
              this.toSelect = [];
            }
          });
      }
      const items = this.dataSource.items();
      const toRemove = [];
      for (let toDes of this.toDeselect) {
        const item = items.find((el) => el.TaskId === toDes);
        if (!item) {
          toRemove.push(this.toDeselect.indexOf(toDes));
        }
      }
      for (let i = toRemove.length - 1; i >= 0; i--) {
        this.toDeselect.splice(toRemove[i], 1);
      }
      if (this.toDeselect.length) {
        let data = [];
        for (let item of this.toDeselect) {
          if (item?.TaskId) {
            data.push(item.TaskId);
          } else {
            data.push(item);
          }
        }
        data = this.event.removeDuplicates(data);
        this.selectedAmount.set(this.selectedAmount() - data.length);
        if (this.selectedAmount() < 0) {
          this.selectedAmount.set(0);
        }
        this.event
          .selectRecords(`crm/selection/documents/unselect`, data)
          .then((val) => {
            if (val) {
              this.toDeselect = [];
            }
          });
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  // pojedyncze
  selectItemTaskStatus = (e) => {
    this.localStorageData.taskStatusName =
      e.itemData.StatusId !== -1 ? e.itemData.Description : '';
    this.localStorageData.taskStatusCode = e.itemData.Code || -1;
    this.localStorageData.taskStatusId = e.itemData.StatusId || -1;
    // this.getData();
  };

  // tree
  selectItemTaskType = (e) => {
    this.localStorageData.taskTypeName =
      e.itemData.TypeId !== -1 ? e.itemData.Name : '';
    this.localStorageData.taskTypeId = e.itemData.TypeId || -1;
    this.type = null;
    this.typeId = null;
    // this.getData();
  };

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  }

  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle(true);
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle();
  }

  resizingBottom = (e) => {
    let height;
    this.showBottomPanel = true;
    this.tabPanel.visible = this.showBottomPanel;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '230px';
    if (height < 235) {
      height = 235;
    }
    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 45;
    this.tabPanel.height = '100%';
    this.heightGrid = window.innerHeight - height - 200;
    this.localStorageData.bottomPanelHeight = `${height}px`;
    this.gridBank?.instance?.updateDimensions();
    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  };

  onInserted = (e) => {
    this.dataSource.reload().then(() => {
      const index = this.gridBank.instance.getRowIndexByKey(e.TaskId);
      this.focusedRowIndex = index || 0;
      this.gridBank.instance.focus();
      if (this.localStorageData.bottomPanelVisible) {
        this.getDatailsData(this.articleIdFocused);
      }
    });
  };

  setArticleLock = () => {
    this.setSingleRecordVisible('edit');
  };

  onFocusedRowChanged = (e) => {
    if (!e.row) {
      return;
    }

    this.isDetailOnFocus = false;
    this.articleEdit = [];
    this.articleEdit.push(e.row.data.TaskId);

    this.articlesListPom = [];
    this.articlesListPom.push(e.row.data);

    this.details = null;

    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }

    this.myTimer = setTimeout(() => {
      this.articleIdFocused = e.row.data.TaskId;
      if (this.localStorageData.bottomPanelVisible) {
        this.getDatailsData(this.articleIdFocused);
      }
      this.tableService.addTableContext({
        id: this.articleIdFocused,
        idParentObject: e.row.data,
        tableName: this.USER_EXTENSIONS_GROUP_CODE,
      });
    }, 300);
  };

  onSelectionTabChanged = () => {
    try {
      if (this.articleIdFocused) {
        if (this.details === null) {
          this.getDatailsData(this.articleIdFocused);
        }
        localStorage.setItem(
          'leftTaskPanel',
          JSON.stringify(this.localStorageData)
        );
      }
    } catch (err) {}
  };

  @ViewChild('eDocumentss') eDocumentss;
  getDatailsData(id: number) {
    if (!this.showBottomPanel || !id) {
      return;
    }

    if (
      this.bottomPanelTabs[this.localStorageData?.selectionTab] ===
      this.translate.instant('menu.eDocuments')
    ) {
      this.eDocumentss.getDocuments();
      this.cd.detectChanges();
    }

    this.appService.getAuth(`crm/tasks/${id}`).subscribe(
      (res) => {
        this.details = res;
        this.cd.detectChanges();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  }

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRows = [];
      this.onChoosed.emit(null);
    }
  };

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode && !this.isPopupMode) {
      if (this.doubleClickResult === 'Edycja') {
        this.showEditArticle();
      } else {
        this.setSingleRecordVisible('show');
      }
    } else {
      this.onChoosingArticles();
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    this.articleName = [];
    if (changes.selectedId?.currentValue) {
      this.getData();
    }
  }

  onChoosingArticles = () => {
    if (!this.readOnly) {
      this.articlesList = this.articlesListPom;
      this.articleName = this.selectedRows[0];
      this.onChoosed.emit(this.articlesList[0]);
      this.onClosing.emit(true);
      this.isGridBoxOpened = false;
    }
  };

  openedChange = (e) => {
    if (e) {
      try {
        setTimeout(() => {
          this.gridBank.instance.focus();
        }, 500);
      } catch (err) {}
    }
  };

  onItemClick(e) {
    this.localStorageData.filter.orderBy = e.itemData.value;
    this.displayValue = e.itemData.label;
  }

  getStatuses = () => {
    const localStatuses: string = localStorage.getItem('crmTaskGridStatuses');
    if (localStatuses) {
      this.taskStatuses = this.event.decryptString(localStatuses);
      return;
    }
    this.appService.getAuth(`crm/tasks/status`).subscribe(
      (res) => {
        res.data.forEach((element) => {
          element.parentId = -1;
        });
        res.data.unshift({
          Description: 'Status',
          StatusId: -1,
          expanded: true,
        });
        this.taskStatuses = res.data;
        localStorage.setItem(
          'crmTaskGridStatuses',
          this.event.encryptString(this.taskStatuses)
        );
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        localStorage.removeItem('crmTaskGridStatuses');
      }
    );
  };

  getTaskTypesDef() {
    const localData = this.event.getSessionStorageData(TASK_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`crm/taskTypes`).pipe(
        map((res) => res.data || []),
        tap((data) => {
          this.event.saveSessionStorageData(TASK_TYPES_KEY, data);
        })
      );
    }
  }

  getTaskTypes = async () => {
    return new Promise(async (resolve) => {
      try {
        this.taskTypes = await this.tasks.getTaskTypes();
        this.cd.detectChanges();
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    });

    return new Promise((resolve) => {
      const title = this.translate.instant('filterPanel.type');
      this.getTaskTypesDef().subscribe({
        next: (res) => {
          res.forEach((element) => {
            element.parentId = -1;
          });
          res.unshift({ Name: title, TypeId: -1, expanded: true });
          this.taskTypes = res;
          this.cd.detectChanges();
          resolve(true);
        },
        error: (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
          localStorage.removeItem('crmTaskTypes');
          resolve(false);
        },
      });
    });
  };

  getSortIcon() {
    return this.localStorageData.filter.order === 'ASC'
      ? 'icon absui-icon--sort-ascending'
      : 'icon absui-icon--sort-descending';
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit(true);
      this.componentVisible = false;
    }
  }

  focusedRowIndex;
  focusedRowIndexWorkers;
  timerSel: any;
  holdDelayInMs: number = 200;
  isHold: boolean = false;
  isQuickPress: boolean = true;
  skipSendSelectionEvent = false;
  toDeselect = [];
  toSelect = [];
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.localStorageData.filter.orderBy
    );

    this.localStorageData.filter.value = this.event.setFilterValueBySearchKey(
      this.localStorageData.filter.value,
      key,
      orderByItem.type === 'data'
    );

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.localStorageData.filter.value) !==
          this.paramsObj[this.localStorageData.filter.orderBy]
        ) {
          this.getData();
        }
        return;
      }
      this.getData();
    }, 300);
  }

  onKeyDown(e) {
    if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'O' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'E' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (this.isPerToEdocuments()) {
        this.edocs.getToEDocuments(
          'CrmTaskRelated',
          this.articlesListPom[0]?.TaskId,
          this.articlesListPom[0]?.Subject
        );
      }
    } else if (e.event.key === 'F7') {
      e.event.preventDefault();
      if (e.event.shiftKey) {
        this.setCustomerFromFocusedRow();
      } else {
        this.isCustomerVisible = true;
      }
      this.cd.detectChanges();
    } else if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.gridBank.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.selectedRows = [];
      this.deselectAllRecords();
    } else if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  closeConfirm() {
    this.isDelete = false;
    setTimeout(() => {
      if (this.gridBank) {
        this.gridBank.instance.focus();
      }
    }, 300);
  }

  // delete = (SocketCode: string = null) => {
  //   const el = this.gridBank.instance
  //     .getDataSource()
  //     .items()
  //     .find((el, i) => this.focusedRowIndex === i);
  //   this.appService.deleteAuth(`crm/tasks/${el.TaskId}`).subscribe(
  //     (res) => {
  //       this.getData();
  //       if (SocketCode) {
  //         this.findSocketAndRun(SocketCode);
  //       }
  //     },
  //     (error) => {
  //       this.event.showNotification('error', JSON.parse(error).detail);
  //     }
  //   );
  // };
  delete = () => {
    const el = this.gridBank.instance
      .getDataSource()
      .items()
      .find((_el, i) => this.focusedRowIndex === i);
    this.isDelete = false;

    if (this.selectedRows.length > 0) {
      let array = [];

      this.selectedRows.forEach((field) => {
        array.push(this.appService.deleteAuth(`crm/tasks/${field}`));
      });

      forkJoin(array).subscribe(
        () => {
          this.skipSendSelectionEvent = true;
          this.selectedRows = [];
          this.getData();
          this.cd.detectChanges();

          this.findSocketAndRun('XGCLYU2');
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
    } else {
      this.appService.deleteAuth(`crm/tasks/${el.TaskId}`).subscribe(
        () => {
          this.getData();
          this.findSocketAndRun('XGCLYU2');
          this.cd.detectChanges();
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
    }
  };

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  showNewRow() {
    this.findSocketAndRun('XGCLYD1');
  }

  showEditArticle() {
    this.findSocketAndRun('XGCLYP1');
  }

  showDeleteArticle() {
    this.findSocketAndRun('XGCLYU1');
  }

  // NEST
  onInitErr: boolean = false;
  activatedRouteSub: Subscription;
  componentNests: Socket;
  nest: Socket;
  nestsSub: Subscription;
  isNestRunning = false;
  nestOperation: NestOperation;
  closeNest: Socket;
  globalNestSub: Subscription;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGCLYD1':
        this.findSocketAndRun('XGCLYA1', 'add');
        break;
      case 'XGCLYD2':
        this.findSocketAndRun('XGCLYA2', 'add');
        break;
      case 'XGCLYP1':
        this.findSocketAndRun('XGCLYA1', 'edit');
        break;
      case 'XGCLYU1':
        this.findSocketAndRun('XGCLYA1', 'delete');
        break;
      case 'XGCLYU2':
        this.findSocketAndRun('XGCLYA2', 'delete');
        break;
      case 'XGCLYA1':
        if (this.nestOperation === 'add') {
          this.setSingleRecordVisible('add');
        } else if (this.nestOperation === 'edit') {
          this.setArticleLock();
        } else if (this.nestOperation === 'delete') {
          this.delete();
        }
        break;
      case 'XGCLO1':
        this.componentVisible = true;
        if (this.onInitErr) {
          setTimeout(() => {
            this.getViewConfigurations();
          }, 300);
        }
        this.findSocketAndRun('XGCLO2');
        break;
      case 'XGCLO2':
        this.searchControl?.focusInput();
        break;
      case 'XGCLEXCRM_BRWPOLADOD_E1':
        this.visibleUserExtensions = true;
        break;
      case 'XGCLEXCRM_BRWPOLADODGRUP_E1':
        this.visibleUserExtensions = true;
        break;
      case `XGCLEX${
        this.selectedOwnOperation?.GroupCode
      }_${this.selectedOwnOperation?.Name?.toUpperCase()}_E1`:
        this.runInnerOwnOperation();
        break;
      default:
        break;
    }
  }

  visibleUserExtensions = false;

  getTypeDescription(value) {
    return this.taskTypes.find((el) => el.TypeId == value)?.Name || '';
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.localStorageData.sidePanel.isVisible = false;
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
  };

  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.localStorageData.sidePanel.isVisible = true;
    this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.width = `280px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 280px)`;
  };

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }

    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  getResizeIcon() {
    return this.localStorageData.sidePanel.isVisible
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  onChoosedWorker(e) {
    if (!e) {
      return;
    }
    this.appService
      .postAuth(`crm/tasks/${this.articlesListPom[0].TaskId}/employees`, {
        TaskId: this.articlesListPom[0].TaskId,
        EmployeeId: e.EmployeeId,
      })
      .subscribe(
        () => {
          this.gridBank.instance.refresh();
          this.getDatailsData(this.articleIdFocused);
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  deleteWorker = () => {
    const el = this.workersGrid.instance
      .getDataSource()
      .items()
      .find((_el, i) => this.focusedRowIndexWorkers === i);

    this.appService
      .deleteAuth(
        `crm/tasks/${this.articlesListPom[0].TaskId}/employees/${el.EmployeeId}`
      )
      .subscribe(
        () => {
          this.getDatailsData(this.articlesListPom[0].TaskId);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  onChoosedContact(e) {
    if (!e) {
      return;
    }
    this.appService
      .postAuth(`crm/tasks/${this.articlesListPom[0].TaskId}/contacts`, {
        TaskId: this.articlesListPom[0].TaskId,
        ContactId: e.ContactId,
      })
      .subscribe(
        () => {
          this.gridBank.instance.refresh();
          this.getDatailsData(this.articleIdFocused);
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  deleteContact = () => {
    const el = this.contactsGrid.instance
      .getDataSource()
      .items()
      .find((_el, i) => this.focusedRowIndexContact === i);

    this.appService
      .deleteAuth(
        `crm/tasks/${this.articlesListPom[0].TaskId}/contacts/${el.ContactId}`
      )
      .subscribe(
        () => {
          this.getDatailsData(this.articlesListPom[0].TaskId);
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  };

  openColumnsChooser() {
    this.columnsChooserVisible = true;
  }

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  onOptionChanged(e) {
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {}
    }
  }

  openDateRangeVisible() {
    this.dateRangeVisible = true;
  }

  onDateRangeChoosed() {
    if (this.onlySelected) {
      return;
    }

    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.getData();
    }
  }

  getExternalFieldsDefinition() {
    let localData: any = localStorage.getItem(this.EXTERNAL_FIELDS_KEY);
    if (localData) {
      localData = this.event.decryptString(localData);
      localData.forEach((col) => {
        col.FieldValue = 'Field' + col.NameDefault.replace('pole', '');
      });
      this.externalFieldColumns = localData;
      return;
    }

    this.appService
      .getAuth('userExtensions/additionalFields/groups/Crm_zadanie/definitions')
      .subscribe(
        (res) => {
          localStorage.setItem(
            this.EXTERNAL_FIELDS_KEY,
            this.event.encryptString(res.data)
          );
          res.data.forEach((col) => {
            col.FieldValue = 'Field' + col.NameDefault.replace('pole', '');
          });
          this.externalFieldColumns = res.data;
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  updateColumns() {
    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.gridBank?.instance;

        if (!instance) {
          return;
        }

        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          instance.columnOption(col.caption, 'width', col.width);
          instance.columnOption(col.caption, 'fixed', col.fixed);
        });
        this.cd.detectChanges();
      }, 250);
    }
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.displayValue = e.selectedItem.label;
      this.orderby = e.selectedItem.value;
      this.localStorageData.filter.order = 'ASC';
    }
    this.localStorageData.filter.value = e.filterValue;
    this.getData(true);
  }

  resetFiltr(filterType: string) {
    if (filterType === 'taskStatusName') {
      this.localStorageData.taskStatusName = '';
      this.localStorageData.taskStatusId = '-1';
    } else if (filterType === 'taskTypeName') {
      this.localStorageData.taskTypeName = '';
      this.localStorageData.taskTypeId = '-1';
      this.type = null;
      this.typeId = null;
    } else if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    } else if (filterType === 'onlySelected') {
      this.onlySelected = false;
    }
    this.restoreTreeConfig(filterType);
    this.getData();
  }

  editRecord(e, d?) {
    if (d) {
      this.articleEdit = [d.TaskId];
    }
    e.stopPropagation();
    if (this.isPopupMode) {
      return;
    }
    if (
      !this.readOnly &&
      !this.isSingleRecordVisible &&
      this.articleEdit.length > 0 &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.showEditArticle();
    }
  }

  onItemClickTab = () => {
    this.showBottomPanel = true;
    this.localStorageData.bottomPanelVisible = true;
    if (this.localStorageData.bottomPanelHeight == null) {
      this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
      this.heightGrid = window.innerHeight - 435;
      this.tabPanel.height = '100%';
    } else if (this.localStorageData.bottomPanelHeight != null) {
      this.bottomSidePanel.nativeElement.style.minHeight =
        this.localStorageData.bottomPanelHeight;
      this.heightGrid =
        window.innerHeight -
        Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
        200;
      this.tabPanel.height = '100%';
    }
    this.gridBank?.instance?.updateDimensions();
    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  };

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle();
  };

  isExternalTableVisible: boolean = false;

  onAdditionalTableChanged(e) {
    this.localStorageData.selectedAdditionalTable = Object.assign({}, e);
    this.bottomPanelTabs[4] = e.text;

    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  shortcutTable;
  isShortcutTableOpened: boolean = false;
  isSingleRecordVisible: boolean = false;

  refreshView() {
    sessionStorage.removeItem(TASK_TYPES_KEY);
    localStorage.removeItem('crmTaskGridStatuses');
    this.getTaskTypes();
    this.getStatuses();
    this.getData();
  }

  onSingleRecordClosing() {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    this.gridBank.instance.focus();
    this.cd.detectChanges();
  }

  onSingleRecordInserted(e) {
    this.onInserted(e);
    if (this.singleRecordMode === 'add') {
      this.findSocketAndRun('XGCLYD2');
    } else if (this.singleRecordMode === 'edit') {
      this.findSocketAndRun('XGCLYP2');
    }
    this.singleRecordMode = null;
    this.isSingleRecordVisible = false;
  }

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }
  
  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'leftTaskPanel',
          JSON.stringify(this.localStorageData)
        );
        this.getData();
      }
    })
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  isDetailOnFocus = false;

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'leftTaskPanel',
      this.event.encryptString(this.localStorageData)
    );

    this.getData();
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  onCellClick(e) {
    if (this.event.deviceType != 'mobile') {
      if (!e?.row?.cells) {
        return;
      }

      for (let cell of e?.row?.cells) {
        this.event.removeFocusClassFromCell(cell.cellElement);
      }
      e.cellElement.className += ' dx-focused';
    }
  }

  currentFocusedCell = null;

  onFocusedCellChanged(e) {
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedCell = e?.cellElement[0];
  }

  onFocusElementChanged(e: boolean) {
    this.isDetailOnFocus = e;
  }

  focusedContactData;

  onFocusedRowChangedDetail(e: FocusedRowChangedEvent, tab?: string) {
    this.onFocusElementChanged(e.rowIndex > -1);
    this.focusedContactData = null;
    if (tab === 'contacts' && e?.row?.data) {
      this.focusedContactData = e.row.data;
    }
  }

  currentFocusedDetailCell = null;

  onFocusedCellDetailChanged(e) {
    if (this.currentFocusedDetailCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedDetailCell);
    }
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedDetailCell = e.cellElement[0];
  }

  isInNormalMode() {
    return !(
      this.dropDownBoxMode ||
      this.isPopupMode ||
      this.event.deviceType === 'mobile'
    );
  }

  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  runGuide() {
    this.isGuideActive = true;
  }

  onShouldReloadTypes() {
    this.getTaskTypes();
  }

  restoreTreeConfig = (filterType) => {
    if (this.onlySelected) {
      return;
    }
    setTimeout(() => {
      switch (filterType) {
        case 'taskStatusName':
          this.statusTreeView.instance.selectItem(
            this.localStorageData.taskStatusId
          );
          break;
        case 'taskTypeName':
          this.typeTreeView.instance.selectItem(
            this.localStorageData.taskTypeId
          );
          break;
      }
    }, 0);
  };

  lastTreeSelection = {
    statusTree: [],
    typeTree: [],
  };

  onTreeSelectionChanged = (e, tree) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection[tree] = selectedNodes;
    }
    e.component.selectItem(this.lastTreeSelection[tree]);

    this.getData();
  };

  openRightPanel = () => {
    if (this.width <= 21) {
      this.resizeWindowBtn();
    }
  };

  advancedFilterTemplateData = null;
  deleteFilter = false;

  clearValues(onlySelected: boolean = false) {
    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = true;
    this.cd.detectChanges();
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.resetFiltr('taskTypeName');
    this.resetFiltr('taskStatusName');
    this.resetContrahentFilterNoRefresh();
    this.getData();
    this.deleteFilter = false;
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getData();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData?.sidePanel?.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );

    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  setSearchCriteria = (orderBy: string) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.getData();
    this.cd.detectChanges();
  };

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGC');
    }
    this.gridBank.instance.focus();
  }

  closeCopyTask() {
    this.copyPopupVisible = false;
  }

  copyTask() {
    this.appService
      .postAuth(`crm/tasks/${this.articleEdit[0]}/copy`, null)
      .subscribe(
        (res) => {
          this.copyPopupVisible = false;
          this.dataSource.reload().then(() => {
            this.articleEdit = [res.Id];
            this.setArticleLock();
          });
        },
        (error) => {
          this.copyPopupVisible = false;
          this.event.httpErrorNotification(error);
        }
      );
  }

  openPrint() {}

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getData();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.deselectAllRecords();
    this.getData();
  }

  deselectAllRecords() {
    this.isAllSelected = false;
    this.selectedAmount.set(0);
    return this.event.deselectAllRecords('crm/selection/document');
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  customerFilter = {
    CustomerId: null,
    CustomerName: '',
  };
  isCustomerVisible = false;

  onChoosedCustomer = (e) => {
    if (e) {
      this.customerFilter.CustomerId = e.CustomerId;
      this.customerFilter.CustomerName = e.Name;
      this.isCustomerVisible = false;
      this.getData();
    }
  };

  resetContrahentFilter = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
    this.getData();
  };

  resetContrahentFilterNoRefresh = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
  };

  setCustomerFromFocusedRow() {
    if (
      this.articlesListPom[0] &&
      this.customerFilter.CustomerId !== this.articlesListPom[0].CustomerId
    ) {
      this.customerFilter.CustomerId = this.articlesListPom[0].CustomerId;
      this.customerFilter.CustomerName = this.articlesListPom[0].CustomerName;
      this.getData();
    }
  }

  showContextMenuMobile = (rowData: Task) => {
    if (this.selectedRows.includes(rowData.TaskId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  };

  orderServiceMode: 'add' | 'show' = 'add';
  orderServiceId: number[] = null;
  isServiceOrderVisible = signal(false);
  serviceTaskId: number = null;
  perABDServiceOrders = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };

  onServiceOrderClosing() {
    this.serviceTaskId = null;
    this.isServiceOrderVisible.set(false);
  }

  onServiceOrderInserted(_e) {
    this.isServiceOrderVisible.set(false);
  }

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridBank.instance.getDataSource().items()[
        this.focusedRowIndex
      ];
      const id = row.TaskId;
      if (!this.selectedRows.includes(id)) {
        this.gridBank.instance.selectRows(id, true);
      } else {
        this.gridBank.instance.deselectRows(id);
      }
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: any): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        try {
          this.recordCardObjectId = this.articlesListPom[0].CustomerId;
          if (!this.recordCardObjectId) {
            throw new Error();
          }
        } catch (e) {
          this.recordCardObjectId = null;
          this.event.showNotification(
            'warning',
            this.translate.instant('recordCard.noCustomerTask')
          );
        }
        break;
      case 'contractorContact':
        this.recordCardObjectId = this.focusedContactData.CustomerId;
        break;
      // case 'article':
      //   this.recordCardObjectId = this.articlesListPom[0].ProductId;
      //   break;
    }
    if (!this.recordCardObjectId) {
      return;
    }
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  onReportsPopupClosed() {
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }

  
  onShowFutureOrdersChanged(e) {
    this.showFutureOrders.set(e);

    if(!this.localStorageData.params) {
      this.localStorageData.params = [];
    }
    const index = this.localStorageData.params.findIndex(
      (el) => el.name === 'showFutureOrders'
    );
    if (index > -1) {
      this.localStorageData.params[index].value = e;
    } else {
      this.localStorageData.params.push({ value: e, name: 'showFutureOrders' });
    }

    localStorage.setItem(
      'leftTaskPanel',
      JSON.stringify(this.localStorageData)
    );
    this.getData();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  DxButtonModule,
  DxCheckBoxModule, DxContextMenuModule,
  DxDataGridModule,
  DxDropDownBoxModule, DxNumberBoxModule,
  DxPopupModule,
  DxScrollViewModule, DxTextAreaModule, DxTextBoxModule,
  DxTooltipModule
} from 'devextreme-angular';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../../../core/confirm-dialog/confirm-dialog.component';
import {NgShortcutsComponent} from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import {ShortcutInput} from 'ng-keyboard-shortcuts';
import {EventService} from '../../../event.service';
import {ShortcutDirective} from '../../../menu-wapro/shortcut.directive';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import {environment} from '../../../../environments/environment';
import {AppServices} from '../../../app-services.service';
import {PipesModule} from '../../../pipes/pipes.module';

@Component({
    selector: 'app-packing-box',
    imports: [CommonModule, DxDropDownBoxModule, DxDataGridModule, TranslateModule, DxButtonModule, ConfirmDialogComponent, NgShortcutsComponent, DxTooltipModule, ShortcutDirective, DxCheckBoxModule, DxPopupModule, DxScrollViewModule, DxTextAreaModule, DxTextBoxModule, ReactiveFormsModule, DxNumberBoxModule, DxContextMenuModule, PipesModule],
    templateUrl: './packing-box.component.html',
    styleUrl: './packing-box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})


export class PackingBoxComponent {

  @ViewChild('gridClass') gridClass;

  @Output() onChosen = new EventEmitter();

  @Input() readOnly: boolean;
  @Input() className;
  @Input() width;
  @Input() selectedPackaging;

  availableValue = [];
  isGridBoxOpened = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  choosingValue = [];
  dataSource;
  isDeleteBox = false;
  isBoxVisible = false;
  shortcuts: ShortcutInput[] = [];
  focusedBox = [];
  widthWindow = 570;
  heightWindow = 300;
  title: string;
  mode: string = 'add';
  isAddingOrEditingVisible = false;
  submitted: boolean = false;
  form: FormGroup;
  showTooltipHelp: boolean = false;
  format0 = '#0.0';
  format000 = '#0.000';
  isEditDisabled = signal(false);
  isDeleteDisabled = signal(false);
  contextMenu = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: this.readOnly,
        translationSrc: 'buttons.add',
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled: this.readOnly || this.isEditDisabled(),
        translationSrc: 'buttons.edit',
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled: this.readOnly || this.isDeleteDisabled(),
        translationSrc: 'buttons.delete',
      }
    ];
  })

  constructor(
    public cd: ChangeDetectorRef,
    public event: EventService,
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    private appService: AppServices,
  ) {
    this.getDataSource();
  }

  packingFocusedRowIndex = -1;
  firstLoadPacking = true;
  getDataSource = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'PackagingId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}products/packaging`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (data.length == 0) {
            this.focusedBox = [];
          } else {
            if (this.availableValue.length == 0) {
              this.availableValue = [data[0]];
              // console.log('getDataSource', this.availableValue);
              this.choosingValue = this.availableValue[0].PackagingId;
              setTimeout(() => {
                this.onChosen.emit(this.availableValue[0]);
              });
            }
          }
          if (this.firstLoadPacking) {
            this.firstLoadPacking = false;
            this.packingFocusedRowIndex = 0;
            this.onFocusedRowChanged({
              row: {data: data[this.packingFocusedRowIndex]},
            });
            this.cd.detectChanges();
          } else {
            this.onFocusedRowChanged({
              row: {data: data[this.packingFocusedRowIndex]},
            });
            this.cd.detectChanges();
          }
        },
      })
    });
  };

  disabledChange(e) {
    this.isEditDisabled.set(e);
  }

  onOpenedChanged = (e) => {
    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;
    if (!e) {
      this.isAddingOrEditingVisible = false;
    }
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      PackagingId: [null],
      CompanyId: [0],
      Name: ['', Validators.required],
      Height: [null, Validators.required],
      Width: [null, Validators.required],
      Depth: [null, Validators.required],
      Thickness: [0],
      MaxWeight: [null, Validators.required]
    });

    if (this.mode == 'edit') {
      this.form.patchValue(this.focusedBox[0]);
      console.log('this.form', this.form.value);
    }
  };

  onFocusedRowChanged(e) {
    if (e && e?.row?.data) {
      this.focusedBox = [e.row.data];
      this.isDeleteDisabled.set(this.focusedBox[0].CompanyId === 0);
      this.isEditDisabled.set(this.focusedBox[0].CompanyId === 0);
    }
  }

  addBox() {
    this.mode = 'add';
    this.title = this.translate.instant(
      'addingCardboard'
    );
    this.isAddingOrEditingVisible = true;

  }

  isDelete() {
    if (this.focusedBox[0].CompanyId === 0) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'articles.systemPackagingCannotBeDeleted'
        )
      );
      return;
    }

    if (!this.readOnly && this.isGridBoxOpened) {
      this.isDeleteBox = true;
    }
  }

  contextMenuClick(e: any) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.addBox();
        }
        break;
      case 1:
        if (
          !this.readOnly &&
          this.focusedBox.length != 0) {
          this.onEditClick();
        }
        break;
      case 2:
        if (
          !this.readOnly &&
          this.focusedBox.length != 0
        ) {
          this.isDelete();
        }
        break;
    }
  }


  deleteBox() {
    this.appService.deleteAuth(`products/packaging/${this.focusedBox[0].PackagingId}`)
      .subscribe(() => {
        this.isDeleteBox = false;
        this.focusedBox = [];
        this.getDataSource();
        this.cd.detectChanges();
      }, error => {
        this.event.httpErrorNotification(error);
      });
  }

  closeConfirm() {
    this.isDeleteBox = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  }

  onEditClick() {
    if (this.focusedBox[0].CompanyId === 0) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'articles.systemPackagingCannotBeEdited'
        )
      );
      return;
    }
    this.mode = 'edit';
    this.title = this.translate.instant(
      'cardboardEditing'
    );
    this.isAddingOrEditingVisible = true;
  }

  onSave() {

    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.mode == 'add') {
      this.appService.postAuth(`products/packaging`, this.form.value).subscribe((res) => {
        this.submitted = false;
        this.isAddingOrEditingVisible = false;
        this.updateDataSource(res.PackagingId);
      }, error => {
        this.event.httpErrorNotification(error);
      });
    } else if (this.mode == 'edit') {
      this.appService.putAuth(`products/packaging/${this.form.value.PackagingId}`, this.form.value).subscribe((res) => {
        this.submitted = false;
        this.isAddingOrEditingVisible = false;
        this.updateDataSource(res.PackagingId);
      }, error => {
        this.event.httpErrorNotification(error);
      });
    }
  }

  updateDataSource = async (e) => {
    try {
      await this.dataSource.reload();

      this.packingFocusedRowIndex = this.event.returnFocusIndex(
        this.dataSource._items,
        'PackagingId',
        e
      );

      this.onFocusedRowChanged({row: {data: this.dataSource[this.packingFocusedRowIndex]}});

      if (this.mode === 'edit') {
        this.availableValue = [this.focusedBox[0]];
        this.choosingValue = this.availableValue[0].PackagingId;
        this.onChosen.emit(this.availableValue[0]);
      }

      this.event.setFocus(this.gridClass);
      this.cd.detectChanges();
    } catch (error) {
      this.event.httpErrorNotification(error);
    }
  };


  onChoosing = () => {
    if (!this.readOnly) {
      this.availableValue = this.focusedBox;
      this.choosingValue = this.availableValue[0].PackagingId;
      this.onChosen.emit(this.availableValue[0]);
      console.log('onChoosing', this.availableValue[0]);
      this.isGridBoxOpened = false;
    }
  };
}

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="210"
  (onShown)="event.onShownPopUp(); event.setFocus(firstFocused)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
    >
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="checkOstrzegajMinMax()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#fitToWidth' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "servicesDevices.fitToWidth" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#externalFields' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "categories.externalFields" | translate }} (Ctrl + P)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "form-commercial-operation.grid.articleName" | translate }}:
          <strong>{{ articleToAdd.ProductFullName }}</strong> |
          {{ "customerGroups.catalogIndex" | translate }}:
          <strong>{{ articleToAdd.IndexCatalogue }}</strong> |
          {{ "articles.availableAmount" | translate }}:
          <strong>{{ articleToAdd.StockAvailable }}</strong>
        </p>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <div
            class="col-md-6"
            style="border-right: 1px solid var(--neutral-80); padding-right: 30px"
          >
            <label
              style="
                width: 138px;
                display: inline-block;
                text-align: right;
                margin-right: 10px;
              "
            >
              {{ "form-commercial-operation.grid.count" | translate }}
            </label>

            <dx-number-box
              [showSpinButtons]="true"
              [min]="0"
              [width]="160"
              [useLargeSpinButtons]="true"
              (onValueChanged)="AmountBaseChanged($event)"
              valueChangeEvent="input"
              style="display: inline-block; margin-bottom: 6px"
              #firstFocused
              formControlName="AmountBase"
              [format]="IsDivisible ? formatDivisible : formatNotDivisible"
            >
            </dx-number-box
            ><br />

            <label
              style="
                width: 138px;
                display: inline-block;
                text-align: right;
                margin-right: 10px;
              "
            >
              {{ "articles.sizeUnit" | translate }}
            </label>
            <dx-drop-down-box
              [(value)]="form.value.UnitId"
              valueExpr="UnitId"
              [deferRendering]="false"
              displayExpr="UnitName"
              [(opened)]="isGridBoxOpened"
              [dataSource]="unitList"
              [showClearButton]="false"
              style="width: 160px; display: inline-block; margin-bottom: 6px"
              (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
              [ngClass]="{ 'input-required': className == true }"
              [readOnly]="readOnly"
            >
              <div *dxTemplate="let data of 'content'" style="height: 280px">
                <div class="row">
                  <div class="col-md-12" class="no-padding">
                    <dx-data-grid
                      [dataSource]="unitList"
                      [wordWrapEnabled]="true"
                      [showBorders]="true"
                      shortcut
                      [height]="258"
                      style="max-width: 100% !important"
                      class="dropDownBoxClassGrid"
                      [columnResizingMode]="'widget'"
                      [remoteOperations]="true"
                      (onRowDblClick)="onRowDblClick()"
                      [columnAutoWidth]="true"
                      [allowColumnResizing]="true"
                      (onFocusedRowChanged)="onFocusedRowChanged($event)"
                      [id]="'grid' + unicalGuid"
                      [focusedRowEnabled]="true"
                      #gridClass
                      [hoverStateEnabled]="true"
                    >
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxo-scrolling mode="infinite"></dxo-scrolling>
                      <dxo-paging [pageSize]="50"></dxo-paging>
                      <dxo-selection mode="single" showCheckBoxesMode="always">
                      </dxo-selection>

                      <dxi-column
                        caption="{{ 'articles.shortcut' | translate }}"
                        width="60"
                        dataField="Code"
                        [allowSorting]="false"
                        cellTemplate="Code"
                        headerCellTemplate="CodeHeaderTemplate"
                      >
                      </dxi-column>
                      <div
                        *dxTemplate="let data of 'CodeHeaderTemplate'"
                        style="padding-left: 10px"
                      >
                        {{ "articles.shortcut" | translate }}
                      </div>
                      <div
                        *dxTemplate="let cell of 'Code'"
                        style="padding-left: 10px"
                      >
                        {{ cell.value }}
                      </div>
                      <dxi-column
                        caption="{{ 'articles.unit' | translate }}"
                        dataField="UnitName"
                        [allowSorting]="false"
                        width="100"
                      ></dxi-column>

                      <dxi-column
                        caption="{{ 'articles.converter' | translate }}"
                        dataField="Factor"
                        [allowSorting]="false"
                        alignment="left"
                      ></dxi-column>

                      <!-- <dxi-column caption="{{'form-commercial-operation.grid.count' | translate}}"
                              dataField="Quantity" [allowSorting]="false" [width]="100" alignment="left"></dxi-column> -->
                    </dx-data-grid>
                  </div>
                </div>

                <div class="bottom-drop-box popup-footer">
                  <dx-button
                    class=""
                    type="danger"
                    [text]="
                      'warehouseDocument.selectUnitAndQuantity' | translate
                    "
                    [disabled]="readOnly"
                    (onClick)="chooseUnitAndQuantity()"
                  >
                  </dx-button>

                  <dx-button
                    class=""
                    type="success"
                    [text]="'buttons.choose' | translate"
                    [disabled]="readOnly"
                    (onClick)="chooseUnit()"
                  >
                  </dx-button>
                </div>
              </div>
            </dx-drop-down-box>
          </div>

          <div class="col-md-6">
            <label
              style="
                width: 138px;
                display: inline-block;
                text-align: right;
                margin-right: 10px;
              "
            >
              {{ "articles.amountInJMag" | translate }}
            </label>
            <dx-number-box
              width="160"
              style="display: inline-block; margin-bottom: 6px"
              [readOnly]="readOnly"
              valueChangeEvent="input"
              [showSpinButtons]="true"
              [min]="0"
              [width]="160"
              [useLargeSpinButtons]="true"
              formControlName="Amount"
              (onValueChanged)="AmountChanged($event)"
              [format]="IsDivisibleBase ? formatDivisible : formatNotDivisible"
            >
            </dx-number-box
            ><br />

            <label
              style="
                width: 138px;
                display: inline-block;
                text-align: right;
                margin-right: 10px;
              "
            >
              {{ "warehouseDocument.stockUnit" | translate }}
            </label>
            <dx-text-box
              width="160"
              style="display: inline-block; margin-bottom: 6px"
              [readOnly]="true"
              valueChangeEvent="input"
              formControlName="BaseUnitName"
            >
            </dx-text-box>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<!-- pola dodatkowe -->
<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Pozycja_dokumentu_magazynowego"
  [readOnly]="readOnly"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="field"
  #isValidUserExtensions
  *ngIf="isVisible"
>
</app-form-user-extensions>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || visibleUserExtensions"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<app-confirm-dialog
  *ngIf="isOstrzegajMinMax"
  kindDialog="question"
  [confirmText]="textOstrzegajMinMax"
  [isVisible]="true"
  (onRemoving)="onSave()"
  (onClosing)="noOstrzegajMinMax()"
>
</app-confirm-dialog>

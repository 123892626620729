import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-customer-advance-payment',
    templateUrl: './customer-advance-payment.component.html',
    styleUrls: ['./customer-advance-payment.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'selectedId',
        'selected',
        'readOnly',
        'Number',
        'invoiceDocumentId',
        'customerId',
        'CurrencyCode',
        'TotalGrossAmount',
        'isOperatorFormPayment',
        'TotalToPay',
    ],
    standalone: false
})
export class CustomerAdvancePaymentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;

  isVisible;
  title;
  customerId;
  selectedId;
  selected;
  readOnly;
  unicalGuid;
  Number;
  CurrencyCode;
  isOperatorFormPayment;
  TotalGrossAmount;

  widthWindow = '95%';
  heightWindow = 650;
  invoiceDocumentId;
  selectedtab: number = 0;
  TotalToPay: number = 0;
  TotalToSettlement = 0;
  isGenerateSet: boolean = false;
  radioList = [
    { label: 'płatnika', value: 1 },
    { label: 'kontrahenta', value: 0 },
    { label: 'operatora płatności', value: 2, disabled: true },
  ];

  CustomerMode = 1;
  tabPanelItem = ['Zaliczki', 'Inne płatności - Fakir'];
  dataSource;
  dataSourceAdvane;
  shortcuts: ShortcutInput[] = [];
  minWidth;
  myEventDelete;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.widthWindow = this.event.setWidthPopUp();
    this.minWidth = this.event.setMinWidthPopUp();

    //translate
    this.translate
      .get('buffor.Advances')
      .subscribe((text) => (this.tabPanelItem[0] = text));
    this.translate
      .get('form-commercial-operation.otherPayments')
      .subscribe((text) => (this.tabPanelItem[1] = text));

    this.translate
      .get('form-commercial-operation.payer')
      .subscribe((text) => (this.radioList[0].label = text));
    this.translate
      .get('form-commercial-operation.customer')
      .subscribe((text) => (this.radioList[1].label = text));
    this.translate
      .get('form-commercial-operation.paymentOperator')
      .subscribe((text) => (this.radioList[2].label = text));

    this.myEventDelete = this.event.delete.subscribe(() => {
      if (this.isVisible && this.selectedRowKeysRight.length > 0)
        this.disconnect();
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.selectedRowKeysLeft.length > 0) this.connect();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy(): void {
    this.myEventDelete.unsubscribe();
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  getData = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'SettlementId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${this.invoiceDocumentId}/customerAdvencePayment`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (e) => {
          if (e.length > 0) {
            this.focusedRowIndex = 0;
          }
          this.cd.detectChanges();
        },
      }),
      reshapeOnPush: true,
    });
  };

  focusedRowIndexRight = -1;
  focusedRowIndex = -1;
  getAdvancePayment = () => {
    this.dataSourceAdvane = new DataSource({
      store: AspNetData.createStore({
        key: 'SettlementId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${this.invoiceDocumentId}/advencePayment`,
        loadParams: { DocumentId: this.invoiceDocumentId },
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (data.length > 0) {
            this.focusedRowIndexRight = 0;
          }
          this.settledAmmount = 0;
          this.TotalToSettlement = this.TotalGrossAmount;
          data.forEach((field) => {
            this.TotalToSettlement -= field.Amount;
            this.settledAmmount += field.Amount;
          });
          this.event.onHidden();
        },
      }),
      reshapeOnPush: true,
    });
  };

  onFocusedRowChanged = (e) => {
    this.selectedRowKeysLeft = [e.row?.data?.SettlementId];
  };

  onFocusedRowChangedRight = (e) => {
    this.selectedRowKeysRight = [e.row?.data?.SettlementId];
  };

  getLoadParams = () => {
    let obj: any = {};

    obj.CustomerId = this.customerId;
    obj.DocumentId = this.invoiceDocumentId;
    obj.CurrencyCode = this.CurrencyCode != '' ? this.CurrencyCode : 'PLN';
    obj.CustomerMode = this.CustomerMode;

    if (
      this.isOperatorFormPayment.PayerId != 0 &&
      this.isOperatorFormPayment.PayerId != null &&
      this.CustomerMode == 2
    )
      obj.CustomerId = this.isOperatorFormPayment.PayerId;

    this.selectedtab === 0 ? (obj.MagFakirMode = 1) : (obj.MagFakirMode = 2);

    return obj;
  };

  visibleChange = (e) => {
    if (
      this.isOperatorFormPayment.PayerId != 0 &&
      this.isOperatorFormPayment.PayerId != null
    )
      this.radioList[2].disabled = false;
    else this.radioList[2].disabled = true;
    if (!e) {
      this.onClosing.emit(this.settledAmmount);
      this.selectedtab = 0;
    }
  };

  onValueRadioChanged(e) {
    if (e.value == 0 || e.value == 1) this.getData();

    //if(e.value == 2);
  }

  selectedRowKeysLeft = [];
  selectedRowKeysRight = [];
  settledAmmount = 0;

  customerAdvencePaymentConnect(IsAdvanceWithDivided?) {
    return new Promise((resolve) => {
      let object = {
        SettlementId: this.selectedRowKeysLeft[0],
        IsAdvanceWithDivided: IsAdvanceWithDivided
          ? IsAdvanceWithDivided
          : false,
        TotalToPay: this.TotalToPay,
      };

      this.appService
        .postAuth(
          `invoices/documents/${this.invoiceDocumentId}/customerAdvencePayment/connect`,
          object
        )
        .subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            resolve(true);
            this.event.onHidden();
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  async connect() {
    let settlemetn = this.dataSource._items.find(
      (field) => field.SettlementId === this.selectedRowKeysLeft[0]
    );

    if (this.TotalToSettlement < settlemetn.Amount) {
      this.isGenerateSet = true;
      this.cd.detectChanges();
      return;
    }

    this.event.onShown();
    await this.customerAdvencePaymentConnect();
    this.getData();
    this.getAdvancePayment();
    this.event.onHidden();
    this.cd.detectChanges();
  }

  customerAdvencePaymentDisconnect() {
    return new Promise((resolve) => {
      this.appService
        .postAuth(
          `invoices/documents/${this.invoiceDocumentId}/customerAdvencePayment/disconnect`,
          this.selectedRowKeysRight[0]
        )
        .subscribe(
          () => {
            resolve(true);
            this.cd.detectChanges();
          },
          (error) => {
            resolve(true);
            this.event.onHidden();
            this.event.loadingVisible = false;
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  async disconnect() {
    // this.event.onShown();
    await this.customerAdvencePaymentDisconnect();
    this.dataSource.reload();
    this.dataSourceAdvane.reload();
    // this.getData();
    // this.getAdvancePayment();
    this.cd.detectChanges;
  }

  onSelectionChangedTab = () => {
    this.getData();
  };

  async yes() {
    this.event.onShown();
    await this.customerAdvencePaymentConnect(true);
    this.getData();
    this.getAdvancePayment();
    this.event.onHidden();
    this.isGenerateSet = false;
    this.cd.detectChanges();
  }

  no() {
    this.isGenerateSet = false;
    this.cd.detectChanges();
  }
}

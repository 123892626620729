import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { DragulaService } from 'ng2-dragula';
import { Subject, Subscription, of, take, switchMap } from 'rxjs';
import { tap, throttleTime } from 'rxjs/operators';
import { AppServices } from 'src/app/app-services.service';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import {
  CustomWindowConfig,
  RefreshCardResult,
  SingleRecordMode,
} from 'src/app/event.model';
import { EventService } from 'src/app/event.service';
import { environment } from 'src/environments/environment';

import { HelpService } from 'src/app/help-service.service';
import { EDokumentsService } from 'src/app/libraries/e-dokuments/e-dokuments.service';
import { GlobalDate } from 'src/app/core/date-range/GlobalDate';
import { SERVICE_TYPES_KEY } from 'src/app/consts';
@Component({
    selector: 'app-orders-kanban',
    templateUrl: './orders-kanban.component.html',
    styleUrls: ['./orders-kanban.component.scss'],
    standalone: false
})
export class OrdersKanbanComponent implements OnInit {
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  canbanHeight: number = 780;
  isDelete: boolean = false;
  newTaskStatus = null;
  newTaskType = null;
  taskTypes = [];
  helpService = inject(HelpService);
  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'SERVICE_ORDER';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Serwis_zlecenie';
  viewUserDefinitionSub: Subscription = null;
  columnsChooserVisible = false;
  readOnly;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  width = 280;
  widthLeftPanel = 280;
  selectedTask = [];
  tabRightVisible: boolean = true;
  event = inject(EventService);
  localStorageData: CustomWindowConfig = {
    filter: {
      order: 'ASC',
      orderBy: 'number',
      value: '',
    },
    tree: [], //{ value: any;key: string;}
    columns: [],
    mainPanelWidth: 'calc(100% - 300px)',
    bottomPanel: {
      selectedIndex: null,
      isVisible: false, //true
      height: 0,
    },
    sidePanel: {
      selectedIndex: 0,
      // secondRowSelectedIndex: 'number', // optional
      isVisible: true,
      width: 300,
    },
    windowSize: {
      isFullscreen: false,
      width: this.event.setWidthPopUp(),
      height: '90%',
    },
    params: [],
    // additionalTable: { // optional
    //   text: 'string',
    //   TableId: 'number',
    //   ServerName: 'string',
    // },
    advancedFilter: {
      FilterUsersDefinitionId: null,
      Name: '',
      UserFilterDefinitionValues: null, //AdditionalFieldFilter[],
    },
    // skipUserGuide: 'boolean', // optional
    other: {
      singleWidth: 300,
      groupsChecked: [],
    },
  };
  popupSelectBoxSource = [
    { i: 'edit', text: 'buttons.edit' },
    { i: 'delete', text: 'buttons.delete' },
    { i: 'show', text: 'buttons.show' },
    { i: 'print', text: 'buttons.prints' },
    { i: 'edocuments', text: 'buttons.eDocuments' },
  ];
  shortcuts: ShortcutInput[] = [];
  unicalGuid;
  // contextMenu = [
  //   {
  //     text: 'Operacje dodatkowe',
  //     items: [],
  //   },
  // ];
  dropSub = new Subscription();
  openCustomDropDown: boolean = false;
  selectedTaskData: any;
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();

  @HostListener('window:resize', ['$event.target'])
  public onResize(target) {
    this.changeSize.next(target.innerHeight);
  }

  pinnedViewsSub: Subscription = null;
  private changeSize = new Subject();

  eDocumentsVisible: boolean = false;
  perEdocuments: boolean = false;

  constructor(
    public cd: ChangeDetectorRef,
    public edocs: EDokumentsService,
    public translate: TranslateService,
    private dragulaService: DragulaService,
    private appService: AppServices,
    private dynamicFilterService: DynamicFilterService
  ) {
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
      this.getTasks();
      this.cd.detectChanges();
    });
    this.canbanHeight = window.innerHeight - 160;
    this.changeSize
      .asObservable()
      .pipe(throttleTime(1000))
      .subscribe((height: any) => {
        this.canbanHeight = height - 160;
      });

    this.getReminderTimes();
    this.getTranslations();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.dragulaService.createGroup('COLUMNS', {
      direction: 'horizontal',
      moves: (_el, _source, handle) => {
        return handle.className === 'group-handle';
      },
    });

    this.perEdocuments = !this.event.isDisabledEDocuments(
      'ServiceOrderRelated'
    );
    dragulaService.createGroup('ITEMS', {
      copy: (_el, source) => {
        if (source.id === 'copy-src') {
          return true;
        }
        return false;
      },
      accepts: (_el, target, _source, _sibling) => {
        return target.id !== 'copy-src';
      },
      copyItem: (_item) => {
        return {};
      },
      moves: (_el, _container, handle, _sibling) => {
        return handle.className.indexOf('move-box') > -1;
      },
    });

    this.dropSub.add(
      dragulaService
        .dropModel('ITEMS')
        .subscribe(({ el, target, targetModel, item }) => {
          if (
            item &&
            Object.keys(item).length === 0 &&
            Object.getPrototypeOf(item) === Object.prototype
          ) {
            const index = targetModel.findIndex((el) => !el.ServiceOrderId);
            if (index !== -1) {
              targetModel.splice(index, 1);
            }

            this.newTaskType = Number.parseInt(el['dataset']['id']);
            this.newTaskStatus = target['dataset']['id'];
            if (!this.perABD.addBtn) return;
            this.setSingleRecordVisible('add');
            this.cd.detectChanges();
          } else {
            const newStatus = target['dataset']['id'];
            const itemToUpdate = Object.assign({}, item);
            itemToUpdate.ServiceOrderStatus = newStatus;
            if (!this.perABD.editBtn) return;
            this.updateRecordAfterDragDrop(itemToUpdate);
          }
        })
    );

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/modul-serwisu');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.event.isDisabledEDocuments('ServiceOrderRelated')) {
            this.edocs.getToEDocuments(
              'ServiceOrderRelated',
              this.selectedTaskData?.ServiceOrderId,
              this.selectedTaskData?.Number
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.openCustomDropDown = !this.openCustomDropDown;
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex != 1) {
            this.localStorageData.sidePanel.selectedIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );
  }

  visibleAdvanceFilters = false;
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  updateRecordAfterDragDrop(record) {
    this.appService
      .putAuth(`services/orders/${record.ServiceOrderId}`, record)
      .subscribe({
        next: () => {},
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  ngOnDestroy(): void {
    this.dropSub?.unsubscribe();
    this.dragulaService.destroy('COLUMNS');
    this.pinnedViewsSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();
    this.dragulaService.destroy('ITEMS');
    if (
      JSON.stringify(this.initialLocalStorageData) !==
      JSON.stringify(this.localStorageData)
    ) {
      localStorage.setItem(
        'leftCanbanOrderPanel',
        JSON.stringify(this.localStorageData)
      );
      const session = {
        View: 'CanbanOrderPanel',
        Configuration: JSON.stringify(this.localStorageData),
        TenantId: this.event.footerInfo.TenantId,
      };
      this.appService
        .postAuth(`viewConfigurations`, session)
        .subscribe(() => {});
    }
    this.currentDateSub?.unsubscribe();
  }

  public groups: Array<any> = [
    {
      name: 'N',
      caption: 'Niezrealizowane',
      checked: true,
      items: [],
    },
    {
      name: 'R',
      caption: 'Realizowane',
      checked: true,
      items: [],
    },
    {
      name: 'Z',
      caption: 'Zrealizowane',
      checked: true,
      items: [],
    },
    {
      name: 'O',
      caption: 'Odebrane',
      checked: true,
      items: [],
    },
    {
      name: 'A',
      caption: 'Anulowane',
      checked: true,
      items: [],
    },
  ];

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  ngOnInit(): void {
    this.perABD = this.event.checkPermissionsBtn(
      'SerwisZlecD',
      'SerwisZlecE',
      'SerwisZlecU',
      'SerwisZlecO'
    );
    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;

        this.getTasks();
        this.taskTypes.forEach((el) => {
          this.getOrdersOfType(el);
        });
      }
    );

    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.getStatuses();
    this.getServiceTypes();
    this.getUserParameters();
    this.getViewConfigurations();

    this.pinnedViewsSub = this.dynamicFilterService
      .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          if (!data) {
            return;
          }
          if (this.localStorageData?.advancedFilter) {
            this.localStorageData.advancedFilter.PinnedFilters =
              data.pinnedFilters;
          } else {
            this.localStorageData.advancedFilter = {
              PinnedFilters: data.pinnedFilters,
            } as any;
          }
        },
      });
  }

  initialLocalStorageData = null;
  getViewConfigurations = () => {
    try {
      if (!localStorage.getItem('leftCanbanOrderPanel')) {
        this.appService
          .getAuth(`viewConfigurations/CanbanOrderPanel`)
          .subscribe((res) => {
            if (res.Configuration) {
              this.localStorageData = Object.assign(
                this.localStorageData,
                JSON.parse(res.Configuration)
              );
            }

            localStorage.setItem(
              'leftCanbanOrderPanel',
              JSON.stringify(this.localStorageData)
            );
            if (this.localStorageData.sidePanel.width <= '21px') {
              this.tabRightVisible = false;
            }
            this.getViewConfigurations();
          });
      } else if (localStorage.getItem('leftCanbanOrderPanel')) {
        this.localStorageData = JSON.parse(
          localStorage.getItem('leftCanbanOrderPanel')
        );
        this.initialLocalStorageData = JSON.parse(
          localStorage.getItem('leftCanbanOrderPanel')
        );

        if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
          this.getDefaultFilter();
        }
        else if (
          this.localStorageData?.advancedFilter
            ?.FilterUsersDefinitionId ||
          this.localStorageData?.advancedFilter?.IsPinned
        ) {
          this.getSavedFilterDefinitions();
        }

        if (this.filterValue) {
          this.getTasks();
          this.taskTypes.forEach((el) => {
            this.getOrdersOfType(el);
          });
        }
        setTimeout(() => {
          try {
            if (this.localStorageData.sidePanel.width != null) {
              this.leftPanel.nativeElement.style.width =
                this.localStorageData.sidePanel.width;
            }
            if (this.localStorageData.sidePanel.width != null) {
              this.width = Number(
                this.localStorageData.sidePanel.width.replace('px', '')
              );
            }
            if (this.localStorageData.mainPanelWidth != null) {
              this.rightPanel.nativeElement.style.width =
                this.localStorageData.mainPanelWidth;
            }

            if (this.localStorageData.sidePanel.width <= '21px') {
              this.tabRightVisible = false;
            }

            this.resizeSingleBox();
          } catch {}
        }, 0);
      }
      //this.getData()
    } catch (e) {
      //this.onInitErr = true;
    }
  };

  dataSources: DataSource[] = [];
  dataSourceGroups: any[] = [
    { status: 'N', index: 0 },
    { status: 'R', index: 1 },
    { status: 'Z', index: 2 },
    { status: 'O', index: 3 },
    { status: 'A', index: 4 },
  ];
  statuses = [];
  getStatuses() {
    const localStatuses: string = sessionStorage.getItem(
      'crmOrderKanbanStatuses'
    );
    if (localStatuses) {
      this.statuses = this.event.decryptString(localStatuses);

      this.onAfterGetStatuses();
      return;
    }
    this.appService.getAuth('services/orders/statusTree').subscribe(
      (res) => {
        this.statuses = res;
        sessionStorage.setItem(
          'crmOrderKanbanStatuses',
          this.event.encryptString(this.statuses)
        );
        this.onAfterGetStatuses();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        sessionStorage.removeItem('crmOrderKanbanStatuses');
      }
    );
  }

  onAfterGetStatuses() {
    for (let status of this.statuses) {
      const group = this.groups.find((el) => el.name === status.StatusCode);
      if (group) {
        group.StatusBackgroundColor = status.StatusBackgroundColor;
        group.StatusStringColor = status.StatusStringColor;
      }
    }
  }

  getOrderBy() {
    if (this.localStorageData.filter.orderBy === 'ProductNameIntuition') {
      return 'ProductName1';
    }
    return this.localStorageData.filter.orderBy.indexOf('Intuition') === -1
      ? this.localStorageData.filter.orderBy
      : this.localStorageData.filter.orderBy.substring(
          0,
          this.localStorageData.filter.orderBy.indexOf('Intuition')
        );
  }

  getOrdersOfType(type) {
    this.appService
      .getAuth(
        `services/orders${this.getOrdersOfTypeLoadParams(
          type.ServiceOrderTypeId
        )}`
      )
      .subscribe({
        next: (res) => {
          type.amount = res.totalCount;
        },
        error: (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
          localStorage.removeItem('crmCanbanTaskTypes');
        },
      });
  }

  getOrdersOfTypeLoadParams(typeId: number) {
    let params = `?serviceOrderType=${typeId}&dateStart=${
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || ''
    }&dateEnd=${
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || ''
    }`;

    switch (this.localStorageData.filter.orderBy && this.filterValue) {
      case 'number':
        params += `&number=${this.filterValue}`;
        break;
      case 'dateReceived':
        params += `&dateReceived=${this.filterValue}`;
        break;
      case 'taskDate':
        params += `&taskDate=${this.filterValue}`;
        break;
      case 'dateEnd':
        params += `&dateEnd=${this.filterValue}`;
        break;
      case 'customerName':
        params += `&customerName=${this.filterValue}`;
        break;
      case 'employeeName':
        params += `&employeeName=${this.filterValue}`;
        break;
      case 'EAN':
        params += `&EAN=${this.filterValue}`;
        break;
      case 'ServiceDeviceName':
        params += `&ServiceDeviceName=${this.filterValue}`;
        break;
      case 'factoryNumber':
        params += `&factoryNumber=${this.filterValue}`;
        break;
      case 'serialNumber':
        params += `&serialNumber=${this.filterValue}`;
        break;
      default:
        break;
    }

    return params;
  }

  getLoadParams(ServiceOrderStatus: string) {
    let obj: any = {
      orderBy: this.getOrderBy(),
      order: this.localStorageData.filter.order,
      ServiceOrderStatus,
      dateStart:
        this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '',
      dateEnd:
        this.event.dataFormatted({ value: this.currentDate.dateTo }) || '',
    };

    switch (this.localStorageData.filter.orderBy) {
      case 'number':
        obj.number = this.filterValue;
        break;

      case 'dateReceived':
        obj.dateReceived = this.filterValue;
        break;

      case 'dateStart':
        obj.dateStart = this.filterValue;
        break;

      case 'dateEnd':
        obj.dateEnd = this.filterValue;
        break;

      case 'customerName':
        obj.customerName = this.filterValue;
        break;

      case 'employeeName':
        obj.employeeName = this.filterValue;
        break;

      case 'EAN':
        obj.EAN = this.filterValue;
        break;

      case 'ServiceDeviceName':
        obj.ServiceDeviceName = this.filterValue;
        break;

      case 'factoryNumber':
        obj.factoryNumber = this.filterValue;
        break;

      case 'serialNumber':
        obj.serialNumber = this.filterValue;
        break;

      default:
        break;
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    return obj;
  }

  firstLoad = true;
  getTasks() {
    for (let item of this.groups) {
      item.items = [];
    }

    this.dataSources = [];
    let isEmpty = true;

    for (let dsg of this.dataSourceGroups) {
      this.dataSources.push(
        new DataSource({
          store: AspNetData.createStore({
            key: 'ServiceOrderId',
            onBeforeSend: this.event.onBeforeSendDataSource,
            loadUrl: `${environment.domain}services/orders`,
            loadParams: this.getLoadParams(dsg.status),
            onAjaxError: this.event.onAjaxDataSourceError,
            deleteUrl: `${environment.domain}services/orders`,
            onLoaded: (res) => {
              if (res.length > 0) {
                isEmpty = false;
              }
              const group = this.groups.find((el) => el.name == dsg.status);

              group.items = group.items.concat(res);
              if (this.firstLoad) {
                this.firstLoad = false;
                this.searchControl?.focusInput();
              }
            },
          }),
          reshapeOnPush: true,
        })
      );
    }
    if (isEmpty) {
      this.selectedTask = [];
    }

    this.dataSources.forEach((source) => {
      source.load();
    });

    setTimeout(() => {
      this.resizeSingleBox();
    });
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();

    this.resizeSingleBox();
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  onInserted = () => {
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getOrdersOfType(el);
    });
  };

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.tabRightVisible = false;
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    this.localStorageData.sidePanel.isVisible = false;
    this.resizeSingleBox();
  };

  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.width = `280px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 280px)`;
    this.localStorageData.sidePanel.isVisible = true;
    this.resizeSingleBox();
  };

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }
  }

  resizeSingleBox() {
    const panelWidth: number = this.rightPanel.nativeElement.offsetWidth - 25;
    this.localStorageData.other.singleWidth = Math.floor(
      panelWidth / this.groups.length
    );
    if (this.localStorageData.other.singleWidth < 220) {
      this.localStorageData.other.singleWidth = 220;
    }
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'leftCanbanOrderPanel',
      this.event.encryptString(this.localStorageData)
    );
  }

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'leftCanbanOrderPanel',
          JSON.stringify(this.localStorageData)
        );
        this.getTasks();
      }
    })
  }

  clientX;
  clientY;
  arrowBottom: boolean = false;
  arrowRight: boolean = false;

  setSelected(item, e) {
    if (e.clientX > window.innerWidth - 350) {
      this.clientX = e.clientX - 350;
      this.arrowRight = true;
    } else {
      this.clientX = e.clientX - 20;
      this.arrowRight = false;
    }

    if (e.clientY > this.canbanHeight - 90) {
      this.clientY = e.clientY - 250;
      this.arrowBottom = true;
    } else {
      this.clientY = e.clientY;
      this.arrowBottom = false;
    }

    if (item.selected) {
      item.selected = false;
      this.selectedTask = [];
      return;
    }

    this.clearSelectedItem();

    item.selected = true;
    this.selectedTask = [item.ServiceOrderId];
    this.selectedTaskData = item;
    this.setSelection();
  }

  setSelection() {
    this.event.selectRecords(
      `services/selection/orders/select`,
      this.selectedTask
    );
  }

  getTypesDef() {
    const localData = this.event.getSessionStorageData(SERVICE_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`services/orders/types`).pipe(
        tap((data) => {
          this.event.saveSessionStorageData(SERVICE_TYPES_KEY, data);
        })
      );
    }
  }

  getServiceTypes = () => {
    this.getTypesDef().subscribe(
      (res) => {
        res.data.forEach((el) => {
          if (el.Name === 'Naprawa') {
            el.icon = 'icon-repair';
          } else if (el.Name === 'Przegląd') {
            el.icon = 'icon-review';
          } else {
            el.icon = 'default-icon';
          }
        });
        this.taskTypes = res.data;
        this.taskTypes.forEach((el) => {
          this.getOrdersOfType(el);
        });
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  };

  getTypeDescription(value) {
    return (
      this.taskTypes.find((el) => el.ServiceOrderTypeId == value)?.Name || '-'
    );
  }

  getNameShortcut(name: string) {
    if (!name) {
      return '??';
    }
    name = name.trim();
    if (!name) {
      return '??';
    }

    const lastSpace: number = name.lastIndexOf(' ');
    if (lastSpace !== -1) {
      return name[0] + name.substring(lastSpace + 1, lastSpace + 2);
    }
    return name[0] + name[1];
  }

  getReminderTimer(val) {
    return this.reminderTimes.find((el) => el.value === val)?.label || '-';
  }

  reminderTimes = [];

  getReminderTimes() {
    const amount = 47;
    this.reminderTimes.push({ value: null, label: 'Brak' });
    this.reminderTimes.push({ value: 0, label: '00:00' });
    for (let i = 0; i < amount; i++) {
      let time =
        i < 19 ? '0' + Math.round(i / 2) : Math.round(i / 2).toString();
      time += i % 2 === 0 ? ':30' : ':00';

      this.reminderTimes.push({ value: i + 1, label: time });
    }
  }

  stop(e) {
    e.stopPropagation();
  }

  selectBoxValue = null;

  onSelectBoxValueChanged(e, item) {
    this.selectBoxValue = null;
    this.clearSelectedItem();
    this.selectedTask = [item.ServiceOrderId];

    if (e.value.i == 'edit' && this.perABD.editBtn) {
      this.setSingleRecordVisible('edit');
    } else if (e.value.i == 'delete' && this.perABD.deleteBtn) {
      this.isDelete = true;
    } else if (e.value.i == 'show' && this.perABD.showBtn) {
      this.setSingleRecordVisible('show');
    } else if (e.value.i == 'print') {
    } else if (e.value.i == 'edocuments' && this.perEdocuments) {
      this.eDocumentsVisible = true;
      this.cd.detectChanges();
    }
    this.isOpened = false;
  }

  onTitleClick(id: number) {
    if (!this.perABD.editBtn) return;

    this.selectedTask = [id];
    this.setSingleRecordVisible('edit');
  }

  clearSelectedItem() {
    this.groups.forEach((g) => {
      g.items.forEach((i) => {
        i.selected = false;
      });
    });
    this.event.deselectAllRecords(`services/selection/devices`);
  }

  showNewRow() {
    if (!this.perABD.addBtn) return;

    this.newTaskStatus = null;
    this.newTaskType = null;
    this.setSingleRecordVisible('add');
  }

  showEditArticle = () => {
    if (!this.perABD.editBtn) return;

    this.setSingleRecordVisible('edit');
  };

  addNewTaskBtn(status) {
    if (!this.perABD.addBtn) return;

    this.newTaskStatus = status;
    this.newTaskType = null;
    this.setSingleRecordVisible('add');
  }

  editSelectedRow(item) {
    if (!this.perABD.editBtn) return;

    this.selectedTask = [item.ServiceOrderId];
    this.setSingleRecordVisible('edit');
    this.clearSelectedItem();
  }

  showDeleteArticle() {
    if (this.selectedTask.length == 0) {
      return;
    }
    this.appService
      .deleteAuth(`services/orders/${this.selectedTask[0]}`)
      .subscribe(
        () => {
          this.isDelete = false;
          this.getTasks();
          this.taskTypes.forEach((el) => {
            this.getOrdersOfType(el);
          });
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  closeConfirm() {
    this.isDelete = false;
  }

  showNewType: boolean = false;

  addNewType() {
    if (this.event.checkPermissions('SerwisTypZlecenieD')) {
      this.showNewType = true;
    } else {
      this.event.showNotification(
        'info',
        this.translate.instant('permissions.noPermission')
      );
    }
  }

  addTask(task) {
    if (!this.perABD.addBtn) return;

    this.newTaskType = task.ServiceOrderTypeId;
    this.newTaskStatus = null;
    this.setSingleRecordVisible('add');
    this.cd.detectChanges();
  }

  filterCriteria: ICustomSearchItem[] = [
    { value: 'number', label: '' },
    { value: 'dateStart', label: '', type: 'data' },
    { value: 'dateReceived', label: '', type: 'data' },
    { value: 'dateEnd', label: '', type: 'data' },
    { value: 'customerName', label: '' },
    { value: 'employeeName', label: '' },
    { value: 'EAN', label: '' },
    { value: 'ServiceDeviceName', label: '' },
    { value: 'factoryNumber', label: '' },
    { value: 'serialNumber', label: '' },
  ];
  displayValue: string = 'nazwa';
  filterValue = '';

  getTranslations() {
    this.translate
      .get('form-financial-document.number')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('servicesDevices.ServiceOrderDate')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('servicesDevices.DateReceived')
      .subscribe((text) => (this.filterCriteria[2].label = text));
    this.translate
      .get('servicesDevices.DateEnd')
      .subscribe((text) => (this.filterCriteria[3].label = text));
    this.translate
      .get('form-financial-document.payer-type.contractor')
      .subscribe((text) => (this.filterCriteria[4].label = text));
    this.translate
      .get('worker')
      .subscribe((text) => (this.filterCriteria[5].label = text));
    this.translate
      .get('production-documents.barcode')
      .subscribe((text) => (this.filterCriteria[6].label = text));
    this.translate
      .get('servicesDevices.nameDevice')
      .subscribe((text) => (this.filterCriteria[7].label = text));
    this.translate
      .get('servicesDevices.productionNumber')
      .subscribe((text) => (this.filterCriteria[8].label = text));
    this.translate
      .get('servicesDevices.serialNumber')
      .subscribe((text) => (this.filterCriteria[9].label = text));

    this.translate.get('form-financial-document.number').subscribe((text) => {
      this.displayValue = text;
      this.localStorageData.filter.orderBy = 'number';
    });
  }

  advancedFilterTemplateData = null;
  deleteFilters = false;

  clearValues() {
    this.deleteFilters = !this.deleteFilters;
    this.event.setCurrentDate(new GlobalDate(), false, true);
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.deleteFilters = false;
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.displayValue = e.selectedItem.label;
      this.localStorageData.filter.order = 'ASC';
      this.localStorageData.filter.orderBy = e.selectedItem.value;
      this.localStorageData.filter.value = e.filterValue;
    }
    this.filterValue = e.filterValue;
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getOrdersOfType(el);
    });
  }

  isOpened: boolean = false;
  isLeftSelect: boolean = false;

  switchIsOpened(e) {
    this.isLeftSelect = e.clientX > window.innerWidth - 200;
    setTimeout(() => {
      this.isOpened = !this.isOpened;
    });

    this.stop(e);
  }

  onInsertedType() {
    this.getServiceTypes();
  }

  refreshView() {
    sessionStorage.removeItem(SERVICE_TYPES_KEY);
    sessionStorage.removeItem('crmOrderKanbanStatuses');
    this.getStatuses();
    this.getServiceTypes();
    this.getTasks();
  }

  isSingleRecordVisible: boolean = false;
  singleRecordMode: SingleRecordMode = null;

  onSingleRecordClosing() {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    this.cd.detectChanges();
  }

  onSingleRecordInserted() {
    this.onInserted();
    this.singleRecordMode = null;
  }

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  resetFiltr = (filterType: string) => {
    if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned
        );
    }
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getOrdersOfType(el);
    });
  };

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'leftCanbanOrderPanel',
      this.event.encryptString(this.localStorageData)
    );

    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getOrdersOfType(el);
    });
  }

  isGroupStatusChangeVisible = false;
  visibleUserExtensions = false;
  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  onClosingUserExtensions() {
    this.visibleUserExtensions = false;
  }

  // contextMenuClick = (e) => {
  //   switch (e.itemData.itemIndex) {
  //     case '0011_000_POLADODGRUP':
  //       if (!this.readOnly) {
  //         if (this.selectedTask.length == 0) {
  //           this.event.showNotification(
  //             'info',
  //             this.translate.instant('buffor.selectLeastPosition')
  //           );
  //         } else {
  //           this.visibleUserExtensions = true;
  //         }
  //       }
  //       break;
  //     case '1111_000_ZMSTATZLEC':
  //       if (!this.readOnly) {
  //         if (this.selectedTask.length == 0) {
  //           this.event.showNotification(
  //             'info',
  //             this.translate.instant('buffor.selectLeastPosition')
  //           );
  //         } else {
  //           this.isGroupStatusChangeVisible = true;
  //         }
  //       }
  //       break;
  //   }
  // };

  onGroupStatusChangeClosed() {
    this.isGroupStatusChangeVisible = false;
  }

  onGroupStatusChangeSaved() {
    this.isGroupStatusChangeVisible = false;
    this.selectedTask = [];
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getOrdersOfType(el);
    });
  }

  setAdditionalFields(e) {
    this.appService
      .putAuth(`services/additionalOperations/setAdditionalFields`, e)
      .subscribe(
        () => {
          // this.visibleUserExtensions = false;
          this.additionalFieldsList = {
            Field1: '',
            Field2: '',
            Field3: '',
            Field4: '',
            Field5: '',
            Field6: '',
            Field7: '',
            Field8: '',
            Field9: '',
            Field10: '',
          };

          this.selectedTask = [];
          this.getTasks();
          this.taskTypes.forEach((el) => {
            this.getOrdersOfType(el);
          });
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onColumnsChanged() {
    this.localStorageData.other.groupsChecked = this.groups
      .filter((group) => group.checked)
      .map((el) => {
        return el.name;
      });

    this.resizeSingleBox();
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getTasks();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData?.sidePanel?.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  onScanerInputChanged(e) {
    // this.setSearchCriteria('Barcode')
    this.localStorageData.filter.orderBy = 'EAN';
    this.filterValue = e.toString();
    this.getServiceTypes();
    this.getTasks();
    this.taskTypes.forEach((el) => {
      this.getOrdersOfType(el);
    });
  }

  refreshCardResult: RefreshCardResult = 'Tak';
  dateRangeVisible: boolean = false;
  onDateRangeChoosed() {
    // if (this.refreshCardResult === 'Tak') {
    //   this.getTasks();
    //   this.taskTypes.forEach((el) => {
    //     this.getOrdersOfType(el);
    //   });
    // }
  }

  getUserParameters() {
    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }
  }
  
  filterCriteriaViewOpened: boolean = false;
  
  changeFilterCriteria(e: any): void {
    let updateDocuments: boolean = e.autoEmit;
    if (e.sortDirection !== this.localStorageData?.filter?.sortOrder) {
      this.localStorageData.filter.order = e.sortDirection;
      updateDocuments = true;
    }
    if (e.selectedItem.value !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = e.selectedItem.value;
    }
    //this.updateKanbanLocalStorageData();
    if (updateDocuments) {
      this.getTasks();
    }
  }
}

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getReservationPositions()"
  (onHidden)="event.onHiddenPopUp(); sumPosition = 0"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <!-- <dx-button text="{{'buttons.choose' |translate}}" type="success" style="margin-right: 6px;"
        [id]="'choosed'+unicalGuid" *ngIf="!readOnly" [disabled]="creadOnly"></dx-button> -->
      <dx-button
        type="success"
        [text]="'documentsOrders.transferReservation' | translate"
        [disabled]="readOnly || sumPosition == 0"
        [id]="'transferReservation' + unicalGuid"
        (onClick)="moveReservationPositions()"
        style="margin-right: 6px"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <div class="d-flex justify-content-start">
          <div style="width: calc(100% - 720px)">
            <p class="p-header-info" style="margin-top: 10px">
              {{ "form-commercial-operation.grid.articleName" | translate }}:
              <strong>{{ product[0]?.Name }}</strong> |
              {{ "customerGroups.catalogIndex" | translate }}:
              <strong>{{ product[0]?.IndexCatalogue }}</strong> |
              {{ "menu.measureUnits" | translate }}:
              <strong>{{ product[0]?.Unit }}</strong>
            </p>
          </div>
          <div class="text-right" style="width: 180px; padding-right: 30px">
            <p class="p-toPay-net" style="margin-bottom: 0px">
              {{ productDetails?.Stock }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "offers.stockAmount" | translate }}
            </p>
          </div>

          <div
            class="text-right border-left"
            style="width: 180px; padding-right: 30px"
          >
            <p class="p-toPay-net" style="margin-bottom: 0px">
              {{ productDetails?.SuppliersAmount }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "articles.supplierDeliver" | translate }}
            </p>
          </div>

          <div
            class="text-right border-left"
            style="width: 180px; padding-right: 30px"
          >
            <p class="p-toPay-net" style="margin-bottom: 0px">
              {{ productDetails?.RecipientAmount }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "articles.receiversReceive" | translate }}
            </p>
          </div>

          <div class="text-right border-left" style="width: 180px">
            <p class="p-toPay-net" style="margin-bottom: 0px">
              {{ productDetails?.Reserved }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "documentsOrders.reservation" | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="header-document" style="margin-top: 0px">
        <div class="d-flex justify-content-start">
          <div style="width: calc(100% - 720px)">
            <p class="p-header-info" style="margin-top: 10px">
              {{
                "form-commercial-operation.parametersOfTheSelectedItem"
                  | translate
              }}:
              <strong>{{ orderDocumentNumber }}</strong>
            </p>
          </div>

          <div class="text-right" style="width: 180px; padding-right: 30px">
            <p class="p-toPay-net" style="margin-bottom: 0px">
              {{ product[0]?.Amount }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "plannedOrders.ordered" | translate }}
            </p>
          </div>

          <div
            class="text-right border-left"
            style="width: 180px; padding-right: 30px"
          >
            <p class="p-toPay-net" style="margin-bottom: 0px">
              {{ product[0]?.AmountRealized }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "plannedOrders.completed" | translate }}
            </p>
          </div>

          <div
            class="text-right border-left"
            style="width: 180px; padding-right: 30px"
          >
            <p class="p-toPay-net green-color" style="margin-bottom: 0px">
              {{ product[0]?.AmountToReservation }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "articles.toReservation" | translate }}
            </p>
          </div>

          <div class="text-right border-left" style="width: 180px">
            <p class="p-toPay-net green-color" style="margin-bottom: 0px">
              {{ product[0]?.AmountReserved }}
            </p>
            <p class="p-toPayDesc" style="margin-bottom: 0px">
              {{ "articles.reserved" | translate }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--symbol-plus-minus"
            [text]="'documentsOrders.changeAmountToTransfer' | translate"
            [disabled]="readOnly"
            [id]="'changeAmountToTransfer' + unicalGuid"
            (onClick)="changeAmountToTransfer()"
            style="margin-left: 1px"
          >
          </dx-button>
          <br />

          <app-custom-dropdown-box
            [items]="filterCriteria"
            [(selectedItem)]="valueCriteria"
            [(filterValue)]="filterValue"
            (onValueChanged)="onFilterDataChanged($event)"
            style="margin-top: 10px"
          >
          </app-custom-dropdown-box>

          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            height="calc(85vh - 381px)"
            [autoNavigateToFocusedRow]="true"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onCellDblClick)="onCellDblClick($event)"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onKeyDown)="onKeyDown($event)"
            (onFocusedRowChanged)="onDropdownnFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            [focusedRowEnabled]="true"
            #gridBank
            [hoverStateEnabled]="true"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxo-scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              [mode]="'multiple'"
              [showCheckBoxesMode]="'always'"
              selectAllMode="page"
            >
            </dxo-selection>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [width]="150"
              [allowSorting]="false"
              dataField="OrderDocumentNumber"
              headerCellTemplate="OrderDocumentNumber"
              renderAsync="true"
              [cssClass]="
                orderBy == 'OrderDocumentNumber'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
            >
            </dxi-column>
            <div *dxTemplate="let data of 'OrderDocumentNumber'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria('OrderDocumentNumber')"
              >
                {{ "form-financial-document.number" | translate }}
                <i
                  class="header-sort-icon"
                  [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                ></i>
              </div>
            </div>

            <dxi-column
              caption="S"
              width="40"
              [allowSorting]="false"
              dataField="OrderState"
              cellTemplate="statusCell"
              headerCellTemplate="StatusHeaderCellTemplate"
              renderAsync="true"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>

            <div
              *dxTemplate="let data of 'StatusHeaderCellTemplate'"
              class="text-center"
            >
              <div class="grid-header-wrapper" [id]="'status' + unicalGuid">
                {{ "production-documents.s" | translate }}
              </div>
              <dx-tooltip
                [target]="'#status' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "production-documents.status" | translate }}
                </div>
              </dx-tooltip>
            </div>

            <div *dxTemplate="let data of 'statusCell'">
              <div
                [ngClass]="{
                  statusN:
                    data.data.OrderState != 'Z' &&
                    data.data.OrderState != 'R' &&
                    data.data.OrderState != 'O' &&
                    data.data.OrderState != 'Y' &&
                    data.data.OrderState != 'C' &&
                    data.data.OrderState != 'Z',
                  statusZ:
                    data.data.OrderState == 'Z' || data.data.OrderState == 'Z',
                  statusR:
                    data.data.OrderState == 'R' || data.data.OrderState == 'C',
                  statusO: data.data.OrderState == 'O',
                  statusY: data.data.OrderState == 'Y'
                }"
              >
                <!-- data.data.RealizationState || data.data.OrderStatus || "N" -->
                {{ data.data.OrderStatus || data.data.RealizationState || "N" }}
              </div>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              width="120"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              dataField="Date"
              renderAsync="true"
              headerCellTemplate="Date"
              [cssClass]="
                orderBy == 'Date' ? 'sort-column-bg' : 'sort-column-muted'
              "
            >
            </dxi-column>
            <div *dxTemplate="let data of 'Date'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria('Date')"
              >
                {{ "form-financial-document.date" | translate }}
                <i
                  class="header-sort-icon"
                  [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                ></i>
              </div>
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.realisationDate' | translate }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="140"
              [allowSorting]="false"
              dataField="RealizationDate"
              [cssClass]="
                orderBy === 'RealizationDate'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="realisationHeaderTemplate"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'realisationHeaderTemplate'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria('RealizationDate')"
              >
                {{ "plannedOrders.realisationDate" | translate }}
                <i
                  class="header-sort-icon"
                  [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                ></i>
              </div>
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.ordered' | translate }}"
              width="120"
              [allowSorting]="false"
              cellTemplate="numberPrecision4Template"
              dataField="Amount"
              [hidingPriority]="18"
            >
            </dxi-column>
            <div *dxTemplate="let cell of 'numberPrecision4Template'">
              {{ cell.value | amountFormatter :cell}}
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.completed' | translate }}"
              width="120"
              [allowSorting]="false"
              cellTemplate="numberPrecision4Template"
              dataField="AmountRealized"
              [hidingPriority]="17"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'documentsOrders.toReserve' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="AmountToReservation"
              cellTemplate="numberPrecision4Template"
              [hidingPriority]="16"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'articles.reserved' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="AmountReserved"
              cellTemplate="numberPrecision4Template"
              [hidingPriority]="15"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'documentsOrders.toTransfer' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="AmountToTransfer"
              cellTemplate="AmountToTransfer"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'AmountToTransfer'">
              <div
                style="margin-bottom: 0px"
                *ngIf="!data.data.isInputAmount"
                class="borderNumberAmount"
                [ngClass]="{
                  borderNumberAmountRed: data.data.AmountToTransfer != 0
                }"
              >
                {{ data.data.AmountToTransfer }}
              </div>
              <dx-number-box
                [visible]="data.data.isInputAmount"
                [(ngModel)]="data.data.AmountToTransfer"
                (onKeyDown)="onFocusOut($event, data)"
                [ngModelOptions]="{ standalone: true }"
                #amountToTransfer
              ></dx-number-box>
            </div>

            <dxi-column
              caption="{{ 'locations.description' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="Description"
              [hidingPriority]="2"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              width="120"
              [allowSorting]="false"
              dataField="CustomerName"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'warehouseDocument.customerOrderNumber' | translate
              }}"
              width="180"
              [allowSorting]="false"
              dataField="CustomerOrderNumber"
              [cssClass]="
                orderBy === 'CustomerOrderNumber'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="CustomerOrderNumber"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'CustomerOrderNumber'">
              <div
                class="grid-header-wrapper"
                (click)="setSearchCriteria('CustomerOrderNumber')"
              >
                {{ "warehouseDocument.customerOrderNumber" | translate }}
                <i
                  class="header-sort-icon"
                  [ngClass]="order === 'DESC' ? 'arr-down' : 'arr-up'"
                ></i>
              </div>
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.priority' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="PriorityName"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'plannedOrders.priority' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="PriorityName"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netAmount' | translate
              }}"
              width="120"
              cellTemplate="numberPrecisionTemplate"
              width="120"
              [allowSorting]="false"
              dataField="PriceNet"
              [hidingPriority]="12"
            >
            </dxi-column>
            <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
              {{ cell.value | amountFormatter:cell }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              width="130"
              [allowSorting]="false"
              dataField="TotalNetAmount"
              cellTemplate="numberPrecisionTemplate"
              renderAsync="true"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalGrossAmount"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'bankAccounts.grid.columns.currency' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="CurrencyCode"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.netPriceCur' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalNetAmountCurrency"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.grossValCur' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalGrossAmountCurrency"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
          </dx-data-grid>

          <div
            class="count-container"
            style="margin-top: 12px; height: auto; width: calc(100% - 5px)"
          >
            <div class="row">
              <div class="col-md-6 text-left">
                <p
                  class="p-toPayDesc"
                  style="
                    text-align: left;
                    width: 120px;
                    left: 10px;
                    position: relative;
                    top: 5px;
                    font-weight: 500 !important;
                  "
                >
                  {{ "documentsOrders.moveReservation" | translate }}
                </p>

                <dx-radio-group
                  [items]="radioList"
                  [readOnly]="readOnly"
                  [(value)]="radioListValue"
                  displayExpr="label"
                  valueExpr="value"
                  style="margin-left: 130px; margin-top: -10px"
                >
                </dx-radio-group>
              </div>

              <div class="col-md-6 text-right">
                <p class="summary-amount-correction" style="margin-top: 5px">
                  <strong
                    style="
                      font-size: 18px !important;
                      line-height: 21px !important;
                    "
                    >{{ sumPosition }}</strong
                  >
                </p>
                <p class="p-toPayDesc">
                  {{ "documentsOrders.totalToMove" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-tooltip
  [target]="'#transferReservation' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
  [showEvent]="event.tooltipShowEvent"
>
  <div *dxTemplate="let data of 'content'">
    {{ "documentsOrders.transferReservation" | translate }} (F10)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#changeAmountToTransfer' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
  [showEvent]="event.tooltipShowEvent"
>
  <div *dxTemplate="let data of 'content'">
    {{ "documentsOrders.changeAmountToTransfer" | translate }} (Enter)
  </div>
</dx-tooltip>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

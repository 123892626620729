<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up title-pop-upPeyment">
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>

    <div>
      <p style="margin-bottom: 0px">{{ text }}</p>
    </div>

    <footer style="position: absolute; bottom: 20px">
      <dx-button
        text="{{
          'form-commercial-operation.selectAndreplaceDocumentNotes' | translate
        }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'replace' + unicalGuid"
        style="margin-right: 6px"
        (onClick)="onReplace(true)"
      ></dx-button>

      <dx-button
        text="{{
          'form-commercial-operation.selectAndAttachToDocumentNotes' | translate
        }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onReplace(false)"
      >
      </dx-button>
    </footer>
  </div>
</dx-popup>

<dx-tooltip
  [target]="'#add' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "form-commercial-operation.selectAndAttachToDocumentNotes" | translate }}
    (F10)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#replace' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "form-commercial-operation.selectAndreplaceDocumentNotes" | translate }}
    (F9)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#cancel' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.cancel" | translate }} (Esc)
  </div>
</dx-tooltip>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<dx-popup
  [width]="1050"
  [height]="470"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  title="Wybierz dokument"
  [showTitle]="true"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <!-- title="{{ 'articles.groupChangeTradeData' | translate }}" -->

  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="confirmDocument()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="onClosed.emit()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (Enter)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-data-grid
      #gridBank
      [dataSource]="dataSource"
      [wordWrapEnabled]="false"
      [showBorders]="true"
      shortcut
      [height]="heightGrid"
      [columnResizingMode]="'widget'"
      [remoteOperations]="true"
      (onRowDblClick)="onRowDblClick()"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      (onFocusedRowChanged)="onFocusedRowChanged($event)"
      [id]="'grid' + unicalGuid"
      [(focusedRowKey)]="focusedRowKey"
      [focusedRowEnabled]="true"
      [hoverStateEnabled]="true"
      (onOptionChanged)="event.onOptionChanged($event)"
    >
      <dxo-load-panel [enabled]="false"></dxo-load-panel>
      <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
      <dxo-scrolling mode="virtual"></dxo-scrolling>
      <dxo-paging [pageSize]="pageSize"></dxo-paging>

      <ng-container *ngIf="documentType === 'invoice'">
        <dxi-column
          caption="{{ 'buffor.number' | translate }}"
          alignment="left"
          width="100"
          [allowSorting]="false"
          dataField="InvoiceDocumentNumber"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'form-commercial-operation.dateOfIssue' | translate }}"
          alignment="left"
          width="250"
          [allowSorting]="false"
          dataType="date"
          [format]="{ type: event.dateFormat }"
          dataField="DateOfInvoiceDocument"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'servicesDevices.saleDate' | translate }}"
          alignment="left"
          width="250"
          [allowSorting]="false"
          dataType="date"
          [format]="{ type: event.dateFormat }"
          dataField="DateOfSell"
        >
        </dxi-column>
      </ng-container>

      <ng-container *ngIf="documentType === 'warehouse'">
        <dxi-column
          caption="{{ 'buffor.number' | translate }}"
          alignment="left"
          width="100"
          [allowSorting]="false"
          dataField="WarehouseDocumentNumber"
        >
          <!-- nr dok. mag. -->
        </dxi-column>
        <dxi-column
          caption="{{ 'production-documents.date' | translate }}"
          alignment="left"
          width="250"
          [allowSorting]="false"
          dataField="DateOfWarehouseDocument"
          dataType="date"
          [format]="{ type: event.dateFormat }"
        >
          <!-- data dok. mag. -->
        </dxi-column>
      </ng-container>

      <dxi-column
        caption="{{ 'form-commercial-operation.grid.netValue' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="TotalNetAmount"
        cellTemplate="amountTemplate"
      >
      </dxi-column>
      <dxi-column
        caption="{{ 'form-commercial-operation.grid.grossValue' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="TotalGrossAmount"
        cellTemplate="amountTemplate"
      >
      </dxi-column>

      <dxi-column
        *ngIf="documentType === 'warehouse'"
        caption="{{ 'production-documents.docNumberCommercial' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="InvoiceDocumentNumber"
      >
      </dxi-column>
      <!-- wspolne kolumny -->
      <dxi-column
        caption="{{ 'production-documents.warehouse' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="WarehouseName"
      >
      </dxi-column>
      <dxi-column
        caption="{{ 'production-documents.contractor' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="CustomerName"
      >
      </dxi-column>
      <dxi-column
        caption="{{ 'production-documents.remarks' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="Remarks"
      >
      </dxi-column>
      <dxi-column
        caption="{{ 'bankAccounts.grid.columns.currency' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="CurrencyCode"
      >
      </dxi-column>
      <dxi-column
        caption="{{
          'form-commercial-operation.TotalNetAmountCurrency' | translate
        }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="TotalNetAmountCurrency"
        cellTemplate="amount4Template"
      >
      </dxi-column>
      <dxi-column
        caption="{{
          'form-commercial-operation.TotalGrossAmountCurrency' | translate
        }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="TotalGrossAmountCurrency"
        cellTemplate="amount4Template"
      >
      </dxi-column>
      <dxi-column
        caption="{{ 'baseCurrencies.currencyValue' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="CurrencyFactor"
        cellTemplate="amount4Template"
      >
      </dxi-column>
      <dxi-column
        caption="{{ 'servicesDevices.barcode' | translate }}"
        alignment="left"
        width="250"
        [allowSorting]="false"
        dataField="EAN"
      >
      </dxi-column>

      <div *dxTemplate="let cell of 'amount4Template'">
        {{ cell.value | amountFormatter: cell : 4 }}
      </div>

      <div *dxTemplate="let cell of 'amountTemplate'">
        {{ cell.value | amountFormatter: cell }}
      </div>
    </dx-data-grid>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

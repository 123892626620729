<dx-popup
  [width]="450"
  [height]="event.deviceType != 'mobile' ? 210 : 300"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'documentOrders.deliveryOperator' | translate }}"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClosing.emit(true)"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      *ngIf="form"
    >
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 470px">
          <form [formGroup]="form">
            <label style="width: 90px"
              >{{ "intrastatCnCodes.name" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              #focusFirst
              width="276"
              formControlName="Name"
              [ngClass]="{
                'input-required': !form.controls.Name.valid && submitted
              }"
              [readOnly]="readOnly"
              valueChangeEvent="keyup"
            ></dx-text-box
            ><br />

            <label style="width: 90px">Url</label>
            <dx-text-area
              width="276"
              formControlName="Url"
              [readOnly]="readOnly"
              valueChangeEvent="keyup"
            >
            </dx-text-area>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
></wapro-keyboard-shortcuts>

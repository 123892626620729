<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-commercial-operation.paymentSchedule' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxWidth]="event.maxWidthSignal()"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up title-pop-upPeyment">
      <dx-button
        text="{{ 'form-commercial-operation.approveSettlements' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        [disabled]="!IsApproveSettlementsActive"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="cancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "form-commercial-operation.approveSettlements" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="header-document" style="margin-top: 0px; margin-bottom: 6px">
        <div class="row">
          <div class="col-md-6">
            <div class="d-flex flex-row">
              <div class="text-right">
                <p class="p-header-info">
                  {{ "form-commercial-operation.net" | translate }}
                </p>
                <p class="p-header-info">{{ "menu.fk.vat" | translate }}</p>
                <p class="p-header-info">
                  {{ "form-commercial-operation.gross" | translate }}
                </p>
              </div>
              <div class="text-right" style="width: 130px">
                <p class="p-header-info">
                  <strong>{{
                    sumPosition?.TotalNetAmount?.toFixed(2)
                      | currency : " "
                      | pointReplacer
                  }}</strong>
                </p>

                <p class="p-header-info">
                  <strong>{{
                    sumPosition?.TotalTaxAmount?.toFixed(2)
                      | currency : " "
                      | pointReplacer
                  }}</strong>
                </p>

                <p class="p-header-info">
                  <strong>{{
                    sumPosition?.TotalGrossAmount?.toFixed(2)
                      | currency : " "
                      | pointReplacer
                  }}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            @if(CurrencyCode !== '' && CurrencyCode !== null && CurrencyCode !==
            "PLN"){
            <!-- waluta -->
            <div class="col-md-6">
              <div class="d-flex flex-row">
                <div class="text-right" style="width: 120px">
                  <p class="p-header-info">
                    {{ "buffor.netCurrency" | translate }} {{ CurrencyCode }}
                  </p>
                  <p class="p-header-info">
                    {{ "buffor.vatCurrency" | translate }} {{ CurrencyCode }}
                  </p>
                  <p class="p-header-info">
                    {{ "buffor.grossCurrency" | translate }}
                    {{ CurrencyCode }}
                  </p>
                </div>
                <div class="text-right" style="width: 130px">
                  <p class="p-header-info">
                    <strong>{{
                      sumPosition?.TotalNetAmountCurrency?.toFixed(2)
                        | currency : " "
                        | pointReplacer
                    }}</strong>
                  </p>

                  <p class="p-header-info">
                    <strong>{{
                      sumPosition?.TotalTaxAmountCurrency?.toFixed(2)
                        | currency : " "
                        | pointReplacer
                    }}</strong>
                  </p>

                  <p class="p-header-info">
                    <strong>{{
                      sumPosition?.TotalGrossAmountCurrency?.toFixed(2)
                        | currency : " "
                        | pointReplacer
                    }}</strong>
                  </p>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
      <div>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--add-circle"
          [text]="'buttons.add' | translate"
          [id]="'btn-add' + unicalGuid"
          [disabled]="true"
          (onClick)="onAdd()"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--mode-edit"
          stylingMode="text"
          type="default"
          [id]="'btn-edit' + unicalGuid"
          [disabled]="readOnly || focusedSelected.length === 0"
          (onClick)="onEdit()"
        >
        </dx-button>

        <dx-button
          icon="icon absui-icon--highlight-off"
          [id]="'btn-delete' + unicalGuid"
          class="btn-dx-top-menu-folder"
          [disabled]="readOnly || focusedSelected.length === 0"
          stylingMode="text"
          type="default"
          (onClick)="onDelete()"
        >
        </dx-button>

        <dx-button
          [id]="'btn-generateNetVat' + unicalGuid"
          class="outline-secondary"
          stylingMode="outlined"
          [disabled]="readOnly"
          stylingMode="text"
          type="default"
          [text]="'form-commercial-operation.generateNetVat' | translate"
          (onClick)="generatePaymentSechedule()"
        >
        </dx-button>

        <dx-tooltip
          [target]="'#btn-add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }} (Insert)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-edit' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.edit" | translate }} (F2)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-delete' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.delete" | translate }} (Del)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-generateNetVat' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "form-commercial-operation.generateNetVat" | translate }} (Ctrl +
            G)
          </div>
        </dx-tooltip>
      </div>

      <div style="margin-top: 6px">
        <dx-data-grid
          [dataSource]="dataSource()"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="heightGrid"
          [(selectedRowKeys)]="selectedRows"
          [columnResizingMode]="'widget'"
          (onInitialized)="onInitialized($event)"
          [remoteOperations]="true"
          (onRowDblClick)="onRowDblClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onContentReady)="onContentReady($event)"
          (onKeyDown)="onKeyDown($event)"
          [(focusedRowIndex)]="focusedRowIndex"
          [focusedRowEnabled]="true"
          #gridBank
          (onOptionChanged)="event.onOptionChanged($event)"
          data-cy="correction-invoice-ducument-grid"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection
            mode="single"
            showCheckBoxesMode="always"
            selectAllMode="page"
          >
          </dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            caption="T"
            [allowSorting]="false"
            [width]="50"
            dataField="TypeOfAmount"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-financial-document.grid.amount' | translate }}"
            [allowSorting]="false"
            width="100"
            cellTemplate="netAmount"
            dataField="Amount"
          >
          </dxi-column>
          <div *dxTemplate="let cell of 'netAmount'">
            {{ cell.data.Amount | amountFormatter : cell }}
          </div>

          <dxi-column
            caption="{{ 'bankAccounts.grid.columns.currency' | translate }}"
            [allowSorting]="false"
            [width]="100"
            dataField="CurrencyCode"
          >
          </dxi-column>

          <dxi-column
            caption="{{
              'form-financial-document.amountInCurrency' | translate
            }}"
            [allowSorting]="false"
            width="100"
            cellTemplate="netCurrencyAmount"
            dataField="AmountCurrency"
          >
          </dxi-column>
          <div *dxTemplate="let cell of 'netCurrencyAmount'">
            {{ cell.data.AmountCurrency | amountFormatter : cell }}
          </div>

          <dxi-column
            caption="{{ 'form-payment.paymentMethod' | translate }}"
            [allowSorting]="false"
            [width]="150"
            dataField="PaymentFormName"
          >
          </dxi-column>

          <dxi-column
            caption="{{
              'form-commercial-operation.dateOfPaymentt' | translate
            }}"
            [allowSorting]="false"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            dataField="PaymentDate"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'bankAccounts.form.accountNumber' | translate }}"
            [allowSorting]="false"
            dataField="BankAccountNumber"
          >
          </dxi-column>
        </dx-data-grid>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

@if(isAddRow()){
<app-add-payment-schedule
  [readOnly]="readOnly"
  [mode]="mode"
  [isVisible]="isAddRow()"
  (onClosing)="isAddRow.set(false)"
  (onInserted)="getPaymentSechedule(); isDeleteRow.set(false)"
  [CurrencyCode]="CurrencyCode"
  [PaymentFormName]="PaymentFormName"
  [DateOfPayment]="DateOfPayment"
  [type]="type"
  [BankAccountId]="BankAccountId"
  [CustomerId]="CustomerId"
  [InvoiceDocumentId]="InvoiceDocumentId"
  [selected]="focusedSelected"
></app-add-payment-schedule>
}

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

@if(isDeleteRow()){
<app-confirm-dialog
  [isVisible]="isDeleteRow()"
  (onRemoving)="deleteItem()"
  (onClosing)="isDeleteRow.set(false)"
></app-confirm-dialog>
}

import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { forkJoin } from 'rxjs';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { Socket } from 'src/app/socket.model';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { NestService } from './../../../nest-service.service';
import { WarehouseDocument } from '../warehouse-documents.model';
import {
  DataToPrintV2,
  ReportData,
} from 'src/app/core/reports/IReportParameters';
import { PrintService } from 'src/app/print.service';

@Component({
    selector: 'app-correction',
    templateUrl: './correction.component.html',
    styleUrls: ['./correction.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'selected',
        'typeOfDocument',
        'componentNests',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CorrectionComponent implements OnInit {
  @ViewChild('reports') reports: ReportsComponent;
  @Output() onClosing = new EventEmitter();
  @ViewChild('keyboardShortcuts') keyboardShortcuts;
  @ViewChild('btnAdd') btnAdd;

  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  widthWindow: any = '75%';
  heightWindow: any = '620px';
  typeOfDocument;

  headerDocument = {
    WarehouseDocumentNumber: '',
    DateOfWarehouseDocument: '',
    CustomerName: '',
  };

  localStorageData = {};
  sumPosition: any = {
    TotalGrossAmount: 0,
    TotalNetAmount: 0,
    TotalTaxAmount: 0,
    TotalGrossAmountPrevious: 0,
    TotalNetAmountPrevious: 0,
    TotalTaxAmountPrevious: 0,
  };

  unicalGuid;

  dataSource;
  selectedRows = [];

  heightGrid = 345;
  shortcuts: ShortcutInput[] = [];
  addRow: boolean = false;
  editRow: boolean = false;
  sumPositionIncome;
  showCorrection: boolean = false;
  editCorrection: boolean = false;
  myEventDelete;
  myEventEdit;
  myEditShow;

  openNestFinished: boolean = false;
  componentNests;
  mode;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private nestService: NestService,
    private print: PrintService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    // this.myEventDelete = this.event.delete.subscribe((res) => {
    //   if (this.isVisible && !this.readOnly) this.isDeleteRow = true;
    // });

    // this.myEventEdit = this.event.edit.subscribe((res) => {
    //   if (this.isVisible && !this.readOnly) this.editCorrectionDocument();
    // });

    // this.myEditShow = this.event.show.subscribe((res) => {
    //   if (this.isVisible && !this.readOnly) this.showCorrectionDocument();
    // });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (this.isVisible && !this.readOnly && !e.event.shiftKey)
            this.editCorrectionDocument();
          if (this.isVisible && !this.readOnly && e.event.shiftKey)
            this.showCorrectionDocument();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.isVisible &&
            !this.readOnly &&
            this.focusedSelected.length > 0
          )
            this.isDeleteRow = true;
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.addCorection();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    // this.myEventDelete.unsubscribe();
    // this.myEventEdit.unsubscribe();
    // this.myEditShow.unsubscribe();
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  correctionSums = () => {
    this.appService
      .getAuth(
        `warehouseDocuments/documents/${this.selected[0].WarehouseDocumentOriginalId}/correctionSums`
      )
      .subscribe(
        (res) => {
          this.sumPosition = res;
        },
        (error) => {
          this.isDeleteRow = false;
          this.event.httpErrorNotification(error);
        }
      );
  };

  delete = () => {
    this.findSocketAndRun('XGMKLYU1');
  };

  deleteBetween() {
    this.appService
      .deleteAuth(
        `warehouseDocuments/documents/${this.selected[0].WarehouseDocumentOriginalId}/deleteLastCorrection?extWarehouseId=${this.selected[0].WarehouseId}`
      )
      .subscribe(
        () => {
          this.dataSource.reload();
          this.correctionSums();
          this.isDeleteRow = false;
          this.findSocketAndRun('XGMKLYU2');
        },
        (error) => {
          this.isDeleteRow = false;
          this.event.httpErrorNotification(error);
        }
      );
  }
  isDeleteRow: boolean = false;
  isDelete = () => {
    if (!this.readOnly) {
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      this.findSocketAndRun('XGMKLO1');
    }

    if (this.isVisible && this.selected) {
      this.headerDocument.WarehouseDocumentNumber =
        this.selected[0].WarehouseDocumentNumber;
      this.headerDocument.DateOfWarehouseDocument =
        this.selected[0].DateOfWarehouseDocument;
      this.headerDocument.CustomerName = this.selected[0].CustomerName;

      this.sumPosition.TotalGrossAmount = this.selected[0].TotalGrossAmount;
      this.sumPosition.TotalNetAmount = this.selected[0].TotalNetAmount;
      this.sumPosition.TotalTaxAmount = this.selected[0].TotalTaxAmount;
      this.correctionSums();
      this.getDocuments();
    }
  }

  getDocuments = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}WarehouseDocuments/documents`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          const selected: any[] = data
            .filter((el) => el.IsSelected)
            .map((el) => el.FinanceDocumentId);
          if (selected.length > 0)
            this.event.deselectAllRecords(
              `warehouseDocuments/selection/documents`
            );

          if (data.length == 0) {
            this.focusedRowIndex = 0;
            this.focusedSelected = [];
          }

          if (data.length > 0) {
            this.focusedRowIndex = 0;
            this.event.setFocus(this.gridBank);
          }
          this.cd.detectChanges();
        },
        deleteUrl: `${environment.domain}WarehouseDocuments/documents`,
      }),
      reshapeOnPush: true,
    });
  };

  getLoadParams() {
    let obj: any = {};
    obj.CorrectionListForWarehouseDocumentId =
      this.selected[0].WarehouseDocumentOriginalId;
    obj.IsCorrectionDocument = true;

    obj.ExtWarehouseId = this.selected[0].WarehouseId;
    return obj;
  }

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }
  onKeyDown(e) {
    if (
      e.event.keyCode == 113 ||
      e.event.keyCode == 32 ||
      e.event.keyCode == 27
    ) {
      e.event.preventDefault();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onClosingForm = () => {
    this.addRow = false;
    this.editRow = false;
    this.showCorrection = false;
    this.editCorrection = false;
    this.cd.detectChanges();
  };

  corectionDocumentSelected;
  correctionDocumentType;
  WarehouseDocumentCorrectionLastId;
  addCorectionBetween() {
    if (this.dataSource && this.dataSource._items.length > 0)
      this.selected[0] = this.dataSource._items[0];

    let object = {
      WarehouseDocumentId: this.selected[0].WarehouseDocumentCorrectionLastId,
      DateOfWarehouseDocument: new Date(),
      WarehouseDocumentTypeId: this.selected[0].WarehouseDocumentTypeId,
      CustomerId: this.selected[0].CustomerId,
      WarehouseDocumentOriginalId: this.selected[0].WarehouseDocumentOriginalId,
      ExtWarehouseId: this.selected[0].WarehouseId,
    };

    this.WarehouseDocumentCorrectionLastId =
      this.selected[0].WarehouseDocumentCorrectionLastId;

    let postArray = [];

    let documentType = this.appService.getAuth(
      `document/sales/types/warehouse?documentContext=DOCUMENT_WAREHOUSE`
    );
    let oryginalDocument = this.appService.getAuth(
      `warehouseDocuments/documents?ObjectId=${this.selected[0].WarehouseDocumentOriginalId}&ExtWarehouseId=${this.selected[0].WarehouseId}`
    );

    postArray.push(documentType);
    postArray.push(oryginalDocument);

    forkJoin(postArray).subscribe((res: any[]) => {
      switch (res[1].data[0].TypeOfDocument.replace(' ', '')) {
        case 'WZ':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'WZk'
          );
          break;
        case 'PZ':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'PZk'
          );
          break;
        case 'ZU':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'ZUk'
          );
          break;
        case 'SU':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'SUk'
          );
          break;
        case 'WZk':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'WZk'
          );
          break;
        case 'PZk':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'PZk'
          );
          break;
        case 'ZUk':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'ZUk'
          );
          break;
        case 'SUk':
          this.correctionDocumentType = (<any>res[0]).data.find(
            (field) => field.Signature == 'SUk'
          );
          break;
        default:
          this.correctionDocumentType = null;
      }

      object = {
        WarehouseDocumentId: res[1].data[0].WarehouseDocumentCorrectionLastId,
        DateOfWarehouseDocument: object.DateOfWarehouseDocument,
        WarehouseDocumentTypeId: object.WarehouseDocumentTypeId,
        CustomerId: res[1].data[0].CustomerId,
        WarehouseDocumentOriginalId: (<any>res[1]).data[0]
          .WarehouseDocumentOriginalId,
        ExtWarehouseId: res[1].data[0].WarehouseId,
      };

      this.event.onShown();
      this.appService
        .getAuth(
          `warehouseDocuments/documents?ObjectId=${
            (<any>res[1]).data[0].WarehouseDocumentCorrectionLastId
          }&ExtWarehouseId=${this.selected[0].WarehouseId}`
        )
        .subscribe((res) => {
          this.corectionDocumentSelected = this.selected;
          this.corectionDocumentSelected[0].OryginalWarehouseDocumentNumber =
            res.data[0].WarehouseDocumentNumber;
          this.corectionDocumentSelected[0].OryginalDateOfWarehouseDocument =
            res.data[0].DateOfWarehouseDocument;
          if (this.correctionDocumentType) {
            object.WarehouseDocumentTypeId = this.correctionDocumentType.TypeId;
            this.appService
              .postAuth(
                `warehouseDocuments/documents/${this.selected[0].WarehouseDocumentId}/correctionHeader`,
                object
              )
              .subscribe(
                (res) => {
                  this.corectionDocumentSelected[0].WarehouseDocumentId =
                    res.WarehouseDocumentId;

                  this.corectionDocumentSelected[0].WarehouseDocumentTypeId =
                    this.correctionDocumentType.TypeId;
                  this.corectionDocumentSelected[0].TypeOfDocument =
                    this.correctionDocumentType.Signature;
                  this.corectionDocumentSelected[0].DateOfWarehouseDocument =
                    new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');

                  this.corectionDocumentSelected[0].WarehouseDocumentNumber =
                    '<auto>';

                  this.sumPositionIncome = res;
                  this.mode = 'add';
                  this.addRow = true;
                  this.event.onHidden();
                  this.cd.detectChanges();
                },
                (error) => {
                  this.event.httpErrorNotification(error);
                  this.event.onHidden();
                  this.cd.detectChanges();
                }
              );
          }
        });
    });
  }

  addCorection = () => {
    if (
      this.selected[0].TypeOfDocument.trim() == 'SU' ||
      this.selected[0].TypeOfDocument.trim() == 'RW'
    )
      return;

    this.findSocketAndRun('XGMKLYD1');
  };

  setFoucus = () => {
    setTimeout(() => {
      this.event.setFocus(this.btnAdd);
    }, 0);
  };

  @ViewChild('gridBank') gridBank;
  onInserted = (e: DataToPrintV2) => {
    this.dataSource.reload().then(() => {
      const index = this.dataSource._items.findIndex(
        (x: WarehouseDocument) =>
          x.WarehouseDocumentId == e.document.form.WarehouseDocumentId
      );

      if (index != -1) {
        this.focusedRowIndex = index;
        this.focusedSelected = [this.dataSource.items()[index]];
        this.event.setFocus(this.gridBank);
      }
    });
    if (this.mode == 'add') {
      this.findSocketAndRun('XGMKLYD2');
    }
    if (this.mode == 'edit') this.findSocketAndRun('XGMKLYP2');
    this.correctionSums();

    if (
      e.documentsToPrint?.includes('PZk') ||
      e.documentsToPrint?.includes('WZk')
    ) {
      this.printAfterSave({
        document: e.document,
        documentsToPrint: e.documentsToPrint,
      });
    }
  };

  async printAfterSave(e: DataToPrintV2) {
    const warehouseDocument: WarehouseDocument = e.document.form;
    let reportData: ReportData = {
      allowFiscalPrint: false,
      code: 'Dokument_magazynowy',
      currencyCode: null,
      documentData: warehouseDocument,
      documentNumber: warehouseDocument.WarehouseDocumentNumber,
      menuName: warehouseDocument.MenuName,
      objectId: warehouseDocument.WarehouseDocumentId,
      report: null,
      reportTypeId: null,
      type: warehouseDocument.TypeOfDocument.slice(0, 2),
      actions: {
        downloadPdf: false,
        sendToEdocuments: false,
        email: false,
        print: true,
      },
      isBatch: false,
      lang: null,
    };
    if (reportData.type == 'WZ') {
      reportData.reportTypeId = 2;
    } else if (reportData.type == 'PZ') {
      reportData.reportTypeId = 53;
    }
    const reportTypes = await this.print.getReportTypes(
      warehouseDocument.MenuName
    );
    // reportData.report = this.print.getReportTemplate(reportData, reportTypes);
    reportData.report = reportTypes.find(
      (x) => x.ReportId === reportData.reportTypeId
    );
    await this.print.printReport(reportData);
  }

  onRowDblClick = () => {
    this.editCorrectionDocument();
  };

  focusedSelected = [];
  menuName: string = null;
  onFocusedRowChanged = (e) => {
    if (e && e.row) {
      this.menuName = e.row.data?.MenuName || null;
      this.focusedSelected = [e.row.data];
    }
  };

  showCorrectionDocument = () => {
    if (!this.readOnly && this.focusedSelected.length > 0) {
      this.appService
        .getAuth(
          `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/correctionSums?useForOneDocument=true`
        )
        .subscribe((res) => {
          this.mode = 'show';
          this.addRow = true;
          this.cd.detectChanges();
          this.sumPositionIncome = res;
        });
    }
  };

  editCorrectionDocument = () => {
    this.findSocketAndRun('XGMKLYP1');
  };

  editCorrectionDocumentBetween() {
    if (!this.readOnly && this.focusedSelected.length > 0) {
      this.appService
        .getAuth(
          `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/correctionSums?useForOneDocument=true`
        )
        .subscribe((res) => {
          this.mode = 'edit';
          this.addRow = true;
          this.cd.detectChanges();
          this.sumPositionIncome = res;
        });
    }
  }

  onSelectionChanged = () => {};

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
    }
    //this.createForm(); //reset form with initial value

    //this.selectedtab = 0;
    this.cd.detectChanges();
  };

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGMKLO1':
        this.openNestFinished = true;
        this.findSocketAndRun('XGMKLO2');
        break;
      case 'XGMKLA1':
        this.findSocketAndRun('XGMKLA2');
        break;
      case 'XGMKLA2':
        this.afterCloseWindow();
        break;
      case 'XGMKLYD1':
        this.findSocketAndRun('XGMKLYA1', 'add');
        break;
      case 'XGMKLYP1':
        this.findSocketAndRun('XGMKLYA1', 'edit');
        break;
      case 'XGMKLYU1':
        this.findSocketAndRun('XGMKLYA1', 'delete');
        break;
      case 'XGMKLYA1':
        if (this.nestOperation === 'add') {
          this.addCorectionBetween();
        } else if (this.nestOperation === 'edit') {
          this.editCorrectionDocumentBetween();
        } else {
          this.deleteBetween();
        }
        break;
      case 'XGMKLYU2':
        this.findSocketAndRun('XGMKLYA2');
        break;
      case 'XGMKLYD2':
        this.findSocketAndRun('XGMKLYA2');
        break;
      default:
        break;
    }
  }

  componentVisible: boolean = false;
  onInitErr: boolean = false;
  isNestRunning: boolean = false;
  nestOperation: NestOperation = null;
  nest: Socket;
  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      // nieznalazlo
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  closeWindow() {
    this.findSocketAndRun('XGMKLA1');
  }

  afterCloseWindow() {
    this.isVisible = false;
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGM');
    }
  }
}

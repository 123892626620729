<dx-popup
  [width]="event.deviceType != 'mobile' ? 710 : '100%'"
  [height]="event.deviceType != 'mobile' ? 420 : 500"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="
    event.deviceType != 'mobile'
      ? title
      : title.length > 30
      ? (title | slice : 0 : 30) + '...'
      : title
  "
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <dx-radio-group
        [items]="inFullModeList"
        layout="horizontal"
        displayExpr="label"
        valueExpr="value"
        [readOnly]="readOnly"
        [(value)]="fullMode"
        (onValueChanged)="onValueChanged()"
        class="sum"
      >
      </dx-radio-group
      ><br />

      <dx-data-grid
        [dataSource]="dataSourceSuma"
        [wordWrapEnabled]="false"
        [showBorders]="true"
        [columnResizingMode]="'widget'"
        shortcut
        [height]="240"
        [columnAutoWidth]="true"
        [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxi-column
          caption=""
          dataField="name"
          alignment="left"
          [allowSorting]="false"
        ></dxi-column>

        <dxi-column
          caption="{{ 'document-type.expenditure' | translate }}"
          alignment="center"
          [allowSorting]="false"
          [hidingPriority]="4"
        >
          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.net' | translate }}"
            [width]="134"
            dataField="NetAmountOutcomeInSell"
            [allowSorting]="false"
            cellTemplate="NetAmountOutcomeInSellCell"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'NetAmountOutcomeInSellCell'">
            {{ data.data.NetAmountOutcomeInSell | amountFormatter:data }}
          </div>

          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.gross' | translate }}"
            [width]="134"
            [hidingPriority]="3"
            dataField="GrossAmountOutcomeInSell"
            [allowSorting]="false"
            cellTemplate="GrossAmountOutcomeInSellCell"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'GrossAmountOutcomeInSellCell'">
            {{ data.data.GrossAmountOutcomeInSell | amountFormatter:data }}
          </div>
        </dxi-column>

        <dxi-column
          caption="{{ 'document-type.income' | translate }}"
          alignment="center"
          [allowSorting]="false"
          [hidingPriority]="0"
        >
          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.net' | translate }}"
            [width]="134"
            dataField="IncomeNet"
            [allowSorting]="false"
            cellTemplate="IncomeNetCell"
            [hidingPriority]="1"
          >
            <dxo-format type="fixedPoint" [precision]="1"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'IncomeNetCell'">
            {{ data.data.IncomeNet | amountFormatter:data }}
          </div>

          <dxi-column
            alignment="right"
            caption="{{ 'form-commercial-operation.gross' | translate }}"
            [width]="134"
            dataField="IncomeGrodd"
            [allowSorting]="false"
            cellTemplate="IncomeGroddCell"
            [hidingPriority]="0"
          >
            <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'IncomeGroddCell'">
            {{ data.data.IncomeGrodd | amountFormatter:data }}
          </div>
        </dxi-column>
      </dx-data-grid>

      <dx-radio-group
        [items]="radioButtonList"
        layout="horizontal"
        displayExpr="label"
        valueExpr="value"
        [readOnly]="readOnly"
        [(value)]="forSelected"
        (onValueChanged)="onValueChanged()"
        class="sum"
      >
      </dx-radio-group>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { FormBuilder, Validators } from '@angular/forms';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-add-desciption',
    imports: [
        CommonModule,
        FormsModule,
        DxPopupModule,
        DxTextAreaModule,
        DxScrollViewModule,
        TranslateModule,
        ReactiveFormsModule,
        DxButtonModule,
        DxTooltipModule,
        NgShortcutsComponent,
    ],
    templateUrl: './add-desciption.component.html',
    styleUrl: './add-desciption.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['isVisible', 'selected', 'readOnly', 'title', 'mode']
})
export class AddDesciptionComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('descriptionBox') descriptionBox;

  unicalGuid;

  isVisible;
  selected;
  readOnly;
  title;
  mode;

  widthWindow = 400;
  heightWindow = 150;
  submitted: boolean = false;
  form;

  shortcuts: ShortcutInput[] = [];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit() {
    this.createForm();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  ngOnChanges() {
    if (this.mode === 'add')
      this.title = this.translate.instant('form-commercial-operation.title');
    if (this.mode === 'edit')
      this.title = this.translate.instant(
        'form-commercial-operation.editInvoice'
      );

    if (this.mode === 'edit' && this.selected.length > 0) {
      setTimeout(() => {
        this.form.patchValue(this.selected[0]);
      }, 0);
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      Description: ['', Validators.required],
      DesciptionOfFinanceDocumentPositionId: [null],
      IsDefault: [false],
    });
  }

  visibleChange = (e) => {
    this.submitted = false;

    if (!e) {
      //reset form with initial values
      this.createForm();
      this.onClosing.emit(false);
    }
  };

  onSave() {
    this.submitted = true;
    if (this.form.valid) {
      if (this.form.value.DesciptionOfFinanceDocumentPositionId === null) {
        this.appService
          .postAuth(`invoices/desciptionOfFinanceDocumentPosition`, {
            Description: this.form.value.Description,
          })
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else {
        this.appService
          .putAuth(
            `invoices/desciptionOfFinanceDocumentPosition/${this.form.value.DesciptionOfFinanceDocumentPositionId}`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  }
}

<div class="custom-dropdown-container">
    <i
      (click)="changeSortDirection()"
      id="sort-direction"
      class="icon sort-icon"
      [ngClass]="sortDirection === 'DESC' ? 'absui-icon--sort-down' : 'absui-icon--sort-up'"
    ></i>
    <div style="display: flex; align-items: center" id="change-filter">
      <div class="selected-item-label">{{findSelectedItemObject(selectedItem)?.label}}</div>
      <dx-drop-down-button
        [(opened)]="openDropDown"
        width="26"
        displayExpr="value"
        [selectedItem]="selectedItem"
        [dropDownOptions]="filterDropdownOptions"
        stylingMode="text"
        type="default"
        class="d-inline-block list-view"
        (onButtonClick)="addClassForViewDropdown()"
        style="border: none"
      >
        <div *dxTemplate="let data of 'content'" class="actions-list">
          <dx-list
            #filterList
            [dataSource]="items"
            [searchEnabled]="false"
            [grouped]="false"
            itemTemplate="listItem"
            selectionMode="single"
            (onItemClick)="changeFilterCriteria($event)"
          >
            <div *dxTemplate="let item of 'listItem'" class="actions-item">
              <div *ngIf="item.value === selectedItem" class="view-selected">
                <i class="icon absui-icon--check"></i>
                {{item.label}}
              </div>
              <div *ngIf="item.value !== selectedItem" class="view-not-selected">{{item.label}}</div>
            </div>
          </dx-list>
        </div>
      </dx-drop-down-button>
    </div>
  </div>
  
  <dx-tooltip
    [target]="'#change-filter'"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="{ name: 'dxhoverstart', delay: tooltipDelay}"
  >
    <div *dxTemplate="let data of 'content'">{{"sortBy" | translate}}</div>
  </dx-tooltip>
  
  <dx-tooltip
    [target]="'#sort-direction'"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="{ name: 'dxhoverstart', delay: tooltipDelay}"
  >
    <div *dxTemplate="let data of 'content'">{{"tooltip.sortDirection" | translate}}</div>
  </dx-tooltip>
  
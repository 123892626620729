import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
  signal,
  computed,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription, of, tap } from 'rxjs';
import { AdditionalOperationComponent } from 'src/app/core/additional-operation/additional-operation.component';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import {
  DataToPrintV2,
  ReportData,
} from 'src/app/core/reports/IReportParameters';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { TableService } from 'src/app/core/table-user-extension/table-service.service';
import {
  AdditionalTable,
  DoubleClickResult,
  RefreshCardResult,
} from 'src/app/event.model';
import { EDokumentsService } from 'src/app/libraries/e-dokuments/e-dokuments.service';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { ExportFakirService } from 'src/app/other/export-fakir.service';
import { PrintService } from 'src/app/print.service';
import { Socket } from 'src/app/socket.model';
import { environment } from '../../../environments/environment';
import { AppServices } from '../../app-services.service';
import { CustomDropdownBoxComponent } from '../../core/custom-dropdown-box/custom-dropdown-box.component';
import { GlobalDate } from '../../core/date-range/GlobalDate';
import { DynamicFilterService } from '../../core/dynamic-filter.service';
import { CustomWindowConfig } from '../../event.model';
import { EventService } from '../../event.service';
import { HelpService } from '../../help-service.service';
import { NestService } from '../../nest-service.service';
import { WarehouseDocument } from './warehouse-documents.model';
import { PrintSettingsService } from 'src/app/core/print-settings/print-settings.service';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';
import { NotesService } from 'src/app/core/notes/notes.service';
import { OnInsertedData } from 'src/app/comercial-operation/new-comercial-operation/new-comercial-operation.component';
import { additionalReportList } from './warehouse-documents.model';
const helper = new JwtHelperService();

@Component({
  selector: 'app-warehouse-documents',
  templateUrl: './warehouse-documents.component.html',
  styleUrls: ['./warehouse-documents.component.scss'],
  inputs: [
    'dropDownBoxMode',
    'readOnly',
    'popUpMode',
    'isVisible',
    'title',
    'fromAdvance',
    'customerName',
    'customerId',
    'isSelected',
    'isDisconnectWarehouseDocument',
    'customerIdAdvance',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WarehouseDocumentsComponent implements OnInit, OnChanges {
  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('gridRealHan') gridRealHan;
  exportFakirService = inject(ExportFakirService);

  @Output() onClosed = new EventEmitter();
  @Output() onChoosedDocument = new EventEmitter();
  //mobile
  modeSelectionMobile: string = 'none';
  customerIdAdvance;
  isContextMenuMobile: boolean = false;
  isDetails: boolean = false;
  isShowMoreDetails: boolean = false;
  isVisible;
  title;
  widthPopup: any = null;
  customerId;
  isSelected;
  isDisconnectWarehouseDocument;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  @ViewChild('gridBank') gridBank;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('rightTabPanel') rightTabPanel;
  @Output() onChoosed = new EventEmitter();

  @Output() onOpened = new EventEmitter();
  doubleClickResult: DoubleClickResult = 'Edycja';
  refreshCardResult: RefreshCardResult = 'Tak';
  popUpMode = false;
  isGuideActive = false;
  filterMobileVisible: boolean = false;
  fromAdvance;
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'WarehouseDocumentNumber',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 210,
    },
    mainPanelWidth: 'calc(100% - 385px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 385,
    },
    windowSize: {
      isFullscreen: false,
      width: 0,
      height: 0,
    },
    params: [],
  };
  dataSource: any;
  heightGrid: number = 200;
  isGridBoxOpened: boolean = false;
  choosingDocument = [];
  documentList;
  customerName;
  dropDownBoxMode;
  readOnly;
  isCorrection: boolean = false;

  selectedRows = [];
  focusedSelected: any[] = [];
  editRow: boolean = false;
  isPzPostponed: boolean = false;
  isTask: boolean = false;

  public translate = inject(TranslateService);

  bottomPanelTabs = [
    this.translate.instant('form-financial-document.tabs.items'),
    this.translate.instant('menu.orders'),
    this.translate.instant('warehouseDocument.dockOriginalCorrection'),
    this.translate.instant('form-commercial-operation.deliveryAddress'),
    this.translate.instant('form-financial-document.tabs.package'),
    this.translate.instant('notes.notes'),
    this.translate.instant('articles.externalTable'),
    this.translate.instant('menu.eDocuments'),
  ];
  isVerificationPosition: boolean = false;
  additionalTables: AdditionalTable[] = [];
  filterCriteria: ICustomSearchItem[] = [
    {
      value: 'DateOfWarehouseDocument',
      label: '',
      type: 'data',
      translationSrc: 'form-financial-document.date',
    },
    {
      value: 'WarehouseDocumentNumber',
      label: '',
      translationSrc: 'form-financial-document.number',
    },
    {
      value: 'CustomerName',
      label: '',
      translationSrc: 'form-financial-document.payer-type.contractor',
    },
    {
      value: 'TotalNetAmount',
      label: '',
      type: 'number',
      translationSrc: 'form-commercial-operation.grid.netValue',
    },
    { value: 'EAN', label: '', translationSrc: 'constractors.barcode' },
  ];
  paramsObj;

  isCustomer: boolean = false;
  unicalGuid;

  userExtensionColumns = {
    Pole1: {
      IsActive: false,
      Name: 'pole1',
    },
    Pole2: {
      IsActive: false,
      Name: 'pole2',
    },
    Pole3: {
      IsActive: false,
      Name: 'pole3',
    },
    Pole4: {
      IsActive: false,
      Name: 'pole4',
    },
    Pole5: {
      IsActive: false,
      Name: 'pole5',
    },
    Pole6: {
      IsActive: false,
      Name: 'pole6',
    },
    Pole7: {
      IsActive: false,
      Name: 'pole7',
    },
    Pole8: {
      IsActive: false,
      Name: 'pole8',
    },
    Pole9: {
      IsActive: false,
      Name: 'pole9',
    },
    Pole10: {
      IsActive: false,
      Name: 'pole10',
    },
  };

  EtykietyDokMag: boolean = false;
  UEtykietyDokMag: boolean = false;
  isExternalTableVisible: boolean = false;
  shortcuts: ShortcutInput[] = [];
  columnsChooserVisible: boolean = false;
  permissionToChooseColums: boolean = false;
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  filterCriteriaStatus = [
    { value: true, label: 'zablokowane' },
    { value: false, label: 'niezablokowane' },
  ];

  filterCriteriaAtachments = [
    { value: true, label: 'z załącznikami' },
    { value: false, label: 'bez załączników' },
  ];

  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  documentFinances = [
    {
      value: 'InvoiceToday',
      label:
        'Dokumenty magazynowa, dla których trzeba dziś wystawić dokument handlowy',
    },
    {
      value: 'InvoiceBeforeToday',
      label:
        'Dokumenty magazynowe po terminie wystawienia dokumentu handlowego',
    },
  ];
  fakeOrderBy: any = null;
  checkBoxList: any[] = [
    {
      text: '',
      modelName: 'paymentDocument',
      items: [
        {
          label: 'warehouseDocument.paymentDocument',
          disabled: false,
          value: 2,
        },
        {
          label: 'warehouseDocument.warehouseDocumentPayments',
          disabled: false,
          value: 0,
        },
        {
          label: 'warehouseDocument.warehouseDocument',
          disabled: false,
          value: 1,
        },
      ],
      value: 0,
      readOnly: false,
      layout: 'vertical',
    },
  ];

  isShowBlock = null;
  isDeleteRow: boolean = false;

  addRow: boolean = false;
  showRow: boolean = false;
  editCorrection: boolean = false;

  isShowSum: boolean = false;

  perEdocuments: boolean = false;
  perSum: boolean = false;
  showCorrection: boolean = false;
  isClipboardVisible: boolean = false;

  perPrevCorrect: boolean = false;
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();
  isFilterPanelComponent = false;
  ObjectId;
  Type;
  openCustomDropDown: boolean = false;

  shortcutTable;
  isShortcutTableOpened = false;
  loadChildComponent: boolean = false;
  activatedRouteSub: Subscription;
  nestsSub: Subscription;
  globalNestSub: Subscription;
  closeNest;
  mode;
  isCorrect: boolean = false;
  user_id;
  isLabel: boolean = false;
  pageSize: number = 100;
  focusOnLastRow: RefreshCardResult = 'Nie';
  mobileOperationsVisible: boolean = false;

  contextMenuPositionSignal = computed(() => {
    return [
      {
        text: this.translate.instant('recordCard.articleCard'),
        disabled: !this.articleCardPermission,
        itemIndex: '1111_000_KARTA_ART',
      },
      {
        text: this.translate.instant('warehouseDocument.relatedItems'),
        disabled: false,
        itemIndex: 0,
      },
      {
        text: this.translate.instant('offers.card'),
        disabled: false,
        itemIndex: 1,
      },
    ];
  });
  deleteFilter = false;
  isAllSelected: boolean = false;
  visibleAdvanceFilters = false;
  contextMenuWarehouse = [
    { text: 'Pokaż', icon: 'icon absui-icon--form-items' },
  ];
  readonly DynamicFilterDictionaryCode = 'DOCUMENT_WAREHOUSE';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Dokument_magazynowy';
  dynamicFilterUserDefinitions: any[] = [];
  productionDocumentsEditMode = false;

  OstrzegODokumZDnia;
  KasowanieMWMP;
  userId: number = null;

  confirmEditingWarehouseDocumentRelatedSADDDocument = false;

  selectedDocumentsData: WarehouseDocument[] = [];

  eDocumentsVisible: boolean;
  event = inject(EventService);

  disabledEdit: boolean = false;
  remarksInvoiceDokument: string = '';
  isOutcome: boolean = false;

  additionalOperationList = signal([]);
  isEditDeleteDisabled = signal(true);
  contextMenuSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        disabled: !this.perABD.addBtn || this.event.readOnly,
        translationSrc: 'buttons.add',
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        disabled:
          !this.perABD.editBtn ||
          this.isEditDeleteDisabled() ||
          this.event.readOnly,
        translationSrc: 'buttons.edit',
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        disabled:
          !this.perABD.deleteBtn ||
          this.isEditDeleteDisabled() ||
          this.event.readOnly,
        translationSrc: 'buttons.delete',
      },
      {
        text: this.translate.instant('buttons.show'),
        icon: 'icon absui-icon--form-items',
        itemIndex: 3,
        disabled: !this.perABD.showBtn || this.isEditDeleteDisabled(),
        translationSrc: 'buttons.show',
      },
      {
        text: this.translate.instant('buttons.duplicate'),
        itemIndex: 13,
        icon: 'icon absui-icon--files-copy',
        disabled: !this.PDokMag || this.isEditDeleteDisabled(),
        translationSrc: 'warehouse.cloneDocument',
      },
      {
        text: this.translate.instant('stockHistory.sum'),
        icon: 'icon absui-icon--sum',
        itemIndex: 4,
        disabled: !this.perSum,
        translationSrc: 'stockHistory.sum',
      },
      {
        icon: 'icon absui-icon--file-edit',
        text: this.translate.instant('warehouseDocument.corrections'),
        itemIndex: 20,
        translationSrc: 'warehouseDocument.corrections',
        disabled: this.isEditDeleteDisabled(),
      },
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 5,
        translationSrc: 'repairFunctions.selectAll',
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 6,
        translationSrc: 'articles.setUnselectedAll',
        disabled: this.selectedAmount() === 0,
      },
      {
        text: this.translate.instant('showSelected'),
        icon: '',
        itemIndex: 7,
        items: [],
        translationSrc: 'showSelected',
        disabled: this.selectedAmount() === 0,
      },

      {
        text: this.translate.instant('labels.labels'),
        itemIndex: 8,
        items: [
          {
            text: this.translate.instant('labels.assign'),
            itemIndex: 11,
            disabled: !this.EtykietyDokMag,
          },
          {
            text: this.translate.instant('labels.delete'),
            itemIndex: 12,
            disabled: !this.UEtykietyDokMag,
          },
        ],
        translationSrc: 'labels.labels',
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        itemIndex: 14,
        items: this.additionalOperationList(),
        translationSrc: 'form-commercial-operation.additionalOperations',
      },
      {
        text: this.translate.instant('tasks.addTask'),
        itemIndex: 10,
        items: [],
        translationSrc: 'tasks.addTask',
      },
      {
        text: this.translate.instant('menu.eDocuments'),
        itemIndex: 30,
        disabled: !this.perEdocuments,
      },
    ];
  });
  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        text: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: this.event.deviceType === 'mobile',
      },
      ...this.contextMenuSignal(),
    ];
  });
  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  isPrintDisabled = signal<boolean>(false);
  isRecordCardVisible: boolean;
  recordCardObjectId: number;
  recordCardMode: RecordCardMode;
  articleCardPermission: boolean;
  isCalculateQuantitativelyVisible = signal(false);
  isMobileReportsPopupVisible: boolean;
  additionalReportList: additionalReportList[] = [
    {
      Name: 'Rejestry - dokumenty magazynowe',
      Translation: 'warehouse.registersWarehouseDocuments',
      value: 'registers_warehouseDocuments',
    },
    {
      Name: 'Rejestry - dokumenty rozchodowe',
      Translation: 'warehouse.registersExpenseDocuments',
      value: 'registers_expenseDocuments',
    },
  ];

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppServices,
    private dynamicFilterService: DynamicFilterService,
    private router: Router,
    private tableService: TableService,
    public cd: ChangeDetectorRef,
    public edocs: EDokumentsService,
    public nestService: NestService,
    public print: PrintService,
    private helpService: HelpService,
    private printSettings: PrintSettingsService,
    public notesService: NotesService
  ) {
    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.print.onRefreshRequested.pipe(takeUntilDestroyed()).subscribe(() => {
      this.dataSource.reload();
      this.cd.detectChanges();
    });
    this.translate
      .get('form-commercial-operation.selectTheDocumentAndItsCorrections')
      .subscribe((text) => {
        this.contextMenuCorection[0].text = text;
      });

    this.userId = this.event.returnUserId();
    this.OstrzegODokumZDnia = this.event.getConfigurationParameter(
      'OstrzegODokumZDnia',
      true
    );
    this.translate.get('buttons.show').subscribe((text) => {
      this.contextMenuWarehouse[0].text = text;
    });

    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getDocuments();
      try {
        this.cd.detectChanges();
      } catch {}
    });

    this.translate
      .get('selectMultiple')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));
    this.user_id = helper.decodeToken(
      localStorage.getItem('WAPRO-HTML-token')
    ).user_id;

    this.event.translateSearchboxLabels(this.filterCriteria);

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests || {};

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );
  }

  contextMenuOperationsMobile = [
    { name: 'Zaznacz wiele', icon: 'icon checkAll', itemIndex: 99 },
    { name: 'Suma', icon: 'icon absui-icon--sum', itemIndex: 4 },
  ];

  isBlockRow: boolean = false;
  textBlock: string = '';

  isNewInvoiceDocument: boolean = false;
  InvoiceDocumentId;
  closeNewComercialOperation = () => {
    this.isNewInvoiceDocument = false;
    this.printSettings.currentComponentName.set('warehouseDocuments');
    this.cd.detectChanges();
    if (this.newInvoiceDocumentCode === '1111_000_DM_NA_DH') {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DH_E2');
    } else if (this.newInvoiceDocumentCode === '1111_000_DM_NA_DM') {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DM_E2');
    }
    this.newInvoiceDocumentCode = null;
  };

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData.sidePanel.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  resizeIcon = [
    'icon absui-icon--arrow-drop-right',
    'icon absui-icon--arrow-drop-left',
  ];
  currentResizeIcon = this.resizeIcon[0];
  showRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '385px';
    this.width = 385;
    this.localStorageData.sidePanel.isVisible = true;
    this.localStorageData.sidePanel.width = `385px`;
    this.localStorageData.mainPanelWidth = 'calc(100% - 385px)';
    this.rightPanel.nativeElement.style.width = `calc(100% - 385px)`;
    this.currentResizeIcon = this.resizeIcon[0];
  };

  hideRightPanel = () => {
    this.leftPanel.nativeElement.style.width = '10px';
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;

    this.localStorageData.sidePanel.isVisible = false;
    this.localStorageData.sidePanel.width = `21px`;
    this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    this.currentResizeIcon = this.resizeIcon[1];
  };

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getDocuments();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  visibleAdvanceFiltersWithoutPanel = false;

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onInsertedDocument = (_e: OnInsertedData) => {
    this.isNewInvoiceDocument = false;

    this.event.showNotification(
      'success',
      this.translate.instant('buffor.documentAdded')
    );
    this.deselectAllRecords();
    this.getDocuments();
    this.cd.detectChanges();
    if (this.newInvoiceDocumentCode === '1111_000_DM_NA_DH') {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DH_E2');
    } else if (this.newInvoiceDocumentCode === '1111_000_DM_NA_DM') {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DM_E2');
    }
    this.newInvoiceDocumentCode = null;
  };

  documentIdAdditionalField: number = null;
  newInvoiceDocumentCode: string = null;
  paramsConfirmClone;
  visibleUserExtensions = false;
  isCloneDocument: boolean = false;
  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  selectAll() {
    if (this.fromAdvance) {
      return;
    }
    this.modeSelectionMobile = 'multiple';
    this.isAllSelected = true;
    this.appService
      .postAuth(`warehouseDocuments/selection/documents`, this.paramsObj)
      .subscribe(({ RowNumber }) => {
        this.selectedAmount.set(RowNumber);
      });
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'WarehouseDocumentsComponent',
      this.event.encryptString(this.localStorageData)
    );

    this.getDocuments();
  }

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  checkAddInvoiceDocument = (document?) => {
    document ? (document = document) : ([document] = this.focusedSelected);

    if (
      this.selectedRows.length == 0 &&
      document.length == 1 &&
      document[0].InvoiceDocumentId != null
    ) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.itIsNotPossibleToIssueAnInvoiceToTheWarehouse',
          { number: document[0].InvoiceDocumentNumber }
        )
      );
      return false;
    }

    if (this.selectedRows.length > 0) {
      let pomCustomerList = [];

      this.selectedRows.forEach((el) => {
        let rowIndex = this.gridBank.instance.getRowIndexByKey(el);
        let row = this.dataSource._items[rowIndex];
        pomCustomerList.push(row);
      });

      if (!this.allWithoutInvoice(pomCustomerList)) {
        this.event.showNotification(
          'info',
          this.translate.instant(
            'form-commercial-operation.itIsNotPossibleToIssueAnInvoiceTo'
          )
        );
        return false;
      }

      if (!this.allTheSame(pomCustomerList)) {
        this.event.showNotification(
          'info',
          this.translate.instant('form-commercial-operation.ItIsNotPossible')
        );
        return false;
      }
    }

    return true;
  };

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') e.items = [];
  }

  onTaskClosing = () => {
    this.isTask = false;
    this.cd.detectChanges();
  };

  allWithoutInvoice = (array) => {
    let isInvoice: boolean = false;
    array.forEach((el) => {
      if (el.InvoiceDocumentId != null) {
        isInvoice = true;
      }
    });

    return !isInvoice;
  };

  allTheSame(array) {
    let first = array[0].CustomerId;
    return array.every(function (element) {
      return element.CustomerId === first;
    });
  }

  onSingleRecordClosing() {
    this.isDelivery = false;
    this.printSettings.currentComponentName.set('warehouseDocuments');
    this.cd.detectChanges();
  }

  createInvoicesDocumentPopupVisible: boolean = false;
  onlyShow: boolean = false;
  objectToDelivery: any;
  isDelivery: boolean = false;
  isStatus: boolean = false;
  selectedOwnOperation: any = null;
  runOwnAdditionalOperation(operation: any) {
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(
      `XGMLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`
    );
  }

  runInnerOwnOperation() {
    const data = this.event.prepareAdditionalOperationData(
      this.selectedOwnOperation,
      this.currentDate,
      this.selectedRows.length == 0
        ? this.focusedSelected[0]?.WarehouseDocumentId
        : null
    );
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if (this.selectedOwnOperation?.RefreshList) {
          this.dataSource.reload();
        }
        this.findSocketAndRun(
          `XGMLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`
        );
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      },
    });
  }

  contextMenuClick = (e) => {
    console.log(e);
    if (e.itemData.IsOuter) {
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }
    let BlokDokHandl = this.event.checkPermissions('BlokDokMag');
    switch (e.itemData.itemIndex) {
      case '1111_000_EKS_JPK_MAG':
        this.isExportVisible.set(true);
        break;
      case 20:
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.showCorrect();
        }
        break;
      case 0:
        if (!this.readOnly && this.perABD.addBtn) {
          this.beforeAddRowVisible();
        }
        break;
      case 1:
        if (!this.readOnly && this.perABD.editBtn) {
          this.editDocument();
        }
        break;
      case 2:
        this.isDelete();
        break;
      case 3:
        if (this.perABD.showBtn) {
          this.editDocument(true, 'IsOutcome');
        }
        break;
      case 4:
        this.isShowSum = true;
        break;
      case 5:
        this.skipSendSelectionEvent = true;
        this.gridBank.instance.selectAll();
        this.selectAll();
        break;
      case 6:
        this.skipSendSelectionEvent = true;
        this.selectedRows = [];
        this.deselectAllRecords();
        break;
      case 7:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case '1111_000_SCHOWCDM':
        let el;
        if (!this.selectedRows.length) {
          el = this.focusedSelected[0];
        }
        this.copyToClipboard(el?.WarehouseDocumentId);
        break;
      case '1111_000_SCHOWVDM':
        this.findSocketAndRun('XGMLEXDOKMAG_BRSCHOWVDM_E1');
        break;
      case 10:
        this.createCrmTask();
        break;
      case 11:
        if (!this.readOnly && this.EtykietyDokMag) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isLabel = true;
          }
        }
        break;

      case 12:
        if (!this.readOnly && this.UEtykietyDokMag) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.event.deleteLabel('warehouseDocuments/selection/label');
            setTimeout(() => {
              this.selectedRows = [];
              this.dataSource.reload();
            }, 100);
          }
        }
        break;
      case 30:
        this.eDocumentsVisible = true;
        this.cd.detectChanges();
        break;
      case '1111_000_BLOKDOK':
        if (!this.readOnly && BlokDokHandl) {
          this.textBlock =
            'form-commercial-operation.doYouPerformDocumentBlock';
          this.findSocketAndRun('XGMLEXDOKMAG_BRWBLOKDOK_E1');
        }
        break;
      case '1111_000_DMIKOR':
        if (this.focusedSelected.length > 0) {
          this.resetTypeForShortcut();
          this.selectInvoiceAndCorrections(
            this.focusedSelected[0].WarehouseDocumentId
          );
        }
        break;
      case '1111_000_ODBLOKDOK':
        if (!this.readOnly && BlokDokHandl) {
          this.textBlock =
            'form-commercial-operation.doYouPerformDocumentUnblock';
          this.findSocketAndRun('XGMLEXDOKMAG_BRWODBLOKDOK_E1');
        }
        break;
      case '1111_000_DO_KSIEGOW':
        if (!this.readOnly) {
          this.findSocketAndRun('XGMLEXDOKMAG_BRWDO_KSIEGOW_E1');
        }
        break;
      case '1111_000_OD_KSIEGOW':
        if (!this.readOnly) {
          this.findSocketAndRun('XGMLEXDOKMAG_BRWOD_KSIEGOW_E1');
        }
        break;
      case '1111_000_DM_NA_DH':
        if (
          this.focusedSelected.length > 0 &&
          this.focusedSelected[0].Semaphore != null
        ) {
          this.event.showNotification(
            'info',
            this.translate.instant(
              'form-commercial-operation.theDocumentsIsEditingOnDifferentUser'
            )
          );
          return;
        }
        this.chossedTypeInvoice = null;

        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.event
            .checkIsInvoiceAvailable(
              this.focusedSelected[0].WarehouseDocumentTypeId
            )
            .then((res) => {
              if (res) {
                if (
                  this.OstrzegODokumZDnia.Value == 'Tak' &&
                  this.focusedSelected[0].TypeOfDocument.trim() != 'PZ'
                ) {
                  this.isNotShowReceipt(this.focusedSelected[0].CustomerName);
                } else {
                  this.newInvoiceDocumentCode = '1111_000_DM_NA_DH';
                  this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DH_E1');
                }
              } else {
                this.event.showNotification(
                  'error',
                  this.translate.instant(
                    'warehouseDocument.itIsNotPossibleToIssueAtradeDocument'
                  )
                );
              }
            });
        }
        break;

      case '0111_000_ROZDMZAM':
        if (this.focusedSelected.length > 0) {
          this.isCalculateQuantitativelyVisible.set(true);
        }
        break;
      case '1111_000_DM_NA_DM':
        if (this.selectedRows.length == 0) {
          this.event.showNotification(
            'info',
            this.translate.instant('buffor.selectLeastPosition')
          );
        } else if (this.event.footerInfo.VariantName === 'START') {
          this.event.showNotification(
            'info',
            'Realizacja PZ/PW na MW jest możliwa w wariancie BIZNES lub wyższym'
          );
        } else {
          this.newInvoiceDocumentCode = '1111_000_DM_NA_DM';
          this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DM_E1');
        }
        break;
      case '1111_000_WERPOZDOK':
        if (this.focusedSelected.length > 0) {
          if (this.focusedSelected[0].IsCorrectionDocument) {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-financial-document.theVerificationOperationIsNotAllowedForCorrectionDocuments'
              )
            );
            return;
          }
          this.findSocketAndRun('XGMLEXDOKMAG_BRWWERPOZDOK_E1');
        }
        break;

      case '1111_000_DM_NA_TYPDM':
        if (this.selectedRows.length == 0) {
          this.event.showNotification(
            'info',
            this.translate.instant('buffor.selectLeastPosition')
          );
          return;
        }

        this.getWarehouseDocumentTypes();
        break;

      case '1111_000_DM_NA_TYPDH':
        if (
          this.focusedSelected.length > 0 &&
          this.focusedSelected[0].Semaphore != null
        ) {
          this.event.showNotification(
            'info',
            this.translate.instant(
              'form-commercial-operation.theDocumentsIsEditingOnDifferentUser'
            )
          );
          return;
        }

        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.event
            .checkIsInvoiceAvailable(
              this.focusedSelected[0].WarehouseDocumentTypeId
            )
            .then((res) => {
              if (res) {
                this.InvoiceDocumentId = null;

                if (this.checkAddInvoiceDocument()) {
                  this.getInvoicesDocumentTypes();
                }
              } else {
                this.event.showNotification(
                  'error',
                  this.translate.instant(
                    'warehouseDocument.itIsNotPossibleToIssueAtradeDocument'
                  )
                );
              }
            });
        }
        break;
      case '0011_000_POLADODGRUP':
        if (!this.readOnly) {
          this.onlyShow = false;
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };
            this.documentIdAdditionalField = null;
            this.findSocketAndRun('XGMLEXDOKMAG_BRWPOLADODGRUP_E1');
          }
        }
        break;
      case '0011_000_POLADOD':
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.onlyShow = true;
          this.documentIdAdditionalField =
            this.focusedSelected[0].WarehouseDocumentId;
          this.additionalFieldsList = this.focusedSelected[0];
          this.findSocketAndRun('XGMLEXDOKMAG_BRWPOLADOD_E1');
        }
        break;

      case '1111_000_WZ_W_CZASIE':
        this.isOutcome = true;
        this.isPzPostponed = true;
        this.cd.detectChanges();
        break;
      case '1111_000_PZ_W_CZASIE':
        this.isOutcome = false;
        this.isPzPostponed = true;
        this.cd.detectChanges();
        break;
      case '1111_000_PACZKAGEN':
        if (!this.readOnly && this.focusedSelected.length > 0) {
          this.appService
            .postAuth(
              `warehouseDocuments/additionalOperations/addPackage/${this.focusedSelected[0].WarehouseDocumentId}`,
              null
            )
            .subscribe((res) => {
              this.objectToDelivery = res;
              this.isDelivery = true;
              this.cd.detectChanges();
            });
        }
        break;
      case 13:
        if (!this.readOnly && this.PDokMag && this.focusedSelected.length > 0) {
          this.paramsConfirmClone = {
            number: this.focusedSelected[0].WarehouseDocumentNumber,
          };
          this.isCloneDocument = true;
        }
        this.cd.detectChanges();
        break;
      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridBank.instance.getDataSource().items()[
          this.focusedRowIndex
        ];
        const id = row.WarehouseDocumentId;
        if (!this.selectedRows.includes(id)) {
          this.gridBank.instance.selectRows(id, true);
        } else {
          this.gridBank.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.localStorageData.bottomPanel.isVisible = true;
        this.getDetails(this.focusedSelected[0].WarehouseDocumentId);
        this.isDetails = true;
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;
      case '0111_000_DRUKETY':
        this.printLabels('goods');
        break;
      case '0111_000_DRUKETYADR':
        this.printLabels('address');
        break;
      case '1111_000_ZMSTATDM':
        if (!this.readOnly && this.EtykietyDokMag) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isStatus = true;
          }
        }
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
    }
  };

  printLabels(type: 'goods' | 'address') {
    if (this.selectedRows.length != 0) {
      this.isLabelPrintOperationVisible = true;
      this.labelType = type;
    } else {
      this.event.showNotification(
        'info',
        this.translate.instant('buffor.selectLeastPosition')
      );
    }
  }

  labelType: 'goods' | 'address' = 'goods';
  isLabelPrintOperationVisible: boolean = false;

  onLabelPrintOperationClosing() {
    this.isLabelPrintOperationVisible = false;
    this.selectedRows = [];
    this.deselectAllRecords();
    this.cd.detectChanges();
  }

  taskSelectedId: any = [];

  createCrmTask() {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.event.onShown();
      this.appService
        .postAuth(
          `warehouseDocuments/additionalOperations/${this.focusedSelected[0].WarehouseDocumentId}/createCrmTask`,
          null
        )
        .subscribe(
          (res) => {
            this.taskSelectedId = [res['Id']];
            this.isTask = true;
            this.cd.detectChanges();
            this.event.onHidden();
          },
          (error) => {
            this.event.httpErrorNotification(error);
            this.event.onHidden();
          }
        );
    }
  }

  invoicesDocumentsTypes: any = [];

  documentRWWZList: any[] = [];
  isCreateRWMW: boolean = false;

  objectToCreateRWMW: any = {
    TypeId: null,
    CustomerId: null,
  };

  getWarehouseDocumentTypes() {
    this.appService
      .getAuth(`document/sales/types/warehouse?skip=0&take=200`)
      .subscribe((res) => {
        this.documentRWWZList = [];
        res.data.forEach((el) => {
          if (el.Signature.trim() === 'RW' || el.Signature.trim() === 'WZ') {
            this.documentRWWZList.push(el);
          }
        });
        this.isCreateRWMW = true;
        this.mode = 'add';
        this.cd.detectChanges();
      });
  }

  onChoosedRWWZ() {
    this.isCreateRWMW = false;

    this.objectToCreateRWMW.TypeId =
      this.documentRWWZList[this.focusedDocRowIndexIncoices].TypeId;

    this.isCustomer = true;
    this.cd.detectChanges();
  }

  onCreateRWWZDocument(object) {
    this.objectToCreateRWMW = {
      TypeId: null,
      CustomerId: null,
    };
    this.event.onShown();
    this.appService
      .postAuth(
        `warehouseDocuments/additionalOperations/createOutcomeWarehouseDocument`,
        {
          OutcomeDocumentTypeId: object.TypeId,
          CustomerId: object.CustomerId,
        }
      )
      .subscribe(
        (res) => {
          this.appService
            .getAuth(`warehouseDocuments/documents?ObjectId=${res.Result.Id}`)
            .subscribe((doc) => {
              this.focusedSelected = [doc.data[0]];
              this.mode = 'edit';
              this.recalculatePosition = true;
              this.addRow = true;
              this.event.onHidden();
              this.cd.detectChanges();
            });
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  }

  getInvoiceTypes() {
    let data: any = localStorage.getItem('typesInvoice');
    if (data) {
      return of({ data: this.event.decryptString(data) });
    }
    return this.appService.getAuth(`document/sales/types/invoice`).pipe(
      tap((res) => {
        localStorage.setItem(
          'typesInvoice',
          this.event.encryptString(res.data)
        );
      })
    );
  }

  getInvoicesDocumentTypes() {
    this.event.onShown();
    this.invoicesDocumentsTypes = [];

    let type = this.focusedSelected[0].TypeOfDocument.replace(/ /g, '');
    if (
      type == 'BO' ||
      type == 'BT' ||
      type == 'MP' ||
      type == 'MW' ||
      type == 'REM' ||
      type == 'RT'
    ) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.youCannotIssueAcommercialDocument'
        )
      );
      this.event.onHidden();
      return;
    }

    this.appService
      .getAuth(`document/sales/types/warehouse?signature=${type}`)
      .subscribe((res) => {
        const IsOutcome: boolean = res.data[0].IsOutcome;

        this.getInvoiceTypes().subscribe({
          next: (res) => {
            if (IsOutcome) {
              this.invoicesDocumentsTypes = res.data.filter(
                (el) => el.DocumentType === 's'
              );
            } else {
              this.invoicesDocumentsTypes = res.data.filter(
                (el) => el.DocumentType === 'z'
              );
            }

            for (let i = this.invoicesDocumentsTypes.length - 1; i >= 0; i--) {
              if (
                this.invoicesDocumentsTypes[i].Signature == 'DRS' ||
                this.invoicesDocumentsTypes[i].Signature == 'FN' ||
                this.invoicesDocumentsTypes[i].Signature == 'FM' ||
                this.invoicesDocumentsTypes[i].Signature == 'N' ||
                this.invoicesDocumentsTypes[i].Signature == 'PM'
              ) {
                this.invoicesDocumentsTypes.splice(i, 1);
              }
            }
            this.createInvoicesDocumentPopupVisible = true;
            this.event.onHidden();
            this.cd.detectChanges();
          },
          error: () => {
            this.event.onHidden();
          },
        });
      });
  }

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
    if (this.documentIdAdditionalField) {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWPOLADODGRUP_E2');
    } else {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWPOLADOD_E2');
    }
  };

  setAdditionalFields = (e) => {
    const uri = this.documentIdAdditionalField
      ? `warehouseDocuments/additionalOperations/${this.documentIdAdditionalField}/setAdditionalFields`
      : 'warehouseDocuments/additionalOperations/setAdditionalFields';

    this.appService.putAuth(uri, e).subscribe(
      () => {
        this.visibleUserExtensions = false;
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };
        this.dataSource.reload();
        this.selectedRows = [];
        this.cd.detectChanges();
        if (this.documentIdAdditionalField) {
          this.findSocketAndRun('XGMLEXDOKMAG_BRWPOLADODGRUP_E2');
        } else {
          this.findSocketAndRun('XGMLEXDOKMAG_BRWPOLADOD_E2');
        }
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  };

  cloneSingleDocumetn(id) {
    return new Promise((resolve) => {
      this.appService
        .postAuth(`warehouseDocuments/documents/${id}/clone`, null)
        .subscribe(
          () => {
            this.dataSource.reload().then(() => {
              this.event.onHidden();
              this.cd.detectChanges();
              this.event.showNotification(
                'info',
                this.translate.instant('warehouseDocument.clonedDocument')
              );
              resolve(true);
            });
          },
          (error) => {
            this.event.onHidden();
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  async waitForClone(el) {
    await this.cloneSingleDocumetn(el);
  }

  async cloneDocument() {
    this.isCloneDocument = false;
    this.event.onShown();
    this.cd.detectChanges();

    if (this.selectedRows.length > 1) {
      for (const item of this.selectedRows) {
        await this.waitForClone(item);
      }

      return;
    }
    this.waitForClone(this.focusedSelected[0].WarehouseDocumentId);
  }

  createInterWarehouseDocument = () => {
    this.mode = 'add';
    this.appService
      .postAuth(
        `warehouseDocuments/additionalOperations/createInterWarehouseDocument`,
        null
      )
      .subscribe(
        (res) => {
          this.appService
            .getAuth(`warehouseDocuments/documents?ObjectId=${res.Result.Id}`)
            .subscribe((res) => {
              this.focusedSelected = [res.data[0]];
              this.appService
                .getAuth(
                  `document/sales/types/warehouse?signature=${res.data[0].TypeOfDocument.trim()}`
                )
                .subscribe((data) => {
                  this.focusedSelected[0].WarehouseDocumentTypeId =
                    data.data[0].TypeId;
                  this.mode = 'edit';
                  this.recalculatePosition = true;
                  this.addRow = true;
                  this.cd.detectChanges();
                });
            });
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  focusedDocRowIndexIncoices = 0;
  chossedTypeInvoice: any = null;
  createChosedInvoices = () => {
    this.chossedTypeInvoice =
      this.invoicesDocumentsTypes[this.focusedDocRowIndexIncoices];

    this.chossedTypeInvoice.document = this.focusedSelected[0];
    this.createInvoicesDocumentPopupVisible = false;
    this.createOnChosedDocument();
    this.cd.detectChanges();
  };

  onDocRowDblClick() {
    this.createChosedInvoices();
  }
    
  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'WarehouseDocumentsComponent',
          JSON.stringify(this.localStorageData)
        );
        this.getDocuments();
      }
    })
  }

  createOnChosedDocument = () => {
    if (!this.checkAddInvoiceDocument()) {
      return;
    }

    let type = this.focusedSelected[0].TypeOfDocument.replace(/ /g, '');
    if (
      type == 'BO' ||
      type == 'BT' ||
      type == 'MP' ||
      type == 'MW' ||
      type == 'REM' ||
      type == 'RT'
    ) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.youCannotIssueAcommercialDocument'
        )
      );
      this.event.onHidden();
      return;
    }

    const UwagiDokMagNaDokHan = this.event
      .decryptString(localStorage.getItem('companyConfiguration'))
      .find((field) => field.Field.FieldCode == 'UwagiDokMagNaDokHan').Value;
    if (UwagiDokMagNaDokHan == 'Tak') {
      this.remarksInvoiceDokument = this.focusedSelected[0].Remarks;
    }

    this.isNewInvoiceDocument = true;
    this.cd.detectChanges();
  };

  createInvoiceDocument = (document) => {
    if (!this.checkAddInvoiceDocument(document)) {
      return;
    }

    let type = document.TypeOfDocument.replace(/ /g, '');
    if (
      type == 'BO' ||
      type == 'BT' ||
      type == 'MP' ||
      type == 'MW' ||
      type == 'REM' ||
      type == 'RT'
    ) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.youCannotIssueAcommercialDocument'
        )
      );
      this.event.onHidden();
      return;
    }

    this.event.onShown();
    this.appService
      .postAuth(
        `warehouseDocuments/additionalOperations/${document.WarehouseDocumentId}/createInvoiceDocument`,
        null
      )
      .subscribe(
        (res) => {
          this.InvoiceDocumentId = [
            { InvoiceDocumentId: res.Result.Id, CurrencyCode: '' },
          ];

          document.WarehouseDocumentCorrectionLastId !=
            document.WarehouseDocumentId || document.IsCorrectionDocument
            ? (this.disabledEdit = true)
            : (this.disabledEdit = false);

          this.isNewInvoiceDocument = true;

          const UwagiDokMagNaDokHan = this.event
            .decryptString(localStorage.getItem('companyConfiguration'))
            .find(
              (field) => field.Field.FieldCode == 'UwagiDokMagNaDokHan'
            ).Value;
          if (UwagiDokMagNaDokHan == 'Tak') {
            this.remarksInvoiceDokument = document.Remarks;
          }

          this.cd.detectChanges();
          this.event.onHidden();
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  setForAccounting = (setValue: boolean) => {
    let url =
      this.selectedRows.length > 0
        ? 'warehouseDocumentId=0'
        : `warehouseDocumentId=${this.focusedSelected[0].WarehouseDocumentId}`;

    this.appService
      .postAuth(
        `warehouseDocuments/additionalOperations/setForAccounting?SetValue=${setValue}&${url}`,
        { setValue: setValue }
      )
      .subscribe(
        () => {
          let msg = setValue
            ? this.translate.instant(
                'warehouseDocument.theDocumentToBePostedWasMarked'
              )
            : this.translate.instant('warehouseDocument.unlockedForEditing');
          this.event.showNotification('info', msg);
          this.dataSource.reload();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          if (setValue) {
            this.findSocketAndRun('XGMLEXDOKMAG_BRWDO_KSIEGOW_E2');
          } else {
            this.findSocketAndRun('XGMLEXDOKMAG_BRWOD_KSIEGOW_E2');
          }
        }
      );
  };

  blockUnblockDocument = () => {
    this.isBlockRow = false;

    let isBlock =
      this.textBlock == 'form-commercial-operation.doYouPerformDocumentBlock'
        ? true
        : false;
    let id =
      this.selectedRows.length > 0
        ? ''
        : `&warehouseDocumentId=${this.focusedSelected[0].WarehouseDocumentId}`;

    let href = `${isBlock}${id}`;
    this.appService
      .postAuth(
        `warehouseDocuments/additionalOperations/blockUnblock?isBlocked=${href}`,
        null
      )
      .subscribe(
        () => {
          if (
            this.textBlock ==
            'form-commercial-operation.doYouPerformDocumentBlock'
          ) {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-commercial-operation.theDocumentHasBeenBlocked'
              )
            );
          }

          if (
            this.textBlock ==
            'form-commercial-operation.doYouPerformDocumentUnblock'
          ) {
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-commercial-operation.theDocumentHasBeenUnblocked'
              )
            );
          }
          this.dataSource.reload();
          this.selectedRows = [];
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          if (isBlock) {
            this.findSocketAndRun('XGMLEXDOKMAG_BRWBLOKDOK_E2');
          } else {
            this.findSocketAndRun('XGMLEXDOKMAG_BRWODBLOKDOK_E2');
          }
        }
      );
  };

  clipboardId: number = null;

  copyToClipboard(id: number = null) {
    this.clipboardId = id;
    this.findSocketAndRun('XGMLEXDOKMAG_BRWSCHOWCDM_E1');
  }

  copyToClipboardBetween() {
    if (!this.clipboardId) {
      this.event.saveToClipboard('DOCUMENT_WAREHOUSE', 0, 'copyAll');
    } else {
      this.event.saveToClipboard(
        'DOCUMENT_WAREHOUSE',
        this.clipboardId,
        'copyAll'
      );
    }
    this.findSocketAndRun('XGMLEXDOKMAG_BRWSCHOWCDM_E2');
  }

  disabledChange(e) {
    this.isEditDeleteDisabled.set(e);
  }

  iloscArtBezPrzecinka: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue && !this.fromAdvance) {
      this.deselectAllRecords();
    }
  }

  ExtWarehouseId: number = null;

  ngOnInit(): void {
    this.getViewConfigurations();
    this.printSettings.currentComponentName.set('warehouseDocuments');
    this.readOnly = this.event.readOnly;
    this.iloscArtBezPrzecinka =
      this.event.getConfigurationParameter('IloscArtBezPrzecinka')?.Value ===
      'Tak';

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 210;
    }
    this.getUserParameters();
    if (this.event.isAdditionalTableAvailable()) {
      this.additionalTables = this.event.getTablesByGroupCode(
        this.USER_EXTENSIONS_GROUP_CODE
      );
    }
    this.permissionToChooseColums =
      this.event.checkPermissions('KonfKolDokMag');
    //sprawdzam uprawnienia do dodawania, edycji i usuwania
    this.perABD = this.event.checkPermissionsBtn(
      'DDokMag',
      'EDokMag',
      'UDokMag',
      'ODokMag'
    );

    this.perEdocuments = !this.event.isDisabledEDocuments('Warehouse');
    this.perSum = this.event.checkPermissions('SumDokMag');
    this.perPrevCorrect = this.event.checkPermissions('KorDokMag');

    this.activatedRoute.queryParams.subscribe((params) => {
      this.ObjectId = params.id;
      this.customerId = params.customerId;
      if (params.customerId) {
        this.customerFilter.CustomerId = params.customerId;
        this.customerFilter.CustomerName = params.customerName;
      }
      this.Type = params.type;
    });

    this.deselectAllRecords();
    this.getTypeTree().then(() => {
      this.typeTree[0].items.forEach((field) => {
        if (
          (this.Type == 'income' && field.TypeCode == 'Income') ||
          (this.Type == 'outcome' && field.TypeCode == 'Outcome')
        ) {
          this.typeFilter.warehouseDocumentTypeId =
            field.WarehouseDocumentTypeId;
          this.typeFilter.typeName = field.TypeName;
          setTimeout(() => {
            this.treeView?.instance.selectItem(
              this.typeFilter.warehouseDocumentTypeId
            );
          }, 300);
        }
      });
      if (this.ObjectId || this.customerId || this.Type) {
        this.getDocuments();
      }
    });

    setTimeout(() => {
      this.closeNest = this.nestService.findNest(
        this.componentNests?.Sockets,
        'XGMLA'
      );
      this.findSocketAndRun('XGMLO1');
    });

    this.checkRecordCardPermission();

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.dropDownBoxMode) {
          return;
        }
        if (this.isCustomer || this.isLabel) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });

    

      
  }

  checkRecordCardPermission(): void {
    const additionalOperations = this.event.decryptString(
      localStorage.getItem('appAdditionalOperationsPermissions')
    );
    const POZ_DOKMAG = additionalOperations.find(
      (op) => op.GroupCode === 'POZ_DOKMAG'
    );
    this.articleCardPermission = POZ_DOKMAG.Permissions.find(
      (x) => x.Shortcut === 'Ctrl+Shift+['
    )
      ? true
      : false;
  }

  additionalOperationShortcutSub: Subscription = null;

  onValueChangedState = (e) => {
    this.isShowBlock = e;
    setTimeout(() => {
      if (e == '') {
        this.isShowBlock = null;
      }
    }, 0);

    this.getDocuments();
  };

  isShowAtachments = null;
  onAtachmentsValueChanged = (e) => {
    this.isShowAtachments = e;
    setTimeout(() => {
      if (e == '') {
        this.isShowAtachments = null;
      }
    }, 0);

    this.getDocuments();
  };

  isComercialDocument = null;
  onComercialDocumentValueChanged = (e) => {
    this.isComercialDocument = e;
    setTimeout(() => {
      if (e == '') {
        this.isComercialDocument = null;
      }
    }, 0);

    this.getDocuments();
  };

  @ViewChild('treeView') treeView;
  getViewConfigurations = () => {
    if (this.event.deviceType != 'mobile') {
      try {
        if (
          !localStorage.getItem('WarehouseDocumentsComponent') &&
          !this.dropDownBoxMode
        ) {
          this.heightGrid = window.innerHeight - 210;
          this.gridBank?.instance?.updateDimensions();

          this.appService
            .getAuth(`viewConfigurations/WarehouseDocumentsComponent`)
            .subscribe((res) => {
              if (res.Configuration != null) {
                localStorage.setItem(
                  'WarehouseDocumentsComponent',
                  res.Configuration
                );
                this.localStorageData = JSON.parse(res.Configuration);
              }
              this.setUserWindowConf();
              this.getDocuments();
            });
          return;
        }

        if (!this.dropDownBoxMode) {
          this.localStorageData = JSON.parse(
            localStorage.getItem('WarehouseDocumentsComponent')
          );
          this.setUserWindowConf();
        }

        this.getDocuments();
      } catch (e) {
        this.onInitErr = true;
      }
    }
  };

  setUserWindowConf = () => {
    try {
      this.filterValue = this.localStorageData.filter.value;
      if (this.localStorageData.tree.length > 0) {
        this.onItemClickType({
          itemData: {
            WarehouseDocumentTypeId:
              this.localStorageData.tree[0].value.WarehouseDocumentTypeId,
            TypeName: this.localStorageData.tree[0].value.TypeName,
          },
        });

        setTimeout(() => {
          this.treeView?.instance.selectItem(
            this.localStorageData.tree[0].value.WarehouseDocumentTypeId
          );
        }, 0);
      }

      if (this.localStorageData.bottomPanel.isVisible) {
        setTimeout(() => {
          this.ngModelChange('first');
        }, 0);
      }

      this.selectedtabIndex = this.localStorageData.bottomPanel.selectedIndex;

      if (
        this.additionalTables.length &&
        this.localStorageData?.additionalTable?.TableId
      ) {
        const name = this.additionalTables.find(
          (el) => el.TableId === this.localStorageData?.additionalTable?.TableId
        )?.Name;

        const index = this.bottomPanelTabs.findIndex(
          (tab) => tab === this.translate.instant('articles.externalTable')
        );

        this.bottomPanelTabs[index] = name;
        this.localStorageData.additionalTable.text = name;
      }

      this.updateColumns();
      this.width = Number(
        this.localStorageData.sidePanel.width.replace('px', '')
      );
    } catch (e) {}
  };

  updateColumns() {
    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.gridBank?.instance;
        if (!instance) {
          return;
        }

        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          if (col.width !== 'auto') {
            instance.columnOption(col.caption, 'width', col.width);
          }
          instance.columnOption(col.caption, 'fixed', col.fixed);
        });
        this.cd.detectChanges();
      }, 500);
    }
  }

  onRowClick = (e) => {
    try {
      this.focusedSelected = [e.data];
    } catch {}
  };

  ngOnDestroy() {
    this.exportFakirService.isSelectMode.set(false);
    this.additionalOperationShortcutSub?.unsubscribe();
    this.tableService.removeTableContext(this.USER_EXTENSIONS_GROUP_CODE);
    // this.localStorageData.columns = this.event.setWidthColumnGrid(
    //   this.gridBank
    // );
    this.currentDateSub?.unsubscribe();

    this.activatedRouteSub.unsubscribe();
    this.globalNestSub.unsubscribe();
    this.isAutoOnboardingSub?.unsubscribe();

    if (this.event.deviceType != 'mobile') {
      if (
        localStorage.getItem('WarehouseDocumentsComponent') &&
        JSON.stringify(this.localStorageData) !=
          localStorage.getItem('WarehouseDocumentsComponent')
      ) {
        let object = {
          View: 'WarehouseDocumentsComponent',
          Configuration: JSON.stringify(this.localStorageData),
          TenantId: this.event.footerInfo.TenantId,
        };
        localStorage.setItem(
          'WarehouseDocumentsComponent',
          JSON.stringify(this.localStorageData)
        );
        this.appService
          .postAuth(`viewConfigurations`, object)
          .subscribe(() => {});
      }
    }
  }

  PDokMag: boolean = false;

  ngAfterViewInit() {
    if (!this.additionalTables.length) {
      const index = this.bottomPanelTabs.findIndex(
        (tab) => tab === this.translate.instant('articles.externalTable')
      );
      // remove from bottomPanelTabs array item with index 5
      this.bottomPanelTabs.splice(index, 1);
      // this.bottomPanelTabs.pop();
    }

    this.PDokMag = this.event.checkPermissions('PDokMag');
    this.translate
      .get('warehouseDocument.paymentDocument')
      .subscribe((text) => (this.checkBoxList[0].items[0].label = text));
    this.translate
      .get('warehouseDocument.warehouseDocumentPayments')
      .subscribe((text) => (this.checkBoxList[0].items[1].label = text));
    this.translate
      .get('warehouseDocument.warehouseDocument')
      .subscribe((text) => (this.checkBoxList[0].items[2].label = text));

    this.translate
      .get('warehouseDocument.isBlock')
      .subscribe((text) => (this.filterCriteriaStatus[0].label = text));
    this.translate
      .get('warehouseDocument.unblocked')
      .subscribe((text) => (this.filterCriteriaStatus[1].label = text));

    this.translate
      .get('warehouseDocument.withAttachments')
      .subscribe((text) => (this.filterCriteriaAtachments[0].label = text));
    this.translate
      .get('warehouseDocument.withoutAttachments')
      .subscribe((text) => (this.filterCriteriaAtachments[1].label = text));

    this.translate
      .get('warehouseDocument.warehouseDocumentsWithComercialDocument')
      .subscribe((text) => (this.documentFinances[0].label = text));
    this.translate
      .get('warehouseDocument.warehouseDocumentsAfterDateIssuing')
      .subscribe((text) => (this.documentFinances[1].label = text));

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.cd.detectChanges();

    this.EtykietyDokMag = this.event.checkPermissions('DEtykietyDokMag');
    this.UEtykietyDokMag = this.event.checkPermissions('UEtykietyDokMag');

    

    this.event
      .getUserExtension(this.USER_EXTENSIONS_GROUP_CODE, this.userExtensionColumns)
      .then((res) => {
        try {
          this.localStorageData.columns =
            this.event.getAdditionalColumnsCaptions(
              res,
              this.localStorageData.columns
            );
        } catch {}
      });

    
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getDocuments();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          let BlokDokHandl = this.event.checkPermissions('BlokDokMag');
          if (!this.readOnly && BlokDokHandl) {
            this.textBlock =
              'form-commercial-operation.doYouPerformDocumentUnblock';
            this.findSocketAndRun('XGMLEXDOKMAG_BRWODBLOKDOK_E1');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + O',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          let BlokDokHandl = this.event.checkPermissions('BlokDokMag');
          if (!this.readOnly && BlokDokHandl) {
            this.textBlock =
              'form-commercial-operation.doYouPerformDocumentUnblock';
            this.findSocketAndRun('XGMLEXDOKMAG_BRWODBLOKDOK_E1');
          }
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRows.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.selectChoosed();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            this.selectedRows.length &&
            this.exportFakirService.isSelectMode()
          ) {
            this.cancelChoosed();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.event.isDisabledEDocuments('Warehouse')) {
            this.edocs.getToEDocuments(
              this.USER_EXTENSIONS_GROUP_CODE,
              this.focusedSelected[0]?.WarehouseDocumentId,
              this.focusedSelected[0]?.WarehouseDocumentNumber
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.popUpMode) {
            this.isVisible = false;
            this.onClosed.emit(true);
          }
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            this.setCustomerFromFocusedRow();
          } else {
            this.isCustomer = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + b',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          let BlokDokHandl = this.event.checkPermissions('BlokDokMag');
          if (!this.readOnly && BlokDokHandl) {
            this.textBlock =
              'form-commercial-operation.doYouPerformDocumentUnblock';
            this.findSocketAndRun('XGMLEXDOKMAG_BRWBLOKDOK_E1');
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && this.perABD.addBtn) {
            if (
              !this.addRow &&
              !this.editRow &&
              !this.showRow &&
              !this.isLabel &&
              !this.editInventory &&
              !this.isDeleteRow &&
              !this.isDeleteInventory &&
              !this.isShowSum &&
              !this.isCorrection &&
              !this.isClipboardVisible &&
              !e.event.shiftKey
            ) {
              this.beforeAddRowVisible();
            }
          }

          if (
            !this.readOnly &&
            this.PDokMag &&
            this.focusedSelected.length > 0 &&
            e.event.shiftKey
          ) {
            this.paramsConfirmClone = {
              number: this.focusedSelected[0].WarehouseDocumentNumber,
            };
            this.isCloneDocument = true;
          }
          this.cd.detectChanges();
        },
        preventDefault: true,
      },

      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && this.perABD.editBtn && !e.event.shiftKey) {
            if (
              this.focusedSelected.length > 0 &&
              !this.addRow &&
              !this.editRow &&
              !this.showRow &&
              !this.isLabel &&
              !this.editInventory &&
              !this.isDeleteRow &&
              !this.isDeleteInventory &&
              !this.isShowSum &&
              !this.isCorrection &&
              !this.isClipboardVisible
            ) {
              this.editDocument();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.perABD.deleteBtn) {
            if (
              !this.editRow &&
              !this.showRow &&
              !this.addRow &&
              this.perABD.deleteBtn &&
              !this.editInventory &&
              !this.isDeleteRow &&
              !this.isLabel &&
              !this.isDeleteInventory &&
              !this.isShowSum &&
              !this.isCorrection &&
              !this.isClipboardVisible
            ) {
              this.isDelete();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.perABD.showBtn) {
            if (
              this.focusedSelected.length > 0 &&
              !this.addRow &&
              !this.editRow &&
              !this.showRow &&
              !this.editInventory &&
              !this.isDeleteRow &&
              !this.isLabel &&
              !this.isDeleteInventory &&
              !this.isShowSum &&
              !this.isCorrection
            ) {
              this.editDocument(true, 'IsOutcome');
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + k',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            this.perPrevCorrect &&
            this.focusedSelected.length > 0
          ) {
            this.showCorrect();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + c',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          let el;
          if (!this.selectedRows.length) {
            el = this.focusedSelected[0];
          }
          this.copyToClipboard(el?.WarehouseDocumentId);
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.altKey) {
            this.isShowSum = true;
            this.cd.detectChanges();
          }

          if (e.event.altKey && !this.readOnly) {
            this.router.navigateByUrl('trade-operations/sales');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + v',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.findSocketAndRun('XGMLEXDOKMAG_BRSCHOWVDM_E1');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.focusedSelected.length == 1) {
            this.isExternalTableVisible = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 0;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.mousedown();
            this.mouseup();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 1;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.mousedown();
            this.mouseup();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 2;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.mousedown();
            this.mouseup();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 3;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.mousedown();
            this.mouseup();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 4;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.mousedown();
            this.mouseup();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }
          this.selectedtabIndex = 5;
          if (!this.localStorageData.bottomPanel.isVisible) {
            this.mousedown();
            this.mouseup();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex === 0) {
            this.localStorageData.sidePanel.selectedIndex = 1;
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/dokumenty/magazynowe/dokumenty-magazynowe'
          );
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.deleteFilter = true;
            if (
              !this.advancedFilterTemplateData &&
              this.localStorageData.advancedFilter
            ) {
              this.localStorageData.advancedFilter =
                this.dynamicFilterService.createDefaultAdvancedFilter(
                  this.localStorageData.advancedFilter?.IsPinned,
                  this.localStorageData.advancedFilter.PinnedFilters
                );
              this.cd.detectChanges();
            }
            this.onlySelected = false;
            this.event.setCurrentDate(new GlobalDate(), false, true);
            this.resetTypeForShortcut();
            this.resetContrahentFilterForShortcut();
            this.getDocuments();
            this.cd.detectChanges();
            this.deleteFilter = false;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + j',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('address');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + J',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('address');
        },
        preventDefault: true,
      },
      {
        key: 'alt + shift + t',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('goods');
        },
        preventDefault: true,
      },
      {
        key: 'alt + shift + T',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.printLabels('goods');
        },
        preventDefault: true,
      }
    );

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.focusedSelected.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
            }
          },
          preventDefault: true,
        });
        this.cd.detectChanges();
      }
    }

    setTimeout(() => {
      if (!this.readOnly) {
        this.readOnly = this.event.readOnly;
      }
      this.loadChildComponent = true;

      if (this.router.url == '/warehouse/warehouse-documents/add') {
        this.beforeAddRowVisible();
      }

      let idDocument;
      this.activatedRoute.params.subscribe(
        (params) => (idDocument = parseInt(params['warehouseDocumentId']))
      );
      this.activatedRoute.params.subscribe(
        (params) => (this.mode = params['mode'])
      );
      if (idDocument) {
        this.appService
          .getAuth(`warehouseDocuments/documents?ObjectId=${idDocument}`)
          .subscribe((res) => {
            this.focusedSelected = [res.data[0]];
            if (this.mode == 'edit') {
              this.editDocument();
            }
            if (this.mode == 'show' && this.perABD.showBtn) {
              this.editDocument(true);
            }
          });
      }

      if (
        !this.localStorageData.skipUserGuide &&
        this.isAutoOnboarding &&
        this.isInNormalMode()
      ) {
        this.isGuideActive = true;
      }

      this.cd.detectChanges();
    }, 1000);

    if (this.event.deviceType === 'mobile') {
      this.getDocuments();
    }
  }

  setCustomerFromFocusedRow() {
    if (
      this.focusedSelected[0] &&
      this.customerFilter.CustomerId !== this.focusedSelected[0].CustomerId
    ) {
      this.customerFilter.CustomerId = this.focusedSelected[0].CustomerId;
      this.customerFilter.CustomerName = this.focusedSelected[0].CustomerName;
      this.getDocuments();
    }
  }

  isInNormalMode() {
    return !(this.dropDownBoxMode || this.event.deviceType === 'mobile');
  }

  showCorrect = () => {
    if (
      this.focusedSelected[0].SignatureOfDocument != null &&
      (this.focusedSelected[0].SignatureOfDocument.trim() === 'WZ' ||
        this.focusedSelected[0].SignatureOfDocument.trim() === 'WZk' ||
        this.focusedSelected[0].SignatureOfDocument.trim() === 'PZk' ||
        this.focusedSelected[0].SignatureOfDocument.trim() === 'PZ')
    ) {
      this.isCorrection = true;
      this.cd.detectChanges();
      return;
    }

    this.event.showNotification(
      'info',
      this.translate.instant(
        'warehouseDocument.CorrectionsApplyOnlyWZandGRNdocuments'
      )
    );
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRows = [];
      this.onChoosed.emit(null);
    }
  };

  onOpenedChanged = (e) => {
    this.onOpened.emit(e);
    if (e) {
      setTimeout(() => {
        this.gridBank.instance.focus();
      }, 500);
    }
  };

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
    this.updateColumns();
    localStorage.setItem(
      'WarehouseDocumentsComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  mapColumnKey(col: any) {
    return col.dataField;
  }

  typeTree = [];

  setTypeTree = (res) => {
    this.typeTree = [
      {
        id: '1',
        WarehouseDocumentTypeId: res.WarehouseDocumentTypeId,
        TypeName: res.TypeName,
        TypeCode: res.TypeCode,
        Types: res.Types,
        expanded: true,
        items: res.Types,
      },
    ];
    this.setItems(this.typeTree, 'Types');
  };
  getTypeTree = () => {
    return new Promise((resolve) => {
      if (localStorage.getItem('WarehouseDocumentsType')) {
        this.setTypeTree(
          this.event.decryptString(
            localStorage.getItem('WarehouseDocumentsType')
          )
        );
        resolve(true);
      } else {
        this.refreshTypeTree(resolve);
      }
    });
  };

  refreshTypeTree = (resolve) => {
    this.appService.getAuth(`WarehouseDocuments/documents/typeTree`).subscribe(
      (res) => {
        localStorage.setItem(
          `WarehouseDocumentsType`,
          this.event.encryptString(res)
        );

        this.setTypeTree(res);
        if (resolve) {
          resolve(true);
        }
      },
      (error) => {
        if (resolve) {
          resolve(false);
        }
        this.event.httpErrorNotification(error);
      }
    );
  };

  setItems = (item, typeName) => {
    // if (!item.TypeName.includes('MP-') && !item.TypeName.includes('MW-')) {
    item.forEach((field) => {
      field.items = field[typeName];

      field.expanded = true;
      if (field.items != null) {
        if (
          this.event.footerInfo.VariantName === 'START' ||
          this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE' ||
          this.event.footerInfo.VariantName === 'FAKTUROWANIE' ||
          this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO' ||
          this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO'
        ) {
          field.items = field.items.filter(
            (el) => !el.TypeName.includes('MP-') && !el.TypeName.includes('MW-')
          );
        }

        this.setItems(field[typeName], typeName);
      }
    });
    // }
  };

  typeFilter = {
    warehouseDocumentTypeId: null,
    typeName: '',
  };
  onItemClickType = (e, getDocuments = true) => {
    if (e.itemData.TypeCode == 'All') {
      this.typeFilter.warehouseDocumentTypeId = null;

      this.localStorageData.tree = [];
    } else {
      let tree = this.localStorageData.tree.findIndex(
        (field) => field.key == 'TypeDocument'
      );

      if (tree == -1) {
        this.localStorageData.tree.push({
          key: 'TypeDocument',
          value: {
            WarehouseDocumentTypeId: e.itemData.WarehouseDocumentTypeId,
            TypeName: e.itemData.TypeName,
          },
        });
      } else {
        this.localStorageData.tree[tree].value = {
          WarehouseDocumentTypeId: e.itemData.WarehouseDocumentTypeId,
          TypeName: e.itemData.TypeName,
        };
      }

      this.typeFilter.warehouseDocumentTypeId =
        e.itemData.WarehouseDocumentTypeId;
      this.typeFilter.typeName = e.itemData.TypeName;
    }

    if (getDocuments) {
      this.getDocuments();
    }
  };

  resetType = () => {
    this.localStorageData.tree = [];
    this.typeFilter.warehouseDocumentTypeId = null;
    this.typeFilter.typeName = '';
    this.treeView.instance.unselectAll();
    this.restoreTreeConfig();
    this.getDocuments();
  };

  resetTypeForShortcut() {
    this.localStorageData.tree = [];
    this.typeFilter.warehouseDocumentTypeId = null;
    this.typeFilter.typeName = '';
    this.treeView.instance.unselectAll();
    this.restoreTreeConfig();
  }

  customerFilter = {
    CustomerId: null,
    CustomerName: '',
  };
  onChoosedCustomer = (e) => {
    if (e) {
      if (this.objectToCreateRWMW.TypeId != null) {
        this.objectToCreateRWMW.CustomerId = e.CustomerId;
        this.isCustomer = false;
        this.onCreateRWWZDocument(this.objectToCreateRWMW);
        return;
      }

      this.customerFilter.CustomerId = e.CustomerId;
      this.customerFilter.CustomerName = e.Name;
      this.isCustomer = false;
      this.getDocuments();
    }
  };

  resetContrahentFilter = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
    this.getDocuments();
  };

  resetContrahentFilterForShortcut() {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
  }

  onFilterDataChanged(e) {
    this.localStorageData.filter.orderBy = e.selectedItem.value;
    this.filterValue = e.filterValue;
    this.localStorageData.filter.value = e.filterValue;
    this.getDocuments(true);
  }

  //bottom panel resize
  heightBottomPanel;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  heightBottomGrid = 210;

  selectedtabIndex = 0;

  onItemClickTab = () => {
    try {
      if (!this.localStorageData.bottomPanel.isVisible) {
        this.localStorageData.bottomPanel.isVisible = true;
        this.localStorageData.bottomPanel.height = 230;
        this.heightBottomGrid = 210;
        this.heightGrid = window.innerHeight - this.heightBottomGrid - 176;

        this.cd.detectChanges();
      }

      this.gridBank?.instance?.updateDimensions();
    } catch {}
  };

  onSelectionTabChanged = () => {
    this.localStorageData.bottomPanel.selectedIndex = this.selectedtabIndex;
    try {
      if (this.focusedSelected[0].WarehouseDocumentId) {
        this.getDetails(this.focusedSelected[0].WarehouseDocumentId);
      }
    } catch {}
  };

  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle(true);
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle();
  }

  resizingBottom = (e) => {
    let height;
    this.localStorageData.bottomPanel.isVisible = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '230px';
    if (height < 235) {
      height = 235;
    }

    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 25;

    this.heightGrid = window.innerHeight - height - 200;
    this.localStorageData.bottomPanel.height = `${height}px`;
    this.tabPanel.height = '100%';
    this.gridBank?.instance?.updateDimensions();
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle();
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop =
      this.event.getPanelBorderStyle(true);
  };

  timeoutHandler;

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  ngModelChange = (noChangeState?) => {
    if (noChangeState != 'first' && this.event.deviceType != 'mobile') {
      this.localStorageData.bottomPanel.isVisible =
        !this.localStorageData.bottomPanel.isVisible;
    }

    if (!this.dropDownBoxMode && this.event.deviceType != 'mobile') {
      if (
        this.localStorageData.bottomPanel.isVisible &&
        this.localStorageData.bottomPanel.height == 210
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
        this.heightGrid = window.innerHeight - 435;
        this.tabPanel.height = '100%';
        this.heightBottomGrid = 210;
      } else if (
        this.localStorageData.bottomPanel.isVisible &&
        this.localStorageData.bottomPanel.height != 210
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanel.height;
        this.heightGrid =
          window.innerHeight -
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          200;
        this.tabPanel.height = '100%';
        this.heightBottomGrid =
          Number(this.localStorageData.bottomPanel.height.replace('px', '')) -
          25;
      } else {
        this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
        this.tabPanel.height = '34px';
        this.bottomSidePanel.nativeElement.style.height = `34px`;
        this.heightGrid = window.innerHeight - 210;
      }
      if (this.focusedSelected.length > 0) {
        this.getDetails(this.focusedSelected[0].WarehouseDocumentId);
      }
      this.gridBank?.instance?.updateDimensions();
    }
    if (!this.localStorageData.bottomPanel.isVisible) {
      this.isDetailOnFocus = false;
    }
  };

  //left panle resizing
  widthLeftPanel = 385;
  width = 345;

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 385) {
      width = 385;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;

    this.localStorageData.sidePanel = {
      isVisible: true,
      width: `calc(100% - ${rightWidth}px)`,
      selectedIndex: 0,
    };
    this.localStorageData.sidePanel.width = `${width}px`;
    this.localStorageData.mainPanelWidth = `calc(100% - ${rightWidth}px)`;
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.leftPanel.nativeElement.style.width = '10px';
      this.width = 21;
      this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;

      this.localStorageData.sidePanel.isVisible = false;
      this.localStorageData.sidePanel.width = `21px`;
      this.localStorageData.mainPanelWidth = `calc(100% - 26px)`;
    } else {
      this.leftPanel.nativeElement.style.width = '385px';
      this.width = 385;
      this.localStorageData.sidePanel.isVisible = true;
      this.localStorageData.sidePanel.width = `385px`;
      this.localStorageData.mainPanelWidth = 'calc(100% - 385px)';
      this.rightPanel.nativeElement.style.width = `calc(100% - 385px)`;
    }
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle(true);
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft =
      this.event.getPanelBorderStyle();
  };

  //---------------------------------------------------------------------

  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;
  getDocuments = async (fromSearch: boolean = false) => {
    return new Promise((resolve) => {
      this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'WarehouseDocumentId',
          onBeforeSend: this.event.onBeforeSendDataSource,
          loadUrl: `${environment.domain}WarehouseDocuments/documents`,
          loadParams: this.getLoadParams(),
          onAjaxError: this.event.onAjaxDataSourceError,
          onLoading(loadOptions: LoadOptions) {
            loadOptions.requireTotalCount = true;
          },
          onLoaded: (data) => {
            this.focusedRowIndex =
              data.length > this.event.initFocusedRowIndex('warehouseDocuments')
                ? this.event.initFocusedRowIndex('warehouseDocuments')
                : 0;
            if (data.length == 0) {
              this.focusedSelected = [];
              return;
            }
            const toAdd = data.filter(
              (el) =>
                el.IsSelected &&
                this.selectedRows.indexOf(el.WarehouseDocumentId) === -1
            );
            for (let item of toAdd) {
              this.selectedRows.push(item.WarehouseDocumentId);
              this.skipSendSelectionEvent = true;
            }

            const toDelete = [];
            for (let id of this.selectedRows) {
              const item = data.find((el) => el.WarehouseDocumentId === id);
              if (item && !item.IsSelected) {
                toDelete.push(id);
              }
            }
            for (let id of toDelete) {
              this.selectedRows.splice(
                this.selectedRows.findIndex((el) => el === id),
                1
              );
              this.skipSendSelectionEvent = true;
            }
            setTimeout(() => {
              if (this.focusedRowIndex < 0) {
                this.focusedRowIndex = 0;
              }

              if (data.length >= this.focusedRowIndex) {
                this.onFocusedRowChanged({
                  row: { data: data[this.focusedRowIndex] },
                });
              } else if (this.focusedRowIndex > data.length) {
                // if (!this.dataSource.paginate()) {
                this.focusedRowIndex = data.length - 1;
                // } else {
                //   let pageIndex = this.dataSource.pageIndex();
                //   let pageSize = this.dataSource.pageSize();
                //   if (pageSize + pageIndex * pageSize < this.focusedRowIndex) {
                //     this.dataSource.pageIndex(pageIndex++);
                //     this.dataSource.load();
                //     return;
                //   }
                //   let index = this.focusedRowIndex - pageIndex * pageSize;
                //   setTimeout(() => {
                //     this.focusedRowIndex = this.indexToFocus;
                //     this.onFocusedRowChanged({
                //       row: { data: data[this.indexToFocus || index] },
                //     });
                //   }, 300);
                // }
                this.onFocusedRowChanged({
                  row: { data: data[this.focusedRowIndex] },
                });
              } else if (data.length > 0) {
                this.focusedRowIndex =
                  data.length >
                  this.event.initFocusedRowIndex('warehouseDocuments')
                    ? this.event.initFocusedRowIndex('warehouseDocuments')
                    : 0;
                this.onFocusedRowChanged({
                  row: { data: data[this.focusedRowIndex] },
                });
              }

              if (data.length == 0) {
                this.focusedSelected = [];
                this.positionList = [];
                this.orderList = [];
                this.warehouseDocumentOriginalList = [];
                this.deliveryAddress = [];
                this.cd.detectChanges();
              }
              setTimeout(() => {
                if (this.firstLoad) {
                  this.firstLoad = false;
                  this.searchControl?.focusInput();
                  this.updateColumns();
                }
              }, 100);
            }, 0);

            setTimeout(() => {
              if (fromSearch) {
                this.searchControl?.focusInput();
              } else {
                this.event.setFocus(this.gridBank);
              }
            }, 100);

            this.isSelectInvoiceAndCorrections = false;
          },
          deleteUrl: `${environment.domain}WarehouseDocuments/documents`,
        }),

        reshapeOnPush: true,
        requireTotalCount: true,
      });
      resolve(this.dataSource);
    });
  };

  setFocusAfterLoad() {
    // this.focusedRowIndex = this.indexToFocus;
    // this.setFocusOnLoad = false;
    // this.onFocusedRowChanged({ row: { data: data[this.focusedRowIndex] } });
    // this.cd.detectChanges();
  }

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }

    let obj: any = {};
    (obj.OrderBy = this.localStorageData.filter.orderBy), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.Order = this.localStorageData.filter.order);

    if (this.typeFilter.warehouseDocumentTypeId != null) {
      obj.WarehouseDocumentTypeId = this.typeFilter.warehouseDocumentTypeId;
      this.customerId;
    }

    obj.ObjectId = this.ObjectId;
    if (this.customerFilter.CustomerId != null) {
      // obj.CustomerName = this.customerFilter.CustomerName;
      obj.CustomerId = this.customerFilter.CustomerId;
    }

    if (this.fromAdvance && !this.isSelected) {
      this.customerFilter.CustomerName = this.customerName;
      this.customerFilter.CustomerId = this.customerIdAdvance;
      //obj.CustomerName = this.customerName;
      obj.CustomerId = this.customerIdAdvance;
    }

    if (this.fromAdvance && this.isSelected) {
      obj.IsFiltrForAdvanceInvoice = true;
    }

    obj.dateStart =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.dateEnd =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    if (this.isSelectInvoiceAndCorrections) {
      obj.IsSelected = true;
      obj.CustomerId = this.customerId;
    }

    if (this.customerId) {
      obj.CustomerId = this.customerId;
    }
    if (this.customerId || this.ObjectId) {
      obj.dateStart = null;
      obj.dateEnd = null;
    }
    if (this.useForSelection) {
      obj.UseForSelection = true;
    }
    if (this.isShowBlock != null) {
      obj.IsBlocked = this.isShowBlock;
    }
    if (this.isShowAtachments != null) {
      obj.HasAttachment = this.isShowAtachments;
    }
    if (this.isComercialDocument == 'InvoiceToday') {
      obj.InvoiceToday = true;
    }
    if (this.isComercialDocument == 'InvoiceBeforeToday') {
      obj.InvoiceBeforeToday = true;
    }

    if (this.localStorageData.advancedFilter) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }

    if (this.isSelectInvoiceAndCorrections) {
      obj.dateStart = null;
      obj.dateEnd = null;
      obj.IsSelected = true;
    }

    if (this.exportFakirService.isSelectMode()) {
      obj.ExtWarehouseId = 0;
    }
    // this.localStorageData.filter.value &&
    if (this.filterValue) {
      switch (this.localStorageData.filter.orderBy) {
        case 'DateOfWarehouseDocument':
          if (this.filterValue != '' && this.filterValue != null) {
            obj.dateStart = this.event.setCorrectDate(this.filterValue);
            obj.dateEnd = this.event.setCorrectDate(this.filterValue);
          }
          break;
        case 'WarehouseDocumentNumber':
          obj.WarehouseDocumentNumber = this.filterValue;
          break;
        case 'CustomerName':
          obj.CustomerName = this.filterValue;
          break;
        case 'TotalNetAmount':
          obj.TotalNetAmount = this.filterValue;
          break;
        case 'EAN':
          obj.EAN = this.filterValue;
          break;
      }
    }

    this.paramsObj = obj;

    return obj;
  }

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  focusedRowIndex = 0;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.localStorageData.filter.orderBy
    );

    this.filterValue = this.event.setFilterValueBySearchKey(
      this.filterValue,
      key,
      orderByItem.type === 'data'
    );

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.filterValue) !==
          this.paramsObj[this.localStorageData.filter.orderBy]
        ) {
          this.getDocuments();
        }
        return;
      }
      this.getDocuments();
    }, 300);
  }

  onKeyDown(e) {
    if (e.event.shiftKey && e.event.key === 'J') {
      this.isExportVisible.set(true);
      e.event.preventDefault();
    }
    if (e.event.shiftKey && e.event.key === 'K') {
      this.selectInvoiceAndCorrections(
        this.positionFocused[0].InvoiceDocumentId
      );
      e.event.preventDefault();
    }

    if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.key === 'O' && e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key == 'O' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      let BlokDokHandl = this.event.checkPermissions('BlokDokMag');
      if (!this.readOnly && BlokDokHandl) {
        this.textBlock =
          'form-commercial-operation.doYouPerformDocumentUnblock';
        this.findSocketAndRun('XGMLEXDOKMAG_BRWODBLOKDOK_E1');
      }
    } else if (e.event.key == 'B' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      let BlokDokHandl = this.event.checkPermissions('BlokDokMag');
      if (!this.readOnly && BlokDokHandl) {
        this.textBlock =
          'form-commercial-operation.doYouPerformDocumentUnblock';
        this.findSocketAndRun('XGMLEXDOKMAG_BRWBLOKDOK_E1');
      }
    } else if (e.event.key === 'S' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.isShowSum = true;
    } else if (e.event.key === 'E' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (!this.event.isDisabledEDocuments('Warehouse')) {
        this.edocs.getToEDocuments(
          'DOKUMENT_MAGAZYNOWY',
          this.focusedSelected[0]?.WarehouseDocumentId,
          this.focusedSelected[0]?.WarehouseDocumentNumber
        );
      }
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (e.event.key === 'Insert' && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.beforeAddRowVisible();
    } else if (
      e.event.key === 'F2' &&
      !e.event.shiftKey &&
      this.focusedSelected.length
    ) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.editDocument();
    } else if (
      e.event.key === 'F2' &&
      e.event.shiftKey &&
      this.focusedSelected.length
    ) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.editDocument(true, 'IsOutcome');
    } else if (e.event.key === 'F7') {
      e.event.preventDefault();
      if (e.event.shiftKey) {
        this.setCustomerFromFocusedRow();
      } else {
        this.isCustomer = true;
      }
      this.cd.detectChanges();
    } else if (e.event.key === 'Enter') {
      if (this.selectedRows.length && this.exportFakirService.isSelectMode()) {
        this.selectChoosed();
      }
    } else if (e.event.key === 'Escape') {
      if (this.exportFakirService.isSelectMode()) {
        this.cancelChoosed();
      }
    } else if (e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    } else if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.gridBank.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.selectedRows = [];
      this.deselectAllRecords();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    } else if (e.event.key === 'T' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.printLabels('goods');
    } else if (e.event.key === 'J' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.printLabels('address');
    }
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getDocuments();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.deselectAllRecords();
    this.getDocuments();
  }

  deselectAllRecords() {
    this.isAllSelected = false;
    this.selectedAmount.set(0);
    return this.event.deselectAllRecords(
      'warehouseDocuments/selection/documents'
    );
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  onPostponedClosing() {
    this.isPzPostponed = false;
    this.cd.detectChanges();
    this.getDocuments();
  }

  onRowDblClick = () => {
    if (!this.readOnly) {
      if (!this.dropDownBoxMode) {
        if (this.doubleClickResult === 'Edycja') {
          this.editDocument();
        } else {
          this.editDocument(true);
        }
      } else {
        this.onChoosingItem();
      }
    }
  };

  onRowClickGrid(e) {
    if (e.data) {
      this.focusedSelected = [e.data];
    }
  }

  myTimer;
  onFocusedRowChanged = (e) => {
    try {
      this.focusedSelected = [e.row.data];

      this.event.setFocusedRowIndex('warehouseDocuments', this.focusedRowIndex);
      this.isDetailOnFocus = false;
      this.menuName = e.row.data?.MenuName || null;
      if (this.myTimer) {
        clearTimeout(this.myTimer);
      }
      this.myTimer = setTimeout(() => {
        if (this.focusedSelected.length > 0) {
          this.getDetails(this.focusedSelected[0]?.WarehouseDocumentId);
        }
      }, 300);
    } catch {}
  };

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (
      e.name === 'columns' &&
      e.fullName.indexOf('width') > -1 &&
      e.value != 'auto'
    ) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);

      try {
        const indexCol = this.localStorageData.columns.findIndex(
          (col) => col.name === this.gridBank.columns[colIndex].name
        );
        this.localStorageData.columns[indexCol].width = e.value;
      } catch (e) {}
    }

    localStorage.setItem(
      'WarehouseDocumentsComponent',
      JSON.stringify(this.localStorageData)
    );
  };

  switchOrder() {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }

    this.localStorageData.filter.order = 'ASC';
  }

  sendRequestTimer;
  onlySelected = false;
  skipSendSelectionEvent = false;
  useForSelection: boolean = false;
  onSelectionChanged = (e) => {
    this.selectedDocumentsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let toSelect = [];

        this.selectedRows.forEach((field) => {
          if (typeof field == 'object') {
            toSelect.push(field.WarehouseDocumentId);
          } else {
            toSelect.push(field);
          }
        });

        this.toSelect = [];
        if (toSelect.length) {
          this.event.selectRecords(
            `warehouseDocuments/selection/documents/select`,
            toSelect
          );
        }
      }
      if (this.toDeselect.length) {
        let toDeselect = [];
        this.toDeselect.forEach((field) => {
          if (typeof field == 'object') {
            toDeselect.push(field.WarehouseDocumentId);
          } else {
            toDeselect.push(field);
          }
        });
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of toDeselect) {
          const item = items.find((el) => el.WarehouseDocumentId === toDes);
          if (!item) {
            toRemove.push(toDeselect.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          toDeselect.splice(toRemove[i], 1);
        }
        this.selectedAmount.set(this.selectedAmount() - toDeselect.length);
        if (this.selectedAmount() < 0) {
          this.selectedAmount.set(0);
        }
        if (toDeselect.length) {
          this.event.selectRecords(
            `warehouseDocuments/selection/documents/unselect`,
            toDeselect
          );
        }
        toDeselect = [];
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  @ViewChild('eDocumentss') eDocumentss;
  getDetails = (id) => {
    this.tableService.addTableContext({
      id,
      idParentObject: this.focusedSelected[0],
      tableName: this.USER_EXTENSIONS_GROUP_CODE,
    });

    if (this.localStorageData.bottomPanel.isVisible && id) {
      switch (this.selectedtabIndex) {
        case 0:
          this.getPositions(id);
          break;
        case 1:
          this.getOrders(id);
          break;
        case 2:
          this.getOryginalDocument();
          break;
        case 3:
          this.getDeliveryAddresses(id);
          break;
        case 4:
          this.getPackages(id);
          break;
      }
    }

    if (
      this.bottomPanelTabs[this.selectedtabIndex] ===
      this.translate.instant('menu.eDocuments')
    ) {
      this.eDocumentss.getDocuments();
      this.cd.detectChanges();
    }
  };

  orderList = [];
  getOrders = (id) => {
    this.appService
      .getAuth(`orders/documents/connected?WarehouseDocumentId=${id}`)
      .subscribe(
        (res) => {
          this.orderList = res;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  warehouseDocumentOriginalList = [];
  getOryginalDocument = () => {
    this.appService
      .getAuth(
        `warehouseDocuments/documents?CorrectionListForWarehouseDocumentId=${this.focusedSelected[0].WarehouseDocumentId}`
      )
      .subscribe(
        (res) => {
          this.warehouseDocumentOriginalList = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  positionList;
  isStockLeft = true;
  getPositions = (id) => {
    this.isStockLeft = false;
    let url =
      this.focusedSelected[0].TypeOfDocument.trim() == 'REM' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'BO'
        ? `inventory/documents/${id}/positions`
        : `warehouseDocuments/documents/${id}/positions?ParentObjectId=${id}`;

    this.positionList = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentPositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        onLoaded: (data) => {
          if (
            this.focusedSelected[0].TypeOfDocument.trim() == 'REM' ||
            this.focusedSelected[0].TypeOfDocument.trim() == 'BO'
          ) {
            this.isStockLeft = true;
            data.forEach((field) => {
              field.Amount = field.StockAmount;
              field.PriceNet = field.BuyPriceNet;
              field.PriceGross = field.BuyPriceGross;
              field.TaxCode = field.TaxCodeBuy;
            });
          }
        },
        loadUrl: `${environment.domain}${url}`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
    this.cd.detectChanges();
  };

  deliveryAddress = [];
  getDeliveryAddresses = (id) => {
    this.appService
      .getAuth(`WarehouseDocuments/${id}/deliveryAddresses`)
      .subscribe(
        (res) => {
          this.deliveryAddress = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  packagesData = [];
  getPackages = (id) => {
    this.appService
      .getAuth(
        `deliveries/operators/-1/packages?ObjectId=${id}&DictionaryCode=DOCUMENT_WAREHOUSE`
      )
      .subscribe(
        (res) => {
          this.packagesData = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  deleteObject: any = null;

  deleteBetween() {
    if (
      this.focusedSelected[0].TypeOfDocument.trim() == 'REM' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'REM ' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'RT ' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'RT' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'BO ' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'BO'
    ) {
      this.appService
        .deleteAuth(
          `inventory/documents/${this.focusedSelected[0].WarehouseDocumentId}`
        )
        .subscribe(
          () => {
            this.dataSource.reload();
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    } else {
      if (
        this.focusedSelected[0].TypeOfDocument.trim() == 'MP' ||
        this.focusedSelected[0].TypeOfDocument.trim() == 'MW'
      ) {
        this.deleteObject = [{ value: 0 }];
      }

      this.appService
        .deleteAuth(
          `warehouseDocuments/documents/${
            this.focusedSelected[0].WarehouseDocumentId
          }?DeleteMode=${this.deleteObject[0].value}&${
            this.focusedSelected[0].InvoiceDocumentId
              ? 'InvoiceDocumentId=' +
                this.focusedSelected[0].InvoiceDocumentId +
                '&'
              : 'InvoiceDocumentId=&'
          }WarehouseDocumentId=${this.focusedSelected[0].WarehouseDocumentId}`
        )
        .subscribe(
          () => {
            this.positionList = [];
            this.orderList = [];
            this.warehouseDocumentOriginalList = [];
            this.deliveryAddress = [];
            this.packagesData = [];
            this.dataSource.reload();
            let index = this.selectedRows.findIndex(
              (field) => field === this.focusedSelected[0].WarehouseDocumentId
            );

            if (index != -1) {
              this.selectedRows.splice(index, 1);
            }
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
    this.isDeleteRow = false;
    this.isDeleteInventory = false;
    this.isDeleteMp = false;
  }

  afterDelete() {
    this.gridBank.focusedRowIndex = 0;
    this.gridBank.instance.focus();
    this.dataSource.reload();
  }

  delete = (e) => {
    this.deleteObject = e;
    this.findSocketAndRun('XGMLYU1');
  };

  isDeleteInventory = false;
  paramsMsgMp: any = null;
  isDeleteMp: boolean = false;
  isDeleteMpNo: boolean = false;
  isEditCorection: boolean = false;

  cancelEditCorection() {
    this.isEditCorection = false;
    this.cd.detectChanges();
  }

  isDelete = () => {
    if (this.readOnly) {
      return;
    }

    if (
      this.focusedSelected[0].TypeOfDocument.trim() == 'MP' ||
      this.focusedSelected[0].TypeOfDocument.trim() == 'MW'
    ) {
      this.KasowanieMWMP = this.event.getConfigurationParameter(
        'KasowanieMWMP',
        true
      );

      if (this.KasowanieMWMP.Value === 'Tak') {
        const regularExpression: RegExp = /:\s*(.*)$/;
        let match = this.focusedSelected[0]?.Remarks?.match(regularExpression);
        let number2 = '';
        if (match) {
          if (match[0].includes(':')) {
            match[0] = match[0].replace(':', '');
          }
          number2 = match[0].trim();
        }

        this.paramsMsgMp = {
          number: this.focusedSelected[0].WarehouseDocumentNumber,
          number2: number2,
          warehouse: this.focusedSelected[0].CustomerName,
        };
        this.isDeleteMp = true;
        this.cd.detectChanges();
        return;
      } else {
        this.isDeleteMpNo = true;
        this.cd.detectChanges();
        return;
      }
    }

    if (this.focusedSelected[0].IsCorrectionDocument) {
      this.isEditCorection = true;
      this.cd.detectChanges();
      // this.event.showNotification(
      //   'info',
      //   this.translate.instant(
      //     'warehouseDocument.removingCorrectionsFromHereIsNotPossible'
      //   )
      // );
      return;
    }
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      //this.isDeleteRow = true;
      this.checkBoxList[0].items[0].disabled = true;
      this.checkBoxList[0].items[1].disabled = true;
      this.checkBoxList[0].value = 1;

      if (
        this.focusedSelected[0].WarehouseDocumentNumber?.indexOf('WZ') != -1 ||
        this.focusedSelected[0].TypeOfDocument?.indexOf('PZ') != -1
      ) {
        this.checkBoxList[0].items[0].disabled = false;
        this.checkBoxList[0].items[1].disabled = false;
        this.checkBoxList[0].value = 0;
      }

      this.cd.detectChanges();

      if (
        this.focusedSelected[0].TypeOfDocument != 'BO' &&
        this.focusedSelected[0].TypeOfDocument != 'BO ' &&
        this.focusedSelected[0].TypeOfDocument != 'REM ' &&
        this.focusedSelected[0].TypeOfDocument != 'REM' &&
        this.focusedSelected[0].TypeOfDocument != 'RT ' &&
        this.focusedSelected[0].TypeOfDocument != 'RT'
      ) {
        this.isDeleteRow = true;
      } else {
        this.isDeleteInventory = true;
      }
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    this.isDeleteInventory = false;
    this.isDeleteMp = false;
    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 500);
  }

  closeConfirmNo() {
    this.isDeleteRow = false;
    this.isDeleteInventory = false;
    this.isDeleteMpNo = false;
    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 500);
  }

  onClosingForm = () => {
    this.addRow = false;
    this.editRow = false;
    this.showRow = false;
    this.showCorrection = false;
    this.editCorrection = false;
    this.isShowSum = false;
    if (!this.dontRefresh) {
      this.dataSource.reload().then(() => {
        this.gridBank.instance.focus();
      });
    }
    this.dontRefresh = false;
    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 500);
    this.cd.detectChanges();
  };

  pageIndex = 0;

  async findInsertedDocument(warehouseDocumentId: number) {
    return new Promise<{ document: WarehouseDocument; index: number }>(
      async (resolve) => {
        this.dataSource.pageIndex(this.pageIndex);
        let isLastPage = this.dataSource.isLastPage();
        const document = this.dataSource
          .items()
          .find((x) => x.WarehouseDocumentId == warehouseDocumentId);
        if (document) {
          const index = this.dataSource
            .items()
            .findIndex((x) => x.WarehouseDocumentId == warehouseDocumentId);
          this.indexToFocus = index;
          resolve({ document: document, index: index });
        } else if (
          !document &&
          !isLastPage &&
          this.dataSource.totalCount() > 0
        ) {
          this.pageIndex++;
          this.dataSource.pageIndex(this.pageIndex);
          await this.dataSource.load();
          resolve(this.findInsertedDocument(warehouseDocumentId));
        } else {
          resolve(undefined);
        }
        // const index = this.dataSource.items()?.findIndex(document)
      }
    );
  }

  dontRefresh: boolean = false;

  dataToPrintAfterSave: { document: any; printAfterSave: any } = null;

  async onInserted(e: DataToPrintV2) {
    if (
      e.document.form.IsDeferredIncome &&
      e.document.form.TypeOfDocument == 'PZ'
    ) {
      const treeObj = this.event.findNestedObject(
        this.typeTree[0].items,
        'TypeCode',
        'DeferredIncome'
      );
      this.typeFilter.warehouseDocumentTypeId = treeObj.WarehouseDocumentTypeId;
      this.typeFilter.typeName = treeObj.TypeName;
      this.treeView?.instance.selectItem(500000);
      await this.getDocuments();
    }
    this.addRow = false;
    this.editRow = false;
    this.showRow = false;
    this.showCorrection = false;
    this.editCorrection = false;
    this.isShowSum = false;

    this.dontRefresh = true;
    let index: number = -1;
    let document: WarehouseDocument = await new Promise((resolve) => {
      this.appService
        .getAuth(`warehouseDocuments/documents`, {
          ObjectId: e.document.form.WarehouseDocumentId,
        })
        .subscribe({
          next: (res) => {
            resolve(res?.data[0]);
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
            resolve(null);
          },
        });
    });
    if (this.pageIndex > 0) {
      this.pageIndex = 0;
    }
    await this.dataSource.reload();

    this.focusedRowIndex = this.event.returnFocusIndex(
      this.dataSource._items,
      'WarehouseDocumentId',
      e.document.form.WarehouseDocumentId
    );
    this.focusedSelected = [this.dataSource._items[this.focusedRowIndex]];

    this.cd.detectChanges();
    if (e.documentsToPrint?.length) {
      this.printAfterSave({
        document: document,
        documentsToPrint: e.documentsToPrint,
      });
    }

    if (index != -1) {
      this.focusedRowIndex = index;
      this.focusedSelected = [this.dataSource._items[this.focusedRowIndex]];
    }

    if (this.mode == 'add' && !this.isCorrect) {
      this.findSocketAndRun('XGMLYD2');
    }
    if (this.mode == 'edit' && !this.isCorrect) {
      this.findSocketAndRun('XGMLYP2');
    }

    if (
      e.document.registerInvoiceDocument &&
      this.focusedSelected[0].InvoiceDocumentId == null
    ) {
      this.event.onShown();
      this.createInvoiceDocument(document);
      this.cd.detectChanges();
    }

    setTimeout(() => {
      this.gridBank.instance.focus();
    }, 100);
  }

  editInventory: boolean = false;
  idDocumentInventory = 0;
  inventoryType = '';
  //location.pathname == '/warehouse/inventory' ? 'INV' : 'OB';

  sumPositionIncome;
  isAddDescription: boolean = false;
  isShowDocumentDescription: boolean = false;
  Remarks: String = '';
  addQuestion = () => {
    this.isShowDocumentDescription = true;
    this.isAddDescription = false;
    this.cd.detectChanges;
  };

  onSave = () => {
    let form = this.focusedSelected[0];
    this.isShowDocumentDescription = false;
    this.appService
      .putAuth(
        `warehouseDocuments/documents/${form.WarehouseDocumentId}/modifyRemarks`,
        {
          WarehouseDocumentId: form.WarehouseDocumentId,
          Remarks: this.Remarks,
        }
      )
      .subscribe(
        () => {
          this.getDocuments();
          this.isShowDocumentDescription = false;
          this.cd.detectChanges;
        },
        (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        }
      );
  };

  chechSignature() {
    return new Promise((resolve) => {
      if (
        this.focusedSelected[0]?.TypeOfDocument.trim() == 'PZ' ||
        this.focusedSelected[0]?.TypeOfDocument.trim() == 'ZU' ||
        this.focusedSelected[0]?.TypeOfDocument.trim() == 'PW'
      ) {
        resolve('IsOutcome');
      } else {
        resolve('IsIncome');
      }
    });
  }

  //getFocusedElement() {
  //  return this.dataSource.items().find((el, i) => this.focusedRowIndex === i);
  //}

  addQuestionIncome() {
    this.isOutcomeDocument = false;
    this.editDocument(null, 'IsOutcome');
    this.cd.detectChanges;
  }

  recalculatePosition: boolean = false;
  isOutcomeDocument: boolean = false;

  editRelatedSADDDocument = false;

  getFocusedElement() {
    this.mode = 'add';
    return this.dataSource.items().find((_el, i) => this.focusedRowIndex === i);
  }

  async editDocument(isShow?, Outcome?) {
    this.focusedSelected = [this.getFocusedElement()];
    if (!this.perABD.editBtn) {
      return;
    }

    if (
      !isShow &&
      this.focusedSelected[0].SadDocumentId &&
      this.focusedSelected[0].SadDocumentId != 0 &&
      !this.editRelatedSADDDocument
    ) {
      this.confirmEditingWarehouseDocumentRelatedSADDDocument = true;
      this.editRelatedSADDDocument = true;
      return;
    }
    if (this.editRelatedSADDDocument) {
      this.sadDocumentsEditMode = true;
    }
    this.editRelatedSADDDocument = false;
    // nie ruszać tego, co powyżej, bo się spieprzy edycja dokumentu z SAD

    if (!isShow) {
      this.productionDocumentsEditMode =
        !!this.focusedSelected[0]?.ProductionDocumentId;
    }
    let IsOutcome: any = await this.chechSignature();

    if (
      IsOutcome == 'IsOutcome' &&
      this.focusedSelected[0].WarehouseDocumentId ==
        this.focusedSelected[0].WarehouseDocumentCorrectionLastId &&
      this.focusedSelected[0].WarehouseDocumentCorrectionLastId != null &&
      !Outcome
    ) {
      this.isOutcomeDocument = true;
      this.cd.detectChanges();
      return;
    }

    this.recalculatePosition = false;

    if (
      this.focusedSelected[0].WarehouseDocumentId !=
        this.focusedSelected[0].WarehouseDocumentCorrectionLastId &&
      this.focusedSelected[0].WarehouseDocumentCorrectionLastId != null &&
      !isShow
    ) {
      this.event.showNotification(
        'error',
        this.translate.instant('warehouseDocument.documentCannotBeModified', {
          number: this.focusedSelected[0].WarehouseDocumentNumber,
        })
      );
      setTimeout(() => {
        this.isAddDescription = true;
        this.cd.detectChanges();
      }, 500);
      return;
    }

    if (this.focusedSelected[0].WarehouseDocumentCorrectionLastId == null) {
      this.recalculatePosition = true;
    }

    if (
      this.focusedSelected[0].TypeOfDocument == 'REM' ||
      this.focusedSelected[0].TypeOfDocument == 'REM ' ||
      this.focusedSelected[0].TypeOfDocument == 'RT ' ||
      this.focusedSelected[0].TypeOfDocument == 'RT' ||
      this.focusedSelected[0].TypeOfDocument == 'BO ' ||
      this.focusedSelected[0].TypeOfDocument == 'BO'
    ) {
      this.editInventory = true;
      this.cd.detectChanges();
      this.idDocumentInventory = this.focusedSelected[0].WarehouseDocumentId;
      if (
        this.focusedSelected[0].TypeOfDocument == 'REM' ||
        this.focusedSelected[0].TypeOfDocument == 'REM ' ||
        this.focusedSelected[0].TypeOfDocument == 'RT ' ||
        this.focusedSelected[0].TypeOfDocument == 'RT'
      ) {
        this.inventoryType = 'INV';
      } else {
        this.inventoryType = 'OB';
      }
    } else {
      this.event.loadingVisible = true;
      if (!this.focusedSelected[0].IsCorrectionDocument) {
        this.isCorrect = false;
        if (isShow) {
          this.appService
            .getAuth(
              `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/summary`
            )
            .subscribe({
              next: (res) => {
                this.event.loadingVisible = false;
                this.sumPositionIncome = res;
                this.mode = 'show';
                this.addRow = true;
                this.cd.detectChanges();
              },
              error: (error) => {
                this.event.loadingVisible = false;
                this.event.httpErrorNotification(error);
              },
            });
        } else {
          this.appService
            .putAuth(
              `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/prepareForModify`,
              {
                warehouseDocumentId:
                  this.focusedSelected[0].WarehouseDocumentId,
              }
            )
            .subscribe(
              (res) => {
                this.event.loadingVisible = false;
                if (!isShow) {
                  this.beforeEditRowVisible();
                } else {
                  this.mode = 'show';
                  this.addRow = true;
                  this.cd.detectChanges();
                }
                this.sumPositionIncome = res;
              },
              (error) => {
                if (
                  JSON.parse(error).message === 'CANT_EDIT_WAREHOUSE_DOC_CURRIN'
                ) {
                  this.isAddDescription = true;
                  this.cd.detectChanges();
                }

                this.event.loadingVisible = false;
                this.event.httpErrorNotification(error);
              }
            );
        }
      } else {
        this.event.loadingVisible = true;
        this.isCorrect = true;

        if (isShow) {
          this.mode = 'show';
          this.appService
            .getAuth(
              `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/correctionSums?useForOneDocument=true`
            )
            .subscribe({
              next: (res) => {
                this.sumPositionIncome = res;
                this.event.loadingVisible = false;
                this.addRow = true;
                this.cd.detectChanges();
              },
              error: (error) => {
                this.event.loadingVisible = false;
                this.event.httpErrorNotification(error);
              },
            });
          return;
        }

        if (this.perPrevCorrect) {
          if (this.userId == this.focusedSelected[0].Semaphore) {
            // brakuje sumpositionincome
            this.appService
              .getAuth(
                `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/summary`
              )
              .subscribe({
                next: (res) => {
                  this.sumPositionIncome = res;
                  this.event.loadingVisible = false;
                  !isShow ? (this.mode = 'edit') : (this.mode = 'show');
                  this.addRow = true;
                  this.cd.detectChanges();
                },
                error: (error) => {
                  this.event.loadingVisible = false;
                  this.event.httpErrorNotification(error);
                },
              });
          } else {
            this.appService
              .putAuth(
                `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/prepareForModify`,
                {
                  warehouseDocumentId:
                    this.focusedSelected[0].WarehouseDocumentId,
                }
              )
              .subscribe(
                () => {
                  this.appService
                    .getAuth(
                      `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/correctionSums?useForOneDocument=true`
                    )
                    .subscribe({
                      next: (resSum) => {
                        this.sumPositionIncome = resSum;
                        this.event.loadingVisible = false;
                        !isShow ? (this.mode = 'edit') : (this.mode = 'show');
                        this.addRow = true;
                        this.cd.detectChanges();
                      },
                      error: (error) => {
                        this.event.loadingVisible = false;
                        this.event.httpErrorNotification(error);
                      },
                    });
                },
                (error) => {
                  this.event.loadingVisible = false;
                  this.event.httpErrorNotification(error);
                }
              );
          }
        }
      }
    }
  }

  onClosingCorrection = () => {
    this.dataSource.reload();
    this.cd.detectChanges();
  };

  onClosingInventory = () => {
    this.editInventory = false;
    this.cd.detectChanges();
  };

  onChoosingItem = () => {};

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.getDocuments();
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGMLO1':
        this.componentVisible = true;
        this.cd.detectChanges();
        if (this.onInitErr) {
          this.getViewConfigurations();
        }
        this.findSocketAndRun('XGMLO2');
        break;
      case 'XGMLO2':
        this.searchControl?.focusInput();
        break;
      case 'XGMLYD1':
        this.findSocketAndRun('XGMLYA1', 'add');
        break;
      case 'XGMLYP1':
        this.findSocketAndRun('XGMLYA1', 'edit');
        break;
      case 'XGMLYU1':
        this.findSocketAndRun('XGMLYA1', 'delete');
        break;
      case 'XGMLYA1':
        if (this.nestOperation === 'add') {
          this.setAddRowVisible();
        } else if (this.nestOperation === 'edit') {
          this.setEditRowVisible();
        } else if (this.nestOperation === 'delete') {
          this.deleteBetween();
          this.findSocketAndRun('XGMLYU2', 'delete');
        }
        break;
      case 'XGMLYU2':
        this.findSocketAndRun('XGMLYA2', 'delete');
        break;
      case 'XGMLYA2':
        if (this.nestOperation === 'delete') {
          this.afterDelete();
        }
        break;
      case 'XGMLYD2':
        this.findSocketAndRun('XGMLYA2');
        break;
      case 'XGMLYP2':
        this.findSocketAndRun('XGMLYA2');
        break;

      case 'XGMLEXDOKMAG_BRWSCHOWCDM_E1':
        this.copyToClipboardBetween();
        break;

      case 'XGMLEXDOKMAG_BRSCHOWVDM_E1':
        this.isClipboardVisible = true;
        break;
      case 'XGMLEXDOKMAG_BRWBLOKDOK_E1':
        this.isBlockRow = true;
        break;
      case 'XGMLEXDOKMAG_BRWODBLOKDOK_E1':
        this.isBlockRow = true;
        break;
      case 'XGMLEXDOKMAG_BRWDO_KSIEGOW_E1':
        this.setForAccounting(true);
        break;
      case 'XGMLEXDOKMAG_BRWOD_KSIEGOW_E1':
        this.setForAccounting(false);
        break;
      case 'XGMLEXDOKMAG_BRWWERPOZDOK_E1':
        this.isVerificationPosition = true;
        break;
      case 'XGMLEXDOKMAG_BRWDM_NA_DH_E1':
        this.createInvoiceDocument(this.focusedSelected[0]);
        break;
      case 'XGMLEXDOKMAG_BRWDM_NA_DM_E1':
        this.createInterWarehouseDocument();
        break;
      case 'XGMLEXDOKMAG_BRWPOLADOD_E1':
        this.visibleUserExtensions = true;
        break;
      case 'XGMLEXDOKMAG_BRWPOLADODGRUP_E1':
        this.visibleUserExtensions = true;
        break;
      case `XGMLEX${
        this.selectedOwnOperation?.GroupCode
      }_${this.selectedOwnOperation?.Name?.toUpperCase()}_E1`:
        this.runInnerOwnOperation();
        break;
      default:
        break;
    }
  }

  componentVisible: boolean = false;
  onInitErr: boolean = false;
  isNestRunning: boolean = false;
  nestOperation: NestOperation = null;
  nest: Socket;
  componentNests: Socket;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      // nieznalazlo
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  beforeAddRowVisible() {
    this.recalculatePosition = false;
    this.mode = 'add';
    this.isCorrect = false;
    this.productionDocumentsEditMode = false;
    this.findSocketAndRun('XGMLYD1');
  }

  setAddRowVisible() {
    this.mode = 'add';
    this.addRow = true;
    this.cd.detectChanges();
  }

  beforeEditRowVisible() {
    this.findSocketAndRun('XGMLYP1');
  }

  setEditRowVisible() {
    this.mode = 'edit';
    this.addRow = true;
    this.cd.detectChanges();
  }

  editRecord(e, i?) {
    if (i) {
      this.focusedSelected = [i];
    }
    e.stopPropagation();
    if (
      !this.readOnly &&
      !this.addRow &&
      !this.editRow &&
      this.perABD.editBtn &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.editDocument();
    }
  }

  onChoosedLabel = () => {
    this.isLabel = false;
    setTimeout(() => {
      this.dataSource.reload();
    }, 100);
    this.selectedRows = [];
  };

  onAdditionalTableChanged(e) {
    this.localStorageData.additionalTable = Object.assign({}, e);
    const index = this.bottomPanelTabs.findIndex(
      (tab) => tab === this.translate.instant('articles.externalTable')
    );
    this.bottomPanelTabs[index] = e.text;
  }

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  onKeyDowngridRealHan(e) {
    if (e.event.keyCode == 27) {
      this.createInvoicesDocumentPopupVisible = false;
      e.event.preventDefault();
    }

    if (e.event.keyCode == 13) {
      this.createChosedInvoices();
    }
  }

  clearMobileFilters() {
    this.resetType();
    this.resetContrahentFilter();
  }

  isDetailOnFocus = false;

  onFocusElementChanged(e: boolean) {
    this.isDetailOnFocus = e;
  }

  onCellClick(e) {
    if (this.event.deviceType != 'mobile') {
      if (!e?.row?.cells) {
        return;
      }

      for (let cell of e?.row?.cells) {
        this.event.removeFocusClassFromCell(cell?.cellElement);
      }
      e.cellElement.className += ' dx-focused';
    }
  }

  currentFocusedCell = null;

  onFocusedCellChanged(e) {
    if (e) {
      if (this.currentFocusedCell) {
        this.event.removeFocusClassFromCell(this.currentFocusedCell);
      }
      this.currentFocusedCell = e.cellElement[0];
    }
  }

  positionFocused: any = [];

  onFocusedRowChangedDetail(e) {
    this.onFocusElementChanged(e.rowIndex > -1);
    this.positionFocused = [e.row.data];
  }

  currentFocusedDetailCell = null;

  onFocusedCellDetailChanged(e) {
    if (this.currentFocusedDetailCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedDetailCell);
    }
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedDetailCell = e.cellElement[0];
  }

  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    if (this.event.deviceType != 'mobile') {
      localStorage.setItem(
        'WarehouseDocumentsComponent',
        JSON.stringify(this.localStorageData)
      );
    }
  }

  runGuide() {
    this.isGuideActive = true;
  }

  onBlockRowClosing() {
    this.isBlockRow = false;
    if (this.textBlock.indexOf('Unblock') > -1) {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWODBLOKDOK_E2');
    } else {
      this.findSocketAndRun('XGMLEXDOKMAG_BRWBLOKDOK_E2');
    }
  }

  onVerificationPositionClosing(printAfterVerification: boolean = false) {
    if (printAfterVerification) {
      this.printAfterSave({
        document: this.focusedSelected[0],
      });
    }
    this.isVerificationPosition = false;
    this.printSettings.currentComponentName.set('warehouseDocuments');
    this.dataSource.reload();
    this.cd.detectChanges();
    this.findSocketAndRun('XGMLEXDOKMAG_BRWWERPOZDOK_E2');
  }

  menuName: string = null;

  restoreTreeConfig = () => {
    setTimeout(() => {
      if (this.localStorageData.tree.length == 0) {
        this.treeView.instance.selectItem(100000);
      }
    }, 0);
  };

  lastTreeSelection;

  onTreeSelectionChanged = (e) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection = selectedNodes;
    }
    e.component.selectItem(this.lastTreeSelection);
  };

  openRightPanel = () => {
    if (this.width <= 21) {
      this.resizeWindowBtn();
    }
  };

  showRelatedItems: boolean = false;
  contextPositionMenuClick = (e) => {
    if (this.positionFocused.length == 0) {
      return;
    }
    switch (e.itemData.itemIndex) {
      case 0:
        this.showRelatedItems = true;
        this.cd.detectChanges();
        break;
      case 1:
        this.router.navigate(['/library/articles'], {
          queryParams: { id: this.positionFocused[0].ProductId },
        });
        break;
      case '1111_000_KARTA_ART':
        this.showRecordCard('article');
        break;
      default:
        break;
    }
  };

  onRelatedItemsClosing = () => {
    this.showRelatedItems = false;
    this.cd.detectChanges();
  };

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.cd.detectChanges();
    this.getDocuments();
  };

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGM');
    }
    this.gridBank.instance.focus();
  }

  visibleChange = (e) => {
    if (!e) {
      this.isVisible = false;
      this.onClosed.emit(false);
      this.cd.detectChanges();
    }
  };

  onChoosedDocuments() {
    if (this.selectedRows.length == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant('buffor.selectLeastDocument')
      );
      return;
    }

    this.onChoosedDocument.emit({
      focusedSelected: this.focusedSelected,
      selectedRows: this.selectedRows,
    });
    this.isVisible = false;
    this.cd.detectChanges();
  }

  advancedFilterTemplateData = null;

  clearValues(onlySelected: boolean = false) {
    const url = new URL(window.location.href);
    url.search = '';
    history.replaceState(null, '', url.toString());

    this.customerId = null;
    this.Type = null;
    this.ObjectId = null;
    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = true;
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.resetTypeForShortcut();
    this.resetContrahentFilterForShortcut();
    this.getDocuments();
    this.cd.detectChanges();
    this.deleteFilter = false;
  }

  showOrderDocuments: boolean = false;

  contextMenuWarehoseClick(t) {
    if (t == 'gridOrders' && this.positionFocused.length > 0) {
      if (!this.event.checkPermissions('OZam')) {
        this.event.showNotification(
          'info',
          this.translate.instant('permissions.noPermission')
        );
      } else {
        this.showOrderDocuments = true;
        this.cd.detectChanges();
      }
    }
  }

  onClosingFormWarehouse() {
    this.showOrderDocuments = false;
    this.cd.detectChanges();
  }

  isOstrzegODokumZDnia: boolean = false;
  isOstrzegODokumZDniaParams = {
    date: new DatePipe('en-US').transform(new Date(), this.event.dateFormat),
  };

  yesOstrzegODokumZDnia() {
    this.isOstrzegODokumZDnia = false;

    this.newInvoiceDocumentCode = '1111_000_DM_NA_DH';
    this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DH_E1');
    this.cd.detectChanges();
  }

  noOstrzegODokumZDnia() {
    this.isOstrzegODokumZDnia = false;
    this.cd.detectChanges();
  }

  isNotShowReceipt(customerId) {
    return new Promise((resolve) => {
      let todayDate = new DatePipe('en-US').transform(new Date(), 'yyyy-MM-dd');
      this.appService
        .getAuth(
          `invoices/documents?IsNotShowReceipt=true&DateOfInvoiceDocument=${todayDate}&CustomerName=${customerId}&Skip=0&Take=1`
        )
        .subscribe((res) => {
          if (res.data.length > 0) {
            this.isOstrzegODokumZDnia = true;
            this.cd.detectChanges();
          } else {
            this.newInvoiceDocumentCode = '1111_000_DM_NA_DH';
            this.findSocketAndRun('XGMLEXDOKMAG_BRWDM_NA_DH_E1');
          }
          resolve(res.data.length > 0);
        });
    });
  }

  onSelectionSideTabChanged() {}

  showItem() {}

  onChoosedDescription() {}

  contextMenuCorection = [
    { text: 'Zaznacz dokument i jego korekty', disabled: false, itemIndex: 0 },
  ];

  contextCorectionMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (this.positionFocused.length > 0) {
          this.resetTypeForShortcut();
          this.selectInvoiceAndCorrections(
            this.focusedSelected[0].WarehouseDocumentId
          );
        }
        break;
    }
  }

  isSelectInvoiceAndCorrections = false;
  dontUnselect: boolean = false;
  selectInvoiceAndCorrections = (id) => {
    this.appService
      .putAuth(
        `warehouseDocuments/additionalOperations/${id}/selectWarehouseDocumentAndCorrections`,
        null
      )
      .subscribe(
        () => {
          this.isSelectInvoiceAndCorrections = true;

          this.dontUnselect = true;
          this.getDocuments();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  IsOpposite: any = null;
  isIsOpposite: any = null;
  isIsDocCurrencyDomestic: any = null;
  IsDocCurrencyDomestic: any = null;
  isAdvancedFilter: any = null;
  isAdvance: any = null;
  Advanced: any = null;
  Advance: any = null;
  valueCriteria: any = null;
  filterValue: any = '';

  sadDocumentsEditMode = false;

  cancelConfirmEditingWarehouseDocumentRelatedSADDDocument() {
    this.confirmEditingWarehouseDocumentRelatedSADDDocument = false;
    this.isOutcomeDocument = true;
    this.cd.detectChanges();
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('EAN');
    this.filterValue = e.toString();
    this.getDocuments();
  }

  resetFiltr(filterType: string) {
    if (filterType === 'onlySelected') {
      this.onlySelected = false;
    }
    this.getDocuments();
  }

  async calculateQuantitatively(): Promise<void> {
    await this.dataSource.reload();
    this.gridBank.instance.focus();
    this.isCalculateQuantitativelyVisible.set(false);
  }

  async printAfterSave(e: DataToPrintV2) {
    let reportData: ReportData = {
      allowFiscalPrint: false,
      code: this.USER_EXTENSIONS_GROUP_CODE,
      currencyCode: null,
      documentData: e.document,
      documentNumber: e.document.WarehouseDocumentNumber,
      menuName: e.document.MenuName,
      objectId: e.document.WarehouseDocumentId,
      report: null,
      reportTypeId: null,
      type: e.document.TypeOfDocument,
      actions: {
        downloadPdf: false,
        sendToEdocuments: false,
        email: false,
        print: true,
      },
      isBatch: false,
      lang: null,
    };
    const reportTypes = await this.print.getReportTypes(e.document.MenuName);
    reportData.report = this.print.getReportTemplate(
      reportData.menuName,
      reportData.type,
      reportTypes
    );
    await this.print.printReport(reportData);
  }

  onReportsPopupClosed() {
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }

  selectChoosed() {
    const value = this.exportFakirService.selections.getValue();
    if (this.isAllSelected) {
      this.exportFakirService.selections.next({
        ...value,
        Warehouse: this.dataSource._totalCount,
      });
    } else if (this.selectedRows) {
      this.exportFakirService.selections.next({
        ...value,
        Warehouse: this.selectedRows.length,
      });
    }
    this.event.isExportFKVisible.set(true);
  }

  cancelChoosed() {
    this.deselectAllRecords();
    const value = this.exportFakirService.selections.getValue();
    this.exportFakirService.selections.next({
      ...value,
      Warehouse: 0,
    });
    this.event.isExportFKVisible.set(true);
  }

  showContextMenuMobile(rowData) {
    if (this.selectedRows.includes(rowData.WarehouseDocumentId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  }

  isExportVisible = signal(false);
  selectedAmount = signal(0);

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridBank.instance.getDataSource().items()[
        this.focusedRowIndex
      ];
      const id = row.WarehouseDocumentId;
      if (!this.selectedRows.includes(id)) {
        this.gridBank.instance.selectRows(id, true);
      } else {
        this.gridBank.instance.deselectRows(id);
      }
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        this.recordCardObjectId = this.focusedSelected[0].CustomerId;
        break;
      case 'article':
        this.recordCardObjectId = this.positionFocused[0].ProductId;
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }
}

<div>
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</div>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
  >
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      type="default"
      stylingMode="text"
      id="btn-add-articles"
      (onClick)="showNewRow()"
      [disabled]="readOnly || !perABD.addBtn"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--mode-edit"
      type="default"
      stylingMode="text"
      id="btn-edit-articles"
      (onClick)="showEditArticle()"
      [disabled]="readOnly || selectedTask.length == 0 || !perABD.editBtn"
    >
    </dx-button>

    <dx-button
      icon="icon absui-icon--form-items"
      id="btn-show-articles"
      class="btn-dx-top-menu-folder"
      [disabled]="selectedTask.length == 0 || !perABD.showBtn"
      type="default"
      stylingMode="text"
      (onClick)="setSingleRecordVisible('show')"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--highlight-off"
      type="default"
      stylingMode="text"
      id="btn-delete-articles"
      (onClick)="isDelete = true"
      [disabled]="readOnly || selectedTask.length == 0 || !perABD.deleteBtn"
    >
    </dx-button>

    <dx-button
      icon="icon absui-icon--print"
      id="btn-print-tasks"
      class="btn-dx-top-menu"
      [disabled]="selectedTask.length == 0"
      type="default"
      stylingMode="text"
      (onClick)="(null)"
    >
    </dx-button>
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-product-scaner
      #scaner
      [readOnly]="readOnly"
      [showScanerContainer]="false"
      (onInputChanged)="onScanerInputChanged($event)"
      [keyboardShortcutsDisabled]="
        isSingleRecordVisible ||
        isDelete ||
        showNewType ||
        visibleUserExtensions ||
        isGroupStatusChangeVisible ||
        columnsChooserVisible
      "
      [parentId]="unicalGuid"
    >
    </app-product-scaner>

    <!-- <app-additional-operation
      nameComponent="OrdersComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="
        contextMenu[0].items = event.onSetAdditionalOperation($event)
      "
    >
    </app-additional-operation> -->

    <!-- tooltips -->

    <dx-tooltip
      target="#btn-add-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-edit-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-delete-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-show-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-print-tasks"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.prints" | translate }} (Ctrl + D)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-eDocuments-tasks"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.eDocuments" | translate }} (Ctrl + E)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        type="default"
        stylingMode="text"
        (click)="refreshView()"
        style="margin-right: 6px"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="column-chooser"
        type="default"
        stylingMode="text"
        (click)="columnsChooserVisible = true"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSettings" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row" (click)="clearSelectedItem()">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div class="with_search_panel" #rightPanel>
          <div class="row">
            <div class="col-md-12 kanban">
              <div style="    display: flex;
              align-items: center;
              gap: 6px;    justify-content: space-between;
          ">
          <div class="d-inline-block">
            <app-custom-dropdown-box
            [openDropDown]="openCustomDropDown"
            style="margin-right: 10px"
            [items]="filterCriteria"
            [(selectedItem)]="localStorageData.filter.orderBy"
            [(filterValue)]="filterValue"
            (onValueChanged)="onFilterDataChanged($event)"
            #searchControl
          >
          </app-custom-dropdown-box>
          <app-date-range
            #dateRange
            [isVisible]="dateRangeVisible"
            [readOnly]="readOnly"
            [isPopupMode]="true"
            [chipsVisible]="true"
          >
          </app-date-range>

          <app-custom-chips-button
            *ngIf="
              localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
              localStorageData?.advancedFilter?.IsPinned
            "
            [name]="'filterPanel.template' | translate"
            [labelExpr]="'Name'"
            [valueExpr]="'FilterUsersDefinitionId'"
            [selectedValueInput]="
              localStorageData?.advancedFilter?.Name || null
            "
            [list]="dynamicFilterUserDefinitions"
            (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
          >
          </app-custom-chips-button>

          <app-view-dynamic-quick-filters
            [dictionaryCode]="DynamicFilterDictionaryCode"
            [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
            [values]="
              localStorageData?.advancedFilter?.UserFilterDefinitionValues
            "
            (onValueChanged)="onPinnedValueChanged($event)"
          ></app-view-dynamic-quick-filters>

          <dx-button
            [id]="'clearValues' + unicalGuid"
            class="btn-filter-box"
            icon="icon absui-icon--clear-filter"
            (onClick)="clearValues()"
          ></dx-button>
          <dx-tooltip
            [target]="'#clearValues' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "clearAllFilters" | translate }} (Ctrl + F10)
            </div>
          </dx-tooltip>
          </div>
          <div>
            <app-custom-filter-dropdown-box
              style="margin-top: -6px;"
              [openDropDown]="filterCriteriaViewOpened"
              [items]="filterCriteria"
              [dropdownHeight]="180"
              [selectedItem]="localStorageData?.filter?.orderBy"
              [sortDirection]="localStorageData?.filter?.sort"
              [filterValue]="localStorageData.filter.orderBy"
              [autoEmit]="true"
              (onValueChanged)="changeFilterCriteria($event)"
            />
          </div>
          </div>
              

              <dx-scroll-view
                width="100%"
                [height]="canbanHeight"
                direction="horizontal"
              >
                <div
                  class="wrapper"
                  dragula="COLUMNS"
                  [(dragulaModel)]="groups"
                >
                  <ng-container *ngFor="let group of groups; let index = index">
                    <div
                      *ngIf="group.checked"
                      class="status-block"
                      [style.width.px]="
                        localStorageData?.other?.singleWidth || 220
                      "
                    >
                      <div class="group-handle">
                        <app-status-label
                          [text]="group.name"
                          [color]="group.StatusStringColor"
                          [backgroundColor]="group.StatusBackgroundColor"
                        ></app-status-label>

                        {{ group.name === "N" ? "Niezrealizowane" : "" }}
                        {{ group.name === "A" ? "Anulowane" : "" }}
                        {{ group.name === "R" ? "Realizowane" : "" }}
                        {{ group.name === "Z" ? "Zrealizowane" : "" }}
                        {{ group.name === "O" ? "Odebrane" : "" }}
                        <div class="status-amount">
                          ({{ group.items.length }})
                        </div>
                      </div>
                      <div
                        class="status-container"
                        [style.height.px]="canbanHeight - 60"
                        [ngClass]="{
                          'opacity-bg': group.items && group.items.length,
                          'box-bg-none-even': index % 2 === 0,
                          'box-bg-none-odd': index % 2 === 1
                        }"
                      >
                        <!-- 'box-bg-none-even': ((!group.items || !group.items.length) && index % 2 === 0),
                                                'box-bg-none-odd': ((!group.items || !group.items.length) && index % 2 === 1) -->
                        <dx-scroll-view
                          width="100%"
                          [height]="'calc(100% - 25px)'"
                          direction="vertical"
                        >
                          <div
                            dragula="ITEMS"
                            class="nested-wrapper"
                            [style.width.px]="
                              localStorageData?.other?.singleWidth
                                ? localStorageData?.other?.singleWidth - 20
                                : 220
                            "
                            [(dragulaModel)]="group.items"
                            [attr.data-id]="group.name"
                          >
                            <div
                              *ngFor="let item of group.items"
                              class="final-box"
                              (click)="stop($event)"
                              [ngClass]="{
                                'final-box-selected': item.selected
                              }"
                              (click)="setSelected(item, $event)"
                              (dblclick)="editSelectedRow(item)"
                            >
                              <div class="final-box-content">
                                <div
                                  class="left-border"
                                  [style.background]="
                                    item.ColorText
                                      ? '#' + item.ColorText
                                      : '#AED581'
                                  "
                                ></div>
                                <div class="box-content move-box">
                                  <div class="title move-box">
                                    {{ item.Number }}
                                  </div>
                                  <div class="device move-box">
                                    {{ item.ServiceDeviceName }}
                                  </div>
                                  <div class="date move-box">
                                    <span
                                      [id]="'dateStart' + item.ServiceOrderId"
                                      >{{
                                        item.DateStart
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}</span
                                    >
                                    <i class="icon arrow-small-right"></i>
                                    <span [id]="'date' + item.ServiceOrderId">{{
                                      item.DateEnd
                                        | date
                                          : "dd.MM.yyyy HH:mm"
                                          : translate.currentLang || "pl"
                                    }}</span>
                                  </div>
                                  <dx-tooltip
                                    [target]="
                                      '#dateStart' + item.ServiceOrderId
                                    "
                                    [showEvent]="event.tooltipShowEvent"
                                    hideEvent="dxhoverend"
                                    [hideOnOutsideClick]="false"
                                  >
                                    <div *dxTemplate="let data of 'content'">
                                      Rozpoczęcie:
                                      {{
                                        item.DateStart
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}
                                    </div>
                                  </dx-tooltip>
                                  <dx-tooltip
                                    [target]="'#date' + item.ServiceOrderId"
                                    [showEvent]="event.tooltipShowEvent"
                                    hideEvent="dxhoverend"
                                    [hideOnOutsideClick]="false"
                                  >
                                    <div *dxTemplate="let data of 'content'">
                                      Zakończenie:
                                      {{
                                        item.DateStart
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}
                                    </div>
                                  </dx-tooltip>
                                  <div class="task-progress move-box">
                                    <div>
                                      <i
                                        [id]="'icon' + item.ServiceOrderId"
                                        class="icon icon-group icon-cell icon-repair"
                                        *ngIf="
                                          item.ServiceOrderName === 'Naprawa'
                                        "
                                      ></i>
                                      <i
                                        [id]="'icon' + item.ServiceOrderId"
                                        class="icon icon-group icon-cell icon-review"
                                        *ngIf="
                                          item.ServiceOrderName === 'Przegląd'
                                        "
                                      ></i>
                                      <i
                                        [id]="'icon' + item.ServiceOrderId"
                                        class="icon icon-group icon-cell default-icon"
                                        *ngIf="
                                          item.ServiceOrderName !== 'Naprawa' &&
                                          item.ServiceOrderName !== 'Przegląd'
                                        "
                                      ></i>

                                      <div
                                        class="d-inline-block percent"
                                        style="padding-top: 5px"
                                        [id]="
                                          'realisation' + item.ServiceOrderId
                                        "
                                      >
                                        {{ item.CompletionPercentage }}%
                                      </div>

                                      <dx-tooltip
                                        [target]="'#icon' + item.ServiceOrderId"
                                        [showEvent]="event.tooltipShowEvent"
                                        hideEvent="dxhoverend"
                                        [hideOnOutsideClick]="false"
                                      >
                                        <div
                                          *dxTemplate="let data of 'content'"
                                        >
                                          {{ item.ServiceOrderName }}
                                        </div>
                                      </dx-tooltip>

                                      <dx-tooltip
                                        [target]="
                                          '#realisation' + item.ServiceOrderId
                                        "
                                        [showEvent]="event.tooltipShowEvent"
                                        hideEvent="dxhoverend"
                                        [hideOnOutsideClick]="false"
                                      >
                                        <div
                                          *dxTemplate="let data of 'content'"
                                        >
                                          Realizacja:
                                          {{ item.CompletionPercentage }}%
                                        </div>
                                      </dx-tooltip>
                                    </div>
                                    <div
                                      *ngIf="
                                        item && item.EmployeeName.trim().length
                                      "
                                      class="person text-center move-box"
                                      [id]="'person' + item.ServiceOrderId"
                                    >
                                      {{ getNameShortcut(item?.EmployeeName) }}
                                    </div>
                                    <dx-tooltip
                                      [target]="'#person' + item.ServiceOrderId"
                                      [showEvent]="event.tooltipShowEvent"
                                      hideEvent="dxhoverend"
                                      [hideOnOutsideClick]="false"
                                    >
                                      <div *dxTemplate="let data of 'content'">
                                        {{ item.EmployeeName }}
                                      </div>
                                    </dx-tooltip>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="abs-details"
                                (click)="isOpened = false"
                                *ngIf="item.selected"
                                [style.left.px]="clientX"
                                [style.top.px]="clientY"
                                style="width: 380px; height: 220px"
                                (click)="stop($event)"
                              >
                                <div
                                  class="dx-popover-arrow"
                                  [ngClass]="{
                                    'dx-popover-arrow-bottom': arrowBottom,
                                    'dx-popover-arrow-right': arrowRight
                                  }"
                                ></div>
                                <div class="movie-tooltip">
                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 10px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    >
                                      <div
                                        class="color-circle"
                                        [style.background]="
                                          item.ColorText
                                            ? '#' + item.ColorText
                                            : '#AED581'
                                        "
                                      ></div>
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 276px; padding-top: 4px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label main-col-details"
                                        style="
                                          width: 276px;
                                          font-weight: 700;
                                          font-size: 0.8125rem;
                                          line-height: 15.23px;
                                          color: #4c4c4c;
                                          white-space: break-spaces;
                                        "
                                        (click)="
                                          onTitleClick(item.ServiceOrderId)
                                        "
                                        >{{ item.Number }}</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 40px; position: relative"
                                    >
                                      <dx-button
                                        class="btn-dx-top-menu-folder"
                                        icon="more-horizontal"
                                        [ngClass]="{ 'btn-bord': isOpened }"
                                        style="margin-left: 7px"
                                        (click)="switchIsOpened($event)"
                                      ></dx-button>
                                      <dx-select-box
                                        [opened]="isOpened"
                                        [focusStateEnabled]="false"
                                        [hoverStateEnabled]="false"
                                        class="hidden-select-box star-menu"
                                        [ngClass]="{
                                          'right-side': !isLeftSelect,
                                          'left-side': isLeftSelect
                                        }"
                                        [value]="selectBoxValue"
                                        (onValueChanged)="
                                          onSelectBoxValueChanged($event, item)
                                        "
                                        [dataSource]="popupSelectBoxSource"
                                      >
                                        <div *dxTemplate="let data of 'item'">
                                          <div class="custom-item-temp">
                                            <i
                                              class="icon absui-icon--mode-edit"
                                              *ngIf="data.i == 'edit'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--trash"
                                              *ngIf="data.i == 'delete'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--form-items"
                                              *ngIf="data.i == 'show'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--print"
                                              *ngIf="data.i == 'print'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--edocuments"
                                              *ngIf="data.i == 'edocuments'"
                                            ></i>

                                            <div
                                              class="product-name d-inline-block"
                                            >
                                              {{ data.text | translate }}
                                            </div>
                                          </div>
                                        </div>
                                      </dx-select-box>
                                    </div>
                                    <!-- <div class="form-group-inline-right" style="width: 40px;">
                                                                        <dx-select-box class="sel-hide-val" width="38px" [value]="selectBoxValue" (onValueChanged)="onSelectBoxValueChanged($event, item)"
                                                                            fieldTemplate="field" [dataSource]="popupSelectBoxSource">
                                                                            <div *dxTemplate="let data of 'field'">
                                                                                <div class="custom-item text-center">
                                                                                  <dx-text-box width="1"
                                                                                    class="product-name"
                                                                                    [value]="null"
                                                                                    [readOnly]="true"
                                                                                  ></dx-text-box>
                                                                                </div>
                                                                            </div>
                                                                            <div *dxTemplate="let data of 'item'">
                                                                                <div class="custom-item-temp">
                                                                                    <i class="icon absui-icon--mode-edit" *ngIf="data.i == 'edit'"></i>
                                                                                    <i class="icon absui-icon--trash" *ngIf="data.i == 'delete'"></i>
                                                                                    <i class="icon absui-icon--form-items" *ngIf="data.i == 'show'"></i>
                                                                                    <i class="icon absui-icon--print" *ngIf="data.i == 'print'"></i>
                                                                                    <i class="icon absui-icon--edocuments" *ngIf="data.i == 'edocuments'"></i>
                                                                                    <i class="icon absui-icon--operations" *ngIf="data.i == 'operations'"></i>

                                                                                    <div class="product-name d-inline-block">
                                                                                    {{ data.text | translate }}
                                                                                  </div>
                                                                                </div>
                                                                            </div>
                                                                        </dx-select-box>
                                                                    </div> -->
                                  </div>

                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 8px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    ></div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 120px"
                                    >
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "taskCalendar.dateStart" | translate
                                        }}:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "taskCalendar.dateEnd" | translate
                                        }}:</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 190px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          item.DateStart
                                            | date
                                              : "dd.MM.yyyy HH:mm"
                                              : translate.currentLang ||
                                                  "pl" ||
                                                  "-"
                                        }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          item.DateEnd
                                            | date
                                              : "dd.MM.yyyy HH:mm"
                                              : translate.currentLang ||
                                                  "pl" ||
                                                  "-"
                                        }}</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 8px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    ></div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 120px; position: relative"
                                    >
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Osoba przypisana:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="
                                          width: 120px;
                                          position: absolute;
                                          bottom: 0 !important;
                                          top: auto;
                                        "
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "form-financial-document.payer-type.contractor"
                                            | translate
                                        }}:</label
                                      >
                                      <!-- <br>
                                                                        <label for="" style="width: 120px;" class="text-left cloud-label left-lbl">{{ 'taskCalendar.reminder' | translate }}:</label> -->
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 190px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{ item.EmployeeName || "-" }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="
                                          width: 100%;
                                          white-space: break-spaces;
                                        "
                                        >{{ item.CustomerName || "-" }}</label
                                      >
                                      <!-- <br>
                                                                        <label for="" class="text-left cloud-label right-lbl f-roboto">{{ getReminderTimer(item.RemindTimer)}}</label> -->
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 5px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    ></div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 120px"
                                    >
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Status:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "taskCalendar.realization"
                                            | translate
                                        }}:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Typ:</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right right-lbl"
                                      style="width: 190px"
                                    >
                                      <label
                                        for=""
                                        style="width: 100%"
                                        class="cloud-label right-lbl f-roboto"
                                      >
                                        {{
                                          item.ServiceOrderStatus === "N"
                                            ? "Niezrealizowane"
                                            : ""
                                        }}
                                        {{
                                          item.ServiceOrderStatus === "Y"
                                            ? "Zamknięte"
                                            : ""
                                        }}
                                        {{
                                          item.ServiceOrderStatus === "R"
                                            ? "Realizowane"
                                            : ""
                                        }}
                                        {{
                                          item.ServiceOrderStatus === "Z"
                                            ? "Zrealizowane"
                                            : ""
                                        }}
                                        {{
                                          item.ServiceOrderStatus === "O"
                                            ? "Odebrane"
                                            : ""
                                        }}
                                        {{
                                          !item.ServiceOrderStatus ? "-" : ""
                                        }}
                                      </label>
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{ item.CompletionPercentage }}%</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          getTypeDescription(
                                            item.ServiceOrderType
                                          )
                                        }}</label
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </dx-scroll-view>
                        <div class="text-center new-task">
                          <dx-button
                            icon="icon absui-icon--add-no-circle"
                            (onClick)="addNewTaskBtn(group.name)"
                            text="{{ 'plannedOrders.newOrder' | translate }}"
                          ></dx-button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </dx-scroll-view>
            </div>
          </div>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          #leftPanel
        >
          <div class="right-panel-btn">
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>

          <dx-tab-panel
            [width]="width - 15"
            [visible]="localStorageData?.sidePanel?.isVisible"
            [items]="[1, 2]"
            [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
            style="margin-top: -10px"
            [animationEnabled]="false"
            [swipeEnabled]="false"
            [showNavButtons]="true"
            class="right-panel-box"
          >
            <div *dxTemplate="let item of 'title'">
              <div class="title-temp">
                <i class="icon absui-icon--tab-filter" *ngIf="item == 1"></i>
                <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
              </div>
            </div>
            <div *dxTemplate="let name of 'item'">
              <ng-container [ngSwitch]="name">
                <div *ngSwitchCase="1">
                  <div class="list-container">
                    <div
                      class="dx-draggable"
                      dragula="ITEMS"
                      id="copy-src"
                      [(dragulaModel)]="taskTypes"
                    >
                      <div
                        class="drag-item dx-draggable move-box"
                        *ngFor="let task of taskTypes"
                        [attr.data-id]="task.ServiceOrderTypeId"
                      >
                        <i
                          class="icon absui-icon--operations-kanban grab-area move-box"
                        ></i>
                        <i
                          class="icon icon-group move-box"
                          [ngClass]="task.icon"
                        ></i>
                        <div class="display-wrapper move-box">
                          <div class="amount-txt move-box">
                            {{ task.amount || 0 }}
                          </div>
                          <div class="type-txt move-box">{{ task.Name }}</div>
                        </div>
                        <i
                          class="icon icon-group plus-btn"
                          (click)="addTask(task)"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-center new-task"
                    style="
                      display: block !important;
                      font-family: Roboto !important;
                      color: #4c4c4c !important;
                    "
                  >
                    <dx-button
                      icon="icon absui-icon--add-no-circle"
                      [disabled]="readOnly"
                      (onClick)="addNewType()"
                      text="Nowy typ zadania"
                    ></dx-button>
                  </div>
                </div>
                <div *ngSwitchCase="2">
                  <app-filter-panel
                    [openPanelFilters]="visibleAdvanceFilters"
                    [deleteFilters]="deleteFilters"
                    (onFilterSave)="onFilterSaved($event)"
                    [dictionaryCode]="DynamicFilterDictionaryCode"
                    [selectedTemplate]="
                      localStorageData?.advancedFilter?.FilterUsersDefinitionId
                    "
                    [isPinned]="localStorageData?.advancedFilter?.IsPinned"
                    (onTemplatePinChanged)="onTemplatePinChanged($event)"
                    [tabActive]="
                      localStorageData.sidePanel.isVisible &&
                      localStorageData.sidePanel.selectedIndex == 1
                    "
                    [panelWidth]="localStorageData.sidePanel.width"
                    [values]="
                      localStorageData?.advancedFilter
                        ?.UserFilterDefinitionValues
                    "
                    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
                  >
                  </app-filter-panel>
                </div>
              </ng-container>
            </div>
          </dx-tab-panel>
        </div>
      </div>
    </div>
  </div>

  <app-add-orders-service
    [isVisible]="isSingleRecordVisible"
    [mode]="singleRecordMode"
    [readOnly]="readOnly || singleRecordMode === 'show'"
    (onClosing)="onSingleRecordClosing()"
    (onInserted)="onSingleRecordInserted()"
    [selectedId]="selectedTask"
    [status]="newTaskStatus"
    [serviceOrderTypeId]="newTaskType"
    title="{{ 'servicesDevices.addOrderService' | translate }}"
  >
  </app-add-orders-service>

  <app-confirm-dialog
    [isVisible]="isDelete"
    (onRemoving)="showDeleteArticle()"
    (onClosing)="closeConfirm()"
  ></app-confirm-dialog>

  <app-add-order-type
    *ngIf="showNewType"
    [isVisible]="true"
    (onClosing)="showNewType = false"
    (onInserted)="onInsertedType()"
    title="{{ 'servicesDevices.addTypesOrder' | translate }}"
    mode="add"
  ></app-add-order-type>

  <app-form-user-extensions
    *ngIf="visibleUserExtensions"
    [isVisible]="visibleUserExtensions"
    [groupCode]="USER_EXTENSIONS_GROUP_CODE"
    [readOnly]="readOnly"
    title="{{ 'categories.externalFields' | translate }}"
    (onChoosed)="setAdditionalFields($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="additionalFieldsList"
  >
  </app-form-user-extensions>

  <app-orders-group-status-change
    *ngIf="isGroupStatusChangeVisible"
    (onClosed)="onGroupStatusChangeClosed()"
    (onSaved)="onGroupStatusChangeSaved()"
  >
  </app-orders-group-status-change>

  <app-column-chooser
    [rawColumns]="groups"
    [(visible)]="columnsChooserVisible"
    (columnsChanged)="onColumnsChanged()"
  ></app-column-chooser>

  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [unicalGuid]="unicalGuid"
    [disabled]="
      isSingleRecordVisible ||
      isDelete ||
      showNewType ||
      visibleUserExtensions ||
      isGroupStatusChangeVisible ||
      columnsChooserVisible
    "
  >
  </wapro-keyboard-shortcuts>

  <div *ngIf="isFilterPanelComponent">
    <app-filter-panel
      [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
      (onFilterSave)="onFilterSaved($event)"
      [dictionaryCode]="DynamicFilterDictionaryCode"
      [selectedTemplate]="
        localStorageData?.advancedFilter?.FilterUsersDefinitionId
      "
      [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
    >
    </app-filter-panel>
  </div>
</ng-template>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="perEdocuments"
    DocumentCode="ServiceOrderRelated"
    tableName="SERWIS_ZLECENIE"
    [contextObjectId]="selectedTaskData?.ServiceOrderId"
    [parentDocumentName]="selectedTaskData?.Number"
    style="display: inline-block"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="650"
  [minHeight]="280"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="col-md-12">
          <p
            [innerHTML]="'documentsOrders.anOrderTotheSupplier' | translate"
          ></p>
        </div>

        <div
          class="form-group-inline-right"
          style="width: 100%; margin-left: 15px"
        >
          <div class="c-frame-row" style="width: 100%">
            <h5>
              {{ "documentsOrders.specifyTheParameters" | translate }}
            </h5>
          </div>

          <label>{{ "constractors.supplier" | translate }}</label>
          <app-contractors
            [readOnly]="readOnly"
            [selectedId]="object.SupplierCusotmerId"
            [dropDownBoxMode]="true"
            width="276"
            typeOfCustomer="IsSupplier"
            style="width: 276px"
            (onChoosed)="onChoosedCustomer($event)"
            [className]="
              (object.SupplierCusotmerId == 0 ||
                object.SupplierCusotmerId == null) &&
              submitted
                ? true
                : false
            "
          >
          </app-contractors
          ><br /><br />

          <div class="c-frame-row" style="width: 100%">
            <h5>
              {{ "documentsOrders.transferQuantityFromZOitem" | translate }}
            </h5>
          </div>

          <dx-radio-group
            [items]="listRadio"
            layout="horizontal"
            [readOnly]="readOnly"
            displayExpr="label"
            valueExpr="value"
            [(value)]="object.AmountPositionsMode"
          >
          </dx-radio-group
          ><br />

          <dx-check-box
            [(value)]="object.IsMargePositions"
            text="{{
              'documentsOrders.mergeItemsWithIdenticalProperties' | translate
            }}"
            style="margin-left: 5px"
          ></dx-check-box>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-group-doc',
    templateUrl: './group-doc.component.html',
    styleUrls: ['./group-doc.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'selected',
        'inventoryDocumentId',
    ],
    standalone: false
})
export class GroupDocComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  selected;
  inventoryDocumentId;

  widthWindow = '90%';
  heightWindow = '90%';

  unicalGuid;
  shortcuts: ShortcutInput[] = [];
  form;

  algorithmTypelist = [
    { value: 'ORDER', label: '' },
    { value: 'FIFO_LIFO', label: '' },
  ];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('inventory.byOrder')
      .subscribe((text) => (this.algorithmTypelist[0].label = text));
    this.translate
      .get('inventory.byFifo')
      .subscribe((text) => (this.algorithmTypelist[1].label = text));
  }

  ngOnInit(): void {
    if (this.readOnly) this.readOnly = this.event.readOnly;
    this.createForm();
  }

  ngOnChanges() {
    if (this.selected && this.isVisible) {
      setTimeout(() => {
        this.form.controls.InventoryDocumentId.setValue(
          this.inventoryDocumentId
        );
      }, 0);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      ProductId: [0],
      InventoryDocumentId: [0],
      StockLeft: [0],
      AlgorithmType: ['ORDER'],
    });
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.createForm();
    }
  };

  onSave = () => {
    if (this.form.valid && !this.readOnly) {
      this.appService
        .putAuth(
          `inventory/documents/${this.form.value.InventoryDocumentId}/setSummaryStockLeft`,
          this.form.value
        )
        .subscribe(
          () => {
            this.onInserted.emit(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  };
}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); prevousData()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      #scrollView
      [formGroup]="form"
    >
      <div class="d-flex justify-content-center flex-wrap">
        <div
          class="form-group-inline-right column-content"
          style="width: 460px"
        >
          <div class="form-box">
            <div class="box-title">
              <label style="width: 125px; position: relative">{{
                "servicesDevices.VATcode" | translate
              }}</label>

              <dx-text-box
                width="276"
                [readOnly]="true"
                formControlName="TaxCode"
              ></dx-text-box>
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 125px; position: relative">{{
                "form-commercial-operation.grid.netValue" | translate
              }}</label>
              <dx-number-box
                width="276"
                style="display: inline-block; margin-bottom: 6px"
                [readOnly]="readOnly"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="TotalNetAmount"
              >
              </dx-number-box>
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 125px; position: relative">VAT</label>

              <dx-number-box
                width="276"
                style="display: inline-block; margin-bottom: 6px"
                [readOnly]="readOnly"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="TotalTaxAmount"
              >
              </dx-number-box>
            </div>
          </div>

          <div class="form-box" *ngIf="IsCurrencyDocument">
            <div class="box-title">
              <label style="width: 125px; position: relative">{{
                "form-commercial-operation.TotalNetAmountCurrency" | translate
              }}</label>
              <dx-number-box
                width="276"
                style="display: inline-block; margin-bottom: 6px"
                [readOnly]="readOnly"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="TotalNetAmountCurrency"
              >
              </dx-number-box>
            </div>
          </div>

          <div class="form-box" *ngIf="IsCurrencyDocument">
            <div class="box-title">
              <label style="width: 125px; position: relative">{{
                "buffor.vatCurrency" | translate
              }}</label>

              <dx-number-box
                width="276"
                style="display: inline-block; margin-bottom: 6px"
                [readOnly]="readOnly"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                formControlName="TotalTaxAmountCurrency"
              >
              </dx-number-box>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  *ngIf="isConfirmValue"
  kindDialog="question"
  [confirmText]="
    'form-commercial-operation.theValueBeforeTheChangeHadAdifferentSign'
      | translate
  "
  [isVisible]="true"
  (onRemoving)="yesValue()"
  (onClosing)="noValue()"
>
</app-confirm-dialog>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { AppServices } from 'src/app/app-services.service';
import { GlobalDate } from 'src/app/core/date-range/GlobalDate';
import { IBasicSelectBox } from 'src/app/core/Icore';
import { EventService } from 'src/app/event.service';
import { IDocumentOfferSum } from './sum-offers.model';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-sum-offers',
    templateUrl: './sum-offers.component.html',
    styleUrls: ['./sum-offers.component.scss'],
    inputs: ['isVisible', 'filters'],
    standalone: false
})
export class SumOffersComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @ViewChild('gridBank') gridBank: DxDataGridComponent;
  isVisible: boolean;

  widthWindow = '90%';
  heightWindow = '90%';
  filters;
  shortcuts: ShortcutInput[] = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  dataSourceSum: any[] = [];

  radioButtonList: IBasicSelectBox[] = [
    { value: false, label: 'Tylko dokumenty filtrowane' },
    { value: true, label: 'Tylko dokumenty zaznaczone' },
  ];

  forSelected: boolean = false;
  currentDateSub: Subscription;
  currentDate: GlobalDate;
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.currentDateSub = this.event.currentDate.subscribe(
      (res: GlobalDate) => {
        this.currentDate = res;
      }
    );

    this.getTranslations();
  }

  getTranslations() {
    this.translate
      .get('warehouseDocument.filtereDocumentsOnly')
      .subscribe((text: string) => (this.radioButtonList[0].label = text));
    this.translate
      .get('warehouseDocument.onlySelectedDocuments')
      .subscribe((text: string) => (this.radioButtonList[1].label = text));
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.currentDateSub?.unsubscribe();
  }

  transformFiltersToString(filters: any = {}) {
    let params: string = '';
    for (let param in filters) {
      params += `${param}=${filters[param]}&`;
    }

    return params.substring(0, params.length - 1);
  }

  onValueChanged = () => {
    this.getData();
  };

  getData = () => {
    let url: string =
      `offers/documents/summary?` + this.transformFiltersToString(this.filters);

    if (this.forSelected) url += `&IsOnlySelected=true`;
    this.appService.getAuth(url).subscribe(async (res: IDocumentOfferSum) => {
      this.dataSourceSum = [];

      let all: string = await this.translate
        .get('form-financial-document.count')
        .toPromise();

      this.dataSourceSum.push({
        name: all,
        net: res.TotalNetAmount,
        gross: res.TotalGrossAmount,
      });
    });
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible?.currentValue) {
      this.getData();
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventService } from "src/app/event.service";
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { AppServices } from "src/app/app-services.service";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import { map } from "rxjs";
import { BaseGridDataResponse } from "src/app/event.model";
import { OrderStatus } from "src/app/warehouse/documents-orders/new-documents-orders/order.model";

@Component({
    selector: "app-orders-group-status-change",
    templateUrl: "./orders-group-status-change.component.html",
    styleUrls: ["./orders-group-status-change.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrdersGroupStatusChangeComponent implements OnInit {
  @Output() onClosed: EventEmitter<void> = new EventEmitter();
  @Output() onSaved: EventEmitter<void> = new EventEmitter();
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  form: FormGroup;
  isDescriptionActive = false;
  isServiceOrderStatusDescription = false;
  orderStatusList: OrderStatus[] = [];
  constructor(
    public event: EventService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private appService: AppServices,
    public cd: ChangeDetectorRef
  ) {
    this.createForm();
    this.getStatusList();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: "f10",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.saveForm();
        },
        preventDefault: true,
      },
      {
        key: "esc",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosed.emit();
        },
        preventDefault: true,
      }
    );
  }

  createForm() {
    this.form = this.fb.group({
      ServiceStatus: ["N", Validators.required],
      Description: null,
    });
  }

  getStatusList = () => {
    if (localStorage.getItem("statusDocumentTypeS")) {
      let list = this.event.decryptString(
        localStorage.getItem("statusDocumentTypeS")
      );
      this.orderStatusList = list;
      for (let i = this.orderStatusList.length - 1; i >= 0; i--) {
        if (!this.orderStatusList[i].IsActive)
          this.orderStatusList.splice(i, 1);
      }
      this.cd.detectChanges();
      return;
    }

    this.appService
      .getAuth(`document/sales/status?documentType=S`)
      .pipe(
        map((res: BaseGridDataResponse<OrderStatus>) => res.data || []),
        map((data: OrderStatus[]) =>
          data
            .filter((s) => s.IsActive)
            .sort((a, b) => a.OrderNumber - b.OrderNumber)
        )
      )
      .subscribe((data) => {
        this.orderStatusList = data;
        this.cd.detectChanges();
      });
  };

  onServiceOrderStatusDescription = (e) => {
    let text = "";
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });
    }
    this.form.controls.Description.setValue(text);
    this.isServiceOrderStatusDescription = false;
  };

  onActiveChanged(e: ValueChangedEvent) {
    if (!e.value) {
      this.form.controls.Description.setValue(null);
    }
  }

  saveForm() {
    if (!this.form.valid) return;

    const value = this.form.value;

    if (!this.isDescriptionActive) {
      delete value.Description;
    }

    this.appService
      .postAuth(`services/additionalOperations/ServiceStatus`, value)
      .subscribe({
        next: () => {
          this.onSaved.emit();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }
}

<dx-popup
  width="600"
  height="220"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'sumOffers.title' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <dx-radio-group
        [items]="radioButtonList"
        layout="horizontal"
        displayExpr="label"
        valueExpr="value"
        [(value)]="forSelected"
        (onValueChanged)="onValueChanged()"
        class="sum-rad"
      >
      </dx-radio-group>

      <dx-data-grid
        #gridBank
        [dataSource]="dataSourceSum"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        shortcut
        [height]="100"
        [columnAutoWidth]="true"
        [allowColumnResizing]="true"
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

        <dxi-column
          caption=""
          dataField="name"
          alignment="left"
          [width]="100"
          [allowSorting]="false"
        ></dxi-column>

        <dxi-column
          caption="{{ 'form-commercial-operation.grid.netValue' | translate }}"
          alignment="center"
          dataField="net"
          [allowSorting]="false"
          cellTemplate="valueTemplate"
        >
        </dxi-column>

        <dxi-column
          caption="{{
            'form-commercial-operation.grid.grossValue' | translate
          }}"
          alignment="center"
          dataField="gross"
          [allowSorting]="false"
          cellTemplate="valueTemplate"
        >
        </dxi-column>

        <div *dxTemplate="let cell of 'valueTemplate'">
          {{ cell.value | amountFormatter : cell }}
        </div>
      </dx-data-grid>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../../app-services.service';
import { EventService } from '../../../../../event.service';

@Component({
    selector: 'app-add-exemplary-codes',
    templateUrl: './add-exemplary-codes.component.html',
    styleUrls: ['./add-exemplary-codes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: [
        'isVisible',
        'selectedId',
        'selected',
        'readOnly',
        'title',
        'mode',
        'Amount',
        'OrderDocumentPositionId',
        'WarehouseDocumentPositionId',
        'SadDocumentPositionId',
        'ProdDocumentPositionId',
        'OfferDocumentPositionId',
    ],
    standalone: false
})
export class AddExemplaryCodesComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('keyboardShortcuts') keyboardShortcuts;
  @ViewChild('SpecimenCode') SpecimenCode;

  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  widthWindow: any = '600px';
  heightWindow: any = '270px';
  unicalGuid;
  shortcuts: ShortcutInput[] = [];
  focusedSelected: any = [];
  OrderDocumentPositionId;
  WarehouseDocumentPositionId;
  SadDocumentPositionId;
  ProdDocumentPositionId;
  OfferDocumentPositionId;

  dataSource: any = [];
  selectedRows: any = [];
  focusedRowIndex: number = 0;
  Amount;
  form;
  mode;
  submitted: boolean = false;
  IsGroup: boolean = false;
  valueIncrement: number = 0;
  AmountToEnter: number = 0;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnInit(): void {
    this.onCreateForm();
  }

  ngOnChanges() {
    if (this.mode == 'edit' && this.isVisible && this.selected.length > 0) {
      setTimeout(() => {
        this.form.patchValue(this.selected[0]);
        if (this.selected[0].IsGroup == null)
          this.form.controls['IsGroup'].setValue(false);
      }, 0);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.dataSource = [];
      this.onCreateForm();
    }
    this.submitted = false;
    this.cd.detectChanges();
  };

  closeWindow() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  onCreateForm() {
    this.form = this.formBuilder.group({
      SpecimenCodeId: [0],
      SpecimenCode: ['', Validators.required],
      SpecimenCodeCopy: [''],
      OrderDocumentPositionId: [0],
      WarehouseDocumentPositionId: [0],
      FlagState: [0],
      SadDocumentPositionId: [0],
      ProdDocumentPositionId: [0],
      OfferDocumentPositionId: [0],
      IsGroup: [false],
      Interval: [0],
      AmountFrom: [0],
      Amonut: [0],
    });
  }

  setId() {
    this.form.controls['OrderDocumentPositionId'].setValue(
      this.OrderDocumentPositionId
    );
    this.form.controls['WarehouseDocumentPositionId'].setValue(
      this.WarehouseDocumentPositionId
    );
    this.form.controls['SadDocumentPositionId'].setValue(
      this.SadDocumentPositionId
    );
    this.form.controls['ProdDocumentPositionId'].setValue(
      this.ProdDocumentPositionId
    );
    this.form.controls['OfferDocumentPositionId'].setValue(
      this.OfferDocumentPositionId
    );
  }

  isOnlyNumber(evt) {
    let regex = /^[0-9]+$/;

    return regex.test(evt);
  }

  onValueChanged(e) {
    if (e) {
      if (!this.isOnlyNumber(this.form.controls['SpecimenCode'].value)) {
        this.event.showNotification(
          'error',
          this.translate.instant(
            'warehouseDocument.theBatchInputOperationCanOnlyBePerformedForAcodeWithAnumericValue'
          )
        );
        setTimeout(() => {
          this.IsGroup = false;
          this.cd.detectChanges();
        }, 0);
      } else {
        this.form.controls['Amonut'].setValue(this.Amount);
        this.form.controls['Interval'].setValue(1);
        this.form.controls['AmountFrom'].setValue(
          Number(this.form.value.SpecimenCode)
        );
        // this.AmountToEnter = this.Amount;
        // this.valueIncrement = 1;
        this.cd.detectChanges();
      }
    }
  }

  onSave() {
    this.submitted = true;

    if (this.form.invalid) return;

    if (this.form.value.SpecimenCodeId == 0) {
      this.appService
        .postAuth(`warehouseDocuments/documents/specimens`, this.form.value)
        .subscribe(
          () => {
            this.onInserted.emit(this.form.value);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    } else {
      this.appService
        .postAuth(
          `warehouseDocuments/documents/specimensmodyfiy`,
          this.form.value
        )
        .subscribe(
          () => {
            this.onInserted.emit(this.form.value);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  }
}

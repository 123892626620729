import {
  Component,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
} from '@angular/core';
import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-exemplary-codes',
    templateUrl: './exemplary-codes.component.html',
    styleUrls: ['./exemplary-codes.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'selected', 'type'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ExemplaryCodesComponent {
  @Output() onClosing = new EventEmitter();
  @ViewChild('keyboardShortcuts') keyboardShortcuts;
  @ViewChild('gridBank') gridBank;
  @ViewChild('TextSearch') TextSearch;

  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  widthWindow: any = '75%';
  heightWindow: any = '620px';
  unicalGuid;
  shortcuts: ShortcutInput[] = [];
  focusedSelected: any = [];

  dataSource: any = [];
  selectedRows: any = [];
  focusedRowIndex: number = 0;
  Amount: number = 0;
  type;
  addRow: boolean = false;
  mode: string = 'add';

  isRowDelete: boolean = false;

  WarehouseDocumentPositionId;
  OrderDocumentPositionId;
  SadDocumentPositionId;
  ProdDocumentPositionId;
  OfferDocumentPositionId;

  searchValue: string = '';

  icon = 'icon absui-icon--add-circle';
  extraButton;
  selectBoxOpened: boolean = false;

  filterSource: any[] = [
    { label: 'Dodaj', value: 'add' },
    { label: 'Usuń', value: 'edit' },
    { label: 'Szukaj', value: 'search' },
  ];

  modeSource: any[] = [
    { label: 'Dodaj', value: 0, icon: 'absui-icon--add-circle' },
    { label: 'Odejmij', value: 1, icon: 'absui-icon--remove-circle-outline' },
    { label: 'Szukaj', value: 2, icon: 'icon-search' },
  ];
  activeMode: number = 0;

  selectedItem: string = 'add';

  contextMenu: any;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.extraButton = {
      icon: 'no-stroke-width icon absui-icon--add-circle',
      type: 'default',
      elementAttr: {
        id: 'extra' + this.unicalGuid,
      },
      onClick: () => {
        // if (!this.filterValue.length) {
        //   this.isPopupVisible = true;
        // } else {
        //   this.onChoosed(this.filterValue);
        // }
      },
    };

    this.contextMenu = [
      {
        text: 'Dodaj',
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
        translationSrc: 'buttons.add',
      },
      {
        text: 'Edytuj',
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
        translationSrc: 'buttons.edit',
      },
      {
        text: 'Usuń',
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
        translationSrc: 'buttons.delete',
      },
    ];
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.addCode();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.editCode();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.isDelete();
        },
        preventDefault: true,
      }
    );
  }

  changeMode() {
    if (this.icon == 'icon absui-icon--add-circle') {
      this.icon = 'icon absui-icon--remove-circle-outline';
    } else if (this.icon == 'icon absui-icon--remove-circle-outline') {
      this.icon = 'icon icon-search';
    } else if (this.icon == 'icon icon-search') {
      this.icon = 'icon absui-icon--add-circle';
    }
  }

  onEnterKey() {
    if (this.activeMode === 2) {
      let index = this.dataSource.findIndex(
        (field) => field.SpecimenCode == this.searchValue
      );

      if (index != -1) {
        this.focusedRowIndex = index;
        this.focusedSelected = [this.dataSource[index]];
      }
    } else if (this.activeMode === 1) {
      let index = this.dataSource.findIndex(
        (field) => field.SpecimenCode == this.searchValue
      );

      if (index != -1) {
        this.focusedRowIndex = index;
        this.focusedSelected = [this.dataSource[index]];
        this.isDelete();
      }
    } else if (this.activeMode === 0) {
      if (this.Amount > 0) {
        let object = {
          SpecimenCodeId: 0,
          SpecimenCode: this.searchValue,
          SpecimenCodeCopy: '',
          OrderDocumentPositionId: this.OrderDocumentPositionId,
          WarehouseDocumentPositionId: this.WarehouseDocumentPositionId,
          FlagState: 0,
          SadDocumentPositionId: this.SadDocumentPositionId,
          ProdDocumentPositionId: this.ProdDocumentPositionId,
          OfferDocumentPositionId: this.OfferDocumentPositionId,
          IsGroup: false,
          Amonut: 0,
          Interval: 0,
          AmountFrom: 0,
        };

        this.appService
          .postAuth(`warehouseDocuments/documents/specimens`, object)
          .subscribe(
            () => {
              this.getData().then(() => {
                let index = this.dataSource.findIndex(
                  (field) => field.SpecimenCode == object.SpecimenCode
                );
                if (index != -1) {
                  setTimeout(() => {
                    this.focusedRowIndex = index;
                    this.focusedSelected = [this.dataSource[index]];
                  }, 100);
                  this.focusedRowIndex = index;
                  this.focusedSelected = [this.dataSource[index]];
                } else {
                  this.focusedRowIndex = 0;
                  this.focusedSelected = [this.dataSource[0]];
                }
              });
              this.searchValue = '';
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  }

  addCode() {
    if (this.Amount == 0) return;
    if (this.readOnly) return;

    this.mode = 'add';
    this.addRow = true;
    this.cd.detectChanges();
  }

  onKeyDownTextSearch(e) {
    if (e.event.keyCode == 40 || e.event.keyCode == 38) {
      this.event.setFocus(this.gridBank);
    }
  }

  editCode() {
    if (this.readOnly) return;

    if (this.focusedSelected.length == 0) return;

    this.mode = 'edit';
    this.addRow = true;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.dataSource = [];
      this.Amount = 0;
    }
    this.cd.detectChanges();
  };

  closeWindow() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  onRowDblClick = () => {
    this.editCode();
  };

  onFocusedRowChanged = (e) => {
    if (e && e.row) {
      this.focusedSelected = [e.row.data];
    }
  };

  onRowClick = (e) => {
    try {
      this.focusedSelected = [e.data];
    } catch {}
  };

  onSelectionChanged = () => {};

  getData() {
    return new Promise((resolve) => {
      let url = `warehouseDocuments/documents/PositionSpecimenWarehouseGet/WarehouseDocuments/${this.selected[0].WarehouseDocumentPositionId}`;

      if (this.type == 'order') {
        url = `warehouseDocuments/documents/PositionSpecimenWarehouseGet/OrderDocuments/${this.selected[0].OrderDocumentPositionId}`;
      }

      if (this.type == 'sad') {
        url = `warehouseDocuments/documents/PositionSpecimenWarehouseGet/SadDocuments/${this.selected[0].SadDocumentPositionId}`;
      }

      if (this.type == 'prod') {
        url = `warehouseDocuments/documents/PositionSpecimenWarehouseGet/ProdDocuments/${this.selected[0].ProductionDocumentPositionId}`;
      }

      if (this.type == 'offer') {
        url = `warehouseDocuments/documents/PositionSpecimenWarehouseGet/OfferDocuments/${this.selected[0].OfferDocumentPositionId}`;
      }
      if (this.selected && this.selected.length > 0) {
        this.Amount = this.selected[0].Amount;
        this.WarehouseDocumentPositionId = this.selected[0]
          .WarehouseDocumentPositionId
          ? this.selected[0].WarehouseDocumentPositionId
          : 0;

        this.OrderDocumentPositionId = this.selected[0].OrderDocumentPositionId
          ? this.selected[0].OrderDocumentPositionId
          : 0;
        this.SadDocumentPositionId = this.selected[0].SadDocumentPositionId
          ? this.selected[0].SadDocumentPositionId
          : 0;
        this.ProdDocumentPositionId = this.selected[0]
          .ProductionDocumentPositionId
          ? this.selected[0].ProductionDocumentPositionId
          : 0;
        this.OfferDocumentPositionId = this.selected[0].OfferDocumentPositionId
          ? this.selected[0].OfferDocumentPositionId
          : 0;

        this.appService.getAuth(url).subscribe(
          (res) => {
            this.dataSource = res;
            this.Amount = this.Amount - this.dataSource.length;
            this.cd.detectChanges();
            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
            resolve(true);
          }
        );
      }
    });
  }

  async onInserted(e) {
    await this.getData();
    let index = this.dataSource.findIndex(
      (field) => field.SpecimenCode == e.SpecimenCode
    );
    if (index != -1) {
      setTimeout(() => {
        this.focusedRowIndex = index;
        this.focusedSelected = [this.dataSource[index]];
        this.event.setFocus(this.gridBank);
      }, 100);
      this.focusedRowIndex = index;
      this.focusedSelected = [this.dataSource[index]];
    } else {
      this.focusedRowIndex = 0;
      this.focusedSelected = [this.dataSource[0]];
      this.event.setFocus(this.gridBank);
    }
    this.addRow = false;
    this.cd.detectChanges();
  }

  onClosingAdd() {
    this.addRow = false;
    this.cd.detectChanges();
  }

  isDelete() {
    if (!this.readOnly && this.focusedSelected.length > 0) {
      this.isRowDelete = true;
      this.cd.detectChanges();
    }
  }

  onKeyDown(e) {
    if (e.event.keyCode === 27) {
      this.closeWindow();
    }

    if (e.event.keyCode == 113 || e.event.keyCode == 46) {
      //f2 escape
      e.event.preventDefault();
    }
  }

  closeConfirm() {
    this.isRowDelete = false;
    this.cd.detectChanges();
  }

  onModeValueChanged() {}

  getIcon() {
    if (this.activeMode == 0) return 'icon absui-icon--add-circle';
    if (this.activeMode == 1) return 'icon absui-icon--remove-circle-outline';
    if (this.activeMode == 2) return 'icon icon-search';

    return 'icon absui-icon--add-circle';
  }

  toggleSelectBox() {
    this.selectBoxOpened = !this.selectBoxOpened;
  }

  delete() {
    this.isRowDelete = false;
    if (this.selectedRows.length > 0) {
      let forkArray = [];

      this.selectedRows.forEach((element) => {
        forkArray.push(
          this.appService.deleteAuth(
            `warehouseDocuments/specimens/${element.SpecimenCodeId}`
          )
        );
      });

      forkJoin(forkArray).subscribe(
        () => {
          this.focusedRowIndex = 0;

          this.getData();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );

      return;
    }

    this.appService
      .deleteAuth(
        `warehouseDocuments/specimens/${this.focusedSelected[0].SpecimenCodeId}`
      )
      .subscribe(
        () => {
          this.focusedRowIndex = 0;

          this.getData();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  contextMenuClick(e) {
    switch (e.itemData.itemIndex) {
      case 0:
        this.addCode();
        break;

      case 1:
        this.editCode();
        break;

      case 2:
        this.isDelete();
        break;
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { AppServices } from "../../../app-services.service";
import { EventService } from "../../../event.service";
import { DefaultObject } from "./../../../nest/nest.model";
import { Socket } from "./../../../socket.model";
import { DxDataGridComponent } from "devextreme-angular";

@Component({
    selector: "app-profits",
    templateUrl: "./profits.component.html",
    styleUrls: ["./profits.component.scss"],
    inputs: ["isVisible"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ProfitsComponent {
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  componentNests: Socket;
  nest: Socket;
  isNestRunning: boolean = false;
  openNestFinished: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild("focusFirst") focusFirst;
  @ViewChild("gridBank") gridBank:DxDataGridComponent

  isVisible;
  selectedId;
  readOnly;
  title;
  widthWindow = 670;
  heightWindow = 350;

  shortcuts: ShortcutInput[] = [];

  submitted: boolean = false;

  unicalGuid;

  dataSource: any = [];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.shortcuts.push({
      key: "esc",
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.closePopup();
        this.cd.detectChanges();
      },
      preventDefault: true,
    });
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  closePopup() {
    this.isVisible = false;
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  getData() {
    this.appService.getAuth(`services/additionalOperations/profits`).subscribe(
      (res) => {
        this.dataSource.push({
          Name: this.translate.instant("form-financial-document.Invoiced"),
          TotalBuyPrice: res.InvoiceBuyPrice ? res.InvoiceBuyPrice : 0,
          TotalPrice: res.InvoicePrice ? res.InvoicePrice : 0,
          TotalProfit: res.InvoiceProfit ? res.InvoiceProfit : 0,
        });

        this.dataSource.push({
          Name: this.translate.instant("form-financial-document.Stocked"),
          TotalBuyPrice: res.WarehouseBuyPrice ? res.WarehouseBuyPrice : 0,
          TotalPrice: res.WarehousePrice ? res.WarehousePrice : 0,
          TotalProfit: res.WarehouseProfit ? res.WarehouseProfit : 0,
        });

        this.dataSource.push({
          Name: this.translate.instant("form-financial-document.count"),
          TotalBuyPrice: res.TotalBuyPrice ? res.TotalBuyPrice : 0,
          TotalPrice: res.TotalPrice ? res.TotalPrice : 0,
          TotalProfit: res.TotalProfit ? res.TotalProfit : 0,
        });
        this.cd.detectChanges();
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  }
}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="onClose()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.close' | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="d-flex justify-content-start flex-wrap">
        <dx-button
          class="outline-secondary"
          text="{{ 'form-financial-document.setNumber' | translate }}"
          type="default"
          stylingMode="text"
          [id]="'btn-edit' + unicalGuid"
          [disabled]="readOnly || focusedSelected.length != 1"
          (onClick)="onSetNumber()"
        ></dx-button>

        <dx-data-grid
          [showBorders]="true"
          [wordWrapEnabled]="false"
          [hoverStateEnabled]="true"
          [dataSource]="dataSource()"
          shortcut
          [focusedRowEnabled]="true"
          [allowColumnResizing]="true"
          [columnResizingMode]="'widget'"
          [(selectedRowKeys)]="selectedRowKeys"
          style="
            width: 100%;
            min-height: 50px;
            margin-bottom: 10px;
            margin-top: 10px;
          "
          [height]="370"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [(focusedRowIndex)]="focusedRowIndex"
          (onKeyDown)="onKeyDown($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection
            mode="single"
            showCheckBoxesMode="always"
            selectAllMode="page"
          ></dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            [caption]="'form-financial-document.number' | translate"
            dataField="WarehouseDocumentNumber"
            [allowSorting]="false"
            [width]="150"
          ></dxi-column>
          <dxi-column
            [caption]="'form-financial-document.date' | translate"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            dataField="DateOfWarehouseDocument"
            [allowSorting]="false"
          ></dxi-column>
          <dxi-column
            [caption]="'menu.warehouse' | translate"
            dataField="WarehouseName"
            [allowSorting]="false"
          ></dxi-column>
        </dx-data-grid>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

@if(isSetNumber()){
<app-set-number
  [isVisible]="true"
  (onClosing)="isSetNumber.set(false)"
  [warehouseDocumentData]="focusedSelected[0]"
  (onInserted)="isSetNumber.set(false); getData()"
></app-set-number>
}

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>
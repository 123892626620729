import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxTabPanelComponent } from 'devextreme-angular';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { NestService } from 'src/app/nest-service.service';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { Socket } from 'src/app/socket.model';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';
import { HelpService } from '../../../../help-service.service';
import { DefaultObject, NestOperation } from './../../../../nest/nest.model';

@Component({
    selector: 'app-position-warehouse-document',
    templateUrl: './position-warehouse-document.component.html',
    styleUrls: ['./position-warehouse-document.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'selected',
        'customerId',
        'documentId',
        'documentDate',
        'documentType',
        'destinationWarehouseId',
        'typeOfCrossBorderSale',
        'editMode',
        'isCorrect',
        'WarehouseDocumentOriginalId',
        'componentNests',
        'sumBy',
        'customerIdRetailSalesProdDoc',
        'IsMargin',
        'articleAmountOnDocument',
        'warehouseId',
    ],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class PositionWarehouseDocumentComponent implements OnInit {
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocused') firstFocused;
  @ViewChild('tabPanel') tabPanel: DxTabPanelComponent;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  @ViewChild('FactorInput') FactorInput;
  @ViewChild('priceNetInput') priceNetInput;
  @ViewChild('priceGrosstInput') priceGrosstInput;
  @ViewChild('priceDiscount') priceDiscount;
  @ViewChild('valueGross') valueGross;
  @ViewChild('inputDiscount') inputDiscount;
  @ViewChild('inputPriceDiscount') inputPriceDiscount;
  @ViewChild('gridBoxUnit') gridBoxUnit;
  unicalGuid;
  className = false;
  scrollPosition = 0;
  widthWindow = '730px';
  heightWindow = '600px';
  customerIdRetailSalesProdDoc = null;
  warehouseId;
  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  selectedtab = 0;
  customerId;
  documentId;
  documentDate;
  documentType;
  destinationWarehouseId;
  typeOfCrossBorderSale;
  isCorrect;
  WarehouseDocumentOriginalId;
  priceVisible: boolean = false;

  isDefaultMarkupVisible: boolean = false;
  sumBy;
  shortcuts: ShortcutInput[] = [];
  IsMargin;
  articleToAdd = {
    ProductFullName: '',
    StockAvailable: '',
    IndexCatalogue: '',
  };

  visibleUserExtensions: boolean = false;
  isGridBoxOpened: boolean = false;
  Factor;
  positionForm: any = {
    ProductId: 0,
    Amount: 0,
    Unit: '',
    PriceNet: 0,
    PriceGross: 0,
    Discount: 0,
    TaxCode: '',
    Description: '',
    PriceIdentificator: '',
    SerialNumber: '',
    ExpirationDate: '',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    PriceNetSale: null,
    PriceGrossSale: null,
    PriceNetWasteManagement: null,
    PriceGrossWasteManagement: null,
    WarehouseDocumentPositionDeliveryId: null,
    PriceDescription: '',
    IsModifyPriceWhenDelivery: false,
    WarehouseDocumentId: 0,
    WarehouseDateOfDocument: '',
    Factor: 0,
    DestinationWarehouseId: 0,
    TypeOfCrossBorderSale: '',
    DeliveryId: 0,
    WarehouseDocumentPositionId: 0,
    DateOfApprovalForUse: null,
    Mileage: '',
    ExemptionCode: 0,
    ExemptionDescription: '',
  };
  editMode;

  componentNests: Socket;
  isPermissionToChangeDiscount: boolean = false;
  FormPozDokMag = 'z cenami';
  showMore: boolean = true;

  itemsTabLess = ['Dane podstawowe'];
  AmountPrevious = null;
  radioType = [
    { value: 0, label: 'Nie dotyczy' },
    { value: 1, label: 'Ustawa' },
    { value: 2, label: 'Dyrektywa' },
    { value: 3, label: 'Inny' },
  ];

  OmijajIloscJedMag: boolean = true;
  FormPozDokMagKD: boolean = false;
  selectedAccordionItems = [];

  localStorageData = {
    skipUserGuide: false,
  };
  dataSourceAccordion = ['Zaawansowane'];
  ArtykCenWyborPozDok: boolean = false;
  PriceDescription: string = '';
  items = [1, 2];

  ArtykCenESDok: boolean = false;
  ArtykCenEZDok: boolean = false;
  articleAmountOnDocument: number = 0;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private helpService: HelpService,
    private nestService: NestService
  ) {
    this.createForm();
    this.ArtykCenESDok = this.event.checkPermissions('ArtykCenESDok');
    this.ArtykCenEZDok = this.event.checkPermissions('ArtykCenEZDok');
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('buttons.advanced')
      .subscribe((text) => (this.dataSourceAccordion[0] = text));
    this.FormPozDokMag =
      this.event.getConfigurationParameter('FormPozDokMag')?.Value;

    let OmijajIloscJedMag =
      this.event.getConfigurationParameter('OmijajIloscJedMag')?.Value;

    OmijajIloscJedMag == 'Tak'
      ? (this.OmijajIloscJedMag = true)
      : (this.OmijajIloscJedMag = false);

    this.translate
      .get('warehouseDocument.NotApplicable')
      .subscribe((text) => (this.radioType[0].label = text));
    this.translate
      .get('warehouseDocument.Act')
      .subscribe((text) => (this.radioType[1].label = text));
    this.translate
      .get('warehouseDocument.Directive')
      .subscribe((text) => (this.radioType[2].label = text));
    this.translate
      .get('warehouseDocument.Other')
      .subscribe((text) => (this.radioType[3].label = text));

    if (this.FormPozDokMag == 'bez cen') {
      this.showMore = false;
      this.selectedAccordionItems = [];
      this.heightWindow = '310px';
    } else {
      this.selectedAccordionItems = [this.dataSourceAccordion[0]];
    }

    const localData = localStorage.getItem(
      'positionWarehouseDocumentComponent'
    );
    if (localData) {
      this.localStorageData = JSON.parse(localData);
    }

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );
  }

  afterOpen() {
    setTimeout(() => {
      if (this.form?.TaxCode.trim() == 'ZW') {
        this.items = [1, 2, 3];
      } else {
        this.items = [1, 2];
      }
    }, 0);

    setTimeout(() => {
      this.event.setFocus(this.firstFocused);
      this.cd.detectChanges();
    }, 500);
  }

  ngOnDestroy() {
    this.isAutoOnboardingSub?.unsubscribe();
    this.event.onHiddenPopUp();
  }

  checkKeyboard = () => {
    try {
      let FormPozDokMagKD =
        this.event.getConfigurationParameter('FormPozDokMagKD')?.Value;

      FormPozDokMagKD == 'Tak'
        ? (this.FormPozDokMagKD = true)
        : (this.FormPozDokMagKD = false);
    } catch {}
  };

  focusedElemetntRef;

  setFocus(e) {
    setTimeout(() => {
      this.event.setFocus(e);
      this.focusedElemetntRef = e;
    }, 0);
  }

  onEnter = (e) => {
    switch (this.modelToChange) {
      case 'Quantity':
        this.form.Quantity = e;
        break;

      case 'PriceDetail.PriceNet':
        this.form.PriceDetail.PriceNet = e;
        if (this.form.BaseUnitId == this.form.UnitId) {
          this.form.PriceDetail.PriceNetBase = this.form.PriceDetail.PriceNet;
        } else {
          this.form.PriceDetail.PriceNetBase =
            this.form.PriceDetail.PriceNet / this.form.PriceDetail.Factor;
        }

        this.form.PriceDetail.PriceGross = Number(
          this.event.countAmountGross(
            this.form.PriceDetail.PriceNet,
            this.form.TaxRate
          )
        );
        this.changeAmount();
        break;
      case 'PriceDetail.PriceGross':
        this.form.PriceDetail.PriceGross = e;
        this.form.PriceDetail.PriceNet = this.event.countAmountNet(
          this.form.PriceDetail.PriceGross,
          this.form.TaxRate
        );

        if (this.form.BaseUnitId == this.form.UnitId) {
          this.form.PriceDetail.PriceNetBase = this.form.PriceDetail.PriceNet;
        } else {
          this.form.PriceDetail.PriceNetBase =
            this.form.PriceDetail.PriceNet * this.form.PriceDetail.Factor;
        }
        this.changeAmount();
        break;
      case 'PriceDetail.Factor':
        this.form.PriceDetail.Factor = e;
        break;
      case 'PriceDetail.PriceNetBase':
        this.form.PriceDetail.PriceNetBase = e;
        if (this.form.BaseUnitId == this.form.UnitId) {
          this.form.PriceDetail.PriceNet = this.form.PriceDetail.PriceNetBase;
        } else {
          this.form.PriceDetail.PriceNet =
            this.form.PriceDetail.Factor * this.form.PriceDetail.PriceNetBase;
        }

        this.form.PriceDetail.PriceGross = Number(
          this.event.countAmountGross(
            this.form.PriceDetail.PriceNet,
            this.form.TaxRate
          )
        );
        this.changeAmount();
        break;
      case 'PriceDetail.Discount':
        this.form.PriceDetail.Discount = e / 100;
        this.form.PriceDetail.DiscountOryginal = e;
        break;
    }

    this.event.setFocus(this.focusedElemetntRef);
  };

  modelToChange = '';
  setFocusedElemetntRef = (e, focus) => {
    this.modelToChange = e;
    this.focusedElemetntRef = focus;
  };

  changeShowMore = () => {
    this.showMore = !this.showMore;
    this.showMore
      ? (this.heightWindow = '560px')
      : (this.heightWindow = '310px');
    this.cd.detectChanges();
  };

  nextTab = () => {
    this.focusedElemetntRef.element.nativeElement.dispatchEvent(
      new KeyboardEvent('keydown', { key: 'tab' })
    );
  };

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.isPermissionToChangeDiscount =
      this.event.checkPermissions('DokMagEdytRabatPoz');

    this.ArtykCenWyborPozDok = this.event.checkPermissions(
      'ArtykCenWyborPozDok'
    );
    this.createForm();
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.refreshKeyPressed.next();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.beforeCloseWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.checkOstrzegajMinMax();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/dokumenty/magazynowe/dokumenty-magazynowe-formatka'
          );
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 1;
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  setUserExtensions = (selected) => {
    this.field.Field1 = selected.Field1;
    this.field.Field2 = selected.Field2;
    this.field.Field3 = selected.Field3;
    this.field.Field4 = selected.Field4;
    this.field.Field5 = selected.Field5;
    this.field.Field6 = selected.Field6;
    this.field.Field7 = selected.Field7;
    this.field.Field8 = selected.Field8;
    this.field.Field9 = selected.Field9;
    this.field.Field10 = selected.Field10;
  };

  getDefautlDate() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isVisible && changes.isVisible.currentValue) {
      if (!this.isCorrect) {
        if (!this.editMode) {
          // add
          this.findSocketAndRun('XGMPDO1');
        } else {
          // edit
          this.findSocketAndRun('XGMPPO1');
        }
      } else {
        this.openNestFinished = true;
      }

      if (changes?.isVisible?.currentValue) {
        setTimeout(() => {
          if (
            !this.localStorageData.skipUserGuide &&
            this.isAutoOnboarding &&
            this.event.deviceType === 'desktop' &&
            this.isVisible
          ) {
            this.isGuideActive = true;
          }
        }, 2000);
      }
    }

    if (this.isVisible && this.selected && !this.editMode) {
      if (
        this.documentType.Signature.replace(/ /g, '') == 'PZ' ||
        this.documentType.Signature.replace(/ /g, '') == 'ZU'
      ) {
        this.documentType.IsIncome = true;
      }
      this.articleToAdd.ProductFullName = this.selected.ProductFullName;
      this.articleToAdd.StockAvailable = this.selected.StockAvailable;
      this.articleToAdd.IndexCatalogue = this.selected.IndexCatalogue;

      this.positionForm.PriceNetSale = this.selected.SellPriceNetDefault;
      this.positionForm.PriceGrossSale = this.selected.SellPriceGrossDefault;
      this.positionForm.PriceNetWasteManagement = this.selected.PriceNetKGO;
      this.positionForm.PriceGrossWasteManagement = this.selected.PriceGrossKGO;

      let dateOfDo = new DatePipe('en-US').transform(
        this.documentDate,
        'yyyy-MM-dd'
      );

      this.setUserExtensions(this.selected);

      let ProductId;
      if (typeof this.selected == 'number') {
        ProductId = this.selected;
      } else {
        ProductId = this.selected.ProductId;
      }

      if (!this.customerId && this.customerIdRetailSalesProdDoc) {
        this.customerId = this.customerIdRetailSalesProdDoc;
      }

      let filter = `ProductId=${ProductId}&CustomerId=${this.customerId}&DocumentId=${this.documentId}&documentDate=${dateOfDo}&DocumentType=${this.documentType.Signature}&Currency=&CurrencyFactor=&IsBuy=${this.documentType.IsIncome}`;
      //let filter = `ProductId=${this.selected.ProductId}&CustomerId=${this.customerId}&DocumentId=977&documentDate=${dateOfDo}&DocumentType=${this.documentType.Signature}&Currency=&CurrencyFactor=&IsBuy=${this.documentType.IsIncome}`;

      this.appService
        .getAuth(
          `products/${ProductId}/productDetailsForCustomer/${this.customerId}?${filter}`
        )
        .subscribe(
          (res) => {
            this.PriceDescription = res.PriceDetail.PriceDescription;
            this.IsDivisible = res.PriceDetail.IsDivisible;
            this.Factor = res.PriceDetail.Factor;
            this.form = res;
            this.selected.EnteredAmount == 0
              ? (this.form.Quantity = 1)
              : (this.form.Quantity = this.selected.EnteredAmount);
            //this.form.Quantity = 1;
            this.form.QuantityWarehouse = 1;

            this.form.PriceDetail.AmountNet = this.form.PriceDetail.PriceNet;
            this.form.PriceDetail.PriceWithDiscount =
              this.form.PriceDetail.PriceNet;
            this.form.PriceDetail.AmountGross =
              this.form.PriceDetail.Factor *
              this.form.PriceDetail.PriceGrossBase;
            this.form.PriceDetail.DiscountOryginal = res.PriceDetail.Discount;
            if (this.form.PriceDetail.Discount != 0) {
              this.form.PriceDetail.Discount =
                this.form.PriceDetail.Discount / 100;
              this.form.PriceDetail.PriceWithDiscount =
                this.form.PriceDetail.PriceWithDiscount *
                (this.form.PriceDetail.Discount + 1);
            }

            if (this.form.UnitId == 0 || this.form.UnitId == null) {
              this.form.UnitId = this.form.BaseUnitId;
            }

            if (this.form.UnitCode == null) {
              this.form.UnitCode = this.form.BaseUnitCode;
            }

            this.articleToAdd.ProductFullName = res.ProductFullName;
            this.articleToAdd.StockAvailable = res.StockAvailable;
            this.articleToAdd.IndexCatalogue = res.IndexCatalogue;

            this.form.DateOfApprovalForUse = null;
            this.form.Mileage = '';
            this.form.ExemptionCode = 0;
            this.form.ExemptionDescription = '';

            setTimeout(() => {
              if (Number.isNaN(this.form.Quantity)) {
                this.form.Quantity;
                this.form.PriceDetail.Factor = this.Factor;
                this.cd.detectChanges();
              }
            }, 500);
            this.afterOpen();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }

    if (this.isVisible && this.selected && this.editMode && !this.isCorrect) {
      if (
        this.documentType.Signature.replace(/ /g, '') == 'PZ' ||
        this.documentType.Signature.replace(/ /g, '') == 'ZU'
      ) {
        this.documentType.IsIncome = true;
      }
      this.articleToAdd.ProductFullName = this.selected[0].ProductFullName;
      this.articleToAdd.StockAvailable = this.selected[0].TotalLeft;
      this.articleToAdd.IndexCatalogue = this.selected[0].IndexCatalogue;
      this.Description = this.selected[0].Description;
      this.positionForm.PriceNetWasteManagement =
        this.selected[0].PriceNetWasteManagement;
      this.positionForm.PriceGrossWasteManagement =
        this.selected[0].PriceGrossWasteManagement;
      this.positionForm.PriceNetSale = this.selected[0].PriceNetSale;
      this.positionForm.PriceGrossSale = this.selected[0].PriceGrossSale;

      let dateOfDo = new DatePipe('en-US').transform(
        this.documentDate,
        'yyyy-MM-dd'
      );

      if (!this.customerId && this.customerIdRetailSalesProdDoc) {
        this.customerId = this.customerIdRetailSalesProdDoc;
      }

      let filter = `ProductId=${this.selected[0].ProductId}&CustomerId=${this.customerId}&DocumentId=${this.documentId}&documentDate=${dateOfDo}&DocumentType=${this.documentType.Signature}&Currency=&CurrencyFactor=&IsBuy=${this.documentType.IsIncome}`;

      this.appService
        .getAuth(
          `products/${this.selected[0].ProductId}/productDetailsForCustomer/${this.customerId}?${filter}`
        )
        .subscribe(
          (res) => {
            this.form = res;
            this.Factor = res.PriceDetail.Factor;
            this.form.Quantity = 1;
            this.form.QuantityWarehouse = 1;

            this.form.PriceDetail.AmountNet = this.form.PriceDetail.PriceNet;
            this.form.PriceDetail.PriceWithDiscount =
              this.form.PriceDetail.PriceNetBase;
            this.form.PriceDetail.AmountGross =
              this.form.PriceDetail.Factor *
              this.form.PriceDetail.PriceGrossBase;
            this.form.PriceDetail.DiscountOryginal = res.PriceDetail.Discount;

            if (this.form.PriceDetail.Discount != 0) {
              this.form.PriceDetail.Discount =
                this.form.PriceDetail.Discount / 100;
              this.form.PriceDetail.PriceWithDiscount =
                this.form.PriceDetail.PriceWithDiscount *
                (this.form.PriceDetail.Discount + 1);
            }
            this.form.Units.forEach((field) => {
              field.Quantity = this.form.PriceDetail.Factor / field.Factor;
            });

            if (this.form.UnitId == 0 || this.form.UnitId == null) {
              this.form.UnitId = this.form.BaseUnitId;
            }
            this.form.PriceDetail.Discount = this.selected[0].Discount / 100;
            this.articleToAdd.ProductFullName = res.ProductFullName;
            this.articleToAdd.StockAvailable = res.StockAvailable;
            this.articleToAdd.IndexCatalogue = res.IndexCatalogue;

            let unitId = res.Units.find(
              (field) =>
                field.Code == this.selected[0].Unit ||
                field.UnitName == this.selected[0].Unit
            );
            try {
              this.form.UnitId = unitId.UnitId;
              this.form.UnitCode = unitId.Code;
              this.Factor = unitId.Factor;
              this.form.Quantity = this.selected[0].Amount;
              this.form.PriceDetail.Factor =
                unitId.Factor * this.selected[0].Amount;
              //ustawiam wartosci na podstawie edytowanej pozycji
              this.form.UnitId = unitId.UnitId;
              this.IsDivisible = unitId.IsDivisible;
            } catch {}
            this.form.DateOfApprovalForUse =
              this.selected[0].DateOfApprovalForUse;
            this.form.Mileage = this.selected[0].Mileage;
            this.form.ExemptionCode = this.selected[0].ExemptionCode;
            this.form.ExemptionDescription =
              this.selected[0].ExemptionDescription;

            this.form.PriceDetail.PriceName = '';
            this.PriceDescription = this.selected[0].PriceDescription;
            let staticData = this.event.decryptString(
              localStorage.getItem('staticData')
            );
            this.cd.detectChanges();
            setTimeout(() => {
              this.form.TaxCode = this.selected[0].TaxCode;
              this.form.TaxRate = staticData.taxRates.find(
                (field) => field.TaxCode == this.form.TaxCode
              ).TaxRate;
              this.form.PriceDetail.PriceNetBase = this.selected[0].PriceNet;

              this.form.PriceDetail.PriceNetBase =
                this.form.PriceDetail.PriceNetBase / this.Factor;
              if (this.sumBy.replace(/ /g, '') == 'Brutto') {
                this.form.PriceDetail.PriceGross = this.selected[0].PriceGross;
              }

              let unitId = res.Units.find(
                (field) =>
                  field.Code == this.selected[0].Unit ||
                  field.UnitName == this.selected[0].Unit
              );
              try {
                this.form.UnitId = unitId.UnitId;
                this.form.UnitCode = unitId.Code;
                this.Factor = unitId.Factor;
                this.form.Quantity = this.selected[0].Amount;
                this.form.PriceDetail.Factor =
                  unitId.Factor * this.selected[0].Amount;
                //ustawiam wartosci na podstawie edytowanej pozycji
                this.form.UnitId = unitId.UnitId;
                this.IsDivisible = unitId.IsDivisible;

                this.form.PriceDetail.PriceNetBase = this.selected[0].PriceNet;
                this.form.PriceDetail.PriceNetBase =
                  this.form.PriceDetail.PriceNetBase / this.Factor;

                this.form.PriceDetail.PriceGrossBase =
                  this.selected[0].PriceGross;
                this.form.PriceDetail.PriceGrossBase =
                  this.form.PriceDetail.PriceGrossBase / this.Factor;

                if (this.sumBy.trim() == 'Brutto') {
                  this.form.PriceDetail.PriceGross =
                    this.selected[0].PriceGross;

                  this.form.PriceDetail.PriceNet = this.selected[0].PriceNet;
                }

                if (this.sumBy.trim() == 'Netto') {
                  this.form.PriceDetail.PriceNet = this.selected[0].PriceNet;
                }
              } catch {}
              this.changeAmount();
              this.afterOpen();
              this.cd.detectChanges();

              setTimeout(() => {
                this.form.TaxCode = this.selected[0].TaxCode;
                this.form.TaxRate = staticData.taxRates.find(
                  (field) => field.TaxCode == this.form.TaxCode
                ).TaxRate;
                this.form.PriceDetail.PriceNetBase = this.selected[0].PriceNet;

                this.form.PriceDetail.PriceNetBase =
                  this.form.PriceDetail.PriceNetBase / this.Factor;
                if (this.sumBy.replace(/ /g, '') == 'Brutto') {
                  this.form.PriceDetail.PriceGross =
                    this.selected[0].PriceGross;
                }

                if (this.sumBy.replace(/ /g, '') == 'Netto') {
                  this.form.PriceDetail.PriceNet = this.selected[0].PriceNet;
                }
                this.changeAmount();
                this.afterOpen();
                this.cd.detectChanges();
              }, 0);
            }, 0);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );

      this.positionForm.Field1 = this.selected[0].Field1;
      this.positionForm.Field2 = this.selected[0].Field2;
      this.positionForm.Field3 = this.selected[0].Field3;
      this.positionForm.Field4 = this.selected[0].Field4;
      this.positionForm.Field5 = this.selected[0].Field5;
      this.positionForm.Field6 = this.selected[0].Field6;
      this.positionForm.Field7 = this.selected[0].Field7;
      this.positionForm.Field8 = this.selected[0].Field8;
      this.positionForm.Field9 = this.selected[0].Field9;
      this.positionForm.Field10 = this.selected[0].Field10;

      this.intrastat.CountryCode = this.selected[0].CountryCode;
      this.intrastat.DeliveryCountryCode = this.selected[0].DeliveryCode;
      this.intrastat.TransactionCode = this.selected[0].TransactionCode;
      this.intrastat.CNCode = this.selected[0].CnCode;
      this.intrastat.TransportCode = this.selected[0].TransportCode;
      this.intrastat.OrginCountryCode = this.selected[0].CountryOrginCode;
      this.intrastat.MassNet = this.selected[0].NetWeight;
      this.intrastat.CustomerId = this.selected[0].IntrastaCustomerId;
      this.positionForm.Excise = this.selected[0].Excise;
      this.positionForm.IsSplitPayment = this.selected[0].IsSplitPayment;
      this.intrastat.IsAgriculturalFund = this.selected[0].IsAgriculturalFund;
    }

    if (this.isVisible && this.selected && this.editMode && this.isCorrect) {
      if (
        this.documentType.Signature.replace(/ /g, '') == 'PZ' ||
        this.documentType.Signature.replace(/ /g, '') == 'ZU'
      ) {
        this.documentType.IsIncome = true;
      }
      if (!this.documentType.IsIncome) {
        this.AmountPrevious = this.selected[0].AmountPrevious;
      }

      if (!this.customerId && this.customerIdRetailSalesProdDoc) {
        this.customerId = this.customerIdRetailSalesProdDoc;
      }

      this.appService
        .getAuth(
          `warehouseDocuments/documents?ObjectId=${this.WarehouseDocumentOriginalId}&ExtWarehouseId=${this.warehouseId}`
        )
        .subscribe((res) => {
          let IsIncome = false;
          let filter = `ProductId=${this.selected[0].ProductId}&CustomerId=${
            this.customerId
          }&DocumentId=${res.data[0].WarehouseDocumentId}&documentDate=${
            res.data[0].DateOfWarehouseDocument
          }&DocumentType=${res.data[0].TypeOfDocument.replace(
            ' ',
            ''
          )}&Currency=&CurrencyFactor=&IsBuy=${IsIncome}`;
          this.Description = this.selected[0].Description;
          this.Description = this.selected[0].Description;
          this.positionForm.PriceNetWasteManagement =
            this.selected[0].PriceNetWasteManagement;
          this.positionForm.PriceGrossWasteManagement =
            this.selected[0].PriceGrossWasteManagement;
          this.positionForm.PriceNetSale = this.selected[0].PriceNetSale;
          this.positionForm.PriceGrossSale = this.selected[0].PriceGrossSale;
          this.articleToAdd.ProductFullName = this.selected[0].ProductFullName;
          this.articleToAdd.StockAvailable = this.selected[0].TotalLeft;
          this.articleToAdd.IndexCatalogue = this.selected[0].IndexCatalogue;

          this.appService
            .getAuth(
              `document/sales/types/warehouse?typeId=${res.data[0].WarehouseDocumentTypeId}`
            )
            .subscribe((res) => {
              IsIncome = res.data[0].IsIncome;
              this.appService
                .getAuth(
                  `products/${this.selected[0].ProductId}/productDetailsForCustomer/${this.customerId}?${filter}`
                )
                .subscribe((res) => {
                  this.form = res;
                  this.Factor = res.PriceDetail.Factor;
                  this.form.Quantity = 1;
                  this.form.QuantityWarehouse = 1;

                  this.form.PriceDetail.AmountNet =
                    this.form.PriceDetail.PriceNet;
                  this.form.PriceDetail.PriceWithDiscount =
                    this.form.PriceDetail.PriceNetBase;
                  this.form.PriceDetail.AmountGross =
                    this.form.PriceDetail.Factor *
                    this.form.PriceDetail.PriceGrossBase;
                  this.form.PriceDetail.DiscountOryginal =
                    res.PriceDetail.Discount;
                  if (this.form.PriceDetail.Discount != 0) {
                    this.form.PriceDetail.Discount =
                      this.form.PriceDetail.Discount / 100;
                    this.form.PriceDetail.PriceWithDiscount =
                      this.form.PriceDetail.PriceWithDiscount *
                      (this.form.PriceDetail.Discount + 1);
                  }

                  this.form.Units.forEach((field) => {
                    field.Quantity =
                      this.form.PriceDetail.Factor / field.Factor;
                  });

                  if (this.form.UnitId == 0 || this.form.UnitId == null) {
                    this.form.UnitId = this.form.BaseUnitId;
                  }

                  this.form.PriceDetail.Discount =
                    this.selected[0].Discount / 100;
                  this.articleToAdd.ProductFullName = res.ProductFullName;
                  this.articleToAdd.StockAvailable = res.StockAvailable;
                  this.articleToAdd.IndexCatalogue = res.IndexCatalogue;

                  let unitId = res.Units.find(
                    (field) => field.Code == this.selected[0].Unit
                  );
                  this.form.UnitId = unitId.UnitId;
                  this.form.UnitCode = unitId.Code;
                  this.Factor = unitId.Factor;
                  this.form.Quantity = this.selected[0].Amount;
                  this.form.PriceDetail.Factor =
                    unitId.Factor * this.selected[0].Amount;
                  this.IsDivisible = unitId.IsDivisible;
                  this.form.DateOfApprovalForUse =
                    this.selected[0].DateOfApprovalForUse;
                  this.form.Mileage = this.selected[0].Mileage;
                  this.form.ExemptionCode = this.selected[0].ExemptionCode;
                  this.form.ExemptionDescription =
                    this.selected[0].ExemptionDescription;
                  //ustawiam wartosci na podstawie edytowanej pozycji
                  this.form.UnitId = unitId.UnitId;
                  this.form.PriceDetail.PriceNet = this.selected[0].PriceNet;
                  this.form.PriceDetail.PriceName = '';
                  this.PriceDescription = this.selected[0].PriceDescription;
                  let staticData = this.event.decryptString(
                    localStorage.getItem('staticData')
                  );

                  this.IsDivisibleBase = this.form.Units.find(
                    (field) => field.Code == this.form.BaseUnitCode
                  )?.IsDivisible;
                  setTimeout(() => {
                    this.form.TaxCode = this.selected[0].TaxCode;
                    this.form.TaxRate = staticData.taxRates.find(
                      (field) => field.TaxCode == this.form.TaxCode
                    ).TaxRate;
                    this.form.PriceDetail.Factor =
                      unitId.Factor * this.selected[0].Amount;
                    //ustawiam wartosci na podstawie edytowanej pozycji
                    this.form.UnitId = unitId.UnitId;
                    this.IsDivisible = unitId.IsDivisible;

                    this.form.PriceDetail.PriceNetBase =
                      this.selected[0].PriceNet;
                    this.form.PriceDetail.PriceNetBase =
                      this.form.PriceDetail.PriceNetBase / this.Factor;

                    this.form.PriceDetail.PriceGrossBase =
                      this.selected[0].PriceGross;
                    this.form.PriceDetail.PriceGrossBase =
                      this.form.PriceDetail.PriceGrossBase / this.Factor;

                    if (this.sumBy.trim() == 'Brutto') {
                      this.form.PriceDetail.PriceGross =
                        this.selected[0].PriceGross;

                      this.form.PriceDetail.PriceNet =
                        this.selected[0].PriceNet;
                    }

                    if (this.sumBy.trim() == 'Netto') {
                      this.form.PriceDetail.PriceNet =
                        this.selected[0].PriceNet;
                    }
                    this.changeAmount();
                    this.afterOpen();
                    this.cd.detectChanges();
                  }, 0);
                });
            });
        });
      this.positionForm.Field1 = this.selected[0].Field1;
      this.positionForm.Field2 = this.selected[0].Field2;
      this.positionForm.Field3 = this.selected[0].Field3;
      this.positionForm.Field4 = this.selected[0].Field4;
      this.positionForm.Field5 = this.selected[0].Field5;
      this.positionForm.Field6 = this.selected[0].Field6;
      this.positionForm.Field7 = this.selected[0].Field7;
      this.positionForm.Field8 = this.selected[0].Field8;
      this.positionForm.Field9 = this.selected[0].Field9;
      this.positionForm.Field10 = this.selected[0].Field10;

      this.intrastat.CountryCode = this.selected[0].CountryCode;
      this.intrastat.DeliveryCountryCode = this.selected[0].DeliveryCode;
      this.intrastat.TransactionCode = this.selected[0].TransactionCode;
      this.intrastat.CNCode = this.selected[0].CnCode;
      this.intrastat.TransportCode = this.selected[0].TransportCode;
      this.intrastat.OrginCountryCode = this.selected[0].CountryOrginCode;
      this.intrastat.MassNet = this.selected[0].NetWeight;
      this.intrastat.CustomerId = this.selected[0].IntrastaCustomerId;
      this.intrastat.IsAgriculturalFund = this.selected[0].IsAgriculturalFund;

      this.positionForm.Excise = this.selected[0].Excise;
      this.positionForm.IsSplitPayment = this.selected[0].IsSplitPayment;
    }
  }

  focusedUnit = null;
  onFocusedRowChanged = (e) => {
    this.focusedUnit = e.row.data;
  };

  onChoosedDescription = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });
    }
  };

  form;
  createForm = () => {
    this.form = {
      Quantity: 1,
      ProductFullName: '',
      IndexCatalogue: '',
      IndexTrading: '',
      TaxCode: '',
      StockAvailable: 0,
      DefaultPriceId: 0,
      UnitId: 0,
      PriceDetail: {
        PriceNetBase: 0,
        PriceGrossBase: 0,
        PriceWithDiscount: 0,
        AmountNet: 0,
        AmountGross: 0,
        Unit: '',
        PriceName: '',
        IsDivisible: false,
        PriceCurrency: '',
        PriceDescription: '',
        PriceIdentificator: '',
        PriceParentObjectId: 0,
        PriceObjectType: '',
        PriceId: 0,
        PriceNet: 0,
        PriceGross: 0,
        CurrencyCode: null,
        Factor: 0,
        Discount: 0,
      },
      Units: [],
      TypesOfCrossBorderSale: [],
      DateOfApprovalForUse: null,
      Mileage: [''],
      ExemptionCode: [0],
      ExemptionDescription: [''],
    };
  };

  timerWarehouse = 200;
  myTimerWarehouse;
  isFirstLoadWarehouse: boolean = true;
  onValueWarhaouseAmount = () => {
    clearTimeout(this.myTimerWarehouse);
    this.myTimerWarehouse = setTimeout(() => {
      this.form.PriceDetail.PriceNet =
        this.form.PriceDetail.PriceNetBase * this.Factor;

      this.form.PriceDetail.PriceGross = Number(
        this.event.countAmountGross(
          this.form.PriceDetail.PriceNet,
          this.form.TaxRate
        )
      );
      this.changeAmount();

      if (this.timerWarehouse == 0) {
        this.isFirstLoadWarehouse = false;
      }
    }, this.timerWarehouse);
  };

  onValueChangedQuantity = () => {
    try {
      let Quantity = this.form.Units.find(
        (field) => field.UnitId == this.form.UnitId
      );
      this.form.PriceDetail.Factor = this.form.Quantity * Quantity.Factor;
    } catch {}

    this.changeAmount();

    if (this.isCorrect) {
      return;
    }

    if (!this.editMode) {
      this.findSocketAndRun('XGMPDILZ2');
    } else {
      this.findSocketAndRun('XGMPPILZ2');
    }
  };

  onRowDblClick = (e) => {
    this.form.UnitId = e.data.UnitId;
    this.form.UnitCode = e.data.Code;
    this.form.PriceDetail.Factor = e.key.Factor * this.form.Quantity;
    this.form.PriceDetail.PriceNet =
      (this.form.PriceDetail.PriceNetBase * this.form.PriceDetail.Factor) /
      this.form.Quantity;
    this.isGridBoxOpened = false;
    this.Factor = e.data.Factor;
    this.changeAmount();
  };

  onValueChangedQuantityBase = () => {
    try {
      let Quantity = this.form.Units.find(
        (field) => field.UnitId == this.form.UnitId
      );
      this.form.Quantity = this.form.PriceDetail.Factor / Quantity.Factor;
    } catch {}

    this.changeAmount();
  };

  changeAmount = () => {
    try {
      this.form.PriceDetail.PriceNet = Number(
        this.form.PriceDetail.PriceNet.toFixed(2)
      );
      this.form.PriceDetail.PriceGross = Number(
        this.form.PriceDetail.PriceGross.toFixed(2)
      );
      this.form.PriceDetail.PriceNetBase = Number(
        this.form.PriceDetail.PriceNetBase.toFixed(2)
      );
      this.form.PriceDetail.PriceGrossBase = Number(
        this.form.PriceDetail.PriceGrossBase.toFixed(2)
      );

      if (this.sumBy.replace(/ /g, '') == 'Netto') {
        this.form.PriceDetail.PriceGross = this.event.countAmountGross(
          this.form.PriceDetail.PriceNet,
          this.form.TaxRate
        );
      }
      if (this.sumBy.replace(/ /g, '') == 'Brutto') {
        this.form.PriceDetail.PriceGross =
          this.form.PriceDetail.PriceGrossBase * this.Factor;
        this.form.PriceDetail.PriceNet = this.event.countAmountNet(
          this.form.PriceDetail.PriceGross,
          this.form.TaxRate
        );
      }
    } catch {}

    this.form.PriceDetail.AmountNet =
      this.form.PriceDetail.Factor != 0
        ? this.form.PriceDetail.PriceNet *
          this.form.Quantity *
          (1 + this.form.PriceDetail.Discount)
        : 0;

    this.form.PriceDetail.AmountGross =
      this.form.PriceDetail.Factor != 0
        ? this.form.PriceDetail.PriceGross *
          this.form.Quantity *
          (1 + this.form.PriceDetail.Discount)
        : 0;

    this.form.PriceDetail.PriceWithDiscount =
      this.form.PriceDetail.PriceNet * (1 + this.form.PriceDetail.Discount);

    // if (
    //   this.form.PriceDetail.AmountGross == 0 ||
    //   this.form.PriceDetail.AmountNet == 0
    // ) {
    //   this.form.Quantity = 0;
    //   this.form.PriceDetail.PriceNet =
    //     this.form.PriceDetail.PriceNetBase * this.Factor;
    //   this.form.PriceDetail.PriceGross =
    //     this.form.PriceDetail.PriceGrossBase * this.Factor;
    //   this.form.PriceDetail.PriceWithDiscount =
    //     this.form.PriceDetail.PriceNet * (1 + this.form.PriceDetail.Discount);
    // }
    this.cd.detectChanges();
  };

  timerNetto = 200;
  myTimerNetto;
  onValueNetChange = () => {
    clearTimeout(this.myTimerNetto);
    this.myTimerNetto = setTimeout(() => {
      if (this.form.BaseUnitId == this.form.UnitId) {
        this.form.PriceDetail.PriceNetBase = this.form.PriceDetail.PriceNet;
      } else {
        this.form.PriceDetail.PriceNetBase =
          this.form.PriceDetail.PriceNet / this.form.PriceDetail.Factor;
      }

      this.form.PriceDetail.PriceGross = Number(
        this.event.countAmountGross(
          this.form.PriceDetail.PriceNet,
          this.form.TaxRate
        )
      );
      this.changeDescription();
      this.changeAmount();
    }, this.timerNetto);
  };

  formatCurrency() {
    this.form.PriceDetail.AmountGross =
      this.form.PriceDetail.AmountGross.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.AmountGross =
      this.form.PriceDetail.AmountGross.replace(',', '.');

    this.form.PriceDetail.AmountNet =
      this.form.PriceDetail.AmountNet.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.AmountNet = this.form.PriceDetail.AmountNet.replace(
      ',',
      '.'
    );

    this.form.PriceDetail.BuyPriceGross =
      this.form.PriceDetail.BuyPriceGross.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.BuyPriceGross =
      this.form.PriceDetail.BuyPriceGross.replace(',', '.');

    this.form.PriceDetail.PriceGross =
      this.form.PriceDetail.PriceGross.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.PriceGross = this.form.PriceDetail.PriceGross.replace(
      ',',
      '.'
    );

    this.form.PriceDetail.PriceGrossBase =
      this.form.PriceDetail.PriceGrossBase.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.PriceGrossBase =
      this.form.PriceDetail.PriceGrossBase.replace(',', '.');

    this.form.PriceDetail.PriceNet =
      this.form.PriceDetail.PriceNet.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.PriceNet = this.form.PriceDetail.PriceNet.replace(
      ',',
      '.'
    );

    this.form.PriceDetail.PriceNetBase =
      this.form.PriceDetail.PriceNetBase.toLocaleString('pl-PL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    this.form.PriceDetail.PriceNetBase =
      this.form.PriceDetail.PriceNetBase.replace(',', '.');
  }

  changeDescription() {
    this.PriceDescription = this.translate.instant(
      'form-commercial-operation.modifiedByHand'
    );
  }

  timerAmount = 200;
  myTimerAmount;

  onValueGrossAmountGross(e) {
    if (this.form.PriceDetail.PriceNetBase == 0) {
      return;
    }

    clearTimeout(this.myTimerAmount);

    this.myTimerAmount = setTimeout(() => {
      if (e.event && this.form.PriceDetail.PriceNetBase == 0) {
        this.event.showNotification(
          'error',
          this.translate.instant('servicesDevices.enterItemPrice')
        );
        return;
      }
      let quantity = Math.round(
        this.form.PriceDetail.AmountGross / this.form.PriceDetail.PriceGross
      );
      this.form.Quantity = quantity;
      this.cd.detectChanges();
    }, this.timerAmount);
  }

  timer = 200;
  myTimer;
  isFirstLoad: boolean = true;
  onValueGrossChange = () => {
    clearTimeout(this.myTimer);
    this.myTimer = setTimeout(() => {
      this.form.PriceDetail.PriceNet = this.event.countAmountNet(
        this.form.PriceDetail.PriceGross,
        this.form.TaxRate
      );

      if (this.form.BaseUnitId == this.form.UnitId) {
        this.form.PriceDetail.PriceNetBase = this.form.PriceDetail.PriceNet;
      } else {
        this.form.PriceDetail.PriceNetBase =
          this.form.PriceDetail.PriceNet / this.form.PriceDetail.Factor;
      }

      if (this.form.BaseUnitId == this.form.UnitId) {
        this.form.PriceDetail.PriceGrossBase = this.form.PriceDetail.PriceGross;
      } else {
        this.form.PriceDetail.PriceGrossBase =
          this.form.PriceDetail.PriceGrossBase / this.form.PriceDetail.Factor;
      }

      this.changeAmount();

      if (this.timer == 0) {
        this.isFirstLoad = false;
      }
    }, this.timer);
  };

  onValueChangedWasteManagement(mode) {
    clearTimeout(this.myTimer);
    this.myTimer = setTimeout(() => {
      if (mode == 'net') {
        this.positionForm.PriceGrossWasteManagement =
          this.event.countAmountGross(
            this.positionForm.PriceNetWasteManagement,
            this.form.TaxRate
          );
      }

      if (mode == 'gross') {
        this.positionForm.PriceNetWasteManagement = this.event.countAmountNet(
          this.positionForm.PriceGrossWasteManagement,
          this.form.TaxRate
        );
      }
      this.cd.detectChanges();
    }, this.timer);
  }

  IsDivisible: boolean = false;
  formatDivisible = '#0.0000';
  formatNotDivisible = '#0';
  IsDivisibleBase: boolean = false;
  onUnitChoosed = () => {
    this.IsDivisible = this.form.Units.find(
      (field) => field.UnitId == this.form.UnitId
    )?.IsDivisible;

    this.IsDivisibleBase = this.form.Units.find(
      (field) => field.Code == this.form.BaseUnitCode
    )?.IsDivisible;

    this.form.PriceDetail.PriceGross = Number(
      this.event.countAmountGross(
        this.form.PriceDetail.PriceNet * this.form.PriceDetail.Factor,
        this.form.TaxRate
      )
    );
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
      this.customerIdRetailSalesProdDoc = null;
    }
    this.createForm(); //reset form with initial value

    this.positionForm.Field1 = '';
    this.positionForm.Field2 = '';
    this.positionForm.Field3 = '';
    this.positionForm.Field4 = '';
    this.positionForm.Field5 = '';
    this.positionForm.Field6 = '';
    this.positionForm.Field7 = '';
    this.positionForm.Field8 = '';
    this.positionForm.Field9 = '';
    this.positionForm.Field10 = '';
    this.selectedtab = 0;
    this.cd.detectChanges();
  };

  onBuyTaxChanged = (e) => {
    this.form.TaxCode = e[0].TaxCode;
    this.form.TaxRate = e[0].TaxRate;
    this.changeAmount();
    this.afterOpen();
  };

  cancel = () => {};

  Description = '';
  SerialNumber = '';
  ExpirationDate = null;
  field = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  onInsertedUserExtensions = (e) => {
    this.field.Field1 = e['Field1'];
    this.field.Field2 = e['Field2'];
    this.field.Field3 = e['Field3'];
    this.field.Field4 = e['Field4'];
    this.field.Field5 = e['Field5'];
    this.field.Field6 = e['Field6'];
    this.field.Field7 = e['Field7'];
    this.field.Field8 = e['Field8'];
    this.field.Field9 = e['Field9'];
    this.field.Field10 = e['Field10'];
  };

  @ViewChild('gridClass') gridClass;
  chooseUnit = () => {
    if (this.focusedUnit != null) {
      this.Factor = this.focusedUnit.Factor;
      this.form.UnitId = this.focusedUnit.UnitId;
      this.form.UnitCode = this.focusedUnit.Code;
      this.form.PriceDetail.Factor =
        this.focusedUnit.Factor * this.form.Quantity;
      this.isGridBoxOpened = false;
      this.form.PriceDetail.PriceNet =
        (this.form.PriceDetail.PriceNetBase * this.form.PriceDetail.Factor) /
        this.form.Quantity;
      this.changeAmount();
    }
  };

  chooseUnitAndQuantity = () => {
    if (this.focusedUnit != null) {
      this.Factor = this.focusedUnit.Factor;
      this.form.UnitId = this.focusedUnit.UnitId;
      this.form.UnitCode = this.focusedUnit.Code;

      this.form.Quantity =
        this.form.PriceDetail.Factor / this.focusedUnit.Factor;
      this.form.PriceDetail.PriceNet =
        (this.form.PriceDetail.PriceNetBase * this.form.PriceDetail.Factor) /
        this.form.Quantity;
      this.isGridBoxOpened = false;
      this.changeAmount();
    }
  };

  onChoosedPrice = (e) => {
    if (e.length > 0) {
      this.form.PriceDetail.PriceNetBase = e[0].NetPrice;
      this.form.PriceDetail.PriceGrossBase = e[0].GrossPrice;
      this.form.PriceDetail.PriceNet =
        this.form.PriceDetail.PriceNetBase * this.Factor;
      this.form.PriceDetail.PriceGross =
        this.form.PriceDetail.PriceGross * this.Factor;

      this.form.PriceDetail.PriceName = e[0].PriceName;
      // this.Factor = e[0].Factor;
      this.form.PriceDetail.PriceId = e[0].PriceId;
      this.PriceDescription = e[0].Description;
      this.form.PriceDetail.Discount = e[0].DiscountMarkUp / 100;
      this.form.PriceDetail.PriceWithDiscount =
        this.form.PriceDetail.PriceNet * (1 + this.form.PriceDetail.Discount);
      this.changeAmount();
    }
  };

  intrastat = {
    isIntrastat: false,
    CountryCode: '',
    DeliveryCountryCode: '',
    TransactionCode: '',
    CNCode: '',
    TransportCode: '',
    OrginCountryCode: '',
    MassNet: 0,
    CustomerId: '',
    IsAgriculturalFund: false,
  };

  isOstrzegajMinMax: boolean = false;
  textOstrzegajMinMax: string = '';
  paramsOstrzegajMinMax: any = null;

  getProductData(id) {
    return new Promise((resolve) => {
      this.appService
        .getAuth(`products?ObjectId=${id}&IsMarginProcedure=${this.IsMargin}`)
        .subscribe((res) => {
          resolve(res.data[0]);
        });
    });
  }

  async checkOstrzegajMinMax() {
    let StockMax = 0;
    let StockMin = 0;
    let Stock = 0;
    if (this.editMode) {
      try {
        const productData: any = await this.getProductData(
          this.selected[0].ProductId
        );

        StockMax = productData?.StockMax;
        StockMin = productData?.StockMin;
        Stock = !this.documentType.IsIncome
          ? productData.Stock + this.form.Quantity
          : productData.Stock;
      } catch {
        this.event.showNotification(
          'error',
          `Nie znaleziono artykułu o id ${this.selected[0].ProductId} w bazie danych`
        );
      }
    } else {
      StockMax = this.selected.StockMax;
      StockMin = this.selected.StockMin;
      Stock = this.selected.Stock;
    }
    const OstrzegajMinMax = this.event.getConfigurationParameter(
      'OstrzegajMinMax',
      true
    );

    if (OstrzegajMinMax.Value === 'Min i Max') {
      if (
        Stock - this.form.Quantity < StockMin &&
        StockMin != 0 &&
        !this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMinimumBalanceToBeExceededBy',
          {
            number: Math.abs(Stock - this.form.Quantity - StockMin),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }

      if (
        Stock + this.form.Quantity + this.articleAmountOnDocument > StockMax &&
        StockMax != 0 &&
        this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMaximumBalanceToBeExceededBy',
          {
            number: Math.abs(
              Stock +
                this.form.Quantity +
                this.articleAmountOnDocument -
                StockMax
            ),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }
    }

    if (OstrzegajMinMax.Value === 'Min') {
      if (
        Stock - this.form.Quantity < StockMin &&
        StockMin != 0 &&
        !this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMinimumBalanceToBeExceededBy',
          {
            number: Math.abs(Stock - this.form.Quantity - StockMin),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }
    }

    if (OstrzegajMinMax.Value === 'Max') {
      if (
        Stock + this.form.Quantity + this.articleAmountOnDocument > StockMax &&
        StockMax != 0 &&
        this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMaximumBalanceToBeExceededBy',
          {
            number: Math.abs(
              Stock +
                this.form.Quantity +
                this.articleAmountOnDocument -
                StockMax
            ),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }
    }

    this.onSave();
  }

  noOstrzegajMinMax() {
    this.isOstrzegajMinMax = false;
    this.cd.detectChanges();
  }

  async onSave(co?) {
    this.isOstrzegajMinMax = false;
    let price = await this.checkPrice();

    const CenyDokMagOglCenZak = this.event.checkPermissions(
      'CenyDokMagOglCenZak'
    );
    const CenyDokMagOglCenSpr = this.event.checkPermissions(
      'CenyDokMagOglCenSpr'
    );

    const isChceckPriceZero = this.documentType.IsIncome
      ? CenyDokMagOglCenZak
      : CenyDokMagOglCenSpr;

    if (co != 'continue' && !price && isChceckPriceZero) {
      // this.isPriceZero = true;
      // this.cd.detectChanges();
      // return;
    }

    let dateOfDo = new DatePipe('en-US').transform(
      this.documentDate,
      'yyyy-MM-dd'
    );

    this.positionForm.ExtWarehouseId = this.warehouseId;
    this.positionForm.ProductId = this.selected.ProductId;
    this.positionForm.Amount = this.form.PriceDetail.Factor;
    this.positionForm.Unit = this.form.UnitCode;
    this.positionForm.PriceNet = Number(this.form.PriceDetail.PriceNet).toFixed(
      2
    );
    this.positionForm.PriceGross = Number(
      this.form.PriceDetail.PriceGross
    ).toFixed(2);
    this.positionForm.Discount = this.form.PriceDetail.Discount * 100;
    this.positionForm.TaxCode = this.form.TaxCode;
    this.positionForm.PriceDescription = this.PriceDescription;
    this.positionForm.Description = this.Description;
    this.positionForm.PriceIdentificator =
      this.form.PriceDetail.PriceIdentificator;
    this.positionForm.SerialNumber = this.SerialNumber;
    this.positionForm.ExpirationDate = this.ExpirationDate;
    this.positionForm.WarehouseDocumentPositionDeliveryId =
      this.selected.WarehouseDocumentPositionDeliveryId;
    this.positionForm.Field1 = this.field.Field1;
    this.positionForm.Field2 = this.field.Field2;
    this.positionForm.Field3 = this.field.Field3;
    this.positionForm.Field4 = this.field.Field4;
    this.positionForm.Field5 = this.field.Field5;
    this.positionForm.Field6 = this.field.Field6;
    this.positionForm.Field7 = this.field.Field7;
    this.positionForm.Field8 = this.field.Field8;
    this.positionForm.Field9 = this.field.Field9;
    this.positionForm.Field10 = this.field.Field10;
    this.positionForm.WarehouseDocumentId = this.documentId;
    this.positionForm.WarehouseDateOfDocument = dateOfDo;
    this.positionForm.Factor = this.Factor;
    this.positionForm.DestinationWarehouseId = this.destinationWarehouseId;
    this.positionForm.TypeOfCrossBorderSale = 'nie dotyczy';
    this.positionForm.DeliveryId = 0;

    this.positionForm.DateOfApprovalForUse = this.form.DateOfApprovalForUse;
    this.positionForm.Mileage = this.form.Mileage;
    this.positionForm.ExemptionCode = this.form.ExemptionCode;
    this.positionForm.ExemptionDescription = this.form.ExemptionDescription;

    //intrastat
    this.positionForm.CountryCode = this.intrastat.CountryCode;
    this.positionForm.DeliveryCode = this.intrastat.DeliveryCountryCode;
    this.positionForm.TransactionCode = this.intrastat.TransactionCode;
    this.positionForm.CnCode = this.intrastat.CNCode;
    this.positionForm.TransportCode = this.intrastat.TransportCode;
    this.positionForm.CountryOrginCode = this.intrastat.OrginCountryCode;
    this.positionForm.NetWeight = this.intrastat.MassNet;
    this.positionForm.IntrastaCustomerId = this.intrastat.CustomerId;
    this.positionForm.IsAgriculturalFund = this.intrastat.IsAgriculturalFund;

    this.formatCurrency();
    try {
      if (
        this.positionForm.Mileage != '' &&
        this.positionForm.Mileage.indexOf('km') == -1 &&
        this.positionForm.Mileage.indexOf('h') == -1
      ) {
        this.event.showNotification(
          'info',
          this.translate.instant('warehouseDocument.TheMileageField')
        );
        return;
      }
    } catch {}

    if (co != 'continue') {
      if (this.editMode) {
        this.positionForm.WarehouseDocumentPositionId =
          this.selected[0].WarehouseDocumentPositionId;
        this.positionForm.ProductId = this.selected[0].ProductId;
        this.positionForm.Factor = this.Factor;
      }
      await this.positionsValid();
    }

    if (this.isValidUserExtensions.checkIsValid()) {
      if (this.positionForm.Amount > 0) {
        if (!this.editMode) {
          this.findSocketAndRun('XGMPDZ1');
        } else {
          this.positionForm.WarehouseDocumentPositionId =
            this.selected[0].WarehouseDocumentPositionId;
          this.positionForm.ProductId = this.selected[0].ProductId;
          this.positionForm.Factor = this.Factor;

          this.findSocketAndRun('XGMPPZ1');
          // setTimeout(() => {
          //   this.updatePostition();
          // }, 200);
        }
      } else if (this.positionForm.Amount == 0 && this.isCorrect) {
        this.positionForm.WarehouseDocumentPositionId =
          this.selected[0].WarehouseDocumentPositionId;
        this.positionForm.ProductId = this.selected[0].ProductId;
        this.positionForm.Factor = this.Factor;
        setTimeout(() => {
          this.updatePostition();
        }, 200);
      } else {
        this.event.showNotification(
          'warning',
          this.translate.instant(
            'warehouseDocument.pleaseEnterTheAmountOfGoodsServices'
          )
        );
      }
    } else {
      this.visibleUserExtensions = true;
    }
  }

  onDefaultMarkupChoosed(e) {
    this.form.PriceDetail.Discount = e.discountMarkupPercent / 100;
    this.form.PriceDetail.DiscountOryginal = e.discountMarkupPercent;
    // this.discountValue = e.discountMarkupPercent
    // this.setDiscountMarkupConfig()
  }

  // setDiscountMarkupConfig(){
  //   this.radioBoxList[0].items[0].label = this.radioBoxTranslations.changeFor + ' ' + this.discountValue + this.radioBoxTranslations.allPositions
  //   this.radioBoxList[0].items[1].label = this.radioBoxTranslations.changeFor + ' ' + this.discountValue + this.radioBoxTranslations.forZero

  //   this.confirmChangeMarkupVisible = true
  // }

  emitAfterAddObject = null;

  onAfterAddPosition() {
    this.onInserted.emit(this.emitAfterAddObject);
    this.isVisible = false;
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  addPosition = () => {
    this.event.onShown();
    this.appService
      .postAuth(
        `warehouseDocuments/documents/${this.documentId}/positions/Add`,
        this.positionForm
      )
      .subscribe(
        (res) => {
          this.emitAfterAddObject = {
            position: this.positionForm,
            res: res,
          };

          if (res.WarningMessage !== null && res.WarningMessage != '') {
            this.event.showNotification(
              'warning',
              res.WarningMessage.replace(/\|/g, '\n')
            );
          }
          this.findSocketAndRun('XGMPDZ2');
          this.cd.detectChanges();
          this.event.onHidden();
        },
        (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  isReleased: boolean = false;
  yesExpenditureOverload() {
    this.isExpenditureOverload = false;
    this.isReleased = true;
    this.cd.detectChanges();
  }

  isExpenditureOverload: boolean = false;
  updatePostition = () => {
    this.event.onShown();
    this.appService
      .putAuth(
        `warehouseDocuments/documents/${this.documentId}/positions/${this.selected[0].WarehouseDocumentPositionId}`,
        this.positionForm
      )
      .subscribe(
        (res) => {
          this.emitAfterAddObject = {
            position: this.positionForm,
            res: res,
          };

          if (res.WarningMessage !== null && res.WarningMessage != '') {
            this.event.showNotification(
              'warning',
              res.WarningMessage.replace(/\|/g, '\n')
            );
          }
          if (this.isCorrect) {
            this.onAfterAddPosition();
          } else {
            this.findSocketAndRun('XGMPPZ2');
          }
          this.cd.detectChanges();
          this.event.onHidden();
        },
        (error) => {
          if (JSON.parse(error).detail.indexOf('posiada rozchód') != -1) {
            this.isExpenditureOverload = true;
            this.cd.detectChanges();
          }
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        }
      );
  };

  isNestRunning: boolean = false;
  nest: Socket;
  openNestFinished: boolean = false;
  nestOperation: NestOperation = null;

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.ParentObject) {
      e.updatedParameters.ParentObject =
        this.nestService.removeNonEditableVariables(
          e.updatedParameters.ParentObject,
          e.SocketCode,
          true
        );
      this.nestParentUpdated.emit(e.updatedParameters?.ParentObject);
    }
    if (e.updatedParameters?.Object) {
      e.updatedParameters.Object = this.nestService.removeNonEditableVariables(
        e.updatedParameters.Object,
        e.SocketCode
      );
      this.form.patchValue(e.updatedParameters?.Object, { emitEvent: false });
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGMPDO1':
        this.findSocketAndRun('XGMPAO1', 'add');
        break;
      case 'XGMPPO1':
        this.findSocketAndRun('XGMPAO1', 'edit');
        break;
      case 'XGMPAO1':
        this.openNestFinished = true;
        if (this.nestOperation === 'add') {
          this.findSocketAndRun('XGMPDO2');
        } else {
          this.findSocketAndRun('XGMPPO2');
        }
        break;
      case 'XGMPDO2':
        this.findSocketAndRun('XGMPAO2');
        break;
      case 'XGMPPO2':
        this.findSocketAndRun('XGMPAO2');
        break;
      case 'XGMPDA1':
        this.findSocketAndRun('XGMPAA1', 'add');
        break;
      case 'XGMPPA1':
        this.findSocketAndRun('XGMPAA1', 'edit');
        break;
      case 'XGMPAA1':
        if (this.nestOperation === 'add') {
          this.findSocketAndRun('XMGPDA2');
        } else {
          this.findSocketAndRun('XMGPPA2');
        }
        break;
      case 'XGMPDA2':
        this.findSocketAndRun('XGMPAA2');
        break;
      case 'XGMPPA2':
        this.findSocketAndRun('XGMPAA2');
        break;
      case 'XGMPAA2':
        this.afterCloseWindow();
        break;
      case 'XGMPDZ1':
        this.findSocketAndRun('XGMPAZ1', 'add');
        break;
      case 'XGMPPZ1':
        this.findSocketAndRun('XGMPAZ1', 'edit');
        break;
      case 'XGMPAZ1':
        if (this.nestOperation === 'add') {
          setTimeout(() => {
            this.addPosition();
          }, 200);
        } else {
          this.updatePostition();
        }
        break;
      case 'XGMPDZ2':
        this.findSocketAndRun('XGMPAZ2');
        break;
      case 'XGMPPZ2':
        this.findSocketAndRun('XGMPAZ2');
        break;
      case 'XGMPAZ2':
        this.onAfterAddPosition();
        break;
      case 'XGMPDILZ2':
        this.findSocketAndRun('XGMPAILZ2');
        break;
      case 'XGMPPILZ2':
        this.findSocketAndRun('XGMPAILZ2');
        break;
      case 'XMGPDA2':
        this.findSocketAndRun('XMGPAA2');
        break;
      case 'XMGPPA2':
        this.findSocketAndRun('XMGPAA2');
        break;
      case 'XMGPAA2':
        this.afterCloseWindow();
        break;
      default:
        break;
    }
  }

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    this.nestObjectParameters = Object.assign({}, this.form.value);
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  beforeCloseWindow() {
    if (this.isCorrect) {
      this.afterCloseWindow();
    } else {
      if (!this.editMode) {
        this.findSocketAndRun('XGMPDA1');
      } else {
        this.findSocketAndRun('XGMPPA1');
      }
    }
  }

  onChoosedDescriptionJPK = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.ExemptionDescription = text;
    }
  };

  afterCloseWindow() {
    this.isVisible = false;
    this.cancel();
    this.onClosing.emit(false);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    localStorage.setItem(
      'positionWarehouseDocumentComponent',
      JSON.stringify(this.localStorageData)
    );

    this.event.setFocus(this.firstFocused);
  }

  runGuide() {
    this.isGuideActive = true;
  }

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGM');
    }
  }

  confirmText: string = '';

  questionsList: any[] = [];
  positionsValid() {
    return new Promise((resolve) => {
      this.event.onShown();
      this.positionForm.ExtWarehouseId = this.warehouseId;
      this.appService
        .putAuth(
          `warehouseDocuments/documents/${this.documentId}/positions/valid`,
          this.positionForm
        )
        .subscribe(
          (res) => {
            if (res.Questions != null && res.Questions.length > 0) {
              this.questionsList = res.Questions;
              this.confirmText = res.Questions[0].Question;
              this.isConfirmVisible = true;
              this.cd.detectChanges();
            } else {
              resolve(true);
            }
            this.event.onHidden();
          },
          (error) => {
            this.event.httpErrorNotification(error);
            this.event.onHidden();
            this.cd.detectChanges();
          }
        );
    });
  }

  isConfirmVisible: boolean = false;

  yesContinue() {
    this.isConfirmVisible = false;
    this.questionsList.splice(0, 1);

    if (this.questionsList.length > 0) {
      setTimeout(() => {
        this.confirmText = this.questionsList[0].Question;
        this.isConfirmVisible = true;
        this.cd.detectChanges();
      }, 0);

      return;
    }

    this.onSave('continue');
    this.cd.detectChanges();
  }

  noContinue() {
    this.isConfirmVisible = false;
    this.cd.detectChanges();
  }

  isPriceZero: boolean = false;

  noApprove() {
    this.isPriceZero = false;
    this.event.setFocus(this.priceNetInput);
    this.cd.detectChanges();
  }

  yesApprove() {
    this.onSave('continue');
    this.isPriceZero = false;
    this.cd.detectChanges();
  }

  checkPrice() {
    return new Promise((resolve) => {
      if (this.form.PriceDetail.PriceGross == 0) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  }
}

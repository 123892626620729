import { TranslateModule } from '@ngx-translate/core';
import { DocumentOrderDescriptionComponent } from './documents-orders/new-documents-orders/document-order-description/document-order-description.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LibrariesModule } from '../libraries/libraries.module';
import { LabelModule } from '../label/label.module';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { CoreModule } from '../core/core.module';
import { DxTooltipModule } from 'devextreme-angular';
import { ResizableModule } from 'angular-resizable-element';
import { UserExtensionModule } from '../user-extension/user-extension.module';
import { PipesModule } from '../pipes/pipes.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { NewWarehouseDocumentComponent } from './warehouse-documents/new-warehouse-document/new-warehouse-document.component';
import { PositionWarehouseDocumentComponent } from './warehouse-documents/new-warehouse-document/position-warehouse-document/position-warehouse-document.component';
import { StockTransferComponent } from './warehouse-documents/new-warehouse-document/stock-transfer/stock-transfer.component';
import { DeliveryAddressWarehouseDocumentComponent } from './warehouse-documents/delivery-address-warehouse-document/delivery-address-warehouse-document.component';
import { PositionVeryficationComponent } from './position-veryfication/position-veryfication.component';
import { NewDocumentsOrdersComponent } from './documents-orders/new-documents-orders/new-documents-orders.component';
import { DocumentOrderPositionComponent } from './documents-orders/document-order-position/document-order-position.component';
import { OrderPaymentsComponent } from './documents-orders/order-payments/order-payments.component';
import { OrderRealizationComponent } from '../comercial-operation/new-comercial-operation/order-realization/order-realization.component';
import { StartRealizationComponent } from '../comercial-operation/new-comercial-operation/order-realization/start-realization/start-realization.component';
import { IndividualPricesListComponent } from '../comercial-operation/new-comercial-operation/individual-prices-list/individual-prices-list.component';
import { TransactionDeliveryTransportCodsComponent } from './intrastate-documents/new-position-intrastate-documents/transaction-delivery-transport-cods/transaction-delivery-transport-cods.component';
import { TransakcjeOssComponent } from '../comercial-operation/new-comercial-operation/transakcje-oss/transakcje-oss.component';
import { BookingManagmentComponent } from './documents-orders/booking-managment/booking-managment.component';
import { CreateZDComponent } from './documents-orders/create-zd/create-zd.component';
import { WarehouseDocumentsComponent } from './warehouse-documents/warehouse-documents.component';
import { CorrectionComponent } from './warehouse-documents/correction/correction.component';
import { PzPostponedComponent } from '../comercial-operation/comercial-operation/pz-postponed/pz-postponed.component';
// comercial operation
import { NewComercialOperationComponent } from '../comercial-operation/new-comercial-operation/new-comercial-operation.component';
import { PaymentScheduleComponent } from '../comercial-operation/new-comercial-operation/payment-schedule/payment-schedule.component';
import { RemarksToOrdersComponent } from '../comercial-operation/new-comercial-operation/remarks-to-orders/remarks-to-orders.component';
import { AdditionalCostComponent } from '../comercial-operation/new-comercial-operation/additional-cost/additional-cost.component';
import { AddDesciptionComponent } from '../comercial-operation/new-comercial-operation/additional-cost/add-desciption/add-desciption.component';
import { CashBackWindowComponent } from '../comercial-operation/new-comercial-operation/cash-back-window/cash-back-window.component';
import { ConnectedWarehouseDocumentComponent } from '../comercial-operation/new-comercial-operation/connected-warehouse-document/connected-warehouse-document.component';
import { CustomerAdvancePaymentComponent } from '../comercial-operation/new-comercial-operation/customer-advance-payment/customer-advance-payment.component';
import { IncreaseTotalAmountComponent } from '../comercial-operation/new-comercial-operation/increase-total-amount/increase-total-amount.component';
import { ModifyForFrComponent } from '../comercial-operation/new-comercial-operation/modify-for-fr/modify-for-fr.component';
import { PaymentInvoiceComponent } from '../comercial-operation/new-comercial-operation/payment-invoice/payment-invoice.component';
import { AddAdvancePaymentComponent } from '../comercial-operation/new-comercial-operation/payment-invoice/add-advance-payment/add-advance-payment.component';
import { PositionInvoiceDocumentComponent } from '../comercial-operation/new-comercial-operation/position-invoice-document/position-invoice-document.component';
import { PositionsForIntrastateComponent } from '../comercial-operation/new-comercial-operation/positions-for-intrastate/positions-for-intrastate.component';
import { RestWindowComponent } from '../comercial-operation/new-comercial-operation/rest-window/rest-window.component';
import { TaxMomentComponent } from '../comercial-operation/new-comercial-operation/tax-moment/tax-moment.component';
import { EditTaxMomentComponent } from '../comercial-operation/new-comercial-operation/tax-moment/edit-tax-moment/edit-tax-moment.component';
import { VatVerificationComponent } from '../comercial-operation/new-comercial-operation/vat-verification/vat-verification.component';
import { EditVatComponent } from '../comercial-operation/new-comercial-operation/vat-verification/edit-vat/edit-vat.component';
import { VerificationFormPaymentComponent } from '../comercial-operation/new-comercial-operation/verification-form-payment/verification-form-payment.component';
import { NewInventoryComponent } from './inventory/new-inventory/new-inventory.component';
import { GroupArticleComponent } from './inventory/group-article/group-article.component';
import { SumInventoryComponent } from './inventory/sum-inventory/sum-inventory.component';
import { GroupDocComponent } from './inventory/group-doc/group-doc.component';
import { NewPositionComponent } from './inventory/new-inventory/new-position/new-position.component';
import { QuantitySetsComponent } from '../comercial-operation/new-comercial-operation/quantity-sets/quantity-sets.component';
import { SumaWarehouseDocumentsComponent } from './warehouse-documents/suma-warehouse-documents/suma-warehouse-documents.component';
import { PositionVerificationCorrectionComponent } from './position-veryfication/position-verification-correction/position-verification-correction.component';
import { ExemplaryCodesComponent } from './warehouse-documents/new-warehouse-document/exemplary-codes/exemplary-codes.component';
import { AddExemplaryCodesComponent } from './warehouse-documents/new-warehouse-document/exemplary-codes/add-exemplary-codes/add-exemplary-codes.component';
import { ConfirmDialogComponent } from '../core/confirm-dialog/confirm-dialog.component';
import { AddTransDelTranspCodesComponent } from './intrastate-documents/new-position-intrastate-documents/transaction-delivery-transport-cods/add-trans-del-transp-codes/add-trans-del-transp-codes.component';
import { OfferStatusHistoryComponent } from '../crm/offers/new-offer/offer-status-history/offer-status-history.component';
import { BtnExportExcelComponent } from '../menu-wapro/btn-export-excel/btn-export-excel.component';
import { NgShortcutsComponent } from '../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { ExpansionListComponent } from '../expansion-slote/expansion-list/expansion-list.component';
import { CustomChipsButtonComponent } from '../core/custom-chips-button/custom-chips-button.component';
import { MobileListComponent } from '../core/mobile-list/mobile-list.component';
import { WaproDateBoxComponent } from '../core/wapro-date-box/wapro-date-box.component';
import { CustomDropdownBoxComponent } from '../core/custom-dropdown-box/custom-dropdown-box.component';
import { TableDataUserExtensionComponent } from '../core/table-user-extension/table-data-user-extension/table-data-user-extension.component';
import { TableUserExtensionComponent } from '../core/table-user-extension/table-user-extension.component';
import { NestComponent } from '../nest/nest.component';
import { PositionsConnectionSellComponent } from './warehouse-documents/positions-connection-sell/positions-connection-sell.component';
import { GenerateBarcodeComponent } from '../libraries/articles/generate-barcode/generate-barcode.component';
import { ExportJpkXmlComponent } from '../core/export-jpk-xml/export-jpk-xml.component';
// import { LabelPrintersModule } from '../libraries/external-devices/label-printers/label-printers.module';
import { ReleasedComponent } from './warehouse-documents/new-warehouse-document/released/released.component';
import { ChangeStatusComponent } from './warehouse-documents/change-status/change-status.component';
import { StatusLabelComponent } from '../core/status-label/status-label.component';
import { ReleasedQuantityComponent } from './warehouse-documents/new-warehouse-document/released-quantity/released-quantity.component';
import { LabelComponent } from '../core/label/label.component';
import { AddCrmDeliveryComponent } from '../crm/crm-deliveries/add-crm-delivery/add-crm-delivery.component';
import { OrdersDeliveryOperatorsComponent } from './documents-orders/orders-delivery-operators/orders-delivery-operators.component';
import { PreventRightClickDirective } from '../core/directives/prevent-right-click.directive';
import { PackingBoxComponent } from './position-veryfication/packing-box/packing-box.component';
import { PackingSimulationComponent } from './position-veryfication/packing-simulation/packing-simulation.component';
import { DxProgressBarModule } from 'devextreme-angular';
import { RecordCardComponent } from '../libraries/record-card/record-card.component';
import { ShortcutDirective } from '../menu-wapro/shortcut.directive';
import { CalculateQuantitativelyComponent } from './warehouse-documents/calculate-quantitatively/calculate-quantitatively.component';
import { NotesComponent } from '../core/notes/notes.component';
import { LabelPrinterOperationsComponent } from '../libraries/external-devices/label-printers/label-printer-operations/label-printer-operations.component';
import { EmptyStateGridSvgComponent } from 'src/assets/svg/empty-state-grid-svg/empty-state-grid-svg.component';
import { EmptyDocSvgComponent } from 'src/assets/svg/empty-doc-svg/empty-doc-svg.component';
import { PoweredByGeniusWhiteSvgComponent } from 'src/assets/svg/powered-by-genius-white-svg/powered-by-genius-white-svg.component';
import { WarehouseDocumentListForInvoiceDocumentComponent } from '../comercial-operation/new-comercial-operation/warehouse-document-list-for-invoice-document/warehouse-document-list-for-invoice-document.component';

@NgModule({
  declarations: [
    CalculateQuantitativelyComponent,
    AdditionalCostComponent,
    ExemplaryCodesComponent,
    AddExemplaryCodesComponent,
    GroupDocComponent,
    NewInventoryComponent,
    CashBackWindowComponent,
    GroupArticleComponent,
    SumInventoryComponent,
    ConnectedWarehouseDocumentComponent,
    CustomerAdvancePaymentComponent,
    IncreaseTotalAmountComponent,
    ModifyForFrComponent,
    PaymentInvoiceComponent,
    AddAdvancePaymentComponent,
    PositionInvoiceDocumentComponent,
    PositionsForIntrastateComponent,
    RestWindowComponent,
    NewPositionComponent,
    PzPostponedComponent,
    TaxMomentComponent,
    EditTaxMomentComponent,
    VatVerificationComponent,
    EditVatComponent,
    VerificationFormPaymentComponent,
    IndividualPricesListComponent,
    CorrectionComponent,
    BookingManagmentComponent,
    CreateZDComponent,
    TransakcjeOssComponent,
    NewWarehouseDocumentComponent,
    PositionWarehouseDocumentComponent,
    StockTransferComponent,
    PositionVeryficationComponent,
    DeliveryAddressWarehouseDocumentComponent,
    NewDocumentsOrdersComponent,
    DocumentOrderPositionComponent,
    WarehouseDocumentsComponent,
    SumaWarehouseDocumentsComponent,
    DocumentOrderDescriptionComponent,
    OrderPaymentsComponent,
    OrderRealizationComponent,
    NewComercialOperationComponent,
    QuantitySetsComponent,
    StartRealizationComponent,
    TransactionDeliveryTransportCodsComponent,
    PositionVerificationCorrectionComponent,
  ],
  imports: [
    WarehouseDocumentListForInvoiceDocumentComponent,
    PaymentScheduleComponent,
    AddCrmDeliveryComponent,
    AddDesciptionComponent,
    AddTransDelTranspCodesComponent,
    BtnExportExcelComponent,
    ChangeStatusComponent,
    CommonModule,
    ConfirmDialogComponent,
    CoreModule,
    CustomChipsButtonComponent,
    CustomDropdownBoxComponent,
    DxAccordionModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownBoxModule,
    DxListModule,
    DxNumberBoxModule,
    DxPopupModule,
    DxProgressBarModule,
    DxRadioGroupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTabPanelModule,
    DxTabsModule,
    DxTextAreaModule,
    DxTextBoxModule,
    DxTooltipModule,
    DxTreeViewModule,
    ExpansionListComponent,
    ExportJpkXmlComponent,
    FormsModule,
    GenerateBarcodeComponent,
    KeyboardShortcutsModule,
    LabelComponent,
    LabelModule,
    // LabelPrintersModule,
    LibrariesModule,
    MobileListComponent,
    NestComponent,
    NgShortcutsComponent,
    NotesComponent,
    OfferStatusHistoryComponent,
    OrdersDeliveryOperatorsComponent,
    PackingBoxComponent,
    PackingSimulationComponent,
    PipesModule,
    PositionsConnectionSellComponent,
    PreventRightClickDirective,
    ReactiveFormsModule,
    RecordCardComponent,
    ReleasedComponent,
    ReleasedQuantityComponent,
    RemarksToOrdersComponent,
    ResizableModule,
    ShortcutDirective,
    StatusLabelComponent,
    TableDataUserExtensionComponent,
    TableUserExtensionComponent,
    TranslateModule,
    UserExtensionModule,
    WaproDateBoxComponent,
    LabelPrinterOperationsComponent,
    EmptyStateGridSvgComponent,
    EmptyDocSvgComponent,
    PoweredByGeniusWhiteSvgComponent
  ],
  exports: [
    PaymentScheduleComponent,
    IndividualPricesListComponent,
    TransakcjeOssComponent,
    AddDesciptionComponent,
    GroupDocComponent,
    PzPostponedComponent,
    ExemplaryCodesComponent,
    NewWarehouseDocumentComponent,
    PositionWarehouseDocumentComponent,
    DeliveryAddressWarehouseDocumentComponent,
    StockTransferComponent,
    CorrectionComponent,
    AddExemplaryCodesComponent,
    NewInventoryComponent,
    CreateZDComponent,
    SumaWarehouseDocumentsComponent,
    NewPositionComponent,
    NewDocumentsOrdersComponent,
    DocumentOrderPositionComponent,
    PositionVeryficationComponent,
    OrderRealizationComponent,
    StartRealizationComponent,
    QuantitySetsComponent,
    TransactionDeliveryTransportCodsComponent,
    WarehouseDocumentsComponent,
    NewComercialOperationComponent,
    AdditionalCostComponent,
    CashBackWindowComponent,
    ConnectedWarehouseDocumentComponent,
    CustomerAdvancePaymentComponent,
    IncreaseTotalAmountComponent,
    ModifyForFrComponent,
    PaymentInvoiceComponent,
    AddAdvancePaymentComponent,
    PositionInvoiceDocumentComponent,
    PositionsForIntrastateComponent,
    RestWindowComponent,
    TaxMomentComponent,
    EditTaxMomentComponent,
    GroupArticleComponent,
    SumInventoryComponent,
    VatVerificationComponent,
    EditVatComponent,
    VerificationFormPaymentComponent,
    PositionsConnectionSellComponent,
    RemarksToOrdersComponent,
    ExportJpkXmlComponent,
    // LabelPrintersModule,
  ],
})
export class WarehouseNewDocumentModule {}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); getPosition(); cantRealized = false"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="cancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      showScrollbar="never"
      #scrollView
    >
      <div class="header-document">
        <header>
          <div>
            <p class="p-header-info">
              {{ "form-financial-document.number" | translate }}:
              <strong *ngIf="documentDate[0]">{{
                documentDate[0]?.OrderDocumentNumber
              }}</strong>
              | {{ "constractors.recipient" | translate }}:
              <strong *ngIf="documentDate[0]">{{
                documentDate[0]?.CustomerName
              }}</strong>
              | {{ "form-commercial-operation.dateOfIssue" | translate }}:
              <strong *ngIf="documentDate[0]">{{
                documentDate[0]?.Date | date : event.dateFormat
              }}</strong>
              | {{ "form-commercial-operation.executionDate" | translate }}:
              <strong *ngIf="documentDate[0]">{{
                documentDate[0]?.RealizationDate | date : event.dateFormat
              }}</strong>
            </p>
          </div>
        </header>
      </div>

      <div class="center-panel-scroll-verticall" style="margin-bottom: 12px">
        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          [text]="
            'form-commercial-operation.changeTheQuantityToBeDelivered'
              | translate
          "
          [disabled]="readOnly"
          [id]="'changeTheQuantityToBeDelivered' + unicalGuid"
          (onClick)="changeAmountToRealization()"
          style="margin-right: 6px"
        >
        </dx-button>

        <!-- <dx-button class="btn-dx-top-menu" [text]="'form-commercial-operation.selectCopyCodes' | translate"
          [disabled]="readOnly" [id]="'selectCopyCodes'+unicalGuid">
        </dx-button> -->

        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          [text]="
            'form-commercial-operation.changeTheQuantityToBeRealizedForTheMarkedOnes'
              | translate
          "
          [disabled]="readOnly"
          [id]="'changeTheQuantityToBeRealizedForTheMarkedOnes' + unicalGuid"
          (onClick)="isAmountToRealized = true"
          style="margin-right: 6px"
        >
        </dx-button>

        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          [text]="
            'form-commercial-operation.resetTheQuantityToBeRealizedForTheSelectedOnes'
              | translate
          "
          [disabled]="readOnly"
          [id]="'resetTheQuantityToBeRealizedForTheSelectedOnes' + unicalGuid"
          (onClick)="resetAmountToRealized()"
          style="margin-right: 6px"
        >
        </dx-button>

        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          [text]="'form-commercial-operation.redeemSelected' | translate"
          [disabled]="readOnly || cantRealized"
          [id]="'redeemSelected' + unicalGuid"
          (onClick)="continueRealization()"
          data-cy="redeemSelected-btn"
        >
        </dx-button>
      </div>

      <app-custom-dropdown-box
        [items]="wgList"
        [(selectedItem)]="valueCriteriaPosition"
        [(filterValue)]="filterValuePosition"
        (onValueChanged)="onFilterPosition($event)"
        style="margin-right: 5px; margin-top: 5px"
        class="warehouse-documents-search"
      >
      </app-custom-dropdown-box>

      <dx-data-grid
        [showBorders]="true"
        [wordWrapEnabled]="false"
        [hoverStateEnabled]="true"
        [dataSource]="dataSource"
        #itemGrid
        shortcut
        [focusedRowEnabled]="true"
        [(selectedRowKeys)]="selectedPositionDocument"
        [allowColumnResizing]="true"
        (onFocusedRowChanged)="onFocusedRowChanged($event)"
        (onSelectionChanged)="onSelectionChangedGridPosition($event)"
        (onContentReady)="onContentReady($event)"
        (onKeyDown)="onKeyDown($event)"
        [(focusedRowIndex)]="focusedRowIndex"
        (onOptionChanged)="event.onOptionChanged($event)"
        [columnResizingMode]="'widget'"
        style="width: 100%; min-height: 50px; margin-bottom: 10px"
        [height]="heightWindow - 300"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling
          mode="infinite"
          columnRenderingMode="virtual"
          preloadEnabled="true"
        >
        </dxo-scrolling>
        <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>

        <dxo-selection
          mode="multiple"
          showCheckBoxesMode="always"
          selectAllMode="page"
          *ngIf="!readOnly"
        ></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

        <dxi-column
          [caption]="'form-commercial-operation.grid.Lp' | translate"
          width="32"
          cellTemplate="lpTemplate"
          [allowSorting]="false"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'lpTemplate'; let index = i">
          {{ data.rowIndex + 1 }}
        </div>

        <dxi-column
          [caption]="'form-commercial-operation.grid.articleName' | translate"
          width="150"
          dataField="Name"
          headerCellTemplate="articleNameTemplate"
          [cssClass]="orderby == 'ProductFullName' ? 'sort-column-bg' : null"
          [allowSorting]="false"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'articleNameTemplate'">
          <div class="grid-header-wrapper">
            {{ "form-commercial-operation.grid.articleName" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'ProductFullName'"
              (click)="switchOrder(); getPosition()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>

        <dxi-column
          [caption]="'customerGroups.catalogIndex' | translate"
          width="150"
          dataField="IndexCatalogue"
          headerCellTemplate="IndexCatalogue"
          [cssClass]="orderby == 'IndexCatalogue' ? 'sort-column-bg' : null"
          [allowSorting]="false"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'IndexCatalogue'">
          <div class="grid-header-wrapper">
            {{ "form-commercial-operation.grid.articleName" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'IndexCatalogue'"
              (click)="switchOrder(); getPosition()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>

        <dxi-column
          [caption]="'customerGroups.tradingIndex' | translate"
          width="150"
          dataField="IndexTrading"
          headerCellTemplate="IndexTrading"
          [cssClass]="orderby == 'IndexTrading' ? 'sort-column-bg' : null"
          [allowSorting]="false"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'IndexTrading'">
          <div class="grid-header-wrapper">
            {{ "form-commercial-operation.grid.articleName" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'IndexTrading'"
              (click)="switchOrder(); getPosition()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>

        <dxi-column
          [caption]="'articles.localization' | translate"
          width="150"
          dataField="Location"
          headerCellTemplate="Location"
          [cssClass]="orderby == 'Location' ? 'sort-column-bg' : null"
          [allowSorting]="false"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'Location'">
          <div class="grid-header-wrapper">
            {{ "form-commercial-operation.grid.articleName" | translate }}
            <i
              class="header-sort-icon"
              *ngIf="orderby == 'Location'"
              (click)="switchOrder(); getPosition()"
              [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
            ></i>
          </div>
        </div>

        <dxi-column
          [caption]="'plannedOrders.ordered' | translate"
          width="100"
          dataField="Amount"
          [allowSorting]="false"
        >
        </dxi-column>

        <dxi-column
          [caption]="'plannedOrders.completed' | translate"
          width="100"
          dataField="AmountRealized"
          [allowSorting]="false"
        >
        </dxi-column>

        @if(event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
        event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
        event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
        event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){
           <dxi-column
          [caption]="'articles.reserved' | translate"
          width="100"
          dataField="AmountReserved"
          [allowSorting]="false"
        >
        </dxi-column>
        }
       

        <dxi-column
          [caption]="'form-commercial-operation.amountToRealized' | translate"
          width="100"
          dataField="AmountToRealized"
          [allowSorting]="false"
          cellTemplate="AmountToRealized"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'AmountToRealized'">
          <p style="margin-bottom: 0px" *ngIf="!data.data.isInputAmount">
            {{ data.data.AmountToRealized }}
          </p>
          <dx-number-box
            [visible]="data.data.isInputAmount"
            [(value)]="data.data.AmountToRealized"
            (onValueChanged)="check(data.data)"
            (onKeyDown)="checkAmountToRealized($event, data.data)"
            (onFocusOut)="check(data.data)"
          ></dx-number-box>
          <!-- [ngModelOptions]="{ standalone: true }" -->
        </div>

        <dxi-column
          [caption]="'clipboard.measureUnit' | translate"
          width="80"
          dataField="Unit"
          [allowSorting]="false"
        >
        </dxi-column>

        <dxi-column
          caption="{{ 'form-commercial-operation.grid.netValue' | translate }}"
          [allowSorting]="false"
          width="100"
          dataField="TotalNetAmount"
          cellTemplate="TotalNetAmount"
          [allowSorting]="false"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'TotalNetAmount'">
          {{ data.data.TotalNetAmount | amountFormatter : data }}
        </div>

        <dxi-column
          caption="{{ 'form-commercial-operation.grid.vatAmount' | translate }}"
          [allowSorting]="false"
          width="150"
          dataField="PriceIdentificator"
          cellTemplate="vatAmount"
          alignment="right"
          [allowSorting]="false"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'vatAmount'">
          {{
            data.data.TotalGrossAmount - data.data.TotalNetAmount
              | amountFormatter : data
          }}
        </div>

        <dxi-column
          [caption]="'offers.defaultBarcode' | translate"
          width="180"
          dataField="Barcode"
          [allowSorting]="false"
        >
        </dxi-column>

        <dxi-column
          [caption]="'workers.documentNumber' | translate"
          width="150"
          dataField="OrderNumber"
          [allowSorting]="false"
        >
        </dxi-column>
      </dx-data-grid>

      <div class="count-container">
        <div class="row">
          <div class="col-5 text-left" style="padding-top: 9px">
            <p class="p_paymentMethod">
              {{ "clipboard.selectedPositionsTotal" | translate }}
            </p>
          </div>
          <div class="col-7 text-right">
            <div class="contener-pay">
              <p class="p-toPay">
                {{
                  sumPosition.TotalGrossAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.gross" | translate }}
              </p>
            </div>

            <div class="contener-pay" style="padding-right: 15px">
              <p class="p-toPay-net">
                {{
                  sumPosition.TotalNetAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.net" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<dx-popup
  [width]="340"
  [height]="140"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-commercial-operation.executionofTheContract' | translate"
  [visible]="isAmountToRealized"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'confirm' + unicalGuid"
        (onClick)="setAmountToRealizedForSelected()"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isAmountToRealized = false"
        [id]="'cancel1' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel1' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      showScrollbar="never"
      #scrollView
    >
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 340px">
          <label style="width: auto">{{
            "form-commercial-operation.amountToRealizedSelected" | translate
          }}</label>
          <dx-number-box
            [showSpinButtons]="true"
            [min]="0"
            [width]="190"
            [useLargeSpinButtons]="true"
            [readOnly]="readOnly"
            [(ngModel)]="amountToRealized"
            valueChangeEvent="input"
          >
          </dx-number-box>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType !== 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType !== 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  *ngIf="openNestFinished"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      *ngIf="event.deviceType != 'mobile'; else mobileHeaderTemplate"
    >
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closePopup()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap" style="width: 101%">
        <div class="form-group-inline-right" style="width: 550px">
          <form [formGroup]="form">
            <dx-tab-panel
              [items]="[
                'servicesDevices.partsUsedForRepair' | translate,
                'form-financial-document.description' | translate
              ]"
              [loop]="false"
              [animationEnabled]="false"
              [swipeEnabled]="false"
            >
              <div *dxTemplate="let name of 'item'">
                <ng-container [ngSwitch]="name">
                  <div
                    *ngSwitchCase="
                      'servicesDevices.partsUsedForRepair' | translate
                    "
                  >
                    <label style="width: 90px"></label>
                    <dx-check-box
                      text="{{ 'servicesDevices.partInvoiced' | translate }}"
                      #focusFirst
                      formControlName="IsInvoiced"
                      style="width: calc(100% - 105px)"
                      [readOnly]="readOnly"
                      (onValueChanged)="onValueChangedInvoiced($event)"
                    >
                    </dx-check-box>

                    <label style="width: 90px"></label>
                    <dx-check-box
                      text="{{ 'servicesDevices.baseService' | translate }}"
                      formControlName="IsBaseService"
                      style="width: calc(100% - 105px)"
                      [readOnly]="readOnly"
                      (onValueChanged)="onValueChangedBaseService($event)"
                    >
                    </dx-check-box>

                    <label style="width: 90px"></label>
                    <dx-check-box
                      text="{{
                        'servicesDevices.addValueToBaseService' | translate
                      }}"
                      formControlName="AddToServiceOrder"
                      style="width: calc(100% - 105px)"
                      [readOnly]="readOnly"
                      (onValueChanged)="onValueChangedBaseorder($event)"
                    ></dx-check-box>

                    <label style="width: 90px"
                      >{{ "articles.title" | translate }}
                      <strong>*</strong></label
                    >
                    <app-articles
                      [dropDownBoxMode]="true"
                      [selectedId]="form.get('ProductId').value"
                      [readOnly]="readOnly"
                      (onChoosed)="onChoosedArticle($event)"
                      widthDropBox="372"
                      [className]="
                        !form.controls.ProductId.valid && submitted
                          ? true
                          : false
                      "
                      style="position: absolute"
                    >
                    </app-articles
                    ><br />
                    <div style="width: 100%; margin-bottom: 15px"></div>

                    <label style="width: 90px"
                      >{{ "articles.unit" | translate }}
                      <strong>*</strong></label
                    >
                    <div
                      style="
                        display: inline-block;
                        height: 27px;
                        position: relative;
                        top: 1px;
                      "
                    >
                      <dx-text-box
                        [elementAttr]="{ class: 'txtbot-as-dropdown-text' }"
                        formControlName="Unit"
                        width="84"
                        [readOnly]="true"
                        [ngClass]="{
                          'input-required':
                            !form.controls.Unit.valid && submitted
                        }"
                        (onFocusIn)="event.onFocusCountry('Unit', form)"
                        (onFocusOut)="event.onFocusOut('Unit', form)"
                        style="
                          display: inline-block;
                          border: 1px solid var(--neutral-50) !important;
                          background: var(--neutral-100) !important;
                        "
                      >
                      </dx-text-box>

                      <dx-button
                        [elementAttr]="{ class: 'btn-as-dropdown-icon' }"
                        icon="icon absui-icon--folder-expanddd"
                        type="normal"
                        style="
                          position: relative;
                          top: -0.6px;
                          height: 28px !important;
                        "
                        (onClick)="sizeUnitsVisible = !sizeUnitsVisible"
                        [disabled]="readOnly"
                      >
                      </dx-button>
                    </div>

                    <small class="text-danger" *ngIf="false">
                      <!-- #TYPEFIX
                    *ngIf="
                        submitted &&
                        form.get('Unit').focus &&
                        !form.get('Unit').valid
                      " -->
                      {{ "form.required" | translate }}
                    </small>

                    <label style="width: 90px">{{
                      "servicesDevices.VATcode" | translate
                    }}</label>
                    <app-tax-rates
                      [dropDownBoxMode]="true"
                      [readOnly]="readOnly"
                      [taxCode]="form.get('TaxCode').value"
                      (onChoosed)="onBuyTaxChanged($event)"
                      [width]="150"
                    >
                    </app-tax-rates
                    ><br />

                    <label style="width: 90px"
                      >{{ "form-commercial-operation.grid.count" | translate }}
                      <strong>*</strong></label
                    >
                    <dx-number-box
                      [readOnly]="readOnly"
                      valueChangeEvent="input"
                      formControlName="Amount"
                      width="119"
                      [min]="0"
                      [ngClass]="{
                        'input-required':
                          !form.controls.Amount.valid && submitted
                      }"
                      (onFocusIn)="event.onFocusCountry('Amount', form)"
                      (onFocusOut)="event.onFocusOut('Amount', form)"
                      valueChangeEvent="input"
                      [format]="event.formatPLN"
                    >
                    </dx-number-box>
                    <br />
                    <small class="text-danger" *ngIf="false">
                      <!-- #TYPEFIX
                    *ngIf="
                        submitted &&
                        form.get('Amount').focus &&
                        !form.get('Amount').valid
                      " -->
                      {{ "form.required" | translate }}
                    </small>

                    <label style="width: 90px">{{
                      "form-commercial-operation.grid.netAmount" | translate
                    }}</label>
                    <dx-number-box
                      [readOnly]="readOnly"
                      valueChangeEvent="input"
                      formControlName="PriceNet"
                      width="119"
                      [min]="0"
                      (onBlur)="calculatePrices()"
                      (onInput)="onValueNetChange()"
                      [format]="event.formatPLN"
                    ></dx-number-box>

                    <label style="width: 90px">{{
                      "form-commercial-operation.grid.grossAmount" | translate
                    }}</label>
                    <dx-number-box
                      [readOnly]="readOnly"
                      valueChangeEvent="input"
                      format="#0.00"
                      formControlName="PriceGross"
                      width="119"
                      [min]="0"
                      (onInput)="onValueGrossChange()"
                    ></dx-number-box>
                    <div
                      class="header-bar d-flex align-items-center"
                      *ngIf="
                        currencyCode != '' && currencyCode != 'PLN' && foundRate
                      "
                    >
                      <div class="confirme-info-message"></div>
                      <div class="header-bar_text" style="padding: 4px 0">
                        {{ "baseCurrencies.byCourse" | translate }}
                        {{ foundRate?.Rates[0]?.Middle }}
                        {{ "baseCurrencies.ofTheDay" | translate }}
                        {{ foundRate?.Date | date : event.dateFormat }}
                      </div>
                    </div>
                    <br />

                    <label style="width: 90px"
                      >{{ "form-payment.discountMarkUp" | translate }} %</label
                    >
                    <dx-number-box
                      [readOnly]="readOnly"
                      valueChangeEvent="input"
                      format="#0.00%"
                      [showSpinButtons]="true"
                      [useLargeSpinButtons]="true"
                      [step]="0.01"
                      formControlName="Discount"
                      width="119"
                    ></dx-number-box
                    ><br />

                    <app-size-units
                      [isPopupMode]="true"
                      [isVisible]="sizeUnitsVisible"
                      [articleId]="form.value.ProductId"
                      (onClosed)="sizeUnitsVisible = false"
                      (onChoosed)="onSizeUnitsChoosed($event)"
                    ></app-size-units>
                  </div>

                  <div
                    *ngSwitchCase="
                      'form-financial-document.description' | translate
                    "
                  >
                    <dx-text-area
                      style="
                        border-top-right-radius: 0px !important;
                        max-width: 600px !important;
                        width: calc(100%);
                      "
                      class="dx-texteditor-input-height-200"
                      [readOnly]="readOnly"
                      formControlName="Description"
                      valueChangeEvent="input"
                    >
                    </dx-text-area>

                    <br />
                    <dx-button
                      class="btn-dx-secondary"
                      icon="icon absui-icon--folder-expand"
                      text="{{ 'servicesDevices.chooseFromList' | translate }}"
                      style="width: auto"
                      (onClick)="addDescription = true"
                      [disabled]="readOnly"
                    >
                    </dx-button>

                    <app-description-dictionary
                      [popUpMode]="true"
                      type="K"
                      [isVisible]="addDescription"
                      (onClosed)="addDescription = false; cd.detectChanges()"
                      title="{{
                        'servicesDevices.dictionaryDescriptionsItems'
                          | translate
                      }}"
                      (onChoosed)="onChoosedDeviceCondition($event)"
                    >
                    </app-description-dictionary>
                  </div>
                </ng-container>
              </div>
            </dx-tab-panel>
          </form>
        </div>
      </div>
    </dx-scroll-view>

    <div *ngIf="event.deviceType == 'mobile'">
      <ng-container *ngTemplateOutlet="mobileBottomTemplate"></ng-container>
    </div>
  </div>
</dx-popup>

<!-- pola dodatkowe -->
<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Serwis_pozycja_kosztorysu"
  [readOnly]="readOnly"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="form.value"
  #isValidUserExtensions
>
</app-form-user-extensions>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="
    !isVisible ||
    sizeUnitsVisible ||
    visibleUserExtensions ||
    isExpansionListVisible
  "
>
</wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGSK'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<!-- mobile template -->

<ng-template #mobileHeaderTemplate>
  <div class="header-search-mobile">
    <i
      class="icon absui-icon--close"
      style="position: absolute; top: 14px; right: 16px; z-index: 1"
      (click)="isVisible = false"
    ></i>
  </div>
</ng-template>

<ng-template #mobileBottomTemplate>
  <div class="bottom-mobile-form">
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      style="float: left"
      (onClick)="isMobileSettings = true"
    ></dx-button>

    <dx-button
      text="{{ 'buttons.save' | translate }}"
      type="success"
      style="margin-right: 12px"
      (onClick)="onSave()"
      [id]="'add' + unicalGuid"
      *ngIf="!readOnly"
    ></dx-button>
    <dx-button
      text="{{ 'buttons.cancel' | translate }}"
      type="danger"
      (onClick)="isVisible = false"
      [id]="'cancel' + unicalGuid"
    ></dx-button>
  </div>

  <app-mobile-list
    [dataSource]="mobileMenuFooter"
    [visible]="isMobileSettings"
    (onClosed)="isMobileSettings = false"
    (onItemClick)="clickOptions($event)"
  ></app-mobile-list>
</ng-template>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGSK'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-financial-document.rateDateDocumentDifferentVar"
  [paramsMsg]="paramsMsgDiffDate"
  [isVisible]="true"
  *ngIf="idDownloadCurses"
  (onRemoving)="downloadCurse()"
  (onClosing)="idDownloadCurses = false; cd.detectChanges()"
>
</app-confirm-dialog>

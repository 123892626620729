import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

import { environment } from '../../../../../environments/environment';
import { PipesModule } from '../../../../pipes/pipes.module';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ReleasedSugestionComponent } from '../released-sugestion/released-sugestion.component';

import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { ConfirmDialogComponent } from '../../../../core/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-released',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxScrollViewModule,
        TranslateModule,
        DxDataGridModule,
        PipesModule,
        DxTooltipModule,
        NgShortcutsComponent,
        ReleasedSugestionComponent,
        ConfirmDialogComponent,
    ],
    templateUrl: './released.component.html',
    styleUrl: './released.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['isVisible', ' readOnly', 'warehouseDocumentId', 'position']
})
export class ReleasedComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onClosing = new EventEmitter();

  widthWindow = '90%';
  heightWindow: number = 400;
  title: string = 'Rozchód dostawy w jednostce magazynowania';
  position;
  isVisible: boolean = false;
  readOnly;
  unicalGuid;
  warehouseDocumentId;
  dataSource;
  heightGrid = 320;

  isReleased: boolean = false;
  shortcuts: ShortcutInput[] = [];
  constructor(
    public event: EventService,
    public appServices: AppServices,
    public cd: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('form-commercial-operation.outgoingDeliveryInTheStorageUnit')
      .subscribe((text) => {
        this.title = text;
      });
  }

  ngOnInit() {
    this.readOnly = this.event.readOnly;
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.connectWithAnotherDelivery();
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit();
    }
  }

  closeWindow() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  getData() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}warehouseDocuments/documents/${this.warehouseDocumentId}/released`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  }

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex = 0;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;
  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e) {
    if (
      e.event.keyCode == 113 ||
      e.event.keyCode == 32 ||
      e.event.keyCode == 27
    ) {
      e.event.preventDefault();
    }
  }

  getLoadParams() {
    let obj: any = {};

    obj.ObjectId = this.position[0].WarehouseDocumentPositionIdPrevious;
    return obj;
  }

  onRowDblClick() {}

  focusedSelected: any = [];
  onFocusedRowChanged = (e) => {
    if (e && e.row) {
      this.focusedSelected = [e.row.data];
    }
  };

  IsDocumentPosted: boolean = false;
  connectWithAnotherDelivery() {
    if (this.focusedSelected.length === 0) return;

    if (this.focusedSelected[0].IsDocumentPosted) {
      this.IsDocumentPosted = true;
      this.cd.detectChanges();
      return;
    }

    this.isReleased = true;
    this.cd.detectChanges();
  }

  yesIsPosted() {
    this.IsDocumentPosted = false;
    this.isReleased = true;
    this.cd.detectChanges();
  }
}

import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { catchError, of, switchMap } from 'rxjs';
import { NestService } from 'src/app/nest-service.service';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { DefaultObject, NestOperation } from './../../../nest/nest.model';
import { Socket } from './../../../socket.model';

@Component({
    selector: 'app-job-cost',
    templateUrl: './job-cost.component.html',
    styleUrls: ['./job-cost.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'orderId',
        'componentNests',
        'currencyCode',
        'currencyFactor',
        'serviceOrderDate',
    ],
    standalone: false
})
export class JobCostComponent implements OnInit {
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  componentNests: Socket;
  nest: Socket;
  isNestRunning: boolean = false;
  openNestFinished: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('focusFirst') focusFirst;

  isVisible;
  serviceOrderDate;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  orderId;
  currencyCode;
  currencyFactor;
  widthWindow = 550;
  heightWindow = 450;
  isMobileSettings: boolean = false;
  shortcuts: ShortcutInput[] = [];
  mobileMenuFooter = [{ id: 0, name: 'Pola dodatkowe' }];
  form: FormGroup;
  submitted: boolean = false;

  unicalGuid;
  sizeUnitsVisible: boolean = false;
  addDescription: boolean = false;
  selectContractor;
  visibleUserExtensions: boolean = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private nestService: NestService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('menu.user-extensions')
      .subscribe((text) => (this.mobileMenuFooter[0].name = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.createForm();

    this.selectContractor = {
      icon: 'icon absui-icon--folder-expand',
      type: 'default',
      onClick: () => {},
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible?.currentValue) {
      if (this.selectedId?.length) {
        this.getDateSelectedId(this.selectedId[0]);
        this.findSocketAndRun('XGSKPO1');
      } else {
        this.findSocketAndRun('XGSKDO1');
      }
      setTimeout(() => {
        this.focusFirst?.instance?.focus();
      }, 700);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closePopup();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          //this.onSave();
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  clickOptions = (e) => {
    if (e.itemData.id == 0 && !this.readOnly) this.visibleUserExtensions = true;
  };

  getDateSelectedId = (selectedId) => {
    this.appService
      .getAuth(
        `services/orders/${this.orderId}/jobCosts?orderId=${selectedId.ServiceJobCostId}`
      )
      .subscribe((res) => {
        res.data.forEach((field) => {
          if (field.ServiceJobCostId == selectedId.ServiceJobCostId)
            this.form.patchValue(field);
        });
        if (this.form.value.Discount != 0)
          this.form.controls.Discount.setValue(this.form.value.Discount / 100);
        this.cd.detectChanges();
      });
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      ServiceJobCostId: [0],
      ProductId: [0, [Validators.required, Validators.min(1)]],
      Amount: [0, [Validators.required, Validators.min(1)]],
      Unit: ['', Validators.required],
      TaxCode: [''],
      PriceNet: [0],
      PriceGross: [0],
      WarehouseDocumentId: [0],
      IsInvoiced: [true],
      ServiceOrderId: [this.orderId],
      Factor: [0],
      AddToServiceOrder: [false],
      IsBaseService: [false],
      Description: [''],
      Field1: [''],
      Field2: [''],
      Field3: [''],
      Field4: [''],
      Field5: [''],
      Field6: [''],
      Field7: [''],
      Field8: [''],
      Field9: [''],
      Field10: [''],
      DocumentOrderId: [0],
      Discount: [0],
    });
  };

  onValueChangedBaseService(e){
    if(e.value){
      this.form.controls['AddToServiceOrder'].setValue(false)
    }
  }

  onValueChangedBaseorder(e){
    if(e.value){
      this.form.controls['IsBaseService'].setValue(false);
      this.form.controls['IsInvoiced'].setValue(true)
    }
  }

  getCurrencyFactor() {
    return new Promise((resolve) => {
      const dateToCheck = this.event.getLastWorkDayDate(
        new Date(this.serviceOrderDate)
      );

      this.appService
        .getAuth(`currencies/rates?currencyCode=${this.currencyCode}&bank=NBP`)
        .subscribe((res) => {
          if (!res.data || !res.data?.length) {
            // brak w ogóle kursów - import
            this.downloadCurse();
            this.cd.detectChanges();
            resolve(true);
            return;
          } else if (!res.data[0].Rates?.length) {
            // brak w ogóle kursu dla daty - import
            this.downloadCurse();
            this.cd.detectChanges();
            resolve(true);
            return;
          }

          this.foundRate =
            res.data.find((field) => {
              const fieldDate = new DatePipe('en-US').transform(
                field.Date,
                'yyyy-MM-dd'
              );
              return (
                fieldDate.substring(0, 10) === dateToCheck.substring(0, 10)
              );
            }) || res.data[0];

          const dataCurse = new DatePipe('en-US').transform(
            this.foundRate.Date,
            'yyyy-MM-dd'
          );

          if (dataCurse !== dateToCheck) {
            // nie ma aktualnego, ale sa jakies - pytanie czy pobrac aktualne, czy zaktualizować tymi ktore sa
            this.paramsMsgDiffDate = {
              dateDoc: dateToCheck,
              dateFound: dataCurse,
            };
            this.idDownloadCurses = true;
            this.cd.detectChanges();
            resolve(true);
            return;
          }
          resolve(true);
        }
      );
    });
  }

  idDownloadCurses: boolean = false;
  foundRate = null;
  paramsMsgDiffDate = {
    dateDoc: '',
    dateFound: '',
  };
  downloadCurse = () => {
    const dateToCheckFormatted = this.event.getLastWorkDayDate(
      new Date(this.serviceOrderDate)
    );
    const object = {
      Bank: 'NBP',
      Date: dateToCheckFormatted,
      ForceReset: false,
    };

    this.appService
      .postAuth(`currencies/importRates`, object)
      .pipe(
        switchMap(() =>
          this.appService.getAuth(
            `currencies/rates?currencyCode=${this.form.value.CurrencyCode}&date=${dateToCheckFormatted}&bank=NBP`
          )
        ),
        catchError(() => {
          this.translate.instant('taxRates.noRateAvailable');
          return of({});
        })
      )
      .subscribe((res) => {
        this.foundRate = res?.data?.find((field) => {
          const date = new DatePipe('en-US').transform(
            field.Date,
            'yyyy-MM-dd'
          );

          return (
            date.substring(0, 10) === dateToCheckFormatted.substring(0, 10)
          );
        });

        if (this.foundRate) {
          this.form.controls.CurrencyFactor.setValue(
            this.foundRate.Rates[0].Middle
          );

          // this.confirmRecalculate();
        } else {
          // brak rekordu
          this.event.showNotification(
            'error',
            this.translate.instant('taxRates.noRateAvailable')
          );
          
          if(res?.data?.length){
            this.foundRate = res.data[0];
            this.form.controls.CurrencyFactor.setValue(
              this.foundRate.Rates[0].Middle
            );
            this.form.controls.CurrencyFactorDate.setValue(this.foundRate.Date);
          }
        }
      });
    this.idDownloadCurses = false;

    if (this.foundRate !== null) {
      this.form.controls.TaxCode.setValue('0');
      this.form.controls.PriceNet.setValue(
        this.choosedArticle[0].SellPriceGrossDefault /
          this.foundRate.Rates[0].Middle
      );
      this.form.controls.PriceGross.setValue(
        this.choosedArticle[0].SellPriceGrossDefault /
          this.foundRate.Rates[0].Middle
      );
    }
    this.cd.detectChanges();
  };

  ProductType: string = '';
  choosedArticle;
  async onChoosedArticle(e) {
    if (this.currencyCode !== 'PLN' && this.currencyCode !== '') {
      await this.getCurrencyFactor();
    }
    if (e) {
      if (!Array.isArray(e)) e = [e];
      this.choosedArticle = e;
      this.ProductType = e[0].ProductType;
      this.form.controls.ProductId.setValue(e[0].ProductId);
      this.form.controls.Amount.setValue(1);
      this.form.controls.Unit.setValue(e[0].BaseUnit);
      if (
        this.currencyCode.trim() === 'PLN' ||
        this.currencyCode === '' ||
        this.foundRate === null
      ) {
        this.form.controls.TaxCode.setValue(e[0].SellTaxCode.replace(' ', ''));
        this.form.controls.PriceNet.setValue(e[0].SellPriceNetDefault);
        this.form.controls.PriceGross.setValue(e[0].SellPriceGrossDefault);
      } else if (this.foundRate !== null) {
        this.form.controls.TaxCode.setValue('0');
        this.form.controls.PriceNet.setValue(
          e[0].SellPriceGrossDefault / this.foundRate.Rates[0].Middle
        );
        this.form.controls.PriceGross.setValue(
          e[0].SellPriceGrossDefault / this.foundRate.Rates[0].Middle
        );
      }
    } else {
      this.ProductType = '';
      this.form.controls.ProductId.setValue(0);
      this.form.controls.Unit.setValue('');
      this.form.controls.TaxCode.setValue('');
      this.form.controls.PriceNet.setValue(0);
      this.form.controls.PriceGross.setValue(0);
    }
    this.cd.detectChanges();
  }

  onSizeUnitsChoosed = (e) => {
    if (e) {
      this.form.controls.Unit.setValue(e.Code);
      this.sizeUnitsVisible = false;
    }
  };

  onBuyTaxChanged = (e) => {
    if (e) {
      this.form.controls.TaxCode.setValue(e[0].TaxCode);
      this.calculatePrices();
    }
  };

  onChoosedDeviceCondition = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });
    }
    this.form.controls.Description.setValue(text);
    this.addDescription = false;
  };

  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.createForm(); //reset form with initial values
    this.submitted = false;

    if (!e) {
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
    }
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  onInsertedUserExtensions = (e) => {
    this.form.patchValue(e);
  };

  //przeliczam cene brutto na podstawie wybranej stawki podatkowej.
  calculatePrices = () => {
    if (this.form.value.TaxCode != '-- ') {
      try {
        let TaxCode;
        if (
          this.form.value.TaxCode.trim() == 'ZW' ||
          this.form.value.TaxCode.trim() == 'NP' ||
          this.form.value.TaxCode.trim() == '--'
        ) {
          TaxCode = 0;
        } else {
          TaxCode = Number(this.form.value.TaxCode.replace(/ /g, ''));
        }

        let gross = this.event.countAmountGross(
          this.form.value.PriceNet,
          TaxCode
        );
        // let vat = (this.form.value.PriceNet * TaxCode) / 100;
        this.form.controls.PriceGross.setValue(gross);
      } catch {}
    }
  };

  calculateNetPrice = () => {
    if (this.form.value.TaxCode != '-- ') {
      try {
        let TaxCode;
        if (
          this.form.value.TaxCode.trim() == 'ZW' ||
          this.form.value.TaxCode.trim() == 'NP' ||
          this.form.value.TaxCode.trim() == '--'
        ) {
          TaxCode = 0;
        } else {
          TaxCode = Number(this.form.value.TaxCode.replace(/ /g, ''));
        }

        let net = this.event.countAmountNet(
          this.form.value.PriceGross,
          TaxCode
        );

        this.form.controls.PriceNet.setValue(net);
      } catch {}
    }
  };

  myTimerNetto;
  onValueNetChange = () => {
    if (this.myTimerNetto) {
      clearTimeout(this.myTimerNetto);
    }

    this.myTimerNetto = setTimeout(() => {
      this.calculatePrices();
    }, 100);
  };

  myTimerGross;
  onValueGrossChange = () => {
    if (this.myTimerGross) {
      clearTimeout(this.myTimerGross);
    }

    this.myTimerGross = setTimeout(() => {
      this.calculateNetPrice();
    }, 100);
  };

  onSave = () => {
    this.submitted = true;
    this.form.controls.ServiceOrderId.setValue(this.orderId);
    if (this.form.valid && !this.readOnly) {
      if (this.form.value.IsBaseService && !this.form.value.IsInvoiced) {
        this.event.showNotification(
          'warning',
          this.translate.instant(
            'servicesDevices.itIsNotPossibleToAddAitemWithoutMarkedOption'
          )
        );
        return;
      }

      if (this.ProductType != 'Usługa' && this.form.value.IsBaseService) {
        this.event.showNotification(
          'warning',
          this.translate.instant(
            'servicesDevices.theBaseServiceCanOnlyBeAnAssortment'
          )
        );
        return;
      }

      this.form.value.Discount = this.form.value.Discount * 100;
      if (this.form.value.ServiceJobCostId == 0) {
        this.findSocketAndRun('XGSKDZ1');
      } else {
        this.findSocketAndRun('XGSKPZ1');
      }
    }
  };

  saveNewRecord() {
    this.appService
      .postAuth(`services/orders/${this.orderId}/jobCosts`, this.form.value)
      .subscribe(
        () => {
          this.findSocketAndRun('XGSKDZ2');
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  afterSaveNewRecord() {
    this.onInserted.emit(this.form.value);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
    this.isVisible = false;
  }

  saveOldRecord() {
    this.appService
      .putAuth(
        `services/orders/${this.orderId}/jobCosts/${this.form.value.ServiceJobCostId}`,
        this.form.value
      )
      .subscribe(
        () => {
          this.findSocketAndRun('XGSKPZ2');
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  afterSaveOldRecord() {
    this.onInserted.emit(this.form.value);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
    this.isVisible = false;
  }

  nestOperation: NestOperation = null;
  findSocketAndRun(SocketCode: string, nestOperation: NestOperation = null) {
    this.nestObjectParameters = Object.assign({}, this.form.value);
    this.nestOperation = nestOperation;
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return false;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.ParentObject) {
      e.updatedParameters.ParentObject =
        this.nestService.removeNonEditableVariables(
          e.updatedParameters.ParentObject,
          e.SocketCode,
          true
        );
      this.nestParentUpdated.emit(e.updatedParameters?.ParentObject);
    }
    if (e.updatedParameters?.Object) {
      e.updatedParameters.Object = this.nestService.removeNonEditableVariables(
        e.updatedParameters.Object,
        e.SocketCode
      );
      this.form.patchValue(e.updatedParameters?.Object, { emitEvent: false });
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGSKPO1':
        this.findSocketAndRun('XGSKAO1');
        break;
      case 'XGSKDO1':
        this.findSocketAndRun('XGSKAO1');
        break;
      case 'XGSKAO1':
        this.openNestFinished = true;
        if (this.selectedId?.length) {
          this.findSocketAndRun('XGSKPO2');
        } else {
          this.findSocketAndRun('XGSKDO2');
        }
        break;
      case 'XGSKPO2':
        this.findSocketAndRun('XGSKAO2');
        break;
      case 'XGSKDO2':
        this.findSocketAndRun('XGSKAO2');
        break;
      case 'XGSKPA1':
        this.findSocketAndRun('XGSKAA1', 'edit');
        break;
      case 'XGSKDA1':
        this.findSocketAndRun('XGSKAA1', 'add');
        break;
      case 'XGSKAA1':
        if (this.nestOperation === 'add') {
          this.findSocketAndRun('XGSKDA2', 'add');
        } else if (this.nestOperation === 'edit') {
          this.findSocketAndRun('XGSKPA2', 'add');
        }
        break;
      case 'XGSKDA2':
        this.findSocketAndRun('XGSKAA2');
        break;
      case 'XGSKPA2':
        this.findSocketAndRun('XGSKAA2');
        break;
      case 'XGSKAA2':
        this.setVisibleFalse();
        break;
      case 'XGSKDZ1':
        this.findSocketAndRun('XGSKAZ1');
        break;
      case 'XGSKPZ1':
        this.findSocketAndRun('XGSKAZ1');
        break;
      case 'XGSKAZ1':
        if (this.form.value.ServiceJobCostId == 0) {
          this.saveNewRecord();
        } else {
          this.saveOldRecord();
        }
        break;
      case 'XGSKDZ2':
        this.findSocketAndRun('XGSKAZ2');
        break;
      case 'XGSKPZ2':
        this.findSocketAndRun('XGSKAZ2');
        break;
      case 'XGSKAZ2':
        if (this.form.value.ServiceJobCostId == 0) {
          this.afterSaveNewRecord();
        } else {
          this.afterSaveOldRecord();
        }
        break;
      default:
        break;
    }
  }

  closePopup() {
    if (this.selectedId?.length) {
      this.findSocketAndRun('XGSKPA1');
    } else {
      this.findSocketAndRun('XGSKDA1');
    }
  }

  onValueChangedInvoiced(e: any) {
    if (!e.value) {
    this.form.controls.AddToServiceOrder.setValue(e.value);
    }
  }

  setVisibleFalse() {
    this.isVisible = false;
    this.onClosing.emit(true);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGS');
    }
  }
}

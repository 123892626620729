import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTooltipModule,
  DxTreeViewComponent,
  DxTreeViewModule,
} from 'devextreme-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { IOfferStatusHistory } from './offerstatushistory';
import { CoreModule } from 'src/app/core/core.module';
import { CommonModule, DatePipe } from '@angular/common';
import { ResizableModule } from 'angular-resizable-element';
import { NgShortcutsComponent } from 'src/app/core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-offer-status-history',
    templateUrl: './offer-status-history.component.html',
    styleUrls: ['./offer-status-history.component.scss'],
    inputs: ['isVisible', 'offerId'],
    imports: [
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxDataGridModule,
        TranslateModule,
        DxTreeViewModule,
        CoreModule,
        DatePipe,
        CommonModule,
        ResizableModule,
        DxScrollViewModule,
        NgShortcutsComponent,
    ]
})
export class OfferStatusHistoryComponent implements OnInit, OnChanges {
  showBottomPanel: boolean = true;
  @Output('onClosing') onClosing: EventEmitter<any> = new EventEmitter();
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  @ViewChild('treeView') treeView: DxTreeViewComponent;
  shortcuts = [];
  width: number = 300;
  unicalGuid;
  isVisible: boolean = false;
  offerId;
  widthWindow: 900;
  statusHistory: IOfferStatusHistory[] = [];
  focusedRowIndex: number = -1;
  heightBottomPanel;

  constructor(
    public event: EventService,
    public appServices: AppServices,
    public translate: TranslateService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.getUsers();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.isVisible?.currentValue &&
      changes.isVisible.currentValue !== changes.isVisible.previousValue &&
      this.offerId
    ) {
      this.getStatusHistory();
    }
  }

  visibleChange = () => {
    if (!this.isVisible) {
      this.onClosing.emit(true);
      //this.positionTypeValue = false;
    }
  };

  filteredName: string = null;
  users: any[] = [];

  getUsers() {
    this.appServices.getAuth(`appPermissions/users`).subscribe(
      (res) => {
        const all = this.translate.instant('all');
        this.users = [
          {
            AppUserName: all,
            AppUserId: -1,
            expanded: true,
            items: res.data,
          },
        ];
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  }

  closeWindow() {
    this.isVisible = false;
    this.onClosing.emit(true);
  }

  getStatusHistory() {
    this.appServices
      .getAuth(`Offers/documents/${this.offerId}/statusHistory`)
      .subscribe(
        (res) => {
          this.statusHistory = this.filteredName
            ? res.data.filter((x) => x.UserName == this.filteredName)
            : res.data;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  selectItemTreeview(e) {
    if (e.itemData.AppUserId == -1) {
      this.filteredName = null;
    } else {
      this.filteredName = e.itemData.AppUserName;
    }
    this.getStatusHistory();
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  focusedElement: any = null;

  onFocusedRowChanged(e) {
    if (!e.row) {
      return;
    }

    this.focusedElement = e.row.data;
  }

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  resizingBottom = (e) => {
    let height;
    this.showBottomPanel = true;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '35px';
    if (height < 35) {
      height = 35;
    }
    if (height > 560 - 35) height = 560 - 35;
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;

    this.heightGrid = 560 - height - 100;
    this.localStorageData.bottomPanelHeight = `${height}px`;

    localStorage.setItem(
      'leftPanelOfferStatusHistory',
      this.event.encryptString(this.localStorageData)
    );
  };

  heightGrid: number = 300;

  localStorageData = {
    bottomPanelHeight: null,
    bottomPanelVisible: true,
  };

  timeoutHandler;
  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange = () => {
    this.showBottomPanel = !this.showBottomPanel;

    if (
      this.showBottomPanel &&
      this.localStorageData.bottomPanelHeight == null
    ) {
      this.bottomSidePanel.nativeElement.style.minHeight = `35px`;
      this.heightGrid = 560 - 135;
    } else if (
      this.showBottomPanel &&
      this.localStorageData.bottomPanelHeight != null
    ) {
      this.bottomSidePanel.nativeElement.style.minHeight =
        this.localStorageData.bottomPanelHeight;
      this.heightGrid =
        560 -
        Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
        100;
    } else {
      this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
      this.bottomSidePanel.nativeElement.style.height = `34px`;
      this.heightGrid = 560 - 110;
    }
    this.localStorageData.bottomPanelVisible = this.showBottomPanel;

    localStorage.setItem(
      'leftPanelOfferStatusHistory',
      this.event.encryptString(this.localStorageData)
    );
  };
}

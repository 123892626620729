<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-commercial-operation.groupDataChange' | translate"
  [visible]="true"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onSave()"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div
        class="form-group-inline-right"
        style="width: 100%"
        [formGroup]="form"
      >
        <label style="width: 205px">
          {{
            "form-commercial-operation.isSubjectTointrastatDeclaration"
              | translate
          }}
        </label>

        <dx-select-box
          [items]="isSubjectTointrastatDeclaration"
          [valueExpr]="'code'"
          [displayExpr]="'label'"
          width="276"
          formControlName="IsSubjectToDeclaration"
        ></dx-select-box
        ><br />

        <label style="width: 205px">
          {{ "intrastate-documents.new-position.CountryCode" | translate }} (11)
        </label>

        <app-country-box
          [advancedChooser]="true"
          style="width: 276px; margin-bottom: 0px"
          [selectedCountry]="form.value.DeliveryCountryCode"
          (onChoosed)="selectedCountryChange($event)"
          [readOnly]="readOnly"
        >
        </app-country-box
        ><br />

        <label style="width: 205px">
          {{ "intrastate-documents.new-position.DeliveryCode" | translate }}
          (12)
        </label>
        <app-transaction-delivery-transport-cods
          [selectedCode]="form.get('DeliveryCode').value"
          style="width: 276px"
          (onChoosed)="selectedDeliveryCodeChange($event)"
          [readOnly]="readOnly"
          [type]="'delivery'"
        >
        </app-transaction-delivery-transport-cods
        ><br />

        <label style="width: 205px">
          {{ "intrastate-documents.new-position.TransactionCode" | translate }}
          (13)
        </label>
        <app-transaction-delivery-transport-cods
          [selectedCode]="form.get('TransactionCode').value"
          style="width: 276px"
          (onChoosed)="selectedTransactionCodeChange($event)"
          [readOnly]="readOnly"
          [type]="'transaction'"
        >
        </app-transaction-delivery-transport-cods
        ><br />

        <label style="width: 205px">
          {{ "articles.cnCode" | translate }} (14)
        </label>
        <app-intrastat-cn-codes
          [selectedCnCode]="form.get('CNCode').value"
          (onChoosed)="onCnCodeChoosed($event)"
          [readOnly]="readOnly"
        >
        </app-intrastat-cn-codes
        ><br />

        <label style="width: 205px">
          {{ "intrastate-documents.new-position.TransportCode" | translate }}
          (15)</label
        >
        <app-transaction-delivery-transport-cods
          [selectedCode]="form.get('TransportCode').value"
          style="width: 276px"
          (onChoosed)="selectedTransportCodeChange($event)"
          [readOnly]="readOnly"
          [type]="'transport'"
        >
        </app-transaction-delivery-transport-cods
        ><br />

        <label style="width: 205px">
          {{ "intrastate-documents.new-position.CountryOrginCode" | translate }}
          (16)
        </label>

        <app-country-box
          [advancedChooser]="true"
          [selectedCountry]="form.get('OrginCountryCode').value"
          style="width: 276px; margin-bottom: 0"
          (onChoosed)="selectedCountryOrginChange($event)"
          [readOnly]="readOnly"
        >
        </app-country-box
        ><br />

        <label style="width: 205px">
          {{ "intrastate-documents.new-position.NetWeight" | translate }} (17)
        </label>
        <dx-number-box
          style="width: 276px"
          formControlName="MassNet"
          format="#0.000"
          [min]="0"
        >
        </dx-number-box
        ><br />

        <label style="width: 205px">
          {{
            "intrastate-documents.new-position.ForeignContractorCode"
              | translate
          }}
          (18)
        </label>
        <app-contractors
          [dropDownBoxMode]="true"
          style="width: 276px"
          [readOnly]="readOnly"
          [selectedId]="form.get('CustomerId').value"
          #appCustomer
          (onChoosed)="onChoosedCustomer($event)"
        >
        </app-contractors>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

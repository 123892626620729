<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  [minWidth]="1400"
  [fullScreen]="fullScreen"
  (onResizeEnd)="onResizeEnd()"
  (fullScreenChange)="fullScreenChange($event)"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  (onShown)="event.onShownPopUp(); updateColumns()"
  (onHidden)="
    event.onHiddenPopUp();
    removeSemaphor();
    submitted = false;
    setLocalStorage()
  "
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--fullscreen2': !fullScreen,
          'absui-icon--fullscreen-off': fullScreen
        }"
        (click)="fullScreen = !fullScreen"
        style="position: relative; top: 5px"
        [id]="'fitToWidth' + unicalGuid"
      ></i>

      <dx-button
        text="{{ 'buttons.finish' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly || unsavedDocument"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.hang' | translate }}"
        type="danger"
        (onClick)="isVisible = false; suspended()"
        [id]="'hang' + unicalGuid"
        style="margin-right: 6px"
        *ngIf="!isDisableCancel && !readOnly"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="cancel()"
        [id]="'cancel' + unicalGuid"
        *ngIf="!isDisableCancel && !readOnly"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="close()"
        [id]="'close' + unicalGuid"
        *ngIf="readOnly"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#hang' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.hang" | translate }} (Ctrl + Z)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.finish" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#close' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <div
      class="top-menu-panel"
      style="left: 10px; box-shadow: none; display: flex; align-items: center"
    >
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        (onClick)="addRow = true"
        [id]="'btn-add' + unicalGuid"
        [disabled]="readOnly"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        [text]="'buttons.edit' | translate"
        [id]="'btn-edit' + unicalGuid"
        (onClick)="editDok()"
        [disabled]="readOnly || focusedSelected?.length == 0"
        #btnEdit
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="focusedSelected?.length == 0 || readOnly"
        [text]="'buttons.delete' | translate"
        (onClick)="isDelete()"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--files-copy"
        [id]="'btn-clone' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="focusedSelected?.length == 0 || readOnly"
        [text]="'inventory.duplicate' | translate"
        (onClick)="clonePosition()"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--sum"
        [id]="'btn-sum' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="readOnly"
        [text]="'stockHistory.sum' | translate"
        (onClick)="sum()"
      >
      </dx-button>

      <app-reports
        #reports
        [code]="'Remanent_pozycja'"
        [readOnly]="readOnly"
        [disableReadOnly]="true"
        menuName="JL_REM"
        type="Remanent_pozycja"
        [objectId]="form?.value?.InventoryDocumentId"
        [params]="getLoadParams()"
      >
      </app-reports>

      <dx-button
        [id]="'btn-group' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="readOnly || focusedSelected?.length == 0"
        [text]="'inventory.numberOfGroups' | translate"
        (onClick)="isGroupArt = true"
      >
      </dx-button>

      <dx-button
        [id]="'btn-group-doc' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="readOnly || focusedSelected?.length == 0"
        [text]="'inventory.numberOfDocument' | translate"
        (onClick)="isGroupDoc = true"
      >
      </dx-button>

      <div style="display: inline-flex; align-items: center">
        <dx-check-box
          text="{{ 'inventory.resetStatesAfter' | translate }}"
          [id]="'btn-zero' + unicalGuid"
          (onValueChanged)="onValueChangeZero($event, true)"
          [(value)]="SetStockAsZero"
          [readOnly]="readOnly"
        >
        </dx-check-box>
      </div>

      <!-- <dx-button [id]="'btn-zero'+unicalGuid" class="btn-dx-top-menu" icon="icon `absui-icon--remove-circle-outline"
        [disabled]="readOnly" [text]="'inventory.resetStatesAfter' | translate"
        (onClick)="isSetStockAsZero = true">
      </dx-button> -->

      <dx-tooltip
        [target]="'#btn-group' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "inventory.numberOfGroups" | translate }} (Ctrl + G)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-group-doc' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "inventory.numberOfDocument" | translate }} (Ctrl + G)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-edit' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.edit" | translate }} (F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Del)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-clone' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "inventory.duplicate" | translate }} (Ctrl + P)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-zero' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "inventory.resetStatesAfter" | translate }} (Ctrl + Shif + Z)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-sum' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "stockHistory.sum" | translate }} (Ctrl + S)
        </div>
      </dx-tooltip>
    </div>
    <div class="center-panel-scroll-verticall">
      <div class="row">
        <div class="col-md-12">
          <div class="panels-flex-container">
            <div class="with_search_panel" #rightPanel>
              <div class="row">
                <div class="col-md-12">
                  <div [formGroup]="form" style="margin-bottom: 10px">
                    <div
                      style="
                        display: flex;
                        flex-wrap: wrap;
                        max-width: 100%;
                        align-items: center;
                        justify-content: space-between;
                        gap: 6px;
                        margin-bottom: 12px;
                      "
                    >
                      <div
                        style="
                          min-width: 560px;
                          display: flex;
                          flex-wrap: wrap;
                          align-items: center;
                        "
                      >
                        <label
                          style="
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                          "
                          >{{ "form-financial-document.number" | translate }}
                          <strong>*</strong></label
                        >
                        <dx-text-box
                          width="276"
                          formControlName="Number"
                          [readOnly]="readOnly || autoNumeration"
                          valueChangeEvent="input"
                          style="display: inline-block; margin-right: 10px"
                        ></dx-text-box>
                        <label
                          style="
                            width: auto;
                            display: inline-block;
                            margin-right: 10px;
                          "
                          >{{
                            "form-financial-document.date" | translate
                          }}</label
                        >
                        <wapro-date-box
                          [readOnly]="readOnly"
                          type="date"
                          width="180"
                          [displayFormat]="event.dateFormat"
                          formControlName="Date"
                          style="display: inline-block; position: relative"
                        >
                        </wapro-date-box>
                      </div>
                      <div
                        style="
                          min-width: 291px;
                          margin-left: auto;
                          height: 100%;
                          display: flex;
                          align-items: center;
                        "
                      >
                        <app-product-scaner
                          [inventory]="readOnly ? false : true"
                          [readOnly]="readOnly"
                          class="comercial-operation-form-step-3"
                          (onInputChanged)="onProductScanerInputChanged($event)"
                          (onCollectorOpened)="onCollectorOpened($event)"
                        >
                          [keyboardShortcutsDisabled]="addRow"
                          [parentId]="unicalGuid"
                        </app-product-scaner>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex">
                    <div
                      class="d-flex flex-wrap"
                      style="gap: 8px 0; margin-bottom: 10px"
                    >
                      <app-custom-dropdown-box
                        [items]="filterCriteria"
                        [(selectedItem)]="valueCriteria"
                        [(filterValue)]="filterValue"
                        (onValueChanged)="onFilterDataChanged($event)"
                        style="margin-right: 5px; margin-bottom: 0"
                        class="inventory-search"
                      >
                      </app-custom-dropdown-box>
                      <app-custom-chips-button
                        name="{{ 'warehouse.grid.state' | translate }}"
                        [list]="stateList"
                        (onValueChanged)="onValueChangedState($event)"
                        positionTop="0"
                        style="display: inline-flex"
                      >
                      </app-custom-chips-button>
                      <app-custom-chips-button
                        name="{{ 'inventory.stateOfExecution' | translate }}"
                        [list]="positionStateList"
                        (onValueChanged)="onValueChangedStateDone($event)"
                        positionTop="0"
                        [customDisplayValue]="customValue"
                        [deleteFilter]="true"
                        [disabledClear]="true"
                        style="display: inline-flex"
                      >
                      </app-custom-chips-button>
                      <app-custom-chips-button
                        name="{{
                          'inventory.actionAfterReaderAdd' | translate
                        }}"
                        [list]="actionAfter"
                        positionTop="0"
                        (onValueChanged)="onMarginValueChanged($event)"
                        #marginFilterBox
                        [customDisplayValue]="actionAfter[marginFilter].label"
                        [disabledClear]="true"
                      >
                      </app-custom-chips-button>
                      <app-custom-chips-button
                        name="{{ 'inventory.showStateAfter' | translate }}"
                        [list]="stateAfter"
                        positionTop="0"
                        (onValueChanged)="onShowStateAfterChanged($event)"
                        [disabledClear]="false"
                      >
                      </app-custom-chips-button>
                    </div>

                    <div class="ml-auto">
                      <div class="refresh-panel d-flex">
                        <dx-button
                          class="btn-dx-top-menu-folder"
                          icon="icon dx-icon-icon-refresh"
                          stylingMode="text"
                          type="default"
                          (click)="getDocumentPosition()"
                          style="margin-right: 6px"
                          [disabled]="readOnly"
                          [id]="'btn-refresh' + unicalGuid"
                        ></dx-button>
                        <dx-button
                          class="btn-dx-top-menu-folder warehouse-documents-step-2"
                          icon="column-chooser"
                          stylingMode="text"
                          type="default"
                          (click)="columnsChooserVisible = true"
                          [id]="'btn-columns' + unicalGuid"
                        >
                        </dx-button>
                      </div>
                    </div>
                  </div>

                  <dx-tooltip
                    [target]="'#horizontal' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "inventory.showOnlyO" | translate }}
                    </div>
                  </dx-tooltip>

                  <dx-data-grid
                    [id]="'gridContainer' + unicalGuid"
                    #gridClass
                    [dataSource]="dataSource"
                    [remoteOperations]="true"
                    [wordWrapEnabled]="false"
                    [showBorders]="true"
                    shortcut
                    [height]="heightGrid"
                    [(selectedRowKeys)]="selectedRow"
                    (onSelectionChanged)="onSelectionChanged($event)"
                    [focusedRowEnabled]="true"
                    (onRowDblClick)="onRowDblClick()"
                    (onRowClick)="onRowClick($event)"
                    (onFocusedRowChanged)="onFocusedRowChanged($event)"
                    (onContentReady)="onContentReady($event)"
                    (onInitialized)="onInitialized($event)"
                    (onKeyDown)="onKeyDown($event)"
                    (onOptionChanged)="onOptionChanged($event)"
                    [allowColumnResizing]="true"
                    [columnResizingMode]="'widget'"
                    [(focusedRowIndex)]="focusedRowIndex"
                    (dataSourceChange)="dataSourceChange()"
                  >
                    <dxo-load-panel [enabled]="false"></dxo-load-panel>
                    <dxo-scrolling
                      mode="virtual"
                      rowRenderingMode="virtual"
                      [showScrollbar]="'always'"
                    ></dxo-scrolling>
                    <dxo-paging [pageSize]="200" [enabled]="true"></dxo-paging>
                    <dxo-selection
                      mode="multiple"
                      showCheckBoxesMode="always"
                      selectAllMode="allPages"
                    >
                    </dxo-selection>
                    <dxo-editing
                      mode="row"
                      [confirmDelete]="false"
                    ></dxo-editing>

                    <dxo-editing
                      mode="cell"
                      refreshMode="repaint"
                    ></dxo-editing>

                    <dxi-column
                      caption="{{ 'companies.grid.columns.name' | translate }}"
                      dataField="ProductFullName"
                      [allowSorting]="false"
                      [allowReordering]="true"
                      width="300"
                      headerCellTemplate="dateTemplate"
                      [cssClass]="
                        orderby === 'ProductFullName' ? 'sort-column-bg' : null
                      "
                    ></dxi-column>

                    <div *dxTemplate="let data of 'dateTemplate'">
                      <div class="grid-header-wrapper">
                        {{ "companies.grid.columns.name" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'ProductFullName'"
                          (click)="switchOrder(); getDocumentPosition()"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.jm' | translate
                      }}"
                      width="100"
                      [allowSorting]="false"
                      [allowReordering]="true"
                      dataField="Unit"
                    >
                    </dxi-column>

                    <dxi-column
                      caption="{{ 'customerGroups.catalogIndex' | translate }}"
                      dataField="IndexCatalogue"
                      [allowSorting]="false"
                      width="150"
                      headerCellTemplate="catalogIndex"
                      [allowReordering]="true"
                      [cssClass]="
                        orderby === 'IndexCatalogue' ? 'sort-column-bg' : null
                      "
                    ></dxi-column>
                    <div *dxTemplate="let data of 'catalogIndex'">
                      <div class="grid-header-wrapper">
                        {{ "customerGroups.catalogIndex" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'IndexCatalogue'"
                          (click)="switchOrder(); getDocumentPosition()"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      caption="{{ 'inventory.stateBefore' | translate }}"
                      dataField="StockAmount"
                      [allowSorting]="false"
                      [allowReordering]="true"
                      width="100"
                      alignment="left"
                      cellTemplate="numberPrecision4Template"
                    ></dxi-column>
                    <dxi-column
                      caption="{{ 'inventory.stateAfter' | translate }}"
                      dataField="StockLeft"
                      [allowReordering]="true"
                      [allowSorting]="false"
                      width="100"
                      alignment="left"
                      cellTemplate="numberPrecision4Template"
                    ></dxi-column>

                    <dxi-column
                      caption="{{ 'inventory.difference' | translate }}"
                      dataField="StockDifference"
                      [allowReordering]="true"
                      [allowSorting]="false"
                      width="100"
                      alignment="left"
                      cellTemplate="numberPrecision4Template"
                    ></dxi-column>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.netAmount' | translate
                      }}"
                      [allowReordering]="true"
                      dataField="BuyPriceNet"
                      [allowSorting]="false"
                      width="100"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint" [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.netValue' | translate
                      }}"
                      dataField="TotalNetAmount"
                      [allowReordering]="true"
                      [allowSorting]="false"
                      width="100"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint" [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.netValueAfter'
                          | translate
                      }}"
                      [allowReordering]="true"
                      dataField="TotalNetAmountDifference"
                      [allowSorting]="false"
                      width="180"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint" [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.grossAmount' | translate
                      }}"
                      [allowReordering]="true"
                      dataField="BuyPriceGross"
                      [allowSorting]="false"
                      width="100"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint" [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.grossValue' | translate
                      }}"
                      [allowReordering]="true"
                      dataField="TotalGrossAmount"
                      [allowSorting]="false"
                      width="100"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint" [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      caption="{{
                        'form-commercial-operation.grid.grossValueAfter'
                          | translate
                      }}"
                      [allowReordering]="true"
                      dataField="TotalGrossAmountDifference"
                      [allowSorting]="false"
                      width="180"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint" [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      caption="{{ 'customerGroups.tradingIndex' | translate }}"
                      dataField="IndexTrading"
                      [allowSorting]="false"
                      width="150"
                      alignment="left"
                      [allowReordering]="true"
                      headerCellTemplate="IndexTrading"
                      [cssClass]="
                        orderby === 'IndexTrading' ? 'sort-column-bg' : null
                      "
                    ></dxi-column>
                    <div *dxTemplate="let data of 'IndexTrading'">
                      <div class="grid-header-wrapper">
                        {{ "customerGroups.tradingIndex" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'IndexTrading'"
                          (click)="switchOrder(); getDocumentPosition()"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      caption="{{ 'articles.certificateDate' | translate }}"
                      dataField="ExpirationDate"
                      [allowSorting]="false"
                      width="150"
                      dataType="date"
                      [allowReordering]="true"
                      [format]="{ type: event.dateFormat }"
                    ></dxi-column>

                    <dxi-column
                      caption="{{
                        'inventory.deliveryDocumentNumber' | translate
                      }}"
                      dataField="DeliveryDocumentNumber"
                      [allowSorting]="false"
                      width="100"
                      alignment="left"
                      [allowReordering]="true"
                    >
                    </dxi-column>

                    <dxi-column
                      caption="W"
                      dataField="IsFinished"
                      [allowSorting]="false"
                      width="40"
                      alignment="left"
                      [allowReordering]="true"
                      cellTemplate="IsFinished"
                    >
                    </dxi-column>
                    <div *dxTemplate="let data of 'IsFinished'">
                      <i
                        class="icon absui-icon--check check-grid"
                        *ngIf="data.data.IsFinished"
                      ></i>
                      <i
                        class="icon absui-icon--close close-grid"
                        *ngIf="!data.data.IsFinished"
                      ></i>
                    </div>

                    <dxi-column
                      caption="{{ 'warehouse.location' | translate }}"
                      dataField="Location"
                      [allowSorting]="false"
                      width="100"
                      alignment="left"
                      headerCellTemplate="Location"
                      [allowReordering]="true"
                      [cssClass]="
                        orderby === 'Location' ? 'sort-column-bg' : null
                      "
                    >
                    </dxi-column>
                    <dxi-column
                      [allowFiltering]="false"
                      caption="{{ 'deliveries.serialNo' | translate }}"
                      width="130"
                      [allowSorting]="false"
                      dataField="SerialNumber"
                      [allowReordering]="true"
                    ></dxi-column>

                    <div *dxTemplate="let data of 'Location'">
                      <div class="grid-header-wrapper">
                        {{ "warehouse.location" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'Location'"
                          (click)="switchOrder(); getDocumentPosition()"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>
                    <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                      {{ cell.value | amountFormatter : cell : 2 }}
                    </div>
                    <div *dxTemplate="let cell of 'numberPrecision4Template'">
                      {{ cell.value | amountFormatter : cell : 4 }}
                    </div>
                  </dx-data-grid>

                  <dx-context-menu
                    [dataSource]="contextMenu"
                    [width]="200"
                    [target]="'#gridContainer' + unicalGuid"
                    (onItemClick)="contextMenuClick($event)"
                    (onItemRendered)="event.onItemRendered($event)"
                  >
                  </dx-context-menu>
                </div>
              </div>

              <app-confirm-dialog
                [isVisible]="isShowConfirm"
                [showIcon]="false"
                confirmText="inventory.documentWithoutArticles"
                textAligne="left"
                (onClosing)="onClosingConfirm()"
                (onRemoving)="deleteDocumentInventory()"
              >
              </app-confirm-dialog>

              <app-confirm-dialog
                [isVisible]="isShowConfirmDelete"
                confirmText="inventory.wantToCancel"
                (onClosing)="onClosingConfirm()"
                (onRemoving)="deleteDocument()"
                kindDialog="question"
              >
              </app-confirm-dialog>

              <app-confirm-dialog
                [isVisible]="isSetStockAsZero"
                confirmText="inventory.resetStateAnswer"
                (onClosing)="onClosingConfirm()"
                (onRemoving)="setStockAsZero()"
                [showIcon]="false"
              >
              </app-confirm-dialog>

              <app-new-position
                [isVisible]="isDuplicat"
                [isDuplicat]="isDuplicat"
                [documentType]="documentType"
                (onClosing)="onClosingNewPosition()"
                (onInserted)="onDuplicatPosition($event)"
                [selected]="focusedSelected"
              ></app-new-position>

              <app-new-position
                [isVisible]="editRow"
                [documentType]="documentType"
                (onClosing)="onClosingNewPosition()"
                (onInserted)="onInsertedPosition($event)"
                [selected]="focusedSelected"
              ></app-new-position>

              <app-articles
                *ngIf="!readOnly"
                [isPopupMode]="true"
                [isVisible]="addRow"
                (onChoosed)="onChoosedProduct($event)"
                (onClosing)="closeArticle()"
                [focusedProductId]="
                  focusedSelected?.length ? focusedSelected[0]?.ProductId : null
                "
                [DocumentContext]="
                  documentType == 'INV'
                    ? 'DOCUMENT_INVENTORY'
                    : 'DOCUMENT_OPENING_BALANCE'
                "
              >
              </app-articles>

              <app-sum-inventory
                [isVisible]="isSum"
                (onClosing)="isSum = false; cd.detectChanges()"
                [selectedId]="form.value.InventoryDocumentId"
              ></app-sum-inventory>

              <app-group-article
                [isVisible]="isGroupArt"
                (onClosing)="isGroupArt = false; cd.detectChanges()"
                [selected]="focusedSelected"
                [inventoryDocumentId]="form.value.InventoryDocumentId"
                (onInserted)="onInsertedFifo()"
              >
              </app-group-article>

              <app-group-doc
                [isVisible]="isGroupDoc"
                (onClosing)="isGroupDoc = false; cd.detectChanges()"
                [selected]="focusedSelected"
                [inventoryDocumentId]="form.value.InventoryDocumentId"
                (onInserted)="onInsertedFifo()"
              >
              </app-group-doc>
            </div>



            <div
              class="right-side-panel"
              mwlResizable
              (resizeEnd)="resizeEnd()"
              (resizeStart)="onResizeStart()"
              (resizing)="resizing()"
              #leftPanel
            >
              <div class="right-panel-btn">
                <!-- <i class="icon text-icon"></i> -->
                <dx-button
                  (mousedown)="mousedownSidePanel()"
                  (mouseup)="mouseupSidePanel()"
                  (touchend)="mouseupSidePanel()"
                  (touchstart)="mousedownSidePanel()"
                  [icon]="getResizeIcon()"
                  class="btn-dx-right-filter"
                  mwlResizeHandle
                  [resizeEdges]="{ left: true }"
                >
                </dx-button>
              </div>
              <div
                class="resize-handle-left"
                mwlResizeHandle
                [resizeEdges]="{ left: true }"
                (mouseenter)="mouseEnter()"
                (mouseleave)="mouseLeave()"
              ></div>

              <dx-tree-view
                [dataSource]="categoriesList"
                selectionMode="single"
                [selectByClick]="true"
                #categoriesTree
                displayExpr="ProductCategoryName"
                (onItemClick)="selectItemTreeviewClassification($event)"
              >
              </dx-tree-view>
            </div>


            
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  confirmText="inventory.thisOperationWillRemoveAllItemsRelated"
  [paramsMsg]="groupMsgDelete"
  (onRemoving)="delete(); gridClass.instance.focus()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<app-data-collector
  [visible]="isCollectorVisible"
  *ngIf="isCollectorVisible"
  (onPopupClose)="isCollectorVisible = false"
  [transferConfig]="collectorTransferConfig"
  (onSend)="getDocumentPosition()"
>
</app-data-collector>

<dx-tooltip
  [target]="'#btn-refresh' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "refresh" | translate }}
  </div>
</dx-tooltip>

<app-column-chooser
  [gridRef]="gridClass"
  [(visible)]="columnsChooserVisible"
  (columnsChanged)="onColumnsChanged($event)"
></app-column-chooser>

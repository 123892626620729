import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DxNumberBoxComponent } from 'devextreme-angular/ui/number-box';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { DefaultObject, NestFinishedEvent } from 'src/app/nest/nest.model';
import { NestService } from '../../../../nest-service.service';
import {
  IArticlePositionDetail,
  IOfferAfterPositionDelete,
  IUnit,
} from '../../IDocumentOffer';
import { NestOperation } from './../../../../nest/nest.model';
import { Socket } from './../../../../socket.model';

@Component({
    selector: 'app-offer-position',
    templateUrl: './offer-position.component.html',
    styleUrls: ['./offer-position.component.scss'],
    inputs: [
        'isVisible',
        'article',
        'position',
        'offerId',
        'customerId',
        'documentType',
        'documentDate',
        'currencyFactor',
        'currencyCode',
        'currencyFactorDate',
        'componentNests',
    ],
    standalone: false
})
export class OfferPositionComponent implements OnInit {
  @ViewChild('firstInputFocus') firstInputFocus: DxNumberBoxComponent;
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  @Output() onClosing = new EventEmitter();
  @Output() onAdding = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  @ViewChild('btnSave') btnSave;
  isNestRunning: boolean = false;
  openNestFinished: boolean = false;
  componentNests: Socket;
  @Input() isCurrencyDocument: boolean = false;
  nest: Socket;
  documentDate;
  customerId: number;
  documentType;
  offerId;
  position;
  isPaymentFormVisible: boolean = true;
  isVisible = false;
  article: any;
  DateDocument;
  currencyFactor;
  currencyCode;
  currencyFactorDate;
  PriceDescription: string = '';
  selectedAccordionItems = [];
  widthWindow = 700;
  submitted: boolean = false;
  format = '###,###,###,##0.00';
  isUnitDivisible = true;
  formatNonDivisible = '###,###,###,##0';

  articleDetail: IArticlePositionDetail = null;
  pricePerUnit: number = 0;
  pricePerUnitNet: number = 0;
  pricePerUnitCurrency: number = 0;
  pricePerUnitNetCurrency: number = 0;
  valueNetWithDiscount: number = 0;
  valueWithDiscount: number = 0;
  PriceGrossCurrencyWithDiscount: number = 0;
  PriceNetCurrencyWithDiscount: number = 0;
  priceId = null;
  unitId = null;
  unit: IUnit = null;
  shortcuts: ShortcutInput[] = [];
  form: FormGroup;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  advancedTranslate: string = '';
  selectedTabIndex: number = 0;
  ArtykCenWyborPozDok: boolean = false;
  isGuideButtonHighlighted: boolean = false;
  localStorageData = {
    skipUserGuide: false,
  };
  popupAnimationDuration: number = 300;

  onboardingSub: Subscription = null;
  isDefaultOnboarding: boolean = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) this.isGuideButtonHighlighted = false;
  }
  ArtykCenESDok: boolean = false;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public event: EventService,
    public appService: AppServices,
    private nestService: NestService,
    private cd: ChangeDetectorRef
  ) {
    this.ArtykCenESDok = this.event.checkPermissions('ArtykCenESDok');
    this.onboardingSub = this.event.isAutoOnboarding$.subscribe((value) => {
      this.isDefaultOnboarding = value;
    });

    const FormPozOfe2 = this.event.getConfigurationParameter('FormPozOfe2');
    if (FormPozOfe2) {
      this.isPaymentFormVisible = FormPozOfe2.Value === 'z cenami';
      this.selectedAccordionItems = this.isPaymentFormVisible
        ? [this.advancedTranslate]
        : [];
    }
  }

  ngOnInit(): void {
    this.createForm();

    if (window.innerWidth < 570) {
      this.widthWindow = 360;
    }

    this.translate.get('buttons.advanced').subscribe((text) => {
      this.advancedTranslate = text;
    });

    this.ArtykCenWyborPozDok = this.event.checkPermissions(
      'ArtykCenWyborPozDok'
    );
    this.getLocalStoargeData();
  }

  onDestroy() {
    this.onboardingSub?.unsubscribe();
  }

  getLocalStoargeData() {
    if (localStorage.getItem('CrmOfferPositions'))
      this.localStorageData = JSON.parse(
        localStorage.getItem('CrmOfferPositions')
      );
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      ProductId: 0,
      Amount: 1,
      Unit: null,
      PriceNet: 0,
      PriceGross: 0,
      Discount: 0,
      TotalNetAmount: 0,
      TotalNetAmountCurrency: 0,
      TaxCode: null,
      TotalGrossAmount: 0,
      TotalGrossAmountCurrency: 0,
      Description: '',
      PriceIdentificator: '',
      SerialNumber: '',
      ExpirationDate: this.event.getDatePlusDay(7),
      Field1: '',
      Field2: '',
      Field3: '',
      Field4: '',
      Field5: '',
      Field6: '',
      Field7: '',
      Field8: '',
      Field9: '',
      Field10: '',
      OfferDocumentPositionId: 0,
      OfferDocumentId: 0,
      PriceNetCurrency: 0,
      PriceGrossCurrency: 0,
      Factor: 1,
      ProductVariantId: 0,
    });
  };

  getArticleDetail(id: number) {
    return new Promise((resolve) => {
      const docDate = this.event.dataFormatted({
        value: new Date(this.documentDate),
      });
      const filter: any = {
        ProductId: id || this.article?.ProductId,
        CustomerId: this.customerId,
        DocumentId: this.offerId,
        DocumentType: this.documentType.Signature,
        documentDate: docDate,
        IsBuy: this.documentType.IsIncome,
      };
      if (this.currencyCode && this.currencyFactor) {
        filter.CurrencyCode = this.currencyCode;
        filter.currencyFactor = this.currencyFactor;
      }
      this.appService
        .getAuth(
          `products/${
            id || this.article?.ProductId
          }/productDetailsForCustomer/${this.customerId}`,
          filter
        )
        .subscribe(
          (res: IArticlePositionDetail) => {
            this.articleDetail = res;

            if (id === null) {
              this.unitId = res.UnitId;
              if (!this.unitId && res.Units.length) {
                this.unitId = res.Units[0].UnitId;
              }
              this.unit = this.getUnit();
              this.isUnitDivisible = this.unit?.IsDivisible;
              this.form.patchValue({
                ProductId: id || this.article?.ProductId,
                OfferDocumentId: this.offerId,
                TaxCode: res.TaxCode,
                Unit: res.BaseUnitCode,
                Factor: this.unit?.Factor || 1,
                Amount: 1,
                Discount: res.PriceDetail.Discount,
                PriceNet: res.PriceDetail.PriceNet,
                PriceGross: res.PriceDetail.PriceGross,
                PriceIdentificator: res.PriceDetail.PriceIdentificator,
              });
              this.positionAmount = this.unit?.Factor || 1;
              this.pricePerUnitNet =
                res.PriceDetail.PriceNet / this.form.value.Factor;
              this.pricePerUnit =
                res.PriceDetail.PriceGrossBase / this.form.value.Factor;

              this.PriceDescription = res.PriceDetail.PriceDescription;
              this.priceId = res.DefaultPriceId;
              // this.priceName = res.PriceName

              if (this.isCurrencyDocument) {
                this.form.patchValue({
                  PriceNetCurrency: res.PriceDetail.PriceNetCurrency,
                  PriceGrossCurrency: res.PriceDetail.PriceGrossCurrency,
                });
                this.pricePerUnitNetCurrency = Number(
                  (this.form.value.PriceNetCurrency / this.unit.Factor).toFixed(
                    4
                  )
                );
                this.pricePerUnitCurrency = Number(
                  (
                    this.form.value.PriceGrossCurrency / this.unit.Factor
                  ).toFixed(4)
                );
              }

              this.countDiscounts();
              this.countTotalAmounts();
            }

            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);

            resolve(false);
          },
          () => {
            if (id === null) {
              this.findSocketAndRun('XGOPDO2');
            }
          }
        );
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible?.currentValue && this.article?.ProductId) {
      // nowa pozycja
      this.findSocketAndRun('XGOPDO1');
    } else if (changes.isVisible?.currentValue && this.position) {
      // stara pozycja
      this.findSocketAndRun('XGOPPO1');
    }

    if (
      changes.isVisible?.currentValue == true &&
      !this.localStorageData.skipUserGuide
    ) {
      const isAutoOnboarding =
        this.event.getConfigurationParameter('ShowAnywhereGuide');
      if (isAutoOnboarding?.Value === 'Tak') {
        setTimeout(() => {
          // this.runGuide()
        }, this.popupAnimationDuration + 1000);
      }
    }

    // pobranie gniazd jak nie ma i jest popup/dropdown
    if (!this.componentNests || !this.componentNests.Id) {
      this.componentNests = this.nestService.getNestBySocketCode('XGO');
    }
  }

  async getOldPosition() {
    await this.getArticleDetail(this.position?.ProductId);

    this.form.patchValue(this.position);
    this.positionAmount = Number(
      (this.position.Amount * this.position.Factor).toFixed(4)
    );
    this.form.controls.OfferDocumentId.setValue(this.offerId);
    this.setUpdateProperties(this.position);

    this.findSocketAndRun('XGOPPO2');
  }

  setUpdateProperties(position) {
    const unit = this.articleDetail.Units.find(
      (el) => el.Code == position.Unit
    );

    this.unitId = unit.UnitId;
    this.unit = unit;
    this.priceId = null;

    this.positionAmount = this.form.value.Amount * unit.Factor;
    this.form.controls.Factor.setValue(unit.Factor);

    this.pricePerUnitNet = Number(
      (this.form.value.PriceNet / this.positionAmount).toFixed(4)
    );
    this.pricePerUnit = Number(
      (this.form.value.PriceGross / this.positionAmount).toFixed(4)
    );

    if (this.isCurrencyDocument) {
      this.pricePerUnitNetCurrency = Number(
        (this.form.value.PriceNetCurrency / this.unit.Factor).toFixed(4)
      );
      this.pricePerUnitCurrency = Number(
        (this.form.value.PriceGrossCurrency / this.unit.Factor).toFixed(4)
      );
    }

    this.countDiscounts();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.refreshKeyPressed.next();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          if (this.isVisible) {
            this.addItem();
          }
        },
      },
      {
        key: 'F3',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.selectedAccordionItems = this.selectedAccordionItems.length
            ? []
            : [this.advancedTranslate];
        },
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.closeWindow();
        },
      }
    );
  }

  visibleChange = () => {
    if (!this.isVisible) {
      this.onClosing.emit(true);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
      this.createForm();
    }
  };

  onChoosedDescription = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.controls.Description.setValue(text);
    }
  };

  closeWindow() {
    // run close
    if (this.form.value.OfferDocumentPositionId === 0) {
      this.findSocketAndRun('XGOPDA1');
    } else {
      this.findSocketAndRun('XGOPPA1');
    }
  }

  positionTypeChanged = (e) => {
    const newValue = e.value ? 'P' : '';
    this.form.controls.PositionType.setValue(newValue);
  };

  addItem = () => {
    if (!this.form.valid) {
      return;
    }

    // zatwierdzenie danych pozycji
    if (this.form.value.OfferDocumentPositionId === 0) {
      // nowa pozycja
      this.findSocketAndRun('XGOPDZ1');
    } else {
      this.findSocketAndRun('XGOPPZ1');
    }
  };

  savedResponse = null;
  saveItemNest() {
    if (this.form.value.OfferDocumentPositionId) {
      // update
      this.appService
        .putAuth(
          `offers/documents/${this.offerId}/positions/${this.form.value.OfferDocumentPositionId}`,
          this.form.value
        )
        .subscribe(
          (res: IOfferAfterPositionDelete) => {
            this.savedResponse = res;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGOPPZ2');
          }
        );
    } else {
      this.appService
        .postAuth(
          `offers/documents/${this.offerId}/positions/Add`,
          this.form.value
        )
        .subscribe(
          (res: IOfferAfterPositionDelete) => {
            this.savedResponse = res;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          },
          () => {
            this.findSocketAndRun('XGOPDZ2');
          }
        );
    }
  }

  onUnitChoosed() {
    const unit = this.getUnit();

    if (!unit) return;

    this.unit = unit;

    this.isUnitDivisible = unit.IsDivisible;
    if (!this.isUnitDivisible) {
      if (this.positionAmount % 1 !== 0) {
        const newValue = this.positionAmount - (this.positionAmount % 1) || 1;
        this.positionAmount = newValue;
      }
    }

    this.form.controls.Unit.setValue(unit.Code);
    const newAmount: number = Number(
      (this.positionAmount / unit.Factor).toFixed(4)
    );

    this.form.controls.Factor.setValue(unit.Factor);
    this.form.controls.Amount.setValue(newAmount);
    this.form.controls.PriceNet.setValue(this.pricePerUnitNet * unit.Factor);

    const gross = this.event.countAmountGross(
      this.form.value.PriceNet,
      this.form.value.TaxCode
    );
    this.form.controls.PriceGross.setValue(Number(gross));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onTaxCodeChoosed(e) {
    if (e?.length) {
      this.form.controls.TaxCode.setValue(e[0].TaxCode);
      const gross = this.event.countAmountGross(
        this.form.value.PriceNet,
        e[0].TaxRate
      );
      this.form.controls.PriceGross.setValue(Number(gross));
      const perUnitGross = this.event.countAmountGross(
        this.pricePerUnit,
        e[0].TaxRate
      );
      this.pricePerUnit = Number(perUnitGross);
      if (this.isCurrencyDocument) {
        const grossCurrency = this.event.countAmountGross(
          this.form.value.PriceNetCurrency,
          e[0].TaxRate
        );
        this.form.controls.PriceGrossCurrency.setValue(Number(grossCurrency));
        const perUnitGrossCurrency = this.event.countAmountGross(
          this.pricePerUnitNetCurrency,
          e[0].TaxRate
        );
        this.pricePerUnitCurrency = Number(perUnitGrossCurrency);
      }
      this.countDiscounts();
      this.countTotalAmounts();
    }
  }

  onPriceChoosed(e) {
    if (!e) {
      return;
    }

    if (e.length > 0) {
      this.pricePerUnitNet = e[0].NetPrice;
      this.pricePerUnit = e[0].GrossPrice;
      this.priceName = e[0].PriceName;
      this.priceId = e[0].PriceId;
    }

    this.PriceDescription = e[0].Description;

    const priceGross =
      this.pricePerUnit * (this.form.value.Amount / this.positionAmount);
    this.form.controls.PriceGross.setValue(Number(priceGross.toFixed(4)));
    const priceNet = this.event.countAmountNet(
      this.form.value.PriceGross,
      Number(this.form.value.TaxCode)
    );
    this.form.controls.PriceNet.setValue(Number(priceNet));

    if (this.isCurrencyDocument) {
      const priceGrossCurrency =
        this.pricePerUnitCurrency * this.form.value.Factor;
      this.form.controls.PriceGrossCurrency.setValue(
        Number(priceGrossCurrency.toFixed(4))
      );
      const priceNetCurrency = this.event.countAmountNet(
        this.form.value.PriceGrossCurrency,
        Number(this.form.value.TaxCode)
      );
      this.form.controls.PriceNetCurrency.setValue(Number(priceNetCurrency));
    }
    this.countDiscounts();
    this.countTotalAmounts();
  }

  focusedUnit: IUnit = null;
  onFocusedRowChanged = (e) => {
    this.focusedUnit = e.row.data;
  };

  isGridBoxOpened: boolean = false;

  onRowDblClick = (e) => {
    this.unitId = e.data.UnitId;
    this.unit = this.getUnit();
    this.isUnitDivisible = this.unit.IsDivisible;
    this.form.controls.Amount.setValue(this.positionAmount / e.data.Factor);
    this.isGridBoxOpened = false;
  };

  getUnit() {
    return this.articleDetail.Units.find((el) => el.UnitId == this.unitId);
  }

  onAmountChanged(e) {
    if (!e.event) {
      return;
    }
    const newPositionAmount: number = e.value * this.unit.Factor;

    this.positionAmount = Number(newPositionAmount.toFixed(4));
    this.countTotalAmounts();

    if (this.form.value.OfferDocumentPositionId === 0) {
      // nowa pozycja
      this.findSocketAndRun('XGOPDILZ2');
    } else {
      // poprawa pozycji
      this.findSocketAndRun('XGOPPILZ2');
    }
  }

  onAmountFactorChanged(e) {
    if (!e.event) {
      return;
    }
    const newAmount: number = e.value / this.unit.Factor;

    this.form.controls.Amount.setValue(Number(newAmount.toFixed(4)));

    this.countTotalAmounts();
  }

  onDiscountChanged(e) {
    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }
    this.countDiscounts();
    this.countTotalAmounts();
  }

  countDiscounts() {
    const amountNet: number =
      this.form.value.PriceNet +
      (this.form.value.Discount / 100) * this.form.value.PriceNet;
    const amountGross: number =
      this.form.value.PriceGross +
      (this.form.value.Discount / 100) * this.form.value.PriceGross;

    this.valueWithDiscount = Number(amountGross.toFixed(4));
    this.valueNetWithDiscount = Number(amountNet.toFixed(4));

    if (this.isCurrencyDocument) {
      const amountNetCurrency: number =
        this.form.value.PriceNetCurrency +
        (this.form.value.Discount / 100) * this.form.value.PriceNetCurrency;
      const amountGrossCurrency: number =
        this.form.value.PriceGrossCurrency +
        (this.form.value.Discount / 100) * this.form.value.PriceGrossCurrency;

      this.PriceGrossCurrencyWithDiscount = Number(
        amountGrossCurrency.toFixed(4)
      );
      this.PriceNetCurrencyWithDiscount = Number(amountNetCurrency.toFixed(4));
    }
  }

  countTotalAmounts() {
    const amountNet: number =
      this.valueNetWithDiscount * this.form.value.Amount;
    this.form.controls.TotalNetAmount.setValue(Number(amountNet.toFixed(4)));

    const amountGross: number = this.valueWithDiscount * this.form.value.Amount;
    this.form.controls.TotalGrossAmount.setValue(
      Number(amountGross.toFixed(4))
    );

    if (this.isCurrencyDocument) {
      const amountNetCurrency: number =
        this.PriceNetCurrencyWithDiscount * this.form.value.Amount;
      this.form.controls.TotalNetAmountCurrency.setValue(
        Number(amountNetCurrency.toFixed(4))
      );

      const amountGrossCurrency: number =
        this.PriceGrossCurrencyWithDiscount * this.form.value.Amount;
      this.form.controls.TotalGrossAmountCurrency.setValue(
        Number(amountGrossCurrency.toFixed(4))
      );
    }
  }

  countAmountPerUnit() {
    const perUnitNet = Number(
      (this.form.value.PriceNet / this.unit.Factor).toFixed(4)
    );
    this.pricePerUnitNet = perUnitNet;
    const gross = this.event.countAmountGross(
      perUnitNet,
      this.form.value.TaxCode
    );
    this.pricePerUnit = Number(gross);

    if (this.isCurrencyDocument) {
      const perUnitNetCurrency = Number(
        (this.form.value.PriceNetCurrency / this.unit.Factor).toFixed(4)
      );
      this.pricePerUnitNetCurrency = perUnitNetCurrency;

      const grossCurrency = this.event.countAmountGross(
        perUnitNetCurrency,
        Number(this.form.value.TaxCode)
      );
      this.pricePerUnitCurrency = Number(grossCurrency);
    }
  }

  onValueGrossChanged(e) {
    if (!e.event) {
      return;
    }

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    const net = this.event.countAmountNet(
      e.value,
      Number(this.form.value.TaxCode)
    );
    this.form.controls.PriceNet.setValue(Number(net));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onValueNetChanged(e) {
    if (!e.event) {
      return;
    }

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    const gross = this.event.countAmountGross(e.value, this.form.value.TaxCode);

    this.form.controls.PriceGross.setValue(Number(gross));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onPricePerUnitChanged(e) {
    if (!e.event) {
      return;
    }

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }
    this.form.controls.PriceNet.setValue(
      Number((e.value * this.unit.Factor).toFixed(4))
    );

    this.pricePerUnit = Number(
      this.event.countAmountGross(this.pricePerUnitNet, this.form.value.TaxCode)
    );

    const gross = this.event.countAmountGross(
      this.form.value.PriceNet,
      this.form.value.TaxCode
    );
    this.PriceDescription = this.translate.instant(
      'form-commercial-operation.modifiedByHand'
    );
    this.form.controls.PriceGross.setValue(Number(gross));

    this.countDiscounts();
    this.countTotalAmounts();
  }

  positionAmount: number = 1;
  isDefaultMarkupVisible: boolean = false;

  setDefaultDiscountMarkup() {
    this.isDefaultMarkupVisible = true;
  }

  onDefaultMarkupChoosed() {
    this.isDefaultMarkupVisible = false;
    // this.discountValue = e.discountMarkupPercent
    // this.setDiscountMarkupConfig()
  }

  visibleUserExtensions: boolean = false;

  onInsertedUserExtensions = (e) => {
    this.form.patchValue(e);
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
  };

  isPriceVisible: boolean = false;
  priceName: string = '';

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.ParentObject) {
      e.updatedParameters.ParentObject =
        this.nestService.removeNonEditableVariables(
          e.updatedParameters.ParentObject,
          e.SocketCode,
          true
        );
      this.nestParentUpdated.emit(e.updatedParameters?.ParentObject);
    }
    if (e.updatedParameters?.Object) {
      e.updatedParameters.Object = this.nestService.removeNonEditableVariables(
        e.updatedParameters.Object,
        e.SocketCode
      );
      this.form.patchValue(e.updatedParameters?.Object, { emitEvent: false });
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      // dodawanie lub aktualizacja pozycji
      case 'XGOPDZ1':
        this.findSocketAndRun('XGOPAZ1');
        break;
      case 'XGOPPZ1':
        this.findSocketAndRun('XGOPAZ1');
        break;
      case 'XGOPAZ1': // zatwierdzenie nowa lub poprawiona pozycja
        this.saveItemNest();
        break;
      case 'XGOPDO1':
        this.findSocketAndRun('XGOPAO1', 'add');
        break;
      case 'XGOPAO1':
        this.openNestFinished = true;
        if (this.nestOperation === 'add') {
          this.getArticleDetail(null);
        } else if (this.nestOperation === 'edit') {
          this.getOldPosition();
        }
        break;
      case 'XGOPPO1':
        this.findSocketAndRun('XGOPAO1', 'edit');
        break;
      case 'XGOPDO2':
        this.findSocketAndRun('XGOPAO2', 'add');
        break;
      case 'XGOPPO2':
        this.findSocketAndRun('XGOPAO2', 'edit');
        break;
      case 'XGOPDA1':
        this.findSocketAndRun('XGOPAA1', 'add');
        break;
      case 'XGOPAA1':
        if (this.nestOperation === 'add') {
          this.findSocketAndRun('XGOPDA2');
        } else if (this.nestOperation === 'edit') {
          this.findSocketAndRun('XGOPPA2');
        }
        break;
      case 'XGOPDA2':
        this.findSocketAndRun('XGOPAA2');
        break;
      case 'XGOPPA2':
        this.findSocketAndRun('XGOPAA2');
        break;
      case 'XGOPAA2':
        this.onClosing.emit(true);
        setTimeout(() => {
          this.openNestFinished = false;
        }, 500);
        break;
      case 'XGOPPA1':
        this.findSocketAndRun('XGOPAA1', 'edit');
        break;
      case 'XGOPDZ2':
        this.findSocketAndRun('XGOPAZ2');
        break;
      case 'XGOPPZ2':
        this.findSocketAndRun('XGOPAZ2');
        break;
      case 'XGOPAZ2':
        this.isVisible = false;
        this.onAdding.emit(this.savedResponse);
        setTimeout(() => {
          this.openNestFinished = false;
        }, 500);
        break;
      case 'XGOPDILZ2':
        this.findSocketAndRun('XGOPAILZ2');
        break;
      case 'XGOPPILZ2':
        this.findSocketAndRun('XGOPAILZ2');
        break;
      default:
        break;
    }
  }

  nestOperation: NestOperation = null;
  findSocketAndRun(SocketCode: string, nestOperation: NestOperation = null) {
    this.nestObjectParameters = Object.assign({}, this.form.value);
    this.nestOperation = nestOperation;
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  isBlockedAccordion = false;
  onAccordionSelectionChanged(e) {
    if (this.isBlockedAccordion) return;
    this.isBlockedAccordion = true;
    this.updateAdvancedParameter(!!e.addedItems.length);
  }

  updateAdvancedParameter(value: boolean) {
    this.appService
      .postAuth(`configurations/users/currentUser/config`, [
        {
          FieldCode: 'FormPozOfe2',
          Value: value ? 'z cenami' : 'bez cen',
        },
      ])
      .subscribe({
        next: () => {},
        error: () => {},
        complete: () => {
          this.isBlockedAccordion = false;
        },
      });
  }

  chooseUnit = () => {
    if (this.focusedUnit != null) {
      this.form.controls.Factor.setValue(this.focusedUnit.Factor);
      this.form.controls.Unit.setValue(this.focusedUnit.Code);
      this.positionAmount = Number(
        (this.form.value.Amount * this.focusedUnit.Factor).toFixed(4)
      );
      this.form.controls.PriceNet.setValue(
        this.pricePerUnitNet * this.focusedUnit.Factor
      );

      const gross = this.event.countAmountGross(
        this.form.value.PriceNet,
        this.form.value.TaxCode
      );
      this.form.controls.PriceGross.setValue(Number(gross));

      this.countAmountPerUnit();
      this.countDiscounts();
      this.countTotalAmounts();
      this.isGridBoxOpened = false;
      this.unitId = this.focusedUnit.UnitId;
    }
  };

  chooseUnitAndQuantity = () => {
    if (this.focusedUnit != null) {
      this.form.controls.Factor.setValue(this.focusedUnit.Factor);
      this.form.controls.Unit.setValue(this.focusedUnit.Code);
      //this.positionAmount = Number((this.form.value.Amount * this.focusedUnit.Factor).toFixed(4))
      this.form.controls.Amount.setValue(
        this.positionAmount / this.focusedUnit.Factor
      ); //e.value / this.unit.Factor
      this.form.controls.PriceNet.setValue(
        this.pricePerUnitNet * this.focusedUnit.Factor
      );

      const gross = this.event.countAmountGross(
        this.form.value.PriceNet,
        this.form.value.TaxCode
      );
      this.form.controls.PriceGross.setValue(Number(gross));

      this.countAmountPerUnit();
      this.countDiscounts();
      this.countTotalAmounts();
      this.isGridBoxOpened = false;
      this.unitId = this.focusedUnit.UnitId;
    }
  };

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGO');
    }
  }

  onValueNetCurrencyChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }
    const grossCurrency = this.event.countAmountGross(
      e.value,
      Number(this.form.value.TaxCode)
    );

    this.form.controls.PriceGrossCurrency.setValue(Number(grossCurrency));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onValueGrossCurrencyChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    const net = this.event.countAmountNet(
      e.value,
      Number(this.form.value.TaxCode)
    );
    this.form.controls.PriceNetCurrency.setValue(Number(net));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onPricePerUnitCurrencyChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    this.form.controls.PriceNetCurrency.setValue(
      Number((e.value * this.getUnit().Factor).toFixed(4))
    );

    this.pricePerUnitCurrency = Number(
      this.event.countAmountGross(
        this.pricePerUnitNetCurrency,
        Number(this.form.value.TaxCode)
      )
    );

    const gross = this.event.countAmountGross(
      this.form.controls.PriceNetCurrency.value,
      Number(this.form.value.TaxCode)
    );
    this.PriceDescription = this.translate.instant(
      'form-commercial-operation.modifiedByHand'
    );
    this.form.controls.PriceGrossCurrency.setValue(Number(gross));

    this.countDiscounts();
    this.countTotalAmounts();
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ArticlesService } from 'src/app/libraries/articles/articles.service';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';
import { HelpService } from '../../../../help-service.service';

@Component({
    selector: 'app-new-position',
    templateUrl: './new-position.component.html',
    styleUrls: ['./new-position.component.scss'],
    inputs: ['isVisible', 'selectedId', 'title', 'selected', 'documentType'],
    standalone: false
})
export class NewPositionComponent implements OnInit {
  @Input() isDuplicat: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('StockLeftInput') StockLeftInput;

  title;
  isVisible;
  visibleShortcut;
  selectedId;
  selected;
  documentType;
  widthWindow = 480;
  heightWindow = 500;
  selectedtab: number = 0;
  items = [1];
  unicalGuid;

  submitted: boolean = false;
  form;

  format = '#0.00';
  shortcuts: ShortcutInput[] = [];
  isArticle: boolean = false;
  productData;
  RemWRozbNaNrSerii;
  visibleUserExtensions: boolean = false;
  taxRate;
  isFirstLoad: boolean = true;
  ProductId;

  articlesService = inject(ArticlesService);

  isSupply: boolean = false;
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private helpService: HelpService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.RemWRozbNaNrSerii = this.event.getConfigurationParameter(
      'RemWRozbNaNrSerii',
      true
    );

    if (this.RemWRozbNaNrSerii.Value == 'Tak') {
      this.items = [1, 2];
    } else this.items = [1];
  }

  ngOnInit(): void {
    this.createForm();
  }

  getSupply() {
    this.appService
      .getAuth(
        `products/${this.selected[0].ProductId}/availableSupply?skip=0&take=3`
      )
      .subscribe((res) => {
        if (res.data.length != 0) this.isSupply = true;
        else this.isSupply = false;
      });
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      BaseUnit: [null],
      BaseUnitId: [0],
      BuyPriceGross: [0],
      BuyPriceNet: [0],
      CategoryId: [0],
      CategoryTreeId: [0],
      DeliveryDocumentNumber: [''],
      WarehouseDocumentId: [0],
      ExpirationDate: [''],
      Factor: [0],
      IndexCatalogue: [''],
      IndexProducer: [null],
      IndexTrading: [''],
      InventoryPostionDate: [''],
      InventoryPostionId: [0],
      IsFinished: [false],
      IsSelected: [false],
      Location: [''],
      ProductFullName: [''],
      ProductId: [0],
      ProductName1: [''],
      ProductName2: [''],
      ProductType: [''],
      SerialNumber: [''],
      StockAmount: [0],
      StockLeft: [0, Validators.required],
      Unit: [''],
      WarehouseId: [0],
      NetValue: [0],
      GrossValue: [0],
      NetValueAfter: [0],
      GrossValueAfter: [0],
    });
  };

  ngOnChanges() {
    if (this.selected && this.isVisible) {
      this.getDataProduct();
      this.getSupply();
      setTimeout(() => {
        this.form.patchValue(this.selected[0]);
        this.cd.detectChanges();
        this.form.controls.NetValue.setValue(
          this.form.value.BuyPriceNet * this.form.value.StockAmount
        );
        this.form.controls.GrossValue.setValue(
          this.form.value.BuyPriceGross * this.form.value.StockAmount
        );

        this.form.controls.NetValueAfter.setValue(
          this.form.value.BuyPriceNet * this.form.value.StockLeft
        );
        this.form.controls.GrossValueAfter.setValue(
          this.form.value.BuyPriceGross * this.form.value.StockLeft
        );
        this.ProductId = [this.form.value.ProductId];
      }, 0);
    }
  }

  myTimerNetto;
  onValueNetChange = () => {
    if (this.myTimerNetto) {
      clearTimeout(this.myTimerNetto);
    }

    this.myTimerNetto = setTimeout(() => {
      this.form.controls.BuyPriceGross.setValue(
        this.event.countAmountGross(this.form.value.BuyPriceNet, this.taxRate)
      );
    }, 100);
  };

  myTimer;
  onValueGrossChange = () => {
    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }

    this.myTimer = setTimeout(() => {
      this.form.controls.BuyPriceNet.setValue(
        this.event.countAmountNet(this.form.value.BuyPriceGross, this.taxRate)
      );
      this.isFirstLoad = false;
    }, 100);
  };

  getDataProduct = () => {
    this.appService
      .getAuth(`products?ObjectId=${this.selected[0].ProductId}`)
      .subscribe((res) => {
        this.productData = res.data;
        this.taxRate = Number(res.data[0].BuyTaxCode);
      });
  };

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.documentType === 'OB') {
            this.helpService.openHelp(
              'sprzedaz-i-magazyn/dokumenty/remanent/bilans'
            );
          } else {
            this.helpService.openHelp(
              'sprzedaz-i-magazyn/dokumenty/remanent/remanent-dzialanie'
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'F2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.prepareForModify();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + p',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.visibleUserExtensions = true;
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.items.length == 2) {
            this.selectedtab = 1;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClose();
        },
        preventDefault: true,
      }
    );
  }

  onClose = () => {
    this.StockLeftInput?.instance?.blur();
    this.submitted = false;
    this.isVisible = false;
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onValueChanged = (e) => {
    try {
      this.form.controls.NetValueAfter.setValue(
        this.form.value.BuyPriceNet * e.value
      );
      this.form.controls.GrossValueAfter.setValue(
        this.form.value.BuyPriceGross * e.value
      );
    } catch {}
  };

  onSave = () => {
    this.submitted = true;
    if (this.form.valid) {
      this.onInserted.emit(this.form.value);
      this.isVisible = false;
      this.appService
        .postAuth(`products`, this.productData[0])
        .subscribe(() => {});
    }
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  onInsertedUserExtensions = (e) => {
    try {
      this.productData[0].Field1 = e['Field1'];
      this.productData[0].Field2 = e['Field2'];
      this.productData[0].Field3 = e['Field3'];
      this.productData[0].Field4 = e['Field4'];
      this.productData[0].Field5 = e['Field5'];
      this.productData[0].Field6 = e['Field6'];
      this.productData[0].Field7 = e['Field7'];
      this.productData[0].Field8 = e['Field8'];
      this.productData[0].Field9 = e['Field9'];
      this.productData[0].Field10 = e['Field10'];
    } catch {}
  };

  prepareForModify() {
    this.articlesService.prepareForModify(this.ProductId).subscribe({
      next: () => {
        this.isArticle = true;
      },
      error: () => {},
    });
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { AppServices } from "../../../app-services.service";
import { EventService } from "../../../event.service";

@Component({
    selector: "app-verification-form-payment",
    templateUrl: "./verification-form-payment.component.html",
    styleUrls: ["./verification-form-payment.component.scss"],
    inputs: ["readOnly", "isVisible", "title", "dataSource"],
    standalone: false
})
export class VerificationFormPaymentComponent implements OnInit, AfterViewInit {
  @Output() onClosing = new EventEmitter();
  @Output() onChoosedForm = new EventEmitter();
  readOnly;
  isVisible;
  title;

  unicalGuid;
  widthWindow = 700;
  heightWindow = 400;

  shortcuts: ShortcutInput[] = [];

  dataSource;
  selectedRowKeys: any[] = [];
  focusedSelected: any[] = [];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: "escape",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: "F10",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  closeWindow() {
    this.isVisible = false;
    this.onClosing.emit(true);
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  onRowDblClick = () => {
    if (!this.readOnly) this.onSave();
  };

  onSave = () => {
    if (this.focusedSelected.length > 0) {
      this.onChoosedForm.emit(this.focusedSelected);
      return;
    }

    this.event.showNotification(
      "info",
      this.translate.instant("form-commercial-operation.chooseFormOfPayment")
    );
  };
}

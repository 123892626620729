<ng-container>
  <dx-drop-down-box
    #dropDownBox
    [(value)]="choosingValue"
    valueExpr="DeliveryOperatorId"
    [deferRendering]="false"
    displayExpr="Name"
    [(opened)]="isGridBoxOpened"
    [dataSource]="dataSource"
    [showClearButton]="true"
    [width]="276"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="onOpenedChanged($event)"
    (keydown.space)="isGridBoxOpened = true"
    [ngClass]="{
      'input-required': className
    }"
    [readOnly]="readOnly"
  >
    <div
      *dxTemplate="let data of 'content'"
      [ngStyle]="{ height: event.deviceType != 'mobile' ? '280px' : '290px' }"
    >
      <div
        class="col-md-12"
        class="no-padding"
        style="width: calc(100% + 30px); margin-left: -20px"
        *ngIf="isGridBoxOpened"
      >
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="true"
          [showBorders]="true"
          shortcut
          [height]="250"
          [(selectedRowKeys)]="selectedRow"
          [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
          [columnResizingMode]="'widget'"
          [remoteOperations]="false"
          (onRowClick)="onRowDblClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onKeyDown)="onKeyDown($event)"
          [focusedRowEnabled]="true"
          #gridRef
          [hoverStateEnabled]="true"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="200"></dxo-paging>
          <dxo-selection
            [mode]="dropDownBoxMode ? 'single' : 'multiple'"
            showCheckBoxesMode="always"
          >
          </dxo-selection>
          <dxi-column
            [width]="event.deviceType != 'mobile' ? 45 : 10"
            cellTemplate="editTemplate"
            [allowSorting]="false"
            alignment="center"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'formPayment.operator' | translate }}"
            dataField="Name"
            [allowSorting]="false"
          ></dxi-column>

          <!-- <dxi-column
            caption="Url"
            dataField="Url"
            [allowSorting]="false"
          ></dxi-column> -->

          <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
            <dx-button
              class="btn-dx-top-menu-folder-grid"
              icon="icon absui-icon--mode-edit hover-edit-icon"
              [disabled]="readOnly"
              (onClick)="editFromTemplate($event)"
            >
            </dx-button>
          </div>
        </dx-data-grid>
      </div>
      <div
        class="bottom-drop-box"
        style="margin-left: -20px; width: calc(100% + 40px)"
      >
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--add-circle"
          [id]="'add' + unicalGuid"
          [text]="'buttons.add' | translate"
          [disabled]="readOnly"
          (onClick)="setSingleRecordVisible('add')"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          (click)="refreshData()"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }}
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-refresh' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "refresh" | translate }}
          </div>
        </dx-tooltip>
      </div>
    </div>
  </dx-drop-down-box>
</ng-container>

<app-add-orders-delivery-operator
  *ngIf="isSingleRecordVisible"
  [selectedId]="selectedRow"
  [mode]="singleRecordMode"
  (onInserted)="onInserted()"
  (onClosing)="onSingleRecordClosing()"
>
</app-add-orders-delivery-operator>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DxAccordionModule } from 'devextreme-angular/ui/accordion';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxContextMenuModule } from 'devextreme-angular/ui/context-menu';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { DxDateBoxModule } from 'devextreme-angular/ui/date-box';
import { DxDropDownBoxModule } from 'devextreme-angular/ui/drop-down-box';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { DxFileUploaderModule } from 'devextreme-angular/ui/file-uploader';
import { DxHtmlEditorModule } from 'devextreme-angular/ui/html-editor';
import { DxListModule } from 'devextreme-angular/ui/list';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxRadioGroupModule } from 'devextreme-angular/ui/radio-group';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DxSwitchModule } from 'devextreme-angular/ui/switch';
import { DxTabPanelModule } from 'devextreme-angular/ui/tab-panel';
import { DxTabsModule } from 'devextreme-angular/ui/tabs';
import { DxTextAreaModule } from 'devextreme-angular/ui/text-area';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';
import { DxTreeViewModule } from 'devextreme-angular/ui/tree-view';
import { DragulaModule } from 'ng2-dragula';
import { LibrariesModule } from '../libraries/libraries.module';
import { UsersModule } from './../users/users.module';
import { routing } from './crm-routing';
import { CreatePriceListForCustomerComponent } from './offers/new-offer/create-price-list-for-customer/create-price-list-for-customer.component';
import { OfferPositionDescriptionComponent } from './offers/new-offer/offer-position-description/offer-position-description.component';
import { SumOffersComponent } from './offers/sum-offers/sum-offers.component';
import { AddComplaintItemsComponent } from './orders/add-complaint-items/add-complaint-items.component';
import { OrdersGroupStatusChangeComponent } from './orders/orders-group-status-change/orders-group-status-change.component';

import { CoreModule } from '../core/core.module';
import { LabelModule } from '../label/label.module';

import { ResizableModule } from 'angular-resizable-element';
import { DxTooltipModule } from 'devextreme-angular';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

import { DxSchedulerModule } from 'devextreme-angular/ui/scheduler';
import { PipesModule } from '../pipes/pipes.module';
import { UserExtensionModule } from '../user-extension/user-extension.module';

import { DxDraggableModule, DxMenuModule } from 'devextreme-angular';
import { DxProgressBarModule } from 'devextreme-angular/ui/progress-bar';
import { QuicklinkModule } from 'ngx-quicklink';
import { WarehouseNewDocumentModule } from '../warehouse/warehouse-new-document.module';
import { AmountFormatterPipe } from './../pipes/amountFormatter.pipe';
import { ContainsCourierPipe } from './crm-deliveries/add-crm-delivery/containsCourierPipe';

import { NewOfferComponent } from './offers/new-offer/new-offer.component';
import { OfferPositionComponent } from './offers/new-offer/offer-position/offer-position.component';
import { OfferStatusHistoryComponent } from './offers/new-offer/offer-status-history/offer-status-history.component';
import { OffersStatusComponent } from './offers/offers-status/offers-status.component';
import { OffersComponent } from './offers/offers.component';
import { OrdersKanbanComponent } from './orders-kanban/orders-kanban.component';
import { AddOrdersServiceComponent } from './orders/add-orders-service/add-orders-service.component';
import { ConnectServiceToDocumentComponent } from './orders/add-orders-service/connect-service-to-document/connect-service-to-document.component';
import { OrdersServiceAddDeliveryAddressComponent } from './orders/add-orders-service/orders-service-delivery-address/orders-service-add-delivery-address/orders-service-add-delivery-address.component';
import { OrdersServiceDeliveryAddressComponent } from './orders/add-orders-service/orders-service-delivery-address/orders-service-delivery-address.component';
import { JobCostComponent } from './orders/job-cost/job-cost.component';
import { AddOrderTypeComponent } from './orders/order-type/add-order-type/add-order-type.component';
import { OrderTypeComponent } from './orders/order-type/order-type.component';
import { OrdersComponent } from './orders/orders.component';
import { ProfitsComponent } from './orders/profits/profits.component';
import { CalendarSettingsComponent } from './task-calendar/calendar-settings/calendar-settings.component';
import { TaskCalendarComponent } from './task-calendar/task-calendar.component';
import { TaskCanbanComponent } from './task-canban/task-canban.component';
import { TasksComponent } from './tasks/tasks.component';
import { ConfirmDialogComponent } from '../core/confirm-dialog/confirm-dialog.component';
import { BtnExportExcelComponent } from '../menu-wapro/btn-export-excel/btn-export-excel.component';
import { NgShortcutsComponent } from '../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { ExpansionListComponent } from '../expansion-slote/expansion-list/expansion-list.component';
import { MobileListComponent } from '../core/mobile-list/mobile-list.component';
import { CustomChipsButtonComponent } from '../core/custom-chips-button/custom-chips-button.component';
import { CustomDropdownBoxComponent } from '../core/custom-dropdown-box/custom-dropdown-box.component';
import { TableDataUserExtensionComponent } from '../core/table-user-extension/table-data-user-extension/table-data-user-extension.component';
import { TableUserExtensionComponent } from '../core/table-user-extension/table-user-extension.component';
import { WaproDateBoxComponent } from '../core/wapro-date-box/wapro-date-box.component';
import { NestComponent } from 'src/app/nest/nest.component';
import { EmailAttachmentComponent } from '../crm/email/email-attachment/email-attachment.component';
import { StatusLabelComponent } from '../core/status-label/status-label.component';
import { OfferMarginOverheadComponent } from './offers/new-offer/offer-margin-overhead/offer-margin-overhead.component';
import { LabelComponent } from '../core/label/label.component';
import { PreventRightClickDirective } from '../core/directives/prevent-right-click.directive';
import { RecordCardComponent } from '../libraries/record-card/record-card.component';
import { NotesComponent } from '../core/notes/notes.component';
import { EmptyStateGridSvgComponent } from '../../assets/svg/empty-state-grid-svg/empty-state-grid-svg.component';
import { CustomFilterDropdownBoxComponent } from '../core/custom-filter-dropdown-box/custom-filter-dropdown-box.component';

@NgModule({
  declarations: [
    ProfitsComponent,
    OrdersComponent,
    OffersStatusComponent,
    AddOrdersServiceComponent,
    JobCostComponent,
    OrderTypeComponent,
    AddOrderTypeComponent,
    TasksComponent,
    AddComplaintItemsComponent,
    TaskCanbanComponent,
    OrdersKanbanComponent,
    TaskCalendarComponent,
    CalendarSettingsComponent,
    ContainsCourierPipe,
    OffersComponent,
    NewOfferComponent,
    OfferPositionComponent,
    SumOffersComponent,
    OfferPositionDescriptionComponent,
    OrdersGroupStatusChangeComponent,
    ConnectServiceToDocumentComponent,
    CreatePriceListForCustomerComponent,
    OrdersServiceDeliveryAddressComponent,
    OrdersServiceAddDeliveryAddressComponent,
  ],
  imports: [
    CommonModule,
    PipesModule,
    CoreModule,
    QuicklinkModule,
    ConfirmDialogComponent,
    ResizableModule,
    UserExtensionModule,
    LabelModule,
    DxNumberBoxModule,
    ReactiveFormsModule,
    DxContextMenuModule,
    FormsModule,
    DxSchedulerModule,
    TranslateModule,
    DxTextBoxModule,
    DxTreeViewModule,
    DxPopupModule,
    DxRadioGroupModule,
    DxScrollViewModule,
    DxDropDownButtonModule,
    DxDateBoxModule,
    UsersModule,
    DxCheckBoxModule,
    DragulaModule,
    DxButtonModule,
    DxTabsModule,
    DxTabPanelModule,
    DxSelectBoxModule,
    DxDataGridModule,
    LabelComponent,
    DxSwitchModule,
    DxDropDownBoxModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    routing,
    DxProgressBarModule,
    DxTextAreaModule,
    DxTooltipModule,
    DxListModule,
    LibrariesModule,
    KeyboardShortcutsModule.forRoot(),
    WarehouseNewDocumentModule,
    ExpansionListComponent,
    DxMenuModule,
    DxFileUploaderModule,
    DxDraggableModule,
    OfferStatusHistoryComponent,
    BtnExportExcelComponent,
    NgShortcutsComponent,
    MobileListComponent,
    CustomChipsButtonComponent,
    WaproDateBoxComponent,
    CustomDropdownBoxComponent,
    TableUserExtensionComponent,
    TableDataUserExtensionComponent,
    NestComponent,
    EmailAttachmentComponent,
    StatusLabelComponent,
    OfferMarginOverheadComponent,
    PreventRightClickDirective,
    RecordCardComponent,
    NotesComponent,
    EmptyStateGridSvgComponent,
    CustomFilterDropdownBoxComponent
  ],
  providers: [ContainsCourierPipe, AmountFormatterPipe],
  exports: [
    NewOfferComponent,
    AddComplaintItemsComponent,
    AddOrdersServiceComponent,
  ],
})
export class CrmModule {}

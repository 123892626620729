import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxDataGridComponent,
  DxDataGridModule,
  DxDropDownBoxComponent,
  DxDropDownBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { EventService } from 'src/app/event.service';
import { TranslateModule } from '@ngx-translate/core';
import { ORDERS_OPERATORS_KEY } from 'src/app/consts';
import { catchError, of, tap } from 'rxjs';
import { AppServices } from 'src/app/app-services.service';
import { SingleRecordMode } from 'src/app/event.model';
import { AddOrdersDeliveryOperatorComponent } from './add-orders-delivery-operator/add-orders-delivery-operator.component';

@Component({
    selector: 'app-orders-delivery-operators',
    imports: [
        CommonModule,
        DxDropDownBoxModule,
        DxDataGridModule,
        DxButtonModule,
        DxTooltipModule,
        TranslateModule,
        AddOrdersDeliveryOperatorComponent,
    ],
    templateUrl: './orders-delivery-operators.component.html',
    styleUrl: './orders-delivery-operators.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersDeliveryOperatorsComponent {
  @Input() selectedId: number = null;
  @Input() className = false;
  @Input() readOnly = false;
  @Output() onChoosed: EventEmitter<any> = new EventEmitter();

  @ViewChild('dropDownBox') dropDownBox: DxDropDownBoxComponent;
  @ViewChild('gridRef') gridRef: DxDataGridComponent;
  cdr = inject(ChangeDetectorRef);
  appService = inject(AppServices);
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  isGridBoxOpened = false;
  dropDownBoxMode = true;
  event = inject(EventService);
  dataSource: any = [];
  choosingValue = [];
  selectedRow = [];

  ngOnInit() {
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.selectedId?.currentValue) {
      this.updateSelectedValue();
    }
  }

  updateSelectedValue(value = null) {
    const newValue = value || this.selectedId;
    if (newValue) {
      this.choosingValue = [newValue];
    } else {
      this.dropDownBox.instance.reset();
      this.choosingValue = null;
      this.selectedRow = [];
    }
  }

  getData() {
    const localData = this.event.getSessionStorageData(ORDERS_OPERATORS_KEY);
    if (localData) {
      this.dataSource = localData;
      this.cdr.detectChanges();
    } else {
      this.appService
        .getAuth(`orders/documents/0/deliveryOperators`)
        .pipe(
          tap((data) => {
            this.event.saveSessionStorageData(ORDERS_OPERATORS_KEY, data);
          }),
          catchError((error) => {
            this.event.httpErrorNotification(error);
            return of([]);
          })
        )
        .subscribe({
          next: (data) => {
            this.dataSource = data;
            this.cdr.detectChanges();
          },
        });
    }
  }

  refreshData() {
    this.event.removeSessionStorageData(ORDERS_OPERATORS_KEY);
    this.getData();
  }

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRow = [];
      this.onChoosed.emit(null);
    }
  };

  onOpenedChanged = (e) => {
    if (e) {
      setTimeout(() => {
        this.gridRef.instance.focus();
      }, 300);
    }
  };

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode) {
      // if (this.doubleClickResult === 'Edycja' && this.perABD.editBtn) {
      //   this.setSingleRecordVisible('edit');
      // } else {
      //   this.setSingleRecordVisible('show');
      // }
    } else this.onChoosingRow();
  };

  onChoosingRow() {
    if (!this.readOnly) {
      this.choosingValue = this.selectedRow[0];
      this.onChoosed.emit(this.choosingValue);
      this.isGridBoxOpened = false;
    }
  }

  onFocusedRowChanged = (e) => {
    if (!e?.row?.data) return;

    this.selectedRow = [e.row.data.DeliveryOperatorId];
  };

  onKeyDown = (e) => {
    if (e?.event?.originalEvent?.code === 'Enter') this.onChoosingRow();
  };

  isSingleRecordVisible = false;
  singleRecordMode: SingleRecordMode = null;

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  editFromTemplate(e) {
    e.event.stopPropagation();
    this.setSingleRecordVisible('edit');
  }

  onSingleRecordClosing() {
    this.isSingleRecordVisible = false;
    this.gridRef.instance.focus();
    this.singleRecordMode = null;
  }

  onInserted = () => {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    this.refreshData();
  };
}

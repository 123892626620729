import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { NestService } from 'src/app/nest-service.service';
import { Socket } from 'src/app/socket.model';
import { environment } from '../../../../environments/environment';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { ICustomSearchItem } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.model';
import { SingleRecordMode } from 'src/app/event.model';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { DocumentsOrdersService } from 'src/app/warehouse/documents-orders/documents-orders.service';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-order-realization',
    templateUrl: './order-realization.component.html',
    styleUrls: ['./order-realization.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'readOnly',
        'customerId',
        'invoiceDocumentId',
        'customerName',
        'DateOfInvoiceDocument',
        'SumBy',
        'document',
        'productionDocumentsEditMode',
        'DocumentType',
    ],
    standalone: false
})
export class OrderRealizationComponent implements OnInit, OnChanges {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  @ViewChild('gridOrder') gridOrder;
  documentsOrdersService = inject(DocumentsOrdersService);
  DocumentType;
  isVisible;
  title;
  readOnly;
  unicalGuid;
  invoiceDocumentId;
  customerName;
  customerId;
  DateOfInvoiceDocument;
  widthWindow = '700';
  heightWindow = 600;
  maxHeight;
  maxWidth;
  SumBy;
  productionDocumentsEditMode = false;
  shortcuts: ShortcutInput[] = [];
  dataSource;
  focusedSelectedNoConnected = [];
  warehouseId;
  showRow: boolean = false;
  OrderDocumentId = null;
  filterCriteria: ICustomSearchItem[] = [
    {
      value: 'OrderDocumentDateFrom',
      label: 'Wg daty wystawienia',
      type: 'data',
    },
    { value: 'NumberIntuition', label: 'Wg numeru' },
    {
      value: 'RealizationPlanDateFrom',
      label: 'Wg terminu realizacji',
      type: 'data',
    },
    { value: 'CustomerOrderNumberIntuition', label: 'Wg nr zam. klienta' },
  ];

  filterCriteriaAtachments = [
    { value: null, label: 'wszystkie' },
    {
      value: 'IsPartiallyForRealization',
      label: 'częściowo gotowe do realizacji',
    },
    {
      value: 'IsOnlyFullToRealization',
      label: 'całkowicie gotowe do realizacji',
    },
  ];

  valueCriteria = 'RealizationPlanDateFrom';
  filterValue = '';
  orderby: string = 'RealizationPlanDateFrom';
  order: string = 'ASC'; // asc/desc
  heightGrid: number = 200;

  selectedtabIndex = 0;

  bottomPanelTabs = ['Uwagi', 'Informacje dodatkowe'];
  mode: SingleRecordMode = 'show';

  isStartRealization: boolean = false;

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };

  focusedRowIndex = 0;
  myEventEsc;
  isSprNFZamKon: boolean = false;
  document = 'invoices';
  //nests
  componentNests: Socket;
  openNestFinished = false;
  user = null;
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices,
    private nestService: NestService
  ) {
    this.widthWindow = this.event.setWidthPopUp();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
    this.user = this.event.returnUserId();
    this.warehouseId = this.event.decryptString(
      localStorage.getItem('sessionInfoWaproHtml')
    ).AppWarehouseId;

    this.translate
      .get('comments')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));

    this.translate
      .get('crmDeliveries.additionalInformation')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));

    this.translate
      .get('orderHistory.byOrderDate')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('orderHistory.byNr')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('orderHistory.byRealisationDate')
      .subscribe((text) => (this.filterCriteria[2].label = text));
    this.translate
      .get('orderHistory.byCustomerOrderNr')
      .subscribe((text) => (this.filterCriteria[3].label = text));

    this.translate
      .get('all')
      .subscribe((text) => (this.filterCriteriaAtachments[0].label = text));
    this.translate
      .get('form-commercial-operation.partiallyReadyForImplementation')
      .subscribe((text) => (this.filterCriteriaAtachments[1].label = text));
    this.translate
      .get('form-commercial-operation.completelyReadyForImplementation')
      .subscribe((text) => (this.filterCriteriaAtachments[2].label = text));

    this.heightGrid = this.heightWindow - 270;

    this.perABD = this.event.checkPermissionsBtn(
      'DZam',
      'EZam',
      'UZam',
      'OZam'
    );

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (this.isVisible && !this.showRow && !this.isStartRealization) {
        this.findSocketAndRun('XGZWOAA1');
      }
    });

    setTimeout(() => {
      this.checkSprNFZamKon();
    }, 0);
  }

  async checkSprNFZamKon() {
    const SprNFZamKon = await this.event.getHiddenParameter(
      this.document === 'invoices' ? 'SprNFZamKon' : 'SprNMZamKon'
    );

    setTimeout(() => {
      SprNFZamKon?.value == '1'
        ? (this.isSprNFZamKon = true)
        : (this.isSprNFZamKon = false);
    }, 0);
  }

  onChangedSprNFZZamKon = (e) => {
    if (e.previousValue === null) return;
    let object = {
      code: this.document === 'invoices' ? 'SprNFZamKon' : 'SprNMZamKon',
      value: e.value ? '1' : '0',
    };
    this.event.saveHiddenParameter(object);
  };

  //-------------------------------------------
  isSprGotZamRlz: boolean = false;
  customValue = '';
  isOpenMoreBtn: boolean = false;

  async checkSprGotZamRlz() {
    const SprGotZamRlz = await this.event.getHiddenParameter('SprGotZamRlz');
    setTimeout(() => {
      SprGotZamRlz?.value == '1'
        ? (this.isSprGotZamRlz = true)
        : (this.isSprGotZamRlz = false);
      if (this.isSprGotZamRlz) {
        this.customValue = this.filterCriteriaAtachments.find(
          (field) => field.value == 'IsPartiallyForRealization'
        )?.label;
        this.orderStatus = 'IsPartiallyForRealization';
        this.getOrders();
      }
    }, 0);
  }

  onSprGotZamRlz = (e) => {
    let object = {
      code: 'SprGotZamRlz',
      value: e.value ? '1' : '0',
    };
    this.event.saveHiddenParameter(object);
  };

  //---------------------------------------------

  isSprGotCaleZamRlz: boolean = false;

  async checkSprGotCaleZamRlz() {
    const SprGotCaleZamRlz = await this.event.getHiddenParameter(
      'SprGotCaleZamRlz'
    );
    setTimeout(() => {
      SprGotCaleZamRlz?.value == '1'
        ? (this.isSprGotCaleZamRlz = true)
        : (this.isSprGotCaleZamRlz = false);
      if (this.isSprGotCaleZamRlz) {
        this.customValue = this.filterCriteriaAtachments.find(
          (field) => field.value == 'IsOnlyFullToRealization'
        )?.label;
        this.orderStatus = 'IsOnlyFullToRealization';
        this.getOrders();
      }
    }, 0);
  }

  onSprGotCaleZamRlz = (e) => {
    let object = {
      code: 'SprGotCaleZamRlz',
      value: e.value ? '1' : '0',
    };
    this.event.saveHiddenParameter(object);
  };

  //---------------------------------------------

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // pobranie gniazd jak nie ma i jest popup/dropdown
    if (!this.componentNests || !this.componentNests.Id) {
      // jak nie ma
      this.componentNests = this.nestService.getNestBySocketCode('XGZ');
    }

    if (changes?.isVisible?.currentValue) {
      this.findSocketAndRun('XGZWOAO1');
    }
  }

  onRowDblClick = () => {
    let object: any = {};
    if (this.document == 'warehouseDocuments') {
      object = {
        WarehouseDocumentId: this.invoiceDocumentId,
        OrderDocumentId: this.focusedSelectedNoConnected[0].OrderDocumentId,
      };
    } else {
      object = {
        InvoiceDocumentId: this.invoiceDocumentId,
        OrderDocumentId: this.focusedSelectedNoConnected[0].OrderDocumentId,
      };
    }

    let url = this.document;
    if (this.document == 'warehouseDocuments') {
      url += '/realization';
    } else {
      url += '/documents';
    }

    this.appService
      .putAuth(`${url}/${this.invoiceDocumentId}/addStartRealization`, object)
      .subscribe(
        () => {
          this.isStartRealization = true;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGZWOAW2');
        }
      );
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.findSocketAndRun('XGZWOAA1');
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly || this.focusedSelectedNoConnected.length > 0) {
            this.addStartRealization();
          }
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    this.myEventEsc?.unsubscribe();

    this.event.onHiddenPopUp(this.unicalGuid);
  }

  refreshPosition = (e) => {
    e.AdvancePayment = this.focusedSelectedNoConnected[0].AdvancePayment;
    e.StateFlag = this.focusedSelectedNoConnected[0].StateFlag;
    e.RealizationState = this.focusedSelectedNoConnected[0].RealizationState;
    e.Remarks = this.focusedSelectedNoConnected[0].Remarks;
    this.findSocketAndRun('XGZWOAA1');
    this.onInserted.emit(e);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
    this.cd.detectChanges();
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
      this.selectedtabIndex = 0;
      this.focusedSelectedNoConnected = [];
    }
  };

  getOrders = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'OrderDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.document}/documents/notRealizedOrdersDocuments`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          this.focusedRowIndex = 0;
          if (data.length > 0) {
            this.focusedSelectedNoConnected = [data[0]];
          }
          setTimeout(() => {
            this.event.setFocus(this.gridOrder);
          }, 0);
        },
      }),
      reshapeOnPush: true,
    });
  };

  getLoadParams() {
    let obj: any = {};

    if (this.document == 'invoices') {
      obj.InvoiceDocumentId = this.invoiceDocumentId;
    }
    if (this.document == 'warehouseDocuments') {
      obj.WarehouseDocumentId = this.invoiceDocumentId;
    }

    if (this.customerId) {
      obj.CustomerId = this.customerId;
    }

    if (this.orderStatus == 'IsPartiallyForRealization') {
      obj.IsPartiallyForRealization = true;
    }
    if (this.orderStatus == 'IsOnlyFullToRealization') {
      obj.IsOnlyFullToRealization = true;
    }

    obj.WarehouseFilterId = this.warehouseId;

    switch (this.valueCriteria) {
      case 'OrderDocumentDateFrom':
        obj.OrderDocumentDateFrom = this.filterValue;
        break;
      case 'NumberIntuition':
        obj.NumberIntuition = this.filterValue;
        break;
      case 'RealizationPlanDateFrom':
        obj.RealizationPlanDateFrom = this.filterValue;
        break;
      case 'CustomerOrderNumberIntuition':
        obj.CustomerOrderNumberIntuition = this.filterValue;
        break;
    }

    return obj;
  }

  orderStatus = null;
  onAtachmentsValueChanged = (e) => {
    this.customValue = '';
    this.orderStatus = e;
    setTimeout(() => {
      if (e == '') {
        this.orderStatus = null;
        this.onSprGotCaleZamRlz({ value: false });
        this.onSprGotZamRlz({ value: false });
      }

      if (e == 'IsPartiallyForRealization') {
        this.onSprGotCaleZamRlz({ value: false });
        this.onSprGotZamRlz({ value: true });
      }

      if (e == 'IsOnlyFullToRealization') {
        this.onSprGotCaleZamRlz({ value: true });
        this.onSprGotZamRlz({ value: false });
      }
    }, 0);

    this.getOrders();
  };

  onChoosedWarehouse = (e) => {
    if (e) {
      this.warehouseId = e.WarehouseId;
    } else {
      this.warehouseId = this.event.decryptString(
        localStorage.getItem('sessionInfoWaproHtml')
      ).AppWarehouseId;
    }
    this.getOrders();
  };

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
      this.orderby = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;
    this.getOrders();
  }

  onFocusedRowChangedNoConected = (e) => {
    this.focusedSelectedNoConnected = [e.row.data];
    this.OrderDocumentId = [e.row.data.OrderDocumentId];
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  heightBottomPanel;
  showBottomPanel: boolean = false;
  ngModelChange = () => {
    this.showBottomPanel = !this.showBottomPanel;

    if (this.showBottomPanel) {
      this.bottomSidePanel.nativeElement.style.minHeight = `80px`;
      this.heightGrid = this.heightWindow - 350;
    } else {
      this.bottomSidePanel.nativeElement.style.minHeight = `34px`;

      this.bottomSidePanel.nativeElement.style.height = `34px`;
      this.heightGrid = this.heightWindow - 270;
    }
  };

  onInsertedOrder = () => {
    this.showRow = false;
    if (this.mode == 'edit') {
      this.dataSource.reload();
    }
  };

  addStartRealization = () => {
    this.findSocketAndRun('XGZWOAW1');
  };

  nest: Socket;
  isNestRunning = false;
  nestOperation: NestOperation = null;

  findSocketAndRun(SocketCode: string, nestOperation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = nestOperation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return false;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGZWOAO1':
        this.openNestFinished = true;
        this.findSocketAndRun('XGZWOAO2');
        break;
      case 'XGZWOAW1':
        this.onRowDblClick();
        break;
      case 'XGZWOAA1':
        this.isVisible = false;
        this.findSocketAndRun('XGZWOAA2');
        break;
      // gniazda zwiazane z pojedynczym zamowieniem -> nie wywoluja sie w magu
      default:
        break;
    }
  }

  beforeClosing() {
    this.findSocketAndRun('XGZWOAA1');
  }

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGZ');
    }
  }

  prepareForModify() {
    this.documentsOrdersService
      .prepareForModify(
        this.focusedSelectedNoConnected[0].OrderDocumentId,
        this.warehouseId
      )
      .subscribe({
        next: () => {
          this.showRow = true;
          this.cd.detectChanges();
        },
        error: () => {},
      });
  }

  onEditOrder() {
    this.prepareForModify();
    // this.mode = 'edit';
    // if (this.focusedSelectedNoConnected[0].Semaphore === this.user) {
    //   this.showRow = true;
    //   return;
    // }
    // this.showRow = true;
    // this.cd.detectChanges();
    // this.appService
    //   .putAuth(
    //     `orders/documents/${this.focusedSelectedNoConnected[0].OrderDocumentId}/prepareForModify`,
    //     null
    //   )
    //   .subscribe({
    //     next: () => {
    //       this.showRow = true;
    //       this.cd.detectChanges();
    //     },
    //     error: (err) => {
    //       this.event.httpErrorNotification(err);
    //     },
    //   });
  }
}

<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  [title]="event.deviceType != 'mobile' ? title : ''"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="840"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getData()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{
    id: unicalGuid,

  }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'deliveries.enterTheQuantityToBeTransferred' | translate }}"
        type="danger"
        (onClick)="insertAmount()"
        [id]="'insertAmount' + unicalGuid"
        [disabled]="readOnly || focusedSelected.length === 0"
        style="margin-right: 6px"
      ></dx-button>

      <dx-button
        text="{{ 'deliveries.repin' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'choosed' + unicalGuid"
        *ngIf="!readOnly"
        [disabled]="readOnly"
        (onClick)="isConfirmDialog = true; cd.detectChanges()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }}
          (esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#choosed' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "deliveries.repin" | translate }}
          (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#insertAmount' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "deliveries.enterTheQuantityToBeTransferred" | translate }}
          (F2)
        </div>
      </dx-tooltip>
    </div>

    <div class="row">
      <div class="col-md-12">
        <dx-check-box
          text="Ten sam dostawca"
          [(value)]="isTheSameSupplier"
          style="margin-right: 10px"
          (onValueChanged)="getData()"
        ></dx-check-box>
        <dx-check-box
          text="Ta sama cena"
          [(value)]="isTheSamePrice"
          style="margin-right: 10px"
          (onValueChanged)="getData()"
        ></dx-check-box>
        <dx-check-box
          text="Mniejsze ilości"
          [(value)]="IsShowLower"
          style="margin-right: 10px"
          (onValueChanged)="getData()"
        ></dx-check-box>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="heightGrid"
          [columnResizingMode]="'widget'"
          (onInitialized)="onInitialized($event)"
          [remoteOperations]="true"
          (onRowDblClick)="onRowDblClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onContentReady)="onContentReady($event)"
          (onKeyDown)="onKeyDown($event)"
          [(focusedRowIndex)]="focusedRowIndex"
          [focusedRowEnabled]="true"
          #gridBank
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection
            mode="single"
            showCheckBoxesMode="always"
            selectAllMode="page"
          >
          </dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            caption="{{
              'servicesDevices.warehouseDocumentNumber' | translate
            }}"
            dataField="WarehouseDocumentNumber"
            [allowSorting]="false"
            [width]="150"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'offers.warehouseDocDate' | translate }}"
            dataField="WarehouseDocumentDate"
            [allowSorting]="false"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            caption="{{
              'form-financial-document.payer-type.contractor' | translate
            }}"
            dataField="CustomerName"
            [allowSorting]="false"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'loyaltyProram.commercialDocumentNumber' | translate }}"
            dataField="InvoiceDocumentNumber"
            [allowSorting]="false"
            [width]="150"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'offers.tradingDocDate' | translate }}"
            dataField="InvoiceDocumentDate"
            [allowSorting]="false"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-commercial-operation.grid.count' | translate }}"
            dataField="Amount"
            [allowSorting]="false"
            [width]="150"
            cellTemplate="cellTemplateCount"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'cellTemplateCount'; let index = i">
            {{ data.data.Amount | amountFormatter : data : 4 }}
          </div>

          <dxi-column
            caption="{{ 'deliveries.insertAmount' | translate }}"
            dataField="InsertAmount"
            [allowSorting]="false"
            [width]="150"
            cellTemplate="insertAmount"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'insertAmount'; let index = i">
            {{ data.data.InsertAmount | amountFormatter : data : 4 }}
          </div>
        </dx-data-grid>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div
          class="header-document"
          style="margin-top: 0px; width: calc(100% - 2px)"
        >
          <p class="p-header-info">
            {{ "deliveries.maximumAmountToOvervoltage" | translate }}:
            <strong>{{ position[0]?.AmountOfReleased }}</strong> |
            {{ "deliveries.typedTogether" | translate }}:
            <strong>{{ insertAll }}</strong>
          </p>
        </div>

        <app-released-quantity
          *ngIf="isChangeQuantity"
          [isVisible]="isChangeQuantity"
          (onClosing)="isChangeQuantity = false; cd.detectChanges()"
          [position]="position[0]"
          [maxAmount]="maxAmount"
          (onInserted)="onInserted($event)"
        ></app-released-quantity>

        <app-confirm-dialog
          *ngIf="isConfirmDialog"
          kindDialog="question"
          confirmText="form-commercial-operation.doYouWantConnectExpenditureWithNewDeliveries"
          [isVisible]="true"
          (onRemoving)="yesConfirmDialog()"
          (onClosing)="isConfirmDialog = false; cd.detectChanges()"
        >
        </app-confirm-dialog>
      </div>
    </div>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

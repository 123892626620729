import { Socket } from './../../socket.model';
import { NestOperation } from './../../nest/nest.model';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestService } from 'src/app/nest-service.service';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { SingleRecordMode } from 'src/app/event.model';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { HelpService } from 'src/app/help-service.service';
import { TASK_TYPES_KEY } from 'src/app/consts';
import { of, map, tap } from 'rxjs';
import { ViewType } from 'devextreme/ui/scheduler';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';

@Component({
    selector: 'app-task-calendar',
    templateUrl: './task-calendar.component.html',
    styleUrls: ['./task-calendar.component.scss'],
    inputs: [
        'dropDownBoxMode',
        'selectedId',
        'readOnly',
        'isPopupMode',
        'isVisible',
    ],
    standalone: false
})
export class TaskCalendarComponent implements OnInit {
  @Output('onClosing') onClosing = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('reports') reports: ReportsComponent;

  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('tabPanel') tabPanel;

  helpService = inject(HelpService);
  isVisible;
  isPopupMode;
  componentVisible: boolean = false;
  readOnly;
  dropDownBoxMode;
  selectedId;
  dataSource;
  selectedRows = [];
  isGridBoxOpened: boolean = false;
  isDelete: boolean = false;
  filterCriteria = [
    { value: 'subjectIntuition', label: 'temat (intuicja)' },
    { value: 'PctOfCompletion', label: 'realizacja', type: 'number' },
    { value: 'taskDate', label: 'data wprowadzenia', type: 'data' },
  ];
  articleName = [];
  articlesList = [];
  articlesListPom = [];
  filterValue = '';
  displayValue: string = 'nazwa';
  valueCriteria = 'subjectIntuition';
  shortcuts: ShortcutInput[] = [];
  order: string = 'ASC'; // asc/desc
  orderby: string = 'IndexCatalogue';
  taskStatusId = '';
  taskTypeId = '';
  width = 250;
  widthLeftPanel = 250;
  initialLocalStorageData = null;
  localStorageData = {
    leftPanelWidth: null,
    rightPanelWidth: null,
    filterValue: null,
    filterCriteria: null,
  };
  colors = [];
  timer = 500;
  myTimer;
  selectedTask = [];
  unicalGuid;
  dateFrom;
  dateTo;
  newTaskType = null;
  calendarHeight: number = 700;
  private changeSize = new Subject();

  perABDServiceOrders = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };

  popupSelectBoxSource = [
    { i: 'edit', text: 'buttons.edit' },
    { i: 'delete', text: 'buttons.delete' },
    { i: 'show', text: 'buttons.show' },
    { i: 'print', text: 'buttons.prints' },
    { i: 'edocuments', text: 'buttons.eDocuments' },
    { i: 'operations', text: 'form-financial-document.operations' },
  ];
  isRecordCardVisible: boolean;
  recordCardMode: RecordCardMode;
  recordCardObjectId: any;

  @HostListener('window:resize', ['$event.target'])
  public onResize(target) {
    this.changeSize.next(target.innerHeight);
  }

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    public nestService: NestService,
    private router: Router
  ) {
    this.event.isInventory.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getData();
    });
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((res) => {
      this.readOnly = res;
    });
    this.calendarHeight = window.innerHeight - 160;
    this.changeSize
      .asObservable()
      .pipe(throttleTime(500))
      .subscribe((height: any) => {
        this.calendarHeight = height - 160;
      });

    this.onAppointmentAdd = this.onAppointmentAdd.bind(this);

    this.event.closeDrobBox.pipe(takeUntilDestroyed()).subscribe(() => {
      this.closeDropBox();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.dateFrom = this.event.dataFormatted({
      value: new Date(new Date().setDate(new Date().getDate() - 7)),
    });
    this.dateTo = this.event.dataFormatted({ value: new Date() });

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests;

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });
  }

  colors$: Subscription;

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    document.addEventListener('click', (e) => {
      if ((e.target as any)?.className !== 'dx-icon dx-icon-more-horizontal') {
        if (this.model) {
          this.model.isOpened = false;
        }
      }
    });

    this.colors$ = this.event.colors.subscribe((colors) => {
      this.colors = colors;
    });

    this.getReminderTimes();
    this.getData();
    this.getTranslations();
    this.getViewConfigurations();
    this.getTaskTypes();

    this.perABDServiceOrders = this.event.checkPermissionsBtn(
      'SerwisZlecD',
      'SerwisZlecE',
      'SerwisZlecU',
      'SerwisZlecO'
    );

    this.isPerToAddServiceOrder.set(this.perABDServiceOrders.addBtn);
    this.isPerToShowServiceOrder.set(this.perABDServiceOrders.showBtn);

    const calendarConfig: string = localStorage.getItem(
      'calendarConfiguration'
    );
    if (calendarConfig) {
      try {
        this.calendarConfiguration = JSON.parse(calendarConfig);
      } catch {}
    }

    this.closeNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      'XGCKA'
    );
    this.findSocketAndRun('XGCKO1');
  }

  getTranslations() {
    this.translate.get('tasks.subjectIntuition').subscribe((text: string) => {
      this.filterCriteria[0].label = text;
      this.displayValue = text;
    });

    this.translate.get('tasks.realisation').subscribe((text: string) => {
      this.filterCriteria[1].label = text.toLowerCase();
    });

    this.translate.get('tasks.insertDate').subscribe((text: string) => {
      this.filterCriteria[2].label = text.toLowerCase();
    });
  }

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  getViewConfigurations = () => {
    try {
      if (!localStorage.getItem('leftTaskCalendarPanel')) {
        this.appService
          .getAuth(`viewConfigurations/TaskCalendarPanel`)
          .subscribe((res) => {
            if (res.Configuration) {
              this.localStorageData = JSON.parse(res.Configuration);
            }
            localStorage.setItem(
              'leftTaskCalendarPanel',
              JSON.stringify(this.localStorageData)
            );
            this.getViewConfigurations();
          });
      } else if (
        localStorage.getItem('leftTaskCalendarPanel') &&
        !this.dropDownBoxMode
      ) {
        this.localStorageData = JSON.parse(
          localStorage.getItem('leftTaskCalendarPanel')
        );
        this.initialLocalStorageData = JSON.parse(
          localStorage.getItem('leftTaskCalendarPanel')
        );
        if (this.localStorageData.leftPanelWidth != null)
          this.leftPanel.nativeElement.style.width =
            this.localStorageData.leftPanelWidth;
        if (this.localStorageData.leftPanelWidth != null)
          this.width = Number(
            this.localStorageData.leftPanelWidth.replace('px', '')
          );
        if (this.localStorageData.rightPanelWidth != null)
          this.rightPanel.nativeElement.style.width =
            this.localStorageData.rightPanelWidth;

        this.filterValue = this.localStorageData.filterValue;
      }
    } catch (e) {
      this.onInitErr = true;
    }
  };

  ngAfterViewInit() {
    this.getViewConfigurations();
    this.cd.detectChanges();
    this.shortcuts.push(
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/modul-crm');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.showNewRow();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.selectedTask.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          )
            this.showEditArticle();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.selectedTask.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.isDelete = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.selectedTask.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          )
            this.setSingleRecordVisible('show');
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            if (this.selectedTask.length > 0) this.onChoosingArticles();
          }
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    this.selectedTask = [];

    if (
      JSON.stringify(this.initialLocalStorageData) !==
      JSON.stringify(this.localStorageData)
    ) {
      const session = {
        View: 'TaskCalendarPanel',
        Configuration: JSON.stringify(this.localStorageData),
        TenantId: this.event.footerInfo.TenantId,
      };
      this.appService
        .postAuth(`viewConfigurations`, session)
        .subscribe(() => {});
    }
    this.colors$.unsubscribe();
    this.activatedRouteSub.unsubscribe();
    this.globalNestSub.unsubscribe();
  }

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  getOrderBy() {
    if (this.valueCriteria === 'ProductNameIntuition') {
      return 'ProductName1';
    }
    return this.valueCriteria.indexOf('Intuition') === -1
      ? this.valueCriteria
      : this.valueCriteria.substring(
          0,
          this.valueCriteria.indexOf('Intuition')
        );
  }

  futureTaskDays = [];
  getTasksDateFilter() {
    const currentDate = new Date(this.calendarConfiguration.currentDate);
    if (
      this.calendarConfiguration.currentView === 'day' ||
      this.calendarConfiguration.currentView === 'timelineDay'
    ) {
      const date = this.event.dataFormatted({ value: currentDate });
      return `&dateStart=${date}&dateEnd=${date}`;
    } else if (
      this.calendarConfiguration.currentView === 'workWeek' ||
      this.calendarConfiguration.currentView === 'week' ||
      this.calendarConfiguration.currentView === 'timelineWorkWeek' ||
      this.calendarConfiguration.currentView === 'timelineWeek'
    ) {
      const first = currentDate.getDate() - currentDate.getDay() + 1;
      const monday = new Date(currentDate.setDate(first));
      const sunday = this.event.getDatePlusDay(7, monday);
      const dateStartFormatted = this.event.dataFormatted({ value: monday });
      const dateEndFormatted = this.event.dataFormatted({ value: sunday });
      return `&dateStart=${dateStartFormatted}&dateEnd=${dateEndFormatted}`;
    } else if (
      this.calendarConfiguration.currentView === 'month' ||
      this.calendarConfiguration.currentView === 'timelineMonth'
    ) {
      const dateStart = new Date(currentDate.setDate(1));
      const dateEnd = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      const dateStartFormatted = this.event.dataFormatted({ value: dateStart });
      const dateEndFormatted = this.event.dataFormatted({ value: dateEnd });
      return `&dateStart=${dateStartFormatted}&dateEnd=${dateEndFormatted}`;
    }

    return '';
  }

  getData() {
    let uri = `crm/tasks?skip=0&take=200`;
    if (this.selectedCalendar === 'my') uri += `&forCurrentUser=true`;
    uri += this.getTasksDateFilter();

    this.appService.getAuth(uri).subscribe((res) => {
      this.tasks = res.data;
      this.currentTasks = res.data.filter((task) => {
        const todayDate = new Date().toISOString().slice(0, 10);
        let tomorrowDate: any = new Date();
        tomorrowDate.setDate(new Date().getDate() + 1);
        tomorrowDate = tomorrowDate.toISOString().slice(0, 10);
        return task.DateStart >= todayDate && task.DateEnd <= tomorrowDate;
      });
      this.futureTasks = res.data.filter((task) => {
        let tomorrowDate: any = new Date();
        tomorrowDate.setDate(new Date().getDate() + 1);
        tomorrowDate = tomorrowDate.toISOString().slice(0, 10);
        return task.DateStart >= tomorrowDate;
      });

      this.futureTasks.forEach((task: any) => {
        const day = task.DateStart.slice(0, 10);
        const item = this.futureTaskDays.find((date) => date.day === day);
        if (!item)
          this.futureTaskDays.push({
            day,
            tasks: [task],
          });
        else item.tasks.push(task);
      });
    });
  }

  getTasksOfType(type) {
    this.appService
      .getAuth(`crm/tasks${this.getTasksOfTypeLoadParams(type.TypeId)}`)
      .subscribe({
        next: (res) => {
          type.amount = res.totalCount;
        },
        error: (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
          localStorage.removeItem('crmCanbanTaskTypes');
        },
      });
  }

  getTasksOfTypeLoadParams(typeId: number) {
    let params = `?typeId=${typeId}`;
    if (this.selectedCalendar === 'my') params += `&forCurrentUser=true`;
    params += this.getTasksDateFilter();

    return params;
  }

  onValueChangeSearch = (e) => {
    if (e.value == '') this.getData();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  };

  onSelectionChanged = (e) => {
    this.articlesListPom = [];
    this.articlesListPom.push(e.selectedRowsData[0]);
  };

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) width = 280;
    if (width > 620) width = 620;
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.leftPanelWidth = `${width}px`;
    this.localStorageData.rightPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'leftTaskCalendarPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  onInserted = () => {
    this.getData();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  };

  setArticleLock = () => {
    this.setSingleRecordVisible('edit');
  };

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRows = [];
      this.onChoosed.emit(null);
    }
  };

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode && !this.isPopupMode)
      this.showEditArticle();
    else this.onChoosingArticles();
  };

  ngOnChanges(changes: SimpleChanges) {
    this.articleName = [];
    if (changes.selectedId?.currentValue) {
      this.getData();
      this.taskTypes.forEach((el) => {
        this.getTasksOfType(el);
      });
    }
  }

  onChoosingArticles = () => {
    if (!this.readOnly) {
      this.articlesList = this.articlesListPom;
      this.articleName = this.selectedRows[0];
      this.onChoosed.emit(this.articlesList[0]);
      this.onClosing.emit(true);
      this.isGridBoxOpened = false;
    }
  };

  onItemClick(e) {
    this.valueCriteria = e.itemData.value;
    this.displayValue = e.itemData.label;
  }

  getTaskTypesDef() {
    const localData = this.event.getSessionStorageData(TASK_TYPES_KEY);
    if (localData) {
      return of(localData);
    } else {
      return this.appService.getAuth(`crm/taskTypes`).pipe(
        map((res) => res.data || []),
        tap((data) => {
          this.event.saveSessionStorageData(TASK_TYPES_KEY, data);
        })
      );
    }
  }

  getTaskTypes = () => {
    this.getTaskTypesDef().subscribe(
      (data) => {
        data.forEach((el) => {
          if (el.Name === 'Spotkanie') el.icon = 'user-group';
          else if (el.Name === 'Telefon') el.icon = 'phone-group';
          else if (el.Name === 'Wyjazd') el.icon = 'car-group';
          else if (el.Name === 'Zlecenie serwisowe') el.icon = 'service-group';
          else el.icon = 'default-icon';

          this.getTasksOfType(el);
        });
        this.taskTypes = data;
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        localStorage.removeItem('crmCanbanTaskTypes');
      }
    );
  };

  getSortIcon() {
    return this.order === 'ASC'
      ? 'icon absui-icon--sort-ascending'
      : 'icon absui-icon--sort-descending';
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit(true);
      this.componentVisible = false;
    }
  }

  closeConfirm() {
    this.isDelete = false;
  }

  delete = (SocketCode: string = null) => {
    if (this.selectedTask.length == 0) return;
    this.appService.deleteAuth(`crm/tasks/${this.selectedTask[0]}`).subscribe(
      () => {
        this.getData();
        this.taskTypes.forEach((el) => {
          this.getTasksOfType(el);
        });
        if (SocketCode) {
          this.findSocketAndRun(SocketCode);
        }
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  };

  showNewRow() {
    this.newDateStart = null;
    this.newDateEnd = null;
    this.newColour = null;
    this.newTaskType = null;
    this.setSingleRecordVisible('add');
  }

  showEditArticle() {
    this.setArticleLock();
  }

  showDeleteArticle() {
    this.delete();
  }

  // NEST
  onInitErr: boolean = false;
  activatedRouteSub: Subscription;
  componentNests: Socket;
  nest: Socket;
  nestsSub: Subscription;
  isNestRunning = false;
  nestOperation: NestOperation;
  closeNest: Socket;
  globalNestSub: Subscription;
  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest && newNest.Operations && newNest.Operations.length) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGCKO1':
        this.componentVisible = true;
        if (this.onInitErr) {
          this.ngAfterViewInit();
        }
        this.findSocketAndRun('XGCKO2');
        break;
      default:
        break;
    }
  }

  onConfirmSingle(socketCode) {
    this.findSocketAndRun(socketCode);
  }

  timeoutHandlerSidePanel;
  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.leftPanel.nativeElement.style.width = '10px';
      this.width = 21;
      this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
      this.localStorageData.leftPanelWidth = `21px`;
      this.localStorageData.rightPanelWidth = `calc(100% - 26px)`;
    } else {
      this.leftPanel.nativeElement.style.width = '280px';
      this.width = 280;
      this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
      this.localStorageData.leftPanelWidth = `280px`;
      this.localStorageData.rightPanelWidth = `calc(100% - 280px)`;
    }

    localStorage.setItem(
      'leftTaskCalendarPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  addTaskType: boolean = false;

  openAddTaskType() {
    this.addTaskType = true;
  }

  onInsertedTaskType() {
    this.getTaskTypes();
  }

  selectedTab: number = 0;
  draggingGroupName: string = 'tasksGroup';
  taskTypes: any[] = [];

  onTimeLineChanged() {
    this.setViews();
  }

  onItemDragStart(e) {
    e.itemData = e.fromData;
  }

  onItemDragEnd(e) {
    if (e.toData) {
      e.cancel = true;
    }
  }

  onListDragStart(e) {
    e.cancel = true;
  }

  addTask(task) {
    this.newTaskType = task.TypeId;
    this.setSingleRecordVisible('add');
  }

  newColour = null;
  tasks = [];
  onAppointmentAdd(e) {
    const index = this.taskTypes.indexOf(e.fromData);

    if (index >= 0) {
      this.newTaskType = e.fromData.TypeId;
      this.newDateStart = e.itemData.DateStart;
      this.newDateEnd = e.itemData.DateEnd;
      this.newColour = e.fromData.DefaultColor;
      this.setSingleRecordVisible('add');
    }
  }

  calendarConfigurationVisible: boolean = false;

  openCalendarConfiguration() {
    this.calendarConfigurationVisible = true;
  }

  calendarConfiguration = {
    endDayHour: 17,
    startDayHour: 9,
    cellDuration: 30,
    currentView: 'day' as ViewType,
    isTimeline: false,
    taskDescription: 'title',
    currentDate: new Date(),
  };

  availableViews = ['day', 'workWeek', 'week', 'month'];

  setViews() {
    this.availableViews = !this.calendarConfiguration.isTimeline
      ? ['day', 'workWeek', 'week', 'month']
      : ['timelineDay', 'timelineWorkWeek', 'timelineWeek', 'timelineMonth'];
    if (
      this.calendarConfiguration.isTimeline &&
      this.calendarConfiguration.currentView.indexOf('timeline') == -1
    ) {
      let newView: string =
        this.calendarConfiguration.currentView[0].toUpperCase() +
        this.calendarConfiguration.currentView.substr(1);
      newView = 'timeline' + newView;
      this.calendarConfiguration.currentView = newView as ViewType;
    } else if (
      !this.calendarConfiguration.isTimeline &&
      this.calendarConfiguration.currentView.indexOf('timeline') > -1
    ) {
      let newView: string = this.calendarConfiguration.currentView.substr(8);
      newView = newView[0].toLowerCase() + newView.substr(1);
      this.calendarConfiguration.currentView = newView as ViewType;
    }
    localStorage.setItem(
      'calendarConfiguration',
      JSON.stringify(this.calendarConfiguration)
    );
  }

  onCalendarConfigurationUpdated(e) {
    this.calendarConfigurationVisible = false;
    this.calendarConfiguration = e;
    this.cd.detectChanges();
    localStorage.setItem(
      'calendarConfiguration',
      JSON.stringify(this.calendarConfiguration)
    );
  }

  accordionTabs = ['taskList'];
  selectedAccordionTabs = ['taskList'];
  currentTasks = [];
  futureTasks = [];
  selectedCalendar = 'my';
  calendarTypes = [
    { value: 'my', label: 'Moje zadania' },
    { value: 'all', label: 'Wszystkich pracowników' },
    { value: 'stacked', label: 'Zbiorcze kalendarze' },
  ];
  onAppointmentUpdated(e) {
    this.appService
      .putAuth(`crm/tasks/${e.appointmentData.TaskId}`, e.appointmentData)
      .subscribe(
        () => {},
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  newDateStart = null;
  newDateEnd = null;
  newColor = null;

  onAppointmentFormOpening(e) {
    e.cancel = true;
    if(this.preventOpeningAppontmentForm) {
      this.preventOpeningAppontmentForm = false;
      return;
    }
    if (e.appointmentData?.TaskId) {
      // update
      this.selectedTask = [e.appointmentData.TaskId];
      this.newDateStart = null;
      this.newDateEnd = null;
      this.newColour = null;
      this.setSingleRecordVisible('edit');
    } else {
      // nowy
      this.selectedTask = [];
      this.newDateStart = e.appointmentData?.DateStart || null;
      this.newDateEnd = e.appointmentData?.DateEnd || null;
      this.newColour = null;
      this.newTaskType = null;
      this.setSingleRecordVisible('add');
    }
  }

  selectedTypeId: number = null;
  serviceTaskCustomerId: number = null;
  selectedTaskEmployees = signal([]);
  onAppointmentClick(e) {
    this.selectedTaskEmployees.set([]);
    this.selectedTypeId = e?.appointmentData?.TypeId;
    this.selectedTask = [e.appointmentData.TaskId];
    this.serviceTaskCustomerId = e?.appointmentData?.CustomerId || null;
    this.appService.getAuth(`crm/tasks/${this.selectedTask[0]}`).subscribe({
      next: (res) => {
        this.selectedTaskEmployees.set(res.Employees);
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  cellClick() {
    this.selectedTask = [];
  }

  reminderTimes = [];
  getReminderTimes() {
    const amount = 47;
    this.reminderTimes.push({ value: null, label: 'Brak' });
    this.reminderTimes.push({ value: 0, label: '00:00' });
    for (let i = 0; i < amount; i++) {
      let time =
        i < 19 ? '0' + Math.round(i / 2) : Math.round(i / 2).toString();
      time += i % 2 === 0 ? ':30' : ':00';

      this.reminderTimes.push({ value: i + 1, label: time });
    }
  }

  getReminderTimer(val) {
    return this.reminderTimes.find((el) => el.value === val)?.label || '-';
  }

  deleteFromPopup(e) {
    e.stopPropagation();

    this.isDelete = true;
  }

  getBgColour(colour, data) {
    if (colour) {
      if (data.TaskId == this.selectedTask[0]) {
        return '#' + colour;
      }
      return '#' + colour + '99';
    }
    return '#4DB6AC';
  }

  getBorderColour(colour) {
    if (colour) {
      const foundColor = this.colors.find((el) => el.Body.indexOf(colour) > -1);
      if (foundColor) {
        return foundColor.Header;
      }
      return '#009688';
    }
    return '#009688';
  }

  onSelectionCategoryTabChanged() {}

  getTypeDescription(value) {
    return this.taskTypes.find((el) => el.TypeId == value)?.Name || '-';
  }

  model = null;
  preventOpeningAppontmentForm: boolean = false;
  toggleOpened(model) {
    this.model = model;
    model.isOpened = !model.isOpened;
    this.preventOpeningAppontmentForm = true;
  }

  selectBoxValue = null;
  onSelectBoxValueChanged(e, item) {
    this.selectBoxValue = null;
    this.selectedTask = [item.TaskId];

    if (e.value.i == 'edit') {
      this.setSingleRecordVisible('edit');
    } else if (e.value.i == 'delete') {
      this.isDelete = true;
    } else if (e.value.i == 'show') {
      this.setSingleRecordVisible('show');
    } else if (e.value.i == 'print') {
    } else if (e.value.i == 'edocuments') {
    } else if (e.value.i == 'operations') {
    }
  }

  onTitleClick(id: number) {
    this.selectedTask = [id];
    this.setSingleRecordVisible('edit');
  }

  isOpened: boolean = false;

  stopPropagation(e) {
    e.stopPropagation();
  }

  menuBtnClick() {
    this.isOpened = !this.isOpened;
  }

  isWeekend(date: Date) {
    const day = date.getDay();
    return day === 0 || day === 6;
  }

  refreshView() {
    sessionStorage.removeItem(TASK_TYPES_KEY);
    this.getTaskTypes();
    this.getData();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  }

  onSelectedCalendarChanged() {
    this.getData();
    this.taskTypes.forEach((el) => {
      this.getTasksOfType(el);
    });
  }

  onSchedulerOptionChanged(e) {
    if (e.name === 'currentView' && e.previousValue !== e.value) {
      this.calendarConfiguration.currentView = e.value;
      this.getData();
      this.taskTypes.forEach((el) => {
        this.getTasksOfType(el);
      });
    } else if (e.name === 'currentDate') {
      this.calendarConfiguration.currentDate = e.value;
      this.getData();
      this.taskTypes.forEach((el) => {
        this.getTasksOfType(el);
      });
    }
  }

  singleRecordMode: SingleRecordMode = null;
  isSingleRecordVisible: boolean = false;

  onSingleRecordClosing() {
    this.activeTabIndex = null;
    this.isSingleRecordVisible = false;
    if (this.singleRecordMode === 'add') {
      this.findSocketAndRun('XGCODA2', 'add');
    } else if (this.singleRecordMode === 'edit') {
      this.findSocketAndRun('XGCOPA2', 'edit');
    }
    this.singleRecordMode = null;
    this.cd.detectChanges();
  }

  onSingleRecordInserted() {
    this.activeTabIndex = null;
    this.onInserted();
    this.singleRecordMode = null;
    this.isSingleRecordVisible = false;
  }

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  onShouldReloadTypes() {
    this.getTaskTypes();
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGC');
    }
  }

  isServiceOrderVisible = signal(false);
  orderServiceId: number[] = null;
  serviceTaskId: number = null;
  orderServiceMode: 'add' | 'show' = 'add';
  onServiceOrderClosing() {
    this.serviceTaskId = null;
    this.selectedTask = [];
    this.serviceTaskCustomerId = null;
    this.isServiceOrderVisible.set(false);
  }

  onServiceOrderInserted(_e) {
    this.serviceTaskId = null;
    this.selectedTask = [];
    this.serviceTaskCustomerId = null;
    this.isServiceOrderVisible.set(false);
  }

  getDayOfWeek(date: string) {
    if (!date) return '';
    const day = new Date(date).getDay();
    if (day === 0) {
      return 'Ndz';
    }
    if (day === 1) {
      return 'Pon';
    }
    if (day === 2) {
      return 'Wt';
    }
    if (day === 3) {
      return 'Śr';
    }
    if (day === 4) {
      return 'Czw';
    }
    if (day === 5) {
      return 'Pt';
    }
    if (day === 6) {
      return 'Sob';
    }
    return '';
  }

  getLocale() {
    var locale = localStorage.getItem('lang');
    return locale != null ? locale : 'pl';
  }

  isPerToAddServiceOrder = signal(false);
  isPerToShowServiceOrder = signal(false);
  contextMenuSignal = computed(() => {
    return [
      {
        icon: 'icon absui-icon--add-circle',
        text: this.translate.instant('buttons.add'),
        itemIndex: 0,
      },
      {
        icon: 'icon absui-icon--mode-edit',
        text: this.translate.instant('buttons.edit'),
        itemIndex: 1,
      },
      {
        icon: 'icon absui-icon--trash',
        text: this.translate.instant('buttons.delete'),
        itemIndex: 2,
      },
      {
        icon: 'icon absui-icon--form-items',
        text: this.translate.instant('buttons.show'),
        itemIndex: 3,
      },
      {
        icon: 'icon absui-icon--print',
        text: this.translate.instant('buttons.prints'),
        itemIndex: 4,
        disabled: true,
      },
      {
        icon: '',
        text: this.translate.instant('tasks.addServiceOrder'),
        itemIndex: 5,
        disabled: !this.isPerToAddServiceOrder(),
      },
      {
        icon: '',
        text: this.translate.instant('tasks.showServiceOrder'),
        itemIndex: 6,
        disabled: !this.isPerToShowServiceOrder(),
      },
      {
        icon: 'icon absui-icon--operations',
        text: this.translate.instant('form-financial-document.operations'),
        itemIndex: 6,
        items: this.additionalOperationList(),
      },
    ];
  });

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) this.showNewRow();
        break;
      case 1:
        if (!this.readOnly && this.selectedTask.length)
          this.setSingleRecordVisible('edit');
        break;
      case 2:
        if (!this.readOnly && this.selectedTask.length) this.isDelete = true;
        break;
      case 3:
        if (!this.readOnly && this.selectedTask.length)
          this.setSingleRecordVisible('show');
        break;
      case 4:
        //TODO dodać wydruki
        return;

      case 5:
        const taskType = this.taskTypes.find(
          (el) => el.TypeId == this.selectedTypeId
        );
        if (taskType.Name !== 'Zlecenie serwisowe') {
          this.event.showNotification(
            'error',
            this.translate.instant('tasks.cantCreateServiceOrder')
          );
          return;
        }
        this.orderServiceMode = 'add';
        this.serviceTaskId = this.selectedTask[0];
        // serviceTaskCustomerId /
        this.isServiceOrderVisible.set(true);
        break;
      case 6:
        this.orderServiceMode = 'show';
        const showTaskType = this.taskTypes.find(
          (el) => el.TypeId == this.selectedTypeId
        );
        if (showTaskType.Name !== 'Zlecenie serwisowe') {
          this.event.showNotification(
            'error',
            this.translate.instant('tasks.notPossibleShowServiceOrder')
          );
          return;
        }
        this.appService
          .getAuth(`services/orders`, {
            TaskId: this.selectedTask[0],
          })
          .subscribe({
            next: (res) => {
              if (!res?.data?.length) {
                this.event.showNotification(
                  'error',
                  this.translate.instant('tasks.notPossibleShowServiceOrder')
                );
                return;
              }
              res.data.reverse();
              this.orderServiceId = [res.data[0].ServiceOrderId];
              this.isServiceOrderVisible.set(true);
            },
            error: (error) => {
              this.event.httpErrorNotification(error);
            },
          });
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
    }
  };

  additionalOperationList = signal<any[]>([]);
  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        try {
          this.recordCardObjectId = this.serviceTaskCustomerId;
          if (!this.recordCardObjectId) throw new Error();
        } catch (e) {
          this.recordCardObjectId = null;
          this.event.showNotification(
            'warning',
            this.translate.instant('recordCard.noCustomerTask')
          );
        }
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

onShowMoreClick() {
    this.activeTabIndex = 2;
    this.newDateStart = null;
    this.newDateEnd = null;
    this.newColour = null;
    this.setSingleRecordVisible('edit');
  }
  activeTabIndex = null;
}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title()"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); event.setFocus(gridBank)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{
    id: unicalGuid
  }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <div style="display: inline-block">
        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          text="{{ 'buttons.edit' | translate }}"
          style="margin-right: 6px; display: inline-block; width: auto"
          (onClick)="onEditOrder()"
          [id]="'edit' + unicalGuid"
          [disabled]="articleIdFocused.length == 0 || !perABD.editBtn"
        >
        </dx-button>

        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          text="{{ 'buttons.preview' | translate }}"
          style="margin-right: 6px; display: inline-block; width: auto"
          (onClick)="mode = 'show'; showRow.set(true)"
          [id]="'preview' + unicalGuid"
          [disabled]="articleIdFocused.length == 0 || !perABD.showBtn"
        >
        </dx-button>

        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px; display: inline-block"
          [id]="'choosed' + unicalGuid"
          *ngIf="!event.readOnly"
          [disabled]="event.readOnly || this.articleIdFocused.length === 0"
          (onClick)="onSave()"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="closeWindow()"
          [id]="'cancel' + unicalGuid"
        ></dx-button>
      </div>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "warehouseDocument.customer" | translate }}:
          <strong>{{ headerDocument?.CustomerName }}</strong>
        </p>
      </div>

      <div class="d-flex flex-row">
        <div>
          <app-custom-dropdown-box
            [openDropDown]="openCustomDropDown()"
            class="warehouse-documents-step-3"
            [items]="filterCriteria()"
            [(selectedItem)]="localStorageData.filter.orderBy"
            [(filterValue)]="filterValue"
            (onValueChanged)="onFilterDataChanged($event)"
            style="margin-right: 5px"
            (onArrowDown)="gridBank.instance.focus()"
            #searchControl
          >
          </app-custom-dropdown-box>
        </div>
        <div style="padding-top: 2px">
          <app-custom-chips-button
            name="{{ 'menu.orders' | translate }}"
            [list]="filterCriteriaAtachments()"
            (onValueChanged)="onAtachmentsValueChanged($event)"
            [customDisplayValue]="customValue"
            [ngClass]="{ topMargin: customValue != '' }"
          >
          </app-custom-chips-button>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [allowColumnReordering]="true"
            [height]="heightGrid"
            class=""
            [remoteOperations]="true"
            [id]="'grid' + unicalGuid"
            [autoNavigateToFocusedRow]="true"
            (onInitialized)="onInitialized($event)"
            [allowColumnResizing]="true"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellChanged($event)"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            (onRowClick)="onRowClick($event)"
            [(focusedRowIndex)]="focusedRowIndex"
            [focusedRowEnabled]="true"
            (onContentReady)="onGridContentReady($event)"
            #gridBank
            [columnResizingMode]="'widget'"
            (onEditorPreparing)="onEditorPreparing($event)"
            (onKeyDown)="onKeyDown($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-selection
              [mode]="'single'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            >
            </dxo-scrolling>
            <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxo-column-fixing [enabled]="false"></dxo-column-fixing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [cssClass]="
                localStorageData.filter.orderBy === 'NumberIntuition' ||
                localStorageData.filter.orderBy === 'OrderDocumentNumber'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="numberHeaderTemplate"
              width="150"
              [allowSorting]="false"
              dataField="OrderDocumentNumber"
              cellTemplate="mainColumn"
            >
            </dxi-column>

            <div *dxTemplate="let data of 'mainColumn'">
              <span>{{ data.data.OrderDocumentNumber }}</span>
            </div>
            <div *dxTemplate="let data of 'numberHeaderTemplate'">
              <div class="grid-header-wrapper">
                {{ "form-financial-document.number" | translate }}
              </div>
            </div>
            <dxi-column
              caption="S"
              width="40"
              [allowSorting]="false"
              dataField="OrderStatus"
              cellTemplate="statusCell"
              headerCellTemplate="StatusHeaderCellTemplate"
              renderAsync="true"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <div
              *dxTemplate="let data of 'StatusHeaderCellTemplate'"
              class="text-center"
            >
              <div class="grid-header-wrapper" [id]="'status' + unicalGuid">
                {{ "production-documents.s" | translate }}
              </div>
              <dx-tooltip
                [target]="'#status' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "production-documents.status" | translate }}
                </div>
              </dx-tooltip>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              width="100"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              dataField="Date"
              [cssClass]="
                localStorageData.filter.orderBy === 'OrderDocumentDateFrom'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="documentDateHeaderTemplate"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'documentDateHeaderTemplate'">
              <div class="grid-header-wrapper">
                {{ "form-financial-document.date" | translate }}
              </div>
            </div>

            <dxi-column
              caption="{{ 'warehouseDocument.label' | translate }}"
              width="105"
              [allowSorting]="false"
              dataField="LabelName"
              cellTemplate="labelTemplate"
              renderAsync="true"
              alignment="left"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'labelTemplate'">
              <app-label
                [bgColor]="data.data.LabelBackgroundColor"
                [color]="data.data.LabelTextColor"
                [text]="data.data.LabelName"
              >
              </app-label>
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.clientOrderNr' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="CustomerOrderNumber"
              [cssClass]="
                localStorageData.filter.orderBy === 'CustomerOrderNumber'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="nameHeaderTemplate"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'nameHeaderTemplate'">
              <div class="grid-header-wrapper">
                {{ "plannedOrders.clientOrderNr" | translate }}
              </div>
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.realisationDate' | translate }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="140"
              [allowSorting]="false"
              dataField="RealizationDate"
              [cssClass]="
                localStorageData.filter.orderBy === 'RealizationPlanDateFrom'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="realisationHeaderTemplate"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'realisationHeaderTemplate'">
              <div class="grid-header-wrapper">
                {{ "plannedOrders.realisationDate" | translate }}
              </div>
            </div>

            <dxi-column
              caption="{{ 'plannedOrders.priority' | translate }}"
              width="80"
              [allowSorting]="false"
              dataField="PriorityName"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.advance' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="AdvancePayment"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalNetAmount"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="
                localStorageData.filter.orderBy === 'TotalNetAmount'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
              headerCellTemplate="amountNetHeaderTemplate"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'amountNetHeaderTemplate'">
              <div class="grid-header-wrapper">
                {{ "form-commercial-operation.grid.netValue" | translate }}
              </div>
            </div>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalGrossAmount"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.type' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="DocumentTypeName"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'documentOrders.reservationType' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="ReservationModeName"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'articles.warning' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              width="150"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'bankAccounts.grid.columns.currency' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="CurrencyCode"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.netPriceCur' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalNetAmountCurrency"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.grossValCur' | translate }}"
              width="120"
              [allowSorting]="false"
              dataField="TotalGrossAmountCurrency"
              cellTemplate="numberPrecisionTemplate"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'production-documents.type' | translate }}"
              [allowSorting]="false"
              [cssClass]="'sorting-unavailable'"
              dataField="RealizationState"
              headerCellTemplate="DocumentType"
              cellTemplate="statusCellType"
              alignment="center"
              width="40"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'DocumentType'" class="text-center">
              <div
                class="grid-header-wrapper text-center"
                [id]="'type' + unicalGuid"
              >
                {{ "intrastate-documents.importExport" | translate }}
              </div>
              <dx-tooltip
                [target]="'#type' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "production-documents.type" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <div
              *dxTemplate="let data of 'statusCellType'"
              style="text-align: center"
            >
              <app-status-label
                [text]="
                  data.data.OrderStatus || data.data.RealizationState || 'N'
                "
                [color]="data.data.StatusStringColor"
                [backgroundColor]="data.data.StatusBackgroundColor"
              ></app-status-label>
            </div>

            <dxi-column
              caption="{{ 'baseCurrencies.currencyValue' | translate }}"
              [allowSorting]="false"
              dataField="CurrencyFactor"
              cellTemplate="numberPrecision4Template"
              width="100"
              [cssClass]="'sorting-unavailable'"
            >
            </dxi-column>

            <div *dxTemplate="let cell of 'numberPrecision4Template'">
              {{ cell.value | amountFormatter : cell : 4 }}
            </div>
            <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
              {{ cell.value | amountFormatter : cell }}
            </div>
            <div *dxTemplate="let cell of 'reservationTemplate'">
              <ng-container *ngIf="cell.value === 1">{{
                "automatic" | translate
              }}</ng-container>
              <ng-container *ngIf="cell.value === 2">{{
                "manual" | translate
              }}</ng-container>
            </div>

            <div *dxTemplate="let cell of 'priorityTemplate'">
              <ng-container *ngIf="cell.value === 1">{{
                "constractors.high" | translate
              }}</ng-container>
              <ng-container *ngIf="cell.value === 2">{{
                "constractors.medium" | translate
              }}</ng-container>
              <ng-container *ngIf="cell.value === 3">{{
                "constractors.low" | translate
              }}</ng-container>
            </div>

            <div
              *dxTemplate="let data of 'statusCell'"
              style="text-align: center"
            >
              <app-status-label
                [text]="
                  data.data.OrderStatus || data.data.RealizationState || 'N'
                "
                [color]="data.data.StatusStringColor"
                [backgroundColor]="data.data.StatusBackgroundColor"
              ></app-status-label>
            </div>
          </dx-data-grid>
        </div>
      </div>

      <!-- bottom panel -->
      <div class="row">
        <div
          class="bottom-side-panel"
          #bottomSidePanel
          style="margin-top: 40px"
        >
          <div
            class="resize-handle-top"
            mwlResizeHandle
            [resizeEdges]="{ top: true }"
            appResizeBottoPanel
          ></div>
          <div class="col-md-12 text-right resize-bottop-panel">
            <i
              class="icon bottom-panel-icon"
              mwlResizeHandle
              [resizeEdges]="{ top: true }"
              [ngClass]="{
                'absui-icon--arrow-drop-down bottom-icon-resize-down':
                  showBottomPanel,
                'absui-icon--arrow-drop-up bottom-icon-resize-up':
                  !showBottomPanel
              }"
              (click)="ngModelChange()"
            ></i>

            <div class="row">
              <div class="col-md-12 text-left">
                <dx-tab-panel
                  #tabPanel
                  width="100%"
                  [items]="bottomPanelTabs"
                  [(selectedIndex)]="selectedtabIndex"
                  [animationEnabled]="false"
                  [swipeEnabled]="false"
                >
                  <div *dxTemplate="let name of 'item'">
                    <ng-container [ngSwitch]="name" *ngIf="showBottomPanel">
                      <div *ngSwitchCase="'comments' | translate">
                        <p
                          *ngIf="articleIdFocused[0]"
                          style="margin-bottom: 0px"
                        >
                          {{ articleIdFocused[0].Remarks }}
                        </p>
                      </div>

                      <div
                        *ngSwitchCase="
                          'crmDeliveries.additionalInformation' | translate
                        "
                      >
                        <p
                          *ngIf="articleIdFocused[0]"
                          style="margin-bottom: 0px"
                        >
                          {{ articleIdFocused[0].AdditionalInformations }}
                        </p>
                      </div>
                    </ng-container>
                  </div>
                </dx-tab-panel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-confirm-dialog
  [isVisible]="true"
  *ngIf="isConfirmDialogVisible()"
  (onRemoving)="confirmRealizatino()"
  (onClosing)="isConfirmDialogVisible.set(false)"
  [confirmText]="'warehouseDocument.quantitativeSettlement'"
  [paramsMsg]="{
    noOrder: articleIdFocused[0]?.OrderDocumentNumber,
    noWarehouse: headerDocument?.WarehouseDocumentNumber
  }"
></app-confirm-dialog>

<app-new-documents-orders
  [isVisible]="showRow()"
  [extWarehouseId]="warehouseId"
  [selectedId]="[articleIdFocused[0]?.OrderDocumentId]"
  (onClosed)="showRow.set(false); event.setFocus(gridBank)"
  [readOnly]="mode == 'show' ? true : false"
  [mode]="mode"
  (onInserted)="onInsertedOrder()"
></app-new-documents-orders>

<dx-tooltip
  [target]="'#choosed' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.choose" | translate }} (Enter)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#edit' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.edit" | translate }} (F2)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#preview' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.preview" | translate }} (Shift + F2)
  </div>
</dx-tooltip>

<dx-tooltip
  [target]="'#cancel' + unicalGuid"
  [showEvent]="event.tooltipShowEvent"
  hideEvent="dxhoverend"
  [hideOnOutsideClick]="false"
>
  <div *dxTemplate="let data of 'content'">
    {{ "buttons.close" | translate }} (Esc)
  </div>
</dx-tooltip>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible || isConfirmDialogVisible()"
>
</wapro-keyboard-shortcuts>

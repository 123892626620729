import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
  signal,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxNumberBoxModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CurrencyBoxComponent } from '../../../../libraries/currency-box/currency-box.component';
import { FormsPaymentComponent } from '../../../../libraries/forms-payment/forms-payment.component';
import { WaproDateBoxComponent } from '../../../../core/wapro-date-box/wapro-date-box.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BankAccountsComponent } from '../../../../libraries/bank-accounts/bank-accounts.component';
import { BankAccountsContractorsComponent } from '../../../../libraries/contractors/bank-accounts-contractors/bank-accounts-contractors.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

interface RadioType {
  value: string;
  label: string;
}

interface PaymentSechedule {
  Amount: number;
  AmountCurrency: number;
  CurrencyCode: string;
  InvoiceDocumentId: number;
  InvoiceDocumentPaymentSecheduleId: number;
  PaymentDate: string;
  PaymentFormName: string;
  BankAccountId: number;
  BankAccountNumber: string;
  StateFlag: number;
  TypeOfAmount: string;
}

@Component({
  selector: 'app-add-payment-schedule',
  imports: [
    CommonModule,
    DxPopupModule,
    DxButtonModule,
    DxTooltipModule,
    DxScrollViewModule,
    TranslateModule,
    DxRadioGroupModule,
    DxNumberBoxModule,
    CurrencyBoxComponent,
    FormsPaymentComponent,
    WaproDateBoxComponent,
    FormsModule,
    BankAccountsComponent,
    BankAccountsContractorsComponent,
    NgShortcutsComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './add-payment-schedule.component.html',
  styleUrl: './add-payment-schedule.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddPaymentScheduleComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('firstInput') firstInput;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @Input() isVisible: boolean = false;
  @Input({ required: true }) readOnly: boolean = false;
  @Input({ required: true }) mode: 'add' | 'edit' = 'add';
  @Input({ required: true }) CurrencyCode;
  @Input({ required: true }) PaymentFormName: string;
  @Input({ required: true }) DateOfPayment: string;
  @Input({ required: true }) type: 'z' | 's' = 's';
  @Input({ required: true }) BankAccountId: string;
  @Input({ required: true }) CustomerId: number = 0;
  @Input({ required: true }) InvoiceDocumentId: number = 0;
  @Input() selected: PaymentSechedule[] = [];

  widthWindow: number | string = 550;
  heightWindow: number | string = 380;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  title: string = '';

  protected event = inject(EventService);
  protected appServices = inject(AppServices);
  private translate = inject(TranslateService);
  private formBuilder = inject(FormBuilder);

  shortcuts: ShortcutInput[] = [];
  form;
  radioType = signal<RadioType[]>([]);

  ngOnInit() {
    this.setRadioType();
    this.initForm();

    if (this.mode === 'edit') {
      this.patchFormValue();
    }
  }

  ngAfterViewInit(): void {
    this.initTitle();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  onShown() {
    setTimeout(() => {
      this.event.setFocus(this.firstInput);
    }, 0);
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit(false);
    }
  }

  private setRadioType() {
    this.radioType.set([
      {
        value: 'n',
        label: this.translate.instant('form-commercial-operation.net'),
      },
      {
        value: 'v',
        label: this.translate.instant('menu.fk.vat'),
      },
    ]);
  }

  initShortcuts() {
    this.shortcuts = [
      {
        key: 'F10',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          this.isVisible = false;
        },
        preventDefault: true,
      },
    ];
  }

  private initTitle() {
    this.title =
      this.mode === 'add'
        ? this.translate.instant('form.mode.add')
        : this.translate.instant('form.mode.edit');
  }

  private initForm() {
    this.form = this.formBuilder.group({
      Amount: [0],
      AmountCurrency: [0],
      CurrencyCode: [this.CurrencyCode],
      InvoiceDocumentId: [this.InvoiceDocumentId],
      InvoiceDocumentPaymentSecheduleId: [0],
      PaymentDate: [this.DateOfPayment],
      PaymentFormName: [this.PaymentFormName],
      TypeOfAmount: ['n'],
      BankAccountId: [this.BankAccountId],
      BankAccountNumber: [''],
      StateFlag: [0],
    });
  }

  onChoosedCurrency(e) {
    if (e) {
      this.form.patchValue({
        CurrencyCode: e.CurrencyCode,
      });
    }
  }

  private patchFormValue() {
    this.form.patchValue({
      Amount: this.selected[0].Amount,
      AmountCurrency: this.selected[0].AmountCurrency,
      CurrencyCode: this.selected[0].CurrencyCode,
      InvoiceDocumentId: this.selected[0].InvoiceDocumentId,
      InvoiceDocumentPaymentSecheduleId:
        this.selected[0].InvoiceDocumentPaymentSecheduleId,
      PaymentDate: this.selected[0].PaymentDate,
      PaymentFormName: this.selected[0].PaymentFormName,
      TypeOfAmount: this.selected[0].TypeOfAmount,
      StateFlag: this.selected[0].StateFlag,
      BankAccountId: this.selected[0].BankAccountId,
      BankAccountNumber: this.selected[0].BankAccountNumber,
    });
  }

  onChoosedFormPeyment(e) {
    if (e) {
      this.form.patchValue({
        PaymentFormName: e.PaymentFormName,
      });
    }
  }

  onChoosedCompanyBank(e) {
    if (e) {
      this.form.patchValue({
        BankAccountId: e.BankAccountId,
        BankAccountNumber: e.BankAccountNumber,
      });
    }
  }

  private httpSuccessNotification() {
    this.onInserted.emit();
    this.onClosing.emit(true);
  }

  onSave() {
    if (this.form.invalid) return;

    this.appServices
      .postAuth(
        `invoices/documents/${this.InvoiceDocumentId}/paymentSechedule/addOrModify`,
        this.form.value
      )
      .subscribe({
        next: () => {
          this.httpSuccessNotification();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  Input,
  Output,
  EventEmitter,
  input,
  signal,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { SetNumberComponent } from './set-number/set-number.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-warehouse-document-list-for-invoice-document',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxScrollViewModule,
        TranslateModule,
        DxDataGridModule,
        SetNumberComponent,
        NgShortcutsComponent,
    ],
    templateUrl: './warehouse-document-list-for-invoice-document.component.html',
    styleUrl: './warehouse-document-list-for-invoice-document.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarehouseDocumentListForInvoiceDocumentComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() isVisible: boolean = false;
  @Input() readOnly: boolean = false;

  invoiceDocumentid = input.required<number>();
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  translate = inject(TranslateService);
  event = inject(EventService);
  appService = inject(AppServices);

  widthWindow: number = 800;
  heightWindow: number = 500;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  title = this.translate.instant(
    'form-financial-document.commercialDocumentWarehouseDocuments'
  );

  dataSource = signal<any[]>([]);
  selectedRowKeys: any[] = [];
  focusedSelected: any[] = [];
  focusedRowIndex: number = 0;
  isSetNumber = signal<boolean>(false);
  shortcuts: ShortcutInput[] = [];

  constructor() {}

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClose();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSetNumber();
        },
        preventDefault: true,
      }
    );
  }

  getData() {
    this.appService
      .getAuth(
        `invoices/documents/${this.invoiceDocumentid()}/connectedWarehouseDocuments`
      )
      .subscribe({
        next: (data) => {
          this.dataSource.set(
            this.removeWarehouseDocumentWithAutoNumeration(data.data)
          );
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp()
  }

  removeWarehouseDocumentWithAutoNumeration(data) {
    for (let i = data.length - 1; i >= 0; i--) {
      if (
        this.event.checkAutoNumeration(
          'WarehouseDocuments',
          data[i].WarehouseDocumentTypeId
        ) ||
        data[i].WarehouseDocumentNumber !== '<auto>'
      ) {
        data.splice(i, 1);
      }
    }

    return data;
  }

  onFocusedRowChanged(e) {
    this.focusedSelected = [e.row.data];
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  checkWarehouseDocumentNumbers(array) {
    return array.every(
      (element) =>
        element.WarehouseDocumentNumber !== null &&
        element.WarehouseDocumentNumber !== '<auto>'
    );
  }

  onClose() {
    if (!this.checkWarehouseDocumentNumbers(this.dataSource())) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'form-financial-document.setNumberForAllDocument'
        )
      );
      return;
    }

    this.onInserted.emit(true);
  }

  onSetNumber() {
    if (this.focusedSelected.length > 0) this.isSetNumber.set(true);
  }

  onKeyDown(event: any) {
    const BLOCKED_KEYS = ['F2', 'Escape', 'Delete', 'Enter'];

    if (BLOCKED_KEYS.includes(event.event.key)) {
      event.event.preventDefault();
    }
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }
}

// this.isAutoNumeration = this.event.checkAutoNumeration(
//   'WarehouseDocuments',
//   this.form.value.WarehouseDocumentTypeId
// );

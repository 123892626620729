import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-modify-for-fr',
    templateUrl: './modify-for-fr.component.html',
    styleUrls: ['./modify-for-fr.component.scss'],
    inputs: ['readOnly', 'isVisible', 'title', 'documentData'],
    standalone: false
})
export class ModifyForFrComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onSaved = new EventEmitter();
  readOnly;
  isVisible;
  title;
  documentData;
  unicalGuid;
  widthWindow = 430;
  heightWindow = 150;
  maxHeight;
  maxWidth;
  Amount = 0;

  shortcuts: ShortcutInput[] = [];
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
  }

  onCancel = () => {
    this.closeWindow();
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  closeWindow() {
    this.onClosing.emit(true);
  }

  ClassValue: string = '';
  onSave = () => {
    if (!this.readOnly) {
      this.appService
        .putAuth(
          `invoices/additionalOperations/${this.documentData.InvoiceDocumentId}/modifyPositionsForFR`,
          {
            InvoiceDocumentId: this.documentData.InvoiceDocumentId,
            ClassValue: this.ClassValue,
          }
        )
        .subscribe(
          () => {
            this.onSaved.emit(true);
          },
          (error) => {
            this.event.loadingVisible = false;
            this.event.httpErrorNotification(error);
          }
        );
    }
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onSave()"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
        data-cy="cancel-verification-form-payment"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="d-flex justify-content-center flex-wrap">
        <dx-data-grid
          [showBorders]="true"
          [wordWrapEnabled]="false"
          [hoverStateEnabled]="true"
          [dataSource]="dataSource"
          shortcut
          [focusedRowEnabled]="true"
          [allowColumnResizing]="true"
          [columnResizingMode]="'widget'"
          [(selectedRowKeys)]="selectedRowKeys"
          style="
            width: 100%;
            min-height: 50px;
            margin-bottom: 10px;
            margin-top: 10px;
          "
          [height]="250"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          (onRowDblClick)="onRowDblClick()"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection
            mode="single"
            showCheckBoxesMode="always"
            selectAllMode="page"
          ></dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            [caption]="
              'form-commercial-operation.formDescriptionOrOrderNumber'
                | translate
            "
            dataField="Description"
            [allowSorting]="false"
          >
          </dxi-column>
          <dxi-column
            [caption]="'form-payment.paymentMethod' | translate"
            dataField="FormPayment"
            [allowSorting]="false"
          >
          </dxi-column>
          <dxi-column
            [caption]="'form-commercial-operation.dateOfPayment' | translate"
            width="140"
            dataField="DayOfPayment"
            [allowSorting]="false"
          >
          </dxi-column>
          <dxi-column
            [caption]="'form-commercial-operation.dateOfDaymentts' | translate"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            dataField="dateOfPayment"
            [allowSorting]="false"
          >
          </dxi-column>
        </dx-data-grid>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<dx-drop-down-box
  #countryDropDownBox
  [dataSource]="availableValue"
  [valueExpr]="codeId"
  [width]="width || 276"
  [deferRendering]="false"
  [displayExpr]="code"
  [(opened)]="isGridBoxOpened"
  [(value)]="choosingValue"
  [showClearButton]="true"
  (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
  (openedChange)="onOpenedChanged($event)"
  [ngClass]="{ 'input-required': className == true }"
  [readOnly]="readOnly"
>
  <div *dxTemplate="let data of 'content'" style="height: 280px">
    <div class="row">
      <div class="col-md-12" class="no-padding" *ngIf="isGridBoxOpened">
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="true"
          [showBorders]="true"
          shortcut
          [height]="258"
          [(selectedRowKeys)]="selectedCode"
          [columnResizingMode]="'widget'"
          [remoteOperations]="true"
          (onRowClick)="onRowDblClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onSelectionChanged)="onSelectionChanged($event)"
          [focusedRowEnabled]="true"
          #gridClass
          [hoverStateEnabled]="true"
          style="width: calc(100% + 10px)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection [mode]="'single'" showCheckBoxesMode="always">
          </dxo-selection>

          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'delivery'">
              <dxi-column
                [width]="event.deviceType != 'mobile' ? 45 : 10"
                cellTemplate="editTemplate"
                [allowSorting]="false"
                alignment="center"
              >
              </dxi-column>

              <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
                <dx-button
                  class="btn-dx-top-menu-folder-grid"
                  icon="icon absui-icon--mode-edit hover-edit-icon"
                  [disabled]="readOnly"
                  (onClick)="onEditClick($event)"
                >
                </dx-button>
              </div>
              <dxi-column
                dataField="IsDefault"
                caption=" "
                width="30"
                cellTemplate="IsDefault"
              ></dxi-column>
              <div *dxTemplate="let data of 'IsDefault'">
                <i
                  class="icon absui-icon--check check-grid"
                  *ngIf="data.data.IsDefault"
                ></i>
                <i
                  class="icon absui-icon--close close-grid"
                  *ngIf="!data.data.IsDefault"
                ></i>
              </div>
              <dxi-column
                dataField="DeliveryCode"
                [caption]="
                  'intrastate-documents.new-position.DeliveryCode' | translate
                "
              ></dxi-column>

              <dxi-column
                dataField="Name"
                [caption]="'companies.grid.columns.name' | translate"
              ></dxi-column>
            </ng-container>

            <ng-container *ngSwitchCase="'transport'">
              <dxi-column
                [width]="45"
                cellTemplate="editTemplate"
                [allowSorting]="false"
                alignment="center"
              >
              </dxi-column>

              <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
                <dx-button
                  class="btn-dx-top-menu-folder-grid"
                  icon="icon absui-icon--mode-edit hover-edit-icon"
                  [disabled]="readOnly"
                  (onClick)="onEditClick($event)"
                >
                </dx-button>
              </div>
              <dxi-column
                dataField="IsDefault"
                caption=" "
                width="30"
                cellTemplate="IsDefault"
              ></dxi-column>
              <div *dxTemplate="let data of 'IsDefault'">
                <i
                  class="icon absui-icon--check check-grid"
                  *ngIf="data.data.IsDefault"
                ></i>
                <i
                  class="icon absui-icon--close close-grid"
                  *ngIf="!data.data.IsDefault"
                ></i>
              </div>
              <dxi-column
                dataField="TransportCode"
                [caption]="
                  'intrastate-documents.new-position.TransportCode' | translate
                "
              ></dxi-column>

              <dxi-column
                dataField="Name"
                [caption]="'companies.grid.columns.name' | translate"
              ></dxi-column>
            </ng-container>

            <ng-container *ngSwitchCase="'transaction'">
              <dxi-column
                [width]="45"
                cellTemplate="editTemplate"
                [allowSorting]="false"
                alignment="center"
              >
              </dxi-column>

              <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
                <dx-button
                  class="btn-dx-top-menu-folder-grid"
                  icon="icon absui-icon--mode-edit hover-edit-icon"
                  [disabled]="readOnly"
                  (onClick)="onEditClick($event)"
                >
                </dx-button>
              </div>
              <dxi-column
                dataField="IsDefault"
                caption=" "
                width="30"
                cellTemplate="IsDefault"
              ></dxi-column>
              <div *dxTemplate="let data of 'IsDefault'">
                <i
                  class="icon absui-icon--check check-grid"
                  *ngIf="data.data.IsDefault"
                ></i>
                <i
                  class="icon absui-icon--close close-grid"
                  *ngIf="!data.data.IsDefault"
                ></i>
              </div>
              <dxi-column
                dataField="TransactionCode"
                [caption]="
                  'intrastate-documents.new-position.TransactionCode'
                    | translate
                "
              ></dxi-column>

              <dxi-column
                dataField="Name"
                [caption]="'companies.grid.columns.name' | translate"
              ></dxi-column>
            </ng-container>
          </ng-container>
        </dx-data-grid>
      </div>
    </div>
    <div
      class="bottom-drop-box"
      style="margin-left: -20px; width: calc(100% + 40px)"
    >
      <!-- [ngClass]="{'btn-dx-top-menu':!dropDownBoxMode, 'btn-dx-top-menu-folder': dropDownBoxMode}" -->
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        [disabled]="readOnly"
        (onClick)="addCode()"
        [id]="'add' + unicalGuid"
      >
      </dx-button>

      <dx-button
        (onClick)="isDelete()"
        [disabled]="readOnly || !selectedCodeId?.length"
        [id]="'delete' + unicalGuid"
        [text]="'buttons.delete' | translate"
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--highlight-off"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu"
        icon="icon absui-icon--check-circle"
        [id]="'default' + unicalGuid"
        [text]="'buttons.setDefault' | translate"
        [disabled]="readOnly || !selectedCodeId?.length"
        (onClick)="onSetDefault()"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#delete' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#default' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.setDefault" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <app-add-trans-del-transp-codes
      [type]="'transaction'"
      [isVisible]="addTransactionCode"
      (onClosing)="onClose()"
      (onInserted)="onInserted()"
    >
    </app-add-trans-del-transp-codes>

    <app-add-trans-del-transp-codes
      [type]="'delivery'"
      [isVisible]="addDeliveryCode"
      (onClosing)="onClose()"
      (onInserted)="onInserted()"
    >
    </app-add-trans-del-transp-codes>

    <app-add-trans-del-transp-codes
      [type]="'transport'"
      [isVisible]="addTransportCode"
      (onClosing)="onClose()"
      (onInserted)="onInserted()"
    >
    </app-add-trans-del-transp-codes>

    <app-add-trans-del-transp-codes
      [selectedCode]="selectedCode[0]"
      [type]="'transaction'"
      [selectedCodeId]="selectedCodeId[0]"
      [isVisible]="editTransactionCode"
      (onClosing)="onClose()"
      (onInserted)="onInserted()"
    >
    </app-add-trans-del-transp-codes>

    <app-add-trans-del-transp-codes
      [selectedCode]="selectedCode[0]"
      [selectedCodeId]="selectedCodeId[0]"
      [type]="'delivery'"
      [isVisible]="editDeliveryCode"
      (onClosing)="onClose()"
      (onInserted)="onInserted()"
    >
    </app-add-trans-del-transp-codes>

    <app-add-trans-del-transp-codes
      [selectedCode]="selectedCode[0]"
      [selectedCodeId]="selectedCodeId[0]"
      [type]="'transport'"
      [isVisible]="editTransportCode"
      (onClosing)="onClose()"
      (onInserted)="onInserted()"
    >
    </app-add-trans-del-transp-codes>
  </div>
</dx-drop-down-box>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="
    !isGridBoxOpened ||
    addTransactionCode ||
    addTransportCode ||
    addDeliveryCode ||
    editTransactionCode ||
    editTransportCode ||
    editDeliveryCode ||
    isDeleteCode
  "
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  [isVisible]="isDeleteCode"
  (onRemoving)="delete(); gridClass.instance.focus()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

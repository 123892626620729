import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
  inject,
  Input,
  OnInit,
  OnDestroy,
  signal,
  AfterViewInit,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { PipesModule } from '../../../pipes//pipes.module';
import { AddPaymentScheduleComponent } from './add-payment-schedule/add-payment-schedule.component';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { ConfirmDialogComponent } from '../../../core/confirm-dialog/confirm-dialog.component';

interface SumPosition {
  TotalGrossAmount: number;
  TotalNetAmount: number;
  TotalTaxAmount: number;
  TotalGrossAmountCurrency: number;
  TotalNetAmountCurrency: number;
  TotalTaxAmountCurrency: number;
}

interface PaymentSechedule {
  Amount: number;
  AmountCurrency: number;
  CurrencyCode: string;
  InvoiceDocumentId: number;
  InvoiceDocumentPaymentSecheduleId: number;
  PaymentDate: string;
  PaymentFormName: string;
  BankAccountId: number;
  BankAccountNumber: string;
  StateFlag: number;
  TypeOfAmount: string;
}

@Component({
    selector: 'app-payment-schedule',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxScrollViewModule,
        TranslateModule,
        PipesModule,
        DxDataGridModule,
        AddPaymentScheduleComponent,
        NgShortcutsComponent,
        ConfirmDialogComponent,
    ],
    templateUrl: './payment-schedule.component.html',
    styleUrl: './payment-schedule.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentScheduleComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Output() onInserted = new EventEmitter();
  @Output() onClosing = new EventEmitter();
  @Input() isVisible: boolean = false;

  @Input({ required: true }) readOnly: boolean = false;
  @Input({ required: true }) sumPosition: SumPosition;
  @Input({ required: true }) DateOfPayment: string;
  @Input({ required: true }) PaymentFormName: string;
  @Input({ required: true }) BankAccountId: string;
  @Input({ required: true }) CurrencyCode: string;
  @Input({ required: true }) type: 'z' | 's' = 's';
  @Input({ required: true }) CustomerId: number = 0;
  @Input({ required: true }) IsApproveSettlementsActive: boolean = false;
  @Input({ required: true }) InvoiceDocumentId: number = 0;

  protected event = inject(EventService);
  protected appServices = inject(AppServices);

  widthWindow: number | string = 900;
  heightWindow: number | string = 600;

  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  dataSource = signal<PaymentSechedule[]>([]);
  heightGrid: number = 420;
  selectedRows: PaymentSechedule[] = [];
  focusedSelected: PaymentSechedule[] = [];

  isHold = false;
  timerSel: any;
  holdDelayInMs = 250;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex: number = 0;
  mode: 'add' | 'edit' = 'add';
  isAddRow = signal<boolean>(false);
  shortcuts: ShortcutInput[] = [];
  isDeleteRow = signal<boolean>(false);

  async ngOnInit() {
    if (this.IsApproveSettlementsActive) {
      await this.prepareForModify();
    }

    this.getPaymentSechedule();
  }

  ngAfterViewInit(): void {
    this.initShortcuts();
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp();
  }

  initShortcuts() {
    this.shortcuts = [
      {
        key: 'Insert',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          this.onAdd();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          this.onEdit();
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          alert('del');
        },
        preventDefault: true,
      },

      {
        key: 'ctrl + g',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          this.generatePaymentSechedule();
        },
        preventDefault: true,
      },

      {
        key: 'f10',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          alert('zatwierdz');
        },
        preventDefault: true,
      },

      {
        key: 'escape',
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: async () => {
          await this.cancel();
        },
        preventDefault: true,
      },
    ];
  }

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e): void {
    if ([13, 27, 113, 114].includes(e.event.keyCode)) {
      e.event.preventDefault();
    }
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit(false);
    }
  }

  onAdd() {
    if (this.readOnly) return;
    this.mode = 'add';
    this.isAddRow.set(true);
  }

  async onEdit() {
    if (this.readOnly || this.focusedSelected.length === 0) return;
    this.mode = 'edit';
    this.isAddRow.set(true);
  }

  onDelete() {
    if (this.readOnly || this.focusedSelected.length === 0) return;
    this.isDeleteRow.set(true);
  }

  deleteItem() {
    this.isDeleteRow.set(false);
    this.event.onShown();
    this.appServices
      .deleteAuth(
        `invoices/documents/${this.InvoiceDocumentId}/paymentSechedule/${this.focusedSelected[0].InvoiceDocumentPaymentSecheduleId}`
      )
      .subscribe({
        next: () => {
          this.getPaymentSechedule();
          this.event.onHidden();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          this.event.onHidden();
        },
      });
  }

  private prepareForModify() {
    return new Promise((resolve) => {
      this.appServices
        .putAuth(
          `invoices/documents/${this.InvoiceDocumentId}/paymentSechedule/prepareForModify `,
          null
        )
        .subscribe({
          next: () => {
            resolve(true);
          },
          error: (err) => {
            this.event.httpErrorNotification(err);
          },
        });
    });
  }

  protected getPaymentSechedule() {
    this.appServices
      .getAuth(`invoices/documents/${this.InvoiceDocumentId}/paymentSechedule`)
      .subscribe({
        next: (res: PaymentSechedule[]) => {
          this.dataSource.set(res);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  protected generatePaymentSechedule() {
    this.appServices
      .postAuth(
        `invoices/documents/${this.InvoiceDocumentId}/paymentSechedule/generate`,
        null
      )
      .subscribe({
        next: () => {
          this.getPaymentSechedule();
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }

  onRowDblClick() {
    this.onEdit();
  }

  cancel() {
    return new Promise((resolve) => {
      if (this.IsApproveSettlementsActive) {
        this.appServices
          .putAuth(
            `invoices/documents/${this.InvoiceDocumentId}/paymentSechedule/cancel`,
            null
          )
          .subscribe({
            next: () => {
              resolve(true);
              this.onClosing.emit(true);
            },
            error: (err) => {
              this.event.httpErrorNotification(err);
            },
          });
      } else {
        resolve(true);
        this.onClosing.emit(true);
      }
    });
  }

  onSave() {
    this.appServices
      .putAuth(
        `invoices/documents/${this.InvoiceDocumentId}/paymentSechedule/accept`,
        null
      )
      .subscribe({
        next: () => {
          this.onInserted.emit(true);
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
        },
      });
  }
}

<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  [title]="event.deviceType != 'mobile' ? title : ''"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="840"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getData()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{
    id: unicalGuid,
  }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{
          'form-commercial-operation.connectWithAnotherDelivery' | translate
        }}"
        type="success"
        style="margin-right: 6px"
        [id]="'choosed' + unicalGuid"
        *ngIf="!readOnly"
        [disabled]="readOnly || focusedSelected.le"
        (onClick)="connectWithAnotherDelivery()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#choosed' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{
            "form-commercial-operation.connectWithAnotherDelivery" | translate
          }}
          (F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (esc)
        </div>
      </dx-tooltip>
    </div>

    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [height]="heightGrid"
          [columnResizingMode]="'widget'"
          (onInitialized)="onInitialized($event)"
          [remoteOperations]="true"
          (onRowDblClick)="onRowDblClick()"
          [columnAutoWidth]="true"
          [allowColumnResizing]="true"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          [id]="'grid' + unicalGuid"
          (onContentReady)="onContentReady($event)"
          (onKeyDown)="onKeyDown($event)"
          [(focusedRowIndex)]="focusedRowIndex"
          [focusedRowEnabled]="true"
          #gridBank
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection
            mode="single"
            showCheckBoxesMode="always"
            selectAllMode="page"
          >
          </dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            caption="{{
              'servicesDevices.warehouseDocumentNumber' | translate
            }}"
            dataField="WarehouseDocumentNumber"
            [allowSorting]="false"
            [width]="150"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-financial-document.posted' | translate }}"
            dataField="IsDocumentPosted"
            [allowSorting]="false"
            [width]="100"
            cellTemplate="cellTemplateIsDocumentPosted"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'cellTemplateIsDocumentPosted'">
            <i
              class="icon absui-icon--status-ok-green"
              *ngIf="data.data.IsDocumentPosted"
            ></i>
          </div>

          <dxi-column
            caption="{{ 'offers.warehouseDocDate' | translate }}"
            dataField="WarehouseDocumentDate"
            [allowSorting]="false"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            caption="{{
              'form-financial-document.payer-type.contractor' | translate
            }}"
            dataField="CustomerName"
            [allowSorting]="false"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'loyaltyProram.commercialDocumentNumber' | translate }}"
            dataField="InvoiceDocumentNumber"
            [allowSorting]="false"
            [width]="150"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'offers.tradingDocDate' | translate }}"
            dataField="InvoiceDocumentDate"
            [allowSorting]="false"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
          >
          </dxi-column>

          <dxi-column
            caption="{{ 'form-commercial-operation.Released' | translate }}"
            dataField="AmountOfReleased"
            [allowSorting]="false"
            [width]="150"
            cellTemplate="cellTemplateCount"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'cellTemplateCount'; let index = i">
            {{ data.data.AmountOfReleased | amountFormatter : data : 4 }}
          </div>

          <dxi-column
            caption="{{
              'form-commercial-operation.grid.netAmount' | translate
            }}"
            [allowSorting]="false"
            width="100"
            dataField="PriceNet"
            cellTemplate="PriceNet"
            [hidingPriority]="15"
          >
            <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'PriceNet'">
            {{ data.data.PriceNet | amountFormatter : data }}
          </div>

          <dxi-column
            caption="{{
              'form-commercial-operation.grid.grossAmount' | translate
            }}"
            [allowSorting]="false"
            width="100"
            dataField="PriceGross"
            cellTemplate="PriceGross"
            [hidingPriority]="15"
          >
            <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
          </dxi-column>
          <div *dxTemplate="let data of 'PriceGross'">
            {{ data.data.PriceGross | amountFormatter : data }}
          </div>
        </dx-data-grid>
      </div>
    </div>
    <wapro-keyboard-shortcuts
      [shortcuts]="shortcuts"
      [unicalGuid]="unicalGuid"
      [disabled]="!isVisible"
    >
    </wapro-keyboard-shortcuts>
  </div>
</dx-popup>

<app-released-sugestion
  *ngIf="isReleased"
  [isVisible]="true"
  [position]="focusedSelected"
  [WarehouseDocumentPositionIdPrevious]="
    position[0]?.WarehouseDocumentPositionIdPrevious
  "
  [warehouseDocumentId]="warehouseDocumentId"
  (onClosing)="isReleased = false; getData(); cd.detectChanges()"
></app-released-sugestion>

<app-confirm-dialog
  kindDialog="question"
  *ngIf="IsDocumentPosted"
  [confirmText]="
    'form-commercial-operation.warehouseDocumentIsPosted' | translate
  "
  [isVisible]="true"
  (onRemoving)="yesIsPosted()"
  (onClosing)="IsDocumentPosted = false; cd.detectChanges()"
>
</app-confirm-dialog>

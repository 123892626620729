<div *ngIf="!dropDownBoxMode && !popUpMode">
  <ng-container *ngIf="componentVisible">
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="popUpMode">
  <dx-popup
    [width]="widthPopup"
    [height]="
      event.deviceType != 'mobile' ? 'calc(100vh - 32px)' : 'calc(100% - 100px)'
    "
    position="bottom"
    [showCloseButton]="false"
    [animation]="{
      show: { type: 'slideIn', direction: 'bottom' },
      hide: { type: 'slideOut', direction: 'bottom' }
    }"
    [showTitle]="true"
    [resizeEnabled]="true"
    [title]="title"
    [visible]="isVisible"
    (visibleChange)="visibleChange($event)"
    (onShown)="event.onShownPopUp()"
    (onHidden)="event.onHiddenPopUp()"
    [wrapperAttr]="{ id: unicalGuid }"
  >
    <div *dxTemplate="let content of 'content'">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px;"
          (onClick)="onChoosedDocuments()"
          [id]="'contractors' + unicalGuid"
          *ngIf="
            (!readOnly && !isSelected) ||
            (!readOnly && isDisconnectWarehouseDocument)
          "
          [disabled]="focusedSelected.length == 0 || readOnly"
          data-cy="warehouse-choosed-btn"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ 'buttons.close' | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <dx-scroll-view width="100%" height="100%" direction="vertical">
        <div class="top-menu-popup">
          <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div>
        <br />
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </dx-scroll-view>
    </div>
  </dx-popup>
</div>

<div *ngIf="dropDownBoxMode">
  <dx-drop-down-box
    [(value)]="choosingDocument"
    valueExpr="WarehouseDocumentId"
    [deferRendering]="false"
    displayExpr="WarehouseDocumentNumber"
    [(opened)]="isGridBoxOpened"
    [dataSource]="documentList"
    [showClearButton]="true"
    style="width: 276px; height: 26px;"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="onOpenedChanged($event)"
    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
    [readOnly]="readOnly"
  >
    <div
      *dxTemplate="let data of 'content'"
      style="height: 280px; min-height: 280px;"
    >
      <div class="row">
        <div class="col-md-12" class="no-padding">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="258"
            [(selectedRowKeys)]="selectedRows"
            [ngClass]="{ dropDownBoxClassGrid: dropDownBoxMode }"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            (onSelectionChanged)="onSelectionChanged($event)"
            [focusedRowEnabled]="true"
            #gridBank
            [hoverStateEnabled]="true"
            (onEditorPreparing)="onEditorPreparing($event)"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              [mode]="dropDownBoxMode ? 'single' : 'multiple'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxi-column
              [width]="event.deviceType != 'mobile' ? 45 : 10"
              cellTemplate="editTemplate"
              [allowSorting]="false"
              alignment="center"
            ></dxi-column>

            <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
              <dx-button
                class="btn-dx-top-menu-folder-grid"
                icon="icon absui-icon--mode-edit hover-edit-icon"
                [disabled]="readOnly"
                (onClick)="showItem()"
              ></dx-button>
            </div>
            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [width]="150"
              [allowSorting]="false"
              dataField="WarehouseDocumentNumber"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [allowSorting]="false"
              dataField="DateOfWarehouseDocument"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              [cssClass]="
                localStorageData.filter.orderBy == 'WarehouseDateOfDocument'
                  ? 'sort-column-bg'
                  : 'sort-column-muted'
              "
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              dataField="TotalNetAmount"
              [allowSorting]="false"
              width="100"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              dataField="TotalGrossAmount"
              [allowSorting]="false"
              width="100"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>

      <div class="bottom-drop-box">
        <dx-button
          [ngClass]="{
            'btn-dx-top-menu': !dropDownBoxMode,
            'btn-dx-top-menu-folder': dropDownBoxMode
          }"
          icon="icon absui-icon--add-circle"
          [text]="'buttons.add' | translate"
          [disabled]="readOnly"
          (onClick)="beforeAddRowVisible()"
        ></dx-button>
      </div>
    </div>
  </dx-drop-down-box>
</div>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <div
      class="d-inline-block warehouse-documents-step-1"
      style="padding: 1px 0;"
    >
      <app-wapro-selected-box
        [(ngModel)]="selectedRows"
        [dataSource]="dataSource"
        [selectedAmount]="selectedAmount()"
        [selectedAll]="isAllSelected"
        (onDeselectAll)="onDeselectAll()"
        (onOnlySelected)="onOnlySelected()"
      ></app-wapro-selected-box>

      <dx-button
        class="btn-dx-top-menu-folder"
        stylingMode="text"
        type="default"
        icon="icon absui-icon--add-circle"
        [id]="'btn-add' + unicalGuid"
        [disabled]="readOnly || !perABD.addBtn"
        (onClick)="beforeAddRowVisible()"
        data-cy="add-warehouse-documents-btn"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        stylingMode="text"
        type="default"
        icon="icon absui-icon--mode-edit"
        [id]="'btn-edit' + unicalGuid"
        (onClick)="editDocument()"
        [disabled]="!perABD.editBtn || readOnly || focusedSelected.length != 1"
        data-cy="edit-warehouse-documents-btn"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--form-items"
        stylingMode="text"
        type="default"
        [id]="'contractorsShow' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="focusedSelected.length != 1 || !perABD.showBtn"
        (onClick)="editDocument(true, 'IsOutcome')"
        (disabledChange)="disabledChange($event)"
        *ngIf="!dropDownBoxMode"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--highlight-off"
        stylingMode="text"
        type="default"
        [id]="'btn-delete' + unicalGuid"
        class="btn-dx-top-menu-folder"
        [disabled]="
          focusedSelected.length == 0 || readOnly || !perABD.deleteBtn
        "
        (onClick)="isDelete()"
        data-cy="delete-warehouse-documents-btn"
      >
        <!-- [DISABLED-text]="'buttons.delete' | translate" -->
      </dx-button>

      <dx-button
        icon="icon absui-icon--sum"
        stylingMode="text"
        type="default"
        [id]="'suma' + unicalGuid"
        class="btn-dx-top-menu"
        [text]="'stockHistory.sum' | translate"
        (onClick)="isShowSum = true"
        *ngIf="!dropDownBoxMode"
        [disabled]="!perSum"
      ></dx-button>

      <dx-button
        icon="icon absui-icon--file-edit"
        stylingMode="text"
        type="default"
        [id]="'correction' + unicalGuid"
        class="btn-dx-top-menu"
        [disabled]="focusedSelected.length == 0 || readOnly"
        [text]="'warehouseDocument.corrections' | translate"
        (onClick)="showCorrect()"
        *ngIf="!dropDownBoxMode"
        data-cy="correction-warehouse-documents-btn"
      ></dx-button>

      <app-print-settings
        componentName="warehouseDocuments"
      ></app-print-settings>

      <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>
      <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

      <app-btn-export-excel
        [gridReference]="gridBank"
        [nameFile]="'menu.warehouseDocuments' | translate"
        url="WarehouseDocuments/documents"
        [paramsObj]="paramsObj"
      ></app-btn-export-excel>

      <app-product-scaner
        #scaner
        [readOnly]="readOnly"
        [showScanerContainer]="false"
        (onInputChanged)="onScanerInputChanged($event)"
        [keyboardShortcutsDisabled]="
          isTask ||
          showOrderDocuments ||
          isPzPostponed ||
          visibleUserExtensions ||
          isVerificationPosition ||
          isNewInvoiceDocument ||
          isOutcomeDocument ||
          confirmEditingWarehouseDocumentRelatedSADDDocument ||
          addRow ||
          isCustomer ||
          editInventory ||
          isExternalTableVisible ||
          isShortcutTableOpened ||
          isCloneDocument ||
          isAddDescription ||
          isDeleteRow ||
          isDeleteMp ||
          isDeleteInventory ||
          isShowSum ||
          isCorrection ||
          isLabel ||
          isBlockRow ||
          isExpansionListVisible ||
          showRelatedItems ||
          isClipboardVisible ||
          localStorageData?.sidePanel?.isVisible ||
          isOstrzegODokumZDnia ||
          isDelivery ||
          (!isVisible && popUpMode)
        "
        [parentId]="unicalGuid"
      ></app-product-scaner>

      <app-additional-operation
        #additionalOperation
        nameComponent="WarehouseDocumentsComponent"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      ></app-additional-operation>

      <dx-button
        icon="icon absui-icon--tables"
        [id]="'tables' + unicalGuid"
        class="btn-dx-top-menu"
        *ngIf="additionalTables.length"
        [disabled]="focusedSelected.length == 0"
        [text]="'buttons.tables' | translate"
        (onClick)="isExternalTableVisible = true"
      ></dx-button>
    </div>

    <dx-tooltip
      [target]="'#tables' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.tables' | translate }} (Ctrl + Y)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <ng-container *ngIf="exportFakirService.isSelectMode()">
        <dx-button
          text="{{ 'buttons.choose' | translate }}"
          type="success"
          style="margin-right: 6px;"
          (onClick)="selectChoosed()"
          [id]="'choose' + unicalGuid"
        ></dx-button>

        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="cancelChoosed()"
          [id]="'cancel' + unicalGuid"
        ></dx-button>
      </ng-container>

      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData.sidePanel.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px;"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        stylingMode="text"
        type="default"
        (onClick)="runGuide()"
        style="margin-right: 6px;"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        stylingMode="text"
        type="default"
        (click)="getDocuments(); refreshTypeTree(null)"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px;"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder warehouse-documents-step-2"
        icon="column-chooser"
        stylingMode="text"
        type="default"
        (click)="columnsChooserVisible = true"
        *ngIf="permissionToChooseColums && !dropDownBoxMode"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#choose' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.choose' | translate }} (Enter)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.cancel' | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-show-dynamic-filters' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'filterPanel.filters' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'userGuide.runGuideAgain' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'menu.navigationPanelUser.help' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#eDocuments' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.eDocuments' | translate }} (Ctrl + E)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'refresh' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'columnSettings' | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.add' | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#suma' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'stockHistory.sum' | translate }} (Ctrl + S)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.edit' | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#contractorsShow' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.show' | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#correction' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'warehouseDocument.corrections' | translate }} (Ctrl + K)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.delete' | translate }} (Del)
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          [ngClass]="{
            without_search_panel: dropDownBoxMode,
            with_search_panel: !dropDownBoxMode
          }"
          #rightPanel
          [style.width]="localStorageData.mainPanelWidth"
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="openCustomDropDown"
                class="warehouse-documents-step-3"
                [items]="filterCriteria"
                [(selectedItem)]="localStorageData.filter.orderBy"
                [(filterValue)]="filterValue"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px;"
                (onArrowDown)="gridBank.instance.focus()"
                #searchControl
              ></app-custom-dropdown-box>

              <div
                class="d-inline-block warehouse-documents-step-4"
                style="line-height: 38px;"
              >
                <app-date-range
                  [readOnly]="readOnly"
                  [isPopupMode]="true"
                  (onChoosed)="onDateRangeChoosed()"
                  #dateRange
                  [chipsVisible]="true"
                  data-cy="warehouse-documents-date-range"
                ></app-date-range>
                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="onlySelected"
                >
                  {{ 'showSelected' | translate }}:
                  <b>{{ 'yes' | translate }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('onlySelected')"
                  ></i>
                </dx-button>

                <app-custom-chips-button
                  [deleteFilter]="deleteFilter"
                  name="Status"
                  [list]="filterCriteriaStatus"
                  (onValueChanged)="onValueChangedState($event)"
                ></app-custom-chips-button>

                <app-custom-chips-button
                  [deleteFilter]="deleteFilter"
                  name="{{ 'workers.attachment' | translate }}"
                  [list]="filterCriteriaAtachments"
                  (onValueChanged)="onAtachmentsValueChanged($event)"
                ></app-custom-chips-button>

                <app-custom-chips-button
                  [deleteFilter]="deleteFilter"
                  name="{{ 'menu.commercialDocuments' | translate }}"
                  [list]="documentFinances"
                  (onValueChanged)="onComercialDocumentValueChanged($event)"
                ></app-custom-chips-button>

                <dx-button
                  class="btn-filter-box"
                  [ngClass]="{ 'chips-btn': customerFilter.CustomerId != null }"
                  (onClick)="isCustomer = true; cd.detectChanges()"
                >
                  {{
                    'form-financial-document.payer-type.contractor' | translate
                  }}
                  <b
                    style="margin-left: 6px;"
                    *ngIf="customerFilter.CustomerId != null"
                  >
                    {{ customerFilter.CustomerName }}
                  </b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetContrahentFilter()"
                    *ngIf="customerFilter.CustomerId != null"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="typeFilter.warehouseDocumentTypeId != null"
                  (click)="openRightPanel()"
                >
                  {{ 'form-financial-document.type' | translate }}:
                  <b>{{ typeFilter.typeName }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetType()"
                  ></i>
                </dx-button>

                <app-custom-chips-button
                  *ngIf="
                    localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                    localStorageData?.advancedFilter?.IsPinned
                  "
                  [name]="'filterPanel.template' | translate"
                  [labelExpr]="'Name'"
                  [valueExpr]="'FilterUsersDefinitionId'"
                  [selectedValueInput]="
                    localStorageData?.advancedFilter?.Name || null
                  "
                  [list]="dynamicFilterUserDefinitions"
                  (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
                ></app-custom-chips-button>

                <app-view-dynamic-quick-filters
                  [dictionaryCode]="DynamicFilterDictionaryCode"
                  [pinnedFilters]="
                    localStorageData.advancedFilter?.PinnedFilters
                  "
                  [values]="
                    localStorageData?.advancedFilter?.UserFilterDefinitionValues
                  "
                  (onValueChanged)="onPinnedValueChanged($event)"
                ></app-view-dynamic-quick-filters>

                <dx-button
                  [id]="'clearValues' + unicalGuid"
                  class="btn-filter-box"
                  icon="icon absui-icon--clear-filter"
                  (onClick)="clearValues()"
                ></dx-button>
                <dx-tooltip
                  [target]="'#clearValues' + unicalGuid"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ 'clearAllFilters' | translate }} (Ctrl + F10)
                  </div>
                </dx-tooltip>
              </div>

              <dx-tooltip
                [target]="'#customer' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'buffor.selectAontractor' | translate }} (F7)
                </div>
              </dx-tooltip>
              <dx-tooltip
                [target]="'#range' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'buffor.setDateRange' | translate }} (F9)
                </div>
              </dx-tooltip>

              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                [(selectedRowKeys)]="selectedRows"
                class=""
                [ngClass]="{
                  dropDownBoxClassGrid: dropDownBoxMode,
                  'focus-on-detail': isDetailOnFocus
                }"
                [columnResizingMode]="'widget'"
                (onInitialized)="onInitialized($event)"
                [remoteOperations]="true"
                [autoNavigateToFocusedRow]="true"
                (onRowDblClick)="onRowDblClick()"
                [columnAutoWidth]="true"
                [allowColumnResizing]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [id]="'grid' + unicalGuid"
                (onContentReady)="onContentReady($event)"
                (onKeyDown)="onKeyDown($event)"
                [allowColumnReordering]="true"
                (onCellClick)="onCellClick($event)"
                (onFocusedCellChanged)="onFocusedCellChanged($event)"
                [(focusedRowIndex)]="focusedRowIndex"
                (onSelectionChanged)="onSelectionChanged($event)"
                [focusedRowEnabled]="true"
                #gridBank
                (onOptionChanged)="onOptionChanged($event)"
                (onRowClick)="onRowClick($event)"
                (onEditorPreparing)="onEditorPreparing($event)"
                data-cy="warehouse-documents-grid"
                (onContextMenuPreparing)="contextMenuPreparing($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="infinite"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>

                <dxo-selection
                  [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                ></dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                <dxo-column-fixing [enabled]="!popUpMode"></dxo-column-fixing>

                <dxi-column
                  cellTemplate="markerConfigTemplate"
                  [fixed]="true"
                  [width]="0"
                ></dxi-column>
                <div *dxTemplate="let data of 'markerConfigTemplate'">
                  <div
                    *ngIf="data.data.Semaphore"
                    [ngClass]="{
                      editRowByMe: userId == data.data.Semaphore,
                      editRowByAnotherUser: userId != data.data.Semaphore
                    }"
                  ></div>
                </div>
                <dxi-column
                  caption=""
                  [width]="26"
                  [allowReordering]="false"
                  [allowSorting]="false"
                  dataField="IsBlocked"
                  cellTemplate="blockTemplate"
                  headerCellTemplate="blockHeaderTemplate"
                  [cssClass]="'sorting-unavailable'"
                  [allowExporting]="false"
                ></dxi-column>
                <div
                  *dxTemplate="let data of 'blockHeaderTemplate'"
                  class="text-center"
                >
                  <div
                    class="grid-header-wrapper"
                    [id]="'IsBlocked' + unicalGuid"
                  >
                    S
                  </div>
                  <dx-tooltip
                    [target]="'#IsBlocked' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{
                        'form-commercial-operation.documentStatus' | translate
                      }}
                    </div>
                  </dx-tooltip>
                </div>

                <dxi-column
                  caption="Status"
                  [width]="60"
                  [allowReordering]="false"
                  [allowSorting]="false"
                  dataField="DocumentStatus"
                  cellTemplate="cellStatus"
                ></dxi-column>

                <div
                  *dxTemplate="let data of 'cellStatus'"
                  style="text-align: center;"
                >
                  <app-status-label
                    [text]="data.data.DocumentStatus"
                    [color]="data.data.StatusStringColor"
                    [backgroundColor]="data.data.StatusBackgroundColor"
                  ></app-status-label>
                </div>

                <div *dxTemplate="let data of 'blockTemplate'">
                  <i
                    class="icon absui-icon--set-lock"
                    *ngIf="
                      data.data.IsBlocked &&
                      !data.data.IsSetForAccounting &&
                      !data.data.IsDeferredIncome
                    "
                    [id]="'IsBlocked' + unicalGuid + data.rowIndex"
                  ></i>
                  <!-- <i class="icon absui-icon--money-dollar-fill-pz" *ngIf="data.data.IsDeferredIncome"></i> -->
                  <i
                    class="icon absui-icon--partly-check-circle"
                    [id]="'IsSetForAccounting' + unicalGuid + data.rowIndex"
                    *ngIf="
                      data.data.IsSetForAccounting &&
                      !data.data.IsDeferredIncome
                    "
                  ></i>
                  <i
                    class="icon absui-icon--status-cancel-pz"
                    *ngIf="data.data.IsDeferredIncome"
                    style="position: relative; top: 2px;"
                    [id]="'IsDeferredIncome' + data.data.WarehouseDocumentId"
                  ></i>

                  <dx-tooltip
                    [target]="'#IsBlocked' + unicalGuid + data.rowIndex"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ 'form-financial-document.isBlocked' | translate }}
                    </div>
                  </dx-tooltip>
                  <dx-tooltip
                    [target]="
                      '#IsSetForAccounting' + unicalGuid + data.rowIndex
                    "
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{
                        'form-financial-document.isSetForAccounting' | translate
                      }}
                    </div>
                  </dx-tooltip>
                  <dx-tooltip
                    [target]="
                      '#IsDeferredIncome' + data.data.WarehouseDocumentId
                    "
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{
                        'form-financial-document.isDeferredIncome' | translate
                      }}
                    </div>
                  </dx-tooltip>
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  [width]="150"
                  [allowSorting]="false"
                  dataField="WarehouseDocumentNumber"
                  cellTemplate="mainColumn"
                  headerCellTemplate="sortingHeaderTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'WarehouseDocumentNumber'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>
                <div *dxTemplate="let data of 'mainColumn'">
                  <span class="hov-underline" (click)="editRecord($event)">
                    {{ data.data.WarehouseDocumentNumber }}
                  </span>
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.date' | translate }}"
                  [allowSorting]="false"
                  dataField="DateOfWarehouseDocument"
                  headerCellTemplate="sortingHeaderTemplate"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'DateOfWarehouseDocument'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.payer-type.contractor' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="CustomerName"
                  headerCellTemplate="sortingHeaderTemplate"
                  width="200"
                  [cssClass]="
                    localStorageData.filter.orderBy == 'CustomerName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>

                <dxi-column
                  caption="{{ 'warehouseDocument.label' | translate }}"
                  width="105"
                  [allowSorting]="false"
                  dataField="LabelName"
                  cellTemplate="labelTemplate"
                  renderAsync="true"
                  alignment="left"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <div *dxTemplate="let data of 'labelTemplate'">
                  <app-label
                    [bgColor]="data.data.LabelBackgroundColor"
                    [color]="data.data.LabelTextColor"
                    [text]="data.data.LabelName"
                  ></app-label>
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.netValue' | translate
                  }}"
                  dataField="TotalNetAmount"
                  [allowSorting]="false"
                  width="100"
                  headerCellTemplate="TotalNetAmount"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'TotalNetAmount' &&
                    fakeOrderBy != 'TotalGrossAmount'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  cellTemplate="TotalNetAmountCell"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalNetAmountCell'">
                  {{ data.data.TotalNetAmount | amountFormatter: data }}
                </div>
                <div *dxTemplate="let data of 'TotalNetAmount'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('TotalNetAmount')"
                  >
                    {{ 'form-commercial-operation.grid.netValue' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.grossValue' | translate
                  }}"
                  dataField="TotalGrossAmount"
                  [allowSorting]="false"
                  width="100"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="TotalGrossAmountCell"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalGrossAmountCell'">
                  {{ data.data.TotalGrossAmount | amountFormatter: data }}
                </div>

                <dxi-column
                  caption="{{
                    'warehouseDocument.commercialCocumentNumber' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="InvoiceDocumentNumber"
                  width="180"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  caption="{{
                    'warehouseDocument.dateOfInvoiceDocument' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="DateOfInvoiceDocument"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="180"
                  alignment="left"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'comments' | translate }}"
                  [allowSorting]="false"
                  dataField="Remarks"
                  width="200"
                  alignment="left"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'warehouseDocument.distinguishCode' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="DistinguishCode"
                  width="200"
                  alignment="left"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'warehouseDocument.invoiceDocumentDateRequired' | translate
                  }}"
                  [allowSorting]="false"
                  dataField="InvoiceDocumentDateRequired"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="180"
                  alignment="left"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'workers.attachment' | translate }}"
                  [allowSorting]="false"
                  dataField="AttachmentsAmount"
                  width="80"
                  alignment="right"
                  cellTemplate="attachmentTemplate"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <div
                  *dxTemplate="let cell of 'attachmentTemplate'"
                  class="attachmentTemplate"
                >
                  <!-- <i
                                      class="icon icon-absui-icon--attach-file attachment-icon grid-attachment"
                                      *ngIf="cell.value"
                                    ></i> -->
                  {{ cell.value }}
                </div>

                <dxi-column
                  caption="{{ 'constractors.barcode' | translate }}"
                  [allowSorting]="false"
                  dataField="EAN"
                  width="110"
                  headerCellTemplate="sortingHeaderTemplate"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'EAN'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                ></dxi-column>

                <dxi-column
                  caption="{{ 'warehouseDocument.dateOfTax' | translate }}"
                  [allowSorting]="false"
                  dataField="DateOfTax"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="180"
                  alignment="left"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'warehouseDocument.received' | translate }}"
                  [allowSorting]="false"
                  dataField="PickedUpBy"
                  width="180"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'worker' | translate }}"
                  [allowSorting]="false"
                  dataField="EmployeeName"
                  width="200"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  [caption]="userExtensionColumns['Pole1'].Name"
                  dataField="Field1"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole1'] &&
                    userExtensionColumns['Pole1'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole2'].Name"
                  dataField="Field2"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole2'] &&
                    userExtensionColumns['Pole2'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole3'].Name"
                  dataField="Field3"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole3'] &&
                    userExtensionColumns['Pole3'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole4'].Name"
                  dataField="Field4"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole4'] &&
                    userExtensionColumns['Pole4'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole5'].Name"
                  dataField="Field5"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole5'] &&
                    userExtensionColumns['Pole5'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole6'].Name"
                  dataField="Field6"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole6'] &&
                    userExtensionColumns['Pole6'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole7'].Name"
                  dataField="Field7"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole7'] &&
                    userExtensionColumns['Pole7'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole8'].Name"
                  dataField="Field8"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole8'] &&
                    userExtensionColumns['Pole8'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole9'].Name"
                  dataField="Field9"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole9'] &&
                    userExtensionColumns['Pole9'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole10'].Name"
                  dataField="Field10"
                  [allowSorting]="false"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole10'] &&
                    userExtensionColumns['Pole10'].IsActive
                  "
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <dxi-column
                  [caption]="'servicesDevices.commentStatus' | translate"
                  dataField="StatusComment"
                  [allowSorting]="false"
                  width="300"
                ></dxi-column>

                <div *dxTemplate="let cell of 'sortingHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria(cell.column.dataField)"
                  >
                    {{ cell.column.caption }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
              </dx-data-grid>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.asc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'columnSorting.asc' | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.desc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'columnSorting.desc' | translate }}
                </div>
              </dx-tooltip>
            </div>
          </div>

          <div class="row">
            <div
              class="bottom-side-panel"
              mwlResizable
              *ngIf="!dropDownBoxMode"
              #bottomSidePanel
              (resizeStart)="onResizeStartBottom()"
              (resizeEnd)="resizeEndBottom()"
              (resizing)="resizingBottom($event)"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down':
                      localStorageData.bottomPanel.isVisible,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up': !localStorageData
                      .bottomPanel.isVisible
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>

                <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          #leftPanel
          [style.width]="localStorageData.sidePanel.width"
        >
          <div class="right-panel-btn">
            <!-- <i class="icon text-icon"></i> -->
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>
          <div #leftPanelTab>
            <dx-scroll-view
              width="100%"
              height="calc(100vh - 130px)"
              class="warehouse-documents-step-5"
              direction="vertical"
              style="margin-top: -10px;"
            >
              <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
            </dx-scroll-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div *ngIf="loadChildComponent">
  <app-expansion-list
    *ngIf="isExpansionListVisible"
    [isPopupMode]="true"
    [socketCodeFilter]="'XGML'"
    (onClosing)="onExpansionListClosing($event)"
  ></app-expansion-list>

  <app-confirm-dialog
    *ngIf="isOstrzegODokumZDnia"
    kindDialog="question"
    confirmText="form-commercial-operation.documentHasAlreadyBeenIssuedForTheSelectedCounterpartyOn"
    [isVisible]="true"
    [paramsMsg]="isOstrzegODokumZDniaParams"
    (onRemoving)="yesOstrzegODokumZDnia()"
    (onClosing)="noOstrzegODokumZDnia()"
  ></app-confirm-dialog>

  <app-add-crm-delivery
    [isVisible]="isDelivery"
    [readOnly]="readOnly"
    [objectToDelivery]="objectToDelivery"
    mode="add"
    (onClosing)="onSingleRecordClosing()"
    (onInserted)="onSingleRecordClosing()"
  ></app-add-crm-delivery>

  <app-new-task
    [isVisible]="isTask"
    mode="edit"
    (onClosing)="onTaskClosing()"
    [readOnly]="readOnly"
    [selectedId]="taskSelectedId"
    (onInserted)="onTaskClosing()"
  ></app-new-task>

  <app-new-documents-orders
    *ngIf="showOrderDocuments"
    [isVisible]="true"
    [mode]="'show'"
    [selectedId]="[positionFocused[0]?.OrderDocumentId]"
    [componentNests]="null"
    [isCurrentlyEdit]="
      positionFocused?.length ? !!positionFocused[0]?.Semaphore : false
    "
    [readOnly]="true"
    (onClosed)="onClosingFormWarehouse()"
  ></app-new-documents-orders>

  <app-pz-postponed
    *ngIf="isPzPostponed"
    [isVisible]="isPzPostponed"
    (onClosing)="onPostponedClosing()"
    [readOnly]="readOnly"
    document="warehouseDocuments"
    [isOutcome]="isOutcome"
  ></app-pz-postponed>

  <!-- pola dodatkowe -->
  <app-form-user-extensions
    [isVisible]="visibleUserExtensions"
    [groupCode]="USER_EXTENSIONS_GROUP_CODE"
    [readOnly]="readOnly || onlyShow"
    [groupChanged]="true"
    title="{{ 'categories.externalFields' | translate }}"
    (onChoosed)="setAdditionalFields($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="additionalFieldsList"
    #isValidUserExtensions
  ></app-form-user-extensions>

  <app-position-veryfication
    [isVisible]="isVerificationPosition"
    [documentNumber]="
      focusedSelected.length > 0
        ? focusedSelected[0]?.WarehouseDocumentNumber
        : 0
    "
    [documentId]="
      focusedSelected.length > 0 ? focusedSelected[0]?.WarehouseDocumentId : 0
    "
    typeDocument="warehouseDocuments"
    (onClosing)="onVerificationPositionClosing($event)"
    [readOnly]="readOnly"
    [isBlocked]="focusedSelected[0]?.isBlocked || false"
  ></app-position-veryfication>

  <app-new-comercial-operation
    [remarksInvoiceDokument]="remarksInvoiceDokument"
    *ngIf="isNewInvoiceDocument"
    [isVisible]="isNewInvoiceDocument"
    (onClosing)="closeNewComercialOperation()"
    title="{{ 'form-commercial-operation.title' | translate }}"
    (onInserted)="onInsertedDocument($event)"
    [mode]="chossedTypeInvoice != null ? 'add' : 'edit'"
    [selectedId]="InvoiceDocumentId"
    [readOnly]="mode == 'show' ? true : readOnly"
    [isFromWerhouse]="true"
    [chossedTypeInvoice]="chossedTypeInvoice"
    [disabledEdit]="disabledEdit"
  ></app-new-comercial-operation>

  <app-column-chooser
    [gridRef]="gridBank"
    [(visible)]="columnsChooserVisible"
    (columnsChanged)="onColumnsChanged($event)"
  ></app-column-chooser>

  <app-contractors
    [popUpMode]="true"
    [selectedId]="focusedSelected[0]?.CustomerId"
    [isVisible]="isCustomer"
    *ngIf="isCustomer"
    (onClosed)="isCustomer = false; cd.detectChanges()"
    (onChoosed)="onChoosedCustomer($event)"
  ></app-contractors>

  <app-confirm-dialog
    [isVisible]="true"
    (onRemoving)="addQuestionIncome()"
    *ngIf="isOutcomeDocument"
    (onClosing)="
      isOutcomeDocument = false;
      productionDocumentsEditMode = false;
      cd.detectChanges()
    "
    confirmText="form-commercial-operation.modificationOfTheIncomeDocumentWillNotAffectTheIssueDocumentsValuedAtPurchasePricesContinueWarehouse"
    kindDialog="question"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [confirmText]="
      'sadDocuments.confirmEditingWarehouseDocumentRelatedSADDDocument'
    "
    [isVisible]="confirmEditingWarehouseDocumentRelatedSADDDocument"
    [kindDialog]="'warning'"
    btnConfig="close"
    *ngIf="confirmEditingWarehouseDocumentRelatedSADDDocument"
    (onClosing)="cancelConfirmEditingWarehouseDocumentRelatedSADDDocument()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [confirmText]="
      'warehouseDocument.removingCorrectionsFromHereIsNotPossibleDoYouWantEdit'
    "
    (onRemoving)="isEditCorection = false; editDocument()"
    [isVisible]="isEditCorection"
    (onClosing)="cancelEditCorection()"
    *ngIf="isEditCorection"
  ></app-confirm-dialog>

  <app-new-warehouse-document
    [productionDocumentsEditMode]="productionDocumentsEditMode"
    [sadDocumentsEditMode]="sadDocumentsEditMode"
    [isVisible]="addRow"
    (onClosing)="onClosingForm(); this.sadDocumentsEditMode = false"
    [warehouseDocumentTypeId]="typeFilter"
    [componentNests]="componentNests"
    title="{{ 'warehouseDocument.addingWarehouseDocument' | translate }}"
    [selected]="focusedSelected"
    [documentObject]="focusedSelected[0]"
    [sumPositionIncome]="sumPositionIncome"
    (onInserted)="onInserted($event)"
    [isCorrect]="isCorrect"
    [editMode]="mode == 'edit' || mode == 'show' ? true : false"
    [readOnly]="mode == 'show' ? true : readOnly"
    [recalculatePosition]="recalculatePosition"
    [customerId]="customerFilter?.CustomerId"
  ></app-new-warehouse-document>

  <app-new-inventory
    [isVisible]="editInventory"
    *ngIf="editInventory"
    (onClosing)="onClosingInventory()"
    [selectedWarehouse]="focusedSelected"
    [documentType]="inventoryType"
    title="{{ 'warehouseDocument.documentPreview' | translate }}"
    [readOnly]="true"
  ></app-new-inventory>

  <app-table-user-extension
    [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
    (onClosing)="isExternalTableVisible = false"
    [isVisible]="isExternalTableVisible"
    [isPopupMode]="true"
  ></app-table-user-extension>

  <app-table-data-user-extension
    [readOnly]="readOnly"
    [isVisible]="isShortcutTableOpened"
    [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
    (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
    [table]="shortcutTable"
  ></app-table-data-user-extension>

  <app-confirm-dialog
    *ngIf="isCloneDocument"
    [isVisible]="true"
    (onRemoving)="cloneDocument()"
    (onClosing)="isCloneDocument = false; cd.detectChanges()"
    [confirmText]="
      selectedRows.length < 1
        ? 'warehouse.doYouWantToDuplicateTheDocument'
        : 'form-commercial-operation.shouldIDuplicateSelectedDocuments'
    "
    kindDialog="question"
    [paramsMsg]="paramsConfirmClone"
  ></app-confirm-dialog>

  <app-confirm-dialog
    *ngIf="isAddDescription"
    [isVisible]="true"
    (onRemoving)="addQuestion()"
    (onClosing)="isAddDescription = false; cd.detectChanges()"
    confirmText="warehouseDocument.doYouWantChangeRemarksOfDocument"
    kindDialog="question"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="true"
    *ngIf="isDeleteRow"
    (onRemoving)="delete($event); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
    [radioBoxList]="checkBoxList"
    confirmText="{{ 'warehouseDocument.isDeleting' | translate }}"
    confirmHeader="{{ 'warehouseDocument.isDeletingHeader' | translate }}"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="true"
    *ngIf="isDeleteMp"
    (onRemoving)="delete($event); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
    [paramsMsg]="paramsMsgMp"
    confirmText="warehouseDocument.deletingAdocumentWillDeleteTheDocumentInTheWarehouse"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="true"
    *ngIf="isDeleteMpNo"
    (onRemoving)="delete($event); gridBank.instance.focus()"
    (onClosing)="closeConfirmNo()"
    confirmText="deleteMWMPNo"
  ></app-confirm-dialog>

  <app-confirm-dialog
    [isVisible]="true"
    *ngIf="isDeleteInventory"
    (onRemoving)="delete($event); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
  ></app-confirm-dialog>

  <app-suma-warehouse-documents
    [isVisible]="isShowSum"
    (onClosing)="isShowSum = false; cd.detectChanges()"
    title="{{ 'warehouseDocument.summaryWarehouseDocuments' | translate }}"
    [selectedId]="focusedSelected"
    [forSelected]="selectedRows.length > 0 ? true : false"
    [filters]="paramsObj"
  ></app-suma-warehouse-documents>

  <app-correction
    [isVisible]="isCorrection"
    *ngIf="isCorrection"
    (onClosing)="isCorrection = false; cd.detectChanges()"
    [selected]="focusedSelected"
    [typeOfDocument]="typeTree"
    (onClosing)="onClosingCorrection()"
    [componentNests]="componentNests"
    title="{{
      'warehouseDocument.correctionsOfTheWarehouseDocument' | translate
    }}"
  ></app-correction>

  <app-labels
    *ngIf="isLabel"
    [popUpMode]="true"
    (onChoosed)="onChoosedLabel()"
    (onClosed)="isLabel = false"
    [isVisible]="isLabel"
    [readOnly]="readOnly"
    IsForWarehouseDoc="true"
    url="warehouseDocuments/selection/label"
  ></app-labels>

  <app-confirm-dialog
    *ngIf="isBlockRow"
    [isVisible]="true"
    (onRemoving)="blockUnblockDocument()"
    (onClosing)="onBlockRowClosing()"
    [confirmText]="textBlock"
    kindDialog="question"
  ></app-confirm-dialog>

  <app-dependent-postition
    [isVisible]="showRelatedItems"
    [readOnly]="readOnly"
    documentContext="DOCUMENT_WAREHOUSE_POSITION"
    [displayInfo]="true"
    [productId]="positionFocused[0]?.ProductId"
    [serialNo]="positionFocused[0]?.SerialNumber"
    [expireDate]="positionFocused[0]?.ExpirationDate"
    [docPositionId]="positionFocused[0]?.WarehouseDocumentPositionId"
    (onClosed)="showRelatedItems = false; cd.detectChanges()"
  ></app-dependent-postition>
</div>
<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    addRow ||
    isCustomer ||
    isCorrection ||
    isLabel ||
    isExpansionListVisible ||
    (!isVisible && popUpMode) ||
    notesService.isNotesFocused()
  "
></wapro-keyboard-shortcuts>

<app-clipboard
  *ngIf="isClipboardVisible"
  [isVisible]="true"
  (onClosing)="
    isClipboardVisible = false; findSocketAndRun('XGMLEXDOKMAG_BRSCHOWVDM_E2')
  "
  [isPopupMode]="true"
  [showMode]="true"
></app-clipboard>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGML'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="valueCriteria"
      [(filterValue)]="filterValue"
      (onValueChanged)="onFilterDataChanged($event)"
      *ngIf="!dropDownBoxMode"
      (onArrowDown)="gridBank.instance.focus()"
      #searchControl
    ></app-custom-dropdown-box>

    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getDocuments()"
      ></i>
    </dx-button>
  </div>
  <dx-scroll-view
    width="100%"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="onScroll"
  >
    <div style="width: 1000px; padding-top: 2px;">
      <app-date-range
        #dateRange
        [readOnly]="readOnly"
        [isPopupMode]="true"
        (onChoosed)="onDateRangeChoosed()"
        [chipsVisible]="true"
        style="margin-left: -5px;"
      ></app-date-range>
      <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected">
        {{ 'showSelected' | translate }}:
        <b>{{ 'yes' | translate }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('onlySelected')"
        ></i>
      </dx-button>
      <app-custom-chips-button
        name="Status"
        [list]="filterCriteriaStatus"
        (onValueChanged)="onValueChangedState($event)"
      ></app-custom-chips-button>

      <app-custom-chips-button
        name="{{ 'workers.attachment' | translate }}"
        [list]="filterCriteriaAtachments"
        (onValueChanged)="onAtachmentsValueChanged($event)"
      ></app-custom-chips-button>

      <app-custom-chips-button
        name="{{ 'menu.commercialDocuments' | translate }}"
        [list]="documentFinances"
        (onValueChanged)="onComercialDocumentValueChanged($event)"
      ></app-custom-chips-button>

      <i
        class="icon absui-icon--settings-user-buffor icon-chips"
        style="margin-right: 6px; top: -3px;"
        [id]="'customer' + unicalGuid"
        (click)="isCustomer = true; cd.detectChanges()"
      ></i>

      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="typeFilter.warehouseDocumentTypeId != null"
      >
        {{ 'form-financial-document.type' | translate }}:
        <b>{{ typeFilter.typeName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetType()"
        ></i>
      </dx-button>

      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="customerFilter.CustomerId != null"
      >
        {{ 'form-financial-document.payer-type.contractor' | translate }}:
        <b>{{ customerFilter.CustomerName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetContrahentFilter()"
        ></i>
      </dx-button>
    </div>
  </dx-scroll-view>

  <dx-data-grid
    [dataSource]="dataSource"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    shortcut
    [height]="heightGrid - 50"
    [(selectedRowKeys)]="selectedRows"
    [columnResizingMode]="'widget'"
    (onInitialized)="onInitialized($event)"
    [remoteOperations]="true"
    [autoNavigateToFocusedRow]="true"
    (onRowDblClick)="onRowDblClick()"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    (onContentReady)="onContentReady($event)"
    (onKeyDown)="onKeyDown($event)"
    class="main-mobile-grid"
    [(focusedRowIndex)]="focusedRowIndex"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    #gridBank
    (onOptionChanged)="onOptionChanged($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    ></dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex;">
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == data.data.Semaphore,
            'semafore-edit-by-another-user':
              userId != data.data.Semaphore && data.data.Semaphore != null
          }"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <span
            class="hov-underline-mobile"
            (click)="editRecord($event, data.data)"
          >
            {{ data.data.WarehouseDocumentNumber }}
          </span>
          <br />
          <label class="mobile-grid-label">
            {{ 'form-financial-document.date' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.DateOfWarehouseDocument | date: event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ 'form-financial-document.payer-type.contractor' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{
              data.data.CustomerName?.length > 25
                ? (data.data.CustomerName | slice: 0:25) + '...'
                : data.data.CustomerName
            }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ 'form-commercial-operation.grid.netValue' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.TotalNetAmount | amountFormatter: null }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ 'form-commercial-operation.grid.grossValue' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.TotalGrossAmount | amountFormatter: null }}
          </p>
          <br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px);"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px;"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == focusedSelected[0]?.Semaphore,
            'semafore-edit-by-another-user':
              userId != focusedSelected[0]?.Semaphore &&
              focusedSelected[0]?.Semaphore != null
          }"
        ></div>
        <div class="right-details">
          <p *ngIf="focusedSelected[0]" class="nameSelectedPosition">
            {{ focusedSelected[0]?.WarehouseDocumentNumber }}
          </p>

          <label class="details-label">
            {{ 'form-financial-document.date' | translate }}:
          </label>
          <p class="details-text">
            {{
              focusedSelected[0]?.DateOfWarehouseDocument
                | date: event.dateFormat
            }}
          </p>

          <label class="details-label">
            {{ 'form-financial-document.payer-type.contractor' | translate }}:
          </label>
          <p class="details-text">{{ focusedSelected[0]?.CustomerName }}</p>

          <label class="details-label">
            {{ 'form-commercial-operation.grid.netValue' | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0]?.TotalNetAmount | amountFormatter: null }}
          </p>

          <label class="details-label">
            {{ 'form-commercial-operation.grid.grossValue' | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0]?.TotalGrossAmount | amountFormatter: null }}
          </p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ 'tasks.details' | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%;">
          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'warehouseDocument.commercialCocumentNumber' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0]?.InvoiceDocumentNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'warehouseDocument.dateOfInvoiceDocument' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{
                  focusedSelected[0]?.DateOfInvoiceDocument
                    | date: event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'warehouseDocument.distinguishCode' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0]?.DistinguishCode }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{
                  'warehouseDocument.invoiceDocumentDateRequired' | translate
                }}
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{
                  focusedSelected[0]?.InvoiceDocumentDateRequired
                    | date: event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'workers.attachment' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0]?.AttachmentsAmount }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'constractors.barcode' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0]?.EAN }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'warehouseDocument.dateOfTax' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0]?.DateOfTax | date: event.dateFormat }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'warehouseDocument.received' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0]?.PickedUpBy }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'worker' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0]?.EmployeeName }}</p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px;">
        <div class="c-frame-row">
          <h5>{{ 'comments' | translate }}</h5>
        </div>
        <p class="details-text">{{ focusedSelected[0]?.Remarks }}</p>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
  </div>
</ng-template>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    width="100%"
    [items]="bottomPanelTabs"
    [(selectedIndex)]="selectedtabIndex"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="bottomTabPanelMobile warehouse-documents-step-6"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div [id]="'tabToolTip' + i + unicalGuid">
        <span>{{ name }}</span>
      </div>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container
        [ngSwitch]="name"
        *ngIf="localStorageData.bottomPanel.isVisible"
      >
        <div *ngSwitchCase="'menu.eDocuments' | translate">
          <app-e-dokuments
            #eDocumentss
            [permission]="perEdocuments"
            DocumentCode="Warehouse"
            tableName="DOKUMENT_MAGAZYNOWY"
            style="display: inline-block; width: 100%;"
            [contextObjectId]="focusedSelected[0]?.WarehouseDocumentId"
            [parentDocumentName]="focusedSelected[0]?.WarehouseDocumentNumber"
            [isShowSidePanel]="false"
            [heightGrid]="
              event.deviceType != 'mobile' ? heightBottomGrid - 65 : 545 - 65
            "
            [ContextObjectId]="
              focusedSelected.length > 0
                ? focusedSelected[0]?.FinanceDocumentId
                : null
            "
            [readOnly]="focusedSelected.length === 0"
          ></app-e-dokuments>
        </div>
        <div
          *ngSwitchCase="'warehouseDocument.dockOriginalCorrection' | translate"
        >
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="warehouseDocumentOriginalList"
            [focusedRowEnabled]="true"
            [columnResizingMode]="'widget'"
            [id]="'contextMenuCorection'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              dataField="WarehouseDocumentNumber"
              [allowSorting]="false"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              dataField="DateOfInvoiceDocument"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="TotalNetAmount"
              cellTemplate="TotalNetAmount"
              [hidingPriority]="0"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalNetAmount'">
              {{ data.data.TotalNetAmount | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{
                'warehouseDocument.commentsBasisForCorrection' | translate
              }}"
              dataField="Remarks"
              [allowSorting]="false"
            ></dxi-column>
          </dx-data-grid>
          <wapro-context-menu
            [dataSource]="contextMenuCorection"
            [width]="200"
            [target]="'#contextMenuCorection'"
            (onItemClick)="contextCorectionMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></wapro-context-menu>
        </div>
        <div *ngSwitchCase="'menu.orders' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="orderList"
            [focusedRowEnabled]="true"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [id]="'gridOrders' + unicalGuid"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="OrderDocumentNumber"
            ></dxi-column>
            <dxi-column
              caption="S"
              headerCellTemplate="statusOrder"
              width="32"
              [allowSorting]="false"
              dataField="OrderStatus"
              [hidingPriority]="7"
            ></dxi-column>
            <div *dxTemplate="let data of 'statusOrder'" class="text-center">
              <div
                class="grid-header-wrapper"
                [id]="'statusOrder' + unicalGuid"
              >
                {{ 'production-documents.s' | translate }}
              </div>
              <dx-tooltip
                [target]="'#statusOrder' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'production-documents.status' | translate }}
                </div>
              </dx-tooltip>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              dataField="Date"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="160"
              alignment="left"
            ></dxi-column>
            <dxi-column
              caption="{{
                'warehouseDocument.customerOrderNumber' | translate
              }}"
              width="150"
              [allowSorting]="false"
              dataField="CustomerOrderNumber"
              [hidingPriority]="6"
            ></dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.realisationDate' | translate }}"
              dataField="RealizationDate"
              [hidingPriority]="5"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
            ></dxi-column>
            <dxi-column
              caption="{{
                'warehouseDocument.prefereTypeOfdocument' | translate
              }}"
              width="250"
              [allowSorting]="false"
              dataField="PreferredDocumentTypeName"
              [hidingPriority]="10"
            ></dxi-column>

            <dxi-column
              caption="{{ 'plannedOrders.priority' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="Priority"
              [hidingPriority]="4"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="TotalNetAmount"
              cellTemplate="NetAmount"
              [hidingPriority]="3"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'NetAmount'">
              {{ data.data.TotalNetAmount | amountFormatter: data }}
            </div>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="TotalGrossAmount"
              cellTemplate="GrossAmount"
              [hidingPriority]="2"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'GrossAmount'">
              {{ data.data.TotalGrossAmount | amountFormatter: data }}
            </div>
            <dxi-column
              caption="{{ 'bankAccounts.form.currency' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="CurrencyCode"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              caption="{{ 'comments' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
          <wapro-context-menu
            [dataSource]="contextMenuWarehouse"
            [width]="200"
            [target]="'#gridOrders' + unicalGuid"
            (onItemClick)="contextMenuWarehoseClick('gridOrders')"
            (onItemRendered)="event.onItemRendered($event)"
          ></wapro-context-menu>
        </div>
        <div *ngSwitchCase="'form-financial-document.tabs.items' | translate">
          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="positionList"
            [focusedRowEnabled]="true"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            id="warehousePositionGrid"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.articleName' | translate
              }}"
              width="267"
              [allowSorting]="false"
              dataField="ProductFullName"
            ></dxi-column>

            <dxi-column
              caption="{{ 'customerGroups.catalogIndex' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="IndexCatalogue"
              [hidingPriority]="17"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-commercial-operation.grid.count' | translate }}"
              width="70"
              [allowSorting]="false"
              dataField="Amount"
              cellTemplate="amountTemplate"
            ></dxi-column>
            <div *dxTemplate="let cell of 'amountTemplate'">
              {{
                cell.value
                  | amountFormatter: cell:(iloscArtBezPrzecinka ? 0 : 4)
              }}
            </div>
            <dxi-column
              caption="{{ 'inventory.stateAfter' | translate }}"
              dataField="StockLeft"
              [allowSorting]="false"
              width="100"
              alignment="left"
              *ngIf="isStockLeft"
              cellTemplate="amountTemplate"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.unit' | translate }}"
              width="80"
              [allowSorting]="false"
              dataField="Unit"
              [hidingPriority]="16"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netAmount' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="PriceNet"
              cellTemplate="PriceNet"
              [hidingPriority]="15"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'PriceNet'">
              {{ data.data.PriceNet | amountFormatter: data }}
            </div>

            <dxi-column
              caption="VAT"
              width="80"
              [allowSorting]="false"
              dataField="TaxCode"
              [hidingPriority]="14"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossAmount' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="PriceGross"
              cellTemplate="PriceGross"
              [hidingPriority]="15"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'PriceGross'">
              {{ data.data.PriceGross | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="TotalNetAmount"
              cellTemplate="TotalNetAmount"
              [hidingPriority]="13"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalNetAmount'">
              {{ data.data.TotalNetAmount | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [allowSorting]="false"
              width="100"
              dataField="TotalGrossAmount"
              cellTemplate="TotalGrossAmount"
              [hidingPriority]="12"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalGrossAmount'">
              {{ data.data.TotalGrossAmount | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{ 'customerGroups.tradingIndex' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="IndexTrading"
              [hidingPriority]="11"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.orderNumber' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="OrderDocumentNumber"
              [hidingPriority]="10"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.packageNumber' | translate }}"
              width="130"
              [allowSorting]="false"
              [hidingPriority]="9"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              width="200"
              [allowSorting]="false"
              dataField="Description"
              [hidingPriority]="8"
            ></dxi-column>

            <dxi-column
              caption="{{ 'deliveries.serialNo' | translate }}"
              width="200"
              [allowSorting]="false"
              dataField="SerialNumber"
              [hidingPriority]="7"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.certificateDate' | translate }}"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
              [hidingPriority]="6"
            ></dxi-column>

            <dxi-column
              caption="{{ 'offers.defaultBarcode' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="EAN"
              [hidingPriority]="5"
            ></dxi-column>

            <dxi-column
              caption="{{ 'left' | translate }}"
              width="100"
              [allowSorting]="false"
              dataField="TotalLeft"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouse.location' | translate }}"
              width="100"
              [allowSorting]="false"
              dataField="Location"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.originalName' | translate }}"
              width="100"
              [allowSorting]="false"
              [hidingPriority]="2"
              dataField="NameOriginal"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.filter.indexProducer' | translate }}"
              width="150"
              [allowSorting]="false"
              [hidingPriority]="1"
              dataField="IndexProducer"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.label' | translate }}"
              width="150"
              [allowSorting]="false"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>

          <wapro-context-menu
            [dataSource]="contextMenuPositionSignal()"
            [width]="200"
            [target]="'#warehousePositionGrid'"
            (onItemClick)="contextPositionMenuClick($event)"
            (onItemRendered)="event.onItemRendered($event)"
          ></wapro-context-menu>
        </div>
        <div
          *ngSwitchCase="
            'form-commercial-operation.deliveryAddress' | translate
          "
        >
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="deliveryAddress"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px;"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [caption]="'companies.form.addressDetail.country' | translate"
              width="100"
              dataField="CountryCode"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.postalCode' | translate"
              width="100"
              dataField="PostalCode"
              [hidingPriority]="9"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.city' | translate"
              width="150"
              dataField="City"
              [hidingPriority]="8"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.street' | translate"
              width="150"
              dataField="Street"
              [hidingPriority]="7"
            ></dxi-column>

            <dxi-column
              [caption]="'constractors.company' | translate"
              width="150"
              dataField="CompanyName"
              [hidingPriority]="6"
            ></dxi-column>

            <dxi-column
              [caption]="'constractors.recipient' | translate"
              width="150"
              dataField="Receiver"
              [hidingPriority]="5"
            ></dxi-column>

            <dxi-column
              [caption]="'companies.form.phone' | translate"
              width="100"
              dataField="Phone"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              [caption]="'delivery.addressType' | translate"
              width="100"
              dataField="AddressType"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              [caption]="'formPayment.operator' | translate"
              width="100"
              dataField="PostageOperator"
              [hidingPriority]="2"
            ></dxi-column>

            <dxi-column
              [caption]="'constractors.pickupPoint' | translate"
              width="100"
              dataField="PickUpAddressCode"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              [caption]="'form-financial-document.description' | translate"
              width="300"
              dataField="Description"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
        </div>

        <div *ngSwitchCase="'form-financial-document.tabs.package' | translate">
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="packagesData"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px;"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [caption]="'crmDeliveries.packageNo' | translate"
              width="120"
              dataField="PackageNumber"
              [allowSorting]="false"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.postingDate' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ShipmentDate"
              dataType="date"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.returnDate' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ReturnDate"
              dataType="date"
            ></dxi-column>
            <dxi-column
              [caption]="'formPayment.operator' | translate"
              [allowSorting]="false"
              width="130"
              dataField="DeliveryOperatorName"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.courier' | translate"
              [allowSorting]="false"
              width="130"
              dataField="Courier"
            ></dxi-column>
            <dxi-column
              [caption]="'production-documents.status' | translate"
              [allowSorting]="false"
              width="80"
              dataField="StatusCode"
            ></dxi-column>
            <dxi-column
              [caption]="'userExtensions.customers' | translate"
              [allowSorting]="false"
              width="130"
              dataField="CustomerName"
            ></dxi-column>
            <dxi-column
              [caption]="'constractors.recipient' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ReceiverLastName !== null ? ReceiverLastName : CustomerName"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.pickingUp' | translate"
              [allowSorting]="false"
              width="120"
              dataField="CashOnDeliveryValue"
            ></dxi-column>
          </dx-data-grid>
        </div>

        <div
          *ngSwitchCase="
            localStorageData?.additionalTable?.text || 'articles.externalTable'
              | translate
          "
        >
          <app-table-user-extension
            [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            (onTableChanged)="onAdditionalTableChanged($event)"
            (onFocusElementChanged)="onFocusElementChanged($event)"
            [selectedTable]="localStorageData.additionalTable"
            *ngIf="
              selectedtabIndex == 4 &&
              focusedSelected.length &&
              localStorageData.bottomPanel.isVisible
            "
          ></app-table-user-extension>
        </div>

        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="warehouseDocuments"
            [objectId]="
              focusedSelected?.length && !addRow
                ? focusedSelected[0]?.WarehouseDocumentId
                : null
            "
            *ngIf="
              bottomPanelTabs[selectedtabIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanel.isVisible
                : isDetails)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanel.height
                : 545
            "
          ></app-notes>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>

  <dx-tooltip
    *ngFor="let tabs of bottomPanelTabs; let i = index"
    [target]="'#tabToolTip' + i + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="mouseleave"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">(Alt+{{ i + 1 }})</div>
  </dx-tooltip>
</ng-template>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[1, 2]"
    [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
    (onSelectionChanged)="onSelectionSideTabChanged()"
    class="filter-panel right-panel-box production-documents-step-5"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
    #rightTabPanel
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-scroll-view
            width="100%"
            height="calc(100vh - 130px)"
            direction="vertical"
          >
            <dx-tree-view
              [dataSource]="typeTree"
              [selectNodesRecursive]="false"
              keyExpr="WarehouseDocumentTypeId"
              displayExpr="TypeName"
              [selectByClick]="true"
              (onSelectionChanged)="onTreeSelectionChanged($event)"
              (onInitialized)="restoreTreeConfig()"
              #treeView
              selectionMode="single"
              (onItemClick)="onItemClickType($event)"
              style="width: calc(100% - 10px);"
            ></dx-tree-view>
          </dx-scroll-view>
        </div>
        <div *ngSwitchCase="2">
          <div style="width: 100%;">
            <app-filter-panel
              [deleteFilters]="deleteFilter"
              [openPanelFilters]="visibleAdvanceFilters"
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData.sidePanel.isVisible &&
                localStorageData.sidePanel.selectedIndex == 1
              "
              [panelWidth]="localStorageData.sidePanel.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            ></app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
  ></app-filter-panel>
</div>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative;">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAmount]="selectedAmount()"
      [selectedAll]="isAllSelected"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      (onSelectAll)="gridBank.instance.selectAll()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly || !this.perABD.addBtn"
      (onClick)="beforeAddRowVisible()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0;"
    ></dx-button>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
      (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="WarehouseDocumentsComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    ></app-additional-operation>
  </div>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [maxHeight]="event.maxHeightSignal()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1;"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333;"
        icon="icon absui-icon--clear-filter"
        (onClick)="clearMobileFilters()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<dx-popup
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'form-financial-document.addRemarksToTheDocument' | translate }}"
  [(visible)]="isShowDocumentDescription"
  [width]="event.deviceType != 'mobile' ? 500 : '100%'"
  [height]="188"
  *ngIf="isShowDocumentDescription"
  [maxHeight]="event.maxHeightSignal()"
  (onHidden)="Remarks = ''"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px;"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isShowDocumentDescription = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical" #scrollView>
      <app-description-dictionary
        [dropDownBoxMode]="true"
        type="U"
        heightArea="100"
        [widthArea]="460"
        title="{{ 'comments' | translate }}"
        (onChoosed)="onChoosedDescription()"
        [(ngModel)]="Remarks"
        [readOnly]="readOnly"
      ></app-description-dictionary>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'warehouse-documents'"
></app-user-guide>

<dx-popup
  [width]="700"
  [height]="370"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'documentsOrders.realizationInvoices' | translate }}"
  [(visible)]="createInvoicesDocumentPopupVisible"
  (onShown)="
    event.onShownPopUp();
    event.setFocus(gridRealHan);
    focusedDocRowIndexIncoices = 0
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px;"
        (onClick)="createChosedInvoices()"
        [id]="'choose-doc' + unicalGuid"
        data-cy="invoice-document-type-form-warehouse-document-choose-button"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="createInvoicesDocumentPopupVisible = false"
        [id]="'cancel-doc' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel-doc' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.cancel' | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
          [dataSource]="invoicesDocumentsTypes"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [columnAutoWidth]="true"
          (onRowDblClick)="onDocRowDblClick()"
          [height]="250"
          [id]="'grid-doc' + unicalGuid"
          [(focusedRowIndex)]="focusedDocRowIndexIncoices"
          (onKeyDown)="onKeyDowngridRealHan($event)"
          [focusedRowEnabled]="true"
          #gridRealHan
          [allowColumnResizing]="true"
          (onOptionChanged)="event.onOptionChanged($event)"
          data-cy="invoice-document-type-form-warehouse-document"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption="S"
            width="40"
            dataField="IsSystem"
            cellTemplate="booleanTemplate"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.typeName' | translate"
            dataField="Name"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.signature' | translate"
            dataField="Signature"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.type' | translate"
            dataField="DocumentType"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.pricingByPrice' | translate"
            dataField="CalculateByPrice"
          ></dxi-column>

          <div *dxTemplate="let data of 'booleanTemplate'">
            <i
              class="icon absui-icon--check check-grid"
              *ngIf="data.value; else falseTemplate"
            ></i>
          </div>
          <ng-template #falseTemplate>
            <i class="icon absui-icon--close close-grid"></i>
          </ng-template>
        </dx-data-grid>

        <div class="d-flex flex-row">
          <div class="p-2">
            <dx-check-box
              text="{{ 'documentsOrders.prepaymentInvoice' | translate }}"
              [(ngModel)]="Advance"
              *ngIf="isAdvance"
            ></dx-check-box>
          </div>
          <div class="p-2">
            <dx-check-box
              text="{{ 'documentsOrders.nationalCurrency' | translate }}"
              [(ngModel)]="IsDocCurrencyDomestic"
              *ngIf="isIsDocCurrencyDomestic"
            ></dx-check-box>
          </div>
          <div class="p-2">
            <dx-check-box
              text="{{ 'documentsOrders.opposite' | translate }}"
              [(ngModel)]="IsOpposite"
              *ngIf="isIsOpposite"
            ></dx-check-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<app-label-printer-operations
  *ngIf="isLabelPrintOperationVisible"
  [isVisible]="true"
  context="Magazyn"
  [labelType]="labelType"
  (onClosing)="onLabelPrintOperationClosing()"
></app-label-printer-operations>

<app-export-jpk-xml
  type="Mag"
  *ngIf="isExportVisible()"
  (onClosing)="isExportVisible.set(false)"
></app-export-jpk-xml>

<dx-popup
  [width]="700"
  [height]="370"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'documentsOrders.realizationInvoices' | translate }}"
  [(visible)]="isCreateRWMW"
  (onShown)="
    event.onShownPopUp();
    event.setFocus(gridRealHan);
    focusedDocRowIndexIncoices = 0
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px;"
        (onClick)="onChoosedRWWZ()"
        [id]="'choose-doc' + unicalGuid"
        data-cy="invoice-document-type-form-warehouse-document-choose-button"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isCreateRWMW = false"
        [id]="'cancel-doc' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel-doc' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.cancel' | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
          [dataSource]="documentRWWZList"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [columnAutoWidth]="true"
          (onRowDblClick)="onChoosedRWWZ()"
          [height]="290"
          [id]="'grid-doc' + unicalGuid"
          [(focusedRowIndex)]="focusedDocRowIndexIncoices"
          (onKeyDown)="onKeyDowngridRealHan($event)"
          [focusedRowEnabled]="true"
          #gridRealHan
          [allowColumnResizing]="true"
          (onOptionChanged)="event.onOptionChanged($event)"
          data-cy="invoice-document-type-form-warehouse-document"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption="A"
            width="40"
            dataField="IsActive"
            cellTemplate="booleanTemplate"
          ></dxi-column>
          <dxi-column
            caption="S"
            width="40"
            dataField="IsSystem"
            cellTemplate="booleanTemplate"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.typeName' | translate"
            dataField="Name"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.signature' | translate"
            dataField="Signature"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.type' | translate"
            dataField="DocumentType"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.pricingByPrice' | translate"
            dataField="CalculateByPrice"
          ></dxi-column>

          <div *dxTemplate="let data of 'booleanTemplate'">
            <i
              class="icon absui-icon--check check-grid"
              *ngIf="data.value; else falseTemplate"
            ></i>
          </div>
          <ng-template #falseTemplate>
            <i class="icon absui-icon--close close-grid"></i>
          </ng-template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</dx-popup>

<app-change-status
  [isVisible]="isStatus"
  *ngIf="isStatus"
  (onClosing)="isStatus = false; cd.detectChanges()"
  (onInserted)="isStatus = false; dataSource.reload()"
></app-change-status>

<wapro-context-menu
  [dataSource]="contextMenuSignal()"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
></wapro-context-menu>

<app-record-card
  *ngIf="isRecordCardVisible"
  [cardMode]="recordCardMode"
  selectedTab="{{ 'contactsAdd.warehouse' | translate }}"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

<app-calculate-quantitatively
  *ngIf="isCalculateQuantitativelyVisible()"
  [isVisible]="isCalculateQuantitativelyVisible()"
  (onClosing)="
    isCalculateQuantitativelyVisible.set(false); gridBank.instance.focus()
  "
  (onInserted)="calculateQuantitatively()"
  [headerDocument]="focusedSelected.length > 0 ? focusedSelected[0] : null"
></app-calculate-quantitatively>

<ng-template #reportsTemplate>
  <app-reports
    #reports
    [code]="USER_EXTENSIONS_GROUP_CODE"
    [menuName]="menuName"
    [objectId]="
      focusedSelected?.length ? focusedSelected[0]?.WarehouseDocumentId : null
    "
    (onReportsPopupClosed)="onReportsPopupClosed()"
    [selectedDocumentsData]="selectedDocumentsData"
    [type]="focusedSelected?.length ? focusedSelected[0]?.TypeOfDocument : null"
    [warehouseDocument]="focusedSelected[0]"
    [listOfAdditionalReports]="additionalReportList"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    [params]="getLoadParams()"
  ></app-reports>
</ng-template>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="perEdocuments"
    DocumentCode="Warehouse"
    tableName="DOKUMENT_MAGAZYNOWY"
    [contextObjectId]="focusedSelected[0]?.WarehouseDocumentId"
    [parentDocumentName]="focusedSelected[0]?.WarehouseDocumentNumber"
    style="display: inline-block;"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  signal,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription } from 'rxjs';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { DoubleClickResult, RefreshCardResult } from 'src/app/event.model';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { GlobalDate } from '../../../core/date-range/GlobalDate';
import { EventService } from '../../../event.service';
import { OnInsertedData } from '../../new-comercial-operation/new-comercial-operation.component';

@Component({
    selector: 'app-pz-postponed',
    templateUrl: './pz-postponed.component.html',
    styleUrls: ['./pz-postponed.component.scss'],
    inputs: ['isVisible', 'readOnly', 'document', 'isOutcome'],
    standalone: false
})
export class PzPostponedComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @ViewChild('dateRange') dateRange: DateRangeComponent;

  unicalGuid;
  readOnly;
  isVisible;
  orderby: any = null;
  widthWindow: any = '760';
  heightWindow: any = '75%';
  focusedSelected = [];
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  refreshCardResult: RefreshCardResult = 'Tak';
  doubleClickResult: DoubleClickResult = 'Edycja';
  selectedRows = [];

  filterList = [
    { value: true, label: 'Tak' },
    { value: false, label: 'Nie' },
  ];
  document;
  ApprovalDate = this.filterList[1].label;
  isIncludeemployee = this.filterList[1].label;
  isCurrentMagazine = this.filterList[1].label;
  mode; //add || edit || show
  approvalDate = new Date();
  loadChildComponent: boolean = false;
  newRowPopupVisible: boolean = false;
  shortcuts: ShortcutInput[] = [];
  typeFilter = {
    invoiceTypeId: null,
    typeName: '',
    invoiceTypeIdShort: null,
  };

  myEventEnter;
  myEventEsc;
  userId: number = null;
  DokMagZatwGrupOdl: boolean = false;

  isOutcome: boolean = false;

  radioOption;

  IsControlDate: boolean | any = null;
  IsControlPrice: boolean | any = null;
  IsControlMargin = this.filterList[0].label;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.radioOption = signal([
      { label: this.translate.instant('yes'), value: 1 },
      { label: this.translate.instant('no'), value: 0 },
      {
        label: this.translate.instant(
          'form-commercial-operation.fromSystemSettings'
        ),
        value: 2,
      },
    ]);

    this.IsControlDate = this.radioOption()[0].label;
    this.IsControlPrice = this.radioOption()[0].label;
    this.DokMagZatwGrupOdl = this.event.checkPermissions('DokMagZatwGrupOdl');

    this.widthWindow = this.event.setWidthPopUp();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.userId = this.event.returnUserId();
    this.translate
      .get('yes')
      .subscribe((text) => (this.filterList[0].label = text));
    this.translate.get('no').subscribe((text) => {
      this.filterList[1].label = text;
      this.ApprovalDate = this.filterList[1].label;
      this.isIncludeemployee = this.filterList[1].label;
      this.isCurrentMagazine = this.filterList[1].label;
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (this.isVisible && !this.newRowPopupVisible) {
        this.isVisible = false;
        this.onClosing.emit(true);
      }
    });

    this.myEventEnter = this.event.choose.subscribe(() => {
      if (this.isVisible && !this.readOnly) {
        this.realizePzPostponed();
      }
    });
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    this.perABD = this.event.checkPermissionsBtn(
      'DDokHandl',
      'EDokHandl',
      'UDokHandl',
      'ODokHandl'
    );

    if (this.document == 'warehouseDocuments') {
      this.perABD = this.event.checkPermissionsBtn(
        'DDokMag',
        'EDokMag',
        'UDokMag',
        'ODokMag'
      );
    }

    this.getUserParameters();
  }

  ngOnDestroy(): void {
    this.myEventEnter.unsubscribe();
    this.myEventEsc.unsubscribe();

    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.loadChildComponent = true;
    }, 500);

    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(true);
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.perABD.editBtn && this.focusedSelected.length > 0)
            this.onEdit();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + alt + w',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.realizePzPostponed(true);
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.newRowPopupVisible &&
            this.perABD.showBtn &&
            this.focusedSelected.length > 0
          ) {
            this.onShown();
          }
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(true);
    }
  };

  closeWindow = () => {
    this.isVisible = false;
    this.onClosing.emit(true);
  };

  closeNewComercialOperation = () => {
    this.newRowPopupVisible = false;
    this.cd.detectChanges();
  };

  onInsertedDocument = (e: OnInsertedData) => {
    /* Czy focusedSelected nie powinien być typu WarehouseDocument? */
    this.focusedSelected = [e.document];
    this.dataSource.reload();
    this.newRowPopupVisible = false;
    this.cd.detectChanges();
  };

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }
  }

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.getDocuments();
    }
  }

  firstLoad = true;
  indexToFocus: number;
  setFocusOnLoad = false;
  dataSource;

  getDocuments = () => {
    if (this.document == 'invoice') {
      this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'InvoiceDocumentId',
          onBeforeSend: this.event.onBeforeSendDataSource,
          loadUrl: `${environment.domain}invoices/documents`,
          loadParams: this.getLoadParams(),
          onAjaxError: this.event.onAjaxDataSourceError,
          onLoading(loadOptions: LoadOptions) {
            loadOptions.requireTotalCount = true;
          },
          onLoaded: (data) => {
            const selected: any[] = data
              .filter((el) => el.IsSelected)
              .map((el) => el.InvoiceDocumentId);
            if (selected.length > 0)
              this.event.deselectAllRecords(`invoices/selection/documents`);
            this.cd.detectChanges();
          },
        }),
        reshapeOnPush: true,
        requireTotalCount: true,
        paginate: true,
      });
    }

    if (this.document == 'warehouseDocuments') {
      this.dataSource = new DataSource({
        store: AspNetData.createStore({
          key: 'WarehouseDocumentId',
          onBeforeSend: this.event.onBeforeSendDataSource,
          loadUrl: `${environment.domain}WarehouseDocuments/documents`,
          loadParams: this.getLoadParamsWarehouse(),
          onAjaxError: this.event.onAjaxDataSourceError,
          onLoading(loadOptions: LoadOptions) {
            loadOptions.requireTotalCount = true;
          },
          onLoaded: (data) => {
            const selected: any[] = data
              .filter((el) => el.IsSelected)
              .map((el) => el.InvoiceDocumentId);
            if (selected.length > 0)
              this.event.deselectAllRecords(
                `warehouseDocuments/selection/documents`
              );
            this.cd.detectChanges();
          },
        }),
        reshapeOnPush: true,
        requireTotalCount: true,
        paginate: true,
      });
    }
  };

  getLoadParams() {
    let obj: any = {};
    console.log('idPz', this.idPz);
    obj.InvoiceTypeTreeId = this.idPz;
    obj.DateOfInvoiceDocumentStart =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.DateOfInvoiceDocumentStop =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    return obj;
  }

  getLoadParamsWarehouse() {
    let obj: any = {};

    obj.WarehouseDocumentTypeId = this.idPz;
    obj.dateStart =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.dateEnd =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    return obj;
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  onRowDblClick = () => {
    if (
      !this.readOnly &&
      this.focusedSelected.length > 0 &&
      this.perABD.editBtn
    ) {
      if (this.doubleClickResult === 'Edycja') {
        this.onEdit();
      } else {
        this.onShown();
      }
    }
  };

  getTypeTree = () => {
    return new Promise((resolve) => {
      if (this.document == 'invoice') {
        if (localStorage.getItem('InvoicesDocumentsType')) {
          let pz = this.event.decryptString(
            localStorage.getItem('InvoicesDocumentsType')
          );

          this.findPZ(
            pz,
            this.isOutcome
              ? 'Sprzedaż odłożona w czasie'
              : 'Zakupy odłożone w czasie'
          );
          this.getDocuments();
          this.cd.detectChanges();
          resolve(true);
        } else {
          this.refreshTypeTree(resolve);
        }
      } else {
        if (localStorage.getItem('WarehouseDocumentsType')) {
          let pz = this.event.decryptString(
            localStorage.getItem('WarehouseDocumentsType')
          );
          this.isOutcome
            ? this.findPZ(pz.Types.find((el) => el.TypeCode == 'Outcome').Types)
            : this.findPZ(pz.Types.find((el) => el.TypeCode == 'Income').Types);
          this.getDocuments();
          this.cd.detectChanges();
          resolve(true);
        } else {
          this.refreshTypeTree(resolve);
        }
      }
    });
  };

  idPz = null;
  findPZ = (e, name: any = 'Zakupy odłożone w czasie') => {
    e.forEach((field) => {
      if (
        field.Name == name ||
        field.TypeCode == 'DeferredIncome' ||
        field.TypeCode == 'ExpenditurePostponed'
      ) {
        this.idPz =
          this.document == 'invoice'
            ? field.InvoiceTypeTreeId
            : field.WarehouseDocumentTypeId;
      }

      if (field.items) {
        this.findPZ(field.items, name);
      }
    });
  };

  grouped;
  typeTreeGroup = [];
  typeTree;
  refreshTypeTree = (resolve) => {
    if (this.document == 'invoice') {
      this.appService.getAuth(`invoices/documents/typeTree`).subscribe(
        (res) => {
          this.typeTree = res;

          localStorage.setItem(
            'InvoicesDocumentsType',
            this.event.encryptString(this.typeTreeGroup[0].items)
          );
          if (resolve) resolve(true);

          this.appService
            .getAuth(`document/sales/types/invoice`)
            .subscribe((res) => {
              localStorage.setItem(
                'typesInvoice',
                this.event.encryptString(res.data)
              );
            });
        },
        (error) => {
          this.event.httpErrorNotification(error);
          if (resolve) resolve(false);
        }
      );
    } else {
      this.appService
        .getAuth(`WarehouseDocuments/documents/typeTree`)
        .subscribe(
          (res) => {
            this.typeTree = res;

            localStorage.setItem(
              'WarehouseDocumentsType',
              this.event.encryptString(this.typeTreeGroup[0].items)
            );
            if (resolve) resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
            if (resolve) resolve(false);
          }
        );
    }
  };

  returnValueDatePrice(value) {
    const val = this.radioOption().find((el) => el.label == value).value;

    if (val === 2) return null;
    if (val === 1) return true;

    return false;
  }

  async realizedWarehouse(all?) {
    if (!all) {
      if (!this.isOutcome) {
        let object = {
          WarehouseDocumentId: this.focusedSelected[0].WarehouseDocumentId,
          EmployeeId:
            this.isIncludeemployee == 'Nie'
              ? null
              : this.focusedSelected[0].EmployeeId,
          Date: this.ApprovalDate == 'Nie' ? null : this.approvalDate,
          InvoiceDocumentId: this.focusedSelected[0].InvoiceDocumentId,
        };

        this.appService
          .putAuth(
            `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/realizeMpPostponed`,
            object
          )
          .subscribe(
            () => {
              this.getDocuments();
              this.cd.detectChanges();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
        return;
      }

      let object = {
        ObjectId: this.focusedSelected[0].WarehouseDocumentId,
        IsControlDate: this.returnValueDatePrice(this.IsControlDate),
        IsControlPrice: this.returnValueDatePrice(this.IsControlPrice),
        IsControlMargin: this.IsControlMargin === 'Tak' ? true : false,
      };

      this.appService
        .putAuth(
          `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/realizeWzPostponed`,
          object
        )
        .subscribe(
          () => {
            this.getDocuments();
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );

      return;
    }

    if (all && !this.DokMagZatwGrupOdl) {
      this.event.showNotification(
        'error',
        this.translate.instant('permissions.noPermission')
      );
      return;
    }

    if (!this.isOutcome) {
      this.dataSource._items.forEach((field) => {
        let object = {
          WarehouseDocumentId: field.WarehouseDocumentId,
          EmployeeId: this.isIncludeemployee == 'Nie' ? null : field.EmployeeId,
          Date: this.ApprovalDate == 'Nie' ? null : this.approvalDate,
          InvoiceDocumentId: field.InvoiceDocumentId,
        };

        this.appService
          .putAuth(
            `warehouseDocuments/documents/${field.WarehouseDocumentId}/realizeMpPostponed`,
            object
          )
          .subscribe(
            () => {
              this.getDocuments();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      });

      return;
    }

    for (const item of this.dataSource._items) {
      let object = {
        ObjectId: item.WarehouseDocumentId,
        IsControlDate: this.returnValueDatePrice(this.IsControlDate),
        IsControlPrice: this.returnValueDatePrice(this.IsControlPrice),
        IsControlMargin: this.IsControlMargin === 'Tak' ? true : false,
      };

      await this.waitOperation(item.WarehouseDocumentId, object);
    }

    this.cd.detectChanges();
    this.dataSource._items.forEach((field, index) => {
      if (index == this.dataSource._items.length - 1 && field) {
        this.getDocuments();
        this.cd.detectChanges();
      }
    });
  }

  async waitOperationInvoices(InvoiceDocumentId, object) {
    await this.repairInvoices(InvoiceDocumentId, object);
  }

  repairInvoices(InvoiceDocumentId, object) {
    return new Promise((resolve) => {
      this.appService
        .putAuth(
          `invoices/documents/${InvoiceDocumentId}/realizeWzPostponed`,
          object
        )
        .subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  async waitOperation(WarehouseDocumentId, object) {
    await this.repair(WarehouseDocumentId, object);
  }

  repair(WarehouseDocumentId, object) {
    return new Promise((resolve) => {
      this.appService
        .putAuth(
          `warehouseDocuments/documents/${WarehouseDocumentId}/realizeWzPostponed`,
          object
        )
        .subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  async realizedInvoice(all?) {
    if (!all) {
      if (this.isOutcome) {
        let object = {
          ObjectId: this.focusedSelected[0].InvoiceDocumentId,
          IsControlDate: this.returnValueDatePrice(this.IsControlDate),
          IsControlPrice: this.returnValueDatePrice(this.IsControlPrice),
          IsControlMargin: this.IsControlMargin === 'Tak' ? true : false,
        };

        this.appService
          .putAuth(
            `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}/realizeWzPostponed`,
            object
          )
          .subscribe(
            () => {
              this.getDocuments();
              this.cd.detectChanges();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
        return;
      }
      let object = {
        InvoiceDocumentId: this.focusedSelected[0].InvoiceDocumentId,
        EmployeeId:
          this.isIncludeemployee == 'Nie'
            ? null
            : this.focusedSelected[0].EmployeeId,
        Date: this.ApprovalDate == 'Nie' ? null : this.approvalDate,
      };

      this.appService
        .putAuth(
          `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}/realizePzPostponed`,
          object
        )
        .subscribe(
          () => {
            this.getDocuments();
            this.cd.detectChanges();
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
      return;
    }

    if (all && !this.DokMagZatwGrupOdl) {
      this.event.showNotification(
        'error',
        this.translate.instant('permissions.noPermission')
      );
      return;
    }

    if (!this.isOutcome) {
      this.dataSource._items.forEach((field) => {
        let object = {
          InvoiceDocumentId: field.InvoiceDocumentId,
          EmployeeId: this.isIncludeemployee == 'Nie' ? null : field.EmployeeId,
          Date: this.ApprovalDate == 'Nie' ? null : this.approvalDate,
        };

        this.appService
          .putAuth(
            `invoices/documents/${field.InvoiceDocumentId}/realizePzPostponed`,
            object
          )
          .subscribe(
            () => {
              this.getDocuments();
              this.cd.detectChanges();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      });
      return;
    }

    for (const item of this.dataSource._items) {
      let object = {
        ObjectId: item.InvoiceDocumentId,
        IsControlDate: this.returnValueDatePrice(this.IsControlDate),
        IsControlPrice: this.returnValueDatePrice(this.IsControlPrice),
        IsControlMargin: this.IsControlMargin === 'Tak' ? true : false,
      };

      await this.waitOperationInvoices(item.InvoiceDocumentId, object);
    }

    this.cd.detectChanges();
    this.dataSource._items.forEach((field, index) => {
      if (index == this.dataSource._items.length - 1 && field) {
        this.getDocuments();
        this.cd.detectChanges();
      }
    });
  }

  realizePzPostponed = (all?) => {
    if (this.document == 'invoice') {
      this.realizedInvoice(all);
      return;
    }

    if (this.document == 'warehouseDocuments') {
      this.realizedWarehouse(all);
      return;
    }
  };

  onShown = () => {
    if (
      !this.readOnly &&
      this.focusedSelected.length > 0 &&
      this.perABD.showBtn
    ) {
      this.event.loadingVisible = true;
      this.mode = 'show';

      if (this.document == 'invoice') {
        this.newRowPopupVisible = true;
        this.cd.detectChanges();
      }

      if (this.document == 'warehouseDocuments') {
        this.newRowPopupVisible = true;
      }
    }
  };

  sumPositionIncome;
  onEdit = () => {
    if (
      !this.readOnly &&
      this.focusedSelected.length > 0 &&
      this.perABD.editBtn
    ) {
      this.event.loadingVisible = true;
      this.mode = 'edit';

      if (this.document == 'invoice') {
        this.appService
          .putAuth(
            `invoices/documents/${this.focusedSelected[0].InvoiceDocumentId}/prepareForModify`,
            {
              invoiceDocumentId: this.focusedSelected[0].InvoiceDocumentId,
            }
          )
          .subscribe(
            () => {
              this.event.loadingVisible = false;
              this.newRowPopupVisible = true;
              this.cd.detectChanges();
            },
            (error) => {
              this.event.loadingVisible = false;
              this.event.httpErrorNotification(error);
              this.cd.detectChanges();
            }
          );
      }

      if (this.document == 'warehouseDocuments') {
        this.appService
          .putAuth(
            `warehouseDocuments/documents/${this.focusedSelected[0].WarehouseDocumentId}/prepareForModify`,
            { warehouseDocumentId: this.focusedSelected[0].WarehouseDocumentId }
          )
          .subscribe(
            (res) => {
              this.event.loadingVisible = false;

              this.sumPositionIncome = res;
              this.newRowPopupVisible = true;
              this.cd.detectChanges();
            },
            (error) => {
              this.event.loadingVisible = false;
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };

  onValueChangedState = (e) => {
    if (e) this.ApprovalDate = this.filterList[0].label;
    else this.ApprovalDate = this.filterList[1].label;
  };

  onValueIncludeemployee = (e) => {
    if (e) this.isIncludeemployee = this.filterList[0].label;
    else this.isIncludeemployee = this.filterList[1].label;
  };

  onValueCurrentMagazine = (e) => {
    if (e) this.isCurrentMagazine = this.filterList[0].label;
    else this.isCurrentMagazine = this.filterList[1].label;
  };

  onValueChangedControlDate(e) {
    const value = this.radioOption().find((el) => el.value == e).label;
    this.IsControlDate = value;
  }

  onValueChangedControlPrice(e) {
    const value = this.radioOption().find((el) => el.value == e).label;
    this.IsControlPrice = value;
  }

  onValueChangedControlMargin(e) {
    if (e) this.IsControlMargin = this.filterList[0].label;
    else this.IsControlMargin = this.filterList[1].label;
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-individual-prices-list',
    templateUrl: './individual-prices-list.component.html',
    styleUrls: ['./individual-prices-list.component.scss'],
    inputs: ['readOnly', 'isVisible', 'title', 'document', 'documentType'],
    standalone: false
})
export class IndividualPricesListComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onSaved = new EventEmitter();
  readOnly;
  isVisible;
  title;
  document;

  unicalGuid;
  widthWindow = 430;
  heightWindow = 250;
  maxHeight;
  maxWidth;
  Amount = 0;

  shortcuts: ShortcutInput[] = [];

  optionList = [
    { label: 'z pozycji dokumnetu', value: 0 },
    { label: 'z cennika', value: 1 },
  ];
  pricingOptionValue;
  documentType;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;

    this.translate
      .get('form-commercial-operation.fromThePositionOfTheDocument')
      .subscribe((text: string) => {
        this.optionList[0].label = text;
      });

    this.translate
      .get('form-commercial-operation.fromThePriceList')
      .subscribe((text: string) => {
        this.optionList[1].label = text;
      });

    this.pricingOptionValue = 0;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
    this.getPrices();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  onCancel = () => {
    this.closeWindow();
  };

  pricesList: any[] = [];
  priceValue;
  getPrices() {
    this.appService.getAuth(`products/prices?skip=0&take=100`).subscribe(
      (data) => {
        this.pricesList = data.data;
        this.priceValue = this.pricesList[0]?.PriceId;
      },
      (error) => {
        this.event.loadingVisible = false;
        this.event.httpErrorNotification(error);
      }
    );
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  closeWindow() {
    this.DiscountMarkup = 0;
    this.pricingOptionValue = 0;
    this.onClosing.emit(true);
  }

  visibleChange = (e) => {
    this.Amount = 0;
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  DiscountMarkup = 0;
  onSave = () => {
    if (!this.readOnly) {
      let object = {
        CustomerId: 0,
        PriceId: 0,
        DiscountMarkup: 0,
        InvoiceDocumentId: 0,
        WarehouseDocumentId: 0,
        OrderDocumentId: 0,
        OfferDocumentId: 0,
      };

      object.CustomerId = this.document.CustomerId;

      let documetnId;
      if (this.documentType == 'invoices') {
        object.InvoiceDocumentId = this.document.InvoiceDocumentId;
        documetnId = this.document.InvoiceDocumentId;
      }

      if (this.documentType == 'warehouseDocuments') {
        object.WarehouseDocumentId = this.document.WarehouseDocumentId;
        documetnId = this.document.WarehouseDocumentId;
      }

      if (this.documentType == 'orders') {
        object.OrderDocumentId = this.document.OrderDocumentId;
        documetnId = this.document.OrderDocumentId;
      }

      if (this.pricingOptionValue == 1) {
        object.PriceId = this.priceValue;
        object.DiscountMarkup = this.DiscountMarkup * 100;
      }

      this.appService
        .postAuth(
          `${this.documentType}/additionalOperations/${documetnId}/createPriceListForCustomer`,
          object
        )
        .subscribe(
          () => {
            this.event.showNotification(
              'success',
              this.translate.instant(
                'form-commercial-operation.basedOnTheSelectedItemsIndividualPricesForTheContractorWereModified'
              )
            );
            this.closeWindow();
          },
          (error) => {
            this.event.loadingVisible = false;
            this.event.httpErrorNotification(error);
          }
        );
    }
  };
}

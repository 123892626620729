<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="event.deviceType != 'mobile' ? title : ''"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); getData()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'buttons.edit' | translate }}"
        style="margin-right: 6px"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
        (onClick)="onRowDblClick()"
        [disabled]="focusedSelected.length == 0"
      >
      </dx-button>
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{
          'form-commercial-operation.verificationOfTheAmount' | translate
        }}"
        style="margin-right: 6px"
        [id]="'verificationOfTheAmount' + unicalGuid"
        [disabled]="!IsVerifcationAmoutsSplitPaymentButtonEnable"
        *ngIf="!form.IsDeferredIncome"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onSave()"
        [ngClass]="{ 'margin-top-varification': event.deviceType === 'mobile' }"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
        [ngClass]="{ 'margin-top-varification': event.deviceType === 'mobile' }"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div
        class="row"
        [ngClass]="{ 'margin-top-varification': event.deviceType === 'mobile' }"
      >
        <div class="col-md-12" style="padding-right: 15px">
          <dx-radio-group
            [items]="keepsRecords"
            [layout]="event.deviceType !== 'mobile' ? 'horizontal' : 'vertical'"
            [readOnly]="readOnly"
            displayExpr="label"
            valueExpr="value"
            [(value)]="isTaxGreaterThanZero"
            (onValueChanged)="getData()"
          >
          </dx-radio-group
          ><br />

          <dx-data-grid
            #grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="dataSource"
            shortcut
            [focusedRowEnabled]="true"
            [columnResizingMode]="'widget'"
            [(selectedRowKeys)]="selectedRowKeys"
            [(focusedRowIndex)]="focusedRowIndex"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            style="
              width: 100%;
              min-height: 50px;
              margin-bottom: 10px;
              height: calc(70vh - 190px);
            "
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            (onRowDblClick)="onRowDblClick()"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            ></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              [caption]="'M'"
              [allowSorting]="false"
              dataField="IsModified"
              width="40"
              cellTemplate="IsModified"
              *ngIf="!form.IsDeferredIncome"
            ></dxi-column>

            <div *dxTemplate="let data of 'IsModified'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.IsModified; else nonDefaultTemplate"
              ></i>
            </div>

            <dxi-column
              [caption]="'S'"
              dataField="IsSell"
              [allowSorting]="false"
              width="40"
              cellTemplate="IsSell"
              *ngIf="!form.IsDeferredIncome"
              [hidingPriority]="15"
            ></dxi-column>
            <div *dxTemplate="let data of 'IsSell'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.IsSell; else nonDefaultTemplate"
              ></i>
            </div>

            <dxi-column
              [caption]="'Z'"
              dataField="IsBuy"
              [allowSorting]="false"
              width="40"
              cellTemplate="IsBuy"
              *ngIf="!form.IsDeferredIncome"
              [hidingPriority]="14"
            ></dxi-column>
            <div *dxTemplate="let data of 'IsBuy'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.IsBuy; else nonDefaultTemplate"
              ></i>
            </div>

            <dxi-column
              [caption]="'warehouseDocument.dateOfTax' | translate"
              dataField="TaxDate"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              *ngIf="!form.IsDeferredIncome"
              [hidingPriority]="13"
            ></dxi-column>
            <dxi-column
              [caption]="'servicesDevices.VATcode' | translate"
              dataField="TaxCode"
              [allowSorting]="false"
              width="60"
              [hidingPriority]="12"
            ></dxi-column>
            <dxi-column
              [caption]="'form-commercial-operation.grid.netValue' | translate"
              dataField="TotalNetAmount"
              [allowSorting]="false"
              width="100"
              cellTemplate="PriceNet"
              [hidingPriority]="11"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'PriceNet'">
              {{
                data.data.TotalNetAmount?.toFixed(2)
                  | currency : " "
                  | pointReplacer
              }}
            </div>

            <dxi-column
              [caption]="'VAT'"
              dataField="TotalTaxAmount"
              [allowSorting]="false"
              width="100"
              cellTemplate="PriceVat"
              [hidingPriority]="10"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'PriceVat'">
              {{
                data.data.TotalTaxAmount?.toFixed(2)
                  | currency : " "
                  | pointReplacer
              }}
            </div>

            <dxi-column
              [caption]="
                'form-commercial-operation.grid.grossValue' | translate
              "
              dataField="InvoiceDocumentId"
              [allowSorting]="false"
              width="100"
              cellTemplate="PriceGross"
              [hidingPriority]="9"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'PriceGross'">
              {{
                data.data.TotalNetAmount + data.data.TotalTaxAmount
                  | currency : " "
                  | pointReplacer
              }}
            </div>

            <dxi-column
              [caption]="
                'form-commercial-operation.TotalNetAmountCurrency' | translate
              "
              dataField="TotalNetAmountCurrency"
              [allowSorting]="false"
              width="130"
              cellTemplate="TotalNetAmountCurrency"
              *ngIf="IsCurrencyDocument"
              [hidingPriority]="8"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalNetAmountCurrency'">
              {{
                data.data.TotalNetAmountCurrency?.toFixed(2)
                  | currency : " "
                  | pointReplacer
              }}
            </div>

            <dxi-column
              [caption]="'buffor.vatCurrency' | translate"
              dataField="TotalTaxAmountCurrency"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalTaxAmountCurrency"
              *ngIf="IsCurrencyDocument"
              [hidingPriority]="7"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalTaxAmountCurrency'">
              {{
                data.data.TotalTaxAmountCurrency?.toFixed(2)
                  | currency : " "
                  | pointReplacer
              }}
            </div>

            <dxi-column
              [caption]="
                'form-commercial-operation.TotalGrossAmountCurrency' | translate
              "
              dataField="TotalTaxAmountCurrency"
              [allowSorting]="false"
              width="130"
              cellTemplate="PriceGrossCurrency"
              *ngIf="IsCurrencyDocument"
              [hidingPriority]="6"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'PriceGrossCurrency'">
              {{
                data.data.TotalTaxAmountCurrency +
                  data.data.TotalNetAmountCurrency
                  | currency : " "
                  | pointReplacer
              }}
            </div>

            <dxi-column
              [caption]="'dateRange.mounth' | translate"
              dataField="MonthNumber"
              [allowSorting]="false"
              *ngIf="!form.IsDeferredIncome"
              [hidingPriority]="5"
            ></dxi-column>
            <dxi-column
              [caption]="'dateRange.quarter' | translate"
              dataField="QuarterNumber"
              *ngIf="!form.IsDeferredIncome"
              [allowSorting]="false"
              [hidingPriority]="4"
            ></dxi-column>
            <dxi-column
              [caption]="'dateRange.year' | translate"
              dataField="YearNumber"
              [allowSorting]="false"
              *ngIf="!form.IsDeferredIncome"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              [caption]="'warehouseDocument.dateOfTheCourse' | translate"
              dataField="CurrencyFactorDate"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              *ngIf="IsCurrencyDocument && !form.IsDeferredIncome"
              [hidingPriority]="2"
            ></dxi-column>

            <dxi-column
              [caption]="'currencyBox.grid.rate' | translate"
              dataField="CurrencyFactor"
              [allowSorting]="false"
              width="110"
              *ngIf="IsCurrencyDocument && !form.IsDeferredIncome"
              cellTemplate="CurrencyFactor"
              [hidingPriority]="1"
            ></dxi-column>
            <div *dxTemplate="let data of 'CurrencyFactor'">
              {{ data.data.CurrencyFactor | amountFormatter : data : 4 }}
            </div>
          </dx-data-grid>

          <div class="count-container">
            <div class="row">
              <div class="col-6 text-left">
                <div
                  class="contener-pay"
                  style="float: left; padding-right: 15px"
                  *ngIf="IsCurrencyDocument"
                >
                  <p class="p-toPay-net">
                    {{
                      !IsCurrencyDocument
                        ? (sumPositionCost.TotalNetAmountCurrency?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : (sumPositionCost.TotalNetAmount?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                    }}
                  </p>
                  <p class="des-toPay">
                    {{ "form-commercial-operation.net" | translate }}
                    PLN
                  </p>
                </div>

                <div
                  class="contener-pay"
                  style="float: left; padding-right: 15px"
                  [ngClass]="{
                    'margin-top-varification': event.deviceType === 'mobile'
                  }"
                  *ngIf="IsCurrencyDocument"
                >
                  <p class="p-toPay-net">
                    {{
                      !IsCurrencyDocument
                        ? (sumPositionCost.TotalTaxAmountCurrency?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : (sumPositionCost.TotalTaxAmount?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                    }}
                  </p>
                  <p class="des-toPay">VAT</p>
                </div>

                <div
                  class="contener-pay"
                  style="float: left"
                  *ngIf="IsCurrencyDocument"
                  [ngClass]="{
                    'margin-top-varification': event.deviceType === 'mobile'
                  }"
                >
                  <p class="p-toPay">
                    {{
                      !IsCurrencyDocument
                        ? (sumPositionCost.TotalGrossAmountCurrency?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : (sumPositionCost.TotalGrossAmount?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                    }}
                  </p>
                  <p class="des-toPay">
                    {{ "form-commercial-operation.gross" | translate }}

                    PLN
                  </p>
                </div>
              </div>
              <div class="col-6 text-right">
                <div class="contener-pay">
                  <p class="p-toPay">
                    {{
                      IsCurrencyDocument
                        ? (sumPositionCost.TotalGrossAmountCurrency?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : isCorrect
                        ? (sumPositionCost.TotalGrossAmountCorrection?.toFixed(
                            2
                          )
                          | currency : " "
                          | pointReplacer)
                        : (sumPositionCost.TotalGrossAmount?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                    }}
                  </p>
                  <p class="des-toPay">
                    {{ "form-commercial-operation.gross" | translate }}

                    {{ CurrencyCode }}
                  </p>
                </div>

                <div
                  class="contener-pay"
                  style="padding-right: 15px"
                  [ngClass]="{
                    'margin-top-varification': event.deviceType === 'mobile'
                  }"
                >
                  <p class="p-toPay-net">
                    {{
                      IsCurrencyDocument
                        ? (sumPositionCost.TotalTaxAmountCurrency?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : isCorrect
                        ? (sumPositionCost.TotalTaxAmountCorrection?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : (sumPositionCost.TotalTaxAmount?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                    }}
                  </p>
                  <p class="des-toPay">VAT</p>
                </div>

                <div
                  class="contener-pay"
                  style="padding-right: 15px"
                  [ngClass]="{
                    'margin-top-varification': event.deviceType === 'mobile'
                  }"
                >
                  <p class="p-toPay-net">
                    {{
                      IsCurrencyDocument
                        ? (sumPositionCost.TotalNetAmountCurrency?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : isCorrect
                        ? (sumPositionCost.TotalNetAmountCorrection?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                        : (sumPositionCost.TotalNetAmount?.toFixed(2)
                          | currency : " "
                          | pointReplacer)
                    }}
                  </p>
                  <p class="des-toPay">
                    {{ "form-commercial-operation.net" | translate }}
                    {{ CurrencyCode }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<div *ngIf="loadChildComponent">
  <app-edit-vat
    [readOnly]="readOnly"
    [isVisible]="isEdit"
    title="{{ 'form-commercial-operation.editTheVATRate' | translate }}"
    [IsCurrencyDocument]="IsCurrencyDocument"
    [selected]="focusedSelected"
    (onClosing)="onClosingVatEdit()"
    (onInserted)="onInserted($event)"
  >
  </app-edit-vat>
</div>

<ng-template #nonDefaultTemplate>
  <i class="icon absui-icon--close close-grid"></i>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

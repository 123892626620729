import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DxDropDownBoxComponent } from 'devextreme-angular';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { forkJoin } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';

@Component({
    selector: 'app-transaction-delivery-transport-cods',
    templateUrl: './transaction-delivery-transport-cods.component.html',
    styleUrls: ['./transaction-delivery-transport-cods.component.scss'],
    standalone: false
})
export class TransactionDeliveryTransportCodsComponent {
  @ViewChild('countryDropDownBox') countryDropDownBox: DxDropDownBoxComponent;
  @ViewChild('gridClass') gridClass;

  @Output() onChoosed = new EventEmitter();

  @Input() readOnly: boolean;
  @Input() width;
  @Input() className;
  @Input() selectedCode;
  @Input() type: string;

  addTransactionCode = false;
  addTransportCode = false;
  addDeliveryCode = false;
  editTransactionCode = false;
  editTransportCode = false;
  editDeliveryCode = false;
  url;
  code;
  codeId;
  dataSource;
  availableValue = [];
  availableValuePom = [];
  choosingValue = [];
  valueExpr: string;
  isGridBoxOpened = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  isDeleteCode = false;
  shortcuts: ShortcutInput[] = [];

  constructor(
    private appService: AppServices,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.selectedCode === null) {
      this.selectedCode = [];
    }
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.availableValue = [];
    this.choosingValue = [];
    switch (this.type) {
      case 'delivery':
        this.url = `intrastates/intrastatedelivery`;
        this.code = 'DeliveryCode';
        this.codeId = 'IntrastateDeliveryId';
        break;
      case 'transport':
        this.url = `intrastates/intrastatetransport`;
        this.code = 'TransportCode';
        this.codeId = 'IntrastateTransportId';
        break;
      case 'transaction':
        this.url = `intrastates/intrastatetransaction`;
        this.code = 'TransactionCode';
        this.codeId = 'IntrastateTransactionId';
    }

    if (
      this.selectedCode?.length &&
      changes.selectedCode.currentValue !== changes.selectedCode.previousValue
    ) {
      this.appService
        .getAuth(
          `${this.url}?${this.code}=${changes.selectedCode.currentValue}`
        )
        .subscribe((response) => {
          this.availableValue = response.data;
          this.choosingValue = response.data[0][this.codeId];
          this.cd.detectChanges();
        });
    }
  }

  onOpenedChanged = (e) => {
    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  };

  onRowDblClick = () => {
    this.onChoosing();
  };

  onChoosing = () => {
    if (!this.readOnly) {
      this.availableValue = [this.focusedRowData];
      this.choosingValue = this.availableValue[0][this.codeId];
      this.onChoosed.emit([this.focusedRowData]);
      this.isGridBoxOpened = false;
    }
  };

  selectedCodeId = [];
  focusedRowData = null;

  onFocusedRowChanged(e) {
    this.selectedCode = [e.row.data[this.code]];
    this.selectedCodeId = [e.row.data[this.codeId]];
    this.focusedRowData = e.row.data;
  }

  onSelectionChanged = (e) => {
    this.availableValuePom = [];
    this.availableValuePom.push(e.selectedRowsData[0]);
  };

  getData = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: this.code,
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.url}`,
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}${this.url}`,
      }),
      reshapeOnPush: true,
    });
  };

  onInserted = () => {
    this.dataSource.reload();
  };

  addCode() {
    switch (this.type) {
      case 'delivery':
        this.addDeliveryCode = true;
        break;
      case 'transport':
        this.addTransportCode = true;
        break;
      case 'transaction':
        this.addTransactionCode = true;
    }
  }

  onClose() {
    this.addTransactionCode = false;
    this.editTransactionCode = false;
    this.addDeliveryCode = false;
    this.editDeliveryCode = false;
    this.addTransportCode = false;
    this.editTransportCode = false;
    this.gridClass.instance.focus();
    this.cd.detectChanges();
  }

  onEditClick(e) {
    e.event.stopPropagation();

    switch (this.type) {
      case 'delivery':
        this.editDeliveryCode = true;
        break;
      case 'transport':
        this.editTransportCode = true;
        break;
      case 'transaction':
        this.editTransactionCode = true;
    }
  }

  isDelete() {
    if (this.selectedCodeId.length && !this.readOnly && this.isGridBoxOpened) {
      this.isDeleteCode = true;
    }
  }

  delete() {
    switch (this.type) {
      case 'delivery':
        this.appService
          .deleteAuth(`${this.url}/${this.selectedCodeId[0]}`)
          .subscribe(
            () => {
              this.isDeleteCode = false;
              this.selectedCodeId = [];
              this.dataSource.reload();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
        break;
      case 'transport':
        this.appService
          .deleteAuth(`${this.url}/${this.selectedCodeId[0]}`)
          .subscribe(
            () => {
              this.isDeleteCode = false;
              this.selectedCodeId = [];
              this.dataSource.reload();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
        break;
      case 'transaction':
        this.appService
          .deleteAuth(`${this.url}/${this.selectedCodeId[0]}`)
          .subscribe(
            () => {
              this.isDeleteCode = false;
              this.selectedCodeId = [];
              this.dataSource.reload();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
    }
  }

  setSelfFocus() {
    this.countryDropDownBox.instance.focus();
  }

  closeConfirm() {
    this.isDeleteCode = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  }

  onSetDefault() {
    const requests = [];
    let newReq;

    const newDefault = Object.assign({}, this.focusedRowData);
    newDefault.IsDefault = !newDefault.IsDefault;

    switch (this.type) {
      case 'delivery':
        newReq = this.appService.putAuth(
          `${this.url}/${newDefault.DeliveryCode}`,
          newDefault
        );
        break;
      case 'transport':
        newReq = this.appService.putAuth(
          `${this.url}/${newDefault.TransportCode}`,
          newDefault
        );

        break;
      case 'transaction':
        newReq = this.appService.putAuth(
          `${this.url}/${newDefault.TransactionCode}`,
          newDefault
        );
    }

    requests.push(newReq);

    if (newDefault.IsDefault) {
      const oldDefaultItem = this.dataSource.items().find((el) => el.IsDefault);
      if (oldDefaultItem) {
        oldDefaultItem.IsDefault = false;
        let oldReq;
        switch (this.type) {
          case 'delivery':
            oldReq = this.appService.putAuth(
              `${this.url}/${oldDefaultItem.DeliveryCode}`,
              oldDefaultItem
            );
            break;
          case 'transport':
            oldReq = this.appService.putAuth(
              `${this.url}/${oldDefaultItem.TransportCode}`,
              oldDefaultItem
            );
            break;
          case 'transaction':
            oldReq = this.appService.putAuth(
              `${this.url}/${oldDefaultItem.TransactionCode}`,
              oldDefaultItem
            );
        }

        requests.push(oldReq);
      }
    }
    forkJoin(requests).subscribe(
      () => {
        this.dataSource.reload();
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  }
}

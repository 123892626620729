<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); getTaxMoment()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.edit' | translate }}"
        type="danger"
        [disabled]="readOnly || focusedSelected.length == 0"
        (onClick)="isEditTaxMoment = true"
        [id]="'edit' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onSave()"
        data-cy="save-tax-moment-invoice-documnent-btn"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="d-flex justify-content-center flex-wrap">
        <dx-data-grid
          [showBorders]="true"
          [wordWrapEnabled]="false"
          [hoverStateEnabled]="true"
          [dataSource]="dataSource"
          shortcut
          [focusedRowEnabled]="true"
          [allowColumnResizing]="true"
          [columnResizingMode]="'widget'"
          [(selectedRowKeys)]="selectedRowKeys"
          style="
            width: 100%;
            min-height: 50px;
            margin-bottom: 10px;
            margin-top: 10px;
          "
          [height]="290"
          (onFocusedRowChanged)="onFocusedRowChanged($event)"
          (onRowDblClick)="onRowDblClick()"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxo-scrolling mode="infinite"></dxo-scrolling>
          <dxo-paging [pageSize]="50"></dxo-paging>
          <dxo-selection
            mode="multiple"
            showCheckBoxesMode="always"
            selectAllMode="page"
          ></dxo-selection>
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

          <dxi-column
            [caption]="'form-financial-document.number' | translate"
            dataField="WarehouseDocumentNumber"
            [allowSorting]="false"
          >
          </dxi-column>
          <dxi-column
            [caption]="'document-type.signature' | translate"
            dataField="Signature"
            [allowSorting]="false"
          >
          </dxi-column>
          <dxi-column
            [caption]="'form-financial-document.date' | translate"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            dataField="DateOfDocument"
            [allowSorting]="false"
          >
          </dxi-column>
          <dxi-column
            [caption]="'warehouseDocument.dateOfTax' | translate"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            dataField="DateOfTax"
            [allowSorting]="false"
          >
          </dxi-column>

          <dxi-column
            [caption]="'warehouseDocument.dateOfTheCourse' | translate"
            dataType="date"
            [format]="{ type: event.dateFormat }"
            width="110"
            alignment="left"
            dataField="DateOfCurrencyTax"
            [allowSorting]="false"
            *ngIf="documentData?.IsCurrencyDocument"
          >
          </dxi-column>

          <dxi-column
            [caption]="'currencyBox.grid.rate' | translate"
            dataField="CurrencyFactor"
            [allowSorting]="false"
            *ngIf="documentData?.IsCurrencyDocument"
            cellTemplate="rateTemplate"
          >
          </dxi-column>
          <div *dxTemplate="let data of 'rateTemplate'">
            {{data.data.CurrencyFactor | amountFormatter : data:4}}
            </div>
        </dx-data-grid>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-edit-tax-moment
  [isVisible]="isEditTaxMoment"
  [selected]="focusedSelected"
  (onClosing)="isEditTaxMoment = false"
  [currencyCode]="documentData.CurrencyCode"
  (onClosing)="isEditTaxMoment = false"
  (onInserted)="onEditedTaxMoment($event)"
>
</app-edit-tax-moment>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || isEditTaxMoment"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

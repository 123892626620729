<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      #scrollView
      [formGroup]="form"
    >
      <div class="d-flex justify-content-center flex-wrap">
        <div
          class="form-group-inline-right column-content"
          style="width: 460px"
        >
          <div class="form-box">
            <div class="box-title">
              <div class="c-frame-row" style="width: 100%">
                <h5 *ngIf="form.value.CurrencyCode != ''">
                  {{ "bankAccounts.form.currency" | translate }}:
                  {{ currencyCode }}
                </h5>
                <h5 *ngIf="form.value.CurrencyCode == ''">
                  {{ "warehouseDocument.dateOfTax" | translate }}
                </h5>
              </div>
            </div>
          </div>
          <div class="form-box">
            <div class="box-title">
              <label style="width: 80px; position: relative; top: -3px">{{
                "warehouseDocument.dateOfTax" | translate
              }}</label>
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                formControlName="DateOfTax"
                [readOnly]="readOnly"
              >
              </wapro-date-box
              ><br />
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 80px; position: relative; top: -3px">{{
                "warehouseDocument.dateOfTheCourse" | translate
              }}</label>
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                formControlName="DateOfCurrencyTax"
                [readOnly]="readOnly || form.value.CurrencyCode == ''"
              >
              </wapro-date-box
              ><br />
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 80px">{{
                "currencyBox.grid.rate" | translate
              }}</label>
              <dx-number-box
                formControlName="CurrencyFactor"
                [readOnly]="readOnly || form.value.CurrencyCode == ''"
                valueChangeEvent="input"
                [format]="event.formatCurrency"
                [width]="276"
              ></dx-number-box>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

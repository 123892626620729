import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxDataGridModule } from 'devextreme-angular/ui/data-grid';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from '../../../event.service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { LoadOptions } from 'devextreme/data';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-positions-connection-sell',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxScrollViewModule,
        DxDataGridModule,
        NgShortcutsComponent,
        TranslateModule,
    ],
    templateUrl: './positions-connection-sell.component.html',
    styleUrl: './positions-connection-sell.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: [
        'isVisible',
        'readOnly',
        'warehouseDocumentId',
        'mode',
        'identyfikator',
    ]
})
export class PositionsConnectionSellComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('gridBank') gridBank;

  widthWindow = '90%';
  heightWindow = 600;
  mode: string = 'warehouseDocuments';
  identyfikator: string = 'WarehouseDocumentId';
  unicalGuid;
  isVisible: boolean = false;
  readOnly;
  dataSource;
  warehouseDocumentId;
  selectedRows: any[] = [];
  focusedRowIndex: number = 0;

  shortcuts: ShortcutInput[] = [];

  constructor(
    public event: EventService,
    public cd: ChangeDetectorRef,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit() {}

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.positionsConnectionSell();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }

  async onKeyDown(e) {
    e.event.preventDefault();
  }

  toDeselect = [];
  toSelect = [];
  sendRequestTimer;
  onSelectionChanged(e) {
    this.toSelect.push(...e.currentSelectedRowKeys);
    if (e.currentDeselectedRowKeys.length > 0)
      this.toDeselect.push(e.currentDeselectedRowKeys[0]);

    this.toDeselect = this.event.removeDuplicates(this.toDeselect);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    this.sendRequestTimer = setTimeout(() => {
      if (this.selectedRows.length > 0) {
        this.toSelect = [];
        this.event.selectRecords(
          `${this.mode}/selection/positionsConnectionSell/select`,
          this.selectedRows
        );
      }
      if (this.toDeselect.length) {
        this.event.selectRecords(
          `${this.mode}/selection/positionsConnectionSell/unselect`,
          this.toDeselect
        );
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  getData() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'ProductConnectSellId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.mode}/documents/${this.warehouseDocumentId}/positionsConnectionSell`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          if (data.length > 0) {
            setTimeout(() => {
              this.event.setFocus(this.gridBank);
              this.gridBank.instance.selectAll();
            }, 0);
          }
        },
        deleteUrl: `${environment.domain}${this.mode}/documents`,
      }),

      reshapeOnPush: true,
      requireTotalCount: true,
    });
  }

  positionsConnectionSell() {
    let uri;
    if (this.mode === 'warehouseDocuments')
      uri = `${this.mode}/documents?ObjectId=${this.warehouseDocumentId}`;
    if (this.mode === 'invoices')
      uri = `${this.mode}/documents?InvoiceDocumentId=${this.warehouseDocumentId}`;

    this.appService.getAuth(uri).subscribe((data: any) => {
      if (data.data.length > 0) {
        let object = {
          WarehouseDocumentId: data.data[0].WarehouseDocumentId,
          ExtWarehouseId: data.data[0].WarehouseId,
          InvoiceDocumentId: data.data[0].InvoiceDocumentId,
        };

        this.appService
          .postAuth(
            `${this.mode}/documents/${this.warehouseDocumentId}/positionsConnectionSell`,
            object
          )
          .subscribe(
            () => {
              this.onInserted.emit(true);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else {
        this.event.showNotification(
          'error',
          'Nie znaleziono dokumentu magazynowego'
        );
      }
    });
  }

  getLoadParams() {
    let obj: any = {};

    obj[this.identyfikator] = this.warehouseDocumentId;

    obj.IsMinAmountOk = true;
    return obj;
  }

  visibleChange = (e) => {
    if (!e) {
      // this.onClosing.emit(false);
    }
  };

  closeWindow() {
    this.isVisible = false;
    this.cd.detectChanges();
    this.onClosing.emit(false);
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-rest-window',
    templateUrl: './rest-window.component.html',
    styleUrls: ['./rest-window.component.scss'],
    inputs: ['readOnly', 'isVisible', 'title', 'documentData'],
    standalone: false
})
export class RestWindowComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;
  readOnly;
  isVisible;
  title;
  documentData;

  unicalGuid;
  widthWindow = 450;
  heightWindow = 200;
  maxHeight;
  maxWidth;

  shortcuts: ShortcutInput[] = [];

  payment: Number = 0;
  change: Number = 0;
  myTimerWarehouse;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngOnChanges() {
    if (this.isVisible) {
    }
  }

  ngAfterViewInit(): void {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.closeWindow();
      },
      preventDefault: true,
    });
  }

  closeWindow() {
    this.onClosing.emit(true);
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.payment = 0;
    }
  };

  onEnterKey() {
    if (Number(this.payment) < this.CurrentPayment.value) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'form-commercial-operation.pleaseEnterAnAmountGreaterThanTheAmountDue'
        )
      );
    }
  }

  @ViewChild('CurrentPayment') CurrentPayment;
  onValueChanged = () => {
    if (this.myTimerWarehouse) {
      clearTimeout(this.myTimerWarehouse);
    }

    this.myTimerWarehouse = setTimeout(() => {
      this.change =
        Number(this.payment) - Number(this.documentData.CurrentPayment);
      if (Number(this.change) < 0) {
        this.change = 0;
      }
      this.cd.detectChanges();
    }, 100);
  };
}

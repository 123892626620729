<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'deliveries.exemplaryCodes' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  (onShown)="
    event.onShownPopUp(); event.setFocus(SpecimenCode); setId(); IsGroup = false
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 550px">
          <form [formGroup]="form">
            <label style="width: 245px"
              >{{ "bankBox.grid.columns.postalCode" | translate }}
              <strong>*</strong></label
            >
            <dx-text-box
              width="276"
              formControlName="SpecimenCode"
              [ngClass]="{
                'input-required': !form.controls.SpecimenCode.valid && submitted
              }"
              [readOnly]="readOnly"
              valueChangeEvent="input"
              #SpecimenCode
            ></dx-text-box>
            <br />

            <label style="width: 245px"></label>
            <dx-check-box
              [text]="'deliveries.enteringSampleCodesInGroups' | translate"
              formControlName="IsGroup"
              [readOnly]="readOnly || Amount <= 1 || mode == 'edit'"
              (onValueChanged)="onValueChanged($event.value)"
            ></dx-check-box>

            <br />

            <label style="width: 245px"
              >{{ "deliveries.numberOfAllPossibleCodes" | translate }}
            </label>
            <dx-number-box
              width="276"
              [(value)]="Amount"
              [readOnly]="true"
              valueChangeEvent="input"
            ></dx-number-box>

            <label style="width: 245px"
              >{{ "deliveries.numberOfCodesToEnter" | translate }}
            </label>
            <dx-number-box
              width="276"
              [readOnly]="readOnly || !form.value.IsGroup || mode == 'edit'"
              valueChangeEvent="input"
              formControlName="Amonut"
              [max]="Amount"
            ></dx-number-box>

            <label style="width: 245px"
              >{{ "deliveries.valueIncrement" | translate }}
            </label>
            <dx-number-box
              width="276"
              [readOnly]="readOnly || !form.value.IsGroup || mode == 'edit'"
              valueChangeEvent="input"
              formControlName="Interval"
            ></dx-number-box>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
  #keyboardShortcuts
>
</wapro-keyboard-shortcuts>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <form [formGroup]="form">
        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
          <div
            class="form-group-inline-right flex-box-column"
            style="width: 100%"
          >
            <div class="form-box">
              <div class="box-title">
                <label style="width: 120px">Status</label>
                <dx-select-box
                  width="276"
                  [dataSource]="offerStatuses"
                  formControlName="OfferDocumentStatus"
                  valueExpr="StatusCode"
                  [displayExpr]="offerStatusDisplay"
                  itemTemplate="item"
                >
                  <div *dxTemplate="let data of 'item'">
                    <div>{{ data.StatusName }} ({{ data.StatusCode }})</div>
                  </div>
                </dx-select-box>
              </div>
            </div>
            <div class="form-box">
              <div class="box-title">
                <label style="width: 120px">{{
                  "offers.comment" | translate
                }}</label>
                <app-description-dictionary
                  [dropDownBoxMode]="true"
                  type="K"
                  controllerType="offers"
                  (onClosed)="cd.detectChanges()"
                  formControlName="Comment"
                  widthArea="470"
                  heightArea="230"
                  title="{{ 'offers.comment' | translate }}"
                  (onChoosed)="onChoosedComment($event)"
                >
                </app-description-dictionary>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

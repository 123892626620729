import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { forkJoin } from 'rxjs';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-payment-invoice',
    templateUrl: './payment-invoice.component.html',
    styleUrls: ['./payment-invoice.component.scss'],
    inputs: [
        'isVisible',
        'TotalGrossAmount',
        'InvoiceDocumentId',
        'CurrencyCode',
        'PaymentFormName',
        'readOnly',
    ],
    standalone: false
})
export class PaymentInvoiceComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @Output() onPaymentFormNameChanged = new EventEmitter();

  isVisible;
  InvoiceDocumentId;
  CurrencyCode;
  widthWindow = 870;
  heightWindow = '90%';
  //heightWindow = 669;
  title = 'Prosta forma płatności';
  unicalGuid;
  TotalGrossAmount;
  fullScreen: boolean = false;
  PaymentFormName;
  readOnly;
  paid = 0;
  rest = 0;
  left = 0;

  isCash: boolean = false;
  isSpillway: boolean = false;
  isCreditCard: boolean = false;
  isVoucher: boolean = false;
  scrollPosition = 0;
  cashValue = 0;
  spillwayValue = 0;
  creditCardValue = 0;
  voucherValue = 0;
  isSetDefaultCashValue: boolean = false;
  isSmashDonations: boolean = false;
  shortcuts: ShortcutInput[] = [];
  paymentFormChosed = <any>['isCash'];

  isAdvancePayment: boolean = false;

  advancePaymentList: AdvancePayment[] = [];
  addRow: boolean = false;

  idDownloadCurses: boolean = false;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('form-commercial-operation.simpleFormOfPayment')
      .subscribe((text) => (this.title = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    if (localStorage.getItem('fullscreenpaymentinvoices'))
      this.fullScreen =
        localStorage.getItem('fullscreenpaymentinvoices') == 'false'
          ? false
          : true;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  setPaymentForm() {
    setTimeout(() => {
      if (this.PaymentFormName == null) {
        this.activeFormPayment('isCash');
      }
      if (this.PaymentFormName == 'gotówka') {
        this.activeFormPayment('isCash');
      }

      if (this.PaymentFormName == 'przelew') {
        this.activeFormPayment('isSpillway');
      }

      if (this.PaymentFormName == 'karta kredytowa') {
        this.activeFormPayment('isCreditCard');
      }
    }, 0);
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(true);
        },
        preventDefault: true,
      }
    );
  }

  fullScreenChange = () => {
    localStorage.setItem(
      'fullscreenpaymentinvoices',
      JSON.stringify(this.fullScreen)
    );
  };

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.isAdvancePayment = false;
      this.isSetDefaultCashValue = false;
      this.isSmashDonations = false;
      this.resetValue();
      this.paid = 0;
      this.left = 0;
      this.rest = 0;
      return;
    }

    if (this.PaymentFormName == 'gotówka') this.activeFormPayment('isCash');
    if (this.PaymentFormName == 'przelew') this.activeFormPayment('isSpillway');
    if (this.PaymentFormName == 'karta kredytowa')
      this.activeFormPayment('isCreditCard');
    if (this.PaymentFormName == 'bon') this.activeFormPayment('isVoucher');
  };

  resetValue = () => {
    this.paymentFormChosed = [];
    this.cashValue = 0;
    this.spillwayValue = 0;
    this.creditCardValue = 0;
    this.voucherValue = 0;
    this.isCash = false;
    this.isSpillway = false;
    this.isCreditCard = false;
    this.isVoucher = false;
  };

  closingWarnignCash = () => {
    if (this.previousValue.textBox == 'cashValue') {
      this.cashValue = this.previousValue.previousValue;
    }
    if (this.previousValue.textBox == 'spillwayValue') {
      this.spillwayValue = this.previousValue.previousValue;
    }
    if (this.previousValue.textBox == 'creditCardValue') {
      this.creditCardValue = this.previousValue.previousValue;
    }
    if (this.previousValue.textBox == 'voucherValue') {
      this.voucherValue = this.previousValue.previousValue;
    }

    this.isWrongAmountPayment = false;
    this.cd.detectChanges();
  };

  isWrongAmountPayment: boolean = false;
  previousValue;
  sumValue = (e?, cash?) => {
    this.paid =
      this.cashValue +
      this.spillwayValue +
      this.creditCardValue +
      this.voucherValue;

    if (this.paid < this.TotalGrossAmount)
      this.left = this.TotalGrossAmount - this.paid;
    else this.left = 0;

    if (this.paid > this.TotalGrossAmount) {
      if (!this.isSmashDonations || cash == 'cashValue') {
        this.rest = this.paid - this.TotalGrossAmount;
      } else if (
        this.spillwayValue > this.left ||
        this.creditCardValue > this.left ||
        this.voucherValue > this.left
      ) {
        this.isWrongAmountPayment = true;
        this.previousValue = {
          textBox: cash,
          previousValue: e.previousValue,
        };
      }
    } else this.rest = 0;
  };

  dontActive: boolean = false;
  changedChosedFormPayment = (f, e) => {
    this.dontActive = true;
    for (let i = this.paymentFormChosed.length - 1; i >= 0; i--) {
      if (this.paymentFormChosed[i] == f) {
        this.paymentFormChosed.splice(i, 1);
        if (f == 'isCash' && !this.isCash) this.cashValue = 0;
        if (f == 'isSpillway' && !this.isSpillway) this.spillwayValue = 0;
        if (f == 'isCreditCard' && !this.isCreditCard) this.creditCardValue = 0;
        if (f == 'isVoucher' && !this.isVoucher) this.voucherValue = 0;
      }
    }

    if (e.value) {
      this.paymentFormChosed.push(f);
    }

    if (!e.value) {
      this.sumValue();
    }

    if (f == 'isCash' && this.isCash) {
      this.cashValue = this.left == 0 ? 0 : this.left;
    }

    if (f == 'isSpillway' && this.isSpillway) {
      this.spillwayValue = this.left == 0 ? 0 : this.left;
    }

    if (f == 'isCreditCard' && this.isCreditCard) {
      this.creditCardValue = this.left == 0 ? 0 : this.left;
    }

    if (f == 'isVoucher' && this.isVoucher) {
      this.voucherValue = this.left == 0 ? 0 : this.left;
    }
  };

  activeFormPayment = (i) => {
    if (!this.isSmashDonations) {
      this.resetValue();

      if (i == 'isCash') {
        let index = this.paymentFormChosed.findIndex(
          (item) => item == 'isCash'
        );
        if (index == -1) this.paymentFormChosed = ['isCash'];

        if (this.paymentFormChosed.length > 1) this.isSmashDonations = true;
        this.isCash = true;
        this.cashValue = this.TotalGrossAmount;
        this.sumValue();
        return;
      }

      if (i == 'isSpillway') {
        let index = this.paymentFormChosed.findIndex(
          (item) => item == 'isSpillway'
        );
        if (index == -1) this.paymentFormChosed = ['isSpillway'];
        if (this.paymentFormChosed.length > 1) this.isSmashDonations = true;
        this.isSpillway = true;
        this.spillwayValue = this.TotalGrossAmount;
        return;
      }

      if (i == 'isCreditCard') {
        let index = this.paymentFormChosed.findIndex(
          (item) => item == 'isCreditCard'
        );
        if (index == -1) this.paymentFormChosed = ['isCreditCard'];
        if (this.paymentFormChosed.length > 1) this.isSmashDonations = true;
        this.isCreditCard = true;
        this.creditCardValue = this.TotalGrossAmount;
        return;
      }

      if (i == 'isVoucher') {
        let index = this.paymentFormChosed.findIndex(
          (item) => item == 'isVoucher'
        );
        if (index == -1) this.paymentFormChosed = ['isVoucher'];
        if (this.paymentFormChosed.length > 1) this.isSmashDonations = true;
        this.isVoucher = true;
        //this.voucherValue = this.TotalGrossAmount;
        return;
      }
    }
    this.dontActive = false;
  };

  downloadCurse = () => {
    this.event.onShown();
    this.idDownloadCurses = false;
    let DateOfInvoiceDocument = new DatePipe('en-US').transform(
      new Date(),
      'yyyy-MM-dd'
    );
    let object = {
      Bank: 'NBP',
      Date: DateOfInvoiceDocument,
      ForceReset: false,
    };
    this.getRate(object, 1);
  };

  getRate = (object, dayMinus) => {
    this.appService.postAuth(`currencies/importRates`, object).subscribe(
      () => {
        let lastDay = new DatePipe('en-US').transform(
          new Date().getTime() - dayMinus * 1000 * 60 * 60 * 24,
          'yyyy-MM-dd'
        );
        this.appService
          .getAuth(
            `currencies/rates?currencyCode=${
              this.advancePaymentList[this.editIndex].CurrencyCode
            }&date=${lastDay}&bank=NBP`
          )
          .subscribe((res) => {
            // let date = new DatePipe('en-US').transform(
            //   res.data[0].Date,
            //   'yyyy-MM-dd'
            // );

            this.advancePaymentList[this.editIndex].CurrencyFactor =
              res.data[0].Rates[0].Buy;
            this.event.onHidden();
          });
      },
      () => {
        dayMinus++;
        let lastDay = new Date().getTime() - dayMinus * 1000 * 60 * 60 * 24;
        let newObject = {
          Bank: 'NBP',
          Date: new DatePipe('en-US').transform(lastDay, 'yyyy-MM-dd'),
          ForceReset: false,
        };
        this.getRate(newObject, dayMinus);
      }
    );
  };

  valutToEmit: any[] = [];
  emitChosedForPayment = () => {
    let list: any[] = [];
    if (!this.isAdvancePayment) {
      this.paymentFormChosed.forEach((field) => {
        if (field == 'isCash')
          list.push({ formPaymentName: 'gotówka', value: this.cashValue });
        if (field == 'isSpillway')
          list.push({ formPaymentName: 'przelew', value: this.spillwayValue });
        if (field == 'isCreditCard')
          list.push({
            formPaymentName: 'karta kredytowa',
            value: this.creditCardValue,
          });
        if (field == 'isVoucher')
          list.push({ formPaymentName: 'bon', value: this.voucherValue });
      });
      this.valutToEmit = list;
    }
  };

  onFormPaymentChanged = () => {
    if (this.valutToEmit.length == 1)
      this.onPaymentFormNameChanged.emit(this.valutToEmit[0].formPaymentName);

    if (
      this.valutToEmit.length == 2 &&
      this.valutToEmit.findIndex(
        (field) => field.formPaymentName == 'gotówka'
      ) != -1
    ) {
      this.valutToEmit.forEach((field) => {
        if (field.formPaymentName != 'gotówka')
          this.onPaymentFormNameChanged.emit(field.formPaymentName);
      });
    }
    this.isVisible = false;
    this.isChangeFormPaymeny = false;
    this.cd.detectChanges();
    this.onInserted.emit(this.valutToEmit);

    if (sessionStorage.getItem('paymentForms')) {
      // let formPaymenyList = this.event.decryptString(
      //   sessionStorage.getItem('paymentForms')
      // );
      return;
    }

    this.appService.getAuth(`finances/paymentForms`).subscribe((res) => {
      sessionStorage.setItem(
        'paymentForms',
        this.event.encryptString(res.data)
      );
    });
  };

  closingDefrentForm = () => {
    this.isChangeFormPaymeny = false;
    this.cd.detectChanges();
    if (!this.checkCashValue()) return;

    this.isVisible = false;
    this.onClosing.emit(true);
    this.emitChosedForPayment();
    this.onInserted.emit(this.valutToEmit);
  };

  isChangeFormPaymeny: boolean = false;
  DetalFormaPlat;
  onSave = () => {
    if (this.isAdvancePayment) {
      if (!this.checkCashValue()) return;

      let arrayForkjoin: any = [];

      this.advancePaymentList.forEach((field) => {
        if (field.CurrencyCode != 'PLN')
          arrayForkjoin.push(
            this.appService.postAuth(
              `invoices/documents/${this.InvoiceDocumentId}/manyCurrencyPayments`,
              field
            )
          );
        if (field.CurrencyCode == 'PLN')
          arrayForkjoin.push(
            this.appService.postAuth(
              `invoices/documents/${this.InvoiceDocumentId}/manyPayments`,
              field
            )
          );
      });

      forkJoin(arrayForkjoin).subscribe(
        () => {
          this.isVisible = false;
          this.emitChosedForPayment();
          this.onInserted.emit(this.valutToEmit);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
      return;
    }
    if (this.paymentFormChosed.length == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant('form-commercial-operation.chooseFormOfPayment')
      );
      return;
    }

    if (!this.checkCashValue()) return;
    this.emitChosedForPayment();

    this.DetalFormaPlat =
      this.event.getConfigurationParameter('DetalFormaPlat')?.Value;

    if (
      this.paymentFormChosed.length == 2 &&
      this.paymentFormChosed.findIndex((field) => field == 'isCash') != -1
    ) {
      let payment;
      this.valutToEmit.forEach((field) => {
        if (field.formPaymentName != 'gotówka') payment = field.formPaymentName;
      });

      if (payment != this.PaymentFormName) {
        if (this.DetalFormaPlat == 'Zmieniaj') {
          this.onFormPaymentChanged();
        }

        if (this.DetalFormaPlat == 'Pytaj') {
          this.isChangeFormPaymeny = true;
          this.cd.detectChanges();
        }

        return;
      }
    }
    if (
      this.paymentFormChosed.length == 1 &&
      this.valutToEmit[0].formPaymentName != null &&
      this.PaymentFormName != null &&
      this.valutToEmit[0].formPaymentName.trim() != this.PaymentFormName.trim()
    ) {
      if (this.DetalFormaPlat == 'Zmieniaj') {
        this.onFormPaymentChanged();
      }

      if (this.DetalFormaPlat == 'Pytaj') {
        this.isChangeFormPaymeny = true;
        this.cd.detectChanges();
      }
      return;
    }

    this.onInserted.emit(this.valutToEmit);

    if (this.isChangeFormPaymeny) return;
    this.isVisible = false;
  };

  checkCashValue = () => {
    if (this.TotalGrossAmount > this.paid) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'form-commercial-operation.pleaseEnterAnAmountGreaterThanOrEqualToTheAmountToBePaid'
        )
      );
      return false;
    }

    return true;
  };

  editIndex = null;
  onChoosedCurrency = (e, index) => {
    if (e) {
      if (e.CurrencyCode != 'PLN') {
        this.editIndex = index;
        this.advancePaymentList[index].CurrencyCode = e.CountryCode;
        this.cd.detectChanges();
        this.appService
          .getAuth(`currencies/rates?currencyCode=${e.CurrencyCode}&bank=NBP`)
          .subscribe((res) => {
            try {
              let dataCurse = new DatePipe('en-US').transform(
                res.data[0].Rates[0].Date,
                'yyyy-MM-dd'
              );

              let dataToday = new DatePipe('en-US').transform(
                new Date(),
                'yyyy-MM-dd'
              );

              this.advancePaymentList[index].CurrencyFactor =
                res.data[0].Rates[0].Buy;

              if (dataCurse != dataToday) {
                this.idDownloadCurses = true;
              }
            } catch {
              this.idDownloadCurses = true;
            }
            this.advancePaymentList[index].CurrencyCode = e.CurrencyCode;
          });
      }
    } else {
      this.advancePaymentList[index].CurrencyCode = '';
      this.advancePaymentList[index].CurrencyFactor = 0;
    }
  };

  deleteAdvancePayment = (i) => {
    this.advancePaymentList.splice(i, 1);
    this.countPaymentAdvance();
  };

  timerWarehouse = 100;
  myTimerWarehouse;
  setPLNValue = () => {
    this.timerWarehouse = 100;
    clearInterval(this.myTimerWarehouse);
    this.myTimerWarehouse = setInterval(() => {
      this.timerWarehouse--;
      if (this.timerWarehouse == 0) {
        clearInterval(this.myTimerWarehouse);
        this.advancePaymentList[this.editIndex].Amount =
          this.advancePaymentList[this.editIndex].CurrencyAmount *
          this.advancePaymentList[this.editIndex].CurrencyFactor;
      }
    });
  };

  onAdvancePayment = () => {
    this.advancePaymentList = [];
    let cash = {
      InvoiceDocumentId: this.InvoiceDocumentId,
      Amount: this.TotalGrossAmount,
      PaymentFormName: 'gotówka',
      Rest: 0,
      PaymentId: 0,
      CurrencyAmount: 0,
      CurrencyCode: 'PLN',
      CurrencyFactor: 0,
      Expanded: false,
    };

    this.advancePaymentList.push(cash);

    this.countPaymentAdvance();
    this.paid = this.TotalGrossAmount;
    this.title = this.translate.instant(
      'form-commercial-operation.advancePayment'
    );
    this.isAdvancePayment = true;
  };

  onInsertedAdvanceFormPayment = (e) => {
    e.Expanded = false;
    this.addRow = false;
    this.advancePaymentList.push(e);
  };

  timerAmount = 100;
  myTimerAmount;
  setRest = (i, index) => {
    if (this.TotalGrossAmount < i.Amount)
      i.Rest = i.Amount - this.TotalGrossAmount;

    this.editIndex = index;
    this.timerAmount = 100;
    clearInterval(this.myTimerAmount);
    this.myTimerAmount = setInterval(() => {
      this.timerAmount--;
      if (this.timerAmount == 0) {
        clearInterval(this.myTimerAmount);
        if (i.CurrencyCode != 'PLN' && i.CurrencyFactor != 0)
          i.CurrencyAmount = i.Amount / i.CurrencyFactor;
      }
    });
  };

  countPaymentAdvance = () => {
    let paid = 0;
    this.advancePaymentList.forEach((field) => {
      paid += field.Amount;
    });

    this.paid = paid;

    if (this.paid < this.TotalGrossAmount)
      this.left = this.TotalGrossAmount - this.paid;
    else this.left = 0;

    if (this.paid > this.TotalGrossAmount)
      this.rest = this.paid - this.TotalGrossAmount;
    else this.rest = 0;
  };
}

export interface AdvancePayment {
  InvoiceDocumentId: number;
  Amount: number;
  PaymentFormName: string;
  Rest: number;
  PaymentId: number;
  CurrencyAmount: number;
  CurrencyCode: string;
  CurrencyFactor: number;
  Expanded: boolean;
}

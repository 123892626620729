import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  Input,
  signal,
  ViewChild,
  inject,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { EventService } from '../../../event.service';

import { ICustomSearchItem } from '../../../core/custom-dropdown-box/custom-dropdown-box.model';
import { CustomWindowConfig, SingleRecordMode } from '../../../event.model';
import { WarehouseDocument } from '../warehouse-documents.model';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../../environments/environment';
import { DocumentsOrdersService } from '../../documents-orders/documents-orders.service';
import { IDocumentOrder } from '../../documents-orders/IDocumentOrder';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';

interface FilterCriteriaAttachments {
  value: string | null;
  label: string;
}

@Component({
    selector: 'app-calculate-quantitatively',
    templateUrl: './calculate-quantitatively.component.html',
    styleUrl: './calculate-quantitatively.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CalculateQuantitativelyComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() isVisible: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('gridBank') gridBank: DxDataGridComponent;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  widthWindow: number | string = '75%';
  heightWindow: number = 600;
  unicalGuid: number;
  title;

  openCustomDropDown = signal<boolean>(false);
  filterCriteria = signal<ICustomSearchItem[]>([]);
  filterValue: string = '';

  localStorageData: CustomWindowConfig;
  @Input() headerDocument: WarehouseDocument | null;
  dataSource: DataSource;
  heightGrid: number | string = 350;

  articleIdFocused: IDocumentOrder[] = [];
  pageSize: number = 100;

  filterCriteriaAtachments = signal<FilterCriteriaAttachments[]>([]);
  customValue: string = '';
  warehouseId;
  showBottomPanel: boolean = false;
  bottomPanelTabs: string[] = ['Uwagi', 'Informacje dodatkowe'];
  selectedtabIndex: number = 0;
  isConfirmDialogVisible = signal<boolean>(false);
  showRow = signal<boolean>(false);
  mode: SingleRecordMode = 'show';

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  documentsOrdersService = inject(DocumentsOrdersService);
  shortcuts: ShortcutInput[] = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public event: EventService,
    private tr: TranslateService,
    private appServices: AppServices
  ) {
    this.title = signal(
      this.tr.instant('sadDocuments.selectionOrderFulfilled')
    );

    this.warehouseId = this.event.decryptString(
      localStorage.getItem('sessionInfoWaproHtml')
    ).AppWarehouseId;

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.perABD = this.event.checkPermissionsBtn(
      'DZam',
      'EZam',
      'UZam',
      'OZam'
    );

    this.initializeFilterCriteriaAtachments();
    this.initializeFilterCriteria();
    this.initializeLocalStorageData();
    this.initializeTranslation();
  }
  ngOnInit(): void {
    this.getDocuments();
  }

  ngAfterViewInit(): void {
    this.initializeShortcuts();
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp();
  }

  onKeyDown(e): void {
    if ([13, 27, 113, 114].includes(e.event.keyCode)) {
      e.event.preventDefault();
    }
  }

  private initializeShortcuts(): void {
    this.shortcuts = [
      {
        key: 'Enter',
        preventDefault: true,
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          if (this.articleIdFocused.length > 0) {
            this.onSave();
          }
        },
      },
      {
        key: 'Escape',
        preventDefault: true,
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: () => {
          this.isVisible = false;
          this.cdr.detectChanges();
        },
      },
      {
        key: 'F2',
        preventDefault: true,
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: (e: any) => {
          if (!e.event.shiftKey && this.articleIdFocused.length > 0) {
            this.onEditOrder();
          }
        },
      },
      {
        key: 'shift + F2',
        preventDefault: true,
        allowIn: [AllowIn.Textarea, AllowIn.Input],
        command: (e: any) => {
          if (
            e.event.shiftKey &&
            e.event.code === 'F2' &&
            this.perABD.showBtn &&
            this.articleIdFocused.length > 0
          ) {
            this.mode = 'show';
            this.showRow.set(true);
          }
        },
      },
    ];
  }

  private initializeTranslation(): void {
    this.tr
      .get('comments')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));

    this.tr
      .get('crmDeliveries.additionalInformation')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));
  }

  private initializeFilterCriteriaAtachments(): void {
    this.filterCriteriaAtachments.set([
      { value: null, label: this.tr.instant('all') },
      {
        value: 'IsPartiallyForRealization',
        label: this.tr.instant(
          'form-commercial-operation.partiallyReadyForImplementation'
        ),
      },
      {
        value: 'IsOnlyFullToRealization',
        label: this.tr.instant(
          'form-commercial-operation.completelyReadyForImplementation'
        ),
      },
    ]);
  }

  orderStatus = null;
  onAtachmentsValueChanged(e): void {
    this.customValue = '';
    this.orderStatus = e;
    setTimeout(() => {
      if (e == '') {
        this.orderStatus = null;
        this.onSprGotCaleZamRlz({ value: false });
        this.onSprGotZamRlz({ value: false });
      }

      if (e == 'IsPartiallyForRealization') {
        this.onSprGotCaleZamRlz({ value: false });
        this.onSprGotZamRlz({ value: true });
      }

      if (e == 'IsOnlyFullToRealization') {
        this.onSprGotCaleZamRlz({ value: true });
        this.onSprGotZamRlz({ value: false });
      }
    }, 0);
    this.getDocuments();
  }

  private onSprGotCaleZamRlz(e): void {
    let object = {
      code: 'SprGotCaleZamRlz',
      value: e.value ? '1' : '0',
    };
    this.event.saveHiddenParameter(object);
  }

  private onSprGotZamRlz(e): void {
    let object = {
      code: 'SprGotZamRlz',
      value: e.value ? '1' : '0',
    };
    this.event.saveHiddenParameter(object);
  }

  private getDocuments(): void {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'OrderDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}warehouseDocuments/documents/notRealizedOrdersDocuments`,
        loadParams: this.getLoadParams(),
        onLoaded: () => {
          this.focusedRowIndex = 0;
        },
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
      paginate: true,
    });
  }

  private getLoadParams(): Object {
    let obj: any = {};

    obj.WarehouseDocumentId = this.headerDocument?.WarehouseDocumentId;

    obj.CustomerId = this.headerDocument?.CustomerId;

    if (this.orderStatus === 'IsPartiallyForRealization') {
      obj.IsPartiallyForRealization = true;
    }
    if (this.orderStatus === 'IsOnlyFullToRealization') {
      obj.IsOnlyFullToRealization = true;
    }

    obj.WarehouseFilterId = this.warehouseId;
    if (this.localStorageData.filter.value !== '') {
      switch (this.localStorageData.filter.orderBy) {
        case 'OrderDocumentDateFrom':
          obj.OrderDocumentDateFrom = this.filterValue;
          break;
        case 'NumberIntuition':
          obj.NumberIntuition = this.filterValue;
          break;
        case 'RealizationPlanDateFrom':
          obj.RealizationPlanDateFrom = this.filterValue;
          break;
        case 'CustomerOrderNumberIntuition':
          obj.CustomerOrderNumberIntuition = this.filterValue;
          break;
      }
    }

    return obj;
  }

  setSearchCriteria(orderBy): void {
    if (orderBy !== this.localStorageData.filter.orderBy) {
      this.localStorageData.filter.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.cdr.detectChanges();
    this.getDocuments();
  }

  private switchOrder(): void {
    if (this.localStorageData.filter.order === 'ASC') {
      this.localStorageData.filter.order = 'DESC';
      return;
    }
    this.localStorageData.filter.order = 'ASC';
  }

  private initializeFilterCriteria(): void {
    this.filterCriteria.set([
      {
        value: 'OrderDocumentDateFrom',
        label: this.tr.instant('orderHistory.byOrderDate'),
        type: 'data',
      },
      {
        value: 'NumberIntuition',
        label: this.tr.instant('orderHistory.byNr'),
        type: 'string',
      },
      {
        value: 'RealizationPlanDateFrom',
        label: this.tr.instant('orderHistory.byRealisationDate'),
        type: 'data',
      },
      {
        value: 'CustomerOrderNumberIntuition',
        label: this.tr.instant('orderHistory.byCustomerOrderNr'),
        type: 'string',
      },
    ]);
  }

  private initializeLocalStorageData(): void {
    this.localStorageData = {
      filter: {
        value: '',
        orderBy: 'OrderDocumentDateFrom',
        order: 'ASC',
      },
      tree: [],
      columns: [],
      bottomPanel: {
        selectedIndex: 0,
        isVisible: true,
        height: 210,
      },
      mainPanelWidth: 'calc(100% - 385px)',
      sidePanel: {
        selectedIndex: 0,
        isVisible: true,
        width: 385,
      },
      windowSize: {
        isFullscreen: false,
        width: 0,
        height: 0,
      },
      params: [],
    };
  }

  focusedRowIndex: number = 0;
  timerSel: any;
  holdDelayInMs: number = 200;
  isHold: boolean = false;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  onCellClick(e) {
    if (this.event.deviceType != 'mobile') {
      if (!e.row) {
        return;
      }

      for (let cell of e?.row?.cells) {
        this.event.removeFocusClassFromCell(cell.cellElement);
      }
      e.cellElement.className += ' dx-focused';
    }
  }

  currentFocusedCell = null;
  onFocusedCellChanged(e) {
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    if (e.cellElement) {
      this.currentFocusedCell = e.cellElement[0];
    }
  }

  onFocusedRowChanged(e): void {
    this.articleIdFocused = [e.row.data];
  }

  onFilterDataChanged(e): void {
    this.localStorageData.filter.orderBy = e.selectedItem.value;
    this.filterValue = e.filterValue;
    this.localStorageData.filter.value = e.filterValue;
    this.getDocuments();
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };
  onGridContentReady(e): void {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType === 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }

    this.sortingIcons.asc = e.element.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = e.element.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  onEditorPreparing(e): void {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  visibleChange(e): void {
    if (!e) {
      this.onClosing.emit(false);
    }
    this.cdr.detectChanges();
  }

  closeWindow(): void {
    this.isVisible = false;
  }

  ngModelChange(): void {
    this.showBottomPanel = !this.showBottomPanel;

    if (this.showBottomPanel) {
      this.bottomSidePanel.nativeElement.style.minHeight = `80px`;
      this.heightGrid = this.heightWindow - 350;
    } else {
      this.bottomSidePanel.nativeElement.style.minHeight = `34px`;

      this.bottomSidePanel.nativeElement.style.height = `34px`;
      this.heightGrid = 350;
    }
  }

  onRowClick(e): void {
    this.articleIdFocused = [e.data];
  }

  onSave(): void {
    if (this.articleIdFocused.length == 0) {
      return;
    }

    this.isConfirmDialogVisible.set(true);
  }

  confirmRealizatino(): void {
    this.appServices
      .postAuth(
        `warehouseDocuments/additionalOperations/${this.headerDocument?.WarehouseDocumentId}/settelmentOrderDocument/${this.articleIdFocused[0].OrderDocumentId}`,
        null
      )
      .subscribe({
        next: () => {
          this.isConfirmDialogVisible.set(false);
          this.onInserted.emit(true);
          this.isVisible = false;
        },
        error: (error) => {
          this.isConfirmDialogVisible.set(false);
          this.event.httpErrorNotification(error);
        },
      });
  }

  onInsertedOrder(): void {
    this.showRow.set(false);
    if (this.mode == 'edit') {
      this.dataSource.reload();
    }
  }

  onEditOrder(): void {
    if (this.perABD.editBtn && !this.event.readOnly) {
      this.mode = 'edit';
      this.prepareForModify();
    }
  }

  private prepareForModify() {
    this.documentsOrdersService
      .prepareForModify(
        this.articleIdFocused[0].OrderDocumentId,
        this.warehouseId
      )
      .subscribe({
        next: () => {
          this.showRow.set(true);
        },
        error: () => {},
      });
  }
}

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  [minWidth]="minWidth"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="
    event.onShownPopUp();
    TotalToSettlement = TotalGrossAmount;
    getData();
    getAdvancePayment()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      #scrollView
      class="popup-form"
    >
      <div class="header-document">
        <header>
          <p class="p-header-info">
            {{
              "form-commercial-operation.associatingAdvancesWithCommercialDocumentNo"
                | translate
            }}
            <strong>{{ Number }}</strong>
          </p>
        </header>
      </div>

      <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
        <div
          class="form-group-inline-right flex-box-column"
          style="width: 520px"
        >
          <div class="form-box">
            <div class="box-title">
              <div class="c-frame-row" style="width: 100%">
                <h5>
                  {{ "form-commercial-operation.showDocuments" | translate }}
                </h5>
              </div>

              <dx-radio-group
                [items]="radioList"
                style="margin-left: -5px; position: relative; z-index: 2"
                [readOnly]="readOnly"
                displayExpr="label"
                valueExpr="value"
                layout="horizontal"
                [(value)]="CustomerMode"
                (onValueChanged)="getData()"
              >
              </dx-radio-group>
            </div>
          </div>
          <div class="form-box">
            <div class="box-title">
              <dx-tab-panel
                #tabPanel
                [items]="tabPanelItem"
                [loop]="false"
                [animationEnabled]="false"
                [swipeEnabled]="false"
                [(selectedIndex)]="selectedtab"
                (onSelectionChanged)="onSelectionChangedTab()"
              >
                <div *dxTemplate="let name of 'item'">
                  <div
                    class="d-flex justify-content-center flex-wrap flex-box-wrapper"
                  >
                    <div
                      class="form-group-inline-right flex-box-column"
                      style="width: 520px"
                    >
                      <div class="form-box">
                        <div class="box-title">
                          <dx-data-grid
                            [showBorders]="true"
                            [wordWrapEnabled]="false"
                            [hoverStateEnabled]="true"
                            [dataSource]="dataSource"
                            [(focusedRowIndex)]="focusedRowIndex"
                            #itemGrid
                            shortcut
                            [focusedRowEnabled]="true"
                            [allowColumnResizing]="true"
                            [columnResizingMode]="'widget'"
                            [(selectedRowKeys)]="selectedRowKeysLeft"
                            (onFocusedRowChanged)="onFocusedRowChanged($event)"
                            (onRowDblClick)="connect()"
                            style="
                              width: 100%;
                              min-height: 50px;
                              margin-bottom: 10px;
                              margin-top: 10px;
                              display: inline-block;
                            "
                            [height]="260"
                            (onOptionChanged)="event.onOptionChanged($event)"
                          >
                            <dxo-load-panel [enabled]="false"></dxo-load-panel>
                            <dxo-scrolling mode="virtual"></dxo-scrolling>
                            <dxo-paging [pageSize]="50"></dxo-paging>
                            <dxo-selection
                              mode="single"
                              showCheckBoxesMode="always"
                              selectAllMode="page"
                            >
                            </dxo-selection>
                            <dxo-editing
                              mode="row"
                              [confirmDelete]="false"
                            ></dxo-editing>

                            <dxi-column
                              [caption]="
                                'form-commercial-operation.paymentNumber'
                                  | translate
                              "
                              dataField="Number"
                              width="100"
                              [allowSorting]="false"
                            >
                            </dxi-column>

                            <dxi-column
                              [caption]="
                                'form-financial-document.date' | translate
                              "
                              dataField="Date"
                              dataType="date"
                              [format]="{ type: event.dateFormat }"
                              width="110"
                              alignment="left"
                              [allowSorting]="false"
                            >
                            </dxi-column>

                            <dxi-column
                              caption="{{
                                'form-financial-document.grid.amount'
                                  | translate
                              }}"
                              dataField="Amount"
                              [allowSorting]="false"
                              width="100"
                              [allowSorting]="false"
                              cellTemplate="TotalNetAmountCell"
                            >
                              <dxo-format type="fixedPoint" [precision]="2">
                              </dxo-format>
                            </dxi-column>
                            <div *dxTemplate="let data of 'TotalNetAmountCell'">
                              {{ data.data.Amount | amountFormatter : data }}
                            </div>

                            <dxi-column
                              [caption]="
                                'bankAccounts.form.currency' | translate
                              "
                              dataField="CurrencyCode"
                              width="80"
                              [allowSorting]="false"
                            >
                            </dxi-column>

                            <dxi-column
                              [caption]="
                                'form-financial-document.description'
                                  | translate
                              "
                              dataField="Description"
                              width="100"
                              [allowSorting]="false"
                            >
                            </dxi-column>
                          </dx-data-grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dx-tab-panel>
            </div>
          </div>
        </div>

        <div
          class="form-group-inline-right column-content"
          style="width: 40px; padding-top: 12%; display: inline-block"
          [style.height]="heightWindow - 180"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon icon--keyboard-arrow-right"
            [disabled]="readOnly || selectedRowKeysLeft.length == 0"
            (onClick)="connect()"
            [id]="'connect' + unicalGuid"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon icon--keyboard-arrow-left"
            [disabled]="readOnly || selectedRowKeysRight.length == 0"
            (onClick)="disconnect()"
            [id]="'disconnect' + unicalGuid"
          >
          </dx-button>

          <dx-tooltip
            [target]="'#connect' + unicalGuid"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.add" | translate }} (Insert)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#disconnect' + unicalGuid"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.delete" | translate }} (Del)
            </div>
          </dx-tooltip>
        </div>

        <div
          class="form-group-inline-right column-content"
          style="width: 520px"
        >
          <div class="form-box">
            <div class="box-title">
              <div class="c-frame-row" style="width: 100%">
                <h5>
                  {{
                    "form-commercial-operation.billingAssociatedWithTheDocument"
                      | translate
                  }}
                </h5>
              </div>

              <dx-data-grid
                [showBorders]="true"
                [wordWrapEnabled]="false"
                [hoverStateEnabled]="true"
                [dataSource]="dataSourceAdvane"
                [(focusedRowIndex)]="focusedRowIndexRight"
                #itemGrid
                shortcut
                [focusedRowEnabled]="true"
                [allowColumnResizing]="true"
                [columnResizingMode]="'widget'"
                [(selectedRowKeys)]="selectedRowKeysRight"
                (onFocusedRowChanged)="onFocusedRowChangedRight($event)"
                style="
                  width: 100%;
                  min-height: 50px;
                  margin-bottom: 10px;
                  margin-top: 10px;
                "
                [height]="330"
                (onRowDblClick)="disconnect()"
                (onOptionChanged)="event.onOptionChanged($event)"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling mode="infinite"></dxo-scrolling>
                <dxo-paging [pageSize]="50"></dxo-paging>
                <dxo-selection
                  mode="single"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                >
                </dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                <dxi-column
                  [caption]="
                    'form-commercial-operation.paymentNumber' | translate
                  "
                  dataField="Number"
                  width="100"
                  [allowSorting]="false"
                >
                </dxi-column>

                <dxi-column
                  [caption]="'form-financial-document.date' | translate"
                  dataField="Date"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [allowSorting]="false"
                >
                </dxi-column>

                <dxi-column
                  caption="{{
                    'form-financial-document.grid.amount' | translate
                  }}"
                  dataField="Amount"
                  [allowSorting]="false"
                  width="100"
                  cellTemplate="TotalNetAmountCell"
                  [allowSorting]="false"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalNetAmountCell'">
                  {{ data.data.Amount | amountFormatter : data }}
                </div>

                <dxi-column
                  [caption]="'bankAccounts.form.currency' | translate"
                  [allowSorting]="false"
                  dataField="CurrencyCode"
                  width="80"
                >
                </dxi-column>

                <dxi-column
                  [caption]="'form-financial-document.description' | translate"
                  [allowSorting]="false"
                  dataField="Description"
                  width="100"
                >
                </dxi-column>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>

      <div class="count-container">
        <div class="row">
          <div class="col-2 text-left"></div>
          <div class="col-10 text-right">
            <div class="contener-pay">
              <p class="p-toPay">
                {{ settledAmmount.toFixed(2) | currency : " " | pointReplacer }}
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.settled" | translate }}
              </p>
            </div>

            <div class="contener-pay" style="padding-right: 15px">
              <p class="p-toPay-net">
                {{
                  TotalToSettlement.toFixed(2) | currency : " " | pointReplacer
                }}
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.forSettlement" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-confirm-dialog
  *ngIf="isGenerateSet"
  kindDialog="question"
  confirmText="{{
    'form-financial-document.financialSettlementDocument' | translate
  }}"
  [isVisible]="true"
  (onRemoving)="yes()"
  (onClosing)="no()"
>
</app-confirm-dialog>

<div style="margin-top: 10px" #rendererContainer></div>

<!--<app-confirm-dialog-->
<!--  [width]="463"-->
<!--  [confirmHeader]="'articles.packageSizeErrorTitle'"-->
<!--  [paramsMsg]="paramsWeightError()"-->
<!--  [confirmText]="'articles.packageWeightError'"-->
<!--  [isVisible]="packageWeightErrorVisible"-->
<!--  [kindDialog]="'warning'"-->
<!--  btnConfig="close"-->
<!--  (onClosing)="packageWeightErrorVisible = false"-->
<!--&gt;-->
<!--</app-confirm-dialog>-->

<app-confirm-dialog
  btnConfig="yesno"
  [confirmText]="'articles.packageSizeError'"
  [isVisible]="packageSizeErrorVisible"
  [kindDialog]="'question'"
  (onClosing)="onClosingConfirmPlaceProduct()"
  (onRemoving)="onRemovingPlacedProduct()"
>
</app-confirm-dialog>

<app-confirm-dialog
  [confirmText]="'articles.packageSizeErrorMain'"
  [isVisible]="packageMainSizeErrorVisible"
  [kindDialog]="'warning'"
  btnConfig="close"
  (onClosing)="packageMainSizeErrorVisible = false"
>
</app-confirm-dialog>

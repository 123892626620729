import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { environment } from '../../../../environments/environment';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-additional-cost',
    templateUrl: './additional-cost.component.html',
    styleUrls: ['./additional-cost.component.scss'],
    inputs: [
        'isVisible',
        'title',
        'selectedId',
        'selected',
        'readOnly',
        'customerId',
        'invoiceDocumentId',
        'IsPositionCurrencyBase',
    ],
    standalone: false
})
export class AdditionalCostComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;
  @ViewChild('gridClass') gridClass;
  @ViewChild('IdentityMasksBox') IdentityMasksBox;

  isVisible;
  title;
  selectedId;
  selected;
  readOnly;
  unicalGuid;
  invoiceDocumentId;
  IsPositionCurrencyBase: boolean = false;
  widthWindow = 700;
  heightWindow = 820;
  maxHeight;
  maxWidth;

  impactOnIncomeItemsList = [
    { value: 0, label: 'bez wpływu' },
    { value: 1, label: 'wszystkie pozycje' },
    { value: 2, label: 'wybrane pozycje' },
  ];

  accountingForValueList = [
    { value: 0, label: 'netto' },
    { value: 1, label: 'brutto' },
  ];
  submitted: boolean = false;

  productList = [];
  shortcuts: ShortcutInput[] = [];
  customerId;

  itemsTab = ['Dane pozycji kosztowej w walucie'];

  itemTabChosed = [
    'Dane pozycji kosztowej w walucie',
    'Pozycje PZ, na które koszt ma wpływ',
  ];

  isVisibleChosedPosition: boolean = false;
  selectedPositionDocument = [];
  selectedPositionAdded = [];
  dataSource;

  choosingName: string = '';
  isGridBoxOpened: boolean = false;
  nameList: any[] = [];
  selectedRow: any[] = [];
  focusedRowIndex: number = 0;
  mode: string = 'add';
  focusedNameSelected: any[] = [];

  onValueChanged(e) {
    if (e.value == null) {
      this.selectedRow = [];
    }
  }

  onOpenedChanged(e) {
    if (e) {
      setTimeout(() => {
        this.gridClass.instance.focus();
      }, 500);
    }
  }

  onRowDblClick() {
    this.form.controls.Name.setValue(this.focusedNameSelected[0].Description);
    this.isGridBoxOpened = false;
  }

  onFocusedRowChanged(e) {
    this.focusedNameSelected = [e.row.data];
  }

  dataSourceName;
  getDate = () => {
    this.dataSourceName = new DataSource({
      store: AspNetData.createStore({
        key: 'DesciptionOfFinanceDocumentPositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/desciptionOfFinanceDocumentPosition`,
        onLoaded: () => {},
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}invoices/desciptionOfFinanceDocumentPosition`,
      }),
      reshapeOnPush: true,
    });
  };

  onInsertedName(e) {
    this.addRow = false;
    this.dataSourceName.reload().then((data) => {
      this.focusedRowIndex = this.event.returnFocusIndex(
        data,
        'DesciptionOfFinanceDocumentPositionId',
        e.Id
      );
      this.cd.detectChanges();
    });
  }

  addNewName(mode) {
    this.mode = mode;
    this.addRow = true;
    this.cd.detectChanges();
  }

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) this.addNewName('add');
        break;
      case 1:
        if (!this.readOnly) this.addNewName('edit');
        break;
      case 2:
        if (!this.readOnly) this.isDelete();
        break;
    }
  };

  deleteRow: boolean = false;
  isDelete() {
    this.deleteRow = true;
    this.cd.detectChanges();
  }

  delete() {
    this.deleteRow = false;
    this.appService
      .deleteAuth(
        `invoices/desciptionOfFinanceDocumentPosition/${this.focusedNameSelected[0].DesciptionOfFinanceDocumentPositionId}`
      )
      .subscribe(
        () => {
          this.dataSourceName.reload();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
  ];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));

    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('form-commercial-operation.withoutInfluence')
      .subscribe((text) => (this.impactOnIncomeItemsList[0].label = text));
    this.translate
      .get('offers.allPositions')
      .subscribe((text) => (this.impactOnIncomeItemsList[1].label = text));
    this.translate
      .get('form-commercial-operation.selectedItems')
      .subscribe((text) => (this.impactOnIncomeItemsList[2].label = text));

    this.translate
      .get('form-commercial-operation.net')
      .subscribe((text) => (this.accountingForValueList[0].label = text));
    this.translate
      .get('form-commercial-operation.gross')
      .subscribe((text) => (this.accountingForValueList[1].label = text));

    this.translate
      .get('form-commercial-operation.costItemDataInCurrency')
      .subscribe((text) => {
        this.itemsTab[0] = text;
        this.itemsTab[0] = text;
      });

    this.translate
      .get('form-commercial-operation.PZitemsAffectedByCost')
      .subscribe((text) => {
        this.itemTabChosed[1] = text;
      });

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  addRow: boolean = false;

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;

    this.createForm();
    this.getDate();
  }

  ngOnDestroy() {
    this.productList = [];
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  @ViewChild('taxRateDropBox') taxRateDropBox;
  ngOnChanges() {
    if (this.isVisible && this.selected) {
      setTimeout(() => {
        this.form.patchValue(this.selected[0]);
        try {
          this.selected[0].InvoiceDocumentPositionList.forEach((field) => {
            this.form
              .get('InvoiceDocumentPositionList')
              .push(this.formBuilder.control(field));
          });
        } catch {}
        let amountDay =
          (new Date(this.selected[0].DateOfPayment).getTime() -
            new Date(this.selected[0].DocumentDate).getTime()) /
          3600000;
        if (Math.round(amountDay / 24) < 0) {
          amountDay = 0;
          this.form.controls.DateOfPayment.setValue(
            new DatePipe('en-US').transform(new Date().getTime(), 'yyyy-MM-dd')
          );
        }
        this.form.controls.DateOfPaymentDay.setValue(
          Math.round(amountDay / 24)
        );
        try {
          this.form.controls.TaxRate.setValue(
            this.taxRateDropBox.dataSource.find(
              (field) =>
                field.TaxCode == this.selected[0].TaxCode.replace(' ', '')
            ).TaxRate
          );
          this.changeAmount();
        } catch {}
      }, 0);
    }
  }

  impactOnItemsModeChanged = (e) => {
    if (e.value == 0 || e.value == 2) this.deleteAllPosition();
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  closeWindow() {
    this.onClosing.emit(true);
  }

  form;
  createForm = () => {
    this.form = this.formBuilder.group({
      InvoiceDocumentId: [0],
      InvoiceDocumentCostPositionId: [0],
      Name: ['', Validators.required],
      Amount: [0, Validators.min(1)],
      PriceNet: [0],
      PriceGross: [0],
      TaxCode: ['23'],
      TaxRate: [23],
      KindCost: [0],
      IsWithoutAffecting: [true],
      CustomerId: [0],
      InvoiceDocumentNumber: [''],
      DateOfPayment: [null],
      DateOfPaymentDay: [0],
      PaymentForm: [''],
      DocumentDate: [null],
      DateOfReceived: [null],
      DateOfOperation: [null],
      SumByOfDocument: ['netto'],
      ImpactOnItemsMode: [1],
      InvoiceDocumentPositionList: this.formBuilder.array([]),
      TotalGrossAmount: [0],
      TotalNetAmount: [0],
      NetGrossMode: [0],
      IsPositionCurrencyBase: [false],
    });
  };

  netGrossModeChanged = (e) => {
    if (e.value == 0) this.form.controls.SumByOfDocument.setValue('netto');
    if (e.value == 1) this.form.controls.SumByOfDocument.setValue('brutto');
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;

    if (!e) {
      this.onClosing.emit(false);
      this.productList = [];
      this.selectedtab = 0;
    }
  };

  onChoosedPayer = (e) => {
    if (e) {
      this.form.controls.CustomerId.setValue(e.CustomerId);
      this.form.controls.PaymentForm.setValue(e.PaymentForm);
    } else {
      this.form.controls.CustomerId.setValue(0);
    }
    this.cd.detectChanges();
  };

  TaxCodeChanged = (e) => {
    if (e) {
      this.form.controls.TaxCode.setValue(e[0].TaxCode);
      this.form.controls.TaxRate.setValue(e[0].TaxRate);
    } else {
      this.form.controls.TaxCode.setValue('');
    }

    this.onValueNetChange();
    this.cd.detectChanges();
  };

  setCustomerId = () => {
    if (this.customerId != 0) {
      // this.form.controls.CustomerId.setValue(this.customerId);
      this.form.controls.InvoiceDocumentId.setValue(this.invoiceDocumentId);
      // this.appService
      //   .getAuth(`customers/${this.form.value.CustomerId}`)
      //   .subscribe((res) => {
      //     if (this.form.value.InvoiceDocumentCostPositionId == 0) {
      //       this.form.controls.DateOfPayment.setValue(new Date());
      //       this.form.controls.DateOfReceived.setValue(new Date());
      //       this.form.controls.DateOfOperation.setValue(new Date());
      //       this.form.controls.DocumentDate.setValue(new Date());
      //       this.form.controls.PaymentForm.setValue(res.PaymentForm);
      //     }
      //   });
    }
  };

  DateOfPaymentNumberChanged = (e) => {
    this.form.controls.DateOfPayment.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('DocumentDate').value).getTime() +
          1000 * 60 * 60 * 24 * e.value,
        'yyyy-MM-dd'
      )
    );
  };

  DateOfPaymentChanged = (e) => {
    if (this.form.value.DocumentDate == null) {
      this.form.controls.DocumentDate.setValue(new Date());
    }

    let amountDay =
      new Date(this.event.dataFormatted(e)).getTime() -
      new Date(
        this.event.dataFormatted({ value: this.form.value.DocumentDate })
      ).getTime();

    if (Math.ceil(amountDay / (1000 * 3600 * 24)) < 0) {
      amountDay = 0;
      this.form.controls.DateOfPayment.setValue(
        new DatePipe('en-US').transform(
          new Date(this.form.value.DocumentDate).getTime(),
          'yyyy-MM-dd'
        ),
        { emitEvent: false }
      );
    }
    this.cd.detectChanges();
    this.form.controls.DateOfPaymentDay.setValue(
      Math.round(amountDay / (1000 * 3600 * 24))
    );
  };

  changeDateDocument = () => {
    this.form.controls.DateOfPayment.setValue(
      new DatePipe('en-US').transform(
        new Date(this.form.get('DocumentDate').value).getTime() +
          1000 * 60 * 60 * 24 * this.form.value.DateOfPaymentDay,
        'yyyy-MM-dd'
      )
    );
  };

  onChoosedFormPeyment = (e) => {
    if (e) {
      this.form.controls.PaymentForm.setValue(e.PaymentFormName);
    }
  };

  myTimerNetto;
  onValueNetChange = () => {
    if (this.myTimerNetto) {
      clearTimeout(this.myTimerNetto);
    }
    this.myTimerNetto = setTimeout(() => {
      this.form.controls.PriceGross.setValue(
        Number(
          this.event.countAmountGross(
            this.form.value.PriceNet,
            Number(this.form.value.TaxRate)
          )
        )
      );
      this.changeAmount();
    }, 100);
  };

  changeAmount = () => {
    this.form.controls.TotalGrossAmount.setValue(
      this.form.value.Amount * this.form.value.PriceGross
    );
    this.form.controls.TotalNetAmount.setValue(
      this.form.value.Amount * this.form.value.PriceNet
    );
  };

  myTimer;
  isFirstLoad: boolean = true;
  onValueGrossChange = () => {
    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }
    this.myTimer = setTimeout(() => {
      this.isFirstLoad = false;
      this.form.controls.PriceNet.setValue(
        this.event.countAmountNet(
          this.form.value.PriceGross,
          Number(this.form.value.TaxRate)
        )
      );
    }, 100);

    this.changeAmount();
  };

  onCellDblClick = () => {};

  getPositionDocument = () => {
    this.dataSource = this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentPositionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${this.invoiceDocumentId}/positions`,
        loadParams: { ParentObjectId: this.invoiceDocumentId },
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  onChosedPosition = () => {
    this.form
      .get('InvoiceDocumentPositionList')
      .push(this.formBuilder.control(this.selectedPositionDocument[0]));
    this.isVisibleChosedPosition = false;
    this.refreshDate();
  };

  selectedtab = 0;
  tabSelectionChanged = () => {
    if (this.selectedtab == 1) this.refreshDate();
  };

  refreshDate() {
    this.productList = [];
    this.form.value.InvoiceDocumentPositionList.forEach((field) => {
      this.appService
        .getAuth(
          `invoices/documents/${this.invoiceDocumentId}/positions?ParentObjectId=${this.invoiceDocumentId}&ObjectId=${field}`
        )
        .subscribe((res) => {
          if (res.data.length > 0) this.productList.push(res.data[0]);
          else this.productList = [];
          this.cd.detectChanges();
        });
    });
  }

  deleteAddedPosition = () => {
    this.form
      .get('InvoiceDocumentPositionList')
      .removeAt(this.selectedPositionAdded[0]);
    this.refreshDate();
  };

  deleteAllPosition = () => {
    this.form
      .get('InvoiceDocumentPositionList')
      .removeAt(0, this.form.value.InvoiceDocumentPositionList.length - 1);
    this.refreshDate();
  };

  addAll = () => {
    this.selectedtab = 0;
    this.form.controls.ImpactOnItemsMode.setValue(1);
    this.deleteAllPosition();
  };

  onSave = () => {
    this.submitted = true;
    this.form.controls.IsPositionCurrencyBase.setValue(
      this.IsPositionCurrencyBase
    );
    if (this.form.valid) {
      if (this.form.value.InvoiceDocumentCostPositionId == 0) {
        this.appService
          .postAuth(
            `invoices/documents/${this.form.value.InvoiceDocumentId}/costPositions`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit({ data: this.form.value, res: res });
              this.isVisible = false;
            },
            (error) => {
              this.event.loadingVisible = false;
              this.event.httpErrorNotification(error);
            }
          );

        return;
      }

      if (this.form.value.InvoiceDocumentCostPositionId != 0) {
        this.appService
          .putAuth(
            `invoices/documents/${this.form.value.InvoiceDocumentId}/costPositions`,
            this.form.value
          )
          .subscribe(
            (res) => {
              this.onInserted.emit({ data: this.form.value, res: res });
              this.isVisible = false;
            },
            (error) => {
              this.event.loadingVisible = false;
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };
}

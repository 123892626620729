<ng-template #headerButton>
  <dx-button
    class="btn-dx-top-menu"
    icon="icon absui-icon--operationsBtn"
    [ngClass]="{ moreOptionBtnOpened: isOpenMoreBtn }"
    [id]="'moreOption' + unicalGuid"
    (onClick)="isOpenMoreBtn = !isOpenMoreBtn"
  >
  </dx-button>
  <div
    class="moreOptionBtn"
    style="width: 90%; right: 0px"
    *ngIf="isOpenMoreBtn"
    [ngClass]="{ moreOptionBtnReadOnly: readOnly }"
  >
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>
</ng-template>

<ng-template #topButtons>
  <dx-switch
    [readOnly]="readOnly"
    style="margin-bottom: 6px; width: 100%"
    [id]="'SprNFZamKon' + unicalGuid"
    [(ngModel)]="isSprNFZamKon"
    (onValueChanged)="onChangedSprNFZZamKon($event)"
  >
  </dx-switch>
  <p
    style="
      left: 35px;
      width: 90%;
      position: relative;
      top: -28px;
      margin-bottom: -15px;
    "
  >
    {{
      "form-commercial-operation.checkIfThereAreUnrelatedOrdersDocumentsWhenSelectingAcontractor"
        | translate
    }}
  </p>

  <dx-button
    class="outline-secondary"
    stylingMode="outlined"
    text="{{ 'buttons.edit' | translate }}"
    style="margin-bottom: 6px; width: 100%"
    (onClick)="onEditOrder()"
    [id]="'edit' + unicalGuid"
    [disabled]="focusedSelectedNoConnected.length == 0 || !perABD.editBtn"
  >
  </dx-button>

  <dx-button
    class="outline-secondary"
    stylingMode="outlined"
    text="{{ 'buttons.preview' | translate }}"
    style="margin-bottom: 6px; width: 100%"
    (onClick)="mode = 'show'; showRow = true"
    [id]="'preview' + unicalGuid"
    [disabled]="focusedSelectedNoConnected.length == 0 || !perABD.showBtn"
  >
  </dx-button>
</ng-template>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  *ngIf="openNestFinished"
  (onShown)="
    event.onShownPopUp();
    event.setFocus(gridOrder);
    checkSprGotZamRlz();
    checkSprGotCaleZamRlz();
    getOrders()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <div
        style="display: inline-block"
        *ngIf="event.deviceType != 'mobile'; else headerButton"
      >
        <dx-check-box
          [(ngModel)]="isSprNFZamKon"
          (onValueChanged)="onChangedSprNFZZamKon($event)"
          [readOnly]="readOnly"
          style="margin-right: 15px; top: 5px; position: relative"
          [id]="'SprNFZamKon' + unicalGuid"
          text="{{
            'form-commercial-operation.checkIfThereAreUnrelatedOrdersDocumentsWhenSelectingAcontractor'
              | translate
          }}"
        ></dx-check-box>
        <!-- <dx-switch
          [readOnly]="readOnly"
          style="margin-right: 15px"
          [id]="'SprNFZamKon' + unicalGuid"
          [(ngModel)]="isSprNFZamKon"
          (onValueChanged)="onChangedSprNFZZamKon($event)"
        >
        </dx-switch> -->

        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          text="{{ 'buttons.edit' | translate }}"
          style="margin-right: 6px"
          (onClick)="mode = 'edit'; onEditOrder()"
          [id]="'edit' + unicalGuid"
          [disabled]="focusedSelectedNoConnected.length == 0 || !perABD.editBtn"
        >
        </dx-button>

        <dx-button
          class="outline-secondary"
          stylingMode="outlined"
          text="{{ 'buttons.preview' | translate }}"
          style="margin-right: 6px"
          (onClick)="mode = 'show'; showRow = true; cd.detectChanges()"
          [id]="'preview' + unicalGuid"
          [disabled]="focusedSelectedNoConnected.length == 0 || !perABD.showBtn"
        >
        </dx-button>
      </div>

      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="addStartRealization()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        [disabled]="focusedSelectedNoConnected.length == 0"
        data-cy="choosed-order-document-to-realized-btn"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="beforeClosing()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#SprNFZamKon' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{
            "form-commercial-operation.checkIfThereAreUnrelatedOrdersDocumentsWhenSelectingAcontractor"
              | translate
          }}
          (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="102%"
      direction="vertical"
      showScrollbar="never"
      #scrollView
    >
      <div *ngIf="!productionDocumentsEditMode" class="header-document">
        <header>
          <p class="p-header-info">
            {{ "constractors.recipient" | translate }}
            <strong>{{ customerName }}</strong>
          </p>
        </header>
      </div>

      <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
        <div
          class="form-group-inline-right flex-box-column"
          style="width: 100%"
        >
          <div class="form-box">
            <div class="box-title">
              <div class="d-flex flex-row">
                <div>
                  <app-custom-dropdown-box
                    [items]="filterCriteria"
                    [(selectedItem)]="valueCriteria"
                    [(filterValue)]="filterValue"
                    (onValueChanged)="onFilterDataChanged($event)"
                    style="margin-right: 5px"
                  >
                  </app-custom-dropdown-box>
                </div>
                <div style="padding-top: 2px">
                  <app-warehouse-list
                    *ngIf="
                      document == 'invoices' &&
                      event.footerInfo.VariantName !== 'START'
                    "
                    [dropDownBoxMode]="true"
                    (onChoosed)="onChoosedWarehouse($event)"
                    [selectedId]="warehouseId"
                    width="276"
                    style="margin-right: 6px"
                  >
                  </app-warehouse-list>
                </div>
                <div>
                  <app-custom-chips-button
                    name="{{ 'menu.orders' | translate }}"
                    [list]="filterCriteriaAtachments"
                    (onValueChanged)="onAtachmentsValueChanged($event)"
                    [customDisplayValue]="customValue"
                    [ngClass]="{ topMargin: customValue != '' }"
                    style="top: 0px"
                  >
                  </app-custom-chips-button>
                </div>
              </div>

              <dx-data-grid
                [showBorders]="true"
                [wordWrapEnabled]="false"
                [hoverStateEnabled]="true"
                [dataSource]="dataSource"
                [focusedRowEnabled]="true"
                [allowColumnResizing]="true"
                shortcut
                [columnResizingMode]="'widget'"
                [height]="heightGrid - 5"
                #gridOrder
                [(focusedRowIndex)]="focusedRowIndex"
                (onFocusedRowChanged)="onFocusedRowChangedNoConected($event)"
                style="margin-top: 10px"
                (onRowDblClick)="onRowDblClick()"
                (onOptionChanged)="event.onOptionChanged($event)"
                data-cy="list-order-document-to-realized"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="infinite"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                >
                </dxo-scrolling>
                <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
                <dxo-selection
                  mode="single"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                >
                </dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  width="150"
                  [allowSorting]="false"
                  dataField="OrderDocumentNumber"
                  [cssClass]="
                    orderby == 'NumberIntuition' ? 'sort-column-bg' : null
                  "
                >
                </dxi-column>

                <dxi-column
                  caption="{{ 'form-financial-document.date' | translate }}"
                  [allowSorting]="false"
                  dataField="Date"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [cssClass]="
                    orderby == 'OrderDocumentDateFrom' ? 'sort-column-bg' : null
                  "
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'plannedOrders.clientOrderNr' | translate }}"
                  width="150"
                  [allowSorting]="false"
                  dataField="CustomerOrderNumber"
                  [cssClass]="
                    orderby == 'CustomerOrderNumberIntuition'
                      ? 'sort-column-bg'
                      : null
                  "
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'plannedOrders.realisationDate' | translate }}"
                  [allowSorting]="false"
                  dataField="RealizationDate"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                  alignment="left"
                  [cssClass]="
                    orderby == 'RealizationPlanDateFrom'
                      ? 'sort-column-bg'
                      : null
                  "
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'plannedOrders.priority' | translate }}"
                  width="150"
                  [allowSorting]="false"
                  dataField="PriorityName"
                ></dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.netValue' | translate
                  }}"
                  dataField="TotalNetAmount"
                  [allowSorting]="false"
                  width="100"
                  cellTemplate="TotalNetAmountCell"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalNetAmountCell'">
                  {{ data.data.TotalNetAmount | amountFormatter : data }}
                </div>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.grossValue' | translate
                  }}"
                  dataField="TotalGrossAmount"
                  [allowSorting]="false"
                  width="100"
                  cellTemplate="TotalGrossAmountCell"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalGrossAmountCell'">
                  {{ data.data.TotalGrossAmount | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.advance' | translate }}"
                  dataField="AdvancePayment"
                  [allowSorting]="false"
                  width="100"
                  cellTemplate="AdvancePayment"
                >
                  <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'AdvancePayment'">
                  {{ data.data.AdvancePayment | amountFormatter : data }}
                </div>

                <dxi-column
                  caption="{{ 'comments' | translate }}"
                  [allowSorting]="false"
                  dataField="Remarks"
                  alignment="left"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'bankAccounts.form.currency' | translate }}"
                  dataField="CurrencyCode"
                  [allowSorting]="false"
                  width="100"
                >
                </dxi-column>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.TotalNetAmountCurrency'
                      | translate
                  }}"
                  dataField="TotalNetAmountCurrency"
                  [allowSorting]="false"
                  width="150"
                  cellTemplate="TotalNetAmountCurrency"
                >
                  <dxo-format type="fixedPoint" [precision]="4"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalNetAmountCurrency'">
                  {{
                    data.data.TotalNetAmountCurrency | amountFormatter : data
                  }}
                </div>

                <dxi-column
                  caption="{{
                    'form-commercial-operation.TotalGrossAmountCurrency'
                      | translate
                  }}"
                  dataField="TotalGrossAmountCurrency"
                  [allowSorting]="false"
                  width="150"
                  cellTemplate="TotalGrossAmountCurrency"
                >
                  <dxo-format type="fixedPoint" [precision]="4"> </dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'TotalGrossAmountCurrency'">
                  {{
                    data.data.TotalGrossAmountCurrency | amountFormatter : data
                  }}
                </div>
              </dx-data-grid>

              <!-- bottom panel -->
              <div class="row">
                <div
                  class="bottom-side-panel"
                  #bottomSidePanel
                  style="margin-top: 40px"
                >
                  <div
                    class="resize-handle-top"
                    mwlResizeHandle
                    [resizeEdges]="{ top: true }"
                    (mouseleave)="mouseLeaveDown()"
                    (mouseenter)="mouseEnterDown()"
                  ></div>
                  <div class="col-md-12 text-right resize-bottop-panel">
                    <i
                      class="icon bottom-panel-icon"
                      mwlResizeHandle
                      [resizeEdges]="{ top: true }"
                      [ngClass]="{
                        'absui-icon--arrow-drop-down bottom-icon-resize-down':
                          showBottomPanel,
                        'absui-icon--arrow-drop-up bottom-icon-resize-up':
                          !showBottomPanel
                      }"
                      (click)="ngModelChange()"
                    ></i>

                    <div class="row">
                      <div class="col-md-12 text-left">
                        <dx-tab-panel
                          #tabPanel
                          width="100%"
                          [items]="bottomPanelTabs"
                          [(selectedIndex)]="selectedtabIndex"
                          [animationEnabled]="false"
                          [swipeEnabled]="false"
                        >
                          <div *dxTemplate="let name of 'item'">
                            <ng-container
                              [ngSwitch]="name"
                              *ngIf="showBottomPanel"
                            >
                              <div *ngSwitchCase="'comments' | translate">
                                <p
                                  *ngIf="focusedSelectedNoConnected[0]"
                                  style="margin-bottom: 0px"
                                >
                                  {{ focusedSelectedNoConnected[0].Remarks }}
                                </p>
                              </div>

                              <div
                                *ngSwitchCase="
                                  'crmDeliveries.additionalInformation'
                                    | translate
                                "
                              >
                                <p
                                  *ngIf="focusedSelectedNoConnected[0]"
                                  style="margin-bottom: 0px"
                                >
                                  {{
                                    focusedSelectedNoConnected[0]
                                      .AdditionalInformations
                                  }}
                                </p>
                              </div>
                            </ng-container>
                          </div>
                        </dx-tab-panel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-start-realization
  [isVisible]="isStartRealization"
  (onClosing)="isStartRealization = false; cd.detectChanges()"
  [readOnly]="readOnly"
  title="{{ 'form-commercial-operation.fulfillmentOfOrderItems' | translate }}"
  [documentDate]="focusedSelectedNoConnected"
  (onInserted)="refreshPosition($event)"
  [document]="document"
  [invoicesDocumentId]="invoiceDocumentId"
  [DateOfInvoiceDocument]="DateOfInvoiceDocument"
  [SumBy]="SumBy"
  [documentType]="DocumentType"
>
</app-start-realization>

<app-new-documents-orders
  [isVisible]="showRow"
  [extWarehouseId]="warehouseId"
  [selectedId]="OrderDocumentId"
  (onClosed)="showRow = false; cd.detectChanges()"
  [readOnly]="mode == 'show' ? true : false"
  [mode]="mode"
  (onInserted)="onInsertedOrder()"
></app-new-documents-orders>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="
    !isVisible || showRow || isStartRealization || isExpansionListVisible
  "
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGZWOA'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-payment.newPayment' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); setAmount()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up title-pop-upPeyment">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <form [formGroup]="form">
        <div
          class="form-group-inline-right flex-box-column"
          style="width: 100%"
        >
          <div class="form-box">
            <div class="box-title">
              <label style="width: 130px">{{
                "form-payment.typeOfPayment" | translate
              }}</label>
              <app-forms-payment
              [onlyActive]="true"
                [dropDownBoxMode]="true"
                [readOnly]="readOnly"
                (onChoosed)="onChoosedFormPeyment($event)"
                width="200"
                [selectedId]="form.value.PaymentFormName"
                paymentForm="true"
                [className]="
                  !form.controls.PaymentFormName.valid && submitted
                    ? true
                    : false
                "
              >
              </app-forms-payment>
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 130px">{{
                "form-payment.depositAmount" | translate
              }}</label>
              <dx-number-box
                width="200"
                style="height: 38px"
                [format]="event.formatPLN"
                valueChangeEvent="input"
                class="payment-box-number text-left-payment"
                formControlName="Amount"
                (onValueChanged)="setRest()"
                [ngClass]="{
                  'input-required': !form.controls.Amount.valid && submitted
                }"
              >
              </dx-number-box>
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 130px">{{
                "bankAccounts.form.currency" | translate
              }}</label>
              <app-currency-box
                [dropDownBoxMode]="true"
                [readOnly]="readOnly"
                #currencyList
                [selectedId]="form.value.CurrencyCode"
                (onChoosed)="onChoosedCurrency($event)"
                [width]="200"
                [paymentForm]="true"
              >
              </app-currency-box>
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 130px">{{
                "baseCurrencies.currencyValue" | translate
              }}</label>
              <dx-number-box
                width="200"
                style="height: 38px"
                [format]="event.formatCurrency"
                valueChangeEvent="input"
                class="payment-box-number text-left-payment"
                formControlName="CurrencyFactor"
                (onValueChanged)="setPLNValue()"
              >
              </dx-number-box>
            </div>
          </div>

          <div class="form-box">
            <div class="box-title">
              <label style="width: 130px">{{
                "form-payment.paymentInCurrency" | translate
              }}</label>
              <dx-number-box
                width="200"
                style="height: 38px"
                [format]="event.formatCurrency"
                valueChangeEvent="input"
                class="payment-box-number text-left-payment"
                formControlName="CurrencyAmount"
                (onValueChanged)="setPLNValue()"
              >
              </dx-number-box>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-confirm-dialog
  kindDialog="question"
  confirmText="form-financial-document.rateDateDocumentDifferent"
  [isVisible]="idDownloadCurses"
  (onRemoving)="downloadCurse()"
  (onClosing)="idDownloadCurses = false"
>
</app-confirm-dialog>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../app-services.service';
import { EventService } from '../../../../event.service';
@Component({
    selector: 'app-edit-vat',
    templateUrl: './edit-vat.component.html',
    styleUrls: ['./edit-vat.component.scss'],
    inputs: ['readOnly', 'isVisible', 'title', 'selected', 'IsCurrencyDocument'],
    standalone: false
})
export class EditVatComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  readOnly;
  isVisible;
  title;
  selected;
  IsCurrencyDocument;
  unicalGuid;
  widthWindow = 460;
  heightWindow = 185;

  maxHeight;
  maxWidth;
  form;

  shortcuts: ShortcutInput[] = [];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.createForm();
  }

  ngOnChanges(): void {
    if (this.isVisible) {
      this.form.patchValue(this.selected[0]);
    }
  }

  visibleChange = (e) => {
    if (e && this.IsCurrencyDocument) this.heightWindow = 276;
    else this.heightWindow = 185;
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  closeWindow() {
    this.onClosing.emit(true);
  }

  createForm() {
    this.form = this.formBuilder.group({
      InvoiceDoucmentVerificationAmountsId: [0],
      TaxCode: [''],
      TotalNetAmount: [0],
      TotalTaxAmount: [0],
      TotalNetAmountCurrency: [0],
      TotalTaxAmountCurrency: [0],
    });
  }

  oryginalData;
  prevousData() {
    this.oryginalData = this.form.value;
  }

  yesValue() {
    this.isConfirmValue = false;
    this.onInserted.emit(this.form.value);
    this.isVisible = false;
    this.cd.detectChanges();
  }

  noValue() {
    this.isConfirmValue = false;
  }

  isConfirmValue: boolean = false;
  onSave() {
    if (
      this.form.value.TotalNetAmount > 0 &&
      this.oryginalData.TotalNetAmount < 0 &&
      !this.IsCurrencyDocument
    ) {
      this.isConfirmValue = true;
      this.cd.detectChanges();
      return;
    }

    if (
      this.form.value.TotalNetAmount < 0 &&
      this.oryginalData.TotalNetAmount > 0 &&
      !this.IsCurrencyDocument
    ) {
      this.isConfirmValue = true;
      this.cd.detectChanges();
      return;
    }

    if (
      this.form.value.TotalNetAmountCurrency > 0 &&
      this.oryginalData.TotalNetAmountCurrency < 0 &&
      this.IsCurrencyDocument
    ) {
      this.isConfirmValue = true;
      this.cd.detectChanges();
      return;
    }

    if (
      this.form.value.TotalNetAmountCurrency < 0 &&
      this.oryginalData.TotalNetAmountCurrency > 0 &&
      this.IsCurrencyDocument
    ) {
      this.isConfirmValue = true;
      this.cd.detectChanges();
      return;
    }

    this.yesValue();
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  ChangeDetectorRef,
  Output,
  SimpleChanges,
  signal,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { environment } from 'src/environments/environment';
import { IOrderSettlement } from './IOrderPayment';

@Component({
    selector: 'app-order-payments',
    templateUrl: './order-payments.component.html',
    styleUrls: ['./order-payments.component.scss'],
    inputs: [
        'customerId',
        'customerName',
        'orderNumber',
        'orderId',
        'isVisible',
        'details',
        'readOnly',
        'currencyCode',
    ],
    standalone: false
})
export class OrderPaymentsComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onPaymentAmountChanged = new EventEmitter();
  documentItems = [
    { value: 1, label: 'płatnika' },
    { value: 2, label: 'kontrahenta' },
  ];
  customerMode: number = 2;

  @Input() orderGrossValue: number = 0;
  connectedPaymentsValue: number = 0;
  currencyCode: string = null;
  readOnly: boolean = false;
  orderNumber: string;
  customerId: number;
  customerName: string;
  orderId: number;
  isVisible = false;

  focusedRowIndex3: number = null;
  focusedRowIndex2: number = null;
  focusedRowIndex: number = null;
  focusedRow: IOrderSettlement = null;
  focusedRow2: IOrderSettlement = null;
  focusedRow3: IOrderSettlement = null;
  selectedTabIndex: number = 0;
  isGridBoxOpened: boolean = false;

  widthWindow = 1200;
  shortcuts: ShortcutInput[] = [];

  dataSource: DataSource;
  paymentsDataSource: DataSource;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  isDialogVisible = signal(false);
  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public appService: AppServices
  ) {}

  ngOnInit(): void {
    if (window.innerWidth < 570) this.widthWindow = 360;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue && this.orderId) {
      this.getData();
      this.getConnectedPayments();
    }
  }

  onTabIndexChanged() {
    this.focusedRow = null;
    this.focusedRow2 = null;
    this.focusedRowIndex = null;
    this.focusedRowIndex2 = null;
    this.getData();
  }

  getConnectedPayments() {
    this.paymentsDataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'SettlementId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}orders/documents/${this.orderId}/advencePayment`,
        loadParams: this.getConnectedPaymentsLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (result) => {
          this.connectedPaymentsValue = result.reduce(
            (acc, curr) => acc + curr.Amount,
            0
          );
        },
      }),
      reshapeOnPush: true,
    });
  }

  getConnectedPaymentsLoadParams() {
    return {
      DocumentId: this.orderId,
    };
  }

  getLoadParams() {
    return {
      DocumentId: this.orderId,
      CustomerMode: this.customerMode,
      MagFakirMode: this.selectedTabIndex === 0 ? 1 : 2,
      CustomerId: this.customerId,
      CurrencyCode: this.currencyCode || 'PLN',
    };
  }

  getData() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'SettlementId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}orders/documents/${this.orderId}/customerAdvencePayment`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  }

  onCustomerModeChanged() {
    this.focusedRow = null;
    this.focusedRow2 = null;
    this.focusedRowIndex = null;
    this.focusedRowIndex2 = null;
    this.getData();
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.isVisible = false;
        },
      },
      {
        key: 'ins',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          if (!this.readOnly) {
            this.addPayment();
          }
        },
      },
      {
        key: 'del',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          if (!this.readOnly) {
            this.deletePayment();
          }
        },
      }
    );
  }

  visibleChange = () => {
    if (!this.isVisible) {
      this.onPaymentAmountChanged.emit(this.connectedPaymentsValue);
      this.onClosing.emit(true);
    }
  };

  closeWindow() {
    this.onPaymentAmountChanged.emit(this.connectedPaymentsValue);
    this.onClosing.emit(true);
  }

  onFocusedRowChanged(e) {
    this.focusedRowIndex2 = null;
    this.focusedRow = e?.row?.data || null;
  }

  onFocusedRowChanged2(e) {
    this.focusedRowIndex = null;
    this.focusedRow2 = e?.row?.data || null;
  }

  onFocusedRowChanged3(e) {
    this.focusedRow3 = e?.row?.data || null;
  }

  addPayment(force = false) {
    if (!this.focusedRow && !this.focusedRow2) return;

    if (!force && this.connectedPaymentsValue <= this.orderGrossValue) {
      const value = this.focusedRow?.Amount || this.focusedRow2?.Amount;
      if (this.connectedPaymentsValue + value > this.orderGrossValue) {
        this.isDialogVisible.set(true);
        return;
      }
    }

    this.appService
      .postAuth(
        `orders/documents/${this.orderId}/customerAdvencePayment/connect`,
        this.focusedRow?.SettlementId || this.focusedRow2?.SettlementId
      )
      .subscribe(
        () => {
          this.focusedRow = null;
          this.focusedRow2 = null;
          this.focusedRowIndex = null;
          this.focusedRowIndex2 = null;
          this.getData();
          this.getConnectedPayments();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  deletePayment() {
    if (!this.focusedRow3) return;

    this.appService
      .postAuth(
        `orders/documents/${this.orderId}/customerAdvencePayment/disconnect`,
        this.focusedRow3?.SettlementId
      )
      .subscribe(
        () => {
          this.focusedRow3 = null;
          this.focusedRowIndex3 = null;
          this.getData();
          this.getConnectedPayments();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onDialogConfirm() {
    this.isDialogVisible = signal(false);
    this.addPayment(true);
  }

  onDialogClosing() {
    this.isDialogVisible = signal(false);
  }
}

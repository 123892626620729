import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { AppServices } from "../../../app-services.service";
import { EventService } from "../../../event.service";

@Component({
    selector: "app-quantity-sets",
    templateUrl: "./quantity-sets.component.html",
    styleUrls: ["./quantity-sets.component.scss"],
    inputs: ["readOnly", "isVisible", "title", "documentData"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class QuantitySetsComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onSaving = new EventEmitter();
  @ViewChild("firstFocus") firstFocus;
  readOnly;
  isVisible;
  title;
  documentData;

  unicalGuid;
  widthWindow = 415;
  heightWindow = 130;
  maxHeight;
  maxWidth;

  shortcuts: ShortcutInput[] = [];

  payment: Number = 0;
  change: Number = 0;
  timerWarehouse = 100;
  myTimerWarehouse;
  quantity: Number = 1;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
    this.quantity = 0;
  }

  ngOnChanges() {
    if (this.isVisible) {
    }
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: "esc",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: "F10",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  closeWindow() {
    this.onClosing.emit(true);
  }

  visibleChange = (e) => {
    this.quantity = 0;
    if (!e) {
      this.onClosing.emit(false);
      this.payment = 0;
    }
  };

  onSave() {
    this.onSaving.emit(this.quantity);
  }
}

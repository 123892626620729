<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="
    event.onShownPopUp(); setCustomerId(); event.setFocus(IdentityMasksBox)
  "
  (onHidden)="event.onHiddenPopUp(); productList = []"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="d-flex justify-content-center flex-wrap" [formGroup]="form">
        <dx-tab-panel
          #tabPanel
          [(selectedIndex)]="selectedtab"
          (onSelectionChanged)="tabSelectionChanged()"
          [items]="form.value.ImpactOnItemsMode == 2 ? itemTabChosed : itemsTab"
          [loop]="false"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          style="width: 100%"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div
                *ngSwitchCase="
                  'form-commercial-operation.costItemDataInCurrency' | translate
                "
              >
                <div class="form-group-inline-right" style="width: 100%">
                  <label
                    style="
                      display: inline-block;
                      width: 120px;
                      text-align: right;
                    "
                    >{{ "companies.form.name" | translate }}
                    <strong>*</strong></label
                  >
                  <ng-container *ngTemplateOutlet="nameBox"></ng-container>

                  <!-- <dx-text-box
                    valueChangeEvent="input"
                    [readOnly]="readOnly"
                    style="display: inline-block"
                    #firstFocus
                    formControlName="Name"
                    [ngClass]="{
                      'input-required': !form.controls.Name.valid && submitted
                    }"
                  >
                  </dx-text-box
                  > --><br />

                  <label
                    style="
                      display: inline-block;
                      width: 120px;
                      text-align: right;
                    "
                    >{{ "form-commercial-operation.grid.count" | translate }}
                    <strong>*</strong></label
                  >
                  <dx-number-box
                    width="180"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    [min]="0"
                    [readOnly]="readOnly"
                    formControlName="Amount"
                    [ngClass]="{
                      'input-required': !form.controls.Amount.valid && submitted
                    }"
                    (onValueChanged)="changeAmount()"
                  >
                  </dx-number-box
                  ><br />

                  <label style="width: 120px">{{
                    "form-commercial-operation.grid.netAmount" | translate
                  }}</label>
                  <dx-number-box
                    [readOnly]="readOnly"
                    width="180"
                    valueChangeEvent="input"
                    [format]="event.formatPLN"
                    formControlName="PriceNet"
                    (onInput)="onValueNetChange()"
                  >
                  </dx-number-box
                  ><br />

                  <label style="width: 120px">{{
                    "form-commercial-operation.vatRate" | translate
                  }}</label>
                  <app-tax-rates
                    [dropDownBoxMode]="true"
                    [readOnly]="readOnly"
                    [width]="180"
                    [taxCode]="form.value.TaxCode"
                    (onChoosed)="TaxCodeChanged($event)"
                    #taxRateDropBox
                  >
                  </app-tax-rates>
                  <label style="width: 100px">{{
                    "form-commercial-operation.grid.netValue" | translate
                  }}</label>
                  <dx-number-box
                    [readOnly]="true"
                    width="180"
                    valueChangeEvent="input"
                    [format]="event.formatPLN"
                    formControlName="TotalNetAmount"
                  >
                  </dx-number-box
                  ><br />

                  <label style="width: 120px">{{
                    "form-commercial-operation.grid.grossAmount" | translate
                  }}</label>
                  <dx-number-box
                    [readOnly]="readOnly"
                    width="180"
                    valueChangeEvent="input"
                    [format]="event.formatPLN"
                    formControlName="PriceGross"
                    (onInput)="onValueGrossChange()"
                  >
                  </dx-number-box>

                  <label style="width: 100px">{{
                    "form-commercial-operation.grid.grossValue" | translate
                  }}</label>
                  <dx-number-box
                    [readOnly]="true"
                    width="180"
                    valueChangeEvent="input"
                    formControlName="TotalGrossAmount"
                    [format]="event.formatPLN"
                  >
                  </dx-number-box
                  ><br />

                  <div
                    class="d-flex flex-row c-frame-row"
                    style="margin-top: 10px"
                  >
                    <h5>
                      {{
                        "form-commercial-operation.impactOnIncomeItems"
                          | translate
                      }}
                    </h5>
                  </div>

                  <label style="width: 115px"></label>
                  <dx-radio-group
                    [items]="impactOnIncomeItemsList"
                    layout="horizontal"
                    [readOnly]="readOnly"
                    displayExpr="label"
                    valueExpr="value"
                    formControlName="ImpactOnItemsMode"
                    (onValueChanged)="impactOnItemsModeChanged($event)"
                  ></dx-radio-group
                  ><br />

                  <label style="width: 115px"></label>
                  <dx-check-box
                    [text]="
                      'form-commercial-operation.theCostItemIsNotAffectedByVAT'
                        | translate
                    "
                    style="margin-top: 5px; margin-left: 5px"
                    formControlName="IsWithoutAffecting"
                  ></dx-check-box>

                  <div
                    class="d-flex flex-row c-frame-row"
                    style="margin-top: 15px"
                  >
                    <h5>
                      {{
                        "form-commercial-operation.theCostItemGeneratesTheSettlement"
                          | translate
                      }}
                    </h5>
                  </div>

                  <label style="width: 120px"
                    >{{ "form-financial-document.payer" | translate }}
                  </label>
                  <app-contractors
                    [dropDownBoxMode]="true"
                    style="width: 276px"
                    [readOnly]="readOnly"
                    (onChoosed)="onChoosedPayer($event)"
                    [selectedId]="form.value.CustomerId"
                    [className]="
                      !form.controls.CustomerId.valid && submitted
                        ? true
                        : false
                    "
                  >
                  </app-contractors
                  ><br />

                  <label style="width: 120px"
                    >{{ "form-financial-document.number" | translate }}
                  </label>
                  <dx-text-box
                    valueChangeEvent="input"
                    [readOnly]="readOnly"
                    style="display: inline-block"
                    width="180"
                    formControlName="InvoiceDocumentNumber"
                    [ngClass]="{
                      'input-required':
                        !form.controls.InvoiceDocumentNumber.valid && submitted
                    }"
                  >
                  </dx-text-box
                  ><br />

                  <label style="width: 120px">{{
                    "form-commercial-operation.form.dateOfIssue" | translate
                  }}</label>
                  <wapro-date-box
                    type="date"
                    width="180"
                    [displayFormat]="event.dateFormat"
                    formControlName="DocumentDate"
                    (onValueChanged)="changeDateDocument()"
                  >
                  </wapro-date-box>

                  <label style="width: 100px">{{
                    "form-financial-document.receivedDate" | translate
                  }}</label>
                  <wapro-date-box
                    type="date"
                    width="180"
                    [displayFormat]="event.dateFormat"
                    formControlName="DateOfReceived"
                  >
                  </wapro-date-box>
                  <br />

                  <label style="width: 120px">{{
                    "form-payment.paymentMethod" | translate
                  }}</label>
                  <div style="display: inline-block; width: 180px">
                    <app-forms-payment
                    [onlyActive]="true"
                      [dropDownBoxMode]="true"
                      width="180"
                      style="width: 180px"
                      [readOnly]="readOnly"
                      [selectedId]="form.value.PaymentForm"
                      (onChoosed)="onChoosedFormPeyment($event)"
                    >
                    </app-forms-payment>
                  </div>

                  <label style="width: 100px">{{
                    "form-commercial-operation.operationDate" | translate
                  }}</label>
                  <wapro-date-box
                    type="date"
                    width="180"
                    [displayFormat]="event.dateFormat"
                    formControlName="DateOfOperation"
                  >
                  </wapro-date-box
                  ><br />

                  <label style="width: 120px">{{
                    "form-commercial-operation.dateOfPayment" | translate
                  }}</label>
                  <dx-number-box
                    width="180"
                    [useLargeSpinButtons]="true"
                    [showSpinButtons]="true"
                    [min]="0"
                    formControlName="DateOfPaymentDay"
                    [readOnly]="readOnly"
                    (onValueChanged)="DateOfPaymentNumberChanged($event)"
                  >
                  </dx-number-box>

                  <label style="width: 100px">{{
                    "form-commercial-operation.dateOfDaymentts" | translate
                  }}</label>
                  <wapro-date-box
                    type="date"
                    width="180"
                    [displayFormat]="event.dateFormat"
                    formControlName="DateOfPayment"
                    (onValueChanged)="DateOfPaymentChanged($event)"
                  >
                  </wapro-date-box
                  ><br />

                  <div
                    class="d-flex flex-row c-frame-row"
                    style="margin-top: 15px"
                  >
                    <h5>
                      {{
                        "form-commercial-operation.accountingForValue"
                          | translate
                      }}
                    </h5>
                  </div>
                  <label style="width: 115px"></label>
                  <dx-radio-group
                    [items]="accountingForValueList"
                    layout="horizontal"
                    [readOnly]="readOnly"
                    displayExpr="label"
                    valueExpr="value"
                    formControlName="NetGrossMode"
                    (onValueChanged)="netGrossModeChanged($event)"
                  ></dx-radio-group>
                </div>
              </div>

              <div
                *ngSwitchCase="
                  'form-commercial-operation.PZitemsAffectedByCost' | translate
                "
              >
                <dx-button
                  class="btn-dx-top-menu-folder"
                  icon="icon absui-icon--add-circle"
                  [text]="'buttons.add' | translate"
                  id="btn-add-comercial-operation"
                  [disabled]="readOnly"
                  (onClick)="isVisibleChosedPosition = true"
                >
                </dx-button>

                <dx-button
                  icon="icon absui-icon--highlight-off"
                  [id]="'btn-delete' + unicalGuid"
                  class="btn-dx-top-menu-folder"
                  [disabled]="readOnly"
                  [text]="'buttons.delete' | translate"
                  [disabled]="selectedPositionAdded.length == 0 || readOnly"
                  (onClick)="deleteAddedPosition()"
                >
                </dx-button>

                <dx-button
                  class="btn-dx-top-menu"
                  icon="icon absui-icon--add-circle-visible"
                  [text]="'form-commercial-operation.addAll' | translate"
                  [id]="'btn-edit' + unicalGuid"
                  [disabled]="readOnly"
                  (onClick)="addAll()"
                >
                </dx-button>

                <dx-button
                  icon="icon absui-icon--highlight-off-visible"
                  [id]="'btn-delete' + unicalGuid"
                  class="btn-dx-top-menu"
                  [disabled]="readOnly"
                  [text]="'buttons.deleteAll' | translate"
                  (onClick)="deleteAllPosition()"
                  [disabled]="readOnly"
                >
                </dx-button>

                <dx-data-grid
                  [showBorders]="true"
                  [wordWrapEnabled]="false"
                  [hoverStateEnabled]="true"
                  [dataSource]="productList"
                  #itemGrid
                  shortcut
                  [focusedRowEnabled]="true"
                  [allowColumnResizing]="true"
                  [columnResizingMode]="'widget'"
                  [(selectedRowKeys)]="selectedPositionAdded"
                  style="
                    width: 100%;
                    min-height: 50px;
                    margin-bottom: 10px;
                    margin-top: 10px;
                  "
                  [height]="500"
                  (onOptionChanged)="event.onOptionChanged($event)"
                >
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxo-scrolling mode="infinite"></dxo-scrolling>
                  <dxo-paging [pageSize]="50"></dxo-paging>
                  <dxo-selection
                    mode="single"
                    showCheckBoxesMode="always"
                    selectAllMode="page"
                  ></dxo-selection>
                  <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                  <dxi-column
                    [caption]="'form-commercial-operation.grid.Lp' | translate"
                    width="32"
                    [allowSorting]="false"
                    cellTemplate="lpTemplate"
                    [allowSorting]="false"
                  >
                  </dxi-column>
                  <div *dxTemplate="let data of 'lpTemplate'; let index = i">
                    {{ data.rowIndex + 1 }}
                  </div>

                  <dxi-column
                    [caption]="
                      'form-commercial-operation.grid.articleName' | translate
                    "
                    dataField="ProductFullName"
                    [allowSorting]="false"
                  >
                  </dxi-column>
                  <dxi-column
                    caption="{{ 'customerGroups.catalogIndex' | translate }}"
                    width="130"
                    [allowSorting]="false"
                    dataField="IndexCatalogue"
                  >
                  </dxi-column>
                  <dxi-column
                    [caption]="
                      'form-commercial-operation.grid.count' | translate
                    "
                    width="93"
                    dataField="Amount"
                    [allowSorting]="false"
                  >
                  </dxi-column>
                  <dxi-column
                    [caption]="'form-commercial-operation.grid.jm' | translate"
                    width="32"
                    dataField="Unit"
                    [allowSorting]="false"
                  >
                  </dxi-column>
                  <dxi-column
                    caption="{{
                      'form-commercial-operation.grid.netAmount' | translate
                    }}"
                    [allowSorting]="false"
                    width="100"
                    dataField="PriceNet"
                    cellTemplate="PriceNet"
                  >
                    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let data of 'PriceNet'">
                    {{ data.data.PriceNet | amountFormatter : data }}
                  </div>
                  <dxi-column
                    caption="{{
                      'form-commercial-operation.grid.grossAmount' | translate
                    }}"
                    [allowSorting]="false"
                    width="100"
                    dataField="PriceGross"
                    cellTemplate="PriceGross"
                  >
                    <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
                  </dxi-column>
                  <div *dxTemplate="let data of 'PriceGross'">
                    {{ data.data.PriceGross | amountFormatter : data }}
                  </div>
                </dx-data-grid>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [width]="700"
  [height]="300"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisibleChosedPosition"
  (onShown)="event.onShownPopUp(); getPositionDocument()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
  [maxWidth]="maxWidth"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onChosedPosition()"
        [id]="'addPosition' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisibleChosedPosition = false"
        [id]="'cancelPosition' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#addPosition' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.choose" | translate }} (Enter)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancelPosition" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <dx-data-grid
        [showBorders]="true"
        [wordWrapEnabled]="false"
        [hoverStateEnabled]="true"
        [dataSource]="dataSource"
        shortcut
        [focusedRowEnabled]="true"
        [(selectedRowKeys)]="selectedPositionDocument"
        [allowColumnResizing]="true"
        (onCellDblClick)="onCellDblClick()"
        [columnResizingMode]="'widget'"
        style="width: 100%; min-height: 50px; margin-bottom: 10px"
        [height]="210"
        (onOptionChanged)="event.onOptionChanged($event)"
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="50"></dxo-paging>
        <dxo-selection
          mode="single"
          showCheckBoxesMode="always"
          selectAllMode="page"
        ></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
        <dxi-column
          [caption]="'form-commercial-operation.grid.Lp' | translate"
          width="32"
          cellTemplate="lpTemplate"
        >
        </dxi-column>
        <div *dxTemplate="let data of 'lpTemplate'; let index = i">
          {{ data.rowIndex + 1 }}
        </div>
        <dxi-column
          [caption]="'form-commercial-operation.grid.articleName' | translate"
          dataField="ProductFullName"
          width="200"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'customerGroups.catalogIndex' | translate }}"
          width="130"
          [allowSorting]="false"
          dataField="IndexCatalogue"
        ></dxi-column>
        <dxi-column
          [caption]="'form-commercial-operation.grid.count' | translate"
          width="70"
          dataField="Amount"
        >
        </dxi-column>
        <dxi-column
          [caption]="'form-commercial-operation.grid.jm' | translate"
          width="100"
          dataField="Unit"
        >
        </dxi-column>
        <dxi-column
          caption="{{ 'form-commercial-operation.grid.netAmount' | translate }}"
          [allowSorting]="false"
          width="100"
          dataField="PriceNet"
          cellTemplate="PriceNet"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'PriceNet'">
          {{ data.data.PriceNet | amountFormatter : data }}
        </div>

        <dxi-column
          caption="VAT"
          width="80"
          [allowSorting]="false"
          dataField="TaxCode"
        >
        </dxi-column>

        <dxi-column
          caption="{{
            'form-commercial-operation.grid.grossAmount' | translate
          }}"
          [allowSorting]="false"
          width="100"
          dataField="PriceGross"
          cellTemplate="PriceGross"
        >
          <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
        </dxi-column>
        <div *dxTemplate="let data of 'PriceGross'">
          {{ data.data.PriceGross | amountFormatter : data }}
        </div>
      </dx-data-grid>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<ng-template #nameBox>
  <div [formGroup]="form" style="display: inline-block">
    <dx-drop-down-box
      formControlName="Name"
      valueExpr="DesciptionOfFinanceDocumentPositionId"
      [deferRendering]="false"
      displayExpr="Description"
      [(opened)]="isGridBoxOpened"
      [dataSource]="nameList"
      [showClearButton]="true"
      [width]="276"
      (onValueChanged)="onValueChanged($event)"
      (openedChange)="onOpenedChanged($event)"
      [acceptCustomValue]="true"
      #IdentityMasksBox
      [openOnFieldClick]="false"
      [readOnly]="readOnly"
    >
      <div *dxTemplate="let data of 'content'" style="height: 280px">
        <div class="row">
          <div class="col-md-12" class="no-padding" *ngIf="isGridBoxOpened">
            <dx-data-grid
              [dataSource]="dataSourceName"
              [wordWrapEnabled]="true"
              [showBorders]="true"
              shortcut
              [height]="264"
              [(selectedRowKeys)]="selectedRow"
              [(focusedRowIndex)]="focusedRowIndex"
              class="dropDownBoxClassGrid"
              [columnResizingMode]="'widget'"
              [remoteOperations]="true"
              (onRowClick)="onRowDblClick()"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              (onFocusedRowChanged)="onFocusedRowChanged($event)"
              [id]="'grid' + unicalGuid"
              [focusedRowEnabled]="true"
              #gridClass
              [hoverStateEnabled]="true"
            >
              <dxo-load-panel [enabled]="false"></dxo-load-panel>
              <dxo-scrolling mode="infinite"></dxo-scrolling>
              <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
              <dxo-paging [pageSize]="50"></dxo-paging>
              <dxo-selection [mode]="'single'" showCheckBoxesMode="always">
              </dxo-selection>

              <dxi-column
                [width]="event.deviceType != 'mobile' ? 45 : 10"
                cellTemplate="editTemplate"
                [allowSorting]="false"
                alignment="center"
                renderAsync="true"
              >
              </dxi-column>

              <div *dxTemplate="let data of 'editTemplate'" tabindex="-1">
                <dx-button
                  class="btn-dx-top-menu-folder-grid"
                  icon="icon absui-icon--mode-edit hover-edit-icon"
                  [disabled]="readOnly"
                  (onClick)="addNewName('edit')"
                >
                </dx-button>
              </div>

              <dxi-column
                [caption]="'form-financial-document.description' | translate"
                dataField="Description"
                [allowSorting]="false"
              ></dxi-column>
            </dx-data-grid>
          </div>

          <div class="bottom-drop-box">
            <dx-button
              class="btn-dx-top-menu-folder"
              icon="icon absui-icon--add-circle"
              style="margin-left: 6px"
              [text]="'buttons.add' | translate"
              [disabled]="readOnly"
              (onClick)="addNewName('add')"
              [id]="'btn-drop-add' + unicalGuid"
            >
            </dx-button>

            <dx-tooltip
              [target]="'#btn-drop-add' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "buttons.add" | translate }}
              </div>
            </dx-tooltip>
            <!-- <ng-container *ngTemplateOutlet="topButtons"></ng-container> -->
          </div>
        </div>
      </div>
    </dx-drop-down-box>

    <dx-context-menu
      [dataSource]="contextMenu"
      [width]="200"
      [target]="'#grid' + unicalGuid"
      (onItemClick)="contextMenuClick($event)"
      (onItemRendered)="event.onItemRendered($event)"
    >
    </dx-context-menu>
  </div>

  <app-add-desciption
    [mode]="mode"
    [isVisible]="true"
    [selected]="focusedNameSelected"
    *ngIf="addRow"
    (onClosing)="addRow = false"
    (onInserted)="onInsertedName($event)"
  ></app-add-desciption>
</ng-template>

<app-confirm-dialog
  *ngIf="deleteRow"
  [isVisible]="true"
  (onRemoving)="delete()"
  (onClosing)="deleteRow = false"
>
</app-confirm-dialog>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  (visibleChange)="visibleChange($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'save' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#save' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 470px">
          <ng-container [ngSwitch]="type">
            <ng-container *ngSwitchCase="'delivery'">
              <form [formGroup]="form">
                <label style="width: 100px"
                  >{{ "intrastatCnCodes.code" | translate }}
                  <strong>*</strong></label
                >
                <dx-text-box
                  width="140"
                  formControlName="DeliveryCode"
                  [ngClass]="{
                    'input-required':
                      !form.controls.DeliveryCode.valid && submitted
                  }"
                  (onFocusIn)="event.onFocusCountry('DeliveryCode', form)"
                  (onFocusOut)="event.onFocusOut('DeliveryCode', form)"
                  [readOnly]="readOnly"
                  valueChangeEvent="keyup"
                >
                </dx-text-box>
                <div style="margin-left: 97px">
                  <dx-check-box
                    text="{{ 'intrastatCnCodes.default' | translate }}"
                    style="width: 100px; margin-left: 15px"
                    formControlName="IsDefault"
                    [readOnly]="readOnly"
                  ></dx-check-box>
                </div>
                <br />
                <label style="width: 100px">{{
                  "intrastatCnCodes.name" | translate
                }}</label>
                <dx-text-area
                  width="284"
                  formControlName="Name"
                  [readOnly]="readOnly"
                  valueChangeEvent="keyup"
                >
                </dx-text-area>
              </form>
            </ng-container>

            <ng-container *ngSwitchCase="'transport'">
              <form [formGroup]="form">
                <label style="width: 100px"
                  >{{ "intrastatCnCodes.code" | translate }}
                  <strong>*</strong></label
                >
                <dx-text-box
                  width="140"
                  formControlName="TransportCode"
                  [ngClass]="{
                    'input-required':
                      !form.controls.TransportCode.valid && submitted
                  }"
                  (onFocusIn)="event.onFocusCountry('TransportCode', form)"
                  (onFocusOut)="event.onFocusOut('TransportCode', form)"
                  [readOnly]="readOnly"
                  valueChangeEvent="keyup"
                >
                </dx-text-box>
                <div style="margin-left: 97px">
                  <dx-check-box
                    text="{{ 'intrastatCnCodes.default' | translate }}"
                    style="width: 100px; margin-left: 15px"
                    formControlName="IsDefault"
                    [readOnly]="readOnly"
                  ></dx-check-box>
                </div>
                <br />
                <label style="width: 100px">{{
                  "intrastatCnCodes.name" | translate
                }}</label>
                <dx-text-area
                  width="284"
                  formControlName="Name"
                  [readOnly]="readOnly"
                  valueChangeEvent="keyup"
                >
                </dx-text-area>
              </form>
            </ng-container>

            <ng-container *ngSwitchCase="'transaction'">
              <form [formGroup]="form">
                <label style="width: 100px"
                  >{{ "intrastatCnCodes.code" | translate }}
                  <strong>*</strong></label
                >
                <dx-text-box
                  width="140"
                  formControlName="TransactionCode"
                  [ngClass]="{
                    'input-required':
                      !form.controls.TransactionCode.valid && submitted
                  }"
                  (onFocusIn)="event.onFocusCountry('TransactionCode', form)"
                  (onFocusOut)="event.onFocusOut('TransactionCode', form)"
                  [readOnly]="readOnly"
                  valueChangeEvent="keyup"
                >
                </dx-text-box>
                <div style="margin-left: 97px">
                  <dx-check-box
                    text="{{ 'intrastatCnCodes.default' | translate }}"
                    style="width: 100px; margin-left: 15px"
                    formControlName="IsDefault"
                    [readOnly]="readOnly"
                  ></dx-check-box>
                </div>
                <br />
                <label style="width: 100px">{{
                  "intrastatCnCodes.name" | translate
                }}</label>
                <dx-text-area
                  width="284"
                  formControlName="Name"
                  [readOnly]="readOnly"
                  valueChangeEvent="keyup"
                >
                </dx-text-area>
                <div style="margin-left: 113px">
                  <dx-check-box
                    text="{{
                      'intrastatCnCodes.StatisticalValueRequired' | translate
                    }}"
                    style="width: 400px"
                    formControlName="StatisticalValueRequired"
                    [readOnly]="readOnly"
                  ></dx-check-box>
                </div>
              </form>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';

import { EventService } from '../../../event.service';

@Component({
    selector: 'app-remarks-to-orders',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        TranslateModule,
        DxTooltipModule,
        NgShortcutsComponent,
    ],
    templateUrl: './remarks-to-orders.component.html',
    styleUrl: './remarks-to-orders.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['isVisible', 'readOnly', 'text']
})
export class RemarksToOrdersComponent implements OnInit, AfterViewInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  widthWindow: number = 600;
  heightWindow: number = 300;

  isVisible: boolean = false;
  unicalGuid;
  readOnly;
  title: string = 'form-commercial-operation.informationWhenSelectingAnOrder';

  text: string = '';

  shortcuts: ShortcutInput[] = [];

  constructor(public event: EventService) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onReplace(false);
        },
        preventDefault: true,
      },
      {
        key: 'F9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onReplace(true);
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onReplace(replace) {
    this.onInserted.emit({
      replace: replace,
      text: this.text,
    });
  }
}

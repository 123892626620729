<dx-popup
  [width]="600"
  [height]="725"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'calendarSettings.title' | translate }}"
  [visible]="isPopupMode && isVisible"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="saveConfiguration()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <!-- <div class="top-menu-popup">
            <ng-container *ngTemplateOutlet="topButtons"></ng-container>
        </div> -->

    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</dx-popup>

<ng-container *ngIf="!isPopupMode">
  <div *ngIf="!dropDownBoxMode; else dropDownMode">
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="dropDownValue"
    valueExpr="DocPositionId"
    [deferRendering]="false"
    displayExpr="Barcode"
    [(opened)]="isGridBoxOpened"
    [dataSource]="[]"
    [showClearButton]="true"
    [ngStyle]="{ width: width || '276px;' }"
    [readOnly]="readOnly"
    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
  >
    <div *dxTemplate="let data of 'content'" style="height: 300px">
      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <br />
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons> </ng-template>

<ng-template #grid>
  <div class="d-flex justify-content-center flex-wrap">
    <div class="form-group-inline-right" style="width: 460px">
      <div class="c-frame-row" style="width: 100%">
        <h5>{{ "calendarSettings.basicParameters" | translate }}</h5>
      </div>
      <label style="width: 140px" for="">{{
        "calendarSettings.timeRange" | translate
      }}</label>
      <dx-select-box
        width="276"
        [items]="timeItems"
        valueExpr="value"
        displayExpr="label"
        [(value)]="_configuration.cellDuration"
        [readOnly]="readOnly"
      ></dx-select-box>
      <br />
      <label style="width: 140px" for="">
        {{ "calendarSettings.taskDescription" | translate }}</label
      >
      <dx-select-box
        width="276"
        [items]="taskDescriptions"
        valueExpr="value"
        displayExpr="label"
        [(value)]="_configuration.taskDescription"
        [readOnly]="readOnly"
      ></dx-select-box>
      <br />
      <div style="position: relative">
        <label style="width: 140px; position: absolute; top: 8px" for="">
          {{ "calendarSettings.weekView" | translate }}</label
        >
        <dx-radio-group
          style="position: relative; left: 150px; top: 5px"
          [items]="weekViewItems"
          layout="vertical"
          [(value)]="weekView"
          [readOnly]="readOnly"
          displayExpr="label"
          valueExpr="value"
        ></dx-radio-group>
      </div>
      <div style="position: relative">
        <label style="width: 140px; position: absolute; top: 8px" for="">
          {{ "calendarSettings.workerCalendar" | translate }}</label
        >
        <dx-radio-group
          style="position: relative; left: 150px; top: 5px"
          [items]="workerCalendarItems"
          layout="vertical"
          [(value)]="workerCalendar"
          [readOnly]="readOnly"
          displayExpr="label"
          valueExpr="value"
        ></dx-radio-group>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center flex-wrap">
    <div class="form-group-inline-right" style="width: 460px">
      <div class="c-frame-row" style="width: 100%">
        <h5>{{ "calendarSettings.workDayRange" | translate }}</h5>
      </div>
      <label style="width: 140px" for="">{{
        "calendarSettings.startTime" | translate
      }}</label>
      <dx-select-box
        width="276"
        [items]="timeValueItems"
        valueExpr="value"
        displayExpr="label"
        [(value)]="_configuration.startDayHour"
        [readOnly]="readOnly"
      ></dx-select-box>
      <br />
      <label style="width: 140px" for="">
        {{ "calendarSettings.endTime" | translate }}</label
      >
      <dx-select-box
        width="276"
        [items]="timeValueItems"
        valueExpr="value"
        displayExpr="label"
        [(value)]="_configuration.endDayHour"
        [readOnly]="readOnly"
      ></dx-select-box>
      <br />
      <label style="width: 140px" for=""> </label>
      <dx-check-box
        [text]="'calendarSettings.onlyWorkWeek' | translate"
        width="300"
        [(value)]="onlyWorkWeek"
        [readOnly]="readOnly"
      ></dx-check-box>
      <br />
      <label style="width: 140px" for="">
        {{ "calendarSettings.workDays" | translate }}</label
      >
      <dx-check-box
        [text]="workDaysValues[0].label"
        width="276"
        [(value)]="workDaysValues[0].value"
        [readOnly]="readOnly"
      ></dx-check-box>
      <br />
      <ng-container *ngFor="let workDay of workDaysValues; let index = index">
        <ng-container *ngIf="index > 0">
          <label style="width: 140px" for=""></label>
          <dx-check-box
            [text]="workDay.label"
            width="276"
            [(value)]="workDay.value"
            [readOnly]="readOnly"
          ></dx-check-box>
          <br />
        </ng-container>
      </ng-container>
    </div>
  </div>
  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [unicalGuid]="unicalGuid"
    [disabled]="dropDownBoxMode && !isGridBoxOpened"
  >
  </wapro-keyboard-shortcuts>
</ng-template>

import { NestService } from './../../../nest-service.service';
import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ViewChild,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { Socket } from 'src/app/socket.model';
import { GlobalDate } from '../../../core/date-range/GlobalDate';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { RefreshCardResult } from 'src/app/event.model';

import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { DatePipe } from '@angular/common';
@Component({
    selector: 'app-connected-warehouse-document',
    templateUrl: './connected-warehouse-document.component.html',
    styleUrls: ['./connected-warehouse-document.component.scss'],
    inputs: [
        'readOnly',
        'isVisible',
        'title',
        'invoiceDocumentId',
        'customerId',
        'payerId',
        'componentNests',
        'DateOfInvoiceDocument',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConnectedWarehouseDocumentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @ViewChild('btnAllWarehouseDocument') btnAllWarehouseDocument;
  @ViewChild('gridToConnect') gridToConnect;
  @ViewChild('gridConnected') gridConnected;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  openNestFinished = false;
  componentNests: Socket;
  unicalGuid;
  widthWindow = '95%';
  heightWindow = '70%';
  isVisible;
  title;
  payerId;
  customerId;
  invoiceDocumentId;
  minWidth;
  shortcuts: ShortcutInput[] = [];
  readOnly;
  DateOfInvoiceDocument;
  documentWarehouse;
  radioList = [
    { value: 0, label: 'Kontrahenta' },
    { value: 1, label: 'Płatnika' },
    { value: 2, label: 'Kontrahentów płatnika' },
  ];

  isBuyText = 'Kontrahenta';
  heightGrid;
  addRow: boolean = false;
  focusedSelected = [];
  sumPositionIncome = {
    TotalGrossAmount: 0,
    TotalNetAmount: 0,
    TotalTaxAmount: 0,
  };
  isCorrect: boolean = false;
  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: false,
  };
  perPrevCorrect: boolean = false;
  focusedSelectedNoConnected = [];
  focusedSelectedToPreview = [];
  customerMode = 0;
  warehouseId;

  kindDocumentType = [
    { value: '', label: 'Wszystkie' },
    { value: 0, label: 'Pierwotne' },
    { value: 1, label: 'Korekty' },
    { value: 2, label: 'Pierwotne gdy brak korekt' },
    { value: 3, label: 'Korekty niezerowe' },
  ];

  listDocument = [
    { value: '', label: 'Wszystkie dokumenty' },
    { value: 0, label: 'Zrealizowane zamówienia o numerze' },
    { value: 1, label: 'Oczekujące na fakturę zaliczkową końcową' },
    { value: 2, label: 'Nie związane z zamówieniami faktur zaliczkowych' },
  ];

  PrimaryCorrectionMode = '';
  AdditionalFiltresMode = '';
  DolaczDokMag: boolean = false;
  isSprNFDokMagKon: boolean = true;
  refreshCardResult: RefreshCardResult = 'Tak';
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();
  KontrDatDokMag;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices,
    private nestService: NestService
  ) {
    this.widthWindow = this.event.setWidthPopUp();
    this.minWidth = this.event.setMinWidthPopUp();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.warehouseId = this.event.decryptString(
      localStorage.getItem('sessionInfoWaproHtml')
    ).AppWarehouseId;

    this.KontrDatDokMag = this.event.getConfigurationParameter(
      'KontrDatDokMag',
      true
    );

    this.translate
      .get('all')
      .subscribe((text) => (this.kindDocumentType[0].label = text));
    this.translate
      .get('form-commercial-operation.primary')
      .subscribe((text) => (this.kindDocumentType[1].label = text));
    this.translate
      .get('warehouseDocument.corrections')
      .subscribe((text) => (this.kindDocumentType[2].label = text));
    this.translate
      .get('form-commercial-operation.primaryWhenThereAreNoCorrections')
      .subscribe((text) => (this.kindDocumentType[3].label = text));
    this.translate
      .get('form-commercial-operation.nonZeroAdjustments')
      .subscribe((text) => (this.kindDocumentType[4].label = text));

    this.translate
      .get('form-commercial-operation.allDocuments')
      .subscribe((text) => (this.listDocument[0].label = text));
    this.translate
      .get('form-commercial-operation.completedOrdersWithTheNumber')
      .subscribe((text) => (this.listDocument[1].label = text));
    this.translate
      .get('form-commercial-operation.waitingForFinalAdvanceInvoice')
      .subscribe((text) => (this.listDocument[2].label = text));
    this.translate
      .get('form-commercial-operation.primaryWhenThereAreNoCorrections')
      .subscribe((text) => (this.listDocument[3].label = text));

    this.onAdd = this.onAdd.bind(this);
    this.onDragStartGrid = this.onDragStartGrid.bind(this);
    this.onDragEndGrid = this.onDragEndGrid.bind(this);

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );
  }

  async checkSprNFDokMagKon() {
    const SprNFDokMagKon = await this.event.getHiddenParameter(
      'SprNFDokMagKon'
    );
    setTimeout(() => {
      SprNFDokMagKon?.value == '1'
        ? (this.isSprNFDokMagKon = true)
        : (this.isSprNFDokMagKon = false);
    }, 0);
  }

  onChangedSprNFDokMagKon = (e) => {
    let object = {
      code: 'SprNFDokMagKon',
      value: e.value ? '1' : '0',
    };
    this.event.saveHiddenParameter(object);
  };

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
    this.checkSprNFDokMagKon();
    this.perABD = this.event.checkPermissionsBtn(
      'DDokMag',
      'EDokMag',
      'UDokMag',
      'ODokMag'
    );
    this.DolaczDokMag = this.event.checkPermissions('DolaczDokMag');
    this.perPrevCorrect = this.event.checkPermissions('KorDokMag');

    this.getWarehouseList();
  }

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.getNoConnectedWarehouseDocuments();

      setTimeout(() => {
        this.cd.detectChanges();
      }, 1000);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue) {
      this.findSocketAndRun('XGHWOAO1');
    }
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
    this.currentDateSub?.unsubscribe();
  }

  setFocus = () => {
    setTimeout(() => {
      this.event.setFocus(this.btnAllWarehouseDocument);
    }, 300);
  };

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.acceptConnectToInvoice();
        },
        preventDefault: true,
      }
    );
  }

  noDocumentWarehouse;
  getNoConnectedWarehouseDocuments = (e?, t?) => {
    if (t) {
      if (t == 'customerMode') this.customerMode = e;
      if (t == 'AdditionalFiltresMode') this.AdditionalFiltresMode = e;
      if (t == 'PrimaryCorrectionMode') this.PrimaryCorrectionMode = e;
    }

    this.noDocumentWarehouse = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/notConnectedWarehouseDocuments`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  getLoadParams() {
    let obj: any = {};
    obj.CustomerMode = this.customerMode;
    obj.CustomerId = this.customerId;
    obj.PayerId = this.payerId;
    obj.InvoiceDocumentId = this.invoiceDocumentId;
    obj.FilterWarehouseId = this.warehouseId;
    obj.PrimaryCorrectionMode = this.PrimaryCorrectionMode;
    obj.AdditionalFiltresMode = this.AdditionalFiltresMode;

    obj.DateFrom =
      this.event.dataFormatted({ value: this.currentDate.dateFrom }) || '';
    obj.DateTo =
      this.event.dataFormatted({ value: this.currentDate.dateTo }) || '';

    return obj;
  }

  werhouseList = [];
  getWarehouseList = () => {
    if (sessionStorage.getItem('warehouseList')) {
      this.werhouseList = this.event.decryptString(
        sessionStorage.getItem('warehouseList')
      );
      return;
    }

    this.appService.getAuth('warehouses?Skip=0&Take=20').subscribe((res) => {
      res.data.forEach((field) => {
        (field.label = field.WarehouseName), (field.value = field.WarehouseId);
      });
      this.werhouseList = res.data;
      localStorage.setItem(
        'warehouseList',
        this.event.encryptString(this.werhouseList)
      );
    });
  };

  getConnectedWarehouseDocuments = () => {
    this.documentWarehouse = new DataSource({
      store: AspNetData.createStore({
        key: 'WarehouseDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}invoices/documents/${this.invoiceDocumentId}/connectedWarehouseDocuments`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      reshapeOnPush: true,
    });
  };

  onClosingForm = () => {
    this.addRow = false;
    this.cd.detectChanges();
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
    }
  };

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
    this.focusedSelectedNoConnected = [];
    this.focusedSelectedToPreview = [e.row.data];
  };

  onFocusedRowChangedNoConected = (e) => {
    this.focusedSelected = [];
    this.focusedSelectedNoConnected = [e.row.data];
    this.focusedSelectedToPreview = [e.row.data];
  };

  noDiferentDateWarehouseDocument() {
    this.diferentDateWarehouseDocument = false;
    this.cd.detectChanges();
  }

  arrayToAdd: any;
  yesDiferentDateWarehouseDocument() {
    this.diferentDateWarehouseDocument = false;
    this.connectWarehouseDocument(null, null, true);
  }

  chcecBadWarehouseDocment() {
    return new Promise((resolve) => {
      this.arrayToAdd = [];
      this.noDocumentWarehouse._items.forEach((field) => {
        if (
          new DatePipe('en-US').transform(
            field.DateOfWarehouseDocument,
            'yyyy-MM-dd'
          ) !=
          new DatePipe('en-US').transform(
            this.DateOfInvoiceDocument,
            'yyyy-MM-dd'
          )
        ) {
          this.arrayToAdd.push(field);
        }
      });
      resolve(this.arrayToAdd.length);
    });
  }

  connectObject = null;
  diferentDateWarehouseDocument: boolean = false;
  connectWarehouseDocument = (
    WarehouseDocumentId?,
    skipNest: boolean = false,
    skipDiferentDate: boolean = false
  ) => {
    if (this.readOnly) return;

    if (this.KontrDatDokMag.Value === 'Blokuj' && !skipDiferentDate) {
      let focusedSelectedNoConnected;
      if (WarehouseDocumentId) {
        focusedSelectedNoConnected = this.noDocumentWarehouse._items.find(
          (x) => x.WarehouseDocumentId === WarehouseDocumentId
        );
      } else focusedSelectedNoConnected = this.focusedSelectedNoConnected[0];

      if (
        new DatePipe('en-US').transform(
          focusedSelectedNoConnected.DateOfWarehouseDocument,
          'yyyy-MM-dd'
        ) !=
        new DatePipe('en-US').transform(
          this.DateOfInvoiceDocument,
          'yyyy-MM-dd'
        )
      ) {
        this.event.showNotification(
          'error',
          this.translate.instant(
            'form-commercial-operation.thereIsAwarehouseDocumentWithAdateDifferentFromTheSaleDate'
          )
        );
        return;
      }
    }

    if (this.KontrDatDokMag.Value === 'Ostrzegaj' && !skipDiferentDate) {
      let focusedSelectedNoConnected;
      if (WarehouseDocumentId) {
        focusedSelectedNoConnected = this.noDocumentWarehouse._items.find(
          (x) => x.WarehouseDocumentId === WarehouseDocumentId
        );
      } else focusedSelectedNoConnected = this.focusedSelectedNoConnected[0];

      if (
        new DatePipe('en-US').transform(
          focusedSelectedNoConnected?.DateOfWarehouseDocument,
          'yyyy-MM-dd'
        ) !=
        new DatePipe('en-US').transform(
          this.DateOfInvoiceDocument,
          'yyyy-MM-dd'
        )
      ) {
        this.diferentDateWarehouseDocument = true;
        this.cd.detectChanges();
        return;
      }
    }

    if (this.focusedSelectedNoConnected.length == 1 || WarehouseDocumentId) {
      if (!skipNest) {
        this.connectObject = {
          InvoiceDocumentId: this.invoiceDocumentId,
          WarehouseDocumentId: WarehouseDocumentId
            ? WarehouseDocumentId
            : this.focusedSelectedNoConnected[0].WarehouseDocumentId,
        };

        this.findSocketAndRun('XGHWOAW1');
        return;
      }
      const connectObject = {
        InvoiceDocumentId: this.invoiceDocumentId,
        WarehouseDocumentId: WarehouseDocumentId,
      };
      this.connectWarehouseDocumentApi(connectObject, true);
    }
  };

  connectWarehouseDocumentApi(object: any = null, skipNest: boolean = false) {
    this.appService
      .postAuth(
        `invoices/documents/${this.invoiceDocumentId}/connectWarehouseDocument`,
        object || this.connectObject
      )
      .subscribe(
        () => {
          this.focusedSelectedNoConnected = [];
          this.focusedSelected = [];
          this.documentWarehouse.reload();
          this.noDocumentWarehouse.reload();
        },
        (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        },
        () => {
          if (!skipNest) {
            this.findSocketAndRun('XGHWOAW2');
          }
        }
      );
  }

  connectAllWarehouseDocument = () => {
    this.findSocketAndRun('XGHWOAM1');
  };

  async connectAllWarehouseDocumentApi() {
    let length = await this.chcecBadWarehouseDocment();

    if (
      (this.KontrDatDokMag.Value === 'Ostrzegaj' ||
        this.KontrDatDokMag.Value === 'Blokuj') &&
      Number(length) > 0
    ) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'form-commercial-operation.groupAddingOfDocumentsisnotPossible'
        )
      );
      return;
    }

    this.noDocumentWarehouse._items.forEach((field) => {
      this.connectWarehouseDocument(field.WarehouseDocumentId, true);

      setTimeout(() => {}, 500);
    });
    this.findSocketAndRun('XGHWOAM2');
  }

  disconnectObject = null;
  disconnectWarehouseDocument = () => {
    if (this.readOnly) return;
    if (this.focusedSelected.length == 1) {
      this.disconnectObject = {
        InvoiceDocumentId: this.invoiceDocumentId,
        WarehouseDocumentId: this.focusedSelected[0].WarehouseDocumentId,
        IsEditMode: true,
      };

      this.findSocketAndRun('XGHWOAU1');
    }
  };

  disconnectWarehouseDocumentApi() {
    this.appService
      .postAuth(
        `invoices/documents/${this.invoiceDocumentId}/disconnectWarehouseDocument`,
        this.disconnectObject
      )
      .subscribe(
        () => {
          this.focusedSelectedNoConnected = [];
          this.focusedSelected = [];
          this.documentWarehouse.reload();
          this.noDocumentWarehouse.reload();
        },
        (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHWOAU2');
        }
      );
  }

  onRowClickConecct = (e) => {
    this.focusedSelected = [e.data];
    this.focusedSelectedNoConnected = [];
    this.focusedSelectedToPreview = [e.data];
    this.gridToConnect.instance.option('focusedRowIndex', -1);
    this.cd.detectChanges();
  };

  onRowClickDisconect = (e) => {
    this.focusedSelected = [];
    this.focusedSelectedNoConnected = [e.data];
    this.focusedSelectedToPreview = [e.data];
    this.gridConnected.instance.option('focusedRowIndex', -1);
    this.cd.detectChanges();
  };

  onChoosedWarehouse = (e) => {
    if (e) {
      this.warehouseId = e;
    } else {
      this.warehouseId = this.event.decryptString(
        localStorage.getItem('sessionInfoWaproHtml')
      ).AppWarehouseId;
    }
    this.getNoConnectedWarehouseDocuments();
  };

  acceptConnectToInvoice = () => {
    this.findSocketAndRun('XGHWOAA1');
  };

  acceptConnetToInvoiceApi() {
    this.appService
      .putAuth(
        `invoices/documents/${this.invoiceDocumentId}/acceptConnectToInvoice`,
        { invoiceDocumentId: this.invoiceDocumentId }
      )
      .subscribe(
        () => {},
        (error) => {
          this.event.loadingVisible = false;
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGHWOAA2');
        }
      );
  }

  onRowClickNoConected(e) {
    this.focusedSelectedToPreview = [e.data];
  }

  editDocument = () => {
    this.sumPositionIncome.TotalGrossAmount =
      this.focusedSelectedToPreview[0].TotalGrossAmount;
    this.sumPositionIncome.TotalNetAmount =
      this.focusedSelectedToPreview[0].TotalNetAmount;
    this.sumPositionIncome.TotalTaxAmount =
      this.focusedSelectedToPreview[0].TotalTaxAmount;
    if (this.perABD.showBtn) {
      this.focusedSelectedToPreview[0].SumBy =
        this.focusedSelectedToPreview[0].SumBy.replace(' ', '');
      this.addRow = true;
      this.cd.detectChanges();
    }
  };

  fromData;
  onDragStartGrid = (e) => {
    this.fromData = e.fromData;
  };

  @ViewChild('popHeight') popHeight;
  checkHeightPopup() {
    if (this.heightWindow == '70%' && window.innerHeight * 0.7 < 630) {
      this.heightGrid = 400;
      return;
    }

    if (this.heightWindow == '70%' && window.innerHeight * 0.7 > 630) {
      this.heightGrid = window.innerHeight * 0.7 - 300;
      return;
    }

    this.heightGrid = Number(this.heightWindow) - 300;
  }

  onDragEndGrid = () => {
    this.fromData = null;
  };

  onAdd(e) {
    //const key = e.itemData.WarehouseDocumentId;
    //const values = { Status: e.toData };

    if (e.toData == 2) {
      this.connectWarehouseDocument();
      return;
    }

    this.disconnectWarehouseDocument();
  }

  isNestRunning = false;
  nestOperation: NestOperation = null;
  nest: Socket;
  findSocketAndRun(SocketCode: string, nestOperation: NestOperation = null) {
    this.nestOperation = nestOperation;
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGHWOAO1':
        this.openNestFinished = true;
        this.findSocketAndRun('XGHWOAO2');
        break;
      case 'XGHWOAA1':
        this.acceptConnetToInvoiceApi();
        break;
      case 'XGHWOAA2':
        this.isVisible = false;
        this.cd.detectChanges();
        break;
      case 'XGHWOAW1':
        this.connectWarehouseDocumentApi();
        break;
      case 'XGHWOAM1':
        this.connectAllWarehouseDocumentApi();
        break;
      case 'XGHWOAU1':
        this.disconnectWarehouseDocumentApi();
        break;
    }
  }

  isExpansionListVisible = false;
  onExpansionListClosing() {
    this.isExpansionListVisible = false;
    this.componentNests = this.nestService.getNestBySocketCode('XGH');
  }
}

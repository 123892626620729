<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType !== 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType !== 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      *ngIf="event.deviceType != 'mobile'; else mobileHeaderTemplate"
    >
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closePopup()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 550px">
          <form [formGroup]="form">
            <label style="width: 90px"
              >{{ "articles.title" | translate }} <strong>*</strong></label
            >
            <dx-text-box
              [readOnly]="true"
              width="276"
              formControlName="FullName"
            ></dx-text-box
            ><br />

            <label style="width: 90px"
              >{{ "articles.unit" | translate }} <strong>*</strong></label
            >
            <dx-text-box
              [elementAttr]="{ class: 'txtbot-as-dropdown-text' }"
              width="240"
              [readOnly]="true"
              [ngClass]="{
                'input-required': !form.controls.Unit.valid && submitted
              }"
              style="display: inline-block; background: white !important"
              formControlName="Unit"
            >
            </dx-text-box>

            <dx-button
              [elementAttr]="{ class: 'btn-as-dropdown-icon' }"
              icon="icon absui-icon--folder-expand"
              type="normal"
              style="position: relative; top: -0.6px; height: 28px !important"
              (onClick)="sizeUnitsVisible = !sizeUnitsVisible"
              [disabled]="readOnly"
            >
            </dx-button
            ><br />

            <label style="width: 90px">{{
              "servicesDevices.VATcode" | translate
            }}</label>
            <app-tax-rates
              [dropDownBoxMode]="true"
              [readOnly]="readOnly"
              [taxCode]="form.get('TaxCode').value"
              (onChoosed)="onBuyTaxChanged($event)"
              [width]="276"
            >
            </app-tax-rates
            ><br />

            <label style="width: 90px"
              >{{ "form-commercial-operation.grid.count" | translate }}
              <strong>*</strong></label
            >
            <dx-number-box
              width="276"
              [readOnly]="readOnly"
              formControlName="Amount"
              [ngClass]="{
                'input-required': !form.controls.Amount.valid && submitted
              }"
              valueChangeEvent="input"
              #firsFocus
            >
            </dx-number-box
            ><br />

            <label style="width: 90px" *ngIf="SerwisZlecCeny">
              {{ "form-commercial-operation.grid.netAmount" | translate }}
            </label>
            <dx-number-box
              width="276"
              style="display: inline-block; margin-bottom: 6px"
              [readOnly]="readOnly"
              [format]="event.formatPLN"
              valueChangeEvent="input"
              (onInput)="onValueNetChange()"
              formControlName="PriceNet"
              *ngIf="SerwisZlecCeny"
            >
            </dx-number-box>

            <br />

            <label style="width: 90px" *ngIf="SerwisZlecCeny">
              {{
                "form-commercial-operation.grid.grossAmount" | translate
              }}</label
            >
            <dx-number-box
              width="276"
              style="display: inline-block; margin-bottom: 6px"
              [readOnly]="true"
              [format]="event.formatPLN"
              valueChangeEvent="input"
              formControlName="PriceGross"
              *ngIf="SerwisZlecCeny"
            >
            </dx-number-box
            ><br />

            <label style="width: 90px">
              {{ "articles.certificateDate" | translate }}</label
            >
            <wapro-date-box
              type="date"
              width="276"
              [readOnly]="readOnly"
              [displayFormat]="event.dateFormat"
              formControlName="ExpirationDate"
            >
            </wapro-date-box
            ><br />

            <label style="width: 90px">
              {{ "form-payment.discountMarkUp" | translate }}
            </label>
            <dx-number-box
              [readOnly]="readOnly"
              valueChangeEvent="input"
              format="#0.00%"
              [showSpinButtons]="true"
              formControlName="Discount1"
              [useLargeSpinButtons]="true"
              [step]="0.01"
              width="276"
              style="display: inline-block"
            ></dx-number-box
            ><br />

            <label style="width: 90px">
              {{ "form-financial-document.description" | translate }}
            </label>
            <dx-text-area
              width="276"
              formControlName="Description"
            ></dx-text-area>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-template #mobileHeaderTemplate> </ng-template>
<app-size-units
  [isPopupMode]="true"
  [isVisible]="sizeUnitsVisible"
  [articleId]="form.value.ProductId"
  (onClosed)="sizeUnitsVisible = false"
  (onChoosed)="onSizeUnitsChoosed($event)"
></app-size-units>

<!-- pola dodatkowe -->
<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Serwis_zlecenie_pozycja_dok"
  [readOnly]="readOnly"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="form.value"
  #isValidUserExtensions
>
</app-form-user-extensions>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

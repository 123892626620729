import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-vat-verification',
    templateUrl: './vat-verification.component.html',
    styleUrls: ['./vat-verification.component.scss'],
    inputs: [
        'readOnly',
        'isVisible',
        'title',
        'sumPositionCost',
        'IsCurrencyDocument',
        'CurrencyCode',
        'InvoiceDocumentId',
        'form',
        'IsVerifcationAmoutsSplitPaymentButtonEnable',
        'isCorrect',
    ],
    standalone: false
})
export class VatVerificationComponent implements OnInit, AfterViewInit {
  @Output() onClosing = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('grid') grid;
  readOnly;
  isVisible;
  title;
  form;
  unicalGuid;
  widthWindow = '90%';
  heightWindow = '70%';
  maxHeight;
  maxWidth;
  isCorrect;
  dataSource;
  shortcuts: ShortcutInput[] = [];
  focusedSelected = [];

  sumPositionCost;
  IsCurrencyDocument;
  CurrencyCode;
  selectedRowKeys: any[] = [];

  keepsRecords: any[] = [
    { label: 'wszystkie', value: false },
    { label: 'kwota VAT <> 0', value: true },
  ];

  isTaxGreaterThanZero: boolean = false;
  valueFiltr: number = 0;
  InvoiceDocumentId;
  loadChildComponent: boolean = false;
  isEdit: boolean = false;
  focusedRowIndex: number = 0;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;

    this.translate
      .get('form-commercial-operation.verificationOfInvoiceBillAmounts')
      .subscribe((text) => (this.title = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );

    setTimeout(() => {
      this.loadChildComponent = true;
    }, 1000);
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onFocusedRowChanged(e) {
    if (e) {
      this.focusedSelected = [e.row.data];
    }
  }
  IsVerifcationAmoutsSplitPaymentButtonEnable: boolean = false;

  onRowDblClick() {
    if (!this.readOnly) {
      this.isEdit = true;
      this.cd.detectChanges();
    }
  }

  getData() {
    this.appService
      .getAuth(
        `invoices/documents/${this.InvoiceDocumentId}/verificationOfInvoiceAmounts?isTaxGreaterThanZero=${this.isTaxGreaterThanZero}`
      )
      .subscribe(
        (res) => {
          this.dataSource = res;
          this.objectAccepy = {
            InvoiceDocumentId: this.InvoiceDocumentId,
            VerificationPositionList: [],
          };
          setTimeout(() => {
            this.focusedRowIndex = 0;
            this.event.setFocus(this.grid);
          }, 0);

          if (res.length > 0) {
            this.focusedSelected = [res[0]];
          } else this.focusedSelected = [];
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  onClosingVatEdit() {
    this.isEdit = false;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  objectAccepy: any = {
    InvoiceDocumentId: 0,
    VerificationPositionList: [
      {
        InvoiceDoucmentVerificationAmountsId: 0,
        TotalNetAmount: 0,
        TotalTaxAmount: 0,
        TotalNetAmountCurrency: 0,
        TotalTaxAmountCurrency: 0,
      },
    ],
  };
  onInserted(e) {
    this.isEdit = false;
    this.objectAccepy = {
      InvoiceDocumentId: this.InvoiceDocumentId,
      VerificationPositionList: [
        {
          InvoiceDoucmentVerificationAmountsId:
            e.InvoiceDoucmentVerificationAmountsId,
          TotalNetAmount: e.TotalNetAmount,
          TotalTaxAmount: e.TotalTaxAmount,
          TotalNetAmountCurrency: e.TotalNetAmountCurrency,
          TotalTaxAmountCurrency: e.TotalTaxAmountCurrency,
        },
      ],
    };

    let row = this.dataSource.find(
      (field) =>
        field.InvoiceDoucmentVerificationAmountsId ==
        e.InvoiceDoucmentVerificationAmountsId
    );

    row.TotalNetAmount = e.TotalNetAmount;
    row.TotalTaxAmount = e.TotalTaxAmount;
    row.TotalNetAmountCurrency = e.TotalNetAmountCurrency;
    row.TotalTaxAmountCurrency = e.TotalTaxAmountCurrency;
    row.IsModified = true;

    let TotalNetAmountCurrency = 0;
    let TotalTaxAmountCurrency = 0;

    let TotalNetAmount = 0;
    let TotalTaxAmount = 0;

    this.dataSource.forEach((field) => {
      TotalNetAmountCurrency += field.TotalNetAmountCurrency;
      TotalTaxAmountCurrency += field.TotalTaxAmountCurrency;
      TotalNetAmount += field.TotalNetAmount;
      TotalTaxAmount += field.TotalTaxAmount;
    });

    this.sumPositionCost.TotalGrossAmountCurrency =
      TotalNetAmountCurrency + TotalTaxAmountCurrency;
    this.sumPositionCost.TotalGrossAmount = TotalNetAmount + TotalTaxAmount;

    this.sumPositionCost.TotalTaxAmountCurrency = TotalTaxAmountCurrency;
    this.sumPositionCost.TotalTaxAmount = TotalTaxAmount;

    this.sumPositionCost.TotalNetAmountCurrency = TotalNetAmountCurrency;
    this.sumPositionCost.TotalNetAmount = TotalNetAmount;
    this.cd.detectChanges();
  }

  onSave = () => {
    this.objectAccepy.VerificationPositionList = [];
    this.dataSource.forEach((field) => {
      this.objectAccepy.VerificationPositionList.push({
        InvoiceDoucmentVerificationAmountsId:
          field.InvoiceDoucmentVerificationAmountsId,
        TotalNetAmount: field.TotalNetAmount,
        TotalTaxAmount: field.TotalTaxAmount,
        TotalNetAmountCurrency: field.TotalNetAmountCurrency,
        TotalTaxAmountCurrency: field.TotalTaxAmountCurrency,
        IsModified: field.IsModified,
      });
    });

    this.appService
      .putAuth(
        `invoices/documents/${this.InvoiceDocumentId}/acceptVerificationOfInvoiceAmounts`,
        this.objectAccepy
      )
      .subscribe(
        () => {
          this.dataSource = [];
          this.isVisible = false;
          this.cd.detectChanges();
          this.onChoosed.emit(true);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };
}

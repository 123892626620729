<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'individualPrices.customerTitle' | translate"
  [visible]="true"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onSave()"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="form-group-inline-right" style="width: 100%">
        <div class="c-frame-row">
          <h5>
            {{ "form-commercial-operation.pricingOption" | translate }}
          </h5>
        </div>
        <dx-radio-group
          [items]="optionList"
          [readOnly]="readOnly"
          [(value)]="pricingOptionValue"
          layout="horizontal"
          valueExpr="value"
          displayExpr="label"
        >
        </dx-radio-group>
        <br /><br />

        <label style="width: 110px">
          {{ "customerGroups.priceName" | translate }}
        </label>
        <dx-select-box
          [readOnly]="readOnly || pricingOptionValue == 0"
          [dataSource]="pricesList"
          width="276"
          [(value)]="priceValue"
          [valueExpr]="'PriceId'"
          [displayExpr]="'PriceName'"
        ></dx-select-box>
        <br />

        <label style="width: 110px">
          {{ "articles.overheadsAndMargins" | translate }}
        </label>

        <dx-number-box
          format="#0.00%"
          [showSpinButtons]="true"
          [max]="1"
          [step]="0.01"
          [width]="276"
          [useLargeSpinButtons]="true"
          [(value)]="DiscountMarkup"
          [readOnly]="readOnly || pricingOptionValue == 0"
        >
        </dx-number-box>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<dx-popup
  width="600"
  height="380"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'inventory.enterStateAfter' | translate }}"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp(); event.setFocus(firstFocus)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        type="danger"
        text="{{ 'buttons.cancel' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <div class="d-flex justify-content-center flex-wrap">
      <div class="form-group-inline-right" style="width: 100%">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
              <label style="width: 90px">{{
                "companies.grid.columns.name" | translate
              }}</label>
              <dx-text-box
                [readOnly]="true"
                formControlName="ProductFullName"
                style="max-width: 100%; width: calc(100% - 188px)"
                #firstFocus
              ></dx-text-box>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label style="width: 90px">{{
                "inventory.positionUnit" | translate
              }}</label>
              <dx-text-box
                [readOnly]="true"
                formControlName="Unit"
                width="84"
              ></dx-text-box>
            </div>
            <div class="col-md-6">
              <label style="width: 90px">{{
                "warehouseDocument.stockUnit" | translate
              }}</label>
              <dx-text-box
                [readOnly]="true"
                formControlName="UnitName"
                width="84"
              ></dx-text-box>
            </div>
          </div>

          <div class="row" style="margin-top: 20px; margin-bottom: 20px">
            <div class="col-md-6">
              <div class="c-frame-row">
                <h5>{{ "inventory.beforeInventory" | translate }}</h5>
                <label style="width: 90px">{{
                  "warehouse.grid.state" | translate
                }}</label>
                <dx-number-box
                  [readOnly]="true"
                  valueChangeEvent="input"
                  width="84"
                  formControlName="StockAmount"
                >
                </dx-number-box>
              </div>
            </div>

            <div class="col-md-6">
              <div class="c-frame-row">
                <h5>{{ "inventory.afterInventory" | translate }}</h5>
                <label style="width: 90px">{{
                  "warehouse.grid.state" | translate
                }}</label>
                <dx-number-box
                  [readOnly]="readOnly"
                  valueChangeEvent="input"
                  width="84"
                  formControlName="StockLeft"
                >
                </dx-number-box>
              </div>
            </div>
          </div>

          <div class="c-frame-row">
            <h5>{{ "inventory.stateCompletionAlgorithm" | translate }}</h5>

            <dx-radio-group
              [items]="algorithmTypelist"
              layout="horizontal"
              displayExpr="label"
              valueExpr="value"
              [readOnly]="readOnly"
              formControlName="AlgorithmType"
            >
            </dx-radio-group>
          </div>
        </form>
      </div>
    </div>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { IDocumentOfferStatus } from '../IDocumentOffer';

@Component({
    selector: 'app-offers-status',
    templateUrl: './offers-status.component.html',
    styleUrls: ['./offers-status.component.scss'],
    inputs: ['isVisible', 'title', 'readOnly'],
    standalone: false
})
export class OffersStatusComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;

  isVisible;
  title;
  readOnly;
  unicalGuid;

  widthWindow = 700;
  heightWindow = 365;
  form;
  shortcuts: ShortcutInput[] = [];
  offerStatuses: IDocumentOfferStatus[] = [];
  UstStatusOfe: boolean = true;
  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.UstStatusOfe = this.event.checkPermissions('UstStatusOfe');
    this.createForm();
    this.getStatuses();
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'F10',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        if (!this.readOnly) {
          this.onSave();
        }
      },
      preventDefault: true,
    });
  }

  setDisabledStatusCodeYorA() {
    this.offerStatuses.forEach((el) => {
      if (el.StatusCode === 'Y' || el.StatusCode === 'A') {
        el.disabled = !this.UstStatusOfe;
      }
    });
  }

  getStatuses = () => {
    const localStatusTree: string = localStorage.getItem(
      'singleOfferStatusTree'
    );

    if (localStatusTree) {
      this.offerStatuses = this.event.decryptString(localStatusTree);
      this.setDisabledStatusCodeYorA();
      return;
    }
    this.appService.getAuth(`offers/StatusTree`).subscribe(
      (statuses: IDocumentOfferStatus) => {
        this.offerStatuses = [];
        this.transformStatusesToArray(statuses, this.offerStatuses);
        localStorage.setItem(
          'singleOfferStatusTree',
          this.event.encryptString(this.offerStatuses)
        );
        this.setDisabledStatusCodeYorA();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        localStorage.removeItem('singleOfferStatusTree');
      }
    );
  };

  transformStatusesToArray(
    status: IDocumentOfferStatus,
    statuses: IDocumentOfferStatus[]
  ) {
    if (status.StatusCode) {
      statuses.push(status);
    }
    status.Statuses?.forEach((el: IDocumentOfferStatus) => {
      this.transformStatusesToArray(el, statuses);
    });
  }

  createForm = () => {
    this.form = this.formBuilder.group({
      OfferDocumentStatus: '',
      Comment: '',
    });
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  offerStatusDisplay(item: IDocumentOfferStatus) {
    return item && item.StatusName + ` (${item.StatusCode})`;
  }

  onChoosedComment(e) {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.controls.Comment.setValue(text);
    }
  }

  onSave = () => {
    if (!this.readOnly) {
      this.appService
        .putAuth(`offers/additionalOperations/changesStatus`, this.form.value)
        .subscribe(
          () => {
            this.createForm();
            this.onInserted.emit(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  };
}

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  Output,
  EventEmitter,
  input,
  AfterViewInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  DxButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-set-number',
    imports: [
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        CommonModule,
        DxTextBoxModule,
        TranslateModule,
        DxScrollViewModule,
        NgShortcutsComponent,
    ],
    templateUrl: './set-number.component.html',
    styleUrl: './set-number.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetNumberComponent implements AfterViewInit, OnDestroy {
  @Input() isVisible: boolean = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;

  warehouseDocumentNumber: string = '<auto>';
  warehouseDocumentData = input.required<any>();

  translate = inject(TranslateService);
  event = inject(EventService);
  appService = inject(AppServices);

  widthWindow: number = 350;
  heightWindow: number = 150;
  title = this.translate.instant('workers.documentNumber');
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  readOnly = this.event.readOnly;
  shortcuts: ShortcutInput[] = [];

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  setFocus() {
    setTimeout(() => {
      this.firstFocus.instance.focus();
      this.firstFocus.element.nativeElement
        .querySelector('.dx-texteditor-input')
        .select();
    }, 200);
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp();
  }

  onSave() {
    let object = this.warehouseDocumentData();
    object.WarehouseDocumentNumber = this.warehouseDocumentNumber;
    this.event.onShown();
    this.appService
      .putAuth(
        `warehouseDocuments/documents/${
          this.warehouseDocumentData().WarehouseDocumentId
        }/accept`,
        object
      )
      .subscribe({
        next: () => {
          this.appService
            .putAuth(
              `warehouseDocuments/documents/${
                this.warehouseDocumentData().WarehouseDocumentId
              }/prepareForModify`,
              this.warehouseDocumentData().WarehouseDocumentId
            )
            .subscribe({
              next: () => {
                this.onInserted.emit(true);
                this.event.onHidden();
              },
              error: (err) => {
                this.event.httpErrorNotification(err);
                this.event.onHidden();
              },
            });
        },
        error: (err) => {
          this.event.httpErrorNotification(err);
          this.event.onHidden();
        },
      });
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}

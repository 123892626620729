<dx-popup
  [width]="widthWindow"
  height="500"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'form-financial-document.paymentM' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'btn-cancel-item' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#btn-cancel-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 100%">
          <div style="padding-bottom: 10px">
            <div class="d-inline-block" style="width: 48%">
              Kojarzenie płatności z zamówieniem: <b>{{ orderNumber }}</b>
            </div>
            <div class="d-inline-block" style="width: 4%"></div>
            <div class="d-inline-block" style="width: 48%">
              Kontrahent: <b>{{ customerName }}</b>
            </div>
          </div>

          <div>
            <div class="d-inline-block" style="width: 48%">
              <div class="c-frame-row" style="width: 100%">
                <h5>
                  {{ "documentsOrders.prefferedDocumentToOrder" | translate }}
                </h5>
              </div>
              <label style="width: 145px"> </label>
              <dx-radio-group
                [dataSource]="documentItems"
                layout="horizontal"
                [readOnly]="readOnly"
                (onValueChanged)="onCustomerModeChanged()"
                displayExpr="label"
                #evidenceInput
                [(value)]="customerMode"
                valueExpr="value"
              >
              </dx-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 48%">
          <dx-tab-panel
            #tabPanel
            [items]="[
              'buffor.Advances' | translate,
              'documentsOrders.paymentsFakir' | translate
            ]"
            [loop]="false"
            [animationEnabled]="false"
            [(selectedIndex)]="selectedTabIndex"
            (onSelectionChanged)="onTabIndexChanged()"
            [swipeEnabled]="false"
          >
            <div *dxTemplate="let name of 'item'">
              <ng-container [ngSwitch]="name">
                <div *ngSwitchCase="'buffor.Advances' | translate">
                  <div class="d-flex justify-content-center flex-wrap">
                    <dx-data-grid
                      [dataSource]="dataSource"
                      [showBorders]="true"
                      [id]="'payments2' + unicalGuid"
                      [wordWrapEnabled]="false"
                      [remoteOperations]="true"
                      [(focusedRowKey)]="focusedRowIndex"
                      (onFocusedRowChanged)="onFocusedRowChanged($event)"
                      shortcut
                      [focusedRowEnabled]="true"
                      [allowColumnResizing]="true"
                      (onOptionChanged)="event.onOptionChanged($event)"
                    >
                      <dxo-selection
                        mode="none"
                        showCheckBoxesMode="always"
                      ></dxo-selection>
                      <dxo-scrolling
                        mode="infinite"
                        rowRenderingMode="virtual"
                      ></dxo-scrolling>
                      <dxo-paging [pageSize]="100"></dxo-paging>
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxi-column
                        dataField="Number"
                        caption="{{ 'documentsOrders.paymentNo' | translate }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="Amount"
                        caption="{{
                          'form-financial-document.grid.amount' | translate
                        }}"
                        cellTemplate="numberPrecisionTemplate"
                      ></dxi-column>
                      <dxi-column
                        dataField="CurrencyCode"
                        caption="{{
                          'bankAccounts.grid.columns.currency' | translate
                        }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="InvoiceNumber"
                        caption="{{ 'documentsOrders.invoiceNo' | translate }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="OrderNumber"
                        caption="{{
                          'warehouseDocument.orderNumber' | translate
                        }}"
                      ></dxi-column>

                      <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                        {{ cell.value | amountFormatter : cell }}
                      </div>
                    </dx-data-grid>
                  </div>
                </div>
                <div
                  *ngSwitchCase="'documentsOrders.paymentsFakir' | translate"
                >
                  <div class="d-flex justify-content-center flex-wrap">
                    <dx-data-grid
                      [dataSource]="dataSource"
                      [showBorders]="true"
                      [id]="'payments2' + unicalGuid"
                      [wordWrapEnabled]="false"
                      [remoteOperations]="true"
                      [(focusedRowKey)]="focusedRowIndex2"
                      (onFocusedRowChanged)="onFocusedRowChanged2($event)"
                      shortcut
                      [focusedRowEnabled]="true"
                      [allowColumnResizing]="true"
                      (onOptionChanged)="event.onOptionChanged($event)"
                    >
                      <dxo-selection
                        mode="none"
                        showCheckBoxesMode="always"
                      ></dxo-selection>
                      <dxo-scrolling
                        mode="infinite"
                        rowRenderingMode="virtual"
                      ></dxo-scrolling>
                      <dxo-paging [pageSize]="100"></dxo-paging>
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxi-column
                        dataField="Number"
                        caption="{{ 'documentsOrders.paymentNo' | translate }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="Amount"
                        caption="{{
                          'form-financial-document.grid.amount' | translate
                        }}"
                        cellTemplate="numberPrecisionTemplate"
                      ></dxi-column>
                      <dxi-column
                        dataField="CurrencyCode"
                        caption="{{
                          'bankAccounts.grid.columns.currency' | translate
                        }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="InvoiceNumber"
                        caption="{{ 'documentsOrders.invoiceNo' | translate }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="OrderNumber"
                        caption="{{
                          'warehouseDocument.orderNumber' | translate
                        }}"
                      ></dxi-column>

                      <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                        {{ cell.value | amountFormatter : cell }}
                      </div>
                    </dx-data-grid>
                  </div>
                </div>
              </ng-container>
            </div>
          </dx-tab-panel>
        </div>
        <div class="form-group-inline-right" style="width: 4%">
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon icon--keyboard-arrow-left"
            [text]="'buttons.delete' | translate"
            [id]="'delete' + unicalGuid"
            (onClick)="deletePayment()"
            [disabled]="readOnly || !focusedRowIndex3"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon icon--keyboard-arrow-right"
            [text]="'buttons.add' | translate"
            [id]="'add' + unicalGuid"
            (onClick)="addPayment()"
            [disabled]="readOnly || (!focusedRowIndex && !focusedRowIndex2)"
          >
          </dx-button>

          <dx-tooltip
            [target]="'#add' + unicalGuid"
            hideEvent="dxhoverend dxclick mouseleave"
            [hideOnOutsideClick]="false"
            position="bottom"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.add" | translate }} (Insert)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#delete' + unicalGuid"
            hideEvent="dxhoverend dxclick mouseleave"
            [hideOnOutsideClick]="false"
            position="top"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.delete" | translate }} (Del)
            </div>
          </dx-tooltip>
        </div>
        <div class="form-group-inline-right" style="width: 48%">
          <dx-tab-panel
            #tabPanel
            [items]="['documentsOrders.connectedPayments' | translate]"
            [loop]="false"
            [animationEnabled]="false"
            [swipeEnabled]="false"
          >
            <div *dxTemplate="let name of 'item'">
              <ng-container [ngSwitch]="name">
                <div
                  *ngSwitchCase="
                    'documentsOrders.connectedPayments' | translate
                  "
                >
                  <div class="d-flex justify-content-center flex-wrap">
                    <dx-data-grid
                      [dataSource]="paymentsDataSource"
                      [showBorders]="true"
                      [id]="'payments3' + unicalGuid"
                      [wordWrapEnabled]="false"
                      [remoteOperations]="true"
                      [(focusedRowKey)]="focusedRowIndex3"
                      (onFocusedRowChanged)="onFocusedRowChanged3($event)"
                      shortcut
                      [focusedRowEnabled]="true"
                      [allowColumnResizing]="true"
                      (onOptionChanged)="event.onOptionChanged($event)"
                    >
                      <dxo-selection
                        mode="none"
                        showCheckBoxesMode="always"
                      ></dxo-selection>
                      <dxo-scrolling
                        mode="infinite"
                        rowRenderingMode="virtual"
                      ></dxo-scrolling>
                      <dxo-paging [pageSize]="100"></dxo-paging>
                      <dxo-load-panel [enabled]="false"></dxo-load-panel>
                      <dxi-column
                        dataField="Number"
                        caption="{{ 'documentsOrders.paymentNo' | translate }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="Amount"
                        caption="{{
                          'form-financial-document.grid.amount' | translate
                        }}"
                        cellTemplate="numberPrecisionTemplate"
                      ></dxi-column>
                      <dxi-column
                        dataField="CurrencyCode"
                        caption="{{
                          'bankAccounts.grid.columns.currency' | translate
                        }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="InvoiceNumber"
                        caption="{{ 'documentsOrders.invoiceNo' | translate }}"
                      ></dxi-column>
                      <dxi-column
                        dataField="OrderNumber"
                        caption="{{
                          'warehouseDocument.orderNumber' | translate
                        }}"
                      ></dxi-column>

                      <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                        {{ cell.value | amountFormatter : cell }}
                      </div>
                    </dx-data-grid>
                  </div>
                </div>
              </ng-container>
            </div>
          </dx-tab-panel>
        </div>
      </div>

      <div
        class="count-container"
        style="bottom: 0; position: absolute; width: 100%"
      >
        <div class="row">
          <div class="col-6 text-left">
            <p class="p-header-info" style="top: 12px; position: relative">
              <strong>{{ "form-financial-document.count" | translate }}</strong>
            </p>
          </div>
          <div class="col-3 text-right r-border" style="color: black">
            <p class="p-toPay-net">
              {{ orderGrossValue | amountFormatter : null }}
            </p>
            <p class="p-toPayDesc">
              {{ "documentsOrders.orderGross" | translate }}
            </p>
          </div>
          <div class="col-3 text-right">
            <p class="p-toPay">
              {{ connectedPaymentsValue | amountFormatter : null }}
            </p>
            <p class="p-toPayDesc">
              {{ "form-financial-document.paymentM" | translate }}
            </p>
          </div>
        </div>
      </div>
      <wapro-keyboard-shortcuts
        [unicalGuid]="unicalGuid"
        [shortcuts]="shortcuts"
        [disabled]="!isVisible"
      >
      </wapro-keyboard-shortcuts>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-confirm-dialog
  *ngIf="isDialogVisible()"
  [confirmText]="'confirmPayment'"
  [isVisible]="true"
  (onRemoving)="onDialogConfirm()"
  (onClosing)="onDialogClosing()"
  kindDialog="question"
></app-confirm-dialog>

import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Subscription, firstValueFrom } from 'rxjs';
import { NestService } from 'src/app/nest-service.service';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { Socket } from 'src/app/socket.model';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';
import { HelpService } from '../../../help-service.service';
import { DefaultObject, NestOperation } from './../../../nest/nest.model';

interface ApiFilterParams {
  ProductId: number;
  CustomerId: number;
  DocumentId: number;
  documentDate: string;
  DocumentType: string;
  CurrencyCode: string;
  CurrencyFactor: number;
  IsBuy: boolean;
  IsTripartiteEu: boolean;
  IsOpposite: boolean;
  IsDocCurrencyDomestic: boolean;
  IsCurrencyDocument: boolean;
  WarehouseDocumentPositionDeliveryId?: number;
}

// Add after existing imports
interface Intrastat {
  isIntrastat?: boolean;
  CountryCode: string;
  DeliveryCountryCode: string;
  TransactionCode: string;
  CNCode: string;
  TransportCode: string;
  OrginCountryCode: string;
  MassNet: number;
  CustomerId: string;
  IsAgriculturalFund: boolean;
}

@Component({
  selector: 'app-position-invoice-document',
  templateUrl: './position-invoice-document.component.html',
  styleUrls: ['./position-invoice-document.component.scss'],
  inputs: [
    'isVisible',
    'selectedId',
    'readOnly',
    'title',
    'selected',
    'customerId',
    'documentId',
    'documentDate',
    'documentType',
    'typeOfCrossBorderSale',
    'editMode',
    'isCorrect',
    'componentNests',
    'WarehouseDocumentOriginalId',
    'Currency',
    'CurrencyFactor',
    'Signature',
    'IsCurrencyDocument',
    'isOnlyPriceEdit',
    'sumBy',
    'ForSet',
    'IsTripartiteEu',
    'IsDocCurrencyDomestic',
    'IsOpposite',
    'nip',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PositionInvoiceDocumentComponent implements OnInit, OnDestroy {
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocused') firstFocused;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  @ViewChild('gridBoxUnit') gridBoxUnit;
  ForSet;
  IsTripartiteEu;
  IsDocCurrencyDomestic;
  IsOpposite;
  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  customerId;
  CurrencyFactor;
  Currency;
  documentId;
  documentDate;
  IsCurrencyDocument;
  documentType;
  typeOfCrossBorderSale;
  editMode = false;
  isCorrect;
  componentNests: Socket;
  WarehouseDocumentOriginalId;
  shortcuts: ShortcutInput[] = [];
  Signature;
  isOnlyPriceEdit;
  sumBy;
  isPermissionToChangeDiscount: boolean = false;
  visibleUserExtensions: boolean = false;
  priceVisible: boolean = false;
  unicalGuid;
  selectedtab = 0;
  widthWindow = '730px';
  heightWindow = '630px';
  nip;
  positionForm: any = {
    ProductId: 0,
    Amount: 0,
    Unit: '',
    PriceNet: 0,
    PriceGross: 0,
    PriceNetCurrency: 0,
    PriceGrossCurrency: 0,
    Discount: 0,
    TaxCode: '',
    Description: '',
    PriceIdentificator: '',
    SerialNumber: '',
    ExpirationDate: '',
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
    PriceNetSale: null,
    PriceGrossSale: null,
    PriceNetWasteManagement: null,
    PriceGrossWasteManagement: null,
    PriceDescription: '',
    IsModifyPriceWhenDelivery: false,
    InvoiceDocumentId: 0,
    DocumentDate: '',
    WarehouseDateOfDocument: '',
    Factor: 0,
    DestinationWarehouseId: 0,
    SumBy: '',
    DeliveryId: 0,
    WarehouseDocumentPositionId: 0,
    DateOfApprovalForUse: null,
    Mileage: '',
    ExemptionCode: 0,
    ExemptionDescription: '',
  };
  isGridBoxOpened: boolean = false;
  Description = '';
  SerialNumber = '';
  ExpirationDate = null;
  field = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };
  Factor;
  articleToAdd = {
    ProductFullName: '',
    StockAvailable: '',
    IndexCatalogue: '',
  };
  AmountPrevious = null;
  isDefaultMarkupVisible: boolean = false;
  ArtykCenWyborPozDok: boolean = true;

  localStorageData = {
    skipUserGuide: false,
  };

  PriceDescription: string = '';

  items = [1, 2];
  isPriceZero: boolean = false;

  ArtykCenESDok: boolean = true;
  ArtykCenEZDok: boolean = true;

  buyPrice: number = 0;

  radioType = [
    { value: 0, label: 'Nie dotyczy' },
    { value: 1, label: 'Ustawa' },
    { value: 2, label: 'Dyrektywa' },
    { value: 3, label: 'Inny' },
  ];

  optionList = [
    { code: 'nie dotyczy', name: 'nie dotyczy' },
    { code: 'OSS', name: 'Procedura szczególna OSS' },
    { code: 'IOSS', name: 'Procedura szczególna IOSS' },
  ];

  TransakcjeOSS = { Value: 'Nie' };
  InteliTranOSS = { Value: 'Nie' };
  ModCenZakMarza = { Value: 'Tak' };

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    private nestService: NestService,
    private helpService: HelpService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('warehouseDocument.NotApplicable')
      .subscribe((text) => (this.radioType[0].label = text));
    this.translate
      .get('warehouseDocument.Act')
      .subscribe((text) => (this.radioType[1].label = text));
    this.translate
      .get('warehouseDocument.Directive')
      .subscribe((text) => (this.radioType[2].label = text));
    this.translate
      .get('warehouseDocument.Other')
      .subscribe((text) => (this.radioType[3].label = text));

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    this.ArtykCenESDok = this.event.checkPermissions('ArtykCenESDok');
    this.ArtykCenEZDok = this.event.checkPermissions('ArtykCenEZDok');

    this.ArtykCenWyborPozDok = this.event.checkPermissions(
      'ArtykCenWyborPozDok'
    );

    this.TransakcjeOSS = this.event.getConfigurationParameter(
      'TransakcjeOSS',
      true
    );
    this.InteliTranOSS = this.event.getConfigurationParameter(
      'InteliTranOSS',
      true
    );

    this.createForm();

    console.log('TransakcjeOSS', this.TransakcjeOSS);
    console.log('InteliTranOSS', this.InteliTranOSS);
  }

  ngOnDestroy() {
    this.isAutoOnboardingSub?.unsubscribe();
    this.event.onHiddenPopUp();
    this.Description = '';
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.isPermissionToChangeDiscount = this.event.checkPermissions(
      'DokHandlEdytRabatPoz'
    );

    const localData = localStorage.getItem('positionInvoiceDocumentComponent');
    if (localData) {
      this.localStorageData = JSON.parse(localData);
    }
  }

  onFocusOutPriceGrossWasteManagement() {
    const PriceNetWasteManagement = this.event.countAmountNet(
      this.positionForm.PriceGrossWasteManagement,
      this.form.TaxRate
    );

    this.positionForm.PriceNetWasteManagement = PriceNetWasteManagement;
    this.cd.detectChanges();
  }

  onFocusOutPriceNetWasteManagement() {
    const PriceGrossWasteManagement = this.event.countAmountGross(
      this.positionForm.PriceNetWasteManagement,
      this.form.TaxRate
    );

    this.positionForm.PriceGrossWasteManagement = PriceGrossWasteManagement;
    this.cd.detectChanges();
  }

  private readonly INCOME_DOCUMENT_TYPES = ['FZ', 'FR', 'FZUE', 'GVZ'];
  isIncomeDocument(signature: string): boolean {
    if (!signature) {
      return false;
    }

    const normalizedSignature = signature.replace(/ /g, '');
    return this.INCOME_DOCUMENT_TYPES.includes(normalizedSignature);
  }

  private updateArticleAndPrices(): void {
    const selectedItem = this.getSelectedItem();

    // Aktualizacja danych artykułu
    this.articleToAdd = {
      ProductFullName: selectedItem.ProductFullName,
      StockAvailable: selectedItem.StockAvailable,
      IndexCatalogue: selectedItem.IndexCatalogue,
    };

    // Aktualizacja cen
    this.positionForm = {
      ...this.positionForm,
      PriceNetSale: selectedItem.SellPriceNetDefault,
      PriceGrossSale: selectedItem.SellPriceGrossDefault,
      PriceNetWasteManagement: selectedItem.PriceNetKGO,
      PriceGrossWasteManagement: selectedItem.PriceGrossKGO,
    };
  }

  /**
   * Formatuje datę do formatu YYYY-MM-DD
   * @param date - Data do sformatowania (string | Date)
   * @param locale - Lokalizacja (domyślnie 'en-US')
   * @returns string - Sformatowana data lub pusty string w przypadku błędu
   */
  private formatDate(date: string | Date, locale: string = 'en-US'): string {
    try {
      if (!date) {
        return '';
      }

      const formattedDate = new DatePipe(locale).transform(date, 'yyyy-MM-dd');
      return formattedDate || '';
    } catch (error) {
      return '';
    }
  }

  /**
   * Zwraca ProductId na podstawie trybu edycji i wybranej pozycji
   * @returns number - ID produktu lub 0 w przypadku braku
   */
  private getProductId(): number {
    try {
      if (!this.selected) {
        return 0;
      }

      // Jeśli jesteśmy w trybie edycji, pobierz z pierwszego elementu tablicy
      if (this.editMode) {
        return this.selected[0]?.ProductId ?? 0;
      }

      // W trybie dodawania, sprawdź czy selected jest numerem czy obiektem
      return typeof this.selected === 'number'
        ? this.selected
        : this.selected?.ProductId ?? 0;
    } catch (error) {
      return 0;
    }
  }

  /**
   * Przygotowuje parametry filtra
   * @returns ApiFilterParams - Obiekt z parametrami
   */
  private prepareFilterParams(DocumentSignature?: string): ApiFilterParams {
    const selectedItem = this.editMode ? this.selected[0] : this.selected;

    const params: ApiFilterParams = {
      ProductId: this.getProductId(),
      CustomerId: this.customerId,
      DocumentId: this.documentId,
      documentDate: this.formatDate(this.documentDate),
      DocumentType: DocumentSignature || this.documentType?.Signature || '',
      CurrencyCode: this.Currency || '',
      CurrencyFactor: this.CurrencyFactor || 0,
      IsBuy: !!this.documentType?.IsIncome,
      IsTripartiteEu: !!this.IsTripartiteEu,
      IsOpposite: !!this.IsOpposite,
      IsDocCurrencyDomestic: !!this.IsDocCurrencyDomestic,
      IsCurrencyDocument: !!this.IsCurrencyDocument,
    };

    if (selectedItem?.WarehouseDocumentPositionDeliveryId != null) {
      params.WarehouseDocumentPositionDeliveryId =
        selectedItem.WarehouseDocumentPositionDeliveryId;
    }

    return params;
  }

  /**
   * Buduje string filtra dla zapytania API
   * @returns string - Przygotowany string filtra
   */
  private buildApiFilter(DocumentSignature?: string): string {
    try {
      const params = this.prepareFilterParams(DocumentSignature);
      return new URLSearchParams(
        Object.entries(params)
          .filter(([_, value]) => value !== undefined)
          .map(([key, value]) => [key, value.toString()])
      ).toString();
    } catch (error) {
      this.event.showNotification('error', 'Błąd podczas tworzenia filtra');
      return '';
    }
  }

  /**
   * Aktualizuje ceny w walucie
   */
  private updateCurrencyPrices(): void {
    if (this.IsCurrencyDocument) {
      const { PriceDetail } = this.form;

      PriceDetail.PriceNet = PriceDetail.PriceNetCurrency;
      PriceDetail.PriceNetBase = PriceDetail.PriceNetCurrency;
      PriceDetail.AmountNet = PriceDetail.PriceNetCurrency;
      PriceDetail.AmountGross = PriceDetail.PriceGrossCurrency;
      PriceDetail.PriceGross = PriceDetail.PriceGrossCurrency;
      PriceDetail.PriceGrossBase = PriceDetail.PriceGrossCurrency;
    }
    this.cd.detectChanges();
  }

  /**
   * Zwraca aktualnie wybraną pozycję z uwzględnieniem trybu edycji
   * @private
   */
  private getSelectedItem(): any {
    return this.editMode ? this.selected[0] : this.selected;
  }

  /**
   * Aktualizuje formularz danymi z odpowiedzi API
   * @param res - Odpowiedź z API
   */
  private updateFormWithResponse(res: any): void {
    try {
      const selectedItem = this.getSelectedItem();
      this.form = res;
      this.IsDivisible = res.PriceDetail.IsDivisible;
      this.PriceDescription = res.PriceDetail.PriceDescription;
      this.Factor = res.PriceDetail.Factor;
      this.form.Excise = false;
      this.form.IsSplitPayment = false;

      //ustawia classDescription
      if (this.documentType.Signature.includes('FR')) {
        this.form.PackageNumber = 'Klasa I';
      } else {
        this.form.PackageNumber = '';
      }

      //margin
      if (
        this.documentType.Signature.trim() == 'FM' ||
        this.documentType.Signature.trim() == 'PM'
      ) {
        if (selectedItem.WarehouseDocumentPositionDeliveryId == null) {
          this.buyPrice = selectedItem.BuyPriceGross;
        } else {
          this.buyPrice = selectedItem.DocPositionIdGrossPrice;
        }
        this.form.Margin = this.form.PriceDetail.PriceGross - this.buyPrice;
      }
      //-----------------

      this.updateCurrencyPrices();

      selectedItem.EnteredAmount == 0
        ? (this.form.Quantity = 1)
        : (this.form.Quantity = selectedItem.EnteredAmount);
      //this.form.Quantity = 1;
      this.form.QuantityWarehouse = 1;

      this.form.PriceDetail.AmountNet = this.form.PriceDetail.PriceNet;
      this.form.PriceDetail.PriceWithDiscount = this.form.PriceDetail.PriceNet;
      this.form.PriceDetail.AmountGross =
        this.form.PriceDetail.Factor * this.form.PriceDetail.PriceGrossBase;
      this.form.PriceDetail.DiscountOryginal = res.PriceDetail.Discount;
      if (this.form.PriceDetail.Discount != 0) {
        this.form.PriceDetail.Discount = this.form.PriceDetail.Discount / 100;
        this.form.PriceDetail.PriceWithDiscount =
          this.form.PriceDetail.PriceWithDiscount *
          (this.form.PriceDetail.Discount + 1);
      }

      if (this.form.UnitId == 0 || this.form.UnitId == null)
        this.form.UnitId = this.form.BaseUnitId;

      if (this.form.UnitCode == null)
        this.form.UnitCode = this.form.BaseUnitCode;

      this.articleToAdd.ProductFullName = res.ProductFullName;
      this.articleToAdd.StockAvailable = res.StockAvailable;
      this.articleToAdd.IndexCatalogue = res.IndexCatalogue;

      this.form.DateOfApprovalForUse = null;
      this.form.Mileage = '';
      this.form.ExemptionCode = 0;
      this.form.ExemptionDescription = '';
      this.IsDivisibleBase = this.form.Units.find(
        (field) => field.Code == this.form.BaseUnitCode
      )?.IsDivisible;
      setTimeout(() => {
        if (Number.isNaN(this.form.Quantity)) {
          this.form.Quantity;
          this.form.PriceDetail.Factor = this.Factor;
          this.cd.detectChanges();
        }

        if (isNaN(this.form.PriceDetail.Quantity)) {
          if (this.editMode) {
            1;
          } else {
            this.form.Quantity = this.form.Quantity =
              selectedItem.EnteredAmount == 0 ? 1 : selectedItem.EnteredAmount;
          }
          this.cd.detectChanges();
        }
      }, 500);
    } catch (error) {
      this.event.showNotification(
        'error',
        'Błąd podczas aktualizacji formularza'
      );
    }
  }

  private updateFormWithEdit(res: any) {
    try {
      const selectedItem = this.getSelectedItem();

      // Aktualizacja danych Intrastat
      const intrastatUpdate = {
        CountryCode: selectedItem.CountryCode,
        DeliveryCountryCode: selectedItem.DeliveryCode,
        TransactionCode: selectedItem.TransactionCode,
        CNCode: selectedItem.CnCode,
        TransportCode: selectedItem.TransportCode,
        OrginCountryCode: selectedItem.CountryOrginCode,
        MassNet: selectedItem.NetWeight,
        CustomerId: selectedItem.IntrastaCustomerId,
        IsAgriculturalFund: selectedItem.IsAgriculturalFund,
      };

      const staticData = this.event.decryptString(
        localStorage.getItem('staticData')
      );

      const unitId = res.Units.find((field) => field.Code == selectedItem.Unit);

      this.Factor = unitId.Factor;

      // Aktualizacja podstawowych danych formularza
      const formUpdate = {
        UnitId: unitId.UnitId,
        UnitCode: unitId.Code,
        Quantity: selectedItem.Amount,
        typeOfCrossBorderSale: selectedItem.TypeOfCrossBorderSale,
        Excise: selectedItem.Excise,
        IsSplitPayment: selectedItem.IsSplitPayment,
        PackageNumber: selectedItem.PackageNumber,
        DateOfApprovalForUse: selectedItem.DateOfApprovalForUse,
        Mileage: selectedItem.Mileage,
        ExemptionCode: selectedItem.ExemptionCode,
        ExemptionDescription: selectedItem.ExemptionDescription,
        TaxCode: selectedItem.TaxCode,
        TaxRate: staticData.taxRates.find(
          (field) => field.TaxCode == this.form.TaxCode
        ).TaxRate,
      };

      // Aktualizacja szczegółów cen
      const priceUpdate = {
        Factor: unitId.Factor * selectedItem.Amount,
        Discount: selectedItem.Discount / 100,
        PriceName: '',
        PriceNetBase: this.IsCurrencyDocument
          ? selectedItem.PriceNetCurrency / this.Factor
          : selectedItem.PriceNet / this.Factor,
        PriceGrossBase: this.IsCurrencyDocument
          ? selectedItem.PriceGrossCurrency / this.Factor
          : selectedItem.PriceGross / this.Factor,
      };

      this.form = {
        ...this.form,
        ...formUpdate,
        PriceDetail: {
          ...this.form.PriceDetail,
          ...priceUpdate,
        },
      };

      this.Description = selectedItem.Description;
      this.intrastat = intrastatUpdate;
      this.nip = selectedItem.IntrastaCustomerId;
      this.PriceDescription = selectedItem.PriceDescription;

      this.IsDivisibleBase = this.form.Units.find(
        (field) => field.Code == this.form.BaseUnitCode
      )?.IsDivisible;

      setTimeout(() => {
        if (this.sumBy.trim() == 'Brutto') {
          this.form.PriceDetail.PriceGross = !this.IsCurrencyDocument
            ? selectedItem.PriceGross
            : selectedItem.PriceGross / this.CurrencyFactor;
          this.form.PriceDetail.PriceNet = !this.IsCurrencyDocument
            ? selectedItem.PriceNet
            : selectedItem.PriceNet / this.CurrencyFactor;
        }

        if (this.sumBy.trim() == 'Netto') {
          this.form.PriceDetail.PriceNet = !this.IsCurrencyDocument
            ? selectedItem.PriceNet
            : selectedItem.PriceNet / this.CurrencyFactor;
        }

        this.afterOpen();
        this.changeAmount();
        this.cd.detectChanges();
      }, 0);

      const AdditionalFiles = {
        Field1: selectedItem.Field1,
        Field2: selectedItem.Field2,
        Field3: selectedItem.Field3,
        Field4: selectedItem.Field4,
        Field5: selectedItem.Field5,
        Field6: selectedItem.Field6,
        Field7: selectedItem.Field7,
        Field8: selectedItem.Field8,
        Field9: selectedItem.Field9,
        Field10: selectedItem.Field10,
      };

      this.positionForm = {
        ...this.positionForm,
        ...AdditionalFiles,
      };

      //margin
      if (
        this.documentType.Signature.trim() == 'FM' ||
        this.documentType.Signature.trim() == 'PM'
      ) {
        this.buyPrice = selectedItem.PriceGrossBuyMargin;
      }
    } catch (error) {
      this.event.showNotification(
        'error',
        'Błąd podczas aktualizacji formularza'
      );
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isVisible && changes.isVisible.currentValue) {
      //sprawdzanie parametru ModCenZakMarza
      if (
        this.documentType.Signature.trim() == 'FM' ||
        this.documentType.Signature.trim() == 'PM'
      ) {
        this.ModCenZakMarza = this.event.getConfigurationParameter(
          'ModCenZakMarza',
          true
        );
      }

      if (this.isVisible && this.selected && !this.isCorrect) {
        this.documentType.IsIncome = this.isIncomeDocument(
          this.documentType.Signature.replace(/ /g, '')
        );
        this.updateArticleAndPrices();
        this.setUserExtensions();
        const filter = this.buildApiFilter();

        // Wywołanie API
        const response = await firstValueFrom(
          this.appService.getAuth(
            `products/${this.getProductId()}/productDetailsForCustomer/${
              this.customerId
            }?${filter}`
          )
        );
        this.updateFormWithResponse(response);

        if (this.editMode) {
          this.updateCurrencyPrices();
          this.updateFormWithEdit(response);
        }
      }

      if (this.isVisible && this.selected && this.editMode && this.isCorrect) {
        this.documentType.IsIncome = this.isIncomeDocument(
          this.documentType.Signature.replace(/ /g, '')
        );

        const res = await this.appService
          .getAuth(
            `invoices/documents?InvoiceDocumentId=${this.WarehouseDocumentOriginalId}`
          )
          .toPromise();

        let IsIncome = false;
        let list = this.event.decryptString(
          localStorage.getItem('typesInvoice')
        );

        let Signature = list.find(
          (field) => field.TypeId == res.data[0].DocumentTypeId
        );

        let DocumentSignature = list
          .find((field) => field.TypeId == Signature.TypeId)
          .Signature.replace('k', '');
        IsIncome =
          Signature.DocumentType == 's' || Signature.DocumentType == 'ks'
            ? false
            : true;
        this.documentType.IsIncome = IsIncome;

        if (!this.documentType.IsIncome)
          this.AmountPrevious = this.selected[0].AmountPrevious;

        const filter = this.buildApiFilter(DocumentSignature);

        this.updateArticleAndPrices();
        // Wywołanie API
        const response = await firstValueFrom(
          this.appService.getAuth(
            `products/${this.getProductId()}/productDetailsForCustomer/${
              this.customerId
            }?${filter}`
          )
        );
        this.updateFormWithResponse(response);
        this.updateCurrencyPrices();
        this.updateFormWithEdit(response);

        this.cd.detectChanges();
      }

      if (changes?.isVisible?.currentValue) {
        if (!this.editMode) {
          this.findSocketAndRun('XGHPDO1');
        } else {
          this.findSocketAndRun('XGHPPO1');
        }
      }
    }
  }

  getPriceBuy(id) {
    this.appService
      .getAuth(`products?ObjectId=${id}&Skip=0&Take=1`)
      .subscribe((res) => {
        this.buyPrice = res.data[0].BuyPriceGross;
      });
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  timerAmount = 200;
  myTimerAmount;
  onValueGrossAmountGross(e) {
    if (this.form.PriceDetail.PriceNetBase == 0) return;

    if (this.form.PriceDetail.PriceNetBase != 0) {
      clearTimeout(this.myTimerAmount); // Usuwa poprzedni timer

      this.myTimerAmount = setTimeout(() => {
        if (e.event && this.form.PriceDetail.PriceNetBase == 0) {
          this.event.showNotification(
            'error',
            this.translate.instant('servicesDevices.enterItemPrice')
          );
          return;
        }
        let quantity = Math.round(
          this.form.PriceDetail.AmountGross / this.form.PriceDetail.PriceGross
        );
        this.form.Quantity = quantity;
        this.cd.detectChanges();
      }, this.timerAmount);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.refreshKeyPressed.next();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/dokumenty/handlowe/formatka'
          );
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.beforeCloseWindow();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.checkOstrzegajMinMax();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 0;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtab = 1;
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  onCnCodeChoosed = () => {};

  form;
  createForm = () => {
    // todo: dodać typ FormGroup
    this.form = {
      Quantity: 1,
      ProductFullName: '',
      IndexCatalogue: '',
      IndexTrading: '',
      TaxCode: '',
      StockAvailable: 0,
      DefaultPriceId: 0,
      UnitId: 0,
      PriceDetail: {
        PriceNetBase: 0,
        PriceGrossBase: 0,
        PriceWithDiscount: 0,
        AmountNet: 0,
        AmountGross: 0,
        Unit: '',
        PriceName: '',
        IsDivisible: false,
        PriceCurrency: '',
        PriceDescription: '',
        PriceIdentificator: '',
        PriceParentObjectId: 0,
        PriceObjectType: '',
        PriceId: 0,
        PriceNet: 0,
        PriceGross: 0,
        CurrencyCode: null,
        Factor: 0,
        Discount: 0,
      },
      Units: [],
      TypesOfCrossBorderSale: [''],
      DateOfApprovalForUse: null,
      Mileage: [''],
      ExemptionCode: [0],
      ExemptionDescription: [''],
      IsSplitPayment: false,
      Excise: false,
    };
  };

  setUserExtensions = () => {
    const selectedItem = this.getSelectedItem();

    this.field.Field1 = selectedItem.Field1;
    this.field.Field2 = selectedItem.Field2;
    this.field.Field3 = selectedItem.Field3;
    this.field.Field4 = selectedItem.Field4;
    this.field.Field5 = selectedItem.Field5;
    this.field.Field6 = selectedItem.Field6;
    this.field.Field7 = selectedItem.Field7;
    this.field.Field8 = selectedItem.Field8;
    this.field.Field9 = selectedItem.Field9;
    this.field.Field10 = selectedItem.Field10;
  };

  beforeCloseWindow() {
    if (!this.editMode) {
      this.findSocketAndRun('XGHPDA1');
    } else {
      this.findSocketAndRun('XGHPPA1');
    }
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
    }
    this.createForm(); //reset form with initial value
    this.positionForm.Field1 = '';
    this.positionForm.Field2 = '';
    this.positionForm.Field3 = '';
    this.positionForm.Field4 = '';
    this.positionForm.Field5 = '';
    this.positionForm.Field6 = '';
    this.positionForm.Field7 = '';
    this.positionForm.Field8 = '';
    this.positionForm.Field9 = '';
    this.positionForm.Field10 = '';
    this.selectedtab = 0;
    this.cd.detectChanges();
  };

  focusedUnit = null;
  onFocusedRowChanged = (e) => {
    this.focusedUnit = e.row.data;
  };

  onChoosedDescription = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });
    }
  };

  timerWarehouse = 100;
  myTimerWarehouse;
  isFirstLoadWarehouse: boolean = true;
  onValueWarhaouseAmount = () => {
    clearTimeout(this.myTimerWarehouse);
    this.myTimerWarehouse = setTimeout(() => {
      this.form.PriceDetail.PriceNet =
        this.form.PriceDetail.PriceNetBase * this.Factor;

      this.form.PriceDetail.PriceGross = Number(
        this.event.countAmountGross(
          this.form.PriceDetail.PriceNet,
          this.form.TaxRate
        )
      );
      this.changeAmount();

      if (this.timerWarehouse == 0) this.isFirstLoadWarehouse = false;
    }, this.timerWarehouse);
  };

  onValueChangedQuantity = () => {
    try {
      let Quantity = this.form.Units.find(
        (field) => field.UnitId == this.form.UnitId
      );
      this.form.PriceDetail.Factor = this.form.Quantity * Quantity.Factor;
    } catch {}

    this.changeAmount();

    if (!this.editMode) {
      this.findSocketAndRun('XGHPDILZ2');
    } else {
      this.findSocketAndRun('XGHPPILZ2');
    }
    if (this.isCorrect) return;
  };

  onRowDblClick = (e) => {
    this.form.UnitId = e.data.UnitId;
    this.form.UnitCode = e.data.Code;
    this.form.PriceDetail.Factor = e.key.Factor * this.form.Quantity;

    this.form.PriceDetail.PriceNet =
      (this.form.PriceDetail.PriceNetBase * this.form.PriceDetail.Factor) /
      this.form.Quantity;

    this.isGridBoxOpened = false;
    this.Factor = e.data.Factor;
    this.changeAmount();
  };

  onValueChangedQuantityBase = () => {
    try {
      let Quantity = this.form.Units.find(
        (field) => field.UnitId == this.form.UnitId
      );
      this.form.Quantity = this.form.PriceDetail.Factor / Quantity.Factor;
    } catch {}

    this.changeAmount();
  };

  protected changeAmount(): void {
    // Unikamy niepotrzebnych obliczeń jeśli form nie istnieje
    if (!this.form?.PriceDetail) {
      return;
    }

    // Destrukturyzacja dla lepszej czytelności i wydajności
    const { PriceDetail, TaxRate, Quantity } = this.form;

    const {
      PriceNet,
      PriceGross,
      PriceNetBase,
      PriceGrossBase,
      Factor,
      Discount,
    } = PriceDetail;

    // Formatowanie waluty tylko gdy potrzebne
    const shouldFormatCurrency =
      this.documentType.IsIncome && this.IsCurrencyDocument;
    if (!shouldFormatCurrency) {
      // Używamy map do zoptymalizowania operacji na liczbach
      const valuesToFormat = [
        PriceNet,
        PriceGross,
        PriceNetBase,
        PriceGrossBase,
      ];
      const formattedValues = valuesToFormat.map((value) =>
        Number(value.toFixed(2))
      );

      [
        PriceDetail.PriceNet,
        PriceDetail.PriceGross,
        PriceDetail.PriceNetBase,
        PriceDetail.PriceGrossBase,
      ] = formattedValues;
    }

    // Obliczenia cen w zależności od typu sumowania
    try {
      const sumByNetto = this.sumBy.replace(/ /g, '') === 'Netto';
      const sumByBrutto = this.sumBy.replace(/ /g, '') === 'Brutto';

      if (sumByNetto) {
        PriceDetail.PriceGross = this.event.countAmountGross(PriceNet, TaxRate);
      }

      if (sumByBrutto) {
        this.form.PriceDetail.PriceGross =
          this.form.PriceDetail.PriceGrossBase * this.Factor;
        this.form.PriceDetail.PriceNet = this.event.countAmountNet(
          this.form.PriceDetail.PriceGross,
          this.form.TaxRate
        );
      }
    } catch (error) {
      console.warn('Error calculating prices:', error);
    }

    // Obliczanie kwot z uwzględnieniem rabatu
    const discountMultiplier = 1 + Discount;
    const hasValidFactor = Factor !== 0;

    PriceDetail.AmountNet = hasValidFactor
      ? PriceNet * Quantity * discountMultiplier
      : 0;

    PriceDetail.AmountGross = hasValidFactor
      ? PriceGross * Quantity * discountMultiplier
      : 0;

    PriceDetail.PriceWithDiscount = PriceNet * discountMultiplier;

    // Obliczanie marży dla określonych typów dokumentów
    const isMarginDocument = ['FM', 'PM'].includes(
      this.documentType.Signature.trim()
    );
    if (isMarginDocument && !isNaN(PriceDetail.PriceGross)) {
      this.form.Margin =
        (PriceDetail.AmountGross / Quantity - this.buyPrice) * Quantity;
    }

    // Wywołanie detekcji zmian tylko raz na końcu
    this.cd.detectChanges();
  }

  timerNetto = 200;
  private readonly DEFAULT_DEBOUNCE_TIME = 200; // Stała dla debounce timera
  myTimerNetto;
  onValueNetChange = (): void => {
    // Anuluj poprzedni timer
    if (this.myTimerNetto) {
      clearTimeout(this.myTimerNetto);
    }

    // Debounce dla zmian wartości
    this.myTimerNetto = setTimeout(() => {
      // Zabezpieczenie przed undefined
      if (!this.form?.PriceDetail) {
        return;
      }

      // Destrukturyzacja dla lepszej czytelności i wydajności
      const { BaseUnitId, UnitId, PriceDetail, TaxRate } = this.form;

      const isBaseUnit = BaseUnitId === UnitId;
      const factor = PriceDetail.Factor || 1; // Zabezpieczenie przed dzieleniem przez 0

      try {
        // Aktualizacja ceny netto bazowej
        PriceDetail.PriceNetBase = isBaseUnit
          ? PriceDetail.PriceNet
          : PriceDetail.PriceNet / factor;

        // Obliczenie ceny brutto
        PriceDetail.PriceGross = Number(
          this.event.countAmountGross(PriceDetail.PriceNet, TaxRate)
        );

        // Aktualizacja ceny brutto bazowej
        PriceDetail.PriceGrossBase = isBaseUnit
          ? PriceDetail.PriceGross
          : PriceDetail.PriceGrossBase / factor;

        // Aktualizacja pozostałych kalkulacji
        this.changeAmount();
      } catch (error) {
        this.event.showNotification('error', 'Błąd podczas przeliczania cen');
      }
    }, this.timerNetto || this.DEFAULT_DEBOUNCE_TIME);
  };

  changeDescription() {
    this.PriceDescription = this.translate.instant(
      'form-commercial-operation.modifiedByHand'
    );
  }

  timer = 200;
  private readonly DEFAULT_TIMER = 200;
  myTimer;
  isFirstLoad: boolean = true;
  onValueGrossChange = (): void => {
    // Anuluj poprzedni timer
    if (this.myTimer) {
      clearTimeout(this.myTimer);
    }

    this.myTimer = setTimeout(() => {
      // Zabezpieczenie przed undefined
      if (!this.form?.PriceDetail) {
        return;
      }

      // Destrukturyzacja dla lepszej czytelności i wydajności
      const { BaseUnitId, UnitId, PriceDetail, TaxRate } = this.form;

      const {
        PriceGross,
        Factor = 1, // Domyślna wartość zabezpieczająca
      } = PriceDetail;

      try {
        // Obliczenie ceny netto
        PriceDetail.PriceNet = this.event.countAmountNet(PriceGross, TaxRate);

        // Sprawdzenie czy jednostka jest bazowa
        const isBaseUnit = BaseUnitId === UnitId;

        // Aktualizacja cen bazowych
        PriceDetail.PriceNetBase = isBaseUnit
          ? PriceDetail.PriceNet
          : PriceDetail.PriceNet / Factor;

        PriceDetail.PriceGrossBase = isBaseUnit
          ? PriceGross
          : PriceDetail.PriceGrossBase / Factor;

        // Aktualizacja pozostałych kalkulacji
        this.changeAmount();
      } catch (error) {
        this.event.showNotification('error', 'Błąd podczas przeliczania cen');
      }
    }, this.timer || this.DEFAULT_TIMER);
  };

  IsDivisible: boolean = false;
  formatDivisible = '#0.0000';
  formatNotDivisible = '#0';
  IsDivisibleBase: boolean = false;
  onUnitChoosed = (e) => {
    if (e.previousValue != 0) {
      this.IsDivisible = this.form.Units.find(
        (field) => field.UnitId == this.form.UnitId
      )?.IsDivisible;

      this.IsDivisibleBase = this.form.Units.find(
        (field) => field.Code == this.form.BaseUnitCode
      )?.IsDivisible;

      this.form.PriceDetail.PriceGross = Number(
        this.event.countAmountGross(
          this.form.PriceDetail.PriceNet * this.form.PriceDetail.Factor,
          this.form.TaxRate
        )
      );
    }
  };

  onBuyTaxChanged = (e) => {
    this.form.TaxCode = e[0].TaxCode;
    this.form.TaxRate = e[0].TaxRate;

    if (this.form?.TaxCode == 'ZW') this.items = [1, 2, 3];
    else this.items = [1, 2];

    if (this.IsCurrencyDocument) this.items = [1, 2, 4];
    else if (this.form?.TaxCode == 'ZW' && !this.IsCurrencyDocument)
      this.items = [1, 2, 3];
    else if (this.form?.TaxCode == 'ZW' && this.IsCurrencyDocument)
      this.items = [1, 2, 3, 4];
    else this.items = [1, 2];

    this.changeAmount();
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  onInsertedUserExtensions = (e) => {
    this.field.Field1 = e['Field1'];
    this.field.Field2 = e['Field2'];
    this.field.Field3 = e['Field3'];
    this.field.Field4 = e['Field4'];
    this.field.Field5 = e['Field5'];
    this.field.Field6 = e['Field6'];
    this.field.Field7 = e['Field7'];
    this.field.Field8 = e['Field8'];
    this.field.Field9 = e['Field9'];
    this.field.Field10 = e['Field10'];
  };

  @ViewChild('gridClass') gridClass;
  chooseUnit = () => {
    if (this.focusedUnit != null) {
      this.Factor = this.focusedUnit.Factor;
      this.form.UnitId = this.focusedUnit.UnitId;
      this.form.UnitCode = this.focusedUnit.Code;
      this.form.PriceDetail.Factor =
        this.focusedUnit.Factor * this.form.Quantity;
      this.isGridBoxOpened = false;
      this.form.PriceDetail.PriceNet =
        (this.form.PriceDetail.PriceNetBase * this.form.PriceDetail.Factor) /
        this.form.Quantity;
      this.changeAmount();
    }
  };

  chooseUnitAndQuantity = () => {
    if (this.focusedUnit != null) {
      this.Factor = this.focusedUnit.Factor;
      this.form.UnitId = this.focusedUnit.UnitId;
      this.form.UnitCode = this.focusedUnit.Code;

      this.form.Quantity =
        this.form.PriceDetail.Factor / this.focusedUnit.Factor;
      this.form.PriceDetail.PriceNet =
        (this.form.PriceDetail.PriceNetBase * this.form.PriceDetail.Factor) /
        this.form.Quantity;
      this.isGridBoxOpened = false;
      this.changeAmount();
    }
  };

  intrastat: Intrastat = {
    isIntrastat: true,
    CountryCode: 'PL',
    DeliveryCountryCode: '',
    TransactionCode: '',
    CNCode: '',
    TransportCode: '',
    OrginCountryCode: '',
    MassNet: 0,
    CustomerId: '',
    IsAgriculturalFund: false,
  };

  resetIntrastat() {
    this.intrastat = {
      isIntrastat: true,
      CountryCode: '',
      DeliveryCountryCode: '',
      TransactionCode: '',
      CNCode: '',
      TransportCode: '',
      OrginCountryCode: '',
      MassNet: 0,
      CustomerId: '',
      IsAgriculturalFund: false,
    };
  }

  onValueChangeIsIntratstat = () => {
    if (!this.intrastat.isIntrastat) {
      this.intrastat = {
        isIntrastat: false,
        CountryCode: '',
        DeliveryCountryCode: '',
        TransactionCode: '',
        CNCode: '',
        TransportCode: '',
        OrginCountryCode: '',
        MassNet: 0,
        CustomerId: '',
        IsAgriculturalFund: false,
      };
    }
  };
  onChoosedCustomer = (e) => {
    if (e) {
      this.intrastat.CustomerId = e.VatNumber;
    } else if (e === null) {
      this.intrastat.CustomerId = '';
    }
  };

  selectedTransportCodeChange = (e) => {
    if (e) {
      this.intrastat.TransportCode = e[0].TransportCode;
    } else if (e === null) {
      this.intrastat.TransportCode = '';
    }
  };

  selectedCountryOrginChange = (e) => {
    if (e) {
      this.intrastat.OrginCountryCode = e[0].CountryCode;
    } else if (e === null) {
      this.intrastat.OrginCountryCode = '';
    }
  };

  selectedCountryChange(e) {
    if (e) {
      this.intrastat.CountryCode = e[0].CountryCode;
    } else if (e === null) {
      this.intrastat.CountryCode = '';
    }
  }

  selectedDeliveryCodeChange(e) {
    if (e) {
      this.intrastat.DeliveryCountryCode = e[0].DeliveryCode;
    } else if (e === null) {
      this.intrastat.DeliveryCountryCode = '';
    }
  }

  selectedTransactionCodeChange = (e) => {
    if (e) {
      this.intrastat.TransactionCode = e[0].TransactionCode;
    } else if (e === null) {
      this.intrastat.TransactionCode = '';
    }
  };

  onCnCodeChoosedd = (e) => {
    if (e) {
      this.intrastat.CNCode = e[0].CnCode;
    } else if (e === null) {
      this.intrastat.CNCode = '';
    }
  };

  afterOpen() {
    setTimeout(() => {
      if (this.form?.TaxCode.trim() == 'ZW') this.items = [1, 2, 3];
      else this.items = [1, 2];
      if (this.IsCurrencyDocument) this.items = [1, 2];
      else if (this.form?.TaxCode.trim() == 'ZW' && !this.IsCurrencyDocument)
        this.items = [1, 2, 3];
      else if (this.form?.TaxCode.trim() == 'ZW' && this.IsCurrencyDocument)
        this.items = [1, 2, 3];
      else this.items = [1, 2];
      if (
        this.documentType.Signature.trim() == 'FUE' ||
        this.documentType.Signature.trim() == 'FW' ||
        this.documentType.Signature.trim() == 'FZUE'
      ) {
        this.items.push(5);
        if (!this.editMode) {
          this.intrastat.CNCode = this.selected.CnCode;
          this.intrastat.OrginCountryCode = this.selected?.IncomeCountryCode;

          if (this.selected.WeightUnit === 'kg') {
            this.intrastat.MassNet = this.selected.Weight;
          }

          if (this.selected.WeightUnit === 'g') {
            this.intrastat.MassNet = 0.001 * this.selected.Weight;
          }

          if (this.selected.WeightUnit === 'dkg') {
            this.intrastat.MassNet = 0.01 * this.selected.Weight;
          }

          if (this.selected.WeightUnit === 't') {
            this.intrastat.MassNet = 1000 * this.selected.Weight;
          }
        }
      }
      //zakladka 4 akcyza
      if (
        this.IsCurrencyDocument &&
        !this.IsDocCurrencyDomestic &&
        this.documentType.Signature.trim() != 'FUE' &&
        this.documentType.Signature.trim() != 'FZUE'
      ) {
        this.items.push(4);
        this.intrastat.CNCode = this.selected.CnCode;
      }

      //zakladka 4 akcyza
      if (this.documentType.Signature.trim() == 'FR') {
        this.items.push(4);
        this.intrastat.IsAgriculturalFund = this.selected.IsAgriculturalFund;

        if (this.selected && this.editMode) {
          this.intrastat.IsAgriculturalFund =
            this.selected[0].IsAgriculturalFund;
        }
      }

      if (this.selected && this.selected.PCIsActive && !this.editMode) {
        this.intrastat.CNCode = this.selected.CnCode;
        let isExist = this.items.findIndex((field) => field == 4);
        if (isExist == -1) this.items.push(4);
      }

      if (this.selected && this.editMode && this.selected[0].PCIsActive) {
        this.intrastat.CNCode = this.selected[0].CnCode;
        let isExist = this.items.findIndex((field) => field == 4);
        if (isExist == -1) this.items.push(4);
      }

      this.cd.detectChanges();
    }, 0);

    setTimeout(() => {
      this.event.setFocusWithSelect(this.firstFocused);
      this.cd.detectChanges();
    }, 700);
  }

  onChoosedPrice = (e) => {
    if (e.length > 0) {
      if (this.IsCurrencyDocument) {
        this.form.PriceDetail.PriceNet = e[0].NetPrice / this.CurrencyFactor;

        this.form.PriceDetail.PriceNetBase =
          this.form.PriceDetail.PriceNetCurrency;

        this.form.PriceDetail.AmountNet =
          this.form.PriceDetail.PriceNetCurrency;

        this.form.PriceDetail.PriceGrossCurrency =
          e[0].GrossPrice / this.CurrencyFactor;

        this.form.PriceDetail.AmountGross =
          this.form.PriceDetail.PriceGrossCurrency;

        this.form.PriceDetail.PriceGross =
          this.form.PriceDetail.PriceGrossCurrency;

        this.form.PriceDetail.PriceGrossBase =
          this.form.PriceDetail.PriceGrossCurrency;
      } else {
        this.form.PriceDetail.PriceNetBase = e[0].NetPrice;
        this.form.PriceDetail.PriceGrossBase = e[0].GrossPrice;
        this.form.PriceDetail.PriceNet =
          this.form.PriceDetail.PriceNetBase * this.Factor;
        this.form.PriceDetail.PriceGross =
          this.form.PriceDetail.PriceGross * this.Factor;

        //this.form.PriceDetail.PriceIdentificator = e[0].PriceId;
        this.form.PriceDetail.PriceName = e[0].PriceName;
        // this.Factor = e[0].Factor;
      }

      this.form.PriceDetail.PriceId = e[0].PriceId;
      this.PriceDescription = e[0].Description;
      this.form.PriceDetail.Discount = e[0].DiscountMarkUp / 100;
      this.form.PriceDetail.PriceWithDiscount =
        this.form.PriceDetail.PriceNet * (1 + this.form.PriceDetail.Discount);
      this.changeAmount();
    }
  };

  onDefaultMarkupChoosed(e) {
    this.form.PriceDetail.Discount = e.discountMarkupPercent / 100;
    this.form.PriceDetail.DiscountOryginal = e.discountMarkupPercent;
    // this.discountValue = e.discountMarkupPercent
    // this.setDiscountMarkupConfig()
  }

  // setDiscountMarkupConfig(){
  //   this.radioBoxList[0].items[0].label = this.radioBoxTranslations.changeFor + ' ' + this.discountValue + this.radioBoxTranslations.allPositions
  //   this.radioBoxList[0].items[1].label = this.radioBoxTranslations.changeFor + ' ' + this.discountValue + this.radioBoxTranslations.forZero

  //   this.confirmChangeMarkupVisible = true
  // }

  emitAfterSaveApi = null;
  onAfterSaveApi() {
    this.onInserted.emit(this.emitAfterSaveApi);
    this.isVisible = false;
    this.cd.detectChanges();
    console.log('onAfterSaveApi');
    setTimeout(() => {
      this.openNestFinished = false;
      console.log('openNestFinished');
    }, 500);
  }

  addPosition = () => {
    setTimeout(() => {
      this.event.onShown();
    }, 101);

    this.appService
      .postAuth(
        `invoices/documents/${this.documentId}/positions/Add`,
        this.positionForm
      )
      .subscribe(
        (res) => {
          this.emitAfterSaveApi = {
            position: this.positionForm,
            res: res,
          };

          if (res.WarningMessage !== null && res.WarningMessage != '') {
            this.event.showNotification(
              'warning',
              res.WarningMessage.replace(/\|/g, '\n')
            );
          }
          this.event.onHidden();
        },
        (error) => {
          this.event.httpErrorNotification(error);
          this.event.onHidden();
        },
        () => {
          this.event.onHidden();
          this.findSocketAndRun('XGHPDZ2');
        }
      );
  };

  updatePostition = () => {
    this.event.onShown();
    this.positionForm.WarehouseDocumentId =
      this.selected[0].WarehouseDocumentId;
    this.appService
      .putAuth(
        `invoices/documents/${this.documentId}/positions/${this.selected[0].WarehouseDocumentPositionId}`,
        this.positionForm
      )
      .subscribe({
        next: (res) => {
          this.emitAfterSaveApi = {
            position: this.positionForm,
            res: res,
          };

          if (
            res.WarningMessage !== null &&
            res.WarningMessage !== undefined &&
            res.WarningMessage != ''
          ) {
            this.event.showNotification(
              'warning',
              res.WarningMessage.replace(/\|/g, '\n')
            );
          }
          this.event.onHidden();
        },
        error: (error) => {
          this.event.onHidden();
          this.event.httpErrorNotification(error);
        },
        complete: () => {
          this.findSocketAndRun('XGHPPZ2');
        },
      });
  };

  onChoosedDescriptionJPK = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.ExemptionDescription = text;
    }
  };

  onChoosedWarehouse() {
    if (!this.editMode) {
      this.selected.WarehouseId != this.event.footerInfo.AppWarehouseId;
      this.positionForm.ExtWarehouseId = this.selected.WarehouseId;
    }
  }

  isOstrzegajMinMax: boolean = false;
  textOstrzegajMinMax: string = '';
  paramsOstrzegajMinMax: any = null;

  getProductData(id) {
    return new Promise((resolve) => {
      this.appService
        .getAuth(
          `products?ObjectId=${id}&IsMarginProcedure=${
            this.documentType.Signature.trim() == 'FM' ||
            this.documentType.Signature.trim() == 'PM'
              ? true
              : false
          }`
        )
        .subscribe((res) => {
          resolve(res.data[0]);
        });
    });
  }

  async checkOstrzegajMinMax() {
    let StockMax = 0;
    let StockMin = 0;
    let Stock = 0;
    if (this.editMode) {
      try {
        const productData: any = await this.getProductData(
          this.selected[0].ProductId
        );

        StockMax = productData.StockMax;
        StockMin = productData.StockMin;
        Stock = !this.documentType.IsIncome
          ? productData.Stock + this.form.Quantity
          : productData.Stock;
      } catch {
        this.event.showNotification(
          'error',
          `Nie znaleziono artykułu o id ${this.selected[0].ProductId} w bazie danych`
        );
      }
    } else {
      StockMax = this.selected.StockMax;
      StockMin = this.selected.StockMin;
      Stock = this.selected.Stock;
    }
    const OstrzegajMinMax = this.event.getConfigurationParameter(
      'OstrzegajMinMax',
      true
    );

    if (
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'
    ) {
      if (OstrzegajMinMax.Value === 'Min i Max') {
        if (
          Stock - this.form.Quantity < StockMin &&
          StockMin != 0 &&
          !this.documentType.IsIncome
        ) {
          this.textOstrzegajMinMax = this.translate.instant(
            'form-commercial-operation.approvalOfAnItemCausesTheMinimumBalanceToBeExceededBy',
            {
              number: Math.abs(Stock - this.form.Quantity - StockMin),
            }
          );

          this.isOstrzegajMinMax = true;
          this.cd.detectChanges();

          return;
        }

        if (
          Stock + this.form.Quantity > StockMax &&
          StockMax != 0 &&
          this.documentType.IsIncome
        ) {
          this.textOstrzegajMinMax = this.translate.instant(
            'form-commercial-operation.approvalOfAnItemCausesTheMaximumBalanceToBeExceededBy',
            {
              number: Math.abs(Stock + this.form.Quantity - StockMax),
            }
          );

          this.isOstrzegajMinMax = true;
          this.cd.detectChanges();

          return;
        }
      }

      if (OstrzegajMinMax.Value === 'Min') {
        if (
          Stock - this.form.Quantity < StockMin &&
          StockMin != 0 &&
          !this.documentType.IsIncome
        ) {
          this.textOstrzegajMinMax = this.translate.instant(
            'form-commercial-operation.approvalOfAnItemCausesTheMinimumBalanceToBeExceededBy',
            {
              number: Math.abs(Stock - this.form.Quantity - StockMin),
            }
          );

          this.isOstrzegajMinMax = true;
          this.cd.detectChanges();

          return;
        }
      }

      if (OstrzegajMinMax.Value === 'Max') {
        if (
          Stock + this.form.Quantity > StockMax &&
          StockMax != 0 &&
          this.documentType.IsIncome
        ) {
          this.textOstrzegajMinMax = this.translate.instant(
            'form-commercial-operation.approvalOfAnItemCausesTheMaximumBalanceToBeExceededBy',
            {
              number: Math.abs(Stock + this.form.Quantity - StockMax),
            }
          );

          this.isOstrzegajMinMax = true;
          this.cd.detectChanges();

          return;
        }
      }
    }
    this.onSave();
  }

  noOstrzegajMinMax() {
    this.isOstrzegajMinMax = false;
    this.cd.detectChanges();
  }

  async onSave(co?) {
    let price = await this.checkPrice();
    this.isOstrzegajMinMax = false;

    const CenyDokMagOglCenZak = this.event.checkPermissions(
      'CenyDokMagOglCenZak'
    );
    const CenyDokMagOglCenSpr = this.event.checkPermissions(
      'CenyDokMagOglCenSpr'
    );

    const isChceckPriceZero = this.documentType.IsIncome
      ? CenyDokMagOglCenZak
      : CenyDokMagOglCenSpr;
    if (co != 'continue' && !price && isChceckPriceZero) {
      this.isPriceZero = true;
      this.cd.detectChanges();
      return;
    }

    let dateOfDo = new DatePipe('en-US').transform(
      this.documentDate,
      'yyyy-MM-dd'
    );
    this.positionForm.PriceNetCurrency = this.IsCurrencyDocument
      ? this.documentType.IsIncome && this.IsCurrencyDocument
        ? Number(this.form.PriceDetail.PriceNet.toFixed(4))
        : Number(this.form.PriceDetail.PriceNet.toFixed(2))
      : 0;
    this.positionForm.PriceGrossCurrency = this.IsCurrencyDocument
      ? this.documentType.IsIncome && this.IsCurrencyDocument
        ? Number(this.form.PriceDetail.PriceGross.toFixed(4))
        : Number(this.form.PriceDetail.PriceGross.toFixed(2))
      : 0;
    this.positionForm.ProductId = this.selected.ProductId;
    this.positionForm.Amount = this.form.PriceDetail.Factor;
    this.positionForm.Unit = this.form.UnitCode;
    this.positionForm.PriceNet = Number(
      (this.form.PriceDetail.PriceNet * this.CurrencyFactor).toFixed(2)
    );
    this.positionForm.PriceGross = Number(
      (this.form.PriceDetail.PriceGross * this.CurrencyFactor).toFixed(2)
    );

    this.positionForm.Discount = this.form.PriceDetail.Discount * 100;
    this.positionForm.TaxCode = this.form.TaxCode;
    this.positionForm.PriceDescription = this.form.PriceDetail.PriceName;
    this.positionForm.Description = this.Description;
    // this.positionForm.MarginAmount = this.form.Margin;
    this.positionForm.PriceGrossBuyMargin = this.buyPrice;
    this.positionForm.PriceIdentificator =
      this.form.PriceDetail.PriceIdentificator;
    this.positionForm.SerialNumber = this.SerialNumber;
    this.positionForm.ExpirationDate = this.ExpirationDate;
    this.positionForm.Field1 = this.field.Field1;
    this.positionForm.Field2 = this.field.Field2;
    this.positionForm.Field3 = this.field.Field3;
    this.positionForm.Field4 = this.field.Field4;
    this.positionForm.Field5 = this.field.Field5;
    this.positionForm.Field6 = this.field.Field6;
    this.positionForm.Field7 = this.field.Field7;
    this.positionForm.Field8 = this.field.Field8;
    this.positionForm.Field9 = this.field.Field9;
    this.positionForm.Field10 = this.field.Field10;
    this.positionForm.WarehouseDocumentPositionDeliveryId =
      this.selected.WarehouseDocumentPositionDeliveryId;
    this.positionForm.InvoiceDocumentId = this.documentId;
    //this.positionForm.WarehouseDocumentId = 977;
    this.positionForm.DocumentDate = dateOfDo;
    this.positionForm.WarehouseDateOfDocument = dateOfDo;
    this.positionForm.Factor = this.Factor;
    this.positionForm.DestinationWarehouseId = 0;
    this.positionForm.SumBy = this.typeOfCrossBorderSale;
    this.positionForm.DeliveryId = 0;

    this.positionForm.DateOfApprovalForUse = this.form.DateOfApprovalForUse;
    this.positionForm.Mileage = this.form.Mileage;
    this.positionForm.ExemptionCode = this.form.ExemptionCode;
    this.positionForm.ExemptionDescription = this.form.ExemptionDescription;

    this.intrastat.CustomerId = this.nip;
    this.positionForm.CountryCode = this.intrastat.CountryCode;
    this.positionForm.DeliveryCode = this.intrastat.DeliveryCountryCode;
    this.positionForm.TransactionCode = this.intrastat.TransactionCode;
    this.positionForm.CnCode = this.intrastat.CNCode;
    this.positionForm.IsAgriculturalFund = this.intrastat.IsAgriculturalFund;
    this.positionForm.TransportCode = this.intrastat.TransportCode;
    this.positionForm.CountryOrginCode = this.intrastat.OrginCountryCode;
    this.positionForm.NetWeight = this.intrastat.MassNet;
    this.positionForm.IntrastaCustomerId = this.intrastat.CustomerId;
    this.positionForm.IsForIntrastate = this.intrastat.isIntrastat;
    this.positionForm.TypeOfCrossBorderSale = this.form.typeOfCrossBorderSale;
    this.positionForm.Excise = this.form.Excise;
    this.positionForm.IsSplitPayment = this.form.IsSplitPayment;
    this.positionForm.PackageNumber = this.form.PackageNumber;

    if (
      this.documentType.Signature.trim() == 'FM' ||
      this.documentType.Signature.trim() == 'PM'
    ) {
      let priceVat = this.event.countAmountNet(
        this.positionForm.PriceGross - this.positionForm.PriceGrossBuyMargin,
        this.form.TaxRate
      );

      let priceVatToSubstruck =
        this.positionForm.PriceGross -
        this.positionForm.PriceGrossBuyMargin -
        priceVat;

      this.positionForm.PriceNet = Number(
        (this.positionForm.PriceGross - priceVatToSubstruck).toFixed(2)
      );
    }

    if (co != 'continue') {
      if (this.editMode) {
        this.positionForm.WarehouseDocumentPositionId =
          this.selected[0].WarehouseDocumentPositionId;
        this.positionForm.ProductId = this.selected[0].ProductId;
        this.positionForm.Factor = this.Factor;
      }
      await this.positionsValid();
    }

    if (this.isValidUserExtensions.checkIsValid()) {
      if (this.positionForm.Amount > 0) {
        if (!this.editMode) {
          this.findSocketAndRun('XGHPDZ1');
        } else {
          this.findSocketAndRun('XGHPPZ1');
        }
      } else if (this.positionForm.Amount == 0 && this.isCorrect) {
        this.positionForm.WarehouseDocumentPositionId =
          this.selected[0].WarehouseDocumentPositionId;
        this.positionForm.ProductId = this.selected[0].ProductId;
        this.positionForm.Factor = this.Factor;
        setTimeout(() => {
          this.updatePostition();
        }, 200);
      } else {
        this.event.showNotification(
          'warning',
          this.translate.instant(
            'warehouseDocument.pleaseEnterTheAmountOfGoodsServices'
          )
        );
      }
    } else {
      this.visibleUserExtensions = true;
    }
  }

  onSaveApi() {
    if (!this.editMode) {
      setTimeout(() => {
        this.addPosition();
      }, 200);
    } else {
      this.positionForm.WarehouseDocumentPositionId =
        this.selected[0].WarehouseDocumentPositionId;
      this.positionForm.ProductId = this.selected[0].ProductId;
      this.positionForm.Factor = this.Factor;
      setTimeout(() => {
        this.updatePostition();
      }, 200);
    }
  }

  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) this.isGuideButtonHighlighted = false;
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.isGuideActive = false;
    this.localStorageData.skipUserGuide = true;
    this.highlightGuideButton(true, 2000);

    localStorage.setItem(
      'positionInvoiceDocumentComponent',
      JSON.stringify(this.localStorageData)
    );

    this.event.setFocusWithSelect(this.firstFocused);
  }

  runGuide() {
    this.isGuideActive = true;
  }

  openNestFinished = false;
  isNestRunning = false;
  nestOperation: NestOperation = null;
  nest: Socket;
  findSocketAndRun(SocketCode: string, nestOperation: NestOperation = null) {
    this.nestObjectParameters = Object.assign({}, this.form.value);
    this.nestOperation = nestOperation;
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.ParentObject) {
      e.updatedParameters.ParentObject =
        this.nestService.removeNonEditableVariables(
          e.updatedParameters.ParentObject,
          e.SocketCode,
          true
        );
      this.nestParentUpdated.emit(e.updatedParameters?.ParentObject);
    }
    if (e.updatedParameters?.Object) {
      e.updatedParameters.Object = this.nestService.removeNonEditableVariables(
        e.updatedParameters.Object,
        e.SocketCode
      );
      this.form.patchValue(e.updatedParameters?.Object, { emitEvent: false });
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGHPDO1':
        this.findSocketAndRun('XGHPAO1');
        break;
      case 'XGHPPO1':
        this.findSocketAndRun('XGHPAO1');
        break;
      case 'XGHPAO1':
        this.openNestFinished = true;
        if (!this.editMode) {
          this.findSocketAndRun('XGHPDO2');
        } else {
          this.findSocketAndRun('XGHPPO2');
        }
        setTimeout(() => {
          if (
            !this.localStorageData.skipUserGuide &&
            this.isAutoOnboarding &&
            this.event.deviceType === 'desktop' &&
            this.isVisible
          ) {
            this.isGuideActive = true;
            this.cd.detectChanges();
          }
        }, 1500);
        break;
      case 'XGHPDO2':
        this.findSocketAndRun('XGHPAO2');
        break;
      case 'XGHPPO2':
        this.findSocketAndRun('XGHPAO2');
        break;
      case 'XGHPDILZ2':
        this.findSocketAndRun('XGHPAILZ2');
        break;
      case 'XGHPPILZ2':
        this.findSocketAndRun('XGHPAILZ2');
        break;
      case 'XGHPDA1':
        this.findSocketAndRun('XGHPAA1');
        break;
      case 'XGHPPA1':
        this.findSocketAndRun('XGHPAA1');
        break;
      case 'XGHPAA1':
        if (!this.editMode) {
          this.findSocketAndRun('XGHPDA2');
        } else {
          this.findSocketAndRun('XGHPPA2');
        }
        break;
      case 'XGHPDA2':
        this.findSocketAndRun('XGHPAA2');
        break;
      case 'XGHPPA2':
        this.findSocketAndRun('XGHPAA2');
        break;
      case 'XGHPAA2':
        this.isVisible = false;
        setTimeout(() => {
          this.openNestFinished = false;
        }, 500);
        this.onClosing.emit(false);
        break;
      case 'XGHPDZ1':
        this.findSocketAndRun('XGHPAZ1');
        break;
      case 'XGHPPZ1':
        this.findSocketAndRun('XGHPAZ1');
        break;
      case 'XGHPAZ1':
        this.onSaveApi();
        break;

      case 'XGHPDZ2':
        this.findSocketAndRun('XGHPAZ2');
        break;
      case 'XGHPPZ2':
        console.log('XGHPPZ2');
        this.findSocketAndRun('XGHPAZ2');
        break;
      case 'XGHPAZ2':
        this.onAfterSaveApi();
        break;
    }
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGH');
    }
  }

  confirmText: string = '';
  isConfirmVisible: boolean = false;
  questionsList: any[] = [];
  positionsValid() {
    return new Promise((resolve) => {
      this.onChoosedWarehouse();

      if (typeof this.positionForm.Mileage != 'string') {
        this.positionForm.Mileage = '';
      }
      this.event.onShown();
      this.appService
        .putAuth(
          `invoices/documents/${this.documentId}/positions/valid`,
          this.positionForm
        )
        .subscribe(
          (res) => {
            if (res.Questions != null && res.Questions.length > 0) {
              this.questionsList = res.Questions;
              this.confirmText = res.Questions[0].Question;
              this.isConfirmVisible = true;
              this.cd.detectChanges();
            } else {
              resolve(true);
            }
            this.event.onHidden();
          },
          (error) => {
            this.event.httpErrorNotification(error);
            this.event.onHidden();
            this.cd.detectChanges();
          }
        );
    });
  }

  yesContinue() {
    this.isConfirmVisible = false;
    this.questionsList.splice(0, 1);

    if (this.questionsList.length > 0) {
      setTimeout(() => {
        this.confirmText = this.questionsList[0].Question;
        this.isConfirmVisible = true;
        this.cd.detectChanges();
      }, 0);

      return;
    }

    this.onSave('continue');
    this.cd.detectChanges();
  }

  noContinue() {
    this.isConfirmVisible = false;
    this.cd.detectChanges();
  }

  @ViewChild('PriceNetInput') PriceNetInput;
  noApprove() {
    this.isPriceZero = false;
    this.event.setFocus(this.PriceNetInput);
    this.cd.detectChanges();
  }

  yesApprove() {
    this.onSave('continue');
    this.isPriceZero = false;
    this.cd.detectChanges();
  }

  onValueBuyPrice(e) {
    this.form.Margin =
      (this.form.PriceDetail.AmountGross / this.form.Quantity - e.value) *
      this.form.Quantity;
  }

  onValuePriceGrossBase() {
    this.form.PriceDetail.PriceNetBase = this.event.countAmountNet(
      this.form.PriceDetail.PriceGrossBase,
      this.form.TaxRate
    );
    this.onValueWarhaouseAmount();
  }

  checkPrice() {
    return new Promise((resolve) => {
      if (this.form.PriceDetail.PriceGross == 0) {
        resolve(false);
      } else resolve(true);
    });
  }

  onValueChangedWasteManagement(mode) {
    clearTimeout(this.myTimer);
    this.myTimer = setTimeout(() => {
      if (mode == 'net') {
        this.positionForm.PriceGrossWasteManagement =
          this.event.countAmountGross(
            this.positionForm.PriceNetWasteManagement,
            this.form.TaxRate
          );
      }

      if (mode == 'gross') {
        this.positionForm.PriceNetWasteManagement = this.event.countAmountNet(
          this.positionForm.PriceGrossWasteManagement,
          this.form.TaxRate
        );
      }
      this.cd.detectChanges();
    }, this.timer);
  }
}

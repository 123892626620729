import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { CustomWindowConfig } from '../../../event.model';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-delivery-address-warehouse-document',
    templateUrl: './delivery-address-warehouse-document.component.html',
    styleUrls: ['./delivery-address-warehouse-document.component.scss'],
    inputs: [
        'isVisible',
        'documentId ',
        'readOnly',
        'CustomerId',
        'typeDocument',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DeliveryAddressWarehouseDocumentComponent implements OnInit {
  @Output() onClosing = new EventEmitter();

  title;

  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'Code',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: '75%',
      height: '75%',
    },
    params: [],
  };

  isVisible;
  documentId;
  typeDocument;
  readOnly;
  CustomerId;

  widthWindow: any = null;
  heightWindow: any = '620px';

  unicalGuid;
  shortcuts: ShortcutInput[] = [];

  heightGrid = 121;
  selectedRows = [];

  addRow: boolean = false;
  editRow: boolean = false;
  showRow: boolean = false;

  myEventDelete;
  myEventShow;
  myEventEdit;
  myEventEsc;

  emptyStateHeight: any;
  maxHeight = null;
  contextMenu;

  shippingAddress = '';
  pickupPointAddress = '';

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.title = this.translate.instant(
      'form-commercial-operation.deliveryAddress'
    );
    this.shippingAddress = this.translate.instant('delivery.shippingAddress');

    this.pickupPointAddress = this.translate.instant(
      'delivery.pickupPointAddress'
    );

    this.maxHeight = this.event.setMaxHeight;
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.widthWindow = this.event.setWidthPopUp();

    this.contextMenu = [
      {
        text: 'Dodaj',
        icon: 'icon absui-icon--add-circle',
        disabled: false,
        itemIndex: 0,
      },
      {
        text: 'Popraw',
        icon: 'icon absui-icon--mode-edit',
        // disabled: this.readOnly || this.focusedSelected.length === 0,
        itemIndex: 1,
      },
      {
        text: 'Usuń',
        icon: 'icon absui-icon--highlight-off',
        // disabled: this.readOnly || this.focusedSelected.length === 0,
        itemIndex: 2,
      },
      {
        text: 'Pokaż',
        icon: 'icon absui-icon--form-items',
        // disabled: this.focusedSelected.length === 0,
        itemIndex: 3,
      },
    ];

    this.myEventEdit = this.event.edit.subscribe(() => {
      if (
        this.isVisible &&
        !this.addRow &&
        !this.editRow &&
        !this.showRow &&
        !this.isDeleteRow
      ) {
        this.editRow = true;
      }
    });

    this.myEventDelete = this.event.delete.subscribe(() => {
      if (
        this.isVisible &&
        !this.addRow &&
        !this.editRow &&
        !this.showRow &&
        !this.isDeleteRow
      ) {
        this.isDeleteRow = true;
      }
    });

    this.myEventShow = this.event.show.subscribe(() => {
      if (
        this.isVisible &&
        !this.addRow &&
        !this.editRow &&
        !this.showRow &&
        !this.isDeleteRow
      ) {
        this.showRow = true;
      }
    });

    this.myEventEsc = this.event.esc.subscribe(() => {
      if (
        this.isVisible &&
        !this.addRow &&
        !this.editRow &&
        !this.showRow &&
        !this.isDeleteRow
      ) {
        this.onClosing.emit(true);
        this.isVisible = false;
      }
    });
  }

  contextMenuClick(e: any) {
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.addAddresses();
        }
        break;
      case 1:
        if (!this.readOnly && this.focusedSelected.length != 0) {
          this.editRow = true;
        }
        break;
      case 2:
        if (!this.readOnly && this.focusedSelected.length != 0) {
          this.isDelete();
        }
        break;
      case 3:
        if (this.focusedSelected.length != 0) {
          this.showRow = true;
        }
        break;
    }
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
  }

  fullScreen() {
    setTimeout(() => {
      if (this.localStorageData.windowSize.height == '75%') {
        this.emptyStateHeight = window.innerHeight * 0.75;
      } else {
        this.emptyStateHeight = this.localStorageData.windowSize.height;
      }

      if (this.localStorageData.windowSize.isFullscreen) {
        this.emptyStateHeight = window.innerHeight;
      }

      if (this.emptyStateHeight > this.maxHeight) {
        this.emptyStateHeight = this.maxHeight;
      }
    }, 0);
  }

  ngAfterViewInit() {
    this.fullScreen();

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
    this.translate
      .get('buttons.show')
      .subscribe((text) => (this.contextMenu[3].text = text));

    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.addAddresses();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length != 0) {
            this.editRow = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.focusedSelected.length != 0) {
            this.showRow = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'delete',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length != 0) {
            this.isDelete();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      }
    );
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
      this.focusedSelected = [];
    }
    this.cd.detectChanges();
  };

  ngOnChanges() {
    if (this.isVisible) {
      this.getDeliveryAdress();
    }
  }

  onResizeEnd = () => {
    this.localStorageData.windowSize.isFullscreen = false;
  };

  onResizeStart = (e) => {
    this.emptyStateHeight = e.height;
  };

  fullScreenChange = (e) => {
    this.localStorageData.windowSize.isFullscreen = e;
    if (e) {
      this.emptyStateHeight = window.innerHeight;
    } else {
      this.emptyStateHeight = this.localStorageData.windowSize.height;
    }
    if (this.emptyStateHeight == '75%') {
      this.emptyStateHeight = window.innerHeight * 0.75;
    }
  };

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  scrollPosition = 0;

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  ngOnDestroy() {
    this.myEventDelete.unsubscribe();
    this.myEventEdit.unsubscribe();
    this.myEventShow.unsubscribe();
    this.myEventEsc.unsubscribe();

    this.event.onHiddenPopUp(this.unicalGuid);
  }

  onRowDblClick = () => {
    if (!this.readOnly) {
      this.editRow = true;
    }
  };

  onClosingaddresse = () => {
    this.addRow = false;
    this.editRow = false;
    this.showRow = false;
    this.cd.detectChanges();
  };

  onInsertedAddresses = () => {
    this.dataSource.reload();
    this.addRow = false;
    this.editRow = false;
    this.showRow = false;
    this.cd.detectChanges();
  };

  focusedSelected = [];
  onFocusedRowChanged = (e) => {
    if (e?.row?.data) {
      this.focusedSelected = [e?.row?.data];
    }
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  }

  onKeyDown(e) {
    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onSelectionChanged = () => {};

  onOptionChanged = () => {
    // if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
    //   const index1 = e.fullName.indexOf('[');
    //   const index2 = e.fullName.indexOf(']');
    //   const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
    //   const colIndex = parseInt(indexStr);
    // }
  };

  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];
  focusedRowIndex;
  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  dataSource;
  getDeliveryAdress = () => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'DeliveryId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.typeDocument}/${this.documentId}/deliveryAddresses`,
        loadParams: this.getLoadParams(),
        onLoaded: (data) => {
          if (data.length > 0) {
            this.focusedSelected = [data[0]];
          } else {
            this.focusedSelected = [];
          }
          this.focusedRowIndex = 0;
          this.event.setFocus(this.gridBank);
          this.cd.detectChanges();
        },
        onAjaxError: this.event.onAjaxDataSourceError,
        deleteUrl: `${environment.domain}${this.typeDocument}/${this.documentId}/deliveryAddresses`,
      }),
      reshapeOnPush: true,
    });
  };

  customizeAddressType(cellInfo: any): string {
    const addressType = cellInfo.value;

    switch (addressType) {
      case 0:
        if (localStorage.getItem('lang') === 'pl') {
          return `adres wysyłki`;
        } else if (localStorage.getItem('lang') === 'en') {
          return `shipping address`;
        } else if (localStorage.getItem('lang') === 'ua') {
          return `адреса доставки`;
        } else {
          return 'adres wysyłki';
        }
      case 1:
        if (localStorage.getItem('lang') === 'pl') {
          return `adres punktu odbioru`;
        } else if (localStorage.getItem('lang') === 'en') {
          return `pickup point address`;
        } else if (localStorage.getItem('lang') === 'ua') {
          return `адреса пункту видачі`;
        } else {
          return 'adres punktu odbioru';
        }
      default:
        return '';
    }
  }

  getLoadParams() {
    let obj: any = {};
    return obj;
  }

  addAddresses = () => {
    if (this.dataSource._items.length > 0) {
      this.event.showNotification(
        'info',
        this.translate.instant(
          'warehouseDocument.documentCanOnlyHaveOneDeliveryAddress'
        )
      );
      return;
    }

    if (!this.readOnly) {
      this.addRow = true;
    }
  };

  isDeleteRow: boolean = false;
  isDelete = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.isDeleteRow = true;
    }
  };

  @ViewChild('gridBank') gridBank;
  delete = () => {
    this.appService
      .deleteAuth(`${this.typeDocument}/${this.documentId}/deliveryAddresses`)
      .subscribe(
        () => {
          this.getDeliveryAdress();
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
    this.isDeleteRow = false;
    setTimeout(() => {
      if (this.gridBank) {
        this.gridBank.focusedRowIndex = 0;
        this.gridBank.instance.focus();
      }
    }, 100);
  };

  closeConfirm = () => {
    this.isDeleteRow = false;
    this.cd.detectChanges();
  };
}

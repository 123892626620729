import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { CustomWindowConfig } from '../../../../../event.model';
import { EventService } from '../../../../../event.service';

@Component({
    selector: 'app-orders-service-add-delivery-address',
    templateUrl: './orders-service-add-delivery-address.component.html',
    styleUrls: ['./orders-service-add-delivery-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrdersServiceAddDeliveryAddressComponent {
  @Input() isVisible: boolean;
  @Input() readOnly: boolean;
  @Input() mode: string;
  @Output() onClosing = new EventEmitter();

  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: '',
      order: '',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: 745,
      height: '75%',
    },
    params: [],
  };
  submitted = false;
  form: FormGroup;
  title: string;
  minWidth = null;
  maxHeight = null;
  unicalGuid: number;
  selectedTabIndex = 0;
  emptyStateHeight;
  loadChildComponent = false;
  itemTabs = ['', ''];
  scrollPosition = 0;
  shortcuts: ShortcutInput[] = [];

  deliveryAddress = [
    {
      CountryCode: '',
      PostalCode: '',
      City: '',
      Street: '',
      AddressType: '',
      Phone: '',
      Email: '',
      PostageOperator: '',
      PickUpAddressCode: '',
    },
  ];
  isVisibleChooseAddress = false;

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.minWidth = this.event.setMinWidthPopUp();
    this.maxHeight = this.event.setMaxHeight;
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate
      .get('companies.tabs.basicInfo')
      .subscribe((text) => (this.itemTabs[0] = text));
    this.translate
      .get('constractors.pickupDeliveryParameters')
      .subscribe((text) => (this.itemTabs[1] = text));

    this.title = this.translate.instant(
      'serviceOrdersDeliveryAddress.addingDeliveryAddress'
    );
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
      switch (this.mode) {
        case 'add':
          this.title = this.translate.instant(
            'serviceOrdersDeliveryAddress.addingDeliveryAddress'
          );
          this.createForm();
          break;
        case 'edit':
          this.title = this.translate.instant(
            'serviceOrdersDeliveryAddress.editingDeliveryAddress'
          );
      }
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isVisible) {
            this.onCancel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.isVisible && !this.readOnly && this.mode !== 'show') {
            this.onSave();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTabIndex = 0;
          // this.setFocus();
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedTabIndex = 1;
        },
        preventDefault: true,
      }
    );
  }

  onResizeStart = (e) => {
    this.emptyStateHeight = e.height;
  };

  onResizeEnd = () => {
    this.localStorageData.windowSize.isFullscreen = false;
  };

  fullScreenChange = (e) => {
    this.localStorageData.windowSize.isFullscreen = e;
    if (e) {
      this.emptyStateHeight = window.innerHeight;
    } else {
      this.emptyStateHeight = this.localStorageData.windowSize.height;
    }
    if (this.emptyStateHeight == '75%') {
      this.emptyStateHeight = window.innerHeight * 0.75;
    }
  };

  onScroll(e: any) {
    this.scrollPosition = e.scrollOffset.top;
  }

  visibleChange() {
    this.submitted = false;
    this.createForm();
    if (!this.isVisible) {
      this.onClosing.emit(true);
    }

    this.selectedTabIndex = 0;
    this.cd.detectChanges();
  }

  onInit(e: any) {
    const self = this;

    e.component.registerKeyHandler('escape', function () {
      if (self.isVisible) {
        self.onCancel();
        self.cd.detectChanges();
      }
    });
  }

  createForm() {
    this.form = this.formBuilder.group({
      DeliveryId: null,
      AddressId: null,
      CustomerId: null,
      WarehouseDocumentId: null,
      OrderDocumentId: null,
      InvoiceDocumentId: null,
      ServiceOrderId: null,
      DeliveryFormId: null,
      PlanDate: new Date(),
      ReceivedPlanTimeFrom: {
        Ticks: '',
      },
      ReceivedPlanTimeTo: {
        Ticks: '',
      },
      ReceivedDate: new Date(),
      ReceivedTime: {
        Ticks: '',
      },
      IsReceived: true,
    });
  }

  onChoosedDeliveryForm = (e) => {
    if (e) {
      this.form.controls.DeliveryFormId.setValue(e.DeliveryFormId);
    } else {
      this.form.controls.DeliveryFormId.setValue(null);
    }
  };

  setFocus() {}

  onSave() {}

  onCancel() {
    this.isVisible = false;
  }

  onChoosedCustomer(e: any) {
    if (e) {
      this.form.controls.CustomerId.setValue(e.CustomerId);
    } else {
      this.form.controls.CustomerId.setValue(0);
    }
  }
}

import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  ViewChild,
  Output,
} from '@angular/core';
import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder } from '@angular/forms';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-stock-transfer',
    templateUrl: './stock-transfer.component.html',
    styleUrls: ['./stock-transfer.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'selected',
        'customerId',
        'documentId',
        'documentDate',
        'documentType',
        'destinationWarehouseId',
        'typeOfCrossBorderSale',
        'editMode',
        'isCorrect',
        'WarehouseDocumentOriginalId',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StockTransferComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('firstFocused') firstFocused;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;

  field: any = null;
  unicalGuid;
  className = false;
  widthWindow: string | number | Function = '730px';
  heightWindow: string | number | Function = '240px';
  scrollPosition = 0;
  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  customerId;
  documentId;
  documentDate;
  documentType;
  destinationWarehouseId;
  typeOfCrossBorderSale;
  editMode;
  isCorrect;
  WarehouseDocumentOriginalId;
  selectedtab;

  visibleUserExtensions: boolean = false;
  form;
  shortcuts: ShortcutInput[] = [];

  articleToAdd = {
    ProductFullName: '',
    StockAvailable: '',
    IndexCatalogue: '',
  };

  IsDivisible: boolean = false;
  IsDivisibleBase: boolean = false;
  formatDivisible = '#0.0000';
  formatNotDivisible = '#0';

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.createForm();
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.createForm();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          document.getElementById(`add${this.unicalGuid}`).click();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp();
  }
  ngOnChanges() {
    if (this.isVisible && this.selected) {
      if (Array.isArray(this.selected)) this.selected = this.selected[0];
      this.articleToAdd.ProductFullName = this.selected.ProductFullName;
      this.articleToAdd.StockAvailable = this.selected.StockAvailable;
      this.articleToAdd.IndexCatalogue = this.selected.IndexCatalogue;

      setTimeout(() => {
        if (!this.editMode) {
          this.getUnit();
          this.patchValue(this.selected);
          return;
        }

        if (this.editMode) {
          this.getUnitEdit();
          return;
        }
      }, 0);

      this.cd.detectChanges();
    }
  }

  setIsDivisible() {
    let UnitName = this.unitList.find(
      (item) => item.UnitId == this.form.value.BaseUnitId
    );

    let IsDivisible = this.unitList.find(
      (item) => item.UnitId == this.form.value.UnitId
    );
    this.IsDivisible = IsDivisible.IsDivisible;
    this.IsDivisibleBase = UnitName.IsDivisible;
  }

  unitList;
  isGridBoxOpened: boolean = false;
  getUnit = () => {
    let forArray = [];

    let pieceUnit = this.appService.getAuth(
      `products/units?unitId=${this.selected.BaseUnitId}`
    );
    let otherUnit = this.appService.getAuth(
      `products/units?articleId=${this.selected.ProductId}`
    );

    forArray.push(pieceUnit, otherUnit);
    forkJoin(forArray).subscribe((res) => {
      this.unitList = [];
      this.form.controls.UnitId.setValue(res[0].data[0].UnitId);
      this.form.controls.Unit.setValue(res[0].data[0].Code);
      res.forEach((field) => {
        field.data.forEach((item) => this.unitList.push(item));
      });
      this.cd.detectChanges();
      let UnitName = this.unitList.find(
        (item) => item.UnitId == this.selected.BaseUnitId
      );

      this.form.controls.BaseUnitName.setValue(UnitName.Code);
      this.form.controls.Factor.setValue(UnitName.Factor);
      this.form.controls.AmountBase.setValue(UnitName.Factor);

      this.setIsDivisible();
    });
  };

  getUnitEdit = () => {
    this.appService
      .getAuth(`products?ObjectId=${this.selected.ProductId}`)
      .subscribe((res) => {
        this.patchValue(res.data[0]);
        let forArray = [];

        let pieceUnit = this.appService.getAuth(
          `products/units?unitId=${res.data[0].BaseUnitId}`
        );
        let otherUnit = this.appService.getAuth(
          `products/units?articleId=${res.data[0].ProductId}`
        );

        forArray.push(pieceUnit, otherUnit);
        forkJoin(forArray).subscribe((res) => {
          this.unitList = [];
          res.forEach((field) => {
            field.data.forEach((item) => this.unitList.push(item));
          });
          this.cd.detectChanges();
          let UnitNameBase = this.unitList.find(
            (item) => item.UnitId == this.form.value.BaseUnitId
          );

          let UnitName = this.unitList.find(
            (item) => item.UnitName == this.selected.Unit
          );

          this.form.controls.UnitId.setValue(UnitName.UnitId);
          this.form.controls.Unit.setValue(UnitName.Code);

          this.form.controls.BaseUnitName.setValue(UnitNameBase.Code);
          this.form.controls.Factor.setValue(UnitNameBase.Factor);
          this.form.controls.AmountBase.setValue(this.selected.Amount);

          this.setIsDivisible();
        });
      });
  };

  Factor = 1;
  chooseUnit = () => {
    if (this.focusedUnit != null) {
      this.form.controls.Factor.setValue(this.focusedUnit.Factor);
      this.form.controls.UnitId.setValue(this.focusedUnit.UnitId);
      this.form.controls.Unit.setValue(this.focusedUnit.Code);
      this.form.controls.Amount.setValue(
        this.form.value.AmountBase * this.focusedUnit.Factor
      );
      this.isGridBoxOpened = false;
    }
    this.setIsDivisible();
  };

  onRowDblClick = () => {
    if (this.focusedUnit != null) {
      this.form.controls.Factor.setValue(this.focusedUnit.Factor);
      this.form.controls.UnitId.setValue(this.focusedUnit.UnitId);
      this.form.controls.Unit.setValue(this.focusedUnit.Code);
      this.form.controls.Amount.setValue(
        this.form.value.AmountBase * this.focusedUnit.Factor
      );
      this.isGridBoxOpened = false;
    }

    this.setIsDivisible();
  };

  focusedUnit = null;
  onFocusedRowChanged = (e) => {
    this.focusedUnit = e.row.data;
  };

  chooseUnitAndQuantity = () => {
    if (this.focusedUnit != null) {
      this.form.controls.Factor.setValue(this.focusedUnit.Factor);
      this.form.controls.UnitId.setValue(this.focusedUnit.UnitId);
      this.form.controls.AmountBase.setValue(
        this.form.value.Amount / this.focusedUnit.Factor
      );
      this.isGridBoxOpened = false;
    }
    this.setIsDivisible();
  };

  patchValue = (selected) => {
    this.form.controls.ProductId.setValue(selected.ProductId);
    this.form.controls.Amount.setValue(1);
    this.form.controls.BaseUnitId.setValue(selected.BaseUnitId);
    this.form.controls.PriceNet.setValue(selected.BuyPriceNet);
    this.form.controls.PriceGross.setValue(selected.BuyPriceGross);
    this.form.controls.Discount.setValue(0);
    this.form.controls.TotalNetAmount.setValue(selected.BuyPriceNet);
    this.form.controls.TotalGrossAmount.setValue(selected.BuyPriceGross);
    this.form.controls.TotalTaxAmount.setValue(
      selected.BuyPriceGross - selected.BuyPriceNet
    );
    this.form.controls.WarehouseDocumentPositionDeliveryId.setValue(
      selected.WarehouseDocumentPositionDeliveryId
    );
    this.form.controls.TaxCode.setValue(selected.BuyTaxCode);
    this.form.controls.Description.setValue('');
    this.form.controls.PriceIdentificator.setValue('k');
    this.form.controls.SerialNumber.setValue();
    this.form.controls.ExpirationDate.setValue(null);
    this.form.controls.Field1.setValue(null);
    this.form.controls.Field2.setValue(null);
    this.form.controls.Field3.setValue(null);
    this.form.controls.Field4.setValue(null);
    this.form.controls.Field5.setValue(null);
    this.form.controls.Field6.setValue(null);
    this.form.controls.Field7.setValue(null);
    this.form.controls.Field8.setValue(null);
    this.form.controls.Field9.setValue(null);
    this.form.controls.Field10.setValue(null);
    this.form.controls.WarehouseDocumentId.setValue(this.documentId);
    this.form.controls.WarehouseDateOfDocument.setValue(this.documentDate);
    this.form.controls.Factor.setValue(1);
    this.form.controls.UnitId.setValue(selected.BaseUnitId);
    this.form.controls.DestinationWarehouseId.setValue(
      this.destinationWarehouseId
    );
    this.form.controls.TypeOfCrossBorderSale.setValue('');
    this.form.controls.DeliveryId.setValue(null);
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      ProductId: [0],
      Amount: [0],
      AmountBase: [1],
      BaseUnitId: [0],
      BaseUnitName: [],
      Unit: [''],
      PriceNet: [0],
      PriceGross: [0],
      Discount: [0],
      TotalNetAmount: [null],
      TotalGrossAmount: [null],
      TotalTaxAmount: [null],
      TaxCode: [''],
      Description: [''],
      PriceIdentificator: [''],
      SerialNumber: [''],
      ExpirationDate: [''],
      Field1: [''],
      Field2: [''],
      Field3: [''],
      Field4: [''],
      Field5: [''],
      Field6: [''],
      Field7: [''],
      Field8: [''],
      Field9: [''],
      Field10: [''],
      WarehouseDocumentId: [0],
      WarehouseDateOfDocument: [''],
      WarehouseDocumentPositionDeliveryId: [null],
      Factor: [0],
      UnitId: [0],
      DestinationWarehouseId: [0],
      TypeOfCrossBorderSale: [''],
      DeliveryId: 0,
    });
  };

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  AmountBaseChanged = (e) => {
    this.form.controls.Amount.setValue(e.value * this.form.value.Factor);
  };

  AmountChanged = (e) => {
    this.form.controls.AmountBase.setValue(e.value / this.form.value.Factor);
  };

  visibleChange = (e) => {
    if (!e) this.onClosing.emit(false);
    this.createForm(); //reset form with initial value
    this.selectedtab = 0;
    this.cd.detectChanges();
  };

  onInsertedUserExtensions = (e) => {
    this.form.controls.Field1.setValue(e['Field1']);
    this.form.controls.Field2.setValue(e['Field2']);
    this.form.controls.Field3.setValue(e['Field3']);
    this.form.controls.Field4.setValue(['Field4']);
    this.form.controls.Field5.setValue(e['Field5']);
    this.form.controls.Field6.setValue(e['Field6']);
    this.form.controls.Field7.setValue(e['Field7']);
    this.form.controls.Field8.setValue(e['Field8']);
    this.form.controls.Field9.setValue(e['Field9']);
    this.form.controls.Field10.setValue(e['Field10']);
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  isOstrzegajMinMax: boolean = false;
  textOstrzegajMinMax: string = '';
  paramsOstrzegajMinMax: any = null;

  noOstrzegajMinMax() {
    this.isOstrzegajMinMax = false;
    this.cd.detectChanges();
  }

  getProductData(id) {
    return new Promise((resolve) => {
      this.appService.getAuth(`products?ObjectId=${id}`).subscribe((res) => {
        resolve(res.data[0]);
      });
    });
  }

  async checkOstrzegajMinMax() {
    let StockMax = 0;
    let StockMin = 0;
    let Stock = 0;
    if (this.editMode) {
      const productData: any = await this.getProductData(
        this.selected[0].ProductId
      );

      StockMax = productData.StockMax;
      StockMin = productData.StockMin;
      Stock = !this.documentType.IsIncome
        ? productData.Stock + this.form.value.Amount
        : productData.Stock;
    } else {
      StockMax = this.selected.StockMax;
      StockMin = this.selected.StockMin;
      Stock = this.selected.Stock;
    }
    const OstrzegajMinMax = this.event.getConfigurationParameter(
      'OstrzegajMinMax',
      true
    );

    if (OstrzegajMinMax.Value === 'Min i Max') {
      if (
        Stock - this.form.value.Amount < StockMin &&
        StockMin != 0 &&
        !this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMinimumBalanceToBeExceededBy',
          {
            number: Math.abs(Stock - this.form.value.Amount - StockMin),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }

      if (
        Stock + this.form.value.Amount > StockMax &&
        StockMax != 0 &&
        this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMaximumBalanceToBeExceededBy',
          {
            number: Math.abs(Stock + this.form.value.Amount - StockMax),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }
    }

    if (OstrzegajMinMax.Value === 'Min') {
      if (
        Stock - this.form.value.Amount < StockMin &&
        StockMin != 0 &&
        !this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMinimumBalanceToBeExceededBy',
          {
            number: Math.abs(Stock - this.form.value.Amount - StockMin),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }
    }

    if (OstrzegajMinMax.Value === 'Max') {
      if (
        Stock + this.form.value.Amount > StockMax &&
        StockMax != 0 &&
        this.documentType.IsIncome
      ) {
        this.textOstrzegajMinMax = this.translate.instant(
          'form-commercial-operation.approvalOfAnItemCausesTheMaximumBalanceToBeExceededBy',
          {
            number: Math.abs(Stock + this.form.value.Amount - StockMax),
          }
        );

        this.isOstrzegajMinMax = true;
        this.cd.detectChanges();

        return;
      }
    }

    this.onSave();
  }

  onSave = () => {
    if (this.form.value.Amount == 0) {
      this.event.showNotification(
        'warning',
        this.translate.instant(
          'warehouseDocument.pleaseEnterTheAmountOfGoodsServices'
        )
      );
      return;
    }

    if (!this.editMode) {
      this.appService
        .postAuth(
          `warehouseDocuments/documents/${this.documentId}/positions/Add`,
          this.form.value
        )
        .subscribe(
          (res) => {
            let emit = {
              position: this.form.value,
              res: res,
            };
            this.onInserted.emit(emit);
            this.isVisible = false;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  };
}

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventService } from '../../../../event.service';
import { AppServices } from '../../../../app-services.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { DxNumberBoxModule } from 'devextreme-angular/ui/number-box';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { NgShortcutsComponent } from '../../../../core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import { DxTooltipModule } from 'devextreme-angular/ui/tooltip';

@Component({
    selector: 'app-released-quantity',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        TranslateModule,
        DxNumberBoxModule,
        DxScrollViewModule,
        NgShortcutsComponent,
        DxTooltipModule,
        FormsModule,
    ],
    templateUrl: './released-quantity.component.html',
    styleUrl: './released-quantity.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    inputs: ['isVisible', 'readOnly', 'position', 'maxAmount']
})
export class ReleasedQuantityComponent implements OnInit, OnDestroy {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('firstFocused') firstFocused;

  widthWindow = 400;
  heightWindow = 140;
  title;
  unicalGuid;
  isVisible;
  readOnly;
  position;
  formatDivisible = '#0.0000';
  maxAmount = 0;
  Quantity = 0;

  shortcuts: ShortcutInput[] = [];

  constructor(
    public event: EventService,
    public appServices: AppServices,
    public cd: ChangeDetectorRef,
    public translate: TranslateService
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.translate.get('articles.Delivery').subscribe((text) => {
      this.title = text;
    });
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;

    this.Quantity = this.maxAmount;
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
          this.cd.detectChanges();
        },
        preventDefault: true,
      }
    );
  }

  ngOnDestroy(): void {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit();
    }
  }

  closeWindow() {
    this.isVisible = false;
    this.cd.detectChanges();
  }

  onSave() {
    this.onInserted.emit(this.Quantity);
    this.isVisible = false;
    this.cd.detectChanges();
  }
}

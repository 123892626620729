import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'containsCourierPipe', pure: false,
    standalone: false
})
export class ContainsCourierPipe implements PipeTransform {
  transform(tablica: Array<any>, code: string): Array<any> {
    return tablica.filter((record: any)=>{
      return record.DeliveryOperatorCode === code
    });
  }
}

import { EventService } from './../../../../event.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { IDocumentOfferPosition } from '../../IDocumentOffer';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
    selector: 'app-offer-position-description',
    templateUrl: './offer-position-description.component.html',
    styleUrls: ['./offer-position-description.component.scss'],
    standalone: false
})
export class OfferPositionDescriptionComponent implements OnInit {
  @Input() visible = false;
  @Input() record: IDocumentOfferPosition;
  @Output() onClosing: EventEmitter<boolean> = new EventEmitter();
  @Output() onSaved: EventEmitter<string> = new EventEmitter();
  description = '';
  shortcuts: ShortcutInput[] = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  constructor(public event: EventService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.record?.currentValue) {
      this.description = this.record.Description || '';
    }
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'F10',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.onSave();
        },
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.close();
        },
      }
    );
  }

  visibleChange() {}

  onSave() {
    this.onSaved.emit(this.description);
  }

  close() {
    this.onClosing.emit(true);
  }
}

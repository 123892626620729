<dx-popup
  *ngIf="isPopupMode && componentVisible"
  [width]="900"
  [height]="550"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'articles.title' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <ng-container *ngIf="componentVisible">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          id="cancel-choose-article"
        ></dx-button>

        <dx-tooltip
          target="#cancel-choose-article"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.cancel" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </ng-container>
  </div>
</dx-popup>

<ng-container *ngIf="!isPopupMode">
  <div *ngIf="!dropDownBoxMode; else dropDownMode">
    <ng-container *ngIf="componentVisible">
      <div class="top-menu-panel" style="padding-bottom: 0; margin-bottom: 0">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <div class="center-panel-scroll-verticall" style="padding-top: 5px">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="articleName"
    valueExpr="ProductId"
    [deferRendering]="false"
    displayExpr="ProductFullName"
    [(opened)]="isGridBoxOpened"
    width="276"
    [dataSource]="articlesList"
    [showClearButton]="true"
    [readOnly]="readOnly"
    (onValueChanged)="onValueChanged($event)"
  >
    <div *dxTemplate="let data of 'content'" style="height: 300px">
      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    [width]="'100%'"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <div style="display: flex; width: 100%; padding-right: 6px">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        type="default"
        stylingMode="text"
        [id]="'add' + unicalGuid"
        (onClick)="showNewRow()"
        [disabled]="readOnly"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        type="default"
        stylingMode="text"
        [id]="'edit' + unicalGuid"
        (onClick)="showEditArticle()"
        [disabled]="readOnly || selectedTask.length == 0"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--form-items"
        [id]="'show' + unicalGuid"
        *ngIf="!isPopupMode"
        [disabled]="selectedTask.length == 0"
        (onClick)="setSingleRecordVisible('show')"
        type="default"
        stylingMode="text"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--highlight-off"
        type="default"
        stylingMode="text"
        [id]="'delete' + unicalGuid"
        (onClick)="isDelete = true"
        [disabled]="readOnly || selectedTask.length == 0"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      >
      </dx-button>
      <app-reports
        #reports
        [code]="'crm'"
        [menuName]="null"
        [type]="null"
        [objectId]="null"
      >
      </app-reports>
      <app-additional-operation
        nameComponent="CrmTasks"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      >
      </app-additional-operation>
      <dx-select-box
        class="menu-dropdown header-select-box"
        id="menu-select-box"
        width="200"
        [dataSource]="calendarTypes"
        valueExpr="value"
        displayExpr="label"
        [(value)]="selectedCalendar"
        (onValueChanged)="onSelectedCalendarChanged()"
        style="margin-left: auto"
      ></dx-select-box>
      <div class="with-left-border">
        <i class="separator"></i>
        <!-- [items]="['Moje zadania','Wszystkich pracowników', 'Zbiorcze kalendarze']"  -->
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          (click)="refreshView()"
          *ngIf="!dropDownBoxMode"
          style="margin-right: 6px"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="column-chooser"
          (click)="openCalendarConfiguration()"
          *ngIf="!dropDownBoxMode"
          [id]="'btn-columns' + unicalGuid"
        ></dx-button>
        <dx-tooltip
          [target]="'#btn-refresh' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          [hideOnOutsideClick]="false"
          hideEvent="dxhoverend"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "refresh" | translate }}
          </div>
        </dx-tooltip>
        <dx-tooltip
          [target]="'#btn-columns' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "columnSettings" | translate }}
          </div>
        </dx-tooltip>
      </div>
    </div>

    <dx-tooltip
      [target]="'#add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#show' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift + F2)
      </div>
    </dx-tooltip>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="panels-flex-container">
    <div
      [ngClass]="{
        without_search_panel: dropDownBoxMode,
        with_search_panel: !dropDownBoxMode
      }"
      #rightPanel
    >
      <div
        style="display: flex; justify-content: flex-end; padding-bottom: 5px"
      >
        <dx-check-box
          class="settings-check-box"
          style="display: inline-block; top: 2px; position: relative"
          width="95"
          text="{{ 'taskCalendar.timeLine' | translate }}"
          [(value)]="calendarConfiguration.isTimeline"
          (onValueChanged)="onTimeLineChanged()"
          [readOnly]="readOnly"
          [ngClass]="{
            'mobile-position': event.deviceType == 'mobile'
          }"
        ></dx-check-box>
      </div>
      <br />

      <dx-scheduler
        [height]="
          event.deviceType == 'mobile' ? 'calc(100vh - 240px)' : calendarHeight
        "
        shortcut
        [views]="availableViews"
        [startDayHour]="calendarConfiguration.startDayHour"
        [(currentView)]="calendarConfiguration.currentView"
        [endDayHour]="calendarConfiguration.endDayHour"
        [dataSource]="tasks"
        [firstDayOfWeek]="1"
        startDateExpr="DateStart"
        endDateExpr="DateEnd"
        (onCellClick)="cellClick()"
        [id]="'grid' + unicalGuid"
        dataCellTemplate="dataCellTemplate"
        (onOptionChanged)="onSchedulerOptionChanged($event)"
        appointmentTemplate="appointment-template"
        [cellDuration]="calendarConfiguration.cellDuration"
        (onAppointmentUpdated)="onAppointmentUpdated($event)"
        appointmentTooltipTemplate="tooltip-template"
        (onAppointmentClick)="onAppointmentClick($event)"
        (onAppointmentContextMenu)="onAppointmentClick($event)"
        (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
      >
        <dxo-appointment-dragging
          [group]="draggingGroupName"
          [onAdd]="onAppointmentAdd"
        >
        </dxo-appointment-dragging>
        <div
          *dxTemplate="let dataCell of 'dataCellTemplate'"
          class="text-right"
          style="
            padding-top: 3px !important;
            padding-right: 4px !important;
            color: #666666;
            font-family: Roboto-Medium;
          "
          [ngClass]="{
            'disable-date': isWeekend(dataCell.startDate)
          }"
        >
          {{ dataCell.text }}
        </div>
        <div
          *dxTemplate="let model of 'appointment-template'"
          class="appointment-bg"
          [ngClass]="{
            'appointment-border-top':
              calendarConfiguration.currentView !== 'month',
            'appointment-border-left':
              calendarConfiguration.currentView === 'month'
          }"
          [style.borderColor]="getBorderColour(model.appointmentData.Colour)"
          [style.background]="
            getBgColour(model.appointmentData.Colour, model.appointmentData)
          "
        >
          <div
            class="showtime-preview"
            style="height: 100%; padding: 0 7px"
            [ngClass]="{
              'appointment-detail-border-top':
                calendarConfiguration.currentView !== 'month',
              'appointment-detail-border-left':
                calendarConfiguration.currentView === 'month'
            }"
            [id]="'model' + model.appointmentData.TaskId"
          >
            <div
              [ngStyle]="{
                'padding-top.px':
                  calendarConfiguration.currentView === 'month' ? 3 : 0
              }"
              style="
                font-weight: 500;
                color: #000;
                font-size: 11px;
                line-height: 13px;
                font-family: Roboto-Medium;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              "
            >
              {{ model.appointmentData.Subject || "-" }}
            </div>
            <div
              *ngIf="calendarConfiguration.currentView !== 'month'"
              style="
                font-weight: 400;
                color: #000;
                font-size: 11px;
                line-height: 13px;
                font-family: Roboto;
              "
              class="dropdown-appointment-dates"
            >
              {{
                (model.appointmentData.DateStart
                  | date : "HH:mm" : translate.currentLang || "pl") +
                  " - " +
                  (model.appointmentData.DateEnd
                    | date : "HH:mm" : translate.currentLang || "pl")
              }}
            </div>
          </div>
          <dx-tooltip
            *ngIf="selectedTask.length == 0"
            [target]="'#model' + model.appointmentData.TaskId"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ model.appointmentData.Subject }} <br />
              {{
                (model.appointmentData.DateStart
                  | date : "HH:mm" : translate.currentLang || "pl") +
                  " - " +
                  (model.appointmentData.DateEnd
                    | date : "HH:mm" : translate.currentLang || "pl")
              }}<br />
              {{ model.appointmentData.CustomerName }}<br />
              {{ model.appointmentData.Location }}
            </div>
          </dx-tooltip>
        </div>

        <div *dxTemplate="let model of 'tooltip-template'">
          <div class="movie-tooltip" (click)="stopPropagation($event)">
            <div
              class="d-flex justify-content-left flex-wrap"
              style="padding-bottom: 10px; justify-content: space-between"
            >
              <div style="flex: none; width: 32px">
                <div
                  class="color-circle"
                  [style.background]="
                    model.appointmentData.Colour
                      ? '#' + model.appointmentData.Colour
                      : '#4DB6AC'
                  "
                ></div>
              </div>
              <div style="flex: 1; padding-top: 4px">
                <label
                  for=""
                  class="text-left cloud-label main-col-details"
                  style="
                    font-weight: 700;
                    font-size: 0.8125rem;
                    line-height: 15.23px;
                    color: #4c4c4c;
                    white-space: normal;
                  "
                  (click)="onTitleClick(model.appointmentData.TaskId)"
                  >{{ model.appointmentData.Subject }}</label
                >
              </div>
              <div style="flex: none; position: relative">
                <!-- <div
                          (click)="toggleOpened(model.appointmentData)"
                          class="dx-widget dx-button dx-button-mode-contained dx-button-normal dx-button-has-icon btn-dx-top-menu-folder"
                          [ngClass]="{
                            'btn-focused': model.appointmentData.isOpened
                          }"
                          [id]="
                            'btn-more' +
                            model.appointmentData.TaskId +
                            unicalGuid
                          "
                        >
                          <div class="dx-button-content">
                            <i class="dx-icon dx-icon-more-horizontal"></i>
                          </div>
                        </div> -->
                <dx-button
                  class="btn-more"
                  icon="more-horizontal"
                  type="default"
                  stylingMode="text"
                  style="margin-left: 7px"
                  (click)="toggleOpened(model.appointmentData)"
                  [id]="'btn-more' + model.appointmentData.TaskId + unicalGuid"
                ></dx-button>
                <!-- <dx-select-box
                          [opened]="model.appointmentData.isOpened"
                          [focusStateEnabled]="false"
                          [hoverStateEnabled]="false"
                          class="hidden-select-box star-menu"
                          [value]="selectBoxValue"
                          (onValueChanged)="
                            onSelectBoxValueChanged(
                              $event,
                              model.appointmentData
                            )
                          "
                          style="left: 0; width: 200px !important"
                          [dataSource]="popupSelectBoxSource"
                        >
                          <div *dxTemplate="let data of 'item'">
                            <div class="custom-item-temp">
                              <i
                                class="icon absui-icon--mode-edit"
                                *ngIf="data.i == 'edit'"
                              ></i>
                              <i
                                class="icon absui-icon--trash"
                                *ngIf="data.i == 'delete'"
                              ></i>
                              <i
                                class="icon absui-icon--form-items"
                                *ngIf="data.i == 'show'"
                              ></i>
                              <i
                                class="icon absui-icon--print"
                                *ngIf="data.i == 'print'"
                              ></i>
                              <i
                                class="icon absui-icon--edocuments"
                                *ngIf="data.i == 'edocuments'"
                              ></i>
                              <i
                                class="icon absui-icon--operations"
                                *ngIf="data.i == 'operations'"
                              ></i>

                              <div class="product-name d-inline-block">
                                {{ data.text | translate }}
                              </div>
                            </div>
                          </div>
                        </dx-select-box> -->
              </div>
            </div>

            <div class="appointment-grid">
              <div class="appointment-item">
                <label> {{ "taskCalendar.dateStart" | translate }}: </label>
              </div>
              <div class="appointment-item">
                {{
                  model.appointmentData.DateStart
                    | date : "dd.MM.yyyy HH:mm" : translate.currentLang || "pl"
                }}
              </div>
              <div class="appointment-item">
                <label>{{ "taskCalendar.dateEnd" | translate }}: </label>
              </div>
              <div class="appointment-item">
                {{
                  model.appointmentData.DateEnd
                    | date : "dd.MM.yyyy HH:mm" : translate.currentLang || "pl"
                }}
              </div>
            </div>

            <div class="appointment-grid">
              <div class="appointment-item">
                <label> Osoba przypisana: </label>
              </div>
              <div class="appointment-item">
                @if(!selectedTaskEmployees()?.length){
                <ng-container>-</ng-container>
                } @else if(selectedTaskEmployees()?.length === 1){
                <ng-container
                  >{{ selectedTaskEmployees()[0].FirstName }}
                  {{ selectedTaskEmployees()[0].LastName }}</ng-container
                >
                } @else{
                <span
                  style="text-decoration: underline"
                  (click)="onShowMoreClick()"
                  >{{ "showMore" | translate }}</span
                >
                }
              </div>
              <div class="appointment-item">
                <label
                  >{{
                    "form-financial-document.payer-type.contractor" | translate
                  }}:</label
                >
              </div>
              <div class="appointment-item">
                {{ model.appointmentData.CustomerName || "-" }}
              </div>
              <div class="appointment-item">
                <label> {{ "taskCalendar.reminder" | translate }}: </label>
              </div>
              <div class="appointment-item">
                <!-- to do - przerobić na pipe -->
                {{ getReminderTimer(model.appointmentData.RemindTimer) }}
              </div>
            </div>

            <div class="appointment-grid">
              <div class="appointment-item">
                <label>Status: </label>
              </div>
              <div class="appointment-item">
                {{ model.appointmentData.StatusCode || "-" }}
              </div>
              <div class="appointment-item">
                <label>{{ "taskCalendar.realization" | translate }}:</label>
              </div>
              <div class="appointment-item">
                {{ model.appointmentData.PctOfCompletion || "0" }}%
              </div>
              <div class="appointment-item"><label>Typ: </label></div>
              <div class="appointment-item">
                <!-- to do - przerobić na pipe -->
                {{ getTypeDescription(model.appointmentData.TypeId) }}
              </div>
              <div class="appointment-item">
                <label>Priotytet: </label>
              </div>
              <div class="appointment-item">
                <i
                  [id]="'priority' + model.appointmentData.TaskId"
                  class="icon low-priority"
                  [ngClass]="{
                    'low-priority': model.appointmentData.Prioritate == 0,
                    'medium-priority': model.appointmentData.Prioritate == 1,
                    'high-priority': model.appointmentData.Prioritate == 2
                  }"
                ></i>
                <dx-tooltip
                  [target]="'#priority' + model.appointmentData.TaskId"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    Priorytet:
                    {{
                      model.appointmentData.Prioritate == 0
                        ? ("constractors.low" | translate)
                        : ""
                    }}
                    {{
                      model.appointmentData.Prioritate == 1
                        ? ("constractors.medium" | translate)
                        : ""
                    }}
                    {{
                      model.appointmentData.Prioritate == 2
                        ? ("constractors.high" | translate)
                        : ""
                    }}
                  </div>
                </dx-tooltip>
              </div>
            </div>
          </div>
        </div>
      </dx-scheduler>

      <wapro-context-menu
        [dataSource]="contextMenuSignal()"
        [width]="200"
        [target]="'#btn-more' + selectedTask + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
        showEvent="dxclick"
      >
      </wapro-context-menu>
      <wapro-context-menu
        [dataSource]="contextMenuSignal()"
        [width]="200"
        [target]="'#grid' + unicalGuid"
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
      >
      </wapro-context-menu>
    </div>
    <div
      class="right-side-panel"
      mwlResizable
      *ngIf="!dropDownBoxMode"
      (resizeEnd)="resizeEnd()"
      (resizeStart)="onResizeStart()"
      (resizing)="resizing($event)"
      #leftPanel
    >
      <div class="right-panel-btn">
        <dx-button
          (mousedown)="mousedownSidePanel()"
          (mouseup)="mouseupSidePanel()"
          (touchend)="mouseupSidePanel()"
          (touchstart)="mousedownSidePanel()"
          [icon]="getResizeIcon()"
          class="btn-dx-right-filter"
          mwlResizeHandle
          [resizeEdges]="{ left: true }"
        ></dx-button>
      </div>
      <div
        class="resize-handle-left"
        mwlResizeHandle
        [resizeEdges]="{ left: true }"
        (mouseenter)="mouseEnter()"
        (mouseleave)="mouseLeave()"
      ></div>

      <dx-tab-panel
        [items]="[1, 2]"
        [selectedIndex]="0"
        style="margin-top: -10px"
        class="no-padding-tab-panel right-panel-box"
        (onSelectionChanged)="onSelectionCategoryTabChanged()"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        [showNavButtons]="true"
      >
        <div *dxTemplate="let item of 'title'">
          <div class="title-temp">
            <i class="icon absui-icon--tab-filter" *ngIf="item == 1"></i>
            <i class="icon absui-icon--task-list" *ngIf="item == 2"></i>
          </div>
        </div>
        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div *ngSwitchCase="1">
              <dx-scroll-view
                width="100%"
                [height]="'calc(100vh - 195px)'"
                direction="vertical"
                [bounceEnabled]="false"
                [scrollByContent]="true"
              >
                <div class="list-container">
                  <dx-draggable
                    id="list"
                    data="dropArea"
                    [group]="draggingGroupName"
                    (onDragStart)="onListDragStart($event)"
                  >
                    <dx-draggable
                      class="drag-item bg-white"
                      [clone]="true"
                      [group]="draggingGroupName"
                      [data]="task"
                      (onDragStart)="onItemDragStart($event)"
                      (onDragEnd)="onItemDragEnd($event)"
                      *ngFor="let task of taskTypes"
                    >
                      <i
                        class="icon absui-icon--operations-kanban grab-area"
                      ></i>
                      <i class="icon icon-group" [ngClass]="task.icon"></i>
                      <div class="display-wrapper">
                        <div class="amount-txt">{{ task.amount || 0 }}</div>
                        <div class="type-txt">{{ task.Name }}</div>
                      </div>
                      <i
                        class="icon icon-group plus-btn"
                        (click)="addTask(task)"
                      ></i>
                    </dx-draggable>
                  </dx-draggable>
                </div>
              </dx-scroll-view>
              <div
                class="text-center new-task"
                style="
                  display: block !important;
                  font-family: Roboto !important;
                  color: #4c4c4c !important;
                "
              >
                <dx-button
                  icon="icon absui-icon--add-no-circle"
                  (onClick)="openAddTaskType()"
                  text="Nowy typ zadania"
                ></dx-button>
              </div>
            </div>
            <div *ngSwitchCase="2">
              <div class="d-flex justify-content-center flex-wrap">
                <div class="form-group-inline-right" style="width: 100%">
                  <dx-tab-panel
                    id="nested-panel"
                    [items]="[
                      'taskCalendar.current' | translate,
                      'taskCalendar.future' | translate
                    ]"
                    [(selectedIndex)]="selectedTab"
                    [loop]="false"
                    [animationEnabled]="false"
                    [swipeEnabled]="true"
                  >
                    <div *dxTemplate="let name of 'item'">
                      <ng-container [ngSwitch]="name">
                        <div *ngSwitchCase="'taskCalendar.current' | translate">
                          <div
                            class="task-box"
                            *ngFor="let item of currentTasks"
                          >
                            <div class="title">{{ item.Subject }}</div>
                            <div
                              class="icon-priority"
                              [ngClass]="{
                                'high-priority': item.Prioritate === 2,
                                'low-priority': item.Prioritate === 0,
                                'medium-priority': item.Prioritate === 1
                              }"
                            ></div>
                            <div class="description time">
                              {{
                                item.DateStart
                                  | date
                                    : "HH:mm"
                                    : translate.currentLang || "pl"
                              }}
                              -
                              {{
                                item.DateEnd
                                  | date
                                    : "HH:mm"
                                    : translate.currentLang || "pl"
                              }}
                            </div>
                            <div class="description">
                              {{ item.StatusCode || "status" }}
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'taskCalendar.future' | translate">
                          <div
                            class="future-box"
                            *ngFor="let day of futureTaskDays"
                          >
                            <div class="day-container">
                              @if(getLocale() == 'pl'){
                              {{ getDayOfWeek(day.day) }}
                              {{ day.day | date : "d" }}
                              } @else{
                              {{ day.day | date : "E d" }}
                              }
                            </div>
                            <div class="task-container">
                              <div
                                class="task-box"
                                *ngFor="let item of day.tasks"
                              >
                                <div class="title">{{ item.Subject }}</div>
                                <div
                                  class="icon-priority"
                                  [ngClass]="{
                                    'high-priority': item.Prioritate === 2,
                                    'low-priority': item.Prioritate === 0,
                                    'medium-priority': item.Prioritate === 1
                                  }"
                                ></div>
                                <div class="description time">
                                  {{
                                    item.DateStart
                                      | date
                                        : "HH:mm"
                                        : translate.currentLang || "pl"
                                  }}
                                  -
                                  {{
                                    item.DateEnd
                                      | date
                                        : "HH:mm"
                                        : translate.currentLang || "pl"
                                  }}
                                </div>
                                <div class="description">
                                  {{ item.StatusCode || "status" }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </dx-tab-panel>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>
      <!-- <div class="row" style="margin-top: -10px;" *ngIf="width > 21">
                        <div class="col-md-12">
                            <div style="float: right;">
                                <dx-button class="columns-settings" style="display:inline-block;position: relative;" icon="playlist-plus" (click)="openAddTaskType()"></dx-button>
                            </div>
                        </div>
                    </div> -->
      <!-- <div class="row">
                        <div class="col-md-12">
                            <dx-accordion style="padding-right: 5px;padding-left: 15px;"
                                class="normal-text"
                                id="task-accordion"
                                [dataSource]="accordionTabs"
                                [collapsible]="true"
                                [multiple]="true"
                                [animationDuration]="250"
                                [selectedItems]="selectedAccordionTabs">
                                <div *dxTemplate="let tab of 'title'">
                                    {{ ("menu." + tab) | translate }}
                                </div>
                                <div *dxTemplate="let item of 'item'">
                                    <ng-container [ngSwitch]="item">
                                        <div *ngSwitchCase="'taskList'" #accordion1>

                                        </div>
                                    </ng-container>
                                </div>
                            </dx-accordion>
                        </div>
                    </div> -->
    </div>
  </div>
</ng-template>

<app-add-task-types
  [isVisible]="addTaskType"
  (onClosing)="addTaskType = false"
  (onInserted)="onInsertedTaskType()"
  title="{{ 'taskTypes.taskType' | translate }}"
  mode="add"
>
</app-add-task-types>

<app-new-task
  [isVisible]="isSingleRecordVisible"
  [mode]="singleRecordMode"
  [taskTypes]="taskTypes"
  [dateStart]="newDateStart"
  [dateEnd]="newDateEnd"
  [colour]="newColour"
  [typeId]="newTaskType"
  [selectedId]="selectedTask"
  (onClosing)="onSingleRecordClosing()"
  [readOnly]="readOnly || singleRecordMode === 'show'"
  (onInserted)="onSingleRecordInserted()"
  (shouldReloadTypes)="onShouldReloadTypes()"
  [activeTabIndex]="activeTabIndex"
>
</app-new-task>

<app-calendar-settings
  [isVisible]="calendarConfigurationVisible"
  [readOnly]="readOnly"
  [isPopupMode]="true"
  [configuration]="calendarConfiguration"
  (onChoosed)="onCalendarConfigurationUpdated($event)"
  (onClosed)="calendarConfigurationVisible = false"
></app-calendar-settings>

<app-confirm-dialog
  [isVisible]="isDelete"
  (onRemoving)="showDeleteArticle()"
  (onClosing)="closeConfirm()"
></app-confirm-dialog>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="
    calendarConfigurationVisible ||
    isSingleRecordVisible ||
    (dropDownBoxMode && !isGridBoxOpened) ||
    isExpansionListVisible
  "
></wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [socketCodeFilter]="'XGCK'"
  [nest]="nest"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      id="btn-add-articles"
      (onClick)="showNewRow()"
      [disabled]="readOnly"
      *ngIf="!dropDownBoxMode && !isPopupMode"
    >
    </dx-button>
  </div>
</ng-template>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGCK'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-add-orders-service
  [isVisible]="isServiceOrderVisible()"
  [mode]="orderServiceMode"
  [readOnly]="readOnly || orderServiceMode === 'show'"
  (onClosing)="onServiceOrderClosing()"
  (onInserted)="onServiceOrderInserted($event)"
  [selectedId]="orderServiceId"
  [taskId]="serviceTaskId"
  [taskCustomerId]="serviceTaskCustomerId"
  title="{{ 'servicesDevices.addOrderService' | translate }}"
>
</app-add-orders-service>

<app-record-card
  *ngIf="isRecordCardVisible"
  [selectedTab]="null"
  [cardMode]="recordCardMode"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

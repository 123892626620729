import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-add-complaint-items',
    templateUrl: './add-complaint-items.component.html',
    styleUrls: ['./add-complaint-items.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'orderId'],
    standalone: false
})
export class AddComplaintItemsComponent implements OnInit, OnChanges {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('focusFirst') focusFirst;

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  orderId;

  widthWindow = 470;
  heightWindow = 450;
  unicalGuid;
  shortcuts: ShortcutInput[] = [];
  submitted: boolean = false;
  form;
  addProduct: boolean = false;
  sizeUnitsVisible: boolean = false;
  isDeleteComplaints: boolean = false;
  visibleUserExtensions: boolean = false;
  SerwisZlecCeny: boolean = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.SerwisZlecCeny = this.event.checkPermissions('SerwisZlecCeny');
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
    this.createForm();
  }

  taxRates;
  ngOnChanges(): void {
    if (this.isVisible && this.selectedId.length > 0) {
      setTimeout(() => {
        this.form.patchValue(this.selectedId[0]);
        this.form.controls.TaxCode.setValue(
          this.form.value.TaxCode.replace(' ', '')
        );
        this.taxRates = this.event
          .decryptString(localStorage.getItem('staticData'))
          ?.taxRates.find(
            (field) => field.TaxCode == this.form.value.TaxCode
          ).TaxRate;
        this.form.controls.Discount1.setValue(this.form.value.Discount1 / 100);
        this.cd.detectChanges();
      }, 0);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closePopup();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  onInsertedUserExtensions = (e) => {
    this.form.patchValue(e);
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      Amount: [0, [Validators.required, Validators.min(0.01)]],
      Description: [''],
      Discount1: [0],
      FullName: [''],
      ExpirationDate: [''],
      PriceGross: [0],
      PriceGrossCurrency: [0],
      PriceNet: [0],
      ProductId: [0],
      PriceNetCurrency: [0],
      ServiceComplaintPositionId: [0],
      ServiceOrderId: [0],
      TaxCode: [''],
      Unit: ['', Validators.required],
    });
  };

  onSizeUnitsChoosed = (e) => {
    if (e) {
      this.form.controls.Unit.setValue(e.Code);
      this.sizeUnitsVisible = false;
    }
  };

  onBuyTaxChanged = (e) => {
    if (e) {
      this.form.controls.TaxCode.setValue(e[0].TaxCode);
      this.taxRates = e[0].TaxRate;
      this.calculatePrices();
    }
  };

  calculatePrices = () => {
    if (this.form.value.TaxCode != '-- ') {
      try {
        this.onValueNetChange();
      } catch {}
    }
  };

  visibleChange = (e) => {
    this.createForm(); //reset form with initial values
    this.submitted = false;

    if (!e) {
      this.onClosing.emit(false);
    }
  };

  closePopup() {
    this.isVisible = false;
    this.onClosing.emit(true);
  }

  myTimerNetto;
  onValueNetChange = () => {
    if (this.myTimerNetto) {
      clearTimeout(this.myTimerNetto);
    }

    this.myTimerNetto = setTimeout(() => {
      this.form.controls.PriceGross.setValue(
        Number(
          this.event.countAmountGross(this.form.value.PriceNet, this.taxRates)
        )
      );
    }, 100);
  };

  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  onSave = () => {
    this.submitted = true;
    if (this.form.valid) {
      if (this.isValidUserExtensions.checkIsValid()) {
        this.form.value.Discount1 = this.form.value.Discount1 * 100;
        this.appService
          .putAuth(
            `services/orders/${this.form.value.ServiceOrderId}/complaints/${this.form.value.ServiceComplaintPositionId}`,
            this.form.value
          )
          .subscribe(
            () => {
              this.onInserted.emit(this.form.value);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      } else this.visibleUserExtensions = true;
    }
  };
}

<dx-popup
  [(width)]="localStorageData.windowSize.width"
  [(height)]="localStorageData.windowSize.height"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="event.deviceType != 'mobile'"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minHeight]="280"
  [fullScreen]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.isFullscreen
      : false
  "
  (onResizeEnd)="onResizeEnd()"
  (onResize)="onResizeStart($event)"
  [maxHeight]="maxHeight"
  (fullScreenChange)="fullScreenChange($event)"
  (onShown)="
    event.onShownPopUp(); loadChildComponent = true; cd.detectChanges()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  #popUp
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{
        boxShadow: scrollPosition > 0 && event.deviceType != 'mobile'
      }"
    >
      <div style="display: inline-block">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 45px)'"
      direction="vertical"
      (onScroll)="onScroll($event)"
    >
      <div style="margin-bottom: 8px">
        <dx-button
          (onClick)="addAddress()"
          [disabled]="readOnly"
          [id]="'btn-add-address' + unicalGuid"
          [text]="'buttons.add' | translate"
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--add-circle"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--mode-edit"
          [text]="'buttons.edit' | translate"
          [id]="'btn-edit-address' + unicalGuid"
          (onClick)="editAddress()"
          [disabled]="readOnly || focusedSelected.length === 0"
        >
        </dx-button>

        <dx-button
          icon="icon absui-icon--highlight-off"
          [id]="'btn-delete-address' + unicalGuid"
          class="btn-dx-top-menu-folder"
          [disabled]="focusedSelected.length == 0 || readOnly"
          [text]="'buttons.delete' | translate"
          (onClick)="isDeleteAddress()"
        >
        </dx-button>

        <dx-button
          (onClick)="editAddress()"
          [disabled]="focusedSelected.length == 0"
          [id]="'btn-show-address' + unicalGuid"
          stylingMode="text"
          type="default"
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--form-items"
        >
        </dx-button>
      </div>

      <dx-tooltip
        [target]="'#btn-add-address' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.add" | translate }} (Insert)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-edit-address' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.edit" | translate }} (F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-delete-address' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.delete" | translate }} (Del)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-show-address' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.show" | translate }} (Shift + F2)
        </div>
      </dx-tooltip>
      <!--      [columnAutoWidth]="true"-->

      <dx-data-grid
        [dataSource]="dataSource"
        [wordWrapEnabled]="true"
        [showBorders]="true"
        shortcut
        (onKeyDown)="onKeyDown($event)"
        [focusedRowEnabled]="true"
        [allowColumnResizing]="true"
        [hoverStateEnabled]="true"
        [(selectedRowKeys)]="selectedRows"
        [(focusedRowIndex)]="focusedRowIndex"
        [columnResizingMode]="'widget'"
        [id]="'gridAddresses' + unicalGuid"
        [height]="emptyStateHeight - heightGrid"
        #gridClass
      >
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-paging [pageSize]="100"></dxo-paging>
        <dxo-selection mode="single"></dxo-selection>
        <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

        <dxi-column
          caption="{{ 'servicesDevices.o' }}"
          [allowSorting]="false"
          dataField="IsReceived"
          alignment="left"
          width="50"
          [hidingPriority]="14"
          headerCellTemplate="O"
          cellTemplate="cellTemplateO"
        ></dxi-column>
        <div *dxTemplate="let data of 'O'">
          <div class="grid-header-wrapper" [id]="'O' + unicalGuid">
            {{ "servicesDevices.o" | translate }}
          </div>
          <dx-tooltip
            [target]="'#O' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "deliveries.received" | translate }}
            </div>
          </dx-tooltip>
        </div>
        <div *dxTemplate="let data of 'cellTemplateO'">
          <i
            *ngIf="data.value; else falseTemplate"
            class="icon absui-icon--check check-grid"
          ></i>
        </div>
        <ng-template #falseTemplate>
          <i class="icon absui-icon--close close-grid"></i>
        </ng-template>

        <dxi-column
          caption="{{ 'delivery.form' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="DeliveryFormName"
          [hidingPriority]="13"
        ></dxi-column>

        <dxi-column
          caption="{{ 'userExtensions.customers' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="CustomerName"
          [hidingPriority]="12"
        ></dxi-column>

        <dxi-column
          caption="{{ 'companies.form.addressDetail.country' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="CountryCode"
          [hidingPriority]="11"
        >
        </dxi-column>

        <dxi-column
          caption="{{ 'bankBox.grid.columns.postalCode' | translate }}"
          width="200"
          dataField="PostalCode"
          [allowSorting]="false"
          [hidingPriority]="10"
        ></dxi-column>

        <dxi-column
          caption="{{ 'bankBox.grid.columns.city' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="City"
          [hidingPriority]="9"
        >
        </dxi-column>

        <dxi-column
          caption="{{ 'bankBox.grid.columns.adress' | translate }}"
          dataField="Street"
          [allowSorting]="false"
          width="200"
          [hidingPriority]="8"
        >
        </dxi-column>

        <dxi-column
          caption="{{ 'constractors.company' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="CompanyName"
          [hidingPriority]="7"
        ></dxi-column>

        <dxi-column
          caption="{{ 'documentsOrders.person' | translate }}"
          [allowSorting]="false"
          dataField="Receiver"
          [hidingPriority]="6"
          width="200"
        ></dxi-column>

        <dxi-column
          caption="{{
            'intrastate-documents.new-intrastate.telephone' | translate
          }}"
          [allowSorting]="false"
          width="200"
          dataField="Phone"
          [hidingPriority]="5"
        ></dxi-column>

        <dxi-column
          caption="{{
            'intrastate-documents.new-intrastate.email' | translate
          }}"
          [allowSorting]="false"
          width="200"
          dataField="Email"
          [hidingPriority]="4"
        ></dxi-column>

        <dxi-column
          caption="{{ 'delivery.addressType' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="AddressType"
          [hidingPriority]="3"
        ></dxi-column>

        <dxi-column
          caption="{{ 'formPayment.operator' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="OperatorCode"
          [hidingPriority]="2"
        ></dxi-column>

        <dxi-column
          caption="{{ 'constractors.pickupPoint' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="DeliveryPointCode"
          [hidingPriority]="1"
        ></dxi-column>

        <dxi-column
          caption="{{ 'articles.descriptionTab' | translate }}"
          [allowSorting]="false"
          width="200"
          dataField="Description"
          [hidingPriority]="0"
        ></dxi-column>
      </dx-data-grid>

      <dx-context-menu
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
        [dataSource]="contextMenu"
        [target]="'#gridAddresses' + unicalGuid"
        [width]="200"
      >
      </dx-context-menu>
    </dx-scroll-view>
  </div>
</dx-popup>

<div *ngIf="loadChildComponent">
  <!--  <app-orders-service-add-delivery-address-->
  <!--    [isVisible]="isVisibleAddDeliveryAddress"-->
  <!--    (onClosing)="onCancelAddDeliveryAddress()"-->
  <!--    [mode]="deliveryAddressMode"-->
  <!--  >-->
  <!--  </app-orders-service-add-delivery-address>-->
</div>

<ng-template #topButtons>
  <i
    class="icon"
    [ngClass]="{
      'absui-icon--fullscreen2': !localStorageData.windowSize.isFullscreen,
      'absui-icon--fullscreen-off': localStorageData.windowSize.isFullscreen
    }"
    (click)="
      localStorageData.windowSize.isFullscreen =
        !localStorageData.windowSize.isFullscreen;
      fullScreen();
      cd.detectChanges()
    "
    style="position: relative; top: 5px; left: 6px"
    [id]="'fitToWidth' + unicalGuid"
  ></i>

  <div class="d-inline-block">
    <dx-button
      text="{{ 'buttons.close' | translate }}"
      type="danger"
      (onClick)="onCancelAddresses()"
      [id]="'cancel' + unicalGuid"
    >
    </dx-button>
  </div>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>
</ng-template>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../../../app-services.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../../../event.service';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'src/app/core/core.module';
import { NgShortcutsComponent } from 'src/app/core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-add-trans-del-transp-codes',
    templateUrl: './add-trans-del-transp-codes.component.html',
    styleUrls: ['./add-trans-del-transp-codes.component.scss'],
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxScrollViewModule,
        DxTextBoxModule,
        DxCheckBoxModule,
        DxTextAreaModule,
        ReactiveFormsModule,
        CoreModule,
        TranslateModule,
        NgShortcutsComponent,
    ]
})
export class AddTransDelTranspCodesComponent {
  @Input() isVisible;
  @Input() selectedCode;
  @Input() selectedCodeId;
  @Input() readOnly;
  @Input() type;

  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();

  @ViewChild('Codes') Codes: ElementRef;

  visibleShortcut;
  widthWindow = 480;
  heightWindow = 410;
  shortcuts: ShortcutInput[] = [];
  form: FormGroup;
  submitted: boolean = false;

  isEdit: boolean = false;
  unicalGuid: number;
  title: string;
  url;
  code;
  codeId;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  }

  ngOnInit(): void {
    this.readOnly = this.event.readOnly;

    if (window.innerWidth < 1200) {
      this.widthWindow = 295;
      this.heightWindow = 260;
    }
    this.createForm();
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.disabletShortcuts.next(true);
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
          //document.getElementById('btn-save-country').click();
        },
        preventDefault: true,
      }
    );

    // 'intrastate-documents.new-position.addCode' | translate
  }

  visibleChange = (e) => {
    this.visibleShortcut = e;
    this.createForm(); //reset form with initial values
    this.submitted = false;

    if (!e) {
      setTimeout(() => {
        this.event.disabletShortcuts.next(true);
      }, 0);

      this.onClosing.emit(false);
    }
    this.isEdit = false;
  };

  createForm = () => {
    switch (this.type) {
      case 'transport':
        this.form = this.formBuilder.group({
          IntrastateTransportId: 0,
          TransportCode: ['', Validators.required],
          Name: '',
          IsDefault: false,
        });
        break;
      case 'delivery':
        this.form = this.formBuilder.group({
          IntrastateDeliveryId: 0,
          DeliveryCode: ['', Validators.required],
          Name: '',
          IsDefault: false,
        });
        break;
      case 'transaction':
        this.form = this.formBuilder.group({
          IntrastateTransactionId: 0,
          TransactionCode: ['', Validators.required],
          Name: '',
          IsDefault: false,
          StatisticalValueRequired: false,
        });
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible?.currentValue && this.selectedCode) {
      this.getId(this.selectedCodeId);
    }

    switch (this.type) {
      case 'delivery':
        this.url = `intrastates/intrastatedelivery`;
        this.code = 'DeliveryCode';
        this.codeId = 'IntrastateDeliveryId';
        this.title = this.translate.instant(
          'intrastate-documents.new-position.addDeliveryCode'
        );
        break;
      case 'transport':
        this.url = `intrastates/intrastatetransport`;
        this.code = 'TransportCode';
        this.codeId = 'IntrastateTransportId';
        this.title = this.translate.instant(
          'intrastate-documents.new-position.addTransportCode'
        );
        break;
      case 'transaction':
        this.url = `intrastates/intrastatetransaction`;
        this.code = 'TransactionCode';
        this.codeId = 'IntrastateTransactionId';
        this.title = this.translate.instant(
          'intrastate-documents.new-position.addTransactionCode'
        );
    }
  }

  getId = (id) => {
    this.appService
      .getAuth(`${this.url}?${this.codeId}=${id}`)
      .subscribe((res) => {
        this.form.patchValue(res.data[0]);
        this.isEdit = true;
      });
  };

  onSave = () => {
    this.form.markAllAsTouched();
    this.submitted = true;
    if (this.form.valid && !this.readOnly) {
      if (!this.isEdit) {
        this.appService.postAuth(`${this.url}`, this.form.value).subscribe(
          (res) => {
            this.onInserted.emit(res);
            this.isVisible = false;
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
      } else {
        this.appService
          .putAuth(`${this.url}/${this.selectedCodeId}`, this.form.value)
          .subscribe(
            (res) => {
              this.onInserted.emit(res);
              this.isVisible = false;
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    }
  };
}

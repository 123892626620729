import { DefaultObject, NestOperation } from './../../../nest/nest.model';
import { NestService } from './../../../nest-service.service';
import {
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ChangeDetectorRef,
  SimpleChanges,
  ViewChild,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValueChangedEvent } from 'devextreme/ui/number_box';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import {
  IArticlePositionDetail,
  IUnit,
  IOfferAfterPositionDelete,
} from 'src/app/crm/offers/IDocumentOffer';
import { Subscription } from 'rxjs';
import { NestFinishedEvent } from 'src/app/nest/nest.model';
import { Socket } from 'src/app/socket.model';
import { DxNumberBoxComponent } from 'devextreme-angular';
import { HelpService } from '../../../help-service.service';
import { QuestionsSaveOrder } from '../new-documents-orders/order.model';

@Component({
    selector: 'app-document-order-position',
    templateUrl: './document-order-position.component.html',
    styleUrls: ['./document-order-position.component.scss'],
    inputs: [
        'isVisible',
        'article',
        'position',
        'orderId',
        'customerId',
        'documentType',
        'documentDate',
        'currencyCode',
        'currencyFactor',
        'isIncome',
        'disableAmountToReservation',
        'isCurrencyDocument',
        'componentNests',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class DocumentOrderPositionComponent implements OnInit {
  @Input() parentObjectParameters: DefaultObject = {};
  @Output() nestParentUpdated: EventEmitter<DefaultObject> = new EventEmitter();
  nestObjectParameters: DefaultObject = null;
  @Output() onClosing = new EventEmitter();
  @Output() onAdding = new EventEmitter();
  @ViewChild('firstInputFocus') firstInputFocus: DxNumberBoxComponent;
  @ViewChild('gridBoxUnit') gridBoxUnit;
  componentNests: Socket;
  openNestFinished: boolean = false;
  isPaymentFormVisible: boolean = false;
  @ViewChild('btnSave') btnSave;
  isCurrencyDocument: boolean = false;
  documentDate;
  disableAmountToReservation: boolean = false;
  customerId: number;
  documentType;
  orderId;
  position;
  isVisible;
  article: any;
  remarksFault: boolean = false;
  isIncome: boolean = false;
  PayerName;
  DateDocument;
  currencyFactor;
  currencyCode;

  widthWindow = 600;
  submitted: boolean = false;
  format = '#0.0000';
  formatDivisible = '#0.0000';
  formatNonDivisible = '###,###,###,##0';
  isUnitDivisible = true;
  formatCurrency = '#0';
  IsBaseCurrency;
  positionTypeValue: boolean = false;

  PriceDescription: string = '';
  shortcuts: ShortcutInput[] = [];

  selectedAccordionItems = [];
  advancedTranslate: string = '';
  paymentMethod;
  addDescription: any;
  form: FormGroup;

  localStorageData = {
    skipUserGuide: false,
  };

  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  ArtykCenWyborPozDok: boolean = false;

  ArtykCenESDok: boolean = false;
  ArtykCenEZDok: boolean = false;
  ZamZeroVat: boolean = false;
  @HostListener('window:keydown.F10', ['$event'])
  addCompany(event: KeyboardEvent) {
    event.preventDefault();
  }

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public event: EventService,
    public appService: AppServices,
    private nestService: NestService,
    private helpService: HelpService,
    public cd: ChangeDetectorRef
  ) {
    this.ArtykCenESDok = this.event.checkPermissions('ArtykCenESDok');
    this.ArtykCenEZDok = this.event.checkPermissions('ArtykCenEZDok');
    const localData = localStorage.getItem(
      'positionDocumentOrderPositionComponent'
    );
    if (localData) {
      this.localStorageData = JSON.parse(localData);
    }

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    const FormPozOfe2 = this.event.getConfigurationParameter('FormPozOfe2');
    if (FormPozOfe2) {
      this.isPaymentFormVisible = FormPozOfe2.Value === 'z cenami';
      this.selectedAccordionItems = this.isPaymentFormVisible
        ? [this.advancedTranslate]
        : [];
    }

    const ZamZeroVat = this.event.getConfigurationParameter('ZamZeroVat', true);
    if (ZamZeroVat) {
      this.ZamZeroVat = ZamZeroVat.Value === 'Tak';
    }
  }

  ngOnDestroy() {
    this.isAutoOnboardingSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.createForm();

    if (window.innerWidth < 570) this.widthWindow = 360;

    this.translate.get('buttons.advanced').subscribe((text) => {
      this.advancedTranslate = text;
    });

    this.ArtykCenWyborPozDok = this.event.checkPermissions(
      'ArtykCenWyborPozDok'
    );
  }

  articleDetail: IArticlePositionDetail = null;
  createForm = () => {
    this.form = this.formBuilder.group({
      ProductId: this.article?.ProductId || 0,
      Amount: 1,
      Unit: ['', Validators.required],
      PriceNet: 0,
      PriceGross: 0,
      Discount: 0,
      TaxCode: '23',
      Description: '',
      PriceIdentificator: '',
      SerialNumber: '',
      ExpirationDate: [new Date()],
      Field1: null,
      Field2: null,
      Field3: null,
      Field4: null,
      Field5: null,
      Field6: null,
      Field7: null,
      Field8: null,
      Field9: null,
      Field10: null,
      OrderDocumentPositionId: 0,
      OrderDocumentId: this.orderId || 0,
      ProductVariantId: 0,
      AmountRealized: 0,
      AmountReserved: 1,
      AmountToReservation: 1,
      PriceNetCurrency: 0,
      PriceGrossCurrency: 0,
      Factor: 1,
      UserReservedAmount: 0,
      ToReservationPop: 0,
      StateRealized: 0,
      Type: 0,
      RegistrationType: 0,
      OfferDocumentPositionId: 0,
      LabelId: 0,
      OrderDocumenentPositionGuid: null,
      ReservationDeliveryId: 0,
      CharDiscount: 0,

      TotalNetAmount: 0,
      TotalGrossAmount: 0,
      TotalNetAmountCurrency: 0,
      TotalGrossAmountCurrency: 0,
    });
  };

  pricePerUnit: number = 0;
  pricePerUnitNet: number = 0;
  pricePerUnitCurrency: number = 0;
  pricePerUnitNetCurrency: number = 0;
  priceId = null;
  unitId = null;
  unit: IUnit = null;

  getArticleDetailParameters(id: number) {
    const docDate = this.event.dataFormatted({
      value: new Date(this.documentDate),
    });
    let obj: any = {
      DocumentId: this.orderId,
      DocumentType: this.documentType?.Signature,
      documentDate: docDate,
      IsBuy: this.documentType?.IsIncome,
      CustomerId: this.customerId,
      ProductId: id || this.article?.ProductId,
    };
    if (this.currencyCode && this.currencyFactor) {
      obj.currencyCode = this.currencyCode;
      obj.currencyFactor = this.currencyFactor;
    }

    return obj;
  }

  startUnitFactor = null;
  getArticleDetail(id: number) {
    return new Promise((resolve) => {
      this.appService
        .getAuth(
          `products/${
            id || this.article?.ProductId
          }/productDetailsForCustomer/${this.customerId}`,
          this.getArticleDetailParameters(id)
        )
        .subscribe(
          (res: IArticlePositionDetail) => {
            this.articleDetail = res;

            if (id === null) {
              this.unitId = res.UnitId;
              if (!this.unitId && res.Units.length) {
                this.unitId = res.Units[0].UnitId;
              }
              this.unit = this.getUnit();
              this.isUnitDivisible = this.unit?.IsDivisible;
              this.amountPerUnit = Number(
                (this.unit.Factor * this.form.value.Amount).toFixed(4)
              );

              this.form.patchValue({
                ProductId: id || this.article?.ProductId,
                OrderDocumentId: this.orderId,
                TaxCode: res.TaxCode,
                Unit: res.UnitCode || res.BaseUnitCode,
                Factor: this.unit?.Factor || 1,
                Amount:
                  this.article?.EnteredAmount == 0
                    ? 1
                    : this.article?.EnteredAmount || 1,
                Discount: res.PriceDetail.Discount,
                PriceNet: res.PriceDetail.PriceNet,
                PriceGross: res.PriceDetail.PriceGross,
                PriceIdentificator: res.PriceDetail.PriceIdentificator,
              });
              this.startUnitFactor = this.unit?.Factor || 1;
              this.PriceDescription = res.PriceDetail.PriceDescription;
              this.pricePerUnitNet = res.PriceDetail.PriceNetBase;
              this.pricePerUnit = res.PriceDetail.PriceGrossBase;

              this.priceId = res.DefaultPriceId;

              if (this.isCurrencyDocument) {
                this.form.patchValue({
                  PriceNetCurrency: res.PriceDetail.PriceNetCurrency,
                  PriceGrossCurrency: res.PriceDetail.PriceGrossCurrency,
                });
                this.pricePerUnitNetCurrency = Number(
                  (this.form.value.PriceNetCurrency / this.unit.Factor).toFixed(
                    4
                  )
                );
                this.pricePerUnitCurrency = Number(
                  (
                    this.form.value.PriceGrossCurrency / this.unit.Factor
                  ).toFixed(4)
                );
              }

              this.countDiscounts();
              this.countTotalAmounts();
            }

            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);

            resolve(false);
          }
        );
    });
  }

  checkFormatCurrency() {
    if (this.isIncome) {
      this.format = this.event.formatPLN;
    }

    if (!this.isIncome && this.isCurrencyDocument) {
      this.format = this.event.formatCurrency;
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.currencyCode) {
      this.format = changes.currencyCode?.currentValue ? '#0.0000' : '#0.00';
    }
    if (changes.isVisible?.currentValue && this.article?.ProductId) {
      // nowy
      this.findSocketAndRun('XGZPDO1');
    } else if (changes.isVisible?.currentValue && this.position) {
      // aktualizacja
      this.findSocketAndRun('XGZPPO1');
    }

    if (changes?.isVisible?.currentValue) {
      setTimeout(() => {
        if (
          !this.localStorageData.skipUserGuide &&
          this.isAutoOnboarding &&
          this.event.deviceType === 'desktop' &&
          this.isVisible
        ) {
          this.selectedAccordionItems = [this.advancedTranslate];
          this.isGuideActive = true;
          this.cd.detectChanges();
        }
      }, 2000);
    }
  }

  async openNewRecord() {
    await this.getArticleDetail(null);
    this.form.controls.OrderDocumentId.setValue(this.orderId);
    this.checkToSetZeroVat();
    this.findSocketAndRun('XGZPDO2');
  }

  async openOldRecord() {
    await this.getArticleDetail(this.position.ProductId);
    this.form.patchValue(this.position);
    this.startUnitFactor = this.form.value.Factor;
    this.setUpdateProperties(this.position);

    this.findSocketAndRun('XGZPPO2');
  }

  checkToSetZeroVat() {
    if (
      !this.ZamZeroVat ||
      !this.isCurrencyDocument ||
      !this.currencyCode ||
      this.currencyCode === 'PLN'
    )
      return;

    this.appService.getAuth(`customers/${this.customerId}`).subscribe({
      next: (res) => {
        const countryCode = res.CountryCode;
        const ueCustomer = res.IsUeCustomer;
        if (countryCode !== 'PL' || ueCustomer) {
          this.onTaxCodeChoosed([{ TaxCode: '0' }]);
        }
      },
      error: (error) => {
        this.event.httpErrorNotification(error);
      },
    });
  }

  setUpdateProperties(position) {
    this.unit = this.articleDetail.Units.find((el) => el.Code == position.Unit);
    this.isUnitDivisible = this.unit?.IsDivisible;

    this.unitId = this.unit.UnitId;
    this.priceId = null;
    this.amountPerUnit = this.form.value.Amount * this.unit.Factor;

    this.pricePerUnitNet = Number(
      (this.form.value.PriceNet / this.unit.Factor).toFixed(4)
    );
    this.pricePerUnit = Number(
      (this.form.value.PriceGross / this.unit.Factor).toFixed(4)
    );

    if (this.isCurrencyDocument) {
      this.pricePerUnitNetCurrency = Number(
        (this.form.value.PriceNetCurrency / this.unit.Factor).toFixed(4)
      );
      this.pricePerUnitCurrency = Number(
        (this.form.value.PriceGrossCurrency / this.unit.Factor).toFixed(4)
      );
    }

    this.countDiscounts();
  }

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.event.refreshKeyPressed.next();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) return;
          this.helpService.openHelp(
            'sprzedaz-i-magazyn/dokumenty/zamowienia/dokumenty-zamowien'
          );
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.closeWindow();
        },
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          if (this.isVisible) {
            this.addItem();
          }
        },
      },
      {
        key: 'F3',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.selectedAccordionItems = this.selectedAccordionItems.length
            ? []
            : [this.advancedTranslate];
        },
      }
    );
  }

  visibleChange = () => {
    if (!this.isVisible) {
      this.onClosing.emit(true);
      setTimeout(() => {
        this.openNestFinished = false;
      }, 500);
      this.createForm();
    }
  };

  onChoosedDescription = (e) => {
    let text = '';
    if (e) {
      e.forEach((element) => {
        text += `${element.Description}\n`;
      });

      this.form.controls.Description.setValue(text);
      this.remarksFault = false;
    }
  };

  closeWindow() {
    if (!this.position) {
      this.findSocketAndRun('XGZPDA1');
    } else {
      this.findSocketAndRun('XGZPPA1');
    }
  }

  positionTypeChanged = (e) => {
    this.form.controls.PositionType.setValue(e.value ? 'P' : '');
  };

  addItem = () => {
    if (!this.form.valid) {
      return;
    }

    if (this.form.value.Amount == 0) {
      this.event.showNotification(
        'warning',
        this.translate.instant(
          'warehouseDocument.pleaseEnterTheAmountOfGoodsServices'
        )
      );
      return;
    }
    if (this.form.value.AmountToReservation > this.form.value.Amount) {
      this.event.showNotification(
        'error',
        this.translate.instant('documentsOrders.cantReserveMore')
      );
      return;
    }
    if (
      this.disableAmountToReservation &&
      this.isIncome &&
      this.form.value.AmountToReservation >
        this.form.value.Amount - this.form.value.AmountRealized
    ) {
      this.event.showNotification(
        'error',
        this.translate.instant('documentsOrders.cantReserveMoreRealized')
      );
      return;
    }
    if (
      !this.disableAmountToReservation &&
      this.isIncome &&
      this.form.value.Amount <
        this.form.value.AmountToReservation + this.form.value.AmountRealized
    ) {
      this.event.showNotification(
        'error',
        this.translate.instant('documentsOrders.amountsValidation')
      );
      const newValue = this.form.value.Amount - this.form.value.AmountRealized;
      this.form.controls.AmountToReservation.setValue(newValue);
      return;
    }

    if(this.event.footerInfo.VariantName === 'FAKTUROWANIE PRO' || this.event.footerInfo.VariantName === 'ANY FAKTUROWANIE PRO'){
      this.form.controls.AmountToReservation.setValue(0);
    }

    if (this.form.value.OrderDocumentPositionId) {
      // update
      this.findSocketAndRun('XGZPPZ1');
    } else {
      this.findSocketAndRun('XGZPDZ1');
    }
  };

  updateItem() {
    // przeliczanie jezeli zmienil sie przelicznik
    this.formValueToSave = { ...this.form.value };
    // this.startUnitFactor !== this.form.value.Factor
    // if(this.startUnitFactor && this.startUnitFactor != 1) {
    //   const newAmountRealized = (
    //     this.form.value.AmountRealized / this.form.value.Factor
    //   ).toFixed(2);
    //   this.formValueToSave.AmountRealized = Number(newAmountRealized);
    //   const newAmountReserved = (
    //     this.form.value.AmountReserved / this.form.value.Factor
    //   ).toFixed(2);
    //   this.formValueToSave.AmountReserved = Number(newAmountReserved);
    //   const newUserReserved = (
    //     this.form.value.UserReservedAmount / this.form.value.Factor
    //   ).toFixed(2);
    //   this.formValueToSave.UserReservedAmount = Number(newUserReserved);
    // }
    if (
      this.startUnitFactor &&
      this.startUnitFactor !== this.form.value.Factor
    ) {
      const newAmountRealized = (
        this.form.value.AmountRealized / this.form.value.Factor
      ).toFixed(2);
      this.formValueToSave.AmountRealized = Number(newAmountRealized);
      const newAmountReserved = (
        this.form.value.AmountReserved / this.form.value.Factor
      ).toFixed(2);
      this.formValueToSave.AmountReserved = Number(newAmountReserved);
      const newUserReserved = (
        this.form.value.UserReservedAmount / this.form.value.Factor
      ).toFixed(2);
      this.formValueToSave.UserReservedAmount = Number(newUserReserved);
    }
    //this.formValueToSave.Amount = this.amountPerUnit;
    //this.formValueToSave.AmountToReservation *= this.formValueToSave.Factor;

    this.validateQuestions();
  }

  validateQuestions() {
    this.appService
      .postAuth(
        `orders/documents/${this.orderId}/positions/validateAddData`,
        this.formValueToSave
      )
      .subscribe({
        next: (res: QuestionsSaveOrder) => {
          if (res?.Questions?.length) {
            this.questionArray = res.Questions.map((el) => el.Question);
            this.textQuestion = this.questionArray[0];
            this.isQuestion = true;
            this.cd.detectChanges();
          } else {
            if (this.form.value.OrderDocumentPositionId) {
              this.updateItemAfterQuestions();
            } else {
              this.postItemAfterQuestions();
            }
          }
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  updateItemAfterQuestions() {
    this.appService
      .putAuth(
        `orders/documents/${this.orderId}/positions/${this.form.value.OrderDocumentPositionId}`,
        this.formValueToSave
      )
      .subscribe(
        (res: IOfferAfterPositionDelete) => {
          this.completedItem = res;
          this.startUnitFactor = null;
          this.findSocketAndRun('XGZPPZ2');
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  afterCompleteItem() {
    this.onAdding.emit(this.completedItem);
    this.isVisible = false;
    this.onClosing.emit(true);
    setTimeout(() => {
      this.openNestFinished = false;
    }, 500);
  }

  completedItem: IOfferAfterPositionDelete = null;
  formValueToSave: any = null;
  questionArray: string[] = [];
  textQuestion: string = null;
  isQuestion: boolean = false;
  postItem() {
    this.formValueToSave = { ...this.form.value };

    // this.formValueToSave.Amount = this.amountPerUnit;
    // this.formValueToSave.AmountToReservation =
    //   this.formValueToSave.AmountToReservation * this.formValueToSave.Factor;

    this.validateQuestions();
  }

  postItemAfterQuestions() {
    this.appService
      .postAuth(
        `orders/documents/${this.orderId}/positions/Add`,
        this.formValueToSave
      )
      .subscribe(
        (res: IOfferAfterPositionDelete) => {
          this.completedItem = res;
          this.findSocketAndRun('XGZPDZ2');
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  selectedTabIndex: number = 0;

  onTaxCodeChoosed(e) {
    if (e?.length) {
      this.form.controls.TaxCode.setValue(e[0].TaxCode);
      const taxCode = isNaN(Number(e[0].TaxCode)) ? 0 : Number(e[0].TaxCode);

      const gross = this.event.countAmountGross(
        this.form.value.PriceNet,
        taxCode
      );
      this.form.controls.PriceGross.setValue(Number(gross.toFixed(4)));
      const perUnitGross = this.event.countAmountGross(
        this.pricePerUnitNet,
        taxCode
      );
      this.pricePerUnit = Number(perUnitGross.toFixed(4));
      if (this.isCurrencyDocument) {
        const grossCurrency = this.event.countAmountGross(
          this.form.value.PriceNetCurrency,
          taxCode
        );
        this.form.controls.PriceGrossCurrency.setValue(
          Number(grossCurrency.toFixed(4))
        );
        const perUnitGrossCurrency = this.event.countAmountGross(
          this.pricePerUnitNetCurrency,
          taxCode
        );
        this.pricePerUnitCurrency = Number(perUnitGrossCurrency.toFixed(4));
      }
      this.countDiscounts();
      this.countTotalAmounts();
    }
  }

  onPriceChoosed(e) {
    if (!e) return;
    if (this.unitId === e[0].UnitId) return;

    if (e.length > 0) {
      this.pricePerUnitNet = e[0].NetPrice;
      this.pricePerUnit = e[0].GrossPrice;
      this.priceName = e[0].PriceName;
      this.priceId = e[0].PriceId;
    }
    const priceGross = this.pricePerUnit * this.form.value.Factor;
    this.form.controls.PriceGross.setValue(Number(priceGross.toFixed(4)));
    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    const priceNet = this.event.countAmountNet(
      this.form.value.PriceGross,
      taxCode
    );
    this.form.controls.PriceNet.setValue(Number(priceNet.toFixed(4)));
    this.PriceDescription = e[0].Description;
    if (this.isCurrencyDocument) {
      const priceGrossCurrency =
        this.pricePerUnitCurrency * this.form.value.Factor;
      this.form.controls.PriceGrossCurrency.setValue(
        Number(priceGrossCurrency.toFixed(4))
      );
      const priceNetCurrency = this.event.countAmountNet(
        this.form.value.PriceGrossCurrency,
        taxCode
      );
      this.form.controls.PriceNetCurrency.setValue(
        Number(priceNetCurrency.toFixed(4))
      );
    }

    this.countDiscounts();
    this.countTotalAmounts();
  }

  isGridBoxOpened: boolean = false;

  onRowDblClick = (e) => {
    this.isGridBoxOpened = false;
    this.unitId = e.data.UnitId;
    this.unit = this.getUnit();

    if (!this.unit) return;
    this.isUnitDivisible = this.unit?.IsDivisible;
    this.chooseUnit(this.unit);

    return;
  };

  getUnit(): IUnit {
    return this.articleDetail.Units.find((el) => el.UnitId == this.unitId);
  }

  checkAmount() {
    this.minAmount = this.form.value.AmountRealized / this.unit.Factor;
    if (this.form.value.Amount < this.minAmount) {
      this.event.showNotification(
        'error',
        this.translate.instant('documentsOrders.minOrderAmount') +
          this.minAmount
      );
      this.form.controls.Amount.setValue(this.minAmount, { emitEvent: false });
      this.amountPerUnit = Number(
        (this.form.value.Amount * this.unit.Factor).toFixed(4)
      );
      this.form.controls.AmountToReservation.setValue(0);
    }
    if (
      this.form.value.AmountToReservation >
      this.form.value.Amount - this.minAmount
    ) {
      this.form.controls.AmountToReservation.setValue(
        this.form.value.Amount - this.minAmount
      );
    }

    if (this.disableAmountToReservation) {
      // rez. automatyczna.
      const newAmountToReservation = this.form.value.Amount - this.minAmount;
      this.form.controls.AmountToReservation.setValue(newAmountToReservation);
    }
  }

  minAmount: number = 0;
  onAmountChanged(e: ValueChangedEvent) {
    if (!e.event) return;
    let value = e.value;
    const unit: IUnit = this.getUnit();
    const newFactor: number = value * unit.Factor;
    // od odbiorcy
    if (this.isIncome) {
      // rezerwacja automatyczna
      if (this.disableAmountToReservation) {
        if (this.form.value.AmountRealized > 0) {
          const newValue = value - this.form.value.AmountRealized / unit.Factor;
          this.form.controls.AmountToReservation.setValue(
            newValue > 0 ? newValue : 0
          );
        } else {
          this.form.controls.AmountToReservation.setValue(value);
        }
      }
    }

    this.amountPerUnit = Number(newFactor.toFixed(4));
    this.checkAmount();
    this.countTotalAmounts();

    if (!this.position) {
      this.findSocketAndRun('XGZPDILZ2');
    } else {
      this.findSocketAndRun('XGZPPILZ2');
    }
  }

  onAmountFactorChanged(e) {
    if (!e.event) return;
    const unit: IUnit = this.getUnit();
    const newAmount: number = e.value / unit.Factor;

    this.form.controls.Amount.setValue(Number(newAmount.toFixed(4)));
    if (this.disableAmountToReservation && this.isIncome) {
      if (this.form.value.AmountRealized > 0) {
        this.form.controls.AmountToReservation.setValue(
          Number(newAmount.toFixed(4)) - this.minAmount
        );
      } else {
        this.form.controls.AmountToReservation.setValue(
          Number(newAmount.toFixed(4))
        );
      }
    }

    this.countTotalAmounts();
  }

  onDiscountChanged(e) {
    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }
    this.countDiscounts();
    this.countTotalAmounts();
  }

  countDiscounts() {
    const amountNet: number =
      this.form.value.PriceNet +
      (this.form.value.Discount / 100) * this.form.value.PriceNet;
    const amountGross: number =
      this.form.value.PriceGross +
      (this.form.value.Discount / 100) * this.form.value.PriceGross;

    this.PriceGrossWithDiscount = Number(amountGross.toFixed(4));
    this.PriceNetWithDiscount = Number(amountNet.toFixed(4));

    if (this.isCurrencyDocument) {
      const amountNetCurrency: number =
        this.form.value.PriceNetCurrency +
        (this.form.value.Discount / 100) * this.form.value.PriceNetCurrency;
      const amountGrossCurrency: number =
        this.form.value.PriceGrossCurrency +
        (this.form.value.Discount / 100) * this.form.value.PriceGrossCurrency;

      this.PriceGrossCurrencyWithDiscount = Number(
        amountGrossCurrency.toFixed(4)
      );
      this.PriceNetCurrencyWithDiscount = Number(amountNetCurrency.toFixed(4));
    }
  }

  countTotalAmounts() {
    const amountNet: number =
      this.PriceNetWithDiscount * this.form.value.Amount;
    this.form.controls.TotalNetAmount.setValue(Number(amountNet.toFixed(4)));

    const amountGross: number =
      this.PriceGrossWithDiscount * this.form.value.Amount;
    this.form.controls.TotalGrossAmount.setValue(
      Number(amountGross.toFixed(4))
    );

    if (this.isCurrencyDocument) {
      const amountNetCurrency: number =
        this.PriceNetCurrencyWithDiscount * this.form.value.Amount;
      this.form.controls.TotalNetAmountCurrency.setValue(
        Number(amountNetCurrency.toFixed(4))
      );

      const amountGrossCurrency: number =
        this.PriceGrossCurrencyWithDiscount * this.form.value.Amount;
      this.form.controls.TotalGrossAmountCurrency.setValue(
        Number(amountGrossCurrency.toFixed(4))
      );
    }
  }

  countAmountPerUnit() {
    this.unit = this.getUnit();
    const perUnitNet = Number(
      (this.form.value.PriceNet / this.unit.Factor).toFixed(4)
    );
    this.pricePerUnitNet = perUnitNet;
    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    const gross = this.event.countAmountGross(perUnitNet, taxCode);
    this.pricePerUnit = Number(gross.toFixed(4));

    if (this.isCurrencyDocument) {
      const perUnitNetCurrency = Number(
        (this.form.value.PriceNetCurrency / this.unit.Factor).toFixed(4)
      );
      this.pricePerUnitNetCurrency = perUnitNetCurrency;

      const grossCurrency = this.event.countAmountGross(
        perUnitNetCurrency,
        taxCode
      );
      this.pricePerUnitCurrency = Number(grossCurrency.toFixed(4));
    }
  }

  onValueGrossChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    const net = this.event.countAmountNet(
      e.value,
      Number(this.form.value.TaxCode)
    );
    this.form.controls.PriceNet.setValue(Number(net));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onValueGrossCurrencyChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    const net = this.event.countAmountNet(
      e.value,
      Number(this.form.value.TaxCode)
    );
    this.form.controls.PriceNetCurrency.setValue(Number(net));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onValueNetChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    const gross = this.event.countAmountGross(e.value, taxCode);

    this.form.controls.PriceGross.setValue(Number(gross.toFixed(4)));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onValueNetCurrencyChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }
    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    const grossCurrency = this.event.countAmountGross(e.value, taxCode);

    this.form.controls.PriceGrossCurrency.setValue(Number(grossCurrency));

    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
  }

  onPricePerUnitChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    this.form.controls.PriceNet.setValue(
      Number((e.value * this.getUnit().Factor).toFixed(4))
    );

    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    this.pricePerUnit = Number(
      this.event.countAmountGross(this.pricePerUnitNet, taxCode)
    );

    const gross = this.event.countAmountGross(
      this.form.controls.PriceNet.value,
      taxCode
    );

    this.PriceDescription = this.translate.instant(
      'form-commercial-operation.modifiedByHand'
    );
    this.form.controls.PriceGross.setValue(Number(gross));

    this.countDiscounts();
    this.countTotalAmounts();
  }

  onPricePerUnitCurrencyChanged(e) {
    if (!e.event) return;

    if (e.event) {
      this.form.controls.PriceIdentificator.setValue('m');
    }

    this.form.controls.PriceNetCurrency.setValue(
      Number((e.value * this.getUnit().Factor).toFixed(4))
    );

    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    this.pricePerUnitCurrency = Number(
      this.event
        .countAmountGross(this.pricePerUnitNetCurrency, taxCode)
        .toFixed(4)
    );

    const gross = this.event.countAmountGross(
      this.form.controls.PriceNetCurrency.value,
      taxCode
    );

    this.form.controls.PriceGrossCurrency.setValue(Number(gross.toFixed(4)));
    this.PriceDescription = this.translate.instant(
      'form-commercial-operation.modifiedByHand'
    );
    this.countDiscounts();
    this.countTotalAmounts();
  }

  isDefaultMarkupVisible: boolean = false;

  setDefaultDiscountMarkup() {
    this.isDefaultMarkupVisible = true;
  }

  onDefaultMarkupChoosed(e) {
    this.isDefaultMarkupVisible = false;
    this.form.controls.Discount.setValue(e.discountMarkupPercent);

    this.countDiscounts();
    this.countTotalAmounts();
  }

  isPriceVisible: boolean = false;
  priceName: string = '';

  visibleUserExtensions: boolean = false;

  onInsertedUserExtensions = (e) => {
    this.form.patchValue(e);
  };

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
  };

  PriceNetWithDiscount: number = 0;
  PriceGrossWithDiscount: number = 0;
  PriceNetCurrencyWithDiscount: number = 0;
  PriceGrossCurrencyWithDiscount: number = 0;
  amountPerUnit: number = 1;

  nest: Socket;
  isNestRunning: boolean = false;
  nestOperation: NestOperation;
  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    this.nestObjectParameters = Object.assign({}, this.form.value);
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return false; // znalazlo
    } else {
      // nieznalazlo
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) return;
    if (e.updatedParameters?.ParentObject) {
      e.updatedParameters.ParentObject =
        this.nestService.removeNonEditableVariables(
          e.updatedParameters.ParentObject,
          e.SocketCode,
          true
        );
      this.nestParentUpdated.emit(e.updatedParameters?.ParentObject);
    }
    if (e.updatedParameters?.Object) {
      e.updatedParameters.Object = this.nestService.removeNonEditableVariables(
        e.updatedParameters.Object,
        e.SocketCode
      );
      this.form.patchValue(e.updatedParameters?.Object, { emitEvent: false });
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    switch (e.SocketCode) {
      case 'XGZPDO1':
        this.findSocketAndRun('XGZPAO1', 'add');
        break;
      case 'XGZPPO1':
        this.findSocketAndRun('XGZPAO1', 'edit');
        break;
      case 'XGZPAO1':
        this.openNestFinished = true;
        if (this.nestOperation === 'add') {
          this.openNewRecord();
        } else if (this.nestOperation === 'edit') {
          this.openOldRecord();
        }
        break;
      case 'XGZPDILZ2':
        this.findSocketAndRun('XGZPAILZ2');
        break;
      case 'XGZPPILZ2':
        this.findSocketAndRun('XGZPAILZ2');
        break;
      case 'XGZPDA1':
        this.findSocketAndRun('XGZPAA1', 'add');
        break;
      case 'XGZPPA1':
        this.findSocketAndRun('XGZPAA1', 'edit');
        break;
      case 'XGZPAA1':
        if (this.nestOperation === 'add') {
          this.findSocketAndRun('XGZPDA2');
        } else {
          this.findSocketAndRun('XGZPPA2');
        }
        break;
      case 'XGZPDA2':
        this.findSocketAndRun('XGZPAA2');
        break;
      case 'XGZPPA2':
        this.findSocketAndRun('XGZPAA2');
        break;
      case 'XGZPAA2':
        this.onClosing.emit(true);
        setTimeout(() => {
          this.openNestFinished = false;
        }, 500);
        break;
      case 'XGZPPZ1':
        this.findSocketAndRun('XGZPAZ1', 'edit');
        break;
      case 'XGZPDZ1':
        this.findSocketAndRun('XGZPAZ1', 'add');
        break;
      case 'XGZPAZ1':
        if (this.nestOperation === 'add') {
          this.postItem();
        } else {
          this.updateItem();
        }
        break;
      case 'XGZPDZ2':
        this.findSocketAndRun('XGZPAZ2');
        break;
      case 'XGZPPZ2':
        this.findSocketAndRun('XGZPAZ2');
        break;
      case 'XGZPAZ2':
        this.afterCompleteItem();
        break;
      default:
        break;
    }
  }

  isBlockedAccordion = false;
  onAccordionSelectionChanged(e) {
    if (this.isBlockedAccordion) return;
    this.isBlockedAccordion = true;
    this.updateAdvancedParameter(!!e.addedItems.length);
  }

  updateAdvancedParameter(value: boolean) {
    this.appService
      .postAuth(`configurations/users/currentUser/config`, [
        {
          FieldCode: 'FormPozZam',
          Value: value ? 'z cenami' : 'bez cen',
        },
      ])
      .subscribe({
        next: () => {},
        error: () => {},
        complete: () => {
          this.isBlockedAccordion = false;
        },
      });
  }

  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) this.isGuideButtonHighlighted = false;
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  onGuideFinished() {
    this.selectedAccordionItems = [];
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    localStorage.setItem(
      'positionDocumentOrderPositionComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  runGuide() {
    this.selectedAccordionItems = [this.advancedTranslate];
    this.isGuideActive = true;
  }

  isExpansionListVisible = false;
  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGZ');
    }
  }

  onAmountToReservationChanged(e) {
    if (!e.event) return;

    const newValue =
      e.value + this.form.value.AmountRealized / this.unit.Factor;
    if (newValue > this.form.value.Amount) {
      this.form.controls.Amount.setValue(newValue);
    }
  }

  chooseUnitAndQuantity = () => {
    // przeliczanie ilosci glownej
    if (this.focusedUnit != null) {
      const oldFactor = this.form.value.Factor;
      this.form.controls.Factor.setValue(this.focusedUnit.Factor);
      this.form.controls.Unit.setValue(this.focusedUnit.Code);
      //this.positionAmount = Number((this.form.value.Amount * this.focusedUnit.Factor).toFixed(4))
      this.form.controls.Amount.setValue(
        (oldFactor * this.amountPerUnit) / this.focusedUnit.Factor
      ); //e.value / this.unit.Factor
      this.form.controls.PriceNet.setValue(
        Number(
          (
            (this.pricePerUnitNet / oldFactor) *
            this.focusedUnit.Factor
          ).toFixed(4)
        )
      );

      const newAmountToReservation =
        this.form.value.AmountToReservation / this.focusedUnit.Factor;
      this.form.controls.AmountToReservation.setValue(newAmountToReservation);

      const taxCode = isNaN(Number(this.form.value.TaxCode))
        ? 0
        : Number(this.form.value.TaxCode);
      const gross = this.event.countAmountGross(
        this.form.value.PriceNet,
        taxCode
      );
      this.form.controls.PriceGross.setValue(Number(gross.toFixed(4)));

      if (this.isCurrencyDocument) {
        const priceGrossCurrency =
          this.pricePerUnitCurrency * this.form.value.Factor;
        this.form.controls.PriceGrossCurrency.setValue(
          Number(priceGrossCurrency.toFixed(4))
        );
        const priceNetCurrency = this.event.countAmountNet(
          this.form.value.PriceGrossCurrency,
          taxCode
        );
        this.form.controls.PriceNetCurrency.setValue(
          Number(priceNetCurrency.toFixed(4))
        );
      }

      this.unit = this.focusedUnit;
      this.isUnitDivisible = this.unit?.IsDivisible;
      this.unitId = this.focusedUnit.UnitId;
      this.checkAmount();
      this.countAmountPerUnit();
      this.countDiscounts();
      this.countTotalAmounts();
      this.isGridBoxOpened = false;
    }
  };

  chooseUnit = (unit: IUnit) => {
    // przeliczanie na podstawie ilosci glownej
    if (!unit) return;

    this.unit = unit;
    this.unitId = unit.UnitId;
    this.form.controls.Factor.setValue(unit.Factor);
    this.form.controls.Unit.setValue(unit.Code);
    this.amountPerUnit = Number(
      (this.form.value.Amount * unit.Factor).toFixed(4)
    );
    this.form.controls.PriceNet.setValue(
      Number((this.pricePerUnitNet * unit.Factor).toFixed(4))
    );
    const taxCode = isNaN(Number(this.form.value.TaxCode))
      ? 0
      : Number(this.form.value.TaxCode);
    const gross = this.event.countAmountGross(
      this.form.value.PriceNet,
      taxCode
    );
    this.form.controls.PriceGross.setValue(Number(gross.toFixed(4)));

    if (this.isCurrencyDocument) {
      const priceGrossCurrency =
        this.pricePerUnitCurrency * this.form.value.Factor;
      this.form.controls.PriceGrossCurrency.setValue(
        Number(priceGrossCurrency.toFixed(4))
      );
      const priceNetCurrency = this.event.countAmountNet(
        this.form.value.PriceGrossCurrency,
        taxCode
      );
      this.form.controls.PriceNetCurrency.setValue(
        Number(priceNetCurrency.toFixed(4))
      );
    }

    this.checkAmount();
    this.countAmountPerUnit();
    this.countDiscounts();
    this.countTotalAmounts();
    this.isGridBoxOpened = false;
    this.unitId = unit.UnitId;
  };

  focusedUnit: IUnit = null;
  onFocusedRowChanged = (e) => {
    this.focusedUnit = e.row.data;
  };

  yesContinue() {
    this.questionArray.shift();
    this.isQuestion = false;
    if (this.questionArray.length) {
      this.textQuestion = this.questionArray[0];
      setTimeout(() => {
        this.isQuestion = true;
        this.cd.detectChanges();
      });
    } else {
      if (this.form.value.OrderDocumentPositionId) {
        this.updateItemAfterQuestions();
      } else {
        this.postItemAfterQuestions();
      }
    }
  }

  noContinue() {
    this.isQuestion = false;
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { EventService } from 'src/app/event.service';

@Component({
    selector: 'app-position-verification-correction',
    templateUrl: './position-verification-correction.component.html',
    styleUrls: ['./position-verification-correction.component.scss'],
    standalone: false
})
export class PositionVerificationCorrectionComponent implements OnInit {
  @Input() isVisible: boolean;
  @Input() showPopupAnimationDuration: number;
  @Input() readOnly: boolean;
  @Input() correctAmount: number;
  @Output() correctAmountChange = new EventEmitter<number>();
  @Output() onSave = new EventEmitter<number>();
  @Output() onClose = new EventEmitter<boolean>();
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  shortcuts: ShortcutInput[] = [];

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isVisible) {
      if (changes.isVisible.currentValue) {
      }
    }
  }

  ngOnInit = () => {};

  ngAfterViewInit() {
    this.createKeyboardShortcuts();
  }

  createKeyboardShortcuts = () => {
    // if (this.shortcutsCreated) return;

    this.shortcuts = [
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeCorrection();
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.saveCorrection();
          }
        },
        preventDefault: true,
      },
    ];
  };

  saveCorrection = () => {
    this.isVisible = false;
    this.onSave.emit(this.correctAmount);
  };

  closeCorrection = () => {
    this.isVisible = false;
    this.onClose.emit(true);
    this.cd.detectChanges();
  };
}

<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  [title]="event.deviceType != 'mobile' ? title : ''"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="840"
  [minHeight]="280"
  *ngIf="openNestFinished"
  (onShown)="checkKeyboard(); event.onShownPopUp(); afterOpen()"
  (onHidden)="event.onHiddenPopUp(); Description = ''"
  [wrapperAttr]="{
    id: unicalGuid,
    'data-cy': 'form-position-warehouse-document'
  }"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{ boxShadow: scrollPosition > 0 }"
    >
      <dx-button
        class="btn-dx-top-menu-folder"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'form-commercial-operation.expenseSurge' | translate }}"
        (onClick)="isReleased = true; cd.detectChanges()"
        style="margin-right: 6px"
        [id]="'skip' + unicalGuid"
        *ngIf="isCorrect && documentType.IsIncome"
      >
      </dx-button>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="checkOstrzegajMinMax()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="save-button-position-warehouse-document"
        #btnSave
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="beforeCloseWindow()"
        [id]="'cancel' + unicalGuid"
        data-cy="cancel-button-position-warehouse-document"
      ></dx-button>

      <dx-tooltip
        [target]="'#fitToWidth' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "servicesDevices.fitToWidth" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#externalFields' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "categories.externalFields" | translate }} (Ctrl + P)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "menu.navigationPanelUser.help" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "form-commercial-operation.grid.articleName" | translate }}:
          <strong>{{ articleToAdd.ProductFullName }}</strong> |
          {{ "customerGroups.catalogIndex" | translate }}:
          <strong>{{ articleToAdd.IndexCatalogue }}</strong> |
          {{ "articles.availableAmount" | translate }}:
          <strong>{{ articleToAdd.StockAvailable }}</strong>
        </p>
      </div>

      <dx-tab-panel
        #tabPanel
        [(selectedIndex)]="selectedtab"
        [items]="items"
        [loop]="false"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        class=""
      >
        <div *dxTemplate="let item of 'title'; let i = index">
          <div class="title-temp">
            <span
              class="dx-tab-text"
              *ngIf="item == 1"
              [id]="'tabToolTipDoc' + i + unicalGuid"
              >{{ "companies.tabs.basicInfo" | translate }}</span
            >
            <span
              class="dx-tab-text"
              *ngIf="item == 2 && showMore"
              [id]="'tabToolTipDoc' + i + unicalGuid"
              >{{ "form-financial-document.tabs.other" | translate }}</span
            >
            <span
              class="dx-tab-text"
              *ngIf="item == 3 && form.TaxCode == 'ZW'"
              [id]="'tabToolTipDoc' + i + unicalGuid"
              >{{ "warehouseDocument.DataForJPK" | translate }}</span
            >

            <dx-tooltip
              [target]="'#tabToolTipDoc' + i + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="mouseleave"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data = data; of: 'content'">
                (Alt+{{ i + 1 }})
              </div>
            </dx-tooltip>
          </div>
        </div>

        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div *ngIf="name == 1" style="padding-top: 1px">
              <div class="row">
                <div
                  class="col-md-6"
                  style="border-right: 1px solid var(--neutral-80); padding-right: 30px"
                >
                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="!isCorrect"
                  >
                    {{ "form-commercial-operation.grid.count" | translate }}
                  </label>
                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="isCorrect"
                  >
                    {{ "warehouseDocument.correctedQuantity" | translate }}
                  </label>

                  <dx-number-box
                    [showSpinButtons]="true"
                    [min]="0"
                    [width]="220"
                    [useLargeSpinButtons]="true"
                    style="display: inline-block; margin-bottom: 6px"
                    [(ngModel)]="form.Quantity"
                    class=""
                    (onValueChanged)="onValueChangedQuantity()"
                    #firstFocused
                    valueChangeEvent="input"
                    tabindex="0"
                    (keydown.Tab)="
                      setFocus(
                        this.OmijajIloscJedMag
                          ? this.priceNetInput
                          : this.FactorInput
                      )
                    "
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    [format]="
                      IsDivisible ? formatDivisible : formatNotDivisible
                    "
                    [max]="AmountPrevious"
                    (onEnterKey)="event.setFocus(gridBoxUnit)"
                    data-cy="quantity-input-position-warehouse-document"
                  >
                  </dx-number-box
                  ><br />

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "articles.sizeUnit" | translate }}
                  </label>
                  <!--
                  <app-size-units width="160px" [dropDownBoxMode]="true" [readOnly]="readOnly"
                    [selectedCode]="form.UnitId" [articleId]="selected.ProductId" (onChoosed)="onUnitChoosed($event)"
                    style="display: inline-block; margin-bottom: 6px;">
                  </app-size-units> -->

                  <dx-drop-down-box
                    #gridBoxUnit
                    [(value)]="form.UnitId"
                    valueExpr="UnitId"
                    [deferRendering]="false"
                    displayExpr="Code"
                    [(opened)]="isGridBoxOpened"
                    [dataSource]="form.Units"
                    [tabIndex]="-1"
                    [showClearButton]="false"
                    style="
                      width: 220px;
                      display: inline-block;
                      margin-bottom: 6px;
                    "
                    class=""
                    (onValueChanged)="onUnitChoosed()"
                    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
                    [ngClass]="{ 'input-required': className == true }"
                    [readOnly]="readOnly || isCorrect"
                  >
                    <div
                      *dxTemplate="let data of 'content'"
                      style="height: 280px"
                    >
                      <div class="row">
                        <div class="col-md-12" class="no-padding">
                          <dx-data-grid
                            [dataSource]="form.Units"
                            [wordWrapEnabled]="true"
                            [showBorders]="true"
                            shortcut
                            [height]="258"
                            class="dropDownBoxClassGrid"
                            [columnResizingMode]="'widget'"
                            [remoteOperations]="true"
                            (onRowDblClick)="onRowDblClick($event)"
                            [columnAutoWidth]="true"
                            [allowColumnResizing]="true"
                            (onFocusedRowChanged)="onFocusedRowChanged($event)"
                            [id]="'grid' + unicalGuid"
                            [focusedRowEnabled]="true"
                            #gridClass
                            [hoverStateEnabled]="true"
                          >
                            <dxo-load-panel [enabled]="false"></dxo-load-panel>
                            <dxo-scrolling mode="infinite"></dxo-scrolling>
                            <dxo-paging [pageSize]="50"></dxo-paging>
                            <dxo-selection
                              mode="single"
                              showCheckBoxesMode="always"
                            >
                            </dxo-selection>

                            <dxi-column
                              caption="{{ 'articles.shortcut' | translate }}"
                              width="60"
                              dataField="Code"
                              [allowSorting]="false"
                              cellTemplate="Code"
                              headerCellTemplate="CodeHeaderTemplate"
                            >
                            </dxi-column>
                            <div
                              *dxTemplate="let data of 'CodeHeaderTemplate'"
                              style="padding-left: 10px"
                            >
                              {{ "articles.shortcut" | translate }}
                            </div>
                            <div
                              *dxTemplate="let cell of 'Code'"
                              style="padding-left: 10px"
                            >
                              {{ cell.value }}
                            </div>
                            <dxi-column
                              caption="{{ 'articles.unit' | translate }}"
                              dataField="UnitName"
                              [allowSorting]="false"
                            ></dxi-column>

                            <dxi-column
                              caption="{{ 'articles.converter' | translate }}"
                              dataField="Factor"
                              [allowSorting]="false"
                              width="100"
                              alignment="left"
                            ></dxi-column>

                            <!-- <dxi-column caption="{{'form-commercial-operation.grid.count' | translate}}"
                              dataField="Quantity" [allowSorting]="false" [width]="100" alignment="left"></dxi-column> -->
                          </dx-data-grid>
                        </div>
                      </div>

                      <div class="bottom-drop-box popup-footer">
                        <dx-button
                          class=""
                          type="danger"
                          [text]="
                            'warehouseDocument.selectUnitAndQuantity'
                              | translate
                          "
                          [disabled]="readOnly"
                          (onClick)="chooseUnitAndQuantity()"
                        >
                        </dx-button>

                        <dx-button
                          class=""
                          type="success"
                          [text]="'buttons.choose' | translate"
                          [disabled]="readOnly"
                          (onClick)="chooseUnit()"
                        >
                        </dx-button>
                      </div>
                    </div>
                  </dx-drop-down-box>
                  <br />
                </div>

                <div class="col-md-6">
                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "articles.amountInJMag" | translate }}
                  </label>
                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly"
                    valueChangeEvent="input"
                    [showSpinButtons]="true"
                    [min]="0"
                    [width]="160"
                    [useLargeSpinButtons]="true"
                    [(ngModel)]="form.PriceDetail.Factor"
                    (onValueChanged)="onValueChangedQuantityBase()"
                    #FactorInput
                    (keydown.Tab)="setFocus(priceNetInput)"
                    [format]="
                      IsDivisibleBase ? formatDivisible : formatNotDivisible
                    "
                    (onFocusIn)="
                      setFocusedElemetntRef('PriceDetail.Factor', FactorInput);
                      event.onNumberBoxFocusIn($event)
                    "
                    [max]="AmountPrevious"
                  >
                  </dx-number-box
                  ><br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "warehouseDocument.stockUnit" | translate }}
                  </label>
                  <dx-text-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    valueChangeEvent="input"
                    [(ngModel)]="form.BaseUnitCode"
                  >
                  </dx-text-box
                  ><br />
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex justify-content-center flex-wrap">
                    <div class="col-md-12" style="padding-top: 20px">
                      <dx-accordion
                        [dataSource]="dataSourceAccordion"
                        [collapsible]="true"
                        [multiple]="false"
                        [animationDuration]="300"
                        [selectedItems]="selectedAccordionItems"
                        (onSelectionChanged)="changeShowMore()"
                        class="internalAccordion offer-pos-accordion no-padding"
                      >
                        <div *dxTemplate="let company of 'item'">
                          <div class="row">
                            <div
                              class="col-md-6"
                              style="
                                border-right: 1px solid var(--neutral-80);
                                padding-right: 30px;
                              "
                            >
                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                                *ngIf="!destinationWarehouseId"
                              >
                                {{ "customerGroups.price" | translate }}
                              </label>
                              <app-article-price-position-document
                                [isVisible]="priceVisible"
                                [readOnly]="readOnly || !ArtykCenWyborPozDok"
                                [title]="
                                  articleToAdd?.ProductFullName?.length < 100
                                    ? articleToAdd?.ProductFullName
                                    : articleToAdd?.ProductFullName?.substring(
                                        0,
                                        100
                                      ) + '...'
                                "
                                [productId]="selected"
                                [customerId]="customerId"
                                width="220"
                                [PriceName]="form.PriceDetail.PriceName"
                                (onChoosed)="onChoosedPrice($event)"
                                [DocumentDate]="documentDate"
                                tabIndex="-1"
                                *ngIf="!destinationWarehouseId"
                              >
                              </app-article-price-position-document>
                              <br
                                *ngIf="
                                  event.deviceType != 'mobile' &&
                                  !destinationWarehouseId
                                "
                              />

                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                                *ngIf="!destinationWarehouseId"
                              >
                              </label>
                              <dx-text-box
                                width="220"
                                [readOnly]="true"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [value]="PriceDescription"
                                *ngIf="!destinationWarehouseId"
                              ></dx-text-box
                              ><br *ngIf="!destinationWarehouseId" />

                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                                [ngClass]="{
                                  mobileMargin: event.deviceType == 'mobile'
                                }"
                                *ngIf="sumBy.trim() == 'Netto'"
                              >
                                {{
                                  "form-commercial-operation.grid.netAmount"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                width="220"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="
                                  readOnly ||
                                  (!documentType.IsIncome && !ArtykCenESDok) ||
                                  (documentType.IsIncome && !ArtykCenEZDok)
                                "
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                class=""
                                [(ngModel)]="form.PriceDetail.PriceNet"
                                (onInput)="onValueNetChange()"
                                (keyup.arrowdown)="onValueNetChange()"
                                (keyup.arrowup)="onValueNetChange()"
                                [tabIndex]="3"
                                #priceNetInput
                                (keydown.Tab)="setFocus(priceDiscount)"
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.PriceNet',
                                    priceNetInput
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                                [min]="0"
                                *ngIf="sumBy.trim() == 'Netto'"
                              >
                              </dx-number-box>

                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                                *ngIf="sumBy.trim() == 'Brutto'"
                              >
                                {{
                                  "form-commercial-operation.grid.grossAmount"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                *ngIf="sumBy.trim() == 'Brutto'"
                                width="220"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="
                                  readOnly ||
                                  (!documentType.IsIncome && !ArtykCenESDok) ||
                                  (documentType.IsIncome && !ArtykCenEZDok)
                                "
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                [(ngModel)]="form.PriceDetail.PriceGross"
                                (keyup.arrowdown)="onValueGrossChange()"
                                (keyup.arrowup)="onValueGrossChange()"
                                (onInput)="
                                  onValueGrossChange(); changeDescription()
                                "
                                #priceGrosstInput
                                (keydown.Tab)="setFocus(inputDiscount)"
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.PriceGross',
                                    priceGrosstInput
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                                [min]="0"
                              >
                              </dx-number-box>

                              <br *ngIf="event.deviceType != 'mobile'" />
                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                              >
                                {{ "servicesDevices.VATcode" | translate }}
                              </label>
                              <!-- [taxCode]="form.get('TaxCode').value" -->
                              <app-tax-rates
                                [dropDownBoxMode]="true"
                                [readOnly]="readOnly"
                                (onChoosed)="onBuyTaxChanged($event)"
                                [width]="220"
                                [ngClass]="{
                                  displayBloc: event.deviceType == 'mobile'
                                }"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [taxCode]="form.TaxCode"
                              >
                              </app-tax-rates>

                              <br *ngIf="event.deviceType != 'mobile'" />

                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                                [ngClass]="{
                                  mobileMargin: event.deviceType == 'mobile'
                                }"
                                *ngIf="sumBy.trim() == 'Brutto'"
                              >
                                {{
                                  "form-commercial-operation.grid.netAmount"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                width="220"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="
                                  readOnly ||
                                  (!documentType.IsIncome && !ArtykCenESDok) ||
                                  (documentType.IsIncome && !ArtykCenEZDok)
                                "
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                class=""
                                [(ngModel)]="form.PriceDetail.PriceNet"
                                (onInput)="onValueNetChange()"
                                (keyup.arrowdown)="onValueNetChange()"
                                (keyup.arrowup)="onValueNetChange()"
                                [tabIndex]="3"
                                #priceNetInput
                                (keydown.Tab)="setFocus(priceDiscount)"
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.PriceNet',
                                    priceNetInput
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                                [min]="0"
                                *ngIf="sumBy.trim() == 'Brutto'"
                              >
                              </dx-number-box>
                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                                *ngIf="sumBy.trim() == 'Netto'"
                              >
                                {{
                                  "form-commercial-operation.grid.grossAmount"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                *ngIf="sumBy.trim() == 'Netto'"
                                width="220"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="
                                  readOnly ||
                                  (!documentType.IsIncome && !ArtykCenESDok) ||
                                  (documentType.IsIncome && !ArtykCenEZDok)
                                "
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                [(ngModel)]="form.PriceDetail.PriceGross"
                                (keyup.arrowdown)="onValueGrossChange()"
                                (keyup.arrowup)="onValueGrossChange()"
                                (onInput)="
                                  onValueGrossChange(); changeDescription()
                                "
                                #priceGrosstInput
                                (keydown.Tab)="setFocus(inputDiscount)"
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.PriceGross',
                                    priceGrosstInput
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                                [min]="0"
                              >
                              </dx-number-box>

                              <label
                                style="
                                  width: 135px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                              >
                                {{ "form-payment.discountMarkUp" | translate }}
                              </label>
                              <dx-number-box
                                [readOnly]="
                                  readOnly || !isPermissionToChangeDiscount
                                "
                                valueChangeEvent="input"
                                format="#0.00%"
                                [showSpinButtons]="true"
                                [useLargeSpinButtons]="true"
                                [step]="0.01"
                                width="178"
                                style="display: inline-block"
                                [(ngModel)]="form.PriceDetail.Discount"
                                #inputDiscount
                                (onValueChanged)="changeAmount()"
                                (keydown.Tab)="setFocus(valueGross)"
                                [ngClass]="{
                                  oneIconBeside: event.deviceType == 'mobile'
                                }"
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.Discount',
                                    inputDiscount
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                              >
                              </dx-number-box>

                              <dx-button
                                class="btn-dx-secondary btn-secondary-only-icon warehouse-documents-form-position-step-1"
                                style="
                                  width: 36px !important;
                                  margin-left: 6px;
                                  height: 28px;
                                "
                                icon="icon absui-icon--calculator-loan"
                                [disabled]="
                                  readOnly || !isPermissionToChangeDiscount
                                "
                                [id]="'btn-discount' + unicalGuid"
                                (onClick)="isDefaultMarkupVisible = true"
                              >
                              </dx-button>
                              <br />
                            </div>
                            <div class="col-md-6">
                              <label
                                style="
                                  width: 138px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                              >
                                {{
                                  "warehouseDocument.priceInUnitWarehouses"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                width="160"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="
                                  readOnly ||
                                  (!documentType.IsIncome && !ArtykCenESDok) ||
                                  (documentType.IsIncome && !ArtykCenEZDok)
                                "
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                [(ngModel)]="form.PriceDetail.PriceNetBase"
                                (onInput)="
                                  onValueWarhaouseAmount(); changeDescription()
                                "
                                (keyup.arrowdown)="onValueWarhaouseAmount()"
                                (keyup.arrowup)="onValueWarhaouseAmount()"
                                #inputPriceDiscount
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.PriceNetBase',
                                    inputPriceDiscount
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                                [min]="0"
                              >
                              </dx-number-box
                              ><br />

                              <label
                                style="
                                  width: 138px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                              >
                                {{ "offers.priceWithDiscount" | translate }}
                              </label>
                              <dx-number-box
                                width="160"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="true"
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                #priceDiscount
                                [(ngModel)]="form.PriceDetail.PriceWithDiscount"
                                (keydown.Tab)="setFocus(priceGrosstInput)"
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.PriceWithDiscount',
                                    priceDiscount
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                              >
                              </dx-number-box>
                              <br />

                              <label
                                style="
                                  width: 138px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                              >
                                {{
                                  "form-commercial-operation.grid.netValue"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                width="160"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="true"
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                [(ngModel)]="form.PriceDetail.AmountNet"
                              >
                              </dx-number-box
                              ><br />

                              <label
                                style="
                                  width: 138px;
                                  display: inline-block;
                                  text-align: right;
                                  margin-right: 10px;
                                "
                              >
                                {{
                                  "form-commercial-operation.grid.grossValue"
                                    | translate
                                }}
                              </label>
                              <dx-number-box
                                width="160"
                                style="
                                  display: inline-block;
                                  margin-bottom: 6px;
                                "
                                [readOnly]="readOnly"
                                [format]="event.formatPLN"
                                valueChangeEvent="input"
                                [(ngModel)]="form.PriceDetail.AmountGross"
                                #valueGross
                                (keydown.Tab)="setFocus(firstFocused)"
                                (onInput)="onValueGrossAmountGross($event)"
                                (keyup.arrowdown)="
                                  onValueGrossAmountGross($event)
                                "
                                (keyup.arrowup)="
                                  onValueGrossAmountGross($event)
                                "
                                (onFocusIn)="
                                  setFocusedElemetntRef(
                                    'PriceDetail.AmountGross',
                                    valueGross
                                  );
                                  event.onNumberBoxFocusIn($event)
                                "
                                [min]="0"
                              >
                              </dx-number-box>
                            </div>
                          </div>
                        </div>
                      </dx-accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="name == 2">
              <div class="row">
                <div class="col-md-6 text-right">
                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                      position: relative;
                    "
                    >{{
                      "form-financial-document.description" | translate
                    }}</label
                  >
                  <app-description-dictionary
                    [dropDownBoxMode]="true"
                    type="O"
                    heightArea="380"
                    style="margin-bottom: 6px"
                    title="{{ 'comments' | translate }}"
                    (onChoosed)="onChoosedDescription($event)"
                    [(ngModel)]="Description"
                  >
                  </app-description-dictionary
                  ><br />
                </div>

                <div class="col-md-6 text-right" style="padding-right: 15px">
                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                      position: relative;
                    "
                    >{{ "deliveries.serialNo" | translate }}</label
                  >
                  <dx-text-box
                    width="276"
                    style="
                      display: inline-block;
                      margin-bottom: 6px;
                      margin-top: 7px;
                    "
                    [readOnly]="readOnly"
                    valueChangeEvent="input"
                    [(ngModel)]="SerialNumber"
                  >
                  </dx-text-box
                  ><br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                      position: relative;
                    "
                    >{{ "articles.certificateDate" | translate }}</label
                  >
                  <wapro-date-box
                    [readOnly]="readOnly"
                    type="date"
                    width="276"
                    [displayFormat]="event.dateFormat"
                    style="display: inline-block"
                    [(ngModel)]="ExpirationDate"
                  >
                  </wapro-date-box
                  ><br />

                  <dx-check-box
                    text="{{ 'articles.modifyPrices' | translate }}"
                    class="position-check"
                    style="
                      display: inline-block;
                      margin-bottom: 11px;
                      margin-top: 5px;
                      height: 20px;
                      width: 276px;
                    "
                    [readOnly]="true"
                    [(ngModel)]="positionForm.IsModifyPriceWhenDelivery"
                    [ngClass]="{ marginMobile: event.deviceType == 'mobile' }"
                  ></dx-check-box
                  ><br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                    >{{
                      "warehouseDocument.descriptionOfThePrice" | translate
                    }}</label
                  >
                  <dx-text-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceDescription"
                  >
                  </dx-text-box
                  ><br />

                  <div class="c-frame-row">
                    <h5 style="text-align: left; margin-top: 15px">
                      {{ "warehouseDocument.defaultSellingPrices" | translate }}
                    </h5>
                  </div>

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                    >{{ "form-commercial-operation.net" | translate }}</label
                  >
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    [format]="event.formatPLN"
                    valueChangeEvent="input"
                    [(ngModel)]="positionForm.PriceNetSale"
                  >
                  </dx-number-box
                  ><br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                    >{{ "form-commercial-operation.gross" | translate }}</label
                  >
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    [format]="event.formatPLN"
                    valueChangeEvent="input"
                    [(ngModel)]="positionForm.PriceGrossSale"
                  >
                  </dx-number-box>

                  <div
                    class="d-flex flex-row c-frame-row"
                    style="margin-top: 10px"
                  >
                    <h5 style="text-align: left">
                      {{ "articles.recyclingTax" | translate }}
                    </h5>
                  </div>

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                    >{{ "form-commercial-operation.net" | translate }}</label
                  >
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly"
                    [format]="event.formatPLN"
                    valueChangeEvent="input"
                    [(ngModel)]="positionForm.PriceNetWasteManagement"
                    (onValueChanged)="onValueChangedWasteManagement('net')"
                  >
                  </dx-number-box
                  ><br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                    >{{ "form-commercial-operation.gross" | translate }}</label
                  >
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly"
                    [format]="event.formatPLN"
                    valueChangeEvent="input"
                    [(ngModel)]="positionForm.PriceGrossWasteManagement"
                    (onValueChanged)="onValueChangedWasteManagement('gross')"
                  >
                  </dx-number-box>
                </div>
              </div>
            </div>

            <div *ngIf="name == 3">
              <label
                style="
                  width: 185px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
                >{{
                  "warehouseDocument.vehicleMileageHours" | translate
                }}</label
              >
              <dx-text-box
                width="276"
                style="display: inline-block; margin-bottom: 6px"
                [readOnly]="readOnly"
                valueChangeEvent="input"
                [(ngModel)]="form.Mileage"
              >
              </dx-text-box
              ><br />

              <label
                style="
                  width: 185px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
                >{{ "warehouseDocument.ReleaseDateForUse" | translate }}</label
              >
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                [(ngModel)]="form.DateOfApprovalForUse"
                [readOnly]="readOnly"
              >
              </wapro-date-box
              ><br />

              <div class="c-frame-row">
                <h5
                  style="
                    margin-top: 20px;
                    text-align: left;
                    width: 100%;
                    padding-left: 10px;
                  "
                >
                  {{ "warehouseDocument.VATexemptionOnTheBasisOf" | translate }}
                </h5>
              </div>

              <dx-radio-group
                [items]="radioType"
                layout="horizontal"
                [readOnly]="readOnly"
                style="margin-left: 190px"
                [(ngModel)]="form.ExemptionCode"
                displayExpr="label"
                valueExpr="value"
              ></dx-radio-group
              ><br />

              <label
                style="
                  width: 185px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
                >{{
                  "warehouseDocument.DescriptionOfTheExemption" | translate
                }}</label
              >
              <app-description-dictionary
                [dropDownBoxMode]="true"
                type="O"
                heightArea="100"
                [widthArea]="400"
                title="{{
                  'warehouseDocument.DescriptionOfTheExemption' | translate
                }}"
                (onChoosed)="onChoosedDescriptionJPK($event)"
                [(ngModel)]="form.ExemptionDescription"
                [readOnly]="readOnly"
              >
              </app-description-dictionary>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>

      <app-touch-keyboard
        [isVisible]="FormPozDokMagKD"
        [focusedElemetntRef]="focusedElemetntRef"
        (onTab)="nextTab()"
        (onEnter)="onEnter($event)"
        (onClosed)="FormPozDokMagKD = false"
      ></app-touch-keyboard>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'warehouse-documents-form-position'"
></app-user-guide>

<app-discount-markup-factor
  [isVisible]="isDefaultMarkupVisible"
  (onClosed)="isDefaultMarkupVisible = false"
  [valueBefore]="form.PriceDetail.PriceNet"
  [discountMarkupPercent]="form.PriceDetail.DiscountOryginal"
  (onChoosed)="onDefaultMarkupChoosed($event)"
>
</app-discount-markup-factor>

<!-- pola dodatkowe -->
<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Pozycja_dokumentu_magazynowego"
  [readOnly]="readOnly"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="positionForm"
  #isValidUserExtensions
  *ngIf="isVisible"
>
</app-form-user-extensions>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="
    !isVisible ||
    visibleUserExtensions ||
    priceVisible ||
    isExpansionListVisible
  "
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGMP'"
  [objectParameters]="nestObjectParameters"
  [parentObjectParameters]="parentObjectParameters"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGMP'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-confirm-dialog
  *ngIf="isConfirmVisible"
  kindDialog="question"
  [confirmText]="confirmText"
  [isVisible]="true"
  (onRemoving)="yesContinue()"
  (onClosing)="noContinue()"
>
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isPriceZero"
  kindDialog="question"
  [confirmText]="'form-commercial-operation.doYouWantApprovePositionWithPrice0'"
  [isVisible]="true"
  (onRemoving)="yesApprove()"
  (onClosing)="noApprove()"
>
</app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isOstrzegajMinMax"
  kindDialog="question"
  [confirmText]="textOstrzegajMinMax"
  [isVisible]="true"
  (onRemoving)="onSave()"
  (onClosing)="noOstrzegajMinMax()"
>
</app-confirm-dialog>

<app-confirm-dialog
  kindDialog="question"
  *ngIf="isExpenditureOverload"
  confirmText="{{
    'form-commercial-operation.whetherToStartTheOutflowOvervoltageOperationFromTheCorrectedPosition'
      | translate
  }}"
  [isVisible]="true"
  (onRemoving)="yesExpenditureOverload()"
  (onClosing)="isExpenditureOverload = false; cd.detectChanges()"
>
</app-confirm-dialog>

<app-released
  [isVisible]="true"
  *ngIf="isReleased"
  [warehouseDocumentId]="positionForm.WarehouseDocumentId"
  [position]="selected"
  (onClosing)="isReleased = false; cd.detectChanges()"
></app-released>

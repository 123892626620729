import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CustomWindowConfig } from '../../../../event.model';
import { ShortcutInput } from 'ng-keyboard-shortcuts';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../../../../event.service';
import { FormBuilder } from '@angular/forms';
import DataSource from 'devextreme/data/data_source';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { environment } from '../../../../../environments/environment';
import { LoadOptions } from 'devextreme/data';

@Component({
    selector: 'app-orders-service-delivery-address',
    templateUrl: './orders-service-delivery-address.component.html',
    styleUrls: ['./orders-service-delivery-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class OrdersServiceDeliveryAddressComponent {
  @ViewChild('gridClass') gridClass;

  @Input() isVisible: boolean;
  @Input() readOnly: boolean;

  @Output() onClosing = new EventEmitter();

  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'Code',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: true,
      height: 0,
    },
    mainPanelWidth: '',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 0,
    },
    windowSize: {
      isFullscreen: false,
      width: '75%',
      height: '75%',
    },
    params: [],
  };
  minWidth = null;
  maxHeight = null;
  loadChildComponent: boolean = false;
  unicalGuid: number;
  title;
  emptyStateHeight: any;

  scrollPosition = 0;

  dataSource;
  selectedRows = [];
  focusedSelected = [];
  focusedRowIndex;
  isQuickPress: boolean = true;
  contextMenu;
  shortcuts: ShortcutInput[] = [];
  heightGrid = 121;
  deliveryAddressMode = 'add';

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.minWidth = this.event.setMinWidthPopUp();
    this.maxHeight = this.event.setMaxHeight;
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.title = this.translate.instant(
      'serviceOrdersDeliveryAddress.titleDeliveryAddress'
    );

    this.contextMenu = [
      {
        text: 'Dodaj',
        icon: 'icon absui-icon--add-circle',
        disabled: false,
        itemIndex: 0,
      },
      {
        text: 'Popraw',
        icon: 'icon absui-icon--mode-edit',
        disabled: this.readOnly || this.focusedSelected.length === 0,
        itemIndex: 1,
      },
      {
        text: 'Usuń',
        icon: 'icon absui-icon--highlight-off',
        disabled: this.readOnly || this.focusedSelected.length === 0,
        itemIndex: 2,
      },
      {
        text: 'Pokaż',
        icon: 'icon absui-icon--form-items',
        disabled: this.focusedSelected.length === 0,
        itemIndex: 3,
      },
    ];
  }

  ngOnInit() {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.isVisible?.currentValue &&
      changes?.isVisible?.currentValue !== changes?.isVisible?.previousValue
    ) {
    }
  }

  fullScreen() {
    setTimeout(() => {
      if (this.localStorageData.windowSize.height == '75%') {
        this.emptyStateHeight = window.innerHeight * 0.75;
      } else {
        this.emptyStateHeight = this.localStorageData.windowSize.height;
      }

      if (this.localStorageData.windowSize.isFullscreen) {
        this.emptyStateHeight = window.innerHeight;
      }

      if (this.emptyStateHeight > this.maxHeight) {
        this.emptyStateHeight = this.maxHeight;
      }
    }, 0);
  }

  ngAfterViewInit() {
    this.fullScreen();

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
    this.translate
      .get('buttons.show')
      .subscribe((text) => (this.contextMenu[3].text = text));
  }

  visibleChange() {
    if (!this.isVisible) {
      this.onClosing.emit(true);
    }
    this.cd.detectChanges();
  }

  editAddress() {}
  isDeleteAddress() {}
  onResizeEnd = () => {
    this.localStorageData.windowSize.isFullscreen = false;
  };

  onResizeStart = (e) => {
    this.emptyStateHeight = e.height;
  };

  fullScreenChange = (e) => {
    this.localStorageData.windowSize.isFullscreen = e;
    if (e) {
      this.emptyStateHeight = window.innerHeight;
    } else {
      this.emptyStateHeight = this.localStorageData.windowSize.height;
    }
    if (this.emptyStateHeight == '90%') {
      this.emptyStateHeight = window.innerHeight * 0.9;
    }
  };

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  addAddress() {
    this.isVisibleAddDeliveryAddress = true;
    this.deliveryAddressMode = 'add';
  }

  onKeyDown(e) {
    if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    }
    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      //const focusedRowKey = grid.option('focusedRowKey');

      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  getAddresses(ServiceOrderId) {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'DeliveryId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}deliveries/documents/${ServiceOrderId}/addresses`,
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: () => {
          this.focusedRowIndex = this.focusedRowIndex + 1;
          setTimeout(() => {
            this.gridClass.instance.focus();
          }, 0);
          this.cd.detectChanges();
        },
        deleteUrl: `${environment.domain}`,
      }),
      reshapeOnPush: true,
      requireTotalCount: true,
      paginate: true,
    });
  }

  contextMenuClick(e: any) {
    switch (e.itemData.itemIndex) {
      case 0:
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
    }
  }

  onCancelAddresses() {
    this.isVisible = false;
  }

  isVisibleAddDeliveryAddress = false;

  onCancelAddDeliveryAddress() {
    this.isVisibleAddDeliveryAddress = false;
    this.cd.detectChanges();
  }
}

<dx-popup
  [width]="widthWindow"
  [height]="event.deviceType !== 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType !== 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'stockHistory.profitOfSelectedOrders' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); getData()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closePopup()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap" style="width: 101%">
        <div class="form-group-inline-right" style="width: 550px"></div>
        <dx-data-grid
          [dataSource]="dataSource"
          [wordWrapEnabled]="true"
          [showBorders]="true"
          [columnResizingMode]="'widget'"
          shortcut
          [height]="270"
          [columnAutoWidth]="true"
          [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
          #gridBank
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption=""
            dataField="Name"
            alignment="left"
            [width]="150"
            [allowSorting]="false"
          ></dxi-column>

          <dxi-column
            caption="{{ 'stockHistory.netPurchaseValue' | translate }}"
            dataField="TotalBuyPrice"
            [width]="150"
            [allowSorting]="false"
            cellTemplate="PriceNet"
          ></dxi-column>

          <dxi-column
            caption="{{ 'stockHistory.netSalesValue' | translate }}"
            dataField="TotalPrice"
            [width]="150"
            [allowSorting]="false"
            cellTemplate="PriceNet"
          ></dxi-column>

          <dxi-column
            caption="{{ 'stockHistory.netProfit' | translate }}"
            dataField="TotalProfit"
            [width]="150"
            [allowSorting]="false"
            cellTemplate="PriceNet"
          ></dxi-column>

          <div *dxTemplate="let data of 'PriceNet'">
            {{ data.value | amountFormatter:data }}
          </div>
        </dx-data-grid>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

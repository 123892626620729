import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input, Output, signal,
  SimpleChanges,
  ViewChild, WritableSignal,
} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as THREE from 'three';
import {Group, Object3DEventMap} from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {ConfirmDialogComponent} from '../../../core/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {EventService} from '../../../event.service';

interface Product {
  UnitId?: string;
  Id?: number;
  Name?: string;
  Width: number;
  Height: number;
  Depth: number;
  Weight?: number;
  DimensionUnit?: string;
  WeightUnit?: string;
  Factor?: number;
  x?: number;
  y?: number;
  z?: number;
}

interface paramsWeightError {
  maxWeight: number;
  weightOfAllProducts: number;
  productName: string;
  currentProductWeight: number;
}

@Component({
    selector: 'app-packing-simulation',
    imports: [CommonModule, ConfirmDialogComponent],
    templateUrl: './packing-simulation.component.html',
    styleUrl: './packing-simulation.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PackingSimulationComponent {
  @ViewChild('rendererContainer', {static: true})
  rendererContainer!: ElementRef;

  @Output() onAmountOfPlacedProducts = new EventEmitter<number>();
  @Output() usedWeightEvent = new EventEmitter<number>();
  @Output() onVolumeEvent = new EventEmitter<number>();
  @Output() onDeleteAllPlacedProducts = new EventEmitter<boolean>();
  @Output() onDeleteLastPlacedProduct = new EventEmitter<boolean>();
  @Output() onStatusOfDoesntFitProduct = new EventEmitter<boolean>();
  @Output() onDeletePosiotionsPlacedProducts = new EventEmitter<string>();
  @Output() onAddProductConfig = new EventEmitter<boolean>();
  @Output() onAddFactorUnitProductConfig = new EventEmitter<boolean>();
  @Output() setPreviousAmountAfterCancelConfirm = new EventEmitter<boolean>();
  @Output() onPlacedProducts = new EventEmitter<Product[]>();

  @Input() dimensions = {
    Width: 0,
    Height: 0,
    Depth: 0,
    Thickness: 0,
    MaxWeight: 0,
    Name: '',
    CompanyId: 0,
    PackagingId: 0,
  };
  @Input() windowWidth: number = 0;
  @Input() selectedProductId: number = 0;
  @Input() deleteAllPlacedProducts: boolean = false;
  @Input() deleteLastPlacedProduct: boolean = false;
  @Input() statusOfDoesntFitProduct: boolean = false;
  @Input() deletePosiotionsPlacedProducts: string = 'deleteAll';
  @Input() deleteOnlyOneProdukt: boolean = false;
  @Input() addAllProducts: boolean = false;
  @Input() addFactorUnitProduct: boolean = false;
  @Input() selectedProduct: Product =
    {
      UnitId: '',
      Name: '',
      DimensionUnit: '',
      Id: 0,
      Weight: 0,
      Width: 0,
      Height: 0,
      Depth: 0,
      x: 0,
      y: 0,
      z: 0,
    };

  private scene!: THREE.Scene;
  private camera!: THREE.PerspectiveCamera;
  private renderer!: THREE.WebGLRenderer;
  cube!: Group<Object3DEventMap>;
  dimensionChanged: boolean = false;
  buttonClicked: boolean = false;
  placedProducts = [
    {
      UnitId: '',
      Name: '',
      DimensionUnit: '',
      Id: 0,
      Weight: 0,
      Width: 0,
      Height: 0,
      Depth: 0,
      x: 0,
      y: 0,
      z: 0,
    }
  ];

  paramsWeightError: WritableSignal<paramsWeightError> = signal({
    maxWeight: 0,
    weightOfAllProducts: 0,
    productName: '',
    currentProductWeight: 0
  });
  packageWeightErrorVisible: boolean = false;
  packageSizeErrorVisible: boolean = false;
  weightOfAllProducts: number = 0;
  volumeOfAllProducts: number = 0;
  confirmHeader: string = '';
  amountOfPlacedProducts: number = 0;
  productsPlacedSuccessfully: number = 0;
  productsNotPlaced: number = 0;
  packageSizeErrorShown = false;
  packageMainSizeErrorVisible = false;

  constructor(private cd: ChangeDetectorRef, private translate: TranslateService, public event: EventService,) {
    this.translate.get('articles.packageSizeErrorTitle').subscribe((text) => {
      this.confirmHeader = text;
    });

  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cube) {
      if (
        changes.windowWidth?.currentValue != changes.windowWidth?.previousValue
      ) {
        this.renderer.setSize(this.windowWidth, this.windowWidth);
      }
    }

    if (changes.deleteLastPlacedProduct?.currentValue != changes.deleteLastPlacedProduct?.previousValue &&
      changes.deleteLastPlacedProduct?.previousValue == false &&
      changes.deleteLastPlacedProduct?.currentValue == true) {
      this.refreshValuesAfterSimpleDelete();
    }

    if (
      changes.deleteAllPlacedProducts?.currentValue != changes.deleteAllPlacedProducts?.previousValue &&
      changes.deleteAllPlacedProducts?.previousValue == false &&
      changes.deleteAllPlacedProducts?.currentValue == true
    ) {
      this.resetNecessaryValues();
      this.generateBox();
      this.onDeleteAllPlacedProducts.emit(false);
    }

    if (
      changes.deletePosiotionsPlacedProducts?.currentValue !== changes.deletePosiotionsPlacedProducts?.previousValue
    ) {
      if (this.placedProducts.length > 1) {
        // console.log('deletePosiotionsPlacedProducts', this.deletePosiotionsPlacedProducts); // Debug: Sprawdź wartość
        if (this.deletePosiotionsPlacedProducts === 'deleteAll') {
          // console.log('Usuwanie wszystkich produktów'); // Debug: Dodano log
          // Usuń wszystkie produkty z tablicy placedProducts, których Id jest równe selectedProductId
          this.placedProducts = this.placedProducts.filter(product => product.Id !== this.selectedProductId);

          // Usuń wszystkie produkty ze sceny Three.js, których Id jest równe selectedProductId
          this.cube.children = this.cube.children.filter(child => child.userData.Id !== this.selectedProductId);

        } else if (this.deletePosiotionsPlacedProducts === 'deleteOne') {
          // console.log('Usuwanie ostatniego dodanego produktu'); // Debug: Dodano log
          // Usuń ostatni dodany produkt z tablicy placedProducts, którego Id jest równe selectedProductId
          for (let i = this.placedProducts.length - 1; i >= 0; i--) {
            if (this.placedProducts[i].Id === this.selectedProductId) {
              this.placedProducts.splice(i, 1);
              break; // Przerywa pętlę po znalezieniu i usunięciu pierwszego dopasowania
            }
          }

          // Usuń ostatnio dodany produkt ze sceny Three.js, którego Id jest równe selectedProductId
          for (let i = this.cube.children.length - 1; i >= 0; i--) {
            const child = this.cube.children[i];
            if (child.userData.Id === this.selectedProductId) {
              this.cube.remove(child);
              break; // Przerywa pętlę po znalezieniu i usunięciu pierwszego dopasowania
            }
          }
        }

        this.onPlacedProducts.emit(this.placedProducts);

        // Ponownie wyrenderuj scenę
        this.renderer.render(this.scene, this.camera);
      }

      this.onDeletePosiotionsPlacedProducts.emit('');
      this.checkAmountOfPlacedProducts();
      this.checkWeightAndVolume();
    }


    if (
      changes.dimensions?.currentValue &&
      changes.dimensions?.currentValue != changes.dimensions?.previousValue &&
      changes.dimensions?.previousValue &&
      changes.dimensions?.currentValue.Width != 0 &&
      changes.dimensions?.currentValue.Height != 0 &&
      changes.dimensions?.currentValue.Depth != 0
    ) {
      this.boxHasAlreadyBeenGenerated = false;
      if (!this.rendererContainer) {
        return;
      }
      this.resetNecessaryValues();
      this.camera.position.z = changes.dimensions?.currentValue.Depth;
      this.camera.position.y = changes.dimensions?.currentValue.Height;
      this.camera.position.x = changes.dimensions?.currentValue.Width;
      this.generateBox();
      this.cd.detectChanges();
    }

    if (
      changes.selectedProduct &&
      changes.selectedProduct?.currentValue &&
      changes.selectedProduct?.currentValue != changes.selectedProduct?.previousValue &&
      this.boxHasAlreadyBeenGenerated
    ) {
      if (!this.rendererContainer) {
        return;
      }

      if (this.selectedProduct.Weight + this.weightOfAllProducts > this.dimensions.MaxWeight && !this.packageWeightErrorVisible) {
        this.paramsWeightError.set({
          maxWeight: this.dimensions.MaxWeight,
          weightOfAllProducts: this.weightOfAllProducts,
          productName: this.selectedProduct.Name,
          currentProductWeight: this.selectedProduct.Weight
        });
        const maxWeight = this.paramsWeightError().maxWeight;
        const weightOfAllProducts = this.paramsWeightError().weightOfAllProducts;
        const productName = this.paramsWeightError().productName;
        const currentProductWeight = this.paramsWeightError().currentProductWeight;
        if (localStorage.getItem('lang') === 'pl') {
          this.packageWeightErrorVisible = true;
          this.event.showNotification(
            'warning',
            `Przekroczono maksymalną dopuszczalną wagę produktów w opakowaniu. Maksymalna dopuszczalna waga dla tego opakowania to ${maxWeight} kg. Łączna waga wszystkich dodanych artykułów to ${weightOfAllProducts} kg. Waga artykułu ${productName} to ${currentProductWeight} kg.`,
          );
        } else if (localStorage.getItem('lang') === 'en') {
          this.packageWeightErrorVisible = true;
          this.event.showNotification(
            'warning',
            `The maximum allowable weight of products in the packaging has been exceeded. The maximum allowable weight for this package is ${maxWeight} kg. The total weight of all added products is ${weightOfAllProducts} kg. The weight of the product ${productName} is ${currentProductWeight} kg.`,
            10000
          );
        } else if (localStorage.getItem('lang') === 'ua') {
          this.packageWeightErrorVisible = true;
          this.event.showNotification(
            'warning',
            `Перевищено максимально допустиму вагу товарів в опакуванні. Максимально допустима вага для цього упаковки становить ${maxWeight} кг. Загальна вага всіх доданих товарів становить ${weightOfAllProducts} кг. Вага товару ${productName} складає ${currentProductWeight} кг.`,
            10000);
        } else {
          this.packageWeightErrorVisible = true;
          this.event.showNotification(
            'warning',
            `Przekroczono maksymalną dopuszczalną wagę produktów w opakowaniu. Maksymalna dopuszczalna waga dla tego opakowania to ${maxWeight} kg. Łączna waga wszystkich dodanych artykułów to ${weightOfAllProducts} kg. Waga artykułu ${productName} to ${currentProductWeight} kg.`,
            10000
          );
        }
        // this.packageWeightErrorVisible = true;
        // return;
      }
      if (this.addNextWithoutHeight) {
        const packagingSurface = (this.dimensions.Width - 2 * this.dimensions.Thickness) *
          (this.dimensions.Depth - 2 * this.dimensions.Thickness);
        const productSurface1 = this.selectedProduct.Width * this.selectedProduct.Depth;
        const productSurface2 = this.selectedProduct.Width * this.selectedProduct.Height;
        const productSurface3 = this.selectedProduct.Height * this.selectedProduct.Depth;

        if (
          packagingSurface <= productSurface1 ||
          packagingSurface <= productSurface2 ||
          packagingSurface <= productSurface3 ||
          this.selectedProduct.Width > this.dimensions.Width - 2 * this.dimensions.Thickness ||
          this.selectedProduct.Depth > this.dimensions.Depth - 2 * this.dimensions.Thickness ||
          this.selectedProduct.Height > this.dimensions.Height - 2 * this.dimensions.Thickness
        ) {
          this.checkFit(false, false); // Wywołanie funkcji checkFit z odpowiednimi parametrami
        } else {
          this.checkFit(false); // Wywołanie funkcji checkFit z odpowiednimi parametrami
        }
      } else {
        this.checkFit();
      }
    }
  }

  checkAmountOfPlacedProducts(): void {
    this.amountOfPlacedProducts = this.placedProducts.length - 1;
    this.onAmountOfPlacedProducts.emit(this.amountOfPlacedProducts);
  }

  ngAfterViewInit(): void {
    if (!this.rendererContainer) {
      return;
    }

    this.initThree();
    this.generateBox();

  }

  removeProductsById(id: number): void {
    for (let i = this.cube.children.length - 1; i >= 0; i--) {
      if (this.cube.children[i].userData.Id === id) {
        this.cube.remove(this.cube.children[i]);
      }
    }
  }

  initThree(): void {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, 300 / 300, 0.1, 2000);
    // this.camera.position.z = this.dimensions.depth;
    // this.camera.position.y = this.dimensions.height;
    // this.camera.position.x = this.dimensions.width;
    this.renderer = new THREE.WebGLRenderer({antialias: true});
    this.renderer.setSize(this.windowWidth, this.windowWidth);
    // Ustawienie koloru tła sceny na biały (0xffffff)
    this.renderer.setClearColor(0xffffff);

    this.rendererContainer.nativeElement.appendChild(this.renderer.domElement);

    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enableDamping = true; // płynne obracanie
    controls.dampingFactor = 0.25; // szybkość płynnego obracania
  }

  onDimensionChange(): void {
    this.dimensionChanged = true;
  }

  // removeProductById(id: number): void {
  //   for (let i = 0; i < this.cube.children.length; i++) {
  //     if (this.cube.children[i].userData?.Id === id) {
  //       this.cube.remove(this.cube.children[i]);
  //       break;
  //     }
  //   }
  // }

  resetNecessaryValues(): void {
    this.packageWeightErrorVisible = false;
    this.packageSizeErrorVisible = false;
    this.packageSizeErrorShown = false;
    this.addNextWithoutHeight = false;
    this.weightOfAllProducts = 0;
    this.volumeOfAllProducts = 0;
    this.usedWeightEvent.emit(this.weightOfAllProducts);
    this.onVolumeEvent.emit(this.volumeOfAllProducts);
    this.placedProducts = [{UnitId: '', Name: '', DimensionUnit: '', Id: 0, Width: 0, Height: 0, Depth: 0, Weight: 0, x: 0, y: 0, z: 0}];
    this.checkAmountOfPlacedProducts();
    this.onAddProductConfig.emit(false);
    this.onAddFactorUnitProductConfig.emit(false);
    this.onPlacedProducts.emit(this.placedProducts);
  }

  boxHasAlreadyBeenGenerated = false;

  generateBox(): void {
    // Clear the previous cube if exists
    if (this.cube) {
      this.scene.remove(this.cube);
    }

    // Kolor materiałów
    const colorYellow = new THREE.Color('hsl(40, 50%, 78%)');
    const colorGray = new THREE.Color('hsl(0, 0%, 50%)'); // Domyślnie szary
    //uwzględnienie grubości ścianek
    const innerWidth = this.dimensions.Width;
    const innerHeight = this.dimensions.Height;
    const innerDepth = this.dimensions.Depth;

    // Tworzenie geometrii
    const geometry = new THREE.BoxGeometry(innerWidth, innerHeight, innerDepth);

    // Materiał dla ścian sześcianu (żółty)
    const materialYellow = new THREE.MeshBasicMaterial({
      color: colorYellow,
      transparent: true,
      opacity: 0.2,
    });

    // Materiał dla krawędzi (szary)
    const materialGray = new THREE.LineBasicMaterial({color: colorGray});

    // Tworzenie meshów dla ścian i krawędzi
    const cubeSides = new THREE.Mesh(geometry, materialYellow);
    const cubeEdges = new THREE.LineSegments(
      new THREE.EdgesGeometry(geometry),
      materialGray
    );

    // Grupa, do której dodamy oba meshy
    const cubeGroup = new THREE.Group();
    cubeGroup.add(cubeSides);
    cubeGroup.add(cubeEdges);

    // Dodanie grupy do sceny
    this.scene.add(cubeGroup);

    // Ustawienie referencji na grupę
    this.cube = cubeGroup;

    // this.scene.add(light);

    // Ustawienie pozycji kamery
    this.camera.position.z = this.dimensions.Depth;
    this.camera.position.y = this.dimensions.Height;
    this.camera.position.x = this.dimensions.Width;

    this.camera.lookAt(this.dimensions.Width / 2, this.dimensions.Height / 2, this.dimensions.Depth / 2);

    this.boxHasAlreadyBeenGenerated = true;
    // Renderowanie sceny
    this.animate();
  }

  animate() {
    // this.cube.rotation.x += 0.001;

    this.cube.rotation.y += 0.0005;
    requestAnimationFrame(() => this.animate());
    this.renderer.render(this.scene, this.camera);
  }

  checkFit(withHeight = true, withDepthAndWidth = true): void {
    this.buttonClicked = true;

    if (this.dimensionChanged && this.buttonClicked) {
      this.placedProducts = [{UnitId: '', Name: '', DimensionUnit: '', Id: 0, Width: 0, Height: 0, Depth: 0, Weight: 0, x: 0, y: 0, z: 0}];
      this.camera.position.z = this.dimensions.Depth;
      this.camera.position.y = this.dimensions.Height;
      this.camera.position.x = this.dimensions.Width;
      this.cd.detectChanges();
      this.generateBox();
      this.dimensionChanged = false;
    }

    if (!this.selectedProduct) {
      console.error('Nie wybrano produktu.');
      return;
    }

    let fitsInBox = false;
    const possibleOrientations = this.getPossibleOrientations(this.selectedProduct);
    let step = 1;
    const minSize = Math.min(...this.placedProducts.slice(1).map(product => Math.min(product.Width, product.Height, product.Depth)));
    if (minSize <= 5) {
      step = 0.15;
    } else if (minSize <= 10) {
      step = 0.2;
    } else if (minSize <= 20) {
      step = 0.3;
    } else if (minSize <= 50) {
      step = 0.5;
    } else if (minSize <= 100) {
      step = 0.8;
    } else {
      step = 1;
    }
    const minDimension = Math.min(step, Math.min(...this.placedProducts.slice(1).map(product => Math.min(product.Width, product.Height, product.Depth))));
    for (const orientation of possibleOrientations) {
      let x = 0, y = 0, z = 0;
      while (y <= ((withHeight ? this.dimensions.Height - 2 * this.dimensions.Thickness : Infinity) - orientation.Height) && !fitsInBox) {
        z = 0;
        while (z <= ((withDepthAndWidth ? this.dimensions.Depth - 2 * this.dimensions.Thickness : Infinity) - orientation.Depth) && !fitsInBox) {
          x = 0;
          while (x <= ((withDepthAndWidth ? this.dimensions.Width - 2 * this.dimensions.Thickness : Infinity) - orientation.Width) && !fitsInBox) {
            let fits = true;

            // Optimized collision detection
            if (this.placedProducts.some(placedProduct =>
              y < placedProduct.y + placedProduct.Height &&
              y + orientation.Height > placedProduct.y &&
              x < placedProduct.x + placedProduct.Width &&
              x + orientation.Width > placedProduct.x &&
              z < placedProduct.z + placedProduct.Depth &&
              z + orientation.Depth > placedProduct.z
            )) {
              fits = false;
            }

            if (fits) {
              this.placedProducts.push({
                UnitId: this.selectedProduct.UnitId,
                Name: this.selectedProduct.Name,
                DimensionUnit: this.selectedProduct.DimensionUnit,
                Id: this.selectedProduct.Id,
                Width: orientation.Width,
                Height: orientation.Height,
                Depth: orientation.Depth,
                Weight: orientation.Weight,
                x,
                y,
                z
              });

              this.selectedProduct = {
                UnitId: this.selectedProduct.UnitId,
                Name: this.selectedProduct.Name,
                DimensionUnit: this.selectedProduct.DimensionUnit,
                Id: this.selectedProduct.Id,
                ...orientation,
                Width: orientation.Width,
                Height: orientation.Height,
                Depth: orientation.Depth,
                Weight: orientation.Weight
              };

              // Only call after successful placement
              this.checkAmountOfPlacedProducts();
              fitsInBox = true;
              break;
            }
            x += minDimension;
          }
          z += minDimension;
        }
        y += minDimension;
      }
      if (fitsInBox) {
        break;
      }
    }

    if (fitsInBox) {
      this.renderProduct();
      this.packageSizeErrorShown = false;
    } else {
      if (!this.packageSizeErrorShown) {
        this.packageSizeErrorVisible = true;
      } else {
        // Example of a more efficient size check
        if (
          this.selectedProduct.Width > this.dimensions.Width - 2 * this.dimensions.Thickness ||
          this.selectedProduct.Depth > this.dimensions.Depth - 2 * this.dimensions.Thickness ||
          this.selectedProduct.Height > this.dimensions.Height - 2 * this.dimensions.Thickness
        ) {
          this.checkFit(false); // Adjust parameters based on current needs
        }
      }
    }
  }

  addNextWithoutHeight = false;
  theSameProductThatDoesntFit = true;

  onRemovingPlacedProduct(): void {
    const packagingSurface = (this.dimensions.Width - 2 * this.dimensions.Thickness) *
      (this.dimensions.Depth - 2 * this.dimensions.Thickness);
    const productSurface1 = this.selectedProduct.Width * this.selectedProduct.Depth;
    const productSurface2 = this.selectedProduct.Width * this.selectedProduct.Height;
    const productSurface3 = this.selectedProduct.Height * this.selectedProduct.Depth;

    if (
      packagingSurface <= productSurface1 ||
      packagingSurface <= productSurface2 ||
      packagingSurface <= productSurface3 ||
      this.selectedProduct.Width > this.dimensions.Width - 2 * this.dimensions.Thickness ||
      this.selectedProduct.Depth > this.dimensions.Depth - 2 * this.dimensions.Thickness ||
      this.selectedProduct.Height > this.dimensions.Height - 2 * this.dimensions.Thickness
    ) {
      this.checkFit(false, false); // Wywołanie funkcji checkFit z odpowiednimi parametrami
    } else {
      this.checkFit(false); // Wywołanie funkcji checkFit z odpowiednimi parametrami
    }

    this.addNextWithoutHeight = true; // Ustawienie flagi na true
    this.packageSizeErrorShown = true; // Ustawienie flagi na true
    if (this.addAllProducts) {
      this.onAddProductConfig.emit(true);
    }
    if (this.addFactorUnitProduct) {
      this.onAddFactorUnitProductConfig.emit(true);
    }
  }


  getPossibleOrientations(product: Product): Product[] {
    return [
      product, // Original orientation
      {UnitId: product.UnitId, Name: product.Name, Width: product.Width, Height: product.Depth, Depth: product.Height, Weight: product.Weight, Id: product.Id},
      {UnitId: product.UnitId, Name: product.Name, Width: product.Depth, Height: product.Height, Depth: product.Width, Weight: product.Weight, Id: product.Id},
      {UnitId: product.UnitId, Name: product.Name, Width: product.Height, Height: product.Width, Depth: product.Depth, Weight: product.Weight, Id: product.Id},

      {UnitId: product.UnitId, Name: product.Name, Width: product.Width, Height: product.Height, Depth: product.Depth, Weight: product.Weight, Id: product.Id},
      {UnitId: product.UnitId, Name: product.Name, Width: product.Depth, Height: product.Width, Depth: product.Height, Weight: product.Weight, Id: product.Id},
      {UnitId: product.UnitId, Name: product.Name, Width: product.Height, Height: product.Depth, Depth: product.Width, Weight: product.Weight, Id: product.Id}
    ];
  }


  renderProduct(): void {
    if (!this.selectedProduct) {
      return;
    }
    const randomColor = Math.random() * 0xffffff;

    const productGeometry = new THREE.BoxGeometry(
      this.selectedProduct.Width,
      this.selectedProduct.Height,
      this.selectedProduct.Depth
    );
    const productMaterial = new THREE.MeshBasicMaterial({color: randomColor});
    const productMesh = new THREE.Mesh(productGeometry, productMaterial);

    // Przypisz Id do userData obiektu
    productMesh.userData = {Id: this.selectedProduct.Id};

    const cartonCenterX = (this.dimensions.Width) / 2;
    const cartonCenterY = (this.dimensions.Height) / 2;
    const cartonCenterZ = (this.dimensions.Depth) / 2;

    const {x, y, z} = this.placedProducts[this.placedProducts.length - 1];

    const productPosX = (x - cartonCenterX + this.selectedProduct.Width / 2) + this.dimensions.Thickness;
    const productPosY = (y - cartonCenterY + this.selectedProduct.Height / 2) + this.dimensions.Thickness;
    const productPosZ = (z - cartonCenterZ + this.selectedProduct.Depth / 2) + this.dimensions.Thickness;

    productMesh.position.set(productPosX, productPosY, productPosZ);

    this.cube.add(productMesh);
    this.renderer.render(this.scene, this.camera);

    this.checkWeightAndVolume();

    this.onPlacedProducts.emit(this.placedProducts);
  }

  checkWeightAndVolume(): void {
    // Weight of all products
    this.weightOfAllProducts = this.placedProducts.reduce((sum, product) => sum + product.Weight, 0);
    this.usedWeightEvent.emit(this.weightOfAllProducts);

    // volume of all products
    this.volumeOfAllProducts = this.placedProducts.reduce((sum, product) => {
      const volume = product.Width * product.Height * product.Depth;
      return sum + volume;
    }, 0);
    this.onVolumeEvent.emit(this.volumeOfAllProducts);
  }

  private refreshValuesAfterSimpleDelete() {
    if (this.placedProducts.length > 1) {
      this.placedProducts.pop();
      this.cube.remove(this.cube.children[this.cube.children.length - 1]);
      this.checkAmountOfPlacedProducts();
      this.checkWeightAndVolume();
      this.onDeleteLastPlacedProduct.emit(false);
    }
  }

  onClosingConfirmPlaceProduct() {
    this.packageSizeErrorVisible = false;
    this.addNextWithoutHeight = false;
    this.onStatusOfDoesntFitProduct.emit(true);
    this.onAddProductConfig.emit(false);
    this.onAddFactorUnitProductConfig.emit(false);
    this.setPreviousAmountAfterCancelConfirm.emit(true);
  }
}

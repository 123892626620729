<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); setFoucus()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{
    id: unicalGuid,
    'data-cy': 'correction-warehouse-form-list'
  }"
  *ngIf="openNestFinished"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <!-- <dx-button text="{{'buttons.choose' |translate}}" type="success" style="margin-right: 6px;"
        [id]="'choosed'+unicalGuid" *ngIf="!readOnly" [disabled]="creadOnly"></dx-button> -->

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "workers.documentNumber" | translate }}:
          <strong>{{ headerDocument.WarehouseDocumentNumber }}</strong> |
          {{ "form-commercial-operation.documentDate" | translate }}:
          <strong>{{
            headerDocument.DateOfWarehouseDocument | date : "yyyy-MM-dd"
          }}</strong>
          | {{ "warehouseDocument.customer" | translate }}:
          <strong>{{ headerDocument.CustomerName }}</strong>
        </p>
      </div>

      <div style="margin-bottom: 12px; margin-top: 12px">
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--add-circle"
          [id]="'btn-add' + unicalGuid"
          [text]="'buttons.addCorrection' | translate"
          [disabled]="
            readOnly ||
            selected[0]?.TypeOfDocument == 'SU' ||
            selected[0]?.TypeOfDocument == 'RW'
          "
          (onClick)="addCorection()"
          data-cy="add-correction-warehouse-document-btn"
          #btnAdd
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--mode-edit"
          [text]="'buttons.edit' | translate"
          [id]="'btn-edit' + unicalGuid"
          (onClick)="editCorrectionDocument()"
          [disabled]="readOnly || focusedSelected.length == 0"
        >
        </dx-button>

        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon absui-icon--form-items"
          [text]="'buttons.show' | translate"
          [id]="'show' + unicalGuid"
          (onClick)="showCorrectionDocument()"
          [disabled]="readOnly || focusedSelected.length == 0"
        >
        </dx-button>
        <dx-button
          class="btn-dx-top-menu"
          icon="icon absui-icon--highlight-off"
          [disabled]="readOnly || focusedSelected.length == 0"
          [text]="'warehouseDocument.deleteLastCorrection' | translate"
          [id]="'delete' + unicalGuid"
          (onClick)="isDelete()"
          data-cy="delete-correction-warehouse-document-btn"
        >
        </dx-button>

        <app-reports
          #reports
          [code]="'Korekta_Dokument_magazynowy'"
          [menuName]="menuName"
          [type]="
            focusedSelected?.length ? focusedSelected[0]?.TypeOfDocument : null
          "
          [objectId]="
            focusedSelected?.length
              ? focusedSelected[0]?.WarehouseDocumentId
              : null
          "
          [params]="getLoadParams()"
        >
        </app-reports>

        <dx-tooltip
          [target]="'#btn-add' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.add" | translate }} (Insert)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#btn-edit' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.edit" | translate }} (F2)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#show' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.show" | translate }} (Shift + F2)
          </div>
        </dx-tooltip>

        <dx-tooltip
          [target]="'#delete' + unicalGuid"
          [showEvent]="event.tooltipShowEvent"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.delete" | translate }} (Del)
          </div>
        </dx-tooltip>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [height]="heightGrid"
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            (onInitialized)="onInitialized($event)"
            [remoteOperations]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            (onContentReady)="onContentReady($event)"
            (onKeyDown)="onKeyDown($event)"
            [(focusedRowIndex)]="focusedRowIndex"
            (onSelectionChanged)="onSelectionChanged()"
            [focusedRowEnabled]="true"
            #gridBank
            (onOptionChanged)="event.onOptionChanged($event)"
            data-cy="correction-warehouse-document-grid"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="50"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [allowSorting]="false"
              cellTemplate="mainColumn"
              [width]="150"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'mainColumn'">
              <div class="hov-underline" style="text-decoration: none">
                {{ data.data.WarehouseDocumentNumber }}
              </div>
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.form.dateOfIssue' | translate
              }}"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="180"
              alignment="left"
              [allowSorting]="false"
              dataField="DateOfWarehouseDocument"
            ></dxi-column>

            <dxi-column
              caption="{{
                'warehouseDocument.commercialCocumentNumber' | translate
              }}"
              alignment="left"
              [allowSorting]="false"
              dataField="InvoiceDocumentNumber"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.netCorrection' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="numberPrecisionTemplate"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.vatCorrection' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="numberPrecisionTemplate"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>

            <dxi-column
              caption="{{ 'warehouseDocument.grossCorrection' | translate }}"
              [allowSorting]="false"
              width="100"
              cellTemplate="numberPrecisionTemplate"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'numberPrecisionTemplate'">
              {{ data.data.TotalGrossAmount | amountFormatter : data }}
            </div>
          </dx-data-grid>
        </div>
      </div>

      <div class="count-container" style="margin-top: 12px; height: auto">
        <div class="row">
          <div class="col-md-2">
            <!-- <p *ngIf="isCorrect" class="summary-count-container">
              {{ "inventory.summarizedInventory" | translate }}
            </p> -->
          </div>

          <div class="col-md-10 text-right">
            <div class="contener-pay">
              <p class="correction-label">
                {{
                  sumPosition.TotalGrossAmountPrevious.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                {{
                  sumPosition.TotalGrossAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="summary-amount-correction">
                <strong>
                  {{
                    sumPosition.TotalGrossAmount -
                      sumPosition.TotalGrossAmountPrevious.toFixed(2)
                      | currency : " "
                      | pointReplacer
                  }}</strong
                >
              </p>

              <p class="des-toPay">
                {{ "form-commercial-operation.gross" | translate }}
              </p>
            </div>

            <div class="contener-pay" style="padding-right: 15px">
              <p class="correction-label">
                {{
                  sumPosition.TotalTaxAmountPrevious.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                {{
                  sumPosition.TotalTaxAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                <strong>{{
                  (
                    sumPosition.TotalTaxAmount -
                    sumPosition.TotalTaxAmountPrevious
                  ).toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}</strong>
              </p>
              <p class="des-toPay">VAT</p>
            </div>

            <div
              class="contener-pay"
              style="padding-right: 15px; border-left: none"
            >
              <p class="correction-label">
                {{
                  sumPosition.TotalNetAmountPrevious.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>
              <p class="correction-label">
                {{
                  sumPosition.TotalNetAmount.toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}
              </p>

              <p class="correction-label">
                <strong>{{
                  (
                    sumPosition.TotalNetAmount -
                    sumPosition.TotalNetAmountPrevious
                  ).toFixed(2)
                    | currency : " "
                    | pointReplacer
                }}</strong>
              </p>
              <p class="des-toPay">
                {{ "form-commercial-operation.net" | translate }}
              </p>
            </div>

            <div class="contener-pay" style="border-left: none">
              <p class="correction-label">
                {{ "warehouseDocument.beforeCorrection" | translate }}:
              </p>
              <p class="correction-label">
                {{ "warehouseDocument.afterCorrection" | translate }}:
              </p>
              <p class="correction-label">
                <strong
                  >{{
                    "warehouseDocument.correctionValue" | translate
                  }}:</strong
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-new-warehouse-document
  [isVisible]="addRow"
  (onClosing)="onClosingForm()"
  [editMode]="mode == 'edit' || mode == 'show' ? true : false"
  [selected]="
    mode == 'edit' || mode == 'show'
      ? focusedSelected
      : corectionDocumentSelected
  "
  [readOnly]="mode == 'show' ? true : readOnly"
  [isCorrect]="true"
  [sumPositionIncome]="sumPositionIncome"
  [componentNests]="componentNests"
  title="{{ 'warehouseDocument.addingAcorrectionDocument' | translate }}"
  (onInserted)="onInserted($event)"
  [WarehouseDocumentCorrectionLastId]="WarehouseDocumentCorrectionLastId"
  [WarehouseDocumentOriginalId]="selected[0]?.WarehouseDocumentOriginalId"
>
</app-new-warehouse-document>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
  confirmText="{{ 'warehouseDocument.wantDelete' | translate }}"
></app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible || isExpansionListVisible"
  #keyboardShortcuts
>
</wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGMKL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGMKL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

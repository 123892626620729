<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  [fullScreen]="event.deviceType != 'mobile' ? fullScreen : false"
  (onShowing)="event.onShownPopUp(); onGetData(); getPositionVerificationParams(); fullScreenChange($event)"
  (onHiding)="event.onHiddenPopUp(); saveDataToLocalStorage()"
  (fullScreenChange)="fullScreenChange($event)"
  [wrapperAttr]="{ id: unicalGuid }"
  (onResize)="onResizeStartPopUp($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header">
      <div class="box-type-document box-type-document_position-ver">
        <i class="icon-tag icon-tag_deep-orange icon absui-icon--find-start"></i>
        <p class="header_title">{{ 'warehouseDocument.documentPositionVerification' | translate }}</p>
      </div>
      <div class="separator_vertical"></div>
      <div class="header_position-title">
        <strong class="header_position-title_subtitle">
          {{ "workers.documentNumber" | translate }}:
        </strong>
        <p class="header_position-title_number"
           style="">{{ documentNumber }}</p>
      </div>
    </div>

    <div class="text-right title-pop-up buttons-panel">
      <div class="separator_vertical_genius"></div>
      <div class="genius">
        <img
          src="../../../assets/img/powered_by-dark.svg"
          alt="Powered by Genius"
          height="21"
          class="ng-star-inserted"
        />
      </div>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        style="margin-right: 6px"
        [text]="'deliveries.title' | translate"
        [id]="'btn-deliveries' + unicalGuid"
        [disabled]="readOnly"
        (onClick)="deliveresBox()"
      >
      </dx-button>

      <i
        class="icon"
        [ngClass]="{
          'absui-icon--fullscreen2': !fullScreen,
          'absui-icon--fullscreen-off': fullScreen
        }"
        (click)="fullScreen = !fullScreen"
        style="position: relative; top: 5px"
        [id]="'fitToWidth' + unicalGuid"
      ></i>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="success"
        (onClick)="isVisible = false"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#fitToWidth' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "servicesDevices.fitToWidth" | translate }}
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%"
                    height="100%"
                    direction="vertical">
      <div class="row">
        <div class="col-md-12">
          <div class="panels-flex-container">
            <div class="with_search_panel"
                 #rightPanel>
              <!--              <div class="header-document header-document_position-verification"-->
              <!--                   style="margin-top: 0px">-->
              <!--                <p class="p-header-info">-->
              <!--                  {{ "workers.documentNumber" | translate }}:-->
              <!--                  -->
              <!--                </p>-->
              <!--                <strong>{{ documentNumber }}</strong>-->
              <!--              </div>-->

              <div class="row">
                <div class="col-md-12">
                  <div>
                    <dx-button
                      class="btn-dx-top-menu"
                      icon="icon absui-icon--box-edit"
                      [text]="'buttons.edit' | translate"
                      [id]="'btn-edit' + unicalGuid"
                      (onClick)="editPosition()"
                      [disabled]="readOnly || focusedSelected.length == 0"
                    >
                    </dx-button>

                    <!--                  <dx-button-->
                    <!--                    class="btn-dx-top-menu"-->
                    <!--                    [text]="'addWithoutVerification' | translate"-->
                    <!--                    [id]="'btn-addWithoutVerification' + unicalGuid"-->
                    <!--                    [disabled]="readOnly || focusedSelected.length == 0"-->
                    <!--                    (onClick)="addProductWithoutVerification()"-->
                    <!--                  >-->
                    <!--                  </dx-button>-->

                    <!--                  <dx-button-->
                    <!--                    class="btn-dx-top-menu"-->
                    <!--                    [text]="'removeLastOne' | translate"-->
                    <!--                    [id]="'btn-deleteLastPlacedProduct' + unicalGuid"-->
                    <!--                    [disabled]="readOnly || focusedSelected.length == 0 || !amountOfPlacedProducts"-->
                    <!--                    (onClick)="deleteProductWithoutVerification()"-->
                    <!--                  >-->
                    <!--                  </dx-button>-->

                    <dx-button
                      class="btn-dx-top-menu"
                      icon="icon absui-icon--package-delete"
                      [text]="'buttons.deleteAll' | translate"
                      [id]="'btn-deleteAll' + unicalGuid"
                      [disabled]="readOnly || focusedSelected.length == 0 || !amountOfPlacedProducts"
                      (onClick)="deleteAll();"
                    >
                    </dx-button>

                    <dx-button
                      class="btn-dx-top-menu"
                      icon="icon absui-icon--check-circle"
                      [text]="'warehouseDocument.Verify' | translate"
                      [id]="'btn-Verify' + unicalGuid"
                      [disabled]="readOnly || focusedSelected.length == 0"
                      (onClick)="onVerify()"
                    >
                    </dx-button>
                    <dx-button
                      icon="icon absui-icon--cancel"
                      class="btn-dx-top-menu"
                      [text]="'warehouseDocument.cancelPosition' | translate"
                      [id]="'btn-cancelPosition' + unicalGuid"
                      [disabled]="readOnly"
                      (onClick)="positionVerificationCancel()"
                    >
                    </dx-button>

                    <dx-button
                      icon="icon absui-icon--playlist-check"
                      class="btn-dx-top-menu"
                      [text]="'warehouseDocument.verifyItems' | translate"
                      [id]="'btn-VerifyAll' + unicalGuid"
                      [disabled]="readOnly"
                      (onClick)="onVerifyItem()"
                    >
                    </dx-button>
                    <dx-button
                      icon="icon absui-icon--playlist-remove"
                      class="btn-dx-top-menu"
                      [text]="'warehouseDocument.cancelVerification' | translate"
                      [id]="'btn-cancelVerification' + unicalGuid"
                      [disabled]="readOnly || focusedSelected.length == 0"
                      (onClick)="showCancelAllDialog()"
                    >
                      <!-- Anuluj weryfikację -->
                    </dx-button>

                    <dx-button
                      class="btn-dx-top-menu"
                      icon="icon absui-icon--lock-full"
                      [text]="'packageConfiguration.lock' | translate"
                      [id]="'btn-lock' + unicalGuid"
                      [disabled]="readOnly"
                      (onClick)="blockDocument()"
                      *ngIf="!isBlocked"
                    >
                    </dx-button>

                    <dx-button
                      icon="icon absui-icon--unlocked"
                      class="btn-dx-top-menu"
                      [text]="'packageConfiguration.unlock' | translate"
                      [id]="'btn-unlock' + unicalGuid"
                      [disabled]="readOnly"
                      *ngIf="isBlocked"
                      (onClick)="unblockDocument()"
                    >
                    </dx-button>

                    <app-dropdown-config
                      [configDataSource]="configDataSource"
                      [configValues]="verificationParams"
                      [title]=""
                      [dropDownOptions]="dropDownOptions"
                      (onConfigChanged)="onDropdownConfigChanged($event)"
                    ></app-dropdown-config>
                  </div>

                  <dx-tooltip
                    [target]="'#btn-lock' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      (Ctrl + Shift + B)
                    </div>
                  </dx-tooltip>
                  <dx-tooltip
                    [target]="'#btn-unlock' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      (Ctrl + Shift + O)
                    </div>
                  </dx-tooltip>

                  <dx-tooltip
                    [target]="'#btn-edit' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "buttons.edit" | translate }} (F2)
                    </div>
                  </dx-tooltip>

                  <dx-tooltip
                    [target]="'#btn-Verify' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">(F3)</div>
                  </dx-tooltip>

                  <dx-tooltip
                    [target]="'#btn-VerifyAll' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      (Ctrl + Alt + W)
                    </div>
                  </dx-tooltip>

                  <dx-tooltip
                    [target]="'#btn-cancelPosition' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">(Ctrl + A)</div>
                  </dx-tooltip>

                  <dx-tooltip
                    [target]="'#btn-cancelVerification' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">(Ctrl + G)</div>
                  </dx-tooltip>

                  <dx-tooltip
                    [target]="'#btn-deliveries' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">(Ctrl + P)</div>
                  </dx-tooltip>
                </div>
              </div>

              <div class="row"
                   style="margin-top: 12px">
                <div class="col-md-12 d-flex justify-content-between">
                  <div>
                    <app-custom-dropdown-box
                      [items]="filtrPositionList"
                      [(selectedItem)]="valueCriteriaPosition"
                      [(filterValue)]="filterValuePosition"
                      (onValueChanged)="onFilterPosition($event)"
                      style="margin-right: 5px; margin-top: 5px"
                      class="warehouse-documents-search"
                    >
                    </app-custom-dropdown-box>

                    <app-custom-chips-button
                      name="Status"
                      [list]="filterCriteriaStatus"
                      (onValueChanged)="onValueChangedStatus($event)"
                    >
                    </app-custom-chips-button>

                    <app-custom-chips-button
                      name="Ukryj usługi"
                      [list]="services"
                      (onValueChanged)="onValueChangedPriceFilter($event)"
                      [disabledClear]="false"
                      [selectedValueInput]="isBuyText"
                    >
                    </app-custom-chips-button>
                  </div>

                  <div>
                    <dx-button
                      class="btn-dx-top-menu-folder"
                      icon="icon dx-icon-icon-refresh"
                      stylingMode="text"
                      type="default"
                      (click)="getPosition(documentId)"
                      style="margin-right: 6px"
                      [id]="'btn-refresh' + unicalGuid"
                    ></dx-button>

                    <dx-button
                      class="btn-dx-top-menu-folder intrastate-step-3"
                      icon="column-chooser"
                      stylingMode="text"
                      type="default"
                      (click)="openColumnsChooser()"
                      [id]="'btn-columns' + unicalGuid"
                    >
                    </dx-button>

                    <dx-tooltip
                      [target]="'#btn-refresh' + unicalGuid"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "refresh" | translate }}
                      </div>
                    </dx-tooltip>

                    <dx-tooltip
                      [target]="'#btn-columns' + unicalGuid"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "columnSettings" | translate }}
                      </div>
                    </dx-tooltip>
                  </div>


                </div>
              </div>

              <div class="row"
                   style="margin-top: 12px">
                <div class="col-md-12">
                  <dx-data-grid
                    class="grid-height gridPositionVerification"
                    [showBorders]="true"
                    [remoteOperations]="true"
                    [allowColumnReordering]="
                      event.deviceType != 'mobile'
                    "
                    [wordWrapEnabled]="false"
                    [hoverStateEnabled]="true"
                    [dataSource]="dataSource"
                    #itemGrid
                    shortcut
                    [focusedRowEnabled]="true"
                    [(selectedRowKeys)]="selectedPositionDocument"
                    [autoNavigateToFocusedRow]="true"
                    [columnAutoWidth]="true"
                    [allowColumnResizing]="true"
                    [columnResizingMode]="'widget'"
                    style="width: 100%; min-height: 50px; margin-bottom: 10px"
                    [height]="emptyStateHeight - heightGrid"
                    (onFocusedRowChanged)="onFocusedRowChanged($event)"
                    [id]="'grid' + unicalGuid"
                    (onSelectionChanged)="onSelectionChanged($event)"
                    (onKeyDown)="onKeyDown($event)"
                    [(focusedRowIndex)]="focusedRowIndex"
                    (onOptionChanged)="onOptionChanged($event)"
                    (onCellDblClick)="onCellDblClick($event)"
                    (onContentReady)="onContentReady($event)"
                  >
                    <dxo-load-panel [enabled]="true"></dxo-load-panel>
                    <dxo-scrolling mode="virtual"></dxo-scrolling>
                    <dxo-paging [pageSize]="50"></dxo-paging>
                    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

                    <dxi-column
                      [fixed]="true"
                      [caption]="'form-commercial-operation.grid.Lp' | translate"
                      width="50"
                      [allowSorting]="false"
                      cellTemplate="lpTemplate"
                      [allowFiltering]="false"
                      dataField="Id"
                      [cssClass]="
                        orderby == 'PositionId' ? 'sort-column-bg' : 'sort-column-muted'
                      "
                      headerCellTemplate="lpHeaderTemplate"
                    >
                    </dxi-column>
                    <div *dxTemplate="let cellInfo of 'lpTemplate'; let index = i">
                      {{ cellInfo.data.lp }}
                    </div>
                    <div *dxTemplate="let data of 'lpHeaderTemplate'">
                      <div
                        class="grid-header-wrapper"
                        (click)="switchOrder(); getPosition(documentId)"
                      >
                        {{ data.column.caption }}
                        <i
                          class="header-sort-icon"
                          [ngClass]="
                        order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      [caption]="'W'"
                      width="32"
                      [allowSorting]="false"
                      cellTemplate="status"
                      [allowFiltering]="false"
                      dataField="VerificationState"
                      alignment="center"
                    >
                    </dxi-column>

                    <div *dxTemplate="let data of 'status'">
                      <div
                      >
                        <i *ngIf="data.data.VerificationState === 0"
                           class="icon absui-icon--status-cancel2"
                           [id]="'VerificationState-0' + unicalGuid"
                        ></i>
                        <i *ngIf="data.data.VerificationState === 1"
                           class="icon absui-icon--status-ok-warning"
                           [id]="'VerificationState-1' + unicalGuid"
                        ></i>
                        <i *ngIf="data.data.VerificationState === 2"
                           class="icon absui-icon--status-ok-green"
                           [id]="'VerificationState-2' + unicalGuid"
                        ></i>
                        <i *ngIf="data.data.VerificationState === 3"
                           class="icon absui-icon--status-info_blue"
                           [id]="'VerificationState-3' + unicalGuid"
                        ></i>

                        <dx-tooltip
                          [target]="'#VerificationState-0' + unicalGuid"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                          [showEvent]="event.tooltipShowEvent"
                        >
                          <div
                            *dxTemplate="let data of 'content'"
                            [innerHTML]="
                          'verificationStatus.notVerified' | translate
                        "
                          ></div>
                        </dx-tooltip>
                        <dx-tooltip
                          [target]="'#VerificationState-1' + unicalGuid"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                          [showEvent]="event.tooltipShowEvent"
                        >
                          <div
                            *dxTemplate="let data of 'content'"
                            [innerHTML]="
                          'verificationStatus.partiallyVerified' | translate
                        "
                          ></div>
                        </dx-tooltip>
                        <dx-tooltip
                          [target]="'#VerificationState-2' + unicalGuid"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                          [showEvent]="event.tooltipShowEvent"
                        >
                          <div
                            *dxTemplate="let data of 'content'"
                            [innerHTML]="
                          'verificationStatus.verified' | translate
                        "
                          ></div>
                        </dx-tooltip>
                        <dx-tooltip
                          [target]="'#VerificationState-3' + unicalGuid"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                          [showEvent]="event.tooltipShowEvent"
                        >
                          <div
                            *dxTemplate="let data of 'content'"
                            [innerHTML]="
                          'verificationStatus.surplus' | translate
                        "
                          ></div>
                        </dx-tooltip>
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{
                        'form-commercial-operation.grid.articleName' | translate
                      }}"
                      width="230"
                      [allowSorting]="false"
                      dataField="ProductName"
                      headerCellTemplate="sortingHeaderTemplate"
                      [allowFiltering]="false"
                      [cssClass]="
                        orderby == 'ProductName' ? 'sort-column-bg' :  'sort-column-muted'
                      "
                    >
                    </dxi-column>
                    <div
                      *dxTemplate="let data of 'ProductFullNametHeaderTemplate'"
                    >
                      <div class="grid-header-wrapper">
                        {{
                          "form-commercial-operation.grid.articleName"
                            | translate
                        }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'ProductName'"
                          (click)="switchOrder(); getPosition(documentId)"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>
                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'articles.R' | translate }}"
                      [width]="26"
                      [allowSorting]="false"
                      [cssClass]="'sorting-unavailable'"
                      dataField="ProductType"
                      cellTemplate="ProductTypeStockAndReservations"
                      headerCellTemplate="StatusCodeStockAndReservations"
                      [hidingPriority]="43"
                      alignment="center"
                    >
                    </dxi-column>
                    <div
                      *dxTemplate="let data of 'StatusCodeStockAndReservations'"
                      class="text-center"
                    >
                      <div
                        class="grid-header-wrapper"
                        [id]="'StatusCodeStockAndReservations' + unicalGuid"
                      >
                        R
                      </div>
                      <dx-tooltip
                        [target]="'#StatusCodeStockAndReservations' + unicalGuid"
                        [showEvent]="event.tooltipShowEvent"
                        hideEvent="dxhoverend"
                        [hideOnOutsideClick]="false"
                      >
                        <div *dxTemplate="let data of 'content'">
                          {{ "production-documents.type" | translate }}
                        </div>
                      </dx-tooltip>
                    </div>
                    <div
                      *dxTemplate="let data of 'ProductTypeStockAndReservations'"
                    >
                      <div
                        [ngClass]="{
                        articleStatusT: data.data.ProductType === 'T',
                        articleStatusU: data.data.ProductType === 'U',
                        articleStatusP: data.data.ProductType === 'P',
                        articleStatusO: data.data.ProductType === 'O',
                        articleStatusM: data.data.ProductType === 'M',
                        articleStatusZ: data.data.ProductType === 'Z'
                      }"
                      >
                        {{ data.value.substr(0, 1) }}
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      dataField="AmountInBox"
                      caption="{{ 'inThePackage' | translate }}"
                      width="130"
                      [allowSorting]="false"
                      [allowFiltering]="false"
                      [hidingPriority]="29"
                      cellTemplate="inThePackage"
                      headerCellTemplate="headerCellTemplateInThePackage"
                    >
                    </dxi-column>
                    <div *dxTemplate="let headerCellTemplateInThePackage of 'headerCellTemplateInThePackage'">
                      <div
                        style="display: flex;
                            justify-content: center;
                            align-items: center;">
                        <div
                          class="grid-header-wrapper">
                          {{ "inThePackage" | translate }}
                        </div>
                        <dx-button
                          *ngIf="event.deviceType != 'mobile'"
                          class="btn-dx-top-menu-folder"
                          icon="icon absui-icon--status-info absui-icon--status-info_gray"
                          type="default"
                          (click)="showTooltipHelpAmount = true"
                          [id]="'btn-help' + unicalGuid"
                        ></dx-button>
                        <dx-tooltip
                          [target]="'#btn-help' + unicalGuid"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                          [showEvent]="event.tooltipShowEvent"
                        >
                          <div
                            *dxTemplate="let data of 'content'"
                            [innerHTML]="
                          'amountPlacedProductWithoutVerification' | translate
                        "
                          ></div>
                        </dx-tooltip>
                      </div>
                    </div>
                    <div
                      *dxTemplate="let data of 'inThePackage'"
                    >
                      <div style="display: flex; align-items: center; justify-content: center">
                        <p style="margin-bottom: 0"
                           class="amount-fullscreen">{{ data.data.AmountInBox }}</p>

                        <dx-button
                          class="btn-fullscreen"
                          icon="icon dx-numberbox-spin-up-icon"
                          style="display: inline-block"
                          [disabled]="readOnly"
                          (onClick)="onAmountOfPlacedProductsChanged(data.data, 'up')"
                        ></dx-button>

                        <dx-button
                          icon="icon numberbox-spin-down-icon"
                          class="btn-fullscreen"
                          style="display: inline-block"
                          [disabled]="readOnly"
                          (onClick)="onAmountOfPlacedProductsChanged(data.data, 'down')"
                        ></dx-button>
                        <!--                        <dx-number-box-->
                        <!--                          class="readOnly"-->
                        <!--                          width="110"-->
                        <!--                          [(value)]="data.value"-->
                        <!--                          [useLargeSpinButtons]="true"-->
                        <!--                          [showSpinButtons]="true"-->
                        <!--                          [min]="0"-->
                        <!--                          (onValueChanged)="onAmountOfPlacedProductsChanged($event)"-->
                        <!--                          (keydown)="onKeyDownAmountPlacedProduct($event)"-->
                        <!--                        >-->
                        <!--                        </dx-number-box>-->
                      </div>
                    </div>


                    <!--                    <dxi-column-->
                    <!--                      caption="{{ 'dimensions' | translate }}"-->
                    <!--                      width="300"-->
                    <!--                      [allowSorting]="false"-->
                    <!--                      dataField="DimensionUnit"-->
                    <!--                      [allowFiltering]="false"-->
                    <!--                      [hidingPriority]="5"-->
                    <!--                    >-->

                    <!--                    </dxi-column>-->
                    <!--                    <div *dxTemplate="let cell of 'dimensionsTemplate'"-->
                    <!--                         class="dimension-row">-->
                    <!--                      <div class="dimension-item">-->
                    <!--                        {{ cell.data.Width | amountFormatter : cell : 3 }}-->
                    <!--                        <i class="icon absui-icon&#45;&#45;status-width-circle-fill"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="dimension-close">-->
                    <!--                        <i class="icon absui-icon&#45;&#45;close"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="dimension-item">-->
                    <!--                        {{ cell.data.Height | amountFormatter : cell : 3 }}-->
                    <!--                        <i class="icon absui-icon&#45;&#45;status-height-circle-fill"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="dimension-close">-->
                    <!--                        <i class="icon absui-icon&#45;&#45;close"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="dimension-item">-->
                    <!--                        {{ cell.data.Depth | amountFormatter : cell : 3 }}-->
                    <!--                        <i class="icon absui-icon&#45;&#45;status-length-circle-fill"></i>-->
                    <!--                      </div>-->
                    <!--                      <div class="dimension-unit">-->
                    <!--                        {{ cell.data.DimensionUnit }}-->
                    <!--                      </div>-->
                    <!--                    </div>-->

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'customerGroups.catalogIndex' | translate }}"
                      width="130"
                      [allowSorting]="false"
                      dataField="IndexCatalogue"
                      [allowFiltering]="false"
                      headerCellTemplate="sortingHeaderTemplate"
                      [hidingPriority]="17"
                      [cssClass]="
                        orderby == 'IndexCatalogue' ? 'sort-column-bg' :  'sort-column-muted'
                      "
                    ></dxi-column>
                    <div
                      *dxTemplate="let data of 'IndexCatalogueHeaderTemplate'"
                    >
                      <div class="grid-header-wrapper">
                        {{ "customerGroups.catalogIndex" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'IndexCatalogue'"
                          (click)="switchOrder(); getPosition(documentId)"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'customerGroups.tradingIndex' | translate }}"
                      width="130"
                      [allowFiltering]="false"
                      [allowSorting]="false"
                      dataField="IndexTrading"
                      headerCellTemplate="sortingHeaderTemplate"
                      [hidingPriority]="8"
                      [cssClass]="
                        orderby == 'IndexTrading' ? 'sort-column-bg' :  'sort-column-muted'
                      "
                    ></dxi-column>
                    <div *dxTemplate="let data of 'IndexTradingHeaderTemplate'">
                      <div class="grid-header-wrapper">
                        {{ "customerGroups.tradingIndex" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'IndexTrading'"
                          (click)="switchOrder(); getPosition(documentId)"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'offers.defaultBarcode' | translate }}"
                      width="170"
                      [allowSorting]="false"
                      dataField="EAN"
                      headerCellTemplate="sortingHeaderTemplate"
                      [allowFiltering]="false"
                      [cssClass]="orderby == 'EAN' ? 'sort-column-bg' :  'sort-column-muted'"
                      [hidingPriority]="4"
                    >
                    </dxi-column>
                    <div *dxTemplate="let data of 'EANHeaderTemplate'">
                      <div class="grid-header-wrapper">
                        {{ "offers.defaultBarcode" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'EAN'"
                          (click)="switchOrder(); getPosition(documentId)"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'articles.unit' | translate }}"
                      width="80"
                      [allowSorting]="false"
                      dataField="UnitId"
                      [allowFiltering]="false"
                      [hidingPriority]="15"
                    >
                    </dxi-column>

                    <dxi-column
                      [fixed]="true"
                      caption="{{
                        'form-commercial-operation.grid.count' | translate
                      }}"
                      width="100"
                      [allowFiltering]="false"
                      [allowSorting]="false"
                      dataField="Amount"
                      cellTemplate="cellTemplateCount"
                      [hidingPriority]="25"
                    ></dxi-column>
                    <div
                      *dxTemplate="
                        let data of 'cellTemplateCount';
                        let index = i
                      "
                    >
                      <p
                        style="margin-bottom: 0px"
                        *ngIf="isCorrect"
                        [ngClass]="{
                          difrentValue:
                            data.data.AmountPrevious != data.data.Amount
                        }"
                      >
                        {{
                          data.data.AmountPrevious | amountFormatter : data : 4
                        }}
                      </p>
                      <p
                        style="margin-bottom: 0px"
                        *ngIf="!data.data.isVisible"
                      >
                        {{ data.data.Amount | amountFormatter : data : 4 }}
                      </p>
                      <dx-number-box
                        [(ngModel)]="data.data.Amount"
                        [ngModelOptions]="{ standalone: true }"
                        [visible]="data.data.isVisible"
                        (onEnterKey)="keyupEnter()"
                        (onKeyDown)="onFocusOut()"
                      >
                      </dx-number-box>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'warehouseDocument.Verified' | translate }}"
                      width="110"
                      [allowSorting]="false"
                      dataField="AmountVerified"
                      [allowFiltering]="false"
                      [hidingPriority]="28"
                      cellTemplate="numberPrecision4Template"
                    >
                    </dxi-column>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'articles.width' | translate }}"
                      width="100"
                      [allowSorting]="false"
                      dataField="Width"
                      [allowFiltering]="false"
                      [hidingPriority]="5"
                      headerCellTemplate="headerCellTemplateWidth"
                      cellTemplate="widthTemplate"
                    >
                      <div *dxTemplate="let cell of 'widthTemplate'">
                        {{ cell.data.Width | amountFormatter : cell : 3 }}
                      </div>
                      <div *dxTemplate="let width of 'headerCellTemplateWidth'">
                        <div
                          style="display: flex;
                            justify-content: center;
                            align-items: center;"
                          class="grid-header-wrapper">
                          {{ "articles.width" | translate }}
                          <!--                          <i class="icon absui-icon&#45;&#45;status-width-circle-fill"></i>-->
                        </div>
                      </div>
                    </dxi-column>
                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'articles.height' | translate }}"
                      width="100"
                      [allowSorting]="false"
                      dataField="Height"
                      [allowFiltering]="false"
                      [hidingPriority]="5"
                      headerCellTemplate="headerCellTemplateHeight"
                      cellTemplate="heightTemplate"
                    >
                      <div *dxTemplate="let cell of 'heightTemplate'">
                        {{ cell.data.Height | amountFormatter : cell : 3 }}
                      </div>
                      <div *dxTemplate="let height of 'headerCellTemplateHeight'">
                        <div
                          style="display: flex;
                            justify-content: center;
                            align-items: center;"
                          class="grid-header-wrapper">
                          {{ "articles.height" | translate }}
                          <!--                          <i class="icon absui-icon&#45;&#45;status-height-circle-fill"></i>-->
                        </div>
                      </div>
                    </dxi-column>
                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'articles.depth' | translate}}"
                      width="100"
                      [allowSorting]="false"
                      dataField="Depth"
                      [allowFiltering]="false"
                      [hidingPriority]="5"
                      headerCellTemplate="headerCellTemplateDepth"
                      cellTemplate="depthTemplate"
                    >
                      <div *dxTemplate="let depth of 'headerCellTemplateDepth'">
                        <div
                          style="display: flex;
                            justify-content: center;
                            align-items: center;"
                          class="grid-header-wrapper">
                          {{ "articles.depth" | translate }}
                          <!--                          <i class="icon absui-icon&#45;&#45;status-length-circle-fill"></i>-->
                        </div>
                      </div>
                      <div *dxTemplate="let cell of 'depthTemplate'">
                        {{ cell.data.Depth | amountFormatter : cell : 3 }}
                      </div>
                    </dxi-column>
                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'unitBox' | translate }}"
                      width="110"
                      [allowSorting]="false"
                      dataField="DimensionUnit"
                      [allowFiltering]="false"
                      [hidingPriority]="5"
                      headerCellTemplate="headerCellTemplateDimensionUnit"
                    >
                      <div *dxTemplate="let dimensionUnit of 'headerCellTemplateDimensionUnit'">
                        <div
                          style="display: flex;
                            justify-content: center;
                            align-items: center;">
                          <div
                            class="grid-header-wrapper">
                            {{ "unitBox" | translate }}
                          </div>
                          <dx-button
                            *ngIf="event.deviceType != 'mobile'"
                            class="btn-dx-top-menu-folder"
                            icon="icon absui-icon--status-info absui-icon--status-info_gray"
                            type="default"
                            (click)="showTooltipHelp = true"
                            [id]="'btn-help-size' + unicalGuid"
                          ></dx-button>
                          <dx-tooltip
                            [target]="'#btn-help-size' + unicalGuid"
                            hideEvent="dxhoverend"
                            [hideOnOutsideClick]="false"
                            [showEvent]="event.tooltipShowEvent"
                          >
                            <div
                              *dxTemplate="let data of 'content'"
                              [innerHTML]="
                          'warningSizePackingProducts' | translate
                        "
                            ></div>
                          </dx-tooltip>
                        </div>
                      </div>
                    </dxi-column>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'warehouse.location' | translate }}"
                      width="100"
                      [allowSorting]="false"
                      dataField="Location"
                      headerCellTemplate="sortingHeaderTemplate"
                      [allowFiltering]="false"
                      [cssClass]="
                        orderby == 'Location' ? 'sort-column-bg' :  'sort-column-muted'
                      "
                      [hidingPriority]="2"
                    >
                    </dxi-column>
                    <div *dxTemplate="let data of 'LocationHeaderTemplate'">
                      <div class="grid-header-wrapper">
                        {{ "warehouse.location" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'Location'"
                          (click)="switchOrder(); getPosition(documentId)"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{
                        'form-commercial-operation.grid.netAmount' | translate
                      }}"
                      [hidingPriority]="14"
                      [allowSorting]="false"
                      width="100"
                      dataField="NetPrice"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint"
                                  [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      [fixed]="true"
                      caption="{{
                        'form-commercial-operation.grid.grossAmount' | translate
                      }}"
                      [hidingPriority]="13"
                      [allowSorting]="false"
                      width="100"
                      dataField="GrossPrice"
                      cellTemplate="numberPrecisionTemplate"
                    >
                      <dxo-format type="fixedPoint"
                                  [precision]="2">
                      </dxo-format>
                    </dxi-column>

                    <dxi-column
                      [fixed]="true"
                      caption="{{
                        'articles.weight' | translate
                      }}"
                      [hidingPriority]="14"
                      [allowSorting]="false"
                      width="100"
                      dataField="Weight"
                      cellTemplate="WeightUnitTemplate"
                      headerCellTemplate="sortingHeaderTemplate"
                      [cssClass]="
                        orderby == 'Weight' ? 'sort-column-bg' :  'sort-column-muted'
                      "
                    >
                    </dxi-column>
                    <div *dxTemplate="let data of 'WeightHeaderTemplate'">
                      <div class="grid-header-wrapper">
                        {{ "articles.weight" | translate }}
                        <i
                          class="header-sort-icon"
                          *ngIf="orderby == 'Weight'"
                          (click)="switchOrder(); getPosition(documentId)"
                          [ngClass]="order === 'ASC' ? 'arr-down' : 'arr-up'"
                        ></i>
                      </div>
                    </div>
                    <div *dxTemplate="let cell of 'WeightUnitTemplate'">
                      {{ cell.value | amountFormatter : cell : 2 }} {{ cell.data.WeightUnit }}
                    </div>

                    <dxi-column
                      [fixed]="true"
                      caption="{{ 'deliveries.serialNo' | translate }}"
                      width="200"
                      [allowSorting]="false"
                      dataField="SerialNumber"
                      [allowFiltering]="false"
                      [hidingPriority]="5"
                    >
                    </dxi-column>

                    <dxi-column
                      [fixed]="true"
                      caption="{{
                        'form-financial-document.description' | translate
                      }}"
                      width="200"
                      [allowSorting]="false"
                      dataField="Description"
                      [allowFiltering]="false"
                      [hidingPriority]="6"
                    >
                    </dxi-column>
                    <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                      {{ cell.value | amountFormatter : cell : 2 }}
                    </div>
                    <div *dxTemplate="let cell of 'numberPrecision4Template'">
                      {{ cell.value | amountFormatter : cell : 4 }}
                    </div>

                    <div *dxTemplate="let cell of 'sortingHeaderTemplate'">
                      <div
                        class="grid-header-wrapper"
                        (click)="setSearchCriteria(cell.column.dataField)"
                      >
                        {{ cell.column.caption }}
                        <i
                          class="header-sort-icon"
                          [ngClass]="
                        order === 'DESC'
                          ? 'arr-down'
                          : 'arr-up'
                      "
                        ></i>
                      </div>
                    </div>
                    <dx-tooltip
                      *ngFor="let icon of sortingIcons.asc"
                      [target]="icon"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "columnSorting.asc" | translate }}
                      </div>
                    </dx-tooltip>
                    <dx-tooltip
                      *ngFor="let icon of sortingIcons.desc"
                      [target]="icon"
                      [showEvent]="event.tooltipShowEvent"
                      hideEvent="dxhoverend"
                      [hideOnOutsideClick]="false"
                    >
                      <div *dxTemplate="let data of 'content'">
                        {{ "columnSorting.desc" | translate }}
                      </div>
                    </dx-tooltip>
                  </dx-data-grid>
                </div>
              </div>

              <!--              <div class="header-document"-->
              <!--                   style="margin-top: 0px">-->
              <!--                <p class="p-header-info">-->
              <!--                  {{ "warehouseDocument.NumberOfAllItems" | translate }}:-->
              <!--                  <strong *ngIf="dataSource">{{ totalCount }}</strong> |-->

              <!--                  {{ "warehouseDocument.Verified" | translate }}:-->
              <!--                  <strong>{{ quantityVerifed }}</strong> |-->

              <!--                  {{ "warehouseDocument.addedToThePackaging" | translate }}:-->
              <!--                  <strong>{{ amountOfPlacedProducts }}</strong>-->
              <!--                </p>-->
              <!--              </div>-->

              <div class="count-container"
                   style="height: 56px">
                <div class="row"
                     style="justify-content: end">
                  <div
                    style="padding-right: 15px"
                    class="col-7 text-right"
                  >
                    <div class="contener-pay">
                      <p class="summary-positive">
                        {{ amountOfPlacedProducts }}
                      </p>
                      <p class="des-toPay">{{ "inThePackage"| translate }}</p>
                    </div>

                    <div class="contener-pay"
                         style="padding-right: 15px">
                      <p class="summary-positive">
                        {{ quantityVerifed }}
                      </p>
                      <p class="des-toPay">{{ "warehouseDocument.Verified"| translate }}
                      </p>
                    </div>

                    <div class="contener-pay"
                         style="padding-right: 15px; border-left: none;">
                      <p class="p-toPay-net"
                         *ngIf="dataSource">{{ totalCount }}
                      </p>
                      <p class="des-toPay">{{ "warehouseDocument.NumberOfAllItems"| translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!--            [height]="emptyStateHeight - heightLeftPanel"-->

            <div
              class="right-side-panel"
              [ngStyle]="{ height: calculatedHeight }"
              mwlResizable
              (resizeEnd)="resizeEnd()"
              (resizeStart)="onResizeStart()"
              (resizing)="resizing($event)"
              #leftPanel
            >
              <div class="right-panel-btn">
                <!-- <i class="icon text-icon"></i> -->
                <dx-button
                  (mousedown)="mousedownSidePanel()"
                  (mouseup)="mouseupSidePanel()"
                  (touchend)="mouseupSidePanel()"
                  (touchstart)="mousedownSidePanel()"
                  [icon]="getResizeIcon()"
                  class="btn-dx-right-filter"
                  mwlResizeHandle
                  [resizeEdges]="{ left: true }"
                >
                </dx-button>
              </div>
              <div
                class="resize-handle-left"
                mwlResizeHandle
                [resizeEdges]="{ left: true }"
                (mouseenter)="mouseEnter()"
                (mouseleave)="mouseLeave()"
              ></div>
              <div #leftPanelTab>
                <dx-scroll-view
                  width="100%"
                  height="calc(90vh - 130px)"
                  direction="vertical"
                  style="margin-top: -10px"
                >
                  <dx-tab-panel
                    [items]="[1,3, 2]"
                    [(selectedIndex)]="selectedTab"
                    style="width: 100%"
                    class="no-padding-tab-panel right-panel-box"
                    [animationEnabled]="false"
                    [swipeEnabled]="false"
                    [showNavButtons]="true"
                    (onSelectionChanged)="onSelectionChangedTab()"
                  >
                    <div *dxTemplate="let item of 'title'">
                      <div class="title-temp">
                        <i
                          class="icon absui-icon--select-none"
                          [id]="'btn-dod-pic' + unicalGuid"
                          *ngIf="item == 2"
                        ></i>
                        <i
                          class="icon absui-icon--playlist-check"
                          [id]="'btn-playlist-check' + unicalGuid"
                          *ngIf="item == 3"
                        ></i>
                        <i
                          class="icon absui-icon--package-data"
                          [id]="'btn-package-data' + unicalGuid"
                          *ngIf="item == 1"
                        ></i>

                        <dx-tooltip
                          [target]="'#btn-dod-pic' + unicalGuid"
                          [showEvent]="event.tooltipShowEvent"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                        >
                          <div *dxTemplate="let data of 'content'">
                            {{ "articles.productPhoto" | translate }}
                          </div>
                        </dx-tooltip>
                        <dx-tooltip
                          [target]="'#btn-package-data' + unicalGuid"
                          [showEvent]="event.tooltipShowEvent"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                        >
                          <div *dxTemplate="let data of 'content'">
                            {{ "articles.packagingProducts" | translate }}
                          </div>
                        </dx-tooltip>
                      </div>
                    </div>
                    <div *dxTemplate="let name of 'item'">
                      <ng-container [ngSwitch]="name">
                        <div *ngSwitchCase="1">
                          <p
                            style="width: 50px; display: block"
                            class="p-remarks"
                          >
                            {{ "articles.pasteboard" | translate }}
                          </p>
                          <app-packing-box
                            style="width: 100%"
                            [width]="460"
                            (onChosen)="onChosenBox($event)"
                            [selectedPackaging]="selectedPackaging"
                          ></app-packing-box>
                          <div class="separator"
                               style="width: 100%; margin-top: 20px; margin-bottom: 10px">
                          </div>
                          <div style="padding: 6px">
                            <div style="margin-bottom: 6px;"
                                 class="d-flex justify-content-between">
                              <p class="label-size">{{ 'dimensions' | translate }}</p>
                              <p class="value-size">{{ selectedPackaging.Width }} x {{ selectedPackaging.Height }} x
                                {{ selectedPackaging.Depth }}{{ 'cm' | translate }}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                              <p class="label-size">{{ 'packagingThickness2' | translate }}</p>
                              <p class="value-size">{{ selectedPackaging.Thickness }}{{ 'cm' | translate }}</p>
                            </div>
                          </div>
                          <div class="separator"
                               style="width: 100%; margin-top: -5px; margin-bottom: 20px">
                          </div>

                          <app-packing-simulation
                            #packingSimulationComponent
                            (onAmountOfPlacedProducts)="onAmountOfPlacedProducts($event)"
                            (usedWeightEvent)="onUsedWeightEvent($event)"
                            (onVolumeEvent)="onVolumeEvent($event)"
                            [windowWidth]="widthPackingSimulator"
                            [dimensions]="selectedPackaging"
                            [selectedProduct]="selectedProduct"
                            [addAllProducts]="addAllProducts"
                            [addFactorUnitProduct]="addFactorUnitProduct"
                            [selectedProductId]="focusedSelected[0]?.Id"
                            [statusOfDoesntFitProduct]="statusOfDoesntFitProducts"
                            (onStatusOfDoesntFitProduct)="onActualizeAmountOfPlacedProducts($event)"
                            [deleteLastPlacedProduct]="deleteLastPlacedProduct"
                            [deleteAllPlacedProducts]="deleteAllPlacedProducts"
                            (onDeletePosiotionsPlacedProducts)="deletePosiotionsPlacedProducts = $event"
                            [deletePosiotionsPlacedProducts]="deletePosiotionsPlacedProducts"
                            (onDeleteAllPlacedProducts)="deleteAllPlacedProducts = $event"
                            (onDeleteLastPlacedProduct)="deleteLastPlacedProduct = $event"
                            (onAddProductConfig)="onAddProductConfig($event)"
                            (setPreviousAmountAfterCancelConfirm)="setPreviousAmountAfterCancelConfirm()"
                            (onAddFactorUnitProductConfig)="onAddFactorUnitProductConfig($event)"
                            (onPlacedProducts)="onPlacedProducts($event)"
                          ></app-packing-simulation>

                          <div class="d-flex justify-content-between align-items-center volume">
                            <p class="label-size">{{ 'usedSpace' | translate }}</p>
                            <p class="volumeWeight">{{ usedVolume() }} %</p>
                          </div>
                          <dx-progress-bar
                            [ngClass]="{
                            'progress-bar-packing-danger': usedVolume() > 100,
                            'progress-bar-packing': usedVolume() <= 100
                            }"
                            style="display: block; margin-bottom: 21px"
                            width="100%"
                            [min]="0"
                            [max]="100"
                            [value]="usedVolume()"
                          >
                          </dx-progress-bar>

                          <div class="d-flex justify-content-between align-items-center weight">
                            <p class="label-size">{{ 'usedWeight' | translate }}</p>
                            <p class="volumeWeight">{{ usedWeight().usedWeight }} / {{ this.selectedPackaging.MaxWeight }} {{
                                'kg' | translate
                              }}
                            </p>
                          </div>
                          <dx-progress-bar
                            [ngClass]="{
                            'progress-bar-packing-danger': usedWeight().usedWeight > maxPackingWeight,
                            'progress-bar-packing': usedWeight().usedWeight <= maxPackingWeight
                            }"
                            style="display: block;"
                            width="100%"
                            [min]="0"
                            [max]="maxPackingWeight"
                            [value]="usedWeight().usedWeight"
                          >
                          </dx-progress-bar>

                          <div
                            *ngIf="selectedProduct.Weight != 0 && selectedProduct.Factor != 1 && addFactorUnitProductConfirm"
                            class="trialMode"
                          >
                            <p style="display: flex"
                               class="p-warning-text-box">
                              <i class="icon absui-icon--warning2 icon-warning-box"></i>
                              <span style="padding-left: 8px">
                                <span [innerHTML]="'warningPackingProducts' | translate"></span>
                              </span>
                            </p>
                          </div>
                          <div
                            *ngIf="addAllProducts"
                            class="trialMode"
                          >
                            <p style="display: flex"
                               class="p-warning-text-box">
                              <i class="icon absui-icon--warning2 icon-warning-box"></i>
                              <span style="padding-left: 8px">
                                <span [innerHTML]="'warningAddAll' | translate"></span>
                              </span>
                            </p>
                          </div>

                        </div>
                        <div *ngSwitchCase="2">
                          <p class="p-remarks">
                            {{ "articles.productPhoto" | translate }}
                          </p>
                          <div
                            class="img-box"
                            [ngClass]="{ 'no-height': prevPhoto != '' }"
                          >
                            <img
                              [src]="
                                prevPhoto == ''
                                  ? '../../../assets/img/Empty-state/Photo.svg'
                                  : prevPhoto
                              "
                              alt="Asseco Business Solutions SA"
                              style="position: relative; top: calc(50% - 26px)"
                              [ngClass]="{ height247: prevPhoto != '' }"
                            />
                          </div>
                        </div>
                        <div *ngSwitchCase="3">
                          <p class="p-addedItems">
                            {{ "addedItems" | translate }}
                          </p>
                          <!--                          <div class="separator"-->
                          <!--                               style="width: 100%; margin-top: 3px; margin-bottom: 3px">-->
                          <!--                          </div>-->
                          <!--                          <div *ngFor="let product of placedProduct" class="d-flex">-->
                          <!--                            <div class="detail-name" style="width: 280px;">{{ product.Name }}</div>-->
                          <!--                            <div class="detail-name" style="width: 23px">{{ product.Amount }}</div>-->
                          <!--                            <div class="detail-name" style="width: 48px;">{{ product.DimensionUnit || 'N/A' }}</div>-->
                          <!--                          </div>-->
                          <dx-data-grid
                            class="gridPositionVer"
                            [showColumnHeaders]="false"
                            [dataSource]="placedProduct"
                            [wordWrapEnabled]="true"
                            [showBorders]="true"
                            shortcut
                            [height]="emptyStateHeight - heightGrid"
                            [columnResizingMode]="'widget'"
                            [columnAutoWidth]="true"
                            [allowColumnResizing]="true"
                            (onFocusedRowChanged)="onFocusedPlacedProductChanged()"
                            [id]="'gridPlacedProduct' + unicalGuid"
                            [(focusedRowIndex)]="focusedRowIndexPlacedProduct"
                            [focusedRowEnabled]="true"
                            [hoverStateEnabled]="true"
                            #gridBank>
                            <dxo-load-panel [enabled]="false"></dxo-load-panel>
                            <dxo-scrolling mode="virtual"></dxo-scrolling>
                            <dxo-paging [pageSize]="100"></dxo-paging>
                            <dxo-grouping
                              #expand
                              [autoExpandAll]="true"
                              [allowCollapsing]="true"
                            ></dxo-grouping>

                            <dxi-column
                              caption="{{
                        'form-commercial-operation.grid.articleName' | translate
                      }}"
                              [allowSorting]="false"
                              dataField="Name"
                              [allowFiltering]="false"
                            >
                            </dxi-column>
                            <dxi-column
                              caption="{{
                        'form-commercial-operation.grid.count' | translate
                      }}"
                              [allowSorting]="false"
                              dataField="Amount"
                              [allowFiltering]="false"
                            >
                            </dxi-column>

                            <dxi-column
                              caption="{{ 'articles.unit' | translate }}"
                              [allowSorting]="false"
                              dataField="UnitId"
                              [allowFiltering]="false"
                            >
                            </dxi-column>
                          </dx-data-grid>

                          <!--                          <table class="product-table">-->
                          <!--                            <thead>-->
                          <!--                            <tr>-->
                          <!--                              <th>Name</th>-->
                          <!--                              <th>Weight</th>-->
                          <!--                              <th>Dimension Unit</th>-->
                          <!--                              <th>Amount</th>-->
                          <!--                            </tr>-->
                          <!--                            </thead>-->
                          <!--                            <tbody>-->
                          <!--                            <tr *ngFor="let product of placedProduct">-->
                          <!--                              <td>{{ product.Name }}</td>-->
                          <!--                              <td>{{ product.Weight }}</td>-->
                          <!--                              <td>{{ product.DimensionUnit || 'N/A' }}</td>-->
                          <!--                              <td>{{ product.Amount }}</td>-->
                          <!--                            </tr>-->
                          <!--                            </tbody>-->
                          <!--                          </table>-->
                        </div>
                      </ng-container>
                    </div>
                  </dx-tab-panel>

                  <!--                  <p class="p-remarks">-->
                  <!--                    {{ "articles.productPhoto" | translate }}-->
                  <!--                  </p>-->

                  <!--                  <div-->
                  <!--                    class="img-box"-->
                  <!--                    [ngClass]="{ 'no-height': prevPhoto != '' }"-->
                  <!--                  >-->
                  <!--                    <img-->
                  <!--                      [src]="-->
                  <!--                        prevPhoto == ''-->
                  <!--                          ? '../../../assets/img/Empty-state/Photo.svg'-->
                  <!--                          : prevPhoto-->
                  <!--                      "-->
                  <!--                      alt="Asseco Business Solutions SA"-->
                  <!--                      style="position: relative; top: calc(50% - 26px)"-->
                  <!--                      [ngClass]="{ height247: prevPhoto != '' }"-->
                  <!--                    />-->
                  <!--                  </div>-->
                </dx-scroll-view>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-position-verification-correction
  [isVisible]="isCorrect"
  [showPopupAnimationDuration]="showPopupAnimationDuration"
  [readOnly]="readOnly"
  [correctAmount]="correctAmount"
  (onClose)="onCorrectClosing($event)"
  (onSave)="onCorrectSave($event)"
></app-position-verification-correction>

<app-add-crm-delivery
  [isVisible]="isSingleRecordVisible"
  [readOnly]="readOnly"
  [objectToDelivery]="objectToDelivery"
  mode="add"
  (onClosing)="onSingleRecordClosing()"
  (onInserted)="onSingleRecordInserted()"
>
</app-add-crm-delivery>

<app-confirm-dialog
  [isVisible]="isSetSurplus"
  (onRemoving)="onSetSurplus()"
  (onClosing)="onSetSurplusClosing()"
  confirmHeader="documentsOrders.theVerifiedQuantityCannotExceedTheVerifiedQuantityTitle"
  confirmText="documentsOrders.theVerifiedQuantityCannotExceedTheVerifiedQuantity"
  kindDialog="question"
>
</app-confirm-dialog>
<app-confirm-dialog
  [isVisible]="isCancelAllDialogVisible"
  (onRemoving)="positionVerificationCancelAll()"
  (onClosing)="
    isCancelAllDialogVisible = false;
    cd.detectChanges();
    itemGrid.instance.focus()
  "
  confirmText="documentsOrders.cancelVerificationAll"
  kindDialog="question"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="
    !isVisible ||
    isSetSurplus ||
    isSingleRecordVisible ||
    isCorrect ||
    isCancelAllDialogVisible
  "
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<wapro-context-menu
  [dataSource]="contextMenu"
  [width]="'200px'"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
>
</wapro-context-menu>
<!--focusedSelected[0]?.ProductId-->
<app-new-article
  [selectedId]="focusedSelected[0]?.ProductId ? [focusedSelected[0]?.ProductId] : null"
  [isVisible]="isArticleVisible"
  [mode]="singleRecordMode"
  (onClosing)="isArticleVisible = false"
  [readOnly]="
    readOnly || singleRecordMode === 'show'
  "
  [title]="titleArticle"
>
</app-new-article>

<app-column-chooser
  [gridRef]="itemGrid"
  [(visible)]="columnsChooserVisible"
  (columnsChanged)="onColumnsChanged($event)"
></app-column-chooser>


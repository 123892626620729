import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ExportFakirSelections } from './export-fakir/export-fakir-model';

@Injectable({
  providedIn: 'root',
})
export class ExportFakirService {
  isSelectMode: WritableSignal<boolean> = signal(false);
  formValue: any = null;
  selections: BehaviorSubject<ExportFakirSelections> =
    new BehaviorSubject<ExportFakirSelections>({
      Commercial: 0,
      Warehouse: 0,
      Financial: 0,
      RKWB: 0,
      Sad: 0,
    });
  constructor() {}

  resetSelections() {
    this.selections.next({
      Commercial: 0,
      Warehouse: 0,
      Financial: 0,
      RKWB: 0,
      Sad: 0,
    });
  }
}

<dx-popup
  [width]="300"
  [height]="140"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: {
      type: 'slideIn',
      direction: 'bottom',
      duration: showPopupAnimationDuration
    },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'warehouseDocument.documentPositionVerification' | translate"
  [visible]="isVisible"
  (onShowing)="event.onShownPopUp(unicalGuid)"
  (onHiding)="event.onHiddenPopUp(unicalGuid)"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="saveCorrection()"
        [id]="'btn-save' + unicalGuid"
        *ngIf="!readOnly"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeCorrection()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-center flex-wrap">
            <div class="form-group-inline-right" style="width: 470px">
              <label style="width: 130px">{{
                "documentsOrders.verifiedQuantity" | translate
              }}</label>
              <dx-number-box
                width="276"
                [readOnly]="readOnly"
                valueChangeEvent="input"
                [(ngModel)]="correctAmount"
                #correctAmountInput
              ></dx-number-box>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
  <wapro-keyboard-shortcuts
    [shortcuts]="shortcuts"
    [disabled]="!isVisible"
    [unicalGuid]="unicalGuid"
  >
  </wapro-keyboard-shortcuts>
</dx-popup>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'form-commercial-operation.groupDataChange' | translate"
  [visible]="true"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [maxHeight]="maxHeight"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        (onClick)="onSave()"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onCancel()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="102%" direction="vertical" #scrollView>
      <div class="form-group-inline-right" style="width: 100%">
        <label style="width: 110px">
          {{ "form-commercial-operation.theTypeToSet" | translate }}
        </label>

        <dx-drop-down-box
          [(value)]="choosingTransaction"
          valueExpr="code"
          [deferRendering]="false"
          displayExpr="code"
          [(opened)]="isGridBoxOpened"
          [dataSource]="optionList"
          [showClearButton]="false"
          style="width: 276px"
          [readOnly]="readOnly"
        >
          <div *dxTemplate="let data of 'content'" style="height: 280px">
            <div class="row">
              <div class="col-md-12" *ngIf="isGridBoxOpened">
                <dx-data-grid
                  [dataSource]="optionList"
                  [wordWrapEnabled]="true"
                  [showBorders]="true"
                  shortcut
                  [height]="258"
                  class="dropDownBoxClassGrid"
                  [columnResizingMode]="'widget'"
                  [remoteOperations]="true"
                  (onRowClick)="onRowDblClick($event)"
                  [columnAutoWidth]="true"
                  [allowColumnResizing]="true"
                  [id]="'grid' + unicalGuid"
                  [focusedRowEnabled]="true"
                  #gridClass
                  [hoverStateEnabled]="true"
                >
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxo-scrolling mode="infinite"></dxo-scrolling>
                  <dxo-paging [pageSize]="50"></dxo-paging>
                  <dxo-selection [mode]="'single'" showCheckBoxesMode="always">
                  </dxo-selection>
                  <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

                  <dxi-column
                    [width]="100"
                    [allowSorting]="false"
                    dataField="code"
                    renderAsync="true"
                    caption="{{
                      'bankBox.grid.columns.postalCode' | translate
                    }}"
                  >
                  </dxi-column>

                  <dxi-column
                    [allowSorting]="false"
                    dataField="name"
                    renderAsync="true"
                    caption="{{
                      'form-financial-document.description' | translate
                    }}"
                  >
                  </dxi-column>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </dx-drop-down-box>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

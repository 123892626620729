<dx-popup
  [width]="300"
  [height]="300"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'offers.desciptionPositionTitle' | translate }}"
  [(visible)]="visible"
  (visibleChange)="visibleChange()"
  (onShowing)="event.onShownPopUp(unicalGuid)"
  (onHiding)="event.onHiddenPopUp(unicalGuid)"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'btn-save-item' + unicalGuid"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="close()"
        [id]="'btn-cancel-item' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#btn-save-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-cancel-item' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 100%" *ngIf="record">
          <div class="header-document" style="margin-top: 0">
            <p class="p-header-info">
              {{ "intrastatCnCodes.name" | translate }}:
              <strong>{{ record.Name }}</strong>
            </p>
            <p class="p-header-info" style="margin-top: 3px">
              {{ "articles.catalogueIndex" | translate }}:
              <strong>{{ record.IndexCatalogue }}</strong>
            </p>
          </div>
          <label style="width: 60px">{{
            "locations.description" | translate
          }}</label>
          <dx-text-area
            [(value)]="description"
            [width]="365"
            class="text-area-h-100 no-max-width"
          ></dx-text-area>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts [shortcuts]="shortcuts" [unicalGuid]="unicalGuid">
</wapro-keyboard-shortcuts>

import { Socket } from './../../socket.model';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  HostListener,
  signal,
  computed,
} from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { DxDataGridComponent, DxTreeViewComponent } from 'devextreme-angular';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { PathToNestMap } from 'src/app/nest-resolver.service';
import { NestService } from 'src/app/nest-service.service';
import { Observable, forkJoin } from 'rxjs';
import { TableService } from 'src/app/core/table-user-extension/table-service.service';
import {
  DocumentOfferDetails,
  IDocumentOfferStatus,
  IDocumentOfferOrder,
  IDocumentOfferRealisation,
  IDocumentOfferPositionsResponse,
  Offer,
} from './IDocumentOffer';
import { Subscription, of, take, switchMap } from 'rxjs';
import { GlobalDate } from 'src/app/core/date-range/GlobalDate';
import {
  AdditionalTable,
  DoubleClickResult,
  RefreshCardResult,
  SingleRecordMode,
} from 'src/app/event.model';
import { NestFinishedEvent, NestOperation } from 'src/app/nest/nest.model';
import { DynamicFilterService, ViewUserDefinition } from 'src/app/core/dynamic-filter.service';
import { UserDefinition } from 'src/app/core/filter-panel/filterpanel';
import { CustomDropdownBoxComponent } from 'src/app/core/custom-dropdown-box/custom-dropdown-box.component';
import { ReportsComponent } from 'src/app/core/reports/reports.component';
import { DateRangeComponent } from 'src/app/core/date-range/date-range.component';
import { AdditionalOperationComponent } from 'src/app/core/additional-operation/additional-operation.component';
import { HelpService } from 'src/app/help-service.service';
import { EDokumentsService } from 'src/app/libraries/e-dokuments/e-dokuments.service';
import { DataToPrint } from 'src/app/core/reports/IReportParameters';
import { RecordCardMode } from 'src/app/libraries/record-card/record-card.interface';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss'],
    standalone: false
})
export class OffersComponent implements OnInit {
  @ViewChild('additionalOperation')
  additionalOperation: AdditionalOperationComponent;
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('searchControl') searchControl: CustomDropdownBoxComponent;
  @Output('onClosing') onClosing = new EventEmitter();
  @Output() onChoosed = new EventEmitter();
  @ViewChild('statusesTree') statusesTree: DxTreeViewComponent;
  @ViewChild('gridBank') gridBank: DxDataGridComponent;
  @ViewChild('workersGrid') workersGrid: DxDataGridComponent;
  @ViewChild('positionsGrid') positionsGrid: DxDataGridComponent;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @ViewChild('tabPanel') tabPanel;
  @ViewChild('bottomSidePanel') bottomSidePanel;
  @ViewChild('dateRange') dateRange: DateRangeComponent;
  pageSize: number = 100;
  additionalTables: AdditionalTable[] = [];
  isVisible;
  isPopupMode;
  loadChildComponents: boolean = false;
  componentVisible: boolean = false;
  showBottomPanel: boolean = false;
  readOnly;
  dropDownBoxMode = false;
  selectedId;
  search;
  dataSource;
  selectedRows = [];
  modeSelectionMobile: string = 'none';
  isGridBoxOpened: boolean = false;
  isDelete: boolean = false;
  openCustomDropDown: boolean = false;
  filterCriteria: any[] = [
    { value: 'OfferDocumentDate', label: 'data wystawienia', type: 'data' },
    { value: 'OfferDocumentNumber', label: 'numer' },
    { value: 'RealizationPlanDate', label: 'termin realizacji', type: 'data' },
    { value: 'OfferDocumentNameIntuition', label: 'nazwa' },
    { value: 'CustomerName', label: 'kontrahent' },
    { value: 'MaxTotalNetAmount', label: 'kwota', type: 'number' },
    { value: 'EAN', label: 'kod kreskowy' },
    { value: 'EmployeeName', label: 'pracownik' },
  ];

  perAbdExtended = {
    editBlocked: false,
    editClosed: false,
    editRealisedPositions: false,
    setDefaultPricesForOffer: false,
    deleteBlocked: false,
    sum: false,
    deleteGroup: false,
    eDocuments: false,
    blockUnblock: false,
    labels: {
      add: false,
      delete: false,
    },
  };
  selectedAmount = signal(0);
  doubleClickResult: DoubleClickResult = 'Edycja';
  contextMenuMobile = [
    { name: 'Zaznacz', icon: 'icon checkAll', itemIndex: 99 },
    { name: 'Szczegóły', icon: 'icon absui-icon--file-edit', itemIndex: 98 },
  ];
  articleName = [];
  articlesList = [];
  articlesListPom = [];
  filterValue = '';
  displayValue: string = 'numer';
  valueCriteria = 'OfferDocumentNumber';
  shortcuts: ShortcutInput[] = [];
  articleIdFocused = null;
  order: string = 'ASC'; // asc/desc
  orderby: string = 'OfferDocumentNumber';
  isSumOffersVisible: boolean = false;
  sumOffersFilters = null;
  selectedtabIndex;
  width = 280;
  heightGrid: number = 200;
  heightBottomGrid = 200;
  widthLeftPanel = 280;
  heightBottomPanel;
  initialLocalStorageData = null;
  localStorageData = {
    leftPanelWidth: null,
    advancedFilter: null,
    rightPanelWidth: null,
    bottomPanelHeight: null,
    bottomPanelVisible: true,
    selectionTab: 0,
    columns: null,
    filterValue: null,
    filterCriteria: null,
    statusName: null,
    statusId: null,
    selectedAdditionalTable: null,
    skipUserGuide: false,
    sidePanel: {
      isVisible: true,
      width: 280,
      selectedIndex: 1,
    },
  };
  isTask: boolean = false;

  isBlockVisible = false;
  isUnblockVisible = false;
  blockText: string = '';
  unblockText: string = '';
  pinnedViewsSub: Subscription = null;
  viewUserDefinitionSub: Subscription = null;
  dynamicFilterUserDefinitions: any[] = [];
  readonly DynamicFilterDictionaryCode = 'DOCUMENT_OFFER';
  readonly USER_EXTENSIONS_GROUP_CODE = 'Oferta';
  contextMenuOperationsMobile = [
    { name: 'Zaznacz wiele', icon: 'icon checkAll', itemIndex: 99 },
    { name: 'Suma', icon: 'icon absui-icon--sum', itemIndex: 97 },
  ];
  isFilterPanelComponent = false;
  visibleAdvanceFiltersWithoutPanel = false;
  details: DocumentOfferDetails = new DocumentOfferDetails();
  mobileOperationsVisible: boolean = false;
  filterMobileVisible: boolean = false;
  timer = 300;
  myTimer;
  articleEdit = [];
  unicalGuid;
  contextMenu: any[] = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle', itemIndex: 0 },
    { text: 'Edytuj', icon: 'icon absui-icon--mode-edit', itemIndex: 1 },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off', itemIndex: 2 },
    { text: 'Pokaż', icon: 'icon absui-icon--form-items', itemIndex: 3 },
    { text: 'Powiel', icon: 'icon absui-icon--files-copy', itemIndex: 5 },
    { text: 'Nowa wersja', itemIndex: 4 },
    {
      text: 'Zaznacz wszystko',
      icon: 'icon absui-icon--checkbox-all',
      itemIndex: 6,
    },
    {
      text: 'Odznacz wszystko',
      icon: 'icon absui-icon--deselct-all',
      itemIndex: 7,
    },
    {
      text: 'Pokaż zaznaczone',
      icon: '',
      itemIndex: 8,
    },
    {
      text: 'Etykiety',
      items: [
        { text: 'Przypisz', itemIndex: 9 },
        { text: 'Skasuj', itemIndex: 10 },
      ],
    },
    {
      text: 'Operacje dodatkowe',
      itemIndex: 11,
      items: [],
    },
    {
      text: 'Dodaj zadanie CRM',
      itemIndex: 12,
      items: [],
    },
    {
      text: 'e-Dokumenty',
      itemIndex: 30,
      disabled: !this.perAbdExtended.eDocuments,
    },
  ];

  contextMenuSignal = computed(() => {
    return [
      {
        text: this.translate.instant('buttons.add'),
        icon: 'icon absui-icon--add-circle',
        itemIndex: 0,
      },
      {
        text: this.translate.instant('buttons.edit'),
        icon: 'icon absui-icon--mode-edit',
        itemIndex: 1,
      },
      {
        text: this.translate.instant('buttons.delete'),
        icon: 'icon absui-icon--highlight-off',
        itemIndex: 2,
      },
      {
        text: this.translate.instant('buttons.show'),
        icon: 'icon absui-icon--form-items',
        itemIndex: 3,
      },
      {
        text: this.translate.instant('inventory.duplicate'),
        icon: 'icon absui-icon--files-copy',
        itemIndex: 5,
      },
      {
        text: this.translate.instant('offerDocumentStatus.newVersion'),
        itemIndex: 4,
      },
      {
        text: this.translate.instant('repairFunctions.selectAll'),
        icon: 'icon absui-icon--checkbox-all',
        itemIndex: 6,
      },
      {
        text: this.translate.instant('articles.setUnselectedAll'),
        icon: 'icon absui-icon--deselct-all',
        itemIndex: 7,
      },
      {
        text: this.translate.instant('showSelected'),
        icon: '',
        itemIndex: 8,
      },
      {
        text: this.translate.instant('labels.labels'),
        items: [
          { text: this.translate.instant('labels.assign'), itemIndex: 9 },
          { text: this.translate.instant('labels.delete'), itemIndex: 10 },
        ],
      },
      {
        text: this.translate.instant(
          'form-commercial-operation.additionalOperations'
        ),
        itemIndex: 11,
        items: this.additionalOperationList(),
      },
      {
        text: this.translate.instant('tasks.addTask'),
        itemIndex: 12,
        items: [],
      },
      {
        text: this.translate.instant('menu.eDocuments'),
        itemIndex: 30,
      },
    ];
  });
  additionalOperationList = signal<any[]>([]);

  contextMenuMobileSignal = computed(() => {
    return [
      {
        text: this.mobileSelectionText(),
        icon: this.mobileSelectionIcon(),
        itemIndex: 99,
      },
      {
        text: this.translate.instant('tasks.details'),
        icon: 'icon absui-icon--file-edit',
        itemIndex: 98,
      },
      {
        text: this.translate.instant('print'),
        icon: 'icon absui-icon--print',
        itemIndex: 96,
        disabled: this.isPrintDisabled(),
        visible: this.event.deviceType === 'mobile',
      },
      ...this.contextMenuSignal(),
    ];
  });
  mobileSelectionIcon = signal('icon checkAll');
  mobileSelectionText = signal('');
  isPrintDisabled = signal<boolean>(false);

  offerStatuses: IDocumentOfferStatus[] = [];

  columnsChooserVisible: boolean = false;
  externalFieldColumns = [];
  bottomPanelTabs = [
    'Pozycje oferty',
    'Realizacje',
    'Zamówienia',
    'Uwagi',
    'Komentarz statusu',
    'Notatki',
    'Tabela dodatkowa',
  ];

  allVersionsActive: string = null;
  offerDocumentIdAllVersions: number = null;
  isAllVersions: boolean = false;
  copyText: string = '';
  newVersionText: string = '';
  userId: number;
  currentDateSub: Subscription;
  currentDate: GlobalDate = new GlobalDate();
  refreshCardResult: RefreshCardResult = 'Tak';
  focusOnLastRow: RefreshCardResult = 'Nie';
  isChangesStatus: boolean = false;
  isSendSms: boolean = false;
  isContextMenuMobile: boolean = false;
  isDetails: boolean = false;
  isShowMoreDetails: boolean = false;
  isGuideActive = false;
  isAutoOnboardingSub: Subscription = null;
  isAutoOnboarding = true;
  eDocumentsVisible: boolean;
  selectedDocumentsData: Offer[];
  dataToPrint: DataToPrint = null;
  isRecordCardVisible: boolean;
  recordCardMode: RecordCardMode;
  recordCardObjectId: number;
  focusedPositionData: any;
  articleCardPermission: boolean;
  isMobileReportsPopupVisible: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppServices,
    public cd: ChangeDetectorRef,
    public edocs: EDokumentsService,
    public event: EventService,
    public nestService: NestService,
    private router: Router,
    private tableService: TableService,
    private dynamicFilterService: DynamicFilterService,
    private helpService: HelpService,
    public translate: TranslateService
  ) {
    this.event.sendReadOnlyInfo.pipe(takeUntilDestroyed()).subscribe((data) => {
      this.readOnly = data;
      this.getData();
    });
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.activatedRouteSub = this.activatedRoute.data.subscribe((data) => {
      this.componentNests = data.nests;

      this.nestsSub = this.nestService.nests.subscribe((res) => {
        const socketCode =
          PathToNestMap.find((el) => el.path === this.router.url)?.socketCode ||
          null;

        if (!socketCode) {
          return;
        }

        const socket = res.find((el) => el.SocketCode === socketCode);

        if (!socket) {
          setTimeout(() => {
            this.nestsSub?.unsubscribe();
            this.nestService.addNest(this.componentNests);
          }, 0);
        }
      });
    });

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
    this.translate
      .get('buttons.show')
      .subscribe((text) => (this.contextMenu[3].text = text));

    this.translate
      .get('offerDocumentStatus.newVersion')
      .subscribe((text) => (this.contextMenu[5].text = text));
    this.translate
      .get('inventory.duplicate')
      .subscribe((text) => (this.contextMenu[4].text = text));

    this.translate
      .get('repairFunctions.selectAll')
      .subscribe((text) => (this.contextMenu[6].text = text));
    this.translate
      .get('articles.setUnselectedAll')
      .subscribe((text) => (this.contextMenu[7].text = text));
    this.translate
      .get('showSelected')
      .subscribe((text) => (this.contextMenu[8].text = text));
    this.translate
      .get('labels.labels')
      .subscribe((text) => (this.contextMenu[9].text = text));
    this.translate
      .get('labels.assign')
      .subscribe((text) => (this.contextMenu[9].items[0].text = text));
    this.translate
      .get('labels.delete')
      .subscribe((text) => (this.contextMenu[9].items[1].text = text));

    this.translate
      .get('form-commercial-operation.additionalOperations')
      .subscribe((text) => (this.contextMenu[10].text = text));

    this.translate
      .get('offerDocumentStatus.newVersionQuestion')
      .subscribe((text) => (this.newVersionText = text));
    this.translate
      .get('offerDocumentStatus.copyOfferQuestion')
      .subscribe((text) => (this.copyText = text));

    this.translate.get('tasks.addTask').subscribe((text) => {
      this.contextMenu[11].text = text;
    });

    this.globalNestSub = this.nestService.globalNest.subscribe((obj) => {
      if (obj === null) {
        this.closeNest = null;
      }
    });

    this.perABD = this.event.checkPermissionsBtn(
      'DOfe',
      'EOfe',
      'UOfe',
      'OOfe'
    );
    this.getPermissions();

    this.isAutoOnboardingSub = this.event.isAutoOnboarding$.subscribe(
      (value) => {
        this.isAutoOnboarding = value;
      }
    );

    this.viewUserDefinitionSub = this.dynamicFilterService
      .getViewUserDefinition(this.DynamicFilterDictionaryCode)
      .subscribe({
        next: (data) => {
          this.dynamicFilterUserDefinitions = data?.userDefinitions || [];
        },
      });

    this.translate
      .get('menu.eDocuments')
      .subscribe(
        (text) => (this.contextMenu.find((x) => x.itemIndex === 30).text = text)
      );
  }

  getPermissions() {
    this.perABDKonf = this.event.checkPermissions('KonfKolOfe');
    this.perAbdExtended.editBlocked = this.event.checkPermissions('EOfeBlok');
    this.perAbdExtended.editClosed = this.event.checkPermissions('EOfeZamk');
    this.perAbdExtended.editRealisedPositions =
      this.event.checkPermissions('EOfePozZ');
    this.perAbdExtended.setDefaultPricesForOffer =
      this.event.checkPermissions('EOfeCen');
    this.perAbdExtended.deleteBlocked = this.event.checkPermissions('UOfeBlok');
    this.perAbdExtended.sum = this.event.checkPermissions('SumOfe');
    this.perAbdExtended.deleteGroup = this.event.checkPermissions('UGrupOfe');
    this.perAbdExtended.eDocuments =
      !this.event.isDisabledEDocuments('eDokDokOfe');
    this.perAbdExtended.blockUnblock =
      this.event.checkPermissions('BlokDokOfe');
    this.perAbdExtended.labels.add =
      this.event.checkPermissions('DEtykietyOfe');
    this.perAbdExtended.labels.delete =
      this.event.checkPermissions('UEtykietyOfe');
  }

  perABD = {
    addBtn: false,
    editBtn: false,
    deleteBtn: false,
    showBtn: true,
  };

  OfferDocumentId: number = null;
  perABDKonf: boolean = false;

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }

    this.getUserParameters();

    if (!this.dropDownBoxMode) {
      this.heightGrid = window.innerHeight - 210;
    }
    if (this.isPopupMode) {
      this.heightGrid = 300;
    }
    if(this.event.isAdditionalTableAvailable()){
      this.additionalTables = this.event.getTablesByGroupCode('Oferta');
    }
    this.getExternalFieldsDefinition();
    this.userId = this.event.returnUserId();

    if (this.event.deviceType == 'mobile') {
      this.getData();
    }

    this.getTranslations();
    this.getViewConfigurations();
    this.getStatuses();
    this.deselectAllRecords();
    this.closeNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      'XGOLA'
    );
    this.findSocketAndRun('XGOLO1');

    this.currentDateSub = this.event.currentDate.subscribe(
      (currentDate: GlobalDate) => {
        this.currentDate = currentDate;
      }
    );

    setTimeout(() => {
      this.loadChildComponents = true;

      this.pinnedViewsSub = this.dynamicFilterService
        .getPinnedViewsFilters(this.DynamicFilterDictionaryCode)
        .subscribe({
          next: (data) => {
            if (!data) {
              return;
            }
            if (this.localStorageData?.advancedFilter) {
              this.localStorageData.advancedFilter.PinnedFilters =
                data.pinnedFilters;
            } else {
              this.localStorageData.advancedFilter = {
                PinnedFilters: data.pinnedFilters,
              };
            }
          },
        });
    }, 1000);

    this.activatedRoute.queryParams.subscribe((params) => {
      this.OfferDocumentId = +params.id;
      if (params.id) {
        this.getData();
      }
    });

    this.additionalOperationShortcutSub =
      this.event.additinalOperationShortcut$.subscribe((code: string) => {
        if (this.isPopupMode && !this.isVisible) {
          return;
        }
        if (this.dropDownBoxMode) {
          return;
        }
        if (
          this.isSingleRecordVisible ||
          this.isChangesStatus ||
          this.visibleUserExtensions ||
          this.isSendSms ||
          this.isSumOffersVisible ||
          this.isShortcutTableOpened ||
          this.isExternalTableVisible ||
          this.isClipboardVisible ||
          this.columnsChooserVisible ||
          this.copyPopupVisible ||
          this.newVersionPopupVisible ||
          this.isDelete ||
          this.isOrderCreated ||
          this.isPlannedOrdersVisible
        ) {
          return;
        }

        this.contextMenuClick({
          itemData: {
            itemIndex: code,
          },
        });
      });
    this.checkRecordCardPermission();
  }

  checkRecordCardPermission(): void {
    const additionalOperations = this.event.decryptString(
      localStorage.getItem('appAdditionalOperationsPermissions')
    );
    const POZ_OFERTY = additionalOperations.find(
      (op) => op.GroupCode === 'OFERTA_BRW'
    );
    this.articleCardPermission = POZ_OFERTY.Permissions.find(
      (x) => x.Shortcut === 'Ctrl+Shift+['
    )
      ? true
      : false;

    this.offerPositionsContextMenu.find(
      (x) => x.itemIndex === '1111_000_KARTA_ART'
    ).disabled = !this.articleCardPermission;
  }

  selectedOwnOperation: any = null;
  runOwnAdditionalOperation(operation: any){
    this.selectedOwnOperation = operation;
    this.findSocketAndRun(`XGOLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E1`);
  }

  runInnerOwnOperation(){
    const data = this.event.prepareAdditionalOperationData(this.selectedOwnOperation,this.currentDate, this.selectedRows.length == 0 ? this.articleEdit[0] : null)
    this.event.runOwnAdditionalOperation(data).subscribe({
      next: () => {
        if(this.selectedOwnOperation?.RefreshList){
          this.getData();
        }
        this.findSocketAndRun(`XGOLEX${this.selectedOwnOperation.GroupCode}_${this.selectedOwnOperation.Name}_E2`);
      },
      error: (err) => {
        this.event.httpErrorNotification(err);
      }
    })
  }

  additionalOperationShortcutSub: Subscription = null;
  focusedElementNr: string = null;
  // onlyShow: boolean = false;
  OferDocumentIdAdditionalField: number = null;
  contextMenuClick = (e) => {
    if(e.itemData.IsOuter){
      this.runOwnAdditionalOperation(e.itemData);
      return;
    }
    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.showNewRow();
        }
        break;
      case 1:
        if (!this.readOnly) {
          this.setArticleLock();
        }
        break;
      case 2:
        if (!this.readOnly) {
          this.isDelete = true;
        }
        break;
      case 3:
        if (!this.readOnly) {
          this.setSingleRecordVisible('show');
        }
        break;
      case 4:
        if (!this.readOnly) {
          const el = this.getFocusedElement();
          this.focusedElementNr = el?.OfferDocumentNumber;
          this.newVersionPopupVisible = true;
        }
        break;
      case 5:
        if (!this.readOnly) {
          const el = this.getFocusedElement();
          this.focusedElementNr = el?.OfferDocumentNumber;
          this.copyPopupVisible = true;
        }
        break;

      case 6:
        this.skipSendSelectionEvent = true;
        this.gridBank.instance.selectAll();
        this.selectAll();
        break;

      case 7:
        this.skipSendSelectionEvent = true;
        this.selectedRows = [];
        this.deselectAllRecords();
        break;
      case 8:
        if (this.onlySelected) {
          this.revertSelectOnly();
        } else {
          this.onOnlySelected();
        }
        break;
      case 9:
        if (!this.readOnly && this.perAbdExtended.labels.add) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.isLabel = true;
          }
        }
        break;
      case 10:
        if (!this.readOnly && this.perAbdExtended.labels.delete) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.event.deleteLabel('offers/selection/label');
            setTimeout(() => {
              this.selectedRows = [];
              this.dataSource.reload();
            }, 100);
          }
        }
        break;

      case 12:
        if (!this.readOnly) {
          this.createCrmTask();
        }
        break;
      case 30:
        this.eDocumentsVisible = true;
        this.cd.detectChanges();
        break;
      case '1111_000_BLOKDOK':
        if (!this.readOnly) {
          this.findSocketAndRun('XGOLEXOFERTA_BRWBLOKDOK_E1');
        }
        break;
      case '1111_000_ODBLOKDOK':
        if (!this.readOnly) {
          this.findSocketAndRun('XGOLEXOFERTA_BRWODBLOKDOK_E1');
        }
        break;
      case '1111_000_SCHOWCOFE':
        this.copyToClipboardNest();
        break;
      case '1111_000_SCHOWVOFE':
        this.showClipboardNest();
        break;
      case '0011_000_POLADOD':
        if (!this.readOnly && this.articlesListPom.length) {
          this.additionalFieldsList = this.articlesListPom[0];
          this.OferDocumentIdAdditionalField =
            this.articlesListPom[0].OfferDocumentId;
          this.findSocketAndRun('XGOLEXOFERTA_BRWPOLADOD_E1');
        }
        break;
      case '0011_000_POLADODGRUP':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            //this.onlyShow = false;
            this.additionalFieldsList = {
              Field1: '',
              Field2: '',
              Field3: '',
              Field4: '',
              Field5: '',
              Field6: '',
              Field7: '',
              Field8: '',
              Field9: '',
              Field10: '',
            };
            this.OferDocumentIdAdditionalField = null;
            this.findSocketAndRun('XGOLEXOFERTA_BRWPOLADODGRUP_E1');
          }
        }
        break;

      case '1111_000_UTWZOFE11':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGOLEXOFERTA_BRWUTWZOFE11_E1');
          }
        }
        break;
      case '1111_000_UTWZAMOFE':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGOLEXOFERTA_BRWUTWZAMOFE_E1');
          }
        }
        break;
      case '1111_000_ZMSTATOFE':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGOLEXOFERTA_BRWZMSTATOFE_E1');
          }
        }
        break;
      case '1111_000_SMSOFE':
        if (!this.readOnly) {
          if (this.selectedRows.length == 0) {
            this.event.showNotification(
              'info',
              this.translate.instant('buffor.selectLeastPosition')
            );
          } else {
            this.findSocketAndRun('XGOLEXOFERTA_BRWSMSOFE_E1');
          }
        }
        break;
      case '1111_000_KARTA_KONTR':
        this.showRecordCard('contractor');
        break;
      case 97:
        this.offersSumOpen();
        break;
      case 99:
        this.modeSelectionMobile = 'multiple';
        let row = this.gridBank.instance.getDataSource().items()[
          this.focusedRowIndex
        ];
        const id = row.OfferDocumentId;
        if (!this.selectedRows.includes(id)) {
          this.gridBank.instance.selectRows(id, true);
        } else {
          this.gridBank.instance.deselectRows(id);
        }
        this.isContextMenuMobile = false;
        break;
      case 98:
        this.isContextMenuMobile = false;
        this.showBottomPanel = true;
        this.getDatailsData(this.articlesListPom[0].OfferDocumentId);
        this.isDetails = true;
        break;
      case 96:
        this.isMobileReportsPopupVisible = true;
        this.cd.detectChanges();
        break;
      default:
        break;
    }
  };

  taskSelectedId: any = [];

  createCrmTask() {
    if (this.articlesListPom.length > 0 && !this.readOnly) {
      this.event.onShown();
      this.appService
        .postAuth(
          `offers/additionalOperations/${this.articlesListPom[0].OfferDocumentId}/createCrmTask`,
          null
        )
        .subscribe(
          (res) => {
            this.taskSelectedId = [res['Id']];
            this.isTask = true;
            this.cd.detectChanges();
            this.event.onHidden();
          },
          (error) => {
            this.event.httpErrorNotification(error);
            this.event.onHidden();
          }
        );
    }
  }

  moveToOrderDocumentOne = () => {
    this.appService
      .postAuth(`offers/additionalOperations/moveToOrderDocument`, {
        OfferDocumentId: null,
        IsOneOrderDocument: false,
      })
      .subscribe(
        () => {
          this.event.showNotification(
            'info',
            this.translate.instant('offers.anOrderHasBeenCreated')
          );
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGOLEXOFERTA_BRWUTWZOFE11_E2');
        }
      );
  };

  onNewTaskClosing() {
    this.isTask = false;
    this.cd.detectChanges();
  }

  onInsertedStatus = () => {
    this.isChangesStatus = false;
    this.dataSource.reload();
    this.findSocketAndRun('XGOLEXOFERTA_BRWZMSTATOFE_E2');
  };

  isOrderCreated = false;
  createdOrderId: number[] = null;
  moveToOrderDocument = () => {
    this.appService
      .postAuth(`offers/additionalOperations/moveToOrderDocument`, {
        IsOneOrderDocument: true,
        OfferDocumentId: null,
      })
      .subscribe(
        (res: { Id: number }) => {
          this.event.showNotification(
            'info',
            this.translate.instant('offers.anOrderHasBeenCreated')
          );
          this.createdOrderId = [res.Id];
        },
        (error) => {
          this.event.httpErrorNotification(error);
        },
        () => {
          this.findSocketAndRun('XGOLEXOFERTA_BRWUTWZAMOFE_E2');
        }
      );
  };

  onOrderClosing() {
    this.isOrderCreated = false;
    this.createdOrderId = null;
    this.selectedRows = [];
  }

  onOrderInserted() {
    this.isOrderCreated = false;
    this.createdOrderId = null;
    this.selectedRows = [];
  }

  visibleUserExtensions: boolean = false;

  additionalFieldsList = {
    Field1: '',
    Field2: '',
    Field3: '',
    Field4: '',
    Field5: '',
    Field6: '',
    Field7: '',
    Field8: '',
    Field9: '',
    Field10: '',
  };

  allVersionsItems = [{ value: true, label: 'tak' }];

  onClosingUserExtensions = () => {
    this.visibleUserExtensions = false;
    this.cd.detectChanges();
    if (this.OferDocumentIdAdditionalField) {
      this.findSocketAndRun('XGOLEXOFERTA_BRWPOLADOD_E2');
    } else {
      this.findSocketAndRun('XGOLEXOFERTA_BRWPOLADODGRUP_E2');
    }
  };

  onInsertedUserExtensions = (e) => {
    const uri = this.OferDocumentIdAdditionalField
      ? `offers/additionalOperations/${this.OferDocumentIdAdditionalField}/setAdditionalFields`
      : 'offers/additionalOperations/setAdditionalFields';

    this.appService.putAuth(uri, e).subscribe(
      () => {
        // this.visibleUserExtensions = false;
        this.additionalFieldsList = {
          Field1: '',
          Field2: '',
          Field3: '',
          Field4: '',
          Field5: '',
          Field6: '',
          Field7: '',
          Field8: '',
          Field9: '',
          Field10: '',
        };
        this.dataSource.reload();
        this.cd.detectChanges();
        if (this.OferDocumentIdAdditionalField) {
          this.findSocketAndRun('XGOLEXOFERTA_BRWPOLADOD_E2');
        } else {
          this.findSocketAndRun('XGOLEXOFERTA_BRWPOLADODGRUP_E2');
        }
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  };

  getTranslations() {
    this.translate.get('orderHistory.showOrder').subscribe((text) => {
      this.offerOrdersContextMenu[0].text = text;
    });
    this.translate.get('offers.showWarehouseDoc').subscribe((text) => {
      this.offerRealisationsContextMenu[0].text = text;
    });
    this.translate.get('offers.showComercialDoc').subscribe((text) => {
      this.offerRealisationsContextMenu[1].text = text;
    });

    this.translate.get('plannedOrders.plannedReceipts').subscribe((text) => {
      this.offerPositionsContextMenu[0].text = text;
    });
    this.translate
      .get('plannedOrders.scheduledDeliveries')
      .subscribe((text) => {
        this.offerPositionsContextMenu[1].text = text;
      });
    this.translate.get('offers.card').subscribe((text) => {
      this.offerPositionsContextMenu[2].text = text;
    });

    this.translate.get('recordCard.articleCard').subscribe((text) => {
      this.offerPositionsContextMenu[3].text = text;
    });

    this.translate
      .get('mark')
      .subscribe((text) => (this.contextMenuMobile[0].name = text));
    this.translate
      .get('tasks.details')
      .subscribe((text) => (this.contextMenuMobile[1].name = text));
    this.translate
      .get('selectMultiple')
      .subscribe((text) => (this.contextMenuOperationsMobile[0].name = text));
    this.translate
      .get('stockHistory.sum')
      .subscribe((text) => (this.contextMenuOperationsMobile[1].name = text));

    this.translate
      .get('yes')
      .subscribe((text) => (this.allVersionsItems[0].label = text));

    this.translate
      .get('form-commercial-operation.form.dateOfIssue')
      .subscribe((text: string) => {
        this.filterCriteria[0].label = text.toLowerCase();
        this.displayValue = text.toLowerCase();
      });

    this.translate
      .get('constractors.filter.customerCode')
      .subscribe((text: string) => {
        this.filterCriteria[1].label = text.toLowerCase();
      });

    this.translate
      .get('plannedOrders.realisationDate')
      .subscribe((text: string) => {
        this.filterCriteria[2].label = text.toLowerCase();
      });

    this.translate.get('offers.offerName').subscribe((text: string) => {
      this.filterCriteria[3].label = text.toLowerCase();
    });

    this.translate.get('userExtensions.customers').subscribe((text: string) => {
      this.filterCriteria[4].label = text.toLowerCase();
    });

    this.translate
      .get('form-financial-document.grid.amount')
      .subscribe((text: string) => {
        this.filterCriteria[5].label = text.toLowerCase();
      });

    this.translate.get('constractors.filter.ean').subscribe((text: string) => {
      this.filterCriteria[6].label = text.toLowerCase();
    });

    this.translate.get('worker').subscribe((text: string) => {
      this.filterCriteria[7].label = text.toLowerCase();
    });

    this.translate
      .get('offers.bottomTabs.positions')
      .subscribe((text) => (this.bottomPanelTabs[0] = text));
    this.translate
      .get('offers.bottomTabs.realisations')
      .subscribe((text) => (this.bottomPanelTabs[1] = text));
    this.translate
      .get('offers.bottomTabs.orders')
      .subscribe((text) => (this.bottomPanelTabs[2] = text));
    this.translate
      .get('comments')
      .subscribe((text) => (this.bottomPanelTabs[3] = text));
    this.translate
      .get('servicesDevices.commentStatus')
      .subscribe((text) => (this.bottomPanelTabs[4] = text));
    this.translate
      .get('notes.notes')
      .subscribe((text) => (this.bottomPanelTabs[5] = text));

    this.translate
      .get('form-commercial-operation.doYouPerformDocumentBlock')
      .subscribe((text) => (this.blockText = text));
    this.translate
      .get('form-commercial-operation.doYouPerformDocumentUnblock')
      .subscribe((text) => (this.unblockText = text));

    if (this.additionalTables.length) {
      this.translate.get('articles.externalTable').subscribe((text) => {
        this.bottomPanelTabs[6] = text;
      });
    }
  }

  closeDropBox = () => {
    this.isGridBoxOpened = false;
    this.onChoosed.emit(null);
  };

  getViewConfigurations = () => {
    try {
      if (!localStorage.getItem('leftOfferPanel')) {
        this.appService
          .getAuth(`viewConfigurations/OfferPanel`)
          .subscribe((res) => {
            if (res.Configuration) {
              this.localStorageData = Object.assign(
                this.localStorageData,
                JSON.parse(res.Configuration)
              );
            }

            if (
              this.localStorageData.bottomPanelVisible &&
              this.localStorageData.bottomPanelHeight == null
            ) {
              this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
              this.heightGrid = window.innerHeight - 435;
              this.tabPanel.height = '100%';
              this.gridBank?.instance?.updateDimensions();
            }
            localStorage.setItem(
              'leftOfferPanel',
              JSON.stringify(this.localStorageData)
            );
            if (this.localStorageData.leftPanelWidth <= '21px') {
              this.localStorageData.sidePanel.isVisible = false;
            }
            this.getViewConfigurations();
          });
      } else if (
        localStorage.getItem('leftOfferPanel') &&
        !this.dropDownBoxMode
      ) {
        this.localStorageData = JSON.parse(
          localStorage.getItem('leftOfferPanel')
        );
        this.initialLocalStorageData = JSON.parse(
          localStorage.getItem('leftOfferPanel')
        );

        if (this.localStorageData.leftPanelWidth != null) {
          this.leftPanel.nativeElement.style.width =
            this.localStorageData.leftPanelWidth;
        }
        if (this.localStorageData.leftPanelWidth != null) {
          this.width = Number(
            this.localStorageData.leftPanelWidth.replace('px', '')
          );
        }
        if (this.localStorageData.rightPanelWidth != null) {
          this.rightPanel.nativeElement.style.width =
            this.localStorageData.rightPanelWidth;
        }

        if (
          this.additionalTables.length &&
          this.localStorageData?.selectedAdditionalTable?.TableId
        ) {
          const name = this.additionalTables.find(
            (el) =>
              el.TableId ===
              this.localStorageData?.selectedAdditionalTable?.TableId
          )?.Name;
          this.bottomPanelTabs[6] = name;
          this.localStorageData.selectedAdditionalTable.text = name;
        }

        this.filterValue = this.localStorageData.filterValue;

        if(!this.localStorageData?.advancedFilter || !this.localStorageData?.advancedFilter?.FilterUsersDefinitionId){
          this.getDefaultFilter();
        }
        else if (
          this.localStorageData?.advancedFilter
            ?.FilterUsersDefinitionId ||
          this.localStorageData?.advancedFilter?.IsPinned
        ) {
          this.getSavedFilterDefinitions();
        }
        if (this.localStorageData?.advancedFilter?.PinnedFilters) {
          this.dynamicFilterService.addPinnedViewsFilters(
            this.DynamicFilterDictionaryCode,
            this.localStorageData.advancedFilter.PinnedFilters
          );
        }
        setTimeout(() => {
          try {
            if (
              this.localStorageData.bottomPanelVisible != null &&
              this.localStorageData.bottomPanelVisible
            ) {
              this.ngModelChange();
            }

            if (!this.localStorageData.bottomPanelVisible) {
              this.heightGrid = window.innerHeight - 210;
              this.tabPanel.height = '38px';
              this.gridBank?.instance?.updateDimensions();
            }
            this.tabPanel.selectedIndex = this.localStorageData.selectionTab;
            if (this.localStorageData.leftPanelWidth <= '21px') {
              this.localStorageData.sidePanel.isVisible = false;
            }
          } catch {}
        }, 0);

        this.updateColumns();

        if (
          this.localStorageData.bottomPanelHeight != null &&
          this.localStorageData.bottomPanelVisible
        ) {
          this.bottomSidePanel.nativeElement.style.height =
            this.localStorageData.bottomPanelHeight;
          this.heightBottomGrid =
            Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
            35;

          this.heightGrid =
            window.innerHeight -
            Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
            200;
        }

        if (!this.localStorageData.bottomPanelVisible) {
          this.heightGrid = window.innerHeight - 210;
          this.tabPanel.height = '38px';
        }
        this.gridBank?.instance?.updateDimensions();
        this.tabPanel.selectedIndex = this.localStorageData.selectionTab;
      }
      this.getData();
    } catch (e) {
      this.onInitErr = true;
    }
  };

  ngAfterViewInit() {
    this.cd.detectChanges();
    this.contextMenu.forEach((el) => this.contextMenuMobile.push(el));

    if (!this.additionalTables.length) {
      this.bottomPanelTabs.pop();
    }

    this.shortcuts.push(
      {
        key: 'f5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.getData();
        },
        preventDefault: true,
      },
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            return;
          }
          this.helpService.openHelp('sprzedaz-i-magazyn/CRM/modul-ofert');
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + f12',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isExpansionListVisible = true;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: 'f9',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.dateRange.openDateRange();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f7',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            this.setCustomerFromFocusedRow();
          } else {
            this.isCustomerVisible = true;
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (e.event.shiftKey) {
            if (this.onlySelected) {
              this.revertSelectOnly();
            } else {
              this.onOnlySelected();
            }
          } else {
            this.openCustomDropDown = !this.openCustomDropDown;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + f10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clearValues();
          }
        },
        preventDefault: true,
      },
      {
        key: 'f8',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.localStorageData?.sidePanel?.selectedIndex === 0) {
            this.localStorageData.sidePanel.selectedIndex = 1;
            this.cd.detectChanges();
          }
          if (
            this.localStorageData.sidePanel.selectedIndex === 1 &&
            this.localStorageData?.sidePanel?.isVisible
          ) {
            setTimeout(() => {
              this.openAdvanceFilters();
              this.cd.detectChanges();
            }, 500);
          }
          if (!this.localStorageData?.sidePanel?.isVisible) {
            this.isFilterPanelComponent = true;
            this.openAdvanceFiltersWithoutPanel();
            this.cd.detectChanges();
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.shiftKey) {
            this.showNewRow();
          }

          if (!this.readOnly && e.event.shiftKey) {
            const el = this.getFocusedElement();
            this.focusedElementNr = el?.OfferDocumentNumber;
            this.copyPopupVisible = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + e',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.perAbdExtended.eDocuments) {
            this.edocs.getToEDocuments(
              'Offers',
              this.articlesListPom[0]?.OfferDocumentId,
              this.articlesListPom[0]?.OfferDocumentNumber
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.altKey) {
            this.offersSumOpen();
          }

          if (e.event.altKey && !this.readOnly) {
            this.router.navigateByUrl('trade-operations/sales');
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          // wydruki
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + o',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!e.event.shiftKey) {
            this.additionalOperation.openList();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + y',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.additionalTables.length) {
            return;
          }

          if (this.articleEdit.length == 1) {
            this.isExternalTableVisible = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + c',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.copyToClipboardNest();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + v',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.showClipboardNest();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.perABD.editBtn &&
            this.articleEdit.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.showEditArticle();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.perABD.deleteBtn &&
            this.articleEdit.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.isDelete = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'shift + f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            this.perABD.showBtn &&
            this.articleEdit.length > 0 &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            this.setSingleRecordVisible('show');
          }
        },
        preventDefault: true,
      },
      {
        key: 'enter',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (
            !this.readOnly &&
            !this.isSingleRecordVisible &&
            (this.isGridBoxOpened || !this.dropDownBoxMode)
          ) {
            if (this.articleEdit.length > 0) {
              this.onChoosingArticles();
            }
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 0;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 1;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 2;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 4',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 3;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 5',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.selectedtabIndex = 4;
          if (!this.showBottomPanel) {
            this.ngModelChange();
          }
        },
        preventDefault: true,
      },
      {
        key: 'alt + 6',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.additionalTables.length) {
            this.selectedtabIndex = 5;
            if (!this.showBottomPanel) {
              this.ngModelChange();
            }
          }
        },
        preventDefault: true,
      }
    );

    for (let table of this.additionalTables) {
      if (table.Shortcut) {
        this.shortcuts.push({
          key: table.Shortcut.toLowerCase(),
          allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
          command: () => {
            if (this.articleEdit.length) {
              this.shortcutTable = [table];
              this.isShortcutTableOpened = true;
            }
          },
          preventDefault: true,
        });
      }
    }

    setTimeout(() => {
      if (
        !this.localStorageData.skipUserGuide &&
        this.isAutoOnboarding &&
        this.isInNormalMode()
      ) {
        this.isGuideActive = true;
      }
    }, 1000);

    if (
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
      this.event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
      this.event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' &&
      this.perAbdExtended.eDocuments
    ) {
      this.bottomPanelTabs.push(this.translate.instant('menu.eDocuments'));
    }
  }

  isInNormalMode() {
    return !(this.dropDownBoxMode || this.event.deviceType === 'mobile');
  }

  showClipboardNest() {
    this.findSocketAndRun('XGOLEXOFERTA_BRWSCHOWVOFE_E1');
  }

  copyToClipboardNest() {
    this.findSocketAndRun('XGOLEXOFERTA_BRWSCHOWCOFE_E1');
  }

  async copySelectedToClipboard(nest: boolean = false) {
    let el;
    if (!this.selectedRows.length) {
      el = this.getFocusedElement();
    }
    await this.copyToClipboard(el?.OfferDocumentId);
    if (nest) {
      this.findSocketAndRun('XGOLEXOFERTA_BRWSCHOWCOFE_E2');
    }
  }

  timeoutHandler;

  mouseup() {
    if (this.timeoutHandler) {
      clearTimeout(this.timeoutHandler);
      this.ngModelChange();
      this.timeoutHandler = null;
    }
  }

  mousedown() {
    this.timeoutHandler = setTimeout(() => {
      this.timeoutHandler = null;
    }, 500);
  }

  ngModelChange = () => {
    this.showBottomPanel = !this.showBottomPanel;
    if (!this.dropDownBoxMode) {
      if (
        this.showBottomPanel &&
        this.localStorageData.bottomPanelHeight == null
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
        this.heightGrid = window.innerHeight - 435;
        this.tabPanel.height = '100%';
        this.heightBottomGrid = 245;
      } else if (
        this.showBottomPanel &&
        this.localStorageData.bottomPanelHeight != null
      ) {
        this.bottomSidePanel.nativeElement.style.minHeight =
          this.localStorageData.bottomPanelHeight;
        this.heightGrid =
          window.innerHeight -
          Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
          200;
        this.tabPanel.height = '100%';
        this.heightBottomGrid =
          Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
          35;
      } else {
        this.bottomSidePanel.nativeElement.style.minHeight = `34px`;
        this.tabPanel.height = '34px';
        this.bottomSidePanel.nativeElement.style.height = `34px`;
        this.heightGrid = window.innerHeight - 210;
      }
      //this.heightBottomGrid = 200;
      this.localStorageData.bottomPanelVisible = this.showBottomPanel;
      this.gridBank?.instance?.updateDimensions();
    }
    if (this.showBottomPanel && this.articlesListPom.length) {
      this.getDatailsData(this.articlesListPom[0].OfferDocumentId);
    }
    if (!this.showBottomPanel) {
      this.details = new DocumentOfferDetails();
      this.isDetailOnFocus = false;
    }
    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  };

  isGuideButtonHighlighted = false;

  @HostListener('click', ['$event.target'])
  onClick() {
    if (this.isGuideButtonHighlighted) {
      this.isGuideButtonHighlighted = false;
    }
  }

  highlightGuideButton = (autoHide?: boolean, duration?: number) => {
    this.isGuideButtonHighlighted = true;
    if (autoHide) {
      setTimeout(() => {
        this.isGuideButtonHighlighted = false;
      }, duration);
    }
  };

  getDefaultFilter(){
    this.event.getDefaultFilter(this.DynamicFilterDictionaryCode).subscribe((data: any)=>{
      if(data){
        this.event.convertDefaultFilter(this.DynamicFilterDictionaryCode, data)
        this.localStorageData.advancedFilter = {
          ...data,
          PinnedFilters:
            this.localStorageData?.advancedFilter?.PinnedFilters || [],
        };
        localStorage.setItem(
          'leftOfferPanel',
          JSON.stringify(this.localStorageData)
        );
        this.getData();
      }
    })
  }

  onGuideFinished() {
    this.isGuideActive = false;
    this.highlightGuideButton(true, 2000);
    this.localStorageData.skipUserGuide = true;

    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  runGuide() {
    this.isGuideActive = true;
  }

  ngOnDestroy() {
    this.additionalOperationShortcutSub?.unsubscribe();
    this.pinnedViewsSub?.unsubscribe();
    this.isAutoOnboardingSub?.unsubscribe();
    this.viewUserDefinitionSub?.unsubscribe();

    this.tableService.removeTableContext('Oferta');
    this.articleEdit = [];

    if (
      !this.dropDownBoxMode &&
      JSON.stringify(this.localStorageData) !==
        JSON.stringify(this.initialLocalStorageData)
    ) {
      localStorage.setItem(
        'leftOfferPanel',
        JSON.stringify(this.localStorageData)
      );
      const session = {
        View: 'OfferPanel',
        Configuration: JSON.stringify(this.localStorageData),
        TenantId: this.event.footerInfo.TenantId,
      };
      this.appService
        .postAuth(`viewConfigurations`, session)
        .subscribe(() => {});
    }

    this.activatedRouteSub?.unsubscribe();
    this.globalNestSub?.unsubscribe();
    this.currentDateSub?.unsubscribe();
  }

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  getOrderBy() {
    if (this.valueCriteria.startsWith('OfferDocumentName')) {
      return 'Name';
    } else if (this.valueCriteria === 'MaxTotalNetAmount') {
      return 'TotalNetAmount';
    }

    return this.valueCriteria.indexOf('Intuition') === -1
      ? this.valueCriteria
      : this.valueCriteria.substring(
          0,
          this.valueCriteria.indexOf('Intuition')
        );
  }

  selectedRowToSms = [];
  skipSendSelectionEvent = false;
  isAllSelected: boolean = false;
  onSelectionChanged = (e) => {
    this.selectedDocumentsData = e.selectedRowsData;
    if (this.skipSendSelectionEvent) {
      this.toSelect = [];
      this.toDeselect = [];
      this.skipSendSelectionEvent = false;
      return;
    }

    if (!this.onlySelected) {
      if (
        e.currentDeselectedRowKeys.length &&
        e.currentDeselectedRowKeys.length === this.dataSource._totalCount
      ) {
        this.deselectAllRecords();
        return;
      }
    }

    this.toSelect = this.toSelect.concat(e.selectedRowKeys);
    this.selectedAmount.set(
      this.selectedAmount() + e.currentSelectedRowKeys.length
    );
    if (this.selectedAmount() < 0) {
      this.selectedAmount.set(0);
    }
    this.toDeselect = this.toDeselect.concat(e.currentDeselectedRowKeys);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    if (
      this.selectedRows.length === 0 ||
      (this.selectedRows.length &&
        this.selectedRows.length !== this.dataSource._items.length)
    ) {
      this.isAllSelected = false;
    }

    if (this.dataSource._items.length) {
      this.setSelectionTimeout();
    }
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        let data = [];
        for (let item of this.toSelect) {
          if (item?.OfferDocumentId) {
            data.push(item.OfferDocumentId);
          } else {
            data.push(item);
          }
        }
        data = this.event.removeDuplicates(data);

        this.event
          .selectRecords(`offers/selection/documents/select`, this.selectedRows)
          .then((val) => {
            if (val) {
              this.toSelect = [];
            }
          });
      }
      if (this.toDeselect.length) {
        let data = [];
        for (let item of this.toDeselect) {
          if (item?.OfferDocumentId) {
            data.push(item.OfferDocumentId);
          } else {
            data.push(item);
          }
        }
        data = this.event.removeDuplicates(data);
        const items = this.dataSource.items();
        const toRemove = [];
        for (let toDes of data) {
          const item = items.find((el) => el.OfferDocumentId === toDes);
          if (!item) {
            toRemove.push(data.indexOf(toDes));
          }
        }
        for (let i = toRemove.length - 1; i >= 0; i--) {
          data.splice(toRemove[i], 1);
        }
        this.selectedAmount.set(this.selectedAmount() - data.length);
        if (this.selectedAmount() < 0) {
          this.selectedAmount.set(0);
        }
        this.event
          .selectRecords(`offers/selection/documents/unselect`, data)
          .then((val) => {
            if (val) {
              this.toDeselect = [];
            }
          });
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  // pojedyncze
  selectItemTaskStatus = (e) => {
    if (e.itemData.StatusCode !== null) {
      this.localStorageData.statusName = e.itemData.StatusName;
      this.localStorageData.statusId = e.itemData.OfferDocumentStatusId;
    } else {
      this.localStorageData.statusName = null;
      this.localStorageData.statusId = null;
    }
    this.getData();
  };

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 280) {
      width = 280;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.localStorageData.leftPanelWidth = `${width}px`;
    this.localStorageData.rightPanelWidth = `calc(100% - ${rightWidth}px)`;
    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  onResizeStartBottom = () => {
    this.heightBottomPanel = this.bottomSidePanel.nativeElement.clientHeight;
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  resizeEndBottom() {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  }

  resizingBottom = (e) => {
    let height;
    this.showBottomPanel = true;
    this.tabPanel.visible = this.showBottomPanel;
    height = this.heightBottomPanel - e.edges.top;
    this.bottomSidePanel.nativeElement.style.minHeight = '230px';
    if (height < 235) {
      height = 235;
    }
    if (height > window.innerHeight - 250) {
      height = window.innerHeight - 250;
    }
    this.bottomSidePanel.nativeElement.style.height = `${height}px`;
    this.heightBottomGrid = height - 35;
    this.tabPanel.height = '100%';
    this.heightGrid = window.innerHeight - height - 200;
    this.localStorageData.bottomPanelHeight = `${height}px`;
    this.gridBank?.instance?.updateDimensions();
    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  };

  onInserted = (e) => {
    this.dataSource.reload().then(() => {
      if (this.singleRecordMode === 'add') {
        const index = this.gridBank.instance.getRowIndexByKey(e.OfferId);
        this.focusedRowIndex = index || 0;
      }

      this.gridBank.instance.focus();
    });
  };

  setArticleLock = (SocketCode: string = null) => {
    this.setSingleRecordVisible('edit');
    this.cd.detectChanges();
    if (SocketCode) {
      this.findSocketAndRun(SocketCode);
    }
  };

  menuName: string = null;
  onFocusedRowChanged = (e) => {
    try {
      this.articleEdit = [];
      this.isDetailOnFocus = false;
      this.articleEdit.push(e.row.data.OfferDocumentId);

      this.articlesListPom = [];
      this.articlesListPom.push(e.row.data);
      this.details = new DocumentOfferDetails();
      this.menuName = e.row.data?.MenuName || null;

      clearTimeout(this.myTimer);

      this.myTimer = setTimeout(() => {
        this.articleIdFocused = e.row.data.OfferDocumentId;
        this.tableService.addTableContext({
          id: this.articleIdFocused,
          idParentObject: e.row.data,
          tableName: 'Oferta',
        });
        this.getDatailsData(this.articleIdFocused);
      }, this.timer);
    } catch {}
  };

  onSelectionTabChanged = () => {
    this.getDatailsData(this.articleIdFocused);
    try {
      this.localStorageData.selectionTab = this.selectedtabIndex;
      localStorage.setItem(
        'leftOfferPanel',
        JSON.stringify(this.localStorageData)
      );
    } catch {}
  };

  getDatailsData(id) {
    if(this.bottomPanelTabs[this.selectedtabIndex] === this.translate.instant('menu.eDocuments')){
      this.eDocumentss.getDocuments();
      this.cd.detectChanges();
    }
    if (!this.showBottomPanel || !id || this.selectedtabIndex > 2) {
      return;
    }
  
    if (this.selectedtabIndex == 0) {
      this.getOfferPositions(id);
    } else if (this.selectedtabIndex == 1) {
      this.getOfferRealisations(id);
    } else if (this.selectedtabIndex == 2) {
      this.getOfferOrders(id);
    }
  }

  @ViewChild('eDocumentss') eDocumentss;
  getOfferRealisations(id) {
    if (this.details.realisations.length) {
      return;
    }

   
    this.appService.getAuth(`offers/documents/${id}/realizations`).subscribe(
      (res: IDocumentOfferRealisation[]) => {
        this.details.realisations = res;
        this.cd.detectChanges();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  }

  getOfferOrders(id) {
    if (this.details.orders.length) {
      return;
    }

    this.appService.getAuth(`offers/documents/${id}/orders`).subscribe(
      (res: IDocumentOfferOrder[]) => {
        this.details.orders = res;
        this.cd.detectChanges();
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
      }
    );
  }

  getOfferPositions(id) {
    if (this.details.positions.length) {
      return;
    }

    this.appService
      .getAuth(`offers/documents/${id}/positions?parentObjectId=${id}`)
      .subscribe(
        (res: IDocumentOfferPositionsResponse) => {
          this.details.positions = res.data;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  onValueChanged = (e) => {
    if (e.value == null) {
      this.selectedRows = [];
      this.onChoosed.emit(null);
    }
  };

  onRowDblClick = () => {
    if (!this.readOnly && !this.dropDownBoxMode && !this.isPopupMode) {
      if (this.doubleClickResult === 'Edycja') {
        this.showEditArticle();
      } else {
        this.setSingleRecordVisible('show');
      }
    } else {
      this.onChoosingArticles();
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    this.articleName = [];
    if (changes.selectedId?.currentValue) {
      this.getData();
    }

    // pobranie gniazd jak nie ma i jest popup/dropdown
    if (
      changes.isPopupMode?.currentValue ||
      changes.dropDownBoxMode?.currentValue
    ) {
      if (!this.componentNests || !this.componentNests.Id) {
        // jak nie ma
        this.componentNests = this.nestService.getNestBySocketCode('XGO');
      }
    }
  }

  onChoosingArticles = () => {
    if (!this.readOnly) {
      this.articlesList = this.articlesListPom;
      this.articleName = this.selectedRows[0];
      this.onChoosed.emit(this.articlesList[0]);
      this.onClosing.emit(true);
      this.isGridBoxOpened = false;
    }
  };

  openedChange = (e) => {
    if (e) {
      try {
        setTimeout(() => {
          this.gridBank.instance.focus();
        }, 500);
      } catch {}
    }
  };

  onItemClick(e) {
    this.valueCriteria = e.itemData.value;
    this.displayValue = e.itemData.label;
  }

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onGridContentReady(e) {
    this.onContentReady(e);
    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  getStatuses = (forceReload: boolean = false) => {
    const localStatusTree: string = localStorage.getItem('offersStatusTree');
    if (localStatusTree && !forceReload) {
      this.offerStatuses = this.event.decryptString(localStatusTree);
      setTimeout(() => {
        this.statusesTree?.instance?.expandAll();
      }, 200);
      return;
    }
    this.appService.getAuth(`offers/statusTree`).subscribe(
      (statuses: IDocumentOfferStatus) => {
        this.offerStatuses = [statuses];
        setTimeout(() => {
          this.statusesTree?.instance.expandAll();
        }, 200);
        localStorage.setItem(
          'offersStatusTree',
          this.event.encryptString(this.offerStatuses)
        );
      },
      (error) => {
        this.event.showNotification('error', JSON.parse(error).detail);
        localStorage.removeItem('offersStatusTree');
      }
    );
  };

  getSortIcon() {
    return this.order === 'ASC'
      ? 'icon absui-icon--sort-ascending'
      : 'icon absui-icon--sort-descending';
  }

  visibleChange(e) {
    if (!e) {
      this.onClosing.emit(true);
      this.componentVisible = false;
    }
  }

  focusedRowIndex;
  timerSel: any;
  holdDelayInMs: number = 200;
  isHold: boolean = false;
  isQuickPress: boolean = true;

  toDeselect = [];
  toSelect = [];
  keyDownTimeout = null;

  handleSearchFromGrid(key: string) {
    const orderByItem = this.filterCriteria.find(
      (el) => el.value === this.filterValue
    );

    this.filterValue = this.event.setFilterValueBySearchKey(
      this.filterValue,
      key,
      orderByItem.type === 'data'
    );

    if (this.keyDownTimeout) {
      clearTimeout(this.keyDownTimeout);
    }
    this.keyDownTimeout = setTimeout(() => {
      if (orderByItem.type === 'data') {
        if (
          this.event.setCorrectDate(this.filterValue) !==
          this.paramsObj[this.filterValue]
        ) {
          this.getData();
        }
        return;
      }
      this.getData();
    }, 300);
  }

  onKeyDown(e) {
    if (e.event.key === 'F9' && !e.event.ctrlKey && !e.event.shiftKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.dateRange.openDateRange();
    } else if (e.event.key === 'O' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.additionalOperation.openList();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.key === 'E' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      if (this.perAbdExtended.eDocuments) {
        this.edocs.getToEDocuments(
          'Offers',
          this.articlesListPom[0]?.OfferDocumentId,
          this.articlesListPom[0]?.OfferDocumentNumber
        );
      }
    } else if (e.event.key === 'F7') {
      e.event.preventDefault();
      if (e.event.shiftKey) {
        this.setCustomerFromFocusedRow();
      } else {
        this.isCustomerVisible = true;
      }
      this.cd.detectChanges();
    } else if (e.event.key === 'F6' && e.event.shiftKey) {
      e.event.stopPropagation();
      e.event.preventDefault();
      if (this.onlySelected) {
        this.revertSelectOnly();
      } else {
        this.onOnlySelected();
      }
    }
    if (e.event.key === 'F12' && e.event.ctrlKey && e.event.shiftKey) {
      e.event.preventDefault();
      this.isExpansionListVisible = true;
      this.cd.detectChanges();
    } else if (e.event.keyCode === 107) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.gridBank.instance.selectAll();
      this.selectAll();
    } else if (e.event.keyCode === 109) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.skipSendSelectionEvent = true;
      this.selectedRows = [];
      this.deselectAllRecords();
    } else if (
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    } else if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }
  }

  onOnlySelected() {
    this.clearValues(true);
  }

  revertSelectOnly() {
    this.onlySelected = false;
    this.getData();
  }

  onDeselectAll() {
    this.onlySelected = false;
    this.skipSendSelectionEvent = true;
    this.deselectAllRecords();
    this.getData();
  }

  selectAll = () => {
    this.isAllSelected = true;
    this.appService
      .postAuth(`offers/selection/documents`, this.paramsObj)
      .subscribe(({ RowNumber }) => {
        this.selectedAmount.set(RowNumber);
      });
    this.modeSelectionMobile = 'multiple';
  };

  deselectAllRecords() {
    this.isAllSelected = false;
    this.selectedAmount.set(0);
    return this.event.deselectAllRecords('offers/selection/documents');
  }

  closeConfirm() {
    this.isDelete = false;
    setTimeout(() => {
      if (this.gridBank) {
        this.gridBank.instance.focus();
      }
    }, 300);
  }

  delete = (SocketCode: string = null) => {
    this.isDelete = false;
    if (this.selectedRows.length > 1) {
      let array: Observable<any>[] = [];

      this.selectedRows.forEach((field) => {
        array.push(this.appService.deleteAuth(`offers/documents/${field}`));
      });

      forkJoin(array).subscribe(
        () => {
          this.selectedRows = [];
          this.getData();
          this.cd.detectChanges();

          if (SocketCode) {
            this.findSocketAndRun(SocketCode);
          }
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
    } else {
      this.appService
        .deleteAuth(`offers/documents/${this.articleEdit[0]}`)
        .subscribe(
          () => {
            this.getData();
            if (SocketCode) {
              this.findSocketAndRun(SocketCode);
            }
          },
          (error) => {
            this.event.showNotification('error', JSON.parse(error).detail);
          }
        );
    }
  };

  onInitialized(e) {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
  }

  showNewRow() {
    const didntFound = this.findSocketAndRun('XGOLYD1');
    if (didntFound) {
      this.findSocketAndRun('XGOLYA1', 'add');
    }
  }

  showEditArticle() {
    const didntFound = this.findSocketAndRun('XGOLYP1');
    if (didntFound) {
      this.findSocketAndRun('XGOLYA1', 'edit');
    }
  }

  showDeleteArticle() {
    this.findSocketAndRun('XGOLYU1');
  }

  // NEST
  onInitErr: boolean = false;
  activatedRouteSub: Subscription;
  componentNests: Socket;
  nest: Socket;
  nestsSub: Subscription;
  isNestRunning = false;
  nestOperation: NestOperation;
  closeNest: Socket;
  globalNestSub: Subscription;

  findSocketAndRun(SocketCode: string, operation: NestOperation = null) {
    const newNest = this.nestService.findNest(
      this.componentNests?.Sockets,
      SocketCode
    );
    this.nestOperation = operation;
    if (newNest?.Operations?.length && !newNest.IsInactive) {
      setTimeout(() => {
        this.nest = newNest;
        this.isNestRunning = true;
        this.cd.detectChanges();
      });
      return true;
    } else {
      this.onNestFinished({ SocketCode });
      return true;
    }
  }

  onNestFinished(e: NestFinishedEvent) {
    this.isNestRunning = false;
    this.nest = null;
    if (!e) {
      return;
    }
    if (e.updatedParameters?.Operation?.BreakNest) {
      return;
    }
    
    switch (e.SocketCode) {
      //XGOLY
      case 'XGOLYD1':
        this.setSingleRecordVisible('add');
        this.findSocketAndRun('XGOLYD2');
        break;
      case 'XGOLYP1':
        this.setArticleLock('XGOLYP2');
        break;
      case 'XGOLYU1':
        this.findSocketAndRun('XGOLYA1', 'delete');
        break;
      case 'XGOLYU2':
        this.findSocketAndRun('XGOLYA2', 'delete');
        break;
      case 'XGOLYA1':
        if (this.nestOperation === 'add') {
          this.setSingleRecordVisible('add');
          this.findSocketAndRun('XGOLYA2');
        } else if (this.nestOperation === 'edit') {
          this.setArticleLock('XGOLYA2');
        } else if (this.nestOperation === 'delete') {
          this.delete('XGOLYU2');
        }
        break;
      case 'XGOLO1':
        this.componentVisible = true;
        if (this.onInitErr) {
          setTimeout(() => {
            this.getViewConfigurations();
          }, 300);
        }
        this.findSocketAndRun('XGOLO2');
        break;
      case 'XGOLO2':
        this.searchControl?.focusInput();
        break;
      //operacje dodatkowe
      case 'XGOLEXOFERTA_BRWPOLADOD_E1':
        this.visibleUserExtensions = true;
        break;
      case 'XGOLEXOFERTA_BRWPOLADODGRUP_E1':
        this.visibleUserExtensions = true;
        break;
      case 'XGOLEXOFERTA_BRWSCHOWCOFE_E1': // kopiuj do schowka
        this.copySelectedToClipboard(true);
        break;
      case 'XGOLEXOFERTA_BRWSCHOWVOFE_E1': //schowek pozycji
        this.isClipboardVisible = true;
        this.findSocketAndRun('XGOLEXOFERTA_BRWSCHOWVOFE_E2');
        break;
      case 'XGOLEXOFERTA_BRWUTWZOFE11_E1':
        this.moveToOrderDocumentOne();
        break;
      case 'XGOLEXOFERTA_BRWUTWZAMOFE_E1':
        this.moveToOrderDocument();
        break;
      case 'XGOLEXOFERTA_BRWZMSTATOFE_E1':
        this.isChangesStatus = true;
        break;
      case 'XGOLEXOFERTA_BRWSMSOFE_E1':
        this.isSendSms = true;
        break;
      case 'XGOLEXOFERTA_BRWUTWZAMOFE_E2':
        this.isOrderCreated = true;
        break;
      case 'XGOLEXOFERTA_BRWBLOKDOK_E1':
        this.isBlockVisible = true;
        break;
      case 'XGOLEXOFERTA_BRWODBLOKDOK_E1':
        this.isUnblockVisible = true;
        break;
      case `XGOLEX${this.selectedOwnOperation?.GroupCode}_${this.selectedOwnOperation?.Name?.toUpperCase()}_E1`:
        this.runInnerOwnOperation();
        break;
      default:
        break;
    }
  }

  onConfirmSingle(socketCode) {
    this.findSocketAndRun(socketCode);
  }

  blockOffer() {
    this.appService
      .putAuth(`offers/additionalOperations/${this.articleEdit[0]}/block`, null)
      .subscribe({
        next: () => {},
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
        complete: () => {
          this.getData();
          this.closeBlockOffer();
        },
      });
  }

  unblockOffer() {
    this.appService
      .putAuth(
        `offers/additionalOperations/${this.articleEdit[0]}/unblock`,
        null
      )
      .subscribe({
        next: () => {},
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
        complete: () => {
          this.getData();
          this.closeUnblockOffer();
        },
      });
  }

  closeBlockOffer() {
    this.isBlockVisible = false;
    this.findSocketAndRun('XGOLEXOFERTA_BRWBLOKDOK_E2');
  }

  closeUnblockOffer() {
    this.isUnblockVisible = false;
    this.findSocketAndRun('XGOLEXOFERTA_BRWODBLOKDOK_E2');
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
    }

    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  openColumnsChooser() {
    if (this.perABDKonf) {
      this.columnsChooserVisible = true;
    }
  }

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  //

  onOptionChanged(_e) {
    this.event.onOptionChanged(_e);
    if (_e.name === 'columns' && _e.fullName.indexOf('width') > -1) {
      const index1 = _e.fullName.indexOf('[');
      const index2 = _e.fullName.indexOf(']');
      const indexStr = _e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = _e.value;
      } catch (e) {}
    }
  }

  isLabel: boolean = false;
  onChoosedLabel = () => {
    this.selectedRows = [];
    this.isLabel = false;
    this.dataSource.reload();
  };

  readonly EXTERNAL_FIELDS_KEY = `userExtensionsOferta`;

  getExternalFieldsDefinition() {
    let localData: any = localStorage.getItem(this.EXTERNAL_FIELDS_KEY);
    if (localData) {
      localData = this.event.decryptString(localData);
      localData.forEach((col) => {
        col.FieldValue = 'Field' + col.NameDefault.replace('pole', '');
      });
      this.externalFieldColumns = localData;
      return;
    }

    this.appService
      .getAuth('userExtensions/additionalFields/groups/Oferta/definitions')
      .subscribe(
        (res) => {
          localStorage.setItem(
            this.EXTERNAL_FIELDS_KEY,
            this.event.encryptString(res.data)
          );
          res.data.forEach((col) => {
            col.FieldValue = 'Field' + col.NameDefault.replace('pole', '');
          });
          this.externalFieldColumns = res.data;
        },
        (error) => {
          this.event.showNotification('error', JSON.parse(error).detail);
        }
      );
  }

  updateColumns() {
    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.gridBank?.instance;

        if (!instance) {
          return;
        }

        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          instance.columnOption(col.caption, 'width', col.width);
          instance.columnOption(col.caption, 'fixed', col.fixed);
        });
        this.cd.detectChanges();
      }, 250);
    }
  }

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
      this.displayValue = e.selectedItem.label;
      this.orderby = e.selectedItem.value;
      this.order = 'ASC';
    }
    this.filterValue = e.filterValue;
    this.getData(true);
  }

  onlySelected = false;

  resetFiltr(filterType: string) {
    if (filterType === 'statusName') {
      this.localStorageData.statusName = null;
      this.localStorageData.statusId = null;
    } else if (filterType === 'advancedFilter') {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else if (filterType === 'onlySelected') {
      this.onlySelected = false;
    }
    this.restoreTreeConfig();
    this.getData();
  }

  editRecord(e) {
    e.stopPropagation();
    if (this.isPopupMode) {
      return;
    }
    const found = this.getFocusedElement();
    if (found) {
      this.articleEdit = [found?.OfferDocumentId];
    }
    if (
      !this.readOnly &&
      !this.isSingleRecordVisible &&
      this.articleEdit.length > 0 &&
      this.perABD.editBtn &&
      (this.isGridBoxOpened || !this.dropDownBoxMode)
    ) {
      this.showEditArticle();
    }
  }

  onItemClickTab = () => {
    this.showBottomPanel = true;
    this.localStorageData.bottomPanelVisible = true;
    if (this.localStorageData.bottomPanelHeight == null) {
      this.bottomSidePanel.nativeElement.style.minHeight = `235px`;
      this.heightGrid = window.innerHeight - 435;
      this.tabPanel.height = '100%';
    } else if (this.localStorageData.bottomPanelHeight != null) {
      this.bottomSidePanel.nativeElement.style.minHeight =
        this.localStorageData.bottomPanelHeight;
      this.heightGrid =
        window.innerHeight -
        Number(this.localStorageData.bottomPanelHeight.replace('px', '')) -
        200;
      this.tabPanel.height = '100%';
    }
    this.gridBank?.instance?.updateDimensions();
    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  };

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  mouseEnterDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle(true);;
  };

  mouseLeaveDown = () => {
    this.bottomSidePanel.nativeElement.style.borderTop = this.event.getPanelBorderStyle();
  };

  isExternalTableVisible: boolean = false;

  onAdditionalTableChanged(e) {
    this.localStorageData.selectedAdditionalTable = Object.assign({}, e);
    this.bottomPanelTabs[6] = e.text;

    localStorage.setItem(
      'leftOfferPanel',
      JSON.stringify(this.localStorageData)
    );
  }

  onRowClick = (e) => {
    try {
      this.articleEdit = [e.data.OfferDocumentId];
    } catch {}
  };

  shortcutTable;
  isShortcutTableOpened: boolean = false;
  setFocusOnLoad = false;
  firstLoad = true;
  indexToFocus: number;
  paramsObj = {};

  getData(fromSearch: boolean = false) {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'OfferDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}offers/documents`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          if (!data.length) {
            this.articleEdit = [];
            this.cd.detectChanges();
            return;
          }
          const toAdd = data.filter(
            (el) =>
              el.IsSelected &&
              this.selectedRows.indexOf(el.OfferDocumentId) === -1
          );
          for (let item of toAdd) {
            this.selectedRows.push(item.OfferDocumentId);
            this.skipSendSelectionEvent = true;
          }
          const toDelete = [];
          for (let id of this.selectedRows) {
            const item = data.find((el) => el.OfferDocumentId === id);
            if (item && !item.IsSelected) {
              toDelete.push(id);
            }
          }
          for (let id of toDelete) {
            this.selectedRows.splice(
              this.selectedRows.findIndex((el) => el === id),
              1
            );
            this.skipSendSelectionEvent = true;
          }

          if (this.firstLoad) {
            this.firstLoad = false;
            setTimeout(() => {
              const selected: any[] = data
                .filter((el) => el.IsSelected)
                .map((el) => el.OfferDocumentId);
              if (selected.length > 0) {
                this.event.deselectAllRecords(`offers/selection/documents`);
              }

              const filters: any = this.getLoadParams();
              const totalCount: number = this.dataSource.totalCount();
              if (!totalCount) {
                this.cd.detectChanges();
                return;
              }

              if (
                (filters?.order === 'ASC' && this.focusOnLastRow === 'Tak') ||
                (filters?.order === 'DESC' && this.focusOnLastRow === 'Nie')
              ) {
                this.indexToFocus = totalCount - 1;
                if (this.indexToFocus < 0) {
                  this.indexToFocus = data.length - 1;
                }
                const page = Math.floor(totalCount / this.pageSize);
                if (page > 0) {
                  this.setFocusOnLoad = true;
                  this.dataSource.pageIndex(page);
                  this.dataSource.load();
                } else {
                  this.focusedRowIndex = this.indexToFocus;
                  this.onFocusedRowChanged({
                    row: { data: data[this.focusedRowIndex] },
                  });
                  this.cd.detectChanges();
                }
              } else {
                this.focusedRowIndex = 0;
                this.onFocusedRowChanged({
                  row: { data: data[this.focusedRowIndex] },
                });
                this.cd.detectChanges();
              }
              this.searchControl?.focusInput();
            }, 200);
          }

          if (this.setFocusOnLoad) {
            this.setFocusAfterLoad(data);
          }
          if (fromSearch) {
            this.searchControl?.focusInput();
          }

          this.cd.detectChanges();
        },
        deleteUrl: `${environment.domain}offers/documents`,
      }),
    });
  }

  setFocusAfterLoad(data) {
    this.focusedRowIndex = this.indexToFocus;
    this.setFocusOnLoad = false;
    this.onFocusedRowChanged({ row: { data: data[this.focusedRowIndex] } });
  }

  getFocusedElement() {
    return this.gridBank.instance
      .getDataSource()
      .items()
      .find((_el, i) => this.focusedRowIndex === i);
  }

  getLoadParams() {
    if (this.onlySelected) {
      this.paramsObj = {
        onlySelected: true,
      };
      return this.paramsObj;
    }

    let obj: any = {
      OfferDocumentDateFrom:
        this.valueCriteria === 'OfferDocumentDate'
          ? this.event.setCorrectDate(this.filterValue)
          : this.event.dataFormatted({ value: this.currentDate.dateFrom }) ||
            '',
      OfferDocumentDateTo:
        this.valueCriteria === 'OfferDocumentDate'
          ? this.event.setCorrectDate(this.filterValue)
          : this.event.dataFormatted({ value: this.currentDate.dateTo }) || '',
      orderBy: this.getOrderBy(),
      order: this.order,
    };

    if (this.OfferDocumentId) {
      obj.OfferDocumentNumber = this.OfferDocumentId;
      obj.OfferDocumentDateFrom = '';
      obj.OfferDocumentDateTo = '';
    }

    if (this.localStorageData.statusId) {
      obj.OfferDocumentStatusId = this.localStorageData.statusId;
    }

    if (this.filterValue) {
      if (this.valueCriteria === 'RealizationPlanDate') {
        obj.RealizationPlanDateFrom = this.event.setCorrectDate(
          this.filterValue
        );
        obj.RealizationPlanDateTo = this.event.setCorrectDate(this.filterValue);
      } else {
        obj[this.valueCriteria] = this.filterValue;
      }
    }

    if (this.localStorageData.advancedFilter?.FilterUsersDefinitionId) {
      this.event.addAdvancedFiltersToLoadParams(
        obj,
        this.localStorageData.advancedFilter.UserFilterDefinitionValues
      );
    }
    if (this.customerFilter.CustomerId) {
      obj.CustomerId = this.customerFilter.CustomerId;
    }
    if (this.isAllVersions && this.offerDocumentIdAllVersions) {
      obj.OfferDocumentId = this.offerDocumentIdAllVersions;
      obj.IsShowAllVersions = true;
    }
    this.paramsObj = obj;
    return obj;
  }

  newVersionPopupVisible: boolean = false;
  copyPopupVisible: boolean = false;

  closeConfirmNewVersion() {
    this.newVersionPopupVisible = false;
  }

  closeCopyOffer() {
    this.copyPopupVisible = false;
  }

  copyOffer() {
    const el = this.getFocusedElement();
    this.appService
      .postAuth(`Offers/documents/${el.OfferDocumentId}/copy`, null)
      .subscribe(
        (res) => {
          this.copyPopupVisible = false;
          this.dataSource.reload().then(() => {
            this.articleEdit = [res.Id];
            this.setArticleLock();
          });
        },
        (error) => {
          this.copyPopupVisible = false;
          this.event.httpErrorNotification(error);
        }
      );
  }

  createNewVersion() {
    const el = this.getFocusedElement();
    this.appService
      .postAuth(`Offers/documents/${el.OfferDocumentId}/AddVersion`, null)
      .subscribe(
        (res) => {
          this.newVersionPopupVisible = false;
          this.dataSource.reload().then(() => {
            this.articleEdit = [res.Id];
            this.setArticleLock();
          });
        },
        (error) => {
          this.newVersionPopupVisible = false;
          this.event.httpErrorNotification(error);
        }
      );
  }

  sendRequestTimer;
  isClipboardVisible: boolean = false;

  copyToClipboard(id: number = null) {
    return new Promise(async (resolve) => {
      if (!id) {
        await this.event.saveToClipboard('DOCUMENT_OFFER', 0, 'copyAll');
      } else {
        await this.event.saveToClipboard('DOCUMENT_OFFER', id, 'copyAll');
      }
      resolve(true);
    });
  }

  onDateRangeChoosed() {
    if (this.refreshCardResult === 'Tak') {
      this.onlySelected = false;
      this.getData();
    }
  }

  refreshView() {
    this.getStatuses(true);
    this.getData();
  }

  isSingleRecordVisible: boolean = false;
  singleRecordMode: SingleRecordMode = null;

  onSingleRecordClosing() {
    this.isSingleRecordVisible = false;
    this.singleRecordMode = null;
    this.gridBank.instance.focus();
    this.cd.detectChanges();
  }

  onSingleRecordInserted(e) {
    this.onInserted(e);
    if (this.singleRecordMode === 'add') {
      this.findSocketAndRun('XGOLYD2', 'add');
    } else if (this.singleRecordMode === 'edit') {
      this.findSocketAndRun('XGOLYP2', 'add');
    }
    this.singleRecordMode = null;
  }

  setSingleRecordVisible(mode: SingleRecordMode) {
    this.singleRecordMode = mode;
    this.isSingleRecordVisible = true;
  }

  offersSumOpen() {
    this.sumOffersFilters = this.getLoadParams();

    this.isSumOffersVisible = true;
  }

  getUserParameters() {
    const doubleClick = this.event.getConfigurationParameter('DwuklikMyszy');
    if (doubleClick) {
      this.doubleClickResult = doubleClick.Value as DoubleClickResult;
    }

    const refreshOnDateChange =
      this.event.getConfigurationParameter('OdswiezKartot');
    if (refreshOnDateChange) {
      this.refreshCardResult = refreshOnDateChange.Value as RefreshCardResult;
    }

    const focusOnLastRow = this.event.getConfigurationParameter('NaOstatZapis');
    if (focusOnLastRow) {
      this.focusOnLastRow = focusOnLastRow.Value as RefreshCardResult;
    }
  }

  isDetailOnFocus = false;

  onFocusElementChanged(e: boolean) {
    this.isDetailOnFocus = e;
  }

  onCellClick(e) {
    if (this.event.deviceType != 'mobile') {
      if (!e?.row?.cells) {
        return;
      }

      for (let cell of e?.row?.cells) {
        this.event.removeFocusClassFromCell(cell.cellElement);
      }
      e.cellElement.className += ' dx-focused';
    }
  }

  currentFocusedCell = null;

  onFocusedCellChanged(e) {
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedCell = e.cellElement[0];
  }

  currentFocusedDetailCell = null;

  onFocusedCellDetailChanged(e) {
    if (this.currentFocusedDetailCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedDetailCell);
    }
    if (this.currentFocusedCell) {
      this.event.removeFocusClassFromCell(this.currentFocusedCell);
    }
    this.currentFocusedDetailCell = e.cellElement[0];
  }

  onFocusedRowChangedDetail(e) {
    this.onFocusElementChanged(e.rowIndex > -1);
    if (e?.row?.data) {
      this.focusedPositionData = e.row.data;
    }
  }

  visibleAdvanceFilters = false;

  openAdvanceFilters() {
    this.visibleAdvanceFilters = !this.visibleAdvanceFilters;
  }

  openAdvanceFiltersWithoutPanel() {
    this.visibleAdvanceFiltersWithoutPanel =
      !this.visibleAdvanceFiltersWithoutPanel;
  }

  onFilterSaved(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
    } else {
      this.localStorageData.advancedFilter = {
        ...e,
        PinnedFilters:
          this.localStorageData?.advancedFilter?.PinnedFilters || [],
      };
    }
    localStorage.setItem(
      'leftOfferPanel',
      this.event.encryptString(this.localStorageData)
    );

    this.getData();
  }

  onClosingStatus() {
    this.isChangesStatus = false;
    this.findSocketAndRun('XGOLEXOFERTA_BRWZMSTATOFE_E2');
  }

  onSmsClosing() {
    this.isSendSms = false;
    this.findSocketAndRun('XGOLEXOFERTA_BRWSMSOFE_E2');
  }

  restoreTreeConfig = () => {
    setTimeout(() => {
      const value =
        this.localStorageData.statusId !== null &&
        this.localStorageData.statusId !== undefined
          ? this.localStorageData.statusId
          : 1;
      this.statusesTree?.instance.selectItem(value);
    }, 0);
  };

  lastTreeSelection;

  onTreeSelectionChanged = (e) => {
    const selectedNodes = e.component
      .getSelectedNodes()
      .map((node) => node.itemData);
    if (selectedNodes.length > 0) {
      this.lastTreeSelection = selectedNodes;
    }
    e.component.selectItem(this.lastTreeSelection);
  };

  contextMenuPreparing(e: any): void {
    if (e.target == 'header') e.items = [];
  }

  onAllVersionsChanged = (e) => {
    if (e) {
      this.isAllVersions = true;
      this.offerDocumentIdAllVersions = this.articlesListPom[0].OfferDocumentId;
    } else {
      this.isAllVersions = false;
      this.offerDocumentIdAllVersions = null;
    }

    this.getData();
  };

  offerOrdersContextMenu = [{ text: 'Pokaż zamówienie', itemIndex: 0 }];

  offerRealisationsContextMenu = [
    { text: 'Pokaż dok. magazynowy', itemIndex: 0 },
    { text: 'Pokaż dok. handlowy', itemIndex: 1 },
  ];

  offerPositionsContextMenu = [
    { text: 'Planowane odbiory', itemIndex: 0 },
    { text: 'Planowane dostawy', itemIndex: 1 },
    { text: 'Kartoteka asortymentowa', itemIndex: 2 },
    {
      text: 'Karta artykułu',
      disabled: true,
      itemIndex: '1111_000_KARTA_ART',
    },
  ];

  isPlannedOrdersVisible = false;
  plannedOrdersType: number = 1;
  offerRealisationsFocusedRowKey: any = null;
  offerOrdersFocusedRowKey: any = null;
  offerPositionsFocusedRowKey: any = null;

  offerOrdersContextMenuClick(e) {
    if (!this.offerOrdersFocusedRowKey) {
      return;
    }

    switch (e.itemData.itemIndex) {
      case 0:
        this.router.navigate(['/warehouse/orders'], {
          queryParams: { id: this.offerOrdersFocusedRowKey.OrderId },
        });
        break;
      default:
        break;
    }
  }

  offerPositionsContextMenuClick(e) {
    if (!this.offerPositionsFocusedRowKey) {
      return;
    }
    switch (e.itemData.itemIndex) {
      case 0:
        this.plannedOrdersType = 2;
        this.isPlannedOrdersVisible = true;
        break;
      case 1:
        this.plannedOrdersType = 1;
        this.isPlannedOrdersVisible = true;
        break;
      case 2:
        this.router.navigate(['/library/articles'], {
          queryParams: { id: this.offerPositionsFocusedRowKey.ProductId },
        });
        break;
      case '1111_000_KARTA_ART':
        this.showRecordCard('article');
        break;
      default:
        break;
    }
  }

  onClosedPlannedOrders() {
    this.isPlannedOrdersVisible = false;
  }

  offerRealisationsContextMenuClick(e) {
    if (!this.offerRealisationsFocusedRowKey) {
      return;
    }

    switch (e.itemData.itemIndex) {
      case 0:
        if (!this.offerRealisationsFocusedRowKey.WarehouseDocumentId) {
          return;
        }
        this.router.navigate(['/warehouse/warehouse-documents'], {
          queryParams: {
            id: this.offerRealisationsFocusedRowKey.WarehouseDocumentId,
          },
        });
        break;
      case 1:
        if (!this.offerRealisationsFocusedRowKey.InvoiceDocumentId) {
          return;
        }
        this.router.navigate(['/trade-operations/trader-documents'], {
          queryParams: {
            id: this.offerRealisationsFocusedRowKey.InvoiceDocumentId,
          },
        });
        break;
      default:
        break;
    }
  }

  openRightPanel = () => {
    if (this.width <= 21) {
      this.resizeWindowBtn();
    }
  };

  onTemplatePinChanged(e) {
    let advancedFilter = this.localStorageData.advancedFilter;
    if (!advancedFilter) {
      advancedFilter = this.dynamicFilterService.createDefaultAdvancedFilter();
    }
    advancedFilter.IsPinned = e;
    this.localStorageData.advancedFilter = advancedFilter;
    this.cd.detectChanges();
  }

  onAdvancedFilterTemplateChanged(e) {
    if (!e) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.getData();
      this.cd.detectChanges();
      return;
    }
    const item = this.dynamicFilterUserDefinitions.find(
      (el) => el.FilterUsersDefinitionId === e
    );
    if (!item) {
      return;
    }
    const obj = {
      FilterUsersDefinitionId: item.FilterUsersDefinitionId,
      Name: item.Name,
      UserFilterDefinitionValues: item.UserFilterDefinitionValues,
      IsPinned: this.localStorageData.advancedFilter.IsPinned,
      PinnedFilters: this.localStorageData.advancedFilter.PinnedFilters,
    };
    this.localStorageData.advancedFilter = obj;
    this.cd.detectChanges();
  }

  advancedFilterTemplateData = null;
  deleteFilter = false;

  clearValues(onlySelected: boolean = false) {
    this.onlySelected = onlySelected;
    if (onlySelected) {
      this.event.setCurrentDateForOnlySelected();
    } else {
      this.event.setCurrentDate(new GlobalDate(), false, true);
    }
    this.deleteFilter = true;
    this.cd.detectChanges();
    if (
      !this.advancedFilterTemplateData &&
      this.localStorageData.advancedFilter
    ) {
      this.localStorageData.advancedFilter =
        this.dynamicFilterService.createDefaultAdvancedFilter(
          this.localStorageData.advancedFilter?.IsPinned,
          this.localStorageData.advancedFilter.PinnedFilters
        );
      this.cd.detectChanges();
    }
    this.resetFiltr('statusName');
    this.resetContrahentFilterNoRefresh();
    this.deleteFilter = false;
  }

  onPinnedValueChanged(e) {
    const modifiedIndex =
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.findIndex(
        (el) => el.FilterDefinitionId === e.FilterDefinitionId
      );
    if (modifiedIndex > -1) {
      this.localStorageData.advancedFilter.UserFilterDefinitionValues.splice(
        modifiedIndex,
        1
      );
    }
    this.localStorageData.advancedFilter.UserFilterDefinitionValues = [
      ...this.localStorageData.advancedFilter.UserFilterDefinitionValues,
      e,
    ];
    this.cd.detectChanges();
  }

  getSavedFilterDefinitions() {
    const windowId = this.event.getFilterWindowIdFromDictionaryCode(
      this.DynamicFilterDictionaryCode
    );
    this.dynamicFilterService.getViewUserDefinition(this.DynamicFilterDictionaryCode).pipe(
      take(1),
      switchMap((data: ViewUserDefinition) => {
        if(data){
          return of(null)
        }
        else{
          return this.dynamicFilterService.getUsersDefinitionsForWindowId(windowId)
        }
      })
    ).subscribe({
        next: (data: UserDefinition[]) => {
          if(!data) return;

          this.dynamicFilterService.addViewUserDefinitions(
            this.DynamicFilterDictionaryCode,
            data || []
          );
        },
        error: () => {},
      });
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.valueCriteria) {
      this.valueCriteria = orderBy;
    } else {
      this.switchOrder();
    }
    this.getData();
    this.cd.detectChanges();
  };

  toggleDynamicFilters = () => {
    const config = {
      dynamicFilterTabIndex: 1,
      isPanelVisible: this.localStorageData?.sidePanel?.isVisible,
    };

    if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex !=
        config.dynamicFilterTabIndex
    ) {
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    } else if (
      config.isPanelVisible &&
      this.localStorageData.sidePanel.selectedIndex ==
        config.dynamicFilterTabIndex
    ) {
      this.hideRightPanel();
    } else {
      this.showRightPanel();
      this.localStorageData.sidePanel.selectedIndex =
        config.dynamicFilterTabIndex;
    }
  };

  hideRightPanel() {
    this.localStorageData.sidePanel.isVisible = false;
    this.leftPanel.nativeElement.style.width = '10px';
    this.width = 21;
    this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    this.localStorageData.leftPanelWidth = `21px`;
    this.localStorageData.rightPanelWidth = `calc(100% - 26px)`;
  }

  showRightPanel() {
    this.localStorageData.sidePanel.isVisible = true;
    this.leftPanel.nativeElement.style.width = '280px';
    this.width = 280;
    this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
    this.localStorageData.leftPanelWidth = `280px`;
    this.localStorageData.rightPanelWidth = `calc(100% - 280px)`;
  }

  isExpansionListVisible = false;

  onExpansionListClosing(e: boolean) {
    this.isExpansionListVisible = false;
    if (e) {
      this.componentNests = this.nestService.getNestBySocketCode('XGO');
    }
    this.gridBank.instance.focus();
  }

  customerFilter = {
    CustomerId: null,
    CustomerName: '',
  };
  isCustomerVisible = false;

  onChoosedCustomer = (e) => {
    if (e) {
      this.customerFilter.CustomerId = e.CustomerId;
      this.customerFilter.CustomerName = e.Name;
      this.isCustomerVisible = false;
      this.getData();
    }
  };

  resetContrahentFilter = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
    this.getData();
  };

  resetContrahentFilterNoRefresh = () => {
    this.customerFilter.CustomerId = null;
    this.customerFilter.CustomerName = '';
  };

  setCustomerFromFocusedRow() {
    if (
      this.articlesListPom[0] &&
      this.customerFilter.CustomerId !== this.articlesListPom[0].CustomerId
    ) {
      this.customerFilter.CustomerId = this.articlesListPom[0].CustomerId;
      this.customerFilter.CustomerName = this.articlesListPom[0].CustomerName;
      this.getData();
    }
  }

  onScanerInputChanged(e) {
    this.setSearchCriteria('EAN');
    this.filterValue = e.toString();
    this.getData();
  }

  onEditorPreparing(e) {
    if (e.parentType == 'headerRow' && e.command == 'select') {
      e.editorElement.remove();
    }
  }

  printAfterSave(e: { document: Offer; documentsToPrint?: string[] }) {
    // const sendToEDocumentsAfterSave = this.event.getConfigurationParameter(
    //   'ZapiszObrazEDok',
    //   true
    // ).Value;
    // let sendToEDocuments = false;
    // if (sendToEDocumentsAfterSave === 'Po zatwierdzeniu') {
    //   sendToEDocuments = true;
    // }
    this.dataToPrint = {
      objectId: e.document.OfferDocumentId,
      code: 'Oferta',
      documentNumber: e.document.OfferDocumentNumber,
      menuName: e.document.MenuName,
      type: e.document.OfferDocumentType,
      documentsToPrint: e.documentsToPrint,
      // sendToEDocuments: sendToEDocuments,
    };
    this.cd.detectChanges();
  }

  onReportsPopupClosed() {
    this.dataToPrint = null;
    this.isMobileReportsPopupVisible = false;
    this.cd.detectChanges();
  }

  touchTimeout: any;

  onTouchStart(e) {
    e.stopPropagation();
    this.touchTimeout = setTimeout(() => {
      this.modeSelectionMobile = 'multiple';
      let row = this.gridBank.instance.getDataSource().items()[
        this.focusedRowIndex
      ];
      const id = row.OfferDocumentId;
      if (!this.selectedRows.includes(id)) {
        this.gridBank.instance.selectRows(id, true);
      } else {
        this.gridBank.instance.deselectRows(id);
      }
    }, 1500);
  }

  onTouchEnd() {
    clearTimeout(this.touchTimeout);
  }

  onRecordCardClosing(): void {
    this.isRecordCardVisible = false;
    this.cd.detectChanges();
  }

  showRecordCard(mode: RecordCardMode): void {
    this.recordCardMode = mode;
    switch (mode) {
      case 'contractor':
        this.recordCardObjectId = this.articlesListPom[0].CustomerId;
        break;
      case 'article':
        this.recordCardObjectId = this.focusedPositionData.ProductId;
        break;
    }
    if (!this.recordCardObjectId) return;
    this.isRecordCardVisible = true;
    this.cd.detectChanges();
  }

  showContextMenuMobile = (rowData: Offer) => {
    if (this.selectedRows.includes(rowData.OfferDocumentId)) {
      this.mobileSelectionIcon.set('icon absui-icon--deselct-all');
      this.mobileSelectionText.set(this.translate.instant('unmark'));
    } else {
      this.mobileSelectionIcon.set('icon checkAll');
      this.mobileSelectionText.set(this.translate.instant('mark'));
    }
    this.isContextMenuMobile = true;
  };

  onSetAdditionalOperation(e) {
    this.additionalOperationList.set(e);
  }
}

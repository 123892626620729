<dx-popup
  [width]="420"
  [height]="400"
  position="center"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'Export JPK'"
  [visible]="true"
  (visibleChange)="ngModelChange($event)"
  (onShowing)="event.onShownPopUp(unicalGuid)"
  (onHiding)="event.onHiddenPopUp(unicalGuid)"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClosing.emit()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div
          class="form-group-inline-right"
          style="width: 400px"
          [ngClass]="{ 'width-mobile': event.deviceType == 'mobile' }"
        >
          <div style="margin-top: 3px; margin-bottom: 5px">
            <label style="width: 80px"
              >{{ "dateRange.range" | translate }}
            </label>
            <app-date-range
              style="margin-left: -5px"
              [readOnly]="readOnly"
              [isPopupMode]="true"
              (onChoosed)="onDateRangeChoosed($event)"
              [isGlobal]="true"
              [customSettlementDate]="currentDate"
              [chipsVisible]="true"
            >
            </app-date-range>
          </div>
          <div style="margin-bottom: -4px">
            <label style="width: 80px">
              {{ "status-type.type" | translate }}
            </label>
            <dx-select-box
              [disabled]="type === 'Mag'"
              [(value)]="faType"
              [dataSource]="typeDataSource"
              valueExpr="value"
              displayExpr="label"
              [width]="276"
            ></dx-select-box>
          </div>

          <div style="margin-bottom: 3px">
            <label style="width: 80px; top: 4px"
              >{{ "form-financial-document.payer-type.office" | translate
              }}<strong>*</strong>
            </label>
            <app-offices
              style="position: relative; top: 4px"
              [selectedId]="officeId"
              [readOnly]="readOnly"
              [dropDownBoxMode]="true"
              (onChoosed)="onChoosedOffice($event)"
              [className]="!officeId && submitted"
            >
            </app-offices>
          </div>
          <ng-container *ngIf="faType === 'V7'">
            <label style="width: 80px">
              {{ "isQuarterly" | translate }}
            </label>
            <dx-select-box
              [dataSource]="quarterlyDataSource"
              valueExpr="value"
              displayExpr="label"
              [(value)]="IsQuarterly"
              [width]="276"
            ></dx-select-box>
            <br />
            <label style="width: 80px">
              {{ "offices.mail" | translate }}
            </label>
            <dx-text-box [(value)]="Email" [width]="276"></dx-text-box>
            <br />
            <label style="width: 80px">
              {{ "offices.phone" | translate }}
            </label>
            <dx-text-box [(value)]="Phone" [width]="276"></dx-text-box>
          </ng-container>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-keyboard-shortcuts [shortcuts]="shortcuts"> </ng-keyboard-shortcuts>

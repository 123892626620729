<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '95%'"
  [minWidth]="minWidth"
  position="bottom"
  [minHeight]="630"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  *ngIf="openNestFinished"
  (onShown)="
    event.onShownPopUp();
    getConnectedWarehouseDocuments();
    getNoConnectedWarehouseDocuments();
    setFocus();
    checkHeightPopup()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  #popHeight
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="acceptConnectToInvoice()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      height="100%"
      direction="vertical"
      #scrollView
      class="popup-form"
    >
      <div class="row" style="margin-bottom: 15px; margin-top: 2px">
        <div class="col-md-12" style="padding-top: 5.5px">
          <app-custom-chips-button
            name="Magazyn"
            [list]="werhouseList"
            (onValueChanged)="onChoosedWarehouse($event)"
            *ngIf="event.footerInfo.VariantName !== 'START'"
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="Dokumenty dostępne"
            [list]="radioList"
            (onValueChanged)="
              getNoConnectedWarehouseDocuments($event, 'customerMode')
            "
            [disabledClear]="true"
            [selectedValueInput]="isBuyText"
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="Dokumenty z zamówień"
            [list]="listDocument"
            width="275"
            (onValueChanged)="
              getNoConnectedWarehouseDocuments($event, 'AdditionalFiltresMode')
            "
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="{{ 'document-type.type' | translate }}"
            [list]="kindDocumentType"
            (onValueChanged)="
              getNoConnectedWarehouseDocuments($event, 'PrimaryCorrectionMode')
            "
            *ngIf="isCorrect"
          >
          </app-custom-chips-button>

          <app-date-range
            #dateRange
            [readOnly]="readOnly"
            [isPopupMode]="true"
            (onChoosed)="onDateRangeChoosed()"
            [chipsVisible]="true"
          >
          </app-date-range>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 border-right">
          <div class="c-frame-row" style="width: 100%">
            <h5>
              {{ "form-commercial-operation.documentsAvailable" | translate }}
            </h5>
          </div>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--arrow-forward"
            [text]="'buttons.add' | translate"
            [id]="'btn-add' + unicalGuid"
            [disabled]="
              readOnly ||
              focusedSelectedNoConnected.length == 0 ||
              !DolaczDokMag
            "
            (onClick)="connectWarehouseDocument()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--set-settings"
            [text]="'all' | translate"
            [id]="'btn-add' + unicalGuid"
            [disabled]="readOnly || !DolaczDokMag"
            (onClick)="connectAllWarehouseDocument()"
            #btnAllWarehouseDocument
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--document-search"
            [text]="'buttons.preview' | translate"
            [id]="'btn-edit' + unicalGuid"
            [disabled]="readOnly || !perABD.showBtn"
            (onClick)="editDocument()"
          >
          </dx-button>

          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            [dataSource]="noDocumentWarehouse"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="true"
            [columnResizingMode]="'widget'"
            [height]="heightGrid"
            width="100%"
            class="gridWarehouse"
            [ngClass]="{ dragDropGrid: fromData == 2 }"
            (onFocusedRowChanged)="onFocusedRowChangedNoConected($event)"
            style="margin-top: 10px"
            (onRowClick)="onRowClickNoConected($event)"
            (onRowClick)="onRowClickDisconect($event)"
            (onRowDblClick)="connectWarehouseDocument()"
            #gridToConnect
          >
            <dxo-row-dragging
              *ngIf="!readOnly"
              [data]="1"
              group="tasksGroup"
              [onAdd]="onAdd"
              [onDragStart]="onDragStartGrid"
              [onDragEnd]="onDragEndGrid"
              [allowReordering]="false"
              [showDragIcons]="false"
            ></dxo-row-dragging>
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            >
            </dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption=""
              width="20"
              [allowSorting]="false"
              dataField="CompanyId"
              cellTemplate="CompanyId"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'CompanyId'">
              <span class="dx-datagrid-drag-icon"></span>
            </div>
            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="WarehouseDocumentNumber"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [allowSorting]="false"
              dataField="DateOfWarehouseDocument"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'warehouseDocument.dateOfTax' | translate }}"
              [allowSorting]="false"
              dataField="DateOfTax"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              width="150"
              [allowSorting]="false"
              dataField="CustomerName"
            ></dxi-column>
          </dx-data-grid>
        </div>

        <div class="col-md-6">
          <div class="resizing-box">
            <div
              class="circleBox"
              [ngClass]="{
                activeCircleBox:
                  !readOnly &&
                  focusedSelectedNoConnected.length == 1 &&
                  DolaczDokMag
              }"
              (click)="connectWarehouseDocument()"
            >
              <i class="icon absui-icon--arrow-forward"></i>
            </div>
            <div class="resizing-icon">
              <!-- <div class="inside-icon">
                <i class="icon absui-icon--operations"></i>
              </div> -->
            </div>
            <div
              class="circleBox"
              [ngClass]="{
                activeCircleBox:
                  (!readOnly && focusedSelected.length == 1) || !DolaczDokMag
              }"
              (click)="disconnectWarehouseDocument()"
            >
              <i class="icon absui-icon--arrow-back"></i>
            </div>
          </div>
          <div class="c-frame-row" style="width: 100%">
            <h5>
              {{
                "warehouseDocument.documentsRelatedToTheTradeDocument"
                  | translate
              }}
            </h5>
          </div>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--arrow-back"
            [text]="'buttons.delete' | translate"
            [id]="'btn-delete' + unicalGuid"
            [disabled]="
              readOnly || focusedSelected.length == 0 || !DolaczDokMag
            "
            (onClick)="disconnectWarehouseDocument()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--document-search"
            [text]="'buttons.preview' | translate"
            [id]="'btn-edit' + unicalGuid"
            [disabled]="readOnly || !perABD.showBtn"
            (onClick)="editDocument()"
          >
          </dx-button>

          <dx-data-grid
            [showBorders]="true"
            [wordWrapEnabled]="false"
            [hoverStateEnabled]="true"
            class="gridWarehouse"
            [dataSource]="documentWarehouse"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="true"
            (onRowClick)="onRowClickConecct($event)"
            #gridConnected
            [columnResizingMode]="'widget'"
            [height]="heightGrid"
            width="100%"
            style="margin-top: 10px"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            (onRowClick)="onRowClickNoConected($event)"
            (onRowDblClick)="disconnectWarehouseDocument()"
            [ngClass]="{ dragDropGrid: fromData == 1 }"
          >
            <dxo-row-dragging
              *ngIf="!readOnly"
              [data]="2"
              group="tasksGroup"
              [onAdd]="onAdd"
              [onDragStart]="onDragStartGrid"
              [onDragEnd]="onDragEndGrid"
              [allowReordering]="false"
              [showDragIcons]="false"
            ></dxo-row-dragging>

            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="infinite"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            >
            </dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              mode="single"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption=""
              width="20"
              [allowSorting]="false"
              dataField="CompanyId"
              cellTemplate="CompanyId"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'CompanyId'">
              <span class="dx-datagrid-drag-icon"></span>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              width="150"
              [allowSorting]="false"
              dataField="WarehouseDocumentNumber"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [allowSorting]="false"
              dataField="DateOfWarehouseDocument"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'warehouseDocument.dateOfTax' | translate }}"
              [allowSorting]="false"
              dataField="DateOfTax"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              width="150"
              [allowSorting]="false"
              dataField="CustomerName"
            ></dxi-column>
          </dx-data-grid>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-check-box
            text="{{
              'form-commercial-operation.checkIfThereAreUnrelatedWarehouseDocumentsWhenSelectingAcontractor'
                | translate
            }}"
            [(ngModel)]="isSprNFDokMagKon"
            (onValueChanged)="onChangedSprNFDokMagKon($event)"
          ></dx-check-box>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-new-warehouse-document
  [isVisible]="addRow"
  (onClosing)="onClosingForm()"
  title="{{ 'stockHistory.warehouseDoc' | translate }}"
  [selected]="focusedSelectedToPreview"
  [isCancelMode]="true"
  [sumPositionIncome]="sumPositionIncome"
  [InvoiceDocumentId]="invoiceDocumentId"
  [isCorrect]="isCorrect"
  [editMode]="true"
  [readOnly]="true"
>
</app-new-warehouse-document>

<app-confirm-dialog
  *ngIf="diferentDateWarehouseDocument"
  kindDialog="question"
  [confirmText]="
    'form-commercial-operation.thereIsAwarehouseDocumentWithAdateDifferentFromTheSaleDateDoYouWantContinue'
  "
  [isVisible]="true"
  (onRemoving)="yesDiferentDateWarehouseDocument()"
  (onClosing)="noDiferentDateWarehouseDocument()"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || isExpansionListVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGHWOA'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGHWOA'"
  (onClosing)="onExpansionListClosing()"
></app-expansion-list>

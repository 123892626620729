<dx-popup
  #dxpopup
  [(visible)]="isVisible"
  [(width)]="localStorageData.windowSize.width"
  [(height)]="localStorageData.windowSize.height"
  (onResize)="onResizeStart($event)"
  (onResizeEnd)="onResizeEnd()"
  (fullScreenChange)="fullScreenChange($event)"
  position="bottom"
  [showCloseButton]="event.deviceType === 'mobile'"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [minWidth]="minWidth"
  [maxHeight]="maxHeight"
  [minHeight]="280"
  [resizeEnabled]="true"
  [fullScreen]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.isFullscreen
      : false
  "
  (onShown)="
    event.onShownPopUp();
    loadChildComponent = true;
    setFocus();
    cd.detectChanges()
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid, class: 'document-form-mobile' }"
  [hideOnOutsideClick]="false"
  (visibleChange)="visibleChange()"
  contentTemplate="content"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let data of 'content'">
    <div
      class="text-right title-pop-up"
      [ngClass]="{
        boxShadow: scrollPosition > 0 && event.deviceType != 'mobile'
      }"
    >
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 45px)'"
      direction="vertical"
      (onScroll)="onScroll($event)"
    >
      <form [formGroup]="form">
        <dx-tab-panel
          #tabPanel
          [(selectedIndex)]="selectedTabIndex"
          [items]="itemTabs"
          [loop]="false"
          [animationEnabled]="false"
          [swipeEnabled]="false"
          class="tab-panel-document intrastate-form-step-3"
        >
          <div *dxTemplate="let name of 'item'">
            <ng-container [ngSwitch]="name">
              <div *ngSwitchCase="'companies.tabs.basicInfo' | translate">
                <div
                  class="d-flex justify-content-center flex-wrap"
                  style="margin-top: 15px"
                >
                  <div class="form-group-inline-right">
                    <div>
                      <label style="width: 130px">{{
                        "deliveries.formDelivery" | translate
                      }}</label>
                      <app-delivery
                        [dropDownBoxMode]="true"
                        [readOnly]="readOnly"
                        style="display: inline-block"
                        (onChoosed)="onChoosedDeliveryForm($event)"
                        [selectedId]="form.controls.DeliveryFormId.value"
                      >
                      </app-delivery>
                    </div>

                    <div
                      class="c-frame-row"
                      style="width: 100%; margin-top: 15px"
                    >
                      <h5 style="padding-left: 10px; padding-right: 10px">
                        {{
                          "form-commercial-operation.deliveryAddress"
                            | translate
                        }}
                      </h5>
                    </div>

                    <div>
                      <label style="width: 130px"
                        >{{ "userExtensions.customers" | translate
                        }}<strong>*</strong></label
                      >
                      <app-contractors
                        [selectedId]="form.get('CustomerId').value"
                        [dropDownBoxMode]="true"
                        [className]="
                          !form.controls.CustomerId.valid && submitted
                        "
                        style="width: 276px"
                        (onChoosed)="onChoosedCustomer($event)"
                      >
                      </app-contractors>
                    </div>

                    <div>
                      <label style="width: 130px"></label>
                      <dx-button
                        class="outline-secondary"
                        stylingMode="outlined"
                        text="{{ 'crmDeliveries.chooseAddress' | translate }}"
                        style="margin-right: 6px; margin-bottom: 7px"
                        (onClick)="isVisibleChooseAddress = true"
                        [id]="'deliveryAddress' + unicalGuid"
                      >
                      </dx-button>

                      <div class="d-flex justify-content-between">
                        <div>
                          <label style="width: 130px"
                            >{{
                              "companies.form.addressDetail.country" | translate
                            }}
                          </label>
                          <dx-text-box
                            style="width: 101px"
                            [disabled]="true"
                            [value]="deliveryAddress[0].CountryCode"
                          >
                          </dx-text-box>
                        </div>

                        <div>
                          <label style="width: 82px; margin-right: 8px"
                            >{{ "companies.form.postalCode" | translate }}
                          </label>
                          <dx-text-box
                            style="width: 76px"
                            [disabled]="true"
                            [value]="deliveryAddress[0].PostalCode"
                          >
                          </dx-text-box>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label style="width: 130px">{{
                        "singleBank.form.adress" | translate
                      }}</label>
                      <dx-text-box
                        style="width: 276px"
                        [disabled]="true"
                        [value]="deliveryAddress[0].Street"
                      >
                      </dx-text-box>
                    </div>

                    <div>
                      <label style="width: 130px"
                        >{{ "delivery.addressType" | translate }}
                      </label>
                      <dx-text-box
                        style="width: 276px"
                        [disabled]="true"
                        [value]="deliveryAddress[0].AddressType"
                      >
                      </dx-text-box>
                    </div>

                    <div>
                      <label style="width: 130px">{{
                        "offices.phone" | translate
                      }}</label>
                      <dx-text-box
                        style="width: 276px"
                        [disabled]="true"
                        [value]="deliveryAddress[0].Phone"
                      >
                      </dx-text-box>
                    </div>

                    <div>
                      <label style="width: 130px">{{
                        "offices.mail" | translate
                      }}</label>
                      <dx-text-box
                        style="width: 276px"
                        [disabled]="true"
                        [value]="deliveryAddress[0].Email"
                      >
                      </dx-text-box>
                    </div>

                    <div>
                      <label style="width: 130px">{{
                        "formPayment.operator" | translate
                      }}</label>
                      <dx-text-box
                        style="width: 276px"
                        [disabled]="true"
                        [value]="deliveryAddress[0].PostageOperator"
                      >
                      </dx-text-box>
                    </div>

                    <div>
                      <label style="width: 130px">{{
                        "constractors.pickupPoint" | translate
                      }}</label>
                      <dx-text-box
                        style="width: 276px"
                        [disabled]="true"
                        [value]="deliveryAddress[0].PickUpAddressCode"
                      >
                      </dx-text-box>
                    </div>
                  </div>
                </div>
              </div>

              <div
                *ngSwitchCase="
                  'constractors.pickupDeliveryParameters' | translate
                "
              >
                <div
                  class="d-flex justify-content-center flex-wrap"
                  style="margin-top: 15px"
                ></div>
              </div>
            </ng-container>
          </div>
        </dx-tab-panel>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<ng-template #topButtons>
  <i
    class="icon"
    [ngClass]="{
      'absui-icon--fullscreen2': !localStorageData.windowSize.isFullscreen,
      'absui-icon--fullscreen-off': localStorageData.windowSize.isFullscreen
    }"
    (click)="
      localStorageData.windowSize.isFullscreen =
        !localStorageData.windowSize.isFullscreen
    "
    style="position: relative; top: 5px; left: 6px"
    [id]="'fitToWidth' + unicalGuid"
  ></i>

  <dx-button
    class="intrastate-form-step-5"
    text="{{ 'buttons.save' | translate }}"
    type="success"
    style="margin-right: 6px"
    (onClick)="onSave()"
    [id]="'save' + unicalGuid"
    [disabled]="mode === 'show'"
  ></dx-button>

  <dx-button
    text="{{ 'buttons.cancel' | translate }}"
    type="danger"
    (onClick)="onCancel()"
    [id]="'cancel' + unicalGuid"
  >
  </dx-button>

  <dx-tooltip
    [target]="'#save' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.save" | translate }} (F10)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#cancel' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "buttons.cancel" | translate }} (Esc)
    </div>
  </dx-tooltip>

  <dx-tooltip
    [target]="'#fitToWidth' + unicalGuid"
    hideEvent="dxhoverend"
    [hideOnOutsideClick]="false"
    [showEvent]="event.tooltipShowEvent"
  >
    <div *dxTemplate="let data of 'content'">
      {{ "servicesDevices.fitToWidth" | translate }}
    </div>
  </dx-tooltip>
</ng-template>

<!--<app-confirm-dialog-->
<!--  [isVisible]="isShowConfirmCancel"-->
<!--  confirmText="inventory.wantToCancel"-->
<!--  (onClosing)="onClosingConfirm()"-->
<!--  (onRemoving)="onRemovingSettlement()"-->
<!--  kindDialog="question"-->
<!--&gt;-->
<!--</app-confirm-dialog>-->

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

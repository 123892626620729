<div>
  <div class="top-menu-panel">
    <ng-container *ngTemplateOutlet="topButtons"></ng-container>
  </div>

  <div class="center-panel-scroll-verticall">
    <ng-container *ngTemplateOutlet="grid"></ng-container>
  </div>
</div>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
  >
    <div class="d-inline-block tasks-kanban-step-1">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        type="default"
        stylingMode="text"
        id="btn-add-articles"
        (onClick)="setSingleRecordVisible('add')"
        [disabled]="readOnly || !perABD.addBtn"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        type="default"
        stylingMode="text"
        id="btn-edit-articles"
        (onClick)="setSingleRecordVisible('edit')"
        [disabled]="readOnly || selectedTask.length == 0 || !perABD.editBtn"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--form-items"
        id="btn-show-articles"
        class="btn-dx-top-menu-folder"
        [disabled]="selectedTask.length == 0 || !perABD.showBtn"
        type="default"
        stylingMode="text"
        (onClick)="setSingleRecordVisible('show')"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--highlight-off"
        type="default"
        stylingMode="text"
        id="btn-delete-articles"
        (onClick)="isDelete = true"
        [disabled]="readOnly || selectedTask.length == 0"
      >
      </dx-button>

      <!-- [showGroupReports]="false" -->
      <app-reports
        #reports
        [code]="'crm'"
        [menuName]="null"
        [type]="null"
        [objectId]="null"
      >
      </app-reports>

      <app-e-dokuments
        [isPopupMode]="true"
        [isVisible]="eDocumentsVisible"
        [id]="'eDocuments' + unicalGuid"
        #eDocuments
        [permission]="perEdocuments"
        DocumentCode="CrmTaskRelated"
        tableName="CRM_ZADANIE"
        [contextObjectId]="selectedTaskData?.TaskId"
        [parentDocumentName]="selectedTaskData?.Subject"
        style="display: inline-block"
        (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
      ></app-e-dokuments>

      <app-additional-operation
        nameComponent="CrmTasks"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      >
      </app-additional-operation>
    </div>

    <!-- tooltips -->

    <dx-tooltip
      target="#btn-add-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-edit-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-delete-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-show-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-eDocuments-tasks"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.eDocuments" | translate }} (Ctrl + E)
      </div>
    </dx-tooltip>

    <dx-tooltip
      target="#btn-operations-articles"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "form-financial-document.operations" | translate }} (Ctrl + O)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (click)="refreshView()"
        style="margin-right: 6px"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder tasks-kanban-step-2"
        icon="column-chooser"
        (click)="columnsChooserVisible = true"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "menu.navigationPanelUser.help" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSettings" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row" (click)="clearSelectedItem()">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div class="with_search_panel" #rightPanel>
          <div class="row">
            <div class="col-md-12">

              <div style="    display: flex;
              align-items: center;
              gap: 6px;    justify-content: space-between;
          ">
          <div class="d-inline-block tasks-kanban-step-4">
            
            <app-custom-dropdown-box
              class="tasks-kanban-step-3"
              [items]="filterCriteria"
              [(selectedItem)]="localStorageData.filter.orderBy"
              [(filterValue)]="filterValue"
              (onValueChanged)="onFilterDataChanged($event)"
              style="margin-right: 5px"
              #searchControl
            >
            </app-custom-dropdown-box>

            <app-date-range
              #dateRange
              [deleteFilter]="deleteFilter"
              [isVisible]="dateRangeVisible"
              [readOnly]="readOnly"
              [isPopupMode]="true"
              (onChoosed)="onDateRangeChoosed()"
              [chipsVisible]="true"
            >
            </app-date-range>

            <app-custom-chips-button
              *ngIf="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                localStorageData?.advancedFilter?.IsPinned
              "
              [name]="'filterPanel.template' | translate"
              [labelExpr]="'Name'"
              [valueExpr]="'FilterUsersDefinitionId'"
              [selectedValueInput]="
                localStorageData?.advancedFilter?.Name || null
              "
              [list]="dynamicFilterUserDefinitions"
              (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
            >
            </app-custom-chips-button>

            <app-view-dynamic-quick-filters
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [pinnedFilters]="
                localStorageData.advancedFilter?.PinnedFilters
              "
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              (onValueChanged)="onPinnedValueChanged($event)"
            ></app-view-dynamic-quick-filters>

            <dx-button
              [id]="'clearValues' + unicalGuid"
              class="btn-filter-box"
              icon="icon absui-icon--clear-filter"
              (onClick)="clearValues()"
            ></dx-button>
            <dx-tooltip
              [target]="'#clearValues' + unicalGuid"
              [showEvent]="event.tooltipShowEvent"
              hideEvent="dxhoverend"
              [hideOnOutsideClick]="false"
            >
              <div *dxTemplate="let data of 'content'">
                {{ "clearAllFilters" | translate }} (Ctrl + F10)
              </div>
            </dx-tooltip>
          </div>
        <div>
          <app-custom-filter-dropdown-box
            style="margin-top: -6px;"
            [openDropDown]="filterCriteriaViewOpened"
            [items]="filterCriteria"
            [dropdownHeight]="180"
            [selectedItem]="localStorageData?.filter?.orderBy"
            [sortDirection]="localStorageData?.filter?.sort"
            [filterValue]="localStorageData.filter.orderBy"
            [autoEmit]="true"
            (onValueChanged)="changeFilterCriteria($event)"
          />
        </div></div>
              
              <!-- <ng-container *ngFor="let filter of filters; let index = index">
                                <dx-button class="btn-filter-box chips-box" *ngIf="filter.pin" [ngClass]="{ 'chips-pad-left-icon': !filter.label }"
                                    (onClick)="resetFilterPanel(index)">
                                    <i *ngIf="!filter.label" class="icon icon-pin"></i>
                                        {{filter.name}}<span *ngIf="filter.value">:</span>
                                        <b>
                                            {{ filter.value }}
                                        </b>

                                    <i class="icon absui-icon--multiplication chips-close"></i>
                                </dx-button>


                            </ng-container> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 kanban">
              <!-- <dx-button class="btn-filter-box" *ngIf="localStorageData.taskStatusName"
                                (onClick)="resetFiltr('taskStatusName')">Status: <b>{{ localStorageData.taskStatusName }}</b>
                                <i class="icon absui-icon--multiplication chips-close"></i>
                            </dx-button>

                            <dx-button class="btn-filter-box" *ngIf="localStorageData.taskTypeName"
                                (onClick)="resetFiltr('taskTypeName')">{{'userExtensions.fieldType' | translate}}: <b>{{ localStorageData.taskTypeName }}</b>
                                <i class="icon absui-icon--multiplication chips-close"></i>
                            </dx-button> -->

              <dx-scroll-view
                width="100%"
                [height]="canbanHeight"
                direction="horizontal"
                class="tasks-kanban-step-5"
                [id]="'canban' + unicalGuid"
              >
                <div
                  class="wrapper"
                  dragula="COLUMNS"
                  [(dragulaModel)]="groups"
                >
                  <ng-container *ngFor="let group of groups; let index = index">
                    <div
                      *ngIf="group.checked"
                      class="status-block"
                      [style.width.px]="
                        localStorageData?.other?.singleWidth || 220
                      "
                    >
                      <div class="group-handle">
                        <app-status-label
                          [text]="group.name"
                          [color]="group.StatusStringColor"
                          [backgroundColor]="group.StatusBackgroundColor"
                        ></app-status-label>
                        {{ group.caption || "" }}
                        <div class="status-amount" [id]="'amount' + group.name">
                          ({{ group.items.length }})
                        </div>
                        <dx-tooltip
                          [target]="'#amount' + group.name"
                          [showEvent]="event.tooltipShowEvent"
                          hideEvent="dxhoverend"
                          [hideOnOutsideClick]="false"
                        >
                          <div *dxTemplate="let data of 'content'">
                            Ilość zadań: {{ group.items.length }}
                          </div>
                        </dx-tooltip>
                      </div>
                      <div
                        class="status-container"
                        [style.height.px]="canbanHeight - 60"
                        [ngClass]="{
                          'opacity-bg': group.items && group.items.length,
                          'box-bg-none-even': index % 2 === 0,
                          'box-bg-none-odd': index % 2 === 1
                        }"
                      >
                        <!--
                                                'box-bg-none-even': ((!group.items || !group.items.length) && index % 2 === 0),
                                                'box-bg-none-odd': ((!group.items || !group.items.length) && index % 2 === 1) -->
                        <dx-scroll-view
                          width="100%"
                          [height]="'calc(100% - 25px)'"
                          direction="vertical"
                          (onScroll)="onScroll($event, group)"
                          [ngClass]="{ 'tasks-kanban-step-6': index === 0 }"
                        >
                          <div
                            dragula="ITEMS"
                            class="nested-wrapper"
                            [style.width.px]="
                              localStorageData?.other?.singleWidth
                                ? localStorageData?.other?.singleWidth - 20
                                : 220
                            "
                            [(dragulaModel)]="group.items"
                            [attr.data-id]="group.name"
                          >
                            <div
                              *ngFor="let item of group.items"
                              class="final-box"
                              (click)="stop($event)"
                              [ngClass]="{
                                'final-box-selected': item.selected
                              }"
                              (click)="setSelected(item, $event)"
                              (contextmenu)="onRightClick(item, $event)"
                              (dblclick)="editSelectedRow(item)"
                              [id]="'task' + item.TaskId + unicalGuid"
                            >
                              <div class="final-box-content">
                                <div
                                  class="left-border"
                                  [style.background]="
                                    item.Colour ? '#' + item.Colour : '#4DB6AC'
                                  "
                                ></div>
                                <div class="box-content move-box">
                                  <div class="title single-title move-box">
                                    {{ item.Subject }}
                                  </div>
                                  <div class="date move-box">
                                    <span
                                      [id]="'dateStart' + item.TaskId"
                                      class="move-box"
                                    >
                                      {{
                                        item.DateStart
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}
                                    </span>
                                    <i
                                      class="icon arrow-small-right move-box"
                                    ></i>
                                    <span
                                      [id]="'dateEnd' + item.TaskId"
                                      class="move-box"
                                    >
                                      {{
                                        item.DateEnd
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}
                                    </span>
                                  </div>
                                  <dx-tooltip
                                    [target]="'#dateStart' + item.TaskId"
                                    [showEvent]="event.tooltipShowEvent"
                                    hideEvent="dxhoverend"
                                    [hideOnOutsideClick]="false"
                                  >
                                    <div *dxTemplate="let data of 'content'">
                                      Rozpoczęcie:
                                      {{
                                        item.DateStart
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}
                                    </div>
                                  </dx-tooltip>
                                  <dx-tooltip
                                    [target]="'#dateEnd' + item.TaskId"
                                    [showEvent]="event.tooltipShowEvent"
                                    hideEvent="dxhoverend"
                                    [hideOnOutsideClick]="false"
                                  >
                                    <div *dxTemplate="let data of 'content'">
                                      Zakończenie:
                                      {{
                                        item.DateEnd
                                          | date
                                            : "dd.MM.yyyy HH:mm"
                                            : translate.currentLang || "pl"
                                      }}
                                    </div>
                                  </dx-tooltip>
                                  <div class="task-progress move-box">
                                    <div class="move-box">
                                      <i
                                        *ngIf="item.Prioritate == 0"
                                        style="margin-right: 5px"
                                        class="icon low-priority move-box"
                                        [id]="'priority' + item.TaskId"
                                      ></i>
                                      <i
                                        *ngIf="item.Prioritate == 1"
                                        style="margin-right: 5px"
                                        class="icon medium-priority move-box"
                                        [id]="'priority' + item.TaskId"
                                      ></i>
                                      <i
                                        *ngIf="item.Prioritate == 2"
                                        style="margin-right: 5px"
                                        class="icon high-priority move-box"
                                        [id]="'priority' + item.TaskId"
                                      ></i>

                                      <i
                                        [id]="'icon' + item.TaskId"
                                        class="icon icon-group icon-cell user-group move-box"
                                        *ngIf="
                                          getTypeDescription(item.TypeId) ===
                                          'Spotkanie'
                                        "
                                      ></i>
                                      <i
                                        [id]="'icon' + item.TaskId"
                                        class="icon icon-group icon-cell phone-group move-box"
                                        *ngIf="
                                          getTypeDescription(item.TypeId) ===
                                          'Telefon'
                                        "
                                      ></i>
                                      <i
                                        [id]="'icon' + item.TaskId"
                                        class="icon icon-group icon-cell car-group move-box"
                                        *ngIf="
                                          getTypeDescription(item.TypeId) ===
                                          'Wyjazd'
                                        "
                                      ></i>
                                      <i
                                        [id]="'icon' + item.TaskId"
                                        class="icon icon-group icon-cell service-group move-box"
                                        *ngIf="
                                          getTypeDescription(item.TypeId) ===
                                          'Zlecenie serwisowe'
                                        "
                                      ></i>
                                      <i
                                        [id]="'icon' + item.TaskId"
                                        class="icon icon-group icon-cell default-icon move-box"
                                        *ngIf="
                                          getTypeDescription(item.TypeId) !==
                                            'Spotkanie' &&
                                          getTypeDescription(item.TypeId) !==
                                            'Telefon' &&
                                          getTypeDescription(item.TypeId) !==
                                            'Wyjazd' &&
                                          getTypeDescription(item.TypeId) !==
                                            'Zlecenie serwisowe'
                                        "
                                      ></i>

                                      <div
                                        class="d-inline-block percent move-box"
                                        [id]="'realisation' + item.TaskId"
                                      >
                                        {{ item.PctOfCompletion }}%
                                      </div>

                                      <dx-tooltip
                                        [target]="'#priority' + item.TaskId"
                                        [showEvent]="event.tooltipShowEvent"
                                        hideEvent="dxhoverend"
                                        [hideOnOutsideClick]="false"
                                      >
                                        <div
                                          *dxTemplate="let data of 'content'"
                                        >
                                          Priorytet:
                                          {{
                                            item.Prioritate == 0
                                              ? ("constractors.low" | translate)
                                              : ""
                                          }}
                                          {{
                                            item.Prioritate == 1
                                              ? ("constractors.medium"
                                                | translate)
                                              : ""
                                          }}
                                          {{
                                            item.Prioritate == 2
                                              ? ("constractors.high"
                                                | translate)
                                              : ""
                                          }}
                                        </div>
                                      </dx-tooltip>

                                      <dx-tooltip
                                        [target]="'#icon' + item.TaskId"
                                        [showEvent]="event.tooltipShowEvent"
                                        hideEvent="dxhoverend"
                                        [hideOnOutsideClick]="false"
                                      >
                                        <div
                                          *dxTemplate="let data of 'content'"
                                        >
                                          {{ getTypeDescription(item.TypeId) }}
                                        </div>
                                      </dx-tooltip>

                                      <dx-tooltip
                                        [target]="'#realisation' + item.TaskId"
                                        [showEvent]="event.tooltipShowEvent"
                                        hideEvent="dxhoverend"
                                        [hideOnOutsideClick]="false"
                                      >
                                        <div
                                          *dxTemplate="let data of 'content'"
                                        >
                                          Realizacja:
                                          {{ item.PctOfCompletion }}%
                                        </div>
                                      </dx-tooltip>
                                    </div>
                                    <div
                                      class="person text-center"
                                      *ngIf="item.AppUserName"
                                      [id]="'person' + item.TaskId"
                                    >
                                      {{
                                        item.AppUserName.substring(
                                          0,
                                          2
                                        ).toUpperCase()
                                      }}
                                    </div>
                                    <dx-tooltip
                                      [target]="'#person' + item.TaskId"
                                      [showEvent]="event.tooltipShowEvent"
                                      hideEvent="dxhoverend"
                                      [hideOnOutsideClick]="false"
                                    >
                                      <div *dxTemplate="let data of 'content'">
                                        {{
                                          item.AppUserName
                                            ? item.AppUserName
                                            : ""
                                        }}
                                      </div>
                                    </dx-tooltip>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="abs-details"
                                (click)="isOpened = false"
                                *ngIf="item.selected"
                                [style.left.px]="clientX"
                                [style.top.px]="clientY"
                                style="width: 380px"
                                (click)="stop($event)"
                              >
                                <div
                                  class="dx-popover-arrow"
                                  [ngClass]="{
                                    'dx-popover-arrow-bottom': arrowBottom,
                                    'dx-popover-arrow-right': arrowRight
                                  }"
                                ></div>
                                <div class="movie-tooltip">
                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 10px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    >
                                      <div
                                        class="color-circle"
                                        [style.background]="
                                          item.Colour
                                            ? '#' + item.Colour
                                            : '#4DB6AC'
                                        "
                                      ></div>
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 276px; padding-top: 4px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label main-col-details"
                                        style="
                                          width: 276px;
                                          font-weight: 700;
                                          font-size: 0.8125rem;
                                          line-height: 15.23px;
                                          color: var(--text-dark-gray, #222222);
                                          white-space: break-spaces;
                                          text-align: left;
                                        "
                                        (click)="onTitleClick(item.TaskId)"
                                        >{{ item.Subject }}</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 40px; position: relative"
                                    >
                                      <dx-button
                                        class="btn-more"
                                        [ngClass]="{ 'btn-bord': isOpened }"
                                        icon="more-horizontal"
                                        type="default"
                                        stylingMode="text"
                                        style="margin-left: 7px"
                                        (click)="switchIsOpened($event)"
                                        [id]="
                                          'btn-more' + item.TaskId + unicalGuid
                                        "
                                      ></dx-button>
                                      <!-- style="" -->

                                      <wapro-context-menu
                                        [dataSource]="contextMenuSignal()"
                                        [width]="200"
                                        [target]="
                                          '#btn-more' +
                                          selectedTask[0] +
                                          unicalGuid
                                        "
                                        (onItemClick)="contextMenuClick($event)"
                                        (onItemRendered)="
                                          event.onItemRendered($event)
                                        "
                                        showEvent="dxclick"
                                      >
                                      </wapro-context-menu>

                                      <!-- <dx-select-box
                                        [opened]="isOpened"
                                        [focusStateEnabled]="false"
                                        [hoverStateEnabled]="true"
                                        class="hidden-select-box star-menu"
                                        [ngClass]="{
                                          'right-side': !isLeftSelect,
                                          'left-side': isLeftSelect
                                        }"
                                        [value]="selectBoxValue"
                                        (onValueChanged)="
                                          onSelectBoxValueChanged($event, item)
                                        "
                                        [dataSource]="popupSelectBoxSource"
                                        [dropDownOptions]="{
                                          wrapperAttr: { class: 'no-checkbox' }
                                        }"
                                      >
                                        <div *dxTemplate="let data of 'item'">
                                          <div class="custom-item-temp">
                                            <i
                                              class="icon absui-icon--mode-edit"
                                              *ngIf="data.i == 'edit'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--trash"
                                              *ngIf="data.i == 'delete'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--form-items"
                                              *ngIf="data.i == 'show'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--print"
                                              *ngIf="data.i == 'print'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--edocuments"
                                              *ngIf="data.i == 'edocuments'"
                                            ></i>
                                            <i
                                              class="icon absui-icon--operations"
                                              *ngIf="data.i == 'operations'"
                                            ></i>

                                            <div
                                              class="product-name d-inline-block"
                                            >
                                              {{ data.text | translate }}
                                            </div>
                                          </div>
                                        </div>
                                      </dx-select-box> -->
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 8px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    ></div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 120px"
                                    >
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "taskCalendar.dateStart" | translate
                                        }}:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "taskCalendar.dateEnd" | translate
                                        }}:</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 190px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          item.DateStart
                                            | date
                                              : "dd.MM.yyyy HH:mm"
                                              : translate.currentLang || "pl"
                                        }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          item.DateEnd
                                            | date
                                              : "dd.MM.yyyy HH:mm"
                                              : translate.currentLang || "pl"
                                        }}</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 8px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    ></div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 120px; position: relative"
                                    >
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Osoba przypisana:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "form-financial-document.payer-type.contractor"
                                            | translate
                                        }}:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        style="
                                          position: absolute;
                                          bottom: 0 !important;
                                          top: auto;
                                        "
                                        >{{
                                          "taskCalendar.reminder" | translate
                                        }}:</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 190px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          item.AppUserName
                                            ? item.AppUserName
                                            : "-"
                                        }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="
                                          width: 100%;
                                          white-space: break-spaces;
                                        "
                                        >{{ item.CustomerName || "-" }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          item.RemindTimer
                                            ? item.RemindTimer.substr(0, 5)
                                            : "-"
                                        }}</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="d-flex justify-content-left flex-wrap"
                                    style="padding-bottom: 5px"
                                  >
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 32px"
                                    ></div>
                                    <div
                                      class="form-group-inline-right"
                                      style="width: 120px"
                                    >
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Status:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >{{
                                          "taskCalendar.realization"
                                            | translate
                                        }}:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Typ:</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        style="width: 120px"
                                        class="text-left cloud-label left-lbl"
                                        >Priotytet:</label
                                      >
                                    </div>
                                    <div
                                      class="form-group-inline-right right-lbl"
                                      style="width: 190px"
                                    >
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          getStatusDescription(item.StatusCode)
                                        }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{ item.PctOfCompletion }}%</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl f-roboto"
                                        style="width: 100%"
                                        >{{
                                          getTypeDescription(item.TypeId)
                                        }}</label
                                      >
                                      <br />
                                      <label
                                        for=""
                                        class="text-left cloud-label right-lbl"
                                      >
                                        <i
                                          id="prio"
                                          *ngIf="item.Prioritate == 0"
                                          class="icon low-priority"
                                        ></i>
                                        <i
                                          id="prio"
                                          *ngIf="item.Prioritate == 1"
                                          class="icon medium-priority"
                                        ></i>
                                        <i
                                          id="prio"
                                          *ngIf="item.Prioritate == 2"
                                          class="icon high-priority"
                                        ></i>
                                        <dx-tooltip
                                          target="#prio"
                                          [showEvent]="event.tooltipShowEvent"
                                          hideEvent="dxhoverend"
                                          [hideOnOutsideClick]="false"
                                        >
                                          <div
                                            *dxTemplate="let data of 'content'"
                                          >
                                            {{
                                              item.Prioritate == 0
                                                ? ("constractors.low"
                                                  | translate)
                                                : ""
                                            }}
                                            {{
                                              item.Prioritate == 1
                                                ? ("constractors.medium"
                                                  | translate)
                                                : ""
                                            }}
                                            {{
                                              item.Prioritate == 2
                                                ? ("constractors.high"
                                                  | translate)
                                                : ""
                                            }}
                                          </div>
                                        </dx-tooltip>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </dx-scroll-view>

                        <div class="text-center new-task">
                          <dx-button
                            icon="icon absui-icon--add-no-circle"
                            (onClick)="addNewTaskBtn(group.name)"
                            text="{{ 'plannedOrders.newTask' | translate }}"
                            [ngClass]="{ 'tasks-kanban-step-7': index === 0 }"
                          ></dx-button>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </dx-scroll-view>
            </div>
          </div>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          [ngStyle]="{ width: localStorageData.sidePanel.width }"
          #leftPanel
        >
          <div class="right-panel-btn">
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>
          <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[1, 2]"
    [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
    style="margin-top: -10px"
    class="filter-panel right-panel-box tasks-kanban-step-8"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--tab-filter" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-scroll-view
            width="100%"
            [height]="'calc(100vh - 195px)'"
            direction="vertical"
            [bounceEnabled]="false"
            [scrollByContent]="true"
          >
            <div class="list-container">
              <div
                class="dx-draggable"
                dragula="ITEMS"
                id="copy-src"
                [(dragulaModel)]="taskTypes"
              >
                <div
                  class="drag-item dx-draggable move-box"
                  *ngFor="let task of taskTypes"
                  [attr.data-id]="task.TypeId"
                >
                  <i
                    class="icon absui-icon--operations-kanban grab-area move-box"
                  ></i>
                  <i class="icon icon-group move-box" [ngClass]="task.icon"></i>
                  <div class="display-wrapper move-box">
                    <div class="amount-txt move-box">
                      {{ task.amount || 0 }}
                    </div>
                    <div class="type-txt move-box">
                      {{ task.Name }}
                    </div>
                  </div>
                  <i
                    class="icon icon-group plus-btn"
                    (click)="addTask(task)"
                  ></i>
                </div>
              </div>
            </div>
          </dx-scroll-view>
          <div
            class="text-center new-task"
            style="
              display: block !important;
              font-family: Roboto !important;
              color: #4c4c4c !important;
            "
          >
            <dx-button
              icon="icon absui-icon--add-no-circle"
              class="tasks-kanban-step-9"
              (onClick)="addNewType()"
              text="Nowy typ zadania"
            ></dx-button>
          </div>
        </div>
        <div *ngSwitchCase="2">
          <app-filter-panel
            [deleteFilters]="deleteFilter"
            [openPanelFilters]="visibleAdvanceFilters"
            (onFilterSave)="onFilterSaved($event)"
            [dictionaryCode]="DynamicFilterDictionaryCode"
            [selectedTemplate]="
              localStorageData?.advancedFilter?.FilterUsersDefinitionId
            "
            [isPinned]="localStorageData?.advancedFilter?.IsPinned"
            (onTemplatePinChanged)="onTemplatePinChanged($event)"
            [tabActive]="
              localStorageData.sidePanel.isVisible &&
              localStorageData.sidePanel.selectedIndex == 1
            "
            [panelWidth]="localStorageData.sidePanel.width"
            [values]="
              localStorageData?.advancedFilter?.UserFilterDefinitionValues
            "
            [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
          >
          </app-filter-panel>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  >
  </app-filter-panel>
</div>

<ng-container *ngIf="loadChildComponent">
  <app-new-task
    [isVisible]="isSingleRecordVisible"
    [mode]="singleRecordMode"
    [statusCode]="newTaskStatus"
    [typeId]="newTaskType"
    [selectedId]="selectedTask"
    [taskTypes]="taskTypes"
    [componentNests]="singleRecordMode === 'show' ? null : componentNests"
    (onClosing)="onSingleRecordClosing()"
    [readOnly]="readOnly || singleRecordMode === 'show'"
    (onInserted)="onSingleRecordInserted()"
    (shouldReloadTypes)="onShouldReloadTypes()"
  >
  </app-new-task>

  <app-confirm-dialog
    [isVisible]="isDelete"
    (onRemoving)="showDeleteArticle()"
    (onClosing)="closeConfirm()"
  ></app-confirm-dialog>

  <app-add-task-types
    [isVisible]="addTaskType"
    (onClosing)="addTaskType = false"
    (onInserted)="onInsertedTaskType()"
    title="{{ 'calendar.addTaskType' | translate }}"
    mode="add"
  >
  </app-add-task-types>

  <app-column-chooser
    [rawColumns]="groups"
    [(visible)]="columnsChooserVisible"
    (columnsChanged)="onColumnsChanged()"
  ></app-column-chooser>
</ng-container>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'tasks-kanban'"
></app-user-guide>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="isSingleRecordVisible"
>
</wapro-keyboard-shortcuts>

<app-record-card
  *ngIf="isRecordCardVisible"
  [selectedTab]="null"
  [cardMode]="recordCardMode"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

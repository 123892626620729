import { PanelComponent } from './../menu-wapro/panel/panel.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from './orders/orders.component';
import { NestResolverService } from '../nest-resolver.service';
import { NestDeactivateGuard } from '../can-deactive-guard';
import { OrdersKanbanComponent } from './orders-kanban/orders-kanban.component';
import { TasksComponent } from './tasks/tasks.component';
import { TaskCanbanComponent } from './task-canban/task-canban.component';
import { TaskCalendarComponent } from './task-calendar/task-calendar.component';
import { OffersComponent } from './offers/offers.component';
import { CrmPackagesGuard, isPermission } from '../auth.guard';
//import { EmailComponent } from './email/email.component';

const routes: Routes = [
  {
    path: '',
    component: PanelComponent,
  },
  {
    path: 'service-orders',
    component: OrdersComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['SerwisZlec'] },
  },
  {
    path: 'parcels',
    loadComponent: () =>
      import('./crm-deliveries/crm-deliveries.component').then(
        (m) => m.CrmDeliveriesComponent
      ),
    canActivate: [isPermission],
    data: { permissions: ['Paczki'] },
  },
  {
    path: 'parcels/:PackageId',
    loadComponent: () =>
      import('./crm-deliveries/crm-deliveries.component').then(
        (m) => m.CrmDeliveriesComponent
      ),
    canActivate: [isPermission],
    data: { permissions: ['Paczki'] },
  },
  {
    path: 'parcels/:PackageId/:mode',
    loadComponent: () =>
      import('./crm-deliveries/crm-deliveries.component').then(
        (m) => m.CrmDeliveriesComponent
      ),
    canActivate: [isPermission, CrmPackagesGuard],
    data: { permissions: ['Paczki'] },
  },
  {
    path: 'service-orders-kanban',
    component: OrdersKanbanComponent,
    resolve: { nests: NestResolverService },
    canActivate: [isPermission],
    data: { permissions: ['SerwisZlec'] },
  },
  {
    path: 'task-calendars',
    component: TaskCalendarComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['CrMKalendarz'] },
  },
  {
    path: 'crm-tasks',
    component: TasksComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['CrMZadania'] },
  },
  {
    path: 'crm-kanban',
    component: TaskCanbanComponent,
    resolve: { nests: NestResolverService },
    canActivate: [isPermission],
    data: { permissions: ['CrMZadania'] },
  },
  {
    path: 'offers',
    component: OffersComponent,
    resolve: { nests: NestResolverService },
    canDeactivate: [NestDeactivateGuard],
    canActivate: [isPermission],
    data: { permissions: ['Ofe'] },
  },
  //{
  //  path: 'email', component: EmailComponent, resolve: {nests: NestResolverService}, canDeactivate: [NestDeactivateGuard]
  //},
  {
    path: 'email',
    loadComponent: () =>
      import('./email/email.component').then((m) => m.EmailComponent),
    canActivate: [isPermission],
    data: { permissions: ['Serwerpocztowy'] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class routing {}

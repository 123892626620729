import { DatePipe } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { AppServices } from "../../../app-services.service";
import { EventService } from "../../../event.service";

@Component({
    selector: "app-tax-moment",
    templateUrl: "./tax-moment.component.html",
    styleUrls: ["./tax-moment.component.scss"],
    inputs: ["readOnly", "isVisible", "title", "documentData"],
    standalone: false
})
export class TaxMomentComponent implements OnInit, AfterViewInit {
  @Output() onClosing = new EventEmitter();
  readOnly;
  isVisible;
  title;
  documentData;

  unicalGuid;
  widthWindow = 900;
  heightWindow = 400;
  maxHeight;
  maxWidth;

  dataSource = [];
  selectedRowKeys;

  shortcuts: ShortcutInput[] = [];
  isEditTaxMoment: boolean = false;
  focusedSelected = [];

  editedArray = [];

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit(): void {
    this.shortcuts.push(
      {
        key: "esc",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.closeWindow();
        },
        preventDefault: true,
      },
      {
        key: "F10",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  onRowDblClick = () => {
    this.isEditTaxMoment = true;
    this.cd.detectChanges();
  };

  closeWindow() {
    this.onClosing.emit(true);
  }

  onFocusedRowChanged = (e) => {
    this.focusedSelected = [e.row.data];
  };

  getTaxMoment = () => {
    if (this.documentData.DateOfRegistration == null)
      this.documentData.DateOfRegistration =
        this.documentData.DateOfInvoiceDocument;
    this.appService
      .getAuth(
        `invoices/documents/${
          this.documentData.InvoiceDocumentId
        }/taxMoment?InvoiceDocumentId=${
          this.documentData.InvoiceDocumentId
        }&DateOfInvoiceDocument=${new DatePipe("en-US").transform(
          this.documentData.DateOfInvoiceDocument,
          "yyyy-MM-dd"
        )}&DateOfRegistration=${new DatePipe("en-US").transform(
          this.documentData.DateOfRegistration,
          "yyyy-MM-dd"
        )}&CurrencyCode=${
          this.documentData.CurrencyCode
        }&DateOfSell=${new DatePipe("en-US").transform(
          this.documentData.DateOfSell,
          "yyyy-MM-dd"
        )}&DateOfReceived=${new DatePipe("en-US").transform(
          this.documentData.DateOfReceived,
          "yyyy-MM-dd"
        )}`
      )
      .subscribe((res) => {
        this.dataSource = res;
        this.cd.detectChanges();
      });
  };

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    if (this.editedArray.length > 0) {
      this.appService
        .putAuth(
          `invoices/documents/${this.documentData.InvoiceDocumentId}/taxMoment`,
          this.editedArray
        )
        .subscribe(
          () => {
            this.isVisible = false;
            this.cd.detectChanges();
            this.editedArray = [];
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    } else {
      this.isVisible = false;
      this.cd.detectChanges();
    }
  };

  onEditedTaxMoment = (e) => {
    let editRow = this.dataSource.find(
      (field) => field.WarehouseDocumentId == e.WarehouseDocumentId
    );
    editRow.DateOfTax = e.DateOfTax;
    e.InvoiceDocumentId = this.documentData.InvoiceDocumentId;
    if (this.documentData.CurrencyCode == "") {
      e.CurrencyCode = "PLN";
      e.DateOfCurrencyTax = e.DateOfTax;
    }
    else{
      editRow.CurrencyFactor = e.CurrencyFactor;
    }
    this.editedArray.push(e);
  };
}

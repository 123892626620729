import {
  ChangeDetectionStrategy,
  Component,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { ServiceDocumentConnectType } from './service-to-document.model';
import { AppServices } from 'src/app/app-services.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { EventService } from 'src/app/event.service';
import DataSource from 'devextreme/data/data_source';
import { environment } from 'src/environments/environment';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import { FocusedRowChangedEvent } from 'devextreme/ui/data_grid';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-connect-service-to-document',
    templateUrl: './connect-service-to-document.component.html',
    styleUrls: ['./connect-service-to-document.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class ConnectServiceToDocumentComponent {
  readonly pageSize = 50;
  readonly heightGrid = 400;
  @Input() customerId: number = null;
  @Input() documentType: ServiceDocumentConnectType = 'invoice';
  @Input() serviceOrderId: number = null;
  @Output() onClosed: EventEmitter<void> = new EventEmitter();
  @Output() onSaved: EventEmitter<void> = new EventEmitter();
  @ViewChild('gridBank') gridBank: DxDataGridComponent
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  dataSource: DataSource = null;
  focusedRowKey: any = null; // dodac typ
  selectedId: number = null;
  constructor(private appServices: AppServices, public event: EventService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.documentType?.currentValue) {
      this.getDataSource();
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'Enter',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.confirmDocument();
        },
        preventDefault: true,
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosed.emit();
        },
        preventDefault: true,
      }
    );
  }

  confirmDocument() {
    if (!this.selectedId) return;

    this.appServices
      .postAuth(
        `services/additionalOperations/${this.serviceOrderId}/${this.documentType}Document?${this.documentType}DocumentId=${this.selectedId}`,
        null
      )
      .subscribe({
        next: () => {
          this.onSaved.emit();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  onRowDblClick() {
    this.confirmDocument();
  }

  onFocusedRowChanged(e: FocusedRowChangedEvent) {
    if (!e?.row?.data) {
      this.selectedId = null;
      return;
    }
    this.selectedId =
      e.row.data[
        this.documentType === 'invoice'
          ? 'InvoiceDocumentId'
          : 'WarehouseDocumentId'
      ];
  }

  getDataSource() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key:
          this.documentType === 'invoice'
            ? 'InvoiceDocumentId'
            : 'WarehouseDocumentId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}services/additionalOperations/${this.serviceOrderId}/${this.documentType}Document?customerId=${this.customerId}`,
        onAjaxError: this.event.onAjaxDataSourceError,
      }),
      pageSize: this.pageSize,
      paginate: true,
    });
  }
}

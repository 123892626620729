<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="'deliveries.exemplaryCodes' | translate"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="
    event.onShownPopUp();
    getData();
    searchValue = '';
    event.setFocus(TextSearch)
  "
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <!-- <dx-button text="{{'buttons.choose' |translate}}" type="success" style="margin-right: 6px;"
        [id]="'choosed'+unicalGuid" *ngIf="!readOnly" [disabled]="creadOnly"></dx-button> -->

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="com-md-12" style="padding-left: 15px; padding-right: 15px">
          <dx-button
            class="btn-dx-top-menu-folder"
            stylingMode="text"
            type="default"
            icon="icon absui-icon--add-circle"
            [id]="'btn-add' + unicalGuid"
            [disabled]="readOnly"
            (onClick)="addCode()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu-folder"
            stylingMode="text"
            type="default"
            icon="icon absui-icon--mode-edit"
            [id]="'btn-edit' + unicalGuid"
            (onClick)="editCode()"
            [disabled]="readOnly || focusedSelected.length != 1"
          >
          </dx-button>

          <dx-button
            icon="icon absui-icon--highlight-off"
            stylingMode="text"
            type="default"
            [id]="'btn-delete' + unicalGuid"
            class="btn-dx-top-menu-folder"
            [disabled]="focusedSelected.length == 0 || readOnly"
            (onClick)="isDelete()"
          >
            <!-- [DISABLED-text]="'buttons.delete' | translate" -->
          </dx-button>

          <div
            class="scaner-container"
            style="position: relative; float: right"
          >
            <dx-select-box
              [id]="'select-mode' + unicalGuid"
              class="scaner-mode-select"
              [width]="90"
              style="
                margin-top: 2px;
                margin-left: 2px;
                margin-right: 2px;
                display: inline-block;
                border: none;
              "
              [dataSource]="modeSource"
              [(value)]="activeMode"
              valueExpr="value"
              [readOnly]="readOnly"
              displayExpr="label"
              itemTemplate="item"
              (onValueChanged)="onModeValueChanged()"
            >
              <div *dxTemplate="let data of 'item'" style="padding: 7px 9px">
                <i class="dx-icon icon icon-select" [ngClass]="data.icon"></i>
                <div class="select-label">{{ data.label }}</div>
              </div>
            </dx-select-box>

            <div class="custom-scaner-wrapper">
              <dx-button
                [id]="'filter' + unicalGuid"
                [icon]="getIcon()"
                stylingMode="text"
                type="default"
                [disabled]="readOnly"
                class="left-inside-scaner-btn"
                (onClick)="toggleSelectBox()"
              ></dx-button>

              <dx-text-box
                [showClearButton]="false"
                class="inline inline-scaner-search-text-box"
                (keyup.enter)="onEnterKey()"
                valueChangeEvent="keyup"
                [(value)]="searchValue"
                [readOnly]="readOnly"
              >
              </dx-text-box>
            </div>
          </div>

          <br /><br />

          <!-- <dx-text-box
            [(value)]="searchValue"
            style="margin-top: 6px; margin-bottom: 6px; display: inline-block"
            (onEnterKey)="onEnterKey()"
            (onKeyUp)="onKeyDownTextSearch($event)"
            #TextSearch
          >
          </dx-text-box> -->

          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="405"
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            (onRowDblClick)="onRowDblClick()"
            (onRowClick)="onRowClick($event)"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            (onSelectionChanged)="onSelectionChanged()"
            (onKeyDown)="onKeyDown($event)"
            [focusedRowEnabled]="true"
            #gridBank
            [hoverStateEnabled]="true"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="20" [enabled]="true"></dxo-paging>
            <dxo-selection
              [mode]="'multiple'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxi-column
              [caption]="'form-commercial-operation.grid.Lp' | translate"
              width="32"
              [allowSorting]="false"
              cellTemplate="lpTemplate"
              [allowFiltering]="false"
              dataField="SpecimenCodeId"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'lpTemplate'; let index = i">
              {{ data.rowIndex + 1 }}
            </div>

            <dxi-column
              [caption]="'constractors.sampleBarcode' | translate"
              [allowSorting]="false"
              [allowFiltering]="false"
              dataField="SpecimenCode"
            >
            </dxi-column>
          </dx-data-grid>

          <div class="count-container" style="margin-top: 12px; height: auto">
            <div class="row">
              <div class="col-md-2">
                <!-- <p *ngIf="isCorrect" class="summary-count-container">
                  {{ "inventory.summarizedInventory" | translate }}
                </p> -->
              </div>

              <div class="col-md-10 text-right">
                <div class="contener-pay">
                  <p class="correction-label">
                    {{ Amount }}
                  </p>

                  <p class="correction-label">{{ dataSource.length }}</p>
                </div>

                <div class="contener-pay" style="border-left: none">
                  <p class="correction-label">
                    {{
                      "constractors.numberOfPossibleCodesToEnter" | translate
                    }}:
                  </p>
                  <p class="correction-label">
                    {{ "constractors.numberOfCodesEntered" | translate }}:
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
  #keyboardShortcuts
>
</wapro-keyboard-shortcuts>

<app-add-exemplary-codes
  [isVisible]="addRow"
  [mode]="mode"
  [Amount]="Amount"
  (onClosing)="onClosingAdd()"
  (onInserted)="onInserted($event)"
  [WarehouseDocumentPositionId]="WarehouseDocumentPositionId"
  [OrderDocumentPositionId]="OrderDocumentPositionId"
  [SadDocumentPositionId]="SadDocumentPositionId"
  [ProdDocumentPositionId]="ProdDocumentPositionId"
  [OfferDocumentPositionId]="OfferDocumentPositionId"
  [selected]="focusedSelected"
>
</app-add-exemplary-codes>

<app-confirm-dialog
  [isVisible]="true"
  *ngIf="isRowDelete"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
  [confirmText]="
    selectedRows.length > 1 ? 'confirmDeleteMultiple' : 'confirmDelete'
  "
></app-confirm-dialog>

<dx-context-menu
  [dataSource]="contextMenu"
  [width]="200"
  [target]="'#grid' + unicalGuid"
  (onItemClick)="contextMenuClick($event)"
  (onItemRendered)="event.onItemRendered($event)"
>
</dx-context-menu>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-cash-back-window',
    templateUrl: './cash-back-window.component.html',
    styleUrls: ['./cash-back-window.component.scss'],
    inputs: ['readOnly', 'isVisible', 'title', 'cashBackValue'],
    standalone: false
})
export class CashBackWindowComponent implements OnInit, OnChanges {
  @Output() onClosing = new EventEmitter();
  @Output() onValueChanged = new EventEmitter();
  @ViewChild('firstFocus') firstFocus;
  readOnly;
  isVisible;
  title;

  unicalGuid;
  widthWindow = 450;
  heightWindow = 120;
  maxHeight;
  maxWidth;

  shortcuts: ShortcutInput[] = [];
  payment: Number = 0;
  change: Number = 0;
  timerWarehouse = 100;
  myTimerWarehouse;
  cashBackValue;
  amountCashBack = 0;

  constructor(
    public translate: TranslateService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef,
    public event: EventService,
    public appService: AppServices
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.maxHeight = window.innerHeight;
    this.maxWidth = window.innerWidth;
  }

  ngOnInit(): void {
    if (!this.readOnly) this.event.readOnly;
  }

  ngOnChanges(): void {
    this.amountCashBack = this.cashBackValue;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit(): void {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.closeWindow();
      },
      preventDefault: true,
    });
  }

  closeWindow() {
    this.amountCashBack = this.cashBackValue;
    this.onClosing.emit(true);
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  onSave = () => {
    this.onValueChanged.emit(this.amountCashBack);
  };
}

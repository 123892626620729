<dx-popup
  [width]="widthWindow"
  height="560px"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'offerDocumentStatus.statusHistory' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange()"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'close' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#close' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="panels-flex-container">
          <!--  [ngClass]="{'without_search_panel': dropDownBoxMode, 'with_search_panel': !dropDownBoxMode}"  -->
          <div class="with_search_panel" #rightPanel>
            <div class="row">
              <div class="col-md-12">
                <dx-data-grid
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [id]="'offerHistory' + unicalGuid"
                  [wordWrapEnabled]="false"
                  [(focusedRowIndex)]="focusedRowIndex"
                  (onFocusedRowChanged)="onFocusedRowChanged($event)"
                  style="
                    width: 100%;
                    margin-bottom: 10px;
                    margin-top: 10px;
                    min-height: 300px;
                  "
                  shortcut
                  [focusedRowEnabled]="true"
                  [height]="heightGrid"
                  [dataSource]="statusHistory"
                >
                  <dxo-load-panel [enabled]="false"></dxo-load-panel>
                  <dxo-sorting enabled="false"></dxo-sorting>

                  <dxi-column
                    dataField="UserName"
                    width="100"
                    caption="{{ 'users.userName' | translate }}"
                  ></dxi-column>
                  <dxi-column
                    dataField="Date"
                    width="100"
                    caption="{{ 'form-financial-document.date' | translate }}"
                    cellTemplate="dataTemplate"
                  ></dxi-column>
                  <dxi-column
                    dataField="EmployeeName"
                    caption="{{ 'worker' | translate }}"
                    width="100"
                  ></dxi-column>
                  <dxi-column
                    dataField="DocumentStatusCodeBefore"
                    caption="{{ 'offerDocumentStatus.from' | translate }}"
                    width="50"
                  ></dxi-column>
                  <dxi-column
                    dataField="DocumentStatusCodeAfter"
                    caption="{{ 'offerDocumentStatus.to' | translate }}"
                    width="50"
                  ></dxi-column>
                  <dxi-column
                    dataField="DocumentStatusBefore"
                    caption="{{ 'status-type.statusBefore' | translate }}"
                    width="100"
                  ></dxi-column>
                  <dxi-column
                    dataField="DocumentStatusAfter"
                    caption="{{ 'status-type.statusAfter' | translate }}"
                    width="100"
                  ></dxi-column>

                  <div *dxTemplate="let cell of 'dataTemplate'">
                    {{
                      cell.value
                        | date
                          : "dd.MM.yyyy HH:mm"
                          : translate.currentLang || "pl"
                    }}
                  </div>
                </dx-data-grid>
              </div>
            </div>
            <div class="row">
              <div
                class="bottom-side-panel"
                mwlResizable
                (resizeEnd)="resizeEndBottom()"
                #bottomSidePanel
                (resizeStart)="onResizeStartBottom()"
                (resizing)="resizingBottom($event)"
              >
                <div
                  class="resize-handle-top"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  (mouseleave)="mouseLeaveDown()"
                  (mouseenter)="mouseEnterDown()"
                ></div>
                <div class="col-md-12 text-right resize-bottop-panel">
                  <i
                    class="icon bottom-panel-icon"
                    mwlResizeHandle
                    [resizeEdges]="{ top: true }"
                    [ngClass]="{
                      'absui-icon--arrow-drop-down bottom-icon-resize-down':
                        showBottomPanel,
                      'absui-icon--arrow-drop-up bottom-icon-resize-up':
                        !showBottomPanel
                    }"
                    (mousedown)="mousedown()"
                    (mouseup)="mouseup()"
                    (touchend)="mouseup()"
                    (touchstart)="mousedown()"
                  ></i>
                  <div
                    class="text-left bottom-panel-description"
                    [ngClass]="{ invisible: !showBottomPanel }"
                  >
                    {{ focusedElement?.Description }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="right-side-panel" #leftPanel>
            <!-- mwlResizable *ngIf="!dropDownBoxMode" (resizeStart)="onResizeStart()"
                    (resizing)="resizing($event)" (resizeEnd)="resizeEnd()" -->
            <!-- <div class="right-panel-btn">
                        <dx-button (mousedown)="mousedownSidePanel()" (mouseup)="mouseupSidePanel()" [icon]="getResizeIcon()"
                        class="btn-dx-right-filter" mwlResizeHandle [resizeEdges]="{ left: true }"></dx-button>
                    </div>
                    <div class="resize-handle-left" mwlResizeHandle [resizeEdges]="{ left: true }" (mouseenter)="mouseEnter()"
                        (mouseleave)="mouseLeave()"></div> -->

            <dx-tree-view
              #treeView
              [dataSource]="users"
              dataStructure="tree"
              keyExpr="AppUserId"
              style="height: auto"
              displayExpr="AppUserName"
              (onItemClick)="selectItemTreeview($event)"
            >
            </dx-tree-view>
          </div>
        </div>
      </div>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div></div>
      <wapro-keyboard-shortcuts
        [unicalGuid]="unicalGuid"
        [shortcuts]="shortcuts"
        [disabled]="!isVisible"
      >
      </wapro-keyboard-shortcuts>
    </dx-scroll-view>
  </div>
</dx-popup>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output, signal,
  SimpleChanges,
  ViewChild, WritableSignal,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import {AllowIn} from 'ng-keyboard-shortcuts';
import {CustomShortcutInput} from 'src/app/core/ng-keyboard-shortcuts/cutom-shortcut-input.model';
import {environment} from '../../../environments/environment';
import {AppServices} from '../../app-services.service';
import {EventService} from '../../event.service';
import {
  DocumentPositionVerificationParamters,
  DocumentPositionVerificationParamtersBase,
  IPositionVerification,
  VerificationParams,
} from './IPositionVerification.interface';
import {PackingSimulationComponent} from './packing-simulation/packing-simulation.component';
import {CustomWindowConfig, SingleRecordMode} from '../../event.model';

interface Product {
  Id: number;
  Name: string;
  Width: number;
  Height: number;
  Depth: number;
  Weight: number;
  DimensionUnit?: string;
  WeightUnit?: string;
  Factor?: number;
  Amount?: number;
  UnitId?: string;
}

type UsedWeightType = {
  usedWeight: number;
};

@Component({
    selector: 'app-position-veryfication',
    templateUrl: './position-veryfication.component.html',
    styleUrls: ['./position-veryfication.component.scss'],
    inputs: [
        'isVisible',
        'documentNumber',
        'documentId',
        'detalisDocument',
        'readOnly',
        'isOrderComponent',
        'typeDocument',
        'isBlocked',
    ],
    standalone: false
})


export class PositionVeryficationComponent implements OnInit {
  @ViewChild('packingSimulationComponent') packingSimulationComponent: PackingSimulationComponent;
  @ViewChild('correctAmountInput') correctAmountInput;
  @ViewChild('itemGrid') itemGrid;

  @Output() onClosing = new EventEmitter<boolean>();
  @Output() refreshList = new EventEmitter();

  usedWeight: WritableSignal<UsedWeightType> = signal({
    usedWeight: 0
  });
  usedVolume: WritableSignal<number> = signal(0);
  amountOfPlacedProducts: number = 0;
  fullScreen = true;
  widthWindow: any = '90%';
  heightWindow: any = '90%';
  isOrderComponent;
  isVisible;
  documentNumber;
  documentId;
  unicalGuid;
  showTooltipHelp: boolean = false;
  showTooltipHelpAmount: boolean = false;
  detalisDocument;
  readOnly;
  typeDocument: 'invoices' | 'orders' | 'warehouseDocuments';
  showPopupAnimationDuration = 300;
  isBlocked: boolean = false;

  filtrPositionList = [
    {label: 'Wg porządku dodawania', value: 'PositionId'},
    {value: 'ProductName', label: 'wg nazwy'},
    {value: 'IndexCatalogue', label: 'wg indeksu katalogowego'},
    {value: 'IndexTrading', label: 'wg indeksu handlowego'},
    {value: 'Location', label: 'wg lokalizacji'},
    {value: 'Weight', label: 'wg wagi'},
  ];
  valueCriteriaPosition = 'PositionId';
  filterValuePosition = '';

  shortcuts: CustomShortcutInput[] = [];
  localStorageData: CustomWindowConfig = {
    filter: {
      value: '',
      orderBy: 'Number',
      order: 'ASC',
    },
    bottomPanelFilter: {
      value: '',
      orderBy: 'IntrastatePositionId',
      order: 'ASC',
    },
    tree: [],
    columns: [],
    bottomPanelColumns: [],
    bottomPanel: {
      selectedIndex: 0,
      isVisible: false,
      height: 210,
    },
    mainPanelWidth: 'calc(100% - 300px)',
    sidePanel: {
      selectedIndex: 0,
      isVisible: true,
      width: 300,
    },
    windowSize: {
      isFullscreen: false,
      width: 0,
      height: 0,
    },
    params: [],
    skipUserGuide: false,
  };
  focusedSelected: IPositionVerification[] = [];
  dataSource;
  heightLeftPanel = 91;

  selectedPositionDocument: any[] = [];
  prevPhoto: string = '';

  VerifiedStatus = null;

  filterCriteriaStatus: any[] = [
    {value: -1, label: 'wszystkie'},
    {value: -2, label: 'niezweryfikowane'},
    {value: 1, label: 'częściowe'},
    {value: 2, label: 'całkowite'},
    {value: 3, label: 'nadwyżki'},
  ];

  totalCount = null;

  services = [
    {value: null, label: 'Nie'},
    {value: true, label: 'Tak'},
  ];
  isBuyText = 'Nie';
  IsShowServices: boolean = false;
  isSingleRecordVisible: boolean = false;
  isArticleVisible: boolean = false;
  object: DocumentPositionVerificationParamters = {
    ParentObjectId: null,
    ObjectId: null,
    Amount: 1,
    IsSurplus: false,
  };

  configDataSource = [
    {
      label: 'blockDocumentAfterVerification',
      name: 'blockDocumentAfterVerification',
      disabled: false,
      // permission: string,
    },
    {
      label: 'printDefaultDocumentAfterVerification',
      name: 'printDefaultDocumentAfterVerification',
      disabled: false,
      // permission: string,
    },
    {
      label: 'addProductDuringVerification',
      name: 'addProductDuringVerification',
      disabled: false,
      // permission: string,
    },
    {
      label: 'deleteProductDuringVerification',
      name: 'deleteProductDuringVerification',
      disabled: false,
      // permission: string,
    },
  ];

  verificationParams: {
    blockDocumentAfterVerification: boolean;
    printDefaultDocumentAfterVerification: boolean;
    addProductDuringVerification?: boolean,
    deleteProductDuringVerification?: boolean
  } = {
    blockDocumentAfterVerification: false,
    printDefaultDocumentAfterVerification: false,
    addProductDuringVerification: false,
    deleteProductDuringVerification: false,
  };

  dropDownOptions: {
    width: number;
    minWidth: number;
    wrapperAttr?: { width?: number };
  };
  isCancelAllDialogVisible: boolean;
  changesMade: boolean;
  heightGrid = 257;
  selectedTab = 0;
  selectedPackaging = {
    Width: 0,
    Height: 0,
    Depth: 0,
    Thickness: 0,
    MaxWeight: 0,
    Name: '',
    CompanyId: 0,
    PackagingId: 0,
  };
  addProductDuringVerification: WritableSignal<boolean> = signal(false);

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
    this.widthWindow = this.event.setWidthPopUp();
    this.translate
      .get('offers.byAddOrder')
      .subscribe((text) => (this.filtrPositionList[0].label = text));
    this.translate
      .get('warehouseDocument.byName')
      .subscribe((text) => (this.filtrPositionList[1].label = text));
    this.translate
      .get('warehouseDocument.accordingToTheCatalogIndex')
      .subscribe((text) => (this.filtrPositionList[2].label = text));
    this.translate
      .get('warehouseDocument.accordingToTheTradeIndex')
      .subscribe((text) => (this.filtrPositionList[3].label = text));
    this.translate
      .get('warehouseDocument.byLocation')
      .subscribe((text) => (this.filtrPositionList[4].label = text));
    this.translate
      .get('offers.byWeight')
      .subscribe((text) => (this.filtrPositionList[5].label = text));
    this.translate
      .get('blockDocumentAfterVerification')
      .subscribe((text) => (this.configDataSource[0].label = text));
    this.translate
      .get('printDefaultDocumentAfterVerification')
      .subscribe((text) => (this.configDataSource[1].label = text));
    this.translate
      .get('addProductDuringVerification')
      .subscribe((text) => (this.configDataSource[2].label = text));
    this.dropDownOptions = {
      width: 390,
      minWidth: 390,
      wrapperAttr: {width: 320},
    };

    this.verificationParams = {
      blockDocumentAfterVerification:
        this.event.blockDocumentAfterVerification(),
      printDefaultDocumentAfterVerification:
        this.event.printDefaultDocumentAfterVerification(),
      addProductDuringVerification: false,
      deleteProductDuringVerification: false,
    };
  }

  ngOnInit(): void {
    if (!this.readOnly) {
      this.readOnly = this.event.readOnly;
    }
    this.getPackingBox();

    this.getViewConfigurations();

    // this.getPackingBox();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isVisible?.currentValue) {
      this.createKeyboardShortcuts();

      //dla Rafałą:
      // if (this.event.returnEnvironmentInfo() == 'qa' || this.event.returnEnvironmentInfo() == 'dev') {
      //   this.getAPIPacking();
      // }



      // this.event.addKeyboardEventsListeners(this.shortcuts);
    }
  }

  getAPIPacking() {
    this.appService.getAuth(`orders/additionalOperations/${this.documentId}/positionVerificationRecomendedPackaging?ParentObjectId=${this.documentId}&DocumentKind=2`)
      .subscribe((res) => {
        console.log('res', res)
      }, error => {
        this.event.httpErrorNotification(error);
      });
  }

  emptyStateHeight;
  onResizeStartPopUp = (e) => {
    this.emptyStateHeight = e.height;
  };

  fullScreenChange(e: boolean) {

    if (e) {
      this.emptyStateHeight = window.innerHeight;
    } else {
      this.emptyStateHeight = this.heightWindow;
    }
    if (this.emptyStateHeight === '90%') {
      this.emptyStateHeight = window.innerHeight * 0.9;
    }
  }

  get calculatedHeight(): string {
    return `calc(${this.emptyStateHeight}px - ${this.heightLeftPanel}px)`;
  }

  get calculatedWidthRightPane(): string {
    return `calc(${100}% - ${this.heightLeftPanel}px)`;
  }

  shortcutsCreated: boolean = false;

  createKeyboardShortcuts() {
    if (this.shortcutsCreated) {
      return;
    }
    this.shortcuts = [
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.onPopupClose();
        },
        preventDefault: true,
      },
      {
        key: 'F2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0) {
            this.editPosition();
          }
        },
        preventDefault: true,
      },
      {
        key: 'F3',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.onVerify();
          }
        },
        preventDefault: true,
      },
      {
        key: ['cmd + alt + w'],
        ctrlKey: true,
        altKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.onVerifyItem();
          }
        },
        preventDefault: true,
      },
      {
        key: ['cmd + alt + W'],
        ctrlKey: true,
        altKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.onVerifyItem();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + a'],
        ctrlKey: true,
        shiftKey: false,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.positionVerificationCancel();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + A'],
        ctrlKey: true,
        shiftKey: false,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.positionVerificationCancel();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + g'],
        ctrlKey: true,
        altKey: false,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.showCancelAllDialog();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + G'],
        ctrlKey: true,
        altKey: false,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.showCancelAllDialog();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + p'],
        ctrlKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.deliveresBox();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + P'],
        ctrlKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.deliveresBox();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + shift + B'],
        ctrlKey: true,
        shiftKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.blockDocument();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + shift + b'],
        ctrlKey: true,
        shiftKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.blockDocument();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + shift + o'],
        ctrlKey: true,
        shiftKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.blockDocument();
          }
        },
        preventDefault: true,
      },
      {
        key: ['ctrl + shift + O'],
        ctrlKey: true,
        shiftKey: true,
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.blockDocument();
          }
        },
        preventDefault: true,
      },
    ];
    this.shortcutsCreated = true;
    this.cd.detectChanges();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.heightWindow === '90%') {
        this.emptyStateHeight = window.innerHeight * 0.9;
      } else {
        this.emptyStateHeight = this.heightWindow;
      }

      if (this.fullScreen) {
        this.emptyStateHeight = window.innerHeight;
      }

    });
    // this.createKeyboardShortcuts();
  }

  getViewConfigurations = () => {
    if (this.event.deviceType != 'mobile') {
      try {
        if (
          !localStorage.getItem('PositionVerificationComponent')) {
          this.itemGrid?.instance?.updateDimensions();

          this.appService
            .getAuth(`viewConfigurations/PositionVerificationComponent`)
            .subscribe((res) => {
              if (res.Configuration != null) {
                localStorage.setItem(
                  'PositionVerificationComponent',
                  res.Configuration
                );
                this.localStorageData = JSON.parse(res.Configuration);
              }
            });
        } else {
          this.localStorageData = JSON.parse(
            localStorage.getItem('PositionVerificationComponent')
          );
        }

        this.updateColumns();

      } catch (e) {
      }
    }
  };

  updateColumns() {
    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.itemGrid?.instance;
        if (!instance) {
          return;
        }

        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          instance.columnOption(col.caption, 'width', col.width);
          instance.columnOption(col.caption, 'fixed', col.fixed);
        });
        this.cd.detectChanges();
      }, 500);
    }
  }

  onValueChangedStatus = (e) => {
    if (e) {
      this.VerifiedStatus = e;
      if (this.VerifiedStatus == -2) {
        this.VerifiedStatus = 0;
      }
      if (this.VerifiedStatus == -1) {
        this.VerifiedStatus = null;
      }
      this.getPosition(this.documentId);
    }
  };

  onSingleRecordClosing = () => {
    this.isSingleRecordVisible = false;
    this.cd.detectChanges();
  };

  onSingleRecordInserted = () => {
    this.isSingleRecordVisible = false;
    this.cd.detectChanges();
  };

  onFocusedRowChanged = (e) => {
    this.prevPhoto = '';
    this.focusedSelected = [e.row?.data];

    this.cd.detectChanges();
    if (this.selectedTab == 1) {
      this.getPhoto();
    }
  };

  deliveriesBoxOrder() {
    this.appService
      .postAuth(
        `orders/additionalOperations/addPackage/${this.documentId}`,
        null
      )
      .subscribe((res) => {
        this.objectToDelivery = res;
        this.isSingleRecordVisible = true;
        this.cd.detectChanges();
      });
  }

  deliveriesBoxInvoice() {
    this.appService
      .postAuth(
        `invoices/additionalOperations/addPackage/${this.documentId}`,
        null
      )
      .subscribe((res) => {
        this.objectToDelivery = res;
        this.isSingleRecordVisible = true;
        this.cd.detectChanges();
      });
  }

  deliveriesBoxWarehouse() {
  }

  objectToDelivery;
  deliveresBox = () => {
    this.appService
      .postAuth(
        `${this.typeDocument}/additionalOperations/addPackage/${this.documentId}`,
        null
      )
      .subscribe((res) => {
        this.objectToDelivery = res;
        this.isSingleRecordVisible = true;
        this.cd.detectChanges();
      });
    // switch (this.typeDocument) {
    //   case 'orders':
    //     this.deliveriesBoxOrder();
    //     break;
    //   case 'invoices':
    //     this.deliveriesBoxInvoice();
    //     break;
    //   case 'warehouseDocuments':
    //     this.deliveriesBoxWarehouse();
    //     break;
    // }
  };

  getPhoto = () => {
    if (this.focusedSelected && this.focusedSelected[0]) {
      this.appService
        .getAuth(`products/${this.focusedSelected[0].IndexCatalogue}/pictures`)
        .subscribe((res) => {
          if (res.data.length > 0) {
            this.appService
              .getAuth(
                `products/${this.focusedSelected[0].IndexCatalogue}/pictures/${res.data[0].PictureId}`
              )
              .subscribe((res) => {
                this.prevPhoto =
                  'data:image/gif;base64,' + res.data[0].PictureBase64;
              });
          }
        });
    }
  };

  firstLoadPacking = true;
  onSelectionChangedTab = () => {
    if (this.selectedTab == 0) {
      // if (this.firstLoadPacking) {
      //   this.getPackingBox();
      //   this.firstLoadPacking = false;
      // }
    } else if (this.selectedTab == 1) {
      this.getPhoto();
    }
  };

  packaging = [];

  getPackingBox() {
    this.appService.getAuth(`products/packaging`).subscribe((res) => {
      this.packaging = res.data;
      this.selectedPackaging = this.packaging[0];

    }, (error) => {
      this.event.httpErrorNotification(error);
    });
  }

  orderby: string = 'PositionId';
  order: string = 'ASC'; // asc/desc
  onFilterPosition(e) {
    if (e.selectedItem) {
      this.orderby = e.selectedItem.value;
      this.valueCriteriaPosition = e.selectedItem.value;
    }
    this.filterValuePosition = e.filterValue;
    this.getPosition(this.documentId);
  }

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;

  // onContentReady(e) {
  //   e.component.focus(e.component.getRowElement(0));
  //   e.element.addEventListener('keyup', () => {
  //     this.isHold = false;
  //     clearTimeout(this.timerSel);
  //     this.timerSel = null;
  //   });
  // }

  onCorrectAmountReady() {
  }

  focusedRowIndex = -1;

  onKeyDown(e) {

    if (e.event.keyCode === 32) {
      e.event.preventDefault();

      const grid = e.component;

      const focusedRowKey = grid.option('focusedRowKey');
      const isRowSelected = grid.isRowSelected(focusedRowKey);
      if (isRowSelected) {
        grid.deselectRows([focusedRowKey]);
        this.toDeselect.push(focusedRowKey);
      } else {
        grid.selectRows([focusedRowKey], true);
        this.toSelect.push(focusedRowKey);
      }
      // this.focusedRowIndex = this.focusedRowIndex + 1;
      grid.repaint();
      this.isQuickPress = false;
    }

    if (e.event.key == 'F2') {
      e.event.preventDefault();
      if (!this.readOnly && this.focusedSelected.length > 0) {
        this.editPosition();
      }
    }

    if (
      (e.event.key == 'w' || e.event.key == 'W') &&
      e.event.ctrlKey &&
      e.event.altKey
    ) {
      e.event.preventDefault();
      if (!this.readOnly) {
        this.onVerifyItem();
      }
    }

    if ((e.event.key == 'a' || e.event.key == 'A') && e.event.ctrlKey) {
      e.event.preventDefault();
      if (!this.readOnly) {
        this.positionVerificationCancel();
      }
    }

    if ((e.event.key == 'g' || e.event.key == 'G') && e.event.ctrlKey) {
      e.event.preventDefault();
      if (!this.readOnly) {
        this.showCancelAllDialog();
      }
    }

    if ((e.event.key == 'p' || e.event.key == 'P') && e.event.ctrlKey) {
      e.event.preventDefault();
      if (!this.readOnly) {
        this.deliveresBox();
      }
    }

    if (
      (e.event.key == 'b' || e.event.key == 'B') &&
      e.event.ctrlKey &&
      e.event.shiftKey
    ) {
      e.event.preventDefault();
      if (!this.readOnly) {
        this.blockDocument();
      }
    }

    if (e.event.key == 'escape') {
      e.event.preventDefault();
      this.isVisible = false;
      this.onPopupClose();
    }

    if (e.event.key == 'F3') {
      e.event.preventDefault();
      if (!this.readOnly) {
        this.onVerify();
      }
    }
    if (this.focusedSelected.length > 0) {
      if (e.event.keyCode == '109') {
        if (this.dataSource._items[this.focusedRowIndex].AmountInBox >= 1) {
          this.dataSource._items[this.focusedRowIndex].AmountInBox = this.dataSource._items[this.focusedRowIndex].AmountInBox - 1;
          this.data = [...this.dataSource._items];
          setTimeout(() => {
            this.deletePositionVer('deleteOne');
            this.statusOfDoesntFitProducts = false;
            this.itemGrid?.instance.focus();
            this.cd.detectChanges();
          }, 10);
          this.itemGrid.instance.focus();
          this.cd.detectChanges();
        }

      }
      if (e.event.keyCode == '107') {
        this.dataSource._items[this.focusedRowIndex].AmountInBox = this.dataSource._items[this.focusedRowIndex].AmountInBox + 1;
        this.data = [...this.dataSource._items];
        setTimeout(() => {
          this.addProductWithoutVerification(false);
          this.itemGrid?.instance.focus();
          this.cd.detectChanges();
        }, 10);
        this.itemGrid.instance.focus();
        this.cd.detectChanges();
      }
    }

  }

  onCellDblClick = (e) => {
    if (e.rowIndex === -1) {
      return;
    }
    // this.editPosition();
  };

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {
      }
    }

    setTimeout(() => {
      this.localStorageData.columns.forEach((field, index) => {
        field.visibleIndex = index;
      });
    }, 0);
  };

  editPosition = () => {
    let amount =
      this.focusedSelected[0].Amount - this.focusedSelected[0].AmountVerified;
    this.correctAmount = amount < 1 ? 1 : amount;
    this.isCorrect = true;
    this.cd.detectChanges();
  };

  isQuickPress: boolean = true;
  toDeselect: any[] = [];
  toSelect = [];
  sendRequestTimer;
  onSelectionChanged = (e) => {
    this.toSelect.push(...e.currentSelectedRowKeys);
    this.toDeselect.push(...e.currentDeselectedRowKeys);

    this.toDeselect = this.event.removeDuplicates(this.toDeselect);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        this.event.selectRecords(
          `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerification/select`,
          this.toSelect
        );
        this.toSelect = [];
      }
      if (this.toDeselect.length) {
        this.event.selectRecords(
          `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerification/unselect`,
          this.toDeselect
        );
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  };

  onGetData = () => {
    this.appService
      .postAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerificationInitialization`,
        null
      )
      .subscribe({
        next: () => {
          this.getPosition(this.documentId);
          this.getVerifedCount();
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  };

  getPositionVerificationParams() {
    this.verificationParams = {
      addProductDuringVerification: JSON.parse(localStorage.getItem('PositionVerificationParams')).addProductDuringVerification,
      deleteProductDuringVerification: JSON.parse(localStorage.getItem('PositionVerificationParams')).deleteProductDuringVerification,
      blockDocumentAfterVerification: JSON.parse(localStorage.getItem('PositionVerificationParams')).blockDocumentAfterVerification,
      printDefaultDocumentAfterVerification: JSON.parse(localStorage.getItem('PositionVerificationParams')).printDefaultDocumentAfterVerification,
    };
  }

  quantityVerifed = 0;
  getVerifedCount = () => {
    this.appService
      .getAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerification?ParentObjectId=${this.documentId}&VerificationState=2`
      )
      .subscribe((res) => {
        this.quantityVerifed = res.data.length;
      });
    this.appService
      .getAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerification?ParentObjectId=${this.documentId}`
      )
      .subscribe((res) => {
        this.totalCount = res.totalCount;
      });
  };

  columnsChooserVisible = false;

  openColumnsChooser() {
    this.columnsChooserVisible = true;
  }

  correctAmount: number = 1;
  data = [];
  firstLoadPosition = true;
  getPosition = (id, deleteAmountPosition = false) => {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'Id',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}${this.typeDocument}/additionalOperations/${id}/positionVerification`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          data.forEach((el, index) => {
            el.lp = index + 1;
            const matchingItem = this.data.find(dataItem => dataItem.Id === el.Id);
            if (matchingItem) {
              el.AmountInBox = matchingItem.AmountInBox;
            }
          });

          if (deleteAmountPosition) {
            data.forEach((el, index) => {
              el.lp = index + 1;
              if (el.Id === this.focusedSelected[0].Id) {
                el.AmountInBox = 0;
              }
            });
          }

          if (this.firstLoadPosition) {
            this.firstLoadPosition = false;
            setTimeout(() => {
                this.focusedRowIndex = 0;
                this.onFocusedRowChanged({
                  row: {data: data[this.focusedRowIndex]},
                });
                this.itemGrid.instance.focus();
              }, 200
            );
          } else {
            setTimeout(() => {
                if (this.focusedRowIndex === -1) {
                  this.focusedRowIndex = 0;
                }
                this.onFocusedRowChanged({
                  row: {data: data[this.focusedRowIndex]},
                });
                this.itemGrid.instance.focus();
              }, 200
            );
          }

          this.cd.detectChanges();
        }
      }),
      reshapeOnPush: true,
    });
  };

  //left panle resizing
  widthLeftPanel = 385;
  widthPackingSimulator = 345;
  width = 345;
  tabRightVisible = true;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  resizing(event) {
    let width;
    width = this.widthLeftPanel - event.edges.left;
    if (width < 385) {
      width = 385;
    }
    if (width > 620) {
      width = 620;
    }
    this.leftPanel.nativeElement.style.width = `${width}px`;
    this.width = width;

    let rightWidth = width;
    this.rightPanel.nativeElement.style.width = `calc(100% - ${rightWidth}px)`;
    this.widthPackingSimulator = this.leftPanel.nativeElement.clientWidth;
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  isCorrect: boolean = false;

  resizeWindowBtn() {
    if (this.width > 21) {
      this.leftPanel.nativeElement.style.width = '10px';
      this.tabRightVisible = false;
      this.width = 21;
      this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
    } else {
      this.leftPanel.nativeElement.style.width = '385px';
      this.width = 385;
      this.widthPackingSimulator = 385;
      this.tabRightVisible = true;
      this.rightPanel.nativeElement.style.width = `calc(100% - 385px)`;
    }
  }

  async onVerify(add?) {
    if (this.selectedPositionDocument.length > 0) {
      this.object = {
        ParentObjectId: this.documentId,
        ObjectId: this.focusedSelected[0]?.PositionId,
        IsForSelected: true,
      };
      this.verify();
      return;
    }
    try {
      this.isCorrect = false;
      this.object = {
        ParentObjectId: this.documentId,
        ObjectId: this.focusedSelected[0]?.PositionId,
        // Amount: this.focusedSelected[0].AmountVerified + 1,
        Amount: add ? this.correctAmount : 1,
      };

      if (
        this.object.Amount + this.focusedSelected[0].AmountVerified >
        this.focusedSelected[0].Amount
      ) {
        this.isSetSurplus = true;
        this.cd.detectChanges();
        return;
      }

      this.verify();

      if (this.verificationParams.addProductDuringVerification) {
        this.addProductWithoutVerification(false);
      }
    } catch {
    }
  }

  async verify() {
    const rowIndexToFocusOnDataFetch = this.focusedRowIndex;
    this.appService
      .putAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerification`,
        this.object
      )
      .subscribe({
        next: async () => {
          this.changesMade = true;
          this.dataSource._items.forEach((el, index) => {
            if (el.Id === this.focusedSelected[0].Id) {
              this.dataSource._items[index].AmountVerified += this.object.Amount;

              if (this.dataSource._items[index].AmountVerified > this.dataSource._items[index].Amount) {
                this.dataSource._items[index].VerificationState = 3;
              } else if (this.dataSource._items[index].AmountVerified == this.dataSource._items[index].Amount) {
                this.dataSource._items[index].VerificationState = 2;
              } else if (this.dataSource._items[index].AmountVerified < this.dataSource._items[index].Amount) {
                this.dataSource._items[index].VerificationState = 1;
              } else if (this.dataSource._items[index].AmountVerified == 0) {
                this.dataSource._items[index].VerificationState = 0;
              }
              this.cd.detectChanges();
            }
          });

          // await this.dataSource.reload();
          this.focusedRowIndex = rowIndexToFocusOnDataFetch;
          let rowElem = this.itemGrid.instance.getRowElement(
            this.focusedRowIndex
          );
          this.itemGrid.instance.focus(rowElem);
          if (
            this.focusedSelected[0].AmountVerified ==
            this.focusedSelected[0].Amount &&
            this.focusedRowIndex < this.dataSource.items().length - 1
          ) {
            setTimeout(() => {
                this.focusedRowIndex++;
              }
            );
          }

          const allPositionsVerified: boolean =
            await this.areAllPositionsVerified();
          if (
            allPositionsVerified &&
            this.verificationParams.blockDocumentAfterVerification &&
            !this.isBlocked
          ) {
            this.blockDocument();
          }

          this.cd.detectChanges();

          // this.getVerifedCount();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  async areAllPositionsVerified() {
    const items = await this.dataSource.items();
    const totalCount = this.dataSource.totalCount();
    let verifiedCount = 0;
    items.forEach((item) => {
      if (item.VerificationState == 2) {
        verifiedCount++;
      }
    });
    return totalCount == verifiedCount;
  }

  onSetSurplus = () => {
    this.isSetSurplus = false;
    this.object.IsSurplus = true;

    this.verify();
    if (this.verificationParams.addProductDuringVerification) {
      this.addProductWithoutVerification(true);
    }
  };

  blockDocument = () => {
    if (this.typeDocument === 'warehouseDocuments') {
      this.appService
        .postAuth(
          `${this.typeDocument}/additionalOperations/blockUnblock?isBlocked=true&warehouseDocumentId=${this.documentId}`,
          null
        )
        .subscribe({
          next: () => {
            this.refreshList.emit(true);
            this.event.showNotification(
              'info',
              this.translate.instant(
                'form-commercial-operation.theDocumentHasBeenBlocked'
              )
            );
            this.isBlocked = true;
            this.cd.detectChanges();
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
      return;
    }

    this.appService
      .putAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/block`,
        null
      )
      .subscribe({
        next: () => {
          this.event.showNotification(
            'info',
            this.translate.instant(
              'form-commercial-operation.theDocumentHasBeenBlocked'
            )
          );
          this.isBlocked = true;
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  };

  unblockDocument = (msg?) => {
    if (this.typeDocument === 'warehouseDocuments') {
      this.appService
        .postAuth(
          `${this.typeDocument}/additionalOperations/blockUnblock?isBlocked=false&warehouseDocumentId=${this.documentId}`,
          null
        )
        .subscribe({
          next: () => {
            this.refreshList.emit(true);
            this.event.showNotification(
              'info',
              msg ||
              this.translate.instant(
                'form-commercial-operation.theDocumentHasBeenUnblocked'
              )
            );
            this.isBlocked = false;
            this.cd.detectChanges();
          },
          error: (error) => {
            this.event.httpErrorNotification(error);
          },
        });
      return;
    }
    this.appService
      .putAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/unblock`,
        null
      )
      .subscribe({
        next: () => {
          this.event.showNotification(
            'info',
            msg ||
            this.translate.instant(
              'form-commercial-operation.theDocumentHasBeenUnblocked'
            )
          );
          this.isBlocked = false;
          this.cd.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  };

  stopVerification: boolean = false;
  onVerifyItem = () => {
    if (this.selectedPositionDocument.length > 0) {
      this.object = {
        ParentObjectId: this.documentId,
        ObjectId: this.focusedSelected[0]?.PositionId,
        IsForSelected: true,
      };
      this.verify();
      return;
    }
    this.object = {
      ParentObjectId: this.documentId,
      ObjectId: this.focusedSelected[0].PositionId,
      Amount: this.focusedSelected[0].Amount,
    };

    if (this.focusedSelected[0].AmountVerified > 0) {
      this.isSetSurplus = true;
      this.cd.detectChanges();
      return;
    }

    this.verify();

    if (this.verificationParams.addProductDuringVerification) {
      this.addProductWithoutVerification(true);
    }

    // if (this.focusedSelected[0].Factor != 0 && this.focusedSelected[0].Factor != 1) {
    //   this.addProductWithoutVerification();
    // } else if (this.focusedSelected[0].Factor == 1) {
    //   const productAmount = this.focusedSelected[0].Amount;
    //   for (let i = 0; i < productAmount; i++) {
    //     if (this.stopVerification) {
    //       break;
    //     }
    //     this.addProductWithoutVerification(); // Wywołanie funkcji addProductWithoutVerification()
    //   }
    // }
  };

  deletePosiotionsPlacedProducts = 'deleteAll';
  positionVerificationCancel = () => {
    let params: DocumentPositionVerificationParamtersBase = {
      ParentObjectId: this.documentId,
      ObjectId: this.focusedSelected[0].PositionId,
      IsForSelected: false,
    };

    if (this.selectedPositionDocument.length > 0) {
      params.ObjectId = this.focusedSelected[0]?.PositionId;
      params.IsForSelected = true;
    }
    this.cancel(params);
    if (this.verificationParams.deleteProductDuringVerification && this.amountOfPlacedProducts >= 1) {
      this.deletePositionVer('deleteAll');
      setTimeout(() => {
        this.getPosition(this.documentId, true);
      });
    }
  };

  cancel(params: DocumentPositionVerificationParamtersBase) {
    this.appService
      .putAuth(
        `${this.typeDocument}/additionalOperations/${this.documentId}/positionVerificationCancel`,
        params
      )
      .subscribe({
        next: async (res) => {
          if (this.isBlocked) {
            this.unblockDocument(res.MessageContent);
          }
          this.changesMade = true;
          const index = this.focusedRowIndex;
          await this.dataSource.reload();
          this.focusedRowIndex = index;
          this.getVerifedCount();
          this.cd.detectChanges();
          // await this.getPosition(this.documentId);
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  showCancelAllDialog = () => {
    this.isCancelAllDialogVisible = true;
    this.cd.detectChanges();
  };

  positionVerificationCancelAll = () => {
    const params: DocumentPositionVerificationParamtersBase = {
      ParentObjectId: this.documentId,
    };
    this.isCancelAllDialogVisible = false;
    this.cancel(params);
    if (this.verificationParams.deleteProductDuringVerification && this.amountOfPlacedProducts >= 1) {
      this.deleteAll();
    }
  };

  isSetSurplus: boolean = false;

  getLoadParams() {
    let obj: any = {};
    (obj.OrderBy = this.orderby), //this.valueCriteria[0].toLowerCase() + this.valueCriteria.substr(1),//
      (obj.Order = this.order);

    obj.ParentObjectId = this.documentId;

    if (this.VerifiedStatus || this.VerifiedStatus == 0) {
      obj.VerificationState = this.VerifiedStatus;
    }

    obj.IsShowServices = this.IsShowServices;

    switch (this.valueCriteriaPosition) {
      case 'ProductName':
        obj.ProductName = this.filterValuePosition;
        break;
      case 'IndexCatalogue':
        obj.IndexCatalogue = this.filterValuePosition;
        break;
      case 'IndexTrading':
        obj.IndexTrading = this.filterValuePosition;
        break;
      case 'Location':
        obj.Location = this.filterValuePosition;
        break;
      case 'Weight':
        obj.Weight = this.filterValuePosition;
        break;
    }
    return obj;
  }

  onCancelAllClosing() {
    this.isCancelAllDialogVisible = false;
    this.cd.detectChanges();
    setTimeout(() => {
      this.itemGrid.instance.focus();
    }, 100);
  }

  onValueChangedPriceFilter = (e) => {
    if (e) {
      this.IsShowServices = e;
    } else {
      this.IsShowServices = false;
    }
    this.getPosition(this.documentId);
  };

  visibleChange = (e) => {
    if (!e) {
      this.onPopupClose();
    }
    this.cd.detectChanges();
  };

  async onPopupClose() {
    let printAfterVerification: boolean = false;
    const allPositionsVerified: boolean = await this.areAllPositionsVerified();
    if (
      this.changesMade &&
      allPositionsVerified &&
      this.verificationParams.printDefaultDocumentAfterVerification
    ) {
      printAfterVerification = true;
    }
    this.onClosing.emit(printAfterVerification);
  }

  sortingIcons: any = {
    asc: [],
    desc: [],
  };

  onContentReady(e) {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });

    if (this.event.deviceType == 'mobile') {
      e.component.columnOption('command:select', 'visibleIndex', 99);
      e.component.updateDimensions();
    }

    this.sortingIcons.asc = document.querySelectorAll(
      '.header-sort-icon.arr-up'
    );
    this.sortingIcons.desc = document.querySelectorAll(
      '.header-sort-icon.arr-down'
    );
  }

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.orderby) {
      this.orderby = orderBy;
    } else {
      this.switchOrder();
    }
    this.cd.detectChanges();
    this.getPosition(this.documentId);
  };

  keyupEnter() {
  }

  onFocusOut() {
  }

  onDropdownConfigChanged(e: VerificationParams) {
    this.event.onVerificationParamsChanged(e);
    this.verificationParams[e.name] = e.value;
    localStorage.setItem('PositionVerificationParams', JSON.stringify(this.verificationParams));
  }

  onSetSurplusClosing() {
    this.isSetSurplus = false;
    this.cd.detectChanges();
    setTimeout(() => {
      this.itemGrid.instance.focus();
    }, 100);
  }

  onCorrectSave = (e) => {
    if (!e) {
      return;
    }
    this.isCorrect = false;
    this.correctAmount = e;
    this.onVerify(true);
    this.cd.detectChanges();
  };
  onCorrectClosing = (e) => {
    if (!e) {
      return;
    }
    this.isCorrect = false;
    setTimeout(() => {
      this.itemGrid?.instance?.focus();
      this.cd.detectChanges();
    }, 100);
  };

  onChosenBox(e: any) {
    // this.notifiedProductIds.clear();
    this.dataSource._items.forEach((el) => {
      el.AmountInBox = 0;
    });
    this.data = [...this.dataSource._items];
    this.dataSource.reload();
    this.selectedPackaging = e;
    // this.cd.detectChanges();
  }

  selectedProduct: Product =
    {
      Id: 0,
      Name: '',
      Width: 0,
      Height: 0,
      Depth: 0,
      Weight: 0,
      Amount: 0,
      UnitId: ''
    };
  private notifiedProductIds: Set<number> = new Set<number>();
  addFactorUnitProduct = false;

  addProductWithoutVerification(addAllAmount = false) {
    this.selectedTab = 0;

    if (this.focusedSelected[0].Width === 0 || this.focusedSelected[0].Height === 0 || this.focusedSelected[0].Depth === 0) {
      this.event.showNotification(
        'error',
        this.translate.instant(
          'articles.missingDimensionsAlert'
        )
      );
      this.dataSource._items.forEach((el) => {
        if (el.Id === this.focusedSelected[0].Id && el.AmountInBox >= 0) {
          el.AmountInBox -= 1;
        }
      });
      this.data = [...this.dataSource._items];
      this.itemGrid.instance.focus();
      return;
    }

    if (!this.focusedSelected[0].Weight) {
      if (!this.notifiedProductIds.has(this.focusedSelected[0].PositionId)) {
        this.event.showNotification(
          'info',
          this.translate.instant(
            'articles.warningArticlesWithoutWeight'
          )
        );
        this.notifiedProductIds.add(this.focusedSelected[0].PositionId);
        setTimeout(() => {
          this.notifiedProductIds.clear();
        }, 300 * 60 * 1000);
      }
    }
    this.selectedProduct = this.checkAndChangeDimensionsUnit(this.focusedSelected[0]);
    this.selectedProduct = this.checkAndChangeWeightUnit(this.selectedProduct);


    if (this.selectedProduct.Weight != 0 && this.selectedProduct.Factor != 1 && this.selectedProduct.Factor != 0) {
      this.selectedProduct.Weight = this.selectedProduct.Weight * this.selectedProduct.Factor * this.selectedProduct.Amount;
    }
    if ((this.selectedProduct.Factor != 1 && this.selectedProduct.Factor != 0) || addAllAmount) {
      this.selectedProduct.Width = this.selectedProduct.Amount * this.selectedProduct.Factor * this.selectedProduct.Width;
      this.selectedProduct.Weight = this.selectedProduct.Weight * this.selectedProduct.Factor * this.selectedProduct.Amount;


      // this.selectedProduct.Height = this.selectedProduct.Amount / 1000 * this.selectedProduct.Factor * this.selectedProduct.Height;
      // this.selectedProduct.Depth = this.selectedProduct.Amount / 1000 * this.selectedProduct.Factor * this.selectedProduct.Depth;
    }

    this.addFactorUnitProduct = this.selectedProduct.Factor != 1 && this.selectedProduct.Factor != 0;
    this.addAllProducts = addAllAmount;

    // if (this.verificationParams.addProductDuringVerification && this.selectedProduct.Factor == 1) {
    //   const productAmount = this.selectedProduct.Amount;
    //   if (productAmount > 1) {
    //     this.addAllProducts = true
    //   }
    // }

    this.cd.detectChanges();

  }

  addAllProducts = false;
  deleteLastPlacedProduct = false;
  addFactorUnitProductConfirm = false;

  deleteProductWithoutVerification() {
    this.deleteLastPlacedProduct = true;
  }

  checkAndChangeDimensionsUnit(product): any {
    const dimensionsUnit = product.DimensionUnit ? product.DimensionUnit : 'cm';
    switch (dimensionsUnit) {
      case 'cm':
        return {
          Id: product.Id,
          Amount: product.Amount,
          Name: product.ProductName,
          Width: product.Width,
          Height: product.Height,
          Depth: product.Depth,
          Weight: product.Weight,
          DimensionUnit: 'cm',
          WeightUnit: product.WeightUnit,
          Factor: product.Factor,
          UnitId: product.UnitId
        };
      case 'mm':
        return {
          Id: product.Id,
          Amount: product.Amount,
          Name: product.ProductName,
          Width: product.Width / 10,
          Height: product.Height / 10,
          Depth: product.Depth / 10,
          Weight: product.Weight,
          WeightUnit: product.WeightUnit,
          Factor: product.Factor,
          UnitId: product.UnitId
        };
      case 'm':
        return {
          Id: product.Id,
          Amount: product.Amount,
          Name: product.ProductName,
          Width: product.Width * 100,
          Height: product.Height * 100,
          Depth: product.Depth * 100,
          Weight: product.Weight,
          WeightUnit: product.WeightUnit,
          Factor: product.Factor,
          UnitId: product.UnitId
        };
      case 'dm':
        return {
          Id: product.Id,
          Amount: product.Amount,
          Name: product.ProductName,
          Width: product.Width * 10,
          Height: product.Height * 10,
          Depth: product.Depth * 10,
          Weight: product.Weight,
          WeightUnit: product.WeightUnit,
          Factor: product.Factor,
          UnitId: product.UnitId
        };
      default:
        this.selectedProduct = {
          Id: product.Id,
          Amount: product.Amount,
          Name: product.ProductName,
          Width: product.Width,
          Height: product.Height,
          Depth: product.Depth,
          Weight: product.Weight,
          WeightUnit: 'cm',
          Factor: product.Factor,
          UnitId: product.UnitId
        };
    }
  }

  checkAndChangeWeightUnit(product) {
    const weightUnit = product.WeightUnit ? product.WeightUnit : 'kg';
    switch (weightUnit) {
      case 'kg':
        return {
          ...product,
          WeightUnit: 'kg'
        };
      case 'g':
        return {
          ...product,
          Weight: product.Weight / 1000,
          WeightUnit: 'kg'
        };
      case 'dkg':
        return {
          ...product,
          Weight: product.Weight / 100,
          WeightUnit: 'kg'
        };
      case 't':
        return {
          ...product,
          Weight: product.Weight * 1000,
          WeightUnit: 'kg'
        };
      default:
        return {
          ...product,
          WeightUnit: 'kg'
        };
    }
  }

  maxPackingWeight: number = 0;

  onUsedWeightEvent(e: number) {
    this.maxPackingWeight = this.selectedPackaging.MaxWeight;
    const roundedWeight = parseFloat(e.toFixed(3));
    this.usedWeight.set({usedWeight: roundedWeight});
    this.itemGrid?.instance.focus();
  }

  onAmountOfPlacedProducts(e: number) {
    this.amountOfPlacedProducts = e;
    this.itemGrid?.instance.focus();
  }

  onVolumeEvent(e: number) {
    const packingVolume = (this.selectedPackaging.Width - this.selectedPackaging.Thickness * 2) * (this.selectedPackaging.Height - this.selectedPackaging.Thickness * 2) * (this.selectedPackaging.Depth - this.selectedPackaging.Thickness * 2);
    const calculatedVolume = e * 100 / packingVolume;
    const roundedVolume = parseFloat(calculatedVolume.toFixed(2));
    this.usedVolume.set(roundedVolume);
    this.itemGrid?.instance.focus();
  }

  deleteAllPlacedProducts = false;
  contextMenu = [
    {
      text: 'Kartoteka asortymentowa',
      itemIndex: 0,
      translationSrc: 'offers.card',
    }
  ];

  contextMenuClick = (e) => {
    switch (e.itemData.itemIndex) {
      case 0:
        if (this.focusedSelected[0]) {
          this.setSingleRecordVisible('show');
          break;
        }
    }
  };

  titleArticle: string = '';
  singleRecordMode: SingleRecordMode = 'show';
  statusOfDoesntFitProducts: boolean = false;

  setSingleRecordVisible(mode: SingleRecordMode) {
    if (mode == 'show') {
      this.translate
        .get('buttons.preview')
        .subscribe((text) => (this.titleArticle = text));
    }
    this.singleRecordMode = mode;
    this.isArticleVisible = true;
    this.cd.detectChanges();
  }

  amountInBox = [];

  deleteAll() {
    this.deleteAllPlacedProducts = true;
    this.addFactorUnitProductConfirm = false;
    this.addAllProducts = false;
    this.dataSource._items.forEach((el) => {
      el.AmountInBox = 0;
    });
    this.data = [...this.dataSource._items];
    this.dataSource.reload();
    // this.cd.detectChanges();
  }

  deletePositionVer(mode: string) {
    this.deletePosiotionsPlacedProducts = mode;
  }

  previousValue: number = 0;

  onAmountOfPlacedProductsChanged(data, up?) {
    // console.log('onAmountOfPlacedProductsChanged', e);
    // if (e.previousValue != null) {
    //   this.previousValue = e.previousValue;
    // }
    // console.log('onAmountOfPlacedProductsChanged', e);
    // console.log(data);
    // if (e.value === 0 && e.previousValue === null) {
    //   return;
    // }

    if (up == 'up') {
      data.AmountInBox = data.AmountInBox + 1;
      this.data = [...this.dataSource._items];
      setTimeout(() => {
        this.addProductWithoutVerification(false);
        this.cd.detectChanges();
      }, 10);
      this.itemGrid?.instance.focus();
      this.cd.detectChanges();
      return;
    }


    if (up == 'down') {
      if (data.AmountInBox === 0) {
        return;
      }
      data.AmountInBox = data.AmountInBox - 1;
      this.data = [...this.dataSource._items];

      setTimeout(() => {
        //this.statusOfDoesntFitProducts - false, gdy w confirmie czy chcesz umieścić pomimo tego że się nie mieści kliknę NIE i żeby
        // nie usuwać ostatnego produktu
        // if (!this.statusOfDoesntFitProducts) {
        //   this.deletePositionVer('deleteOne');
        // }
        this.deletePositionVer('deleteOne');
        this.statusOfDoesntFitProducts = false;
        this.itemGrid?.instance.focus();
        this.cd.detectChanges();
      }, 10);
      return;

    }


    // if (e.value > e.previousValue) {
    //   setTimeout(() => {
    //     this.addProductWithoutVerification(false);
    //     this.cd.detectChanges();
    //   }, 10);
    //   this.cd.detectChanges();
    //   return;
    // } else if (e.value < e.previousValue) {
    //   setTimeout(() => {
    //     //this.statusOfDoesntFitProducts - false, gdy w confirmie czy chcesz umieścić pomimo tego że się nie mieści kliknę NIE i żeby
    //     // nie usuwać ostatnego produktu
    //     // if (!this.statusOfDoesntFitProducts) {
    //     //   this.deletePositionVer('deleteOne');
    //     // }
    //     this.deletePositionVer('deleteOne');
    //
    //     this.statusOfDoesntFitProducts = false;
    //     this.cd.detectChanges();
    //   }, 10);
    //   return;
    // }
  }

  onKeyDownAmountPlacedProduct(e) {
    e.preventDefault();
  }

  onActualizeAmountOfPlacedProducts(e: boolean) {
    if (e && this.focusedSelected && this.focusedSelected.length === 1) {
      this.statusOfDoesntFitProducts = e;
      this.dataSource._items.forEach((el) => {
        if (el.Id === this.focusedSelected[0].Id && el.AmountInBox > 0) {
          el.AmountInBox -= 1;
        }
      });
      this.data = [...this.dataSource._items];
      this.itemGrid?.instance.focus();
      // this.dataSource.reload();
    }
  }

  onAddProductConfig(e: boolean) {
    this.addAllProducts = e;
    this.itemGrid?.instance.focus();
  }

  onAddFactorUnitProductConfig(e: boolean) {
    this.addFactorUnitProduct = e;
    this.addFactorUnitProductConfirm = e;
    this.itemGrid?.instance.focus();
  }

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
    localStorage.setItem(
      'PositionVerificationComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  setPreviousAmountAfterCancelConfirm() {
  }

  placedProduct = [];

  onPlacedProducts(e: any) {
    this.placedProduct = this.aggregateProducts(e.slice(1));

    // this.placedProduct = e.slice(1);
  }

  aggregateProducts(products: any): any {
    const productMap: { [id: number]: any } = {};

    products.forEach(product => {
      if (productMap[product.Id]) {
        productMap[product.Id].Amount! += 1;
      } else {
        productMap[product.Id] = {...product, Amount: 1};
      }
    });

    return Object.values(productMap);
  }

  focusedRowIndexPlacedProduct = -1;

  onFocusedPlacedProductChanged() {
  }

  saveDataToLocalStorage() {
    const cols = this.event.setWidthColumnGrid(this.itemGrid);
    if (cols.length) {
      this.localStorageData.columns = cols;
    }

    if (this.event.deviceType != 'mobile') {
      if (
        localStorage.getItem('PositionVerificationComponent') &&
        JSON.stringify(this.localStorageData) !=
        localStorage.getItem('PositionVerificationComponent')
      ) {
        let object = {
          View: 'PositionVerificationComponent',
          Configuration: JSON.stringify(this.localStorageData),
          TenantId: this.event.footerInfo.TenantId,
        };
        localStorage.setItem(
          'PositionVerificationComponent',
          JSON.stringify(this.localStorageData)
        );
        this.appService
          .postAuth(`viewConfigurations`, object)
          .subscribe(() => {
          });
      }
    }
  }
}

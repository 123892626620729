import {
  Component,
  OnInit,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  ChangeDetectionStrategy,
  Output,
} from '@angular/core';
import { EventService } from '../../../event.service';
import { AppServices } from '../../../app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-suma-warehouse-documents',
    templateUrl: './suma-warehouse-documents.component.html',
    styleUrls: ['./suma-warehouse-documents.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'forSelected',
        'filters',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SumaWarehouseDocumentsComponent implements OnInit {
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  @ViewChild('gridJobCost') gridJobCost;

  @ViewChild('focusFirst') focusFirst;
  @ViewChild('isValidUserExtensions') isValidUserExtensions;
  @ViewChild('appContractors') appContractors;

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;
  widthWindow = '90%';
  heightWindow = '90%';
  filters;

  shortcuts: ShortcutInput[] = [];

  submitted: boolean = false;

  unicalGuid;

  dataSourceSuma: any = [];
  dataSourceCurrency = [];
  dataSourceSumaAccount = [];

  radioButtonList = [
    { value: false, label: 'Tylko rozrachunki filtrowane' },
    { value: true, label: 'Tylko rozrachunki zaznaczone' },
  ];

  inFullModeList = [
    { value: false, label: 'Skrócone (tylko w cenach sprzedaży)' },
    { value: true, label: 'Pełne (w cenach zakupu i sprzedaży)' },
  ];
  fullMode = false;

  forSelected;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('warehouseDocument.filtereDocumentsOnly')
      .subscribe((text) => (this.radioButtonList[0].label = text));
    this.translate
      .get('warehouseDocument.onlySelectedDocuments')
      .subscribe((text) => (this.radioButtonList[1].label = text));

    this.translate
      .get('warehouseDocument.onlyAbbreviatedAtAellingPrices')
      .subscribe((text) => (this.inFullModeList[0].label = text));
    this.translate
      .get('warehouseDocument.fullAtPurchaseAndSalePrices')
      .subscribe((text) => (this.inFullModeList[1].label = text));
  }

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  onValueChanged = () => {
    this.getData();
    this.cd.detectChanges();
  };

  getParamsFromFilters = () => {
    let paramsString: string = '';
    let isFirst: boolean = true;

    const keys = Object.keys(this.filters);

    keys.forEach((key) => {
      if (
        this.filters[key] === null ||
        this.filters[key] === undefined ||
        this.filters[key] === ''
      )
        return;
      paramsString += `${isFirst ? '' : '&'}${key}=${this.filters[key]}`;
      isFirst = false;
    });

    return paramsString;
  };

  getData = () => {
    let filterParams = this.getParamsFromFilters();

    this.appService
      .getAuth(
        `warehouseDocuments/documents/sums?fromSelected=${this.forSelected}&inFullMode=${this.fullMode}&${filterParams}`
      )
      .subscribe((res) => {
        this.dataSourceSuma = [];

        this.dataSourceSuma.push({
          name: this.translate.instant('warehouseDocument.inSellingPrices'),
          NetAmountOutcomeInSell: res['NetAmountOutcomeInSell'],
          GrossAmountOutcomeInSell: res['GrossAmountOutcomeInSell'],
          IncomeNet: '',
          IncomeGrodd: '',
        });

        this.dataSourceSuma.push({
          name: this.translate.instant('warehouseDocument.atPurchasePrices'),
          NetAmountOutcomeInSell: res['NetAmountOutcomeInBuy'],
          GrossAmountOutcomeInSell: res['GrossAmountOutcomeInBuy'],
          IncomeNet: res['NetAmountIncome'],
          IncomeGrodd: res['GrossAmountIncome'],
        });

        this.dataSourceSuma.push({
          name: this.translate.instant('articles.margin'),
          NetAmountOutcomeInSell: res['Margin'],
          GrossAmountOutcomeInSell: '',
          IncomeNet: '',
          IncomeGrodd: '',
        });

        this.dataSourceSuma.push({
          name: this.translate.instant('articles.discountMarkup'),
          NetAmountOutcomeInSell: res['Overhead'],
          GrossAmountOutcomeInSell: '',
          IncomeNet: '',
          IncomeGrodd: '',
        });

        this.dataSourceSuma.push({
          name: this.translate.instant('stockHistory.profit'),
          NetAmountOutcomeInSell: res['Profit'],
          GrossAmountOutcomeInSell: '',
          IncomeNet: '',
          IncomeGrodd: '',
        });
        this.cd.detectChanges();
      });
  };

  ngOnChanges() {
    if (this.selectedId) {
      if (this.selectedId.length != 0 && this.isVisible) {
        this.getData();
      }
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };
}

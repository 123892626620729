<dx-popup
  width="740"
  height="240"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'inventory.summarizedInventory' | translate }}"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="isVisible = false"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-data-grid
      [dataSource]="dataSource"
      [wordWrapEnabled]="true"
      [showBorders]="true"
      shortcut
      [height]="120"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      (onOptionChanged)="event.onOptionChanged($event)"
    >
      <dxo-load-panel [enabled]="false"></dxo-load-panel>
      <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
      <dxi-column
        caption=""
        alignment="right"
        [width]="100"
        [allowSorting]="false"
        cellTemplate="firstColumn"
      >
      </dxi-column>

      <div *dxTemplate="let data of 'firstColumn'">
        {{ data.data.was }}
      </div>

      <dxi-column
        caption="{{ 'inventory.previous' | translate }}"
        alignment="right"
        [width]="200"
        [allowSorting]="false"
        dataField="NetAmountPrevious"
        cellTemplate="numberPrecisionTemplate"
      >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>

      <dxi-column
        caption="{{ 'inventory.left' | translate }}"
        alignment="right"
        [width]="200"
        [allowSorting]="false"
        dataField="NetAmountLeft"
        cellTemplate="numberPrecisionTemplate"
      >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>

      <dxi-column
        caption="{{ 'inventory.difference' | translate }}"
        alignment="right"
        [width]="200"
        [allowSorting]="false"
        dataField="NetAmountDifference"
        cellTemplate="numberPrecisionTemplate"
      >
        <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
      </dxi-column>
      <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
        {{ cell.value | amountFormatter : cell : 2 }}
      </div>
    </dx-data-grid>

    <dx-radio-group
      [items]="radioButtonList"
      layout="horizontal"
      displayExpr="label"
      valueExpr="value"
      [readOnly]="readOnly"
      [(value)]="forSelected"
      (onValueChanged)="onValueChanged()"
    >
    </dx-radio-group>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

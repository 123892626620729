import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import DataSource from 'devextreme/data/data_source';
import { ValueChangedEvent } from 'devextreme/ui/radio_group';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { Price } from 'src/app/libraries/articles/categories/add-category/ICategory';

export interface PriceListForCustomer {
  CustomerId: FormControl<number>;
  OfferDocumentId: FormControl<number>;
  PriceId: FormControl<number>;
  DiscountMarkup: FormControl<number>;
  InvoiceDocumentId: FormControl<number>;
  WarehouseDocumentId: FormControl<number>;
  OrderDocumentId: FormControl<number>;
}

@Component({
    selector: 'app-create-price-list-for-customer',
    templateUrl: './create-price-list-for-customer.component.html',
    styleUrls: ['./create-price-list-for-customer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class CreatePriceListForCustomerComponent {
  submitted = false;
  radioList = [
    {
      label: 'z pozycji dokumentu',
      value: 0,
    },
    {
      label: 'z cennika',
      value: 1,
    },
  ];

  format = '###,###,###,##0.00';
  radioListValue: number = 0;
  dataSource: DataSource = null;
  @Input() readOnly = false;
  @Input() customerId: number = null;
  @Input() offerId: number = null;
  @Output() onClosing: EventEmitter<boolean> = new EventEmitter();
  @Output() onSaved: EventEmitter<string> = new EventEmitter();
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts: ShortcutInput[] = [];
  form: FormGroup<PriceListForCustomer>;
  appServices = inject(AppServices);
  prices$: Observable<Price[]> = this.appServices
    .getAuth(`products/prices`)
    .pipe(map((res) => res.data));
  constructor(public event: EventService, private translate: TranslateService) {
    this.createForm();
    this.getTranslations();
  }

  getTranslations() {
    this.translate
      .get('customerGroups.fromDocPosition')
      .subscribe((text: string) => {
        this.radioList[0].label = text;
      });
    this.translate.get('customerGroups.fromPrice').subscribe((text: string) => {
      this.radioList[1].label = text;
    });
  }

  createForm() {
    this.form = new FormGroup({
      CustomerId: new FormControl(0, { validators: [Validators.required] }),
      OfferDocumentId: new FormControl(0),
      PriceId: new FormControl(0),
      DiscountMarkup: new FormControl(0),
      InvoiceDocumentId: new FormControl(0),
      WarehouseDocumentId: new FormControl(0),
      OrderDocumentId: new FormControl(0),
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.offerId?.currentValue) {
      this.form.controls.OfferDocumentId.setValue(changes.offerId.currentValue);
    }
    if (changes?.customerId?.currentValue) {
      this.form.controls.CustomerId.setValue(changes.customerId.currentValue);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'F10',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.onSave();
        },
      },
      {
        key: 'esc',
        allowIn: [AllowIn.Textarea, AllowIn.Input, AllowIn.Select],
        preventDefault: true,
        command: () => {
          this.close();
        },
      }
    );
  }

  onSave() {
    this.submitted = true;
    if (
      !this.form.valid ||
      this.readOnly ||
      (this.form.value.PriceId == 0 && this.radioListValue)
    )
      return;

    this.appServices
      .postAuth(
        `offers/additionalOperations/${this.offerId}/createPriceListForCustomer`,
        this.form.value
      )
      .subscribe({
        next: () => {
          this.onSaved.emit();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  close() {
    this.onClosing.emit(true);
  }

  onRadioValueChanged(e: ValueChangedEvent) {
    if (e.value === 0) {
      this.form.patchValue({
        DiscountMarkup: 0,
        PriceId: 0,
      });
      //this.form.controls.PriceId.clearValidators();
    }
    // else {
    //   this.form.controls.PriceId.setValidators([Validators.required]);
    // }
    // this.form.controls.PriceId.removeValidators()
    // this.form.controls.PriceId.updateValueAndValidity();
    // this.form.updateValueAndValidity();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { LoadOptions } from "devextreme/data";
import DataSource from "devextreme/data/data_source";
import { AllowIn, ShortcutInput } from "ng-keyboard-shortcuts";
import { forkJoin } from "rxjs";
import { AppServices } from "src/app/app-services.service";
import { EventService } from "src/app/event.service";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-booking-managment",
    templateUrl: "./booking-managment.component.html",
    styleUrls: ["./booking-managment.component.scss"],
    inputs: ["isVisible", "product", "title", "readOnly", "orderDocumentNumber"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class BookingManagmentComponent {
  @Output() onClosing = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @ViewChild("gridBank") gridBank;

  isVisible;
  product;
  readOnly;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  widthWindow: any = "85%";
  heightWindow: any = "85%";
  title;
  orderDocumentNumber;

  radioList = [
    {
      value: 2,
      label: "przenieś rezerwację Z artykułu ",
    },
    {
      value: 1,
      label: "przenieś rezerwację NA artykuł ",
    },
  ];

  radioListValue: number = 1;
  dataSource;
  valueCriteria = "Date";
  displayValue: string = "data wystawienia";
  orderBy = "Date";

  filterCriteria: any[] = [
    { value: "Date", label: "data wystawienia", type: "data" },
    { value: "OrderDocumentNumber", label: "numer" },
    {
      value: "RealizationDate",
      label: "termin realizacji",
      type: "data",
    },
    { value: "CustomerOrderNumber", label: "nr zam. klienta" },
  ];

  filterValue: string = "";
  sumPosition: number = 0;
  shortcuts: ShortcutInput[] = [];

  constructor(
    public translate: TranslateService,
    public event: EventService,
    public cd: ChangeDetectorRef,
    public appService: AppServices
  ) {}

  ngOnInit(): void {
    if (!this.readOnly) this.readOnly = this.event.readOnly;
  }

  ngOnChanges() {
    if (this.isVisible && this.product && this.product.length > 0) {
      this.translate
        .get("documentsOrders.transferReservationFromArticle", {
          name: this.product[0].Name,
          number: this.product[0].OrderNumber,
        })
        .subscribe((text: string) => {
          this.radioList[0].label = text;
        });
      this.translate
        .get("documentsOrders.moveTheReservationONTheArticle", {
          name: this.product[0].Name,
          number: this.product[0].OrderNumber,
        })
        .subscribe((text: string) => {
          this.radioList[1].label = text;
        });
      this.getDataProduct(this.product[0].ProductId);
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: "escape",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.isVisible = false;
          this.cd.detectChanges();
        },
        preventDefault: true,
      },
      {
        key: "F10",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.readOnly || this.sumPosition == 0) return;
          this.moveReservationPositions();
        },
        preventDefault: true,
      },
      {
        key: "enter",
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.readOnly) return;
          this.changeAmountToTransfer();
        },
        preventDefault: true,
      }
    );
  }

  productDetails: any = {};
  getDataProduct(id) {
    this.appService.getAuth(`products?ObjectId=${id}`).subscribe(
      (res: any) => {
        this.productDetails = res.data[0];
      },
      (error) => {
        this.event.httpErrorNotification(error);
      }
    );
  }

  visibleChange = (e) => {
    if (!e) this.onClosing.emit(false);
    this.cd.detectChanges();
  };

  closeWindow() {
    this.isVisible = false;
    this.onClosing.emit(false);
  }

  getReservationPositions() {
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: "OrderDocumentPositionId",
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}orders/additionalOperations/${this.product[0].OrderDocumentId}/${this.product[0].OrderDocumentPositionId}/reservationPositions`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoading(loadOptions: LoadOptions) {
          loadOptions.requireTotalCount = true;
        },
        onLoaded: (data) => {
          data.forEach((element) => {
            element.AmountToTransfer = 0;
          });
          this.focusedRowIndex = 0;
          this.gridBank.instance.focus();
        },
      }),

      reshapeOnPush: true,
      requireTotalCount: true,
    });
  }

  onCellDblClick(e) {
    if (e.column.dataField == "AmountToTransfer" && !this.event.readOnly) {
      this.dataSource._items[e.rowIndex].isInputAmount = true;
      return;
    }
  }

  onFocusOut = (e, data) => {
    e.event.stopPropagation();
    if (
      e.event.originalEvent.code == "Enter" ||
      e.event.originalEvent.code == "NumpadEnter"
    ) {
      e.event.stopPropagation();
      setTimeout(() => {
        this.keyupEnter(data);
      }, 0);
    }
  };

  async keyupEnter(e) {
    if (
      this.radioListValue == 2 &&
      e.data.AmountToTransfer >
        e.data.AmountToReservation - e.data.AmountReserved
    ) {
      let notify = this.translate.instant(
        "documentsOrders.theAmountToBeTransferredCannotExceedTheDifference",
        {
          number: this.product[0].OrderNumber,
          amount: e.data.AmountToReservation - e.data.AmountReserved,
        }
      );
      this.event.showNotification("info", notify);
      this.amountToTransfer.forEach((element: any) => {
        element.instance.focus();
      });
      return;
    }

    if (
      this.radioListValue == 1 &&
      e.data.AmountToTransfer > e.data.AmountReserved
    ) {
      let notify = this.translate.instant(
        "documentsOrders.theQuantityToBeTransferredCannotExceedTheReservedQuantity",
        {
          number: this.product[0].OrderNumber,
          amount: e.data.AmountReserved,
        }
      );
      this.event.showNotification("info", notify);
      this.amountToTransfer.forEach((element: any) => {
        element.instance.focus();
      });
      return;
    }

    e.data.isInputAmount = false;
    await this.changeAmountReservationPositions(e.data);
    this.totalToMoveReservationPostions(e.data);
    this.gridBank.instance.focus();
  }

  onKeyDown(e) {
    if (
      e.event.keyCode === 113 ||
      e.event.keyCode === 27 ||
      e.event.keyCode === 13
    ) {
      //f2 escape
      e.event.preventDefault();
    }
  }

  @ViewChildren("amountToTransfer") amountToTransfer: QueryList<ElementRef>;
  focusedRowIndex: number = 0;
  changeAmountToTransfer() {
    if (this.focusedSelected.length == 1) {
      this.focusedSelected[0].isInputAmount = true;
    } else if (this.dataSource._items.length > 0) {
      this.focusedRowIndex = 0;
      this.focusedSelected = [this.dataSource._items[0]];
      this.focusedSelected[0].isInputAmount = true;
    }
    setTimeout(() => {
      this.amountToTransfer.forEach((element: any) => {
        element.instance.focus();
      });
    }, 0);
    this.cd.detectChanges();
  }

  changeAmountReservationPositions(data) {
    return new Promise((resolve) => {
      let object = {
        OrderDocumentId: data.OrderDocumentId,
        OrderDocumentPositionId: data.OrderDocumentPositionId,
        OrderDocumentPositionBaseId: this.product[0].OrderDocumentPositionId,
        Amount: data.AmountToTransfer,
      };
      this.appService
        .putAuth(
          `orders/additionalOperations/${data.OrderDocumentId}/${data.OrderDocumentPositionId}/changeAmountReservationPositions`,
          object
        )
        .subscribe(
          () => {
            resolve(true);
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    });
  }

  moveReservationPositions() {
    return new Promise((resolve) => {
      if (this.sumPosition == 0) {
        resolve(true);
        return;
      }

      let forArray = [];

      this.dataSource._items.forEach((element) => {
        if (element.AmountToTransfer > 0) {
          let object = {
            OrderDocumentId: element.OrderDocumentId,
            OrderDocumentPositionId: element.OrderDocumentPositionId,
            Direction: this.radioListValue,
          };

          forArray.push(
            this.appService.putAuth(
              `orders/additionalOperations/${element.OrderDocumentId}/${element.OrderDocumentPositionId}/moveReservationPositions`,
              object
            )
          );
        }
      });

      forkJoin(forArray).subscribe(
        () => {
          // this.isVisible = false;
          this.onRefresh.emit(true);
          this.sumPosition = 0;
          this.getReservationPositions();
          // resolve(true);
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );

      // this.appService
      //   .putAuth(
      //     `orders/additionalOperations/${this.product[0].OrderDocumentId}/${this.product[0].OrderDocumentPositionId}/moveReservationPositions`,
      //     object
      //   )
      //   .subscribe(
      //     (res) => {
      //       this.isVisible = false;
      //       this.onClosing.emit(true);
      //       resolve(true);
      //     },
      //     (error) => {
      //    this.event.httpErrorNotification(error);
      //     }
      //   );
    });
  }

  totalToMoveReservationPostions(data) {
    this.appService
      .getAuth(
        `orders/additionalOperations/${data.OrderDocumentId}/${data.OrderDocumentPositionId}/totalToMoveReservationPostions?OrderDocumentId=${data.OrderDocumentId}&OrderDocumentPositionId=${data.OrderDocumentPositionId}&OrderDocumentPositionBaseId=${this.product[0].OrderDocumentPositionId}`
      )
      .subscribe(
        (res) => {
          this.sumPosition = res.TotalToMove;
          this.cd.detectChanges();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  getLoadParams() {
    let obj: any = {};
    obj.ProductId = this.product[0].ProductId;
    obj.OrderDocumentId = this.product[0].OrderDocumentId;
    obj.OrderDocumentPositionId = this.product[0].OrderDocumentPositionId;

    switch (this.valueCriteria) {
      case "Data":
        obj.Date = this.filterValue;
        break;
      case "OrderDocumentNumber":
        obj.OrderDocumentNumber = this.filterValue;
        break;
      case "RealizationDate":
        obj.RealizationDate = this.filterValue;
        break;
      case "CustomerOrderNumber":
        obj.CustomerOrderNumber = this.filterValue;
        break;
    }
    return obj;
  }

  order: string = "ASC"; // asc/desc

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
      this.displayValue = e.selectedItem.label;
      this.order = "ASC";
      this.orderBy = e.selectedItem.value;
    }
    this.filterValue = e.filterValue;

    this.getReservationPositions();
  }

  switchOrder() {
    if (this.order === "ASC") {
      this.order = "DESC";
      return;
    }

    this.order = "ASC";
  }

  setSearchCriteria = (orderBy) => {
    if (orderBy !== this.orderBy) {
      this.orderBy = orderBy;
    } else {
      this.switchOrder();
    }
    this.cd.detectChanges();
    this.getReservationPositions();
  };

  focusedSelected: any = [];
  onDropdownnFocusedRowChanged(e) {
    this.dataSource._items.forEach((element) => {
      element.isInputAmount = false;
    });
    this.focusedSelected = [e.row.data];
  }
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';
import DataSource from 'devextreme/data/data_source';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { HelpService } from 'src/app/help-service.service';
import { CollectorTransferConfig } from 'src/app/libraries/data-collector/data-collector.model';
import { environment } from '../../../../environments/environment';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

import { ReportsComponent } from 'src/app/core/reports/reports.component';

@Component({
    selector: 'app-new-inventory',
    templateUrl: './new-inventory.component.html',
    styleUrls: ['./new-inventory.component.scss'],
    inputs: [
        'isVisible',
        'selectedId',
        'readOnly',
        'title',
        'selected',
        'documentType',
        'autoNumeration',
        'newPosition',
        'selectedWarehouse',
        'unsavedDocument',
    ],
    standalone: false
})
export class NewInventoryComponent implements OnInit {
  @ViewChild('reports') reports: ReportsComponent;
  @ViewChild('gridClass') gridClass;
  @ViewChild('leftPanel') leftPanel;
  @ViewChild('rightPanel') rightPanel;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted = new EventEmitter();
  helpService = inject(HelpService);
  isVisible;
  selectedId;
  readOnly;
  title;
  selected;
  unicalGuid;
  documentType;
  autoNumeration;
  newPosition;
  selectedWarehouse;
  unsavedDocument;
  columnsChooserVisible: boolean = false;
  widthWindow: any = '90%';
  heightWindow: any = '90%';
  fullScreen: boolean = false;

  scrollPosition = 0;
  form;
  submitted: boolean = false;
  heightGrid: number = window.innerHeight - 310;
  selectedRow = [];

  isShowConfirm: boolean = false;
  isShowConfirmDelete: boolean = false;

  shortcuts: ShortcutInput[] = [];
  categoriesList;
  categoryFilter = '';

  filterCriteria = [
    { value: 'ProductFullNameIntuition', label: '' },
    { value: 'IndexCatalogueIntuition', label: '' },
    { value: 'IndexTradingIntuition', label: '' },
    { value: 'Location', label: '' },
  ];
  customValue = '';
  valueCriteria = 'ProductFullNameIntuition';
  filterValue = '';

  stateList = [
    { value: 'null', label: '' },
    { value: 'minus', label: '' },
    { value: 'plus', label: '' },
  ];

  widthLeftPanel;
  width = 300;
  localStorageData = {
    leftPanelWidth: null,
    rightPanelWidth: null,
    columns: [],
  };

  positionStateList = [
    { value: null, label: '' },
    { value: 1, label: '' },
    { value: 0, label: '' },
  ];

  isDeleteRow: boolean = false;

  addRow: boolean = false;
  editRow: boolean = false;

  myEventDelete;
  myEventEdit;
  myEventEsc;
  myEventControlDown;

  deleteItems = [];
  isGroupDoc: boolean = false;
  contextMenu = [
    { text: 'Dodaj', icon: 'icon absui-icon--add-circle' },
    { text: 'Edytuj', icon: 'icon absui-icon--mode-edit' },
    { text: 'Usuń', icon: 'icon absui-icon--highlight-off' },
    { text: 'Powiel', icon: 'icon absui-icon--files-copy' },
    { text: 'Suma', icon: 'icon absui-icon--sum' },
    { text: 'Zaznacz wszystko', icon: 'icon absui-icon--checkbox-all' },
    { text: 'Odznacz wszystko', icon: 'icon absui-icon--deselct-all' },
    { text: 'Oznacz jako wykonane', icon: null },
    { text: 'Oznacz jako niewykonane', icon: null },
  ];

  isDuplicat: boolean = false;
  isSetStockAsZero: boolean = false;
  SetStockAsZero: boolean = false;
  filterMoreZero: boolean = false;
  isSum: boolean = false;
  isGroupArt: boolean = false;
  isDisableCancel: boolean = false;
  groupMsgDelete;

  actionAfter: any[] = [
    { value: 0, label: 'brak' },
    { value: 1, label: 'poprawianie' },
    { value: 2, label: 'ilość grupowa' },
  ];
  stateAfter: any[] = [
    { value: null, label: 'wszystkie' },
    { value: 1, label: 'większe od zera' },
  ];

  actionAfterValue: number = 0;

  private formCreated = false;

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    private router: Router,
    public cd: ChangeDetectorRef
  ) {
    this.translate
      .get('inventory.lack')
      .subscribe((text) => (this.actionAfter[0].label = text));
    this.translate
      .get('inventory.correcting')
      .subscribe((text) => (this.actionAfter[1].label = text));
    this.translate
      .get('inventory.groupQuantity')
      .subscribe((text) => (this.actionAfter[2].label = text));
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('articles.filter.name')
      .subscribe((text) => (this.filterCriteria[0].label = text));
    this.translate
      .get('articles.filter.indexCatalogue')
      .subscribe((text) => (this.filterCriteria[1].label = text));
    this.translate
      .get('articles.filter.indexTrading')
      .subscribe((text) => (this.filterCriteria[2].label = text));
    this.translate
      .get('articles.filter.location')
      .subscribe((text) => (this.filterCriteria[3].label = text));

    this.translate
      .get('constractors.filter.everything')
      .subscribe((text) => (this.stateList[0].label = text));
    this.translate
      .get('inventory.shortages')
      .subscribe((text) => (this.stateList[1].label = text));
    this.translate
      .get('inventory.surplus')
      .subscribe((text) => (this.stateList[2].label = text));

    this.translate
      .get('constractors.filter.everything')
      .subscribe((text) => (this.positionStateList[0].label = text));
    this.translate
      .get('inventory.done')
      .subscribe((text) => (this.positionStateList[1].label = text));
    this.translate
      .get('inventory.notDone')
      .subscribe((text) => (this.positionStateList[2].label = text));

    this.translate
      .get('buttons.add')
      .subscribe((text) => (this.contextMenu[0].text = text));
    this.translate
      .get('buttons.edit')
      .subscribe((text) => (this.contextMenu[1].text = text));
    this.translate
      .get('buttons.delete')
      .subscribe((text) => (this.contextMenu[2].text = text));
    this.translate
      .get('inventory.duplicate')
      .subscribe((text) => (this.contextMenu[3].text = text));
    this.translate
      .get('stockHistory.sum')
      .subscribe((text) => (this.contextMenu[4].text = text));
    this.translate
      .get('repairFunctions.selectAll')
      .subscribe((text) => (this.contextMenu[5].text = text));
    this.translate
      .get('articles.setUnselectedAll')
      .subscribe((text) => (this.contextMenu[6].text = text));

    this.translate
      .get('inventory.markDone')
      .subscribe((text) => (this.contextMenu[7].text = text));
    this.translate
      .get('inventory.markotCompleted')
      .subscribe((text) => (this.contextMenu[8].text = text));

    this.getCategoriesList();
    this.customValue = this.positionStateList.find(
      (field) => field.value == null
    )?.label;
  }

  ngOnInit(): void {
    if (!this.formCreated) {
      this.createForm();
      this.formCreated = true;
    }

    let ZerStanPoNaRemBil =
      this.event.getConfigurationParameter('ZerStanPoNaRemBil')?.Value;

    ZerStanPoNaRemBil == 'Zeruj'
      ? (this.SetStockAsZero = true)
      : (this.SetStockAsZero = false);
  }

  updateColumns() {
    if (!localStorage.getItem('NewInventoryComponent')) {
      return;
    }

    if (localStorage.getItem('NewInventoryComponent')) {
      this.localStorageData = JSON.parse(
        localStorage.getItem('NewInventoryComponent')
      );
    }

    if (this.localStorageData.columns) {
      setTimeout(() => {
        const instance = this.gridClass?.instance;

        if (!instance) {
          return;
        }
        this.localStorageData.columns.forEach((col, i) => {
          instance.columnOption(col.caption, 'visible', col.visible);
          instance.columnOption(col.caption, 'visibleIndex', i);
          instance.columnOption(col.caption, 'width', col.width);
        });
        this.cd.detectChanges();
      }, 250);
    }
  }

  marginFilter: number = 0;

  onMarginValueChanged(e) {
    if (e == 0 || e == 1 || e == 2) {
      this.marginFilter = e;
    }
  }

  afterAddPosition() {
    setTimeout(() => {
      if (this.marginFilter == 1) {
        this.editDok();
      }

      if (this.marginFilter == 2) {
        this.isGroupArt = true;
      }
    }, 0);
  }

  contextMenuClick = (e) => {
    switch (e.itemIndex) {
      case 0:
        if (!this.readOnly) {
          this.addRow = true;
        }
        break;
      case 1:
        if (!this.readOnly) {
          this.editDok();
        }
        break;
      case 2:
        if (!this.readOnly) {
          this.isDelete();
        }
        break;
      case 3:
        if (!this.readOnly) {
          this.clonePosition();
        }
        break;
      case 4:
        if (!this.readOnly) {
          this.sum();
        }
        break;
      case 5:
        this.gridClass.instance.selectAll();
        break;
      case 6:
        this.gridClass.instance.selectRows([], false);
        break;

      case 7:
        if (!this.readOnly) {
          this.setFinishStateForSelectedPositions(true);
        }
        break;
      case 8:
        if (!this.readOnly) {
          this.setFinishStateForSelectedPositions(false);
        }
        break;
    }
  };

  ngOnDestroy() {
    this.event.onHiddenPopUp();

    // this.setLocalStorage();
  }

  onValueChangeZero = (e, isClick) => {
    if (e.value && isClick && this.dataSource._items.length > 0) {
      this.isSetStockAsZero = true;
    } else if (!e.value && isClick) {
      this.setStockAsZero();
    }

    let updateZerStanPoNaRemBil = [
      {
        FieldCode: 'ZerStanPoNaRemBil',
        Value: e.value ? 'Zeruj' : 'Pozostaw', //pozostaw, zeruj
      },
    ];
    this.appService
      .postAuth(
        `configurations/users/currentUser/config`,
        updateZerStanPoNaRemBil
      )
      .subscribe(() => {});
  };

  removeSemaphor = () => {
    localStorage.removeItem('editWarehouseDocumentId');
  };

  ngOnChanges() {
    if (!this.formCreated) {
      this.createForm();
      this.formCreated = true;
    }

    if (this.selectedId && this.isVisible) {
      if (!this.newPosition) {
        this.isDisableCancel = true;
      }
      this.appService
        .getAuth(`inventory/documents?ObjectId=${this.selectedId}`)
        .subscribe((res) => {
          res.data.forEach((field) => {
            if (field.InventoryDocumentId == this.selectedId) {
              this.form.controls.InventoryDocumentId.setValue(
                field.InventoryDocumentId
              );

              // this.form.controls.Number.setValue(field.DocumentNumber);
              this.form.controls.Date.setValue(field.DocumentDate);
              this.getDocumentPosition();
            }
          });
        });
      this.form.controls.InventoryDocumentId.setValue(this.selectedId);
      this.documentType == 'INV'
        ? this.form.controls.DocumentType.setValue('DOCUMENT_INVENTORY')
        : this.form.controls.DocumentType.setValue('DOCUMENT_OPENING_BALANCE');

      if (this.documentType == 'INV') {
        let documentsNumerations = this.event.decryptString(
          localStorage.getItem('staticData')
        ).documentsNumerations;

        let RTId = documentsNumerations['WarehouseDocuments'].find(
          (field) => field.Symbol.trim() == 'RT'
        ); //6

        let REMId = documentsNumerations['WarehouseDocuments'].find(
          (field) => field.Symbol.trim() == 'REM'
        ); //5

        if (this.isDisableCancel) {
          this.autoNumeration = this.event.checkAutoNumeration(
            'WarehouseDocuments',
            REMId.DocumentTypeId //5
          );
        } else {
          this.autoNumeration = this.event.checkAutoNumeration(
            'WarehouseDocuments',
            RTId.DocumentTypeId //6
          );
        }
      } else {
        let documentsNumerations = this.event.decryptString(
          localStorage.getItem('staticData')
        ).documentsNumerations;

        let BTId = documentsNumerations['WarehouseDocuments'].find(
          (field) => field.Symbol.trim() == 'BT'
        ); //4

        let REMId = documentsNumerations['WarehouseDocuments'].find(
          (field) => field.Symbol.trim() == 'BO'
        ); //3

        if (this.isDisableCancel) {
          this.autoNumeration = this.event.checkAutoNumeration(
            'WarehouseDocuments',
            REMId.DocumentTypeId //3
          );
        } else {
          this.autoNumeration = this.event.checkAutoNumeration(
            'WarehouseDocuments',
            BTId.DocumentTypeId //4
          );
        }
      }
      if (!this.autoNumeration) {
        this.form.controls.Number.setValue('');
      }
    }

    if (this.selected && this.isVisible && !this.selectedId) {
      this.documentType == 'INV'
        ? this.form.controls.DocumentType.setValue('DOCUMENT_INVENTORY')
        : this.form.controls.DocumentType.setValue('DOCUMENT_OPENING_BALANCE');
      this.form.controls.InventoryDocumentId.setValue(
        this.selected[0].InventoryDocumentId
      );
      this.form.controls.Number.setValue(this.selected[0].DocumentNumber);
      this.form.controls.Date.setValue(this.selected[0].DocumentDate);

      this.getDocumentPosition();
    }

    if (this.selectedWarehouse && this.isVisible && !this.selectedId) {
      this.documentType == 'INV'
        ? this.form.controls.DocumentType.setValue('DOCUMENT_INVENTORY')
        : this.form.controls.DocumentType.setValue('DOCUMENT_OPENING_BALANCE');
      this.form.controls.InventoryDocumentId.setValue(
        this.selectedWarehouse[0].WarehouseDocumentId
      );
      this.form.controls.Number.setValue(
        this.selectedWarehouse[0].WarehouseDocumentNumber
      );
      this.form.controls.Date.setValue(
        this.selectedWarehouse[0].DateOfWarehouseDocument
      );

      this.getDocumentPosition();
    }
  }

  resizeEnd() {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  }

  onResizeStart() {
    this.widthLeftPanel = this.leftPanel.nativeElement.clientWidth;
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  }

  timeoutHandlerSidePanel;

  mouseupSidePanel() {
    if (this.timeoutHandlerSidePanel) {
      clearTimeout(this.timeoutHandlerSidePanel);
      this.resizeWindowBtn();
      this.timeoutHandlerSidePanel = null;
    }
  }

  mousedownSidePanel() {
    this.timeoutHandlerSidePanel = setTimeout(() => {
      this.timeoutHandlerSidePanel = null;
    }, 500);
  }

  resizeWindowBtn() {
    if (this.width > 21) {
      this.leftPanel.nativeElement.style.width = '10px';
      this.width = 21;
      this.rightPanel.nativeElement.style.width = `calc(100% - 26px)`;
      this.localStorageData.leftPanelWidth = `21px`;
      this.localStorageData.rightPanelWidth = `calc(100% - 26px)`;
    } else {
      this.leftPanel.nativeElement.style.width = '280px';
      this.width = 280;
      this.rightPanel.nativeElement.style.width = `calc(100% - 280px)`;
      this.localStorageData.leftPanelWidth = `280px`;
      this.localStorageData.rightPanelWidth = `calc(100% - 280px)`;
    }
    localStorage.setItem('inventory', JSON.stringify(this.localStorageData));
  }

  getResizeIcon() {
    return this.width > 21
      ? 'icon absui-icon--arrow-drop-right'
      : 'icon absui-icon--arrow-drop-left';
  }

  mouseEnter = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle(true);;
  };

  mouseLeave = () => {
    this.leftPanel.nativeElement.style.borderLeft = this.event.getPanelBorderStyle();
  };

  createForm = () => {
    this.form = this.formBuilder.group({
      InventoryDocumentId: [0],
      Mode: [0],
      Number: ['<auto>'],
      DocumentType: [''],
      ActionType: [0],
      Date: [new Date()],
    });
  };

  onRowClick = (e) => {
    try {
      this.focusedSelected = [e.data];
    } catch {}
  };

  getCategoriesList = () => {
    this.translate.get('articlesTab.categories').subscribe((text) => {
      this.appService
        .getAuth(`products/categories?skip=0&take=200`)
        .subscribe((res) => {
          this.categoriesList = [
            {
              expanded: true,
              items: res.data,
              CategoryId: '',
              ProductCategoryName: text,
            },
          ];
        });
    });
  };

  // pojedyncze
  selectItemTreeviewClassification = (e) => {
    this.categoryFilter = e.itemData.CategoryId;
    this.getDocumentPosition();
  };

  ngAfterViewInit() {
    setTimeout(() => {
      if (localStorage.getItem('sizeInventory')) {
        this.widthWindow = JSON.parse(
          localStorage.getItem('sizeInventory')
        ).widthWindow;
        this.heightWindow = JSON.parse(
          localStorage.getItem('sizeInventory')
        ).heightWindow;
        this.fullScreen = JSON.parse(
          localStorage.getItem('sizeInventory')
        ).fullScreen;
      }
      //this.heightGrid = Number(this.heightWindow) - 212;
    }, 0);

    this.shortcuts.push(
      {
        key: 'f1',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.documentType === 'OB') {
            this.helpService.openHelp(
              'sprzedaz-i-magazyn/dokumenty/remanent/bilans'
            );
          } else {
            this.helpService.openHelp(
              'sprzedaz-i-magazyn/dokumenty/remanent/remanent-dzialanie'
            );
          }
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly || this.unsavedDocument) {
            this.onSave();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + d',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.reports.openDropdown();
        },
        preventDefault: true,
      },
      {
        key: 'f2',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (this.focusedSelected.length > 0) {
            this.editDok();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + z',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.altKey) {
            this.suspended();
          }
          if (e.event.altKey && !this.readOnly) {
            this.router.navigateByUrl('trade-operations/buy');
          }
        },
        preventDefault: true,
      },
      {
        key: 'escape',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.cancel();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + p',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.clonePosition();
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + shift + z',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.isSetStockAsZero = true;
          }
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + s',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly) {
            this.sum();
          }
        },
        preventDefault: true,
      },
      {
        key: 'Insert',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: (e) => {
          if (!this.readOnly && !e.event.shiftKey) {
            this.addRow = true;
          }

          if (!this.readOnly && e.event.shiftKey) {
            this.clonePosition();
          }
        },
        preventDefault: true,
      },
      {
        key: 'del',
        allowIn: [AllowIn.Input, AllowIn.Select],
        command: () => {
          this.isDelete();
        },
        preventDefault: true,
      },
      {
        key: 'ctrl + g',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          if (!this.readOnly && this.focusedSelected.length > 0) {
            this.isGroupArt = true;
          }
        },
        preventDefault: true,
      }
    );
  }

  closeArticle = () => {
    this.addRow = false;
    this.cd.detectChanges();
  };

  onInit(e: any) {
    e.component.registerKeyHandler('escape', function () {});
  }

  visibleChange = (e) => {
    if (!e) {
      this.focusedSelected = [];
      this.onClosing.emit(false);
    }

    // if (e) {
    //   setTimeout(() => {
    //     this.gridClass.instance.focus();
    //     this.focusedRowIndex = 0;
    //   }, 1200);
    // }
  };

  dataSourceChange = () => {
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  };

  onChoosedProduct = (e) => {
    if (e) {
      let productToAdd = {
        ProductId: e.ProductId,
        InventoryDocumentId: this.form.value.InventoryDocumentId,
        StockLeft: e.Stock,
        SetStockAsZero: this.SetStockAsZero,
      };

      this.event.onShown();
      this.appService
        .postAuth(
          `inventory/documents/${productToAdd.InventoryDocumentId}/position`,
          productToAdd
        )
        .subscribe(
          () => {
            this.dataSource.reload().then((res) => {
              let index = this.dataSource._items.findIndex(
                (x) => x.ProductId == e.ProductId
              );

              if (index != -1) {
                this.focusedRowIndex = index;
                this.focusedSelected = [res[this.focusedRowIndex]];
                this.afterAddPosition();
              }
            });
            this.event.refreshData.next(true);
            this.addRow = false;
            this.event.onHidden();
            // setTimeout(() => {
            //   this.focusedRowIndex = this.dataSource._items.length;
            //   this.editRow = true;
            // }, 500);
          },
          (error) => {
            this.event.onHidden();
            this.event.httpErrorNotification(error);
          }
        );
    }
  };

  onResizeEnd = () => {
    let size = {
      widthWindow: this.widthWindow,
      heightWindow: this.heightWindow,
      fullScreen: false,
    };
    this.heightGrid = Number(this.heightWindow) - 300;
    localStorage.setItem('sizeInventory', JSON.stringify(size));
  };

  fullScreenChange = (e) => {
    let size = {
      widthWindow: this.widthWindow,
      heightWindow: this.heightWindow,
      fullScreen: e,
    };

    if (e) {
      this.heightGrid = window.innerHeight - 210;
    } else {
      this.heightGrid = window.innerHeight - 300;
    }
    localStorage.setItem('sizeInventory', JSON.stringify(size));
  };

  onScroll = (e) => {
    this.scrollPosition = e.scrollOffset.top;
  };

  @ViewChild('btnEdit') btnEdit;
  onClosingNewPosition = () => {
    this.isDuplicat = false;
    this.editRow = false;
    this.cd.detectChanges();
    this.btnEdit.instance.focus();
    setTimeout(() => {
      this.gridClass.instance.focus();
      this.cd.detectChanges();
    }, 500);
  };

  switchOrder() {
    if (this.order === 'ASC') {
      this.order = 'DESC';
      return;
    }
    this.order = 'ASC';
  }

  dataSource;
  getDocumentPosition = () => {
    if (this.form.value.InventoryDocumentId == 0) {
      return;
    }
    this.dataSource = new DataSource({
      store: AspNetData.createStore({
        key: 'InventoryPostionId',
        onBeforeSend: this.event.onBeforeSendDataSource,
        loadUrl: `${environment.domain}inventory/documents/${this.form.value.InventoryDocumentId}/positions`,
        loadParams: this.getLoadParams(),
        onAjaxError: this.event.onAjaxDataSourceError,
        onLoaded: (data) => {
          const selected: any[] = data
            .filter((el) => el.IsSelected)
            .map((el) => el.InventoryPostionId);
          if (selected.length > 0) {
            this.event.deselectAllRecords(`inventory/selection/positions`);
          }
          if (this.dontChangeIndex) {
            this.focusedSelected = [];
            this.dontChangeIndex = false;
          }

          // if (this.marginFilter == 0) {
          //   if (this.focusedSelected.length > 0) {
          //     data.forEach((field, index) => {
          //       if (
          //         field.InventoryPostionId ==
          //         this.focusedSelected[0].InventoryPostionId
          //       ) {
          //         this.focusedSelected = [field];
          //         this.focusedRowIndex = index + 1;
          //       }
          //     });
          //   }
          // }

          if (this.focusedSelected.length == 0) {
            setTimeout(() => {
              this.gridClass.focusedRowKey =
                this.gridClass.instance.getKeyByRowIndex(0);
              this.focusedRowIndex = 0;
            }, 0);
          }

          if (data.length > 0) {
            this.focusedSelected = [data[this.focusedRowIndex]];
          }
        },
        deleteUrl: `${environment.domain}inventory/documents/${this.form.value.InventoryDocumentId}/position`,
      }),
      reshapeOnPush: true,
    });
  };

  onShowStateAfterChanged(e) {
    if (e === 1) {
      this.filterMoreZero = true;
    } else {
      this.filterMoreZero = false;
    }
    this.dontChangeIndex = true;
    this.getDocumentPosition();
  }

  onNgModelChanged = () => {
    this.dontChangeIndex = true;
    this.getDocumentPosition();
  };

  getLoadParams() {
    let obj: any = {};

    (obj.orderBy = this.orderby), (obj.order = this.order);
    if (this.stockLeftType != '') {
      obj.StockLeftType = this.stockLeftType;
    }
    if (this.positionState == 1 || this.positionState == 0) {
      obj.PositionState = this.positionState;
    }
    if (this.filterMoreZero) {
      obj.StockLefGTZero = true;
    }
    if (this.useForSelection) {
      obj.UseForSelection = true;
    }

    if (this.categoryFilter != null && this.categoryFilter != '') {
      obj.CategoryId = this.categoryFilter;
    }

    switch (this.valueCriteria) {
      case 'ProductFullNameIntuition':
        obj.ProductFullNameIntuition = this.filterValue;
        break;
      case 'IndexCatalogueIntuition': {
        obj.IndexCatalogueIntuition = this.filterValue;
        break;
      }
      case 'IndexTradingIntuition': {
        obj.IndexTradingIntuition = this.filterValue;
        break;
      }
      case 'Location': {
        obj.Location = this.filterValue;
        break;
      }
    }

    return obj;
  }

  stockLeftType = '';
  onValueChangedState = (e) => {
    this.stockLeftType = e;
    this.getDocumentPosition();
  };

  positionState = null;
  onValueChangedStateDone = (e) => {
    this.positionState = e;
    this.getDocumentPosition();
  };

  order: string = 'ASC'; // asc/desc
  orderby: string = 'ProductFullName';
  dontChangeIndex: boolean = false;

  onFilterDataChanged(e) {
    if (e.selectedItem) {
      this.valueCriteria = e.selectedItem.value;
      this.orderby = e.selectedItem.value.replace('Intuition', '');
      this.order = 'ASC';
    }
    this.filterValue = e.filterValue;
    this.dontChangeIndex = true;
    this.getDocumentPosition();
  }

  focusedSelected = [];
  useForSelection: boolean = false;
  sendRequestTimer;
  isQuickPress: boolean = true;
  toDeselect = [];
  toSelect = [];

  onSelectionChanged = (e: SelectionChangedEvent) => {
    // this.focusedSelected = [];
    // this.focusedSelected.push(e.selectedRowsData[0].InventoryPostionId);
    // if(!this.useForSelection && e.component.totalCount() == e.selectedRowKeys.length){ // jak nie bylo wszystko zaznaczone i jest
    //   this.useForSelection = true
    //   this.getDocumentPosition()
    //   return
    // }else if(this.useForSelection && e.component.totalCount() > e.selectedRowKeys.length){ // jak bylo i nie jest
    //   this.useForSelection = false
    //   this.getDocumentPosition()
    //   this.toSelect.push(...e.selectedRowKeys)

    //   if (this.sendRequestTimer) {
    //     clearTimeout(this.sendRequestTimer);
    //     this.sendRequestTimer = null;
    //   }

    //   this.setSelectionTimeout()
    //   return
    // }

    this.toSelect.push(...e.currentSelectedRowKeys);
    this.toDeselect.push(...e.currentDeselectedRowKeys);

    this.toDeselect = this.event.removeDuplicates(this.toDeselect);

    if (this.sendRequestTimer) {
      clearTimeout(this.sendRequestTimer);
      this.sendRequestTimer = null;
    }

    this.setSelectionTimeout();
  };

  setSelectionTimeout() {
    this.sendRequestTimer = setTimeout(() => {
      if (this.toSelect.length) {
        this.toSelect = [];
        this.event.selectRecords(
          `inventory/selection/positions/select`,
          this.selectedRow
        );
      }
      if (this.toDeselect.length) {
        this.event.selectRecords(
          `inventory/selection/positions/unselect`,
          this.toDeselect
        );
        this.toDeselect = [];
      }
      this.sendRequestTimer = null;
    }, 500);
  }

  onRowDblClick = () => {
    this.editDok();
  };

  onFocusedRowChanged = (e) => {
    try {
      this.focusedSelected = [e.row.data];
    } catch {}
  };

  timerSel: any;
  holdDelayInMs = 250;
  isHold = false;

  onContentReady = (e) => {
    e.element.addEventListener('keyup', () => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;
    });
  };

  onInitialized = (e) => {
    e.element.addEventListener('keyup', (event) => {
      this.isHold = false;
      clearTimeout(this.timerSel);
      this.timerSel = null;

      if (event.keyCode === 32 && this.isQuickPress) {
        const grid = e.component;
        const focusedRowKey = grid.option('focusedRowKey');
        const isRowSelected = grid.isRowSelected(focusedRowKey);
        if (isRowSelected) {
          grid.deselectRows([focusedRowKey]);
          this.toDeselect.push(focusedRowKey);
        } else {
          grid.selectRows([focusedRowKey], true);
          this.toSelect.push(focusedRowKey);
        }
      }

      this.isQuickPress = true;
    });
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 1000);
  };

  focusedRowIndex = 0;

  onKeyDown = (e) => {
    if (e.event.keyCode === 113 || e.event.keyCode === 27) {
      //f2 escape
      e.event.preventDefault();
    } else if (e.event.key === 'D' && e.event.ctrlKey) {
      e.event.preventDefault();
      e.event.stopPropagation();
      this.reports.openDropdown();
    } else if (e.event.keyCode === 32) {
      e.event.preventDefault();

      if (!this.timerSel) {
        this.timerSel = setTimeout(() => {
          if (this.isHold) {
            const grid = e.component;

            const focusedRowKey = grid.option('focusedRowKey');
            const isRowSelected = grid.isRowSelected(focusedRowKey);
            if (isRowSelected) {
              grid.deselectRows([focusedRowKey]);
              this.toDeselect.push(focusedRowKey);
            } else {
              grid.selectRows([focusedRowKey], true);
              this.toSelect.push(focusedRowKey);
            }

            this.focusedRowIndex = this.focusedRowIndex + 1;

            grid.repaint();

            this.isQuickPress = false;
          }

          this.timerSel = null;
        }, this.holdDelayInMs);
      }

      this.isHold = true;
    }
    else if (      
      this.event.isSearchGridKey(
        e.event.keyCode,
        e.event.altKey,
        e.event.ctrlKey
      )
    ) {
      this.handleSearchFromGrid(e.event.key);
    }
  };


  keyDownTimeout = null;
  handleSearchFromGrid(key: string) {
    this.filterValue = this.event.setFilterValueBySearchKey(
      this.filterValue,
      key,
      false
    );

    if (this.keyDownTimeout) clearTimeout(this.keyDownTimeout);
    this.keyDownTimeout = setTimeout(() => {
      this.getDocumentPosition();
    }, 300);
  }

  onClosingConfirm = () => {
    this.isShowConfirm = false;
    this.isShowConfirmDelete = false;
    this.isSetStockAsZero = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  };

  setStockAsZero = () => {
    this.isSetStockAsZero = false;
    this.appService
      .putAuth(
        `inventory/documents/${this.form.value.InventoryDocumentId}/setStockAsZero`,
        {}
      )
      .subscribe(
        () => {
          this.getDocumentPosition();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  onInsertedFifo = () => {
    this.isGroupArt = false;
    this.isGroupDoc = false;
    this.cd.detectChanges();
    this.getDocumentPosition();
  };

  deleteDocument = () => {
    this.isShowConfirmDelete = false;
    if (this.selected) {
      this.isVisible = false;

      this.form.controls.Mode.setValue(0);
      this.selected[0].IsClosed
        ? this.form.controls.ActionType.setValue(0)
        : this.form.controls.ActionType.setValue(1);

      this.appService
        .putAuth(`inventory/documents/${this.form.value.InventoryDocumentId}`, {
          id: this.form.value.InventoryDocumentI,
        })
        .subscribe(() => {
          this.appService
            .postAuth(
              `inventory/documents/${this.form.value.InventoryDocumentId}/approval`,
              this.form.value
            )
            .subscribe(() => {});
        });
    }

    if (this.selectedId) {
      this.deleteDocumentInventory();
    }
  };

  deleteDocumentInventory = () => {
    this.appService
      .deleteAuth(`inventory/documents/${this.form.value.InventoryDocumentId}`)
      .subscribe(
        (res) => {
          this.onInserted.emit(res);
          this.isVisible = false;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  isDelete = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.groupMsgDelete = {
        name: this.focusedSelected[0].ProductFullName,
      };
      this.isDeleteRow = true;
    }
  };

  closeConfirm() {
    this.isDeleteRow = false;
    setTimeout(() => {
      this.gridClass.instance.focus();
    }, 500);
  }

  delete = () => {
    this.gridClass.instance.deleteRow(
      this.gridClass.instance.getRowIndexByKey(
        this.focusedSelected[0].InventoryPostionId
      )
    );
    this.getDocumentPosition();
    this.isDeleteRow = false;
    setTimeout(() => {
      this.gridClass.focusedRowIndex = 0;
      this.gridClass.instance.focus();
    }, 500);
  };

  onInsertedPosition = (e) => {
    this.dataSource._items.forEach((element) => {
      if (element.InventoryPostionId == e.InventoryPostionId) {
        let putPosition = {
          ProductId: e.ProductId,
          InventoryDocumentId: this.form.value.InventoryDocumentId,
          StockLeft: e.StockLeft,
          InventoryPostionId: e.InventoryPostionId,
          BuyPriceNet: e.BuyPriceNet,
          BuyPriceGross: e.BuyPriceGross,
          SerialNumber: e.SerialNumber,
          ExpirationDate: e.ExpirationDate,
          IsFinish: e.IsFinished,
        };
        this.appService
          .putAuth(
            `inventory/documents/${this.form.value.InventoryDocumentId}/position/${e.InventoryPostionId}`,
            putPosition
          )
          .subscribe(
            () => {
              setTimeout(() => {
                this.gridClass.instance.focus();
              }, 500);
              this.getDocumentPosition();
            },
            (error) => {
              this.event.httpErrorNotification(error);
            }
          );
      }
    });
  };

  editDok = () => {
    if (!this.readOnly && this.focusedSelected?.length > 0) {
      this.editRow = true;
    }
  };

  setFinishStateForSelectedPositions = (isFinish) => {
    if (this.selectedRow.length == 0) {
      this.event.showNotification(
        'info',
        this.translate.instant('inventory.pleaseOneItem')
      );
    } else {
      this.appService
        .putAuth(
          `inventory/documents/${this.form.value.InventoryDocumentId}/setFinishStateForSelectedPositions?isFinish=${isFinish}`,
          {}
        )
        .subscribe(() => {
          this.getDocumentPosition();
          this.selectedRow = [];
        });
    }
  };

  clonePosition = () => {
    if (this.focusedSelected.length > 0 && !this.readOnly) {
      this.isDuplicat = true;
    }
  };

  onDuplicatPosition = (e) => {
    let duplicatPosition = {
      ProductId: e.ProductId,
      InventoryDocumentId: this.form.value.InventoryDocumentId,
      StockLeft: e.StockLeft,
      InventoryPostionId: e.InventoryPostionId,
      BuyPriceNet: e.BuyPriceNet,
      BuyPriceGross: e.BuyPriceGross,
      SerialNumber: e.SerialNumber,
      ExpirationDate: e.ExpirationDate,
      IsFinish: e.IsFinished,
    };
    this.appService
      .postAuth(
        `inventory/documents/${this.form.value.InventoryDocumentId}/position/${e.InventoryPostionId}/clone`,
        duplicatPosition
      )
      .subscribe(
        () => {
          this.dataSource.reload(() => {});
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  sum = () => {
    if (!this.readOnly) {
      this.isSum = true;
    }
  };

  close = () => {
    this.isVisible = false;
    this.onClosing.emit(true);
  };

  cancel = () => {
    if (!this.isDisableCancel) {
      this.isShowConfirmDelete = true;
    } else {
      this.event.getJoinInventory.next(this.form.value.InventoryDocumentId);
    }
  };

  suspended = () => {
    this.form.controls.Mode.setValue(1);
    this.form.controls.ActionType.setValue(1);

    this.appService
      .postAuth(
        `inventory/documents/${this.form.value.InventoryDocumentId}/approval`,
        this.form.value
      )
      .subscribe(
        (res) => {
          this.onInserted.emit(res);
          this.isVisible = false;
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  };

  async onProductScanerInputChanged(e) {
    if (e.items.length == 0) {
      this.event.showNotification(
        'error',
        this.translate.instant('reader.noProductsFound')
      );
      return;
    }

    switch (e.mode) {
      case 0: // add
        if (e.items.length == 1) {
          this.event.onShown();
          let element;
          if (this.dataSource != null) {
            element = this.dataSource
              .items()
              .find((x) => x.ProductId == e.items[0].ProductId);
          }
          if (element) {
            // zwieksz ilosc po + 1
            let object = {
              ProductId: element.ProductId,
              InventoryDocumentId: this.form.value.InventoryDocumentId,
              StockLeft: element.StockLeft + 1,
              InventoryPostionId: element.InventoryPostionId,
              BuyPriceNet: element.BuyPriceNet,
              BuyPriceGross: element.BuyPriceGross,
              SerialNumber: element.SerialNumber,
              ExpirationDate: element.ExpirationDate,
              IsFinish: element.IsFinished,
            };

            this.appService
              .putAuth(
                `inventory/documents/${this.form.value.InventoryDocumentId}/position/${element.InventoryPostionId}`,
                object
              )
              .subscribe(
                () => {
                  this.dataSource.reload().then(() => {
                    this.afterAddPosition();
                  });
                  this.event.onHidden();
                },
                (error) => {
                  this.event.onHidden();
                  this.event.httpErrorNotification(error);
                }
              );

            return;
          } else {
            this.onChoosedProduct(e.items[0]);
            setTimeout(() => {
              this.afterAddPosition();
            }, 700);
            this.cd.detectChanges();
            this.event.onHidden();
          }
        } else {
          this.event.onHidden();
        }
        break;

      case 1: // odejmij
        if (e.items.length == 1) {
          this.event.onShown();
          const element = this.dataSource
            .items()
            .find((x) => x.ProductId == e.items[0].ProductId);
          if (element) {
            let object = {
              ProductId: element.ProductId,
              InventoryDocumentId: this.form.value.InventoryDocumentId,
              StockLeft: element.StockLeft == 0 ? 0 : element.StockLeft - 1,
              InventoryPostionId: element.InventoryPostionId,
              BuyPriceNet: element.BuyPriceNet,
              BuyPriceGross: element.BuyPriceGross,
              SerialNumber: element.SerialNumber,
              ExpirationDate: element.ExpirationDate,
              IsFinish: element.IsFinished,
            };

            this.appService
              .putAuth(
                `inventory/documents/${this.form.value.InventoryDocumentId}/position/${element.InventoryPostionId}`,
                object
              )
              .subscribe(
                () => {
                  this.dataSource.reload().then(() => {
                    this.afterAddPosition();
                  });
                  this.event.onHidden();
                },
                (error) => {
                  this.event.onHidden();
                  this.event.httpErrorNotification(error);
                }
              );
          } else {
          }
          this.event.onHidden();
        } else {
          this.event.onHidden();
        }
        break;

      case 2: // szukaj - podswietlenie znalezionego elementu / popup z produktami ?
        if (e.items.length == 1) {
          const index = this.dataSource
            .items()
            .findIndex((x) => x.ProductId == e.items[0].ProductId);
          if (index !== -1) {
            this.focusedRowIndex = index;
            this.cd.detectChanges();
            setTimeout(() => {
              this.gridClass.instance.focus();
            }, 100);
          } else {
            this.event.showNotification(
              'error',
              this.translate.instant('reader.noProductsFound')
            );
          }
        }
        break;

      case 3: // nadpisz - nadpisanie wybranego elementu ?
        if (e.items.length == 1) {
          const index = this.dataSource
            .items()
            .findIndex((x) => x.ProductId == e.items[0].ProductId);

          if (index == -1) {
            this.onChoosedProduct(e.items[0]);
            setTimeout(() => {
              this.afterAddPosition();
            }, 700);
            this.cd.detectChanges();
            this.event.onHidden();
          } else {
            let object = {
              ProductId: this.dataSource._items[index].ProductId,
              InventoryDocumentId: this.form.value.InventoryDocumentId,
              StockLeft: 1,
              InventoryPostionId:
                this.dataSource._items[index].InventoryPostionId,
              BuyPriceNet: this.dataSource._items[index].BuyPriceNet,
              BuyPriceGross: this.dataSource._items[index].BuyPriceGross,
              SerialNumber: this.dataSource._items[index].SerialNumber,
              ExpirationDate: this.dataSource._items[index].ExpirationDate,
              IsFinish: this.dataSource._items[index].IsFinished,
            };

            this.appService
              .putAuth(
                `inventory/documents/${this.form.value.InventoryDocumentId}/position/${this.dataSource._items[index].InventoryPostionId}`,
                object
              )
              .subscribe(
                () => {
                  this.dataSource.reload().then(() => {
                    this.afterAddPosition();
                  });
                  this.event.onHidden();
                },
                (error) => {
                  this.event.onHidden();
                  this.event.httpErrorNotification(error);
                }
              );
          }
        }
        // if (e.items.length == 1) {
        //   if (this.focusedSelected.length) {
        //     this.delete();

        //     this.onChoosedProduct(e.items[0]);
        //     setTimeout(() => {
        //       this.afterAddPosition();
        //     }, 700);
        //     this.cd.detectChanges();
        //   } else if (this.focusedRowIndex !== -1 && this.focusedRowIndex) {
        //     await this.delete();
        //     this.onChoosedProduct(e.items[0]);
        //     this.cd.detectChanges();
        //   } else {
        //     if (e.items[0]) {
        //       this.onChoosedProduct(e.items[0]);
        //       this.cd.detectChanges();
        //     }
        //   }
        // }
        break;

      case 4: // nowy - dodanie nowego
        if (e.items.length == 1) {
          // dodaj nowy
          this.onChoosedProduct(e.items[0]);
          this.cd.detectChanges();
        } else {
          // popup z elementami
        }
        break;

      default:
        break;
    }
  }

  collectorTransferConfig: CollectorTransferConfig = {
    CustomerId: null,
    DocumentId: null,
    DocumentType: 1, // WarehouseDocument = 1, InvoiceDocument = 4
    Date: new Date(),
  };

  isCollectorVisible: boolean = false;
  onCollectorOpened = (e) => {
    if (e) {
      this.isCollectorVisible = true;
    }
  };

  onSave = () => {
    this.submitted = true;
    if (this.dataSource._items.length == 0) {
      this.isShowConfirm = true;
    } else {
      this.form.controls.Mode.setValue(1);
      this.form.controls.ActionType.setValue(0);
      this.appService
        .postAuth(
          `inventory/documents/${this.form.value.InventoryDocumentId}/approval`,
          this.form.value
        )
        .subscribe(
          (res) => {
            this.deleteItems = [];
            this.isVisible = false;
            this.onInserted.emit(res);
            if (this.isDisableCancel) {
              this.event.getJoinInventory.next(
                this.form.value.InventoryDocumentId
              );
            }
          },
          (error) => {
            this.event.httpErrorNotification(error);
          }
        );
    }
  };

  onColumnsChanged(e) {
    this.localStorageData.columns = e;
    localStorage.setItem(
      'NewInventoryComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  setLocalStorage() {
    localStorage.setItem(
      'NewInventoryComponent',
      JSON.stringify(this.localStorageData)
    );
  }

  onOptionChanged = (e) => {
    this.event.onOptionChanged(e);
    if (e.name === 'columns' && e.fullName.indexOf('width') > -1) {
      const index1 = e.fullName.indexOf('[');
      const index2 = e.fullName.indexOf(']');
      const indexStr = e.fullName.substr(index1 + 1, index2 - index1 - 1);
      const colIndex = parseInt(indexStr);
      try {
        this.localStorageData.columns[colIndex].width = e.value;
      } catch (e) {}
    }
  };

  resizing() {}
}

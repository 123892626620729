// import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  DxButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { EventService } from 'src/app/event.service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { GlobalDate } from '../date-range/GlobalDate';
import { LibrariesModule } from 'src/app/libraries/libraries.module';
import { CoreModule } from '../core.module';
import { AppServices } from 'src/app/app-services.service';

@Component({
    selector: 'app-export-jpk-xml',
    imports: [
        CommonModule,
        DxScrollViewModule,
        DxButtonModule,
        DxPopupModule,
        DxTooltipModule,
        KeyboardShortcutsModule,
        TranslateModule,
        CoreModule,
        LibrariesModule,
        DxSelectBoxModule,
        DxTextBoxModule,
    ],
    templateUrl: './export-jpk-xml.component.html',
    styleUrl: './export-jpk-xml.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExportJpkXmlComponent {
  @Input() type: 'Fa' | 'Mag' = 'Fa';
  @Input() readOnly: boolean = false;
  @Output() onClosing = new EventEmitter();

  faType: 'Fa' | 'Mag' | 'V7' = 'Fa';
  event = inject(EventService);
  http = inject(AppServices);
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  shortcuts = [];

  currentDate: GlobalDate = new GlobalDate('lastMonth');
  // currentDateSub = this.event.currentDate
  //   .pipe(takeUntilDestroyed())
  //   .subscribe((currentDate: GlobalDate) => {
  //     this.currentDate = currentDate;
  //   });
  officeId: number = null;
  officeCode: string = null;
  submitted = false;
  IsQuarterly = false;
  Email = '';
  Phone = '';
  quarterlyDataSource = [
    {
      label: 'Miesięczny',
      value: false,
    },
    {
      label: 'Kwartalny',
      value: true,
    },
  ];
  typeDataSource = [
    {
      label: 'Fa',
      value: 'Fa',
    },
    {
      label: 'V7',
      value: 'V7',
    },
    {
      label: 'Mag',
      value: 'Mag',
      disabled: true,
    },
  ];
  ngModelChange(e: boolean) {
    if (!e) {
      this.onClosing.emit();
    }
  }

  addresses: { DateFrom: string; Phone: string; Email: string }[] = [];

  ngOnInit() {
    if (this.type === 'Mag') {
      this.faType = 'Mag';
    }

    this.http
      .getAuth(`companies/addresses`, {
        CompanyId: this.event.footerInfo.AppCompanyId,
      })
      .subscribe(
        (res: any) => {
          this.addresses = res?.data || [];
          this.setAddresses();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  setAddresses() {
    const dateTo = this.currentDate.dateTo.toISOString();
    for (let address of this.addresses) {
      // find address that have dateFrom before or equal to current date and dateTo after or equal to current date
      if (address.DateFrom <= dateTo) {
        this.Email = address.Email;
        this.Phone = address.Phone;
        return;
      }
    }
  }

  onSave() {
    this.submitted = true;
    if (!this.officeId) return;

    this.http
      .postAuthTextPlain(
        `ExportToJpk${this.faType}Xml/Export`,
        this.getDataObj()
      )
      .subscribe(
        (res: string) => {
          this.downloadXML(res);
          this.onClosing.emit();
        },
        (error) => {
          this.event.httpErrorNotification(error);
        }
      );
  }

  getDataObj() {
    let obj = {
      DateFrom: this.currentDate.dateFrom,
      DateTo: this.currentDate.dateTo,
      TaxOfficeCode: this.officeCode,
    };
    if (this.faType === 'V7') {
      obj['IsQuarterly'] = this.IsQuarterly;
      obj['Email'] = this.Email;
      obj['Phone'] = this.Phone;
    }
    return obj;
  }
  onDateRangeChoosed(e) {
    this.currentDate = e;
    this.setAddresses();
  }

  onChoosedOffice(e) {
    if (e) {
      this.officeId = e.TaxOfficeId;
      this.officeCode = e.Code;
    } else {
      this.officeId = null;
      this.officeCode = null;
    }
  }

  downloadXML(content: string) {
    const a = document.createElement('a');
    const blob = new Blob([content], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute(
      'download',
      `JPK_${this.faType}_${
        this.event.footerInfo.CompanyVat
      }_${this.getCurrentDate()}`
    );
    a.click();
  }

  getCurrentDate() {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const firstPart = new DatePipe('en-US').transform(date, 'yyyy-MM-dd');
    return `${firstPart}_${hours}:${minutes}:${seconds}`;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ShortcutInput, AllowIn } from 'ng-keyboard-shortcuts';
import { AppServices } from 'src/app/app-services.service';
import { EventService } from 'src/app/event.service';
import { PipesModule } from 'src/app/pipes/pipes.module';
import {
  DxButtonModule,
  DxDataGridModule,
  DxPopupModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { NgShortcutsComponent } from 'src/app/core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';
import {
  OfferMarginOverhead,
  OfferMarginSum,
} from './offer-margin-overhead.model';

@Component({
    selector: 'app-offer-margin-overhead',
    imports: [
        CommonModule,
        PipesModule,
        NgShortcutsComponent,
        DxDataGridModule,
        DxButtonModule,
        DxTooltipModule,
        DxPopupModule,
        TranslateModule,
    ],
    templateUrl: './offer-margin-overhead.component.html',
    styleUrl: './offer-margin-overhead.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferMarginOverheadComponent {
  @Input() readOnly = false;
  @Input() paramsObj: any = null;
  @Input() positionId: number = null;
  @Input() offerId: number = null;
  @Output() onClosing = new EventEmitter<void>();

  shortcuts: ShortcutInput[] = [];
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);
  dataSource = [];
  sum: OfferMarginSum = {
    margin: 0,
    netProfit: 0,
    grossProfit: 0,
  };
  cdr = inject(ChangeDetectorRef);
  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService
  ) {}

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.event.onHiddenPopUp(this.unicalGuid);
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'esc',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.onClosing.emit();
      },
      preventDefault: true,
    });
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit();
    }
  };

  getData() {
    this.appService
      .putAuth(`offers/documents/Analysis/${this.offerId}`, null)
      .subscribe({
        next: (res: OfferMarginOverhead[]) => {
          if (this.positionId) {
            this.dataSource = res.filter(
              (x) => x.PosiotionId === this.positionId
            );
          } else {
            this.dataSource = res;
          }
          this.sum = this.countSum(this.dataSource);
          this.cdr.detectChanges();
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  }

  countSum(data: OfferMarginOverhead[]): OfferMarginSum {
    const obj = {
      margin: 0,
      netProfit: 0,
      grossProfit: 0,
    };
    if (this.positionId) {
      const val =
        ((data[0].TotalGrossAmount - data[0].TotalGrossBuyAmount) /
          data[0].TotalGrossAmount) *
        100;
      obj.margin = Number.parseFloat(val.toFixed(2));
    } else {
      obj.margin = data[0].DocumentMargin;
    }

    obj.netProfit = data.reduce((acc, curr) => acc + curr.NetProfit, 0);
    obj.grossProfit = data.reduce((acc, curr) => acc + curr.GrossProfit, 0);

    return obj;
  }
}

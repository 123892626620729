<dx-popup
  [width]="
    event.deviceType != 'mobile' ? localStorageData.windowSize.width : '100%'
  "
  [height]="
    event.deviceType != 'mobile' ? localStorageData.windowSize.height : '90%'
  "
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  [fullScreen]="
    event.deviceType != 'mobile'
      ? localStorageData.windowSize.isFullscreen
      : false
  "
  (onResizeEnd)="onResizeEnd()"
  (onResize)="onResizeStart($event)"
  [maxHeight]="maxHeight"
  (onShown)="event.onShownPopUp(); event.setFocus(gridBank)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
  [hideOnOutsideClick]="false"
  (onInitialized)="onInit($event)"
  (fullScreenChange)="fullScreenChange($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--fullscreen2': !localStorageData.windowSize.isFullscreen,
          'absui-icon--fullscreen-off': localStorageData.windowSize.isFullscreen
        }"
        (click)="
          localStorageData.windowSize.isFullscreen =
            !localStorageData.windowSize.isFullscreen;
          fullScreen();
          cd.detectChanges()
        "
        *ngIf="event.deviceType != 'mobile'"
        style="position: relative; top: 5px; left: 6px"
        [id]="'fitToWidth' + unicalGuid"
      ></i>

      <div class="d-inline-block">
        <dx-button
          text="{{ 'buttons.close' | translate }}"
          type="danger"
          (onClick)="isVisible = false; onClosing.emit(false)"
          [id]="'cancel' + unicalGuid"
        >
        </dx-button>
      </div>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="col-md-12" style="margin-bottom: 6px">
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--add-circle"
            [id]="'btn-add' + unicalGuid"
            [text]="'buttons.add' | translate"
            [disabled]="readOnly"
            (onClick)="addAddresses()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu-folder"
            [id]="'btn-edit' + unicalGuid"
            icon="icon absui-icon--mode-edit"
            [text]="'buttons.edit' | translate"
            [disabled]="focusedSelected.length === 0 || readOnly"
            (onClick)="editRow = true"
          >
          </dx-button>

          <dx-button
            icon="icon absui-icon--form-items"
            [id]="'btn-show' + unicalGuid"
            class="btn-dx-top-menu-folder"
            [disabled]="focusedSelected.length == 0"
            [text]="'buttons.show' | translate"
            (onClick)="showRow = true"
          >
          </dx-button>

          <dx-button
            icon="icon absui-icon--highlight-off"
            [id]="'btn-delete' + unicalGuid"
            class="btn-dx-top-menu-folder"
            [disabled]="focusedSelected.length == 0 || readOnly"
            [text]="'buttons.delete' | translate"
            (onClick)="isDelete()"
          >
          </dx-button>

          <dx-tooltip
            [target]="'#btn-show' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.show" | translate }} (Shift + F2)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-add' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.add" | translate }} (Insert)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-edit' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.edit" | translate }} (F2)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-delete' + unicalGuid"
            [showEvent]="event.tooltipShowEvent"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.delete" | translate }} (Del)
            </div>
          </dx-tooltip>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            shortcut
            [height]="
              event.deviceType != 'mobile'
                ? emptyStateHeight - heightGrid
                : '100%'
            "
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            (onInitialized)="onInitialized($event)"
            [remoteOperations]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'gridAddresses' + unicalGuid"
            (onContentReady)="onContentReady($event)"
            (onKeyDown)="onKeyDown($event)"
            [(focusedRowIndex)]="focusedRowIndex"
            (onSelectionChanged)="onSelectionChanged()"
            [focusedRowEnabled]="true"
            #gridBank
            (onOptionChanged)="onOptionChanged()"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="100"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'servicesDevices.o' }}"
              [allowSorting]="false"
              dataField="IsReceived"
              alignment="left"
              width="50"
              [hidingPriority]="14"
              headerCellTemplate="O"
              cellTemplate="cellTemplateO"
            ></dxi-column>
            <div *dxTemplate="let data of 'O'">
              <div class="grid-header-wrapper" [id]="'O' + unicalGuid">
                {{ "servicesDevices.o" | translate }}
              </div>
              <dx-tooltip
                [target]="'#O' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "deliveries.received" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <div *dxTemplate="let data of 'cellTemplateO'">
              <i
                *ngIf="data.value; else falseTemplate"
                class="icon absui-icon--check check-grid"
              ></i>
            </div>
            <ng-template #falseTemplate>
              <i class="icon absui-icon--close close-grid"></i>
            </ng-template>

            <dxi-column
              caption="{{ 'delivery.form' | translate }}"
              [allowSorting]="false"
              dataField="DeliveryFormName"
              [hidingPriority]="13"
            ></dxi-column>

            <dxi-column
              caption="{{ 'userExtensions.customers' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="CustomerName"
              [hidingPriority]="12"
            ></dxi-column>

            <dxi-column
              caption="{{ 'companies.form.addressDetail.country' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="CountryCode"
              [hidingPriority]="11"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'bankBox.grid.columns.postalCode' | translate }}"
              width="200"
              dataField="PostalCode"
              [allowSorting]="false"
              [hidingPriority]="10"
            ></dxi-column>

            <dxi-column
              caption="{{ 'bankBox.grid.columns.city' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="City"
              [hidingPriority]="9"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'bankBox.grid.columns.adress' | translate }}"
              dataField="Street"
              [allowSorting]="false"
              width="200"
              [hidingPriority]="8"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'constractors.company' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="CompanyName"
              [hidingPriority]="7"
            ></dxi-column>

            <dxi-column
              caption="{{ 'documentsOrders.person' | translate }}"
              [allowSorting]="false"
              dataField="Receiver"
              [hidingPriority]="6"
              width="200"
            ></dxi-column>

            <dxi-column
              caption="{{
                'intrastate-documents.new-intrastate.telephone' | translate
              }}"
              [allowSorting]="false"
              width="200"
              dataField="Phone"
              [hidingPriority]="5"
            ></dxi-column>

            <dxi-column
              caption="{{
                'intrastate-documents.new-intrastate.email' | translate
              }}"
              [allowSorting]="false"
              width="200"
              dataField="Email"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              caption="{{ 'delivery.addressType' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="AddressType"
              [customizeText]="customizeAddressType"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              caption="{{ 'formPayment.operator' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="OperatorCode"
              [hidingPriority]="2"
            ></dxi-column>

            <dxi-column
              caption="{{ 'constractors.pickupPoint' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="DeliveryPointCode"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.descriptionTab' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="Description"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
        </div>
      </div>

      <dx-context-menu
        (onItemClick)="contextMenuClick($event)"
        (onItemRendered)="event.onItemRendered($event)"
        [dataSource]="contextMenu"
        [target]="'#gridAddresses' + unicalGuid"
        [width]="200"
      >
      </dx-context-menu>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-delivery-addresses-position-document
  title="{{ 'form-commercial-operation.addAddress' | translate }}"
  [isVisible]="addRow"
  (onClosing)="onClosingaddresse()"
  (onInserted)="onInsertedAddresses()"
  [CustomerId]="CustomerId"
  [WarehouseDocumentId]="
    typeDocument == 'warehouseDocuments' ? documentId : null
  "
  [ServiceOrderId]="typeDocument == 'services/orders' ? documentId : null"
  [OrderDocumentId]="typeDocument == 'orders' ? documentId : null"
  [InvoiceDocumentId]="typeDocument == 'invoices' ? documentId : null"
  [typeDocument]="typeDocument"
>
</app-delivery-addresses-position-document>

<app-delivery-addresses-position-document
  title="{{ 'form-commercial-operation.addAddress' | translate }}"
  [isVisible]="editRow"
  (onClosing)="onClosingaddresse()"
  (onInserted)="onInsertedAddresses()"
  [CustomerId]="CustomerId"
  [WarehouseDocumentId]="
    typeDocument == 'warehouseDocuments' ? documentId : null
  "
  [ServiceOrderId]="typeDocument == 'services/orders' ? documentId : null"
  [selectedId]="focusedSelected"
  [OrderDocumentId]="typeDocument == 'orders' ? documentId : null"
  [InvoiceDocumentId]="typeDocument == 'invoices' ? documentId : null"
  [typeDocument]="typeDocument"
>
</app-delivery-addresses-position-document>

<app-delivery-addresses-position-document
  title="{{ 'form-commercial-operation.addAddress' | translate }}"
  [isVisible]="showRow"
  (onClosing)="onClosingaddresse()"
  [readOnly]="true"
  [CustomerId]="CustomerId"
  [WarehouseDocumentId]="
    typeDocument == 'warehouseDocuments' ? documentId : null
  "
  [ServiceOrderId]="typeDocument == 'services/orders' ? documentId : null"
  [selectedId]="focusedSelected"
  [OrderDocumentId]="typeDocument == 'orders' ? documentId : null"
  [InvoiceDocumentId]="typeDocument == 'invoices' ? documentId : null"
  [typeDocument]="typeDocument"
>
</app-delivery-addresses-position-document>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
>
</app-confirm-dialog>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  [title]="event.deviceType != 'mobile' ? title : ''"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  (onShown)="event.onShownPopUp(); event.setFocus(firstFocused)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{
    id: unicalGuid,
  }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'choosed' + unicalGuid"
        *ngIf="!readOnly"
        [disabled]="readOnly"
        (onClick)="onSave()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#choosed' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (esc)
        </div>
      </dx-tooltip>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center flex-wrap flex-box-wrapper">
          <div
            class="form-group-inline-right flex-box-column"
            style="width: 100%"
          >
            <div class="form-box">
              <div class="box-title">
                <label style="width: 120px">{{
                  "deliveries.amountToOvervoltage" | translate
                }}</label>

                <dx-number-box
                  [showSpinButtons]="true"
                  [min]="0"
                  [width]="276"
                  [useLargeSpinButtons]="true"
                  [(ngModel)]="Quantity"
                  #firstFocused
                  valueChangeEvent="input"
                  [max]="maxAmount"
                  [format]="formatDivisible"
                >
                </dx-number-box>

                <wapro-keyboard-shortcuts
                  [shortcuts]="shortcuts"
                  [unicalGuid]="unicalGuid"
                  [disabled]="!isVisible"
                >
                </wapro-keyboard-shortcuts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="event.deviceType != 'mobile' ? widthWindow : '100%'"
  [height]="event.deviceType != 'mobile' ? heightWindow : '90%'"
  [position]="event.deviceType != 'mobile' ? 'center' : 'bottom'"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom', duration: 100 },
    hide: { type: 'slideOut', direction: 'bottom', duration: 50 }
  }"
  [showTitle]="true"
  [title]="event.deviceType != 'mobile' ? title : ''"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="840"
  [minHeight]="280"
  *ngIf="openNestFinished"
  (onShown)="event.onShownPopUp(); afterOpen()"
  (onHidden)="
    event.onHiddenPopUp(); Description = ''; items = [1, 2]; resetIntrastat()
  "
  [wrapperAttr]="{
    id: unicalGuid,
    'data-cy': 'form-position-invoice-document'
  }"
  (onInitialized)="onInit($event)"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      ></dx-button>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="checkOstrzegajMinMax()"
        [id]="'add' + unicalGuid"
        *ngIf="!readOnly"
        data-cy="save-button-position-invoice-document"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="beforeCloseWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#fitToWidth' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "servicesDevices.fitToWidth" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#externalFields' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "categories.externalFields" | translate }} (Ctrl + P)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "menu.navigationPanelUser.help" | translate }}
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="header-document" style="margin-top: 0px">
        <p class="p-header-info">
          {{ "form-commercial-operation.grid.articleName" | translate }}:
          <strong>{{ articleToAdd.ProductFullName }}</strong>
          | {{ "customerGroups.catalogIndex" | translate }}:
          <strong>{{ articleToAdd.IndexCatalogue }}</strong>
          @if(event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
          event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
          event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
          event.footerInfo.VariantName !== 'FAKTUROWANIE PRO'){ |
          {{ "articles.availableAmount" | translate }}:
          <strong>{{ articleToAdd.StockAvailable }}</strong>
          }
        </p>
      </div>

      <dx-tab-panel
        #tabPanel
        [(selectedIndex)]="selectedtab"
        [items]="items"
        [loop]="false"
        [animationEnabled]="false"
        [swipeEnabled]="false"
        class=""
      >
        <div *dxTemplate="let item of 'title'">
          <div class="title-temp">
            <span class="dx-tab-text" *ngIf="item == 1" id="tabToolTip1">
              {{ "companies.tabs.basicInfo" | translate }}
            </span>
            <span class="dx-tab-text" *ngIf="item == 2" id="tabToolTip2">
              {{ "form-financial-document.tabs.other" | translate }}
            </span>
            <span
              id="tabToolTip3"
              class="dx-tab-text"
              *ngIf="item == 3 && form.TaxCode == 'ZW'"
            >
              {{ "warehouseDocument.DataForJPK" | translate }}
            </span>
            <span class="dx-tab-text" *ngIf="item == 4" id="tabToolTip4">
              Akcyza, PP, Kod CN
            </span>

            <span class="dx-tab-text" *ngIf="item == 5" id="tabToolTip4">
              Intrastat
            </span>
          </div>
        </div>

        <div *dxTemplate="let name of 'item'">
          <ng-container [ngSwitch]="name">
            <div *ngSwitchCase="1">
              <div class="row" style="padding-top: 1px">
                <div
                  class="col-md-6"
                  style="
                    border-right: 1px solid var(--neutral-80, #e5e5e5);
                    padding-right: 30px;
                    padding-left: 15px;
                  "
                >
                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="!isCorrect"
                  >
                    {{ "form-commercial-operation.grid.count" | translate }}
                  </label>
                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="isCorrect"
                  >
                    {{ "warehouseDocument.correctedQuantity" | translate }}
                  </label>
                  <dx-number-box
                    [showSpinButtons]="true"
                    [min]="0"
                    [width]="220"
                    [useLargeSpinButtons]="true"
                    style="display: inline-block; margin-bottom: 6px"
                    [(ngModel)]="form.Quantity"
                    [format]="
                      IsDivisible ? formatDivisible : formatNotDivisible
                    "
                    valueChangeEvent="input"
                    [readOnly]="isOnlyPriceEdit || readOnly"
                    [max]="AmountPrevious"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (onValueChanged)="onValueChangedQuantity()"
                    (onEnterKey)="event.setFocus(gridBoxUnit)"
                    #firstFocused
                    data-cy="quantity-input-position-invoice-document"
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "articles.sizeUnit" | translate }}
                  </label>

                  <dx-drop-down-box
                    #gridBoxUnit
                    [(value)]="form.UnitId"
                    valueExpr="UnitId"
                    [deferRendering]="false"
                    displayExpr="Code"
                    [(opened)]="isGridBoxOpened"
                    [dataSource]="form.Units"
                    class=""
                    [showClearButton]="false"
                    style="
                      width: 220px;
                      display: inline-block;
                      margin-bottom: 6px;
                    "
                    (onValueChanged)="onUnitChoosed($event)"
                    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
                    [readOnly]="readOnly || isCorrect || isOnlyPriceEdit"
                  >
                    <div
                      *dxTemplate="let data of 'content'"
                      style="height: 280px"
                    >
                      <div class="row">
                        <div class="col-md-12" class="no-padding">
                          <dx-data-grid
                            [dataSource]="form.Units"
                            [wordWrapEnabled]="true"
                            [showBorders]="true"
                            shortcut
                            [height]="258"
                            class="dropDownBoxClassGrid"
                            [columnResizingMode]="'widget'"
                            [remoteOperations]="true"
                            (onRowDblClick)="onRowDblClick($event)"
                            [columnAutoWidth]="true"
                            [allowColumnResizing]="true"
                            (onFocusedRowChanged)="onFocusedRowChanged($event)"
                            [id]="'grid' + unicalGuid"
                            [focusedRowEnabled]="true"
                            #gridClass
                            [hoverStateEnabled]="true"
                          >
                            <dxo-load-panel [enabled]="false"></dxo-load-panel>
                            <dxo-scrolling
                              mode="infinite"
                              columnRenderingMode="virtual"
                              preloadEnabled="true"
                            ></dxo-scrolling>
                            <dxo-paging [pageSize]="50"></dxo-paging>
                            <dxo-selection
                              mode="single"
                              showCheckBoxesMode="always"
                            ></dxo-selection>

                            <dxi-column
                              caption="{{ 'articles.shortcut' | translate }}"
                              width="60"
                              dataField="Code"
                              [allowSorting]="false"
                              cellTemplate="Code"
                              headerCellTemplate="CodeHeaderTemplate"
                            ></dxi-column>
                            <div
                              *dxTemplate="let data of 'CodeHeaderTemplate'"
                              style="padding-left: 10px"
                            >
                              {{ "articles.shortcut" | translate }}
                            </div>
                            <div
                              *dxTemplate="let cell of 'Code'"
                              style="padding-left: 10px"
                            >
                              {{ cell.value }}
                            </div>
                            <dxi-column
                              caption="{{ 'articles.unit' | translate }}"
                              dataField="UnitName"
                              [allowSorting]="false"
                            ></dxi-column>

                            <dxi-column
                              caption="{{ 'articles.converter' | translate }}"
                              dataField="Factor"
                              [allowSorting]="false"
                              width="100"
                              alignment="left"
                            ></dxi-column>
                          </dx-data-grid>
                        </div>
                      </div>

                      <div class="bottom-drop-box popup-footer">
                        <dx-button
                          class=""
                          type="danger"
                          [text]="
                            'warehouseDocument.selectUnitAndQuantity'
                              | translate
                          "
                          [disabled]="readOnly"
                          (onClick)="chooseUnitAndQuantity()"
                        ></dx-button>

                        <dx-button
                          class=""
                          type="success"
                          [text]="'buttons.choose' | translate"
                          [disabled]="readOnly"
                          (onClick)="chooseUnit()"
                        ></dx-button>
                      </div>
                    </div>
                  </dx-drop-down-box>
                  <br />

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "customerGroups.price" | translate }}
                  </label>
                  <app-article-price-position-document
                    [isVisible]="priceVisible"
                    [readOnly]="readOnly || !ArtykCenWyborPozDok"
                    [title]="articleToAdd.ProductFullName"
                    [productId]="selected"
                    [customerId]="customerId"
                    width="220"
                    [PriceName]="form.PriceDetail.PriceName"
                    (onChoosed)="onChoosedPrice($event)"
                    [DocumentDate]="documentDate"
                  ></app-article-price-position-document>
                  <br *ngIf="event.deviceType != 'mobile'" />

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  ></label>
                  <dx-text-box
                    width="220"
                    [readOnly]="true"
                    style="display: inline-block; margin-bottom: 6px"
                    [value]="PriceDescription"
                  ></dx-text-box>
                  <br />

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    [ngClass]="{ marginMobile15: event.deviceType == 'mobile' }"
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM' &&
                      sumBy.trim() == 'Netto'
                    "
                  >
                    {{ "form-commercial-operation.grid.netAmount" | translate }}
                    <p
                      *ngIf="Currency"
                      style="display: inline-block; margin-bottom: 0px"
                    >
                      {{ Currency }}
                    </p>
                  </label>

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM' &&
                      sumBy.trim() == 'Brutto'
                    "
                  >
                    {{
                      "form-commercial-operation.grid.grossAmount" | translate
                    }}

                    <p
                      *ngIf="Currency"
                      style="display: inline-block; margin-bottom: 0px"
                    >
                      {{ Currency }}
                    </p>
                  </label>
                  <dx-number-box
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM' &&
                      sumBy.trim() == 'Netto'
                    "
                    width="220"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="
                      readOnly ||
                      (!documentType.IsIncome && !ArtykCenESDok) ||
                      (documentType.IsIncome && !ArtykCenEZDok)
                    "
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceNet"
                    [max]="event.priceMax"
                    #PriceNetInput
                    (keyup.arrowdown)="onValueNetChange()"
                    (keyup.arrowup)="onValueNetChange()"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (onInput)="onValueNetChange(); changeDescription()"
                    class=""
                    [min]="0"
                  ></dx-number-box>

                  <dx-number-box
                    width="220"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="
                      readOnly ||
                      (!documentType.IsIncome && !ArtykCenESDok) ||
                      (documentType.IsIncome && !ArtykCenEZDok)
                    "
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceGross"
                    [max]="event.priceMax"
                    (onInput)="onValueGrossChange(); changeDescription()"
                    (keyup.arrowdown)="onValueGrossChange()"
                    (keyup.arrowup)="onValueGrossChange()"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    [min]="0"
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM' &&
                      sumBy.trim() == 'Brutto'
                    "
                  ></dx-number-box>

                  <!-- margin invoice -->
                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    [ngClass]="{ marginMobile15: event.deviceType == 'mobile' }"
                    *ngIf="
                      documentType.Signature.trim() == 'FM' ||
                      documentType.Signature.trim() == 'PM'
                    "
                  >
                    {{ "articles.filter.buyPrice" | translate }}
                    <p
                      *ngIf="Currency"
                      style="display: inline-block; margin-bottom: 0px"
                    >
                      {{ Currency }}
                    </p>
                  </label>

                  <dx-number-box
                    *ngIf="
                      documentType.Signature.trim() == 'FM' ||
                      documentType.Signature.trim() == 'PM'
                    "
                    width="220"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly || ModCenZakMarza.Value == 'Nie'"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    class=""
                    [min]="0"
                    [(ngModel)]="buyPrice"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (onValueChanged)="onValueBuyPrice($event)"
                  ></dx-number-box>
                  <!--  -->

                  <br />
                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "servicesDevices.VATcode" | translate }}
                  </label>

                  <app-tax-rates
                    [dropDownBoxMode]="true"
                    [readOnly]="readOnly"
                    (onChoosed)="onBuyTaxChanged($event)"
                    [width]="220"
                    style="display: inline-block; margin-bottom: 6px"
                    [taxCode]="form.TaxCode"
                    [ngClass]="{ displayBloc: event.deviceType == 'mobile' }"
                  ></app-tax-rates> 
                  @if(form.typeOfCrossBorderSale === 'OSS' || form.typeOfCrossBorderSale === 'IOSS'){
                    <p class="p_typeOfCrossBorderSale">{{form.typeOfCrossBorderSale}}</p>
                    <br>
                  }
                  <br *ngIf="event.deviceType != 'mobile'"/>

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{
                      documentType.Signature.trim() == "FM" ||
                      documentType.Signature.trim() == "PM"
                        ? ("packageConfiguration.sellPrice" | translate)
                        : sumBy.trim() == "Netto"
                        ? ("form-commercial-operation.grid.grossAmount"
                          | translate)
                        : ("form-commercial-operation.grid.netAmount"
                          | translate)
                    }}
                    <p
                      *ngIf="Currency"
                      style="display: inline-block; margin-bottom: 0px"
                    >
                      {{ Currency }}
                    </p>
                  </label>

                  <dx-number-box
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM' &&
                      sumBy.trim() == 'Brutto'
                    "
                    width="220"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="
                      readOnly ||
                      (!documentType.IsIncome && !ArtykCenESDok) ||
                      (documentType.IsIncome && !ArtykCenEZDok)
                    "
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceNet"
                    [max]="event.priceMax"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (keyup.arrowdown)="onValueNetChange()"
                    (keyup.arrowup)="onValueNetChange()"
                    (onInput)="onValueNetChange(); changeDescription()"
                    class=""
                    #PriceNetInput
                    [min]="0"
                  ></dx-number-box>

                  <dx-number-box
                    width="220"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="
                      readOnly ||
                      (!documentType.IsIncome && !ArtykCenESDok) ||
                      (documentType.IsIncome && !ArtykCenEZDok)
                    "
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceGross"
                    [max]="event.priceMax"
                    (onInput)="onValueGrossChange(); changeDescription()"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (keyup.arrowdown)="onValueGrossChange()"
                    (keyup.arrowup)="onValueGrossChange()"
                    [min]="0"
                    *ngIf="
                      (documentType.Signature.trim() != 'FM' &&
                        documentType.Signature.trim() != 'PM' &&
                        sumBy.trim() == 'Netto') ||
                      documentType.Signature.trim() == 'FM' ||
                      documentType.Signature.trim() == 'PM'
                    "
                  ></dx-number-box>

                  <label
                    style="
                      width: 135px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "form-payment.discountMarkUp" | translate }}
                  </label>
                  <dx-number-box
                    [readOnly]="readOnly || !isPermissionToChangeDiscount"
                    valueChangeEvent="input"
                    format="#0.00%"
                    [showSpinButtons]="true"
                    [useLargeSpinButtons]="true"
                    [step]="0.01"
                    width="178"
                    style="display: inline-block"
                    [(ngModel)]="form.PriceDetail.Discount"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (onValueChanged)="changeAmount()"
                    [ngClass]="{ oneIconBeside: event.deviceType == 'mobile' }"
                  ></dx-number-box>

                  <dx-button
                    class="btn-dx-secondary btn-secondary-only-icon comercial-operation-form-position-step-1"
                    style="
                      width: 36px !important;
                      margin-left: 6px;
                      height: 28px;
                    "
                    icon="icon absui-icon--calculator-loan"
                    [disabled]="readOnly || !isPermissionToChangeDiscount"
                    [id]="'btn-discount' + unicalGuid"
                    (onClick)="isDefaultMarkupVisible = true"
                  ></dx-button>
                  <br />

                  @if(documentType.Signature.includes("FR")){
                    <div style="margin-top: 6px">
                      <label
                        style="
                          width: 135px;
                          display: inline-block;
                          text-align: right;
                          margin-right: 10px;
                        "
                      >
                        {{
                          "form-commercial-operation.classDescription" | translate
                        }}
                      </label>
                      <dx-text-box
                        [width]="220"
                        [readOnly]="readOnly"
                        style="display: inline-block; margin-bottom: 6px"
                        [(ngModel)]="form.PackageNumber"
                      ></dx-text-box>
                    </div>
                    }
                </div>

                <div class="col-md-6">
                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "articles.amountInJMag" | translate }}
                  </label>
                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly || isOnlyPriceEdit"
                    valueChangeEvent="input"
                    [showSpinButtons]="true"
                    [min]="0"
                    [width]="160"
                    [useLargeSpinButtons]="true"
                    [(ngModel)]="form.PriceDetail.Factor"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (onValueChanged)="onValueChangedQuantityBase()"
                    [format]="
                      IsDivisibleBase ? formatDivisible : formatNotDivisible
                    "
                    [max]="AmountPrevious"
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "warehouseDocument.stockUnit" | translate }}
                  </label>
                  <dx-text-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    valueChangeEvent="input"
                    [(ngModel)]="form.BaseUnitCode"
                  ></dx-text-box>
                  <br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "warehouseDocument.priceInUnitWarehouses" | translate }}
                  </label>
                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="
                      readOnly ||
                      (!documentType.IsIncome && !ArtykCenESDok) ||
                      (documentType.IsIncome && !ArtykCenEZDok)
                    "
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceNetBase"
                    [max]="event.priceMax"
                    (onInput)="onValueWarhaouseAmount()"
                    (keyup.arrowdown)="onValueWarhaouseAmount()"
                    (keyup.arrowup)="onValueWarhaouseAmount()"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    [min]="0"
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM'
                    "
                  ></dx-number-box>
                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="
                      readOnly ||
                      (!documentType.IsIncome && !ArtykCenESDok) ||
                      (documentType.IsIncome && !ArtykCenEZDok)
                    "
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    (onInput)="onValuePriceGrossBase()"
                    (keyup.arrowdown)="onValuePriceGrossBase()"
                    (keyup.arrowup)="onValuePriceGrossBase()"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    [(ngModel)]="form.PriceDetail.PriceGrossBase"
                    [max]="event.priceMax"
                    [min]="0"
                    *ngIf="
                      documentType.Signature.trim() == 'FM' ||
                      documentType.Signature.trim() == 'PM'
                    "
                  ></dx-number-box>

                  <br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "offers.priceWithDiscount" | translate }}
                  </label>
                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [ngModel]="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM'
                        ? form.PriceDetail.PriceWithDiscount
                        : event.countAmountGross(
                            form.PriceDetail.PriceWithDiscount,
                            form.TaxRate
                          )
                    "
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM'
                    "
                  >
                    {{ "form-commercial-operation.grid.netValue" | translate }}
                    <p
                      *ngIf="Currency"
                      style="display: inline-block; margin-bottom: 0px"
                    >
                      {{ Currency }}
                    </p>
                  </label>
                  <dx-number-box
                    *ngIf="
                      documentType.Signature.trim() != 'FM' &&
                      documentType.Signature.trim() != 'PM'
                    "
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.AmountNet"
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{
                      "form-commercial-operation.grid.grossValue" | translate
                    }}
                    <p
                      *ngIf="Currency"
                      style="display: inline-block; margin-bottom: 0px"
                    >
                      {{ Currency }}
                    </p>
                  </label>

                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.AmountGross"
                    (onInput)="onValueGrossAmountGross($event)"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    (keyup.arrowdown)="onValueGrossAmountGross($event)"
                    (keyup.arrowup)="onValueGrossAmountGross($event)"
                    [min]="0"
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: 138px;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                    "
                    *ngIf="
                      documentType.Signature.trim() == 'FM' ||
                      documentType.Signature.trim() == 'PM'
                    "
                  >
                    {{ "form-commercial-operation.margin" | translate }}
                  </label>

                  <dx-number-box
                    width="160"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    valueChangeEvent="input"
                    [showSpinButtons]="true"
                    [min]="0"
                    [width]="160"
                    [useLargeSpinButtons]="true"
                    [(ngModel)]="form.Margin"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    *ngIf="
                      documentType.Signature.trim() == 'FM' ||
                      documentType.Signature.trim() == 'PM'
                    "
                  ></dx-number-box>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="2">
              <div class="row">
                <div class="col-md-6 text-right">
                  <label
                    style="
                      width: 100%;
                      display: inline-block;
                      text-align: left;
                      margin-right: 10px;
                      position: relative;
                    "
                  >
                    {{ "form-financial-document.description" | translate }}
                  </label>
                  <app-description-dictionary
                    [dropDownBoxMode]="true"
                    type="O"
                    heightArea="380"
                    style="margin-bottom: 6px"
                    title="{{ 'comments' | translate }}"
                    (onChoosed)="onChoosedDescription($event)"
                    [(ngModel)]="Description"
                  >
                    <!-- #TYPEFIX
                  [widthArea]="
                      tabPanel.element.nativeElement.clientWidth / 2 - 80
                    " -->
                  </app-description-dictionary>
                  <br />
                </div>

                <div class="col-md-6 text-right" style="padding-right: 15px">
                  @if( event.footerInfo.VariantName !== 'ANY FAKTUROWANIE' &&
                  event.footerInfo.VariantName !== 'FAKTUROWANIE' &&
                  event.footerInfo.VariantName !== 'ANY FAKTUROWANIE PRO' &&
                  event.footerInfo.VariantName !== 'FAKTUROWANIE PRO' ){

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                      position: relative;
                    "
                  >
                    {{ "deliveries.serialNo" | translate }}
                  </label>
                  <dx-text-box
                    width="276"
                    style="
                      display: inline-block;
                      margin-bottom: 6px;
                      margin-top: 7px;
                    "
                    [readOnly]="readOnly"
                    valueChangeEvent="input"
                    [(ngModel)]="SerialNumber"
                  ></dx-text-box>
                  <br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      text-align: right;
                      margin-right: 10px;
                      position: relative;
                    "
                  >
                    {{ "articles.certificateDate" | translate }}
                  </label>
                  <wapro-date-box
                    [readOnly]="readOnly"
                    type="date"
                    width="276"
                    [displayFormat]="event.dateFormat"
                    style="display: inline-block"
                    [(ngModel)]="ExpirationDate"
                  ></wapro-date-box>
                  <br />

                  <dx-check-box
                    text="{{ 'articles.modifyPrices' | translate }}"
                    class="position-check"
                    style="
                      display: inline-block;
                      margin-bottom: 11px;
                      margin-top: 5px;
                      height: 20px;
                      width: 276px;
                    "
                    [readOnly]="true"
                    [(ngModel)]="positionForm.IsModifyPriceWhenDelivery"
                    [ngClass]="{ marginMobile: event.deviceType == 'mobile' }"
                  ></dx-check-box>
                  <br />
                  }

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "warehouseDocument.descriptionOfThePrice" | translate }}
                  </label>
                  <dx-text-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    valueChangeEvent="input"
                    [(ngModel)]="form.PriceDetail.PriceDescription"
                  ></dx-text-box>
                  <br />

                  <!-- transakcja transgraniczna   -->
                  @if(TransakcjeOSS.Value === "Tak" && InteliTranOSS.Value ===
                  "Tak"){
                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "warehouseDocument.crossBorderTransaction" | translate }}
                  </label>
                  <dx-select-box
                    width="225"
                    [dataSource]="optionList"
                    displayExpr="name"
                    valueExpr="code"
                    style="display: inline-block; margin-bottom: 6px"
                    [(value)]="form.typeOfCrossBorderSale"
                  ></dx-select-box>
                  <br />
                  }

                  <div class="c-frame-row">
                    <h5 style="text-align: left; margin-top: 15px">
                      {{ "warehouseDocument.defaultSellingPrices" | translate }}
                    </h5>
                  </div>

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "form-commercial-operation.net" | translate }}
                  </label>
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="positionForm.PriceNetSale"
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "form-commercial-operation.gross" | translate }}
                  </label>
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="true"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    [(ngModel)]="positionForm.PriceGrossSale"
                  ></dx-number-box>

                  <div
                    class="d-flex flex-row c-frame-row"
                    style="margin-top: 10px"
                  >
                    <h5 style="text-align: left">
                      {{ "articles.recyclingTax" | translate }}
                    </h5>
                  </div>

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "form-commercial-operation.net" | translate }}
                  </label>
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    [(ngModel)]="positionForm.PriceNetWasteManagement"
                    (onValueChanged)="onValueChangedWasteManagement('net')"
                  ></dx-number-box>
                  <br />

                  <label
                    style="
                      width: auto;
                      display: inline-block;
                      margin-bottom: 6px;
                      text-align: right;
                      margin-right: 10px;
                    "
                  >
                    {{ "form-commercial-operation.gross" | translate }}
                  </label>
                  <dx-number-box
                    width="276"
                    style="display: inline-block; margin-bottom: 6px"
                    [readOnly]="readOnly"
                    [format]="
                      documentType.IsIncome && IsCurrencyDocument
                        ? event.formatCurrency
                        : event.formatPLN
                    "
                    valueChangeEvent="input"
                    (onFocusIn)="event.onNumberBoxFocusIn($event)"
                    [(ngModel)]="positionForm.PriceGrossWasteManagement"
                    (onValueChanged)="onValueChangedWasteManagement('gross')"
                  ></dx-number-box>
                </div>
              </div>
            </div>

            <div *ngSwitchCase="4">
              <div style="padding-left: calc(50% - 160px)">
                <dx-check-box
                  text="{{
                    'articles.assortmentExemptFromExciseDuty' | translate
                  }}"
                  style="
                    display: inline-block;
                    margin-bottom: 6px;
                    height: 20px;
                  "
                  [(ngModel)]="form.Excise"
                  [readOnly]="readOnly"
                ></dx-check-box>
                <br />

                <label
                  style="
                    width: auto;
                    display: inline-block;
                    text-align: right;
                    margin-right: 10px;
                    position: relative;
                  "
                >
                  {{ "articles.cnCode" | translate }}
                </label>

                <app-intrastat-cn-codes
                  [readOnly]="readOnly"
                  [selectedCnCode]="intrastat.CNCode"
                  (onChoosed)="onCnCodeChoosedd($event)"
                  style="display: inline-block"
                ></app-intrastat-cn-codes>
                <br />

                <dx-check-box
                  text="{{
                    'articles.theRangeCoveredByPPmechanisms' | translate
                  }}"
                  style="
                    display: inline-block;
                    margin-bottom: 6px;
                    height: 20px;
                  "
                  [(ngModel)]="form.IsSplitPayment"
                  [readOnly]="true"
                ></dx-check-box>

                <br />
                <dx-check-box
                  text="{{
                    'articles.theAssortmentIsSubjectToTheAgriculturalProtectionFund'
                      | translate
                  }}"
                  style="
                    display: inline-block;
                    margin-bottom: 6px;
                    height: 20px;
                  "
                  [(ngModel)]="intrastat.IsAgriculturalFund"
                  [readOnly]="readOnly"
                ></dx-check-box>
              </div>
            </div>

            <div *ngSwitchCase="3">
              <label
                style="
                  width: 185px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{ "warehouseDocument.vehicleMileageHours" | translate }}
              </label>
              <dx-text-box
                width="276"
                style="display: inline-block; margin-bottom: 6px"
                [readOnly]="readOnly"
                valueChangeEvent="input"
                [(ngModel)]="form.Mileage"
              ></dx-text-box>
              <br />

              <label
                style="
                  width: 185px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{ "warehouseDocument.ReleaseDateForUse" | translate }}
              </label>
              <wapro-date-box
                type="date"
                width="276"
                [displayFormat]="event.dateFormat"
                [(ngModel)]="form.DateOfApprovalForUse"
                [readOnly]="readOnly"
              ></wapro-date-box>
              <br />

              <div class="c-frame-row">
                <h5
                  style="
                    margin-top: 20px;
                    text-align: left;
                    width: 100%;
                    padding-left: 10px;
                  "
                >
                  {{ "warehouseDocument.VATexemptionOnTheBasisOf" | translate }}
                </h5>
              </div>

              <dx-radio-group
                [items]="radioType"
                layout="horizontal"
                [readOnly]="readOnly"
                style="margin-left: 190px"
                [(ngModel)]="form.ExemptionCode"
                displayExpr="label"
                valueExpr="value"
              ></dx-radio-group>
              <br />

              <label
                style="
                  width: 185px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{ "warehouseDocument.DescriptionOfTheExemption" | translate }}
              </label>
              <app-description-dictionary
                [dropDownBoxMode]="true"
                type="O"
                heightArea="100"
                [widthArea]="400"
                title="{{
                  'warehouseDocument.DescriptionOfTheExemption' | translate
                }}"
                (onChoosed)="onChoosedDescriptionJPK($event)"
                [(ngModel)]="form.ExemptionDescription"
                [readOnly]="readOnly"
              ></app-description-dictionary>
            </div>

            <div *ngSwitchCase="5">
              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              ></label>

              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <dx-check-box
                  [text]="
                    'form-commercial-operation.isSubjectTointrastatDeclaration'
                      | translate
                  "
                  [(value)]="intrastat.isIntrastat"
                ></dx-check-box>
              </div>
              <br />
              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{
                  "intrastate-documents.new-position.CountryCode" | translate
                }}
                (11)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <app-country-box
                  [advancedChooser]="true"
                  style="width: 276px; margin-bottom: 0px"
                  [selectedCountry]="intrastat.CountryCode"
                  (onChoosed)="selectedCountryChange($event)"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  displayExpr="CountryCode"
                ></app-country-box>
              </div>
              <br />

              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{
                  "intrastate-documents.new-position.DeliveryCode" | translate
                }}
                (12)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <app-transaction-delivery-transport-cods
                  [selectedCode]="intrastat.DeliveryCountryCode"
                  style="width: 276px"
                  (onChoosed)="selectedDeliveryCodeChange($event)"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  [type]="'delivery'"
                ></app-transaction-delivery-transport-cods>
              </div>
              <br />

              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{
                  "intrastate-documents.new-position.TransactionCode"
                    | translate
                }}
                (13)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <app-transaction-delivery-transport-cods
                  [selectedCode]="intrastat.TransactionCode"
                  style="width: 276px"
                  (onChoosed)="selectedTransactionCodeChange($event)"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  [type]="'transaction'"
                ></app-transaction-delivery-transport-cods>
              </div>
              <br />

              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{ "articles.cnCode" | translate }}
                (14)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <app-intrastat-cn-codes
                  [selectedCnCode]="intrastat.CNCode"
                  (onChoosed)="onCnCodeChoosedd($event)"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                ></app-intrastat-cn-codes>
              </div>
              <br />

              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{
                  "intrastate-documents.new-position.TransportCode" | translate
                }}
                (15)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <app-transaction-delivery-transport-cods
                  [selectedCode]="intrastat.TransportCode"
                  style="width: 276px"
                  (onChoosed)="selectedTransportCodeChange($event)"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  [type]="'transport'"
                ></app-transaction-delivery-transport-cods>
              </div>
              <br />
              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{
                  "intrastate-documents.new-position.CountryOrginCode"
                    | translate
                }}
                (16)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <app-country-box
                  [advancedChooser]="true"
                  [selectedCountry]="intrastat.OrginCountryCode"
                  style="width: 276px; margin-bottom: 0"
                  (onChoosed)="selectedCountryOrginChange($event)"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  displayExpr="CountryCode"
                ></app-country-box>
              </div>
              <br />

              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{ "intrastate-documents.new-position.NetWeight" | translate }}
                (17)
              </label>
              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <dx-number-box
                  style="width: 276px"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  [(value)]="intrastat.MassNet"
                  format="#0.000"
                  [min]="0"
                ></dx-number-box>
              </div>
              <br />

              <label
                style="
                  width: 205px;
                  display: inline-block;
                  text-align: right;
                  margin-right: 10px;
                  position: relative;
                "
              >
                {{
                  "intrastate-documents.new-position.ForeignContractorCode"
                    | translate
                }}
                (22)
              </label>

              <div
                style="display: inline-block; width: 276px; margin-bottom: 6px"
              >
                <dx-text-box
                  style="width: 276px"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  [(value)]="nip"
                ></dx-text-box>
                <!-- <app-contractors
                  [dropDownBoxMode]="true"
                  style="width: 276px"
                  [readOnly]="readOnly || !intrastat.isIntrastat"
                  [selectedId]="intrastat.CustomerId"
                  (onChoosed)="onChoosedCustomer($event)"
                >
                </app-contractors> -->
              </div>
            </div>
          </ng-container>
        </div>
      </dx-tab-panel>

      <dx-tooltip
        *ngFor="let tabs of items"
        [target]="'#tabToolTip' + tabs"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="mouseleave"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data = data; of: 'content'">(Alt+{{ tabs }})</div>
      </dx-tooltip>
    </dx-scroll-view>
  </div>
</dx-popup>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'comercial-operation-form-position'"
></app-user-guide>

<app-discount-markup-factor
  [isVisible]="isDefaultMarkupVisible"
  (onClosed)="isDefaultMarkupVisible = false"
  [valueBefore]="form.PriceDetail.PriceNet"
  [discountMarkupPercent]="form.PriceDetail.DiscountOryginal"
  (onChoosed)="onDefaultMarkupChoosed($event)"
></app-discount-markup-factor>

<!-- pola dodatkowe -->
<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Pozycja_dokumentu_magazynowego"
  [readOnly]="readOnly"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="positionForm"
  #isValidUserExtensions
  *ngIf="isVisible"
></app-form-user-extensions>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="!isVisible || visibleUserExtensions || priceVisible"
  [unicalGuid]="unicalGuid"
></wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [objectParameters]="nestObjectParameters"
  [socketCodeFilter]="'XGHO'"
  [parentObjectParameters]="parentObjectParameters"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGHO'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-confirm-dialog
  *ngIf="isOstrzegajMinMax"
  kindDialog="question"
  [confirmText]="textOstrzegajMinMax"
  [isVisible]="true"
  (onRemoving)="onSave()"
  (onClosing)="noOstrzegajMinMax()"
></app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isConfirmVisible"
  kindDialog="question"
  [confirmText]="confirmText"
  [isVisible]="true"
  (onRemoving)="yesContinue()"
  (onClosing)="noContinue()"
></app-confirm-dialog>

<app-confirm-dialog
  *ngIf="isPriceZero"
  kindDialog="question"
  [confirmText]="'form-commercial-operation.doYouWantApprovePositionWithPrice0'"
  [isVisible]="true"
  (onRemoving)="yesApprove()"
  (onClosing)="noApprove()"
></app-confirm-dialog>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'warehouseDocument.bundlingSpecifications' | translate }}"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="true"
  [dragEnabled]="true"
  [minWidth]="730"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getData()"
  (onInit)="onInit($event)"
  (onHidden)="event.onHiddenPopUp(); this.selectedRows = []"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.addToDocument' | translate }}"
        type="success"
        style="margin-right: 6px"
        [id]="'choosed' + unicalGuid"
        *ngIf="!readOnly"
        [disabled]="readOnly"
        (onClick)="positionsConnectionSell()"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
    </div>
    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row">
        <div class="col-md-12">
          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="530"
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            [id]="'grid' + unicalGuid"
            [(focusedRowIndex)]="focusedRowIndex"
            (onSelectionChanged)="onSelectionChanged($event)"
            (onKeyDown)="onKeyDown($event)"
            [focusedRowEnabled]="true"
            #gridBank
            [hoverStateEnabled]="true"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="virtual"
              rowRenderingMode="virtual"
            ></dxo-scrolling>
            <dxo-paging [pageSize]="200" [enabled]="true"></dxo-paging>
            <dxo-selection
              [mode]="'multiple'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxi-column
              dataField="ProductName1"
              [allowSorting]="false"
              caption="{{
                'form-commercial-operation.grid.articleName' | translate
              }}"
            >
            </dxi-column>
            <dxi-column
              dataField="WarahouseName"
              [allowSorting]="false"
              [width]="200"
              caption="{{ 'menu.warehouse' | translate }}"
            >
            </dxi-column>

            <dxi-column
              dataField="ProductType"
              [allowSorting]="false"
              cellTemplate="ProductTypeStockAndReservations"
              [width]="40"
              caption="R"
              alignment="center"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'ProductTypeStockAndReservations'">
              <div
                [ngClass]="{
                  articleStatusT: data.data.ProductType === 'Towar',
                  articleStatusU: data.data.ProductType === 'Usługa',
                  articleStatusP: data.data.ProductType === 'Produkt',
                  articleStatusO: data.data.ProductType === 'Opakowanie',
                  articleStatusM: data.data.ProductType === 'Materiał',
                  articleStatusZ: data.data.ProductType === 'Zestaw'
                }"
              >
                {{ data.value.substr(0, 1) }}
              </div>
            </div>

            <dxi-column
              dataField="IndexCatalogue"
              [allowSorting]="false"
              [width]="130"
              caption="{{ 'customerGroups.catalogIndex' | translate }}"
            >
            </dxi-column>

            <dxi-column
              dataField="StockAvailable"
              [allowSorting]="false"
              [width]="130"
              caption="{{ 'plannedOrdersSummary.warehouseState' | translate }}"
            >
              <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
            </dxi-column>

            <dxi-column
              caption="{{ 'form-commercial-operation.grid.jm' | translate }}"
              width="100"
              [allowSorting]="false"
              dataField="ConnectSellUnitCode"
            >
              <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
            </dxi-column>

            <dxi-column
              [allowSorting]="false"
              caption="{{ 'articles.amountRelatedInJM' | translate }}"
            >
              <dxi-column
                [allowSorting]="false"
                dataField="ConnectSellAmount"
                [width]="150"
                caption="{{ 'articles.resultingFromBelow' | translate }}"
              >
                <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
              </dxi-column>

              <dxi-column
                [allowSorting]="false"
                dataField="ConnectSellAmountBeforeRound"
                [width]="150"
                caption="{{ 'articles.beforeRounding' | translate }}"
              >
                <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
              </dxi-column>
              <dxi-column
                [allowSorting]="false"
                dataField="ConnectSellAmountRound"
                [width]="150"
                caption="{{ 'articles.afterRounding' | translate }}"
              >
                <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
              </dxi-column>
            </dxi-column>
            <dxi-column
              [allowSorting]="false"
              caption="{{ 'articles.converter' | translate }}"
              dataField="ConnectSellUnitFactor"
            >
              <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
            </dxi-column>

            <dxi-column
              [allowSorting]="false"
              caption="{{ 'articles.amountRelatedInJMag' | translate }}"
            >
              <dxi-column
                [allowSorting]="false"
                caption="{{ 'articles.beforeRounding' | translate }}"
                dataField="PositionAmountBeforeRound"
                [width]="150"
                ><dxo-format type="fixedPoint" [precision]="4"></dxo-format>
              </dxi-column>
              <dxi-column
                [allowSorting]="false"
                caption="{{ 'articles.afterRounding' | translate }}"
                dataField="PositionAmountRound"
                [width]="150"
                ><dxo-format type="fixedPoint" [precision]="4"></dxo-format>
              </dxi-column>
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import { AppServices } from '../../../app-services.service';
import { EventService } from '../../../event.service';

@Component({
    selector: 'app-sum-inventory',
    templateUrl: './sum-inventory.component.html',
    styleUrls: ['./sum-inventory.component.scss'],
    inputs: ['isVisible', 'selectedId', 'readOnly', 'title', 'forSelected'],
    standalone: false
})
export class SumInventoryComponent implements OnInit {
  @Output() onClosing = new EventEmitter();

  isVisible;
  visibleShortcut;
  selectedId;
  readOnly;
  title;

  widthWindow = '90%';
  heightWindow = '90%';

  unicalGuid;

  radioButtonList = [
    { value: false, label: 'Tylko pozycje filtrowane' },
    { value: true, label: 'Tylko pozycje zaznaczone' },
  ];

  forSelected: boolean = false;
  dataSource = [
    {
      was: '',
      NetAmountPrevious: '',
      NetAmountLeft: '',
      NetAmountDifference: '',
    },
    {
      was: '',
      NetAmountPrevious: '',
      NetAmountLeft: '',
      NetAmountDifference: '',
    },
  ];
  shortcuts: ShortcutInput[] = [];

  constructor(
    private appService: AppServices,
    public translate: TranslateService,
    public event: EventService,
    public formBuilder: FormBuilder,
    public cd: ChangeDetectorRef
  ) {
    this.unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

    this.translate
      .get('inventory.filteredItemsOnly')
      .subscribe((text) => (this.radioButtonList[0].label = text));
    this.translate
      .get('stockHistorySummary.positionsSelected')
      .subscribe((text) => (this.radioButtonList[1].label = text));
  }

  ngOnInit(): void {}

  ngOnChanges() {
    if (this.selectedId && this.isVisible) {
      this.getData();
    }
  }

  ngAfterViewInit() {
    this.shortcuts.push({
      key: 'escape',
      allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
      command: () => {
        this.isVisible = false;
        this.onClosing.emit(false);
      },
      preventDefault: true,
    });
  }

  visibleChange = (e) => {
    if (!e) {
      this.onClosing.emit(false);
    }
  };

  getData = () => {
    this.appService
      .getAuth(
        `inventory/documents/${this.selectedId}/positionsSums?fromSelected=${this.forSelected}`
      )
      .subscribe((res) => {
        this.dataSource[0].was = this.translate.instant(
          'form-commercial-operation.grid.netValue'
        );
        this.dataSource[0].NetAmountLeft = res['NetAmountLeft'];
        this.dataSource[0].NetAmountPrevious = res['NetAmountPrevious'];
        this.dataSource[0].NetAmountDifference = res['NetAmountDifference'];

        this.dataSource[1].was = this.translate.instant(
          'form-commercial-operation.grid.grossValue'
        );
        this.dataSource[1].NetAmountLeft = res['GroussAmountLeft'];
        this.dataSource[1].NetAmountPrevious = res['GrossAmountPrevious'];
        this.dataSource[1].NetAmountDifference = res['GrossAmountDifference'];
      });
  };

  onValueChanged = () => {
    this.getData();
  };
}

<dx-popup
  [width]="520"
  [height]="486"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'ordersGroupChangeStatus.title' | translate }}"
  [visible]="true"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="saveForm()"
        [id]="'confirm' + unicalGuid"
        style="margin-right: 6px"
      ></dx-button>
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="onClosed.emit()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>
      <dx-tooltip
        [target]="'#confirm' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.confirm" | translate }} (F10)
        </div>
      </dx-tooltip>
      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="event.deviceType != 'mobile' ? '100%' : 'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
      *ngIf="form"
    >
      <form [formGroup]="form" class="popup-form">
        <div class="form-columns">
          <div class="form-column">
            <div class="form-column__item">
              <label>{{ "servicesDevices.orderStatus" | translate }}</label>
              <div class="form-control">
                <dx-select-box
                  [dataSource]="orderStatusList"
                  displayExpr="Description"
                  valueChangeEvent="input"
                  valueExpr="Code"
                  width="276"
                  formControlName="ServiceStatus"
                  itemTemplate="status"
                >
                  <div *dxTemplate="let data of 'status'">
                    <div
                      [innerHtml]="data.Description + ' (' + data.Code + ')'"
                    ></div>
                  </div>
                </dx-select-box>
              </div>
            </div>
            <div class="form-column__item">
              <div class="form-control">
                <dx-check-box
                  [text]="'ordersGroupChangeStatus.addComment' | translate"
                  [(value)]="isDescriptionActive"
                  (onValueChanged)="onActiveChanged($event)"
                ></dx-check-box>
              </div>
            </div>
          </div>
          <div class="form-column form-column__full-width">
            <div class="form-column__header">
              {{ "servicesDevices.commentToChangeStatus" | translate }}
            </div>
            <div class="form-column__item">
              <div class="form-control form-control__full-width">
                <app-description-dictionary
                  [dropDownBoxMode]="true"
                  type="T"
                  (onClosed)="
                    isServiceOrderStatusDescription = false; cd.detectChanges()
                  "
                  formControlName="Description"
                  heightArea="210"
                  [dropDownHeight]="242"
                  [readOnly]="!isDescriptionActive"
                  title="{{ 'servicesDevices.commentDictionary' | translate }}"
                  (onChoosed)="onServiceOrderStatusDescription($event)"
                >
                </app-description-dictionary>
              </div>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>

<div *ngIf="!dropDownBoxMode; else dropDownMode">
  <ng-container *ngIf="componentVisible">
    <div class="top-menu-panel">
      <ng-container *ngTemplateOutlet="topButtons"></ng-container>
    </div>

    <div class="center-panel-scroll-verticall">
      <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
        <ng-container *ngTemplateOutlet="grid"></ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="choosingDocument"
    valueExpr="ServiceDeviceId"
    [deferRendering]="false"
    displayExpr="ProductName"
    [(opened)]="isGridBoxOpened"
    [dataSource]="documentList"
    [showClearButton]="true"
    style="width: 276px;"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="onOpenedChanged($event)"
    (keydown.space)="isGridBoxOpened = true; cd.detectChanges()"
    [readOnly]="readOnly"
  >
    <div *dxTemplate="let data of 'content'" style="height: 280px;">
      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>
      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <app-wapro-selected-box
      [(ngModel)]="selectedRow"
      [dataSource]="dataSource"
      [selectedAmount]="selectedAmount()"
      [selectedAll]="isAllSelected"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      type="default"
      stylingMode="text"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="addNewRecord()"
      data-cy="service-orders-add"
    ></dx-button>
    <dx-button
      class="btn-dx-top-menu-folder"
      [id]="'btn-edit' + unicalGuid"
      icon="icon absui-icon--mode-edit"
      type="default"
      stylingMode="text"
      [disabled]="pomSelectedRow.length == 0 || readOnly || !perABD.editBtn"
      (onClick)="setEmployeeLock()"
      data-cy="service-orders-edit"
    ></dx-button>

    <dx-button
      icon="icon absui-icon--form-items"
      [id]="'contractorsShow' + unicalGuid"
      class="btn-dx-top-menu-folder"
      [disabled]="pomSelectedRow.length == 0 || !perABD.showBtn"
      type="default"
      stylingMode="text"
      (onClick)="setSingleRecordVisible('show')"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--highlight-off"
      type="default"
      stylingMode="text"
      [id]="'btn-delete' + unicalGuid"
      [disabled]="pomSelectedRow.length == 0 || readOnly || !perABD.deleteBtn"
      (onClick)="isDelete()"
      data-cy="service-orders-delete"
    ></dx-button>
    <dx-button
      class="btn-dx-top-menu"
      icon="icon absui-icon--tool-select-rectangle"
      type="default"
      stylingMode="text"
      [text]="'buttons.choose' | translate"
      [disabled]="pomSelectedRow.length == 0 || readOnly"
      [id]="'btn-choose' + unicalGuid"
      (onClick)="onChoosingRow()"
      *ngIf="dropDownBoxMode"
    ></dx-button>

    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-btn-export-excel
      [gridReference]="gridClass"
      [nameFile]="'menu.serviceOrders' | translate"
      url="services/orders"
      [paramsObj]="paramsObj"
    ></app-btn-export-excel>

    <app-product-scaner
      #scaner
      [readOnly]="readOnly"
      [showScanerContainer]="false"
      (onInputChanged)="onScanerInputChanged($event)"
      [keyboardShortcutsDisabled]="
        (!isGridBoxOpened && dropDownBoxMode) ||
        isSingleRecordVisible ||
        isExpansionListVisible
      "
      [parentId]="unicalGuid"
    ></app-product-scaner>

    <app-additional-operation
      #additionalOperation
      nameComponent="OrdersComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="
        contextMenu[8].items = event.onSetAdditionalOperation($event);
        contextMenuSignal.set(this.contextMenu)
      "
    ></app-additional-operation>

    <dx-button
      icon="icon absui-icon--tables"
      [id]="'tables' + unicalGuid"
      class="btn-dx-top-menu"
      *ngIf="additionalTables.length"
      [disabled]="pomSelectedRow.length == 0"
      [text]="'buttons.tables' | translate"
      (onClick)="isExternalTableVisible = true"
    ></dx-button>

    <dx-tooltip
      [target]="'#btn-add' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.add' | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#eDocuments' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.eDocuments' | translate }} (Ctrl + E)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-edit' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.edit' | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-delete' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.delete' | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#contractorsShow' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.show' | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#btn-choose' + unicalGuid"
      [showEvent]="event.tooltipShowEvent"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
    >
      <div *dxTemplate="let data of 'content'">
        {{ 'buttons.choose' | translate }} (Enter)
      </div>
    </dx-tooltip>

    <div class="right-header-btn">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon dx-icon-icon-refresh"
        (click)="refreshView()"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px;"
        [id]="'btn-refresh' + unicalGuid"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        type="default"
        stylingMode="text"
        *ngIf="!dropDownBoxMode"
        style="margin-right: 6px;"
        [id]="'btn-x' + unicalGuid"
        (click)="toggleDynamicFilters()"
      ></dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="column-chooser"
        type="default"
        stylingMode="text"
        (click)="columnsChooserVisible = true"
        *ngIf="permissionToChooseColums && !dropDownBoxMode"
        [id]="'btn-columns' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'refresh' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'columnSettings' | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#tables' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.tables' | translate }} (Ctrl + Y)
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          class="with_search_panel"
          #rightPanel
          [style.width]="localStorageData.mainPanelWidth"
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="openCustomDropDown"
                [items]="filterCriteria"
                [(selectedItem)]="localStorageData.filter.orderBy"
                [(filterValue)]="localStorageData.filter.value"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px;"
                (onArrowDown)="gridClass.instance.focus()"
                #searchControl
              ></app-custom-dropdown-box>

              <app-date-range
                [deleteFilter]="deleteFilter"
                [readOnly]="readOnly"
                [isPopupMode]="true"
                (onChoosed)="onDateRangeChoosed()"
                #dateRange
                [chipsVisible]="true"
              ></app-date-range>

              <!-- pokaż zlecenia przyszłe -->
              <!-- <app-custom-chips-button
                [name]="'servicesDevices.showFutureOrders' | translate"
                [labelExpr]="'label'"
                [valueExpr]="'value'"
                [selectedValueInput]="showFutureOrders()"
                [list]="showFutureOrdersList"
                (onValueChanged)="onShowFutureOrdersChanged($event)"
                [selectedValueInput]="
                  showFutureOrders()
                    ? translate.instant('yes')
                    : ''
                "
              ></app-custom-chips-button> -->

              <dx-button
                class="btn-filter-box chips-btn"
                *ngIf="valueCriteriaName != '' && valueCriteria2 != -1"
                (click)="openRightPanel()"
              >
                {{ 'servicesDevices.orderStatus' | translate }}:
                <b>{{ valueCriteriaName }}</b>
                <i
                  class="icon absui-icon--multiplication chips-close"
                  (click)="resetFiltr(null)"
                ></i>
              </dx-button>

              <app-custom-chips-button
                *ngIf="
                  localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                  localStorageData?.advancedFilter?.IsPinned
                "
                [name]="'filterPanel.template' | translate"
                [labelExpr]="'Name'"
                [valueExpr]="'FilterUsersDefinitionId'"
                [selectedValueInput]="
                  localStorageData?.advancedFilter?.Name || null
                "
                [list]="dynamicFilterUserDefinitions"
                (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
              ></app-custom-chips-button>

              <app-view-dynamic-quick-filters
                [dictionaryCode]="DynamicFilterDictionaryCode"
                [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
                [values]="
                  localStorageData?.advancedFilter?.UserFilterDefinitionValues
                "
                (onValueChanged)="onPinnedValueChanged($event)"
              ></app-view-dynamic-quick-filters>

              <dx-button
                [id]="'clearValues' + unicalGuid"
                class="btn-filter-box"
                icon="icon absui-icon--clear-filter"
                (onClick)="clearValues()"
              ></dx-button>
              <dx-tooltip
                [target]="'#clearValues' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'clearAllFilters' | translate }} (Ctrl + F10)
                </div>
              </dx-tooltip>

              <dx-data-grid
                id="gridContainer"
                #gridClass
                [dataSource]="dataSource"
                [remoteOperations]="true"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                shortcut
                [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                [allowColumnReordering]="true"
                [(selectedRowKeys)]="selectedRow"
                (onSelectionChanged)="onSelectionChanged($event)"
                [ngClass]="{ 'focus-on-detail': isDetailOnFocus }"
                (onCellClick)="onCellClick($event)"
                (onFocusedCellChanged)="onFocusedCellChanged($event)"
                [focusedRowEnabled]="true"
                [(focusedRowIndex)]="focusedRowIndex"
                (onRowDblClick)="onRowDblClick()"
                [autoNavigateToFocusedRow]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                (onContentReady)="onGridContentReady($event)"
                (onInitialized)="onInitialized($event)"
                (onKeyDown)="onKeyDown($event)"
                (onOptionChanged)="onOptionChanged($event)"
                [allowColumnResizing]="true"
                [columnResizingMode]="'widget'"
                (onEditorPreparing)="onEditorPreparing($event)"
                [id]="'grid' + unicalGuid"
                (onRowClick)="onRowClick($event)"
                data-cy="service-orders-grid"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-scrolling
                  mode="virtual"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <dxo-selection
                  [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                  showCheckBoxesMode="always"
                  selectAllMode="page"
                ></dxo-selection>
                <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
                <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

                <dxi-column
                  cellTemplate="markerConfigTemplate"
                  [fixed]="true"
                  [width]="0"
                ></dxi-column>
                <div *dxTemplate="let data of 'markerConfigTemplate'">
                  <div
                    *ngIf="data.data.Semaphore"
                    [ngClass]="{
                      editRowByMe: userId == data.data.Semaphore,
                      editRowByAnotherUser: userId != data.data.Semaphore
                    }"
                  ></div>
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'number'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  dataField="Number"
                  [allowSorting]="false"
                  cssClass="numberColumn"
                  cellTemplate="mainColumn"
                  width="150"
                  headerCellTemplate="NumbertHeaderTemplate"
                ></dxi-column>

                <div *dxTemplate="let data of 'mainColumn'">
                  <span class="hov-underline" (click)="editRecord()">
                    {{ data.value }}
                  </span>
                </div>

                <div *dxTemplate="let data of 'NumbertHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('number')"
                  >
                    {{ 'form-financial-document.number' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'servicesDevices.ServiceOrderDate' | translate }}"
                  dataField="ServiceOrderDate"
                  [allowSorting]="false"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'dateStart'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="dateStartHeaderTemplate"
                  width="110"
                ></dxi-column>
                <div *dxTemplate="let data of 'dateStartHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('dateStart')"
                  >
                    {{ 'servicesDevices.ServiceOrderDate' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{
                    'servicesDevices.serviceDeviceName' | translate
                  }}"
                  dataField="ServiceDeviceName"
                  [allowSorting]="false"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'ServiceDeviceName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="deviceHeaderTemplate"
                  width="150"
                ></dxi-column>
                <div *dxTemplate="let data of 'deviceHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('ServiceDeviceName')"
                  >
                    {{ 'servicesDevices.serviceDeviceName' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'warehouseDocument.label' | translate }}"
                  width="105"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="LabelName"
                  cellTemplate="labelTemplate"
                  renderAsync="true"
                  alignment="left"
                ></dxi-column>
                <div *dxTemplate="let data of 'labelTemplate'">
                  <app-label
                    [bgColor]="data.data.LabelBackgroundColor"
                    [color]="data.data.LabelTextColor"
                    [text]="data.data.LabelName"
                  ></app-label>
                </div>

                <dxi-column
                  caption="{{ 'servicesDevices.serviceOrderName' | translate }}"
                  dataField="ServiceOrderName"
                  [cssClass]="'sorting-unavailable'"
                  [allowSorting]="false"
                  width="150"
                ></dxi-column>

                <dxi-column
                  caption="{{ 'servicesDevices.DateReceived' | translate }}"
                  dataField="DateReceived"
                  [width]="110"
                  [allowSorting]="false"
                  dataType="date"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'dateReceived'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  [format]="{ type: event.dateFormat }"
                  headerCellTemplate="dateReceivedHeaderTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'dateReceivedHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('dateReceived')"
                  >
                    {{ 'servicesDevices.DateReceived' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'servicesDevices.DateEnd' | translate }}"
                  dataField="DateEnd"
                  [width]="110"
                  [allowSorting]="false"
                  dataType="date"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'dateEnd'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  [format]="{ type: event.dateFormat }"
                  headerCellTemplate="dateEndHeaderTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'dateEndHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('dateEnd')"
                  >
                    {{ 'servicesDevices.DateEnd' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="S"
                  dataField="ServiceOrderStatus"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="statusCell"
                  headerCellTemplate="StatusCode"
                  width="50"
                ></dxi-column>
                <div *dxTemplate="let data of 'StatusCode'">
                  <div class="grid-header-wrapper" [id]="'status' + unicalGuid">
                    {{ 'production-documents.s' | translate }}
                  </div>
                  <dx-tooltip
                    [target]="'#status' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ 'production-documents.status' | translate }}
                    </div>
                  </dx-tooltip>
                </div>

                <dxi-column
                  caption="G"
                  dataField="IsGuaranteed"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  cellTemplate="guaranteedCell"
                  headerCellTemplate="guaranteedCellHeader"
                  width="50"
                ></dxi-column>
                <div *dxTemplate="let data of 'guaranteedCellHeader'">
                  <div
                    class="grid-header-wrapper"
                    [id]="'guaranteedCellHeader' + unicalGuid"
                  >
                    G
                  </div>
                  <dx-tooltip
                    [target]="'#guaranteedCellHeader' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ 'servicesDevices.warrantyRepair' | translate }}
                    </div>
                  </dx-tooltip>
                </div>
                <div
                  *dxTemplate="let data of 'statusCell'"
                  style="text-align: center;"
                >
                  <app-status-label
                    [text]="data.data.ServiceOrderStatus"
                    [color]="data.data.StatusStringColor"
                    [backgroundColor]="data.data.StatusBackgroundColor"
                  ></app-status-label>
                </div>

                <dxi-column
                  caption="{{
                    'servicesDevices.CompletionPercentage' | translate
                  }}"
                  dataField="CompletionPercentage"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="200"
                  cellTemplate="percentCell"
                  alignment="left"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <dxi-column
                  caption="{{
                    'form-financial-document.payer-type.contractor' | translate
                  }}"
                  dataField="CustomerName"
                  [allowSorting]="false"
                  width="400"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'customerName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="customerNameHeaderTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'customerNameHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('customerName')"
                  >
                    {{
                      'form-financial-document.payer-type.contractor'
                        | translate
                    }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <div *dxTemplate="let data of 'percentCell'">
                  <dx-progress-bar
                    #progressBar
                    style="display: inline-block;"
                    width="60px"
                    [min]="0"
                    [max]="100"
                    [value]="data?.data?.CompletionPercentage"
                  ></dx-progress-bar>
                  <div class="dx-progressbar-percent">
                    {{ data.data.CompletionPercentage }}%
                  </div>
                </div>

                <div *dxTemplate="let data of 'guaranteedCell'">
                  <i
                    class="icon absui-icon--check check-grid"
                    *ngIf="data.data.IsGuaranteed"
                  ></i>
                  <i
                    class="icon absui-icon--close close-grid"
                    *ngIf="!data.data.IsGuaranteed"
                  ></i>
                </div>

                <dxi-column
                  caption="{{ 'worker' | translate }}"
                  dataField="EmployeeName"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'employeeName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  [allowSorting]="false"
                  width="250"
                  headerCellTemplate="EmployeeNameHeaderTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'EmployeeNameHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('employeeName')"
                  >
                    {{ 'worker' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'constractors.barcode' | translate }}"
                  dataField="EAN"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'EAN'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  [allowSorting]="false"
                  width="110"
                  headerCellTemplate="barcodeHeaderTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'barcodeHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('EAN')"
                  >
                    {{ 'constractors.barcode' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'workers.attachment' | translate }}"
                  [allowSorting]="false"
                  dataField="AttachmentsAmount"
                  width="80"
                  alignment="right"
                  cellTemplate="attachmentTemplate"
                  [cssClass]="'sorting-unavailable'"
                ></dxi-column>

                <div
                  *dxTemplate="let cell of 'attachmentTemplate'"
                  class="attachmentTemplate"
                >
                  <!-- <i
                    class="icon icon-absui-icon--attach-file attachment-icon grid-attachment"
                    *ngIf="cell.value"
                  ></i> -->
                  {{ cell.value }}
                </div>
                <dxi-column
                  caption="{{ 'servicesDevices.ExternalNumber' | translate }}"
                  dataField="ExternalNumber"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="150"
                ></dxi-column>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.netValue' | translate
                  }}"
                  dataField="PriceNet"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  cellTemplate="PriceNet"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'PriceNet'">
                  {{ data.data.PriceNet | amountFormatter: data }}
                </div>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.grossValue' | translate
                  }}"
                  dataField="PriceGross"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  cellTemplate="PriceGross"
                >
                  <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
                </dxi-column>
                <div *dxTemplate="let data of 'PriceGross'">
                  {{ data.data.PriceGross | amountFormatter: data }}
                </div>
                <dxi-column
                  caption="{{ 'servicesDevices.DateStart' | translate }}"
                  dataField="DateStart"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="110"
                ></dxi-column>
                <dxi-column
                  caption="{{ 'servicesDevices.productionNumber' | translate }}"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'factoryNumber'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  dataField="FactoryNumber"
                  [allowSorting]="false"
                  width="100"
                  headerCellTemplate="productionNumberHeaderTemplate"
                ></dxi-column>
                <div *dxTemplate="let data of 'productionNumberHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('factoryNumber')"
                  >
                    {{ 'servicesDevices.productionNumber' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'servicesDevices.serialNumber' | translate }}"
                  [cssClass]="
                    localStorageData.filter.orderBy === 'serialNumber'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  dataField="SerialNumber"
                  [allowSorting]="false"
                  width="100"
                  headerCellTemplate="serialNumberNumberHeaderTemplate"
                ></dxi-column>
                <div
                  *dxTemplate="let data of 'serialNumberNumberHeaderTemplate'"
                >
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('serialNumber')"
                  >
                    {{ 'servicesDevices.serialNumber' | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        localStorageData.filter.order === event.sortingRule
                          ? 'arr-down'
                          : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'bankAccounts.form.currency' | translate }}"
                  dataField="CurrencyCode"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                ></dxi-column>

                <dxi-column
                  [caption]="userExtensionColumns['Pole1'].Name"
                  dataField="Field1"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole1'] &&
                    userExtensionColumns['Pole1'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole2'].Name"
                  dataField="Field2"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole2'] &&
                    userExtensionColumns['Pole2'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole3'].Name"
                  dataField="Field3"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole3'] &&
                    userExtensionColumns['Pole3'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole4'].Name"
                  dataField="Field4"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole4'] &&
                    userExtensionColumns['Pole4'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole5'].Name"
                  dataField="Field5"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole5'] &&
                    userExtensionColumns['Pole5'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole6'].Name"
                  dataField="Field6"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole6'] &&
                    userExtensionColumns['Pole6'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole7'].Name"
                  dataField="Field7"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole7'] &&
                    userExtensionColumns['Pole7'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole8'].Name"
                  dataField="Field8"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole8'] &&
                    userExtensionColumns['Pole8'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole9'].Name"
                  dataField="Field9"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole9'] &&
                    userExtensionColumns['Pole9'].IsActive
                  "
                ></dxi-column>
                <dxi-column
                  [caption]="userExtensionColumns['Pole10'].Name"
                  dataField="Field10"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  width="100"
                  *ngIf="
                    userExtensionColumns['Pole10'] &&
                    userExtensionColumns['Pole10'].IsActive
                  "
                ></dxi-column>
              </dx-data-grid>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.asc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'columnSorting.asc' | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.desc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'columnSorting.desc' | translate }}
                </div>
              </dx-tooltip>

              <wapro-context-menu
                [dataSource]="contextMenuSignal()"
                [width]="200"
                [target]="'#grid' + unicalGuid"
                (onItemClick)="contextMenuClick($event)"
                (onItemRendered)="event.onItemRendered($event)"
              ></wapro-context-menu>
            </div>
          </div>

          <div class="row">
            <div
              class="bottom-side-panel"
              mwlResizable
              *ngIf="!dropDownBoxMode"
              #bottomSidePanel
              (resizeStart)="onResizeStartBottom()"
              (resizeEnd)="resizeEndBottom()"
              (resizing)="resizingBottom($event)"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down':
                      localStorageData.bottomPanel.isVisible,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up': !localStorageData
                      .bottomPanel.isVisible
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>
                <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          (resizeStart)="onResizeStart()"
          (resizeEnd)="resizeEnd()"
          (resizing)="resizing($event)"
          #leftPanel
          [style.width]="localStorageData.sidePanel.width"
        >
          <div class="right-panel-btn">
            <!-- <i class="icon text-icon"></i> -->
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>

          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>

          <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <app-add-orders-service
    [isVisible]="isSingleRecordVisible"
    [mode]="singleRecordMode"
    [readOnly]="readOnly || singleRecordMode === 'show'"
    (onClosing)="onSingleRecordClosing()"
    [componentNests]="singleRecordMode === 'show' ? null : componentNests"
    (onInserted)="onSingleRecordInserted($event)"
    [selectedId]="pomSelectedRow"
    title="{{ 'servicesDevices.addOrderService' | translate }}"
    [nextId]="nextId"
  ></app-add-orders-service>
</ng-template>

<app-orders-group-status-change
  *ngIf="isGroupStatusChangeVisible"
  (onClosed)="onGroupStatusChangeClosed()"
  (onSaved)="onGroupStatusChangeSaved()"
></app-orders-group-status-change>

<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Serwis_zlecenie"
  [readOnly]="readOnly || onlyShow"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="setAdditionalFields($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="additionalFieldsList"
  [groupChanged]="true"
></app-form-user-extensions>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [unicalGuid]="unicalGuid"
  [disabled]="
    (!isGridBoxOpened && dropDownBoxMode) ||
    isSingleRecordVisible ||
    isExpansionListVisible
  "
></wapro-keyboard-shortcuts>

<app-column-chooser
  [gridRef]="gridClass"
  [(visible)]="columnsChooserVisible"
  (columnsChanged)="onColumnsChanged($event)"
></app-column-chooser>

<app-confirm-dialog
  [isVisible]="isDeleteRow"
  (onRemoving)="delete()"
  (onClosing)="closeConfirm()"
  [confirmText]="
    selectedRow.length > 1 ? 'confirmDeleteMultiple' : 'confirmDelete'
  "
></app-confirm-dialog>

<app-confirm-dialog
  [isVisible]="isCopyRecord"
  (onRemoving)="copyRecord()"
  (onClosing)="showCopyDialog(false)"
  [confirmText]="
    selectedRow.length
      ? 'servicesDevices.confirmCopyMultiple'
      : translate.instant('servicesDevices.confirmCopy') +
        focusedSelected[0]?.Number +
        '?'
  "
></app-confirm-dialog>

<app-table-user-extension
  tableGroupCode="Serwis_zlecenie"
  (onClosing)="isExternalTableVisible = false"
  [isVisible]="isExternalTableVisible"
  [isPopupMode]="true"
></app-table-user-extension>

<app-table-data-user-extension
  [readOnly]="readOnly"
  [isVisible]="isShortcutTableOpened"
  tableGroupCode="Serwis_zlecenie"
  (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
  [table]="shortcutTable"
></app-table-data-user-extension>

<app-labels
  [popUpMode]="true"
  (onChoosed)="onChoosedLabel()"
  (onClosed)="isLabel = false"
  [isVisible]="isLabel"
  [readOnly]="readOnly"
  IsForServicesDoc="true"
  url="services/selection/orders/label"
></app-labels>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGSL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative;">
    <app-wapro-selected-box
      [(ngModel)]="selectedRow"
      [selectedAmount]="selectedAmount()"
      [dataSource]="dataSource"
      [selectedAll]="isAllSelected"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      (onSelectAll)="gridClass.instance.selectAll()"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [id]="'btn-add' + unicalGuid"
      [text]="'buttons.add' | translate"
      [disabled]="readOnly || !perABD.addBtn"
      (onClick)="addNewRecord()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    ></dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0;"
    ></dx-button>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false"
      (onItemClick)="contextMenuClick($event)"
    ></app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="OrdersComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="
        contextMenu[8].items = event.onSetAdditionalOperation($event);
        contextMenuSignal.set(this.contextMenu)
      "
    ></app-additional-operation>
  </div>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  ></app-filter-panel>
</div>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [width]="width - 30"
    [items]="[1, 2, 3]"
    [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
    style="margin-top: -10px;"
    class="no-padding-tab-panel right-panel-box"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
    (onSelectionChanged)="onSelectionChangedTabRight($event)"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--history-clock" *ngIf="item == 2"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 3"></i>
      </div>
    </div>

    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-tree-view
            [dataSource]="dataSourceUser"
            keyExpr="StatusCode"
            displayExpr="nameStatus"
            #treeViewAll
            [selectByClick]="true"
            selectionMode="single"
            (onItemClick)="onItemAllClick($event)"
            style="width: 100%;"
            itemTemplate="itemTemplate"
            (onSelectionChanged)="onTreeSelectionChanged($event)"
            (onContentReady)="restoreTreeConfig()"
          >
            <div *dxTemplate="let itemObj of 'itemTemplate'">
              <app-status-label
                [text]="itemObj.StatusCode"
                [color]="itemObj.StatusStringColor"
                [backgroundColor]="itemObj.StatusBackgroundColor"
                *ngIf="
                  itemObj.ServiceOrderStatusTreeId != -1 &&
                  itemObj.StatusCode != null
                "
                style="margin-right: 6px;"
              ></app-status-label>

              <div
                [innerHtml]="itemObj?.Name"
                style="display: inline-block;"
              ></div>
            </div>
          </dx-tree-view>
        </div>

        <div *ngSwitchCase="2">
          <dx-list
            #list
            [dataSource]="statusHistory"
            [height]="timelineHeight"
            [searchEnabled]="false"
            class="timeline-list"
            selectionMode="single"
            selectAllMode="allPages"
            [showSelectionControls]="true"
          >
            <div *dxTemplate="let data of 'item'">
              <div class="row">
                <div class="col-6">
                  <p class="p-timeline-date">
                    {{ data.Date | date: 'yyyy-MM-dd HH:mm:ss' }}
                  </p>
                  <p class="p-timeline-user">{{ data.UserName }}</p>
                </div>
                <div class="col-6">
                  <p class="p-timeline-type">{{ data.DocumentStatusAfter }}</p>
                </div>
              </div>
            </div>
          </dx-list>
        </div>

        <div *ngSwitchCase="3">
          <div style="padding-left: 5px; padding-right: 5px;">
            <app-filter-panel
              [deleteFilters]="deleteFilters"
              [openPanelFilters]="visibleAdvanceFilters"
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData.sidePanel.isVisible &&
                localStorageData.sidePanel.selectedIndex == 2
              "
              [focusTimeout]="500"
              [panelWidth]="localStorageData.sidePanel.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            ></app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1;"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333;"
        icon="icon absui-icon--clear-filter"
        (onClick)="resetFiltr(null)"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      ></dx-button>
    </div>
  </div>
</dx-popup>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="localStorageData.filter.orderBy"
      [(filterValue)]="localStorageData.filter.value"
      (onValueChanged)="onFilterDataChanged($event)"
      (onArrowDown)="gridClass.instance.focus()"
      #searchControl
    ></app-custom-dropdown-box>
    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': localStorageData.filter.order === 'ASC',
          'absui-icon--sort-descending': localStorageData.filter.order !== 'ASC'
        }"
        (click)="switchOrder(); getData()"
      ></i>
    </dx-button>
  </div>

  <dx-scroll-view
    width="100%"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="onScroll"
  >
    <div style="width: 700px; padding-top: 2px;">
      <app-date-range
        [deleteFilter]="deleteFilter"
        [readOnly]="readOnly"
        [isPopupMode]="true"
        (onChoosed)="onDateRangeChoosed()"
        #dateRange
        [chipsVisible]="true"
      ></app-date-range>

      <app-custom-chips-button
        *ngIf="
          localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
          localStorageData?.advancedFilter?.IsPinned
        "
        [name]="'filterPanel.template' | translate"
        [labelExpr]="'Name'"
        [valueExpr]="'FilterUsersDefinitionId'"
        [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
        [list]="dynamicFilterUserDefinitions"
        (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
      ></app-custom-chips-button>

      <app-view-dynamic-quick-filters
        [dictionaryCode]="DynamicFilterDictionaryCode"
        [pinnedFilters]="localStorageData.advancedFilter?.PinnedFilters"
        [values]="localStorageData?.advancedFilter?.UserFilterDefinitionValues"
        (onValueChanged)="onPinnedValueChanged($event)"
      ></app-view-dynamic-quick-filters>

      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="valueCriteriaName != '' && valueCriteria2 != -1"
        (click)="openRightPanel()"
      >
        {{ 'servicesDevices.orderStatus' | translate }}:
        <b>{{ valueCriteriaName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr(null)"
        ></i>
      </dx-button>
    </div>
  </dx-scroll-view>

  <dx-data-grid
    id="gridContainer"
    #gridClass
    [dataSource]="dataSource"
    [remoteOperations]="true"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    shortcut
    [height]="heightGrid - 60"
    [(selectedRowKeys)]="selectedRow"
    (onSelectionChanged)="onSelectionChanged($event)"
    [ngClass]="{ 'focus-on-detail': isDetailOnFocus }"
    (onCellClick)="onCellClick($event)"
    (onFocusedCellChanged)="onFocusedCellChanged($event)"
    [focusedRowEnabled]="true"
    [(focusedRowIndex)]="focusedRowIndex"
    (onRowDblClick)="onRowDblClick()"
    [autoNavigateToFocusedRow]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    (onContentReady)="onContentReady($event)"
    (onInitialized)="onInitialized($event)"
    (onKeyDown)="onKeyDown($event)"
    (onOptionChanged)="onOptionChanged($event)"
    [allowColumnResizing]="true"
    [columnResizingMode]="'widget'"
    [id]="'grid-mobile' + unicalGuid"
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    ></dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="Name"
      cellTemplate="mainColumn"
      width="200"
    ></dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex;">
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == data.data.Semaphore,
            'semafore-edit-by-another-user':
              userId != data.data.Semaphore && data.data.Semaphore != null
          }"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <span class="hov-underline-mobile" (click)="editRecord()">
            {{ data.data.Number }}
          </span>
          <br />
          <label class="mobile-grid-label">
            {{ 'servicesDevices.ServiceOrderDate' | translate }}:
          </label>
          <p class="mobile-grid-text">
            {{ data.data.ServiceOrderDate | date: event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label">
            {{ 'servicesDevices.serviceDeviceName' | translate }}:
          </label>
          <p class="mobile-grid-text">{{ data.data.ServiceDeviceName }}</p>
          <br />

          <label class="mobile-grid-label">
            {{ 'form-financial-document.payer-type.contractor' | translate }}:
          </label>
          <p class="mobile-grid-text">{{ data.data.CustomerName }}</p>
          <br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px);"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px;"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == focusedSelected[0].Semaphore,
            'semafore-edit-by-another-user':
              userId != focusedSelected[0].Semaphore &&
              focusedSelected[0].Semaphore != null
          }"
        ></div>
        <div class="right-details">
          <p *ngIf="focusedSelected[0]" class="nameSelectedPosition">
            {{ focusedSelected[0].Number }}
          </p>
          <label class="details-label">
            {{ 'servicesDevices.ServiceOrderDate' | translate }}:
          </label>
          <p class="details-text">
            {{ focusedSelected[0].ServiceOrderDate | date: event.dateFormat }}
          </p>

          <label class="details-label">
            {{ 'servicesDevices.serviceDeviceName' | translate }}:
          </label>
          <p class="details-text">{{ focusedSelected[0].ServiceDeviceName }}</p>

          <label class="details-label">
            {{ 'form-financial-document.payer-type.contractor' | translate }}:
          </label>
          <p class="details-text">{{ focusedSelected[0].CustomerName }}</p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ 'tasks.details' | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%;">
          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.serviceOrderName' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].ServiceOrderName }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.DateReceived' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].DateReceived }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.DateEnd' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].DateEnd }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">Status:</p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].ServiceOrderStatus }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.CompletionPercentage' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].CompletionPercentage }} %
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'worker' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].EmployeeName }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'constractors.barcode' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].EAN }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.ExternalNumber' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].ExternalNumber }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'form-commercial-operation.grid.netValue' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].PriceNet | amountFormatter: null }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'form-commercial-operation.grid.grossValue' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].PriceGross | amountFormatter: null }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.DateStart' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">
                {{ focusedSelected[0].DateStart | date: event.dateFormat }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.productionNumber' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].FactoryNumber }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'servicesDevices.serialNumber' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].SerialNumber }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top;">
              <p class="details-label text-right">
                {{ 'bankAccounts.form.currency' | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px;">
              <p class="details-text">{{ focusedSelected[0].CurrencyCode }}</p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px;">
        <div class="c-frame-row">
          <h5>{{ 'comments' | translate }}</h5>
        </div>
        <p class="details-text">{{ focusedSelected[0].Remarks }}</p>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
  </div>

  <app-add-orders-service
    [isVisible]="isSingleRecordVisible"
    [mode]="singleRecordMode"
    [readOnly]="readOnly || singleRecordMode === 'show'"
    (onClosing)="onSingleRecordClosing()"
    [componentNests]="singleRecordMode === 'show' ? null : componentNests"
    (onInserted)="onSingleRecordInserted($event)"
    [selectedId]="pomSelectedRow"
    title="{{ 'servicesDevices.addOrderService' | translate }}"
    [nextId]="nextId"
  ></app-add-orders-service>
</ng-template>

<ng-template #bottomTab>
  <dx-tab-panel
    #tabPanel
    width="100%"
    [items]="bottomPanelTabs"
    [(selectedIndex)]="selectedtabIndex"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [animationEnabled]="false"
    [swipeEnabled]="false"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div [id]="'tabToolTip' + i + unicalGuid">
        <span>{{ name }}</span>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container
        [ngSwitch]="name"
        *ngIf="
          localStorageData.bottomPanel.isVisible || event.deviceType == 'mobile'
        "
      >
        <div *ngSwitchCase="'menu.eDocuments' | translate">
          <app-e-dokuments
            #eDocumentss
            [permission]="perEdocuments"
            DocumentCode="ServiceOrderRelated"
            tableName="SERWIS_ZLECENIE"
            style="display: inline-block; width: 100%;"
            [contextObjectId]="focusedSelected[0]?.ServiceOrderId"
            [parentDocumentName]="focusedSelected[0]?.Number"
            [isShowSidePanel]="false"
            [heightGrid]="
              event.deviceType != 'mobile' ? heightBottomGrid - 65 : 545 - 65
            "
            [ContextObjectId]="
              focusedSelected.length > 0 ? focusedSelected[0]?.TaskId : null
            "
            [readOnly]="focusedSelected.length === 0"
          ></app-e-dokuments>
        </div>
        <div *ngSwitchCase="'servicesDevices.estimate' | translate">
          <dx-data-grid
            #gridEstimate
            id="gridContainer"
            [dataSource]="jobCosts"
            [wordWrapEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [showBorders]="true"
            shortcut
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            style="height: calc(100vh - 350px); margin-bottom: 10px;"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="200"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption="{{ 'servicesDevices.partName' | translate }}"
              [allowSorting]="false"
              width="350"
              dataField="ProductName"
            ></dxi-column>
            <dxi-column
              caption="{{ 'customerGroups.catalogIndex' | translate }}"
              [allowSorting]="false"
              dataField="IndexCatalogue"
              [hidingPriority]="13"
            ></dxi-column>
            <dxi-column
              caption="{{ 'articles.R' | translate }}"
              width="40"
              [allowSorting]="false"
              dataField="ProductType"
              cellTemplate="IsDefault"
              [hidingPriority]="15"
            ></dxi-column>
            <div *dxTemplate="let data of 'IsDefault'">
              <div
                [ngClass]="{
                  articleStatusT: data.data.ProductType === 'Towar',
                  articleStatusU: data.data.ProductType === 'Usługa',
                  articleStatusP: data.data.ProductType === 'Produkt',
                  articleStatusO: data.data.ProductType === 'Opakowanie',
                  articleStatusM: data.data.ProductType === 'Materiał',
                  articleStatusZ: data.data.ProductType === 'Zestaw'
                }"
              >
                {{ data.value.substr(0, 1) }}
              </div>
            </div>
            <dxi-column
              caption="{{ 'form-commercial-operation.grid.count' | translate }}"
              [allowSorting]="false"
              width="100"
              dataField="Amount"
              [hidingPriority]="14"
            ></dxi-column>
            <dxi-column
              caption="{{ 'articles.availableAmount' | translate }}"
              width="100"
              [allowSorting]="false"
              dataField="StockAvailable"
              renderAsync="true"
              [hidingPriority]="13"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-commercial-operation.grid.jm' | translate }}"
              [allowSorting]="false"
              width="100"
              dataField="Unit"
              [hidingPriority]="12"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netAmount' | translate
              }}"
              [allowSorting]="false"
              width="110"
              dataField="PriceNet"
              [hidingPriority]="11"
            ></dxi-column>
            <dxi-column
              caption="{{ 'menu.vatPrices' | translate }}"
              width="100"
              dataField="TaxCode"
              [allowSorting]="false"
              [hidingPriority]="10"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossAmount' | translate
              }}"
              [allowSorting]="false"
              width="110"
              dataField="PriceGross"
              [hidingPriority]="9"
            ></dxi-column>
            <dxi-column
              caption="F"
              width="40"
              [allowSorting]="false"
              dataField="IsInvoiced"
              cellTemplate="IsInvoiced"
              headerCellTemplate="IsInvoicedHeader"
              [hidingPriority]="8"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'IsInvoicedHeader'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'IsInvoicedHeader' + unicalGuid"
              >
                F
              </div>
              <dx-tooltip
                [target]="'#IsInvoicedHeader' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'servicesDevices.partInvoiced' | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="B"
              width="40"
              [allowSorting]="false"
              dataField="IsBaseService"
              cellTemplate="IsBaseService"
              [hidingPriority]="4"
              headerCellTemplate="IsBaseServiceHeader"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'IsBaseServiceHeader'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'IsBaseServiceHeader' + unicalGuid"
              >
                B
              </div>
              <dx-tooltip
                [target]="'#IsBaseServiceHeader' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'servicesDevices.baseService' | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="U"
              width="40"
              [allowSorting]="false"
              dataField="AddToServiceOrder"
              cellTemplate="AddToServiceOrder"
              [hidingPriority]="7"
              headerCellTemplate="AddToServiceOrderHeader"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'AddToServiceOrderHeader'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'AddToServiceOrderHeader' + unicalGuid"
              >
                U
              </div>
              <dx-tooltip
                [target]="'#AddToServiceOrderHeader' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'servicesDevices.addValueToBaseService' | translate }}
                </div>
              </dx-tooltip>
            </div>

            <dxi-column
              caption="{{ 'production-documents.warehouse' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="WarehouseName"
              [hidingPriority]="6"
            ></dxi-column>
            <dxi-column
              caption="{{ 'warehouse.location' | translate }}"
              [allowSorting]="false"
              width="80"
              dataField="Location"
              [hidingPriority]="5"
            ></dxi-column>
            <dxi-column
              caption="{{
                'servicesDevices.warehouseDocumentNumber' | translate
              }}"
              [allowSorting]="false"
              width="200"
              dataField="WarehouseDocumentNumber"
              [hidingPriority]="4"
            ></dxi-column>
            <dxi-column
              caption="{{
                'loyaltyProram.commercialDocumentNumber' | translate
              }}"
              [allowSorting]="false"
              width="200"
              dataField="InvoiceDocumentNumber"
              [hidingPriority]="3"
            ></dxi-column>
            <dxi-column
              caption="{{ 'servicesDevices.orderDocumentNumber' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="OrderDocumentNumber"
              [hidingPriority]="2"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="Description"
              [hidingPriority]="1"
            ></dxi-column>
            <dxi-column
              caption="R/N/%"
              [allowSorting]="false"
              width="100"
              dataField="Discount"
              [hidingPriority]="0"
            ></dxi-column>

            <div *dxTemplate="let data of 'IsInvoiced'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.IsInvoiced"
              ></i>
              <i
                class="icon absui-icon--close close-grid"
                *ngIf="!data.data.IsInvoiced"
              ></i>
            </div>

            <div *dxTemplate="let data of 'IsBaseService'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.IsBaseService"
              ></i>
              <i
                class="icon absui-icon--close close-grid"
                *ngIf="!data.data.IsBaseService"
              ></i>
            </div>

            <div *dxTemplate="let data of 'AddToServiceOrder'">
              <i
                class="icon absui-icon--check check-grid"
                *ngIf="data.data.AddToServiceOrder"
              ></i>
              <i
                class="icon absui-icon--close close-grid"
                *ngIf="!data.data.AddToServiceOrder"
              ></i>
            </div>
          </dx-data-grid>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'servicesDevices.complaintItems' | translate">
          <dx-data-grid
            [dataSource]="complaintItems"
            #complaintItemsGrid
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [height]="heightGrid"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="true"
            [columnResizingMode]="'widget'"
            [id]="'gridC' + unicalGuid"
            (onOptionChanged)="onOptionChanged($event)"
            style="height: calc(100vh - 250px); margin-bottom: 10px;"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="200"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.articleName' | translate
              }}"
              [allowSorting]="false"
              dataField="FullName"
              width="200"
              [hidingPriority]="18"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-commercial-operation.grid.count' | translate }}"
              [allowSorting]="false"
              width="100"
              dataField="Amount"
              [hidingPriority]="17"
            ></dxi-column>
            <dxi-column
              caption="{{ 'form-commercial-operation.grid.jm' | translate }}"
              [allowSorting]="false"
              width="100"
              dataField="Unit"
              [hidingPriority]="16"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netAmount' | translate
              }}"
              [allowSorting]="false"
              width="110"
              dataField="PriceNet"
              cellTemplate="PriceNet"
              *ngIf="SerwisZlecCeny"
              [hidingPriority]="15"
            ></dxi-column>
            <dxi-column
              caption="{{ 'menu.vatPrices' | translate }}"
              width="100"
              dataField="TaxCode"
              *ngIf="SerwisZlecCeny"
              [allowSorting]="false"
              [hidingPriority]="14"
            ></dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossAmount' | translate
              }}"
              [allowSorting]="false"
              width="110"
              dataField="PriceGross"
              cellTemplate="PriceGross"
              *ngIf="SerwisZlecCeny"
              [hidingPriority]="13"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              dataField="NetValue"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalNetAmountCell"
              *ngIf="SerwisZlecCeny"
              [hidingPriority]="12"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalNetAmountCell'">
              {{ data.data.NetValue | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              dataField="GrossValue"
              [allowSorting]="false"
              width="150"
              *ngIf="SerwisZlecCeny"
              [hidingPriority]="11"
            >
              <dxo-format type="fixedPoint" [precision]="2"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalGrossAmountCell'">
              {{ data.data.GrossValue | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{ 'workers.documentNumber' | translate }}"
              [allowSorting]="false"
              width="150"
              dataField="DocumentNumber"
              [hidingPriority]="10"
            ></dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.description' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="Description"
              [hidingPriority]="9"
            ></dxi-column>

            <dxi-column
              caption="{{ 'bankAccounts.form.currency' | translate }}"
              dataField="CurrencySymbol"
              [allowSorting]="false"
              width="100"
              [hidingPriority]="8"
            ></dxi-column>

            <dxi-column
              caption="{{ 'baseCurrencies.currencyValue' | translate }}"
              dataField="CurrencyFactor"
              [allowSorting]="false"
              width="100"
              [hidingPriority]="7"
            ></dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalNetAmountCurrency' | translate
              }}"
              *ngIf="SerwisZlecCeny"
              dataField="NetValueCurrency"
              [allowSorting]="false"
              width="150"
              cellTemplate="TotalNetAmountCurrency"
              [hidingPriority]="6"
            >
              <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalNetAmountCurrency'">
              {{ data.data.NetValueCurrency | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalGrossAmountCurrency' | translate
              }}"
              *ngIf="SerwisZlecCeny"
              dataField="GrossValueCurrency"
              [allowSorting]="false"
              width="150"
              cellTemplate="TotalGrossAmountCurrency"
              [hidingPriority]="5"
            >
              <dxo-format type="fixedPoint" [precision]="4"></dxo-format>
            </dxi-column>
            <div *dxTemplate="let data of 'TotalGrossAmountCurrency'">
              {{ data.data.GrossValueCurrency | amountFormatter: data }}
            </div>

            <dxi-column
              caption="{{ 'customerGroups.catalogIndex' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="IndexCatalogue"
              [hidingPriority]="4"
            ></dxi-column>
            <dxi-column
              caption="{{ 'customerGroups.tradingIndex' | translate }}"
              width="130"
              [allowSorting]="false"
              dataField="IndexTrading"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              caption="R/N 1"
              width="130"
              [allowSorting]="false"
              dataField="Discount1"
              [hidingPriority]="2"
            ></dxi-column>
            <dxi-column
              caption="R/N 2"
              width="130"
              [allowSorting]="false"
              dataField="Discount2"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              caption="{{ 'warehouse.location' | translate }}"
              [allowSorting]="false"
              width="80"
              dataField="Location"
              [hidingPriority]="0"
            ></dxi-column>

            <div *dxTemplate="let data of 'PriceNet'">
              {{ data.data.PriceNet | currency: ' ' }}
            </div>

            <div *dxTemplate="let data of 'PriceGross'">
              {{ data.data.PriceGross | currency: ' ' }}
            </div>
          </dx-data-grid>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'servicesDevices.historyStatusChanges' | translate">
          <dx-data-grid
            #gridHistory
            id="gridContainer"
            [dataSource]="statusHistory"
            [wordWrapEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [showBorders]="true"
            shortcut
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            style="height: calc(100vh - 350px); margin-bottom: 10px;"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="200"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
            <dxi-column
              caption="{{ 'users.userName' | translate }}"
              [allowSorting]="false"
              width="350"
              dataField="UserName"
            ></dxi-column>
            <dxi-column
              caption="{{ 'deliveries.data' | translate }}"
              [allowSorting]="false"
              width="150"
              dataField="Date"
              dataType="date"
              [format]="{ type: 'yyyy-MM-dd HH:mm:ss' }"
              [hidingPriority]="5"
            ></dxi-column>
            <dxi-column
              caption="{{ 'worker' | translate }}"
              [allowSorting]="false"
              width="110"
              dataField="EmployeeName"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              caption="Z"
              [allowSorting]="false"
              width="40"
              dataField="DocumentStatusCodeBefore"
              alignment="center"
              cellTemplate="statusCell"
              headerCellTemplate="DocumentStatusCodeBefore"
              [hidingPriority]="3"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'DocumentStatusCodeBefore'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'DocumentStatusCodeBefore' + unicalGuid"
              >
                {{ 'status-type.from' | translate }}
              </div>
              <dx-tooltip
                [target]="'#DocumentStatusCodeBefore' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'status-type.statusChangeFrom' | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="Na"
              [allowSorting]="false"
              width="40"
              dataField="DocumentStatusCodeAfter"
              alignment="center"
              cellTemplate="statusCellAfter"
              headerCellTemplate="DocumentStatusCodeAfter"
              [hidingPriority]="2"
            ></dxi-column>
            <div
              *dxTemplate="let data of 'DocumentStatusCodeAfter'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper"
                [id]="'DocumentStatusCodeAfter' + unicalGuid"
              >
                {{ 'status-type.to' | translate }}
              </div>
              <dx-tooltip
                [target]="'#DocumentStatusCodeAfter' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'status-type.statusChangeTo' | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="{{ 'status-type.statusBefore' | translate }}"
              [allowSorting]="false"
              dataField="DocumentStatusBefore"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              caption="{{ 'status-type.statusAfter' | translate }}"
              [allowSorting]="false"
              dataField="DocumentStatusAfter"
              [hidingPriority]="0"
            ></dxi-column>

            <dxi-column
              caption="{{ 'offers.comment' | translate }}"
              [allowSorting]="false"
              dataField="Comment"
              [hidingPriority]="0"
            ></dxi-column>

            <div *dxTemplate="let data of 'statusCell'">
              <div
                [ngClass]="{
                  statusN:
                    data.data.DocumentStatusCodeBefore != 'Z' &&
                    data.data.DocumentStatusCodeBefore != 'R' &&
                    data.data.DocumentStatusCodeBefore != 'O' &&
                    data.data.DocumentStatusCodeBefore != 'Y',
                  statusZ: data.data.DocumentStatusCodeBefore == 'Z',
                  statusR: data.data.DocumentStatusCodeBefore == 'R',
                  statusO: data.data.DocumentStatusCodeBefore == 'O',
                  statusY: data.data.DocumentStatusCodeBefore == 'Y'
                }"
              >
                {{ data.data.DocumentStatusCodeBefore }}
              </div>
            </div>

            <div *dxTemplate="let data of 'statusCellAfter'">
              <div
                [ngClass]="{
                  statusN:
                    data.data.DocumentStatusCodeAfter != 'Z' &&
                    data.data.DocumentStatusCodeAfter != 'R' &&
                    data.data.DocumentStatusCodeAfter != 'O' &&
                    data.data.DocumentStatusCodeAfter != 'Y',
                  statusZ: data.data.DocumentStatusCodeAfter == 'Z',
                  statusR: data.data.DocumentStatusCodeAfter == 'R',
                  statusO: data.data.DocumentStatusCodeAfter == 'O',
                  statusY: data.data.DocumentStatusCodeAfter == 'Y'
                }"
              >
                {{ data.data.DocumentStatusCodeAfter }}
              </div>
            </div>
          </dx-data-grid>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'form-financial-document.description' | translate">
          <dx-text-area
            [minHeight]="heightBottomGrid"
            [value]="descriptionOrder"
            [readOnly]="true"
            style="max-width: none !important; width: 100%;"
          ></dx-text-area>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div
          *ngSwitchCase="
            'servicesDevices.serviceActivitiesPerformed' | translate
          "
        >
          <dx-text-area
            [minHeight]="heightBottomGrid"
            [value]="repairDescription"
            [readOnly]="true"
            style="max-width: none !important; width: 100%;"
          ></dx-text-area>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div
          *ngSwitchCase="'servicesDevices.serviceRecommendations' | translate"
        >
          <dx-text-area
            [minHeight]="heightBottomGrid"
            [value]="serviceOrderMessage"
            [readOnly]="true"
            style="max-width: none !important; width: 100%;"
          ></dx-text-area>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'comments' | translate">
          <dx-text-area
            [minHeight]="heightBottomGrid"
            [value]="remarks"
            [readOnly]="true"
            style="max-width: none !important; width: 100%;"
          ></dx-text-area>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div
          *ngSwitchCase="
            localStorageData?.additionalTable?.text || 'articles.externalTable'
              | translate
          "
        >
          <app-table-user-extension
            [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            (onTableChanged)="onAdditionalTableChanged($event)"
            (onFocusElementChanged)="onFocusElementChanged($event)"
            [selectedTable]="localStorageData.additionalTable"
            *ngIf="selectedtabIndex == 6 && pomSelectedRow.length"
          ></app-table-user-extension>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'deliveries.deliveryAddresses' | translate">
          <dx-data-grid
            #gridAddresses
            [dataSource]="deliveryAddresses"
            [wordWrapEnabled]="true"
            [showBorders]="true"
            shortcut
            [focusedRowEnabled]="true"
            [allowColumnResizing]="true"
            [columnResizingMode]="'widget'"
            [id]="'gridAddresses' + unicalGuid"
            (onOptionChanged)="onOptionChanged($event)"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            style="height: calc(100vh - 350px); margin-bottom: 10px;"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling mode="infinite"></dxo-scrolling>
            <dxo-paging [pageSize]="100"></dxo-paging>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'servicesDevices.o' }}"
              [allowSorting]="false"
              dataField="IsReceived"
              alignment="left"
              width="50"
              [hidingPriority]="14"
              headerCellTemplate="O"
              cellTemplate="cellTemplateO"
            ></dxi-column>
            <div *dxTemplate="let data of 'O'">
              <div class="grid-header-wrapper" [id]="'O' + unicalGuid">
                {{ 'servicesDevices.o' | translate }}
              </div>
              <dx-tooltip
                [target]="'#O' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ 'deliveries.received' | translate }}
                </div>
              </dx-tooltip>
            </div>
            <div *dxTemplate="let data of 'cellTemplateO'">
              <i
                *ngIf="data.value; else falseTemplate"
                class="icon absui-icon--check check-grid"
              ></i>
            </div>
            <ng-template #falseTemplate>
              <i class="icon absui-icon--close close-grid"></i>
            </ng-template>

            <dxi-column
              caption="{{ 'delivery.form' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="DeliveryFormName"
              [hidingPriority]="13"
            ></dxi-column>

            <dxi-column
              caption="{{ 'userExtensions.customers' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="CustomerName"
              [hidingPriority]="12"
            ></dxi-column>

            <dxi-column
              caption="{{ 'companies.form.addressDetail.country' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="CountryCode"
              [hidingPriority]="11"
            ></dxi-column>

            <dxi-column
              caption="{{ 'bankBox.grid.columns.postalCode' | translate }}"
              width="200"
              dataField="PostalCode"
              [allowSorting]="false"
              [hidingPriority]="10"
            ></dxi-column>

            <dxi-column
              caption="{{ 'bankBox.grid.columns.city' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="City"
              [hidingPriority]="9"
            ></dxi-column>

            <dxi-column
              caption="{{ 'bankBox.grid.columns.adress' | translate }}"
              dataField="Street"
              [allowSorting]="false"
              width="200"
              [hidingPriority]="8"
            ></dxi-column>

            <dxi-column
              caption="{{ 'constractors.company' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="CompanyName"
              [hidingPriority]="7"
            ></dxi-column>

            <dxi-column
              caption="{{ 'documentsOrders.person' | translate }}"
              [allowSorting]="false"
              dataField="Receiver"
              [hidingPriority]="6"
              width="200"
            ></dxi-column>

            <dxi-column
              caption="{{
                'intrastate-documents.new-intrastate.telephone' | translate
              }}"
              [allowSorting]="false"
              width="200"
              dataField="Phone"
              [hidingPriority]="5"
            ></dxi-column>

            <dxi-column
              caption="{{
                'intrastate-documents.new-intrastate.email' | translate
              }}"
              [allowSorting]="false"
              width="200"
              dataField="Email"
              [hidingPriority]="4"
            ></dxi-column>

            <dxi-column
              caption="{{ 'delivery.addressType' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="AddressType"
              [hidingPriority]="3"
            ></dxi-column>

            <dxi-column
              caption="{{ 'formPayment.operator' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="OperatorCode"
              [hidingPriority]="2"
            ></dxi-column>

            <dxi-column
              caption="{{ 'constractors.pickupPoint' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="DeliveryPointCode"
              [hidingPriority]="1"
            ></dxi-column>

            <dxi-column
              caption="{{ 'articles.descriptionTab' | translate }}"
              [allowSorting]="false"
              width="200"
              dataField="Description"
              [hidingPriority]="0"
            ></dxi-column>
          </dx-data-grid>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'form-financial-document.tabs.package' | translate">
          <dx-data-grid
            #gridPackages
            [showBorders]="true"
            [allowColumnReordering]="true"
            [dataSource]="packages"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px;"
            [focusedRowEnabled]="true"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            *ngIf="localStorageData.bottomPanel.isVisible"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-selection [mode]="'single'"></dxo-selection>

            <dxi-column
              [caption]="'crmDeliveries.packageNo' | translate"
              width="120"
              dataField="PackageNumber"
              [allowSorting]="false"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.postingDate' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ShipmentDate"
              dataType="date"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.returnDate' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ReturnDate"
              dataType="date"
            ></dxi-column>
            <dxi-column
              [caption]="'formPayment.operator' | translate"
              [allowSorting]="false"
              width="130"
              dataField="DeliveryOperatorName"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.courier' | translate"
              [allowSorting]="false"
              width="130"
              dataField="Courier"
            ></dxi-column>
            <dxi-column
              [caption]="'production-documents.status' | translate"
              [allowSorting]="false"
              width="80"
              dataField="StatusCode"
            ></dxi-column>
            <dxi-column
              [caption]="'userExtensions.customers' | translate"
              [allowSorting]="false"
              width="130"
              dataField="CustomerName"
            ></dxi-column>
            <dxi-column
              [caption]="'constractors.recipient' | translate"
              [allowSorting]="false"
              width="120"
              dataField="ReceiverLastName !== null ? ReceiverLastName : CustomerName"
            ></dxi-column>
            <dxi-column
              [caption]="'crmDeliveries.pickingUp' | translate"
              [allowSorting]="false"
              width="120"
              dataField="CashOnDeliveryValue"
            ></dxi-column>
          </dx-data-grid>
        </div>
      </ng-container>

      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="orders"
            [objectId]="
              pomSelectedRow?.length && !isSingleRecordVisible
                ? pomSelectedRow[0]
                : null
            "
            *ngIf="
              bottomPanelTabs[selectedtabIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanel.isVisible
                : isDetails)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanel.height
                : 545
            "
          ></app-notes>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
  <dx-tooltip
    *ngFor="let tabs of bottomPanelTabs; let i = index"
    [target]="'#tabToolTip' + i + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="mouseleave"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">(Alt+{{ i + 1 }})</div>
  </dx-tooltip>
</ng-template>

<app-new-task
  [isVisible]="isTask"
  mode="add"
  [customerId]="focusedSelected.length > 0 ? focusedSelected[0]?.CustomerId : 0"
  (onClosing)="onTaskClosing()"
  [subject]="subject"
  [readOnly]="readOnly"
  [typeId]="TypeIdTask"
  (onInserted)="onTaskClosing()"
></app-new-task>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGSL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-new-warehouse-document
  [isVisible]="addRow"
  (onClosing)="onClosingForm()"
  title="{{ 'warehouseDocument.addingWarehouseDocument' | translate }}"
  [selected]="focusedSelectedWarehouse"
  [recalculatePosition]="true"
  (onInserted)="onInserted($event)"
  [editMode]="'edit'"
  [readOnly]="readOnly"
></app-new-warehouse-document>

<app-sms-offers
  [isVisible]="isSendSms"
  [readOnly]="readOnly"
  type="services"
  title="{{ 'offers.SMSSending' | translate }}"
  (onClosing)="onSmsClosing()"
></app-sms-offers>

<dx-popup
  [width]="700"
  [height]="370"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'documentsOrders.realizationInvoices' | translate }}"
  [(visible)]="createInvoicesDocumentPopupVisible"
  (onShown)="event.onShownPopUp(); event.setFocus(gridClass)"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.choose' | translate }}"
        type="success"
        style="margin-right: 6px;"
        (onClick)="createChosedInvoices('Signature')"
        [id]="'choose-doc' + unicalGuid"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="createInvoicesDocumentPopupVisible = false"
        [id]="'cancel-doc' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel-doc' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ 'buttons.cancel' | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>
    <div class="row">
      <div class="col-md-12">
        <dx-data-grid
          [dataSource]="invoicesDocumentsTypes"
          [wordWrapEnabled]="false"
          [showBorders]="true"
          shortcut
          [columnAutoWidth]="true"
          (onRowDblClick)="onDocRowDblClick()"
          [height]="250"
          [id]="'grid-doc' + unicalGuid"
          [(focusedRowIndex)]="focusedDocRowIndexIncoices"
          [focusedRowEnabled]="true"
          (onKeyDown)="onKeyUpDh($event)"
          #gridBankDH
          (onOptionChanged)="event.onOptionChanged($event)"
        >
          <dxo-load-panel [enabled]="false"></dxo-load-panel>
          <dxi-column
            caption="S"
            width="40"
            dataField="IsSystem"
            cellTemplate="booleanTemplate"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.typeName' | translate"
            dataField="Name"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.signature' | translate"
            dataField="Signature"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.type' | translate"
            dataField="DocumentType"
          ></dxi-column>
          <dxi-column
            [caption]="'document-type.pricingByPrice' | translate"
            dataField="CalculateByPrice"
          ></dxi-column>

          <div *dxTemplate="let data of 'booleanTemplate'">
            <i
              class="icon absui-icon--check check-grid"
              *ngIf="data.value; else falseTemplate"
            ></i>
          </div>
          <ng-template #falseTemplate>
            <i class="icon absui-icon--close close-grid"></i>
          </ng-template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</dx-popup>

<app-profits
  *ngIf="isProfit"
  [isVisible]="isProfit"
  (onClosing)="isProfit = false"
></app-profits>

<app-new-route
  [context]="contextOfAddingRoute"
  [addingServiceOrdersRouteId]="pomSelectedRow[0]"
  [addingModeServiceOrdersRoute]="addingModeRoute"
  [isVisible]="newRouteVisible"
  [readOnly]="readOnly"
  [mode]="'add'"
  (onClosing)="onClosingNewRoute()"
></app-new-route>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="perEdocuments"
    DocumentCode="ServiceOrderRelated"
    tableName="SERWIS_ZLECENIE"
    [contextObjectId]="focusedSelected[0]?.ServiceOrderId"
    [parentDocumentName]="focusedSelected[0]?.Number"
    style="display: inline-block;"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>
<ng-template #reportsTemplate>
  <app-reports
    #reports
    [code]="'Zlecenie_serwisowe'"
    [menuName]="menuName"
    type="SRW"
    [objectId]="
      focusedSelected?.length ? focusedSelected[0]?.ServiceOrderId : null
    "
    [serviceOrder]="focusedSelected[0]"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    (onReportsPopupClosed)="onReportsPopupClosed()"
    [params]="getLoadParams()"
  ></app-reports>
</ng-template>

<app-new-comercial-operation
  [isVisible]="isAddInvoices()"
  [mode]="'edit'"
  [selectedId]="invoiceDicument"
  (onClosing)="isAddInvoices.set(false)"
  (onInserted)="isAddInvoices.set(false)"
></app-new-comercial-operation>

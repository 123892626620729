<dx-popup
  *ngIf="isPopupMode"
  [width]="900"
  [height]="550"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'menu.offer' | translate }}"
  [(visible)]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <ng-container *ngIf="componentVisible">
      <div class="text-right title-pop-up">
        <dx-button
          text="{{ 'buttons.cancel' | translate }}"
          type="danger"
          (onClick)="isVisible = false"
          [id]="'cancel' + unicalGuid"
        ></dx-button>

        <dx-tooltip
          [target]="'#cancel' + unicalGuid"
          hideEvent="dxhoverend"
          [hideOnOutsideClick]="false"
          [showEvent]="event.tooltipShowEvent"
        >
          <div *dxTemplate="let data of 'content'">
            {{ "buttons.cancel" | translate }} (Esc)
          </div>
        </dx-tooltip>
      </div>

      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </ng-container>
  </div>
</dx-popup>

<ng-container *ngIf="!isPopupMode">
  <div *ngIf="!dropDownBoxMode; else dropDownMode">
    <ng-container *ngIf="componentVisible">
      <div class="top-menu-panel">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <div class="center-panel-scroll-verticall">
        <div *ngIf="event.deviceType != 'mobile'; else mobileTemplate">
          <ng-container *ngTemplateOutlet="grid"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #dropDownMode>
  <dx-drop-down-box
    [(value)]="articleName"
    valueExpr="ProductId"
    [deferRendering]="false"
    displayExpr="ProductFullName"
    [(opened)]="isGridBoxOpened"
    width="276"
    [dataSource]="articlesList"
    [showClearButton]="true"
    [readOnly]="readOnly"
    (onValueChanged)="onValueChanged($event)"
    (openedChange)="openedChange($event)"
  >
    <div *dxTemplate="let data of 'content'" style="height: 300px">
      <div class="top-menu-popup">
        <ng-container *ngTemplateOutlet="topButtons"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="grid"></ng-container>
    </div>
  </dx-drop-down-box>
</ng-template>

<ng-template #topButtons>
  <dx-scroll-view
    width="100%"
    height="100%"
    direction="horizontal"
    showScrollbar="never"
    *ngIf="event.deviceType != 'mobile'; else mobileButtonsTemplate"
  >
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAmount]="selectedAmount()"
      [selectedAll]="isAllSelected"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>

    <div class="offers-step-1" style="width: 700px; display: inline-block">
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--add-circle"
        [text]="'buttons.add' | translate"
        [id]="'add' + unicalGuid"
        (onClick)="showNewRow()"
        [disabled]="readOnly || !perABD.addBtn"
        *ngIf="!dropDownBoxMode && !isPopupMode"
        data-cy="offers-add"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--mode-edit"
        [text]="'buttons.edit' | translate"
        [id]="'edit' + unicalGuid"
        (onClick)="showEditArticle()"
        [disabled]="readOnly || articleEdit.length == 0 || !perABD.editBtn"
        *ngIf="!dropDownBoxMode && !isPopupMode"
        data-cy="offers-edit"
      >
      </dx-button>

      <dx-button
        icon="icon absui-icon--form-items"
        [id]="'show' + unicalGuid"
        class="btn-dx-top-menu-folder"
        *ngIf="!isPopupMode"
        [disabled]="articleEdit.length == 0 || !perABD.showBtn"
        [text]="'buttons.show' | translate"
        (onClick)="setSingleRecordVisible('show')"
        data-cy="offers-show"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--highlight-off"
        [text]="'buttons.delete' | translate"
        [id]="'delete' + unicalGuid"
        (onClick)="isDelete = true"
        [disabled]="readOnly || articleEdit.length == 0 || !perABD.deleteBtn"
        *ngIf="!dropDownBoxMode && !isPopupMode"
        data-cy="offers-delete"
      >
      </dx-button>

      <dx-button
        class="btn-dx-top-menu"
        icon="icon absui-icon--sum"
        [text]="'stockHistory.sum' | translate"
        [id]="'sum' + unicalGuid"
        (onClick)="offersSumOpen()"
        [disabled]="readOnly"
        *ngIf="!dropDownBoxMode && !isPopupMode"
      >
      </dx-button>

      <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

      <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

      <app-btn-export-excel
        [gridReference]="gridBank"
        [nameFile]="'menu.offers' | translate"
        url="offers/documents"
        [paramsObj]="paramsObj"
      >
      </app-btn-export-excel>

      <app-product-scaner
        #scaner
        [readOnly]="readOnly"
        [showScanerContainer]="false"
        (onInputChanged)="onScanerInputChanged($event)"
        [keyboardShortcutsDisabled]="
          isSingleRecordVisible ||
          (dropDownBoxMode && !isGridBoxOpened) ||
          isChangesStatus ||
          visibleUserExtensions ||
          isSendSms ||
          isSumOffersVisible ||
          isShortcutTableOpened ||
          isExternalTableVisible ||
          isClipboardVisible ||
          columnsChooserVisible ||
          copyPopupVisible ||
          newVersionPopupVisible ||
          isDelete ||
          isOrderCreated ||
          isPlannedOrdersVisible ||
          isExpansionListVisible
        "
        [parentId]="unicalGuid"
      >
      </app-product-scaner>

      <app-additional-operation
        #additionalOperation
        nameComponent="OffersComponent"
        (onItemClick)="contextMenuClick($event)"
        (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
      >
      </app-additional-operation>
      <dx-button
        icon="icon absui-icon--tables"
        [id]="'tables' + unicalGuid"
        class="btn-dx-top-menu"
        *ngIf="!isPopupMode && additionalTables.length"
        [disabled]="articleEdit.length == 0"
        [text]="'buttons.tables' | translate"
        (onClick)="isExternalTableVisible = true"
      >
      </dx-button>
    </div>

    <!-- <dx-button icon="icon absui-icon--operations " [id]="'operations' + unicalGuid" class="btn-dx-top-menu"
      *ngIf="!isPopupMode" [disabled]="articleEdit.length == 0 "
      [text]="'form-financial-document.operations' | translate" (onClick)="null">
    </dx-button> -->

    <!-- tooltips -->

    <dx-tooltip
      [target]="'#add' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.add" | translate }} (Insert)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#edit' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.edit" | translate }} (F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#delete' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.delete" | translate }} (Del)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#sum' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "stockHistory.sum" | translate }} (Ctrl + S)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#show' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.show" | translate }} (Shift + F2)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#print' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.prints" | translate }} (Ctrl + D)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#edocuments' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.eDocuments" | translate }} (Ctrl + E)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#operations' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "form-financial-document.operations" | translate }} (Ctrl + O)
      </div>
    </dx-tooltip>

    <dx-tooltip
      [target]="'#tables' + unicalGuid"
      hideEvent="dxhoverend"
      [hideOnOutsideClick]="false"
      [showEvent]="event.tooltipShowEvent"
    >
      <div *dxTemplate="let data of 'content'">
        {{ "buttons.tables" | translate }} (Ctrl + Y)
      </div>
    </dx-tooltip>

    <div class="right-header-btn d-flex">
      <dx-button
        class="btn-dx-top-menu-folder btn-dynamic-state"
        [ngClass]="{ active: localStorageData?.sidePanel?.isVisible }"
        icon="icon absui-icon--filter"
        (onClick)="toggleDynamicFilters()"
        style="margin-right: 6px"
        [id]="'btn-show-dynamic-filters' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>
      <dx-button
        class="btn-dx-top-menu-folder"
        icon="icon absui-icon--book-open"
        (onClick)="runGuide()"
        [ngClass]="{ highlighted: isGuideButtonHighlighted }"
        style="margin-right: 6px"
        [id]="'btn-user-guide' + unicalGuid"
        *ngIf="event.deviceType === 'desktop'"
      >
      </dx-button>

      <div class="">
        <dx-button
          class="btn-dx-top-menu-folder"
          icon="icon dx-icon-icon-refresh"
          (onClick)="refreshView()"
          *ngIf="!dropDownBoxMode"
          style="margin-right: 6px"
          [id]="'btn-refresh' + unicalGuid"
        ></dx-button>

        <dx-button
          class="btn-dx-top-menu-folder offers-step-2"
          icon="column-chooser"
          (onClick)="openColumnsChooser()"
          *ngIf="perABDKonf && !dropDownBoxMode"
          [id]="'btn-columns' + unicalGuid"
        ></dx-button>
      </div>

      <dx-tooltip
        [target]="'#btn-user-guide' + unicalGuid"
        [(visible)]="isGuideButtonHighlighted"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "userGuide.runGuideAgain" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-refresh' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "refresh" | translate }}
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#btn-columns' + unicalGuid"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "columnSettings" | translate }}
        </div>
      </dx-tooltip>
    </div>
  </dx-scroll-view>
</ng-template>

<ng-template #grid>
  <div class="row">
    <div class="col-md-12">
      <div class="panels-flex-container">
        <div
          [ngClass]="{
            without_search_panel: dropDownBoxMode,
            with_search_panel: !dropDownBoxMode
          }"
          #rightPanel
        >
          <div class="row">
            <div class="col-md-12">
              <app-custom-dropdown-box
                [openDropDown]="openCustomDropDown"
                class="offers-step-3"
                [items]="filterCriteria"
                [(selectedItem)]="valueCriteria"
                [(filterValue)]="filterValue"
                (onValueChanged)="onFilterDataChanged($event)"
                style="margin-right: 5px"
                (onArrowDown)="gridBank.instance.focus()"
                #searchControl
              >
              </app-custom-dropdown-box>

              <div class="offers-step-4" style="display: inline">
                <app-date-range
                  #dateRange
                  [readOnly]="readOnly"
                  [isPopupMode]="true"
                  (onChoosed)="onDateRangeChoosed()"
                  [chipsVisible]="true"
                >
                </app-date-range>

                <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected"
                  >{{ "showSelected" | translate }}:
                  <b>{{ "yes" | translate }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('onlySelected')"
                  ></i>
                </dx-button>

                <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="localStorageData.statusName"
                  (click)="openRightPanel()"
                  >Status: <b>{{ localStorageData.statusName }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetFiltr('statusName')"
                  ></i>
                </dx-button>

                <!-- [chipsValue]="allVersionsActive" -->
                <app-custom-chips-button
                  [deleteFilter]="deleteFilter"
                  [name]="'offers.allVersions' | translate"
                  [list]="allVersionsItems"
                  (onValueChanged)="onAllVersionsChanged($event)"
                >
                </app-custom-chips-button>

                <app-custom-chips-button
                  *ngIf="
                    localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
                    localStorageData?.advancedFilter?.IsPinned
                  "
                  [name]="'filterPanel.template' | translate"
                  [labelExpr]="'Name'"
                  [valueExpr]="'FilterUsersDefinitionId'"
                  [selectedValueInput]="
                    localStorageData?.advancedFilter?.Name || null
                  "
                  [list]="dynamicFilterUserDefinitions"
                  (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
                >
                </app-custom-chips-button>

                <dx-button
                  class="btn-filter-box"
                  [ngClass]="{ 'chips-btn': customerFilter.CustomerId }"
                  (onClick)="isCustomerVisible = true"
                >
                  {{
                    "form-financial-document.payer-type.contractor" | translate
                  }}
                  <b style="margin-left: 6px" *ngIf="customerFilter.CustomerId">
                    {{ customerFilter.CustomerName }}</b
                  >
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (click)="resetContrahentFilter()"
                    *ngIf="customerFilter.CustomerId"
                  ></i>
                </dx-button>

                <app-view-dynamic-quick-filters
                  [dictionaryCode]="DynamicFilterDictionaryCode"
                  [pinnedFilters]="
                    localStorageData.advancedFilter?.PinnedFilters
                  "
                  [values]="
                    localStorageData?.advancedFilter?.UserFilterDefinitionValues
                  "
                  (onValueChanged)="onPinnedValueChanged($event)"
                ></app-view-dynamic-quick-filters>

                <dx-button
                  [id]="'clearValues' + unicalGuid"
                  class="btn-filter-box"
                  icon="icon absui-icon--clear-filter"
                  (onClick)="clearValues()"
                ></dx-button>
                <dx-tooltip
                  [target]="'#clearValues' + unicalGuid"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "clearAllFilters" | translate }} (Ctrl + F10)
                  </div>
                </dx-tooltip>

                <!-- dynamicFilterUserDefinitions -->
                <!-- <dx-button
                  class="btn-filter-box chips-btn"
                  *ngIf="
                    localStorageData?.advancedFilter?.FilterUsersDefinitionId
                  "
                  (click)="openRightPanel()"
                  >{{ "filterPanel.template" | translate }}:
                  <b>{{ localStorageData?.advancedFilter?.Name }}</b>
                  <i
                    class="icon absui-icon--multiplication chips-close"
                    (onClick)="resetFiltr('advancedFilter')"
                  ></i>
                </dx-button> -->
              </div>

              <dx-data-grid
                [dataSource]="dataSource"
                [wordWrapEnabled]="false"
                [showBorders]="true"
                [columnResizingMode]="'widget'"
                shortcut
                [columnAutoWidth]="true"
                [allowColumnReordering]="true"
                (onRowDblClick)="onRowDblClick()"
                (onKeyDown)="onKeyDown($event)"
                [height]="heightGrid - event.getHeightBoxLicenseInformation()"
                [(selectedRowKeys)]="selectedRows"
                (onContentReady)="onGridContentReady($event)"
                [ngClass]="{
                  dropDownBoxClassGrid: dropDownBoxMode,
                  'focus-on-detail': isDetailOnFocus
                }"
                [remoteOperations]="true"
                [id]="'grid' + unicalGuid"
                (onCellClick)="onCellClick($event)"
                (onFocusedCellChanged)="onFocusedCellChanged($event)"
                (onOptionChanged)="onOptionChanged($event)"
                (onInitialized)="onInitialized($event)"
                [allowColumnResizing]="true"
                (onFocusedRowChanged)="onFocusedRowChanged($event)"
                [(focusedRowIndex)]="focusedRowIndex"
                [autoNavigateToFocusedRow]="true"
                (onSelectionChanged)="onSelectionChanged($event)"
                [focusedRowEnabled]="true"
                (onEditorPreparing)="onEditorPreparing($event)"
                (onRowClick)="onRowClick($event)"
                #gridBank
                (onContextMenuPreparing)="contextMenuPreparing($event)"
                data-cy="offers-grid"
              >
                <dxo-load-panel [enabled]="false"></dxo-load-panel>
                <dxo-sorting mode="none"></dxo-sorting>
                <dxo-selection
                  *ngIf="!isPopupMode"
                  [mode]="dropDownBoxMode ? 'single' : 'multiple'"
                  showCheckBoxesMode="always"
                  selectAllMode="allPages"
                ></dxo-selection>
                <dxo-scrolling
                  mode="virtual"
                  columnRenderingMode="virtual"
                  preloadEnabled="true"
                ></dxo-scrolling>
                <dxo-paging [pageSize]="pageSize" [enabled]="true"></dxo-paging>
                <!-- dxo-column-fixing [enabled]="!isPopupMode"></dxo-column-fixing -->
                <dxo-column-fixing [enabled]="false"></dxo-column-fixing>

                <dxi-column
                  caption=""
                  [width]="26"
                  [allowReordering]="false"
                  [allowSorting]="false"
                  dataField="IsBlocked"
                  cellTemplate="blockTemplate"
                  [cssClass]="'sorting-unavailable'"
                >
                </dxi-column>

                <div *dxTemplate="let data of 'blockTemplate'">
                  <i
                    class="icon absui-icon--set-lock"
                    *ngIf="data.data.IsBlocked"
                  ></i>
                </div>

                <dxi-column
                  caption="{{ 'form-financial-document.number' | translate }}"
                  [cssClass]="
                    orderby === 'OfferDocumentNumber'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="numberHeaderTemplate"
                  width="150"
                  [allowSorting]="false"
                  dataField="OfferDocumentNumber"
                  cellTemplate="mainColumn"
                  renderAsync="true"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'numberHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('OfferDocumentNumber')"
                  >
                    {{ "form-financial-document.number" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <div *dxTemplate="let data of 'mainColumn'">
                  <div
                    *ngIf="data.data.Semaphore"
                    [ngClass]="{
                      editRowByMe: userId == data.data.Semaphore,
                      editRowByAnotherUser: userId != data.data.Semaphore
                    }"
                  ></div>
                  <span
                    [id]="'main' + data.data.OfferDocumentId"
                    class="hov-underline main-column-tooltip-src"
                    (click)="editRecord($event)"
                    >{{ data.data.OfferDocumentNumber }}</span
                  >
                  <dx-tooltip
                    [target]="'#main' + data.data.OfferDocumentId"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                    [showEvent]="event.tooltipShowEvent"
                  >
                    <div *dxTemplate="let x of 'content'">
                      {{ data.data.OfferDocumentNumber }}
                    </div>
                  </dx-tooltip>
                </div>
                <dxi-column
                  caption="{{ 'warehouseDocument.label' | translate }}"
                  width="105"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="LabelName"
                  cellTemplate="labelTemplate"
                  renderAsync="true"
                  alignment="left"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'labelTemplate'">
                  <app-label
                    [bgColor]="data.data.LabelBackgroundColor"
                    [color]="data.data.LabelTextColor"
                    [text]="data.data.LabelName"
                  >
                  </app-label>
                </div>
                <dxi-column
                  caption="S"
                  width="40"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="OfferDocumentStatus"
                  cellTemplate="statusCell"
                  headerCellTemplate="OfferDocumentStatus"
                  renderAsync="true"
                >
                </dxi-column>
                <div
                  *dxTemplate="let data of 'OfferDocumentStatus'"
                  class="text-center"
                >
                  <div
                    class="grid-header-wrapper"
                    [id]="'OfferDocumentStatus' + unicalGuid"
                  >
                    {{ "production-documents.s" | translate }}
                  </div>
                  <dx-tooltip
                    [target]="'#OfferDocumentStatus' + unicalGuid"
                    [showEvent]="event.tooltipShowEvent"
                    hideEvent="dxhoverend"
                    [hideOnOutsideClick]="false"
                  >
                    <div *dxTemplate="let data of 'content'">
                      {{ "production-documents.status" | translate }}
                    </div>
                  </dx-tooltip>
                </div>
                <dxi-column
                  caption="{{ 'offers.offerDate' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  dataField="OfferDocumentDate"
                  [cssClass]="
                    orderby === 'OfferDocumentDate'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="documentDateHeaderTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'documentDateHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('OfferDocumentDate')"
                  >
                    {{ "offers.offerDate" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'userExtensions.customers' | translate }}"
                  width="140"
                  [allowSorting]="false"
                  dataField="CustomerName"
                  [cssClass]="
                    orderby === 'CustomerName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="customerHeaderTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'customerHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('CustomerName')"
                  >
                    {{ "userExtensions.customers" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'offers.offerName' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  dataType="date"
                  dataField="Name"
                  [cssClass]="
                    orderby === 'OfferDocumentNameIntuition'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="nameHeaderTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'nameHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('OfferDocumentNameIntuition')"
                  >
                    {{ "offers.offerName" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'plannedOrders.realisationDate' | translate }}"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="120"
                  [allowSorting]="false"
                  dataField="RealizationPlanDate"
                  [cssClass]="
                    orderby === 'RealizationPlanDate'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="realisationHeaderTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'realisationHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('RealizationPlanDate')"
                  >
                    {{ "plannedOrders.realisationDate" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>

                <dxi-column
                  caption="{{ 'offers.versionNr' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Version"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'offers.validFrom' | translate }}"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="OfferDocumentDateFrom"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'offers.validTo' | translate }}"
                  dataType="date"
                  [format]="{ type: event.dateFormat }"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="OfferDocumentDateTo"
                >
                </dxi-column>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.netValue' | translate
                  }}"
                  width="120"
                  [allowSorting]="false"
                  dataField="TotalNetAmount"
                  cellTemplate="numberPrecisionTemplate"
                  renderAsync="true"
                  [cssClass]="
                    orderby === 'MaxTotalNetAmount'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="maxTotalNetAmountTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'maxTotalNetAmountTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('MaxTotalNetAmount')"
                  >
                    {{ "form-commercial-operation.grid.netValue" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.grid.grossValue' | translate
                  }}"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalGrossAmount"
                  cellTemplate="numberPrecisionTemplate"
                  renderAsync="true"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'articles.warning' | translate }}"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="Remarks"
                >
                </dxi-column>
                <dxi-column
                  caption="{{
                    'bankAccounts.grid.columns.currency' | translate
                  }}"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="CurrencyCode"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'offers.netPriceCur' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalNetAmountCurrency"
                  cellTemplate="numberPrecisionTemplate"
                  renderAsync="true"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'plannedOrders.grossValCur' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="TotalGrossAmountCurrency"
                  cellTemplate="numberPrecisionTemplate"
                  renderAsync="true"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'baseCurrencies.currencyValue' | translate }}"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="CurrencyFactor"
                  cellTemplate="numberPrecision4Template"
                  renderAsync="true"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'workers.attachment' | translate }}"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="AttachmentsAmount"
                  width="80"
                  alignment="right"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'servicesDevices.barcode' | translate }}"
                  width="100"
                  [allowSorting]="false"
                  dataField="EAN"
                  [cssClass]="
                    orderby === 'EAN' ? 'sort-column-bg' : 'sort-column-muted'
                  "
                  headerCellTemplate="barcodeHeaderTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'barcodeHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('Barcode')"
                  >
                    {{ "servicesDevices.barcode" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'worker' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  dataField="EmployeeName"
                  [cssClass]="
                    orderby === 'EmployeeName'
                      ? 'sort-column-bg'
                      : 'sort-column-muted'
                  "
                  headerCellTemplate="workerHeaderTemplate"
                >
                </dxi-column>
                <div *dxTemplate="let data of 'workerHeaderTemplate'">
                  <div
                    class="grid-header-wrapper"
                    (click)="setSearchCriteria('EmployeeName')"
                  >
                    {{ "worker" | translate }}
                    <i
                      class="header-sort-icon"
                      [ngClass]="
                        order === event.sortingRule ? 'arr-down' : 'arr-up'
                      "
                    ></i>
                  </div>
                </div>
                <dxi-column
                  caption="{{ 'form-payment.paymentMethod' | translate }}"
                  width="120"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="PaymentFormName"
                >
                </dxi-column>
                <dxi-column
                  caption="{{
                    'form-commercial-operation.dateOfPayment' | translate
                  }}"
                  width="140"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="PaymentMaxDelay"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'offers.personToContact' | translate }}"
                  width="140"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="ContactName"
                >
                </dxi-column>
                <dxi-column
                  caption="{{ 'offers.lastOfferNr' | translate }}"
                  width="140"
                  [allowSorting]="false"
                  [cssClass]="'sorting-unavailable'"
                  dataField="OfferDocumentLastVersionId"
                >
                </dxi-column>

                <div *dxTemplate="let cell of 'numberPrecision4Template'">
                  {{ cell.value | amountFormatter : cell : 4 }}
                </div>
                <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
                  {{ cell.value | amountFormatter : cell }}
                </div>

                <div
                  *dxTemplate="let data of 'statusCell'"
                  style="text-align: center"
                >
                  <app-status-label
                    [text]="data.data.OfferDocumentStatus"
                    [color]="data.data.StatusStringColor"
                    [backgroundColor]="data.data.StatusBackgroundColor"
                  ></app-status-label>
                </div>
              </dx-data-grid>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.asc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "columnSorting.asc" | translate }}
                </div>
              </dx-tooltip>
              <dx-tooltip
                *ngFor="let icon of sortingIcons.desc"
                [target]="icon"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "columnSorting.desc" | translate }}
                </div>
              </dx-tooltip>
            </div>
          </div>

          <div class="row" *ngIf="!isPopupMode">
            <div
              class="bottom-side-panel"
              mwlResizable
              *ngIf="!dropDownBoxMode"
              (resizeEnd)="resizeEndBottom()"
              #bottomSidePanel
              (resizeStart)="onResizeStartBottom()"
              (resizing)="resizingBottom($event)"
            >
              <div
                class="resize-handle-top"
                mwlResizeHandle
                [resizeEdges]="{ top: true }"
                (mouseleave)="mouseLeaveDown()"
                (mouseenter)="mouseEnterDown()"
              ></div>
              <div class="col-md-12 text-right resize-bottop-panel">
                <i
                  class="icon bottom-panel-icon"
                  mwlResizeHandle
                  [resizeEdges]="{ top: true }"
                  [ngClass]="{
                    'absui-icon--arrow-drop-down bottom-icon-resize-down':
                      showBottomPanel,
                    'absui-icon--arrow-drop-up bottom-icon-resize-up':
                      !showBottomPanel
                  }"
                  (mousedown)="mousedown()"
                  (mouseup)="mouseup()"
                  (touchend)="mouseup()"
                  (touchstart)="mousedown()"
                ></i>

                <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
              </div>
            </div>
          </div>
        </div>

        <div
          class="right-side-panel"
          mwlResizable
          *ngIf="!dropDownBoxMode"
          (resizeEnd)="resizeEnd()"
          (resizeStart)="onResizeStart()"
          (resizing)="resizing($event)"
          [ngStyle]="{ width: localStorageData?.sidePanel?.width }"
          #leftPanel
        >
          <div class="right-panel-btn">
            <dx-button
              (mousedown)="mousedownSidePanel()"
              (mouseup)="mouseupSidePanel()"
              (touchend)="mouseupSidePanel()"
              (touchstart)="mousedownSidePanel()"
              [icon]="getResizeIcon()"
              class="btn-dx-right-filter"
              mwlResizeHandle
              [resizeEdges]="{ left: true }"
            ></dx-button>
          </div>
          <div
            class="resize-handle-left"
            mwlResizeHandle
            [resizeEdges]="{ left: true }"
            (mouseenter)="mouseEnter()"
            (mouseleave)="mouseLeave()"
          ></div>

          <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="loadChildComponents">
  <app-new-documents-orders
    *ngIf="isOrderCreated"
    [isVisible]="true"
    [mode]="'edit'"
    [selectedId]="createdOrderId"
    [isCurrentlyEdit]="true"
    [readOnly]="readOnly"
    (onClosed)="onOrderClosing()"
    (onInserted)="onOrderInserted()"
  ></app-new-documents-orders>

  <app-new-offer
    [isVisible]="isSingleRecordVisible"
    [mode]="singleRecordMode"
    [selectedId]="articleEdit"
    [componentNests]="singleRecordMode === 'show' ? null : componentNests"
    *ngIf="!isPopupMode"
    [readOnly]="readOnly || singleRecordMode === 'show'"
    (onClosing)="onSingleRecordClosing()"
    (onInserted)="onSingleRecordInserted($event)"
  >
  </app-new-offer>

  <app-confirm-dialog
    [isVisible]="isDelete"
    [confirmText]="
      selectedRows.length > 1 ? 'confirmDeleteMultiple' : 'confirmDelete'
    "
    (onRemoving)="showDeleteArticle(); gridBank.instance.focus()"
    (onClosing)="closeConfirm()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    kindDialog="question"
    [confirmText]="newVersionText + focusedElementNr + '?'"
    [isVisible]="newVersionPopupVisible"
    (onRemoving)="createNewVersion(); gridBank.instance.focus()"
    (onClosing)="closeConfirmNewVersion()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    kindDialog="question"
    [confirmText]="copyText + focusedElementNr + '?'"
    [isVisible]="copyPopupVisible"
    (onRemoving)="copyOffer(); gridBank.instance.focus()"
    (onClosing)="closeCopyOffer()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    *ngIf="isBlockVisible"
    kindDialog="question"
    [confirmText]="blockText"
    [isVisible]="true"
    (onRemoving)="blockOffer()"
    (onClosing)="closeBlockOffer()"
  ></app-confirm-dialog>

  <app-confirm-dialog
    *ngIf="isUnblockVisible"
    kindDialog="question"
    [confirmText]="unblockText"
    [isVisible]="true"
    (onRemoving)="unblockOffer()"
    (onClosing)="closeUnblockOffer()"
  ></app-confirm-dialog>

  <app-column-chooser
    [gridRef]="gridBank"
    [(visible)]="columnsChooserVisible"
    (columnsChanged)="onColumnsChanged($event)"
  ></app-column-chooser>

  <app-clipboard
    *ngIf="isClipboardVisible"
    [isVisible]="true"
    (onClosing)="isClipboardVisible = false"
    [isPopupMode]="true"
    [showMode]="true"
  ></app-clipboard>

  <app-table-user-extension
    tableGroupCode="Oferta"
    (onClosing)="isExternalTableVisible = false"
    [isVisible]="isExternalTableVisible"
    [isPopupMode]="true"
  ></app-table-user-extension>

  <app-table-data-user-extension
    [readOnly]="readOnly"
    [isVisible]="isShortcutTableOpened"
    tableGroupCode="Oferta"
    (onClosing)="isShortcutTableOpened = false; shortcutTable = null"
    [table]="shortcutTable"
  >
  </app-table-data-user-extension>
  <app-labels
    [popUpMode]="true"
    (onChoosed)="onChoosedLabel()"
    (onClosed)="isLabel = false"
    [isVisible]="isLabel"
    [readOnly]="readOnly"
    IsForOfferDoc="true"
    url="offers/selection/label"
  ></app-labels>

  <app-sum-offers
    [isVisible]="isSumOffersVisible"
    (onClosing)="isSumOffersVisible = false"
    [filters]="sumOffersFilters"
  >
  </app-sum-offers>

  <!-- pola dodatkowe -->
  <app-form-user-extensions
    [isVisible]="visibleUserExtensions"
    groupCode="Oferta"
    [readOnly]="readOnly"
    title="{{ 'categories.externalFields' | translate }}"
    (onChoosed)="onInsertedUserExtensions($event)"
    (onClosing)="onClosingUserExtensions()"
    [userExtensionsValue]="additionalFieldsList"
    #isValidUserExtensions
  >
  </app-form-user-extensions>

  <app-offers-status
    [isVisible]="isChangesStatus"
    [readOnly]="readOnly"
    title="{{ 'offers.statusChangeForSelectedOffers' | translate }}"
    (onClosing)="onClosingStatus()"
    (onInserted)="onInsertedStatus()"
  ></app-offers-status>

  <app-sms-offers
    [isVisible]="isSendSms"
    [readOnly]="readOnly"
    [CustomerId]="selectedRowToSms"
    title="{{ 'offers.SMSSending' | translate }}"
    (onClosing)="onSmsClosing()"
  >
  </app-sms-offers>

  <wapro-context-menu
    [dataSource]="contextMenu"
    [width]="200"
    [target]="'#grid' + unicalGuid"
    (onItemClick)="contextMenuClick($event)"
    (onItemRendered)="event.onItemRendered($event)"
  >
  </wapro-context-menu>
</ng-container>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="
    isSingleRecordVisible ||
    (dropDownBoxMode && !isGridBoxOpened) ||
    isChangesStatus ||
    visibleUserExtensions ||
    isSendSms ||
    isSumOffersVisible ||
    isShortcutTableOpened ||
    isExternalTableVisible ||
    isClipboardVisible ||
    columnsChooserVisible ||
    copyPopupVisible ||
    newVersionPopupVisible ||
    isDelete ||
    isOrderCreated ||
    isPlannedOrdersVisible ||
    isExpansionListVisible
  "
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<app-nest
  *ngIf="event.isVersionForNestAvailable"
  [isNestRunning]="isNestRunning"
  [nest]="nest"
  [socketCodeFilter]="'XGOL'"
  (nestFinished)="onNestFinished($event)"
></app-nest>

<ng-template #mobileButtonsTemplate>
  <div style="position: relative">
    <app-wapro-selected-box
      [(ngModel)]="selectedRows"
      [dataSource]="dataSource"
      [selectedAmount]="selectedAmount()"
      [mobileSelectMode]="modeSelectionMobile === 'multiple'"
      (onMobileSelectModeOff)="modeSelectionMobile = 'none'"
      [selectedAll]="isAllSelected"
      (onDeselectAll)="onDeselectAll()"
      (onOnlySelected)="onOnlySelected()"
    ></app-wapro-selected-box>
    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--add-circle"
      [text]="'buttons.add' | translate"
      [id]="'add' + unicalGuid"
      (onClick)="showNewRow()"
      [disabled]="readOnly || !perABD.addBtn"
      *ngIf="!dropDownBoxMode && !isPopupMode"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu-folder"
      icon="icon absui-icon--more-horiz"
      (onClick)="mobileOperationsVisible = true; cd.detectChanges()"
    >
    </dx-button>

    <dx-button
      class="btn-dx-top-menu-folder lastBtn"
      icon="icon absui-icon--filter"
      (onClick)="filterMobileVisible = true"
      style="position: absolute; right: 0"
    ></dx-button>

    <!-- mobile reports popup -->
    <ng-container [ngTemplateOutlet]="reportsTemplate"></ng-container>

    <!-- mobile edocoments popup -->
    <ng-container [ngTemplateOutlet]="edocumentsTemplate"></ng-container>

    <app-mobile-list
      [dataSource]="contextMenuOperationsMobile"
      [visible]="mobileOperationsVisible"
      (onClosed)="mobileOperationsVisible = false; cd.detectChanges()"
      (onItemClick)="contextMenuClick($event)"
    >
    </app-mobile-list>

    <app-additional-operation
      #additionalOperation
      nameComponent="OffersComponent"
      (onItemClick)="contextMenuClick($event)"
      (onSetAdditionalOperation)="onSetAdditionalOperation($event)"
    >
    </app-additional-operation>
  </div>
</ng-template>

<div *ngIf="isFilterPanelComponent">
  <app-filter-panel
    [openPanelFilters]="visibleAdvanceFiltersWithoutPanel"
    (onFilterSave)="onFilterSaved($event)"
    [dictionaryCode]="DynamicFilterDictionaryCode"
    [selectedTemplate]="
      localStorageData?.advancedFilter?.FilterUsersDefinitionId
    "
    [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
  >
  </app-filter-panel>
</div>

<ng-template #rightPanelBox>
  <dx-tab-panel
    [visible]="localStorageData?.sidePanel?.isVisible"
    [items]="[1, 2]"
    [(selectedIndex)]="localStorageData.sidePanel.selectedIndex"
    style="margin-top: -10px"
    class="filter-panel offers-step-5 right-panel-box"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="true"
  >
    <div *dxTemplate="let item of 'title'">
      <div class="title-temp">
        <i class="icon absui-icon--control-tree" *ngIf="item == 1"></i>
        <i class="icon absui-icon--filter" *ngIf="item == 2"></i>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name">
        <div *ngSwitchCase="1">
          <dx-tree-view
            #statusesTree
            [dataSource]="offerStatuses"
            keyExpr="OfferDocumentStatusId"
            style="height: auto"
            dataStructure="tree"
            itemsExpr="Statuses"
            displayExpr="StatusName"
            [selectByClick]="true"
            selectionMode="single"
            parentIdExpr="OfferDocumentStatusParentId"
            (onItemClick)="selectItemTaskStatus($event)"
            itemTemplate="treeStatusTemplate"
            (onSelectionChanged)="onTreeSelectionChanged($event)"
            (onContentReady)="restoreTreeConfig()"
          >
            <div *dxTemplate="let data of 'treeStatusTemplate'">
              <ng-container *ngIf="data.StatusCode !== null">
                <app-status-label
                  [text]="data.StatusCode"
                  [color]="data.StatusStringColor"
                  [backgroundColor]="data.StatusBackgroundColor"
                >
                </app-status-label>
                <!-- <div
                  *ngIf="
                    data.StatusCode !== 'Z' &&
                    data.StatusCode !== 'C' &&
                    data.StatusCode !== 'A'
                  "
                  class="statusN"
                >
                  {{ data.StatusCode }}
                </div>
                <div *ngIf="data.StatusCode === 'Z'" class="statusZ">
                  {{ data.StatusCode }}
                </div>
                <div *ngIf="data.StatusCode === 'C'" class="statusR">
                  {{ data.StatusCode }}
                </div>
                <div *ngIf="data.StatusCode === 'A'" class="statusY">
                  {{ data.StatusCode }}
                </div> -->
              </ng-container>
              {{ data.StatusName }}
            </div>
          </dx-tree-view>
        </div>

        <div *ngSwitchCase="2">
          <div>
            <app-filter-panel
              [deleteFilters]="deleteFilter"
              [openPanelFilters]="visibleAdvanceFilters"
              (onFilterSave)="onFilterSaved($event)"
              [dictionaryCode]="DynamicFilterDictionaryCode"
              [selectedTemplate]="
                localStorageData?.advancedFilter?.FilterUsersDefinitionId
              "
              [isPinned]="localStorageData?.advancedFilter?.IsPinned"
              (onTemplatePinChanged)="onTemplatePinChanged($event)"
              [tabActive]="
                localStorageData?.sidePanel?.isVisible &&
                localStorageData?.sidePanel?.selectedIndex == 1
              "
              [panelWidth]="localStorageData.sidePanel.width"
              [values]="
                localStorageData?.advancedFilter?.UserFilterDefinitionValues
              "
              [additionalFieldsGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            >
            </app-filter-panel>
          </div>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
</ng-template>

<dx-popup
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  title="{{ 'filterPanel.filters' | translate }}"
  [(visible)]="filterMobileVisible"
  width="100%"
  height="100vh"
  *ngIf="filterMobileVisible"
  [maxHeight]="event.maxHeightSignal()"
  [wrapperAttr]="{ id: unicalGuid, class: 'big-form-mobile' }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="header-search-mobile">
      <i
        class="icon absui-icon--close"
        style="position: absolute; top: 14px; right: 16px; z-index: 1"
        (click)="filterMobileVisible = false"
      ></i>
    </div>

    <dx-scroll-view
      width="100%"
      [height]="'calc(100% - 64px)'"
      direction="vertical"
      #scrollView
    >
      <br />
      <ng-container *ngTemplateOutlet="rightPanelBox"></ng-container>
    </dx-scroll-view>

    <div class="bottom-mobile-form">
      <dx-button
        class="btn-dx-top-menu-folder"
        type="danger"
        style="margin-right: 12px; color: #333"
        icon="icon absui-icon--clear-filter"
        (onClick)="resetFiltr('statusName')"
      ></dx-button>

      <dx-button
        text="{{ 'buttons.confirm' | translate }}"
        type="success"
        (onClick)="filterMobileVisible = false"
      >
      </dx-button>
    </div>
  </div>
</dx-popup>

<ng-template #bottomTab>
  <dx-tab-panel
    class="offers-step-6"
    #tabPanel
    [items]="bottomPanelTabs"
    [(selectedIndex)]="selectedtabIndex"
    (onTitleClick)="onItemClickTab()"
    (onSelectionChanged)="onSelectionTabChanged()"
    [loop]="false"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    class="bottomTabPanelMobile"
  >
    <div *dxTemplate="let name of 'title'; let i = index">
      <div [id]="'tabToolTip' + i + unicalGuid">
        <span>{{ name }}</span>
      </div>
    </div>
    <div *dxTemplate="let name of 'item'">
      <ng-container [ngSwitch]="name" *ngIf="showBottomPanel">
        <div *ngSwitchCase="'menu.eDocuments' | translate">
          <app-e-dokuments
            #eDocumentss
            [permission]="perAbdExtended.eDocuments"
            DocumentCode="Offers"
            tableName="OFERTA"
            style="display: inline-block; width: 100%"
            [contextObjectId]="articlesListPom[0]?.OfferDocumentId"
            [parentDocumentName]="articlesListPom[0]?.OfferDocumentNumber"
            [isShowSidePanel]="false"
            [heightGrid]="
              event.deviceType != 'mobile' ? heightBottomGrid - 65 : 545 - 65
            "
            [ContextObjectId]="
              articlesListPom.length > 0
                ? articlesListPom[0]?.OfferDocumentId
                : null
            "
            [readOnly]="articlesListPom.length === 0"
          ></app-e-dokuments>
        </div>
        <div *ngSwitchCase="'offers.bottomTabs.positions' | translate">
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [id]="'offerPositions' + unicalGuid"
            [dataSource]="details.positions"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [wordWrapEnabled]="false"
            #positionsGrid
            [(focusedRowKey)]="offerPositionsFocusedRowKey"
            style="width: 100%; margin-bottom: 10px"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxi-column
              caption="{{ 'companies.grid.columns.name' | translate }}"
              [allowSorting]="false"
              dataField="Name"
              width="100"
            >
            </dxi-column>
            <dxi-column
              caption="R"
              [allowSorting]="false"
              dataField="ProductType"
              width="30"
              headerCellTemplate="ProductTypeHeader"
            >
            </dxi-column>
            <div
              *dxTemplate="let data of 'ProductTypeHeader'"
              class="text-center"
            >
              <div
                class="grid-header-wrapper text-center"
                [id]="'ProductTypeHeader' + unicalGuid"
              >
                R
              </div>
              <dx-tooltip
                [target]="'#ProductTypeHeader' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "production-documents.type" | translate }}
                </div>
              </dx-tooltip>
            </div>
            <dxi-column
              caption="{{ 'customerGroups.catalogIndex' | translate }}"
              [allowSorting]="false"
              dataField="IndexCatalogue"
              width="80"
              [hidingPriority]="18"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'customerGroups.tradingIndex' | translate }}"
              [allowSorting]="false"
              dataField="IndexTrading"
              width="80"
              [hidingPriority]="17"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'articles.amount' | translate }}"
              [allowSorting]="false"
              [hidingPriority]="16"
              cellTemplate="numberPrecision4Template"
              dataField="Amount"
              width="70"
              renderAsync="true"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.completed' | translate }}"
              [allowSorting]="false"
              cellTemplate="numberPrecision4Template"
              dataField="ExecutedAmount"
              width="70"
              renderAsync="true"
              [hidingPriority]="15"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.moved' | translate }}"
              [allowSorting]="false"
              dataField="MovedAmount"
              cellTemplate="numberPrecision4Template"
              width="70"
              renderAsync="true"
              [hidingPriority]="14"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-commercial-operation.grid.jm' | translate }}"
              [allowSorting]="false"
              dataField="Unit"
              width="40"
              [hidingPriority]="13"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netAmount' | translate
              }}"
              cellTemplate="numberPrecisionTemplate"
              width="100"
              [allowSorting]="false"
              dataField="PriceNet"
              renderAsync="true"
              [hidingPriority]="12"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossAmount' | translate
              }}"
              cellTemplate="numberPrecisionTemplate"
              width="100"
              [allowSorting]="false"
              dataField="PriceGross"
              renderAsync="true"
              [hidingPriority]="11"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offerPosition.tags' | translate }}"
              [allowSorting]="false"
              dataField="PriceIdentificator"
              headerCellTemplate="tags"
              width="30"
              [hidingPriority]="10"
            >
              <div *dxTemplate="let data of 'tags'" class="text-center">
                <div
                  class="grid-header-wrapper text-center"
                  [id]="'tags' + unicalGuid"
                >
                  {{ "offerPosition.tags" | translate }}
                </div>
                <dx-tooltip
                  [target]="'#tags' + unicalGuid"
                  [showEvent]="event.tooltipShowEvent"
                  hideEvent="dxhoverend"
                  [hideOnOutsideClick]="false"
                >
                  <div *dxTemplate="let data of 'content'">
                    {{ "offerPosition.priceTags" | translate }}
                  </div>
                </dx-tooltip>
              </div>
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.discount' | translate }}"
              [allowSorting]="false"
              [hidingPriority]="9"
              dataField="Discount"
              cellTemplate="numberPrecisionTemplate"
              width="80"
              renderAsync="true"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.priceWithDiscount' | translate }}"
              width="100"
              [allowSorting]="false"
              cellTemplate="numberPrecisionTemplate"
              dataField="PriceNetWithDiscount"
              renderAsync="true"
              [hidingPriority]="8"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              cellTemplate="numberPrecisionTemplate"
              width="100"
              [allowSorting]="false"
              dataField="TotalNetAmount"
              renderAsync="true"
              [hidingPriority]="7"
            >
            </dxi-column>
            <dxi-column
              caption="VAT"
              [allowSorting]="false"
              dataField="TaxCode"
              width="60"
              [hidingPriority]="6"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              [allowSorting]="false"
              cellTemplate="numberPrecisionTemplate"
              renderAsync="true"
              dataField="TotalGrossAmount"
              width="80"
              [hidingPriority]="5"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'articles.filter.indexProducer' | translate }}"
              [allowSorting]="false"
              dataField="IndexProducer"
              width="80"
              [hidingPriority]="4"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'servicesDevices.barcode' | translate }}"
              [allowSorting]="false"
              dataField="EAN"
              width="80"
              [hidingPriority]="3"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'locations.description' | translate }}"
              [allowSorting]="false"
              dataField="Description"
              width="80"
              [hidingPriority]="2"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.availableInWarehouse' | translate }}"
              cellTemplate="numberPrecision4Template"
              width="120"
              [allowSorting]="false"
              dataField="AvailableAmount"
              renderAsync="true"
              [hidingPriority]="1"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.stockAmount' | translate }}"
              width="100"
              renderAsync="true"
              cellTemplate="numberPrecision4Template"
              [allowSorting]="false"
              dataField="StockAmount"
              [hidingPriority]="0"
            >
            </dxi-column>

            <div *dxTemplate="let cell of 'numberPrecision4Template'">
              {{ cell.value | amountFormatter : cell : 4 }}
            </div>
            <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
              {{ cell.value | amountFormatter : cell }}
            </div>
          </dx-data-grid>

          <wapro-context-menu
            [dataSource]="offerPositionsContextMenu"
            [width]="200"
            [target]="'#offerPositions' + unicalGuid"
            (onItemClick)="offerPositionsContextMenuClick($event)"
          >
          </wapro-context-menu>
        </div>

        <div *ngSwitchCase="'offers.bottomTabs.realisations' | translate">
          <dx-data-grid
            [showBorders]="true"
            #workersGrid
            [allowColumnReordering]="true"
            [id]="'offerRealisations' + unicalGuid"
            [dataSource]="details.realisations"
            [wordWrapEnabled]="false"
            style="width: 100%; margin-bottom: 10px"
            [(focusedRowKey)]="offerRealisationsFocusedRowKey"
            [focusedRowEnabled]="true"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxi-column
              caption="{{
                'servicesDevices.warehouseDocumentNumber' | translate
              }}"
              [allowSorting]="false"
              dataField="WarehouseDocumentNumber"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.warehouseDocDate' | translate }}"
              [allowSorting]="false"
              [hidingPriority]="11"
              dataField="DateOfDocumentWarehouse"
              dataType="date"
              [format]="{ type: event.dateFormat }"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              renderAsync="true"
              cellTemplate="numberPrecisionTemplate"
              [allowSorting]="false"
              dataField="TotalNetAmount"
              [hidingPriority]="10"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              renderAsync="true"
              cellTemplate="numberPrecisionTemplate"
              [allowSorting]="false"
              dataField="TotalGrossAmount"
              [hidingPriority]="9"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'loyaltyProram.commercialDocumentNumber' | translate
              }}"
              [allowSorting]="false"
              dataField="InvoiceDocumentNumber"
              [hidingPriority]="8"
            >
            </dxi-column>
            <dxi-column
              caption="RD"
              [allowSorting]="false"
              dataField="TypeOfDocument"
              [hidingPriority]="7"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.tradingDocDate' | translate }}"
              [allowSorting]="false"
              [hidingPriority]="6"
              dataField="DateOfInvoiceDocument"
              dataType="date"
              [format]="{ type: event.dateFormat }"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.netValTradDoc' | translate }}"
              [allowSorting]="false"
              [hidingPriority]="5"
              cellTemplate="numberPrecisionTemplate"
              dataField="InvoiceTotalNetAmount"
              renderAsync="true"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.grossValTradDoc' | translate }}"
              [allowSorting]="false"
              cellTemplate="numberPrecisionTemplate"
              dataField="InvoiceTotalGrossAmount"
              renderAsync="true"
              [hidingPriority]="4"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'bankAccounts.grid.columns.currency' | translate }}"
              [allowSorting]="false"
              dataField="Currency"
              [hidingPriority]="3"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'offers.netPriceCur' | translate }}"
              renderAsync="true"
              cellTemplate="numberPrecisionTemplate"
              [allowSorting]="false"
              dataField="TotalNetAmountCurrency"
              [hidingPriority]="2"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.grossValCur' | translate }}"
              cellTemplate="numberPrecisionTemplate"
              [allowSorting]="false"
              dataField="TotalGrossAmountCurrency"
              renderAsync="true"
              [hidingPriority]="1"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'servicesDevices.remarks' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              [hidingPriority]="0"
            >
            </dxi-column>
            <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
              {{ cell.value | amountFormatter : cell }}
            </div>
          </dx-data-grid>
          <wapro-context-menu
            [dataSource]="offerRealisationsContextMenu"
            [width]="200"
            [target]="'#offerRealisations' + unicalGuid"
            (onItemClick)="offerRealisationsContextMenuClick($event)"
          >
          </wapro-context-menu>
        </div>

        <div *ngSwitchCase="'offers.bottomTabs.orders' | translate">
          <dx-data-grid
            [showBorders]="true"
            [allowColumnReordering]="true"
            [id]="'offerOrders' + unicalGuid"
            [dataSource]="details.orders"
            [wordWrapEnabled]="false"
            #positionsGrid
            [(focusedRowKey)]="offerOrdersFocusedRowKey"
            (onFocusedRowChanged)="onFocusedRowChangedDetail($event)"
            (onCellClick)="onCellClick($event)"
            (onFocusedCellChanged)="onFocusedCellDetailChanged($event)"
            style="width: 100%; margin-bottom: 10px"
            [focusedRowEnabled]="true"
            [allowColumnResizing]="event.deviceType != 'mobile' ? true : false"
            [columnResizingMode]="'widget'"
            [height]="event.deviceType != 'mobile' ? heightBottomGrid : 545"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [allowSorting]="false"
              dataField="OrderNumber"
            >
            </dxi-column>
            <dxi-column
              caption="S"
              [allowSorting]="false"
              dataField="OrderStatus"
              headerCellTemplate="OrderStatus"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'OrderStatus'" class="text-center">
              <div class="grid-header-wrapper" [id]="'status' + unicalGuid">
                {{ "production-documents.s" | translate }}
              </div>
              <dx-tooltip
                [target]="'#status' + unicalGuid"
                [showEvent]="event.tooltipShowEvent"
                hideEvent="dxhoverend"
                [hideOnOutsideClick]="false"
              >
                <div *dxTemplate="let data of 'content'">
                  {{ "production-documents.status" | translate }}
                </div>
              </dx-tooltip>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [allowSorting]="false"
              dataField="OrderDate"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              [hidingPriority]="9"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.clientOrderNr' | translate }}"
              [allowSorting]="false"
              dataField="ClientOrderNumber"
              [hidingPriority]="8"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'plannedOrders.realisationDate' | translate }}"
              [allowSorting]="false"
              dataField="CompletionDate"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              [hidingPriority]="7"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              renderAsync="true"
              cellTemplate="numberPrecisionTemplate"
              [allowSorting]="false"
              dataField="TotalNetAmount"
              [hidingPriority]="6"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              renderAsync="true"
              cellTemplate="numberPrecisionTemplate"
              [allowSorting]="false"
              dataField="TotalGrossAmount"
              [hidingPriority]="5"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'bankAccounts.grid.columns.currency' | translate }}"
              [allowSorting]="false"
              dataField="CurrencyCode"
              [hidingPriority]="4"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'form-payment.paymentMethod' | translate }}"
              [allowSorting]="false"
              dataField="PaymentFormName"
              [hidingPriority]="3"
            >
            </dxi-column>
            <dxi-column
              caption="{{
                'form-commercial-operation.dateOfPayment' | translate
              }}"
              [allowSorting]="false"
              dataField="PaymentMaxDelay"
              [hidingPriority]="2"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'userExtensions.customers' | translate }}"
              [allowSorting]="false"
              dataField="CustomerName"
              [hidingPriority]="1"
            >
            </dxi-column>
            <dxi-column
              caption="{{ 'servicesDevices.remarks' | translate }}"
              [allowSorting]="false"
              dataField="Remarks"
              [hidingPriority]="0"
            >
            </dxi-column>

            <div *dxTemplate="let cell of 'numberPrecisionTemplate'">
              {{ cell.value | amountFormatter : cell }}
            </div>
          </dx-data-grid>

          <wapro-context-menu
            [dataSource]="offerOrdersContextMenu"
            [width]="200"
            [target]="'#offerOrders' + unicalGuid"
            (onItemClick)="offerOrdersContextMenuClick($event)"
          >
          </wapro-context-menu>
        </div>

        <div *ngSwitchCase="'comments' | translate">
          <dx-text-area
            [minHeight]="heightBottomGrid"
            [value]="articlesListPom.length ? articlesListPom[0].Remarks : null"
            [readOnly]="true"
            style="max-width: none !important; width: 100%"
          ></dx-text-area>
        </div>

        <div *ngSwitchCase="'servicesDevices.commentStatus' | translate">
          <dx-text-area
            [minHeight]="heightBottomGrid"
            [value]="articlesListPom.length ? articlesListPom[0].Comment : null"
            [readOnly]="true"
            style="max-width: none !important; width: 100%"
          ></dx-text-area>
        </div>

        <div *ngSwitchCase="'notes.notes' | translate">
          <app-notes
            #notesComponent
            componentName="offers"
            [objectId]="
              articleEdit?.length && !isSingleRecordVisible
                ? articleEdit[0]
                : null
            "
            *ngIf="
              bottomPanelTabs[selectedtabIndex] ===
                ('notes.notes' | translate) &&
              (event.deviceType === 'desktop'
                ? localStorageData.bottomPanelVisible
                : isDetails)
            "
            [timeout]="500"
            [componentHeight]="
              event.deviceType != 'mobile'
                ? localStorageData.bottomPanelHeight
                : 545
            "
          >
          </app-notes>
        </div>

        <div
          *ngSwitchCase="
            localStorageData?.selectedAdditionalTable?.text ||
              'articles.externalTable' | translate
          "
        >
          <app-table-user-extension
            [tableGroupCode]="USER_EXTENSIONS_GROUP_CODE"
            (onTableChanged)="onAdditionalTableChanged($event)"
            (onFocusElementChanged)="onFocusElementChanged($event)"
            [selectedTable]="localStorageData.selectedAdditionalTable"
            *ngIf="selectedtabIndex == 5 && articleIdFocused"
          ></app-table-user-extension>
        </div>
      </ng-container>
    </div>
  </dx-tab-panel>
  <dx-tooltip
    *ngFor="let tabs of bottomPanelTabs; let i = index"
    [target]="'#tabToolTip' + i + unicalGuid"
    [showEvent]="event.tooltipShowEvent"
    hideEvent="mouseleave"
    [hideOnOutsideClick]="false"
  >
    <div *dxTemplate="let data = data; of: 'content'">(Alt+{{ i + 1 }})</div>
  </dx-tooltip>
</ng-template>

<!-- mobile template -->
<ng-template #mobileTemplate>
  <div class="mobile-search-box-container">
    <app-custom-dropdown-box
      [items]="filterCriteria"
      [(selectedItem)]="valueCriteria"
      [(filterValue)]="filterValue"
      (onValueChanged)="onFilterDataChanged($event)"
      (onArrowDown)="gridBank.instance.focus()"
      #searchControl
    >
    </app-custom-dropdown-box>

    <dx-button type="normal">
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--sort-ascending': order === 'ASC',
          'absui-icon--sort-descending': order !== 'ASC'
        }"
        (click)="switchOrder(); getData()"
      ></i>
    </dx-button>
  </div>
  <dx-scroll-view
    width="100%"
    height="35"
    [scrollByContent]="true"
    direction="horizontal"
    showScrollbar="never"
  >
    <div class="" style="width: 1000px; padding-top: 2px">
      <app-date-range
        #dateRange
        [readOnly]="readOnly"
        [isPopupMode]="true"
        (onChoosed)="onDateRangeChoosed()"
        [chipsVisible]="true"
      >
      </app-date-range>
      <dx-button class="btn-filter-box chips-btn" *ngIf="onlySelected"
        >{{ "showSelected" | translate }}: <b>{{ "yes" | translate }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('onlySelected')"
        ></i>
      </dx-button>
      <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="localStorageData.statusName"
        (click)="openRightPanel()"
        >Status: <b>{{ localStorageData.statusName }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (click)="resetFiltr('statusName')"
        ></i>
      </dx-button>

      <!-- <dx-button
        class="btn-filter-box chips-btn"
        *ngIf="localStorageData?.advancedFilter?.FilterUsersDefinitionId"
        (click)="openRightPanel()"
        >{{ "filterPanel.template" | translate }}:
        <b>{{ localStorageData?.advancedFilter?.Name }}</b>
        <i
          class="icon absui-icon--multiplication chips-close"
          (onClick)="resetFiltr('advancedFilter')"
        ></i>
      </dx-button> -->
      <app-custom-chips-button
        *ngIf="
          localStorageData?.advancedFilter?.FilterUsersDefinitionId ||
          localStorageData?.advancedFilter?.IsPinned
        "
        [name]="'filterPanel.template' | translate"
        [labelExpr]="'Name'"
        [valueExpr]="'FilterUsersDefinitionId'"
        [selectedValueInput]="localStorageData?.advancedFilter?.Name || null"
        [list]="dynamicFilterUserDefinitions"
        (onValueChanged)="onAdvancedFilterTemplateChanged($event)"
      >
      </app-custom-chips-button>
      <!-- [chipsValue]="allVersionsActive" -->
      <app-custom-chips-button
        name="AllVersions"
        [list]="allVersionsItems"
        (onValueChanged)="onAllVersionsChanged($event)"
      >
      </app-custom-chips-button>
    </div>
  </dx-scroll-view>

  <dx-data-grid
    (onEditorPreparing)="onEditorPreparing($event)"
    [dataSource]="dataSource"
    [wordWrapEnabled]="false"
    [showBorders]="true"
    [columnResizingMode]="'widget'"
    shortcut
    [columnAutoWidth]="true"
    (onRowDblClick)="onRowDblClick()"
    (onKeyDown)="onKeyDown($event)"
    [height]="heightGrid - 60"
    [(selectedRowKeys)]="selectedRows"
    (onContentReady)="onContentReady($event)"
    [ngClass]="{
      dropDownBoxClassGrid: dropDownBoxMode,
      'focus-on-detail': isDetailOnFocus
    }"
    [remoteOperations]="true"
    [id]="'grid-mobile' + unicalGuid"
    (onCellClick)="onCellClick($event)"
    (onFocusedCellChanged)="onFocusedCellChanged($event)"
    (onOptionChanged)="onOptionChanged($event)"
    (onInitialized)="onInitialized($event)"
    [allowColumnResizing]="true"
    (onFocusedRowChanged)="onFocusedRowChanged($event)"
    [(focusedRowIndex)]="focusedRowIndex"
    [autoNavigateToFocusedRow]="true"
    (onSelectionChanged)="onSelectionChanged($event)"
    [focusedRowEnabled]="true"
    #gridBank
    class="main-mobile-grid"
  >
    <dxo-load-panel [enabled]="false"></dxo-load-panel>
    <dxo-scrolling
      mode="infinite"
      columnRenderingMode="virtual"
      preloadEnabled="true"
    ></dxo-scrolling>
    <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
    <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>
    <dxo-selection
      [mode]="modeSelectionMobile"
      showCheckBoxesMode="always"
      selectAllMode="page"
    >
    </dxo-selection>

    <dxi-column
      caption="{{ 'constractors.contractorName' | translate }}"
      [allowSorting]="false"
      dataField="OfferDocumentNumber"
      cellTemplate="mainColumn"
      width="200"
    >
    </dxi-column>

    <div
      appPreventRightClick
      (touchend)="onTouchEnd()"
      (touchstart)="onTouchStart($event)"
      *dxTemplate="let data of 'mainColumn'"
    >
      <div style="display: flex">
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == data.data.Semaphore,
            'semafore-edit-by-another-user':
              userId != data.data.Semaphore && data.data.Semaphore != null
          }"
        ></div>
        <div
          class="content-main-grid"
          [ngClass]="{
            'content-main-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <span class="hov-underline-mobile" (click)="editRecord($event)">{{
            data.data.OfferDocumentNumber
          }}</span
          ><br />
          <label class="mobile-grid-label"
            >{{ "form-financial-document.date" | translate }}:</label
          >
          <p class="mobile-grid-text">
            {{ data.data.OfferDocumentDate | date : event.dateFormat }}
          </p>
          <br />

          <label class="mobile-grid-label"
            >{{
              "form-financial-document.payer-type.contractor" | translate
            }}:</label
          >

          <p class="mobile-grid-text">
            {{
              data.data.CustomerName?.length > 25
                ? (data.data.CustomerName | slice : 0 : 25) + "..."
                : data.data.CustomerName
            }}
          </p>
          <br />

          <label class="mobile-grid-label"
            >{{ "plannedOrders.realisationDate" | translate }}:</label
          >

          <p class="mobile-grid-text">
            {{ data.data.RealizationPlanDate | date : event.dateFormat }}
          </p>
          <br />
        </div>
        <div
          class="right-mobile-grid text-center"
          [ngClass]="{
            'right-mobile-grid-selected': modeSelectionMobile == 'multiple'
          }"
        >
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--more-horiz"
            style="float: left; position: relative; top: calc(50% - 13px)"
            (onClick)="showContextMenuMobile(data.data)"
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-data-grid>

  <app-mobile-list
    [dataSource]="contextMenuMobileSignal()"
    [visible]="isContextMenuMobile"
    (onClosed)="isContextMenuMobile = false"
    (onItemClick)="contextMenuClick($event)"
  ></app-mobile-list>

  <div class="details-mobile-panel" *ngIf="isDetails">
    <header>
      <div class="d-flex flex-row">
        <div class="left-header">
          <i
            class="icon absui-backe"
            style="position: relative; top: 4px; margin-right: 10px"
            (click)="isDetails = false"
          ></i>
        </div>
        <div
          class="semafore-mobile"
          [ngClass]="{
            'semafore-edit-by-me': userId == articlesListPom[0].Semaphore,
            'semafore-edit-by-another-user':
              userId != articlesListPom[0].Semaphore &&
              articlesListPom[0].Semaphore != null
          }"
        ></div>
        <div class="right-details">
          <p *ngIf="articlesListPom[0]" class="nameSelectedPosition">
            {{ articlesListPom[0].OfferDocumentNumber }}
          </p>

          <label class="details-label"
            >{{ "form-financial-document.date" | translate }}:</label
          >
          <p class="details-text">
            {{ articlesListPom[0].OfferDocumentDate | date : event.dateFormat }}
          </p>

          <label class="details-label"
            >{{
              "form-financial-document.payer-type.contractor" | translate
            }}:</label
          >
          <p class="details-text">{{ articlesListPom[0].CustomerName }}</p>

          <label class="details-label"
            >{{ "plannedOrders.realisationDate" | translate }}:</label
          >
          <p class="details-text">
            {{ articlesListPom[0].RealizationPlanDate }}
          </p>
        </div>
      </div>
    </header>

    <div
      class="circle-showMore"
      (click)="isShowMoreDetails = !isShowMoreDetails"
    >
      <i
        class="icon"
        [ngClass]="{
          'absui-icon--expand-less': isShowMoreDetails,
          'absui-icon--expand-more': !isShowMoreDetails
        }"
      ></i>
    </div>

    <div class="more-mobile-details" *ngIf="isShowMoreDetails">
      <div>
        <div class="c-frame-row">
          <h5>{{ "tasks.details" | translate }}</h5>
        </div>
      </div>

      <div>
        <table style="width: 100%">
          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">Status:</p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ articlesListPom[0].OfferDocumentStatus }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.offerName" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ articlesListPom[0].Name }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.versionNr" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ articlesListPom[0].Version }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.validFrom" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  articlesListPom[0].OfferDocumentDateFrom
                    | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.validTo" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  articlesListPom[0].OfferDocumentDateTo
                    | date : event.dateFormat
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.grid.netValue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ articlesListPom[0].TotalNetAmount | amountFormatter : null }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.grid.grossValue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  articlesListPom[0].TotalGrossAmount | amountFormatter : null
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "bankAccounts.grid.columns.currency" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ articlesListPom[0].CurrencyCode }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.netPriceCur" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  articlesListPom[0].TotalNetAmountCurrency
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "plannedOrders.grossValCur" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  articlesListPom[0].TotalGrossAmountCurrency
                    | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "baseCurrencies.currencyValue" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{
                  articlesListPom[0].CurrencyFactor | amountFormatter : null : 4
                }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "articles.created" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ articlesListPom[0].AttachmentsAmount }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "servicesDevices.barcode" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ articlesListPom[0].EAN }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "worker" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ articlesListPom[0].EmployeeName }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-payment.paymentMethod" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ articlesListPom[0].PaymentFormName }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "form-commercial-operation.dateOfPayment" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ articlesListPom[0].PaymentMaxDelay }}
              </p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.personToContact" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">{{ articlesListPom[0].ContactName }}</p>
            </td>
          </tr>

          <tr>
            <td style="width: 50%; vertical-align: top">
              <p class="details-label text-right">
                {{ "offers.lastOfferNr" | translate }}:
              </p>
            </td>
            <td style="width: 50%; vertical-align: top; padding-left: 8px">
              <p class="details-text">
                {{ articlesListPom[0].OfferDocumentLastVersionId }}
              </p>
            </td>
          </tr>
        </table>
      </div>

      <div style="margin-top: 25px">
        <div class="c-frame-row">
          <h5>{{ "comments" | translate }}</h5>
        </div>
        <p class="details-text">{{ articlesListPom[0].Remarks }}</p>
      </div>
    </div>

    <ng-container *ngTemplateOutlet="bottomTab"></ng-container>
  </div>
</ng-template>

<app-user-guide
  *ngIf="isGuideActive"
  (finished)="onGuideFinished()"
  [view]="'offers'"
></app-user-guide>

<app-planned-orders
  *ngIf="isPlannedOrdersVisible"
  [isVisible]="true"
  [readOnly]="readOnly"
  [productId]="offerPositionsFocusedRowKey?.ProductId"
  [orderType]="plannedOrdersType"
  (onClosed)="onClosedPlannedOrders()"
>
</app-planned-orders>

<app-new-task
  [isVisible]="isTask"
  mode="edit"
  [selectedId]="taskSelectedId"
  (onClosing)="onNewTaskClosing()"
  (onInserted)="onNewTaskClosing()"
>
</app-new-task>

<app-expansion-list
  *ngIf="isExpansionListVisible"
  [isPopupMode]="true"
  [socketCodeFilter]="'XGOL'"
  (onClosing)="onExpansionListClosing($event)"
></app-expansion-list>

<app-contractors
  [popUpMode]="true"
  [selectedId]="articlesListPom[0]?.CustomerId"
  [isVisible]="isCustomerVisible"
  *ngIf="isCustomerVisible"
  (onClosed)="isCustomerVisible = false"
  (onChoosed)="onChoosedCustomer($event)"
></app-contractors>

<app-record-card
  *ngIf="isRecordCardVisible"
  [cardMode]="recordCardMode"
  selectedTab="{{ 'menu.offer' | translate }}"
  [isPopupMode]="true"
  [isVisible]="isRecordCardVisible"
  [objectId]="recordCardObjectId"
  (onClosing)="onRecordCardClosing()"
></app-record-card>

<ng-template #reportsTemplate>
  <app-reports
    #reports
    [code]="'Oferta'"
    [menuName]="menuName"
    type="OF"
    [objectId]="
      articlesListPom?.length ? articlesListPom[0]?.OfferDocumentId : null
    "
    [offer]="articlesListPom[0]"
    [selectedDocumentsData]="selectedDocumentsData"
    (onReportsPopupClosed)="onReportsPopupClosed()"
    [isVisible]="
      event.deviceType === 'mobile' ? isMobileReportsPopupVisible : true
    "
    [params]="getLoadParams()"
  >
  </app-reports>
</ng-template>

<ng-template #edocumentsTemplate>
  <app-e-dokuments
    [isPopupMode]="true"
    [isVisible]="eDocumentsVisible"
    [id]="'eDocuments' + unicalGuid"
    #eDocuments
    [permission]="perAbdExtended.eDocuments"
    DocumentCode="Offers"
    tableName="OFERTA"
    style="display: inline-block"
    [contextObjectId]="articlesListPom[0]?.OfferDocumentId"
    [parentDocumentName]="articlesListPom[0]?.OfferDocumentNumber"
    (onClosing)="eDocumentsVisible = false; cd.detectChanges()"
  ></app-e-dokuments>
</ng-template>

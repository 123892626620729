<dx-popup
  width="1100"
  height="550"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [title]="'offers.offerMargin' | translate"
  [showTitle]="true"
  [visible]="true"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  (onShowing)="event.onShownPopUp(unicalGuid)"
  (onHiding)="event.onHiddenPopUp(unicalGuid)"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        type="danger"
        text="{{ 'buttons.close' | translate }}"
        style="margin-right: 6px"
        (onClick)="onClosing.emit()"
        [id]="'cancel' + unicalGuid"
      >
      </dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
        [showEvent]="event.tooltipShowEvent"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.close" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <div class="header-bar d-flex" style="margin-bottom: 24px">
      <div
        class="confirme-info-message"
        style="top: 2px; position: relative"
      ></div>
      <div class="header-bar_text" style="padding: 4px 0">
        {{ "valuesMayChange" | translate }}
      </div>
    </div>

    <dx-data-grid
      [dataSource]="dataSource"
      [wordWrapEnabled]="false"
      [showBorders]="true"
      shortcut
      [height]="350"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      (onOptionChanged)="event.onOptionChanged($event)"
    >
      <dxi-column
        [caption]="'form-commercial-operation.grid.articleName' | translate"
        dataField="ProductName"
        alignment="left"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'customerGroups.catalogIndex' | translate"
        dataField="IndexCatalogue"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'articles.amount' | translate"
        dataField="Amount"
        cellTemplate="valueTemplate4"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'form-commercial-operation.grid.netValue' | translate"
        dataField="TotalNetAmount"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'netBuyPrice' | translate"
        dataField="TotalNetBuyAmount"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'form-commercial-operation.grid.grossValue' | translate"
        dataField="TotalGrossAmount"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'grossBuyPrice' | translate"
        dataField="TotalGrossBuyAmount"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'sumCommercialOperations.margin' | translate"
        dataField="Margin"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'sumCommercialOperations.markup' | translate"
        dataField="MarkUp"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'stockHistory.netProfit' | translate"
        dataField="NetProfit"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <dxi-column
        [caption]="'grossProfit' | translate"
        dataField="GrossProfit"
        cellTemplate="valueTemplate"
        [allowSorting]="false"
      ></dxi-column>
      <div *dxTemplate="let cell of 'valueTemplate4'">
        {{ cell.value | amountFormatter : cell : 4 }}
      </div>
      <div *dxTemplate="let cell of 'valueTemplate'">
        {{ cell.value | amountFormatter : cell }}
      </div>
    </dx-data-grid>

    <div class="count-container">
      <div class="row">
        <div class="col-6 text-left"></div>
        <!-- <div class="col-2 text-left r-border">
          <div>
            <p class="p-toPay text-uppercase">
              {{ "sumCommercialOperations.margin" | translate }}
            </p>
            <p class="p-toPayDesc"></p>
          </div>
        </div> -->
        <div class="col-2 text-right r-border" style="color: black">
          <p class="p-toPay-net">
            {{ sum.margin | amountFormatter : null }}
          </p>
          <p class="p-toPayDesc">
            {{ "sumCommercialOperations.margin" | translate }}
          </p>
        </div>
        <div class="col-2 text-right r-border" style="color: black">
          <p class="p-toPay-net">
            {{ sum.netProfit | amountFormatter : null }}
          </p>
          <p class="p-toPayDesc">
            {{ "stockHistory.netProfit" | translate }}
          </p>
        </div>
        <div class="col-2 text-right">
          <p class="p-toPay">
            {{ sum.grossProfit | amountFormatter : null }}
          </p>
          <p class="p-toPayDesc">
            {{ "grossProfit" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</dx-popup>

<wapro-keyboard-shortcuts [shortcuts]="shortcuts" [unicalGuid]="unicalGuid">
</wapro-keyboard-shortcuts>

<dx-popup
  [(width)]="widthWindow"
  [(height)]="heightWindow"
  position="bottom"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="
    isOutcome
      ? ('form-commercial-operation.approvalOfWarehouseOutgoingDocuments'
        | translate)
      : ('form-commercial-operation.approveDeferredIncome' | translate)
  "
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  [resizeEnabled]="false"
  [dragEnabled]="true"
  [minHeight]="280"
  (onShown)="event.onShownPopUp(); getTypeTree()"
  (onHidden)="event.onHiddenPopUp()"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        text="{{ 'buttons.close' | translate }}"
        type="danger"
        (onClick)="closeWindow()"
        [id]="'close' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="row-">
        <div class="col-md-12" style="padding-left: 0px; padding-right: 0px">
          <dx-button
            class="btn-dx-top-menu-folder"
            icon="icon absui-icon--mode-edit"
            [text]="'buttons.edit' | translate"
            [id]="'btn-edit' + unicalGuid"
            [disabled]="
              focusedSelected.length != 1 || readOnly || !perABD.editBtn
            "
            (onClick)="onEdit()"
          >
          </dx-button>

          <dx-button
            icon="icon absui-icon--form-items"
            [id]="'btn-show' + unicalGuid"
            class="btn-dx-top-menu-folder"
            [disabled]="focusedSelected.length != 1 || !perABD.showBtn"
            [text]="'buttons.show' | translate"
            (onClick)="onShown()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--select"
            [text]="'buttons.confirm' | translate"
            [id]="'btn-confirm' + unicalGuid"
            [disabled]="focusedSelected.length != 1 || readOnly"
            (onClick)="realizePzPostponed()"
          >
          </dx-button>

          <dx-button
            class="btn-dx-top-menu"
            icon="icon absui-icon--select"
            [text]="'buttons.confirmAll' | translate"
            [id]="'btn-confirmAll' + unicalGuid"
            [disabled]="readOnly || !DokMagZatwGrupOdl"
            (onClick)="realizePzPostponed(true)"
          >
          </dx-button>

          <dx-tooltip
            [target]="'#btn-confirm' + unicalGuid"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.confirm" | translate }} (Enter)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-edit' + unicalGuid"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.edit" | translate }} (F2)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-show' + unicalGuid"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.show" | translate }} (Shift + F2)
            </div>
          </dx-tooltip>

          <dx-tooltip
            [target]="'#btn-confirmAll' + unicalGuid"
            hideEvent="dxhoverend"
            [hideOnOutsideClick]="false"
            [showEvent]="event.tooltipShowEvent"
          >
            <div *dxTemplate="let data of 'content'">
              {{ "buttons.confirmAll" | translate }} (Ctrl + Alt + W)
            </div>
          </dx-tooltip>

          <div
            style="margin-top: 10px; margin-bottom: 10px; padding-left: 7px"
            *ngIf="!isOutcome"
          >
            <label
              class="label"
              style="display: inline-block; margin-right: 6px"
              >{{ "warehouseDocument.approvalDate" | translate }}</label
            >
            <wapro-date-box
              type="date"
              width="180"
              [displayFormat]="event.dateFormat"
              [(ngModel)]="approvalDate"
              [readOnly]="readOnly"
              style="position: relative; top: 2px"
            >
            </wapro-date-box>
          </div>

          <div
            style="margin-top: 10px; margin-bottom: 10px; padding-left: 7px"
            *ngIf="isOutcome"
          ></div>

          <app-date-range
            #dateRange
            [readOnly]="readOnly"
            [isPopupMode]="true"
            (onChoosed)="onDateRangeChoosed()"
            #dataRange
            [chipsVisible]="true"
            (onClosed)="cd.detectChanges()"
          >
          </app-date-range>

          <app-custom-chips-button
            name="{{
              'form-commercial-operation.includeApprovalDate' | translate
            }}"
            [list]="filterList"
            (onValueChanged)="onValueChangedState($event)"
            [selectedValueInput]="ApprovalDate"
            [disabledClear]="true"
            *ngIf="!isOutcome"
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="{{ 'form-commercial-operation.includeemployee' | translate }}"
            [list]="filterList"
            (onValueChanged)="onValueIncludeemployee($event)"
            [selectedValueInput]="isIncludeemployee"
            [disabledClear]="true"
            *ngIf="!isOutcome"
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="{{
              'form-commercial-operation.controlTheDeliveryDate' | translate
            }}"
            [list]="radioOption()"
            (onValueChanged)="onValueChangedControlDate($event)"
            [selectedValueInput]="IsControlDate"
            [disabledClear]="true"
            *ngIf="isOutcome"
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="{{
              'form-commercial-operation.controlTheDeliveryPrice' | translate
            }}"
            [list]="radioOption()"
            (onValueChanged)="onValueChangedControlPrice($event)"
            [selectedValueInput]="IsControlPrice"
            [disabledClear]="true"
            *ngIf="isOutcome"
          >
          </app-custom-chips-button>

          <app-custom-chips-button
            name="{{
              'form-commercial-operation.controlTheMinimumOverheadFromTheProductList'
                | translate
            }}"
            [list]="filterList"
            (onValueChanged)="onValueChangedControlMargin($event)"
            [selectedValueInput]="IsControlMargin"
            [disabledClear]="true"
            *ngIf="isOutcome"
          >
          </app-custom-chips-button>

          <!-- <app-custom-chips-button name="{{'warehouseDocument.currentMagazine' | translate}}" [list]="filterList"
            (onValueChanged)="onValueCurrentMagazine($event)" [(selectedValueInput)]="isCurrentMagazine"
            disabledClear="none">
          </app-custom-chips-button> -->

          <dx-data-grid
            [dataSource]="dataSource"
            [wordWrapEnabled]="false"
            [showBorders]="true"
            [(selectedRowKeys)]="selectedRows"
            [columnResizingMode]="'widget'"
            [remoteOperations]="true"
            height="calc(75vh - 190px)"
            style="margin-top: 10px"
            (onRowDblClick)="onRowDblClick()"
            [columnAutoWidth]="true"
            [allowColumnResizing]="true"
            (onFocusedRowChanged)="onFocusedRowChanged($event)"
            [id]="'grid' + unicalGuid"
            shortcut
            [autoNavigateToFocusedRow]="true"
            [focusedRowEnabled]="true"
            (onOptionChanged)="event.onOptionChanged($event)"
          >
            <dxo-load-panel [enabled]="false"></dxo-load-panel>
            <dxo-scrolling
              mode="virtual"
              columnRenderingMode="virtual"
              preloadEnabled="true"
            >
            </dxo-scrolling>
            <dxo-paging [pageSize]="100" [enabled]="true"></dxo-paging>
            <dxo-selection
              [mode]="'single'"
              showCheckBoxesMode="always"
              selectAllMode="page"
            >
            </dxo-selection>
            <dxo-editing mode="row" [confirmDelete]="false"></dxo-editing>

            <dxi-column
              caption="{{ 'form-financial-document.number' | translate }}"
              [width]="150"
              [allowSorting]="false"
              dataField="Number"
              cellTemplate="mainColumn"
              [cssClass]="orderby == 'Number' ? 'sort-column-bg' : null"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'mainColumn'">
              <div
                *ngIf="data.data.Semaphore"
                [ngClass]="{
                  editRowByMe: userId == data.data.Semaphore,
                  editRowByAnotherUser: userId != data.data.Semaphore
                }"
              ></div>
              <span class="hov-underline">{{
                document == "invoice"
                  ? data.data.InvoiceDocumentNumber
                  : data.data.WarehouseDocumentNumber
              }}</span>
            </div>

            <dxi-column
              caption="{{ 'form-financial-document.date' | translate }}"
              [allowSorting]="false"
              dataField="DateOfInvoiceDocument"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
              [cssClass]="
                orderby == 'DateOfInvoiceDocument' ? 'sort-column-bg' : null
              "
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-financial-document.payer-type.contractor' | translate
              }}"
              [allowSorting]="false"
              dataField="CustomerName"
              width="200"
              [cssClass]="orderby == 'CustomerName' ? 'sort-column-bg' : null"
              cellTemplate="CustomerNameTemp"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'CustomerNameTemp'">
              {{ data.data.CustomerName }}
            </div>

            <dxi-column
              caption="{{ 'warehouseDocument.label' | translate }}"
              width="105"
              [allowSorting]="false"
              dataField="LabelName"
              cellTemplate="labelTemplate"
              renderAsync="true"
              alignment="left"
            >
            </dxi-column>
            <div *dxTemplate="let data of 'labelTemplate'">
              <app-label
                [bgColor]="data.data.LabelBackgroundColor"
                [color]="data.data.LabelTextColor"
                [text]="data.data.LabelName"
              >
              </app-label>
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.netValue' | translate
              }}"
              dataField="TotalNetAmount"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalNetAmountCell"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmountCell'">
              {{ cell.data.TotalNetAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{ 'buffor.TotalTaxAmount' | translate }}"
              dataField="BankAccountId"
              [allowSorting]="false"
              width="100"
              cellTemplate="TotalTaxAmount"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalTaxAmount'">
              {{
                cell.data.TotalGrossAmount - cell.data.TotalNetAmount
                  | amountFormatter : cell
              }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.grid.grossValue' | translate
              }}"
              dataField="TotalGrossAmount"
              [allowSorting]="false"
              cellTemplate="TotalGrossAmountCell"
              [cssClass]="
                orderby === 'TotalGrossAmount' ? 'sort-column-bg' : null
              "
              width="150"
            >
              <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalGrossAmountCell'">
              {{ cell.data.TotalGrossAmount | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.ReceiptNumber' | translate
              }}"
              dataField="ReceiptNumber"
              [allowSorting]="false"
              width="150"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'comments' | translate }}"
              dataField="Remarks"
              [allowSorting]="false"
              width="150"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'bankAccounts.form.currency' | translate }}"
              dataField="CurrencyCode"
              [allowSorting]="false"
              width="100"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalNetAmountCurrency' | translate
              }}"
              dataField="TotalNetAmountCurrency"
              [allowSorting]="false"
              width="150"
              cellTemplate="TotalNetAmountCurrency"
            >
              <dxo-format type="fixedPoint" [precision]="4"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalNetAmountCurrency'">
              {{ cell.data.TotalNetAmountCurrency | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{
                'form-commercial-operation.TotalGrossAmountCurrency' | translate
              }}"
              dataField="TotalGrossAmountCurrency"
              [allowSorting]="false"
              width="150"
              cellTemplate="TotalGrossAmountCurrency"
            >
              <dxo-format type="fixedPoint" [precision]="4"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'TotalGrossAmountCurrency'">
              {{ cell.data.TotalGrossAmountCurrency | amountFormatter : cell }}
            </div>

            <dxi-column
              caption="{{ 'servicesDevices.saleDate' | translate }}"
              [allowSorting]="false"
              dataField="DateOfSell"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.DistinguishCode' | translate
              }}"
              dataField="DistinguishCode"
              [allowSorting]="false"
              width="100"
              [cssClass]="
                orderby === 'DistinguishCode' ? 'sort-column-bg' : null
              "
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'form-financial-document.payer' | translate }}"
              dataField="PayerName"
              [allowSorting]="false"
              width="150"
              [cssClass]="orderby === 'PayerName' ? 'sort-column-bg' : null"
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'baseCurrencies.currencyValue' | translate }}"
              dataField="CurrencyFactor"
              [allowSorting]="false"
              width="100"
              cellTemplate="CurrencyFactor"
            >
              <dxo-format type="fixedPoint" [precision]="4"> </dxo-format>
            </dxi-column>
            <div *dxTemplate="let cell of 'CurrencyFactor'">
              {{ cell.data.CurrencyFactor | amountFormatter : cell }}
            </div>
            <dxi-column
              caption="{{ 'form-financial-document.receivedDate' | translate }}"
              [allowSorting]="false"
              dataField="DateOfReceived"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="130"
              alignment="left"
              [cssClass]="
                orderby === 'DateOfReceived' ? 'sort-column-bg' : null
              "
            >
            </dxi-column>

            <dxi-column
              caption="{{ 'form-payment.paymentMethod' | translate }}"
              dataField="PaymentFormName"
              [allowSorting]="false"
              width="150"
            >
            </dxi-column>

            <dxi-column
              caption="{{
                'form-commercial-operation.dateOfPaymentt' | translate
              }}"
              dataField="DateOfPayment"
              [allowSorting]="false"
              dataType="date"
              [format]="{ type: event.dateFormat }"
              width="110"
              alignment="left"
            >
            </dxi-column>
          </dx-data-grid>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<div *ngIf="loadChildComponent">
  <app-new-comercial-operation
    [isVisible]="newRowPopupVisible"
    (onClosing)="closeNewComercialOperation()"
    title="{{ 'form-commercial-operation.title' | translate }}"
    (onInserted)="onInsertedDocument($event)"
    [typeSelectedDocument]="typeFilter.invoiceTypeIdShort"
    [mode]="mode"
    [selectedId]="focusedSelected"
    [readOnly]="mode == 'show' ? true : readOnly"
    *ngIf="document == 'invoice'"
  >
  </app-new-comercial-operation>

  <app-new-warehouse-document
    [isVisible]="newRowPopupVisible"
    (onClosing)="closeNewComercialOperation()"
    title="{{ 'warehouseDocument.addingWarehouseDocument' | translate }}"
    [selected]="focusedSelected"
    [editMode]="mode == 'edit' || mode == 'show' ? true : false"
    [readOnly]="mode == 'show' ? true : readOnly"
    *ngIf="document == 'warehouseDocuments'"
    [sumPositionIncome]="sumPositionIncome"
  >
  </app-new-warehouse-document>
</div>

<wapro-keyboard-shortcuts
  [shortcuts]="shortcuts"
  [disabled]="newRowPopupVisible || !isVisible"
  [unicalGuid]="unicalGuid"
>
</wapro-keyboard-shortcuts>

<dx-popup
  [width]="widthWindow"
  [height]="heightWindow"
  position="center"
  [showCloseButton]="false"
  [animation]="{
    show: { type: 'slideIn', direction: 'bottom' },
    hide: { type: 'slideOut', direction: 'bottom' }
  }"
  [showTitle]="true"
  [title]="title"
  [visible]="isVisible"
  (visibleChange)="visibleChange($event)"
  (onShown)="event.onShownPopUp(); event.setFocus(StockLeftInput)"
  (onHidden)="event.onHiddenPopUp(); selectedtab = 0"
  [wrapperAttr]="{ id: unicalGuid }"
>
  <div *dxTemplate="let content of 'content'">
    <div class="text-right title-pop-up">
      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'articles.title' | translate }}"
        style="margin-right: 6px"
        (onClick)="prepareForModify()"
        [id]="'article' + unicalGuid"
      >
      </dx-button>

      <dx-button
        class="outline-secondary"
        stylingMode="outlined"
        text="{{ 'categories.externalFields' | translate }}"
        style="margin-right: 6px"
        (onClick)="visibleUserExtensions = true"
        [id]="'externalFields' + unicalGuid"
        *ngIf="event.checkPermissions('PolaDodatkowe')"
      >
      </dx-button>

      <dx-button
        text="{{ 'buttons.save' | translate }}"
        type="success"
        style="margin-right: 6px"
        (onClick)="onSave()"
        [id]="'add' + unicalGuid"
      >
      </dx-button>
      <dx-button
        text="{{ 'buttons.cancel' | translate }}"
        type="danger"
        (onClick)="onClose()"
        [id]="'cancel' + unicalGuid"
      ></dx-button>

      <dx-tooltip
        [target]="'#externalFields' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "categories.externalFields" | translate }} (Ctrl + P)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#article' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "articles.title" | translate }} (F2)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#add' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.save" | translate }} (F10)
        </div>
      </dx-tooltip>

      <dx-tooltip
        [target]="'#cancel' + unicalGuid"
        [showEvent]="event.tooltipShowEvent"
        hideEvent="dxhoverend"
        [hideOnOutsideClick]="false"
      >
        <div *dxTemplate="let data of 'content'">
          {{ "buttons.cancel" | translate }} (Esc)
        </div>
      </dx-tooltip>
    </div>

    <dx-scroll-view width="100%" height="100%" direction="vertical">
      <div class="d-flex justify-content-center flex-wrap">
        <div class="form-group-inline-right" style="width: 470px">
          <form [formGroup]="form">
            <div class="row">
              <div class="col-md-12">
                <label style="width: 90px">{{
                  "companies.grid.columns.name" | translate
                }}</label>
                <dx-text-box
                  [readOnly]="true"
                  formControlName="ProductFullName"
                  style="max-width: 100%; width: calc(100% - 129px)"
                ></dx-text-box>
                <label style="width: 90px">{{
                  "sadDocuments.headerDate.positionIncome.index" | translate
                }}</label>
                <dx-text-box
                  [readOnly]="true"
                  formControlName="IndexCatalogue"
                  style="max-width: 100%; width: calc(100% - 129px)"
                ></dx-text-box>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label style="width: 90px"
                  >{{ "form-commercial-operation.grid.netAmount" | translate }}
                </label>
                <dx-number-box
                  formControlName="BuyPriceNet"
                  valueChangeEvent="input"
                  [format]="format"
                  width="84"
                  [readOnly]="!isDuplicat && isSupply"
                  (onInput)="onValueNetChange()"
                ></dx-number-box>

                <label style="width: 90px">{{
                  "inventory.positionUnit" | translate
                }}</label>
                <dx-text-box
                  [readOnly]="true"
                  formControlName="Unit"
                  width="84"
                ></dx-text-box>
              </div>
              <div class="col-md-6">
                <label style="width: 100px">{{
                  "form-commercial-operation.grid.grossAmount" | translate
                }}</label>
                <dx-number-box
                  formControlName="BuyPriceGross"
                  valueChangeEvent="input"
                  [format]="format"
                  [readOnly]="!isDuplicat && isSupply"
                  width="84"
                  (onInput)="onValueGrossChange()"
                ></dx-number-box>

                <label style="width: 100px">{{
                  "warehouseDocument.stockUnit" | translate
                }}</label>
                <dx-text-box
                  [readOnly]="true"
                  formControlName="BaseUnit"
                  width="84"
                ></dx-text-box>
              </div>
            </div>

            <dx-tab-panel
              #tabPanel
              [(selectedIndex)]="selectedtab"
              [items]="items"
              [loop]="false"
              [animationEnabled]="false"
              [swipeEnabled]="false"
              class=""
            >
              <div *dxTemplate="let item of 'title'">
                <div class="title-temp">
                  <span
                    class="dx-tab-text"
                    *ngIf="item == 1"
                    id="tabToolTip199"
                    >{{ "inventory.states" | translate }}</span
                  >

                  <span
                    class="dx-tab-text"
                    *ngIf="item == 2"
                    id="tabToolTip199"
                    >{{
                      "userGuide.production-documents-position.4.title"
                        | translate
                    }}</span
                  >
                </div>
              </div>

              <div *dxTemplate="let name of 'item'">
                <ng-container [ngSwitch]="name">
                  <div *ngSwitchCase="1">
                    <div class="row" style="margin-top: 20px">
                      <div class="col-md-6">
                        <div class="c-frame-row">
                          <h5>
                            {{ "inventory.beforeInventory" | translate }}
                          </h5>
                          <label style="width: 90px">{{
                            "warehouse.grid.state" | translate
                          }}</label>
                          <dx-number-box
                            [readOnly]="true"
                            formControlName="StockAmount"
                            valueChangeEvent="input"
                            width="84"
                          >
                          </dx-number-box>

                          <label style="width: 90px">{{
                            "form-commercial-operation.grid.netValue"
                              | translate
                          }}</label>
                          <dx-number-box
                            [readOnly]="true"
                            formControlName="NetValue"
                            valueChangeEvent="input"
                            [format]="format"
                            width="84"
                          ></dx-number-box>

                          <label style="width: 90px">{{
                            "form-commercial-operation.grid.grossValue"
                              | translate
                          }}</label>
                          <dx-number-box
                            [readOnly]="true"
                            formControlName="GrossValue"
                            valueChangeEvent="input"
                            [format]="format"
                            width="84"
                          ></dx-number-box>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="c-frame-row">
                          <h5>
                            {{ "inventory.afterInventory" | translate }}
                          </h5>
                          <label style="width: 90px">{{
                            "warehouse.grid.state" | translate
                          }}</label>
                          <dx-number-box
                            formControlName="StockLeft"
                            valueChangeEvent="input"
                            width="84"
                            (onValueChanged)="onValueChanged($event)"
                            #StockLeftInput
                            [ngClass]="{
                              'input-required':
                                !form.controls.StockLeft.valid && submitted
                            }"
                          >
                          </dx-number-box>

                          <label style="width: 90px">{{
                            "form-commercial-operation.grid.netValue"
                              | translate
                          }}</label>
                          <dx-number-box
                            [readOnly]="true"
                            formControlName="NetValueAfter"
                            valueChangeEvent="input"
                            [format]="format"
                            width="84"
                          ></dx-number-box>

                          <label style="width: 90px">{{
                            "form-commercial-operation.grid.grossValue"
                              | translate
                          }}</label>
                          <dx-number-box
                            [readOnly]="true"
                            formControlName="GrossValueAfter"
                            valueChangeEvent="input"
                            [format]="format"
                            width="84"
                          ></dx-number-box>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngSwitchCase="2">
                    <div class="row" style="margin-top: 20px">
                      <div class="col-md-12">
                        <div class="c-frame-row">
                          <label style="width: 90px">{{
                            "deliveries.serialNo" | translate
                          }}</label>
                          <dx-text-box
                            formControlName="SerialNumber"
                            valueChangeEvent="input"
                            width="276"
                          >
                          </dx-text-box>

                          <label style="width: 90px">{{
                            "form-financial-document.date" | translate
                          }}</label>
                          <wapro-date-box
                            type="date"
                            width="276"
                            [displayFormat]="event.dateFormat"
                            style="display: inline-block"
                            formControlName="ExpirationDate"
                            [readOnly]="false"
                          >
                          </wapro-date-box>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </dx-tab-panel>

            <div class="row" style="margin-top: 20px">
              <div class="col-md-12">
                <dx-check-box
                  formControlName="IsFinished"
                  text="{{ 'inventory.inventoryMade' | translate }}"
                ></dx-check-box>
              </div>
            </div>
          </form>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>

<!-- pola dodatkowe -->
<app-form-user-extensions
  [isVisible]="visibleUserExtensions"
  groupCode="Artykul"
  title="{{ 'categories.externalFields' | translate }}"
  (onChoosed)="onInsertedUserExtensions($event)"
  (onClosing)="onClosingUserExtensions()"
  [userExtensionsValue]="productData[0]"
  *ngIf="productData && productData.length == 1 && this.isVisible"
>
</app-form-user-extensions>

<app-new-article
  [isVisible]="isArticle"
  mode="edit"
  [readOnly]="false"
  [isInventory]="true"
  [selectedId]="ProductId"
  (onClosing)="isArticle = false; cd.detectChanges()"
  (onInserted)="isArticle = false; cd.detectChanges()"
>
</app-new-article>

<wapro-keyboard-shortcuts
  [unicalGuid]="unicalGuid"
  [shortcuts]="shortcuts"
  [disabled]="!isVisible"
>
</wapro-keyboard-shortcuts>

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleRecordMode } from 'src/app/event.model';
import { IProductCategory } from 'src/app/libraries/articles/categories/add-category/ICategory';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { EventService } from 'src/app/event.service';
import { ORDERS_OPERATORS_KEY } from 'src/app/consts';
import { AppServices } from 'src/app/app-services.service';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';
import {
  DxButtonModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxComponent,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgShortcutsComponent } from 'src/app/core/ng-keyboard-shortcuts/ng-keyboardng-keyboard-shortcuts.component';

@Component({
    selector: 'app-add-orders-delivery-operator',
    imports: [
        CommonModule,
        DxPopupModule,
        DxButtonModule,
        DxTooltipModule,
        DxTextBoxModule,
        ReactiveFormsModule,
        DxTextAreaModule,
        TranslateModule,
        DxScrollViewModule,
        NgShortcutsComponent,
    ],
    templateUrl: './add-orders-delivery-operator.component.html',
    styleUrl: './add-orders-delivery-operator.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddOrdersDeliveryOperatorComponent {
  @ViewChild('focusFirst') focusFirst: DxTextBoxComponent;
  @Input() mode: SingleRecordMode = null;
  @Input() selectedId: any = null;
  @Input() readOnly = false;
  @Output() onClosing = new EventEmitter();
  @Output() onInserted: EventEmitter<IProductCategory> = new EventEmitter();

  shortcuts: ShortcutInput[] = [];
  form: FormGroup;
  appService = inject(AppServices);
  fb = inject(FormBuilder);
  event = inject(EventService);
  submitted = false;
  unicalGuid = new Date().getTime() + Math.round(Math.random() * 10000);

  constructor() {
    this.createForm();
  }

  createForm = () => {
    this.form = this.fb.group({
      DeliveryOperatorId: [0],
      Name: ['', Validators.required],
      DeliveryOperatorIdAuction: [0],
      Url: [''],
    });
  };

  ngOnChanges() {
    let selected;
    if (Array.isArray(this.selectedId)) {
      selected = this.selectedId[0];
    } else {
      selected = this.selectedId;
    }

    if (this.mode === 'edit') {
      const localData = this.event.getSessionStorageData(ORDERS_OPERATORS_KEY);
      if (localData) {
        this.form.patchValue(
          localData.find((el) => el.DeliveryOperatorId === selected)
        );
      }
    }

    // setTimeout(() => {
    //   this.focusFirst?.instance?.focus();
    // }, 300);
  }

  ngAfterViewInit() {
    this.shortcuts.push(
      {
        key: 'esc',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onClosing.emit(false);
        },
        preventDefault: true,
      },
      {
        key: 'F10',
        allowIn: [AllowIn.Input, AllowIn.Select, AllowIn.Textarea],
        command: () => {
          this.onSave();
        },
        preventDefault: true,
      }
    );
  }

  onSave = () => {
    this.submitted = true;
    if (!this.form.valid) {
      return;
    }

    this.appService
      .postAuth(`orders/documents/DeliveryOperators`, this.form.value)
      .subscribe({
        next: (data) => {
          this.onInserted.emit(data);
          this.onClosing.emit(true);
        },
        error: (error) => {
          this.event.httpErrorNotification(error);
        },
      });
  };
}
